
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class ForgotPasswordStore extends EventEmitter {
    ForgotPasswordList = "";
    handleForgotPasswordStore = (action) => {
        switch (action.action.type) {
            case "Forgot_Password": {
                this.ForgotPasswordList = action.action.response;
                this.emit('Forgot_Password');
                break;
            }
        }
    }

}

const objForgotPasswordStore = new ForgotPasswordStore;
Dispatcher.register(objForgotPasswordStore.handleForgotPasswordStore.bind(objForgotPasswordStore));
export default objForgotPasswordStore;