import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
import * as MenuAction from './MenuAction';
const NotificationPageLoad = (UserNOtificationBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserNotification,
            SDIConstants.methodNames.UserNotification_PagLoad,
            JSON.stringify(UserNOtificationBO),

            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.UserNotification_PagLoad, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const ArchiveUpdate = (ArchiveBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserNotification,
            SDIConstants.methodNames.UserNotification_imgArchive_Command,
            JSON.stringify(ArchiveBO),

            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.UserNotification_imgArchive_Command, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const NotificationDetail = (DetatilBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserNotification,
            SDIConstants.methodNames.UserNotification_NotifyDetail,
            JSON.stringify(DetatilBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.UserNotification_NotifyDetail, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const ExportData = (OrderStatusDataBO) => {
    try {
        debugger;
        SDISVC.downloadFile(
            SDIConstants.moduleNames.UserNotification,
            SDIConstants.methodNames.UserNotification_FileDownload,
            JSON.stringify(OrderStatusDataBO),
            OrderStatusDataBO.filenameBO,
            (data) => {
                //[5/10/22, CAS - 31, Dhevanesam R]
                console.log("notdata", data)
                 //[03/21/22, PC_UN_08, CAS - Notification download issue fix, Dhevanesam R]
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.UserNotification_FileDownload, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else {
                    //[5/10/22, CAS - 31, Dhevanesam R]
                    let contentData = { type: SDIConstants.methodNames.UserNotification_FileDownload, response: data.success };
                    Dispatcher.dispatch(contentData);                    
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

export { NotificationPageLoad, ArchiveUpdate, NotificationDetail, ExportData, deleteFavPage, insertFavPage, getCurrentFavPage}