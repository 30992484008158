/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/17/2020
 * 
 * Ref: PO_PC_19
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class OrderApprovalStore extends EventEmitter {
    approvalData = "";
    approveDeclineResponse = "";
    currentFavPageResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    /**
    * Ref: PO_PC_20
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleOrderApprovalStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.OrderApproval_PageLoad: {
                this.approvalData = action.action.response;
                this.emit('approvalData');
                break;
            }
            case SDIConstants.methodNames.OrderApproval_ApproveHeaderOrder: {
                this.approveDeclineResponse = action.action.response;
                this.emit('approveDeclineResponse');
                break;
            }
        
            case "ORDERAPP_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "ORDERAPP_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "ORDERAPP_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }


        }
    }
}
const objOrderApprovalStore = new OrderApprovalStore;
// Dispatcher code
Dispatcher.register(objOrderApprovalStore.handleOrderApprovalStore.bind(objOrderApprovalStore));
export default objOrderApprovalStore;