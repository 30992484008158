import * as $ from 'jquery';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';
import * as MenuAction from '../actions/MenuAction';
import * as ProgramMasterAction from '../actions/ProgramMasterAction';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import ProgramMasterStore from '../stores/ProgramMasterStore';
import Autocomplete from 'react-autocomplete';
let currentPagePath;


class ProgramMasterComponent extends Component {
    constructor(props) {

        super(props);
        this.programMasterPageloadResponse = this.programMasterPageloadResponse.bind(this);
        this.programOperationsResponse = this.programOperationsResponse.bind(this);
        this.programOperations = this.programOperations.bind(this);
        this.cancelProgram = this.cancelProgram.bind(this);
        this.retrieveDataAsynchronously = this.retrieveDataAsynchronously.bind(this);
        this.handleSearchClear = this.handleSearchClear.bind(this);

        this.state = {
            isFavorited: false,
            programGridArray: [],
            identifierID: 0,
            isFilter: false,
            programName: '',
            oldProgramName: '',
            programNameAlias: '',
            category: '',
            categoryAlias: '',
            subCategory: '',
            subCategoryAlias: '',
            navigationURL: '',
            active_No: false,
            active_Yes: true,
            site_Name_Z: true,
            site_Name_W: false,
            site_Name_I: false,
            site_Name_O: false,
            explicitSecurity: '',
            accessGroup_customer: true,
            accessGroup_SDI: false,
            explicitSecurity_Yes: false,
            explicitSecurity_No: true,
            queryStringParameters: '',
            filterDisplayName: 'All',
            searchCriteria: 'ALL',
            searchText: '',
            openAccordionIndex: null,
            pageNo: 1,
            totalCount: 0,
            countPerPage: 10,
            pageCount: 0,
            filteredPrograms: [],
            btnName: 'Submit',
            headerName: 'Add New Program',
            popUpText: '',
            categoryDD: [],
            categoryAliasDD: [],
            subCategoryDD: [],
            subCategoryAliasDD: [],
            programNameErr: false,
            programNameAliasErr: false,
            programNameAliasEmptyErr: false,
            categoryErr: false,
            categoryAliasErr: false,
            subcategoryErr: false,
            subcategoryAliasErr: false,
            siteNameErr: false,
            categoryAliasDisable: false,
            subCategoryAliasDisable: false,
            securityAliasDD: [],
            programNameAliasErrText: '',
            categoryAliasErrText: '',
            subCategoryAliasErrText: '',
            categoryEmptyErr: false,
            subCategoryEmptyErr: false,
            subCategoryAliasEmptyErr: false,
            categoryAliasEmptyErr: false,
            userId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            bU: CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            existSubCategory: false,
            existCategory: false,
            applySiteArray: [{ key: "Z", value: "Zeus 2.0", variable: "site_Name_Z" },
            { key: "W", value: "Walmart", variable: "site_Name_W" },
            { key: "I", value: "IPM", variable: "site_Name_I" },
            { key: "M", value: "Zeus Ordering", variable: "site_Name_O" }],
            isCatAliasAndSubCatAliassame: false,
            isCatAndSubCatsame: false,
            oldCategory: "", oldSubCategory: "", dependentSubCategory: []
        };

    }
    /**         
    * PS_ZE_243_API_08/Vishalini
    * Invoke this method on the click of Manual stock adjustment from menu bar.
    * */
    componentDidMount() {
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;

        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        ProgramMasterAction.getCurrentFavPage(getFavPageBO);

        this.programMasterPageload("", this.state.searchCriteria, this.state.isFilter);

        ProgramMasterStore.on('programMasterPageloadResponse', this.programMasterPageloadResponse);
        ProgramMasterStore.on('programOperationResponse', this.programOperationsResponse);
        ProgramMasterStore.on('favpage', this.loadFavPageResponse.bind(this));
        ProgramMasterStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    /**         
    * PS_ZE_243_API_07/Vishalini
    * Invoke this method while redirect to other panels from program master panel.
    * */
    componentWillUnmount() {
        ProgramMasterStore.removeListener('programMasterPageloadResponse', this.programMasterPageloadResponse);
        ProgramMasterStore.removeListener('programOperationResponse', this.programOperationsResponse);
        ProgramMasterStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        ProgramMasterStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    /****************************************************************Component methods starts here***********************************************************************/

    //PS_ZE_243_API_10 Pagaeload method to invoke action method[Vishalini]
    programMasterPageload(searchColumn, searchCriteria, isFilter) {
        let ProgramPageLoadBO = {
            searchType: searchColumn,
            searchCriteria: searchCriteria,
            isFilter: isFilter,
            userID: this.state.userId,
            bU: this.state.bU,
            websiteID: this.state.websiteID
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        ProgramMasterAction.getProgramMasterPageload(ProgramPageLoadBO);
        $("#ProgramMaster_Form").hide();
    }
    //PS_ZE_243_API_14 Invoked while choosing the filter type[Vishalini]
    handleFiltering = (filterDisplayName, columnName) => {
        this.setState({
            filterDisplayName: filterDisplayName,
            searchCriteria: columnName
        });
    }
    //PS_ZE_243_API_14.1 Invoked while chnaging the text in search field[Vishalini]
    handleSearchText = (event) => {
        this.setState({ searchText: event.target.value, openAccordionIndex: null, });
    }


    //PS_ZE_243_API_13 Pagaeload response method[Vishalini]
    programMasterPageloadResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let ProgramPageLoadResponse;
        let errorDt;

        ProgramPageLoadResponse = JSON.parse(ProgramMasterStore.programPageLoadData);
        errorDt = ProgramPageLoadResponse.errorDt;

        if (errorDt && errorDt.length > 0 && errorDt[0].errorMessage === true) {
            swal("Technical error, please contact SDI help desk");
            return;
        }

        let LocProgramGridArray = [];

        if (ProgramPageLoadResponse && ProgramPageLoadResponse.ProgramPageLoadDT) {
            LocProgramGridArray = ProgramPageLoadResponse.ProgramPageLoadDT;

            let LocCategoryDD = [];
            let LocCategoryAliasDD = [];
            let LocSubCategoryDD = [];
            let LocSubCategoryAliasDD = [];
            let LocSecurityAliasDD = [];

            // Iterate over the values and filter out duplicates and blank rows
            ProgramPageLoadResponse.ProgramPageLoadDT.forEach(value => {
                // Check if the value is not blank and not already in the array, then push it
                if (value.ISA_CATEGORY_NAME && value.ISA_CATEGORY_NAME.trim() != "" && !LocCategoryDD.some(item => item.label === value.ISA_CATEGORY_NAME)) {
                    LocCategoryDD.push({ label: value.ISA_CATEGORY_NAME, value: value.ISA_CATEGORY_NAME });
                }
                if (value.CATSECALIAS && !LocCategoryAliasDD.some(item => item.label === value.CATSECALIAS)) {
                    LocCategoryAliasDD.push({ label: value.CATSECALIAS, value: value.CATSECALIAS });
                }
                if (value.ISA_PARENT_NAME && value.ISA_PARENT_NAME.trim() != "" && !LocSubCategoryDD.some(item => item.label === value.ISA_PARENT_NAME)) {
                    LocSubCategoryDD.push({ label: value.ISA_PARENT_NAME, value: value.ISA_PARENT_NAME });
                }
                if (value.PARENTSECALIAS && !LocSubCategoryAliasDD.some(item => item.label === value.PARENTSECALIAS)) {
                    LocSubCategoryAliasDD.push({ label: value.PARENTSECALIAS, value: value.PARENTSECALIAS });
                }
                if (value.SECURITYALIAS && !LocSecurityAliasDD.some(item => item.label === value.SECURITYALIAS)) {
                    LocSecurityAliasDD.push({ label: value.SECURITYALIAS, value: value.SECURITYALIAS });
                }
            });

            $("#ProgramMaster_Form").hide();
            $("#ProgramMaster_Grid").show();
            $("#title").show();

            let totalCountLoc = LocProgramGridArray.length;
            let pg = Math.ceil((totalCountLoc > 0 ? totalCountLoc : 0) / this.state.countPerPage);

            this.setState({
                programGridArray: LocProgramGridArray,
                totalCount: totalCountLoc,
                categoryDD: LocCategoryDD,
                initCategoryDD: LocCategoryDD,
                categoryAliasDD: LocCategoryAliasDD,
                subCategoryDD: LocSubCategoryDD,
                initSubCategoryDD: LocSubCategoryDD,
                subCategoryAliasDD: LocSubCategoryAliasDD,
                pageCount: pg,
                pageNo: 1,
                securityAliasDD: LocSecurityAliasDD

            });
        }
    };



    //PS_ZE_243_API_15 On click of enter in search field[Vishalini]
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.programMasterPageload(this.state.searchCriteria, this.state.searchText, true);
        };
    }
    //PS_ZE_243_API_16 On click of cancel icon in search field, this method get invoked[Vishalini]
    handleSearchClear = () => {
        const LoctotalCount = this.state.programGridArray.length;

        const LocPageCount = Math.ceil(LoctotalCount / this.state.countPerPage);
        this.setState({
            searchText: '',
            filteredPrograms: this.state.programGridArray,
            isFilter: false,
            filterDisplayName: 'All',
            pageCount: LocPageCount,
            totalCount: LoctotalCount,
            openAccordionIndex: null,
            searchCriteria: 'ALL'
        });
        //this.programMasterPageload(this.state.searchCriteria, this.state.searchText, false);

    };

    sorting = (sort, name) => {
        let data = []
        switch (name) {
            case "ISA_IDENTIFIER": {
                data = this.sort(this.state.programGridArray, "Arr[i].ISA_IDENTIFIER", "Arr[j].ISA_IDENTIFIER", sort)
                break;
            }
            case "ISA_IDENTIFIER_NAME": {
                data = this.sort(this.state.programGridArray, "Arr[i].ISA_IDENTIFIER_NAME", "Arr[j].ISA_IDENTIFIER_NAME", sort)
                break;
            }
            case "SECURITYALIAS": {
                data = this.sort(this.state.programGridArray, "Arr[i].SECURITYALIAS", "Arr[j].SECURITYALIAS", sort)
                break;
            }
            case "ISA_CATEGORY_NAME": {
                data = this.sort(this.state.programGridArray, "Arr[i].ISA_CATEGORY_NAME", "Arr[j].ISA_CATEGORY_NAME", sort)
                break;
            }
            case "CATSECALIAS": {
                data = this.sort(this.state.programGridArray, "Arr[i].CATSECALIAS", "Arr[j].CATSECALIAS", sort)
                break;
            }
            case "ISA_PARENT_NAME": {
                data = this.sort(this.state.programGridArray, "Arr[i].ISA_PARENT_NAME", "Arr[j].ISA_PARENT_NAME", sort)
                break;
            }
            case "PARENTSECALIAS": {
                data = this.sort(this.state.programGridArray, "Arr[i].PARENTSECALIAS", "Arr[j].PARENTSECALIAS", sort)
                break;
            }
            default: {
                data = this.sort(this.state.programGridArray, "Arr[i].DATETIME_MODIFIED", "Arr[j].DATETIME_MODIFIED", "DESC");
            }
        }
        this.setState({
            programGridArray: data,
            openAccordionIndex: null
        });

    };

    sort = (Arr, indexval, nextindexval, Type) => {
        let temp = []
        let size = Arr.length
        let i, j = 0;

        if (Type == "ASC") {
            for (i = 0; i < size; i++) {

                for (j = i + 1; j < size; j++) {

                    if (eval(indexval) > eval(nextindexval)) {
                        temp = Arr[i];
                        Arr[i] = Arr[j];
                        Arr[j] = temp;
                    }
                }
            }
            return Arr;
        } else {
            for (i = 0; i < size; i++) {

                for (j = i + 1; j < size; j++) {

                    if (eval(indexval) < eval(nextindexval)) {
                        temp = Arr[i];
                        Arr[i] = Arr[j];
                        Arr[j] = temp;
                    }
                }
            }
            return Arr;
        }
    };

    //PS_ZE_243_API_19 On click of edit icon[Vishalini]
    handleEditClick = (programList) => {
        $("#title").hide();
        const EditPrograms = this.state.programGridArray.filter(
            (x) => x.ISA_IDENTIFIER === programList.ISA_IDENTIFIER
        );

        if (EditPrograms.length === 1) {
            const LocheaderName = programList.ISA_IDENTIFIER_NAME;
            let applySiteStr = programList.SITE_NAME;
            let siteNameState = {
                site_Name_W: false,
                site_Name_Z: false,
                site_Name_I: false,
                site_Name_O: false
            };

            for (const char of applySiteStr) {
                const matchingItem = this.state.applySiteArray.find(item => item.key.toLowerCase() === char.toLowerCase());
                if (matchingItem) {
                    siteNameState[matchingItem.variable] = true;
                }
            }

            let LocSubCategoryDD = [];
            if (programList.ISA_CATEGORY_NAME != null) {
                this.state.programGridArray.forEach(value => {
                    if (value.ISA_CATEGORY_NAME != null && value.ISA_CATEGORY_NAME.toLowerCase() == programList.ISA_CATEGORY_NAME.toLowerCase() && value.ISA_PARENT_NAME && value.ISA_PARENT_NAME.trim() != "" && !LocSubCategoryDD.some(item => item.label.toLowerCase() === value.ISA_PARENT_NAME.toLowerCase())) {
                        LocSubCategoryDD.push({
                            label: value.ISA_PARENT_NAME,
                            value: value.ISA_PARENT_NAME,
                            subCategoryAlias: value.PARENTSECALIAS
                        });
                    }
                });
            }

            this.setState({
                btnName: 'Update',
                headerName: LocheaderName,
                identifierID: programList.ISA_IDENTIFIER,
                programName: programList.ISA_IDENTIFIER_NAME,
                oldProgramName: programList.ISA_IDENTIFIER_NAME,
                programNameAlias: programList.SECURITYALIAS,
                category: programList.ISA_CATEGORY_NAME.trim() == "" ? '' : programList.ISA_CATEGORY_NAME,
                oldCategory: programList.ISA_CATEGORY_NAME.trim() == "" ? '' : programList.ISA_CATEGORY_NAME,
                categoryAlias: programList.CATSECALIAS.trim() == "" ? '' : programList.CATSECALIAS,
                subCategory: programList.ISA_PARENT_NAME.trim() == "" ? '' : programList.ISA_PARENT_NAME,
                oldSubCategory: programList.ISA_PARENT_NAME.trim() == "" ? '' : programList.ISA_PARENT_NAME,
                subCategoryAlias: programList.PARENTSECALIAS.trim() == "" ? '' : programList.PARENTSECALIAS,
                navigationURL: programList.ISA_NAVIGATIONURL == null ? '' : programList.ISA_NAVIGATIONURL,
                active_Yes: programList.ACTIVE == 1 ? true : false,
                active_No: programList.ACTIVE == 1 ? false : true,
                ...siteNameState,
                accessGroup_customer: programList.ACCESS_GROUP == 'CUST/USER' ? true : false,
                accessGroup_SDI: programList.ACCESS_GROUP == 'SDI' ? true : false,
                explicitSecurity_Yes: programList.EXTERNAL_SECURITY == 'Y' ? true : false,
                explicitSecurity_No: programList.EXTERNAL_SECURITY == 'N' || programList.EXTERNAL_SECURITY == null ? true : false,
                queryStringParameters: programList.PARMLIST,
                categoryDD: this.state.categoryDD,
                categoryAliasDD: this.state.categoryAliasDD,
                subCategoryDD: LocSubCategoryDD,
                subCategoryAliasDD: this.state.subCategoryAliasDD,
                categoryAliasDisable: programList.CATSECALIAS.trim() == "" ? false : true,
                subCategoryAliasDisable: programList.PARENTSECALIAS.trim() == "" ? false : true
            });

            $("#ProgramMaster_Grid").hide();
            $("#ProgramMaster_Form").show();
        }
    };

    //PS_ZE_243_API_20 On click of submit/update/delete[Vishalini]
    programOperations = (operation) => {
        let isValidationSuccessful = true;
        if (operation !== "DELETE") {
            isValidationSuccessful = this.handleValidations();
        }
        if (isValidationSuccessful) {
            const OperationBO = {
                userID: this.state.userId,
                bU: this.state.bU,
                websiteID: this.state.websiteID,
                operation: operation === 'Submit' ? "INSERT" : operation === 'Update' ? "UPDATE" : operation,
                isaProgramLevel: 1,
                identifierID: this.state.identifierID,
                isaIdentifierName: this.state.programName,
                isNameUpdated: this.state.programName != this.state.oldProgramName ? "True" : "False",
                securityAlias: this.state.programNameAlias.toUpperCase(),
                isaCategoryName: this.state.category,
                catSecAlias: this.state.categoryAlias.toUpperCase(),
                isaParentName: this.state.subCategory,
                parentSecAlias: this.state.subCategoryAlias.toUpperCase(),
                isaNavigationUrl: this.state.navigationURL === '' ? '/' : this.state.navigationURL,
                active: this.state.active_Yes ? 1 : 0,
                siteName: (this.state.site_Name_W ? 'W' : '') + (this.state.site_Name_Z ? 'Z' : '') + (this.state.site_Name_I ? 'I' : '') + (this.state.site_Name_O ? 'M' : ''),
                accessGroup: this.state.accessGroup_customer ? 'CUST/USER' : 'SDI',
                isaPortal: "Customer",
                isaCreatedBy: this.state.userId,
                isaModifiedBy: this.state.userId,
                externalSecurity: this.state.explicitSecurity_Yes ? 'Y' : 'N',
                parmList: this.state.queryStringParameters == null || this.state.queryStringParameters == '' ? 'EMPTYparam' : this.state.queryStringParameters
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            ProgramMasterAction.programOperations(OperationBO);
        }
    }


    //PS_ZE_243_API_23 On click of submit/update/delete[Vishalini]
    programOperationsResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        $("#DeletePopup").modal('hide');
        let ProgramOperationResponse;
        let errorDt;
        ProgramOperationResponse = JSON.parse(ProgramMasterStore.programOperationResponse);
        errorDt = ProgramOperationResponse.errorDt;

        if (errorDt && errorDt.length > 0 && errorDt[0].errorMessage === true) {
            swal("Technical error, please contact SDI help desk");
            return;
        }
        else {
            let LocPopUpText = '';
            if (ProgramOperationResponse.programExists === 1) {
                LocPopUpText = "Program name already exists!";
            }
            else {
                LocPopUpText = ProgramOperationResponse.rowAffected === 0 ?
                    (ProgramOperationResponse.operation === "INSERT" ? "New program is not inserted successfully!" :
                        (ProgramOperationResponse.operation === "UPDATE" ? "Program is not updated successfully!" :
                            (ProgramOperationResponse.operation === "DELETE" ? "Program is not deleted successfully!" : ""))) :
                    (ProgramOperationResponse.rowAffected === 1 ?
                        (ProgramOperationResponse.operation === "INSERT" ? "New program created successfully!" :
                            (ProgramOperationResponse.operation === "UPDATE" ? "Program updated successfully!" :
                                (ProgramOperationResponse.operation === "DELETE" ? "Program deleted successfully!" : ""))) : "");
                this.clearFields();
            }



            this.setState({ popUpText: LocPopUpText });
            $("#success_failure_popup").modal('show');


        }
    }
    //PS_ZE_243_API_24 On click of fields in add program forms, this method get hitted[Vishalini]
    handleOnChangeFields = (name1, event, type, name2) => {
        let newValue;
        // this.handleValidations();       
        if (type === 'checkbox') {
            newValue = event.target.checked;
            // If it's a checkbox and its value is true, set the value of the other checkbox to false
            if (newValue === true) {
                this.setState({ [name2]: false });
            }
        } else {
            newValue = event.target.value;
        }


        this.setState({ [name1]: newValue }, () => {
            if (type === "dropdown") {
                this.retrieveDataAsynchronously(newValue, name1);
            }
        });
    };


    retrieveDataAsynchronously = async (searchText, name) => {
        let filteredData;
        let responseData = this.state.programGridArray;
        let autoCompleteDataRes = [];

        let LocCategoryDD = [];
        let LocCategoryAliasDD = [];
        let LocSubCategoryDD = [];
        let LocSubCategoryAliasDD = [];

        switch (name) {
            case "category":
                responseData.forEach(value => {
                    if (value.ISA_CATEGORY_NAME && value.ISA_CATEGORY_NAME.trim() != "" && !LocCategoryDD.some(item => item.label === value.ISA_CATEGORY_NAME)) {
                        LocCategoryDD.push({ label: value.ISA_CATEGORY_NAME, value: value.ISA_CATEGORY_NAME });
                    }
                });
                filteredData = LocCategoryDD
                    .filter(item => item.label && item.label.toLowerCase().includes(searchText.toLowerCase()))
                    .map(item => item.label);
                break;
            //case "categoryAlias":
            //    responseData.forEach(value => {
            //        if (value.CATSECALIAS && !LocCategoryAliasDD.some(item => item.label === value.CATSECALIAS)) {
            //            LocCategoryAliasDD.push({ label: value.CATSECALIAS, value: value.CATSECALIAS });
            //        }
            //    });
            //    filteredData = LocCategoryAliasDD
            //        .filter(item => item.label && item.label.toLowerCase().includes(searchText.toLowerCase()))
            //        .map(item => item.label);
            //    break;
            case "subCategory":
                /* responseData.forEach(value => {
                     if (this.state.category == "") {
                         if (value.ISA_PARENT_NAME && !LocSubCategoryDD.some(item => item.label === value.ISA_PARENT_NAME)) {
                             LocSubCategoryDD.push({ label: value.ISA_PARENT_NAME, value: value.ISA_PARENT_NAME });
                         }
                     } else {
                         if (value.ISA_PARENT_NAME && this.state.subCategoryDD.some(item => item.label === value.ISA_PARENT_NAME)  && !LocSubCategoryDD.some(item => item.label === value.ISA_PARENT_NAME)) {
                             LocSubCategoryDD.push({ label: value.ISA_PARENT_NAME, value: value.ISA_PARENT_NAME });
                         }
                     }
                 });*/

                if (this.state.category == "") {

                    responseData.forEach(value => {
                        if (value.ISA_PARENT_NAME && value.ISA_PARENT_NAME.trim() != "" && !LocSubCategoryDD.some(item => item.label === value.ISA_PARENT_NAME)) {
                            LocSubCategoryDD.push({ label: value.ISA_PARENT_NAME, value: value.ISA_PARENT_NAME });
                        }
                    });
                } else {
                    responseData.forEach(value => {
                        if (value.ISA_PARENT_NAME && value.ISA_PARENT_NAME.trim() != "" && this.state.dependentSubCategory.some(item => item.label === value.ISA_PARENT_NAME) && !LocSubCategoryDD.some(item => item.label === value.ISA_PARENT_NAME)) {
                            LocSubCategoryDD.push({ label: value.ISA_PARENT_NAME, value: value.ISA_PARENT_NAME });
                        }
                    });

                }




                filteredData = LocSubCategoryDD
                    .filter(item => item.label && item.label.toLowerCase().includes(searchText.toLowerCase()))
                    .map(item => item.label);
                break;
            //case "subCategoryAlias":
            //    responseData.forEach(value => {
            //        if (value.PARENTSECALIAS && !LocSubCategoryAliasDD.some(item => item.label === value.PARENTSECALIAS)) {
            //            LocSubCategoryAliasDD.push({ label: value.PARENTSECALIAS, value: value.PARENTSECALIAS });
            //        }
            //    });
            //    filteredData = LocSubCategoryAliasDD
            //        .filter(item => item.label && item.label.toLowerCase().includes(searchText.toLowerCase()))
            //        .map(item => item.label);
            //    break;
            default:
                break;
        }


        filteredData.forEach((sugg) => {
            autoCompleteDataRes.push({
                value: sugg,
                label: sugg
            });
        });

        this.setState({ [`${name}DD`]: autoCompleteDataRes });
    };

    onSelectCategory = (val, name) => {
        let locCategoryAlias;
        let locSubCategories;
        let responseData = this.state.programGridArray;

        let LocCategoryDD = [];
        let LocSubCategoryDD = [];

        switch (name) {
            case "category":
                responseData.forEach(value => {
                    if (value.ISA_CATEGORY_NAME && !LocCategoryDD.some(item => item.label.toLowerCase() === value.ISA_CATEGORY_NAME.toLowerCase())) {
                        LocCategoryDD.push({
                            label: value.ISA_CATEGORY_NAME,
                            value: value.ISA_CATEGORY_NAME,
                            categoryAlias: value.CATSECALIAS
                        });
                    }

                    if (value.ISA_CATEGORY_NAME == val && value.ISA_PARENT_NAME && !LocSubCategoryDD.some(item => item.label.toLowerCase() === value.ISA_PARENT_NAME.toLowerCase())) {
                        LocSubCategoryDD.push({
                            label: value.ISA_PARENT_NAME,
                            value: value.ISA_PARENT_NAME,
                            subCategoryAlias: value.PARENTSECALIAS
                        });
                    }

                });
                console.log("subCategory", LocSubCategoryDD);
                locCategoryAlias = LocCategoryDD.find(item => item.value === val);
                this.setState({
                    categoryAlias: locCategoryAlias ? locCategoryAlias.categoryAlias : '',
                    categoryAliasDisable: locCategoryAlias && locCategoryAlias.categoryAlias !== '' ? true : false,
                    subCategoryDD: LocSubCategoryDD, dependentSubCategory: LocSubCategoryDD
                });
                break;
            case "subCategory":
                responseData.forEach(value => {
                    if (value.ISA_PARENT_NAME && !LocSubCategoryDD.some(item => item.label.toLowerCase() === value.ISA_PARENT_NAME.toLowerCase())) {
                        LocSubCategoryDD.push({
                            label: value.ISA_PARENT_NAME,
                            value: value.ISA_PARENT_NAME,
                            subCategoryAlias: value.PARENTSECALIAS
                        });
                    }
                });
                locSubCategories = LocSubCategoryDD.find(item => item.value === val);

                this.setState({
                    subCategoryAlias: locSubCategories ? locSubCategories.subCategoryAlias : '',
                    subCategoryAliasDisable: locSubCategories && locSubCategories.subCategoryAlias !== '' ? true : false
                });


                break;
            default:
                break;
        }

        this.setState({
            [name]: val
        });
    };
    onBlurHandler = (e, name) => {
        const val = e.target.value == "" ? e.target.value.toLowerCase() : e.target.value.trim().toLowerCase();

        let locCategoryAlias;
        let locSubCategories;
        let responseData = this.state.programGridArray;

        let LocCategoryDD = [];
        let LocSubCategoryDD = [];

        switch (name) {
            case "category":
                if (val != this.state.oldCategory.toLowerCase()) {
                    responseData.forEach(value => {
                        if (value.ISA_CATEGORY_NAME && value.ISA_CATEGORY_NAME.trim() != "" && !LocCategoryDD.some(item => item.label.toLowerCase() === value.ISA_CATEGORY_NAME.toLowerCase())) {
                            LocCategoryDD.push({
                                label: value.ISA_CATEGORY_NAME,
                                value: value.ISA_CATEGORY_NAME.toLowerCase(),
                                categoryAlias: value.CATSECALIAS
                            });
                        }
                        if (val != "") {
                            if (value.ISA_CATEGORY_NAME != null && value.ISA_CATEGORY_NAME.trim() != "" && value.ISA_CATEGORY_NAME.toLowerCase() == val.toLowerCase() && value.ISA_PARENT_NAME && value.ISA_PARENT_NAME.trim() != "" && !LocSubCategoryDD.some(item => item.label.toLowerCase() === value.ISA_PARENT_NAME.toLowerCase())) {
                                LocSubCategoryDD.push({
                                    label: value.ISA_PARENT_NAME,
                                    value: value.ISA_PARENT_NAME,
                                    subCategoryAlias: value.PARENTSECALIAS
                                });
                            }
                        }
                        else {
                            LocSubCategoryDD = this.state.initSubCategoryDD;
                        }
                    });
                    let isExistCat = this.state.initSubCategoryDD.some(item => item.label.toLowerCase() === val.toLowerCase());
                    locCategoryAlias = isExistCat ? '' : LocCategoryDD.find(item => item.value === val);
                    this.setState({
                        categoryAlias: locCategoryAlias ? locCategoryAlias.categoryAlias : '',
                        categoryAliasDisable: locCategoryAlias ? locCategoryAlias.categoryAlias !== '' ? true : false : false,
                        categoryAliasErr: false,
                        subCategoryDD: LocSubCategoryDD, dependentSubCategory: LocSubCategoryDD, existCategory: isExistCat, oldCategory: val,
                        subCategory: '', subCategoryAlias: '', subCategoryAliasDisable: false
                    });
                    console.log("1111", LocSubCategoryDD)
                }
                break;

            case "subCategory":
                if (val != this.state.oldSubCategory.toLowerCase()) {
                    responseData.forEach(value => {
                        if (value.ISA_PARENT_NAME && value.ISA_PARENT_NAME.trim() != "" && !LocSubCategoryDD.some(item => item.label.toLowerCase() === value.ISA_PARENT_NAME.toLowerCase())) {
                            LocSubCategoryDD.push({
                                label: value.ISA_PARENT_NAME,
                                value: value.ISA_PARENT_NAME.toLowerCase(),
                                subCategoryAlias: value.PARENTSECALIAS
                            });
                        }
                    });
                    let isExistSub = (LocSubCategoryDD.some(item => item.label.toLowerCase() === val.toLowerCase()) && !this.state.dependentSubCategory.some(item => item.label.toLowerCase() === val.toLowerCase())) || this.state.initCategoryDD.some(item => item.label.toLowerCase() === val.toLowerCase());
                    locSubCategories = isExistSub ? '' : LocSubCategoryDD.find(item => item.value === val);

                    this.setState({
                        subCategoryAlias: locSubCategories ? locSubCategories.subCategoryAlias : '',
                        subCategoryAliasDisable: locSubCategories ? locSubCategories.subCategoryAlias !== '' ? true : false : false,
                        subcategoryAliasErr: false, existSubCategory: isExistSub, oldSubCategory: val
                    });
                    console.log("onblur state subCategoryAliasDisable:", this.state.subCategoryAliasDisable);
                    console.log("initcat", this.state.initCategoryDD)
                }
                break;
            default:
                break;
        }

        this.setState({
            [name]: val
        });
    };




    //PS_ZE_243_API_24.1 On click of accordian icon, this method get hitted[Vishalini]
    handleAccordianClick(index) {
        this.setState(prevState => ({
            openAccordionIndex: prevState.openAccordionIndex === index ? null : index,
        }));
    };
    //PS_ZE_243_API_25 On click of  cancel button and "No" in delete confirm pop up, this method get hitted[Vishalini]
    cancelProgram = () => {
        $("#ProgramMaster_Grid").show();
        $("#title").show();
        $("#ProgramMaster_Form").hide();
        this.clearFields();
        this.programMasterPageload(this.state.searchCriteria, this.state.searchText, false);
        this.setState({ openAccordionIndex: null, isFilter: false });
    }
    //PS_ZE_243_API_26 Thsi method hits to validate fields[Vishalini]
    handleValidations = () => {
        const { t } = this.props;
        let isValid = true;
        let programNameErr = false;
        let programNameAliasErr = false;
        let programNameAliasEmptyErr = false;
        let categoryErr = false;
        let categoryAliasErr = false;
        let subcategoryErr = false;
        let subcategoryAliasErr = false;
        let siteNameErr = false;
        let locsecurityAlias = this.state.programNameAlias;
        let locSecurityAliasDD = this.state.securityAliasDD;
        let locCategoryAlias = this.state.categoryAlias;
        let locCategoryAliasDD = this.state.categoryAliasDD;
        let locSubCategoryAlias = this.state.subCategoryAlias;
        let locSubCategoryAliasDD = this.state.subCategoryAliasDD;
        let locprogramNameAliasErrText = '';
        let locCategoryAliasErrText = '';
        let locSubCategoryAliasErrText = '';
        let locCategoryDD = this.state.categoryDD;
        let locSubCategoryDD = this.state.subCategoryDD;
        let locCategory = this.state.category;
        let locSubCategory = this.state.subCategory;
        let categoryEmptyErr = false;
        let subCategoryEmptyErr = false;
        let subCategoryAliasEmptyErr = false;
        let categoryAliasEmptyErr = false;
        let isCatAndSubCatsame = false;
        let isCatAliasAndSubCatAliassame = false;


        if (!this.state.programName) {
            programNameErr = true;
            isValid = false;
        }

        if (!this.state.programNameAlias) {
            programNameAliasEmptyErr = true;
            locprogramNameAliasErrText = 'Please enter program name alias';
            isValid = false;
        }

        if (!this.state.category && this.state.categoryAlias) {
            categoryEmptyErr = true;
            isValid = false;
        }

        if (this.state.category && !this.state.categoryAlias) {
            categoryAliasEmptyErr = true;
            locCategoryAliasErrText = "Please enter category alias";
            isValid = false;
        }

        if (!this.state.subCategory && this.state.subCategoryAlias) {
            subCategoryEmptyErr = true;
            isValid = false;
        }

        if (this.state.subCategory && !this.state.subCategoryAlias) {
            subCategoryAliasEmptyErr = true;
            locSubCategoryAliasErrText = 'Please enter subcategory alias';
            isValid = false;
        }

        if (!this.state.site_Name_Z && !this.state.site_Name_I && !this.state.site_Name_O && !this.state.site_Name_W) {
            siteNameErr = true;
            isValid = false;
        }
        if (this.state.btnName == "Submit" && locsecurityAlias && locSecurityAliasDD && locSecurityAliasDD.length > 0) {
            const isSecurityAliasPresent = locSecurityAliasDD.some(item => item.label && item.label.toUpperCase().includes(locsecurityAlias.toUpperCase()));
            if (isSecurityAliasPresent) {
                isValid = false;
                programNameAliasErr = true;
                locprogramNameAliasErrText = 'Please enter a unique program name alias';
            }
        }

        if (locCategoryAlias && locCategoryAliasDD && locCategoryAliasDD.length > 0) {
            const isCategoryPresent = locCategoryDD.some(item => item.label && item.label.toUpperCase() === locCategory.toUpperCase());
            const isCategoryAliasPresent = locCategoryAliasDD.some(item => item.label && item.label.toUpperCase() === locCategoryAlias.toUpperCase());
            const isAliasInSubCategoryAlias = locSubCategoryAliasDD.some(item => item.label && item.label.toUpperCase() === locCategoryAlias.toUpperCase());
            if ((!isCategoryPresent) && isCategoryAliasPresent) {
                isValid = false;
                categoryAliasErr = true;
                locCategoryAliasErrText = 'Please enter a unique category name alias';
            }
            else if (this.state.btnName == "Update" && (!isCategoryPresent) && isCategoryAliasPresent) {
                isValid = false;
                categoryAliasErr = true;
                locCategoryAliasErrText = 'Category name alias already exist';
            }
            else if (isAliasInSubCategoryAlias) {
                isValid = false;
                categoryAliasErr = true;
                locCategoryAliasErrText = 'Alias name already exist';
            }
        }
        if (locSubCategoryAlias && locSubCategoryAliasDD && locSubCategoryAliasDD.length > 0) {
            const isSubCategoryPresent = locSubCategoryDD.some(item => item.label && item.label.toUpperCase() === locSubCategory.toUpperCase());
            const isSubCategoryAliasPresent = locSubCategoryAliasDD.some(item => item.label && item.label.toUpperCase() === locSubCategoryAlias.toUpperCase());
            const isAliasInCategoryAlias = locCategoryAliasDD.some(item => item.label && item.label.toUpperCase() === locSubCategoryAlias.toUpperCase());
            if ((!isSubCategoryPresent) && isSubCategoryAliasPresent) {
                isValid = false;
                subcategoryAliasErr = true;
                locSubCategoryAliasErrText = 'Please enter a unique subcategory name alias';
            }
            else if (this.state.btnName == "Update" && (!isSubCategoryPresent) && isSubCategoryAliasPresent) {
                isValid = false;
                subcategoryAliasErr = true;
                locSubCategoryAliasErrText = 'Subcategory name alias already exist';
            }
            else if (isAliasInCategoryAlias) {
                isValid = false;
                subcategoryAliasErr = true;
                locSubCategoryAliasErrText = 'Alias name already exist';
            }
        }
        if (this.state.existSubCategory || this.state.existCategory) {
            isValid = false;
        }

        if (this.state.category != "" && (this.state.category.trim().toUpperCase() == this.state.subCategory.trim().toUpperCase())) {
            isValid = false;
            isCatAndSubCatsame = true
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('The category and subcategory cannot be identical')
            });
        }

        if (this.state.categoryAlias != "" && (this.state.categoryAlias.trim().toUpperCase() == this.state.subCategoryAlias.trim().toUpperCase())) {
            isValid = false;
            isCatAliasAndSubCatAliassame = true

            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('The category alias and subcategory alias cannot be identical')
            });
        }


        this.setState({
            programNameErr: programNameErr,
            programNameAliasErr: programNameAliasErr,
            programNameAliasEmptyErr: programNameAliasEmptyErr,
            categoryErr: categoryErr,
            categoryAliasErr: categoryAliasErr,
            subcategoryErr: subcategoryErr,
            subcategoryAliasErr: subcategoryAliasErr,
            siteNameErr: siteNameErr,
            programNameAliasErrText: locprogramNameAliasErrText,
            categoryAliasErrText: locCategoryAliasErrText,
            subCategoryAliasErrText: locSubCategoryAliasErrText,
            categoryEmptyErr: categoryEmptyErr,
            subCategoryEmptyErr: subCategoryEmptyErr,
            categoryAliasEmptyErr: categoryAliasEmptyErr,
            subCategoryAliasEmptyErr: subCategoryAliasEmptyErr,
            isCatAndSubCatsame: isCatAndSubCatsame,
            isCatAliasAndSubCatAliassame: isCatAliasAndSubCatAliassame

        });

        return isValid;
    }


    //PS_ZE_243_API_27 Thsi method hits to validate fields[Vishalini]
    handlePageClick = (data) => {
        debugger;
        let pageNo = data.selected + 1;
        this.setState({ pageNo: pageNo, openAccordionIndex: null });
    };

    //PS_ZE_243_API_28 Thsi method hits to validate fields[Vishalini]
    clearFields = () => {
        this.setState({
            isFilter: false,
            programName: '',
            oldProgramName: '',
            programNameAlias: '',
            category: '',
            categoryAlias: '',
            subCategory: '',
            subCategoryAlias: '',
            navigationURL: '',
            site_Name_Z: true,
            site_Name_W: false,
            site_Name_I: false,
            site_Name_O: false,
            explicitSecurity: '',
            queryStringParameters: '',
            filterDisplayName: 'All',
            searchCriteria: 'ALL',
            searchText: '',
            openAccordionIndex: null,
            filteredPrograms: [],
            btnName: 'Submit',
            headerName: 'Add New Program',
            programNameErr: false,
            programNameAliasErr: false,
            programNameAliasEmptyErr: false,
            categoryErr: false,
            categoryAliasErr: false,
            subcategoryErr: false,
            subcategoryAliasErr: false,
            siteNameErr: false,
            active_No: false,
            active_Yes: true,
            accessGroup_customer: true,
            accessGroup_SDI: false,
            explicitSecurity_Yes: false,
            explicitSecurity_No: true,
            categoryDD: this.state.categoryDD,
            categoryAliasDD: this.state.categoryAliasDD,
            subCategoryDD: this.state.subCategoryDD,
            subCategoryAliasDD: this.state.subCategoryAliasDD,
            categoryAliasDisable: false,
            subCategoryAliasDisable: false,
            programNameAliasErrText: '',
            categoryAliasErrText: '',
            subCategoryAliasErrText: '',
            categoryEmptyErr: false,
            subCategoryEmptyErr: false,
            subCategoryAliasEmptyErr: false,
            categoryAliasEmptyErr: false,
            isCatAliasAndSubCatAliassame: false,
            isCatAndSubCatsame: false,
            existSubCategory: false,
            existCategory: false
        });
    }
    handleNewProgramClick = () => {
        $("#ProgramMaster_Grid").hide();
        $("#ProgramMaster_Form").show();
        $("#title").hide();
        //this.clearFields();
    }

    handleDeleteClick = (identifier) => {
        this.setState({ identifierID: identifier })
        $('#DeletePopup').modal('show');
    }
    //To get the selected value from suggestion
    getCategoryValue(item) {
        return `${item.label}`;
    }
    //To change background according to selection
    renderCategoryItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );
    }


    /****************************************************************Favorites handle starts from here***********************************************************************/

    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Program Master",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            ProgramMasterAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            ProgramMasterAction.deleteFavPage(deleteFavPageBO)
        }
    };
    loadFavPageResponse = () => {
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let favPageResponse = JSON.parse(ProgramMasterStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ProgramMasterStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(ProgramMasterStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(ProgramMasterStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };

    /****************************************************************HTML parts starts from here***********************************************************************/
    /**         
    * PS_ZE_243_API_29/Vishalini
    * This method is used to bind program grid.
    * */
    bindPrograms = () => {
        return this.state.programGridArray.map((programList, index) => {
            if (index < parseInt(this.state.pageNo) * 10 && index >= parseInt(this.state.pageNo - 1) * 10) {
                return (
                    <React.Fragment key={index}>
                        < tr key={programList.ISA_IDENTIFIER + "-parent-" + index}>

                            <td className={`accordion-toggle ${this.state.openAccordionIndex === index ? '' : 'collapsed'}`}
                                data-toggle="collapse"
                                data-target={`#order-row-${index}`}
                                onClick={(e) => this.handleAccordianClick(index)}
                                aria-expanded={this.state.openAccordionIndex === index}
                            >
                                <span className="accordion-arrow accordion-arrow-change"></span>
                            </td>
                            <td>{programList.ISA_IDENTIFIER == null || programList.ISA_IDENTIFIER === "" ? "-" : programList.ISA_IDENTIFIER}</td>
                            <td>{programList.ISA_IDENTIFIER_NAME == null || programList.ISA_IDENTIFIER_NAME === "" ? "-" : programList.ISA_IDENTIFIER_NAME}</td>
                            <td>{programList.SECURITYALIAS == null || programList.SECURITYALIAS === "" ? "-" : programList.SECURITYALIAS}</td>
                            <td>{programList.ISA_CATEGORY_NAME == null || programList.ISA_CATEGORY_NAME.trim() === "" ? "-" : programList.ISA_CATEGORY_NAME}</td>
                            <td>{programList.CATSECALIAS == null || programList.CATSECALIAS.trim() === "" ? "-" : programList.CATSECALIAS}</td>
                            <td>{programList.ISA_PARENT_NAME == null || programList.ISA_PARENT_NAME.trim() === "" ? "-" : programList.ISA_PARENT_NAME}</td>
                            <td>{programList.PARENTSECALIAS == null || programList.PARENTSECALIAS.trim() === "" ? "-" : programList.PARENTSECALIAS}</td>
                            <td className="text-center text-nowrap">
                                <button className="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" onClick={() => this.handleEditClick(programList)} data-backdrop="static" data-keyboard="false">
                                    <img src="images/edit-icon.svg" className="removeorder-icon" alt="edit" />
                                </button>
                                <button className="btn remove-order" data-toggle="modal" data-target="#DeletePopup" onClick={() => this.handleDeleteClick(programList.ISA_IDENTIFIER)} data-backdrop="static" data-keyboard="false">
                                    <img src="Images/remove-order.svg" className="removeorder-icon" alt="delete" />
                                </button>
                            </td>
                        </tr >
                        <tr className="p">
                            <td colSpan="9" className="hiddenRow border-bottom-0">
                                <div className={`accordion-body collapse py-1 px-3 border-bottom ${this.state.openAccordionIndex === index ? 'show' : ''}`}
                                    id={`program-row-${index}`}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/*Accod details*/}
                                            <div className="row custom-bg py-3">
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <span className="font-14 font-medium">
                                                        Navigation URL :
                                                    </span>
                                                    <span className="font-14 font-regular ml-1">
                                                        {programList.ISA_NAVIGATIONURL === " " || programList.ISA_NAVIGATIONURL == null ? "-" : programList.ISA_NAVIGATIONURL}
                                                    </span>
                                                </div>
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <span className="font-14 font-medium">
                                                        Active :
                                                    </span>
                                                    <span className="font-14 font-regular ml-1">
                                                        {programList.ACTIVE == 1 ? "Yes" : "No"}
                                                    </span>
                                                </div>
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <span className="font-14 font-medium">
                                                        Apply to site :
                                                    </span>
                                                    <span className="font-14 font-regular ml-1">
                                                        {(() => {
                                                            let applySiteStr = programList.SITE_NAME;
                                                            let count = 0;
                                                            let displayString = "";

                                                            for (const char of applySiteStr) {
                                                                const matchingItem = this.state.applySiteArray.find(item => item.key.toLowerCase() === char.toLowerCase());
                                                                if (matchingItem) {
                                                                    if (count === 0) {
                                                                        displayString = matchingItem.value;
                                                                    } else {
                                                                        displayString += "," + matchingItem.value;
                                                                    }
                                                                    count++;
                                                                }
                                                            }

                                                            return displayString;
                                                        })()}
                                                    </span>
                                                </div>
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <span className="font-14 font-medium">
                                                        Access group :
                                                    </span>
                                                    <span className="font-14 font-regular ml-1">
                                                        {programList.ACCESS_GROUP == "CUST/USER" ? "Customer" : "SDI"}
                                                    </span>
                                                </div>
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <span className="font-14 font-medium">
                                                        Explicit security :
                                                    </span>
                                                    <span className="font-14 font-regular ml-1">
                                                        {programList.EXTERNAL_SECURITY == null || programList.EXTERNAL_SECURITY === "N" ? "No" : "Yes"}
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <span className="font-14 font-medium">
                                                        Query string parameters :
                                                    </span>
                                                    <span className="font-14 font-regular ml-1">
                                                        {programList.PARMLIST == null || programList.PARMLIST === "" ? "-" : programList.PARMLIST}
                                                    </span>
                                                </div>
                                            </div>
                                            {/*Accod details*/}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </React.Fragment>
                );
            }
        });
    }


    render() {
        const { t } = this.props;

        return (
            <>
                <div className="container-fluid content-section content-margin" style={{ paddingBottom: '0px' }}>
                    <div className="row">
                        {/*Page Heading starts here*/}
                        <div className="col-md-12 mt-3 page-heading" id="title">
                            <h5 className="page-title"><Trans>Program Master</Trans></h5>
                            <div className="fav-item-maintenance-icon mt-1">
                                <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                <label htmlFor="checkbox-1">
                                    <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </div>
                        </div>
                        {/*Page Heading ends here*/}
                        {/*Grid starts here*/}
                        {/*{this.state.programGridArray.length > 0 ? */}
                        <div className="col-md-12 mt-4" id="ProgramMaster_Grid" >
                            {/*search and add button*/}
                            <div className="d-flex justify-content-end align-items-center mb-3">
                                <div>
                                    <div className="input-group dropdown-search program-master-dropdown-search">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t(this.state.filterDisplayName)}</button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" onClick={(e) => this.handleFiltering("All", "ALL")}><Trans>All</Trans></a>
                                                <a className="dropdown-item" onClick={(e) => this.handleFiltering("Identifier", "ISA_IDENTIFIER")}><Trans>Identifier</Trans></a>
                                                <a className="dropdown-item" onClick={(e) => this.handleFiltering("Program name", "ISA_IDENTIFIER_NAME")}><Trans>Program name</Trans></a>
                                                <a className="dropdown-item" onClick={(e) => this.handleFiltering("Category", "ISA_CATEGORY_NAME")}><Trans>Category</Trans></a>
                                                <a className="dropdown-item" onClick={(e) => this.handleFiltering("Subcategory", "ISA_PARENT_NAME")}><Trans>Subcategory</Trans></a>
                                                <a className="dropdown-item" onClick={(e) => this.handleFiltering("Access Group", "ACCESS_GROUP")}><Trans>Access group</Trans></a>
                                            </div>
                                        </div>
                                        <input type="text" className="form-control pr-5 order-status-search" placeholder={t("Search")} aria-label="Text input with Cust ID button" value={this.state.searchText} id="searchText" onChange={this.handleSearchText} onKeyDown={(e) => this.searchKeyPress(e)} />
                                        <span className={this.state.searchText != null && this.state.searchText != "" && this.state.searchText != " " ? "search-cancel-header cursor-pointer" : "d-none"} style={{ "top": "11px", "right": "24px" }} onClick={() => this.handleSearchClear()}></span>
                                        <span className="dropdown-search-icon" onClick={(e) => this.programMasterPageload(this.state.searchCriteria, this.state.searchText, true)} />
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-3 ml-3 mb-0" onClick={this.handleNewProgramClick}><img className="mr-2" src="images/add-icon.svg" alt="add-icon" /><span className="custom-responsive-none" ><Trans>New</Trans></span> <span><Trans>Program</Trans></span></button>
                                </div>
                            </div>
                            {/*search and add button*/}
                            {/*Table starts here*/}
                            <div className="table-responsive mt-2">
                                {/* {this.state.totalCount == 0 ?
                                    <section className="site-undercontruction container text-center center-panel pt-4">
                                        <div className="img-section">
                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                        </div>
                                        <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                        </p>
                                    </section>
                                    : */}
                                {this.state.programGridArray.length > 0 ?
                                    <table className="table ZEUS_Grid FOM-grid mobileresponsive-grid">
                                        <thead>
                                            <tr>
                                                <th />
                                                <th className="position-sort-icon header-font pr-3"><Trans>Identifier</Trans><span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.sorting("ASC", "ISA_IDENTIFIER")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.sorting("DESC", "ISA_IDENTIFIER")} /></span></th>
                                                <th className="position-sort-icon header-font pr-3 "><Trans>Program name</Trans><span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.sorting("ASC", "ISA_IDENTIFIER_NAME")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.sorting("DESC", "ISA_IDENTIFIER_NAME")} /></span></th>
                                                <th className="position-sort-icon header-font pr-3 "><Trans>Program name alias</Trans><span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.sorting("ASC", "SECURITYALIAS")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.sorting("DESC", "SECURITYALIAS")} /></span></th>
                                                <th className="position-sort-icon header-font pr-3 "><Trans>Category</Trans><span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.sorting("ASC", "ISA_CATEGORY_NAME")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.sorting("DESC", "ISA_CATEGORY_NAME")} /></span></th>
                                                <th className="position-sort-icon header-font pr-3 "><Trans>Category alias</Trans><span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.sorting("ASC", "CATSECALIAS")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.sorting("DESC", "CATSECALIAS")} /></span></th>
                                                <th className="position-sort-icon header-font pr-3 "><Trans>Subcategory</Trans><span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.sorting("ASC", "ISA_PARENT_NAME")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.sorting("DESC", "ISA_PARENT_NAME")} /></span></th>
                                                <th className="position-sort-icon header-font pr-3 "><Trans>Subcategory alias</Trans><span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.sorting("ASC", "PARENTSECALIAS")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.sorting("DESC", "PARENTSECALIAS")} /></span></th>
                                                <th className="text-center"><Trans>Action</Trans></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.bindPrograms()}
                                        </tbody>

                                    </table>
                                    :
                                    <div className="table-responsive mt-2">
                                        <section className="site-undercontruction container text-center center-panel pt-4">
                                            <div className="img-section">
                                                <img src="images/nosearch.jpg" alt="imagefile" />
                                            </div>
                                            <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                            </p>
                                        </section>
                                    </div>
                                }
                            </div>
                            {this.state.totalCount > 0 ?
                                <div className="col-md-12">
                                    <span className="total-records mt-2 d-inline-block">
                                        <Trans>Number of records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
                                    </span>
                                    {this.state.totalCount > this.state.countPerPage ? <ReactPaginate
                                        previousLabel={<span className="right-pagination-arrow" />}
                                        nextLabel={<span className="left-pagination-arrow" />}
                                        previousClassName={'page-item'}
                                        nextClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        breakLabel={'...'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'custom-pagination pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        hrefBuilder={() => '#'}
                                        forcePage={this.state.pageNo - 1}
                                    /> : null}
                                </div> : null}
                            {/*Table ends here*/}
                        </div>

                        {/*Grid ends here*/}

                        {/*Form starts here*/}
                        <div id="ProgramMaster_Form" className="w-100">
                            <title>{this.state.headerName}</title>
                            <meta charSet="UTF-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="stylesheet" type="text/css" href="css/sdiframework.css" />
                            <link rel="stylesheet" type="text/css" href="css/sdistyles.css" />
                            <div className="container-fluid">
                                <div className="row">
                                    {/*Page Heading starts here*/}
                                    <div className="col-md-12 mt-3 page-heading">
                                        <h5 className="page-title"><a className="mr-2" onClick={this.cancelProgram} ><img style={{ marginBottom: '5px' }} src="images/back-icon.svg" alt="back-icon" /></a>{this.state.headerName}</h5>
                                    </div>
                                    {/*Page Heading ends here*/}

                                    {/*Form starts here*/}
                                    <div className="col-md-12 mt-4">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label" htmlFor="prog-name">
                                                    <Trans>Program name</Trans><span className="color-manditory">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${this.state.programNameErr && this.state.programName == "" ? 'border-color-red' : ''}`}
                                                    id="programName"
                                                    maxLength="254"
                                                    placeholder={t("Enter program name")}
                                                    name="programName"
                                                    value={this.state.programName}
                                                    onChange={(e) => this.handleOnChangeFields("programName", e, "textbox", "")}
                                                />
                                                {this.state.programNameErr && this.state.programName == "" ?
                                                    <span className="font-12 font-regular color-validation">
                                                        <Trans>Please enter program name</Trans>
                                                    </span> : null}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label
                                                    className="form-label catalog-label"
                                                    htmlFor="prog-name-alias"
                                                >
                                                    <Trans>Program name alias</Trans><span className="color-manditory">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${this.state.programNameAliasErr && this.state.btnName == "Submit" || (this.state.programNameAliasEmptyErr && this.state.programNameAlias == "") ? 'border-color-red' : ''}`}
                                                    id="prog-name-alias"
                                                    maxLength="50"
                                                    placeholder={t("Enter program name alias")}
                                                    name="programNameAlias"
                                                    value={this.state.programNameAlias}
                                                    onChange={(e) => this.handleOnChangeFields("programNameAlias", e, "textbox", "")}
                                                    disabled={(!this.state.programNameAliasErr || this.state.btnName == "Submit" ? false : true) || (this.state.btnName == "Update" ? true : false)}
                                                />
                                                {this.state.programNameAliasErr && this.state.btnName == "Submit" || (this.state.programNameAliasEmptyErr && this.state.programNameAlias == "") ?
                                                    <span className="font-12 font-regular color-validation">
                                                        <Trans>{this.state.programNameAliasErrText}</Trans> </span> : null}
                                            </div>
                                            {/* Category */}
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label" htmlFor="category">
                                                    <Trans>Category</Trans>
                                                </label>
                                                <div className="search-autocomplete home-search">
                                                    <Autocomplete
                                                        getItemValue={this.getCategoryValue}
                                                        items={this.state.categoryDD}
                                                        renderItem={this.renderCategoryItem}
                                                        value={String(this.state.category)}
                                                        id="category"
                                                        name="category"
                                                        onChange={(e) => this.handleOnChangeFields("category", e, "dropdown", "")}
                                                        onSelect={(e) => this.onSelectCategory(e, "category")}
                                                        disabled={this.state.categoryAliasDisable}
                                                        inputProps={{
                                                            placeholder: t("Enter category"), className: `form-control ${(this.state.categoryEmptyErr && this.state.category === "") || this.state.existCategory || this.state.isCatAndSubCatsame ? 'border-color-red' : ''}`,
                                                            style: { padding: '0px 57px 0px 10px', width: '100%' }, maxLength: 254, onBlur: (e) => this.onBlurHandler(e, "category")
                                                        }}


                                                    />
                                                    {this.state.categoryEmptyErr && this.state.category == "" ?
                                                        <span className="font-12 font-regular color-validation">
                                                            <Trans>Please enter category</Trans> </span> : this.state.existCategory ?
                                                            <span className="font-12 font-regular color-validation">
                                                                <Trans>Category name already exists in subcategory</Trans> </span> : null}
                                                </div>
                                            </div>


                                            {/* Category Alias */}
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label" htmlFor="category-alias">
                                                    <Trans>Category alias</Trans>
                                                </label>
                                                {/*  <div className="search-autocomplete home-search">
                                                    {this.state.categoryAliasDisable ? */}
                                                <input
                                                    type="text"
                                                    //className="form-control"
                                                    className={`form-control ${!this.state.categoryAliasDisable && this.state.categoryAliasErr || (this.state.categoryAliasEmptyErr && this.state.categoryAlias == "") || this.state.isCatAliasAndSubCatAliassame ? 'border-color-red' : ''}`}
                                                    id="categoryAlias"
                                                    disabled={this.state.categoryAliasDisable}
                                                    name="categoryAlias"
                                                    value={this.state.categoryAlias}
                                                    maxLength="50"
                                                    onChange={(e) => this.handleOnChangeFields("categoryAlias", e, "textbox", "")}
                                                    placeholder={t("Enter category alias")}
                                                />
                                                {/*   :
                                                        <Autocomplete
                                                            getItemValue={this.getCategoryValue}
                                                            items={this.state.categoryAliasDD}
                                                            renderItem={this.renderCategoryItem}
                                                            value={String(this.state.categoryAlias)}
                                                            name="categoryAlias"
                                                            onChange={(e) => this.handleOnChangeFields("categoryAlias", e, "dropdown", "")}
                                                            onSelect={(e) => this.onSelectCategory(e, "categoryAlias")}                                                            
                                                            inputProps={{ placeholder: t("Enter category alias"), maxLength: 50, className: `form-control ${this.state.categoryAliasErr && this.state.categoryAlias === "" ? 'border-color-red' : ''}`, style: { padding: '0px 57px 0px 10px', width: '100%' } }}                                                            
                                                         

                                                        />} */}
                                                {!this.state.categoryAliasDisable && this.state.categoryAliasErr || (this.state.categoryAliasEmptyErr && this.state.categoryAlias == "") ?
                                                    <span className="font-12 font-regular color-validation">
                                                        <Trans>{this.state.categoryAliasErrText}</Trans> </span> : null}
                                                {/*  </div> */}
                                            </div>


                                            {/* Subcategory */}
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label" htmlFor="subcategory">
                                                    <Trans>Subcategory</Trans>
                                                </label>
                                                <div className="search-autocomplete home-search">
                                                    <Autocomplete
                                                        getItemValue={this.getCategoryValue}
                                                        items={this.state.subCategoryDD}
                                                        renderItem={this.renderCategoryItem}
                                                        value={String(this.state.subCategory)}
                                                        id="subCategory"
                                                        name="subCategory"
                                                        onChange={(e) => this.handleOnChangeFields("subCategory", e, "dropdown", "")}
                                                        onSelect={(e) => this.onSelectCategory(e, "subCategory")}
                                                        disabled={this.state.subCategoryAliasDisable}
                                                        inputProps={{
                                                            placeholder: t("Enter subcategory"),
                                                            className: `form-control ${(this.state.subCategoryEmptyErr && this.state.subCategory == "") || this.state.existSubCategory || this.state.isCatAndSubCatsame ? 'border-color-red' : ''}`,
                                                            style: { padding: '0px 57px 0px 10px', width: '100%' },
                                                            maxLength: 254,
                                                            onBlur: (e) => this.onBlurHandler(e, "subCategory"),
                                                            disabled: this.state.category == "" ? true : false
                                                        }}

                                                    />
                                                    {(this.state.subCategoryEmptyErr && this.state.subCategory == "") ?
                                                        <span className="font-12 font-regular color-validation">
                                                            <Trans>Please enter subcategory</Trans> </span> : this.state.existSubCategory ? <span className="font-12 font-regular color-validation">
                                                                <Trans>Subcategory already exists</Trans> </span> : null}
                                                </div>
                                            </div>


                                            {/* Subcategory Alias */}
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label" htmlFor="subcategory-alias">
                                                    <Trans>Subcategory alias</Trans>
                                                </label>
                                                {/* <div className="search-autocomplete home-search">
                                                    {this.state.subCategoryAliasDisable ? */}
                                                <input
                                                    type="text"
                                                    className={`form-control ${!this.state.subCategoryAliasDisable && this.state.subcategoryAliasErr || (this.state.subCategoryAliasEmptyErr && this.state.subCategoryAlias == "") || this.state.isCatAliasAndSubCatAliassame ? 'border-color-red' : ''}`}
                                                    id="subCategoryAlias"
                                                    disabled={this.state.subCategoryAliasDisable}
                                                    name="programName"
                                                    value={this.state.subCategoryAlias}
                                                    maxLength="50"
                                                    placeholder="Enter subcategory alias"
                                                    onChange={(e) => this.handleOnChangeFields("subCategoryAlias", e, "textbox", "")}
                                                />
                                                {/*:
                                                        <Autocomplete
                                                        //    getItemValue={this.getCategoryValue}
                                                        //    name="subCategoryAlias"
                                                        //    items={this.state.subCategoryAliasDD}
                                                        //    renderItem={this.renderCategoryItem}
                                                        //    value={String(this.state.subCategoryAlias)}
                                                        //    onChange={(e) => this.handleOnChangeFields("subCategoryAlias", e, "dropdown", "")}
                                                        //    onSelect={(e) => this.onSelectCategory(e, "subCategoryAlias")}
                                                        //    disabled={this.state.subCategoryAliasDisable}
                                                        //    inputProps={{ placeholder: t("Enter subcategory alias"), maxLength: 50,className: `form-control ${this.state.subcategoryAliasErr && this.state.subCategoryAlias === "" ? 'border-color-red' : ''}`, style: { padding: '0px 57px 0px 10px', width: '100%' } }}
                                                           
                                                        ////className={`form-control ${this.state.subcategoryAliasErr && this.state.subCategoryAlias == "" ? 'border-color-red' : ''}`}                                                         
                                                        ///>
                                                    //}  */}
                                                {!this.state.subCategoryAliasDisable && this.state.subcategoryAliasErr || (this.state.subCategoryAliasEmptyErr && this.state.subCategoryAlias == "") ?
                                                    <span className="font-12 font-regular color-validation">
                                                        <Trans>{this.state.subCategoryAliasErrText}</Trans> </span> : null}
                                                {/* </div>*/}
                                            </div>


                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label" htmlFor="nav-url">
                                                    <Trans>Navigation URL</Trans>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="nav-url"
                                                    maxLength="254"
                                                    placeholder={t("Enter navigation URL")}
                                                    name="navigationURL"
                                                    value={this.state.navigationURL}
                                                    onChange={(e) => this.handleOnChangeFields("navigationURL", e, "textbox", "")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label w-100"><Trans>Active</Trans></label>
                                                <label className="custom-radiobtn mr-2 my-2">
                                                    <Trans>Yes</Trans>
                                                    <input type="radio"
                                                        name="active_Yes"
                                                        // onChange={(e) => this.handleOnChangeFields("active_Yes", e, "active_yes_checkbox")}
                                                        onChange={(e) => this.handleOnChangeFields("active_Yes", e, "checkbox", "active_No")}
                                                        checked={this.state.active_Yes}
                                                    />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                                <label className="custom-radiobtn mr-2 my-2">
                                                    <Trans>No</Trans>
                                                    <input type="radio"
                                                        name="active_No"
                                                        //onChange={(e) => this.handleOnChangeFields("active_No", e, "active_no_checkbox")}
                                                        onChange={(e) => this.handleOnChangeFields("active_No", e, "checkbox", "active_Yes")}
                                                        checked={this.state.active_No} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label w-100">
                                                    <Trans>Apply to site</Trans>
                                                </label>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={2.0}
                                                        defaultValue="option1"
                                                        name="site_Name_Z"
                                                        onChange={(e) => this.handleOnChangeFields("site_Name_Z", e, "checkbox", "")}
                                                        checked={this.state.site_Name_Z}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor={2.0}
                                                    >
                                                        <Trans>Zeus 2.0</Trans>
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="walmart"
                                                        defaultValue="option2"
                                                        name="site_Name_W"
                                                        onChange={(e) => this.handleOnChangeFields("site_Name_W", e, "checkbox", "")}
                                                        checked={this.state.site_Name_W}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor="walmart"
                                                    >
                                                        <Trans>Walmart</Trans>
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="zeus-odr"
                                                        defaultValue="option2"
                                                        name="site_Name_O"
                                                        onChange={(e) => this.handleOnChangeFields("site_Name_O", e, "checkbox", "")}
                                                        checked={this.state.site_Name_O}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor="zeus-odr"
                                                    >
                                                        <Trans>Zeus ordering</Trans>
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="ipm"
                                                        defaultValue="option2"
                                                        name="site_Name_I"
                                                        onChange={(e) => this.handleOnChangeFields("site_Name_I", e, "checkbox", "")}
                                                        checked={this.state.site_Name_I}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor="ipm"
                                                    >
                                                        <Trans>IPM</Trans>
                                                    </label>

                                                </div>
                                                {this.state.siteNameErr && (!this.state.site_Name_Z && !this.state.site_Name_W && !this.state.site_Name_I && !this.state.site_Name_O) ?
                                                    <span className="font-12 font-regular color-validation form-check-inline">
                                                        <Trans>Please select atleast one site</Trans>
                                                    </span> : null}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label w-100">
                                                    <Trans>Access group</Trans>
                                                </label>
                                                <label className="custom-radiobtn mr-2 my-2">
                                                    <Trans>Customer</Trans>
                                                    <input type="radio"
                                                        name="accessGroup_customer"
                                                        //onChange={(e) => this.handleOnChangeFields("accessGroup_customer", e, "customer_checkbox")}
                                                        onChange={(e) => this.handleOnChangeFields("accessGroup_customer", e, "checkbox", "accessGroup_SDI")}
                                                        checked={this.state.accessGroup_customer}
                                                    />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                                <label className="custom-radiobtn mr-2 my-2">
                                                    <Trans>SDI</Trans>
                                                    <input type="radio"
                                                        name="accessGroup_SDI"
                                                        onChange={(e) => this.handleOnChangeFields("accessGroup_SDI", e, "checkbox", "accessGroup_customer")}
                                                        // onChange={(e) => this.handleOnChangeFields("accessGroup_SDI", e, "SDI_checkbox")}
                                                        checked={this.state.accessGroup_SDI}
                                                    />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label catalog-label w-100">
                                                    <Trans>Explicit security</Trans>
                                                </label>
                                                <label className="custom-radiobtn mr-2 my-2">
                                                    <Trans>Yes</Trans>
                                                    <input type="radio"
                                                        name="explicitSecurity_Yes"
                                                        onChange={(e) => this.handleOnChangeFields("explicitSecurity_Yes", e, "checkbox", "explicitSecurity_No")}
                                                        //onChange={(e) => this.handleOnChangeFields("explicitSecurity_Yes", e, "expSec_yes_checkbox")}
                                                        checked={this.state.explicitSecurity_Yes}
                                                    />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                                <label className="custom-radiobtn mr-2 my-2">
                                                    <Trans>No</Trans>
                                                    <input type="radio"
                                                        name="explicitSecurity_No"
                                                        onChange={(e) => this.handleOnChangeFields("explicitSecurity_No", e, "checkbox", "explicitSecurity_Yes")}
                                                        // onChange={(e) => this.handleOnChangeFields("explicitSecurity_No", e, "expSec_no_checkbox")}
                                                        checked={this.state.explicitSecurity_No}
                                                    />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label
                                                    className="form-label catalog-label width-style text-nowrap"
                                                    htmlFor="query-string"
                                                >
                                                    <Trans>Query string parameters</Trans>{" "}
                                                    <span
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={t("Enter # if program should not display in menu")}
                                                    >
                                                        <img
                                                            className="ml-1"
                                                            src="images/info-icon-sdi.svg"
                                                            alt="info-icon-sdi"
                                                        />
                                                    </span>
                                                </label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="query-string"
                                                    maxLength="1000"
                                                    placeholder={t("Enter query string parameters")}
                                                    name="queryStringParameters"
                                                    value={this.state.queryStringParameters}
                                                    onChange={(e) => this.handleOnChangeFields("queryStringParameters", e, "textbox", "")}
                                                />
                                            </div>


                                            {/*button section*/}
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-end my-5">
                                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-2" onClick={this.cancelProgram}>
                                                        <Trans>Cancel</Trans>
                                                    </button>
                                                    <button className="btn btn-primary custom-btn-primary primary-btn mb-0" onClick={() => this.programOperations(this.state.btnName)}>
                                                        <Trans>{this.state.btnName}</Trans>
                                                    </button>
                                                </div>
                                            </div>
                                            {/*button section*/}
                                        </div>
                                    </div>
                                    {/*Form ends here*/}


                                </div>
                            </div>

                        </div>

                        {/*Modal starts here*/}
                        {/*Delete Modal starts here*/}
                        <div className="modal fade delete-role" id="DeletePopup" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered custom-rolemaster-width" role="document">
                                <div className="modal-content py-2">
                                    {/* Delete Starts here */}
                                    <div className="modal-body pb-0">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                <img className="mb-3" src="images/trash.svg" alt="delete" />
                                                <h5 className="mb-2 font-16 font-bold color-light-black"><Trans>Confirm</Trans></h5>
                                                <p className="mb-1 font-13 font-regular color-light-black"><Trans>Are you sure you want to delete this program?</Trans></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Delete ends here */}
                                    <div className="modal-footer border-0 d-flex justify-content-center">
                                        <button className="btn btn-custom-secondary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>No</Trans></button>
                                        <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" onClick={() => this.programOperations('DELETE')}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Delete Modal ends here*/}

                        {/*Success/Failure Modal Starts here*/}
                        <div className="modal fade save" tabIndex={-1} role="dialog" aria-hidden="true" id="success_failure_popup" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-centered custom-rolemaster-width" role="document">
                                <div className="modal-content py-2">
                                    {/*Success Starts here*/}
                                    <div className="modal-body pb-2">
                                        <div className="row">
                                            <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center">
                                                {this.state.popUpText.includes("not") || this.state.popUpText.includes("already exists") ? <img className="mb-4" src="images/alert-Icon.svg" alt="alert" /> :
                                                    <img className="mb-4" src="images/tick.svg" alt="tick" />
                                                }
                                                <p className="mb-0 font-13 font-regular color-light-black"><Trans>{this.state.popUpText}</Trans></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Success end here*/}
                                    <div className="modal-footer border-0 d-flex justify-content-center">
                                        <button
                                            className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2"
                                            data-dismiss="modal"
                                            onClick={(e) => this.state.popUpText.includes("not") || this.state.popUpText.includes("already exists") ?
                                                this.clearFields() : this.programMasterPageload(this.state.searchCriteria, this.state.searchText, false)}>
                                            <Trans>OK</Trans>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/*Success/Failure Modal Ends here*/}


                    </div>
                </div>
            </>

        );
    };
}
export default withTranslation()(ProgramMasterComponent);