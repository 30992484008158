import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as PunchInAction from '../actions/PunchInAction';
import * as MenuAction from '../actions/MenuAction';
import PunchinStore from '../stores/PunchinStore';
import * as $ from 'jquery';
//[4/22/22, CAS - 31, Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
let currentPagePath;
class PunchInComponent extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.assignPunchinData = this.assignPunchinData.bind(this);
        const queryParams = new URLSearchParams(window.location.search)
        const USER_ID = queryParams.get('USER_ID');
        const COMP_ID = queryParams.get('COMP_ID');
        const PASSWORD = queryParams.get('PASSWORD');
        this.state = {
            PunchinData: [],
            RedirectURL: "",
            UrlParameter: "",
            UserName: USER_ID,
            loginData: { isBrowserCompatable: true },
            PunchinSessionID: "",
            BUYERCOOKIE: "",
            HOOKURL: "", alert: "", PUNCHINCOMPNAME: "", PUNCHINBUSNUNIT: "", PUNCHINROLENAME:""

        }

    }
    componentDidMount() {
        this._isMounted = true;
        currentPagePath = this.props.location.pathname;

        console.log("currentPagePath", currentPagePath)
        const queryParams = new URLSearchParams(window.location.search)

        const USER_ID = queryParams.get('USER_ID');
        const COMP_ID = queryParams.get('COMP_ID');
        const PASSWORD = queryParams.get('PASSWORD');
        const SEQNO = queryParams.get('SEQNO');
        const EMAIL = queryParams.get('EMAIL');
        const CALLER = queryParams.get('~CALLER');
        const From_cXML = queryParams.get('From_cXML');
        const workorder = queryParams.get('workorder');
        const BUYER_COOKIE = queryParams.get('BUYER_COOKIE');
        const HTML = queryParams.get('HTML');
        const REQTYPE = queryParams.get('REQTYPE');
        const CONFIG = queryParams.get('CONFIG');
        const HOOK_URL = queryParams.get('HOOK_URL');
        if (HOOK_URL == null) {

            this.setState({ alert: "Error missing parameters" });
            return false;
        }
        let ver = [];

        ver = currentPagePath.split('/');
        console.log("ver", ver)
            ;
        let isTokenPresent = CookieService.getCookieValue(SDIConstants.access_token);



        // if (isTokenPresent != "" && isTokenPresent != undefined && isTokenPresent != null && isTokenPresent != "undefined") {
        //this.props.history.push({
        //    pathname: '/HomePage',
        //});
        // } else {
        sessionStorage.clear();
        localStorage.clear();

        //[11/11/21, PC_07, CAS - Punchin Issue, Dhevanesam R]
        const currentURLP = window.location.href // returns the absolute URL of a page
        console.log("currentURLP", currentURLP);
        let restURL = currentURLP.substring(0, currentURLP.lastIndexOf("/") + 1);
        //let RawURL = currentURLP.substring(currentURLP.lastIndexOf("/") + 1, currentURLP.length); 
        //[12/16/21, PC_10, CAS - Punchin Issue, Dhevanesam R]
        let RawURL1 = currentURLP.substring(currentURLP.indexOf("//") + 2, currentURLP.length);
        console.log(RawURL1);
        let RawURL = RawURL1.substring(RawURL1.indexOf("/"), RawURL1.length);

        console.log(restURL);
        console.log(RawURL);
        let PunchinBO = {
            USER_ID: USER_ID,
            PASSWORD: PASSWORD,
            EMAIL: EMAIL,
            SEQNO: SEQNO,
            REQTYPE: REQTYPE,
            CALLER: CALLER,
            COMP_ID: COMP_ID,
            HTML: HTML,
            CONFIG: CONFIG,
            From_cXML: From_cXML,
            HOOK_URL: HOOK_URL,
            workorder: workorder,
            SDIEMP: "",
            CatalogdtTop: "",
            WorkOrderPnchInA: "",
            PunchinAnnouncemString: "",
            PunchiAnnouncDescr: "",
            //[12/16/21, PC_11, CAS - Punchin Issue, Dhevanesam R]
            USERNAME: EMAIL,
            USER_EMAIL: EMAIL,
            BUYER_COOKI: BUYER_COOKIE,
            RawURL: RawURL


        };
        console.log("PunchinBO", PunchinBO)
        PunchInAction.punchinsession(PunchinBO);
        ;
        PunchinStore.on('load', this.assignPunchinData);
        PunchinStore.on('success', this.loginSuccess);
        PunchinStore.on('error', this.loginFailure);
        /**
        * Ref: LN_PC_08
        * Invoke the action to get the page load data from API.
        */
        //}
        //currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        //*************************
        let timerId, percent;

        // reset progress bar
        percent = 0;
        $('#pay').attr('disabled', true);
        $('#load').css('width', '0px');
        //$('#load').addClass('progress-bar-striped active');


        //*************************

    }
    componentWillUnmount() {
        this._isMounted = false;
        PunchinStore.removeListener('load', this.assignPunchinData);
        PunchinStore.removeListener('success', this.loginSuccess);
        PunchinStore.removeListener('error', this.loginFailure);
    }

    loginSuccess = () => {

        const { t } = this.props;
        // $(`#${SDIConstants.SDILoader}`).addClass('hide');
        this.setState({ error_description: "" });
        let loginSuccessData = JSON.parse(PunchinStore.loginSuccess);

        //[10/18/23, PS_ZE_INC_699_APP_02, Dhevanesam R]
        if (loginSuccessData.TokenUpdateStatus.length > 0) {
            loginSuccessData = (loginSuccessData.TokenUpdateStatus[0].isSuccess.toLowerCase() == "true")
            if (loginSuccessData) {
                console.log("loginSuccessData", loginSuccessData);
                let randomId = Math.floor(Math.random() * 10000000000)
                CookieService.updateLocalStorageValues("Session_ID", randomId)
                console.log("uuid", randomId)
                console.log("Session_ID", CookieService.getLocalStorageValues("Session_ID"))
                //console.log("BU", CookieService.getLocalStorageValues("Session_BUSUNIT").trim().toUpperCase());
                //if (CookieService.getLocalStorageValues("Session_BUSUNIT").trim().toUpperCase()== 'I0W01') {
                //    localStorage.clear();
                //    alert('Warning!\nYou do not have permission to access the application');
                //    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                //    return;
                //}
                CookieService.updateLocalStorageValues("Session_BuyerCookie", this.state.BUYERCOOKIE);
                CookieService.updateLocalStorageValues("Session_HOOKURL", this.state.HOOKURL);
                CookieService.updateLocalStorageValues("Session_PUNCHINID", this.state.PunchinSessionID);
                CookieService.updateLocalStorageValues("Session_IOLServer", this.state.loginData.Session_IOLServer);
                CookieService.updateLocalStorageValues("Session_cplusServer", this.state.loginData.Session_cplusServer);

                if (this.state.UrlParameter != null && this.state.UrlParameter != "") {

                    this.props.history.push({
                        pathname: this.state.RedirectURL,
                        search: `?${this.state.UrlParameter}`
                    });
                }
                else {
                    //this.props.history.push({
                    //    pathname: this.state.RedirectURL,
                    //    // search: `?${querystr[1]}`
                    //});
                    ;
                    MenuAction.refreshMenuItems(true, this.props);

                }
                //this.props.history.push({
                //    pathname: '/HomePage',
                //});
                //*
                //MenuAction.refreshMenuItems(true, this.props);

            } else {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Client is inactive")
                })
                // alert(t("Client is inactive"));
                //this.setState({ error_description: "Client is inactive" });
            }
        } else {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }

    }
    loginFailure = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let loginClickData = JSON.parse(PunchinStore.loginError);

        if (loginClickData.error == "1") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t(loginClickData.error_description)
            })
            //alert(t(loginClickData.error_description));
            //this.setState({ error_description: loginClickData.error_description });

        } else if (loginClickData.error == "2") {
            let failureAttemptCount = CookieService.getCookieValue(this.state.userName.toLowerCase());
            if (failureAttemptCount != "") {
                CookieService.setCookieValues(this.state.userName.toLowerCase(), 0, 1);
            }
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t(loginClickData.error_description)
            })
            //alert(t(loginClickData.error_description))
            //this.setState({ error_description: loginClickData.error_description });
        } else if (loginClickData.error == "3" || loginClickData.error == "5") {
            let failureAttemptCount = CookieService.getCookieValue(this.state.userName.toLowerCase());
            if (failureAttemptCount == "") {
                failureAttemptCount = 1;
            } else {
                failureAttemptCount = parseInt(failureAttemptCount) + 1;
            }
            CookieService.setCookieValues(this.state.userName.toLowerCase(), failureAttemptCount, 1);
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t(loginClickData.error_description)
            })
            //alert(t(loginClickData.error_description))
            //this.setState({ error_description: loginClickData.error_description });
        } else if (loginClickData.error == "4") {
            this.props.history.push({
                pathname: `/ChangePassword`,
                search: `?USERID=${this.state.userName}`
            });
        } else if (loginClickData.error == "invalid_grant") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t(loginClickData.error_description)
            })
            //alert(t(loginClickData.error_description))
            //this.setState({ error_description: loginClickData.error_description });
        }
    }

    assignPunchinData = () => {
        // $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let loginJSONData = JSON.parse(PunchinStore.PunchinData).FlagTable;
        let ValidationdataTable = JSON.parse(PunchinStore.PunchinData).ValidationdataTable;

        let sessiondata = JSON.parse(PunchinStore.PunchinData).LoginClickFlags_UpdatetoSession;
        let response = loginJSONData[0].Redirect
        let bus_cross = JSON.parse(PunchinStore.PunchinData)
        console.log("FlagTable", loginJSONData);
        console.log("sessiondata", sessiondata);
        console.log("ValidationdataTable", ValidationdataTable);
        let lblerr = loginJSONData[0].lblError;

        if (loginJSONData[0].lblError != null && loginJSONData[0].lblError != "" && loginJSONData[0].lblError != undefined && loginJSONData[0].lblError != "undefined") {
            console.log(" loginJSONData[0].lblError", loginJSONData[0].lblError);
            this.setState({ alert: loginJSONData[0].lblError });
            return;
        }
        let RedirectUrl = ""
        let UrlParmamter = ""
        let LoginData = JSON.parse(PunchinStore.PunchinData).PageLoadFlags_UpdatetoSession
        let AddPunchinUserEnable = "False"
        let punchincomp = CookieService.getLocalStorageValues("PUNCHINCOMPNAME");
        let Session_BUS_CROSS = CookieService.getLocalStorageValues("Session_BUS_CROSS");
        let punchinrolename = CookieService.getLocalStorageValues("PUNCHINROLENAME");
        let punchinbu = CookieService.getLocalStorageValues("PUNCHINBUSNUNIT");
        let punchinkey = CookieService.getLocalStorageValues("PUNCHINVALIDATIONKEY");
        let SGUID = CookieService.getLocalStorageValues("GUID");
        console.log("punchincomp", punchincomp);
        console.log("punchinrolename", punchinrolename);
        console.log("punchinbu", punchinbu);
        console.log("punchinbu", SGUID);
        // let response = JSON.parse(PunchinStore.PunchinData.FlagTable)[0];
        if (loginJSONData.length > 0 && this._isMounted) {
            let pageName = response

            console.log("response.Session_DEFAULTPAGE", loginJSONData[0].RedirectURL)
            // if (pageName == "HomePage") 
            if (pageName == "AddNewPunchinUser") {

                let queryParamindex = pageName.indexOf("?");
                let pagePath = pageName;
                let queryParams = "";
                if (queryParamindex > 0) {
                    pagePath = pageName.substring(0, queryParamindex);
                    queryParams = pageName.substring(queryParamindex, pageName.length);
                };

                //[Zeus 208, 11/22/2023] - Work order validation for Add new punchIn user - Kishore
                let queryParams1 = new URLSearchParams(window.location.search)
                let workOrder = queryParams1.get('workorder') != undefined && queryParams1.get('workorder') != null && queryParams1.get('workorder').trim() != "" ? queryParams1.get('workorder') : "";
                let query = loginJSONData[0].RedirectURL;
                let querystr = [];
                //if (userid) {
                //    query = `USERID=${userid}`
                //    if (bu) {
                //        query = `&BU=${bu}`
                //    }
                //}
                currentPagePath = this.props.location.pathname;

                currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
                if (query) {
                    querystr = query.split('AddNewPunchinUser?');
                }
                console.log("querystr", query);
                console.log("querystr", querystr[1]);
                console.log("currentPagePath", currentPagePath);
                let currentpage = window.location.href;
                if (currentPagePath.toLowerCase() == "/addnewpunchinuser") {
                    if (window.location.href.indexOf(`?`))
                        currentpage = window.location.href.split('?')[0];
                    window.location.href = `AddNewPunchinUser?${querystr[1]}`;
                }
                RedirectUrl = "/AddNewPunchinUser"
                UrlParmamter = querystr[1] + "&username=" + this.state.UserName + "&PUNCHINCOMPNAME=" + punchincomp + "&PUNCHINROLENAME=" + punchinrolename + "&PUNCHINBUSNUNIT=" + punchinbu + "&SGUID=" + SGUID + "&Session_BUS_CROSS=" + Session_BUS_CROSS + "&PUNCHINVALIDATIONKEY=" + punchinkey + "&WORKORDER=" + workOrder
                //  UrlParmamter = querystr[1] + "&username=" + this.state.UserName
                AddPunchinUserEnable = "true"
                //event.preventDefault();
                //23/11/2020
                //this.props.history.push({
                //    pathname: "/AddNewPunchinUser",
                //    search: `?${querystr[1]}`
                //});
            }
            if (pageName == "HomePage") {
                RedirectUrl = "/HomePage"
                AddPunchinUserEnable = "false"
                //this.props.history.push({
                //    pathname: "/HomePage",
                //   // search: `?${querystr[1]}`
                //});
            }
            let SessionId = ""

            if (sessiondata != null && sessiondata != undefined) {
                if (sessiondata.length > 0) {
                    SessionId = sessiondata[0].Session_PUNCHINID
                }
            }
            const queryParams = new URLSearchParams(window.location.search)
            const BUYER_COOKIE = queryParams.get('BUYER_COOKIE');

            const HOOK_URL = queryParams.get('HOOK_URL');
            this.setState({
                RedirectURL: RedirectUrl, UrlParameter: UrlParmamter, LoginData: LoginData, PunchinSessionID: SessionId,
                BUYERCOOKIE: BUYER_COOKIE, HOOKURL: HOOK_URL, PUNCHINCOMPNAME: punchincomp, PUNCHINROLENAME: punchinrolename, PUNCHINBUSNUNIT: punchinbu
            })
                ;
            // let USER_ID = ""
            //****************NEW****************
            let USER_ID = queryParams.get('USER_ID');
            const COMP_ID = queryParams.get('COMP_ID');
            const PASSWORD = queryParams.get('PASSWORD');
            const EMAIL_ID = queryParams.get('EMAIL');
            const workorder = queryParams.get('workorder');
            //**************END*************
            if (ValidationdataTable) {
                if (ValidationdataTable[0].PUNCHINVALIDATIONKEY == " " || ValidationdataTable[0].PUNCHINVALIDATIONKEY == "EMAIL" || ValidationdataTable[0].PUNCHINVALIDATIONKEY == "FRSTPARTEMAIL") {


                    if (ValidationdataTable[0].Session_USERID != null && ValidationdataTable[0].Session_USERID != "" && ValidationdataTable[0].Session_USERID != " " && ValidationdataTable[0].Session_USERID != undefined && ValidationdataTable[0].Session_USERID !="undefined") {
                        USER_ID = ValidationdataTable[0].Session_USERID;
                    }
                }

            }
            ;
            console.log("EMAIL_ID", EMAIL_ID);
            console.log("USER_ID", USER_ID);
            console.log(" loginJSONData[0].lblError", loginJSONData[0].lblError);
            if (loginJSONData[0].lblError == null || loginJSONData[0].lblError == "" || loginJSONData[0].lblError == undefined || loginJSONData[0].lblError == "undefined") {


                //const USER_ID = queryParams.get('USER_ID');
                //const COMP_ID = queryParams.get('COMP_ID');
                //const PASSWORD = queryParams.get('PASSWORD');
                //[10/18/23, PS_ZE_INC_699_APP_03, Dhevanesam R]
                let failureAttemptCount = 1
                let loginClickBO = {
                    userName: USER_ID,
                    password: PASSWORD,
                    failureAttemptCount: failureAttemptCount,
                    failureAttemptUname: USER_ID,
                    PARAM_DESTINATION_URL: "",
                    PunchinEnable: "true",
                    AddPunchinUserEnable: AddPunchinUserEnable,
                    UserEmailId: EMAIL_ID,
                    workorderNumber: workorder
                }
                console.log("loginClickBO", loginClickBO)
                PunchInAction.PunchinClick(loginClickBO, "true");
                //loginJSONData = loginJSONData[0];
                //loginJSONData.isBrowserCompatable = (loginJSONData.isBrowserCompatable.toLowerCase() == "true");
                //if (loginJSONData.isTestSite.toLowerCase() == "true")
                //    alert("**Warning - this is a test web site only...\nNo activity will be recorded as production.");
                //this.setState({ loginData: loginJSONData });
                console.log("PunchinData", this.state.response);
            }
        }
    }

    render() {
        //[4/22/22, CAS - 31, Dhevanesam R]
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="container-fluid content-section mb-0 mt-5">
                    <div className="row">
                        {/* Loading starts */}
                        <div className="container-fluid content-margin mb-0">
                            <div className="row">
                                <div className="col-md-6 mx-auto d-block mt-5 pt-5">
                                    <h4 className="text-center py-5 loading-heading"><Trans>SDI ZEUS Online Catalog Load</Trans></h4>
                                    {this.state.alert == "" ?
                                        <div className="progress custom-progress">
                                            <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} id="load" style={{ width: '0%' }} />
                                        </div> : null}
                                    {this.state.alert == "" ?
                                        <h4 className="text-center py-5 loading-description"><Trans>Loading ...Please Wait</Trans></h4> :<>
                                            <h4 className="text-center pb-5 err-msg itemPrice">{this.state.alert}</h4>
                                            <div className="text-center row justify-content-center">
                                                <button className="btn btn-primary custom-btn-primary primary-btn float-left non-catalog-mobile-btn px-5 mr-3" ><Trans>Return</Trans></button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default withTranslation()(PunchInComponent);
