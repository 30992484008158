import React, { Component } from 'react';
import Select from 'react-select';
import * as $ from 'jquery';
import { withTranslation, Trans } from 'react-i18next';
import * as MenuAction from '../actions/MenuAction';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import SupplierCallInAction from '../actions/SupplierCallInAction';
import SupplierCallInStore from '../stores/SupplierCallInStore';
import swal from 'sweetalert';
import Copy from 'copy-to-clipboard';
//[PS_AS_1, Kishore], Imported DistanceCalculator package in order to calculate the distance between the current user and the vendor address
import DistanceCalculator from 'distance-calculator-js';
let currentPagePath;
let userLongitude = 0;
let userLatitude = 0;


class SupplierCallInComponent extends Component {

    //[PS_PL_03, Kishore], State variables used in this component were initialised
    constructor(props) {
        super(props);
        this.state =
            {
                isFavorited: false,
                VendorData: [],
                Vendors: [],
                AddressData: [],
                PhoneNumber: "",
                ShowDetails: false,
                defaultVendorSelect: [],
                defaultAddressSelect: [],
                PhoneNumberFlag: false,
                Alertmsg: false
             }
    }

    componentDidMount() {

        debugger;
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        //[PS_AS_2, Kishore], Fetch the latitude and longitude of the current users location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getLatLon);
            function getLatLon(position) {
                userLatitude = position.coords.latitude;
                userLongitude = position.coords.longitude;
            }
        } else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please allow the website to access your location!!"
            });
            return;
        }

        //[PS_PL_05, Kishore], This method is for Initial page load function to fetch the vendor details
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        SupplierCallInAction.SupplierCallInPageLoad(CookieService.getLocalStorageValues("Session_UserID"));

        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };

        SupplierCallInAction.getCurrentFavPage(getFavPageBO);

        SupplierCallInStore.on('SupplierCallIn_PageLoad', this.pageLoadResponse.bind(this));
        SupplierCallInStore.on('favpage', this.loadFavPageResponse.bind(this));
        SupplierCallInStore.on('updateFavPage', this.updateFavPageResponse.bind(this));

    }



    //[PS_PL_24,Kishore], This method recevies the response from the store and set the respective state variables to bind the UI data
    pageLoadResponse = () => {
        debugger;
        let responseData = JSON.parse(SupplierCallInStore.pageLoadData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (responseData.hasOwnProperty('Exception')) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Something went wrong please try again"
            });
        }
        else {
            let vendors = [];
            let vendorsCheck = [];
            let pageLoadData = responseData.VendorData;
            pageLoadData.map((value, index) => {
                console.log(value.NAME1, "supplier callin test");
                if (vendorsCheck.indexOf(value.NAME1) == -1) {
                    let data = { value: value.NAME1, label: value.NAME1 };
                    vendors.push(data);
                    vendorsCheck.push(value.NAME1)
                }
            });
            pageLoadData.map((value, index) => {
                value.FullAddress = value.ADDRESS1 + ',' + value.CITY + ',' + value.STATE;
            });

            this.setState({ Vendors: vendors, VendorData: pageLoadData});

        }

    }

    //[PS_PL_29, Kishore], This method is used to bind the address field data based on the selected vendor
    onChangeVendor = (e) => {
        debugger;
        if (e.value != '0') {
            let currentLocation = { lat: userLatitude, long: userLongitude };
            let Address = [];
            let FinalAddress = [];
            let alertMsg = false;
            this.state.VendorData.map((value, index) => {
                if (value.NAME1 == e.value) {
                    Address.push(value);
                }
            });
            if ((userLatitude == 0 || userLatitude == undefined || userLatitude == null) && (userLatitude == 0 || userLatitude == undefined || userLatitude == null)) {
                alertMsg = true;
            }
            //[PS_AS_3, Kishore], The vendor address is sorted based on the nearest distance from the current users location.
            Address.sort((a, b) => (DistanceCalculator.calculate(currentLocation, { lat: a.LATITUDE, long: a.LONGITUDE }) * 0.00062137) > (DistanceCalculator.calculate(currentLocation, { lat: b.LATITUDE, long: b.LONGITUDE }) * 0.00062137) ? 1 : -1).map((value,index) => {
                let dis1 = DistanceCalculator.calculate(currentLocation, { lat: value.LATITUDE, long: value.LONGITUDE })
                let dis = (dis1 * 0.00062137).toFixed(2);
                let data = {};
                if ((userLatitude == 0 || userLatitude == undefined || userLatitude == null) && (userLatitude == 0 || userLatitude == undefined || userLatitude == null)) {
                    data = { value: value.ADDR_FIELD3, label: value.FullAddress + '~' + ' ' + 'N/A', number: value.PHONE };
                }
                else {
                    data = { value: value.ADDR_FIELD3, label: value.FullAddress + '~' + dis + ' miles', number: value.PHONE };
                }
                
                FinalAddress.push(data);
            })
            this.setState({ ShowDetails: true, AddressData: FinalAddress, defaultAddressSelect: [], PhoneNumber: "", PhoneNumberFlag: false, Alertmsg: alertMsg });
        }
        else {
            this.setState({ ShowDetails: false })
        }
    }

    //[PS_PL_32, Kishore], This method is used to bind the phone number field based on the selected address
    onChangeAddress = (e) => {
        debugger;
        if (e.value != '0') {
            this.setState({ PhoneNumber: e.number, AddressData: this.state.AddressData, defaultAddressSelect: [{ value: e.value, label: e.label }], PhoneNumberFlag: true })
        }
        else {
            this.setState({ PhoneNumber: "" })
        }
    }

    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(SupplierCallInStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };

    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (SupplierCallInStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(SupplierCallInStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(SupplierCallInStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };

    handleFavorite = (event) => {
        debugger;
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Supplier Call-In",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            SupplierCallInAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            SupplierCallInAction.deleteFavPage(deleteFavPageBO)
        }
    };

    LineLevelCopy_Click = (e, number) => {
        Copy(number);
        MenuAction.CopyMessage();
    }


    render() {
        return (
            <>
                <div className="container-fluid content-section">
                    <div className="row">
                        <div className="container-fluid content-margin Ord-title" >
                            <div className="row">
                                <div className="col-md-12 page-heading my-3">
                                    <h5 className="page-title"><Trans>Supplier Call-In</Trans></h5>
                                    <div className="fav-item-maintenance-icon mt-1">
                                        <input type="checkbox" id="checkbox-OrdStatus" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                        <label htmlFor="checkbox-OrdStatus">
                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div class="col-md-12 my-3">
                                        <div class="row">
                                            <div class="col-md-4 col-lg-3 ml-3 mb-3">
                                                <div className="row">
                                                        <label class="catalog-label col-md-3 col-lg-3 px-0 w-100 mt-2" for="BusinessUnit"><Trans>Vendor ID</Trans></label>

                                                        <span style={{ "fontSize": "14px" }} className="col-md-9 col-lg-9"><Select
                                                        id="vendor"
                                                        name="vendor"
                                                        options={this.state.Vendors}
                                                        onChange={(e) => this.onChangeVendor(e)}
                                                    /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.ShowDetails ? <>
                                    <div className="col-md-12">
                                        <div class="col-md-12 my-3">
                                            <div class="row">
                                                <div class="col-md-4 col-lg-3 ml-3 mb-3">
                                                    <div className="row">
                                                        <label class="catalog-label col-md-3 col-lg-3 px-0 w-100 mt-2" ><Trans>Address</Trans></label>
                                                        {this.state.AddressData.length > 0 ?
                                                            <span style={{ "fontSize": "14px" }} className="col-md-9 col-lg-9"><Select
                                                                id="Address"
                                                                name="Address"
                                                                value={this.state.defaultAddressSelect}
                                                                options={this.state.AddressData}
                                                                onChange={(e) => this.onChangeAddress(e)}
                                                            /></span> : <label style={{ "color": "red" }}><Trans>Address not available for the selected Vendor</Trans></label>}
                                                        {this.state.Alertmsg ? <label style={{ "color": "red", "font-size": "13px", "margin-left": "29%" ,"margin-top": "1%"}}><Trans>*Please allow the location for displaying miles</Trans> </label> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.PhoneNumber != "" && this.state.PhoneNumberFlag ?
                                        <div className="col-md-12">
                                            <div class="col-md-12 my-3">
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-3 ml-3 mb-3">
                                                        <div className="row">
                                                            <label class="catalog-label col-md-3 col-lg-3 px-0 w-100 mt-2" ><Trans>Phone Number</Trans></label>
                                                            {this.state.PhoneNumber != "" && this.state.PhoneNumber != null ? <> <a style={{ "margin-left": "15px", "margin-top": "7px" }} href={`tel:${this.state.PhoneNumber}`} >{this.state.PhoneNumber}</a> <span style={{ "margin-top": "7px" }} onClick={(e) => this.LineLevelCopy_Click(e, this.state.PhoneNumber)}><img className="px-1" src="Images/CopyImage.png" alt="Copy" title="Copy" style={{ height: '20px' }} /></span> </> : <label style={{ "color": "red", "font-size":"13px","display": "block", "margin-top": "10px", "margin-left": "14px" }}><Trans>*Phone Number is not available</Trans></label>}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                            </> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
export default withTranslation()(SupplierCallInComponent);

