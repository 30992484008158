/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 08/11/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: NC_PC_23
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
import * as $ from 'jquery';
/**
 * Ref: NC_PC_24
 * 
 * getUnitOfMeasures method gets called from the componentWillMount() of the component.
 * @param nonCatalogBO
 */
const getUnitOfMeasures = async (nonCatalogBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_PageLoad,
            JSON.stringify(nonCatalogBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.NonCatalog_PageLoad, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: NC_PC_27
 * 
 * saveNonCatalog method gets called from the save button click of the component.
 * @param savenonCatalogBO
 */
const saveNonCatalog = async (savenonCatalogBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_SaveNonCatalogEntry,
            JSON.stringify(savenonCatalogBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.NonCatalog_SaveNonCatalogEntry, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: NC_PC_30
 * 
 * getUnitOfMeasures method gets called from the quick item check of the component.
 * @param quickItemBO
 */
const QuickItemCheck = async (quickItemBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_QuickItemCheck,
            JSON.stringify(quickItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NONCAT_QuickItemCheck", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: NC_PC_25
 * 
 * checkCatalogClick method gets called from the check catalog click of the component.
 * @param checkCatalogBO
 */
//Start of commenting[10/16/23, Zeus-218] - Code is commented because Check catalog functionality is been removed from the Panel
/*const checkCatalogClick = async (checkCatalogBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_CheckCatalog_Click,
            JSON.stringify(checkCatalogBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NONCAT_CheckCatalog_Click", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};*/
//End of commenting
/**
 * Ref: NC_PC_26
 * 
 * addAttachment method gets called from the on change event of file
 * @param files
 */
const addAttachment = async (files) => {
    try {
        SDISVC.uploadFile(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_AttachFiles,
            "",
            files,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NONCAT_UploadAttachments", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: NC_PC_28
 * 
 * downloadAttachment method gets called from the on clicking the title of the file
 * @param downloadBO
 */
const downloadAttachment = async (downloadBO) => {
    try {
        SDISVC.downloadFile(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_DownloadFile,
            JSON.stringify(downloadBO),
            downloadBO.fileName,
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: NC_PC_29
 * 
 * removeAttachment method gets called from when clicking the remove icon of the attachment
 * @param removeBO
 */
const removeAttachment = async (removeBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_RemoveFile,
            JSON.stringify(removeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NONCAT_RemoveAttachment", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * getCurrentFavPage method gets called on page load
 * @param getFavPageBO
 */
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NONCAT_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NONCAT_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NONCAT_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export {
    getUnitOfMeasures,
    saveNonCatalog,
    QuickItemCheck,
    //checkCatalogClick,
    addAttachment,
    downloadAttachment,
    removeAttachment,
    getCurrentFavPage,
    insertFavPage,
    deleteFavPage
}