import BgOverlayComponent from './components/BgOverlayComponent';
import TestComponent from './components/TestComponent';
import UnderConstructionComponent from './components/UnderConstructionComponent';
import SessionTimeoutComponent from './components/SessionTimeoutComponent';
import AccessDeniedComponent from './components/AccessDeniedComponent';
import NonCatalogComponent from './components/NonCatalogComponent';
import FavOrderMainComponent from './components/FavOrderMainComponent';
import PunchoutComponent from './components/PunchoutComponent';
import OrderStatusComponent from './components/OrderStatusComponent';
import ShoppingCartComponent from './components/ShoppingCartComponent';
import ShopRedirectComponent from './components/ShopRedirectComponent';
import ItemDetailNewComponent from './components/ItemDetailNewComponent';
import OrderApprovalComponent from './components/OrderApprovalComponent';
import RequestorApprovalComponent from './components/RequestorApprovalComponent';
import ProfileComponent from './components/ProfileComponent';
import WaitingRequestorApprovalComponent from './components/WaitingRequestorApprovalComponent';
import SearchResultsComponent from './components/SearchResultsComponent'
import UserNotificationComponent from './components/UserNotificationComponent';
import FavItemMainComponent from './components/FavItemMainComponent';
import HomepageComponent from './components/HomepageComponent';
import PunchInComponent from './components/PunchInComponent';
import AddNewPunchinUser from './components/AddNewPunchinUser';
import PIPostComponent from './components/PIPostComponent';
import Punchinend from './components/Punchinend';
import PunchInRFQ from './components/PunchInRFQConfirmComponent';
//[09/14/21, PC_05, Dhevanesam R, Priyanks D], Import "ReceiveItemsComponent"
import ReceiveItemsComponent from './components/ReceiveItemsComponent';
//import DefaultPutAwayComponent from './components/DefaultPutAwayComponent';
import ActiveUsersReportComponent from './components/ActiveUsersReportComponent';
import SupplierCallInComponent from './components/SupplierCallInComponent';
import BinToBinComponent from './components/BinToBinComponent';
import STKIssuesCartComponent from './components/STKIssuesCartComponent';
import RMAComponent from './components/RMAComponent';
import RoleMasterComponent from './components/RoleMasterComponent'; 
// Mythili Zeus-241 Product Replenishment panel
import ProductReplenComponent from './components/ProductReplenComponent';
import ManualStkAdjComponent from './components/ManualStkAdjComponent';
import ExpressPutAwayComponent from './components/ExpressPutAwayComponent';
import EnterpriseCustComponent from './components/EnterpriseCustComponent'; 
import MaterialStorageLocComponent from './components/MaterialStorageLocComponent';
import ProgramMasterComponent from './components/ProgramMasterComponent';
import PickingAssigntoComponent from './components/PickingAssigntoComponent';
import RealTimeInvComponent from './components/RealTimeInvComponent';

const route = [
    {
        path: "/realtimeinv",
        component: RealTimeInvComponent,

    },

    // Mythili -- Zeus-40 SDI Enterprise customer master
    {
        path: "/EnterpriseCustomerMaster",
        component: EnterpriseCustComponent
    },
    //  PC_EC_02 Mythili -- Zeus-241 Product Replenishment
    {
        path: "/OrderItems",
        component: ProductReplenComponent
    },
    {
        path: "/pickingassignto",
        component: PickingAssigntoComponent,

    },

    {   //[PS_PL_01, Kishore], Adding the route path for supplier callin component
        path: "/SupplierOrderEntry",
        component: SupplierCallInComponent
    },
    {
        path: "/insiteonlinerma",
        component: RMAComponent
    },
    {
        path: "/ManualStockAdj",
        component: ManualStkAdjComponent,
    },
    {
        path: "/ExpressPutawayLocation",
        component: ExpressPutAwayComponent,
    },
    {
        path: "/MaterialStorageLoc",
        component: MaterialStorageLocComponent,

    },
    {
        path: "/RoleMaster",
        component: RoleMasterComponent,

    },
    {
        path: "/BinToBinTransfer",
        component: BinToBinComponent
    },
	{
		path: "/CoopForm",
		component: BgOverlayComponent
    },
    {
        path: "/sdicoop/",
        component: BgOverlayComponent,
    },
    {
        path: "/ActiveUsersbySite",
        component: ActiveUsersReportComponent
    },
    //{
    //    path: "/PutawayPickingLocations",
    //    component: DefaultPutAwayComponent

    //},
    {
        path: "/PIPost",
        component: PIPostComponent,
    },
    {
        path: "/PunchInRFQConfirm",
        component: PunchInRFQ,
    },
    {
        path: "/Punchinend",
        component: Punchinend,
    },
    {
        path: "/ForgotPassword",
        component: BgOverlayComponent,
    },
    {
        path: "/ChangePassword",
        component: BgOverlayComponent,
    },
    {
        path: "/searchresults",
        component: SearchResultsComponent,
    },
    {
        path: "/needapprove",
        component: OrderApprovalComponent,
    },

    {
        path: "/needquote",
        component: WaitingRequestorApprovalComponent,
    },
    {
        path: "/approvequote",
        component: RequestorApprovalComponent,
    },
    {
        path: "/Shopredirect",
        component: ShopRedirectComponent,
    },
    {
        path: "/RFQEntry",
        component: NonCatalogComponent,

    },
    {
        path: "/HomePage",
        component: HomepageComponent ,

    },
    {
        path: "/TestPage",
        component: TestComponent,

    },
    {
        path: "/favorder",
        component: FavOrderMainComponent,

    },
    {
        path: "/punchout",
        component: PunchoutComponent,

    },
    {
        path: "/sessiontimeout",
        component: SessionTimeoutComponent,

    },
    {
        path: "/underconstruction",
        component: UnderConstructionComponent,

    },
    /**
* Ref: SC_PC_02
* Importing the ShoppingCart which includes the shopping cart page.
*/
    {
        path: "/ShoppingCart",
        component: ShoppingCartComponent,
        //title: "shoppingcart"

    },
    {
        path: "/ItemDetailNew",
        component: ItemDetailNewComponent,

    },

    {
        path: "/orderstatus2",
        component: OrderStatusComponent,

    },

    {
        path: "/accessdenied",
        component: AccessDeniedComponent,

    },

    {
        path: "/profile",
        component: ProfileComponent,

    },
    {
        path: "/UserNotification",
        component: UserNotificationComponent,

    },
    {
        path: "/Favorites",
        component: FavItemMainComponent,

    },
    {
        path: "/PunchInSAP",
        component: PunchInComponent,

    },
    {
        path: "/AddNewPunchinUser",
        component: AddNewPunchinUser,

    },
    //[09/14/21, PC_05, Dhevanesam R, Priyanks D], Assign the receive items panel path "/receiving" to the string variable "path" and "ReceiveItemsComponent" to the "component" variable
    {
        path: "/receiving",
        component: ReceiveItemsComponent,
    },
    //[1/18/2024] Zeus-11 Stock Issues Panel[ Assign the receive items panel path "/STKIssuesCart" to the string variable "path" and "STKIssuesCartComponent" to the "component" variable] - Vishalini
    {
        path: "/STKIssuesCart",
        component: STKIssuesCartComponent,
    },
    {
        path: "/ProgramMaster",
        component: ProgramMasterComponent,
    },

    /**
     * Ref: LN_PC_03
     * Importing the BgOverlayComponent which includes Login and Forgot Password.
     */
    {
        path: "/",
        component: BgOverlayComponent,
        title: "ZEUS"

    },


]
export default route;