import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';


class STKIssuesCartStore extends EventEmitter {
    PageLoadData = "";
    deleteresponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleSTKIssuesCartStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.STKIssuesCart_PageLoad: {
                this.PageLoadData = action.action.response;
                this.emit('STKIssuesCartPageLoad');
                break;
            }
            case SDIConstants.methodNames.STKIssuesCart_ItemSearch: {
                this.InvSearchData = action.action.response;
                this.emit('InvItemSearch');
                break;
            }
            case "STKWOResponse": {
                this.STKWOResponse = action.action.response;
                this.emit('STKWOResponse');
                break;
            }
            case "workorderfail": {
                this.workorderresponse = "fail";
                this.emit('WOFail');
                break;

            }
            case SDIConstants.methodNames.STKIssuesCart_Submit: {
                this.SubmitResponse = action.action.response;
                this.emit('SubmitSTK');
                break;

            }
            case "OrderPDF": {
                this.OrderPDFMsge = action.action.response;
                this.emit('OrderPDF');
                break;
            }
            case "FAVITEM_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVITEM_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVITEM_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "ValidateToken": {
                this.PageLoadData = action.action.response;
                this.emit('ValidateToken');
                break;
            }
            case "BuildChrCD": {
                this.GetchargeCOdeBO = action.action.response;
                this.emit('GetchargeCOdeBO');
                break;
            }

        }
    }
}
const objIssuesCartStore = new STKIssuesCartStore;
// Dispatcher code
Dispatcher.register(objIssuesCartStore.handleSTKIssuesCartStore.bind(objIssuesCartStore));
export default objIssuesCartStore;