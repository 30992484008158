// PC_AU_6
// Import the file and packages
import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import * as CookieService from '../CookieService';
import * as ActiveUsersReportAction from '../actions/ActiveUsersReportAction';
import ActiveUsersReportStore from '../stores/ActiveUsersReportStore';
import * as MenuAction from '../actions/MenuAction';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import { withTranslation, Trans } from 'react-i18next';

let currentPagePath;

class ActiveUsersReportComponent extends Component {
    // PC_AU_6
    // Inilialize the state variable 
    constructor(props) {
        super(props);
        this.state = {
            pageLoad: true,
            searchData: "",
            searchData1:"",
            sortColumn: "ISA_USER_NAME",
            sortType: "ASC",
            pageNo: 1,
            noOfPage: 0,
            gridData: [],
            totalCount: 0,
            Page_ID: "",
            isFavorited: '',
            searchFlag:false
        }
    }
    componentWillUnmount() {
        ActiveUsersReportStore.removeListener('FetchActiveUsers', this.bindResponse);
        ActiveUsersReportStore.removeListener('exportToExcelResponse', this.exportToExcelRes);
        ActiveUsersReportStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        ActiveUsersReportStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    // PC_AU_9, PC_AU_16, PC_AU_36
    // 
    componentDidMount() {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        ActiveUsersReportStore.on('FetchActiveUsers', this.bindResponse);
        ActiveUsersReportStore.on('exportToExcelResponse', this.exportToExcelRes);
        this.fetchActiveUsersData(this.state);
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        ActiveUsersReportAction.getCurrentFavPage(getFavPageBO);
        ActiveUsersReportStore.on('favpage', this.loadFavPageResponse.bind(this));
        ActiveUsersReportStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }


    // PC_AU_17, PC_AU_18,PC_AU_19,PC_AU_20,PC_AU_21,
    // PC_AU_22,PC_AU_23,PC_AU_24,PC_AU_25,PC_AU_26
    // bindResponse method check the response from the API and update it in the state varaible
    bindResponse = () => {
        let reponseData = JSON.parse(ActiveUsersReportStore.FetchActiveUsers);

        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (reponseData.hasOwnProperty('Exception')) {
            this.setState({ gridData: [] });
            if (this.state.pageLoad == true) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Something went wrong, Please try again"
                }).then(() => {
                    window.location.href = '/homepage';
                });
            } else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Something went wrong, Please try again"
                })
            }

        } else {
            if (reponseData.Table != undefined && reponseData.Table != null && reponseData.Table != "" && reponseData.Table.length > 0) {
                let pg = Math.ceil((reponseData.Count[0].Count > 0 ? reponseData.Count[0].Count : 0) / 10);
                this.setState({ gridData: reponseData.Table, totalCount: reponseData.Count[0].Count, noOfPage: pg })
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
            } else {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ gridData: [] });
                if (this.state.pageLoad == true) {

                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "No record found"
                    }).then(() => {
                        window.location.href = '/homepage';
                    });
                } else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "No record found"
                    })
                }
            }
        }

        if (this.state.pageLoad == true) {
            this.setState({ pageLoad: false });
        }

    }
    // PC_AU_27
    // bindGrid method bind the Active User report data in the UI
    // Bug fix : 3438 - Zeus2.0_Zeus 44_ BU Values not displayed in grid
    bindGrid = () => {

        return this.state.gridData.map((value, index) => {
            return <tr>
                <td className="d-none d-sm-none d-md-table-cell" >{value.ISA_USER_NAME}</td>
                <td className="d-none d-sm-none d-md-table-cell" >{value.ISA_EMPLOYEE_ID}</td>
                <td className="d-none d-sm-none d-md-table-cell" >{value.ISA_EMPLOYEE_EMAIL}</td>
                <td className="d-none d-sm-none d-md-table-cell" >{value.PHONE_NUM}</td>
                <td className="d-none d-sm-none d-md-table-cell" >{value.BUSINESS_UNIT}</td>
                <td className="d-none d-sm-none d-md-table-cell" >{value.ROLETYPE}</td>
                <td className="d-none d-sm-none d-md-table-cell" >{value.ROLENAME}</td>
            </tr>
        })
    }
    // PC_AU_10, PC_AU_11
    // fetchActiveUsersData method fetch the Active user data
    fetchActiveUsersData = (obj) => {

        let fetchActiveUsersDataBO = {
            userBU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            searchData: obj.searchData,
            sortColumn: obj.sortColumn,
            sortType: obj.sortType,
            pageNo: obj.pageNo,
        }
        ActiveUsersReportAction.fetchActiveUsersData(fetchActiveUsersDataBO);
    }
    // PC_AU_29, PC_AU_30
    // exportToExcel method download the the Active user data as excel file formate
    exportToExcel = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let exportToExcelBO = {
            userBU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            searchData: this.state.searchData1,
            sortColumn: this.state.sortColumn,
            sortType: this.state.sortType,
            pageNo: this.state.pageNo
        }
        ActiveUsersReportAction.exportToExcel(exportToExcelBO);
    }
    // PC_AU_37, PC_AU_38
    // exportToExcelRes method check the excel file downloaded successfully or not
    exportToExcelRes = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

        let reponseData = JSON.parse(ActiveUsersReportStore.ExportToExcelResponse);
        if (reponseData == undefined || reponseData == null || reponseData == "" ) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            this.setState({ gridData: [] });
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Something went wrong, Please try again"
            }).then(() => {

            });
        }


    }
    // PC_AU_40
    // onChangeSearch method update the searchData state variable
    onChangeSearch = (e) => {
        this.setState({ searchData: e.target.value})
    }
    // PC_AU_44
    // onSortClick method update the sort column and sort type state variable
    onSortClick = (sortType, sortColumn) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        this.setState({ sortColumn: sortColumn, sortType: sortType });
        let obj = { searchData: this.state.searchData, pageNo: this.state.pageNo, sortColumn: sortColumn, sortType: sortType }
        this.fetchActiveUsersData(obj);
    }
    // PC_AU_46
    // onPageClick method update the spageNo state variable
    onPageClick = (e) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        this.setState({ pageNo: e.selected + 1 })
        let obj = { searchData: this.state.searchData, pageNo: e.selected+1, sortColumn: this.state.sortColumn, sortType: this.state.sortType }
        this.fetchActiveUsersData(obj);
    }
    // PC_AU_41
    // onSearchClick method searchData the spageNo state variable
    onSearchClick = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        this.setState({ pageNo: 1, searchData1: this.state.searchData})
        let obj = { searchData: this.state.searchData, pageNo: 1, sortColumn: this.state.sortColumn, sortType: this.state.sortType }
        this.fetchActiveUsersData(obj);
    }
    //PC_AU_48
    // Bug fix - Clear the search data while click "X"
    clearSearch = () => {
        this.setState({ searchData: "", searchData1:""})
    }
    //PC_AU_47
    // Bug fix - Invoeke onSearchClick method by enter button click
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.onSearchClick();
        };
    };

    // To handle favorite
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Active Users - SDI/Customers",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            ActiveUsersReportAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            ActiveUsersReportAction.deleteFavPage(deleteFavPageBO)
        }
    };
    loadFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let favPageResponse = JSON.parse(ActiveUsersReportStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ActiveUsersReportStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(ActiveUsersReportStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(ActiveUsersReportStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };


    render() {
        const { t } = this.props;
        return (<>

            <div class="container-fluid content-section">
                <div class="row">
                    <div class="col-md-12 page-heading mb-2">
                        <h5 class="page-title"><Trans>Active Users - SDI/Customers</Trans></h5>
                        <div className="fav-item-maintenance-icon mt-1">
                            <input type="checkbox" id="checkbox-OrdStatus" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                            <label htmlFor="checkbox-OrdStatus">
                                <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                    <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                        <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                        <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                        <g className="grp7" opacity={0} transform="translate(7 6)">
                                            <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                            <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                        </g>
                                        <g className="grp6" opacity={0} transform="translate(0 28)">
                                            <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                            <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                        </g>
                                        <g className="grp3" opacity={0} transform="translate(52 28)">
                                            <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                            <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                        </g>
                                        <g className="grp2" opacity={0} transform="translate(44 6)">
                                            <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                            <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                        </g>
                                        <g className="grp5" opacity={0} transform="translate(14 50)">
                                            <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                            <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                        </g>
                                        <g className="grp4" opacity={0} transform="translate(35 50)">
                                            <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                            <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                        </g>
                                        <g className="grp1" opacity={0} transform="translate(24)">
                                            <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                            <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                        </g>
                                    </g>
                                </svg>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12 my-3">
                                <div class="row">
                                    <div className="col-md-12 mb-2">
                                        <div className="float-right" style={{ "marginRight": "15px" }}>{this.state.gridData.length > 0 ? <>
                                            <button className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" onClick={this.exportToExcel}><Trans>Export to Excel</Trans></button></> : null}
                                            <div className="input-group dropdown-search float-left mb-2 order-filter-width" style={{ "width": "307px" }}>
                                                <input type="text" className="form-control  newsearchborderright" placeholder={t("Search")} aria-label="Text input with dropdown button" id="searchTxt" value={this.state.searchData} onKeyDown={this.searchKeyPress} onChange={this.onChangeSearch} />
                                                <div className="input-group-append newsearchdiv">
                                                    <span className="search-box newsearch cursor-pointer" id="basic-text1">
                                                        <span className={this.state.searchData.trim().length > 0 ? "search-cancel cursor-pointer" : "d-none"} style={{ "top": "11px", "right": "46px" }} onClick={() => this.clearSearch()}></span>
                                                    </span>
                                                    <span class="input-group-text OrderStatus-SrchBar cursor-pointer"><span onClick={() => this.onSearchClick()} class="shopping-search-icon"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table ZEUS_Grid FOM-grid chk-ordertbl">
                                                    <thead>
                                                        <tr>
                                                            <th className="d-sm-none d-md-table-cell position-sort-icon"><Trans>User Name</Trans><span className="sort-up ml-1" onClick={(e) => this.onSortClick("ASC", "ISA_USER_NAME")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.onSortClick("DESC", "ISA_USER_NAME")}><img src="Images/sort1.svg" /></span></th>
                                                            <th className="d-sm-none d-md-table-cell position-sort-icon"><Trans>User ID</Trans><span className="sort-up ml-1" onClick={(e) => this.onSortClick("ASC", "ISA_EMPLOYEE_ID")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.onSortClick("DESC", "ISA_EMPLOYEE_ID")}><img src="Images/sort1.svg" /></span></th>
                                                            <th className="d-sm-none d-md-table-cell position-sort-icon"><Trans>Email Address</Trans><span className="sort-up ml-1" onClick={(e) => this.onSortClick("ASC", "ISA_EMPLOYEE_EMAIL")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.onSortClick("DESC", "ISA_EMPLOYEE_EMAIL")}><img src="Images/sort1.svg" /></span></th>
                                                            <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Phone</Trans><span className="sort-up ml-1" onClick={(e) => this.onSortClick("ASC", "PHONE_NUM")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.onSortClick("DESC", "PHONE_NUM")}><img src="Images/sort1.svg" /></span></th>
                                                            <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Business Unit</Trans><span className="sort-up ml-1" onClick={(e) => this.onSortClick("ASC", "Business_unit")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.onSortClick("DESC", "Business_unit")}><img src="Images/sort1.svg" /></span></th>
                                                            <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Role Type</Trans><span className="sort-up ml-1" onClick={(e) => this.onSortClick("ASC", "ROLETYPE")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.onSortClick("DESC", "ROLETYPE")}><img src="Images/sort1.svg" /></span></th>
                                                            <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Role Name</Trans><span className="sort-up ml-1" onClick={(e) => this.onSortClick("ASC", "ROLENAME")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.onSortClick("DESC", "ROLENAME")}><img src="Images/sort1.svg" /></span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.gridData.length > 0 ? this.bindGrid() :
                                                            (

                                                                <tr>
                                                                    <td colSpan="11">
                                                                        <section className="site-undercontruction container text-center center-panel pt-4">
                                                                            <div className="img-section">
                                                                                <img src="images/nosearch.jpg" alt="imagefile" />
                                                                            </div>
                                                                            <p className="search-result-content pt-4"><Trans>Sorry, we couldn’t find any search result</Trans>
                                                                            </p>
                                                                        </section>
                                                                    </td>
                                                                </tr>)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {this.state.gridData.length > 0 ? <>
                                            <div className="col-md-12">
                                                <span className="total-records mt-3 d-inline-block">
                                                    <Trans>Number of Records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
                                                </span>
                                                {this.state.totalCount > 10 ? <ReactPaginate
                                                    previousLabel={<span className="right-pagination-arrow" />}
                                                    nextLabel={<span className="left-pagination-arrow" />}
                                                    previousClassName={'page-item'}
                                                    nextClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextLinkClassName={'page-link'}
                                                    disabledClassName={'disabled'}
                                                    breakLabel={'...'}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link'}
                                                    pageCount={this.state.noOfPage}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={this.onPageClick}
                                                    containerClassName={'custom-pagination pagination'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    activeClassName={'active'}
                                                    hrefBuilder={() => '#'}
                                                    forcePage={this.state.pageNo - 1}
                                                /> : null}
                                            </div></> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)

    }

}
export default withTranslation()(ActiveUsersReportComponent);