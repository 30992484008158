/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/22/2020
 * 
 * Ref: PO_PC_19
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class WaitingRequestorApprovalStore extends EventEmitter {
    wRequestorData = "";
    currentFavPageResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    /**
    * Ref: PO_PC_20
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleWaitingRequestorApprovalStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.WaitingRequestorApproval_Pageload: {
                this.wRequestorData = action.action.response;
                this.emit('wrequestorData');
                break;
            }

            case "WREQAPP_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "WREQAPP_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "WREQAPP_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }


        }
    }
}
const objWaitingRequestorApprovalStore = new WaitingRequestorApprovalStore;
// Dispatcher code
Dispatcher.register(objWaitingRequestorApprovalStore.handleWaitingRequestorApprovalStore.bind(objWaitingRequestorApprovalStore));
export default objWaitingRequestorApprovalStore;