import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';

class CoopLandingStore extends EventEmitter {
    pageLoadResponse = "";
    /**         
    * PS_ZE_305_APP_24/Sathis/05/10/2024
    * This method invokes to handle coop landing page store.
    * */
    handleCoopLanding = (action) => {
        switch (action.action.type) {
            case "coopLandingPageLoad": {
                this.pageLoadResponse = action.action.response;
                debugger;
                this.emit('coopClientPageLoad');
                break;
            }

        }
    }
}
const objCoopLanding = new CoopLandingStore;
Dispatcher.register(objCoopLanding.handleCoopLanding.bind(objCoopLanding));
export default objCoopLanding;