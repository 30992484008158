//1 / 9 / 2024 Mythili Zeus - 241 Product Replen panel
//PC_PR_APP_10 to PC_PR_APP_17 adding emitters and dispatching
import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';
class ProductReplenStore extends EventEmitter {
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleProductReplen = (action) => {
        let itemLoadResponse = "";
        let cartLoadResponse = "";
        let placeCartResponse = "";
        switch (action.action.type) {
            case "itemLoadResponse": {
                this.itemLoadResponse = action.action.response;
                this.emit('itemLoadResponse');
                break;
            }
            case "cartLoadResponse": {
                this.cartLoadResponse = action.action.response;
                this.emit('cartLoadResponse');
                break;
            }
            case "placeCartResponse": {
                this.placeCartResponse = action.action.response;
                this.emit('placeCartResponse');
                break;
            }
            case "NOTIFY_InsertFavPage": {
                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }

}
const objProductReplen = new ProductReplenStore;
// Dispatcher code
Dispatcher.register(objProductReplen.handleProductReplen.bind(objProductReplen));
export default objProductReplen;