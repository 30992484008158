import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';

class MaterialStorageLocStore extends EventEmitter {
    pageLoadResponse = "";
    submitResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };

    handleMaterialStorageLocStore = (action) => {
        switch (action.action.type) {
            case "funcPageLoad": {
                this.pageLoadResponse = action.action.response;
                debugger;
                this.emit('funcPageLoad');
                break;
            }
            case "funcSubmit": {
                this.submitLocationResponse = action.action.response;
                debugger;
                this.emit('funcSubmit');
                break;
            }
            case "NOTIFY_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }
}
const objMaterialStorageLocStore = new MaterialStorageLocStore;
Dispatcher.register(objMaterialStorageLocStore.handleMaterialStorageLocStore.bind(objMaterialStorageLocStore));
export default objMaterialStorageLocStore;
