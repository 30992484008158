/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/12/2020
 * 
 * Ref: QI_PC_19
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class QuickItemStore extends EventEmitter {
    quickItemData = "";
    submitQuickItem = "";
    ShopCartsubmitQuickItem = "";
    /**
    * Ref: QI_PC_20
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleQuickItemStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.NonCatalog_getcpdata: {
                this.quickItemData = action.action.response;
                this.emit('quickItemData');
                break;
            }
            case SDIConstants.methodNames.NonCatalog_SubmitQuickEntry: {
                this.submitQuickItem = action.action.response;
                this.emit('submitQuickItem');
                break;
            }
            case SDIConstants.methodNames.ShoppingCart_SubmitQuickEntry: {
                this.ShopCartsubmitQuickItem = action.action.response;
                this.emit('ShopCartsubmitQuickItem');
                break;
            }
          

        }
    }
}
const objQuickItemStore = new QuickItemStore;
// Dispatcher code
Dispatcher.register(objQuickItemStore.handleQuickItemStore.bind(objQuickItemStore));
export default objQuickItemStore;