import React, { Component } from 'react';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
import * as CookieService from '../CookieService';
import { withTranslation, Trans } from 'react-i18next';
class Punchinend extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (!$(`#${SDIConstants.SDILoader}`).hasClass("hide")) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        CookieService.setCookieValues(SDIConstants.access_token, "", -1);
        localStorage.clear();
    }
    componentWillUnmount() { }
    Redirect = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.push({
            pathname: `\PIPost`,
        });
    }
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        return (
            <div className="container-fluid content-section-margin-style">
                <div className="row">
                    <section className="site-undercontruction container text-center center-panel">
                        <p className="primaryinfo"><Trans>Punchin session has been end</Trans></p>
                        <p className="secondaryinfo"><Trans>Click on exit button to return</Trans></p>
                        <div className="img-section">
                            <img src="images/punchin.jpg" alt="imagefile" />
                        </div>
                        <div className="text-center my-3">
                            <button className="btn btn-primary custom-btn-primary primary-btn px-4 secondaryinfo" onClick={() => this.Redirect()}><Trans>Exit</Trans></button>

                        </div>
                    </section>
                </div>
            </div>)
    }
};
export default withTranslation()(Punchinend);

