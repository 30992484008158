/**
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Punchout page component. The component displays the Punchout page details.
 * 
 * App Name: SDI-Walmart
 * Author: Chandrasekar
 * Created Date: 08/17/2020 
 */
import React, { Component } from 'react';
import * as CookieService from '../CookieService';
/**
 * Ref: SR_PC_02
 * Importing the action and store file and set to an object.
 */
import * as ShopRedirectAction from '../actions/ShopRedirectAction';
import ShopRedirectStore from '../stores/ShopRedirectStore';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
import { withTranslation, Trans } from 'react-i18next';
 class ShopRedorectComponent extends Component {
  /**
   * Ref: SR_PC_04
   * Define the constructor that takes props as parameter
   * 
   * @param props
   */
    constructor(props) {
        super(props);
        this.shopRedirectResponse = this.shopRedirectResponse.bind(this);
    }
    /**
    * Ref: SR_PC_06
    * componentWillMount method will be first method which will be invoked
    * when shop redirect page is loaded
    */
    componentWillMount() {
       
      
    };
    componentWillUnmount() {
        ShopRedirectStore.removeListener('shopRedirect', this.shopRedirectResponse);
    };
    /**
    * Listener method for shop redirect page load response
    */
    shopRedirectResponse = () => {
        let shopRedirectData = JSON.parse(ShopRedirectStore.shopRedirectData);
        console.log("shopRedirectData", shopRedirectData);
        if (shopRedirectData.length > 0) {
            if (shopRedirectData[0].is_Success.toLowerCase() == "true") {
                this.props.history.push({
                    pathname: "/shoppingcart",
                });
            }
        }
    };
    /**
    * componentDidMount will be called for loading HTML scripts
    */
    componentDidMount() {
        let isTokenPresentSR = CookieService.getCookieValue(SDIConstants.access_token);
        if (isTokenPresentSR == "") {
            localStorage.clear();
            this.props.history.push({
                pathname: '/',
            });
            return false;
        };
        const urlParams = new URLSearchParams(window.location.search);
        const fileName = urlParams.get('fileName');
        /**
        * Ref: SR_PC_08
        * Invoke the action to get the page load data from API.
        */
        if (fileName) {
            let addPunchoutBO = {
                Session_VENDOR_CONFIG: CookieService.getLocalStorageValues("Session_VENDOR_CONFIG"),
                filePath: fileName,
                Session_OROPUNCHOUTFLAG: CookieService.getLocalStorageValues("Session_OROPUNCHOUTFLAG"),
                Session_ZEUSNOCATALOGSITE: CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE"),
                Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
                Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")
            };
            ShopRedirectAction.addPunchoutItems(addPunchoutBO);
            /**
            * Define the listener methods
            */
            ShopRedirectStore.on('shopRedirect', this.shopRedirectResponse);
        }
        let timerId, percent;

        // reset progress bar
        percent = 0;
        $('#pay').attr('disabled', true);
        $('#load').css('width', '0px');
        //$('#load').addClass('progress-bar-striped active');

        timerId = setInterval(function () {

            // increment progress bar
            percent += 5;
            $('#load').css('width', percent + '%');
            // $('#load').html(percent + '%');


            if (percent >= 100) {
                clearInterval(timerId);
                $('#pay').attr('disabled', false);
                //$('#load').removeClass('progress-bar-striped active');
                //$('#load').html('payment complete');
            }
        }, 500);
    }
  

     render() {
        //[11/26/21, CAS - 31, Priyanka D]
        return (
            <div className="container-fluid content-section mb-0 mt-5">
                <div className="row">
                    {/* Loading starts */}
                    <div className="container-fluid content-margin mb-0">
                        <div className="row">
                            <div className="col-md-6 mx-auto d-block mt-5 pt-5">
                                <h4 className="text-center py-5 loading-heading"><Trans>SDI ZEUS Online Catalog Load</Trans></h4>
                                <div className="progress custom-progress">
                                    <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} id="load" style={{ width: '0%' }} />
                                </div>
                                <h4 className="text-center py-5 loading-description"><Trans>Loading ...Please Wait</Trans></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    };
}
export default withTranslation()(ShopRedorectComponent);