import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';

class CoopFormStore extends EventEmitter {
	pageLoadData = "";
	submitData = "";
	attachmentData = "";
	removeData = "";
	handleCoopFormStore = (action) => {
		switch (action.action.type) {
			case "coopPageLoad": {
				this.pageLoadData = action.action.response;
				debugger;
				this.emit('pageLoadResponse');
				break;
			}
			case "coopSubmit": {
				this.submitData = action.action.response;
				debugger;
				this.emit('submitResponse');
				break;
			}
			case "coopAttachment": {
				this.attachmentData = action.action.response;
				debugger;
				this.emit('attachmentResponse');
				break;
			}
			case "removeAttachment": {
				this.removeData = action.action.response;
				debugger;
				this.emit('removeCoopAttachment');
				break;
			}
		}
	}
}
const objCoopFormStore = new CoopFormStore;
Dispatcher.register(objCoopFormStore.handleCoopFormStore.bind(objCoopFormStore));
export default objCoopFormStore;