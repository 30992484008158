import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';

class ManualStkAdjStore extends EventEmitter {
    searchResponse = "";
    submitResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    /**         
    * PS_ZE_252_APP_24/Sathis/02/01/2024
    * This method invokes to handle manual stock store.
    * */
    handleManualStkAdjStore = (action) => {
        switch (action.action.type) {
            case "search": {
                this.searchResponse = action.action.response;
                debugger;
                this.emit('search');
                break;
            }
            case "submit": {
                this.submitResponse = action.action.response;
                debugger;
                this.emit('submit');
                break;
            }
            case "NOTIFY_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }
}
const objManualStkAdjStore = new ManualStkAdjStore;
Dispatcher.register(objManualStkAdjStore.handleManualStkAdjStore.bind(objManualStkAdjStore));
export default objManualStkAdjStore;