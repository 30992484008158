/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/17/2020
 * 
 * Ref: PO_PC_19
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class PunchoutStore extends EventEmitter {
    punchoutData = "";
    imageLogoResponse = "";
    currentFavPageResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    /**
    * Ref: PO_PC_20
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handlePunchoutStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.Punchout_PageLoad: {
                this.punchoutData = action.action.response;
                this.emit('punchoutData');
                break;
            }
            case SDIConstants.methodNames.Punchout_LogoClick: {
                this.imageLogoResponse = action.action.response;
                this.emit('logoData');
                break;
            }
            case "PUNCHOUT_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "PUNCHOUT_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "PUNCHOUT_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }


        }
    }
}
const objPunchoutStore = new PunchoutStore;
// Dispatcher code
Dispatcher.register(objPunchoutStore.handlePunchoutStore.bind(objPunchoutStore));
export default objPunchoutStore;