/*
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Shopping Cart page component. The component displays the Shopping cart page details.
 * 
 * App Name: SDI-Walmart
 * Author: M.Sushmitha
 * Created Date: 08/10/2020 
 */
import React, { Component } from 'react';

import Modal from 'react-modal';
import Image from 'react-image-resizer';
import Select from 'react-select';
//[10/29/21, CAS - Safari Browser - Crystal Report Issue, PC_01, Dhevanesam R]
import { BrowserView, MobileView, isSafari, isMobile, isIE } from 'react-device-detect';
import { saveAs } from 'file-saver';
//import TokenEx from 'tokenex';
/*
 * CookieService is used for the Usage of the local database values in the component. 
 * The Local database is used for maintaining the login user falgs 
 * 
 * */
import * as CookieService from '../CookieService';
import SDIConstants, { redirectToItemDetails } from '../SDIConstants';
/*
 *Datepicker plugin is used for the usage of react date picker.
 * 
 * */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/*
 * QuickItemComponent is used in the shoppingcartcomponent for checking item whether it can be added by using quick item option.
 * Adding 
 */
import QuickItemComponent from '../components/QuickItemComponent';

import PDFPreviewComponent from './PDFPreviewComponent';

/*
 * ShoppingCartAction has the action method used to call the API method for data response.
 * */
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
/*
 * ShoppingCartstore has the store case whhich emits the action response and invokes the response method.
 * */
import ShoppingCartStore from '../stores/ShoppingCartStore';
/*
 * MenuAction has the action method used to call the API method for data response.
 * When an item is added to cart or the page is made to favorite.Menu bar has to reloaded.
 * For this purpose manuaction is used in Shoppingcartcomponent.
 * */
import * as MenuAction from '../actions/MenuAction';
/*
 *Import the Jquery to use jquery feature for binding the value to the field and so on.
 */
import * as $ from 'jquery';
import { de } from 'date-fns/esm/locale';
//[PC_07, 09/07/21, Author - Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
//var TokenEx = require('../TokenEx/Iframe-v3.min.js')
//[3/15/2023]CAS-44 Importing googlesMaps component to display maps in shopping cart after validating user's address
import GoogleMaps from './GoogleMaps';
//[5/7/23, PC_ZE_85_APP_01, Dhevanesam R]
import swal from 'sweetalert';
import DistanceCalculator from 'distance-calculator-js';
import Autocomplete from 'react-autocomplete';
import * as FavItemMainAction from '../actions/FavItemMainAction';
import FavItemMainStore from '../stores/FavItemMainStore';
let qckKeycounter = 0;
let rmvAttachindex = -1;
//let OLDueDateVal;
let currentPagePath;
let Searchkeywoed = ""
let AlreadyInCart = 0
let QuickItemId = ""
let OrderSuccessCOunt = 0
var iframe = null
//[3/17/2023, PS_SR_URL_APP_13 - To remove catalog look up tree, Dhevanesam R]
//let SearchUnspsc = ""
//let SearchCatTree = ""
//[5/7/23, PC_ZE_85_APP_01, Dhevanesam R]
let CurrentLatitude = 0;
let CurrentLongitude = 0;
let tempEMPEmail = "";
let maxQty = 99999999;


const countryOptions = [
    {
        value: 'USA', label: 'USA'
    },
    {
        value: 'CAN', label: 'CAN'
    },
    {
        value: 'MEX', label: 'MEX'
    }
]
//[5/7/23, PC_ZE_85_APP_22, Dhevanesam R]
const blk = {
    backgroundColor: "Ghostwhite",
    padding: "4px 4px",
    marginTop: "13px",
    float: "left",
    right: "initial",
    border: "1px  solid gray"
}
const la = {
    backgroundColor: "Orange",
    padding: "3px 3px",
    marginTop: "11px",
    float: "left",
    right: "initial",
    border: "1px  solid gray"
}
const lal = {
    backgroundColor: "Green",
    padding: "3px 3px",
    marginTop: "11px",
    float: "left",
    right: "initial",
    border: "1px  solid gray"
}
const txt = {
    color: "black",
    float: "left",
    fontSize: '15px',
    textAlign: "initial",
    marginLeft: "12px"
}
const colourStyles = {
    option: (styles, { data }) => {
        return {
            ...styles,
            alignItems: 'center',
            display: 'flex',
            ':before': {
                backgroundColor: data.color,
                borderRadius: 10,
                content: '" "',
                display: 'block',
                marginRight: 8,
                height: 10,
                width: 10,
                border: "1px  solid gray"
            }
        }
    }
}
let menuStyle = {
    borderRadius: '3px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 12px',
    background: 'rgba(255, 255, 255, 0.9)',
    fontSize: '90%',
    position: 'absolute',
    maxHeight: '160px',
    left: '14px',
    top: '34px',
    minWidth: '92%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    zIndex: 1
};
class ShoppingCart extends Component {
    /**
     * SC_PC_03 
     * Declare a �constructor� method with �props� as its parameters.
     */
    constructor(props) {
        super(props);
        //this.BuildFavMenu = this.BuildFavMenu.bind(this);
        //this.bindFavSubItem = this.bindFavSubItem.bind(this);
        //this.BindCart = this.BindCart.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.quickItemClick = this.quickItemClick.bind(this);
        this.BuildShoppingCartPage = this.BuildShoppingCartPage.bind(this);
        this.loadQuickItem = this.loadQuickItem.bind(this);
        this.closeFavPopup = this.closeFavPopup.bind(this);
        this.DeleteItemStatus = this.DeleteItemStatus.bind(this);
        this.COStatus = this.COStatus.bind(this);
        this.TaxvalueBind = this.TaxvalueBind.bind(this);
        this.PaymentResponse = this.PaymentResponse.bind(this);
        this.AddCardDetails = this.AddCardDetails.bind(this);
        this.buildChargeCode = this.buildChargeCode.bind(this);
        this.OrderPlacedStatus = this.OrderPlacedStatus.bind(this);
        this.Addcard = this.Addcard.bind(this);
        this.favOrderremoveAttachmentResponse = this.favOrderremoveAttachmentResponse.bind(this);
        //this.quickItemClick = this.quickItemClick.bind(this);
        /*
         * All the below mentioned State variable are used in the component to bind,update,show popup and so on.
         * */
        this.state = {
            /*[11/22/21, PC_SC_01, 1854], Priyanka D*/
            OrdLevelChrgCode:false,
            ShowOrderDetails: 1,
            OnlyCatalogItem: true, CREDITCARDPROCSite: CookieService.getLocalStorageValues("Session_CREDITCARDPROCSite") == "Y" ? true : false,
            showprice: (CookieService.getLocalStorageValues("Session_ShowPrice") == "1" && CookieService.getLocalStorageValues("Session_ISAPriceBlock") != "Y" ? "0" : ""),
            OLDueDateVal: "", apiURL: "", sizedd: 5, isFavorited: false, strCplusitemid: "0", isExpand: false, ItemDate: "",
            txtItemID: "", startDate: "", ItemIndex: 0, CartItems: [], FavMenu: [], FavMenuSub: [], EmployeeCC: [], ShipToDDValue: [],
            showShipto: (CookieService.getLocalStorageValues("Session_AddressFlag") != "Y" ? true : false), defaultShipto: "", MachineTable: [], TypeTable: [], SiteCurrencyTable: [], ActiveFieldToUpdate: "", CloseModal: "",
            ItemId: "", UniqId: "", Description: "", FavItemIdPopUp: "", FavSDICatgPopUp: "", FavYourCatgPopUp: "", FavItemDescripPopUp: "",
            FavYourDescripPopUp: "", FavMFGPopUp: "", FavMFGPartNUMPopUp: "", txtUOMHidePopUp: "", txtMfgItemidHidePopUp: "", txtItemidHidePopUp: "",
            txtMFGHidePopUp: "", chkLineNumFlag: "False", attachmentPathArr: [], attachmentErrormsg: "", FavorPopUptitle: "", QuickItemEntryPUp: false,
            AddFavPUp: false, FavOrderConfPUp: false, AddAttachPUp: false, LineIncrePUp: false, CancelOrderConfPUp: false, RemoveItemPUp: false, EditDescPopUp: false,
            OrderEmpyId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
            WordOrderNoLength: 10, hdfChangeLineNum: "N", hdfZeusNonCata: "N", OLEmplyIdLabel: "*Employee ID", OLShipToIdLabel: "*Ship To", ItemMachinePartNum: [],
            SearchKey: "", ShowMachineNumber: true,
            Session_ZEUSNOCATALOGSITE: CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE"),
            Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
            Session_MANORDNO: CookieService.getLocalStorageValues("Session_MANORDNO"),
            removeattach: [], CartCOunt: "", QuickitemEnabled: false, OrderPlacedstatus: "", FavOrder: false, base64URL: "",
            ShowPdf: false, PricedOrderNum: "", NonCataOrderNum: "", PagelaodCartValue: [], CerditCardEnabled: false, AddfavOrderValue: "False",
            taxValue: "", OrderTotalValue: "", WalletValue: [], CardNumber: "", ExpiryDate: "", ExpiryYear: "", CerditCardsExist: false,
            CardCVV: "", CardImageType: "", CardfieldDisabled: false, TokenTable: [], SelectedCardToken: "",
            SelectCardNumber: "", SelectedCardImageType: "", CardToken: "", SelectedRemoveCard: [], ShowCerditCard: false,
            PaymentResponse: [], ReferenceNumber: "", SelectedCardHMCA: "", CardHMCA: "", ChargeCodeMultiple: false, OlChargeCOde: "", CCPlaceDD1Title: "",
            CCPlaceDD2Title: "", CCPlaceDD3Title: "", CCdropCD1SegTitle: "", CCdropCD2SegTitle: "", CCdropCD3SegTitle: "", CCdropEmpSegTitle: "", CCdropItmSegTitle: "",
            CCdropMchSegTitle: "", PlaceDD1Value: [], PlaceDD1Data: [], PlaceDD2Value: [], PlaceDD2Data: [], PlaceDD3Value: [], PlaceDD3Data: [],
            dropCD1SegValue: [], dropCD1SegData: [], dropCD2SegValue: [], dropCD2SegData: [], dropCD3SegValue: [], dropCD3SegData: [], dropEmpSegValue: [], dropEmpSegData: [],
            dropItmSegValue: [], dropItmSegData: [], dropMchSegValue: [], dropMchSegData: [], dropCD1SegVisible: false, dropCD2SegVisible: false, dropCD3SegVisible: false,
            PlaceDD1Visible: false, PlaceDD2Visible: false, PlaceDD3Visible: false, dropMchSegVisible: false, dropEmpSegVisible: false, dropItmSegVisible: false, ChargeSelected: "",
            ChargeCodeLevel: 0, PlaceDD1_disable: false, PlaceDD2_disable: false, PlaceDD3_disable: false, dropCD1Seg_disable: false, dropCD2Seg_disable: false, dropCD3Seg_disable: false,
            dropEmpSeg_disable: false, dropItmSeg_disable: false, dropMchSeg_disable: false, SCbIsWithPricedItems: false, PunchinUser: false,
            WorkOrderTitle: "Work Order #", ChargeCodeTitle: "Order Charge Code", SelectedCardDeleted: false, RedirectToPiPost: false, PiPostOrderNumber: "", Favpopupprice: "", FavSAP: "",
            //[04/08/22, CAS - 31, Dhevanesam R]
            StatusErrVal: "", ErrorMsgVal: "",
            showEditIcon: false, recomendedAddress: "", userSelectedAddress: "", stateValue: "", cityValue: "", streetValue: "", countryValue: "USA" , zipCodeValue: "",
            isValidStreet: "", isValidCity: "", isValidState: "", isValidZipCode: "", isValidCountryCode: "", validLongitude: "", validLatitude: "",
            userAddress: "", disableShipto: false, userAddressSelect: false, recAddressSelect: false, recAddress: true, typedAddress: false, selectCountry: countryOptions[0],
            typedStreet: "", typedCity: "", typedState: "", typedZipCode: "", typedCountry: "", countryObj: countryOptions[0],
            ValidatedWo: CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("validatedWO") != "error" && CookieService.getLocalStorageValues("validatedWO") != "blockpopuprepeat" && CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != "null" ? CookieService.getLocalStorageValues("validatedWO") : "",//[05/05/23, PS_WVF_05, Zeus 86, Sathis. N]-work order validation- stanford
            stanfordAssetId: CookieService.getLocalStorageValues("stanfordAssetId") != "" && CookieService.getLocalStorageValues("stanfordAssetId") != null && CookieService.getLocalStorageValues("stanfordAssetId") != undefined ? CookieService.getLocalStorageValues("stanfordAssetId"):"",
            isAllItem_are_Pickup: false, pickup_vendor: [], PickupStore: [], pickupcheck: false, PickupStoreSelected: [], PickupVendorID: "",
            pickupstore_tbl: [], PickupVendor: "", GraingerStore: [], GraingerQTY: [], lowesitem: false, Graingermsg: [], Pick: [], zipCodeValidation: "",
            category: [], bindCategory: [], categoryError: "", FavYourSubCatgPopup: "", subCategory: [], bindSubCategory: [], subCategoryError: "", lineLevelEmp: "", EmplyeeNameDD: "", IsMultipleSupplier: "",
            isDefAddressChckd: false, existStreet: "", existState: "", existCity: "", existCountry: "", existPostal: "", isExistingAddress: false, userDefaultAddress: "",
            clearCart: "false", EmpIDDisable: false, suggestionWo: [], DeliveryPointFlag: false, DeliveryPointDropdown: [], suggestionWo: [],
            workOrderDescription: CookieService.getLocalStorageValues("workOrderDescription") != "" && CookieService.getLocalStorageValues("workOrderDescription") != null && CookieService.getLocalStorageValues("workOrderDescription") != undefined ? CookieService.getLocalStorageValues("workOrderDescription") : "",
            disableWo: CookieService.getLocalStorageValues("workOrderDisable") != "" && CookieService.getLocalStorageValues("workOrderDisable") != null && CookieService.getLocalStorageValues("workOrderDisable") != undefined ? CookieService.getLocalStorageValues("workOrderDisable") : false,
            existApartmentNo: "", apartmentNoValue: "", typedApartmentNo: "", isRecAddressSelected: false, isUserAddressSelected: false,
            responseIsValidStreet: "", responseIsValidCity: "", responseIsValidState: "", responseIsValidZipCode: "", responseIsValidCountryCode: "",
            responseTypedStreet: "", responseTypedCity: "", responseTypedState: "", responseTypedZipCode: "", responseTypedCountry: "",
            isEMPDDCh: false, Supplier_ID: "",
            woStyle: "",//[09/13/23, PS_ZE_196_APP_01,02, Sathis N],To change delivery point and notes as consistent
            OLEmployeeCCErr: false, OLDueDateErr: false, OLShipToErr: false, DeliveryPointErr: false,
            ChargeCodevalidateErr: false, PickupStoreErr: false, userAddressErr: false, customerNotes: CookieService.getLocalStorageValues("Session_Customer_Notes") != undefined && CookieService.getLocalStorageValues("Session_Customer_Notes") != "" ? CookieService.getLocalStorageValues("Session_Customer_Notes") : "",
            OLDeliveryPoint: CookieService.getLocalStorageValues("Session_OLDeliveryPoint") != undefined && CookieService.getLocalStorageValues("Session_OLDeliveryPoint") != "" ? CookieService.getLocalStorageValues("Session_OLDeliveryPoint") : [],     //[09/13/23, PS_ZE_196_APP_01,02, Sathis N],To change delivery point and notes as consistent
            favOrdDes: "", favOrdCat: "", favOrderCatSugg : [],
            favOrderCatObj: [],
            favOrdsubCategorySugg: [], favOrdsubCategoryObj: [], favOrdSubCat: "",
            attachmentPathArrImg: "", notesErr: false,
            notesReq: CookieService.getLocalStorageValues("Session_OLDeliveryPoint") && CookieService.getLocalStorageValues("Session_OLDeliveryPoint").notesReq == "Y" ? true : false, notesErrMsg: "",
            isPageLoad: false,

        }
    }
    /*
     * RF No: SC_PC_07
     * componentwillmount is the method which is loaded and exceute first.
     * When the Shoppingcart page loaded.
     * */
    componentDidMount() {
        //[11/30/21, PC_SCT_01, CAS - 31], Poornima S//Added translation keywords for alerts, error messages
        const { t } = this.props;
        /*
         *  RF No: SC_PC_08
         *  LoadCartItem is the method used to get the pageload data from the database.
         * */
        debugger
        //***NEW***ADDED**
        //let urlParam = new URLSearchParams(window.location.search);
        //let CartpaymentEnable = urlParam.get('CartPayment');
        //console.log("url", urlParam);
        //if (CartpaymentEnable != null && CartpaymentEnable != undefined) {
        //    $('#OrderlevelDiv').removeClass('d-none');
        //    this.setState({ CerditCardEnabled: true });           
        //}
        //****END*****
        //[5/7/23, PC_ZE_85_APP_01, Dhevanesam R]
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getLatLon);
            function getLatLon(position) {
                /*[03/10/22, PC_01,A1QA-145], Priyanka D */
                CurrentLatitude = position.coords.latitude;
                CurrentLongitude = position.coords.longitude;
            }
        } else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please allow the website to access your location!!"
            });
            return;
        }
        console.log("CurrentLatitude", CurrentLatitude);
        console.log("CurrentLongitude", CurrentLongitude);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        //let BlockCart = CookieService.getLocalStorageValues("Session_ISABlockShoppingCart")
        if (CookieService.getLocalStorageValues("Session_ISABlockShoppingCart") != "Y" && CookieService.getLocalStorageValues("Session_ISABlockShoppingCart") != "" && CookieService.getLocalStorageValues("Session_ISABlockShoppingCart") != null && CookieService.getLocalStorageValues("Session_ISABlockShoppingCart") != "y") {
            let termcondition = CookieService.getLocalStorageValues("sample_termsaccepted")
            if (termcondition == false) {
                //alert(t("Please agree terms and conditions to access shopping cart"));
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Please agree terms and conditions to access shopping cart")
                }).then(() => {
                    this.props.history.push({
                        pathname: "/HomePage",
                    });
                });
            }
            else {
                this.LoadCartItem()
            }

        }
        else {

            //alert("Please agree terms and conditions to access shopping cart");
            //'*******************12/18/2020*********************
            if (CookieService.getLocalStorageValues("Session_PUNCHIN") != "YES") {

                this.props.history.push({
                    pathname: `\HomePage`,
                    search: `?GetAccess21=NoShopCartAccessForCust`
                });
            }
            else {
                this.LoadCartItem()
            }

        }
        let cachedURL = sessionStorage.getItem("serviceURL");
        let Url = ""
        if (!cachedURL || cachedURL == "") {
            $.ajax({
                type: "GET",
                url: SDIConstants.localService
            }).then((data) => {
                this.setState({ apiURL: data });
                //.log("ThroughCall", data);
                sessionStorage.setItem("serviceURL", data);
            });

        } else {
            Url = sessionStorage.getItem("serviceURL");
            //.log("ThroughCache", sessionStorage.getItem("serviceURL"));
            this.setState({ apiURL: sessionStorage.getItem("serviceURL") });
        }
        debugger
        //this.TestCerditCard();
        var today = new Date();
        var date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        var time = today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
        var dateTime = date.toString() + time.toString();
        /*
         * RF No: SC_PC_10
         * This emit call is done when the Getcartitem action has the response.
         * */
        ShoppingCartStore.on("Cartitem", this.BuildShoppingCartPage);
        /*
         * RF No: SC_PC_11
         * This emit call is done when the UpdateItemDesp action has the response.
         * Item level Details filled or updateed.
         * */
        ShoppingCartStore.on("UpdateStatus", this.UpdateItemStatus.bind(this));
        /*
         * This emit call is done when the UpdateCartEmpCC action has the response.
         * Order level Employee CC or Due date is filled.
         * */
        ShoppingCartStore.on("UpdateStatusOL", this.UpdateitemStatusOL.bind(this));
        /*This emit call is done when the quick item or favorite menu item is filled or click for adding item to the cart.
         */
        ShoppingCartStore.on("quickItemCheck", this.loadQuickItem);
        /*
         * This emit call is done when the GetAddFavItemDetail action has the response.
         * */
        ShoppingCartStore.on("FavItemDetails", this.BindAddFavPopUp.bind(this));
        /*
         * This emit call is done when the updateFavsMenu action has the response.
         * */
        ShoppingCartStore.on("FavItemUpdate", this.closeFavPopup);
        /*
         * RF No: SC_PC_12
         * This emit call is done when the DeleteCartItem action has the response.
         * */
        ShoppingCartStore.on("deleteItem", this.DeleteItemStatus);
        /*
         * RF No: SC_PC_13
         * This emit call is done when the SubmitOrder action has the response.
         * */
        ShoppingCartStore.on("PlaceOrder", this.OrderPlacedStatus);
        /* This emit call is done when the order pdf is generate and return from the API throught Action.
         */
        ShoppingCartStore.on("OrderPDF", this.OrderPDFShow.bind(this));
        /*
         * This emit calls are reused from the Non catalog page.
         * During the Attachment bind or remove.
         * */
        ShoppingCartStore.on('attachmentResponse', this.loadAttachments);
        ShoppingCartStore.on('rmvAttach', this.removeAttachmentResponse);
        /*
         * RF No: SC_PC_14
         * This emit call is done when the CancelOrderItem action has the response.
         * */
        ShoppingCartStore.on('CancelOrder', this.COStatus);
        /*
        * Favorite page response listeners
        */
        ShoppingCartStore.on('favpage', this.loadFavPageResponse);
        ShoppingCartStore.on('updateFavPage', this.updateFavPageResponse);
        ShoppingCartStore.on('TaxValue', this.TaxvalueBind);
        ShoppingCartStore.on('WalletValue', this.WalletBind.bind(this));
        ShoppingCartStore.on('RemoveCardValue', this.RemoveCardBind.bind(this));
        ShoppingCartStore.on('ProcessPaymentBO', this.PaymentResponse);
        ShoppingCartStore.on('AddCardDetailsBO', this.AddCardDetails);
        ShoppingCartStore.on('GetchargeCOdeBO', this.buildChargeCode);
        /*
         * [3/15/2023]CAS-44 This emit call is done when the ValidateAddress action has the response.
         * */
        ShoppingCartStore.on('ValidAddress', this.validateAddressResponse);
        ShoppingCartStore.on('favOrderAttachment', this.loadAttachmentsImg);
        ShoppingCartStore.on('favOrderRemoveAttachments', this.favOrderremoveAttachmentResponse);
        //[5/7/23, PC_ZE_85_APP_15, Dhevanesam R]
        ShoppingCartStore.on('grainger', this.Graingerdata);
        ShoppingCartStore.on('SetDefaultAddress', this.DefaultAddressResp);
        ShoppingCartStore.on('workorderValidate', this.woValidateResponse);
        FavItemMainStore.on('DeleteItem', this.DelFavResponse);

    }
    componentWillUnmount() {
        /*
         * RF No: SC_PC_10
         * This emit call is done when the Getcartitem action has the response.
         * */
        ShoppingCartStore.removeListener("Cartitem", this.BuildShoppingCartPage);
        /*
         * RF No: SC_PC_11
         * This emit call is done when the UpdateItemDesp action has the response.
         * Item level Details filled or updateed.
         * */
        ShoppingCartStore.removeListener("UpdateStatus", this.UpdateItemStatus.bind(this));
        /*
         * This emit call is done when the UpdateCartEmpCC action has the response.
         * Order level Employee CC or Due date is filled.
         * */
        ShoppingCartStore.removeListener("UpdateStatusOL", this.UpdateitemStatusOL.bind(this));
        /*This emit call is done when the quick item or favorite menu item is filled or click for adding item to the cart.
         */
        ShoppingCartStore.removeListener("quickItemCheck", this.loadQuickItem);
        /*
         * This emit call is done when the GetAddFavItemDetail action has the response.
         * */
        ShoppingCartStore.removeListener("FavItemDetails", this.BindAddFavPopUp.bind(this));
        /*
         * This emit call is done when the updateFavsMenu action has the response.
         * */
        ShoppingCartStore.removeListener("FavItemUpdate", this.closeFavPopup);
        /*
         * RF No: SC_PC_12
         * This emit call is done when the DeleteCartItem action has the response.
         * */
        ShoppingCartStore.removeListener("deleteItem", this.DeleteItemStatus);
        /*
         * RF No: SC_PC_13
         * This emit call is done when the SubmitOrder action has the response.
         * */
        ShoppingCartStore.removeListener("PlaceOrder", this.OrderPlacedStatus);
        /* This emit call is done when the order pdf is generate and return from the API throught Action.
         */
        ShoppingCartStore.removeListener("OrderPDF", this.OrderPDFShow.bind(this));
        /*
         * This emit calls are reused from the Non catalog page.
         * During the Attachment bind or remove.
         * */
        ShoppingCartStore.removeListener('attachmentResponse', this.loadAttachments);
        ShoppingCartStore.removeListener('rmvAttach', this.removeAttachmentResponse);
        /*
         * RF No: SC_PC_14
         * This emit call is done when the CancelOrderItem action has the response.
         * */
        ShoppingCartStore.removeListener('CancelOrder', this.COStatus);
        /*
        * Favorite page response listeners
        */
        ShoppingCartStore.removeListener('favpage', this.loadFavPageResponse);
        ShoppingCartStore.removeListener('updateFavPage', this.updateFavPageResponse);
        ShoppingCartStore.removeListener('TaxValue', this.TaxvalueBind);
        ShoppingCartStore.removeListener('WalletValue', this.WalletBind.bind(this));
        ShoppingCartStore.removeListener('RemoveCardValue', this.RemoveCardBind.bind(this));
        ShoppingCartStore.removeListener('ProcessPaymentBO', this.PaymentResponse);
        ShoppingCartStore.removeListener('AddCardDetailsBO', this.AddCardDetails);
        ShoppingCartStore.removeListener('GetchargeCOdeBO', this.buildChargeCode);
        ShoppingCartStore.removeListener('ValidAddress', this.validateAddressResponse);
        ShoppingCartStore.removeListener('grainger', this.Graingerdata);
        ShoppingCartStore.removeListener('SetDefaultAddress', this.DefaultAddressResp);
        ShoppingCartStore.removeListener('workorderValidate', this.woValidateResponse);
        ShoppingCartStore.removeListener('favOrderAttachment', this.loadAttachmentsImg);
        ShoppingCartStore.removeListener('favOrderRemoveAttachments', this.favOrderremoveAttachmentResponse);
        tempEMPEmail = "";
        FavItemMainStore.removeListener('DeleteItem', this.DelFavResponse);
    }
    /*
     * RF No: SC_PC_14
     * This method is invoked inside the compouentwillmount.
     * Action method Getcartitem is called to get the page load response of the shopping cart page from the API throught Action.
     * Action method getCurrentFavPage is called to get the page favorite status.
     * */


    LoadCartItem = () => {
        /**
        * Invoke the action to check the current page is favorited
        */
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        currentPagePath = this.props.location.pathname;
        //.log("currentPagePath", currentPagePath)
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: this.state.Login_UserId,
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        }
        ShoppingCartAction.getCurrentFavPage(getFavPageBO);
        /*
         * Object used to get the page load data.
         * */
        // $(`#${SDIConstants.SDILoader}`).removeClass('hide');

        let GetCartBO = {
            userID: this.state.Login_UserId,
            userBU: this.state.Login_UserBU,
            //[4/12/2023, PS_SP_PL_APP_02, Dhevanesam R]
            punchinSessionID: (CookieService.getLocalStorageValues("Session_PUNCHINID") != null && CookieService.getLocalStorageValues("Session_PUNCHINID") != undefined && CookieService.getLocalStorageValues("Session_PUNCHINID") != "" && CookieService.getLocalStorageValues("Session_PUNCHINID") != " " ? CookieService.getLocalStorageValues("Session_PUNCHINID") : ""),
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
            Session_EMAIL: CookieService.getLocalStorageValues("Session_EMAIL"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
            Session_ACTIVEUSERS: CookieService.getLocalStorageValues("Session_ACTIVEUSERS"),
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            CurrentPage_Url: "/SDIXZEUS/ShoppingCart",
            Session_PARTLIST: CookieService.getLocalStorageValues("Session_PARTLIST"),
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_CARTFAIL: "N",
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
            //[07/04/23,PS_ZEUS_127_APP_06, Johnprinto D]
            Session_HOMESTORE: CookieService.getLocalStorageValues("Session_HOMESTORE")
        }
        ShoppingCartAction.Getcartitem(GetCartBO)
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }

    //This method is to calculate the date after 5 business days and prefill them in Delivery date
    calcWorkingDays = (shipwithin) => {
        let count = 0;
        let today = new Date()
        while (count < shipwithin) {
            today.setDate(today.getDate() + 1);
            if (today.getDay() != 0 && today.getDay() != 6) // Skip weekends
                count++;
        }
        let td = this.handledate(today);
        return td
    }

    //This method is to calculate the date after 5 business days and to highlight that date in calendar
    calcWorkingDaysdrop = (shipwithin) => {
        let count = 0;
        let today = new Date()
        while (count < shipwithin) {
            today.setDate(today.getDate() + 1);
            if (today.getDay() != 0 && today.getDay() != 6) // Skip weekends
                count++;
        }
        return today
    }

    /*
     * RF No: SC_PC_19 && RF No: SC_PC_20 && RF No: SC_PC_21
     * All the Page load hide and show based on the flags is hadled in this method.
     * State variables are set by the response value.
     * */
    BuildShoppingCartPage = () => {
        const { t } = this.props;//[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
        //[6/3/23 - ZEUS-116 - Can't able to place an item from Grainger site - 3625 - Bug fix - Johnprinto D]
        MenuAction.refreshMasterData();
        window.scrollTo(0, 0);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let PageLaod_Data = JSON.parse(ShoppingCartStore.CartitemList);
        let { showprice } = this.state;
        let SiteCurrency = PageLaod_Data.siteCurrencyTable;
        let IsAEESvalue = PageLaod_Data.IsAEESValue;
        let PunchInFlagTable = PageLaod_Data.PunchInFlagTable;
        let showShiptoTable = PageLaod_Data.showShipto
        let HashTable = PageLaod_Data.Hashtable;
        let ShipDD = [];
        ShipDD = PageLaod_Data.ShipToDD;
        let Update_Session = PageLaod_Data.Update_Session;
        let FlagTable = PageLaod_Data.FlagTable;
        let WordOrderNoLengthLocal = 7;
        let hdfZeusNonCataLocal = "N";
        let hdfChangeLineNumLocal = "N";
        let OLEmplyIdLabelLocal = "*Employee ID";
        let OLShipToIdLabelLocal = "*Ship To";
        let MultiCHargeCOde = false
        let displayShipTo;
        let ShoppingCartPrilg;
        //[4/12/2023, PS_SP_PL_APP_03, Dhevanesam R]
        if (HashTable != null && HashTable != "" && HashTable != undefined) {
            if (HashTable.length > 0) {
                ShoppingCartPrilg = HashTable.filter((item, index) => item.Key == "ADDCART");
            }
        }
        let PunchinUser = CookieService.getLocalStorageValues("Session_PUNCHINID") != null && CookieService.getLocalStorageValues("Session_PUNCHINID") != undefined && CookieService.getLocalStorageValues("Session_PUNCHINID") != "" && CookieService.getLocalStorageValues("Session_PUNCHINID") != " " ? true : false
        /*[10/28/21, PC_SC_02 CAS - Alert- Mobile Issuing program to request stock], Priyanka D*/
        if (FlagTable != null && FlagTable != "" && FlagTable != undefined) {
            if (FlagTable.length > 0) {
                if (FlagTable[0].AlertMessage != "" && FlagTable[0].AlertMessage != null && FlagTable[0].AlertMessage != undefined) {
                    //alert(FlagTable[0].AlertMessage);
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(FlagTable[0].AlertMessage)
                    })
                }
            }
        }
        let termcondition = CookieService.getLocalStorageValues("sample_termsaccepted")
        if (ShoppingCartPrilg.length == 0 && termcondition != false) {
            /*alert(t("Insufficient privileges for adding to cart"));
            this.props.history.push({
                pathname: "/HomePage",
            });*/
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Insufficient privileges for adding to cart")
            }).then(() => {
                this.props.history.push({
                    pathname: '/HomePage',
                });
            });
            return;
        }

        if (FlagTable) {
            if (FlagTable != null && FlagTable != "" && FlagTable != undefined) {
                if (FlagTable.length > 0) {
                    if (FlagTable[0].FlagValue == "Y" && CookieService.getLocalStorageValues("Session_PUNCHIN") != "YES") {
                        /*alert(t("You are not allowed to use this screen here \n you must login from SAP"));
                        this.props.history.push({
                            pathname: '/HomePage',
                        });*/
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: t("You are not allowed to use this screen here \n you must login from SAP")
                        }).then(() => {
                            this.props.history.push({
                                pathname: '/HomePage',
                            });
                        });
                        return;

                    }
                }
            }
        }
        if (Update_Session != null && Update_Session != "") {
            if (Update_Session.length > 0) {
                if (Update_Session[0].Session_TESTMODE != null && Update_Session[0].Session_TESTMODE != "") {
                    CookieService.updateLocalStorageValues("Session_TESTMODE", Update_Session[0].Session_TESTMODE);
                }
                if (Update_Session[0].Session_CartFail != null && Update_Session[0].Session_CartFail != "") {
                    CookieService.updateLocalStorageValues("Session_CartFail", Update_Session[0].Session_CartFail);
                }
                if (Update_Session[0].dtCM != null && Update_Session[0].dtCM != "") {
                    CookieService.updateLocalStorageValues("Session_dtCM", Update_Session[0].dtCM);
                }
                if (Update_Session[0].Session_gotNSTK != null && Update_Session[0].Session_gotNSTK != "") {
                    CookieService.updateLocalStorageValues("Session_gotNSTK", Update_Session[0].Session_gotNSTK);
                }
                if (Update_Session[0].Session_gotStock != null && Update_Session[0].Session_gotStock != "") {
                    CookieService.updateLocalStorageValues("Session_gotStock", Update_Session[0].Session_gotStock);
                }
                if (Update_Session[0].ChargeCode_Enable != null && Update_Session[0].ChargeCode_Enable != "") {
                    if (Update_Session[0].ChargeCode_Enable.toUpperCase() != "FALSE") {
                        debugger
                        MultiCHargeCOde = true
                    }
                }
            }

        }
        let MAchineNumber = true
        if (PunchinUser == true) {
            $('#CustomerOrderNo').addClass("d-none");
            $('#EditFavButton').addClass("d-none");
            //$('#PanelChargeCOde').addClass("d-none");
            MAchineNumber = false
            $('#CusNotes').addClass("d-none");
            $('#MachinenumberDiv').addClass("d-none");
            $('#TypeDIv').addClass("d-none");
            $('#NotesLAbel').addClass("d-none");
            $('#EmployeeIDDIV').addClass("d-none");
            $('#OrderShipTo').addClass("d-none");
            displayShipTo = false;
            if (this.state.Login_UserBU == "I0A26" || this.state.Login_UserBU == "I0A27" || this.state.Login_UserBU == "I0A28") {
                $('#OrderShipTo').removeClass("d-none");
                displayShipTo = true;
            }
        }
        //.log("ShoppingCartPrilg", ShoppingCartPrilg);
        if (this.state.Login_UserBU == "I0601" || this.state.Login_UserBU == "I0602") {
            if ((CookieService.getLocalStorageValues("Session_BuyerCookie") == null || CookieService.getLocalStorageValues("Session_BuyerCookie") == undefined) && PunchinUser == true) {
                if (CookieService.getLocalStorageValues("Session_BuyerCookie") == "" && PunchinUser == true) {
                    $('#PanelChargeCOde').addClass("d-none");
                }
            }
            else {
                $('#PanelChargeCOde').removeClass("d-none");
            }
        }

        //document.getElementById("CartCount").innerHTML = PageLaod_Data.CartItems.length;
        let ManOrno = CookieService.getLocalStorageValues("Session_MANORDNO")
        if (ManOrno != " " && ManOrno != null) {
            if (CookieService.getLocalStorageValues("Session_MANORDNO") == "A") {
                WordOrderNoLengthLocal = 10;
            }
            if (CookieService.getLocalStorageValues("Session_ORDLNRENUM") == "Y") {
                hdfChangeLineNumLocal = "Y"
            }
        }
        if (CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE") == "Y" && CookieService.getLocalStorageValues("Session_ZEUS_SITE") == "Y") {

            hdfZeusNonCataLocal = "Y"
        }
        debugger
        const queryParams = new URLSearchParams(window.location.search)
        const QS_src = queryParams.get('SearchKey');
        //[3/17/2023, PS_SR_URL_APP_13 - To remove catalog look up tree, Dhevanesam R]
        //const QS_unspsc = queryParams.get('unspsc');//[7/20/2022] Bug - 2357 Getting the unspsc & CatTree query param & storing it in local variable - Poornima S
        //const QS_CatTree = queryParams.get('CatTree');
        const CartpaymentEnable = queryParams.get('CartPayment')
        //.log("Session_SearchValue", QS_src)
        Searchkeywoed = QS_src
        //SearchUnspsc = QS_unspsc
        //SearchCatTree = QS_CatTree
        CookieService.updateLocalStorageValues("Session_SearchValue", "");
        if (SiteCurrency[0].length != 0) {
            CookieService.updateLocalStorageValues("SESSION_SITE_CURRENCY", SiteCurrency);
        }
        if (IsAEESvalue.length != 0) {
            OLEmplyIdLabelLocal = "Requisitioner Name"
        }
        //[4/12/2023, PS_SP_PL_APP_03, Dhevanesam R]
        if (PunchInFlagTable != null && PunchInFlagTable != undefined) {
            if (PunchInFlagTable.length != 0) {
                CookieService.updateLocalStorageValues("Session_Punchin", PunchInFlagTable.Session_PunchIn);
                if (PunchInFlagTable.WorkOrderA != null) {
                    //$('#OLWorkOrderTB').val(PunchInFlagTable.WorkOrderA)
                    this.setState({ ValidatedWo: PunchInFlagTable.WorkOrderA });
                }
            }
        }
        if (CookieService.getLocalStorageValues("Session_SDIEMP") == "SDI" && CookieService.getLocalStorageValues("Session_AGENTUSERID") == "") {
            //[9/2/22, Bug - 2532, PC_SC_01 Dhevanesam R]
            OLEmplyIdLabelLocal = "*Employee ID (by name)" //[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
        }
        //[4/12/2023, PS_SP_PL_APP_03, Dhevanesam R]
        //if ((CookieService.getLocalStorageValues("Session_ShipToFlag") == "Y" && showShiptoTable[0].Show == "true") || ShipDD.length > 1) {
        //    //[9/2/22, Bug - 2532, PC_SC_01 Dhevanesam R]
        //    OLShipToIdLabelLocal = "*Ship To"//[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
        //}
        let Defaultship = CookieService.getLocalStorageValues("Session_ISAShipToID") == null || CookieService.getLocalStorageValues("Session_ISAShipToID") == "" || CookieService.getLocalStorageValues("Session_ISAShipToID") == " " ? "0" : CookieService.getLocalStorageValues("Session_ISAShipToID");
        //.log(Defaultship)
        //There were around 50,000 machine number was found for doyles1 account need to check that for temp I'm concating the length to 100
        let machinenumdata = [];

        if (PageLaod_Data.MachineNum) {
            if (PageLaod_Data.MachineNum != null && PageLaod_Data.MachineNum != undefined) {
                if (PageLaod_Data.MachineNum.length > 0) {
                    if (PageLaod_Data.MachineNum.length > 1000) {
                        PageLaod_Data.MachineNum.length = 1000;
                        machinenumdata = PageLaod_Data.MachineNum;
                        machinenumdata = machinenumdata.sort(function (x, y) {
                            return x.ISA_MACHINE_NO - y.ISA_MACHINE_NO;
                        });
                        // machinenumdata.sort(function (a, b) {return a-b})
                    } else {
                        machinenumdata = PageLaod_Data.MachineNum;
                        machinenumdata = machinenumdata.sort(function (x, y) {
                            return x.ISA_MACHINE_NO - y.ISA_MACHINE_NO;
                        });
                        // machinenumdata.sort(function (a, b) {return a-b})
                    }
                }
                else {
                    // $('#MachinenumberDiv').addClass("d-none");
                    MAchineNumber = false
                }
            }
            else {
                // $('#MachinenumberDiv').addClass("d-none");
                MAchineNumber = false
            }
        }
        else {
            // $('#MachinenumberDiv').addClass("d-none");
            MAchineNumber = false
        }
        let FavmenuDD = PageLaod_Data.FavMenu
        let filteredFavmenuDD = []
        if (FavmenuDD != null && FavmenuDD != undefined) {
            if (FavmenuDD.length > 0) {
                filteredFavmenuDD = FavmenuDD.filter((v, i, a) => a.findIndex(t => (t.FavText.trim().toUpperCase() === v.FavText.trim().toUpperCase())) === i)
            }
        }
        let CartitemList = PageLaod_Data.CartItems
        //let Employeedata = PageLaod_Data.EmployeeCC
        let Loginempolyeeid = {
            ISA_EMPLOYEE_NAME: CookieService.getLocalStorageValues("Session_USERNAME"),
            EMP_ID: this.state.Login_UserId
        }
        let employidDrop = PageLaod_Data.EmployeeCC
        //employidDrop.push(Loginempolyeeid)
        let employidDropDown = employidDrop.filter((v, i, a) => a.findIndex(t => (t.EMP_ID.trim().toUpperCase() === v.EMP_ID.trim().toUpperCase())) === i)
        let Empolyeevalue = []
        /*if (employidDropDown != null && employidDropDown != undefined) {
            Empolyeevalue = employidDropDown.filter((iresult, idx) => iresult.EMP_ID.toUpperCase() == this.state.OrderEmpyId.toUpperCase())
        }*/
        //[Zeus 130, 6/8/23] - Kishore S - If not a supervisor role empId will be disabled
        let EmpAvail = false;
        employidDropDown.map((val) => {
            if (val.EMP_ID == this.state.Login_UserId) {
                EmpAvail = true;
            }
        })

        this.EmployeeDD(employidDropDown);
        //.log("Empolyeevalue", Empolyeevalue)
        if (CartitemList != null && CartitemList != undefined) {
            if (CartitemList.length > 0) {
                var l = 0
                if (Empolyeevalue != "" && Empolyeevalue != null) {
                    if (Empolyeevalue.length > 0) {
                        for (l = 0; l < CartitemList.length; l++) {

                            if (CartitemList[l].EmpChgCode == null || CartitemList[l].EmpChgCode == "" || CartitemList[l].EmpChgCode == "0" || CartitemList[l].EmpChgCode == " ") {
                                CartitemList[l].EmpChgCode = this.state.OrderEmpyId
                            }
                        }
                    }
                }
            }
        }
        let OnlyCatalogitemdata = true
        CartitemList.map((val, index) => {
            let NonCat = val.Itemid.slice(0, 6)
            if (NonCat.toUpperCase() == "NONCAT") {
                OnlyCatalogitemdata = false
            }
        })
        let today = ""
        let dateOL = ""
        //today = new Date();

        if (CookieService.getLocalStorageValues("Session_DeliveryDateFlag") != "Y") {
            today = this.calcWorkingDaysdrop(5);
            dateOL = this.calcWorkingDays(5);
        }
        /*[11/08/21, PC_SC_01], Priyanka D, Binding Line level delivery date when order level delivery date is filled for punchin users*/
        //[5/92023, PS_ZE_101_01, Dhevanesam R]
        if (PunchinUser == true) {
            if (CartitemList != null && CartitemList != undefined) {
                if (CartitemList.length > 0) {
                    if (today != "" && today != null) {
                        for (l = 0; l < CartitemList.length; l++) {
                            if (CartitemList[l].UDueDate == null || CartitemList[l].UDueDate == "" || CartitemList[l].UDueDate == " ") {
                                CartitemList[l].UDueDate = this.handledate(today);
                            }
                        }
                    }
                }
            }
        }
        //.log("dateOL", dateOL)
        //.log("today", today)
        let ShiptoValue = []
        let PerferedShipto = {
            value: "0",
            label: t("Select Ship To")//[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
        }
        debugger
        if (this.state.showShipto) {
            let OrderShipToClass = document.getElementById('OrderShipTo').className;
            if (PageLaod_Data.ShipToDD != null && PageLaod_Data.ShipToDD != undefined) {
                if (PageLaod_Data.ShipToDD.length > 0) {
                    PageLaod_Data.ShipToDD.map((val, index) => {
                        if (index == 0) {
                            let defaultvalue = {
                                value: "0",
                                label: t("Select Ship To")//[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
                            }
                            ShiptoValue.push(defaultvalue)
                        }
                        let localArray = {
                            value: val.CUSTID,
                            label: val.LOCNAME
                        }
                        if (OrderShipToClass == "d-none" && index == 0 && Defaultship == "0") {
                            PerferedShipto = localArray
                        }
                        if (val.CUSTID == Defaultship) {
                            PerferedShipto = localArray
                        }
                        if (PageLaod_Data.ShipToDD.length == 1) {
                            PerferedShipto = localArray
                        }
                        ShiptoValue.push(localArray)
                    });
                }
            }
            debugger
            if (ShiptoValue.length < 3) {
                $('#OrderShipTo').addClass("d-none");
                displayShipTo = false;
            }
            // PS_SC_ST_01 //[05/03/23, PS_SC_ST_01, 3419], Sathis N//Added condition for display shipto field case of default shipto available
            if (PerferedShipto.value != "0") {
                debugger
                $('#OrderShipTo').removeClass("d-none");
                displayShipTo = true;
            }
        }

        let CartItemQuantity = []
        if (CartitemList != null && CartitemList != undefined) {
            if (CartitemList.length > 0) {
                CartitemList.map((val, index) => {
                    let localitem = {
                        Itemid: val.Itemid,
                        Quantity: val.Quantity,
                        UniqNum: val.UniqNum,
                    }
                    CartItemQuantity.push(localitem)
                })
            }
        }
        //.log("CartItemQuantity", CartItemQuantity)
        let WorkOrderTitle = this.state.WorkOrderTitle
        if (CookieService.getLocalStorageValues("Session_WORKORDER") == "Y") {
            if (CookieService.getLocalStorageValues("Session_CARTWOREQ") != null && CookieService.getLocalStorageValues("Session_CARTWOREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTWOREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTWOREQ") == "y" || CookieService.getLocalStorageValues("Session_CARTWOREQ") == "Y") {
                    WorkOrderTitle = "*Work Order #"
                }
            }
        }
        //[15/05/23, PS_EWVB_07, Zeus 112, Sathis. N]- add work order field as mandatory field
        //[05/17/2024, Zeus 307] - Use cart work order required flag for mandatory symbol - Kishore
        if (CookieService.getLocalStorageValues("Session_CARTWOREQ") == "Y") {
            WorkOrderTitle = "* Work Order#"
        }
        let ChargeCodeTitle = this.state.ChargeCodeTitle
        if (CookieService.getLocalStorageValues("Session_CHARGECODE") == "Y") {
            if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != null && CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != "" && CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != " ") {
                    if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == 1
                        || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == "y" || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == "Y") {
                        ChargeCodeTitle = "*Order Charge Code";   //[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords                  
                    }
                }
            }
        }
        //[5/7/23, PC_ZE_85_APP_02, Dhevanesam R]
        debugger
        let pickuploc = "";
        let loclowesitem = false;
        let puchoutcount = 0;
        let disableShiptobol = "";
        if (CartitemList != null && CartitemList != undefined) {
            if (CartitemList.length > 0) {
                CartitemList.map((val, index) => {
                    let localitem = {
                        Itemid: val.Itemid,
                        Quantity: val.Quantity,
                        UniqNum: val.UniqNum,
                    }
                    debugger
                    if (val.PaintColor == "PUNCHOUT" && (val.SUPPLIERNAME.trim().toUpperCase().includes("LOWE'S") || val.SUPPLIERNAME.trim().toUpperCase().includes("HOME DEPOT"))) {
                        puchoutcount = puchoutcount + 1;
                    }
                    CartItemQuantity.push(localitem)
                })
                if (CartitemList.length == puchoutcount) {
                    if (this.state.CartCOunt == "0") {
                        pickuploc = false;
                        disableShiptobol = false;

                    } else {
                        pickuploc = true;
                        loclowesitem = true;
                        disableShiptobol = true;
                    }
                }
                else {
                    pickuploc = false;
                    disableShiptobol = false;
                }
            }
            else {
                pickuploc = false;
                disableShiptobol = false;
            }
        }
        let cartdata = CartitemList;
        let pickupdata = PageLaod_Data.Pickup_VendorList;
        let pickupcount = 0;
        let pickupcheckbox = false;
        let pickupvendor = "";
        let pickupvendorid = "";
        console.log("pickupdata", pickupdata)
        debugger
        if (pickupdata.length != 0 && cartdata.length != 0) {
            pickupdata.forEach((val, index) => {
                CartitemList.filter((x) => x.SupplierID == CartitemList[0].SupplierID && val.VENDOR_ID == x.SupplierID).map((i) => { pickupcount += 1; })
            })

            if (PageLaod_Data.CartItems.length == pickupcount) {
                pickupcheckbox = true;
                pickupdata.map((val, index) => {
                    if (val.VENDOR_ID == cartdata[0].SupplierID) {
                        pickupvendor = val.NAME1;
                        pickupvendorid = val.VENDOR_ID;
                    }
                });
            }
        }
        let pickupstore = [];
        if (PageLaod_Data.Pickup_store_tbl && pickupvendor != "") {
            PageLaod_Data.Pickup_store_tbl.map((storeval, index) => {
                if (storeval.NAME1.toUpperCase() == pickupvendor.toUpperCase()) {
                    let localArray = {
                        ADDRESS3: storeval.VNDR_LOC,
                        VNDR_LOC: storeval.VNDR_LOC,
                        ADDRESS1: storeval.ADDRESS1,
                        CITY: storeval.CITY,
                        STATE: storeval.STATE,
                        POSTAL: storeval.POSTAL,
                        PHONE: storeval.PHONE,
                        NAME1: storeval.NAME1,
                        LATITUDE: storeval.LATITUDE,
                        LONGITUDE: storeval.LONGITUDE
                    }
                    pickupstore.push(localArray)
                }
            })
        }
        let allCategory = []
        let categories = CookieService.getLocalStorageValues("FavItem_Cat")
        if (categories != null && categories != "" && categories != undefined) {
            categories.map((val, index) => {
                allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
            });
            allCategory = allCategory.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }
        console.log("shopping catagory", allCategory);
        let subCategory = []
        let allSubCategory = []
        let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
        if (subCategories != null && subCategories != "" && subCategories != undefined) {
            subCategories.map((val, index) => {
                if (val.ISA_FAVS_SUBCATEGORY != null) {
                    subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                }
            });
        }
        if (subCategory.length > 0) {
            allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
            allSubCategory = allSubCategory.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }

        let userDefAddress = "";
        let street = "";
        let state = "";
        let city = "";
        let country = "";
        let postal = "";
        let apartmentNo = "";
        let existAddress = false;

        debugger
        let UserAddresssArr = PageLaod_Data.UserAddress;
        if (UserAddresssArr != undefined && UserAddresssArr != null) {
            if (UserAddresssArr.length > 0) {
                let concateApartmentNo = UserAddresssArr[0].ADDRESS2 != null && UserAddresssArr[0].ADDRESS2.trim() != "" ? ", " + UserAddresssArr[0].ADDRESS2 : ""
                userDefAddress = UserAddresssArr[0].STREET + concateApartmentNo + ", " + UserAddresssArr[0].CITY + ", " + UserAddresssArr[0].STATE + ", " + UserAddresssArr[0].POSTAL + ", ";
                userDefAddress = userDefAddress + (UserAddresssArr[0].COUNTRY.toLowerCase() == "us" ? "USA" : UserAddresssArr[0].COUNTRY.toLowerCase() == "ca" ? "CAN" : UserAddresssArr[0].COUNTRY.toLowerCase() == "mx" ? "MEX" : UserAddresssArr[0].COUNTRY.toUpperCase()) + ".";
                street = UserAddresssArr[0].STREET;
                city = UserAddresssArr[0].CITY;
                state = UserAddresssArr[0].STATE;
                postal = UserAddresssArr[0].POSTAL;
                country = UserAddresssArr[0].COUNTRY.toLowerCase() == "us" ? "USA" : UserAddresssArr[0].COUNTRY.toLowerCase() == "ca" ? "CAN" : UserAddresssArr[0].COUNTRY.toLowerCase() == "mx" ? "MEX" : UserAddresssArr[0].COUNTRY.toUpperCase();
                apartmentNo = UserAddresssArr[0].ADDRESS2;
                existAddress = true;
            }

        }

        let deliveryPointValues = PageLaod_Data.DeliveryPoint;
        let DPDropdownValues = [];
        if (CookieService.getLocalStorageValues("Session_DeliveryPoint") == "Y") {
            if (deliveryPointValues != undefined && deliveryPointValues.length > 0) {
                deliveryPointValues.map((value) => {
                    let data = { label: value.ISA_UNLOADING_PT, value: value.ISA_UNLOADING_PT, notesReq: value.COMMENT_REQD };
                    DPDropdownValues.push(data);
                })
            }
        }

        let locordcat = []
        let locordcatSGG = []
        let locOrdSubCat = []
        let locOrdSubCatSGG = []
        let suggestionsStr = CookieService.getLocalStorageValues("FavOrder_Category")
        if (suggestionsStr) {
            suggestionsStr.forEach((sugg, index) => {
                if (sugg.ORDER_CATEGORY) {
                    locordcat.push({ label: sugg.ORDER_CATEGORY, value: sugg.ORDER_CATEGORY });
                }
                if (sugg.ORDER_SUB_CATEGORY) {
                    locOrdSubCat.push({ label: sugg.ORDER_SUB_CATEGORY.toUpperCase(), value: sugg.ORDER_SUB_CATEGORY.toUpperCase(), category: sugg.ORDER_CATEGORY.toUpperCase() });
                }
            });
        }
        if (locordcat.length > 0) {
            locordcatSGG = locordcat.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.label != null && t.label != " ")) === i)
            locordcatSGG = locordcatSGG.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }
        if (locOrdSubCat.length > 0) {
            locOrdSubCatSGG = locOrdSubCat.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
            locOrdSubCatSGG = locOrdSubCatSGG.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }

        this.setState({
            ChargeCodeTitle: ChargeCodeTitle,
            OnlyCatalogItem: OnlyCatalogitemdata,
            startDate: today,
            OLDueDateVal: dateOL,
            hdfChangeLineNum: hdfChangeLineNumLocal,
            hdfZeusNonCata: hdfZeusNonCataLocal,
            OLEmplyIdLabel: OLEmplyIdLabelLocal,
            WordOrderNoLength: WordOrderNoLengthLocal,
            PagelaodCartValue: CartItemQuantity,
            CartItems: CartitemList,
            EmployeeCC: employidDropDown,
            ShipToDDValue: ShiptoValue,
            TypeTable: PageLaod_Data.TypeDD,
            FavMenu: filteredFavmenuDD,
            FavMenuSub: PageLaod_Data.FavSubMenu,
            MachineTable: machinenumdata,
            SiteCurrencyTable: SiteCurrency,
            OLShipToIdLabel: OLShipToIdLabelLocal,
            defaultShipto: PerferedShipto,
            ItemMachinePartNum: PageLaod_Data.MachinePartNo,
            SearchKey: QS_src,
            CartCOunt: PageLaod_Data.CartItems.length,
            ChargeCodeMultiple: MultiCHargeCOde,
            PunchinUser: PunchinUser,
            ShowMachineNumber: MAchineNumber,
            WorkOrderTitle: WorkOrderTitle,
            showShipTo: displayShipTo,
            pickup_vendor: PageLaod_Data.Pickup_VendorList,
            isAllItem_are_Pickup: pickupcheckbox,
            PickupStore: pickupstore,
            pickupstore_tbl: PageLaod_Data.Pickup_store_tbl,
            PickupVendor: pickupvendor,
            pickupcheck: pickuploc,
            lowesitem: loclowesitem,
            PickupVendorID: pickupvendorid,
            disableShipto: disableShiptobol,
            category: allCategory, bindCategory: allCategory, categoryError: false, subCategoryError: false, subCategory: allSubCategory, bindSubCategory: [],
            userAddress: userDefAddress,
            existStreet: street,
            existState: state,
            existCity: city,
            existCountry: country,
            existPostal: postal,
            isExistingAddress: existAddress,
            isDefAddressChckd: existAddress,
            userDefaultAddress: userDefAddress,
            //[Zeus 130, 6/8/23] - Kishore S - If not a supervisor role empId will be disabled
            EmpIDDisable: CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0635" && CookieService.getLocalStorageValues("Session_SDIEMP") == "CUST" && CookieService.getLocalStorageValues("Session_USERTYPE") != "SUPERVISOR" && EmpAvail ? true : false,
            DeliveryPointDropdown: DPDropdownValues,
            DeliveryPointFlag: CookieService.getLocalStorageValues("Session_DeliveryPoint") == "Y" ? true : false,
            existApartmentNo: apartmentNo,
            favOrderCatObj: locordcatSGG,
            favOrdsubCategoryObj: locOrdSubCatSGG,
            favOrderCatSugg: locordcatSGG,
            favOrdsubCategorySugg: []
        });
        let RTIStore = []
        this.state.CartItems.forEach(element => {
            // 7/1/2024 - Zeus 297 - Prefil the selected store only the item is RTI - Johnprinto D
            if (element.RTI_STORE && element.RTI_QTY && element.RTI_QTY != "0") {
                RTIStore.push(element.RTI_STORE)
            }
        });
        if (pickupcheckbox && RTIStore.length == this.state.CartItems.length) {
            this.Bindpickupstore();
        }

        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (CartpaymentEnable != null && CartpaymentEnable != undefined) {
            $('#OrderlevelDiv').addClass('d-none');
            if (CartpaymentEnable == "yes") {
                MenuAction.refreshMasterData()
                let TexvalueBO = {
                    strCustID: CookieService.getLocalStorageValues("Session_CUSTID"),
                    ApiUrl: this.state.apiURL,
                    UserBU: this.state.Login_UserBU,
                    UserId: this.state.Login_UserId
                }
                ShoppingCartAction.getTaxValue(TexvalueBO);
                let SCbIsWithPricedItems = false
                if (this.state.CartItems != null && this.state.CartItems != undefined) {
                    if (this.state.CartItems.length > 0) {
                        this.state.CartItems.map((val, index) => {
                            let uniteprice = parseFloat(val.Price) ? parseFloat(val.Price) : 0;
                            if (uniteprice != NaN && uniteprice != 0 && SCbIsWithPricedItems != true) {
                                SCbIsWithPricedItems = true
                                //ItemTotal = uniteprice;
                            }
                            //else {
                            //    SCbIsWithPricedItems = false
                            //}

                        })
                    }
                }
                let status = CookieService.getLocalStorageValues("Session_FavOrder")
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                this.setState({ CerditCardEnabled: true, AddfavOrderValue: status, SCbIsWithPricedItems: SCbIsWithPricedItems })
            }
        }
    }

    EmployeeDD = (employidDropDown) => {
        //debugger
        let USER_ID = CookieService.getLocalStorageValues("Session_EMP_ID");
        if (USER_ID == undefined || USER_ID == null || USER_ID == "") {
            USER_ID = CookieService.getLocalStorageValues("Session_UserID");
        }
        let EmpDDValue = []
        if (employidDropDown.length > 0) {
            employidDropDown.map((val, index) => {
                let localArray = {
                    value: val.EMP_ID,
                    label: val.ISA_EMPLOYEE_NAME,
                    email: val.EMP_EMAIL
                }
                EmpDDValue.push(localArray)
                if (USER_ID == val.EMP_ID) {
                    this.setState({ OrderEmpyId: localArray });
                    tempEMPEmail = val.EMP_EMAIL;
                }
            });
        }
        this.setState({ EmplyeeNameDD: EmpDDValue });
    };
    /*
     * Handle change method for favorite page checkbox
     */
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Shopping Cart",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            ShoppingCartAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            ShoppingCartAction.deleteFavPage(deleteFavPageBO)
        }
    };

    DefaultAddressResp = () => {

        const { t } = this.props;
        let SetDefAddressResponse = ShoppingCartStore.DefaultAddress;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //alert(SetDefAddressResponse);
        swal({
            allowOutsideClick: false,
            closeOnClickOutside: false,
            text: t(SetDefAddressResponse)
        })
        //[5/16/2023]Z-102 User default address will be changed with current value only if it is inserted successfully - Poornima S
        if (SetDefAddressResponse = "Default address added successfully") {
            this.setState({ userDefaultAddress: this.state.userAddress })
        }
    }

    setDefaultAddress = (e) => {
        debugger
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let streetLines;
        let postalCode;
        let countryCode;
        let state;
        let city;
        let apartmentNo;
        let isDefChecked = this.state.isDefAddressChckd;
        if (this.state.typedAddress == true) {
            streetLines = this.state.typedStreet.trim().replace("'", "''");
            postalCode = this.state.typedZipCode.trim();
            countryCode = this.state.typedCountry.trim();
            state = this.state.typedState.trim();
            city = this.state.typedCity.trim().replace("'", "''");
            apartmentNo = this.state.typedApartmentNo.trim().replace("'", "''")
        }
        else if (this.state.isExistingAddress == true) {
            streetLines = this.state.existStreet.trim().replace("'", "''");
            postalCode = this.state.existPostal.trim();
            countryCode = this.state.existCountry.trim();
            state = this.state.existState.trim();
            city = this.state.existCity.trim().replace("'", "''");
            apartmentNo = this.state.existApartmentNo != null ? this.state.existApartmentNo.trim().replace("'", "''") : "";
        }
        else {
            streetLines = this.state.isValidStreet.trim().replace("'", "''");
            postalCode = this.state.isValidZipCode.trim();
            countryCode = this.state.isValidCountryCode.trim();
            state = this.state.isValidState.trim();
            city = this.state.isValidCity.trim().replace("'", "''");
            apartmentNo = this.state.typedApartmentNo.trim().replace("'", "''")
        }

        let DefChckd;
        if (this.state.isDefAddressChckd) {
            DefChckd = false;
        } else {
            DefChckd = true;
        }

        this.setState({ isDefAddressChckd: DefChckd });

        let DefaultAddressBO = {
            UserId: this.state.Login_UserId,
            Bus_Unit: this.state.Login_UserBU,
            Street: streetLines,
            City: city,
            State: state,
            countryCode: countryCode,
            postalCode: postalCode,
            ApartmentNo: apartmentNo,
            IsDefaultAddress: DefChckd
        }
        ShoppingCartAction.setDefaultAddress(DefaultAddressBO);

    }

    /*
     * Listener method for loading favorite page icon
     */
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(ShoppingCartStore.currentFavPageResponse);
        //.log("favPageResponse", favPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };

    //[3/15/2023]CAS-44 Response returned from Google API after validating the address will be handled in validateAddressResponse ()
    validateAddressResponse = () => {
        debugger
        const { t } = this.props;
        let validAddress = JSON.parse(ShoppingCartStore.AddressValidation);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (validAddress != null && validAddress != "" && validAddress != undefined) {
            validAddress = JSON.parse(validAddress)
            let hasUnconfirmedComponents = validAddress.result.verdict.hasUnconfirmedComponents
            console.log("hasUnconfirmedComponents", hasUnconfirmedComponents)
            if (hasUnconfirmedComponents != undefined) {
                document.getElementById("AddressErrorMsg").innerText = "Please enter a valid address"
            }
            else {
                document.getElementById("AddressErrorMsg").innerText = ""
                let uspsDataAddress = validAddress.result.uspsData != undefined ? validAddress.result.uspsData : validAddress.result.address.postalAddress // PS_SC_ST_01 //[05/03/23, PS_SC_AV_01, 3416], Sathis N//condition for validate mexican and canadian adresses
                let standardAddress = uspsDataAddress.standardizedAddress != undefined ? uspsDataAddress.standardizedAddress : uspsDataAddress
                let postalAddress = validAddress.result.address.postalAddress
                let geocode = validAddress.result.geocode
                let location = geocode.location
                $('#AddressFieldsModal').modal('hide');
                document.getElementById("AddressErrorMsg").innerHTML = "";
                let street = standardAddress.firstAddressLine != undefined ? standardAddress.firstAddressLine.toUpperCase() : standardAddress.addressLines[0].toUpperCase()
                let city = standardAddress.city != undefined ? standardAddress.city.toUpperCase() : standardAddress.locality.toUpperCase()
                let state = standardAddress.state != undefined ? standardAddress.state.toUpperCase() : standardAddress.administrativeArea.toUpperCase()
                let zipCode = standardAddress.zipCode != undefined ? standardAddress.zipCode : standardAddress.postalCode.toUpperCase()
                //[Zeus 293, 04/09/2024] -Zipcode extension concatenation - Kishore
                let extZipCode = standardAddress.zipCodeExtension != undefined ? "-" + standardAddress.zipCodeExtension.toUpperCase() : "";
                zipCode = zipCode + extZipCode;
                let countryCode = (postalAddress.regionCode ? (postalAddress.regionCode.toLowerCase() == "us" ? "USA" : postalAddress.regionCode.toLowerCase() == "ca" ? "CAN" : postalAddress.regionCode.toLowerCase() == "mx" ? "MEX" : postalAddress.regionCode.toUpperCase()) : "");
                let concateApartmentNo = this.state.apartmentNoValue.trim() != "" ? ", " + this.state.apartmentNoValue : "";
                let userAddress = this.state.streetValue + concateApartmentNo + ", " + this.state.cityValue + ", " + this.state.stateValue + ", " + this.state.zipCodeValue + ", ";
                userAddress = userAddress + (this.state.countryValue ? (this.state.countryValue.toLowerCase() == "us" ? "USA" : this.state.countryValue.toLowerCase() == "ca" ? "CAN" : this.state.countryValue.toLowerCase() == "mx" ? "MEX" : this.state.countryValue.toUpperCase()) : "") + ".";
                let recAddress = street + concateApartmentNo + ", " + city + ", " + state + ", " + zipCode + ", ";
                recAddress = recAddress + countryCode + ".";
                this.setState({
                    responseIsValidStreet: street, responseIsValidCity: city, responseIsValidState: state, responseIsValidZipCode: zipCode, responseIsValidCountryCode: countryCode,
                    userSelectedAddress: userAddress, recomendedAddress: recAddress,
                    validLatitude: location.latitude, validLongitude: location.longitude,
                    responseTypedStreet: this.state.streetValue, responseTypedCity: this.state.cityValue, responseTypedZipCode: this.state.zipCodeValue, responseTypedState: this.state.stateValue, responseTypedCountry: this.state.countryValue,
                    recAddress: true, typedAddress: false, //Zeus 293 - SIT - 1 4970 - Make recommanded address as by default selected option Bug fix - Johnprinto D
                })
                $('#AddressModal').modal('show');
            }
            console.log("validAddressResp", validAddress)
        }
        else {
            document.getElementById("AddressErrorMsg").innerText = (t("Please enter a valid address"));
        }

    };

    /*
    * Listener method for the response of Save/Delete favorite page
    */
    updateFavPageResponse = () => {
        if (ShoppingCartStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(ShoppingCartStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(ShoppingCartStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
        //this.refreshMenu();
    };
    /*
     * RF No: SC_PC_26
     * Bindind the favorite menu parent events.
     * */
    BuildFavMenu() {
        const { t } = this.props;
        if (this.state.FavMenu.length > 0) {
            return this.state.FavMenu.map((val, index) => {
                let Favmenuvalue = val.FavText == "Not Classified" ? " " : val.FavText
                let childItems = this.state.FavMenuSub.filter((item, index) => item.Fav_Catagory.trim().toUpperCase() == Favmenuvalue.trim().toUpperCase());
                if (index < 4) {
                    return (<li className="dropdown-submenu" key={val.FavText + index}>
                        <a className="dropdown-item">{val.FavText.toUpperCase()}<span className="fav-right-arrow float-right" />
                        </a><div className="dropdown-menu custom-welcome-dropdown item-box" aria-labelledby="navbarDropdown-myfav-order">
                            {this.bindFavSubItem(childItems)}
                            {childItems.length > 5 ? <label className="dropdown-submenu text-center w-100">
                                <button className="btn btn-primary custom-btn-showall primary-btn col-md-10 mt-2" onClick={(event) => this.headerRedirect(event, "/favorites")}>{t("Show All")}</button>
                            </label> : null}
                        </div>
                    </li>)
                }
                else if (index == 4 && childItems.length < 5) {
                    return (<li className="dropdown-submenu" key={val.FavText + index}>
                        <a className="dropdown-item">{val.FavText.toUpperCase()}<span className="fav-right-arrow float-right" />
                        </a><div className="dropdown-menu custom-welcome-dropdown item-box" aria-labelledby="navbarDropdown-myfav-order">
                            {this.bindFavSubItem(childItems)}
                            {childItems.length > 5 ? <label className="dropdown-submenu text-center w-100">
                                <button className="btn btn-primary custom-btn-showall primary-btn col-md-10 mt-2" onClick={(event) => this.headerRedirect(event, "/favorites")}>{t("Show All")}</button>
                            </label> : null}
                        </div>
                    </li>)
                }
                else if (index == 4 && childItems.length == 5) {
                    return (<li className="dropdown-submenu" key={val.FavText + index}>
                        <a className="dropdown-item">{val.FavText.toUpperCase()}<span className="fav-right-arrow float-right" />
                        </a><div className="dropdown-menu custom-welcome-dropdown item-box Fav-last-Item-4" aria-labelledby="navbarDropdown-myfav-order">
                            {this.bindFavSubItem(childItems)}
                            {childItems.length > 5 ? <label className="dropdown-submenu text-center w-100">
                                <button className="btn btn-primary custom-btn-showall primary-btn col-md-10 mt-2" onClick={(event) => this.headerRedirect(event, "/favorites")}>{t("Show All")}</button>
                            </label> : null}
                        </div>
                    </li>)
                }
                else if (index == 4 && childItems.length > 5) {
                    return (<li className="dropdown-submenu" key={val.FavText + index}>
                        <a className="dropdown-item">{val.FavText.toUpperCase()}<span className="fav-right-arrow float-right" />
                        </a><div className="dropdown-menu custom-welcome-dropdown item-box Fav-last-Item" aria-labelledby="navbarDropdown-myfav-order">
                            {this.bindFavSubItem(childItems)}
                            {childItems.length > 5 ? <label className="dropdown-submenu text-center w-100">
                                <button className="btn btn-primary custom-btn-showall primary-btn col-md-10 mt-2" onClick={(event) => this.headerRedirect(event, "/favorites")}>{t("Show All")}</button>
                            </label> : null}
                        </div>
                    </li>)
                }
            });
        }
    }
    /*
     * Bindind the favorite menu Child events.
     * */
    bindFavSubItem = (childItems) => {


        return childItems.map((SubItem, I) => {
            if (I < 5) {
                return (<label id={SubItem.CUSTOMERITEMID} className="dropdown-item" key={SubItem.CUSTOMERITEMID + I} onClick={(e) => this.quickItemClick(e, "2")} data-backdrop="static" data-keyboard="false" data-toggle="tooltip" title={SubItem.DROPDOWNTEXTFIELDS}>{SubItem.DROPDOWNTEXTFIELDS}</label>

                )
            }
        });

    }
    /*[11/22/21, PC_SC_02, 1830,1831], Priyanka D*/
    Replace = (replace_string, replace_char, replacement_char) => {
        debugger
        let i;
        for (i = 0; i < replace_string.length; i++) {
            if (replace_string.charAt(i) == replace_char) {
                replace_string = replace_string.substring(0, i) + replacement_char + replace_string.substring(i + 1);
            }
        }
        return replace_string
    }
    //[5/7/23, PC_ZE_85_APP_04, Dhevanesam R]
    pickupchange = (e) => {
        debugger
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let pickupcheckVal = ""
        this.Bindpickupstore()

        let values = this.state.Pick;
        let filterArray = values.filter((ele, ind) => ind === values.findIndex(elem => elem.label === ele.label))
        this.setState({
            Pick: filterArray
        });


        if (e.target.checked) {
            debugger

            if (this.state.PickupVendor != "" && this.state.PickupVendor != undefined) {
                if (this.state.PickupVendor.toUpperCase().includes("GRAINGER") || this.state.PickupVendor.toUpperCase().includes("DIVERSIFIED SUPPLY")) {
                    $('#ZipCode').modal('show');
                    $('#ZipCode_no').val('');
                    this.setState({
                        pickupcheck: true, disableShipto: true
                    });
                }
                else {
                    this.setState({
                        pickupcheck: true, disableShipto: true
                    });
                }
            }

        }
        else {
            this.setState({ PickupStoreSelected: "", pickupcheck: false, disableShipto: false, StatusErrVal: "", ErrorMsgVal: "", PickupStoreErr: false });
            //[10/11/23, Bug - 4276, Fix the error error message displayed after pickup check box uncheck, Johnprinto]
            if (this.state.PickupStoreErr) {
                this.handleShoppingCartValidation("PICKUP");
            }
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    //[5/7/23, PC_ZE_85_APP_05, Dhevanesam R]
    Bindpickupstore = () => {
        debugger
        let lat
        let long
        let values = this.state.Pick;
        values.forEach((val) => {
            values.splice(val);
        })
        this.setState({
            Pick: values
        });
        if ((this.state.PickupVendor.toUpperCase().includes("GRAINGER") || this.state.PickupVendor.toUpperCase().includes("DIVERSIFIED SUPPLY")) && this.state.pickupcheck == true) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            if (this.state.GraingerStore.length > 0) {
                console.log("GraingerStore", this.state.GraingerStore);
                let curlatGrainger = 0;
                let curlongGrainger = 0;
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(getLatLon);
                    function getLatLon(position) {
                        curlatGrainger = position.coords.latitude;
                        curlongGrainger = position.coords.longitude;
                    }
                } else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "Please allow the website to access your location!!"
                    });
                    return;
                }

                const CurrentLocation = { lat: CurrentLatitude, long: CurrentLongitude };
                return this.state.GraingerStore.sort((a, b) =>
                    (DistanceCalculator.calculate(CurrentLocation, { lat: a.latitude, long: a.longitude }) * 0.00062137) > (DistanceCalculator.calculate(CurrentLocation, { lat: b.latitude, long: b.longitude }) * 0.00062137) ? 1 : -1).map((iresult, index) => {
                        if (iresult != null && iresult != undefined) {

                            let colr = ""
                            if (iresult.availablity == "Green") {
                                colr = "Green"
                            }
                            else if (iresult.availablity == "Orange") {
                                colr = "Orange"
                            }
                            let arr1 = ""
                            if ((iresult.latitude == null || iresult.latitude == undefined) && (iresult.longitude == null || iresult.longitude == undefined)) {
                                arr1 = iresult.address1 + "," + iresult.state + "~" + " " + "N/A"

                            }
                            else {
                                let dis1 = DistanceCalculator.calculate(CurrentLocation, { lat: iresult.latitude, long: iresult.longitude })
                                let dis = (dis1 * 0.00062137).toFixed(2)
                                arr1 = iresult.address1 + "," + iresult.state + "~" + dis + " " + "miles"
                            }
                            let val = iresult.branchId
                            let localArray = {
                                value: val,
                                label: arr1,
                                color: colr
                            }
                            this.state.Pick.push(localArray);
                        }
                    });

                //let graingerdetail = this.state.GraingerStore.sort((a, b) =>
                //    a.distance > b.distance ? 1 : -1).map((iresult, index) => {
                //        if (iresult != null && iresult != undefined) {
                //            let colr = ""
                //            if (iresult.availablity == "Green") {
                //                colr = "Green"
                //            }
                //            else if (iresult.availablity == "Orange") {
                //                colr = "Orange"
                //            }
                //            let arr1 = ""
                //            if (iresult.distance != null) {
                //                arr1 = iresult.address1 + "," + iresult.state + "~" + iresult.distance + " " + "miles"
                //            }
                //            else {
                //                arr1 = iresult.address1 + "," + iresult.state + "~" + " " + "N/A"
                //            }
                //            let val = iresult.branchId
                //            let localArray = {
                //                value: val,
                //                label: arr1,
                //                color: colr
                //            }
                //            this.state.Pick.push(localArray);
                //        }
                //    });
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Pickup is not availble for these items"
                });
                this.setState({ pickupcheck: false, disableShipto: false });
            }
        }
        else {
            debugger
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            if (this.state.PickupStore) {
                let curlat = 0;
                let curlong = 0;
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(getLatLon);
                    function getLatLon(position) {
                        curlat = position.coords.latitude;
                        curlong = position.coords.longitude;
                    }
                } else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "Please allow the website to access your location!!"
                    });
                    return;
                }

                let values = this.state.Pick;
                values.forEach((val) => {
                    values.splice(val);
                })
                this.setState({
                    Pick: values
                });
                const CurrentLocation = { lat: CurrentLatitude, long: CurrentLongitude };
                this.state.PickupStore.sort((a, b) =>
                    (DistanceCalculator.calculate(CurrentLocation, { lat: a.LATITUDE, long: a.LONGITUDE }) * 0.00062137) > (DistanceCalculator.calculate(CurrentLocation, { lat: b.LATITUDE, long: b.LONGITUDE }) * 0.00062137) ? 1 : -1).map((iresult, index) => {
                        if (iresult != null && iresult != undefined) {

                            let arr1 = ""
                            if ((iresult.LATITUDE == null || iresult.LATITUDE == undefined) && (iresult.LONGITUDE == null || iresult.LONGITUDE == undefined)) {
                                arr1 = iresult.ADDRESS1 + "," + iresult.STATE + "~" + " " + "N/A"

                            }
                            else {
                                let dis1 = DistanceCalculator.calculate(CurrentLocation, { lat: iresult.LATITUDE, long: iresult.LONGITUDE })
                                let dis = (dis1 * 0.00062137).toFixed(2)
                                arr1 = iresult.ADDRESS1 + "," + iresult.STATE + "~" + dis + " " + "miles"
                            }

                            let val = iresult.VNDR_LOC
                            let localArray = {
                                value: val,
                                label: arr1,
                                color: "Beige"
                            }
                            this.state.Pick.push(localArray);
                            console.log("pick", this.state.Pick)
                        }
                    });
                //[6/20/2024, Zeus - 297 - Real time inventory - Check the value pickup has same store value- Johnprinto D]
                let RTIStore = []
                let uniqueStore = []
                this.state.CartItems.forEach(element => {
                    // 7/1/2024 - Zeus 297 - Prefil the selected store only the item is RTI - Johnprinto D
                    if (element.RTI_STORE && element.RTI_QTY && element.RTI_QTY != "0") {
                        RTIStore.push(element.RTI_STORE)
                    }
                });
                console.log("pickup1", RTIStore.length == this.state.CartItems.length)
                if (RTIStore.length == this.state.CartItems.length) {
                    let selectedPickup = [];
                    uniqueStore = [...new Set(RTIStore)]
                    if (uniqueStore.length == 1) {
                        selectedPickup = this.state.Pick.filter((item, index) => item.value == RTIStore[0]);
                    }
                    console.log("pickup1", selectedPickup.length > 0 ? selectedPickup[0] : selectedPickup);
                    this.setState({ PickupStoreSelected: selectedPickup.length > 0 ? selectedPickup[0] : selectedPickup, pickupcheck: true });

                }
            }
        }
    }
    //[5/7/23, PC_ZE_85_APP_07, Dhevanesam R]
    handleStoreChange = (values) => {
		//[10/11/23, Bug - 4276, Fix the border highlighted issue after pickup check box uncheck, Johnprinto]
		this.setState({ PickupStoreSelected: values, PickupStoreErr: false });
		if (this.state.PickupStoreErr) {
			this.handleShoppingCartValidation("PICKUP");
        }
        this.LinelevelEmyEdit("", "", "Store", "", values.value);

    }
    //[5/7/23, PC_ZE_85_APP_11, Dhevanesam R]
    ZipCodeClose = () => {
        this.setState({ pickupcheck: false, disableShipto: false, zipCodeValidation: "" });
    }
    ZipCodeClick = () => {
        debugger
        let postal_code = "" 
        postal_code = $('#ZipCode_no').val();
        if (postal_code != "") {
            $('#ZipCode').modal('hide');
            if ((this.state.PickupVendor.toUpperCase().includes("GRAINGER") || this.state.PickupVendor.toUpperCase().includes("DIVERSIFIED SUPPLY")) && this.state.pickupcheck == true) {
                let graingeritem = [];
                this.state.CartItems.map((storeval, index) => {
                    if (storeval.SUPPLIERNAME.trim().toUpperCase().includes("GRAINGER") || storeval.SUPPLIERNAME.trim().toUpperCase().includes("DIVERSIFIED SUPPLY")) { 
                        let localArray = {
                            GraingerItemID: storeval.SupplierPartNumber,
                            GraingerQuantity: storeval.Quantity
                        }
                        graingeritem.push(localArray)
                    }
                });
                let graingerBO = {
                    postalCode: postal_code,
                    graingerItems: graingeritem
                }
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                ShoppingCartAction.GraingerItem(graingerBO);
            }
            
        }
        else {
            this.setState({ zipCodeValidation: "Please Enter a Zip Code" });
            
        }

    }
    //[5/7/23, PC_ZE_85_APP_15, Dhevanesam R]
    Graingerdata = () => {
        debugger
        let response = JSON.parse(ShoppingCartStore.graingerdata);
        if (response != null && response != undefined) {
            if (response.trim() == "") {
                debugger                
                this.setState({ zipCodeValidation: "Please Enter a valid Zip Code" });
                window.stop()
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                $('#ZipCode').modal('show');
            }

            else if (response == "Failure") {
                this.setState({ zipCodeValidation: "Error in processing zip code" });
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                $('#ZipCode').modal('show');
            } else {
                let PickupList = [];
                let GraingerQTY = [];
                let GraingerStore = []
                PickupList = JSON.parse(response);
                PickupList.Pickup.map((i) => {
                    if (i.branch != null && i.branch != "") {
                        if (i.allAvailable == "Y") {
                            i.items.map((j) => {
                                let graingerqty = {
                                    branchId: i.branchId,
                                    itemId: j.itemId,
                                    quantityRequested: j.quantityRequested,
                                    quantityAvailable: j.quantityAvailable,
                                    msg: j.rtaMessage
                                }
                                GraingerQTY.push(graingerqty);
                            });
                            let graingerStore = {
                                branchId: i.branchId,
                                address1: i.branch.branchAddress.address1,
                                city: i.branch.branchAddress.city,
                                postalcode: i.branch.branchAddress.postalcode,
                                state: i.branch.branchAddress.state,
                                latitude: i.branch.latitude,
                                longitude: i.branch.longitude,
                                availablity: "Green",
                                distance: i.branch.distance
                            }
                            GraingerStore.push(graingerStore);
                        }
                        else {
                            i.items.map((j) => {
                                let graingerqty = {
                                    branchId: i.branchId,
                                    itemId: j.itemId,
                                    quantityRequested: j.quantityRequested,
                                    quantityAvailable: j.quantityAvailable,
                                    msg: j.rtaMessage
                                }
                                GraingerQTY.push(graingerqty);
                            });
                            let graingerStore = {
                                branchId: i.branchId,
                                address1: i.branch.branchAddress.address1,
                                city: i.branch.branchAddress.city,
                                postalcode: i.branch.branchAddress.postalcode,
                                state: i.branch.branchAddress.state,
                                latitude: i.branch.latitude,
                                longitude: i.branch.longitude,
                                availablity: "Orange",
                                distance: i.branch.distance
                            }
                            GraingerStore.push(graingerStore);
                        }
                    }
                });
                if (GraingerStore.length != 0) {
                    this.setState({ GraingerStore: GraingerStore, GraingerQTY: GraingerQTY, zipCodeValidation: "" });
                    debugger
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    this.Bindpickupstore()

                }
                else {
                    this.setState({ zipCodeValidation: "Please Enter a valid Zip Code" });
                    window.stop()
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    $('#ZipCode').modal('show');
                }
            }
        }
        else {
            this.setState({ zipCodeValidation: "Please Enter a valid Zip Code" });
            window.stop()
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            $('#ZipCode').modal('show');

        }
        if (this.state.Pick.length == 0) {
            this.setState({ zipCodeValidation: "Please Enter a valid Zip Code" });
            window.stop()
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            $('#ZipCode').modal('show');

        }
    }
    cartToastMessage = (id) => {
        $(`#${id}`).removeClass('d-none');
        setTimeout(() => {
            $(`#${id}`).addClass('d-none');
        }, 3000);

    }
    /*
     * RF No: SC_PC_54
     * In this method the cart item table is binded with the values in the state CartItems.
     * */
    BindCart = () => {
        //[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
        const { t } = this.props;
        let isQtyMax = false;
        return this.state.CartItems.map((val, index) => {

            //[6/20/2024, Zeus - 297 - Real time inventory- Johnprinto D]
            if (!this.state.isPageLoad && (isQtyMax || val.RTI_QTY == parseInt(val.Quantity))) {
                isQtyMax = true;
                if (this.state.CartItems.length == index + 1) {
                    this.cartToastMessage("rti-maxqty-toast");
                    this.setState({ isPageLoad: true })
                }
            }
            // //.log(index, val);
            let imagePath = "";
            if (val.Image == "Images\noimage_new.png") {
                imagePath = `/Images/noimage_new.png`;
            }
            else if (val.Image) {
                val.Image = val.Image.replace('\\', '/');
                if (val.Image.toLowerCase().indexOf("http") >= 0) {
                    imagePath = val.Image;
                } else {
                    imagePath = val.Image.lastIndexOf(`/`) >= 0 ? val.Image.substring(val.Image.lastIndexOf("/") + 1, val.Image.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = this.state.apiURL + `Images/${imagePath}`;
                    }
                }
            }

            else {
                imagePath = `/Images/noimage_new.png`;
            }

            // //.log("Image", imagePath)
            let NonCat = val.Itemid.slice(0, 6)
            let ShowPOLNField = true
            if (CookieService.getLocalStorageValues("Session_SITEBU") != "SDM00") {
                ShowPOLNField = false
            }
            let SupplierId = false
            if (val.SupplierID != null && val.SupplierID != "") {
                SupplierId = true
            }
            /*[11/22/21, PC_SC_03, 1830,1831], Priyanka D*/
            let Pricevalue = "";
            if (val.Price != undefined && val.Price != "" && val.Price != " " && val.Price != null) {
                Pricevalue = this.Replace(val.Price, ",", "");
            }
            if (NonCat == "NONCAT" && Pricevalue == "Price on Request") {
                SupplierId = false
            }
            if (Pricevalue != "Price on Request") {
                let SiteCurrency = this.state.SiteCurrencyTable;
                Pricevalue = parseFloat(Pricevalue) ? parseFloat(Pricevalue) : 0
                let roundOffvalue = Pricevalue.toFixed(2)
                Pricevalue = SiteCurrency[0].Symbol + " " + roundOffvalue
                if (roundOffvalue > 0) {
                    SupplierId = true
                }
            }
            let PricePO = val.PricePO
            if (PricePO != null && PricePO != undefined) {
                if (PricePO != "0" && PricePO != 0) {
                    let SiteCurrency = this.state.SiteCurrencyTable;
                    PricePO = parseFloat(PricePO) ? parseFloat(PricePO) : 0
                    let roundOffvalue = PricePO.toFixed(2)
                    PricePO = SiteCurrency[0].Symbol + " " + roundOffvalue
                }
                else {
                    PricePO = 0
                }
            }
            else {
                PricePO = 0
            }
            let RemoveKey = " " + t("Remove")
            let Showmachinepart = CookieService.getLocalStorageValues("Session_PARTLIST")

            let OrderQuantity = val.Quantity

            let itemNum = index + 1;
            let formateddate = this.convertdate(val.UDueDate)
            let ItemMachinePart = []
            ItemMachinePart = this.state.ItemMachinePartNum.filter((item, index) => item.ItemId == val.Itemid)
            if (ItemMachinePart != null && ItemMachinePart != undefined) {
                if (ItemMachinePart.length == 0) {
                    ItemMachinePart = null
                }
            }
            let showmachinpartno = false
            if (Showmachinepart == "Y") {
                if (ItemMachinePart != null) {
                    showmachinpartno = true
                }
            }
            if (Showmachinepart == "Y" && NonCat == "NONCAT") {
                showmachinpartno = true
            }
            //let EmployName = this.state.OrderEmpyId;
            {/*let EmpFlag = false;
            this.state.EmplyeeNameDD.map((value) => {
                if (CookieService.getLocalStorageValues("Session_UserID") == value.value) {
                    EmpFlag = true;
                }})*/}
           
            /*if (val.EmpChgCode == null || val.EmpChgCode == undefined) {
                val.EmpChgCode =this.state.OrderEmpyId
            }
            else {
                val.EmpChgCode = val.EmpChgCode;
            }*/
            let Employee = this.state.EmplyeeNameDD.filter((emp) => emp.value == val.EmpChgCode);
            val.EmpChgCode = Employee[0];
            console.log("Employee", Employee[0]);
            //.log("EmpChgCode", val.EmpChgCode + "         " + EmployName)
            let ZEUSNOCATALOGSITEFieldHide = false
            if (CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE") == "Y" && CookieService.getLocalStorageValues("Session_ZEUS_SITE") == "Y") {
                if (val.SupplierID != null && val.SupplierID != "" && Pricevalue != "Price on Request") {
                    ZEUSNOCATALOGSITEFieldHide = true
                }
            }
            const style = {
                image: {
                    position: 'sticky',
                    display: 'block',
                    left: '0px !important',
                    top: '0px !important',
                },
            }
            debugger;
            //[4/27/2023, Bug - 3443, PS_SP_PL_APP_04, Dhevanesam R]
            let MfgReplace = "";
            let MfgpartNumber = "";
            if (val.Manufacturer != undefined && val.Manufacturer != "" && val.Manufacturer != " " && val.Manufacturer != null) {
                MfgReplace = val.Manufacturer.includes("&AMP;") ? val.Manufacturer.replaceAll("&AMP;", "&") : val.Manufacturer;
                MfgReplace = MfgReplace.includes("&APOS;") ? MfgReplace.replaceAll("&APOS;", "'") : MfgReplace;
                val.Manufacturer = MfgReplace;
            }
            if (val.Manufacturerpartnumber != undefined && val.Manufacturerpartnumber != "" && val.Manufacturerpartnumber != " " && val.Manufacturerpartnumber != null) {
                MfgpartNumber = val.Manufacturerpartnumber.includes("&AMP;") ? val.Manufacturerpartnumber.replaceAll("&AMP;", "&") : val.Manufacturerpartnumber;
                MfgpartNumber = MfgpartNumber.includes("&APOS;") ? MfgpartNumber.replaceAll("&APOS;", "'") : MfgpartNumber;
                val.Manufacturerpartnumber = MfgpartNumber;
            }

            let FavItem = false
            this.state.FavMenuSub.map((item, index) => {
                let CLIENT_MFG_PART = item.CLIENT_MFG_PART == null || item.CLIENT_MFG_PART == undefined || item.CLIENT_MFG_PART == "" ? " " : item.CLIENT_MFG_PART
                let CUSTOMERITEMID = item.CUSTOMERITEMID == null || item.CUSTOMERITEMID == undefined || item.CUSTOMERITEMID == "" ? " " : item.CUSTOMERITEMID
                let MANUFACTURER = item.MANUFACTURER == null || item.MANUFACTURER == undefined || item.MANUFACTURER == "" ? " " : item.MANUFACTURER
                let SUPPLIER_ID = item.SupplierID == null || item.SupplierID == undefined || item.SupplierID == "" ? " " : item.SupplierID//[06/29/2023, Zeus-94]- Kishore S - Checking supplier ID while displaying to fav in ShoppingCart
                let Manufacturer1 = val.Manufacturer == null || val.Manufacturer == undefined || val.Manufacturer == "" ? " " : val.Manufacturer
                let ItemidFav = val.Itemid == null || val.Itemid == undefined || val.Itemid == "" ? " " : val.Itemid
                let Manufacturerpartnumber = val.Manufacturerpartnumber == null || val.Manufacturerpartnumber == undefined || val.Manufacturerpartnumber == "" ? " " : val.Manufacturerpartnumber
                let vendor_id = val.SupplierID == null || val.SupplierID == undefined || val.SupplierID == "" ? " " : val.SupplierID
                let price = item.PRICE == null || item.PRICE == undefined || item.PRICE == "" || item.PRICE == " " ? " " : item.PRICE
                let des = item.DESCR == null || item.DESCR == undefined || item.DESCR == "" || item.DESCR == " " || item.DESCR == "-" ? "-" : item.DESCR.toUpperCase()
                let UnitPrice = 0.00

                let indexes = des.indexOf("RECOMMENDED");
                let last = des.length;
                let valitemdesc = val.ItemDescription;
                let removedrecommend = des.slice(0, indexes);
                if (des.includes("RECOMMENDED")) {
                    des = removedrecommend;
                }
                //06 / 06 / 22, Added null checking condition to avoid exception while uppercasing item desc - Poornima S
                if (val.ItemDescription != null && val.ItemDescription.trim() != "" && val.ItemDescription != undefined) {
                    if (val.ItemDescription.toUpperCase().includes("RECOMMENDED")) {
                        let ind = val.ItemDescription.toUpperCase().indexOf("RECOMMENDED");
                        let lastlen = val.ItemDescription.length;
                        let removedreco = val.ItemDescription.slice(0, ind);
                        valitemdesc = removedreco;
                    }
                }
                //[4/27/2023, Bug - 3443, PS_SP_PL_APP_04, Dhevanesam R]
                if (val.Manufacturer != undefined && val.Manufacturer != "" && val.Manufacturer != " " && val.Manufacturer != null) {
                    Manufacturer1 = val.Manufacturer.includes("&AMP;") ? val.Manufacturer.replaceAll("&AMP;", "&") : val.Manufacturer;
                    Manufacturer1 = Manufacturer1.includes("&APOS;") ? Manufacturer1.replaceAll("&APOS;", "'") : Manufacturer1;
                    val.Manufacturer = Manufacturer1;
                }
                if (val.Manufacturerpartnumber != undefined && val.Manufacturerpartnumber != "" && val.Manufacturerpartnumber != " " && val.Manufacturerpartnumber != null) {
                    Manufacturerpartnumber = val.Manufacturerpartnumber.includes("&AMP;") ? val.Manufacturerpartnumber.replaceAll("&AMP;", "&") : val.Manufacturerpartnumber;
                    Manufacturerpartnumber = Manufacturerpartnumber.includes("&APOS;") ? Manufacturerpartnumber.replaceAll("&APOS;", "'") : Manufacturerpartnumber;
                    val.Manufacturerpartnumber = Manufacturerpartnumber;
                }
                
                UnitPrice = parseFloat(item.PRICE) ? parseFloat(item.PRICE) : 0
                {/*[04/04/22, PC_SP_01, Bug - 2006, Dhevanesam R*/ }
                //if (CUSTOMERITEMID.toUpperCase() == ItemidFav.toUpperCase() || (CLIENT_MFG_PART.toUpperCase() == Manufacturerpartnumber.toUpperCase()
                //    && MANUFACTURER.toUpperCase() == Manufacturer1.toUpperCase())) {

                //    if (NonCat == "NONCAT" && Pricevalue == "Price on Request") {
                //        // if (UnitPrice == 0 || UnitPrice == " ") {
                //        if (des.toUpperCase().trim() == valitemdesc.toUpperCase().trim()) {
                //            debugger
                //            FavItem = true
                //        }

                //        //}
                //    }

                //    else {
                //        debugger
                //        FavItem = true
                //    }


                //}
                if (NonCat == "NONCAT") {
                    if (CUSTOMERITEMID.toUpperCase() == ItemidFav.toUpperCase() || (CLIENT_MFG_PART.toUpperCase() == Manufacturerpartnumber.toUpperCase()
                        && MANUFACTURER.toUpperCase() == Manufacturer1.toUpperCase())) {
                        if (NonCat == "NONCAT" && Pricevalue == "Price on Request") {
                            if (des.toUpperCase().trim() == valitemdesc.toUpperCase().trim()) {
                                FavItem = true
                            }
                        }
                        else {
                            FavItem = true
                        }
                    }
                }
                //[5/13/22, PC_SC_01, Bug - 2127 & 2117, Dhevanesam R]
                else if (CUSTOMERITEMID.toUpperCase() == ItemidFav.toUpperCase() && CLIENT_MFG_PART.toUpperCase() == Manufacturerpartnumber.toUpperCase()
                    && MANUFACTURER.toUpperCase() == Manufacturer1.toUpperCase() && SUPPLIER_ID.trim().toUpperCase() == vendor_id.trim().toUpperCase()) {
                    FavItem = true

                }
            })
            //[5/7/23, PC_ZE_85_APP_20, Dhevanesam R]
            let msg = ""
            let labeldot;
            let graingerboolean = false;
            if (this.state.PickupVendor != "" && this.state.PickupVendor != undefined && this.state.PickupVendor != null) {
                if ((this.state.PickupVendor.toUpperCase().includes("GRAINGER") || this.state.PickupVendor.toUpperCase().includes("DIVERSIFIED SUPPLY")) && this.state.pickupcheck == true) {
                    if (this.state.PickupStoreSelected != "" && this.state.PickupStoreSelected != undefined && this.state.PickupStoreSelected != null) {
                        let graingermsg = this.state.GraingerQTY.filter((x) => val.SupplierPartNumber == x.itemId && x.branchId == this.state.PickupStoreSelected.value)
                        let graingerstr = this.state.GraingerStore.filter((x) => x.branchId == this.state.PickupStoreSelected.value)
                        if (graingerstr.length > 0) {
                            labeldot = graingerstr[0].availablity
                        }
                        if (graingermsg.length > 0) {
                            msg = graingermsg[0].msg
                            if (msg.includes("<strong>")) {
                                msg = msg.replace("<strong>", "");
                            }
                            if (msg.includes("</strong>")) {
                                msg = msg.replace("</strong>", "");
                            }
                            graingerboolean = true
                        }
                    }
                }
            }

            if (this.state.CerditCardEnabled == false) {
                return (<div className="row mx-0 mb-3 py-3 item-box" key={val.Itemid + index}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="row col-md-1 pl-2 pr-0 mr-0 ml-0 custom-fav-image back-icon  Image-ShoppingCart justify-content-center" onClick={() => redirectToItemDetails(val.Itemid, val.Manufacturerpartnumber, val.SupplierID, "ShoppingCart", this.props)}>
                                {/*<div className="col-md-1 pl-2 custom-fav-image pr-0 Image-ShoppingCart">*/}
                                <Image
                                    src={imagePath}
                                    width={110}
                                    height={160}
                                    style={style.image}
                                    noImageSrc="/Images/noimage_new.png"
                                />
                                {/* <img src={imagePath} alt="fav_item" />
                                </div>*/}
                            </div>
                            <div className="col-md-11 custom-fav-container">
                                <div className="row">
                                    <div className="col-md-12 col-lg-9 Shopping-Cart-itemdiv">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="fav-item-title">
                                                    {/*[01/26/2024, Zeus 261] - Invoke redirectToItemDetails() to navigate to Item details page - Kishore*/}
                                                    {val.Itemid.includes('NONCAT') ? <span className="float-left mt-2">{(val.Manufacturerpartnumber ? val.Manufacturerpartnumber : "") + "  " + itemNum}</span>
                                                        : <span className="float-left mt-2 hyper-link-cart back-icon" onClick={() => redirectToItemDetails(val.Itemid, val.Manufacturerpartnumber, val.SupplierID, "ShoppingCart", this.props)}>{(val.Manufacturerpartnumber ? val.Manufacturerpartnumber : "") + "  " + itemNum}</span>}
                                                    {NonCat.toUpperCase() != "NONCAT" ? <div className="fav-item-maintenance-icon">
                                                        <input type="checkbox" id={'checkbox-' + index} className="checknow" checked={FavItem} onChange={() => this.LinelevelFavEdit(FavItem, val.UniqNum, val.Itemid)} data-backdrop="static" data-keyboard="false" />
                                                        <label htmlFor={'checkbox-' + index}>
                                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </label>
                                                        {/*//[07/04/23,PS_ZEUS_127_APP_07, Johnprinto D]*/}
                                                        {val.ItemType == "ORO" ?
                                                            <span className="oro-item">O</span> : null}
                                                        {val.ItemType == "VMI" ?
                                                            <span className="oro-item vmi-item">V</span> : null}
                                                    </div> :null}
                                                </span>
                                            </div>
                                            {/*[PC_TD_10, CAS - CSS Issue in description, Dhevanesam R*/}
                                            <label className="col-md-12 item-details-value w-100 Mobile-descrip" style={{ "word-wrap": "break-word" }}>{val.ItemDescription}{NonCat == "NONCAT" && SupplierId == false ? <a className="edit-icon ml-2" onClick={() => this.LinelevelDescpEdit(val.Itemid, val.UniqNum, val.ItemDescription)} data-backdrop="static" data-keyboard="false" /> : null}</label>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {/* Zeus - 232 */}
                                                    <div className="col-md-6 fav-maintence d-none">
                                                        <label className="catalog-label"><Trans>ID</Trans>:</label>
                                                        <label className="item-details-value ml-2">{val.Itemid}</label>
                                                    </div>
                                                    <div className="col-md-6 fav-maintence">
                                                        {/* Zeus - 232 */}
                                                        <label className="catalog-label"><Trans>Supplier</Trans> <Trans>Part</Trans># : </label>
                                                        <label className="item-details-value ml-2">{val.Itemid}</label>
                                                    </div>
                                                    <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label"><Trans>Manufacturer</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.Manufacturer}</label>
                                                    </div>
                                                    {val.LineNo != null && val.LineNo.trim() != "" ?
                                                        <div className="col-md-6 fav-maintence">
                                                            <label className="catalog-label"><Trans>Recommended Supplier</Trans> :</label>
                                                            {/*[12/15/23, PC_NC_02, Zeus - 231, Added the class to get the UI properly - Harshitha G]*/}
                                                            <label className="item-details-value ml-2 supplier-value">{val.LineNo}</label>
                                                        </div> : null}
                                                    {val.SUPPLIER_NAME != null && val.SUPPLIER_NAME.trim() != "" ?
                                                        <div className="col-md-6 fav-maintence">
                                                            <label className="catalog-label"><Trans>Supplier</Trans> :</label>
                                                            <label className="item-details-value ml-2">{val.SUPPLIER_NAME}</label>
                                                        </div> : null}
                                                    {/*//[07/03/24,PS_ZE_286_APP_01, Madhumitha K-Hide the Quantity available label for ORO Items]*/}
                                                    {val.SupplierID == "IPM9999999" && val.ItemType != "ORO" ? <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label"><Trans>Quantity Available</Trans> :</label>
                                                        <label className="item-details-value ml-2">{!val.ItemType || isNaN(val.ItemType) == true || (val.ItemType) == "0" ? "0" : val.ItemType}</label>
                                                    </div> : null}

                                                    {/*[5/12/22, CAS - 31, Dhevanesam R]*/}
                                                    <div className="col-md-12 fav-maintence">
                                                        <label className="catalog-label"><Trans>unitofmeasure_key</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.UOM}</label>
                                                    </div>
                                                    {val.RFQReq != null && val.RFQReq != undefined && val.RFQReq != "" ? <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label">RFQ :</label>
                                                        <label className="item-details-value ml-2">{val.RFQReq}</label>
                                                    </div> : null}
                                                </div>
                                            </div>
                                            {/*//[5/7/23, PC_ZE_85_APP_20, Dhevanesam R]*/}
                                            {graingerboolean ?
                                                <div className="col-md-12 fav-maintence">
                                                    {labeldot == "Orange" ?
                                                        <span className="cart-count" data-toggle="tooltip" style={la} title={msg}></span> : <span className="cart-count" data-toggle="tooltip" style={lal} title={msg} ></span>}
                                                    <span className="errorspan1" style={txt}>{msg}</span>
                                                </div> : null}
                                            {/*[11/16/21, PC_SP_02, Bug - 1744, Dhevanesam R]*/}
                                            {/*[11/18/21, PC_SP_01, Bug - 1811, Dhevanesam R]*/}
                                            <a href="#" className="more-details" onClick={(e) => this.onAccordianClickitemlevel(e, index)}><Trans>More Details</Trans></a>
											<button className="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" onClick={() => this.DeleteItem(val.Itemid, val.UniqNum, val.SupplierID)} data-backdrop="static" data-keyboard="false">
                                                <img src="Images/remove-order.svg" className="removeorder-icon" alt="Remove Order" />
                                                {RemoveKey}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-3 pl-0 OSPD-prices Shopping-Cart-Quantity">
                                        <div className="float-right">
                                            <div className="w-100 float-left mb-2 ">
                                                <div className="d-flex justify-content-end">
                                                    {/*[11/18/21, PC_SP_01, Bug - 1807, Dhevanesam R]*/}
                                                    <span className="float-left m-2 "><Trans>Quantity</Trans> : </span>
                                                    {/* <input type="text" value={val.Quantity} id={'Quantity' + val.UniqNum} data-toggle="tooltip" title={val.Quantity} className="form-control float-left catalog-inline-qty" maxLength="8" onChange={(e) => this.QuantityOnchange(val.UniqNum, val.Itemid, e)} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Quantity", "", e)} /> */}
                                                    {/* //[6/20/2024, Zeus - 297 - Real time inventory- Johnprinto D] */}
                                                    <div className="quantity buttons_added" style={{ width: "70px", marginRight: "28px" }}>
                                                        <input type="button" className="minus" value="-" disabled={val.Quantity <= 1 || val.Quantity == ""} onClick={(e) => this.qtyOnchange(e, index, "QTY_DECREASED")} />
                                                        <input type="number" value={val.Quantity} maxLength={8} step="1" class="minus" minLength="1" pattern="" inputmode="" style={{ "width": "73%", "borderLeft": "0" }} onChange={(e) => this.qtyOnchange(e, index, "QTY_CHANGE")} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Quantity", "", val.Quantity)} />
                                                        <input type="button" className="plus" value="+" disabled={val.Quantity >= maxQty || (val.RTI_QTY != 0 && val.RTI_QTY <= val.Quantity)} onClick={(e) => this.qtyOnchange(e, index, "QTY_INCREASED")} />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.showprice == "0" && (PricePO == 0 || Pricevalue.toUpperCase() != "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    {/*[10/13/21, PC_SC_03 CAS - 32], Priyanka D*/}
                                                    <label className="float-left mt-2"><Trans>Unit Price</Trans><span className="float-right ml-1">{this.PriceDesignation()}</span></label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{Pricevalue.toUpperCase() != "PRICE ON REQUEST" ? Pricevalue : t("Price on request")}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && (PricePO != 0 && Pricevalue.toUpperCase() == "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    {/*[10/13/21, PC_SC_03 CAS - 32], Priyanka D*/}
                                                    <label className="float-left mt-2"><Trans>Estimated Price</Trans><span className="float-right ml-1">{this.PriceDesignation()}</span></label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{PricePO}</span>
                                                </div>
                                            </div> : null}
                                            {/*[04/08/22, CAS - 31, Dhevanesam R]*/}
                                            {this.state.showprice == "0" && Pricevalue.toUpperCase() != "PRICE ON REQUEST" ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    {/*[10/13/21, PC_SC_03 CAS - 32], Priyanka D*/}
                                                    <label className="float-left mt-2"><Trans>Ext Price</Trans><span className="float-right ml-1">{this.PriceDesignation()}</span></label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{this.ItemTotalCal(val.Price, val.Quantity)}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && (PricePO != 0 && Pricevalue.toUpperCase() == "PRICE ON REQUEST" && PricePO.toUpperCase() != "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    {/*[10/13/21, PC_SC_03 CAS - 32], Priyanka D*/}
                                                    <label className="float-left mt-2"><Trans>Estimated Ext. Price</Trans><span className="float-right ml-1">{this.PriceDesignation()}</span></label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{this.ItemTotalCal(val.PricePO, val.Quantity)}</span>
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*//[5/92023, PS_ZE_101_02, Dhevanesam R]*/}
                    <div className="col-md-12 pt-3 mt-2 border-top accordian-body d-none" id={'order-row-' + index}>
                        <div className="row">
                            <div className="col-md-12 col-lg-9 moredetail-content">
                                <div className="row">
                                    {/****[8/17/2023]Start of commenting line level Delivery/Required by date for ticket Zeus 174***
                                    {CookieService.getLocalStorageValues("Session_DeliveryDateFlag") == "Y" ?
                                        <div className="col-md-6 col-lg-4 mb-2 OLDatepicker">
                                            <div className="row">
                                                <label className="col-lg-4 col-md-4 catalog-label form-label pl-3 pr-0 Delivery-date"><Trans>Required by date</Trans></label>
                                                <div className="col-lg-8 col-md-8">
                                                    <DatePicker className="form-control"
                                                        id={'DueDate' + val.UniqNum}
                                                        fixedHeight
                                                        placeholderText="MM/DD/YYYY"
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        minDate={new Date()}
                                                        selected={formateddate}
                                                        onChange={(value, e) => this.handleChangeItem(value, e, val.Itemid, val.UniqNum)}
                                                    />
                                                    <span className="calendar-icon-Line" onClick={() => $('#DueDate' + val.UniqNum).focus()} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {ZEUSNOCATALOGSITEFieldHide != true && NonCat == "NONCAT" ?
                                                <div>
                                                    <div className="row">
													<label className="col-lg-4 col-md-4 catalog-label form-label pl-3 pr-0 Delivery-date"><Trans>Delivery Date</Trans></label>
                                            <div className="col-lg-8 col-md-8">
                                                <DatePicker className="form-control"
                                                    id={'DueDate' + val.UniqNum}
                                                    fixedHeight
                                                    placeholderText="MM/DD/YYYY"
                                                    dateFormat="MM/dd/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    //dropdownMode="select"
                                                    minDate={new Date()}
                                                    selected={formateddate}
                                                    onChange={(value, e) => this.handleChangeItem(value, e, val.Itemid, val.UniqNum)}
                                                  />

                                                <span className="calendar-icon-Line" onClick={() => $('#DueDate' + val.UniqNum).focus()} />
                                            </div>
                                        </div>
                                      </div> : null
									  }
									  </div>
                                    }
                                 ///***End of commenting line level Delivery/Required by date for ticket Zeus 174***/}
                                    {/*Bug 2326 - UI issue in attachment label- shopping cart module [7/1/2022] - Dhamotharan P*/}
                                    {NonCat == "NONCAT" && ZEUSNOCATALOGSITEFieldHide != true ? <div className="col-md-6 col-lg-4 mb-3 attachment-btn" style={{ "marginLeft":"1px"}} >
                                        <label className="col-lg-4 col-md-4 catalog-label form-label p-0"><Trans>Attachment</Trans></label>
                                        <button id={val.UniqNum} className="btn btn-primary custom-btn-primary primary-btn ml-2 px-5" data-toggle="modal" data-target="#addattachment" onClick={() => this.LinelevelAtthEdit(val.Itemid, val.UniqNum)} data-backdrop="static" data-keyboard="false"><Trans>Browse</Trans> 
                                 </button>
                                    </div> : null}
                                    {/****Start of commenting line level Employee ID dropdown for ticket Zeus 178****/}
                                    {/*<div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-1"><Trans>Employee ID</Trans>:</label>
                                            <div className="col-lg-7 col-md-7 col-12 pr-0">
                                                <select id={'EmpDD' + val.UniqNum} className="form-control EmployeCC pr-0" value={EmployName} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "EmployeeCC", "")}>
                                                    <option value="0"><Trans>Select</Trans></option>
                                                    {this.BindEmpCCToItem()}
                                                </select>
                                            </div>
                                            <span className="col-lg-7 col-md-7 col-12" style={{ "fontSize": "12px", }}><Select
                                                isDisabled={this.state.EmpIDDisable}
                                                id="OLEmplyDD"
                                                name="EmpDD"
                                                onChange={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "EmployeeCC", "", e)}
                                                value={val.EmpChgCode}
                                                options={this.state.EmplyeeNameDD}
                                            /></span>
                                        </div>
                                    </div>*/}
                                    {Showmachinepart == "Y" ? <div className="col-md-6 col-lg-4 mb-3">
                                        {ItemMachinePart != null && ItemMachinePart != undefined ? <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label"><Trans>Part</Trans> #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <select className="form-control" id={'MachinePart' + val.UniqNum} defaultValue={val.MachineNo} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")}>
                                                    {/*[11/11/21, PC_SP_01, Bug - 1740, Dhevanesam R]*/}
                                                    <option>{t("Select")}</option>
                                                    {this.BuildMachinePart(ItemMachinePart)}
                                                </select></div>
                                        </div> : <div className="row">{showmachinpartno == true ? <React.Fragment><label className="col-lg-4 col-md-4 catalog-label form-label"><Trans>Part</Trans> #</label>
                                            <div className="col-lg-8 col-md-8">
                                                    <input type="text" className="form-control" id={'MachinePart' + val.UniqNum} placeholder={t("Enter Part No")} maxLength="20" defaultValue={val.MachineNo} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")} />
                                            </div>
                                        </React.Fragment> : null}

                                            </div>}

                                    </div> : null}

                                    <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-0"><Trans>Charge Code</Trans></label>
                                            {this.state.ChargeCodeMultiple == true ? <div className="col-lg-7 col-md-7 float-left pr-0 col-12">
                                                <div className="col-lg-10 col-md-10 float-left px-0 col-12 CardDiv">
                                                    <input type="text" className="form-control" placeholder={t("Select charge code")} id={'ItemChgCode' + val.UniqNum} value={val.ItemChgCode == null ? "" : val.ItemChgCode} maxLength="40" onChange={(e) => this.handleChargecodechange(e, 1, val.UniqNum, val.Itemid)} disabled={this.state.ChargeCodeMultiple} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "ItemChgCode", "", e)} />
                                                </div>
                                                <div className="col-lg-2 col-md-2 float-left px-0 CardImageDiv">
                                                    {/*[11/17/21, PC_MC_02 Bug-1775], Priyanka D*/}
                                                    <button className="btn btn-primary custom-btn-primary primary-btn float-right" data-backdrop="static" data-keyboard="false" onClick={() => this.GetChargeCode(1, val.UniqNum, val.Itemid,val.ItemChgCode)}>CC</button>
                                                </div>
                                            </div> : <div className="col-lg-6 col-md-6 float-left px-0 col-12 mr-1">
                                                    <input type="text" className="form-control" placeholder={t("Enter charge code")} id={'ItemChgCode' + val.UniqNum} value={val.ItemChgCode == null ? "" : val.ItemChgCode} maxLength="40" onChange={(e) => this.handleChargecodechange(e, 1, val.UniqNum, val.Itemid)} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "ItemChgCode", "", e)} />

                                                </div>}
                                            {/*this.state.ChargeCodeMultiple == true ? <div className="col-lg-1 float-left px-0 ChargeCodeBtn">*/}


                                        </div>
                                    </div>
                                    {this.state.ShowMachineNumber == true ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-0"><Trans>Asset ID</Trans></label>
                                            <div className="col-lg-8 col-md-8">
                                                {/*<select id={val.UniqNum} className="form-control">
                                                <option>Select</option>
                                                <option>Option</option>
                                            </select>*/}
                                                {this.state.MachineTable.length > 0 ? <select className="form-control" data-toggle="tooltip" title={"Click to select Asset ID or enter it in the textbox"} id={'MachineNo' + val.UniqNum} defaultValue={val.MachineRow} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachineNo", "")}>
                                                    <option>{t("Select")}</option>
                                                    {this.BuildMachineDD()}
                                                </select> : <input type="text" data-toggle="tooltip" title={"Click to select Asset ID or enter it in the textbox"} className="form-control" id={'MachineNo' + val.UniqNum} placeholder={t("Enter Asset ID")} maxLength="20" defaultValue={this.state.stanfordAssetId != "" ? this.state.stanfordAssetId:val.MachineRow} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachineNo", "")} />}
                                            </div>
                                        </div>
                                    </div> : null}
                                    {ShowPOLNField ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">PO #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" id={'PO' + val.UniqNum} className="form-control" placeholder={t("Enter value")} maxLength="20" defaultValue={val.PO} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "PO", "")} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    {ShowPOLNField ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">LN #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" id={'LN' + val.UniqNum} className="form-control" placeholder={t("Enter value")} maxLength="11" defaultValue={val.LN} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "LN", "")} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    <div className="col-md-12 col-lg-12 mt-2">
                                        <div className="row">
                                            {ZEUSNOCATALOGSITEFieldHide != true ? <div className="col-md-4 col-lg-3">
                                                <label className="catalog-label float-left mt-1"><Trans>Is This Priority?</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" className="custom-control-input" id={'customCheck-16a' + val.UniqNum} name="example" defaultValue={val.UPriority} defaultChecked={val.UPriority == "R" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Priority", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-16a' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                            {CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG") != null && CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG") != "" ? <div className="col-md-4 col-lg-3">
                                                <label className="catalog-label float-left mt-1" data-toggle="tooltip" title={"Check if item is tax exempt"}><Trans>Tax Exempt</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" data-toggle="tooltip" title={"Check if item is tax exempt"} className="custom-control-input" id={'customCheck-4' + val.UniqNum} name="example1" defaultValue={val.TaxFlag} defaultChecked={val.TaxFlag == "Y" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "TaxFlag", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-4' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                            {NonCat == "NONCAT" && (CookieService.getLocalStorageValues("Session_ADDTOCATALOG") == "Y") ? <div className="col-md-4 col-lg-3">
                                                <label className="catalog-label float-left mt-1"><Trans>Add Item to Catalog</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" className="custom-control-input" id={'customCheck-5' + val.UniqNum} name="example2" defaultChecked={val.AddToCtlgFlag == "1" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "AddToCtg", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-5' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-3 moredetail-content">
                                <label className="catalog-label d-block"><Trans>Notes</Trans></label>
                                <textarea id={'Notes' + val.UniqNum} className="form-control custom-textarea" defaultValue={val.UNotes} maxLength="1333" onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Notes", "")} />
                            </div>
                        </div>
                    </div>
                </div >)
            }
            else if (this.state.CerditCardEnabled == true && NonCat == "NONCAT" && Pricevalue != "Price on Request") {
                return (<div className="row mx-0 mb-3 py-3 item-box" key={val.Itemid + index}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="row col-md-1 pl-2 pr-0 mr-0 ml-0 custom-fav-image Image-ShoppingCart justify-content-center">
                                <Image
                                    src={imagePath}
                                    width={80}
                                    height={160}
                                    style={style.image}
                                    noImageSrc="/Images/noimage_new.png"
                                />
                                {/* <img src={imagePath} alt="fav_item" />*/}
                            </div>
                            <div className="col-md-11 custom-fav-container">
                                <div className="row">
                                    <div className="col-md-12 col-lg-9 Shopping-Cart-itemdiv">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="fav-item-title"><span className="float-left mt-2">{val.Itemid + "  " + itemNum}</span>
                                                    <div className="fav-item-maintenance-icon">
                                                        <input type="checkbox" id={'checkbox-' + index} className="checknow" checked={FavItem} onChange={() => this.LinelevelFavEdit(FavItem, val.UniqNum, val.Itemid)} data-backdrop="static" data-keyboard="false" />
                                                        <label htmlFor={'checkbox-' + index}>
                                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </label>
                                                    </div>
                                                </span>
                                            </div>
                                            <label className="col-md-12 item-details-value w-100 Mobile-descrip" style={{ "word-wrap": "break-word" }}>{val.ItemDescription}{NonCat == "NONCAT" && SupplierId == false && this.state.CerditCardEnabled != true ? <a className="edit-icon ml-2" onClick={() => this.LinelevelDescpEdit(val.Itemid, val.UniqNum, val.ItemDescription)} data-backdrop="static" data-keyboard="false" /> : null}</label>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label"><Trans>ID</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.Itemid}</label>
                                                    </div>
                                                    <div className="col-md-12 fav-maintence">
                                                        <label className="catalog-label"><Trans>Manufacturer</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.Manufacturer}</label>
                                                    </div>
                                                    <div className="col-md-12 fav-maintence">
                                                        <label className="catalog-label"><Trans>MFG Part #</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.Manufacturerpartnumber}</label>
                                                    </div>
                                                    <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label">UOM :</label>
                                                        <label className="item-details-value ml-2">{val.UOM}</label>
                                                    </div>
                                                    {val.RFQReq != null && val.RFQReq != undefined && val.RFQReq != "" ? <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label">RFQ :</label>
                                                        <label className="item-details-value ml-2">{val.RFQReq}</label>
                                                    </div> : null}

                                                </div>
                                            </div>
                                            {/*//[5/7/23, PC_ZE_85_APP_20, Dhevanesam R]*/}
                                            {graingerboolean ?
                                                <div className="col-md-12 fav-maintence">
                                                    {labeldot == "Orange" ?
                                                        <span className="cart-count" data-toggle="tooltip" style={la} title={msg}></span> : <span className="cart-count" data-toggle="tooltip" style={lal} title={msg} ></span>}
                                                    <span className="errorspan1" style={txt}>{msg}</span>
                                                </div> : null}
                                            {/*[11/16/21, PC_SP_02, Bug - 1744, Dhevanesam R]*/}
                                            {/*[11/18/21, PC_SP_01, Bug - 1811, Dhevanesam R]*/}
                                            <a href="#" className="more-details d-none" onClick={(e) => this.onAccordianClickitemlevel(e, index)}><Trans>More Details</Trans></a>
											<button className="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" onClick={() => this.DeleteItem(val.Itemid, val.UniqNum, val.SupplierID)} data-backdrop="static" data-keyboard="false">
                                                <img src="Images/remove-order.svg" className="removeorder-icon" alt="Remove Order" />
                                                {RemoveKey}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-3 pl-0 OSPD-prices Shopping-Cart-Quantity">
                                        <div className="float-right">
                                            <div className="w-100 float-left mb-2 ">
                                                <div className="float-right">
                                                    <span className="float-left m-2 ">Qty : </span>
                                                    <input type="text" value={val.Quantity} id={'Quantity' + val.UniqNum} className="form-control float-left catalog-inline-qty" maxLength="8" onChange={(e) => this.QuantityOnchange(val.UniqNum, val.Itemid, e)} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Quantity", "", e)} />
                                                </div>
                                            </div>
                                            {this.state.showprice == "0" && (PricePO == 0 || Pricevalue.toUpperCase() != "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Unit Price</Trans>:</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{Pricevalue}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && (PricePO != 0 && Pricevalue.toUpperCase() == "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Estimated Price</Trans>:</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{PricePO}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && Pricevalue.toUpperCase() != "PRICE ON REQUEST" ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Ext Price</Trans> :</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{this.ItemTotalCal(val.Price, val.Quantity)}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && (PricePO != 0 && Pricevalue.toUpperCase() == "PRICE ON REQUEST" && PricePO.toUpperCase() != "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Estimated Ext. Price</Trans>:</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{this.ItemTotalCal(val.PricePO, val.Quantity)}</span>
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 pt-3 mt-2 border-top accordian-body d-none" id={'order-row-' + index}>
                        <div className="row d-none">
                            <div className="col-md-12 col-lg-9 moredetail-content d-none">
                                <div className="row">
                                    {ZEUSNOCATALOGSITEFieldHide != true && NonCat == "NONCAT" ? <div className="col-md-6 col-lg-4 mb-2 OLDatepicker">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pl-3 pr-0 Delivery-date"><Trans>Delivery Date</Trans></label>
                                            <div className="col-lg-8 col-md-8">
                                                <DatePicker className="form-control"
                                                    id={'DueDate' + val.UniqNum}
                                                    fixedHeight
                                                    placeholderText="MM/DD/YYYY"
                                                    dateFormat="MM/dd/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    //dropdownMode="select"
                                                    minDate={new Date()}
                                                    selected={formateddate}
                                                    onChange={(value, e) => this.handleChangeItem(value, e, val.Itemid, val.UniqNum)}
                                                />
                                                {/*<input type="text" id={'DueDate' + val.UniqNum} className="form-control datepicker" data-provide="datepicker" placeholder="Select Date" defaultValue={val.UDueDate} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "DueDate")} />*/}
                                                <span className="calendar-icon-Line" onClick={() => $('#DueDate' + val.UniqNum).focus()} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    {NonCat == "NONCAT" && ZEUSNOCATALOGSITEFieldHide != true ? <div className="col-md-6 col-lg-4 mb-3">
                                        <label className="col-lg-4 col-md-4 catalog-label form-label p-0 Mobile-Attasho"><Trans>Attachment</Trans></label>
                                        <button id={val.UniqNum} className="btn btn-primary custom-btn-primary primary-btn px-5 attachment-btn " data-toggle="modal" data-target="#addattachment" onClick={() => this.LinelevelAtthEdit(val.Itemid, val.UniqNum)} data-backdrop="static" data-keyboard="false"> <Trans>Browse</Trans>

                                 </button>
                                    </div> : null}
                                    {/* <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-1"><Trans>Employee ID</Trans>:</label>
                                            <div className="col-lg-7 col-md-7 col-12">
                                                <select id={'EmpDD' + val.UniqNum} className="form-control EmployeCC" value={EmployName} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "EmployeeCC", "")}>
                                                    <option value="0"><Trans>Select</Trans></option>
                                                    {this.BindEmpCCToItem()}
                                                </select>
                                            </div>
                                            <span className="col-lg-7 col-md-7 col-12" style={{ "fontSize": "12px", }}><Select
                                                isDisabled={this.state.EmpIDDisable}
                                                id="OLEmplyDD"
                                                name="EmpDD"
                                                onChange={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "EmployeeCC", "", e)}
                                                value={val.EmpChgCode}
                                                options={this.state.EmplyeeNameDD}
                                            /></span>
                                        </div>
                                    </div>*/}
                                    {Showmachinepart == "Y" ? <div className="col-md-6 col-lg-4 mb-3">
                                        {ItemMachinePart != null && ItemMachinePart != undefined ? <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label"><Trans>Part</Trans> #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <select className="form-control" id={'MachinePart' + val.UniqNum} defaultValue={val.MachineNo} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")}>
                                                    <option><Trans>Select</Trans></option>
                                                    {this.BuildMachinePart(ItemMachinePart)}
                                                </select></div>
                                        </div> : <div className="row">{showmachinpartno == true ? <React.Fragment><label className="col-lg-4 col-md-4 catalog-label form-label"><Trans>Part</Trans> #</label>
                                            <div className="col-lg-8 col-md-8">
                                                    <input type="text" className="form-control" id={'MachinePart' + val.UniqNum} placeholder={t("Enter Part No")} maxLength="20" defaultValue={val.MachineNo} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")} />
                                            </div>
                                        </React.Fragment> : null}

                                            </div>}

                                    </div> : null}
                                    {/*Showmachinepart == "Y" && CookieService.getLocalStorageValues("Session_PARTLIST") == "Y" && ItemMachinePart == null && ItemMachinePart.length == 0 && NonCat == "NONCAT" ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">Part #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" className="form-control" id={'MachinePart' + val.UniqNum} placeholder="Enter Part No" maxLength="20" defaultValue={val.MachineNo} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")} />
                                            </div>
                                        </div>
                                    </div> : null*/}
                                    <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-0"><Trans>Charge Code</Trans></label>
                                            {this.state.ChargeCodeMultiple == true ? <div className="col-lg-7 col-md-8 ChargeCOdeDIvItem">
                                                <input type="text" className="form-control CHargeTextbox" placeholder={t("Select charge code")} id={'ItemChgCode' + val.UniqNum} value={val.ItemChgCode == null ? "" : val.ItemChgCode} onChange={(e) => this.handleChargecodechange(e, 1, val.UniqNum, val.Itemid)} maxLength="40" disabled={this.state.ChargeCodeMultiple} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "ItemChgCode", "", e)} />

                                            </div> : <div className="col-lg-8 col-md-8">
                                                    <input type="text" className="form-control" placeholder={t("Enter charge code")} id={'ItemChgCode' + val.UniqNum} value={val.ItemChgCode == null ? "" : val.ItemChgCode} onChange={(e) => this.handleChargecodechange(e, 1, val.UniqNum, val.Itemid)} maxLength="40" onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "ItemChgCode", "", e)} />

                                                </div>}
                                            {this.state.ChargeCodeMultiple == true ? <div className="col-lg-1 float-left px-0 ChargeCodeBtn">
                                                <button className="btn btn-primary custom-btn-primary primary-btn float-right" data-backdrop="static" data-keyboard="false" onClick={() => this.GetChargeCode(1, val.UniqNum, val.Itemid)}>CC</button>
                                            </div> : null}
                                        </div>
                                    </div>
                                    {this.state.ShowMachineNumber == true ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-0"><Trans>Asset ID</Trans></label>
                                            <div className="col-lg-8 col-md-8">
                                                {/*<select id={val.UniqNum} className="form-control">
                                                <option>Select</option>
                                                <option>Option</option>
                                            </select>*/}
                                                {this.state.MachineTable.length > 0 ? <select className="form-control" data-toggle="tooltip" title={"Click to select Asset ID or enter it in the textbox"} id={'MachineNo' + val.UniqNum} defaultValue={val.MachineRow} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachineNo", "")}>
                                                    <option>{t("Select")}</option>
                                                    {this.BuildMachineDD()}
                                                </select> : <input type="text" className="form-control" data-toggle="tooltip" title={"Click to select Asset ID or enter it in the textbox"} id={'MachineNo' + val.UniqNum} placeholder={t("Enter Asset ID")} maxLength="20" defaultValue={this.state.stanfordAssetId != "" ? this.state.stanfordAssetId :val.MachineRow} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachineNo", "")} />}
                                            </div>
                                        </div>
                                    </div> : null}
                                    {ShowPOLNField ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">PO #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" id={'PO' + val.UniqNum} className="form-control" placeholder={t("Enter value")} maxLength="20" defaultValue={val.PO} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "PO", "")} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    {ShowPOLNField ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">LN #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" id={'LN' + val.UniqNum} className="form-control" placeholder={t("Enter value")} maxLength="11" defaultValue={val.LN} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "LN", "")} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    <div className="col-md-12 col-lg-8 mt-2">
                                        <div className="row">
                                            {ZEUSNOCATALOGSITEFieldHide != true ? <div className="col-md-4 col-lg-4">
                                                <label className="catalog-label float-left mt-1"><Trans>Is This Priority?</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" className="custom-control-input" id={'customCheck-16a' + val.UniqNum} name="example" defaultValue={val.UPriority} defaultChecked={val.UPriority == "R" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Priority", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-16a' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                            {CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG") != null && CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG") != "" ? <div className="col-md-4 col-lg-4">
                                                <label className="catalog-label float-left mt-1" data-toggle="tooltip" title={"Check if item is tax exempt"}><Trans>Tax Exempt</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" data-toggle="tooltip" title={"Check if item is tax exempt"} className="custom-control-input" id={'customCheck-4' + val.UniqNum} name="example1" defaultValue={val.TaxFlag} defaultChecked={val.TaxFlag == "Y" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "TaxFlag", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-4' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                            {NonCat == "NONCAT" && (CookieService.getLocalStorageValues("Session_ADDTOCATALOG") == "Y") ? <div className="col-md-4 col-lg-4">
                                                <label className="catalog-label float-left mt-1"><Trans>Add Item to Catalog</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" className="custom-control-input" id={'customCheck-5' + val.UniqNum} name="example2" defaultChecked={val.AddToCtlgFlag == "1" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "AddToCtg", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-5' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-3 moredetail-content">
                                <label className="catalog-label d-block">Notes</label>
                                <textarea id={'Notes' + val.UniqNum} className="form-control custom-textarea" defaultValue={val.UNotes} maxLength="254" onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Notes", "")} />
                            </div>
                        </div>
                    </div>
                </div>)
            }
            else if (this.state.CerditCardEnabled == true && NonCat != "NONCAT") {
                return (<div className="row mx-0 mb-3 py-3 item-box" key={val.Itemid + index}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="row col-md-1 pl-2 pr-0 mr-0 ml-0 custom-fav-image Image-ShoppingCart justify-content-center">
                                <Image
                                    src={imagePath}
                                    width={80}
                                    height={160}
                                    style={style.image}
                                    noImageSrc="/Images/noimage_new.png"
                                />
                                {/* <img src={imagePath} alt="fav_item" />*/}
                            </div>
                            <div className="col-md-11 custom-fav-container">
                                <div className="row">
                                    <div className="col-md-12 col-lg-9 Shopping-Cart-itemdiv">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="fav-item-title"><span className="float-left mt-2">{val.Itemid + "  " + itemNum}</span>
                                                    <div className="fav-item-maintenance-icon">
                                                        <input type="checkbox" id={'checkbox-' + index} className="checknow" checked={FavItem} onChange={() => this.LinelevelFavEdit(FavItem, val.UniqNum, val.Itemid)} data-backdrop="static" data-keyboard="false" />
                                                        <label htmlFor={'checkbox-' + index}>
                                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                                    </g>
                                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </label>
                                                    </div>
                                                </span>
                                            </div>
                                            {/*[PC_TD_10, CAS - CSS Issue in description, Dhevanesam R*/}
                                            <label className="col-md-12 item-details-value w-100 Mobile-descrip" style={{ "word-wrap": "break-word" }}>{val.ItemDescription}{NonCat == "NONCAT" && SupplierId == false && this.state.CerditCardEnabled != true ? <a className="edit-icon ml-2" onClick={() => this.LinelevelDescpEdit(val.Itemid, val.UniqNum, val.ItemDescription)} data-backdrop="static" data-keyboard="false" /> : null}</label>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label"><Trans>ID</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.Itemid}</label>
                                                    </div>
                                                    <div className="col-md-12 fav-maintence">
                                                        <label className="catalog-label"><Trans>Manufacturer</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.Manufacturer}</label>
                                                    </div>
                                                    <div className="col-md-12 fav-maintence">
                                                        <label className="catalog-label"><Trans>MFG Part #</Trans> :</label>
                                                        <label className="item-details-value ml-2">{val.Manufacturerpartnumber}</label>
                                                    </div>
                                                    <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label">UOM :</label>
                                                        <label className="item-details-value ml-2">{val.UOM}</label>
                                                    </div>
                                                    {val.RFQReq != null && val.RFQReq != undefined && val.RFQReq != "" ? <div className="col-md-6 fav-maintence">
                                                        <label className="catalog-label">RFQ :</label>
                                                        <label className="item-details-value ml-2">{val.RFQReq}</label>
                                                    </div> : null}

                                                </div>
                                            </div>
                                            {/*//[5/7/23, PC_ZE_85_APP_20, Dhevanesam R]*/}
                                            {graingerboolean ?
                                                <div className="col-md-12 fav-maintence">
                                                    {labeldot == "Orange" ?
                                                        <span className="cart-count" data-toggle="tooltip" style={la} title={msg}></span> : <span className="cart-count" data-toggle="tooltip" style={lal} title={msg} ></span>}
                                                    <span className="errorspan1" style={txt}>{msg}</span>
                                                </div> : null}
                                            {/*[11/16/21, PC_SP_02, Bug - 1744, Dhevanesam R]*/}
                                            {/*[11/18/21, PC_SP_01, Bug - 1811, Dhevanesam R]*/}
                                            <a href="#" className="more-details d-none" onClick={(e) => this.onAccordianClickitemlevel(e, index)}><Trans>More Details</Trans></a>
											<button className="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" onClick={() => this.DeleteItem(val.Itemid, val.UniqNum, val.SupplierID)} data-backdrop="static" data-keyboard="false">
                                                <img src="Images/remove-order.svg" className="removeorder-icon" alt="Remove Order" />
                                                {RemoveKey}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-3 pl-0 OSPD-prices Shopping-Cart-Quantity">
                                        <div className="float-right">
                                            <div className="w-100 float-left mb-2 ">
                                                <div className="float-right">
                                                    <span className="float-left m-2 ">Qty : </span>
                                                    <input type="text" value={val.Quantity} id={'Quantity' + val.UniqNum} className="form-control float-left catalog-inline-qty" maxLength="8" onChange={(e) => this.QuantityOnchange(val.UniqNum, val.Itemid, e)} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Quantity", "", e)} />
                                                </div>
                                            </div>
                                            {this.state.showprice == "0" && (PricePO == 0 || Pricevalue.toUpperCase() != "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Unit Price</Trans> :</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{Pricevalue}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && (PricePO != 0 && Pricevalue.toUpperCase() == "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Estimated Price</Trans>:</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{PricePO}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && Pricevalue.toUpperCase() != "PRICE ON REQUEST" ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Ext Price</Trans>:</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{this.ItemTotalCal(val.Price, val.Quantity)}</span>
                                                </div>
                                            </div> : null}
                                            {this.state.showprice == "0" && (PricePO != 0 && Pricevalue.toUpperCase() == "PRICE ON REQUEST" && PricePO.toUpperCase() != "PRICE ON REQUEST") ? <div className="w-100 float-left">
                                                <div className="float-right">
                                                    <label className="float-left mt-2"><Trans>Estimated Ext. Price</Trans>:</label>
                                                    <span className="float-left mt-2 price-rate shopping-ext Font-14">{this.ItemTotalCal(val.PricePO, val.Quantity)}</span>
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 pt-3 mt-2 border-top accordian-body d-none" id={'order-row-' + index}>
                        <div className="row d-none">
                            <div className="col-md-12 col-lg-9 moredetail-content d-none">
                                <div className="row">
                                    {ZEUSNOCATALOGSITEFieldHide != true && NonCat == "NONCAT" ? <div className="col-md-6 col-lg-4 mb-2 OLDatepicker">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pl-3 pr-0 Delivery-date"><Trans>Delivery Date</Trans></label>
                                            <div className="col-lg-8 col-md-8">
                                                <DatePicker className="form-control"
                                                    id={'DueDate' + val.UniqNum}
                                                    fixedHeight
                                                    placeholderText="MM/DD/YYYY"
                                                    dateFormat="MM/dd/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    //dropdownMode="select"
                                                    minDate={new Date()}
                                                    selected={formateddate}
                                                    onChange={(value, e) => this.handleChangeItem(value, e, val.Itemid, val.UniqNum)}
                                                />
                                                {/*<input type="text" id={'DueDate' + val.UniqNum} className="form-control datepicker" data-provide="datepicker" placeholder="Select Date" defaultValue={val.UDueDate} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "DueDate")} />*/}
                                                <span className="calendar-icon-Line" onClick={() => $('#DueDate' + val.UniqNum).focus()} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    {NonCat == "NONCAT" && ZEUSNOCATALOGSITEFieldHide != true ? <div className="col-md-6 col-lg-4 mb-3">
                                        <label className="col-lg-4 col-md-4 catalog-label form-label p-0 Mobile-Attasho"><Trans>Attachment</Trans></label>
                                        <button id={val.UniqNum} className="btn btn-primary custom-btn-primary primary-btn px-5 attachment-btn " data-toggle="modal" data-target="#addattachment" onClick={() => this.LinelevelAtthEdit(val.Itemid, val.UniqNum)} data-backdrop="static" data-keyboard="false"> <Trans>Browse</Trans>
                                 </button>
                                    </div> : null}
                                    {/* <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-1"><Trans>Employee ID</Trans>:</label>
                                            <div className="col-lg-7 col-md-7 col-12">
                                                <select id={'EmpDD' + val.UniqNum} className="form-control EmployeCC" value={EmployName} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "EmployeeCC", "")}>
                                                    <option value="0"><Trans>Select</Trans></option>
                                                    {this.BindEmpCCToItem()}
                                                </select>
                                            </div>
                                            <span className="col-lg-7 col-md-7 col-12" style={{ "fontSize": "12px", }}><Select
                                                isDisabled={this.state.EmpIDDisable}
                                                id="OLEmplyDD"
                                                name="EmpDD"
                                                onChange={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "EmployeeCC", "", e)}
                                                value={val.EmpChgCode}
                                                options={this.state.EmplyeeNameDD}
                                            /></span>
                                        </div>
                                    </div>*/}

                                    {Showmachinepart == "Y" ? <div className="col-md-6 col-lg-4 mb-3">
                                        {ItemMachinePart != null && ItemMachinePart != undefined ? <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label"><Trans>Part</Trans> #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <select className="form-control" id={'MachinePart' + val.UniqNum} defaultValue={val.MachineNo} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")}>
                                                    <option><Trans>Select</Trans></option>
                                                    {this.BuildMachinePart(ItemMachinePart)}
                                                </select></div>
                                        </div> : <div className="row">{showmachinpartno == true ? <React.Fragment><label className="col-lg-4 col-md-4 catalog-label form-label"><Trans>Part</Trans> #</label>
                                            <div className="col-lg-8 col-md-8">
                                                    <input type="text" className="form-control" id={'MachinePart' + val.UniqNum} placeholder={t("Enter Part No")} maxLength="20" defaultValue={val.MachineNo} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")} />
                                            </div>
                                        </React.Fragment> : null}

                                            </div>}

                                    </div> : null}
                                    {/*Showmachinepart == "Y" && CookieService.getLocalStorageValues("Session_PARTLIST") == "Y" && ItemMachinePart == null && ItemMachinePart.length == 0 && NonCat == "NONCAT" ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">Part #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" className="form-control" id={'MachinePart' + val.UniqNum} placeholder="Enter Part No" maxLength="20" defaultValue={val.MachineNo} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachinePart", "")} />
                                            </div>
                                        </div>
                                    </div> : null*/}
                                    <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-0"><Trans>Charge Code</Trans></label>
                                            {this.state.ChargeCodeMultiple == true ? <div className="col-lg-7 col-md-8 ChargeCOdeDIvItem pl-3">
                                                <input type="text" className="form-control CHargeTextbox" placeholder={t("Select charge code")} id={'ItemChgCode' + val.UniqNum} value={val.ItemChgCode == null ? "" : val.ItemChgCode} onChange={(e) => this.handleChargecodechange(e, 1, val.UniqNum, val.Itemid)} maxLength="40" disabled={this.state.ChargeCodeMultiple} onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "ItemChgCode", "", e)} />

                                            </div> : <div className="col-lg-8 col-md-8">
                                                    <input type="text" className="form-control" placeholder={t("Enter charge code")} id={'ItemChgCode' + val.UniqNum} value={val.ItemChgCode == null ? "" : val.ItemChgCode} onChange={(e) => this.handleChargecodechange(e, 1, val.UniqNum, val.Itemid)} maxLength="40" onBlur={(e) => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "ItemChgCode", "", e)} />

                                                </div>}
                                            {this.state.ChargeCodeMultiple == true ? <div className="col-lg-1 float-left px-0 ChargeCodeBtn">
                                                <button className="btn btn-primary custom-btn-primary primary-btn float-right" data-backdrop="static" data-keyboard="false" onClick={() => this.GetChargeCode(1, val.UniqNum, val.Itemid)}>CC</button>
                                            </div> : null}
                                        </div>
                                    </div>
                                    {this.state.ShowMachineNumber == true ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label pr-0"><Trans>Asset ID</Trans></label>
                                            <div className="col-lg-8 col-md-8">
                                                {/*<select id={val.UniqNum} className="form-control">
                                                <option>Select</option>
                                                <option>Option</option>
                                            </select>*/}
                                                {this.state.MachineTable.length > 0 ? <select className="form-control" data-toggle="tooltip" title={"Click to select Asset ID or enter it in the textbox"} id={'MachineNo' + val.UniqNum} defaultValue={val.MachineRow} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachineNo", "")}>
                                                    <option>{t("Select")}</option>
                                                    {this.BuildMachineDD()}
                                                </select> : <input type="text" className="form-control" data-toggle="tooltip" title={"Click to select Asset ID or enter it in the textbox"} id={'MachineNo' + val.UniqNum} placeholder={t("Enter Asset ID")} maxLength="20" defaultValue={this.state.stanfordAssetId != "" ? this.state.stanfordAssetId :val.MachineRow} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "MachineNo", "")} />}
                                            </div>
                                        </div>
                                    </div> : null}
                                    {ShowPOLNField ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">PO #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" id={'PO' + val.UniqNum} className="form-control" placeholder={t("Enter value")} maxLength="20" defaultValue={val.PO} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "PO", "")} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    {ShowPOLNField ? <div className="col-md-6 col-lg-4 mb-3">
                                        <div className="row">
                                            <label className="col-lg-4 col-md-4 catalog-label form-label">LN #</label>
                                            <div className="col-lg-8 col-md-8">
                                                <input type="text" id={'LN' + val.UniqNum} className="form-control" placeholder={t("Enter value")} maxLength="11" defaultValue={val.LN} onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "LN", "")} />
                                            </div>
                                        </div>
                                    </div> : null}
                                    <div className="col-md-12 col-lg-8 mt-2">
                                        <div className="row">
                                            {ZEUSNOCATALOGSITEFieldHide != true ? <div className="col-md-4 col-lg-4">
                                                <label className="catalog-label float-left mt-1"><Trans>Is This Priority?</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" className="custom-control-input" id={'customCheck-16a' + val.UniqNum} name="example" defaultValue={val.UPriority} defaultChecked={val.UPriority == "R" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Priority", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-16a' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                            {CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG") != null && CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG") != "" ? <div className="col-md-4 col-lg-4">
                                                <label className="catalog-label float-left mt-1" data-toggle="tooltip" title={"Check if item is tax exempt"}><Trans>Tax Exempt</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" data-toggle="tooltip" title={"Check if item is tax exempt"} className="custom-control-input" id={'customCheck-4' + val.UniqNum} name="example1" defaultValue={val.TaxFlag} defaultChecked={val.TaxFlag == "Y" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "TaxFlag", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-4' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                            {NonCat == "NONCAT" && (CookieService.getLocalStorageValues("Session_ADDTOCATALOG") == "Y") ? <div className="col-md-4 col-lg-4">
                                                <label className="catalog-label float-left mt-1"><Trans>Add Item to Catalog</Trans></label>
                                                <div className="custom-control custom-checkbox mb-3 mt-0 item-custom-control ml-4 float-left">
                                                    <input type="checkbox" className="custom-control-input" id={'customCheck-5' + val.UniqNum} name="example2" defaultChecked={val.AddToCtlgFlag == "1" ? true : false} onChange={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "AddToCtg", "")} />
                                                    <label className="custom-control-label" htmlFor={'customCheck-5' + val.UniqNum} />
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-3 moredetail-content">
                                <label className="catalog-label d-block">Notes</label>
                                <textarea id={'Notes' + val.UniqNum} className="form-control custom-textarea" defaultValue={val.UNotes} maxLength="254" onBlur={() => this.LinelevelEmyEdit(val.Itemid, val.UniqNum, "Notes", "")} />
                            </div>
                        </div>
                    </div>
                </div>)
            }
        });

    }

    /*
     * RF No: SC_PC_22
     * In this method employee drop down is binded.
     * 
     * */
    BindEmpCCToItem = () => {
        //.log("EmployeeCC", this.state.EmployeeCC)
        return this.state.EmployeeCC.map((iresult, index) => {
            if (iresult != null && iresult != undefined) {
                let Emp_id = iresult.EMP_ID
                if (Emp_id != null && Emp_id != undefined && Emp_id != "") {
                    Emp_id = Emp_id.toUpperCase()
                }
                return (<option key={iresult.EMP_ID} value={Emp_id}>{iresult.ISA_EMPLOYEE_NAME}</option>)

            }
        });

    }
    /*
     * RF No: SC_PC_24
     * In this method Ship to drop down is binded.
     * 
     * */
    BuildShipToDD = () => {
        //[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
        const { t } = this.props;
        let Defaultship = this.state.defaultShipto;
        return this.state.ShipToDDValue.map((iresult, index) => {
            //if (iresult != null && iresult != undefined && this.state.ShipToDDValue.length > 1) {
            //    return (<option key={iresult.CUSTID} value={iresult.CUSTID}>{iresult.LOCNAME}</option>)

            //}
            //[5/7/23, PC_ZE_85_APP_17, Dhevanesam R]
            if (this.state.ShipToDDValue.length < 2) {

                return (<input type="text" key="1" className={this.state.pickupcheck ? "form-control disabled" : "form-control"} placeholder={t("Enter Location")} defaultValue={iresult.label} id="OLShipTo" readOnly />)
                //$("#OLShipTo").attr("disabled", "disabled"); 
            }
        });
        if (Defaultship != "" && Defaultship != null) {
            $('#OLShipTo').val(Defaultship);
            //document.getElementById("OLShipTo"). = true
        }

    }
    /*
     * RF No: SC_PC_25
     * In this method Machine drop down is binded.
     * 
     * */
    BuildMachineDD = () => {

        return this.state.MachineTable.map((iresult, index) => {
            if (iresult != null && iresult != undefined && this.state.MachineTable.length > 0) {
                return (<option key={iresult.ISA_MACHINE_NO + " " + iresult.ISA_MACHINE_NO} value={iresult.ISA_MACHINE_NO}>{iresult.ISA_MACHINE_NO}</option>)

            }

        });
    }
    BuildMachinePart = (MachinePart = []) => {
        if (MachinePart.length > 0) {
            return MachinePart.map((iresult, index) => {
                if (iresult != null && iresult != undefined && MachinePart.length > 0) {
                    return (<option key={iresult.ISA_MACHINE_NO} value={iresult.ISA_MACHINE_NO}>{iresult.ISA_MACHINE_NO}</option>)

                }

            });
        }
    }
    BuildTypeDD = () => {

        return this.state.TypeTable.map((iresult, index) => {
            if (iresult != null && iresult != undefined && this.state.TypeTable.length > 0) {
                return (<option key={iresult.ORDER_TYPE} value={iresult.ORDER_TYPE}>{iresult.ORDER_DESC}</option>)

            }

        });
    }
    /*
     * RF No: SC_PC_39
     * In this method edit description pop up is shown with the binded value.
     * 
     * */
    LinelevelDescpEdit = (ItemIDE, UniqIDE, Descrip) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        this.setState({ EditDescPopUp: true, ItemId: ItemIDE, UniqId: UniqIDE, Description: Descrip });
        let ExistDescp = $('#DespNewValue').val();

        $('#DespNewValue').val(Descrip);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        document.getElementById("DescriptionValidMsg").innerText = "";
        $('#Edit-description').modal('show');

    }
    // //[6/20/2024, Zeus - 297 - Real time inventory- Johnprinto D]
    qtyOnchange = (e, index, flag) => {
        let CartItems = this.state.CartItems;
        let quantity = CartItems[index].Quantity;
        if (flag === "QTY_INCREASED") {
            CartItems[index].Quantity = (isNaN(quantity) ? 0 : + quantity) + 1; // Increase the quantity for the given index
            this.LinelevelEmyEdit(CartItems[index].Itemid, CartItems[index].UniqNum, "Quantity", "", CartItems[index].Quantity)
        } else if (flag === "QTY_DECREASED") {
            CartItems[index].Quantity = (isNaN(quantity) ? 0 : + quantity) - 1; // Decrease the quantity for the given index
            this.LinelevelEmyEdit(CartItems[index].Itemid, CartItems[index].UniqNum, "Quantity", "", CartItems[index].Quantity)
        } else {
            if ((e.target.value == "") || (!isNaN(e.target.value) && parseInt(e.target.value) > 0 && parseInt(e.target.value) <= maxQty && (CartItems[index].RTI_QTY == 0 || parseInt(CartItems[index].RTI_QTY) >= e.target.value))) {
                CartItems[index].Quantity = e.target.value; // Assign the value directly for the given index
            }
        }
        this.setState({ CartItems });
    }
    /*
     * RF No: SC_PC_30 && SC_PC_31 && SC_PC_32 && SC_PC_33 && SC_PC_34 && SC_PC_35 && SC_PC_36 && SC_PC_37 && SC_PC_38 && SC_PC_39
     * In this method all the item level field update is done by using action UpdateItemDesp.
     * 
     * */
    LinelevelEmyEdit = (ItemIDE, LineNo, fliedTitle, DueDateValue, event) => {
        const { t } = this.props;
        let UpdateItemDetails;
        let DueIndex;
        let filetoupdate = "";
        let targetval = "";
        let Quantityvalidate = 0
        let Attachmentfilevalidation = true
        let ValidChargeCode = true
        let { CartItems } = this.state;
        let isPageLoad = true;
        let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
        if (this.state.AddAttachPUp == true) {
            document.getElementById("AttachError").innerText = ""
        }

        switch (fliedTitle) {
            case "DESCR254": {
                targetval = $('#DespNewValue').val();
                if (targetval != "" && targetval != null) {
                    filetoupdate = "DESCR254"
                    ItemIDE = this.state.ItemId;
                    LineNo = this.state.UniqId;
                    document.getElementById("DescriptionValidMsg").innerText = "";
                    //Zeus  281 - Zeus 2.0 - Non catalog - Duplicate item gets added when use '&' in Description -  Dhinesh R
                    let Description = targetval.trim();
                    this.setState({ Description: Description });
                }
                else {
                    document.getElementById("DescriptionValidMsg").innerText = (t("Description required for the NSTK item."))
                }
                break;
            }
            case "EmployeeCC": {
                debugger
                let id = "#EmpDD" + LineNo;
                targetval = event.value;
                filetoupdate = "EMP_CHG_CODE";
                //let data = { label: event.label, value: event.value };
                CartItems[index].EmpChgCode = event.value;
                this.setState({ CartItems: CartItems, lineLevelEmp: event.value });
                break;
            }
            case "DueDate": {

                targetval = DueDateValue;
                filetoupdate = "DUE_DATE";
                break;
            }
            case "MachineNo": {
                let id = "MachineNo" + LineNo;
                targetval = $('#' + id).val();
                filetoupdate = "MACHINE_ROW";
                //$('#' + id).val(targetval)
                break;
            }
            case "MachinePart": {
                let id = "MachinePart" + LineNo;
                targetval = $('#' + id).val();
                filetoupdate = "ISA_USER_DEFINED_1";
                //$('#' + id).val(targetval)
                break;
            }
            case "PO": {
                let id = "PO" + LineNo;
                targetval = $('#' + id).val();
                filetoupdate = "PO_ID";
                break;
            }
            case "LN": {
                let id = "LN" + LineNo;
                targetval = $('#' + id).val();
                filetoupdate = "PO_LINE";
                break;
            }
            case "Notes": {
                let id = "Notes" + LineNo;
                targetval = $('#' + id).val();
                filetoupdate = "ISA_CUST_NOTES";
                break;
            }
            case "Quantity": {
                targetval = event + "";
                let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
                filetoupdate = "QUANTITY";
                if (targetval == null || targetval == "") {
                    let QuantityExit = this.state.PagelaodCartValue[index].Quantity
                    filetoupdate = ""
                    CartItems[index].Quantity = QuantityExit
                    this.setState(CartItems);
                    return
                }
                isPageLoad = false;
                //else {

                //}
                //this.setState(CartItems);
                break;
            }
            case "ItemChgCode": {
                debugger
                let id = "ItemChgCode" + LineNo;
                targetval = this.state.ChargeCodeMultiple == false ? $('#' + id).val() : this.state.ChargeSelected;
                filetoupdate = "ITEM_CHG_CODE";
                if (targetval != null && targetval != undefined && targetval != "") {
                    let newvalueFilledL = targetval
                    let CheckChargeCOde = newvalueFilledL.replaceAll(/(\r\n|\n|\r)/gm, "")
                    if (CheckChargeCOde != null && CheckChargeCOde != undefined && CheckChargeCOde != "") {
                        if (CheckChargeCOde.length == 0) {
                            ValidChargeCode = false
                        }
                    }
                    else {
                        ValidChargeCode = false
                    }
                }
                else {
                    ValidChargeCode = false
                }
                break;
            }
            case "Priority": {

                let id = "customCheck-16a" + LineNo;
                let { CartItems } = this.state;
                let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
                let ExistValue = CartItems[index].UPriority;
                filetoupdate = "PRIORITY";
                targetval = ExistValue != "R" ? "R" : " ";
                break;
            }
            case "TaxFlag": {

                let { CartItems } = this.state;
                let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
                let ExistValue = CartItems[index].TaxFlag;
                filetoupdate = "TAX_FLAG";
                targetval = ExistValue != "Y" ? "Y" : "0";
                break;
            }
            case "AddToCtg": {

                let { CartItems } = this.state;
                let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
                let ExistValue = CartItems[index].AddToCtlgFlag;
                filetoupdate = "ADD_TO_CTLG";
                targetval = ExistValue != "1" ? "1" : "0";

                break;
            }
            case "Attachment": {
                debugger
                ItemIDE = this.state.ItemId;
                LineNo = this.state.UniqId;
                let attachmentStr = "";
                filetoupdate = "FILE_PATH"
                if (this.state.attachmentPathArr != null && this.state.attachmentPathArr != undefined) {
                    if (this.state.attachmentPathArr.length > 0) {
                        attachmentStr = this.state.attachmentPathArr.map(e => e.FilePath).join("|");
                        if (attachmentStr.length > 254) {
                            Attachmentfilevalidation = false
                            //alert(t("You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names."))
                            swal({
                                allowOutsideClick: false,
                                closeOnClickOutside: false,
                                text: t("You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names.")
                            })
                        }
                        else {
                            targetval = attachmentStr
                        }
                    }
                }
                else {
                    //alert(t("No file is selected"))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("No file is selected")
                    })
                }
                //.log("attachmentPathArr_Befor_update", this.state.attachmentPathArr)
            }
            case "Store": {
                LineNo = "";
                ItemIDE = "";
                filetoupdate = "RTI_STORE";
                targetval = event;
                break;
            }

        }

        if (filetoupdate != "" && Quantityvalidate == 0 && Attachmentfilevalidation == true && ValidChargeCode == true) {
            UpdateItemDetails = {
                userID: this.state.Login_UserId,
                userBU: this.state.Login_UserBU,
                Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                fldToUpdate: filetoupdate,
                ItemID: ItemIDE,
                LineNum: LineNo,
                newValue: targetval.trim()
            }
            ShoppingCartAction.UpdateItemDesp(UpdateItemDetails);
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            this.setState({ ItemId: ItemIDE, UniqId: LineNo, ActiveFieldToUpdate: fliedTitle, isPageLoad: isPageLoad })            
        }
    }
    QuantityOnchange = (LineNo, ItemIDE, event) => {
        debugger
        let Quantityvalidate = 1
        //let targetval = "";
        //let id = "Quantity" + LineNo;
        let { CartItems } = this.state;
        let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
        // targetval = $('#' + id).val();
        const re = SDIConstants.quantityRegEX;
        let txtQTY
        if (re.test(event.target.value)) {
            txtQTY = Math.round(event.target.value);
            console.log("txtQTY", txtQTY)
            if (txtQTY != "0") {
                Quantityvalidate = 0
            }
        }
        else if (!event.target.value) {
            Quantityvalidate = 0
        }
        if (Quantityvalidate == 0) {
            CartItems[index].Quantity = event.target.value;
            //filetoupdate = "QUANTITY";
            this.setState(CartItems);
        }
    }

    /*
     * RF No : SC_PC_27
     * This is emit method invoked by the compouentwillmount when action emit UpdateStatus.
     * Cart item data is update and rebinded.
     * */
    UpdateItemStatus = () => {

        //.log("ShoppingCart Item Details Update", ShoppingCartStore.UpdateMessage);
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let { CartItems } = this.state;
        let { ItemId } = this.state;
        let { UniqId } = this.state;
        let { ActiveFieldToUpdate } = this.state;
        let index = CartItems.findIndex(x => x.Itemid == ItemId && x.UniqNum == UniqId);
        switch (ActiveFieldToUpdate) {
            case "DESCR254":
                {
                    let Nvalue = $('#DespNewValue').val();
                    let ExistValue = CartItems[index].ItemDescription;
                    CartItems[index].ItemDescription = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                    this.setState(CartItems);
                    $('#Edit-description').modal('hide');
                    break;
                }
            case "EmployeeCC": {
                debugger
                let id = "EmpDD" + UniqId;
                let Nvalue = $('#' + id).val();
                let ExistValue = CartItems[index].EmpChgCode;
                CartItems[index].EmpChgCode = ShoppingCartStore.UpdateMessage.Update_Success != "" ? this.state.lineLevelEmp : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "ItemChgCode": {
                debugger
                let id = "ItemChgCode" + UniqId;
                let Nvalue = this.state.ChargeCodeMultiple == false ? $('#' + id).val() : this.state.ChargeSelected;
                let ExistValue = CartItems[index].ItemChgCode;
                CartItems[index].ItemChgCode = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "DueDate": {

                let id = "DueDate" + UniqId;
                let Nvalue = this.state.ItemDate
                //.log("Duedate", Nvalue);
                let ExistValue = CartItems[index].UDueDate;
                CartItems[index].UDueDate = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "MachineNo": {

                let id = "MachineNo" + UniqId;
                let Nvalue = $('#' + id).val();
                let ExistValue = CartItems[index].MachineRow;
                CartItems[index].MachineRow = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "MachinePart": {

                let id = "MachinePart" + UniqId;
                let Nvalue = $('#' + id).val();
                let ExistValue = CartItems[index].MachineNo;
                CartItems[index].MachineNo = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "PO": {

                let id = "PO" + UniqId;
                let Nvalue = $('#' + id).val();
                let ExistValue = CartItems[index].PO;
                CartItems[index].PO = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "LN": {

                let id = "LN" + UniqId;
                let Nvalue = $('#' + id).val();
                let ExistValue = CartItems[index].LN;
                CartItems[index].LN = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "Notes": {

                let id = "Notes" + UniqId;
                let Nvalue = $('#' + id).val();
                let ExistValue = CartItems[index].UNotes;
                CartItems[index].UNotes = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;

            }
            case "Quantity": {
                debugger
                let id = "Quantity" + UniqId;
                let { PagelaodCartValue } = this.state;
                let Nvalue = $('#' + id).val();
                if (Nvalue == null || Nvalue == "") {
                    Nvalue = CartItems[index].Quantity
                }
                let ExistValue = CartItems[index].Quantity;
                CartItems[index].Quantity = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                PagelaodCartValue[index].Quantity = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState({ CartItems: CartItems, PagelaodCartValue: PagelaodCartValue });
                break;
            }
            case "Priority": {

                let Nvalue = " ";
                let ExistValue = CartItems[index].UPriority;
                if (ExistValue != "R") {
                    Nvalue = "R"
                }
                CartItems[index].UPriority = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "TaxFlag": {

                let Nvalue = "0";
                let ExistValue = CartItems[index].TaxFlag;
                if (ExistValue != "Y") {
                    Nvalue = "Y"
                }
                CartItems[index].TaxFlag = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "AddToCtg": {

                let Nvalue = "0";
                let ExistValue = CartItems[index].AddToCtlgFlag;
                if (ExistValue != "1") {
                    Nvalue = "1"
                }
                CartItems[index].AddToCtlgFlag = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState(CartItems);
                break;
            }
            case "Attachment": {
                debugger
                let Attachments = [];
                //.log("attachmentPathArr_After_update", this.state.attachmentPathArr)
                if (this.state.attachmentPathArr != null && this.state.attachmentPathArr != undefined) {
                    this.state.attachmentPathArr.map((value, I) => {
                        Attachments.push(value.FilePath)
                    });
                }
                let Nvalue = Attachments//.map(e => e.FilePath).join("|");
                let ExistValue = CartItems[index].FilePath;
                if (this.state.removeattach != null && this.state.removeattach != undefined) {
                    if (this.state.removeattach.length > 0) {
                        this.state.removeattach.map((val, index) => {

                            let removeBO = {
                                filePath: val.FilePath
                            }
                            ShoppingCartAction.removeAttachment(removeBO);
                        })
                    }
                }
                CartItems[index].FilePath = ShoppingCartStore.UpdateMessage.Update_Success != "" ? Nvalue : ExistValue;
                this.setState({ CartItems, removeattach: [] });
                break;
            }
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /*
     * RF No: SC_PC_43 
     * In this method the order level Duedate or employecc is updated to the unfilled item DUedate and employee.
     * */
    BindEmpCCToItemOL = (FieldType, DueDateOL, e) => {
        debugger
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let ValidChargeCode = true
        let childItems;
        let newvalueFilled;
        let EmpID = e;
        let ItemDetailBo = [];
        let UpdateOrderBO;
        let filetoupdate
        let OlChargeCOde = this.state.OlChargeCOde
		let isEMPDDCh = false;
		let locOLEmployeeCCErr = this.state.OLEmployeeCCErr;
		let locOLDueDateErr = this.state.OLDueDateErr;
		let locChargeCodevalidateErr = this.state.ChargeCodevalidateErr;
        switch (FieldType) {
            case "EmplyCCOL": {
                childItems = [];
                //childItems = this.state.CartItems.filter((item, index) => item.EmpChgCode == null || item.EmpChgCode == "" || item.EmpChgCode == "0" || item.EmpChgCode == " ");
                newvalueFilled = EmpID.value;
                filetoupdate = "EMP_CHG_CODE";
                DueDateOL = this.state.OLDueDateVal;
				locOLEmployeeCCErr = false
                tempEMPEmail = EmpID.email;
                if (this.state.Login_UserBU == "I0635" && this.state.ValidatedWo.trim() != "" && this.state.disableWo == true) {
                    isEMPDDCh = true;
				}
				if (this.state.OLEmployeeCCErr) {
					this.handleShoppingCartValidation("EMPLOYEE");
				}
                break;
            }
            case "OLDueDate": {
                debugger
                childItems = this.state.CartItems.filter((item, index) => item.UDueDate == null || item.UDueDate == "");
                newvalueFilled = DueDateOL;
				locOLDueDateErr = false;
				filetoupdate = "DUE_DATE";
				if (this.state.OLDueDateErr) {
					this.handleShoppingCartValidation("DUEDATE");
				}
                break;
            }
            case "ChargeCode": {
                childItems = this.state.CartItems.filter((item, index) => item.ItemChgCode == null || item.ItemChgCode == "");
                newvalueFilled = this.state.ChargeSelected == "" || this.state.ChargeSelected == null || this.state.ChargeSelected == undefined ? this.state.OlChargeCOde : this.state.ChargeSelected;
                filetoupdate = "ITEM_CHG_CODE";
                if (newvalueFilled != null && newvalueFilled != undefined && newvalueFilled != "") {
                    let newvalueFilledL = newvalueFilled
                    let CheckChargeCOde = newvalueFilledL.replaceAll(/(\r\n|\n|\r)/gm, "")
                    if (CheckChargeCOde != null && CheckChargeCOde != undefined && CheckChargeCOde != "") {
                        if (CheckChargeCOde.length == 0) {
                            ValidChargeCode = false
                        }
                    }
                    else {
                        ValidChargeCode = false
                    }
                }
                else {
                    ValidChargeCode = false
                }
                OlChargeCOde = newvalueFilled;
                DueDateOL = this.state.OLDueDateVal
                break;
            }
        }
        debugger
        if (childItems.length > 0 && ValidChargeCode == true) {
            childItems.map((SubItem, I) => {
                ItemDetailBo[I] = {
                    ItemID: SubItem.Itemid,
                    LineNum: SubItem.UniqNum,
                    newValue: newvalueFilled
                }
            });
            UpdateOrderBO = {
                userID: this.state.Login_UserId,
                userBU: this.state.Login_UserBU,
                Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                fldToUpdate: filetoupdate,
                Session_CARTFAIL: CookieService.getLocalStorageValues("Session_CARTFAIL"),
                LineItemData: ItemDetailBo
            }
			locChargeCodevalidateErr = false;
			if (this.state.ChargeCodevalidateErr) {
				this.handleShoppingCartValidation("CHARGECODE");
			}
			ShoppingCartAction.UpdateCartEmpCC(UpdateOrderBO)
			this.setState({
				ActiveFieldToUpdate: FieldType, OrderEmpyId: EmpID, OLDueDateVal: DueDateOL, OlChargeCOde: OlChargeCOde,
				OLEmployeeCCErr: locOLEmployeeCCErr, OLDueDateErr: locOLDueDateErr, ChargeCodevalidateErr: locChargeCodevalidateErr,
			})}
        else {
            //.log("newvalueFilled", newvalueFilled)
			this.setState({
				OrderEmpyId: EmpID, OLDueDateVal: DueDateOL, OlChargeCOde: OlChargeCOde, isEMPDDCh: isEMPDDCh,
				OLEmployeeCCErr: locOLEmployeeCCErr, OLDueDateErr: locOLDueDateErr, ChargeCodevalidateErr: locChargeCodevalidateErr,
			})
			if (isEMPDDCh) {
                this.handleWoValidation(true);
            } else {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
            }
        }
    }
    /*
    * This method is invoked when the UpdateCartEmpCC() action response is emitted.
    * */
    UpdateitemStatusOL = () => {
        debugger
        let { CartItems } = this.state;
        let UpdateStatusArray = JSON.parse(ShoppingCartStore.UpdateMessageOL)
        //.log("ShoppingCart Item Details Update", UpdateStatusArray);
        //.log("Item Details Update", CartItems);
        if (UpdateStatusArray != []) {
            UpdateStatusArray.map((SubItem, I) => {

                let index = CartItems.findIndex(x => x.Itemid == SubItem.ItemId);
                //let id = "EmpDD" + CartItems[index].UniqNum;
                if (index != -1) {
                    switch (this.state.ActiveFieldToUpdate) {
                        case "EmplyCCOL": {
                            let Nvalue = this.state.OrderEmpyId;
                            let ExistValue = CartItems[index].EmpChgCode;
                            CartItems[index].EmpChgCode = SubItem.Update_Success != null ? Nvalue : ExistValue;
                            let id = "EmpDD" + CartItems[index].UniqNum;
                            $('#' + id).val(SubItem.Update_Success != null ? Nvalue : ExistValue)
                            break;
                        }
                        case "OLDueDate": {
                            debugger
                            let Nvalue = this.state.OLDueDateVal;
                            let ExistValue = CartItems[index].UDueDate;
                            CartItems[index].UDueDate = SubItem.Update_Success != null ? Nvalue : ExistValue;
                            let id = "DueDate" + CartItems[index].UniqNum;
                            $('#' + id).val(SubItem.Update_Success != null ? this.state.startDate : "");
                            break;
                        }
                        case "ChargeCode": {
                            debugger
                            let Nvalue = this.state.OlChargeCOde;
                            let ExistValue = CartItems[index].ItemChgCode;
                            CartItems[index].ItemChgCode = SubItem.Update_Success != null ? Nvalue : ExistValue;
                            let id = "ItemChgCode" + CartItems[index].UniqNum;
                            $('#' + id).val(SubItem.Update_Success != null ? Nvalue : ExistValue)
                            break;
                        }
                    }
                }

            });
            this.setState({ CartItems: CartItems });

        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /*
     * In this method the react datepicker is binded to the order level.
     * */
    ShowDatePicker = () => {
        return (
			<DatePicker className={`form-control datepickerShopCart ${this.state.OLDueDateErr ? "border-color-red" : ""}`}
                id="OLDueDate"
                fixedHeight
                placeholderText="MM/DD/YYYY"
                dateFormat="MM/dd/yyyy"
                showMonthDropdown
                showYearDropdown
                minDate={new Date()}
                selected={this.state.startDate}
                onChange={this.handleChange}

            />
        )
    }
    /*
     * This method is invoke when the order due date is changed.
     * */
    handleChange = date => {
        debugger
        this.setState({
            startDate: date
        });
        //.log("Date", date);
        let dateOL = ""
        if (date != null) {
            dateOL = this.handledate(date);
            //.log("DateValidate", dateOL);
            //OLDueDateVal = dateOL;
            this.BindEmpCCToItemOL("OLDueDate", dateOL, this.state.OrderEmpyId);
        }
        else {
            this.setState({ OLDueDateVal: dateOL })
        }
	};
	handleShoppingCartValidation = (fieldName) => {
		//[10/11/23, Bug - 4276, Fix the error error message displayed when user entered with VALID WO, Johnprinto]
        //[05/17/2024, Zeus 307] - Use cart work order required flag validating the work order field - Kishore
        let locWOErr = this.state.disableWo == true || CookieService.getLocalStorageValues("Session_CARTWOREQ") == "N" ? false : true;
		let locOLEmployeeCCErr = this.state.OLEmployeeCCErr;
		let locOLDueDateErr = this.state.OLDueDateErr;
		let locOLShipToErr = this.state.OLShipToErr;
		let locDeliveryPointErr = this.state.DeliveryPointErr;
		let locChargeCodevalidateErr = this.state.ChargeCodevalidateErr;
		let locPickupStoreErr = this.state.PickupStoreErr;
        let locuserAddressErr = this.state.userAddressErr;
        let locNotesErr = this.state.notesReq ? this.state.notesErr : false;

		switch (fieldName) {
			case "WO": {
				locWOErr = false
				break;
			}
			case "EMPLOYEE": {
				locOLEmployeeCCErr = false;
				break;
			}
			case "DUEDATE": {
				locOLDueDateErr = false
				break;
			}
			case "SHIPTO": {
				locOLShipToErr = false;
				break;
			}
			case "DELIVERYPOINT": {
				locDeliveryPointErr = false
				break;
			}
			case "CHARGECODE": {
				locChargeCodevalidateErr = false
				break;
			}
			case "PICKUP": {
				locPickupStoreErr = false;
				break;
			}
			case "ADDRESS": {
				locuserAddressErr = false
				break;
            }
            case "NOTES": {
                locNotesErr = false
                break;
            }
		}
		if (!locWOErr && !locOLEmployeeCCErr && !locOLDueDateErr && !locOLShipToErr && !locDeliveryPointErr && !locChargeCodevalidateErr
            && !locPickupStoreErr && !locuserAddressErr && !locNotesErr) {
			this.setState({ StatusErrVal: "", ErrorMsgVal: "" });
		}
	}
    /*
     *This method is invoked when the item level due date is changed. 
     * */
    handleChangeItem(value, e, Itemid, UniqNum) {
        debugger
        //.log("Date", value);
        let dateOL = ""
        if (value != null) {
            dateOL = this.handledate(value);
        }
        this.setState({
            ItemDate: dateOL
        });
        this.LinelevelEmyEdit(Itemid, UniqNum, "DueDate", dateOL)
    }
    /*
     * This method is used to format the datepicker value to the required date format.
     * */
    handledate = (date) => {
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear())
    }
    /*
     * This method is used to format the date to the datepicker select format, so that it can be binded to the datepicker.
     * */
    convertdate = (date) => {
        var dateConverted;
        if (date != null && date != "" && date != " ") {
            var dateString = date;
            var dataSplit = dateString.split('/');
            if (dataSplit == undefined || dataSplit == null || dataSplit == "" || dataSplit == " " || dataSplit.length == 1) {
                dataSplit = dateString.split('-');
            }
            //.log("Dateformated", dataSplit);

            if (dataSplit[2].split(" ").length > 1) {

                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[0] - 1, dataSplit[1], hora[0], hora[1]);

            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[0] - 1, dataSplit[1]);
            }
        }
        //.log("Dateformated01", dateConverted);
        return (dateConverted)
    }
    //Quick Item 
    /*
     *This method is reused form the non catalog require page.
     * To check the item id for quick item is valid or not.
     * */
    quickItemClick = (event, type) => {
        debugger
        const { t } = this.props;
        event.preventDefault()
        //$('#Fav-Menu-Ul').addClass('hide');
        //[5/24/22, Bug - 2129, PC_SP_01, Dhevanesam R]
        $('.dropdown-submenu').addClass('d-none');
        let txtItemIDValue = ""

        if (type == 1) {
            txtItemIDValue = $('#QuickItem').val();
            if (txtItemIDValue) {
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                $('#quickconfirm').modal('show');
                this.setState({ strCplusitemid: "1", txtItemID: txtItemIDValue, QuickitemEnabled: true })
                //.log("txtItemIDValue", txtItemIDValue)
                //$(`#${SDIConstants.SDILoader}`).removeClass('hide');
            }
            else {
                //alert(t("No Item Number entered for Quick Item Entry"))
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("No Item Number entered for Quick Item Entry")
                })
            }


        }
        else {
            if (event.target.id != "") {
                txtItemIDValue = event.target.id
                $('#QuickItem').val(txtItemIDValue);
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                $('#quickconfirm').modal('show');
                this.setState({ strCplusitemid: "1", txtItemID: txtItemIDValue, QuickitemEnabled: false })
                //.log("txtItemIDValue", txtItemIDValue)

            }
        }
        if (txtItemIDValue) {
            this.state.CartItems.map((val, index) => {
                if (val.Itemid == txtItemIDValue && txtItemIDValue.slice(0, 6) == "NONCAT") {
                    AlreadyInCart = 1
                    QuickItemId = txtItemIDValue
                }
            })
        }
        
    }
    keyPress(e) {
        if (e.keyCode == 13) {
            this.quickItemClick(e, 1);
        }
    };
    /*
     * This is the response mwthod for quick item check.
     * Quick item pop up is shown.
     * */
    loadQuickItem = () => {
        const { t } = this.props;
        let quickItemChkRes = JSON.parse(ShoppingCartStore.quickItemcheck)[0];
        qckKeycounter = qckKeycounter + 1;
        let txtItemIDValue = $('#QuickItem').val();
        if (quickItemChkRes.strCplusitemid != "0") {
            $('#quickconfirm').modal('show');
            this.setState({ strCplusitemid: quickItemChkRes.strCplusitemid, txtItemID: txtItemIDValue })
        } else {
            this.setState({ strCplusitemid: "0", txtItemID: "" });
            //alert(t("Invalid Item Number entered for Quick Item Entry"));
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Invalid Item Number entered for Quick Item Entry")
            })
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /*
     * This method is used to close the quick item pop up.
     * */
    closePopup = (message) => {
        const { t } = this.props;
        $('#quickconfirm').modal('hide');
        //[5/24/22, Bug - 2129, PC_SP_02, Dhevanesam R]
        $('.dropdown-submenu').removeClass('d-none');
        if (this.state.QuickitemEnabled == false) {
            $('#QuickItem').val("")
        }
        this.setState({ strCplusitemid: "0", txtItemID: "" });
        //.log("Hit", message);
        if (message == 1) {
            //alert(t("Item has been added to cart"));
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Item has been added to cart")
            })

            this.refreshMenu();
            this.LoadCartItem();
        } else if (message == 2) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Invalid Item Number entered for Quick Item Entry")
            })
           // alert(t("Invalid Item Number entered for Quick Item Entry"));
        };
    };
    /*
     * This method is used to refresh the menu bar.
     * */
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""

        };
        MenuAction.getMasterData(masterBO);
    };
    // Add item to Fav
    /*
     * RF No: SC_PC_40
     * This method is invoke when the fav icon in the level is clicked.
     * */
	LinelevelFavEdit = (ItemChecked, UniqID, ItemId) => {

		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		let { CartItems } = this.state;
		//[04/08/22, CAS - 31, Dhevanesam R]
		const { t } = this.props;
		let index = CartItems.findIndex(x => x.Itemid == ItemId && x.UniqNum == UniqID);
		if (!ItemChecked) {
			let AddToFavItemBO = {
				userID: this.state.Login_UserId,
				userBU: this.state.Login_UserBU,
				ItemID: ItemId,
				Item_Description: CartItems[index].ItemDescription,
				Item_MFG: CartItems[index].Manufacturer,
				Supplier_ID: CartItems[index].SupplierID,
				Item_MFGPartNum: CartItems[index].Manufacturerpartnumber,
				Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
				Session_ACTIVEUSERS: CookieService.getLocalStorageValues("Session_ACTIVEUSERS"),
				Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
				CurrentPage_Url: "/SDIXZEUS/ShoppingCart",
				Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
				Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
				Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
				Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
				Session_CARTFAIL: "N",
				Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
				Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
				Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
				Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
				Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
				Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
				Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
				Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
				Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
				Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
				Favitemprice: CartItems[index].Price == " " || CartItems[index].Price.toLowerCase() == "price on request" || CartItems[index].Price == "-" ? "" : CartItems[index].Price,
				Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
				Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")
			};
			ShoppingCartAction.GetAddFavItemDetail(AddToFavItemBO);
			//let FavPopTitle = ItemChecked == true ? t("Edit Favorites") : t("Add Favorites");
			//this.setState({ FavorPopUptitle: FavPopTitle })
		}
		else {
			let deleteFavBO = {
				ItemId: ItemId,
				Supplier_ID: CartItems[index].SupplierID,
				MFG: CartItems[index].Manufacturer,
				MFGPartNo: CartItems[index].Manufacturerpartnumber,
				Session_USERID: this.state.Login_UserId,
				Session_BUSUNIT: this.state.Login_UserBU
			}

			FavItemMainAction.DeletFavItem(deleteFavBO);
		}
	}

	//[Zeus 172, 08/23/2023] - Item Unfavorite response - Kishore
	DelFavResponse = () => {
		const { t } = this.props;
		let Response = JSON.parse(FavItemMainStore.PageLoadData);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');

		if (Response.ErrorTable[0].error != "True") {

			if (Response.ResultFlag[0].DeleteResult == "True") {

				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Favorite item removed successfully")
				});
				MenuAction.refreshMasterData();
				this.setState({ FavMenuSub: Response.FavSubMenu })
			}
			else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Technical Issue Try again")
				});
			}
		}
		else {
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Technical Issue Try again")
			});
		}
	}
    /*
     * Favorite item pop up is shown with  item value. choosen for favorite.
     * */
    BindAddFavPopUp = () => {

        let FavItemDetailsAry = JSON.parse(ShoppingCartStore.FavItemDetailsAry);
        //.log("AddFavdetails", FavItemDetailsAry)
        if (FavItemDetailsAry.AlertMsg != undefined && FavItemDetailsAry.AlertMsg.length > 0 && FavItemDetailsAry.AlertMsg[0].Message != null && FavItemDetailsAry.AlertMsg[0].Message.trim() != "") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Invalid item ID"
            });
        }
        else {
            let Update_Session = FavItemDetailsAry.Update_Session;
            if (Update_Session != null && Update_Session != "") {
                if (Update_Session.length > 0) {
                    if (Update_Session[0].Session_TESTMODE != null && Update_Session[0].Session_TESTMODE != "") {
                        CookieService.updateLocalStorageValues("Session_TESTMODE", Update_Session[0].Session_TESTMODE);
                    }
                    if (Update_Session[0].Session_Desc != null && Update_Session[0].Session_Desc != "") {
                        CookieService.updateLocalStorageValues("Session_Desc", Update_Session[0].Session_Desc);
                    }
                    if (Update_Session[0].dtCM != null && Update_Session[0].dtCM != "") {
                        CookieService.updateLocalStorageValues("Session_dtCM", Update_Session[0].dtCM);
                    }
                    if (Update_Session[0].Session_gotNSTK != null && Update_Session[0].Session_gotNSTK != "") {
                        CookieService.updateLocalStorageValues("Session_gotNSTK", Update_Session[0].Session_gotNSTK);
                    }
                    if (Update_Session[0].Session_gotStock != null && Update_Session[0].Session_gotStock != "") {
                        CookieService.updateLocalStorageValues("Session_gotStock", Update_Session[0].Session_gotStock);
                    }
                    if (Update_Session[0].Session_Category != null && Update_Session[0].Session_Category != "") {
                        CookieService.updateLocalStorageValues("Session_Category", Update_Session[0].Session_Category);
                    }
                }

            }
            let FavItemDetails = FavItemDetailsAry.FavItemDetails;
            if (FavItemDetails != []) {
                let FavSDICatg = FavItemDetails[0].SDI_Catagory == " - " || FavItemDetails[0].SDI_Catagory == null || FavItemDetails[0].SDI_Catagory == "" || FavItemDetails[0].SDI_Catagory == " " ? "UnClassified" : FavItemDetails[0].SDI_Catagory;
                let FavYourCatg = FavItemDetails[0].Your_Catagory == "" || FavItemDetails[0].Your_Catagory == " " ? "" : FavItemDetails[0].Your_Catagory;
                let FavYourDescrip = FavItemDetails[0].Your_Description == "" ? "" : FavItemDetails[0].Your_Description;
                let FavYourSubCatg = FavItemDetails[0].Your_SubCategory == "" || FavItemDetails[0].Your_SubCategory == " " ? "" : FavItemDetails[0].Your_SubCategory;
                //***NEW****(2/23/2021)*******
                let Favprice = FavItemDetails[0].price == "" ? "" : FavItemDetails[0].price;
                //***********END**************
                $('#FavYourDescrip').val(FavYourDescrip);
                $('#FavYourCatg').val(FavYourCatg);
                $('#FavItemSubCatg').val(FavYourSubCatg);
                let allCategory = []
                let categories = CookieService.getLocalStorageValues("FavItem_Cat")
                if (categories != null && categories != "" && categories != undefined) {
                    categories.map((val, index) => {
                        allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
                    });
                    allCategory = allCategory.sort((a, b) =>
                        a.value > b.value ? 1 : -1,
                    );
                }
                console.log("shopping catagory", allCategory);
                let subCategory = []
                let allSubCategory = []
                let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
                if (subCategories != null && subCategories != "" && subCategories != undefined) {
                    subCategories.map((val, index) => {
                        if (val.ISA_FAVS_SUBCATEGORY != null) {
                            subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                        }
                    });
                }
                if (subCategory.length > 0) {
                    allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
                    allSubCategory = allSubCategory.sort((a, b) =>
                        a.value > b.value ? 1 : -1,
                    );
                }
                this.setState({
                    AddFavPUp: true, FavItemIdPopUp: FavItemDetails[0].ItemID, FavSDICatgPopUp: FavSDICatg, FavYourCatgPopUp: FavYourCatg, FavYourSubCatgPopup: FavYourSubCatg,
                    FavItemDescripPopUp: FavItemDetails[0].Item_Description, FavYourDescripPopUp: FavYourDescrip, FavMFGPopUp: FavItemDetails[0].MFG,
                    FavMFGPartNUMPopUp: FavItemDetails[0].MFG_PartNumber, txtUOMHidePopUp: FavItemDetails[0].txtUOMHide, txtMfgItemidHidePopUp: FavItemDetails[0].txtMfgItemid,
                    txtItemidHidePopUp: FavItemDetails[0].txtItemidHide, txtMFGHidePopUp: FavItemDetails[0].txtMFGHide, Favpopupprice: Favprice, FavSAP: FavItemDetails[0].FavSAP,
                    category: allCategory, bindCategory: allCategory, categoryError: false, subCategoryError: false, subCategory: allSubCategory, bindSubCategory: [], Supplier_ID: FavItemDetails[0].txtMfgItemidHide

                })
                //document.getElementById("txtUOMHide").hidden = true
                //document.getElementById("txtMfgItemidHide").hidden = true
                //document.getElementById("txtItemidHide").hidden = true
                //document.getElementById("txtMFGHide").hidden = true
                $('#add-fav-popup').modal('show');

            }
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /*
     * This method is invoke when an add button in the favorite pop up is clicked.
     * */
    AddFavItem = () => {
        debugger;
        let FavDescrip = $('#FavYourDescrip').val()
        let FavCatg = this.state.FavYourCatgPopUp;
        let FavSubCatg = this.state.FavYourSubCatgPopup;

        /*if (FavDescrip == null || FavDescrip == "" || FavCatg == null || FavCatg == "") {
            //*NEW(1/6/2021)*ADDED*(for favitemmain when catotgrie is not filled***************
            let AddToFavItemBO = {
                userID: this.state.Login_UserId,
                userBU: this.state.Login_UserBU,
                ItemID: this.state.FavItemIdPopUp,
                Item_Description: this.state.FavItemDescripPopUp,
                Item_MFG: this.state.FavMFGPopUp,
                Your_Catagory: FavCatg != " " ? FavCatg.toUpperCase() : "",
                Your_Description: FavDescrip != " " ? FavDescrip.toUpperCase() : "",
                SDI_Catagory: this.state.FavSDICatgPopUp,
                txtItemidHide: this.state.txtItemidHidePopUp,
                CurrentPage_Url: "/SDIXZEUS/ShoppingCart",
                txtUOMHide: this.state.txtUOMHidePopUp,
                txtMFGHide: this.state.txtMFGHidePopUp,
                txtMfgItemidHide: this.state.txtMfgItemidHidePopUp,
                Session_Desc: this.state.FavItemDescripPopUp,
                Session_Category: this.state.FavYourCatgPopUp,
                Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                Favitemprice: this.state.Favpopupprice,
                SAP: this.state.FavSAP
            };
            ShoppingCartAction.updateFavsMenu(AddToFavItemBO);
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            //*********END****************
        }
        else*/
        if (FavCatg != null && FavCatg != undefined && FavSubCatg != null && FavSubCatg != undefined) {
            if (FavCatg.trim() != "" && FavSubCatg.trim() != "") {
                let AddToFavItemBO = {
                    userID: this.state.Login_UserId,
                    userBU: this.state.Login_UserBU,
                    ItemID: this.state.FavItemIdPopUp,
                    Item_Description: this.state.FavItemDescripPopUp,
                    Item_MFG: this.state.FavMFGPopUp,
                    Your_Catagory: FavCatg != " " ? FavCatg.toUpperCase() : "",
                    Your_SubCategory: FavSubCatg != " " ? FavSubCatg.toUpperCase() : "",
                    Your_Description: FavDescrip != " " ? FavDescrip.toUpperCase() : "",
                    SDI_Catagory: this.state.FavSDICatgPopUp,
                    txtItemidHide: this.state.txtItemidHidePopUp,
                    CurrentPage_Url: "/SDIXZEUS/ShoppingCart",
                    txtUOMHide: this.state.txtUOMHidePopUp,
                    txtMFGHide: this.state.txtMFGHidePopUp,
					txtMfgItemidHide: this.state.txtMfgItemidHidePopUp,
					Supplier_ID: this.state.Supplier_ID,
                    Session_Desc: this.state.FavItemDescripPopUp,
                    Session_Category: this.state.FavYourCatgPopUp,
                    Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                    SAP: this.state.FavSAP
                };
                ShoppingCartAction.updateFavsMenu(AddToFavItemBO);
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            }
            else {
                this.setState({ categoryError: FavCatg.trim() == "" ? true : false, subCategoryError: FavSubCatg.trim() == "" ? true : false });
            }
        }
        else {
            this.setState({ categoryError: FavCatg == null || FavCatg == undefined ? true : false, subCategoryError: FavSubCatg == null || FavSubCatg == undefined ? true : false });
        }
    }
    /*
     * This method is called when the action updateFavsMenu emits the response.
     * Close the favorite pop up
     * */
    closeFavPopup = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let FavItemUpdateAry = JSON.parse(ShoppingCartStore.FavItemUpdateAry);
        //.log("FavUpdate", FavItemUpdateAry)
        let StatusArray = FavItemUpdateAry.AlertMessage
		if (StatusArray[0].Success_Message != "" && StatusArray[0].Success_Message != null) {
			let FavmenuDD = FavItemUpdateAry.FavMenu
			//let FavmenuDD = PageLaod_Data.FavMenu
			let filteredFavmenuDD = []
			if (FavmenuDD != null && FavmenuDD != undefined) {
				if (FavmenuDD.length > 0) {
					filteredFavmenuDD = FavmenuDD.filter((v, i, a) => a.findIndex(t => (t.FavText.trim().toUpperCase() === v.FavText.trim().toUpperCase())) === i)
				}
			}
			let FavSubMenu = FavItemUpdateAry.FavSubMenu;
			this.setState({ FavMenu: FavmenuDD, FavMenuSub: FavSubMenu, AddFavPUp: false });
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Favorite item inserted successfully")
			});
			//alert(t("Item successfully added to the favorite list"));
			$('#add-fav-popup').modal('hide');
			MenuAction.refreshMasterData()
			this.BuildFavMenu();
		}
		else {
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Technical issue try again")
			});
			//alert(t("Due to technical Issue item is not added to the favorite list.Try after sometime."));
			this.setState({ AddFavPUp: false });
			$('#add-fav-popup').modal('hide');
		}
	}
    /*
     * RF No: SC_PC_41
     * Show the delete item confirmation pop up.
     * */
	DeleteItem = (ItemId, UniqID, SupID) => {

		this.setState({ ItemId: ItemId, UniqId: UniqID, RemoveItemPUp: true, Supplier_ID: SupID });
		$('#Cancel-confirmation').modal('show');
	}
    /*
     * RF No: SC_PC_47
     * This method is called when Delete confirmation button in delete pop up is clicked 
     * */
    DeleteItemConfirmed = () => {

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let { CartItems } = this.state;
        let { ItemId } = this.state;
        let { UniqId } = this.state;
		let { Supplier_ID } = this.state;
		let index = CartItems.findIndex(x => x.Itemid == ItemId && x.UniqNum == UniqId && x.SupplierID == Supplier_ID);
		let filepath = CartItems[index].FilePath;
        let DeleteItemID = this.state.ItemId;
        let DeleteUniqId = this.state.UniqId;
        let DeleteItemBO = {
            userID: this.state.Login_UserId,
            userBU: this.state.Login_UserBU,
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            FilePath: filepath,
            ItemID: DeleteItemID,
            LineNum: DeleteUniqId,
			DeleteFlag: true,
			SupID: this.state.Supplier_ID
        };
        ShoppingCartAction.DeleteCartItem(DeleteItemBO);
        this.setState({ RemoveItemPUp: false });
    }
    /*RF No: SC_PC_51
     * This method is called when the DeleteCartItem action response is emitted.
     * */
    DeleteItemStatus = () => {
        const { t } = this.props;
        let deleteItemAry = JSON.parse(ShoppingCartStore.deleteItemAry);
        //[5/92023, PS_ZE_101_03, Dhevanesam R]
        let OnlyCatalogitemdata = true
        if (deleteItemAry != "" || deleteItemAry != null) {
            let CartItemsValue = this.state.CartItems;
            let PagelaodCart = this.state.PagelaodCartValue;
            let { ItemId } = this.state;
            let { UniqId } = this.state;
			let { Supplier_ID } = this.state;
			let index = CartItemsValue.findIndex(x => x.Itemid == ItemId && x.UniqNum == UniqId && x.SupplierID == Supplier_ID);
			CartItemsValue.splice(index, 1)
            PagelaodCart.splice(index, 1)
            let Cartcountvalue = 0
            if (CartItemsValue != null && CartItemsValue != undefined) {
                if (CartItemsValue.length > 0) {
                    Cartcountvalue = CartItemsValue.length
                }
            }
            let SCbIsWithPricedItems = false
            if (this.state.CerditCardEnabled == true) {
                if (this.state.CartItems != null && this.state.CartItems != undefined) {
                    if (this.state.CartItems.length > 0) {
                        this.state.CartItems.map((val, index) => {
                            let uniteprice = parseFloat(val.Price) ? parseFloat(val.Price) : 0;
                            if (uniteprice != NaN && uniteprice != 0 && SCbIsWithPricedItems != true) {
                                SCbIsWithPricedItems = true
                                //ItemTotal = uniteprice;
                            }
                            //else {
                            //    SCbIsWithPricedItems = false
                            //}

                        })
                    }
                }
            }
            //[5/7/23, PC_ZE_85_APP_02, Dhevanesam R]
            let cartdata = CartItemsValue;
            let pickupdata = this.state.pickup_vendor;
            let pickupcount = 0;
            let pickupcheckbox = false;
            let pickupvendor = "";
            let pickupvendorID = "";
            if (pickupdata.length != 0 && cartdata.length != 0) {
                pickupdata.forEach((val, index) => {
                    CartItemsValue.filter((x) => x.SupplierID == CartItemsValue[0].SupplierID && val.VENDOR_ID == x.SupplierID).map((i) => { pickupcount += 1; })
                })

                if (CartItemsValue.length == pickupcount) {
                    debugger
                    pickupcheckbox = true;
                    pickupdata.map((val, index) => {
                        if (val.VENDOR_ID == cartdata[0].SupplierID) {
                            debugger
                            pickupvendor = val.NAME1;
                            pickupvendorID = val.VENDOR_ID;
                        }
                    });
                }
            }
            let pickuploc = "";
            let loclowesitem = false;
            let punchoutcount = 0;
            let disableShiptobol = false;
            if (this.state.CartItems != undefined && this.state.CartItems != null) {
                if (this.state.CartItems.length > 0) {
                    this.state.CartItems.map((val, index) => {
                        if (val.PaintColor == "PUNCHOUT" && (val.SUPPLIERNAME.trim().toUpperCase().includes("LOWE'S") || val.SUPPLIERNAME.trim().toUpperCase().includes("HOME DEPOT"))) {
                            punchoutcount = punchoutcount + 1;
                        }
                        let NonCat = val.Itemid.slice(0, 6)
                        if (NonCat.toUpperCase() == "NONCAT") {
                            OnlyCatalogitemdata = false
                        }
                    });
                    if (this.state.CartItems.length == punchoutcount) {
                        pickuploc = true;
                        loclowesitem = true;
                        disableShiptobol = true;                        
                    }
                    else {
                        pickuploc = false;
                        disableShiptobol = false;
                    }
                }
                if (this.state.CartItems.length == 0) {
                    pickuploc = false
                    disableShiptobol = false;
                }
            }
            let pickupstore = [];
            if (this.state.pickupstore_tbl && pickupvendor != "") {
                this.state.pickupstore_tbl.map((storeval, index) => {
                    if (storeval.NAME1.toUpperCase() == pickupvendor.toUpperCase()) {
                        let localArray = {
                            ADDRESS3: storeval.VNDR_LOC,
                            VNDR_LOC: storeval.VNDR_LOC,
                            ADDRESS1: storeval.ADDRESS1,
                            CITY: storeval.CITY,
                            STATE: storeval.STATE,
                            POSTAL: storeval.POSTAL,
                            PHONE: storeval.PHONE,
                            NAME1: storeval.NAME1,
                            LATITUDE: storeval.LATITUDE,
                            LONGITUDE: storeval.LONGITUDE
                        }
                        pickupstore.push(localArray)
                    }
                })
            }
            this.setState({
                CartItems: CartItemsValue, CartCOunt: Cartcountvalue, PagelaodCartValue: PagelaodCart, SCbIsWithPricedItems: SCbIsWithPricedItems, OnlyCatalogItem: OnlyCatalogitemdata,
                isAllItem_are_Pickup: pickupcheckbox, PickupStore: pickupstore, PickupVendor: pickupvendor, PickupVendorID: pickupvendorID, pickupcheck: pickuploc, lowesitem: loclowesitem, disableShipto: disableShiptobol,
            })
            let RTIStore = []
            this.state.CartItems.forEach(element => {
                // 7/1/2024 - Zeus 297 - Prefil the selected store only the item is RTI - Johnprinto D
                if (element.RTI_STORE && element.RTI_QTY && element.RTI_QTY != "0") {
                    RTIStore.push(element.RTI_STORE)
                }
            });
            if (pickupcheckbox && RTIStore.length == this.state.CartItems.length) {
                this.Bindpickupstore();
            }
            //this.LoadCartItem();
            this.refreshMenu();

        }
        else {
            //alert(t("Technical issue.Try after sometime."))
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical issue.Try after sometime.")
            })
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /*
     * This method is used to calculate the order total.
     * */

    /*//[10/13/21, PC_SC_01 CAS - 32], Priyanka D*/
    PriceDesignation = () => {  
        let SiteCurrency = this.state.SiteCurrencyTable;
        let cartcount = this.state.CartItems.length;
        if (cartcount != 0) {
            return this.state.CartItems.map((val, index) => {
                let loopindex = index + 1;
                if (loopindex == cartcount) {
                    return ("(" + SiteCurrency[0].id + ")" + ":" );
                }
                }
            );
        }
    }
    OrderTotalCal = () => {

        let SiteCurrency = this.state.SiteCurrencyTable;
        let ItemTotal = 0;
        let OrderTotal = 0;
        let cartcount = this.state.CartItems.length;
        let unitepricevalue = "";
        if (cartcount != 0) {
            return this.state.CartItems.map((val, index) => {
                /*[11/22/21, PC_SC_04, 1830,1831], Priyanka D*/
                if (val.Price != undefined && val.Price != null && val.Price != "" && val.Price != " " ) {
                    unitepricevalue = unitepricevalue = this.Replace(val.Price, ",", "")
                }
                let uniteprice = parseFloat(unitepricevalue) ? parseFloat(unitepricevalue) : 0
                let QuantityAvail = parseFloat(val.Quantity) ? parseFloat(val.Quantity) : 0
                if (val.Price.toUpperCase() != "PRICE ON REQUEST") {
                    if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
                        ItemTotal = uniteprice * QuantityAvail;
                        //ItemTotal = uniteprice;
                    }
                    OrderTotal = OrderTotal + ItemTotal;
                }
                let loopindex = index + 1;
                if (loopindex == cartcount) {
                    let roundOffvalue = OrderTotal.toFixed(2)
                    return (SiteCurrency[0].Symbol + " " + roundOffvalue);
                }
            });
        }
        else {
            if (SiteCurrency != undefined && SiteCurrency != null && SiteCurrency != [] && SiteCurrency != "") {
                return (SiteCurrency[0].Symbol + " " + "0.00");
            }
        }

    }
    ItemTotalCal = (ItemPrice, Quantity) => {
        if (ItemPrice.toUpperCase() != "PRICE ON REQUEST") {
            let ItemTotal = 0;
            let SiteCurrency = this.state.SiteCurrencyTable;
            let PriceFloatvalue = ""
            if (ItemPrice != undefined && ItemPrice != "" && ItemPrice != " " && ItemPrice != null) {
                PriceFloatvalue = this.Replace(ItemPrice, ",", "")
            }
            let uniteprice = parseFloat(PriceFloatvalue) ? parseFloat(PriceFloatvalue) : 0
            let QuantityAvail = parseFloat(Quantity) ? parseFloat(Quantity) : 0
            if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
                ItemTotal = uniteprice * QuantityAvail;
                ItemTotal = ItemTotal.toFixed(2)
                //ItemTotal = uniteprice;
            }
            return (SiteCurrency[0].Symbol + " " + ItemTotal)
        }
        else {
            return (ItemPrice)
        }
    }
    /*
     * RF No: SC_PC_42
     * Show Attachment pop up.
     * */
    LinelevelAtthEdit = (ItemId, UniqID) => {


        let { CartItems } = this.state
        let index = CartItems.findIndex(x => x.Itemid == ItemId && x.UniqNum == UniqID);
        let attachmentPathArrLocal = []
        //.log("Attachment", CartItems[index].FilePath)
        if (CartItems[index].FilePath != null && CartItems[index].FilePath != undefined) {
            if (CartItems[index].FilePath.length < 0) {
                attachmentPathArrLocal = CartItems[index].FilePath.split("|")
            }
            else {
                attachmentPathArrLocal = CartItems[index].FilePath
            }
        }
        //.log("attachmentPathArrLocal", attachmentPathArrLocal)
        let FilePathArray = []
        if (attachmentPathArrLocal != null && attachmentPathArrLocal != undefined) {
            if (attachmentPathArrLocal.length > 0) {
                attachmentPathArrLocal.map((attachment, index) => {
                    let url = attachment;
                    let filename = url.substring(21);
                    //alert(filename);
                    let localArray = {
                        FileName: filename,
                        FilePath: url
                    }
                    FilePathArray.push(localArray)
                });
            }
        }
        //.log("FilePathArray", FilePathArray)
        this.setState({ ItemId: ItemId, UniqId: UniqID, AddAttachPUp: true, attachmentPathArr: FilePathArray });
        $('#addattachment').modal('show');
    }
    /*
     * RF No: SC_PC_48
     * Validation is checked.
     * */
    SubmitOrder = () => {
        debugger
        const { t } = this.props;
        let StatusErr = ""
		let ErrorMsg = ""
		let locOLEmployeeCCErr = false;
		let locOLDueDateErr = false;
		let locOLShipToErr = false;
		let locDeliveryPointErr = false;
		let locChargeCodevalidateErr = false;
		let locPickupStoreErr = false;
        let locuserAddressErr = false;
        let locNotesErr = false;
        let notesErrMsg = "";
        let invalidWoErr = false;
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let OLDueDate = "";
        if (this.state.Session_ZEUSNOCATALOGSITE != "Y" || this.state.Session_ZEUS_SITE != "Y") {
            OLDueDate = this.state.OLDueDateVal;
        }
        //.log("OLDueDate", OLDueDate)
        let OLEmployeeCC = this.state.OrderEmpyId.value == undefined || this.state.OrderEmpyId.value == null ? "0" : this.state.OrderEmpyId;
        let OLShipTo = this.state.defaultShipto.value;
        if (OLShipTo.trim() == "" || OLShipTo == null) {
            if (this.state.ShipToDDValue.length < 2) {
                let ShipToDDValue = this.state.ShipToDDValue
                OLShipTo = ShipToDDValue[0].value;
            }
            else {
                OLShipTo = this.state.defaultShipto.value;
            }
        }
        let validationFalg = true
        let { hdfChangeLineNum } = this.state
        let { OLShipToIdLabel } = this.state
        let { CartItems } = this.state
        let Employeevalidate = true
        let Duedatevalid = true
        let { OlChargeCOde } = this.state
        //let OLWorkOrderTB1 = $('#OLWorkOrderTB').val()
        let OLWorkOrderTB1 = this.state.ValidatedWo;
        // //.log(CartItems)
        //let WorkOrderFlag = false;
        let strOLDeliveryPoint = this.state.OLDeliveryPoint.value == undefined || this.state.OLDeliveryPoint.value == null ? "" : this.state.OLDeliveryPoint.value;
       
        // console.log("workorder validate", WorkOrderFlag);

        if (CartItems.length == 0) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            validationFalg = false
            //alert(t("Cart is empty"))
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Cart is empty")
            })
        }
        let mandatoryfilled = true
        let ZEUSNOCATALOGSITEFieldHide = true
        if (CartItems.length > 0) {
            this.state.CartItems.map((val, index) => {
                let Pricevalue = val.Price
                if (val.SupplierID != null && val.SupplierID != "" && Pricevalue != "Price on Request" && ZEUSNOCATALOGSITEFieldHide != false) {
                    ZEUSNOCATALOGSITEFieldHide = true
                    OLDueDate = this.state.OLDueDateVal
                }           
                /*if (val.EmpChgCode == "0" || val.EmpChgCode == "" || val.EmpChgCode == " " || val.EmpChgCode == null) {
                    //validationFalg = false
                    Employeevalidate = false
                }*/
            })
        }
        let ChargeCodevalidate = true
        //Validate Charge Code in Adaptermain && Cart Charge Code Required in Enterprises
        if (CookieService.getLocalStorageValues("Session_CHARGECODE") == "Y") {
            if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != null && CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != "" && CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != " ") {
                    if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == 1
                        || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == "y" || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == "Y") {

                        this.state.CartItems.map((val, index) => {
                            debugger
                            if (val.ItemChgCode == null || val.ItemChgCode == undefined || val.ItemChgCode == "") {
                                ChargeCodevalidate = false
                            }
                        })
                        if (this.state.OlChargeCOde != null && this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "") {
                            ChargeCodevalidate = true
                        }
                    }
                }
            }
        }
        let ChargeCOdeWorkOrder = true
        if (CookieService.getLocalStorageValues("Session_WORKORDER") == "Y" && CookieService.getLocalStorageValues("Session_CHARGECODE") == "Y") {
            if (CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") != null && CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") == "y" || CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") == "Y") {
                    if (ChargeCodevalidate == false && (OLWorkOrderTB1 == null || OLWorkOrderTB1 == undefined || OLWorkOrderTB1 == "")) {
                        ChargeCOdeWorkOrder = false
                    }
                }
            }
        }
        let WorkOrderValidate = true
        if (CookieService.getLocalStorageValues("Session_WORKORDER") == "Y") {
            if (CookieService.getLocalStorageValues("Session_CARTWOREQ") != null && CookieService.getLocalStorageValues("Session_CARTWOREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTWOREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTWOREQ") == "y" || CookieService.getLocalStorageValues("Session_CARTWOREQ") == "Y") {
                    WorkOrderValidate = false
                }
            }
        }
        //[15/05/23, PS_EWVB_08, Zeus 112, Sathis. N]- add work order field as mandatory field
        //[07/20/23, PC_ZE_162_API_01,Sathis N]
        if (CookieService.getLocalStorageValues("Session_CARTWOREQ") == "Y" && this.state.WorkOrderTitle == "* Work Order#" && (OLWorkOrderTB1 == null || OLWorkOrderTB1 == undefined || OLWorkOrderTB1 == "")) {

            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            validationFalg = false;
			////StatusErr = "Please enter work order number";
			////ErrorMsg = "Please enter work order number";
			this.setState({ woStyle: "EMPTYWOSUBMIT" });

        }
        else if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" && OLWorkOrderTB1 && (this.state.disableWo == false)) {

            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            invalidWoErr = true;
           // validationFalg = false;
            //StatusErr = "Please enter valid work order number";
            //ErrorMsg = "Please enter valid work order number";
            this.setState({ woStyle: "INVALIDWO" });
            this.toastMessage();

        } else if (this.state.woStyle == "MISMATCHWO") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            validationFalg = false;
            this.toastMessage();
        }
        if (Employeevalidate == false || (OLEmployeeCC == " " || OLEmployeeCC == "0")) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            validationFalg = false
            //document.getElementById('StatusMsg').innerText = (t("FillItemLevelEmpID"))
            //document.getElementById('StatusMsg1').innerText = (t("FillItemLevelEmpID"))
			////StatusErr = "Please select the employee ID";
			//ErrorMsg = "Please select the employee ID";
			locOLEmployeeCCErr = true;
        }
        //ADDED***
        //[04/08/22, CAS - 31, Dhevanesam R]
        //[5/92023, PS_ZE_101_04, Dhevanesam R]
        if (OLDueDate == "" || OLDueDate == undefined || OLDueDate == null) {
			$(`#${SDIConstants.SDILoader}`).addClass('hide');
			validationFalg = false
			//[10/06/23, ZEUS - 209, Since we display the common comment the required or delivery date - Johnprinto D]
			locOLDueDateErr = true;
            //document.getElementById('StatusMsg').innerText = (t("FillItemLevelDateAlert"))
            //document.getElementById('StatusMsg1').innerText = (t("FillItemLevelDateAlert"))
            //[10/06/23, ZEUS - 209, Since we display the common comment the required or delivery date - Johnprinto D]
            /*if (CookieService.getLocalStorageValues("Session_DeliveryDateFlag") == "Y") {
                StatusErr = "Please fill the required by date";
                ErrorMsg = "Please fill the required by date";
            } 
            else {
                StatusErr = "Please fill the delivery date";
                ErrorMsg = "Please fill the delivery date";                
            }*/
        }
        //ADDED***
        //[6/10/23, PC_ZEUS_135_APP_01, Johnprinto D]
        if (this.state.DeliveryPointFlag == true && strOLDeliveryPoint == "") {
			$(`#${SDIConstants.SDILoader}`).addClass('hide');
			validationFalg = false;
			locDeliveryPointErr = true;
            //StatusErr = "Please select the delivery point";
            //ErrorMsg = "Please select the delivery point";
        }
        if (this.state.notesReq && this.state.customerNotes == "") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            validationFalg = false;
            locNotesErr = true;
        }
        else if (this.state.notesReq && this.state.customerNotes.length < 5) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            locNotesErr = true;
            notesErrMsg = "Please enter minimum 5 characters";
        }
        //[3/15/2023]CAS-44 Adding condition to show error if both ship to and address fields are not filled by the customer, anyone has to be filled to avoid this
        //[5/7/23, PC_ZE_85_APP_17, Dhevanesam R]

        //else if (this.state.showShipto) {
        //    if ((OLShipTo == "0" && this.state.pickupcheck == false)) {
        //        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //        validationFalg = false
        //        mandatoryfilled = false
        //        StatusErr = "Please fill all Mandatory Fields";
        //        ErrorMsg = "Please fill all Mandatory Fields";
        //    }
        //}
        //else if (!this.state.showShipto) {
        //    if (this.state.userAddress.trim() == "") {
        //        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //        validationFalg = false
        //        mandatoryfilled = false
        //        StatusErr = "Please fill all Mandatory Fields";
        //        ErrorMsg = "Please fill all Mandatory Fields";
        //    }
        //}
        //else if (ChargeCOdeWorkOrder == false) {
        //    validationFalg = false
        //    $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //    //document.getElementById('StatusMsg').innerText = (t("EnterWO#orCC"))
        //    //document.getElementById('StatusMsg1').innerText = (t("EnterWO#orCC"))
        //    StatusErr = "Please enter work order number or charge code";
        //    ErrorMsg = "Please enter work order number or charge code";
        //}
		if (ChargeCodevalidate == false) {
			validationFalg = false
			$(`#${SDIConstants.SDILoader}`).addClass('hide');
			locChargeCodevalidateErr = true;
			//document.getElementById('StatusMsg').innerText = (t("FillItemLevelCC"))
			//document.getElementById('StatusMsg1').innerText = (t("FillItemLevelCC"))
			//StatusErr = "Please fill all item level charge code";
			//ErrorMsg = "Please fill all item level charge code";
		}
		if (WorkOrderValidate == false) {
			//let OLWorkOrderTB = $('#OLWorkOrderTB').val()
			let OLWorkOrderTB = this.state.ValidatedWo
			if (OLWorkOrderTB == null || OLWorkOrderTB == undefined || OLWorkOrderTB == "") {
				validationFalg = false
				this.setState({ woStyle: "EMPTYWOSUBMIT" });
				$(`#${SDIConstants.SDILoader}`).addClass('hide');
				//document.getElementById('StatusMsg').innerText = (t("FillWO"))
				//document.getElementById('StatusMsg1').innerText = (t("FillWO"))
				//StatusErr = "Please fill work order";
				//ErrorMsg  = "Please fill work order";
			}
		}
        if (this.state.pickupcheck == true) {
            debugger
            if (this.state.PickupStoreSelected == "" || this.state.PickupStoreSelected == []) {
                if (this.state.lowesitem == false) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    validationFalg = false
					locPickupStoreErr = true;
                    //StatusErr = "Please select the pickup store"
                    //ErrorMsg  = "Please select the pickup store"
                }
            }
        }
        if (this.state.showShipto) {
            if ((OLShipTo == "0" && this.state.pickupcheck == false)) {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                validationFalg = false
                mandatoryfilled = false
				locOLShipToErr = true;
                //StatusErr = "Please select the ship to";
                //ErrorMsg = "Please select the ship to";
            }
        }
        else if (!this.state.showShipto) {
            if (this.state.userAddress.trim() == "") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                validationFalg = false
                mandatoryfilled = false
				locuserAddressErr = true;
                //StatusErr = "Please enter the address";
                //ErrorMsg = "Please enter the address";
            }
        }
        //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
        let cartItemDS = [];
        let cartItemGrainger = [];
        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
            if (this.state.Login_UserBU.toUpperCase() == "I0631" && validationFalg != false) {
                if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("validWO") != " " && CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null) {

					let rmvndr = CookieService.getLocalStorageValues("RemoveVNDR");
                    if (rmvndr && this.state.CartItems != null && this.state.CartItems != undefined) {
                            //[6/4/2023, Bug 3681, Dhevanesam R]
							let rmvndrID = "";
							let rmvndrName = "";
                            if (this.state.CartItems.length != 0) {
                                this.state.CartItems.forEach((value, index) => {
                                    if (value.SupplierID != null && value.SupplierID != undefined) {
										if (!rmvndr.includes(value.SupplierID.trim().toLowerCase())) {
                                            cartItemDS.push(value);
										} else if (rmvndrID == "") {
											rmvndrID = value.SupplierID.trim().toLowerCase();
											rmvndrName = value.SUPPLIERNAME.trim();
										}
                                    }
                                    else {
                                        if (value.Itemid != null && value.Itemid != undefined) {
                                            if (value.Itemid.trim().toUpperCase().includes("NONCAT")) {
                                                cartItemDS.push(value);
                                            }
                                        }
                                    }
                                });


								if (cartItemDS.length != this.state.CartItems.length) {
									if (rmvndrID == "em00000001") {
										swal({
											allowOutsideClick: false,
											closeOnClickOutside: false,
											text: t("Unable to submit Grainger item using your current work order number")
										});
									} else if (rmvndrID == "em00000026") {
										swal({
											allowOutsideClick: false,
											closeOnClickOutside: false,
											text: t("Unable to submit Diversified supplier item using your current work order number")
										});
									} else {
										swal({
											allowOutsideClick: false,
											closeOnClickOutside: false,
											text: t("Unable to submit " + rmvndrName + "  supplier item using your current work order number")
										});
									}
									$(`#${SDIConstants.SDILoader}`).addClass('hide');
									validationFalg = false
									return;
								}
							}
                    }
                }
            }
        }
        

        //if (validationFalg == false && Employeevalidate == false) {
        //    $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //    document.getElementById('StatusMsg').innerText = "Please fill all Mandatory Fields"
        //    document.getElementById('StatusMsg1').innerText = "Please fill all Mandatory Fields"
        //}
        //if (validationFalg == false && Duedatevalid == false && mandatoryfilled != false) {
        //    $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //    document.getElementById('StatusMsg').innerText = "Line level need By date () cannot be less than today."
        //    document.getElementById('StatusMsg1').innerText = "Line level need By date () cannot be less than today."
        //}

        //if (OLDueDate == "") {
        //    $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //    validationFalg = false
        //    document.getElementById('StatusMsg').innerText = "Fill all Mandatory Fields"
        //    document.getElementById('StatusMsg1').innerText = "Fill all Mandatory Fields"
        //}
        if (validationFalg) {
            //document.getElementById('StatusMsg').innerText = ""
            //document.getElementById('StatusMsg1').innerText = ""
            StatusErr = "";
            ErrorMsg = "";
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            if (hdfChangeLineNum == "Y") {
                this.setState({ LineIncrePUp: true });
                $('#line-increment').modal('show');
            }
            else {
                //[5/8/2023, PS_FO_03, ZEUS-95 - Display the Fav order popup - Johnprinto D]
                //this.setState({ FavOrderConfPUp: true });
                //$('#Fav-confirmation').modal('show');
                //[10/06/21, PC_01, Bug ID - CAS-33, Priyanka D]
                if (!notesErrMsg && !invalidWoErr) {
                    this.setState({ woStyle: "VALIDWO" })
                    this.AddFavOrderStatus();
                }
            }
        }
		else {
			StatusErr = "Please fill all the required fields";
			ErrorMsg = "Please fill all the required fields";
            window.scrollTo(0, 0)
            this.onAccordianClick(0)
        }
		this.setState({
			StatusErrVal: StatusErr, ErrorMsgVal: ErrorMsg, OLEmployeeCCErr: locOLEmployeeCCErr, OLDueDateErr: locOLDueDateErr,
			DeliveryPointErr: locDeliveryPointErr, OLShipToErr: locOLShipToErr, ChargeCodevalidateErr: locChargeCodevalidateErr,
            PickupStoreErr: locPickupStoreErr, userAddressErr: locuserAddressErr, notesErr: locNotesErr, notesErrMsg: notesErrMsg
		});
    }
    /*
     * RF No: SC_PC_55
     * Increment line item pop up is shown.
     * */
    incrementLineNo = (State) => {
        /*[10/28/21, PC_SC_04 CAS - 32 and BUG ID-1823], Priyanka D*/

        //this.setState({
        //    FavOrderConfPUp: true, chkLineNumFlag: State == 1 ? "True" : "False"
        //});
        //$('#Fav-confirmation').modal('show');
        //this.AddFavOrderStatus("False");
        this.AddFavOrderStatus();
    }
    AddFavOrderStatus = () => {
        debugger
        let Session_CREDITCARDPROCSite = CookieService.getLocalStorageValues("Session_CREDITCARDPROCSite");
        let SCbIsWithPricedItems = false
        if (this.state.CartItems != null && this.state.CartItems != undefined) {
            if (this.state.CartItems.length > 0) {
                this.state.CartItems.map((val, index) => {
                    let uniteprice = parseFloat(val.Price) ? parseFloat(val.Price) : 0;
                    if (uniteprice != NaN && uniteprice != 0 && SCbIsWithPricedItems != true) {
                        SCbIsWithPricedItems = true
                        //ItemTotal = uniteprice;
                    }
                    //else {
                    //    SCbIsWithPricedItems = false
                    //}

                })
            }
        }
        if (Session_CREDITCARDPROCSite == "Y" && SCbIsWithPricedItems == true) {
            debugger
            $('#OrderlevelDiv').addClass('d-none');

            let OLShipTo = this.state.defaultShipto.value;
            if (OLShipTo.trim() == "" || OLShipTo == null) {
                if (this.state.ShipToDDValue.length < 2) {
                    let ShipToDDValue = this.state.ShipToDDValue
                    OLShipTo = ShipToDDValue[0].label;
                }
                else {
                    OLShipTo = this.state.defaultShipto.value;
                }
            }
            let OLOrderNum = $('#OrderNum').val();
            if (this.state.CerditCardEnabled == true) {
                let PaymentResponse = this.state.PaymentResponse
                OLOrderNum = PaymentResponse[0].Order_OrderNO
            }
            if (OLOrderNum == undefined) {
                OLOrderNum = "NEXT"
            }

            CookieService.updateLocalStorageValues("Session_SCMachineNum", $('#OLMachDD').val());
            //CookieService.updateLocalStorageValues("Session_SCWorkOrder", $('#OLWorkOrderTB').val());
            CookieService.updateLocalStorageValues("Session_SCWorkOrder", this.state.ValidatedWo);
            CookieService.updateLocalStorageValues("Session_SCReqByDate", this.state.OLDueDateVal);
            CookieService.updateLocalStorageValues("Session_SCChgCD", this.state.OlChargeCOde);
            CookieService.updateLocalStorageValues("Session_SCEmpID", this.state.OrderEmpyId.value);
            CookieService.updateLocalStorageValues("Session_SCUserType", this.state.TypeTable.length > 0 ? $('#OLTyeDD').val() : " ");
            CookieService.updateLocalStorageValues("Session_SCPriority", $('#customCheckOP7').is(":checked") ? "True" : "False");
            CookieService.updateLocalStorageValues("Session_SCChangeLineNumsAccpt", this.state.chkLineNumFlag);
            CookieService.updateLocalStorageValues("Session_SCShipTo", OLShipTo);
            CookieService.updateLocalStorageValues("Session_SCCartLineNotes", $('#CusNotes').val());
            CookieService.updateLocalStorageValues("Session_SCCustRef", $('#CustRef').val());
            CookieService.updateLocalStorageValues("Session_SCReqID", OLOrderNum);
            //CookieService.updateLocalStorageValues("Session_FavOrder", status);
            MenuAction.refreshMasterData()
            this.props.history.push({
                pathname: `\shoppingcart`,
                search: `?CartPayment=yes`
            });
            let TexvalueBO = {
                strCustID: CookieService.getLocalStorageValues("Session_CUSTID"),
                ApiUrl: this.state.apiURL,
                UserBU: this.state.Login_UserBU,
                UserId: this.state.Login_UserId
            }
            ShoppingCartAction.getTaxValue(TexvalueBO);

            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            this.setState({ CerditCardEnabled: true, SCbIsWithPricedItems: true })

        }
        else {
            //[5/7/23, PC_ZE_85_APP_09, Dhevanesam R]
            debugger
            let puchoutcount = 0;
            let punchoutHDCount = 0;
            let punchoutLWCount = 0;
            let punchoutHDBool = false;
            let punchoutLWBool = false;
            if (this.state.CartItems != null && this.state.CartItems != undefined) {
                if (this.state.CartItems.length > 1) {
                    this.state.CartItems.map((val, index) => {
                         
                        if (val.PaintColor == "PUNCHOUT" && val.SUPPLIERNAME.trim().toUpperCase().includes("HOME DEPOT")) {
                            punchoutHDCount = punchoutHDCount + 1;
                            punchoutHDBool = true;
                        }
                        else if (val.PaintColor == "PUNCHOUT" && val.SUPPLIERNAME.trim().toUpperCase().includes("LOWE'S")) {
                            punchoutLWCount = punchoutLWCount + 1;
                            punchoutLWBool = true;
                        }
                        else {}
                        
                    })
                    debugger
                    if (this.state.CartItems.length != 0) {
                        if (this.state.CartItems.length != punchoutHDCount && punchoutHDCount != 0 && (punchoutHDBool == true && punchoutLWBool == false)) {

                            let IsMultipleSupplier = "Home depot items cannot be mixed with other items";
                            this.setState({ IsMultipleSupplier: IsMultipleSupplier })
                            $('#alertpopup').modal('show');
                            return

                        }
                        else if (this.state.CartItems.length != punchoutLWCount && punchoutLWCount != 0 && (punchoutHDBool == false && punchoutLWBool == true)) {
                            let IsMultipleSupplier = "Lowe's items cannot be mixed with other items";
                            this.setState({ IsMultipleSupplier: IsMultipleSupplier })
                            $('#alertpopup').modal('show');
                            return

                        }
                        else if (this.state.CartItems.length != punchoutHDCount + punchoutLWCount && punchoutHDCount !=0 && punchoutLWCount != 0) {
                            let IsMultipleSupplier = "Home depot and Lowe's items cannot be mixed with other items";
                            this.setState({ IsMultipleSupplier: IsMultipleSupplier })
                            $('#alertpopup').modal('show');
                            return

                        }
                        else {
                            //this.AddFavOrder(status)
                            this.setState({ FavOrderConfPUp: true });
                            $('#Fav-confirmation').modal('show');
                        }
                    }
                    else {
                        //this.AddFavOrder(status)
                        this.setState({ FavOrderConfPUp: true });
                        $('#Fav-confirmation').modal('show');
                    }
                }
                else {
                    //this.AddFavOrder(status)
                    this.setState({ FavOrderConfPUp: true });
                    $('#Fav-confirmation').modal('show');
                }
            }
            
        }
    }
    TaxvalueBind = async () => {
        debugger
        let TaxValue = JSON.parse(ShoppingCartStore.TaxValue);
        //.log("TaxValue", TaxValue)
        let CustId = TaxValue.CustID
        let Cerditcards = TaxValue.CerditCards
        let CerditCardsExist = false
        let SiteCurrency = this.state.SiteCurrencyTable;
        let TokenTableL = TaxValue.Token
        let TaxExcept = ""
        let TaxDataValue = ""
        let roundOffvalue = 0
        if (Cerditcards != null && Cerditcards != undefined && Cerditcards != [] && Cerditcards != "") {
            if (Cerditcards.length > 0) {
                CerditCardsExist = true
            }
        }
        if (CustId != null && CustId != undefined) {
            if (CustId.length > 0) {
                let Taxdata = parseFloat(CustId[0].CustID) ? parseFloat(CustId[0].CustID) : 0
                let ItemTotal = 0;
                let OrderTotal = 0;
                this.state.CartItems.map((val, index) => {
                    let uniteprice = parseFloat(val.Price) ? parseFloat(val.Price) : 0
                    let QuantityAvail = parseFloat(val.Quantity) ? parseFloat(val.Quantity) : 0
                    if (val.Price.toUpperCase() != "PRICE ON REQUEST") {
                        if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
                            ItemTotal = uniteprice * QuantityAvail;
                        }
                        OrderTotal = OrderTotal + ItemTotal;
                    }
                });
                OrderTotal = Taxdata + OrderTotal
                roundOffvalue = OrderTotal.toFixed(2)
                TaxExcept = SiteCurrency[0].Symbol + " " + roundOffvalue
                TaxDataValue = SiteCurrency[0].Symbol + " " + Taxdata.toFixed(2)

            }
        }
        
        let getWalletBO = {
            UserId: this.state.Login_UserId,
            UserBU: this.state.Login_UserBU,
            DefaultCard: "Y",
        }
        ShoppingCartAction.getWalletValue(getWalletBO)

        this.setState({ taxValue: TaxDataValue, OrderTotalValue: roundOffvalue, TokenTable: TokenTableL, CerditCardsExist: CerditCardsExist })

    }
    OrderTaxTotal = () => {
        let ItemTotal = 0;
        let OrderTotal = 0;
        let Taxdata = 0;
        let SiteCurrency = this.state.SiteCurrencyTable;
        let cartcount = this.state.CartItems.length;
        if (this.state.TokenTable != null && this.state.TokenTable != undefined) {
            if (this.state.TokenTable.length > 0) {
                Taxdata = parseFloat(this.state.TokenTable[0].CustID) ? (this.state.TokenTable[0].CustID) : 0
            }
        }
        return this.state.CartItems.map((val, index) => {
            let uniteprice = parseFloat(val.Price) ? parseFloat(val.Price) : 0
            let QuantityAvail = parseFloat(val.Quantity) ? parseFloat(val.Quantity) : 0
            if (val.Price.toUpperCase() != "PRICE ON REQUEST") {
                if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
                    ItemTotal = uniteprice * QuantityAvail;
                }
                OrderTotal = OrderTotal + ItemTotal;
            }
            let loopindex = index + 1;
            if (loopindex == cartcount) {
                OrderTotal = Taxdata + OrderTotal
                let roundOffvalue = OrderTotal.toFixed(2)
                return (SiteCurrency[0].Symbol + " " + roundOffvalue);
            }
        });
    }
    /*
     * RF No: SC_PC_49
     * This method is invoked when the fav order pop up button is clicked.
     * */
    AddFavOrder = (status) => {

        let date = this.state.startDate;
        let OLDueDate = this.state.OLDueDateVal;
        let OLEmployeeCC = this.state.OrderEmpyId.value;
        let OLShipTo = this.state.defaultShipto.value;
        if (OLShipTo.trim() == "" || OLShipTo == null) {
            if (this.state.ShipToDDValue.length < 2) {
                let ShipToDDValue = this.state.ShipToDDValue
                OLShipTo = ShipToDDValue[0].label;
            }
            else {
                OLShipTo = this.state.defaultShipto.value;
            }
        }

        //let OLWorkOrder = $('#OLWorkOrderTB').val();
        let OLWorkOrder = this.state.ValidatedWo;
        let OLOrderNum = $('#OrderNum').val();
        if (this.state.CerditCardEnabled == true) {
            let PaymentResponse = this.state.PaymentResponse
            OLOrderNum = PaymentResponse[0].Order_OrderNO
        }
        if (OLOrderNum == undefined) {
            OLOrderNum = "NEXT"
        }
        let OLMachineNo = $('#OLMachDD').val();
        let OLNotes = $('#CusNotes').val();
        let OLCustomerRef = $('#CustRef').val();
        let OLType = this.state.TypeTable.length > 0 ? $('#OLTyeDD').val() : "";
        let OLPriorityOrder = $('#customCheckOP7').is(":checked") ? "True" : "False"
        let ValidatWorkOrder = "N"
        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") != null && CookieService.getLocalStorageValues("Session_ValidateWorkOrder") != undefined) {
            if (this.state.WorkOrderTitle == "*Work Order #" && CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                ValidatWorkOrder = "Y"
            }
        }
        let { chkLineNumFlag } = this.state
        if (this.state.CerditCardEnabled == true) {
            OLDueDate = CookieService.getLocalStorageValues("Session_SCReqByDate")
            OLWorkOrder = CookieService.getLocalStorageValues("Session_SCWorkOrder")
            OLEmployeeCC = CookieService.getLocalStorageValues("Session_SCEmpID")
            OLShipTo = CookieService.getLocalStorageValues("Session_SCShipTo")
            OLMachineNo = CookieService.getLocalStorageValues("Session_SCMachineNum")
            OLNotes = CookieService.getLocalStorageValues("Session_SCCartLineNotes")
            OLCustomerRef = CookieService.getLocalStorageValues("Session_SCCustRef")
            OLType = CookieService.getLocalStorageValues("Session_SCUserType")
            OLPriorityOrder = CookieService.getLocalStorageValues("Session_SCPriority")
            chkLineNumFlag = CookieService.getLocalStorageValues("Session_SCChangeLineNumsAccpt")
        }
        //[3/15/2023]CAS - 44 Checking the address type selected by user and based on it assigning values to address objects to pass it to API
        let streetLines;
        let postalCode;
        let countryCode;
        let state;
        let city;
        let apartmentNo;
        if (!this.state.showShipto) {
            if (this.state.typedAddress == true) {
                streetLines = this.state.typedStreet;
                postalCode = this.state.typedZipCode;
                countryCode = this.state.typedCountry;
                state = this.state.typedState;
                city = this.state.typedCity;
                apartmentNo = this.state.typedApartmentNo != null && this.state.typedApartmentNo.trim() != "" ? this.state.typedApartmentNo : " "
            }
            else if (this.state.isExistingAddress == true) {
                streetLines = this.state.existStreet;
                postalCode = this.state.existPostal;
                countryCode = this.state.existCountry;
                state = this.state.existState;
                city = this.state.existCity;
                apartmentNo = this.state.existApartmentNo != null && this.state.existApartmentNo.trim() != "" ? this.state.existApartmentNo : " "
            }
            else {
                streetLines = this.state.isValidStreet;
                postalCode = this.state.isValidZipCode;
                countryCode = this.state.isValidCountryCode;
                state = this.state.isValidState;
                city = this.state.isValidCity;
                apartmentNo = this.state.typedApartmentNo != null && this.state.typedApartmentNo.trim() != "" ? this.state.typedApartmentNo : " "
            }
        }
       
        //[5/7/23, PC_ZE_85_APP_09, Dhevanesam R]
        let var_pickupcheck = "";
        if (this.state.pickupcheck == true) {
            var_pickupcheck = "PU";
        }
        let PlaceOrderBo = {
            Session_UserID: this.state.Login_UserId,
            Session_BUSUNIT: this.state.Login_UserBU,
            Session_MANORDNO: CookieService.getLocalStorageValues("Session_MANORDNO"),
            Session_ORDERPREFIX: CookieService.getLocalStorageValues("Session_ORDERPREFIX"),
            Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
            punchinSessionID: CookieService.getLocalStorageValues("Session_PUNCHINID"),
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
            Session_EMAIL: CookieService.getLocalStorageValues("Session_EMAIL") != undefined && CookieService.getLocalStorageValues("Session_EMAIL") != null ? CookieService.getLocalStorageValues("Session_EMAIL") : "",
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
            Session_TestMode: CookieService.getLocalStorageValues("Session_TESTMODE"),
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            CurrentPage_Url: "/SDIXZEUS/ShoppingCart",
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_CARTFAIL: CookieService.getLocalStorageValues("Session_CARTFAIL"),
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            Session_ISABudgetaryLimit: CookieService.getLocalStorageValues("Session_ISABudgetaryLimit"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_ValidateWorkOrder: ValidatWorkOrder,
            Validation_Status: "True",
            chkLineNumFlag: chkLineNumFlag,
            Session_ShowPrice: CookieService.getLocalStorageValues("Session_ShowPrice"),
            Session_PHONE: CookieService.getLocalStorageValues("Session_PHONE"),
            Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
            Session_RFQSITE: CookieService.getLocalStorageValues("Session_RFQSite"),
            Session_ISACstVendor: CookieService.getLocalStorageValues("Session_ISACstVendor"),
            Session_ISACstPrice: CookieService.getLocalStorageValues("Session_ISACstPrice"),
            Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
            Order_WorkOrder: OLWorkOrder,
            Order_OrderNO: OLOrderNum,
            Order_DueDate: OLDueDate,
            Order_Customer_Notes: OLNotes,
            Order_EmployeeCC: OLEmployeeCC,
            Order_MachineNum: OLMachineNo,
            Order_Type: OLType,
            //[6/10/23, PC_ZEUS_135_APP_01, Johnprinto D]
            Order_DeliveryPoint: this.state.OLDeliveryPoint.value,
            Order_WODesc: this.state.workOrderDescription,
            Order_Customer_Reference: OLCustomerRef,
            Order_ShipTo: this.state.showShipto ? OLShipTo : "",
            Order_PriorityOrder: OLPriorityOrder,
            AddToFav_Order: status,
            PaymentAbled: this.state.CerditCardEnabled,
            Order_ChargeCode: this.state.CerditCardEnabled != true ? this.state.OlChargeCOde : CookieService.getLocalStorageValues("Session_SCChgCD"),
            Session_ISAAscendProcess: CookieService.getLocalStorageValues("Session_ISAAscendProcess"),
            Session_SPLITPRICEDORDERS: CookieService.getLocalStorageValues("Session_SPLITPRICEDORDERS"),
            Session_PUNCHINRFQCONFIRM: CookieService.getLocalStorageValues("Session_PUNCHINRFQCONFIRM"),
            Session_BuyerCookie: CookieService.getLocalStorageValues("Session_BuyerCookie"),
            Session_WORKORDER: CookieService.getLocalStorageValues("Session_WORKORDER"),
            Session_ISANOTIFYBUYER: CookieService.getLocalStorageValues("Session_ISANOTIFYBUYER"),
            Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
            Session_ID: CookieService.getLocalStorageValues("Session_ID"),
            streetLines: streetLines,
            city: city,
            stateOrProvinceCode: state,
            countryCode: countryCode,
            postalCode: postalCode,
            ApartmentNo: apartmentNo,
            Location2: this.state.pickupcheck == true && this.state.PickupStoreSelected != "" ? this.state.PickupStoreSelected.value : "",
            USER_CHAR2: var_pickupcheck,
            workorderLocation: CookieService.getLocalStorageValues("workorderLocation"),
            OrderCategory: this.state.favOrdCat.trim(),
            OrderSubCategory: this.state.favOrdSubCat.trim(),
            OrderDescription: this.state.favOrdDes.trim(),
            OrderImg: this.state.attachmentPathArrImg ? this.state.attachmentPathArrImg[0] ?.FilePath : null
        }
        //.log("PlaceOrderBo", PlaceOrderBo)
        ShoppingCartAction.SubmitOrder(PlaceOrderBo);
        this.setState({ FavOrder: status })
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    /*
     * RF No:SC_PC_52
     * This method is invoked when the action method SubmitOrder emits the response.
     * */
    OrderPlacedStatus = () => {
        debugger
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        debugger
        //[2/8/22, CAS - Punchin Issue, Dhevanesam R]
        console.log("Session_PUNCHINID", CookieService.getLocalStorageValues("Session_PUNCHINID"))
        console.log("Session_PUNCHINRFQCONFIRM", CookieService.getLocalStorageValues("Session_PUNCHINRFQCONFIRM"))
        console.log("Session_SPLITPRICEDORDERS", CookieService.getLocalStorageValues("Session_SPLITPRICEDORDERS"))
        //[4/22/22, Bug - 2074 & 2087, PC_SC_01, Dhevanesam R]
        debugger
        let OrderStatus = JSON.parse(ShoppingCartStore.PlaceOrderMsge);
        debugger
        let Result = OrderStatus.Result;
        debugger
        if (Result != null && Result != "") {
            if (Result.length > 0) {
                debugger
                if (Result[0].Session_VENDOR_CONFIG != null && Result[0].Session_VENDOR_CONFIG != "") {
                    debugger
                    var iframe = document.createElement('iframe');
                    var html = Result[0].Session_VENDOR_CONFIG;
                    //var html = '<body><div id="foo">bar</div></body>';
                    debugger
                    document.body.appendChild(iframe);
                    iframe.contentWindow.document.open();
                    iframe.contentWindow.document.write(html);
                    iframe.contentWindow.document.close();
                }
                debugger
                let Ordernumbervalue = Result[0].OrderNO
                //[05/29/2023, PS_ZE_123_APP_01, Dhevanesam R]
                CookieService.updateLocalStorageValues("validatedWO", "");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
                CookieService.updateLocalStorageValues("validWO", "");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("Session_EMP_ID", "");
				CookieService.updateLocalStorageValues("Session_EMP_EMAIL", "");
				CookieService.updateLocalStorageValues("Session_Customer_Notes", "");
				CookieService.updateLocalStorageValues("Session_OLDeliveryPoint", "");
                //$('#OLWorkOrderTB').val('');
                this.props.history.push({
                    pathname: `\PIPost`,
                    search: `?REQID=${Ordernumbervalue}`
                });
                debugger
                if (Result[0].Session_VENDOR_CONFIG.trim().substring(0, 1) != "<") {
                    debugger
                    //alert("Error in the response")
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Error in the response")
                    })
                }
                this.setState({ ValidatedWo: "" })
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
            }
        }
        else {
            //let OrderStatus = JSON.parse(ShoppingCartStore.PlaceOrderMsge);
            //.log("OrderPlace", OrderStatus);
            let Update_Session = OrderStatus.update_Session;
            if (Update_Session != null && Update_Session != "") {
                if (Update_Session.length > 0) {
                    if (Update_Session[0].Session_SCbIsLyonsProcessing != null && Update_Session[0].Session_SCbIsLyonsProcessing != "") {
                        CookieService.updateLocalStorageValues("Session_SCbIsLyonsProcessing", Update_Session[0].Session_SCbIsLyonsProcessing);
                    }
                    if (Update_Session[0].Session_SCbIsWithPricedItems != null && Update_Session[0].Session_SCbIsWithPricedItems != "") {
                        CookieService.updateLocalStorageValues("Session_SCbIsWithPricedItems", Update_Session[0].Session_SCbIsWithPricedItems);
                    }
                    if (Update_Session[0].xmlToUrl != null && Update_Session[0].xmlToUrl != "") {
                        CookieService.updateLocalStorageValues("xmlToUrl", Update_Session[0].xmlToUrl);
                    }
                    if (Update_Session[0].PunchINxml != null && Update_Session[0].PunchINxml != "") {
                        CookieService.updateLocalStorageValues("PunchINxml", Update_Session[0].PunchINxml);
                    }
                    if (Update_Session[0].ARRParams != null && Update_Session[0].ARRParams != "") {
                        CookieService.updateLocalStorageValues("ARRParams", Update_Session[0].ARRParams);
                    }
                    if (Update_Session[0].CartPrint != null && Update_Session[0].CartPrint != "") {
                        CookieService.updateLocalStorageValues("CartPrint", Update_Session[0].CartPrint);
                    }
                }

            }
            let Remove_Session = OrderStatus.Remove_Session;
            if (Remove_Session != null && Remove_Session != "") {
                if (Remove_Session.length > 0) {
                    if (Remove_Session[0].SUBMIT != null && Remove_Session[0].SUBMIT != "") {
                        CookieService.updateLocalStorageValues("Session_SUBMIT", "");
                    }
                    if (Remove_Session[0].gotStock != null && Remove_Session[0].gotStock != "") {
                        CookieService.updateLocalStorageValues("Session_gotStock", "");
                    }
                    if (Remove_Session[0].gotNSTK != null && Remove_Session[0].gotNSTK != "") {
                        CookieService.updateLocalStorageValues("Session_gotNSTK", "");
                    }
                }

            }
            debugger
            let Errortable = OrderStatus.ErrorTable;
            let OrderNum = OrderStatus.OrderNumber;
            //[6/17/22, PC_SP_01, Bug - 2247, Dhevanesam R]
            let StatusErr = "";
            let ErrorMsg = "";
            if (Errortable.length > 0) {
                if (Errortable[0].WorkOrder_Validation != null && Errortable[0].WorkOrder_Validation != "") {
                    //alert(t(Errortable[0].WorkOrder_Validation))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Errortable[0].WorkOrder_Validation)
                    })
                    StatusErr = "Enter valid Work Order Number";
                    ErrorMsg = "Enter valid Work Order Number";
                    this.setState({
                        StatusErrVal: StatusErr, ErrorMsgVal: ErrorMsg
                    })
                }
                else if (Errortable[0].OrderNum_Validation != null && Errortable[0].OrderNum_Validation != "") {
                    //alert(t(Errortable[0].OrderNum_Validation))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Errortable[0].OrderNum_Validation)
                    })
                }
                else if (Errortable[0].No_EnterPrise_Found != null && Errortable[0].No_EnterPrise_Found != "") {
                    //alert(t(Errortable[0].No_EnterPrise_Found))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Errortable[0].No_EnterPrise_Found)
                    })
                }
                else if (Errortable[0].ExideErrorHandling != null && Errortable[0].ExideErrorHandling != "") {
                    //alert(t(Errortable[0].ExideErrorHandling))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Errortable[0].ExideErrorHandling)
                    })
                }
                else if (Errortable[0].Serialize_Alert_Message != null && Errortable[0].Serialize_Alert_Message != "") {
                    //alert(t(Errortable[0].Serialize_Alert_Message))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Errortable[0].Serialize_Alert_Message)
                    })
                }
                else if (Errortable[0].ReqId_DB_Error != null && Errortable[0].ReqId_DB_Error != "") {
                    //alert(t(Errortable[0].ReqId_DB_Error))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Errortable[0].ReqId_DB_Error)
                    })
                }
                //[3/15/2023]CAS-44 Displaying error message if there is any error in inserting the address
                else if (Errortable[0].Address_Insertion_Failed != null && Errortable[0].Address_Insertion_Failed != "") {
                    //alert(t(Errortable[0].Address_Insertion_Failed))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Errortable[0].Address_Insertion_Failed)
                    })
                }
                else {
                    //alert(t("Techincal Issue Contact SDI Customer Care"));
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Techincal Issue Contact SDI Customer Care")
                    })
                }
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
            }
            else {
                //let PdforderNum = "";
                MenuAction.refreshMasterData()
                //[05/29/2023, PS_ZE_123_APP_01, Dhevanesam R]
                CookieService.updateLocalStorageValues("validatedWO", "");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
                CookieService.updateLocalStorageValues("validWO", "");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("Session_EMP_ID", "");
				CookieService.updateLocalStorageValues("Session_EMP_EMAIL", "");
				CookieService.updateLocalStorageValues("Session_Customer_Notes", "");
				CookieService.updateLocalStorageValues("Session_OLDeliveryPoint", "");
                //$('#OLWorkOrderTB').val('');
                if (OrderNum.length > 0) {
                    if (Update_Session != null && Update_Session != "") {
                        let Ordernumbervalue = OrderNum[0].NONSTOCK == null || OrderNum[0].NONSTOCK == undefined || OrderNum[0].NONSTOCK == "" ? OrderNum[0].sReqIdPriced : OrderNum[0].NONSTOCK
                        if (Update_Session.length > 0) {
                            if (Update_Session[0].Pipost_Enable != null && Update_Session[0].Pipost_Enable != "" && Update_Session[0].Pipost_Enable != "False") {
                                if (this.state.CerditCardEnabled == true && this.state.SCbIsWithPricedItems == true) {
                                    this.Addcard();
                                }
                                if (Update_Session[0].Pipost_Enable == "True" && Update_Session[0].PipostUrlWithOrderNO == "True") {
                                    //CookieService.setCookieValues(SDIConstants.access_token, "", -1);
                                    //localStorage.clear();
                                    this.setState({ RedirectToPiPost: true, PiPostOrderNumber: Ordernumbervalue })
                                    //if (this.state.CerditCardEnabled != true && this.state.SCbIsWithPricedItems != true) {
                                    //    this.props.history.push({
                                    //        pathname: `\PIPost`,
                                    //        search: `?REQID=${Ordernumbervalue}`
                                    //    });
                                    //    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                                    //}
                                }
                                else if (Update_Session[0].Pipost_Enable == "True") {
                                    //CookieService.setCookieValues(SDIConstants.access_token, "", -1);
                                    //localStorage.clear();
                                    this.setState({
                                        RedirectToPiPost: true, PiPostOrderNumber: ""
                                    })
                                    if (this.state.CerditCardEnabled != true && this.state.SCbIsWithPricedItems != true) {
                                        this.props.history.push({
                                            pathname: `\PIPost`,
                                        });
                                        $(`#${SDIConstants.SDILoader}`).addClass('hide');
                                    }
                                }

                            }
                            else if (Update_Session[0].PipostConfirm_Enable != null && Update_Session[0].PipostConfirm_Enable != "" && Update_Session[0].PipostConfirm_Enable != "False") {
                                CookieService.updateLocalStorageValues("Session_Cart", this.state.CartItems);
                                //CookieService.updateLocalStorageValues("ShopCWrkOrd", $('#OLWorkOrderTB').val());
                                CookieService.updateLocalStorageValues("ShopCWrkOrd", this.state.ValidatedWo);
                                CookieService.updateLocalStorageValues("SiteCurrencySymbol", this.state.SiteCurrencyTable[0].Symbol);
                                CookieService.updateLocalStorageValues("Session_SCMachineNum", $('#OLMachDD').val());
                                //CookieService.updateLocalStorageValues("Session_SCWorkOrder", $('#OLWorkOrderTB').val());
                                CookieService.updateLocalStorageValues("Session_SCWorkOrder", this.state.ValidatedWo);
                                CookieService.updateLocalStorageValues("Session_SCReqByDate", this.state.OLDueDateVal);
                                CookieService.updateLocalStorageValues("Session_SCChgCD", this.state.OlChargeCOde);
                                CookieService.updateLocalStorageValues("Session_SCEmpID", this.state.OrderEmpyId.value);
                                CookieService.updateLocalStorageValues("Session_SCCartLineNotes", $('#CusNotes').val());
                                if (this.state.CerditCardEnabled == true && this.state.SCbIsWithPricedItems == true) {
                                    this.Addcard();
                                }
                                if (Update_Session[0].PipostConfirm_Enable == "True" && Update_Session[0].PipostUrlWithOrderNO == "True") {

                                    this.props.history.push({
                                        pathname: `\PunchInRFQConfirm`,
                                        search: `?REQID=${Ordernumbervalue}`
                                    });
                                }
                                else if (Update_Session[0].PipostConfirm_Enable == "True") {

                                    this.props.history.push({
                                        pathname: `\PunchInRFQConfirm`,
                                    });
                                }
                            }
                            else {
                                let OrderSuccessmessage = this.state.FavOrder == "True" ? (t("Your order placed successfully and added to favorites order")) : (t("Your order placed successfully"))

                                if (this.state.CerditCardEnabled == true && this.state.SCbIsWithPricedItems == true) {
                                    this.Addcard()
                                    let ReferenceNumber = this.state.PaymentResponse[0].strRefNo
                                    this.setState({
                                        FavOrder: false, PricedOrderNum: OrderNum[0].sReqIdPriced,
                                        NonCataOrderNum: OrderNum[0].NONSTOCK, ReferenceNumber: ReferenceNumber
                                    })


                                    $('#Order-Placed').modal('hide');
                                    $('#Success-alert').modal('show');

                                }
                                else {
                                    this.setState({
                                        OrderPlacedstatus: OrderSuccessmessage, FavOrder: false, PricedOrderNum: OrderNum[0].sReqIdPriced,
                                        NonCataOrderNum: OrderNum[0].NONSTOCK
                                    })
                                    $('#Success-alert').modal('hide');
                                    $('#Order-Placed').modal('show');
                                }
                                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                            }
                        }
                    }

                }
                this.setState({
                    ValidatedWo: "", workOrderDescription: "", disableWo: false
                })
            }
        }
    }
    Addcard = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let ReferenceNumber = this.state.PaymentResponse[0].strRefNo
        //let amount = this.state.OrderTotalValue
        let amount = document.getElementById("OrderTaxTotal").innerHTML
        amount = amount.slice(2)

        let CardNumberValue = $('#CardNumber').val()
        CardNumberValue = CardNumberValue.replaceAll(" ", "")
        if (CardNumberValue.length == 14) {
            CardNumberValue = CardNumberValue.slice(10)
        }
        else if (CardNumberValue.length == 15) {
            CardNumberValue = CardNumberValue.slice(11)
        }
        else if (CardNumberValue.length == 16) {
            CardNumberValue = CardNumberValue.slice(12)
        }
        let ExpiryDate = this.state.ExpiryDate
        debugger
        if (ExpiryDate.length == 4) {
            if (ExpiryDate.slice(0, 1) == 0) {
                ExpiryDate = ExpiryDate.slice(1)
            }
        }
        let CardNumberValuedata = $('#CardNumber').val()
        CardNumberValuedata = CardNumberValuedata.replaceAll(" ", "")
        let ValidateDate = ExpiryDate.toString() + this.state.ExpiryYear.toString()
        let AddCardDetailsBO = {
            edate: ValidateDate,
            card: this.state.CardToken == "" || this.state.CardToken == null ? CardNumberValuedata : this.state.CardToken,
            cvv: this.state.CardCVV,
            Order_OrderNO: this.state.PaymentResponse[0].Order_OrderNO,
            UserId: this.state.Login_UserId,
            UserBU: this.state.Login_UserBU,
            amount: amount,
            strRefNo: ReferenceNumber,
            setDefault: "",
            cardNumber: CardNumberValue,
            cardType: this.state.CardImageType,
            ctoken: this.state.CardToken == "" || this.state.CardToken == null ? CardNumberValuedata : this.state.CardToken,
            tokenHMCA: this.state.CardHMCA == "" || this.state.CardHMCA == null ? "2BieDn1Jqu/cE4Ei3dMiHLk/piPH3pdVKBtwabRjiAU=" : this.state.CardHMCA,
            isValidOrder: true,
            Website: CookieService.getLocalStorageValues("Session_WEBSITEID"),

        }
        ShoppingCartAction.AddCardDetails(AddCardDetailsBO)
    }
    AddCardDetails = () => {
        const { t } = this.props;
        let AddCardDetailsBO = JSON.parse(ShoppingCartStore.AddCardDetailsBO);
        debugger
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let AddCardDetail = AddCardDetailsBO.CardInsert
        if (AddCardDetail != "" && AddCardDetail != null) {
            if (AddCardDetail.length > 0) {
                if (AddCardDetail[0].CardInsert != null && AddCardDetail[0].CardInsert != undefined) {
                    if (AddCardDetail[0].CardInsert == "Success") {
                        if (this.state.RedirectToPiPost == true && this.state.PiPostOrderNumber != null && this.state.PiPostOrderNumber != undefined && this.state.PiPostOrderNumber != "") {
                            this.props.history.push({
                                pathname: `\PIPost`,
                                search: `?REQID=${this.state.PiPostOrderNumber}`
                            });
                        }
                        else if (this.state.RedirectToPiPost == true && this.state.PiPostOrderNumber == "") {
                            this.props.history.push({
                                pathname: `\PIPost`,
                            });
                        }
                    }
                }
            }
            else {
                //alert(t("Error while updating WALLET"))
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error while updating WALLET")
                })
            }
        }
    }
    OrderPlaced = () => {
        debugger
        let ReqIdPriced = this.state.PricedOrderNum;
        let NonstockONum = this.state.NonCataOrderNum;
        //PdforderNum = ReqIdPriced != null && ReqIdPriced != "" ? ReqIdPriced : NonstockONum;
        if (ReqIdPriced != null && ReqIdPriced != "") {
            let cartFileBo = {
                Session_UserID: this.state.Login_UserId,
                Session_BUSUNIT: this.state.Login_UserBU,
                OrderNO: ReqIdPriced,//"NY00446489",
                fileName: ""
            }
            ShoppingCartAction.OrderPdf(cartFileBo)
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
        else if (NonstockONum != null && NonstockONum != "") {
            let cartFileBo = {
                Session_UserID: this.state.Login_UserId,
                Session_BUSUNIT: this.state.Login_UserBU,
                OrderNO: NonstockONum,//"NY00446489",
                fileName: ""
            }
            ShoppingCartAction.OrderPdf(cartFileBo)
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
        else {
            this.props.history.push({
                pathname: `\HomePage`,
            });
        }
        
    }
    /*
     * This method is invoke the action OrderPdf emit the response.
     * */
    OrderPDFShow = () => {

        const { t } = this.props;
        OrderSuccessCOunt = OrderSuccessCOunt + 1
        let OrderPdf = ShoppingCartStore.OrderPDFMsge;
        //.log("OrderPdf", OrderPdf)
        //if ((hidden == true || this.state.ShowPdf == true) && (this.state.base64URL != "" || OrderPdf != "")) {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

        // }
        //[10/29/21, CAS - Safari Browser - Crystal Report Issue, PC_02, Dhevanesam R]
        debugger
        if (isSafari || isMobile) {
            debugger;
            let sliceSize = 512
            const byteCharacters = atob(OrderPdf.split(',')[1]);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: "application/pdf" });
            saveAs(blob, "SDIShoppingCartReport.pdf");
            /*alert("The report is downloaded successfully")            
            this.props.history.push({
                pathname: `\HomePage`,
            });*/
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("The report is downloaded successfully")
            }).then(() => {
                this.props.history.push({
                    pathname: '/HomePage',
                });
                window.preventDefault();
            });
            
        }
        else {
            $('#pdfPreview').modal('show');
            this.setState({
                base64URL: OrderPdf
            })
        }                      
    }
    /*
     * Reused from the non catalog require page.
     * To check the length of the attached file name.
     * */
    handleAttachment = (event) => {
        const { t } = this.props;
        document.getElementById("AttachError").innerText = ""
        let files = event.target.files;
        let filetype = files[0].type;
        //[6/22/22, PC_AU_02, Bug - 2075, Dhevanesam R]
        if (!(filetype.toLowerCase().includes('jpg') || (filetype.toLowerCase().includes('png')) || (filetype.toLowerCase().includes('jpeg')) || (filetype.toLowerCase().includes('pdf')))) {
            //alert("You can choose only the Image and PDF files!!");
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("You can choose only the Image and PDF files!!")
            })
            return;
        }
        let Attachments = this.state.attachmentPathArr
        let filesvalue = []
        let removevalue = this.state.removeattach
        if (files.length > 0) {
            if (this.state.attachmentPathArr != null && this.state.attachmentPathArr != undefined) {
                if (this.state.attachmentPathArr.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        debugger
                        let isDuplicate = this.state.attachmentPathArr.filter((attach) => attach.FileName == files[i].name).length > 0 ? true : false;
                        if (isDuplicate) {
                            document.getElementById("AttachError").innerText = (t("Selected file is already attached, please try again"))
                            this.setState({ attachmentErrormsg: "Selected file is already attached, please try again" });
                            return false;
                        }

                        if (removevalue != null && removevalue != undefined) {
                            if (removevalue.length > 0) {
                                this.state.removeattach.map((val, index) => {

                                    if (val.FileName == files[i].name) {
                                        Attachments.push(val)
                                        removevalue.splice(index, 1);
                                    }
                                    else {
                                        filesvalue.push(files[i])
                                    }
                                });
                            }
                            else {
                                filesvalue.push(files[i])
                            }
                        }
                        else {
                            filesvalue.push(files[i])
                        }
                    }
                }
                else {
                    for (let i = 0; i < files.length; i++) {
                        if (removevalue != null && removevalue != undefined) {
                            if (removevalue.length > 0) {
                                this.state.removeattach.map((val, index) => {

                                    if (val.FileName == files[i].name) {
                                        Attachments.push(val)
                                        removevalue.splice(index, 1);
                                    }
                                    else {
                                        filesvalue.push(files[i])
                                    }
                                });
                            }
                            else {
                                filesvalue.push(files[i])
                            }
                        }
                        else {
                            filesvalue.push(files[i])
                        }
                    }
                }
                let filepathlength = ""
                //let filearray = Attachments
                //if (filesvalue != null && filesvalue != undefined) {
                //    if (filesvalue.length != 0) {
                //        for (let j = 0; i < filesvalue.length; j++) {
                //            filearray.push(Attachments[0])
                //        }
                //    }
                //}
                if (Attachments != null && Attachments != undefined) {
                    for (let i = 0; i < Attachments.length; i++) {
                        filepathlength = filepathlength + Attachments[i].FilePath + "|"
                    }
                }
                if (filesvalue != null && filesvalue != undefined) {
                    for (let j = 0; j < filesvalue.length; j++) {
                        filepathlength = filepathlength + filesvalue[j].name + "|"
                    }
                }
                let Vlauelength = filepathlength.length
                let lengthvalidate = filepathlength.length > 254 ? 1 : 0
                if (lengthvalidate == 1) {
                    document.getElementById("AttachError").innerText = (t("You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names."))
                }
                else {
                    document.getElementById("AttachError").innerText = ""
                }
                if (lengthvalidate != 1) {
                    if (filesvalue != null && filesvalue != undefined) {
                        if (filesvalue.length > 0) {
                            ShoppingCartAction.addAttachment(filesvalue);
                        }
                    }
                    this.setState({ attachmentPathArr: Attachments, removeattach: removevalue })
                }
                $("#ShopCartcustomFile").val('');
            }
        }

    };
    favOrderRemoveAttachments = (filepath, index) => {
        console.log(filepath);
        rmvAttachindex = index
        let removeBO = {
            filePath: filepath
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        ShoppingCartAction.favOrderRemoveAttachments(removeBO);
    }

    favOrderremoveAttachmentResponse = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let isValid = ShoppingCartStore.favOrderRemoveAttachments.indexOf("success") >= 0 ? true : false;
        if (isValid) {
            this.setState({ attachmentPathArrImg: [] });
            document.getElementById("Favlblerror").innerText = "";
        } else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Updation failed")
            });
        }
    };
    /*
     * Reused from the non catalog require page.
     * Download attach file.
     * */
    downloadAttachment = (e, attachment) => {

        e.preventDefault();
        let downloadBO = {
            fileName: attachment.FileName,
            filePath: attachment.FilePath
        }
        ShoppingCartAction.downloadAttachment(downloadBO);
    };
    /*
     * Reused from the non catalog require page.
     * Remove the attachment file.
     * */
    removeAttachments = (filepath, index) => {
        const { t } = this.props;
        let tempAttachments = this.state.attachmentPathArr;
        let RemoveAtt = tempAttachments[index]
        let removeattachL = []
        removeattachL.push(RemoveAtt)
        tempAttachments.splice(index, 1);
        this.setState({ attachmentPathArr: tempAttachments, removeattach: removeattachL });
        let filepathlength = ""
        for (let i = 0; i < tempAttachments.length; i++) {
            filepathlength = filepathlength + tempAttachments[0].FilePath + "|"
        }
        let lengthvalidate = filepathlength.length > 254 ? 1 : 0
        if (lengthvalidate == 1) {
            document.getElementById("AttachError").innerText = (t("You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names."))
        }
        else {
            document.getElementById("AttachError").innerText = ""
        }
    }
    /*
     * Reused from the non catalog require page.
     * Bind from the attachment pop up.
     * */
    bindAttachments = () => {

        let atatchmentdata = this.state.attachmentPathArr
        if (atatchmentdata != null && atatchmentdata != undefined) {
            if (atatchmentdata.length > 0) {
                return this.state.attachmentPathArr.map((attachment, index) => {
                    return (
                        <span className="attachment-file-name pl-0 my-2 d-inline-block" key={index}>
                            <a href="#" className="Attachment-overflow" onClick={(e) => this.downloadAttachment(e, attachment)} data-toggle="tooltip" title={attachment.FileName}>{attachment.FileName}</a>
                            <span className="attachment-remove-icon ml-1 mr-1" onClick={() => this.removeAttachments(attachment.FilePath, index)}>X  </span>
                        </span>
                    );
                });
            }
        }
    };
    /*
     * Reused from the non catalog require page.
     * Load attachment.
     * */
    loadAttachments = () => {
        const { t } = this.props;
        document.getElementById("AttachError").innerText = ""
        let isValid = ShoppingCartStore.attachmentResponse.indexOf("You have exceeded the length of total file names") >= 0 ? false : true;
        $("#ShopCartcustomFile").val('');
        if (isValid) {
            let attachmentResponse = JSON.parse(ShoppingCartStore.attachmentResponse);
            let tempAttachments = this.state.attachmentPathArr;
            if (tempAttachments != null) {
                tempAttachments = tempAttachments.concat(attachmentResponse);
            }
            else {
                tempAttachments = attachmentResponse;
            }
            this.setState({ attachmentPathArr: tempAttachments });
        } else {
            document.getElementById("AttachError").innerText = (t("You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names."))
            this.setState({
                attachmentErrormsg: "You have exceeded the length of total file names. \nPlease adjust your entry or remove longer file names."
            });
        }
    };
    /*
     * Reused from the non catalog require page.
     * Remove attachment response.
     * */
    removeAttachmentResponse = () => {

        let isValid = ShoppingCartStore.removeAttachmentResponse.indexOf("success") >= 0 ? true : false;
        if (isValid) {
            //let tempAttachments = this.state.attachmentPathArr;
            //tempAttachments.splice(rmvAttachindex, 1);
            //this.setState({ attachmentPathArr: tempAttachments });
            //rmvAttachindex = -1;
        }
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
    };
    /*
     * RF No: SC_PC_50
     * Cancel order button click.
     * */
    CancelOrder = () => {
        let cancelorderBo = {
            Session_UserID: this.state.Login_UserId,
            Session_BUSUNIT: this.state.Login_UserBU,
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        ShoppingCartAction.CancelOrderItem(cancelorderBo);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    /*
     * RF No: SC_PC_50
     * This method is invoke when the CancelOrderItem response is emitted.
     * */
    COStatus = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let CancelResponse = JSON.parse(ShoppingCartStore.CancelOrderMsge);
        if (CancelResponse == null || CancelResponse == "" || CancelResponse == " ") {
            this.refreshMenu();
            //[05/29/2023, PS_ZE_123_APP_03, Dhevanesam R]
            if (this.state.clearCart != "true") {
                this.props.history.push({
                    pathname: "/HomePage",
                });
            }
            else {
				this.setState({ CartItems: [], CartCOunt: 0 });
            }
        }
        else {
            //alert(t("Technical Issue -- Contact SDI"))
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical Issue -- Contact SDI")
            })
        }
    }
    BacktoSearh = (Type) => {
        //let searchvalue = CookieService.getLocalStorageValues("Session_SearchValue")
        if (Type == 2) {
            //[3/17/2023, PS_SR_URL_APP_11 - To remove catalog look up tree, Dhevanesam R]
            this.props.history.push({
                pathname: `\searchresults`,
                search: `?key=${Searchkeywoed}`
                //&unspsc=${SearchUnspsc}&CatTree=${SearchCatTree}` //[7/20/2022]Bug - 2357 Passing unspsc code & catTree flag to follow catalog lookup search for lookup items - Poornima S
            });
        }
        else if (Type == 3) {
            this.props.history.push({
                pathname: "/HomePage",
            });
        }
        else {
            this.props.history.push({
                pathname: `\Favorites`,
            });
        }
    }
    onAccordianClick = (Status) => {

        $('#order-row1').toggleClass('d-none');
        $('tr').removeClass('tr-border');
        //$('#order-row2').addClass('tr-border');
        let SHowOrder = Status
        if (SHowOrder == 0) {
            SHowOrder = 1
            $('#order-row2').toggleClass('accordion-arrow-change1');
            let ClassAvailable = document.getElementById('order-row2').className;
            if (ClassAvailable == "accordion-arrow1 float-left accordion-arrow-change1") {
                $('#order-row2').removeClass('accordion-arrow-change1')
            }
            $('#order-row1').removeClass('d-none');
        }
        else {
            SHowOrder = 0
            $('#order-row2').toggleClass('accordion-arrow-change1');
            $('#order-row1').addClass('d-none');
        }
        this.setState({ ShowOrderDetails: SHowOrder })
    };
    clearAttaValid = () => {
        $("#ShopCartcustomFile").val('');
        document.getElementById("AttachError").innerText = ""
    }
    expandAll = () => {
        let { CartItems } = this.state
        let l = 0

        if (this.state.isExpand == false) {
            this.setState({ isExpand: true });
            for (l = 0; l < CartItems.length; l++) {
                let itemid = "order-row-" + l;
                let itemidvalue = "#order-row-" + l;
                let ClassAvailable = document.getElementById(itemid).className;
                //.log("ClassAvailable", ClassAvailable)
                if (ClassAvailable == "col-md-12 pt-3 mt-2 border-top accordian-body d-none") {
                    $(itemidvalue).removeClass('d-none');
                }
            }

        } else {
            this.setState({ isExpand: false });
            for (l = 0; l < CartItems.length; l++) {
                let itemid = "order-row-" + l;
                let itemidvalue = "#order-row-" + l;
                let ClassAvailable = document.getElementById(itemid).className;
                //.log("ClassAvailable", ClassAvailable)
                if (ClassAvailable != "col-md-12 pt-3 mt-2 border-top accordian-body d-none") {
                    $(itemidvalue).addClass('d-none');
                }
            }

        }
    }
    onAccordianClickitemlevel = (event, index) => {

        if (event) {
            event.preventDefault();
        }
        let itemid = "order-row-" + index;
        let itemidvalue = "#order-row-" + index;
        let ClassAvailable = document.getElementById(itemid).className;
        //.log("ClassAvailable", ClassAvailable)
        if (ClassAvailable == "col-md-12 pt-3 mt-2 border-top accordian-body d-none") {
            $(itemidvalue).removeClass('d-none');
        }
        else {
            $(itemidvalue).addClass('d-none');
        }
    };
    headerRedirect = (event, pageName) => {
        let queryParamindex = pageName.indexOf("?");
        let pagePath = pageName;
        let queryParams = "";
        if (queryParamindex > 0) {
            pagePath = pageName.substring(0, queryParamindex);
            queryParams = pageName.substring(queryParamindex, pageName.length);
        };

        event.preventDefault();
        this.props.history.push({
            pathname: pagePath,
            search: queryParams
        });
    };
	handleChangeShipto = (values) => {
		//[10/11/23, Bug - 4276, Fix the border highlighted issue after ship to drop down change, Johnprinto]
		this.setState(
			{ defaultShipto: values, OLShipToErr: false })
		if (this.state.OLShipToErr) {
			this.handleShoppingCartValidation("SHIPTO");
		}
		//.log('Option selected:', this.state.defaultShipto);
	}
    GetWallet = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        debugger
        let getWalletBO = {
            UserId: this.state.Login_UserId,
            UserBU: this.state.Login_UserBU,
            DefaultCard: " ",
        }
        ShoppingCartAction.getWalletValue(getWalletBO)
        this.setState({ ShowCerditCard: true })
    }
    WalletBind = () => {
        debugger
        let WalletValue = JSON.parse(ShoppingCartStore.WalletValue);
        let WalletValueTable = WalletValue.CardExist
        //.log("WalletValue", WalletValue)
        if (WalletValueTable != null && WalletValueTable != undefined) {
            if (WalletValueTable.length > 0 && (this.state.SelectedCardToken == "" || this.state.SelectedCardToken == null) && this.state.ShowCerditCard == false) {
                let CardNumber = "XXXX XXXX XXXX " + WalletValueTable[0].CARD_NUMBER
                document.getElementById('CardTypeLabel').innerHTML = WalletValueTable[0].CARD_TYPE
                document.getElementById('CardToken').innerHTML = WalletValueTable[0].CARD_TOKEN
                this.setState({
                    WalletValue: WalletValueTable, CardNumber: CardNumber, CardImageType: WalletValueTable[0].CARD_TYPE, CardfieldDisabled: true,
                    SelectedCardToken: WalletValueTable[0].CARD_TOKEN, CardHMCA: WalletValueTable[0].CARD_HMAC, CardToken: WalletValueTable[0].CARD_TOKEN
                })
            }
            else {
                if (WalletValueTable.length > 0) {
                    let CardNumber = "XXXX XXXX XXXX " + WalletValueTable[0].CARD_NUMBER
                    if (this.state.SelectedCardToken == "" || this.state.SelectedCardToken == null) {
                        this.setState({
                            WalletValue: WalletValueTable, CardfieldDisabled: false, SelectCardNumber: CardNumber, SelectedCardImageType: WalletValueTable[0].CARD_TYPE,
                            CardToken: WalletValueTable[0].CARD_TOKEN, CardHMCA: WalletValueTable[0].CARD_HMAC
                        })
                    }
                    else {
                        let CardNumberValue = $('#CardNumber').val()
                        let Index = 0;
                        if (CardNumberValue != null && CardNumberValue != undefined && CardNumberValue != "") {
                            CardNumberValue = CardNumberValue.replaceAll(" ", "")
                            if (CardNumberValue.length == 14) {
                                CardNumberValue = CardNumberValue.slice(10)
                            }
                            else if (CardNumberValue.length == 15) {
                                CardNumberValue = CardNumberValue.slice(11)
                            }
                            else if (CardNumberValue.length == 16) {
                                CardNumberValue = CardNumberValue.slice(12)
                            }
                            WalletValueTable.map((Wallet, index) => {
                                if (CardNumberValue == Wallet.CARD_NUMBER) {
                                    Index = index
                                    CardNumber = "XXXX XXXX XXXX " + Wallet.CARD_NUMBER
                                }
                            })
                        }

                        this.setState({
                            WalletValue: WalletValueTable, CardfieldDisabled: true, SelectCardNumber: CardNumber, SelectedCardImageType: WalletValueTable[Index].CARD_TYPE,
                            CardToken: WalletValueTable[Index].CARD_TOKEN, CardHMCA: WalletValueTable[Index].CARD_HMAC
                        })
                    }
                }
            }
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    BindCards = () => {
        debugger
        if (this.state.WalletValue != null && this.state.WalletValue != undefined) {
            //.log("SelectedCardToken", this.state.SelectedCardToken)
            return this.state.WalletValue.map((Wallet, index) => {
                debugger
                if ((index == 0 && (this.state.CardToken == "" || this.state.CardToken == null)) || (this.state.CardToken == Wallet.CARD_TOKEN)) {
                    return (
                        <div className="d-block" key={"walletKey" + index}>
                            <label className="custom-radiobtn" htmlFor={"wallet" + index}><input type="radio" name="wallet" id={"wallet" + index} checked={this.state.CardToken == Wallet.CARD_TOKEN ? true : false} onClick={() => this.CardSelected(Wallet.CARD_TOKEN, Wallet.CARD_NUMBER, Wallet.CARD_TYPE)} />
                                <span className="checkmark checkmark-org" />XXXX XXXX XXXX {Wallet.CARD_NUMBER} <span className="delete-icon ml-3" onClick={() => this.RemoveCard(Wallet.CARD_TOKEN, Wallet.CARD_NUMBER)} />
                                <span className="d-none" id={"CARD_TOKEN" + index}>{Wallet.CARD_TOKEN}</span>
                            </label>
                        </div>)
                }
                //else if (this.state.SelectedCardToken == Wallet.CARD_TOKEN) {
                //    return (
                //        < div className="d-block" key={"walletKey" + index}>
                //            <label className="custom-radiobtn" htmlFor={"wallet" + index}><input type="radio" name={"wallet1" + index} id={"wallet" + index} defaultChecked={true} onChange={() => this.CardSelected(Wallet.CARD_TOKEN, Wallet.CARD_NUMBER, Wallet.CARD_TYPE)} />
                //                <span className="checkmark checkmark-org" />XXXX XXXX XXXX {Wallet.CARD_NUMBER} <span className="delete-icon ml-3" onClick={() => this.RemoveCard(Wallet.CARD_TOKEN, Wallet.CARD_NUMBER)} />
                //                <span className="d-none" id={"CARD_TOKEN" + index}>{Wallet.CARD_TOKEN}</span>
                //            </label>
                //        </div>

                //    )
                //}
                else {
                    return (
                        <div className="d-block" key={"walletKey" + index}>
                            <label className="custom-radiobtn" htmlFor={"wallet" + index}><input type="radio" name="wallet" id={"wallet" + index} checked={this.state.CardToken == Wallet.CARD_TOKEN ? true : false} onClick={() => this.CardSelected(Wallet.CARD_TOKEN, Wallet.CARD_NUMBER, Wallet.CARD_TYPE, Wallet.CARD_HMAC)} />
                                <span className="checkmark checkmark-org" />XXXX XXXX XXXX {Wallet.CARD_NUMBER} <span className="delete-icon ml-3" onClick={() => this.RemoveCard(Wallet.CARD_TOKEN, Wallet.CARD_NUMBER)} />
                                <span className="d-none" id={"CARD_TOKEN" + index}>{Wallet.CARD_TOKEN}</span>
                            </label>
                        </div>

                    )
                }
            });
        }
        else {
            return (<div className="d-block">
                <label className="custom-radiobtn" >
                    No card in wallet
                </label>
            </div>)
        }
    }
    CardSelected = (CardToken, Cardvalue, CardType, CARD_HMAC) => {
        debugger
        let CardNumber = "XXXX XXXX XXXX " + Cardvalue;
        this.setState({
            SelectCardNumber: CardNumber, SelectedCardImageType: CardType,
            CardToken: CardToken, SelectedCardHMCA: CARD_HMAC
        })
    }
    SetCardField = (Type) => {
        debugger
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');

        let SelectCardNumber = this.state.SelectCardNumber
        let SelectedCardImageType = this.state.SelectedCardImageType
        let CardToken = this.state.CardToken
        let SelectedCardHMCA = this.state.SelectedCardHMCA
        if (Type == 1) {
            document.getElementById('CardTypeLabel').innerHTML = SelectedCardImageType
            document.getElementById('CardToken').innerHTML = CardToken
            document.getElementById("ExpiryDateError").innerHTML = ""
            document.getElementById("CVVError").innerHTML = ""
            this.setState({
                CardNumber: SelectCardNumber, CardImageType: SelectedCardImageType,
                CardfieldDisabled: true, CardToken: CardToken,
                SelectedCardToken: CardToken, CardHMCA: SelectedCardHMCA, ExpiryDate: "", ExpiryYear: "", CardCVV: ""
            })
        }
        else {
            this.setState({ SelectCardNumber: "", SelectedCardImageType: "", CardToken: "", SelectedCardHMCA: "" })
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    handleFieldChange = (event) => {
        debugger
        const { t } = this.props;
        let Value = 0
        let Validateone = false
        if (event.target.name == "ExpiryDate" && event.target.value != null && event.target.value != "") {
            Value = event.target.value.replace(/[^0-9]/g, '');
            if (Value == null || Value == "") {
                this.setState({ [event.target.name]: "" });
            }
            else if (Value != null || Value != "") {

                let valuelength = Value.length
                if (Value == "01") {
                    this.setState({ [event.target.name]: "01" });
                }
                else if (Value > 1 && Value < 10 && valuelength != 2) {
                    this.setState({ [event.target.name]: "0" + Value });
                    Value = "0" + Value
                }
                else if (Value < 13) {
                    this.setState({ [event.target.name]: Value });
                }
            }
        }
        else if (event.target.name == "CardCVV") {
            let Value = event.target.value.replace(/[^0-9]/g, '');
            let CardTypeValue = this.state.CardImageType
            if (CardTypeValue != "" && CardTypeValue != undefined && CardTypeValue != null) {
                CardTypeValue = CardTypeValue.toUpperCase()
            }
            if (this.state.CardImageType == "AMERICANEXPRESS" && event.target.value.length < 5) {
                this.setState({ [event.target.name]: Value });
            }
            else if (this.state.CardImageType != "AMERICANEXPRESS" && event.target.value.length < 4) {
                this.setState({ [event.target.name]: Value });
            }

        }
        else if (event.target.name == "CardNumber") {
            let ExpiryDate = "";
            let ExpiryYear = "";
            let CardCVV = "";
            let CardNumberValue = event.target.value.replace(/[^0-9]/g, '');
            CardNumberValue = CardNumberValue.replaceAll(" ", "")
            if (CardNumberValue.length == 0) {
                CardNumberValue = CardNumberValue

            }
            else if (CardNumberValue.length > 0 && CardNumberValue.length < 5) {
                CardNumberValue = CardNumberValue
            }
            else if (CardNumberValue.length > 4 && CardNumberValue.length < 9) {
                CardNumberValue = CardNumberValue.slice(0, 4) + " " + CardNumberValue.slice(4)
            }
            else if (CardNumberValue.length > 8 && CardNumberValue.length < 13) {//1234 56968
                CardNumberValue = CardNumberValue.slice(0, 4) + " " + CardNumberValue.slice(4, 8) + " " + CardNumberValue.slice(8)
            }
            else if (CardNumberValue.length > 12 && CardNumberValue.length < 17) {//1234 5678 96339
                CardNumberValue = CardNumberValue.slice(0, 4) + " " + CardNumberValue.slice(4, 8) + " " + CardNumberValue.slice(8, 12) + " " + CardNumberValue.slice(12)
            }
            let cardtypr = this.GetCardType(event.target.value.trim())
            if (cardtypr != undefined && cardtypr != null) {
                document.getElementById('CardTypeLabel').innerHTML = cardtypr
            }
            if (cardtypr != "" && cardtypr != null && cardtypr != undefined) {
                cardtypr = cardtypr.toLowerCase()
            }

            this.setState({ [event.target.name]: CardNumberValue, CardImageType: cardtypr, CardCVV: CardCVV, ExpiryYear: ExpiryYear, ExpiryDate: ExpiryDate });
        }
        else {
            let Value = event.target.value.replace(/[^0-9]/g, '');
            this.setState({ [event.target.name]: Value });
        }
        if (event.target.value.length == 19 && event.target.name == "CardNumber") {
            $("#ExpiryDateValue").focus();
        }
        else if (Value.length == 2 && event.target.name == "ExpiryDate") {
            $("#ExpiryYearValue").focus();
        }
        else if (event.target.value.length == 2 && event.target.name == "ExpiryYear") {
            $("#CardCvvNumber").focus();
        }
        let CardCVV = $('#CardCvvNumber').val();
        if (this.state.CardNumber == null || this.state.CardNumber == undefined || this.state.CardNumber == "") {
            $('#CardNumber').addClass('errorcolour');
            $('#CardNumber').removeClass('fontGreen');
            document.getElementById("ExpiryDateError").innerHTML = (t("Card number is required"));
        }
        else if (this.state.CardImageType == "" || this.state.CardImageType == null || this.state.CardImageType == undefined) {
            $('#CardNumber').addClass('errorcolour');
            $('#CardNumber').removeClass('fontGreen');
            document.getElementById("ExpiryDateError").innerHTML = (t("Invalid card number"));
        }
        else if (this.state.ExpiryDate == null || this.state.ExpiryDate == undefined || this.state.ExpiryDate == "") {
            document.getElementById("ExpiryDateError").innerHTML = (t("Expiration month & Year is required"));
        }
        else if (this.state.ExpiryYear == null || this.state.ExpiryYear == undefined || this.state.ExpiryYear == "") {
            document.getElementById("ExpiryDateError").innerHTML = (t("Expiration month & Year is required"));
        }
        else if (CardCVV == null || CardCVV == undefined || CardCVV == "") {
            document.getElementById("CVVError").innerHTML = (t("CVV/CVC Number is required"));
        }
        else if (CardCVV != null && CardCVV != undefined && CardCVV != "") {

            let CardType = document.getElementById('CardTypeLabel').innerHTML

            if (CardType != "" && CardType != undefined && CardType != null) {
                CardType = CardType.toUpperCase()
            }
            if (CardType == "AMERICANEXPRESS") {
                if (CardCVV.length < 4) {
                    // Validate = false
                    document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
                }
            }
            else if (CardType != "AMERICANEXPRESS" && CardCVV.length > 3) {
                //Validate = false
                document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
            }
            else if (CardCVV.length < 3) {
                //Validate = false
                document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
            }

        }
        if (this.state.CardNumber != null && this.state.CardNumber != undefined && this.state.CardNumber != "" && this.state.CardImageType != "" && this.state.CardImageType != null && this.state.CardImageType != undefined) {
            $('#CardNumber').removeClass('errorcolour');
            $('#CardNumber').addClass('fontGreen');
        }
    }
    GetCardType = (number) => {
        // visa
        debugger
        number = number.replaceAll(" ", "")
        var re = new RegExp("^4");
        if (number.match(re) != null) {
            return "Visa";
        }

        // Mastercard 
        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
            return "Mastercard";
        }

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null) {
            return "AMEX";
        }

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null) {
            return "Discover";
        }
        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null) {
            return "Diners";
        }

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null) {
            return "Diners - Carte Blanche";
        }

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null) {
            return "JCB";
        }

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null) {
            return "Visa Electron";
        }

        return "";

    }
    SetExpiryDate = () => {
        debugger
        let ExpiryDate = $('#ExpiryDateValue').val()
        if (ExpiryDate != "" && ExpiryDate != null) {
            if (ExpiryDate.length != 2) {
                if (ExpiryDate == 1 || ExpiryDate == "1") {
                    let Date = "0" + this.state.ExpiryDate
                    this.setState({ ExpiryDate: Date });
                }
            }
        }
    }
    bindCardImage = () => {
        debugger
        const style = {
            image: {
                position: 'sticky !important',
                display: 'block',
                left: '0px !important',
                top: '0px !important',
            },
        }
        let imagePath = "/images/credit-card.png"
        let CardType = this.state.CardImageType
        if (CardType != "" && CardType != null && CardType != undefined) {
            CardType = CardType.toUpperCase()
        }
        if (CardType == "MASTERCARD") {
            imagePath = "/images/cc-mc.png";
        } else if (CardType == "AMERICANEXPRESS") {
            imagePath = "/images/cc-aex.png";
        } else if (CardType == "DISCOVER") {
            imagePath = "/images/cc-discover.png";
        } else if (CardType == "VISA") {
            imagePath = "/images/cc-visa.png";
        } else if (CardType == "JCB") {
            imagePath = "/images/cc-jcb.png";
        } else if (CardType == "DINERS") {
            imagePath = "/images/cc-dc.png";
        } else if (CardType == "AMEX") {
            imagePath = "/images/cc-aex.png";
        } else {
            imagePath = "/images/credit-card.png";
        }
        if (imagePath != "/images/credit-card.png") {
            $('#CardNumber').removeClass('errorcolour');
            $('#CardNumber').addClass('fontGreen');
        }
        return (<Image
            src={imagePath}
            width={50}
            height={35}
            style={style.image}
            noImageSrc="/images/credit-card.png"
        />)
    }

    RemoveCard = (CardToken, CardNo) => {
        let RemoveCardBO = {
            cardToken: CardToken,
            cardNo: CardNo,
            srcName: "ShoppingCartPayment.aspx",
            UserId: this.state.Login_UserId,
            UserBU: this.state.Login_UserBU,
        }
        ShoppingCartAction.RemoveCard(RemoveCardBO)
        let RemoveItem = []
        this.state.WalletValue.map((Wallet, index) => {
            if (Wallet.CARD_NUMBER == CardNo && Wallet.CARD_TOKEN == CardToken) {
                RemoveItem = this.state.WalletValue[index]
            }
        });
        //.log("RemoveItem", RemoveItem)
        this.setState({ SelectedRemoveCard: RemoveItem })
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    RemoveCardBind = () => {
        let RemoveCard = JSON.parse(ShoppingCartStore.RemoveCardValue);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let Response = RemoveCard.Response
        //.log("DeleteCard_Response", Response)
        let RemainingCard = []
        if (Response != null && Response != undefined) {
            debugger
            if (Response.length > 0) {
                if (Response[0].Response == "Success") {
                    debugger
                    let RemoveItem = this.state.SelectedRemoveCard
                    let WalletValueL = this.state.WalletValue
                    let Cardremoved = false
                    this.state.WalletValue.map((Wallet, index) => {
                        debugger
                        if (Wallet.CARD_NUMBER == RemoveItem.CARD_NUMBER && Wallet.CARD_TOKEN == RemoveItem.CARD_TOKEN) {
                            debugger
                            let CardNumberValue = $('#CardNumber').val()
                            CardNumberValue = CardNumberValue.replaceAll(" ", "")
                            let Cardnumberdata = CardNumberValue.replaceAll(" ", "")
                            if (CardNumberValue.length == 14) {
                                CardNumberValue = CardNumberValue.slice(10)
                            }
                            else if (CardNumberValue.length == 15) {
                                CardNumberValue = CardNumberValue.slice(11)
                            }
                            else if (CardNumberValue.length == 16) {
                                CardNumberValue = CardNumberValue.slice(12)
                            }
                            if (RemoveItem.CARD_NUMBER == CardNumberValue) {
                                Cardremoved = true
                            }
                            WalletValueL.splice(index, 1)
                        }
                    })
                    if (WalletValueL != null && WalletValueL != undefined && Cardremoved == true) {
                        if (WalletValueL.length > 0) {
                            document.getElementById("wallet0").checked = true
                            this.setState({
                                SelectCardNumber: "XXXX XXXX XXXX " + WalletValueL[0].CARD_NUMBER, SelectedCardImageType: WalletValueL[0].CARD_TYPE,
                                CardToken: WalletValueL[0].CARD_TOKEN, SelectedCardHMCA: WalletValueL[0].CARD_HMAC
                            })
                        }
                    }
                    this.setState({
                        WalletValue: WalletValueL,
                        CardNumber: Cardremoved == true ? "" : this.state.CardNumber,
                        CardToken: Cardremoved == true ? "" : this.state.CardToken,
                        CardImageType: Cardremoved == true ? "" : this.state.CardImageType,
                        ExpiryDate: Cardremoved == true ? "" : this.state.ExpiryDate,
                        CardCVV: Cardremoved == true ? "" : this.state.CardCVV,
                        ExpiryYear: Cardremoved == true ? "" : this.state.ExpiryYear,
                        CardfieldDisabled: Cardremoved == true ? false : this.state.CardfieldDisabled,
                        CardHMCA: Cardremoved == true ? "" : this.state.CardHMCA,

                    })
                }
            }
        }
    }
    clearCard = () => {
        document.getElementById('CardTypeLabel').innerHTML = ""
        this.setState({ CardNumber: "", CardToken: "", ExpiryDate: "", ExpiryYear: "", CardCVV: "", CardfieldDisabled: false, SelectedCardToken: "", CardImageType: "", CardHMCA: "" })
    }
    MakePayMent = () => {
        const { t } = this.props;
        let Validate = true
        let CardType = this.state.CardImageType//document.getElementById('CardTypeLabel').innerHTML
        let CardToken = this.state.CardNumber//document.getElementById('CardToken').innerHTML
        let ExpiryDate = $('#ExpiryDateValue').val();
        let ExpiryYear = $('#ExpiryYearValue').val();
        let CardCVV = $('#CardCvvNumber').val();
        document.getElementById("ExpiryDateError").innerHTML = "";
        if (CardType == "" || CardType == null || CardToken == "" || CardToken == null) {
            Validate = false
            document.getElementById("ExpiryDateError").innerHTML = (t("Invalid card number"));
        }
        if (ExpiryDate == "" || ExpiryDate == null) {
            Validate = false
            document.getElementById("ExpiryDateError").innerHTML = (t("Expiration month & Year is required"));
        }
        else {
            var today = new Date();
            var date = today.getMonth() + 1
            var year = today.getFullYear()
            let EnteredYear = $('#ExpiryYearValue').val()
            var EnteredDate = "20" + EnteredYear
            if (parseInt(ExpiryDate) == 0 || parseInt(ExpiryDate) > 12 || (EnteredDate == year.toString() && parseInt(ExpiryDate) < date)) {
                Validate = false
                document.getElementById("ExpiryDateError").innerHTML = (t("Invalid expiry date"));
            }
        }
        if (ExpiryYear == "" || ExpiryYear == null) {
            Validate = false
            document.getElementById("ExpiryDateError").innerHTML = (t("Expiration month & Year is required"));
        }
        else {
            var today = new Date();
            var date = today.getFullYear()
            var Month = today.getMonth() + 1
            var EnteredMOnth = $('#ExpiryDateValue').val()
            var EnteredDate = "20" + ExpiryYear
            if (parseInt(EnteredDate) < date || (EnteredMOnth < Month && parseInt(EnteredDate) == date && EnteredMOnth != "")) {
                Validate = false
                document.getElementById("ExpiryDateError").innerHTML = (t("Invalid expiry date"));
            }
        }
        if (CardCVV == "" || CardCVV == null) {
            Validate = false
            document.getElementById("CVVError").innerHTML = (t("CVV/CVC Number is required"));
        }
        else {
            let CardType = document.getElementById('CardTypeLabel').innerHTML
            if (CardType != null && CardType != undefined && CardType != "") {
                CardType = CardType.toUpperCase()
            }
            if (CardType == "AMERICANEXPRESS") {
                if (CardCVV.length < 4) {
                    Validate = false
                    document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
                }
            }
            else if (CardType != "AMERICANEXPRESS" && CardCVV.length > 3) {
                Validate = false
                document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
            }
            else if (CardCVV.length < 3) {
                Validate = false
                document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
            }
        }
        if (Validate == true) {
            let Ordernumber = $('#OrderNum').val();
            Ordernumber = Ordernumber != "" && Ordernumber != null ? Ordernumber.trim() : "NEXT"

            if (Ordernumber != "NEXT") {
                if (CookieService.getLocalStorageValues("Session_MANORDNO") != "A") {
                    Ordernumber = CookieService.getLocalStorageValues("Session_ORDERPREFIX") + Ordernumber.toUpperCase()
                }
            }
            //let amount = this.state.OrderTotalValue
            let amount = document.getElementById("OrderTaxTotal").innerHTML
            amount = amount.slice(2)
            //.log("amount", amount)
            let CardNumberValue = $('#CardNumber').val()
            CardNumberValue = CardNumberValue.replaceAll(" ", "")
            let Cardnumberdata = CardNumberValue.replaceAll(" ", "")
            if (CardNumberValue.length == 14) {
                CardNumberValue = CardNumberValue.slice(10)
            }
            else if (CardNumberValue.length == 15) {
                CardNumberValue = CardNumberValue.slice(11)
            }
            else if (CardNumberValue.length == 16) {
                CardNumberValue = CardNumberValue.slice(12)
            }
            let ValidateDate = ExpiryDate.toString() + ExpiryYear.toString()
            let ProcessPaymentBo = {
                edate: ValidateDate,
                card: this.state.CardToken == "" || this.state.CardToken == null ? Cardnumberdata : this.state.CardToken,
                cvv: CardCVV,
                Order_OrderNO: Ordernumber,
                UserId: this.state.Login_UserId,
                UserBU: this.state.Login_UserBU,
                amount: amount,
                Session_ORDERPREFIX: CookieService.getLocalStorageValues("Session_ORDERPREFIX"),
                Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU")
            }
            //.log("ProcessPaymentBo", ProcessPaymentBo)
            ShoppingCartAction.ProcessPayment(ProcessPaymentBo)
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }

    }
    PaymentResponse = () => {
        const { t } = this.props;
        let PaymentResponse = JSON.parse(ShoppingCartStore.ProcessPaymentBO);
        let PaymentBL = PaymentResponse.Payment
        if (PaymentBL != null && PaymentBL != undefined) {
            if (PaymentBL.length > 0) {
                if (PaymentBL[0].strRefNo != "" && PaymentBL[0].strRefNo != null && PaymentBL[0].strRefKey != "" && PaymentBL[0].strRefKey != null && PaymentBL[0].result == "A") {
                    this.setState({ PaymentResponse: PaymentBL })
                    this.AddFavOrder(this.state.AddfavOrderValue)
                }
                else {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    document.getElementById("ExpiryDateError").innerHTML = (t("Error In Payment Process"));
                }
            }
        }
    }
    handleChargecodechange = (event, Type, LineNo, ItemIDE) => {
        if (Type == 0) {
            this.setState({ OlChargeCOde: event.target.value })
        }
        else {
            let { CartItems } = this.state;
            let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
            CartItems[index].ItemChgCode = event.target.value;
            this.setState(CartItems);
        }
    }
    /*[11/22/21, PC_SC_02, 1854], Priyanka D*/
    GetChargeCode = (Type, LineNo, ItemIDE, ID, Ordlvl) => {
        debugger
        let CCArray = [];
        {/*[11/17/21, PC_MC_03 Bug-1775], Priyanka D*/ }
        if (ID != null && ID != "" && ID != " " && ID != undefined) {
            ID.includes("--") ? CCArray = ID.split("--") : CCArray = ID.split("-");
                var DD1 = CCArray[0];
                var DD2 = CCArray[1];
                var DD3 = CCArray[2];
            
        }
       
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (Type == 1) {
            this.setState({ ItemId: ItemIDE, UniqId: LineNo, ChargeCodeLevel: Type })
        }
        else {
            this.setState({ ChargeCodeLevel: Type })
        }
        {/*[11/17/21, PC_MC_04 Bug-1775], Priyanka D*/ }
        if (ID != null && ID != "" && ID != " " && ID != undefined) {
            this.setState({ PlaceDD1Value: DD1, PlaceDD2Value: DD2, PlaceDD3Value: DD3, ChargeSelected: ID});
        }
        /*[11/22/21, PC_SC_02, 1854], Priyanka D*/
        if (Ordlvl == "Ordlvl") {
            this.setState({ OrdLevelChrgCode: true });
        }
        let BuildChrCDBO = {
            UserId: this.state.Login_UserId,
            UserBU: this.state.Login_UserBU,
            dropCD1SegValue: "",
            dropCD2SegValue: "",
            dropCD3SegValue: "",
            dropCD1SegFilled: false,
            dropCD2SegFilled: false,
            dropCD1Seg_Visible: this.state.dropCD1SegVisible,
            dropCD2Seg_Visible: this.state.dropCD2SegVisible,
            dropCD3Seg_Visible: this.state.dropCD3SegVisible,
        }
        ShoppingCartAction.GetchargeCOde(BuildChrCDBO)        
    }
    buildChargeCode = () => {
        debugger
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let GetchargeCOdeBO = JSON.parse(ShoppingCartStore.GetchargeCOdeBO);      
        //.log("GetchargeCOdeBO", GetchargeCOdeBO)
        let ChargeCOdeTable = GetchargeCOdeBO.ChargeCOdeTable
        let ChargeCOdeType = GetchargeCOdeBO.ChargeCOdeType
        let CCArray = [];
        //.log("PlaceDD1", PlaceDD1)
        {/*[11/18/21, PC_MC_07 Bug-1775], Priyanka D*/ }
        let ChargeSelected = "";
        var DD1 =[];
        var DD2 =[];
        var DD3 = [];
        let PlaceDD1Val = "";
        let PlaceDD2Val = "";
        let PlaceDD3Val = "";
        let PlaceDDdefault = ""
        if (this.state.ChargeSelected != null && this.state.ChargeSelected != "" && this.state.ChargeSelected != " " && ChargeSelected != undefined) {
            this.state.ChargeSelected.includes("--") ? CCArray = this.state.ChargeSelected.split("--") : CCArray = this.state.ChargeSelected.split("-");            
            var DD1 = CCArray[0];
            var DD2 = CCArray[1];
            var DD3 = CCArray[2];
        }
        if (ChargeCOdeTable[0].PlaceDD1Value != null && ChargeCOdeTable[0].PlaceDD1Value != " " && ChargeCOdeTable[0].PlaceDD1Value != "" && ChargeCOdeTable[0].PlaceDD1Value != undefined) {
            PlaceDD1Val = ChargeCOdeTable[0].PlaceDD1Value
            /*[11/22/21, PC_SC_03, 1854], Priyanka D*/
        } else if ((DD1 != null && DD1 != "" && DD1 != " " && DD1 != undefined) && (this.state.OrdLevelChrgCode != true || (this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "" && this.state.OlChargeCOde != " " && this.state.OlChargeCOde != null))) {
            PlaceDD1Val = DD1;
        }
        if (ChargeCOdeTable[0].PlaceDD2Value != null && ChargeCOdeTable[0].PlaceDD2Value != " " && ChargeCOdeTable[0].PlaceDD2Value != "" && ChargeCOdeTable[0].PlaceDD2Value != undefined) {
            PlaceDD2Val = ChargeCOdeTable[0].PlaceDD2Value
        } else if (DD2 != null && DD2 != "" && DD2 != " " && DD2 != undefined && (this.state.OrdLevelChrgCode != true || (this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "" && this.state.OlChargeCOde != " " && this.state.OlChargeCOde != null))) {
            PlaceDD2Val = DD2;
        }
        if (ChargeCOdeTable[0].PlaceDD3Value != null && ChargeCOdeTable[0].PlaceDD3Value != " " && ChargeCOdeTable[0].PlaceDD3Value != "" && ChargeCOdeTable[0].PlaceDD3Value != undefined) {
            PlaceDD3Val = ChargeCOdeTable[0].PlaceDD3Value
        } else if (DD3 != null && DD3 != "" && DD3 != " " && DD3 != undefined && (this.state.OrdLevelChrgCode != true || (this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "" && this.state.OlChargeCOde != " " && this.state.OlChargeCOde != null))) {
            PlaceDD3Val = DD3;
        }
        {/*[3/16/22, PC_SP_03, CAS - Charge code is not working in 37 machine, Dhevanesam R*/ }
        let PlaceDD1 = this.buildDropDownValue(GetchargeCOdeBO.PlaceDD1, PlaceDDdefault)
        {/*[11/17/21, PC_MC_05 Bug-1775], Priyanka D*/ }
        let PlaceDD1Data = PlaceDD1.ReturnTable;
        PlaceDD1Data = PlaceDD1Data.filter((item, index) => item.value == PlaceDD1Val);
        let PlaceDD2 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.PlaceDD2, ChargeCOdeTable[0].PlaceDD2Value) : ""
        let PlaceDD3 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.PlaceDD3, ChargeCOdeTable[0].PlaceDD3Value) : ""
        let dropCD1Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD1Seg, ChargeCOdeTable[0].dropCD1SegValue) : ""
        let dropCD2Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD2Seg, ChargeCOdeTable[0].dropCD2SegValue) : ""
        let dropCD3Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD3Seg, ChargeCOdeTable[0].dropCD3SegValue) : ""
        let dropEmpSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropEmpSeg, ChargeCOdeTable[0].dropEmpSegValue) : ""
        let dropItmSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropItmSeg, ChargeCOdeTable[0].dropItmSegValue) : ""
        let dropMchSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropMchSeg, ChargeCOdeTable[0].dropMchSegValue) : ""
        {/*[11/18/21, PC_MC_07 Bug-1775], Priyanka D*/ }
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD1Val, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD2Val, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD3Val, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD1SegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD2SegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD3SegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropEmpSegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropItmSegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropMchSegValue, ChargeSelected) : ""
        {/*[11/17/21, PC_MC_05 Bug-1775], Priyanka D*/ }
        let PlaceDD2Data = PlaceDD2.ReturnTable;
        PlaceDD2Data = PlaceDD2Data.filter((item, index) => item.value == PlaceDD2Val);
        let PlaceDD3Data = PlaceDD3.ReturnTable;
        PlaceDD3Data = PlaceDD3Data.filter((item, index) => item.value == PlaceDD3Val);

        if (ChargeCOdeTable[0].ShowAllFieldFillLabel == "True") {
            $('#ChargeCodeLabelDiv').removeClass('d-none')
            $('#ChargeCodeLabel').removeClass('errorcolour')
        }
        else {
            $('#ChargeCodeLabelDiv').addClass('d-none')
        }
        
            
        {/*[11/17/21, PC_MC_06 Bug-1775], Priyanka D*/ }
        this.setState({
            /*[11/22/21, PC_SC_02, 1854], Priyanka D*/
            OrdLevelChrgCode: false, 
            PlaceDD1Value: PlaceDD1Data, PlaceDD1Data: PlaceDD1.ReturnTable, ChargeSelected: ChargeSelected,
            PlaceDD2Value: PlaceDD2Data, PlaceDD2Data: PlaceDD2.ReturnTable,
            PlaceDD3Value: PlaceDD3Data, PlaceDD3Data: PlaceDD3.ReturnTable,
            dropCD1SegValue: dropCD1Seg.SelectedValue, dropCD1SegData: dropCD1Seg.ReturnTable,
            dropCD2SegValue: dropCD2Seg.SelectedValue, dropCD2SegData: dropCD2Seg.ReturnTable,
            dropCD3SegValue: dropCD3Seg.SelectedValue, dropCD3SegData: dropCD3Seg.ReturnTable,
            dropEmpSegValue: dropEmpSeg.SelectedValue, dropEmpSegData: dropEmpSeg.ReturnTable,
            dropItmSegValue: dropItmSeg.SelectedValue, dropItmSegData: dropItmSeg.ReturnTable,
            dropMchSegValue: dropMchSeg.SelectedValue, dropMchSegData: dropMchSeg.ReturnTable,
            CCPlaceDD1Title: ChargeCOdeTable[0].PlaceDD1, CCPlaceDD2Title: ChargeCOdeTable[0].PlaceDD2, CCPlaceDD3Title: ChargeCOdeTable[0].PlaceDD3,
            CCdropCD1SegTitle: ChargeCOdeTable[0].dropCD1Seg, CCdropCD2SegTitle: ChargeCOdeTable[0].dropCD2Seg, CCdropCD3SegTitle: ChargeCOdeTable[0].dropCD3Seg,
            CCdropEmpSegTitle: ChargeCOdeTable[0].dropEmpSeg, CCdropItmSegTitle: ChargeCOdeTable[0].dropItmSeg, CCdropMchSegTitle: ChargeCOdeTable[0].dropMchSeg,
            dropCD1SegVisible: ChargeCOdeTable[0].dropCD1Seg_Visible == "True" ? true : false,
            dropCD2SegVisible: ChargeCOdeTable[0].dropCD2Seg_Visible == "True" ? true : false,
            dropCD3SegVisible: ChargeCOdeTable[0].dropCD3Seg_Visible == "True" ? true : false,
            PlaceDD1Visible: ChargeCOdeTable[0].PlaceDD1_Visible == "True" ? true : false,
            PlaceDD2Visible: ChargeCOdeTable[0].PlaceDD2_Visible == "True" ? true : false,
            PlaceDD3Visible: ChargeCOdeTable[0].PlaceDD3_Visible == "True" ? true : false,
            dropEmpSegVisible: ChargeCOdeTable[0].dropEmpSeg_Visible == "True" ? true : false,
            dropItmSegVisible: ChargeCOdeTable[0].dropItmSeg_Visible == "True" ? true : false,
            dropMchSegVisible: ChargeCOdeTable[0].dropMchSeg_Visible == "True" ? true : false, dropCD1Seg_disable: ChargeCOdeTable[0].dropCD1Seg_Enable == "False" ? true : false,
            dropCD2Seg_disable: ChargeCOdeTable[0].dropCD2Seg_Enable == "False" ? true : false, dropCD3Seg_disable: ChargeCOdeTable[0].dropCD3Seg_Enable == "False" ? true : false,
            PlaceDD1_disable: ChargeCOdeTable[0].PlaceDD1_Enable == "False" ? true : false, PlaceDD2_disable: ChargeCOdeTable[0].PlaceDD2_Enable == "False" ? true : false,
            PlaceDD3_disable: ChargeCOdeTable[0].PlaceDD3_Enable == "False" ? true : false, dropEmpSeg_disable: ChargeCOdeTable[0].dropEmpSeg_Enable == "False" ? true : false,
            dropItmSeg_disable: ChargeCOdeTable[0].dropItmSeg_Enable == "False" ? true : false, dropMchSeg_disable: ChargeCOdeTable[0].dropMchSeg_Enable == "False" ? true : false,
        })
        $('#Charge-Code-popup').modal('show');

    }
    buildChargecodevalue = (CCValue, ChargeSelect) => {
        let ChargeSelected = ChargeSelect
        if (CCValue != null && CCValue != "" && CCValue != undefined) {
            if (ChargeSelected != "" && ChargeSelected != null && ChargeSelected != undefined) {
                ChargeSelected = ChargeSelected + "-" + CCValue
            }
            else {
                ChargeSelected = CCValue
            }
        }
        return (ChargeSelected)
    }
    buildDropDownValue = (DropDownValue, DefaultValue) => {
        const { t } = this.props;
        let ReturnTable = []
        let SelectedValue = []
        if (DropDownValue != null && DropDownValue != undefined) {
            if (DropDownValue.length > 0) {
                let defaulttype = "ISA_CHGCD_USR1_SEG"
                DropDownValue.map((val, index) => {

                    if (index == 0) {
                        if (val.ISA_CHGCD_USR2_SEG != undefined) {
                            defaulttype = "ISA_CHGCD_USR2_SEG"
                        }
                        else if (val.ISA_CHGCD_USR3_SEG != undefined) {
                            defaulttype = "ISA_CHGCD_USR3_SEG"
                        }
                        else if (val.ISA_CUST_CHARGE_CD != undefined) {
                            defaulttype = "ISA_CUST_CHARGE_CD"
                        }
                        else if (val.ISA_CHGCD_ITEM_SEG != undefined) {
                            defaulttype = "ISA_CHGCD_ITEM_SEG"
                        }
                        //.log("defaulttype", defaulttype)
                        let defaultvalue = {
                            value: "",
                            label: t("--Select Charge Code--")
                        }
                        ReturnTable.push(defaultvalue)
                    }
                    let localArray = {
                        value: val.SEQ,
                        label: val[defaulttype]
                    }
                    if (val.SEQ == DefaultValue) {
                        SelectedValue = localArray
                    }
                    ReturnTable.push(localArray)
                });
            }
        }
        let ReturnTableValue = {
            ReturnTable: ReturnTable,
            SelectedValue: SelectedValue
        }
        return (ReturnTableValue)
    }
    handleChangePlaceDD1 = (selectedOption, Title) => {
        {/*[11/17/21, PC_MC_08 Bug-1775], Priyanka D*/ }
        debugger;
        let ChargeSelected = this.state.ChargeSelected;
        let CCArray = [];
        if (ChargeSelected != "" && ChargeSelected != " " && ChargeSelected != null && ChargeSelected != undefined) {
            ChargeSelected.includes("--") ? CCArray = ChargeSelected.split("--") : CCArray = ChargeSelected.split("-");
           let DD1 = CCArray[0];
           let DD2 = CCArray[1];
           let DD3 = CCArray[2];
            if (Title == "PlaceDD1Value") {
                if (DD1 != null && DD1 != undefined && DD1 != "" && DD1 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD1, selectedOption.value);
                } else if (DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
                    ChargeSelected = selectedOption.value + "-" + ChargeSelected;
                } else {
                    ChargeSelected = selectedOption.value
                }
            }
            else if (Title == "PlaceDD2Value") {
                if (DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD2, selectedOption.value);
                } else if (DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
                    ChargeSelected = selectedOption.value + "-" + ChargeSelected;
                }  else {
                    ChargeSelected = ChargeSelected + "-" + selectedOption.value
                }
            }
            else if (Title == "PlaceDD3Value") {
                if (DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD3, selectedOption.value);
                } else {
                    ChargeSelected = ChargeSelected + "-" + selectedOption.value
                }
            }
        } else {
            ChargeSelected = selectedOption.value
        }
        let locArray = []
        locArray.push(selectedOption)
        this.setState({ [Title]: locArray, ChargeSelected: ChargeSelected });
        //.log(`Option selected:`, selectedOption);

        let dropCD1SegValue = this.state.dropCD1SegValue.value == undefined ? "" : this.state.dropCD1SegValue.value
        let dropCD2SegValue = this.state.dropCD2SegValue.value == undefined ? "" : this.state.dropCD2SegValue.value
        let dropCD3SegValue = this.state.dropCD3SegValue.value == undefined ? "" : this.state.dropCD3SegValue.value
        let dropCD1SegFilled = this.state.dropCD1SegValue.value == undefined ? false : true
        let dropCD2SegFilled = this.state.dropCD2SegValue.value == undefined ? false : true
        if (Title == "dropCD1SegValue") {
            if (Title == "dropCD1SegValue") {
                dropCD1SegValue = selectedOption.value
            }
            else if (this.state.dropCD1SegValue != []) {
                dropCD1SegValue = this.state.dropCD1SegValue.value
            }
            dropCD1SegFilled = true
        }
        else if (Title == "dropCD2SegValue") {
            if (Title == "dropCD2SegValue") {
                dropCD2SegValue = selectedOption.value
            }
            else if (this.state.dropCD1SegValue != []) {
                dropCD2SegValue = this.state.dropCD2SegValue.value
            }
            dropCD2SegFilled = true
        }
        else if (Title == "dropCD3SegValue") {
            if (Title == "dropCD3SegValue") {
                dropCD3SegValue = selectedOption.value
            }
            else if (this.state.dropCD1SegValue != []) {
                dropCD3SegValue = this.state.dropCD3SegValue.value
            }
        }
        if (Title == "dropCD1SegValue" || Title == "dropCD2SegValue") {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            let BuildChrCDBO = {
                UserId: this.state.Login_UserId,
                UserBU: this.state.Login_UserBU,
                dropCD1SegValue: dropCD1SegValue,
                dropCD2SegValue: dropCD2SegValue,
                dropCD3SegValue: dropCD3SegValue,
                dropCD1SegFilled: dropCD1SegFilled,
                dropCD2SegFilled: dropCD2SegFilled,
                dropCD1Seg_Visible: this.state.dropCD1SegVisible,
                dropCD2Seg_Visible: this.state.dropCD2SegVisible,
                dropCD3Seg_Visible: this.state.dropCD3SegVisible,
            }
            ShoppingCartAction.GetchargeCOde(BuildChrCDBO)
        }
    };
    UpdateChargeCode = () => {
        debugger
        let Validate = true
        Validate = this.validateChargeCode(this.state.PlaceDD1Visible, this.state.PlaceDD1Value)
        Validate = Validate == true ? this.validateChargeCode(this.state.PlaceDD2Visible, this.state.PlaceDD2Value) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.PlaceDD3Visible, this.state.PlaceDD3Value) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropCD1SegVisible, this.state.dropCD1SegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropCD2SegVisible, this.state.dropCD2SegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropCD3SegVisible, this.state.dropCD3SegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropItmSegVisible, this.state.dropItmSegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropMchSegVisible, this.state.dropMchSegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropEmpSegVisible, this.state.dropEmpSegValue) : Validate
        //.log("ChargeValidate", Validate)
        if (Validate == true) {
            if (this.state.ChargeCodeLevel == 0) {
                this.BindEmpCCToItemOL("ChargeCode", "", this.state.OrderEmpyId)
            }
            else {
                this.LinelevelEmyEdit(this.state.ItemId, this.state.UniqId, "ItemChgCode", "")
            }
            $('#Charge-Code-popup').modal('hide');
        }
        else {
            $('#ChargeCodeLabel').addClass('errorcolour')
        }
    }

    //Zeus  281 - Zeus 2.0 - Non catalog - Duplicate item gets added when use '&' in Description -  Dhinesh R
    handleDescription = (name, event) => {
        if (name == "Description") {

            let Description = event;
            this.setState({
                Description: Description
            });
        }
    }

    validateChargeCode = (ChargeCodeDisplayed, ChargeCode) => {
        debugger
        let Validate = true
        if (ChargeCodeDisplayed) {
            if (ChargeCode == [] || ChargeCode.length == 0) {
                Validate = false
            }
            else if (ChargeCode.length > 0) {
                if (ChargeCode[0].value == "") {
                    Validate = false
                }
            }
        }
        return (Validate)
    }
    gettokenValue = () => {
        const { t } = this.props;
        debugger
        let data = this.state.CardfieldDisabled

        if (this.state.CardNumber == null || this.state.CardNumber == undefined || this.state.CardNumber == "") {
            $('#CardNumber').addClass('errorcolour');
            $('#CardNumber').removeClass('fontGreen');
            document.getElementById("ExpiryDateError").innerHTML = (t("Card number is required"));
        }
        else if (this.state.CardImageType == "" || this.state.CardImageType == null || this.state.CardImageType == undefined) {
            $('#CardNumber').addClass('errorcolour');
            $('#CardNumber').removeClass('fontGreen');
            document.getElementById("ExpiryDateError").innerHTML = (t("Invalid card number"));
        }
        let CardNumberValuedata = $('#CardNumber').val()
        CardNumberValuedata = CardNumberValuedata.replaceAll(" ", "")
        let CardNumberValue = ""
        if (CardNumberValuedata.length == 14) {
            CardNumberValue = CardNumberValuedata.slice(10)
        }
        else if (CardNumberValuedata.length == 15) {
            CardNumberValue = CardNumberValuedata.slice(11)
        }
        else if (CardNumberValuedata.length == 16) {
            CardNumberValue = CardNumberValuedata.slice(12)
        }
        this.state.WalletValue.map((Wallet, index) => {
            debugger
            if (Wallet.CARD_NUMBER == CardNumberValue && this.state.CardImageType == Wallet.CARD_TYPE) {
                $('#CardNumber').addClass('errorcolour');
                document.getElementById("ExpiryDateError").innerHTML = (t("Card already exist in user wallet"));
            }
        })

    }
    //[3/15/2023]CAS-44 Address Edit icon's onclick method to shpw address fields pop up
    handleAddressSelect = () => {
        $('#AddressFieldsModal').modal('show');
        debugger
        if (this.state.userAddress != "") {
            if (this.state.isRecAddressSelected) {
                document.getElementById("AddressErrorMsg").innerHTML = "";
                this.setState({ stateValue: this.state.isValidState, cityValue: this.state.isValidCity, streetValue: this.state.isValidStreet, countryValue: this.state.isValidCountryCode, zipCodeValue: this.state.isValidZipCode, selectCountry: this.state.countryObj, apartmentNoValue: this.state.typedApartmentNo });
            }
            else if (this.state.isUserAddressSelected) {
                document.getElementById("AddressErrorMsg").innerHTML = "";
                this.setState({ stateValue: this.state.typedState, cityValue: this.state.typedCity, streetValue: this.state.typedStreet, countryValue: this.state.typedCountry, zipCodeValue: this.state.typedZipCode, selectCountry: this.state.countryObj, apartmentNoValue: this.state.typedApartmentNo });
            }
            else if (this.state.isExistingAddress == true) {
                document.getElementById("AddressErrorMsg").innerHTML = "";
                let countryObj = this.state.existCountry.trim() == "USA" ? countryOptions[0] : this.state.existCountry.trim() == "CAN" ? countryOptions[1] : countryOptions[2];
                this.setState({ stateValue: this.state.existState.trim(), cityValue: this.state.existCity.trim(), streetValue: this.state.existStreet.trim(), countryValue: this.state.existCountry.trim(), zipCodeValue: this.state.existPostal.trim(), selectCountry: countryObj, apartmentNoValue: this.state.existApartmentNo != null ? this.state.existApartmentNo : "" });
            }
        }
        else {
            document.getElementById("AddressErrorMsg").innerText = "";
            this.setState({ stateValue: "", cityValue: "", streetValue: "", countryValue: "USA", zipCodeValue: "", selectCountry: countryOptions[0], apartmentNoValue: "" })
        }

    }

    //[3/15/2023]CAS-44 onStateChange is hit on change on state text box in address fields modal to convert the characters to upper and to allow alphabets alone
    onStateChange = (e) => {
        let strState = e.target.value.toUpperCase();
        const re = SDIConstants.AlphabetsReg;
        if (re.test(strState) || strState === ' ' || strState === '') {
            this.setState({ stateValue: strState})
        }
    }

    //[3/15/2023]CAS-44 onCityChange is hit on change on city text box in address fields modal to convert the characters to upper
    onCityChange = (e) => {
        let strCity = e.target.value.toUpperCase();
        this.setState({ cityValue: strCity })
    }

    //[3/15/2023]CAS-44 onCountryChange is hit on change on country text box in address fields modal to convert the characters to upper and to allow alphabets alone
    onCountryChange = (e) => {    
        let newObj = e;
        let country = e.value;
        this.setState({ selectCountry: newObj, countryValue: country })
    }

    //[3/15/2023]CAS-44 OnHandleZipCode is hit on change on zip code text box in address fields modal to validate that it is a zip code
    OnHandleZipCode = (e) => {
        let strZipCode = e.target.value.toUpperCase();
        const re = SDIConstants.zipcodeRegEx;
        if (re.test(strZipCode) || strZipCode === ' ' || strZipCode === '') {
            this.setState({ zipCodeValue: strZipCode })
        }
    }

    //[3/15/2023]CAS-44 onStreetChange is hit on change on street text box in address fields modal to convert the characters to upper
    onStreetChange = (e) => {
        let strStreet = e.target.value.toUpperCase();
        this.setState({ streetValue: strStreet })
    }

    //[7/24/2023]Z-163 onApartmentNoChange is hit on change on Apartment # text box
    onApartmentNoChange = (e) => {
        let apartmentNo = e.target.value.toUpperCase();
        this.setState({ apartmentNoValue: apartmentNo })
    }

    closeAddressPopup = () => {
        document.getElementById("AddressErrorMsg").innerText = "";
        this.setState({ stateValue: "", cityValue: "", selectCountry: countryOptions[0], countryValue: "USA", zipCodeValue: "", streetValue: "", apartmentNoValue: "" })
    }

    //[3/15/2023]CAS-44 selectedAddress() is hit on change of address type radio button, flag is set based on the address type selected by the user
    selectedAddress = (Addresstype) => {
        debugger;
        if (Addresstype == "recommendedAddress") {
            this.setState({ typedAddress: false, recAddress: true });
        }
        else {
            this.setState({ typedAddress: true, recAddress: false })
        }
        
    }

    //[3/15/2023]CAS-44 confirmAddress is to store the selected address details in appropriate state variables once the user clicks confirm button in the address pop up
    confirmAddress = () => {
        debugger
        const { t } = this.props;
        $('#AddressModal').modal('hide');  
        let isDefAddress = false;
        if (this.state.recAddress == true) {
            document.getElementById("isValidAddress").innerText = (t("Valid address"));
           //[5/16/2023]Z-102 Set def address box will be checked onlif the user def address and current address are same
            if (this.state.userDefaultAddress == this.state.recomendedAddress) {
                isDefAddress = true;
            }
			this.setState({
				userAddress: this.state.recomendedAddress, disableShipto: true, userSelectedAddress: "", countryObj: this.state.selectCountry, isDefAddressChckd: isDefAddress, isExistingAddress: false, isRecAddressSelected: true, isUserAddressSelected: false, typedApartmentNo: this.state.apartmentNoValue,
				isValidStreet: this.state.responseIsValidStreet, isValidCity: this.state.responseIsValidCity, isValidState: this.state.responseIsValidState, isValidZipCode: this.state.responseIsValidZipCode, isValidCountryCode: this.state.responseIsValidCountryCode, userAddressErr: false
			});
        }
        else {
            //[5/16/2023]Z-102 Set def address box will be checked onlif the user def address and current address are same
            if (this.state.userDefaultAddress == this.state.userSelectedAddress) {
                isDefAddress = true;
            }
            document.getElementById("isValidAddress").innerText = "";
            this.setState({
                userAddress: this.state.userSelectedAddress, disableShipto: true, recomendedAddress: "", countryObj: this.state.selectCountry, isDefAddressChckd: isDefAddress, isExistingAddress: false, isRecAddressSelected: false, isUserAddressSelected: true, typedApartmentNo: this.state.apartmentNoValue,
				typedStreet: this.state.responseTypedStreet, typedCity: this.state.responseTypedCity, typedState: this.state.responseTypedState, typedZipCode: this.state.responseTypedZipCode, typedCountry: this.state.responseTypedCountry, userAddressErr: false });
		}
		this.handleShoppingCartValidation("ADDRESS")
    }

    //[3/15/2023]CAS-44 clearAddress() is to clear the address field and state variables values when clear address is clicked 
    clearAddress = (e) => {
        e.preventDefault();
        document.getElementById("isValidAddress").innerText = "";
        document.getElementById("AddressErrorMsg").innerHTML = "";
        this.setState({
            recomendedAddress: "", userSelectedAddress: "", stateValue: "", cityValue: "", streetValue: "", countryValue: "USA", zipCodeValue:"",
            isValidStreet: "", isValidCity: "", isValidState: "", isValidZipCode: "", isValidCountryCode: "", userAddress: "", recAddress: true, typedAddress: false,
            disableShipto: false, apartmentNoValue: "", selectCountry: countryOptions[0]})
    }

    //[3/15/2023]CAS-44 validateAddressClick() will be hit when validate button in the address fields popup is clicked
    validateAddressClick = () => {
        debugger
        const { t } = this.props;
        if (this.state.stateValue.trim() == "" || this.state.cityValue.trim() == "" || this.state.streetValue.trim() == "" || this.state.countryValue.trim() == "" || this.state.zipCodeValue.trim() == "") {
            document.getElementById("AddressErrorMsg").innerHTML = (t("Please enter a valid address"));
        }
        else {
            document.getElementById("AddressErrorMsg").innerHTML = "";
            let locarr=[]
            let addressLine = this.state.streetValue
            locarr.push(addressLine)
            let Address = {                
                regionCode: this.state.countryValue,
                locality: this.state.cityValue,
                administrativeArea: this.state.stateValue,
                postalCode: this.state.zipCodeValue,
                addressLines: locarr               
            }
            let AddressBO = {
                address: Address
            }
            ShoppingCartAction.ValidateAddress(AddressBO);
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }       
    }

    getItemValue(item) {
        return `${item.value}`;
    }
    renderItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );

    }
    onSelect(val, name) {
        debugger;
        let result = [];
        if (name == "CAT") {
            if (val != "") {
                this.state.subCategory.map((x, i) => {
                    if (val.toUpperCase() == x.category) {
                        result.push({ label: x.value, value: x.value })
                    }
                }
                )
            }
            this.setState({
                FavYourCatgPopUp: val, bindSubCategory: result, categoryError: false, FavYourSubCatgPopup: val != this.state.FavYourCatgPopUp ? "" : this.state.FavYourSubCatgPopup
            });
        }
        else if(name == "SUBCAT") {
            this.setState({
                FavYourSubCatgPopup: val, subCategoryError: false
            });
        }
        else if (name == "FAVORDERCAT") {
            if (val != "") {
                this.state.favOrdsubCategoryObj.forEach((x, i) => {
                    if (val.toUpperCase() == x.category) {
                        result.push({ label: x.value, value: x.value })
                    }
                }
                )
            }
            document.getElementById('favordcatErr').innerHTML = ""
            this.setState({
                favOrdCat: val, favOrdsubCategorySugg: result,
                favOrdSubCat: val != this.state.favOrdCat ? "" : this.state.favOrdSubCat
            });
        }
        else if(name == "FAVORDERSUBCAT") {
            document.getElementById('favordsubcatErr').innerHTML = ""
            this.setState({
                favOrdSubCat: val
            });
        }
    }
    
    favOrderCategoryClose = () => {
        $('#Fav_Order_Category').modal('hide');
        this.setState({ favOrdCat: "", favOrdDes: "",favOrdSubCat:"", attachmentPathArrImg:[]});
    }
    handleCatChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    FavOrdCatPopup = () => {
        //Zeus 264 - New CR - Prefill the WO NO in the Favorite order category - Johnprinto D.
        let favOrderCat = this.state.favOrderCatObj.filter(name => name.label.toUpperCase().includes(this.state.ValidatedWo))
        let favOrderSubCat = [];
        this.state.favOrdsubCategoryObj.forEach((x, i) => {
            if (this.state.ValidatedWo.toUpperCase() == x.category) {
                favOrderSubCat.push({ label: x.value, value: x.value })
            }
        })
        this.setState({ favOrdCat: this.state.ValidatedWo ?.substring(0, 50), favOrderCatSugg: favOrderCat, favOrdsubCategorySugg: favOrderSubCat });
        $('#Fav_Order_Category').modal('show')
        return;
    }
    FavOrdCatOK = () => {
        let resultSubCat = false
        let resultCat = false
        if (this.state.favOrdCat && this.state.favOrdCat.trim()) {
                    resultCat = true
                    document.getElementById('favordcatErr').innerHTML = ""
        }
        else {
            document.getElementById('favordcatErr').innerHTML = "Please enter category"
            resultCat = false
        }
        if (this.state.favOrdSubCat && this.state.favOrdSubCat.trim()) {
                    resultSubCat = true
                    document.getElementById('favordsubcatErr').innerHTML = ""
        }
        else {
            document.getElementById('favordsubcatErr').innerHTML = "Please enter subcategory"
            resultSubCat = false
        }
        if (resultCat && resultSubCat) {
            this.FavOrdImageValidation();
            $('#Fav_Order_Category').modal('hide');
        }
    }
    FavOrdImageValidation = () => {
        if (this.state.attachmentPathArrImg == "" || this.state.attachmentPathArrImg == []) {
            $('#Imagealertpopup').modal('show');
        }
        else {
            this.AddFavOrder("True");
        }
    }
    ImgYesClick = () => {
        $('#Imagealertpopup').modal('hide');
        $('#Fav_Order_Category').modal('show');
    }

    onChange = (e, name) => {
        debugger;
        let val = e.target.value.toUpperCase()
        let len = e.target.value
        let category = [];
        let subCategory = [];
        let re = /^[a-zA-Z0-9_-\s\x20 &, :;=?@#|'<>.{}"*()%!`%^$+~[/\]\\]+$/;
        if (val === '' || re.test(val)) {
            if (name == "CAT") {
                if (e.target.value == "") {
                    this.setState({
						FavYourCatgPopUp: e.target.value, bindCategory: this.state.category, bindSubCategory: [], FavYourSubCatgPopup: ""
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.category != undefined && this.state.category != null) {
                        if (this.state.category.length != 0) {
                            category = this.state.category.filter(name => name.label.toUpperCase().includes(val))

                        }
                    }
                    if (e.target.value != "") {
                        this.state.subCategory.map((x, i) => {
                            if (e.target.value.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
						FavYourCatgPopUp: e.target.value, bindCategory: category, bindSubCategory: subCategory, categoryError: false, FavYourSubCatgPopup: ""
                    });
                }
            }
            else if(name == "SUBCAT"){
                if (e.target.value == "") {
                    if (this.state.FavYourCatgPopUp != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.FavYourCatgPopUp.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
                        FavYourSubCatgPopup: e.target.value, bindSubCategory: subCategory
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.FavYourCatgPopUp != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.FavYourCatgPopUp.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        }
                        )
                        subCategory = subCategory.filter(name => name.label.toUpperCase().includes(val))
                    }
                    this.setState({
                        FavYourSubCatgPopup: e.target.value, bindSubCategory: subCategory, subCategoryError: false
                    });
                }
            }
        else if (name == "FAVORDERCAT") {
                let subcatresult = [];
                if (e.target.value == "") {
                    this.setState({
                        favOrdCat: e.target.value, favOrderCatSugg: this.state.favOrderCatObj, favOrdsubCategorySugg: [], favOrdSubCat:""
                    });
                }
                else if (val.length <= 50) {
                    let result = []
                    if (this.state.favOrderCatObj != null && this.state.favOrderCatObj != undefined) {
                        if (this.state.favOrderCatObj.length > 0) {
                            result = this.state.favOrderCatObj.filter(name => name.label.toUpperCase().includes(val))
                        }

                    }
                    if (e.target.value != "") {
                        this.state.favOrdsubCategoryObj.forEach((x, i) => {
                            if (e.target.value.toUpperCase() == x.category) {
                                subcatresult.push({ label: x.value, value: x.value })
                            }
                        }
                        )
                    }
                    document.getElementById('favordcatErr').innerHTML = ""
                    this.setState({
                        favOrdCat: e.target.value, favOrderCatSugg: result, favOrdsubCategorySugg: subcatresult, favOrdSubCat:""
                    });
                }
            }
            else if(name == "FAVORDERSUBCAT"){
                let result = []
                if (e.target.value == "") {
                    if (this.state.favOrdCat != "") {
                        this.state.favOrdsubCategoryObj.forEach((x, i) => {
                            if (this.state.favOrdCat.toUpperCase() == x.category) {
                                result.push({ label: x.value, value: x.value })
                            }
                        }
                        )
                    }
                    this.setState({
                        favOrdSubCat: e.target.value, favOrdsubCategorySugg: result
                    });
                }
                else if (val.length <= 50) {
                    if (this.state.favOrdCat != "") {
                        this.state.favOrdsubCategoryObj.forEach((x, i) => {
                            if (this.state.favOrdCat.toUpperCase() == x.category) {
                                result.push({ label: x.value, value: x.value })
                            }
                        }
                        )
                    }
                    if (this.state.favOrdsubCategoryObj != null && this.state.favOrdsubCategoryObj != undefined) {
                        if (this.state.favOrdsubCategoryObj.length > 0) {
                            result = result.filter(name => name.label.toUpperCase().includes(val))
                        }

                    }
                    document.getElementById('favordsubcatErr').innerHTML = ""
                    this.setState({
                        favOrdSubCat: e.target.value, favOrdsubCategorySugg: result
                    });
                }
            }
        }
    }
    onInputClick = (event) => {
        event.target.value = ''
    }
    handleAttachmentImg = (event) => {
        let files = event.target.files;
        let acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (acceptedImageTypes.includes(files[0].type)) {
            if (this.state.attachmentPathArrImg) {
                if (this.state.attachmentPathArrImg.length == 0) {
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    ShoppingCartAction.addAttachmentImg(files);
                } else {
                    let lblerror = "You can choose only one Image"
                    document.getElementById("Favlblerror").innerText = lblerror;
                }
            } else {
                document.getElementById("Favlblerror").innerText = "";
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                ShoppingCartAction.addAttachmentImg(files);
            }
        }else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please choose only Image file "
            });
        }
    };
    
    bindAttachmentsImg = () => {
        let atatchmentdata = this.state.attachmentPathArrImg
        if (atatchmentdata != null && atatchmentdata != undefined) {
            if (atatchmentdata.length > 0) {
                return this.state.attachmentPathArrImg.map((attachment, index) => {
                    return (
                        <div className="attachment-file-name mt-2 col-md-12 p-0" style={{ width: "147%", marginTop: "45px" }} key={index}>
                            <a href="#" className="Attachment-overflow col-4 p-0" onClick={(e) => this.downloadAttachment(e, attachment)} data-toggle="tooltip" title={attachment.FileName}>{attachment.FileName}</a>
                            <span className="attachment-remove-icon ml-1" onClick={() => this.favOrderRemoveAttachments(attachment.FilePath, index)}>X  </span>
                        </div>
                    );
                });
            }
        }
    };
    loadAttachmentsImg = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        $(`#nAttachments`).val('');
        let isValid = ShoppingCartStore.favOrderAttachment.indexOf("You have exceeded the length of total file names") >= 0 ? false : true;
        if (isValid) {
            let attachmentResponse = JSON.parse(ShoppingCartStore.favOrderAttachment);
            this.setState({ attachmentPathArrImg: attachmentResponse });
        } else {
            this.setState({
                attachmentErrormsg: "You have exceeded the length of total file names. \nPlease adjust your entry or remove longer file names."
            });
        }
    };
    
               //[15/05/23, PS_EWVB_02, Zeus 112, Sathis. N]- handle wo validation on onblur
    handleWoValidation = (empDDChange) => {
        debugger
        let workOrder = this.state.ValidatedWo;
        let blanketWo = CookieService.getLocalStorageValues("blanketWo");
        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
            let EMP_EMAIL = tempEMPEmail;
            if (EMP_EMAIL == undefined || EMP_EMAIL == null || EMP_EMAIL.trim() == "") {
                EMP_EMAIL = CookieService.getLocalStorageValues("Session_EMAIL");
            }
            let sunetID = EMP_EMAIL.split("@");
            if (workOrder != "" && workOrder != null && workOrder != undefined) {
                if (this.state.disableWo != true || empDDChange) {
					if (blanketWo == undefined || !blanketWo.includes(workOrder)) {
                        let workOrderBO = {
                            WorkOrder: workOrder,
                            BusinessUnit: this.state.Login_UserBU,
                            sunetID: sunetID[0]
                        }
                     

                        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                        ShoppingCartAction.workOrderValidation(workOrderBO);
                    }
                    else {
                        //if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                        //    if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "") {
                        //        if (CookieService.getLocalStorageValues("validatedWO") != workOrder) {
                        //            this.CancelOrder();
                        //            this.setState({
                        //                clearCart: "true"
                        //            })
                        //        }
                        //    }
                        //}
                        if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                console.log("testdesc", this.state.workOrderDescription)
                                this.CancelOrder();
                                this.setState({
                                    clearCart: "true"
                                })
                            }
                        }
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workOrderDescription", this.state.workOrderDescription);
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        let StatusErr = "";
                        let ErrorMsg = "";
                        this.setState({
                            StatusErrVal: StatusErr,
                            ErrorMsgVal: ErrorMsg,
                            ValidatedWo: workOrder,
                            stanfordAssetId: "",
                            disableWo: true
                        })


                       
                    }
                }
            }
            else {
                let StatusErr = "";
                let ErrorMsg = "";
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                this.toastMessage();
                this.setState({
                    //StatusErrVal: StatusErr,
                    //ErrorMsgVal: ErrorMsg,
                    ValidatedWo: "",
                    stanfordAssetId: "",
                    woStyle: "EMPTYWO"
                })
            }
        }
        else {
            CookieService.updateLocalStorageValues("validatedWO", workOrder);
            CookieService.updateLocalStorageValues("validWO", workOrder);
            CookieService.updateLocalStorageValues("WOName", "");
            CookieService.updateLocalStorageValues("stanfordAssetId", "");
            CookieService.updateLocalStorageValues("workOrderDisable", true);
            let StatusErr = "";
            let ErrorMsg = "";
			if (this.state.woStyle != "VALIDWO" && this.state.woStyle != "") {
				this.handleShoppingCartValidation("WO");
			}
			this.setState({
				//StatusErrVal: StatusErr,
				//ErrorMsgVal: ErrorMsg,
                disableWo: true,
                woStyle: "VALIDWO"
			})
        }
    }
                   //[15/05/23, PS_EWVB_06, Zeus 112, Sathis. N]- handle wo validation on onblur
    //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
    woValidateResponse = () => {
        const { t } = this.props;
        let workorder = this.state.ValidatedWo;
        let woValidationData = ShoppingCartStore.woValidationData;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (woValidationData != undefined && woValidationData != null && woValidationData != "" && woValidationData != "null") {
            if (woValidationData != "error occured in server" && woValidationData != "error occured" && woValidationData != "authorization required"
                && woValidationData != "internal server error" && woValidationData != "invalid") {
                let responeData = JSON.parse(ShoppingCartStore.woValidationData);
                //[05/29/2023, PS_ZE_123_APP_03, Dhevanesam R]
                if (responeData[0].WOVALIDATE != null && responeData[0].WOVALIDATE != "") {
                    if (responeData[0].WOVALIDATE == "valid") {
                        //[05/29/2023, PS_ZE_123_APP_03, Dhevanesam R]
                        //if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                        if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workorder) {
                                    this.CancelOrder();
                                    this.setState({
                                        clearCart: "true"
                                    })
                                }
                            }
                       // }
                        CookieService.updateLocalStorageValues("validatedWO", workorder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workorder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].WONAME);
                        CookieService.updateLocalStorageValues("workorderLocation", responeData[0].LOCATION);
                        if (this.state.Login_UserBU.toUpperCase() == "I0631") {
                            CookieService.updateLocalStorageValues("validWO", workorder);
							CookieService.updateLocalStorageValues("WOName", responeData[0].WONAME);
							let rmvndr = responeData.map(function (obj) {
								return obj.REMOVE_VNDR_ID;
							});
							CookieService.updateLocalStorageValues("RemoveVNDR", rmvndr);
                        }
                        else {
                            CookieService.updateLocalStorageValues("validWO", "");
                            CookieService.updateLocalStorageValues("WOName", "");
                        }
						//let StatusErr = "";
						//let ErrorMsg = "";
						if (this.state.woStyle != "VALIDWO" && this.state.woStyle != "") {
							this.handleShoppingCartValidation("WO");
						}
                        this.setState({
                            //StatusErrVal: StatusErr,
                            //ErrorMsgVal: ErrorMsg,
                            disableWo: true,
                            ValidatedWo: workorder,
                            workOrderDescription: responeData[0].WONAME,
                            woStyle: "VALIDWO",

                        })
                    }
                    //else if (responeData[0].WOVALIDATE == "invalid") {
                    //    debugger
                    //    CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                    //    CookieService.updateLocalStorageValues("stanfordAssetId", "");
                    //    CookieService.updateLocalStorageValues("validWO", "clearWO");
                    //    CookieService.updateLocalStorageValues("WOName", "");
                    //    let StatusErr = "Please enter valid work order number";
                    //    let ErrorMsg = "Please enter valid work order number";
                    //    this.setState({
                    //        StatusErrVal: StatusErr,
                    //        ErrorMsgVal: ErrorMsg,
                    //        stanfordAssetId: "",
                    //        ValidatedWo: ""
                    //    })
                    //}
                    //else {
                    //    debugger
                    //    CookieService.updateLocalStorageValues("validatedWO", "error");
                    //    CookieService.updateLocalStorageValues("stanfordAssetId", "");
                    //    CookieService.updateLocalStorageValues("validWO", "clearWO");
                    //    CookieService.updateLocalStorageValues("WOName", "");
                    //    //$('#OLWorkOrderTB').val('');
                    //   // alert("Error occured in work order validation");
                    //    swal({
                    //        allowOutsideClick: false,
                    //        closeOnClickOutside: false,
                    //        text: t("Error occured in work order validation")
                    //    });
                    //    this.setState({
                    //        ValidatedWo: "",
                    //        stanfordAssetId: ""
                    //    })
                    //}

                }
                else {
                    debugger
                    //if (woValidationData != "valid") {
                        //if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                        //    if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "") {
                        //        if (CookieService.getLocalStorageValues("validatedWO") != workorder) {
                        //            this.CancelOrder();
                        //            this.setState({
                        //                clearCart: "true"
                        //            })
                        //        }
                        //    }
                        //}

                    if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workorder) {
                                this.CancelOrder();
                                this.setState({
                                    clearCart: "true"
                                })
                            }
                        }

                        CookieService.updateLocalStorageValues("Session_EMP_ID", this.state.OrderEmpyId.value);
                        CookieService.updateLocalStorageValues("Session_EMP_EMAIL", this.state.OrderEmpyId.email);
                        CookieService.updateLocalStorageValues("validatedWO", workorder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workorder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", responeData[0].assetNumber);
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].workOrderOpDesc);
                        CookieService.updateLocalStorageValues("workOrderDisable", true);

					//let StatusErr = "";
					//let ErrorMsg = "";
					if (this.state.woStyle != "VALIDWO" && this.state.woStyle != "") {
						this.handleShoppingCartValidation("WO");
					}
                        this.setState({
                            //StatusErrVal: StatusErr,
                            //ErrorMsgVal: ErrorMsg,
                            stanfordAssetId: responeData[0].assetNumber,
                            disableWo: true,
                            ValidatedWo: workorder,
                            workOrderDescription: responeData[0].workOrderOpDesc,
                            woStyle: "VALIDWO",
                        })
                   // }
                    
                }

            }
            else if (woValidationData == "invalid") {
                if (!this.state.isEMPDDCh) {
                    CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                    CookieService.updateLocalStorageValues("stanfordAssetId", "");
                    CookieService.updateLocalStorageValues("validWO", "clearWO");
                    CookieService.updateLocalStorageValues("WOName", "");
                    CookieService.updateLocalStorageValues("workOrderDescription", "");
                    CookieService.updateLocalStorageValues("workOrderDisable", false);
                    CookieService.updateLocalStorageValues("workorderLocation", "");
                    let StatusErr = "";
                    let ErrorMsg = "";
                    this.toastMessage();
                    this.setState({
                        //StatusErrVal: StatusErr,
                        //ErrorMsgVal: ErrorMsg,
                        stanfordAssetId: "",
                        ValidatedWo: "",
                        workOrderDescription: "",
                        woStyle: "INVALIDWO",
                    })
                } else {
                    this.toastMessage();
                    this.setState({
                        woStyle: "MISMATCHWO",
                        isEMPDDCh: false
                    })

                }
            }
            else {
                debugger
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("workorderLocation", "");
                //alert("Error occured in work order validation");
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error occured in work order validation")
                });
                this.setState({
                    ValidatedWo: "",
                    stanfordAssetId: "",
                    workOrderDescription: "",
                    StatusErrVal: "",
                    ErrorMsgVal: ""
                })
            }
        }
    }

    getItemWoValue(item) {
        return `${item.value +'~'+ item.desc}`;
    }
    renderWoItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white'}}>
                {item.label}
            </div>
        );

    }
    handleOnChangeWo(e) {
        debugger
        let value = e.target.value.toUpperCase().trim();
        if (value.length < 81) {
            this.setState({
                ValidatedWo: value
            });
            let suggestionWo = CookieService.getLocalStorageValues("blanketWoAndDesc");
            let filteredWo = [];
            if (value == "") {
                this.setState({
                    ValidatedWo: value, suggestionWo: []
                });

            }
            else {
                filteredWo = suggestionWo.filter(name => name.label.includes(value))
                this.setState({
                    ValidatedWo: value, suggestionWo: filteredWo 
                });
            }
        }
        /*if (suggestionWo == "" || value=="") {
            this.handleWoValidation(value);
        }*/
        
    }

    handleOnChangeWoText(e) {
        let value = e.target.value.toUpperCase().trim();
        this.setState({
            ValidatedWo: value
        });
    }

    onSelectItem = (event) => {
        debugger
        let split = event.split("~");
        /*CookieService.updateLocalStorageValues("validatedWO", split[0] );
        CookieService.updateLocalStorageValues("stanfordAssetId", "");
        CookieService.updateLocalStorageValues("workOrderDescription", split[1]);*/
        
        this.setState({
            ValidatedWo: split[0],workOrderDescription: split[1]
        });
    }

    ClearWO = () => {
        debugger
        CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
        CookieService.updateLocalStorageValues("validWO", "clearWO");
        CookieService.updateLocalStorageValues("WOName", "");
        CookieService.updateLocalStorageValues("workOrderDescription", "");
        CookieService.updateLocalStorageValues("workOrderDisable", false);
        CookieService.updateLocalStorageValues("Session_EMP_ID", "");
        CookieService.updateLocalStorageValues("Session_EMP_EMAIL", "");
        this.setState({
            ValidatedWo: "", stanfordAssetId: "", disableWo: false, workOrderDescription:"" });
    }

    handleOnChangeAsset = (event) => {
        this.setState({ stanfordAssetId: event.target.value });
    }

    wodescPopup = () => {
        $('#work-order-details').modal('show');
    }
    OnChangeDeliveryPointDD = (e) => {
        //debugger;
        if (e.notesReq == "Y") {
            this.setState({ OLDeliveryPoint: e, DeliveryPointErr: false, notesReq: true })
        }
        else {
            this.setState({ OLDeliveryPoint: e, DeliveryPointErr: false, notesReq: false, notesErr: false, notesErrMsg: "" })
            this.handleShoppingCartValidation("NOTES");
        }

        CookieService.updateLocalStorageValues("Session_OLDeliveryPoint", e);
        if (this.state.DeliveryPointErr) {
            this.handleShoppingCartValidation("DELIVERYPOINT");
        }
    }
    toastMessage = () => {
        try {
            $("#WOValidation").addClass('d-block');
            setTimeout(() => {
                $("#WOValidation").removeClass('d-block');
            }, 5000);

        } catch (ex) {
            console.log(ex)
        }
	};
	//[09/13/23, PS_ZE_196_APP_04, Sathis N],To invoke on the change of customer notes
    handleOnChangeCustomerNotes = (e) => {
        if (this.state.notesReq) {
            if (e.target.value.length >= 5) {
                this.setState({ notesErrMsg: "", notesErr: false });
            }
            else {
                this.setState({ notesErrMsg: "Please enter minimum 5 characters", notesErr: true })
            }
        }
        if (this.state.notesErr) {
            this.handleShoppingCartValidation("NOTES");
        }
        CookieService.updateLocalStorageValues("Session_Customer_Notes", e.target.value);
        this.setState({ customerNotes: e.target.value });
    }
	//[09/13/23, PS_ZE_196_APP_07, Sathis N],To invoke on the click of enter button(wo field)
	searchKeyPress = (event) => {
		if (event.keyCode == 13) {
			this.handleWoValidation(false);
		};
	};
    render() {
        //[11/26/21, PC_SHT_01, CAS - 31], Poornima S//Added translation keywords
        const { t } = this.props;
        let AddressLabel;
        if (this.state.showShipTo) {
            AddressLabel = "Address";
        }
        else {            
            AddressLabel = "*Address";
        }
        $(".changeevent").on('keyup change', function (event) {
            let id = $(this).attr('id');
            let targetval = $('#' + id).val();
             switch (id) {
                case "CardNumber": {
                    debugger
                    if (targetval == "") {
                        $('#CardNumber').addClass('errorcolour');
                        $('#CardNumber').removeClass('fontGreen');
                        document.getElementById("ExpiryDateError").innerHTML = (t("Card number is required"));
                    }
                    else {
                        let CardNumberValue = targetval.replaceAll(" ", "")
                        if (CardNumberValue.length < 14) {
                            $('#CardNumber').addClass('errorcolour');
                            $('#CardNumber').removeClass('fontGreen');
                            document.getElementById("ExpiryDateError").innerHTML = (t("Invalid card number"));
                        }
                        else {
                            debugger
                            let CardType = document.getElementById('CardTypeLabel').innerHTML
                            if (CardType != null && CardType != undefined && CardType != "") {
                                $('#CardNumber').removeClass('errorcolour');
                                $('#CardNumber').addClass('fontGreen');
                                document.getElementById("ExpiryDateError").innerHTML = "";
                            }
                            else {
                                $('#CardNumber').addClass('errorcolour');
                                $('#CardNumber').removeClass('fontGreen');
                                document.getElementById("ExpiryDateError").innerHTML = (t("Invalid card number"));
                            }
                        }

                    }
                    break;
                }
                case "ExpiryDateValue": {
                    if (targetval == "") {
                        $('#ExpiryDateValue').addClass('errorcolour');
                        $('#ExpiryDateValue').removeClass('fontGreen');
                        document.getElementById("ExpiryDateError").innerHTML = (t("Expiration month & Year is required"));
                    }
                    else {
                        debugger
                        var today = new Date();
                        var date = today.getMonth() + 1
                        var year = today.getFullYear()
                        let EnteredYear = $('#ExpiryYearValue').val()
                        var EnteredDate = "20" + EnteredYear
                        if (parseInt(targetval) == 0 || parseInt(targetval) > 12 || (EnteredDate == year.toString() && parseInt(targetval) < date)) {
                            $('#ExpiryDateValue').addClass('errorcolour');
                            $('#ExpiryDateValue').removeClass('fontGreen');
                            document.getElementById("ExpiryDateError").innerHTML = (t("Invalid expiry date"));
                        }
                        else {
                            $('#ExpiryDateValue').removeClass('errorcolour');
                            $('#ExpiryDateValue').addClass('fontGreen');
                            document.getElementById("ExpiryDateError").innerHTML = "";
                        }
                    }
                    break;
                }
                case "ExpiryYearValue": {
                    if (targetval == "") {
                        $('#ExpiryYearValue').removeClass('errorcolour');
                        $('#ExpiryYearValue').addClass('fontGreen');
                        document.getElementById("ExpiryDateError").innerHTML = (t("Expiration month & Year is required"));
                    }
                    else {
                        var today = new Date();
                        var date = today.getFullYear()
                        var Month = today.getMonth() + 1
                        var EnteredMOnth = $('#ExpiryDateValue').val()
                        var EnteredDate = "20" + targetval
                        if (parseInt(EnteredDate) < date || (EnteredMOnth < Month && parseInt(EnteredDate) == date && EnteredMOnth != "")) {
                            $('#ExpiryYearValue').addClass('errorcolour');
                            $('#ExpiryYearValue').removeClass('fontGreen');
                            document.getElementById("ExpiryDateError").innerHTML = (t("Invalid expiry date"));
                        }
                        else {
                            $('#ExpiryYearValue').removeClass('errorcolour');
                            $('#ExpiryYearValue').addClass('fontGreen');
                            document.getElementById("ExpiryDateError").innerHTML = "";
                        }
                    }
                    break;
                }
                case "CardCvvNumber": {
                    if (targetval == "") {
                        $('#CardCvvNumber').addClass('errorcolour');
                        $('#CardCvvNumber').removeClass('fontGreen');
                        document.getElementById("CVVError").innerHTML = (t("CVV/CVC Number is required"));
                    }
                    else {
                        let CardType = document.getElementById('CardTypeLabel').innerHTML
                        if (CardType != "" && CardType != null && CardType != undefined) {
                            CardType = CardType.toUpperCase()
                        }
                        if (CardType == "AMERICANEXPRESS") {
                            if (targetval.length < 4) {
                                $('#CardCvvNumber').addClass('errorcolour');
                                $('#CardCvvNumber').removeClass('fontGreen');
                                document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
                            }
                            else {
                                $('#CardCvvNumber').removeClass('errorcolour');
                                $('#CardCvvNumber').addClass('fontGreen');
                                document.getElementById("CVVError").innerHTML = "";
                            }
                        }
                        else if (CardType != "AMERICANEXPRESS" && targetval.length > 3) {
                            $('#CardCvvNumber').addClass('errorcolour');
                            $('#CardCvvNumber').removeClass('fontGreen');
                            document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
                        }
                        else if (targetval.length < 3) {
                            $('#CardCvvNumber').addClass('errorcolour');
                            $('#CardCvvNumber').removeClass('fontGreen');
                            document.getElementById("CVVError").innerHTML = (t("Invalid CVV/CVC number"));
                        }
                        else {
                            $('#CardCvvNumber').removeClass('errorcolour');
                            $('#CardCvvNumber').addClass('fontGreen');
                            document.getElementById("CVVError").innerHTML = "";
                        }
                    }
                    break;
                }
            }

        });
        return (           
           
            <div className="container-fluid content-section">
                <div className="row">

                    {/* Shopping Cart starts */}
                    <div className="container-fluid content-margin">
                        <div className="row">
                            <div className="col-md-12 page-heading mb-3">
                                <h5 className="page-title"><Trans>Shopping Cart</Trans></h5>
                                <div className="fav-item-maintenance-icon mt-1">
                                    <input type="checkbox" id="checkbox-a" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-a">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 page-heading mb-3">
                                <h5 className="page-title">{this.state.CerditCardEnabled == false ? null : <Trans>Order Summary & Payment Details</Trans>}</h5><span className="item-count d-inline-block ml-3"># 
                                <Trans>Items</Trans>: <span className="item-count-bold" id="CartCount">{this.state.CartCOunt}</span></span>
                                <div className="float-right my-2 row px-2">
                                    {/*Commented this as we don't need this function - Kishore <div id="QuickItemDIv">
                                        {this.state.Session_ZEUSNOCATALOGSITE != "Y" && this.state.CerditCardEnabled == false ? <div className="input-group charge-code-group float-left mr-2 mb-2" id="QuickItemEntry">
                                            <input type="text" id="QuickItem" className="form-control charge-code-control charge-code-control-search-new" placeholder={t("Quick Item Entry")} maxLength="30" defaultValue={this.state.txtItemID} name="txtItemID" />
                                            <div className="input-group-append" data-toggle="modal" data-target="#quick-item-popup">
                                                <span className="input-group-text" onClick={(e) => this.quickItemClick(e, "1")} data-backdrop="static" data-keyboard="false">
                                                    <span className="shopping-search-icon" />
                                                </span>
                                            </div>
                                        </div> : null}
                                    </div>*/}
                                    {/*<div id="FavButtonDIV">
                                        {this.state.FavMenu.length > 0 && this.state.CerditCardEnabled == false ? <div className="dropdown fav-order-dropdown fav-dropdown float-left mr-2 mb-2" id="FavMenu">
                                            <button type="button" className="btn btn-labeled btn-primary custom-btn-primary primary-btn float-left py-0 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="btn-label"><span className="fav-icon" /></span><Trans>Favorites</Trans></button>
                                            {/*<div id="FavItemMenu"></div>*/}
                                            {/*[02/21/22, CAS - 31, Dhevanesam R]*/}
                                    {/*<ul className="dropdown-menu item-box">
                                                {this.BuildFavMenu()}
                                                {this.state.FavMenu.length > 5 ? <li className="dropdown-submenu text-center">
                                                    <button className="btn btn-primary custom-btn-showall primary-btn col-md-10 mb-2 mt-2" onClick={(event) => this.headerRedirect(event, "/favorites")}><Trans>Show All</Trans></button>
                                                </li> : null}
                                            </ul>
                                        </div> : null}</div>*/}
                                    {this.state.CerditCardEnabled == false ? <button className="btn btn-primary custom-btn-primary primary-btn float-left mr-2" onClick={this.expandAll}>{t(!this.state.isExpand ? `Expand All` : `Collapse All`)}</button> : null}
                                    {/*[11/18/21, PC_SP_03, Bug - 1805, Dhevanesam R]*/}
                                    {/*//[2/18/22, CAS - 31, Dhevanesam R]*/}
                                    <div id="EditFavButton">
                                        {this.state.Session_ZEUSNOCATALOGSITE != "Y" ? <a className="btn btn-labeled btn-primary custom-btn-primary ShoppingCart-Top-Button primary-btn float-left mr-2" id="EditFavLink" onClick={() => this.BacktoSearh(1)}>
                                            <span className="btn-label"><span className="edit-favs-icon" /></span><Trans>Edit Favorites</Trans></a> : null}
                                    </div>
                                    <div id="ContShopButton">
                                        <button className="btn btn-labeled btn-primary custom-btn-primary primary-btn float-left py-0 mr-2 mb-2 btn-continue" id="ContinueShop1" onClick={() => this.BacktoSearh(3)}>
                                            <span className="btn-label"><span className="shopping-bag w-15" /></span><Trans>Continue Shopping</Trans></button>
                                    </div>
                                    {/*[04/08/22, CAS - 31, Dhevanesam R]*/}
                                    {this.state.SearchKey != "" && this.state.SearchKey != null ? <a className="btn btn-primary ShoppingCart-Top-Button custom-btn-primary primary-btn float-left" id="BackSearchpage" onClick={() => this.BacktoSearh(2)}><Trans>Back to search results</Trans></a> : null}
                                </div>
                            </div>
                            <div className="col-lg-12 mb-4" id="OrderlevelDiv">
                                <div className="accordion-toggle" onClick={() => this.onAccordianClick(this.state.ShowOrderDetails)}>
                                    <span className="accordion-arrow1 float-left" id="order-row2" />
                                    <h5 className="fav-item-title float-left p-0 ml-3"><Trans>Order Details</Trans></h5>
                                </div>
                                <div className="p col-md-12 float-left">
                                    <div className="accordian-body py-1" id="order-row1" style={{}}>
                                        <div className="col-md-12 row float-left p-0">
                                            <div className="col-md-8 float-left p-0">
                                                <div className="row ml-4">
                                                    <div className="col-md-6 mb-3 float-left p-0 custom-iphone-padding" id="workorderOL">
                                                        <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label">{t(this.state.WorkOrderTitle)}
                                                            {CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? <span className="info-container">
                                                                <img className="ml-2" src="Images/info-icon.png" />
                                                                <span className="Grid-info-icon text-left sdi-fede-tooltippickup "><Trans>The items in the cart will be removed by changing the WO</Trans></span>
                                                         </span> : null}
                                                        </label>
                                                        <div className="float-left search-autocomplete col-md-6 p-0 home-search sdi-package" >
                                                            {this.state.Login_UserBU == 'I0635' ? 
                                                                <Autocomplete
                                                                    getItemValue={this.getItemWoValue}
                                                                    items={this.state.suggestionWo}
                                                                    renderItem={this.renderWoItem}
                                                                    value={this.state.ValidatedWo}
                                                                    onChange={e => this.handleOnChangeWo(e)}
                                                                    onSelect={(e) => this.onSelectItem(e)}
                                                                    disabled={this.state.disableWo}
                                                                    inputProps={{ placeholder: "Enter Work Order", className: `form-control float-left ${this.state.woStyle == "EMPTYWO" || this.state.woStyle == "INVALIDWO" || this.state.woStyle == "MISMATCHWO" || this.state.woStyle == "EMPTYWOSUBMIT" ? "border-color-red" : ""}`, id: "#OLWorkOrderTB", disabled: this.state.disableWo, onKeyDown: this.searchKeyPress, style: { padding: '0px 57px 0px 10px' } }}
                                                                    maxLength="50"
                                                                /> : <input type="text" className={`form-control  ${this.state.woStyle == "EMPTYWO" || this.state.woStyle == "INVALIDWO" || this.state.woStyle == "MISMATCHWO" || this.state.woStyle == "EMPTYWOSUBMIT" ? "border-color-red" : ""}`} placeholder="Enter Work Order" id="#OLWorkOrderTB" name="WorkOrderNo" disabled={this.state.disableWo} maxLength="80" onKeyDown={(e) => this.searchKeyPress(e)} onChange={e => this.handleOnChangeWoText(e)} value={this.state.ValidatedWo} style={{ padding: "0px 57px 0px 10px" }} />}

                                                            {this.state.ValidatedWo != undefined && this.state.ValidatedWo != "null" && this.state.ValidatedWo != "" && this.state.ValidatedWo != "error" && this.state.ValidatedWo != "blockpopuprepeat" ?
                                                                <> < span class="search-cancel-header cursor-pointer" onClick={() => this.ClearWO()} > </span>
                                                                </> : null

                                                            }
                                                            <span onClick={() => this.handleWoValidation(false)} className="shopping-validate-icon" />
                                                            {/*[05/29/2023, PS_ZE_123_APP_02, Dhevanesam R]*/}
                                                            {(this.state.disableWo != false) ?
                                                                this.state.workOrderDescription != "" && this.state.workOrderDescription != null && this.state.workOrderDescription != undefined ?
                                                                    this.state.workOrderDescription.length < 16 ?
                                                                        <label className="float-left form-label mt-0 w-100 " style={{ "font-size": "14px" }}> {this.state.workOrderDescription}</label> :
                                                                        <label className=" float-left form-label mt-0 w-100 " style={{ "font-size": "14px" }}>{this.state.workOrderDescription.substring(0, 15)}...<button type="button" className="view-more" onClick={(e) => this.wodescPopup(e)}>View more</button></label> : null
                                                                : null}
                                                        </div>
														<span className={`info-container ${this.state.woStyle != "" && this.state.woStyle != "VALIDWO" && this.state.woStyle != "EMPTYWOSUBMIT" ? "" : "d-none"}`}>
															<img className="ml-3 mt-2" style={{ "width": "20px" }} src="Images/validation-icon.svg" />
                                                            <span id="WOValidation" className="Grid-info-icon text-left sdi-fede-tooltippickup text-danger" style={{ "margin": "6px 0px 0px 39px" }}>
                                                                {this.state.woStyle == "EMPTYWO" ? t("Please enter a work order number")
                                                                    : this.state.woStyle == "INVALIDWO" ? t("Please enter a valid work order number")
                                                                        : this.state.woStyle == "MISMATCHWO" ? t("Please enter a valid work order and user combination") : ""}</span>
                                                        </span>
                                                    </div>
                                                <div id="CustomerOrderNo">
                                                    {this.state.Session_MANORDNO != " " && this.state.Session_MANORDNO != null ? <div className="col-md-6 mb-3 float-left p-0 CustomerOrderOL">
                                                        <label className="col-lg-5 col-md-12 float-left p-0 catalog-label form-label"><Trans>Customer Order</Trans> # </label>
                                                        <div className="col-lg-6 col-md-12 float-left p-0 col-12">
                                                            <input type="text" className="form-control" id="OrderNum" placeholder={t("Enter value")} maxLength={this.state.WordOrderNoLength} defaultValue={'NEXT'} />
                                                        </div>
                                                    </div> : null}</div>
                                                {/*[5/92023, PS_ZE_101_05, Dhevanesam R]*/}
                                                
                                                    <div className="col-md-6 mb-3 float-left p-0" id="PanelChargeCOde">
                                                        <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label">{t(this.state.ChargeCodeTitle)}</label>
                                                        {this.state.ChargeCodeMultiple == true ? <div className="col-lg-6 col-md-12 float-left px-0 col-12">
                                                            <div className="col-lg-10 col-md-10 float-left px-0 col-12 CardDiv">
																<input type="text" className={`form-control ${this.state.ChargeCodevalidateErr ? "border-color-red" : ""}`} placeholder={t("Select charge code")} id="OLChargeCode" value={this.state.OlChargeCOde} maxLength="40" onChange={(e) => this.handleChargecodechange(e, 0, "", "")} disabled={this.state.ChargeCodeMultiple} onBlur={() => this.BindEmpCCToItemOL("ChargeCode", "", this.state.OrderEmpyId)} />
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 float-left px-0 CardImageDiv">
                                                                {/*[11/22/21, PC_SC_02, 1854], Priyanka D*/}
                                                                <button className="btn btn-primary custom-btn-primary primary-btn float-right" data-backdrop="static" data-keyboard="false" onClick={() => this.GetChargeCode(0, "", "", this.state.OlChargeCOde, "Ordlvl")}>CC</button>
                                                            </div>
                                                        </div> : <div className="col-lg-6 col-md-12 float-left px-0 col-12 mr-1">
																<input type="text" className={`form-control ${this.state.ChargeCodevalidateErr ? "border-color-red" : ""}`} placeholder={t("Enter charge code")} id="OLChargeCode" value={this.state.OlChargeCOde} maxLength="40" onChange={(e) => this.handleChargecodechange(e, 0, "", "")} onBlur={() => this.BindEmpCCToItemOL("ChargeCode", "", this.state.OrderEmpyId)} />

                                                            </div>}
                                                        {/*this.state.ChargeCodeMultiple == true ? <div className="col-lg-1 float-left px-0 ChargeCodeBtn">
                                                       
                                                    </div> : null*/}
                                                    </div>
                                                <div className="col-md-6 mb-3 float-left p-0" id="EmployeeIDDIV">
                                                    <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label" id="OLEmplyIdLabel">{t(this.state.OLEmplyIdLabel)}</label>
                                                    {/* <div className="col-lg-6 col-md-12 float-left px-0 col-12 mr-1" >
                                                        <select className="form-control" id="OLEmplyDD" value={this.state.OrderEmpyId} onChange={() => this.BindEmpCCToItemOL("EmplyCCOL", "")}>
                                                            <option value=" ">{t("Select")}</option>
                                                            {this.BindEmpCCToItem()}
                                                        </select>
                                                    </div>*/}
                                                        <span className="col-lg-6 col-md-12 float-left px-0 col-12 mr-1" style={{ "fontSize": "12px", }}><Select
                                                        isDisabled={this.state.EmpIDDisable}
                                                        id="OLEmplyDD"
                                                        name="EmpDD"
                                                        onChange={(e) => this.BindEmpCCToItemOL("EmplyCCOL", "", e)}
                                                        value={this.state.OrderEmpyId}
                                                        options={this.state.EmplyeeNameDD}
															styles={{
																control: (provided, state) => ({
																	...provided,
																	borderColor: this.state.woStyle == "MISMATCHWO" || this.state.OLEmployeeCCErr ? "#FF0000" : "#ced4da",
																	boxShadow: (this.state.woStyle == "MISMATCHWO" || this.state.OLEmployeeCCErr) && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
																	"&:hover": {
																		...provided,
																		borderColor: this.state.woStyle == "MISMATCHWO" || this.state.OLEmployeeCCErr ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
																	}
																}),
															}}

                                                    /></span>
                                                </div>
                                                <div className="col-md-6 mb-3 float-left p-0 custom-iphone-padding" id="MachinenumberDiv">
                                                    <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label"><Trans>Asset ID</Trans></label>
                                                    <div className="col-lg-6 float-left p-0 col-md-12 col-12">
                                                        <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                                            {/*<select className="form-control">*/}
                                                                {this.state.MachineTable.length > 0 ? <select className="form-control" id="OLMachDD">
                                                                    <option value="0">{t("Select")}</option>
                                                                    {this.BuildMachineDD()}
                                                                </select> : <input type="text" className="form-control" id="OLMachDD" placeholder={t("Enter Asset ID")} maxLength="20" defaultValue={this.state.stanfordAssetId} onChange={(e) => this.handleOnChangeAsset(e)} />}
                                                          {/* <span className="float-left form-label Machinnum-margin">If not a tagged asset,leave field blank</span>
                                                           <option>Select</option>
                                                                <option>Option</option>
                                                            </select>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<div className="col-md-6 mb-3 float-left p-0"></div>*/}
                                                <div id="TypeDIv">
                                                    {this.state.TypeTable.length > 0 ? <div className="col-md-6 mb-3 float-left p-0 custom-iphone-padding">
                                                        <label className="col-lg-5 col-md-12 float-left p-0 catalog-label form-label">Type</label>
                                                        <div className="col-lg-6 float-left p-0 col-md-12 col-12">
                                                            <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                                                {/*<select className="form-control">*/}
                                                                <select className="form-control" id="OLTyeDD">
                                                                    <option value="OP">{t("Select")}</option>
                                                                    {this.BuildTypeDD()}
                                                                </select>
                                                                {/*<option>Select</option>
                                                                <option>Option</option>
                                                            </select>*/}
                                                            </div>
                                                        </div>
                                                    </div> : null/*<input type="text" className="form-control" id="OLTyeDD" placeholder="Enter Type" maxLength="20" defaultValue={''} />*/}
                                                </div>
                                                <div className="col-md-6 mb-3 float-left p-0" id="OrderCustomerRef">
                                                    {/*[11/18/21, PC_SP_03, Bug - 1811, Dhevanesam R]*/}
                                                    <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label"><Trans>Customer Reference</Trans></label>
                                                    <div className="col-lg-6 col-md-12 float-left px-0 col-12">
                                                        <input type="text" className="form-control" id="CustRef" placeholder={t("Customer Reference")} maxLength="30" />
                                                    </div>
                                                    </div>   
                                                    {CookieService.getLocalStorageValues("Session_DeliveryDateFlag") == "Y" ?
                                                        <div id="Orderduedate" className="col-md-6 mb-3 float-left p-0 custom-iphone-padding OLDatepicker">

                                                            <div id="OLDueDateDiv">
                                                                <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label">*<Trans>Required by date</Trans></label>

                                                                <div className="col-lg-6 float-left p-0 col-md-12 col-12">

                                                                    <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                                                        {this.ShowDatePicker()}
                                                                        <span className="calendar-icon" onClick={() => $(`#OLDueDate`).focus()} />
                                                                    </div>
                                                                </div>
                                                            </div> </div> :
                                                        <div className={`${((this.state.Session_ZEUSNOCATALOGSITE != "Y" || this.state.Session_ZEUS_SITE != "Y") && this.state.OnlyCatalogItem) == false ? 'col-md-6 mb-3 float-left p-0 custom-iphone-padding OLDatepicker' : ''}`}>
                                                            {(this.state.Session_ZEUSNOCATALOGSITE != "Y" || this.state.Session_ZEUS_SITE != "Y") && this.state.OnlyCatalogItem == false ?
                                                                <div id="Orderduedate">

                                                                    <div id="OLDueDateDiv">
                                                                        <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label">*<Trans>Delivery Date</Trans></label>

                                                                        <div className="col-lg-6 float-left p-0 col-md-12 col-12">

                                                                            <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                                                                {this.ShowDatePicker()}
                                                                                <span className="calendar-icon" onClick={() => $(`#OLDueDate`).focus()} />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div> : null}
                                                        </div>}
                                                    {this.state.DeliveryPointFlag ? <>
                                                        <div className="col-md-6 mb-3 float-left p-0" id="DeliveryPointDIV">
                                                            <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label" id="DeliverLabel">*<Trans>Delivery Point</Trans></label>
                                                            <span className="col-lg-6 col-md-12 float-left px-0 col-12 mr-1" style={{ "fontSize": "12px", }}><Select
                                                                id="DPointDD"
                                                                name="DPDD"
                                                                onChange={(e) => this.OnChangeDeliveryPointDD(e)}
                                                                value={this.state.OLDeliveryPoint}
																options={this.state.DeliveryPointDropdown}
																styles={{
																	control: (provided, state) => ({
																		...provided,
																		borderColor: this.state.DeliveryPointErr ? "#FF0000" : "#ced4da",
																		boxShadow: this.state.DeliveryPointErr && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
																		"&:hover": {
																			...provided,
																			borderColor: this.state.DeliveryPointErr ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
																		}
																	}),
																}}
                                                            /></span>
                                                        </div> </> : null}
                                                    <div className="col-md-6 p-0">
                                                        {this.state.showShipto ? <div id="OrderShipTo">
                                                            <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label">{t(this.state.OLShipToIdLabel)}</label>
                                                            <div className="col-lg-6 col-md-12 float-left p-0 col-12">
                                                                {this.state.ShipToDDValue.length > 1 ? <Select
                                                                    id="OLShipTo"
                                                                    className={this.state.pickupcheck ? "DropDown-Font-Size disabled" : "DropDown-Font-Size"}
                                                                    value={this.state.defaultShipto}
                                                                    onChange={this.handleChangeShipto}
                                                                    options={this.state.ShipToDDValue}
																	isDisabled={this.state.disableShipto}
																	styles={{
																		control: (provided, state) => ({
																			...provided,
																			borderColor: this.state.OLShipToErr ? "#FF0000" : "#ced4da",
																			boxShadow: this.state.OLShipToErr && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
																			"&:hover": {
																				...provided,
																				borderColor: this.state.OLShipToErr ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
																			}
																		}),
																	}}
                                                                />
                                                                    : this.BuildShipToDD()}
                                                            </div>
                                                            {/*<div className="col-lg-1 float-left mt-2 ml-2">
                                                        <span style={{ "font-size": "12px", "padding":"2%" }} id="OrTag">(Or)</span>
                                                    </div>*/}
                                                        </div> :
                                                            <div id="AddressField">
                                                                <label className="col-md-12 p-0 catalog-label form-label col-lg-4"><Trans>{AddressLabel}</Trans></label>
                                                                <div className="col-lg-6 col-md-12 float-left p-0 col-12">
																	<textarea className={`form-control AddressTB-Padding ${this.state.userAddressErr ? "border-color-red" : ""}`} placeholder="Add Address" id="AddressTB" value={this.state.userAddress} rows={4} defaultValue={""} disabled />
                                                                    {/*[5/29/2023]Z-102 Address field in cart will be editable always - Poornima S*/}
                                                                    <span class="edit-favs-icon cursor-pointer" id="EditAddress" style={{ "position": "absolute", "z-index": "12px", "top": "12px", "right": "5px" }} onClick={() => this.handleAddressSelect()} > </span>

                                                                    <div>
                                                                        <span className="float-left address-msg text-danger" id="isValidAddress"></span>
                                                                        {this.state.userAddress != "" ? <a href="#" className="float-right" style={{ "font-size": "13px", "margin-top": "5px" }} onClick={(e) => this.clearAddress(e)}><Trans>Clear</Trans></a> : null}
                                                                    </div>
                                                                    {this.state.userAddress != "" ?
                                                                        <div className="custom-control custom-checkbox my-2 pl-3  float-left pr-0 item-custom-control col-lg-12 float-left">
                                                                            <input type="checkbox" className="custom-control-input" id="AddressCB" checked={this.state.isDefAddressChckd} name="AddressCB" onChange={(e) => this.setDefaultAddress(e)} />
                                                                            <label className="custom-control-label catalog-label priority-checkbox" htmlFor="AddressCB"><Trans>Set as default address</Trans></label>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>}
                                                    </div>
                                                    
                                            </div>
                                            </div>

                                            {/*//[5/7/23, PC_ZE_85_APP_06, Dhevanesam R]*/}
                                            <div className="col-md-4 float-left p-0 custom-iphone-padding xs-margin-style">
                                                <label className="catalog-label col-md-12 float-left p-0" id="NotesLAbel">{this.state.notesReq ? <span>*</span> : null}<Trans>Customer Notes</Trans></label>
                                                <textarea className={`form-control col-md-12 float-left px-2 ${this.state.notesErr ? 'border-color-red' : ''}`} maxLength="1333" id="CusNotes" rows={4} value={this.state.customerNotes} onChange={e => this.handleOnChangeCustomerNotes(e)} />
                                                {this.state.notesErrMsg ? <label style={{ 'font-size': '15px', 'color': 'red' }} id="NotesLAbel">{this.state.notesErrMsg}</label> : null}
                                                <div id="PriortityDIv">
                                                    {this.state.Session_ZEUSNOCATALOGSITE != "Y" || this.state.Session_ZEUS_SITE != "Y" ? <div className="custom-control custom-checkbox my-2 pl-3  float-left pr-0 item-custom-control col-md-4 float-left" id="OLPriority">
                                                        <input type="checkbox" className="custom-control-input" id="customCheckOP7" name="example4" />
                                                        <label className="custom-control-label catalog-label priority-checkbox" htmlFor="customCheckOP7"><Trans>Priority Order</Trans></label>
                                                    </div> : null}</div>
                   
												{CookieService.getLocalStorageValues("Session_DeliveryDateFlag") != "Y" ?<div className="custom-control custom-checkbox my-2 pl-3  float-left pr-0 item-custom-control col-md-4 float-left">
                                                    <input type="checkbox" className="custom-control-input" id="pickupcheck" checked={this.state.pickupcheck} name="example3" onChange={e => this.pickupchange(e)} />
                                                    <label className={`custom-control-label priority-checkbox  ${this.state.isAllItem_are_Pickup ? (!this.state.PickupVendor.trim().toUpperCase().includes("LOWE'S") && !this.state.PickupVendor.trim().toUpperCase().includes("HOME DEPOT") && this.state.PickupVendor != "") ? '' : ' custombtn-disabled': ' custombtn-disabled'}`} htmlFor="pickupcheck"><Trans>Pickup</Trans></label>
                                                    <span className="info-container ml-2">
                                                            <img className="ml-2" src="Images/info-icon.png" />
                                                            <span className="Grid-info-icon text-left sdi-fede-tooltippickup "><Trans>Items have to be from the same Supplier/RTI location for pickup to be enabled</Trans></span>
                                                        </span>
                                                        </div>:null}
                    
                                                {/*<div> <span className="errorspan1 mr-2" id="StatusMsg1"></span></div>*/}
                                                {this.state.lowesitem == false && this.state.pickupcheck == true ?
                                                    <div className="col-md-8 float-left p-0 custom-iphone-padding" >
                                                        <div class="col-md-12 mb-3 float-left p-0">
                                                            < p className=" form-label Machinnum-margin"><Trans>Select Supplier Location for pickup</Trans></p>
                                                            <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label"><Trans>Pickup Store</Trans></label>
                                                            <div className="col-lg-8 col-md-12 float-left p-0 col-12">
                                                                <Select
                                                                    id="pickupstore_DD"
                                                                    name="PickupStoreSelected"
                                                                    className="DropDown-Font-Size"
                                                                    value={this.state.PickupStoreSelected}
                                                                    onChange={(e) => this.handleStoreChange(e)}
                                                                    options={this.state.Pick}
																	styles={colourStyles}
																	styles={{
																		control: (provided, state) => ({
																			...provided,
																			borderColor: this.state.PickupStoreErr ? "#FF0000" : "#ced4da",
																			boxShadow: this.state.PickupStoreErr && state.isFocused ? '0 0 0 1px rgba(250, 0, 0) ' : 'initial',
																			"&:hover": {
																				...provided,
																				borderColor: this.state.PickupStoreErr ? '0 0 0 1px rgba(250, 0, 0)' : 'initial',
																			}
																		}),
																		...colourStyles,
																	}}
                                                                />
                                                            </div>
                                                        </div>
                                                        {this.state.PickupVendor.toUpperCase().includes("GRAINGER") || this.state.PickupVendor.toUpperCase().includes("DIVERSIFIED SUPPLY") && this.state.pickupcheck == true ?
                                                            <>
                                                                <div className="col-md-12 float-left p-0">
                                                                    <span className="cart-count" style={lal}></span>
                                                                    <span className="errorspan1" style={txt}><Trans>Available</Trans></span>
                                                                </div>
                                                                <div className="col-md-12 float-left p-0">
                                                                    <span className="cart-count" style={la}></span>
                                                                    <span className="errorspan1 " style={txt}><Trans>Partially available</Trans></span>
                                                                </div>
                                                            </> : <div className="col-md-12 float-left p-0">
                                                                <span className="cart-count" style={blk}></span>
                                                                <span className="errorspan1 " style={txt}><Trans>Contact branch</Trans></span>
                                                            </div>}
                                                    </div>
                                                    : null}
                                            </div> 
                                            

                                            

                                            {/*[3/16/22, PC_SP_04, CAS - Not to show btns when cart has no item, Dhevanesam R]*/}
                                            {this.state.CartItems.length > 0 ? <div className="col-md-12 my-3 float-left">
                                                {/*[8/30/22, Bug - 2555, Validation UI issue fix - Poornima S*/}
                                                <div> <p className="errormsg mr-2 mb-3" id="StatusMsg1"><Trans>{this.state.ErrorMsgVal}</Trans></p></div>
                                                <button className="btn btn-primary custom-btn-primary primary-btn float-right" onClick={() => this.SubmitOrder()} data-backdrop="static" data-keyboard="false">{t(this.state.CREDITCARDPROCSite == false ? "Submit Order" : "Check Out")}</button>
                                                <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right mr-2" data-toggle="modal" data-target="#Cancel-btn" data-backdrop="static" data-keyboard="false"><Trans>Cancel Order</Trans></button>
                                            </div> : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {this.state.CerditCardEnabled == false ? <div className="col-lg-12">
                                {this.BindCart()}
                            </div> : <div className="col-lg-9 itemScroll">
                                    {this.BindCart()}
                                </div>}

                            {this.state.CerditCardEnabled != false ?
                                <div className="col-lg-3 CerditCarddiv">
                                    <div className="row price-payment-container mb-3">
                                        <div className="col-md-12 py-2 mb-3 price-payment-header">
                                            <span className="price-payment-heading"><Trans>Price Details</Trans></span>
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <div className="row">
                                                <label className="col-md-6 col-8 price-payment-label"><Trans>Merchandise Total</Trans></label>
                                                <label className="col-md-6 col-4">
                                                    <span className="float-right price-payment-value">{this.OrderTotalCal()}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <div className="row">
                                                <label className="col-md-6 col-8 price-payment-label"><Trans>Estimated Tax Amount</Trans></label>
                                                <label className="col-md-6 col-4">
                                                    <span className="float-right price-payment-value">{this.state.taxValue}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-2 pb-1 price-payment-footer">
                                            <div className="row">
                                                <label className="col-md-6 col-8 price-order-total"><Trans>Order Total</Trans></label>
                                                <label className="col-md-6 col-4">
                                                    <span className="float-right price-payment-value" id="OrderTaxTotal">{this.OrderTaxTotal()}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row price-payment-container mb-3">
                                        <div className="col-md-12 py-2 mb-3 price-payment-header">
                                            <span className="price-payment-heading"><Trans>Payment Details</Trans></span>
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <label className="d-block payment-label-title"><Trans>Credit Card</Trans> / <Trans>Debit Card</Trans></label>
                                            <label className="d-block payment-info"><Trans>All Cards are accepted</Trans></label>
                                        </div>
                                        <div className="col-md-6 mb-3 px-2">
                                            <button className="btn btn-primary w-100 clear-btn" onClick={() => this.clearCard()}><Trans>Clear Card</Trans></button>
                                        </div>
                                        {this.state.CerditCardsExist == true ? <div className="col-md-6 mb-3 px-2">
                                            <button className="btn btn-primary custom-btn-primary primary-btn w-100 wallet-btn" data-toggle="modal" data-target="#walletpopup" data-backdrop="static" data-keyboard="false" onClick={() => this.GetWallet()}><Trans>Wallet</Trans></button>
                                        </div> : null}
                                        <div className="col-md-12 mb-3 mr-1">
                                            <label className="d-block payment-label mandatory"><Trans>Card Number</Trans></label>
                                            <div className="col-md-10 pl-0 CardDiv">
                                                <input type="text" className="form-control payment-control float-left changeevent" placeholder={t("Enter your Card Number")} name="CardNumber" id="CardNumber" disabled={this.state.CardfieldDisabled} maxLength="19" value={this.state.CardNumber} onChange={this.handleFieldChange} onBlur={this.gettokenValue} />
                                            </div>
                                            {/*<span className="credit-card-icon" />*/}
                                            <div className="col-md-2 float-right pl-0 mr-1 CardImageDiv">
                                                {this.bindCardImage()}</div>

                                            <div id="tokenExIframeDiv d-none" className="iframediv"></div>
                                        </div>

                                        <div className="col-md-6 mb-3 ExpirydateDiv">
                                            <label className="d-block payment-label mandatory"><Trans>Expiry Date</Trans></label>
                                            <div className="float-left pl-0 mr-0">
                                                <input type="text" className="form-control payment-control CardExpiryDate changeevent" placeholder="MM" name="ExpiryDate" id="ExpiryDateValue" maxLength="2" value={this.state.ExpiryDate} onChange={this.handleFieldChange} onBlur={this.SetExpiryDate} />
                                            </div>
                                            <div className="Font-22 float-left pl-3">
                                                /
                                            </div>
                                            <div className="float-left pl-0">
                                                <input type="text" className="form-control payment-control CardExpiryDate changeevent" placeholder="YY" name="ExpiryYear" id="ExpiryYearValue" maxLength="2" value={this.state.ExpiryYear} onChange={this.handleFieldChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 ExpirydateDiv">
                                            <label className="d-block payment-label mandatory"><Trans>CVV/CVC Number</Trans></label>
                                            <input type="text" className="form-control payment-control changeevent" placeholder={t("Enter CVV/CVC")} name="CardCVV" id="CardCvvNumber" maxLength="4" value={this.state.CardCVV} onChange={this.handleFieldChange} />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="col-md-6 pl-0 float-left ExpirydateDiv">
                                                <label className="d-block payment-label DropDown-Font-Size errorcolour" id="ExpiryDateError"></label>
                                            </div>
                                            <div className="col-md-6 pl-0 float-right ExpirydateDiv">
                                                <label className="d-block payment-label pl-3 DropDown-Font-Size errorcolour" id="CVVError"></label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 mt-2 px-2">
                                            <button className="btn btn-secondary custom-btn-secondary secondary-btn w-100 d-none"><Trans>Cancel</Trans></button>
                                        </div>
                                        <div className="col-md-6 mb-3 mt-2 px-2">
                                            <button className="btn btn-primary custom-btn-primary primary-btn w-100" data-toggle="modal" onClick={() => this.MakePayMent()} disabled={this.state.SCbIsWithPricedItems == true ? false : true}><Trans>Pay Now</Trans> </button>
                                        </div>
                                    </div>
                                </div> : null}
                            <label id="CardTypeLabel" className="d-none"></label>
                            <label id="CardToken" className="d-none"></label>
                            {/*[10/26/21, PC_05, IE 11 issue, Dhevanesam R]*/}
                            {this.state.showprice == "0" && this.state.CerditCardEnabled == false ? <div className="row mx-0 w-100">
                                <div className="col-md-12 float-left">
                                    <div className="float-left shopping-order">
                                        {/*[10/13/21, PC_SC_02 CAS - 32], Priyanka D*/}
                                        {/*[11/18/21, PC_SP_01, Bug - 1805, Dhevanesam R]*/}
                                        <Trans>Order Total</Trans><span className="mr-2 ml-1">{this.PriceDesignation()}</span><span className="price-rate">{this.OrderTotalCal()}</span>
                                    </div>
                                </div>

                            </div> : null}
                            {/*[3/16/22, PC_SP_04, CAS - Not to show btns when cart has no item, Dhevanesam R]*/}
                            {this.state.CartItems.length > 0 ?
                                this.state.CerditCardEnabled == false ? <div className="col-md-12 my-3 float-left" style={{ 'padding-right': '53px' }}>
                                    <div style={{ 'margin-right': '7px' }}> <p className="errormsg ml-1 mr-0" id="StatusMsg" ><Trans>{this.state.StatusErrVal}</Trans></p></div>
                                    <button className="btn btn-primary custom-btn-primary primary-btn float-right" onClick={() => this.SubmitOrder()} data-backdrop="static" data-keyboard="false">{t(this.state.CREDITCARDPROCSite == false ? "Submit Order" : "Check Out")}</button>
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right mr-2" data-toggle="modal" data-target="#Cancel-btn" data-backdrop="static" data-keyboard="false"><Trans>Cancel Order</Trans> </button>
                                </div> : <div className="col-md-12 my-3 float-left" style={{ 'padding-right': '53px' }}>
                                        <div style={{ 'margin-right': '7px' }}><p className="errormsg ml-1 mr-0" id="StatusMsg"><Trans>{this.state.StatusErrVal}</Trans></p></div>
                                        <button className="btn btn-primary custom-btn-primary primary-btn float-right" onClick={() => this.SubmitOrder()} data-backdrop="static" data-keyboard="false">{t(this.state.CREDITCARDPROCSite == false ? "Submit Order" : "Check Out")}</button>
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right mr-2" data-toggle="modal" data-target="#Cancel-btn" data-backdrop="static" data-keyboard="false"><Trans>Cancel Order</Trans> </button>
                                    </div>
                                : null}
                        </div>
                    </div>
                </div>
                {/* Edit description popup starts */}

                <div className="modal" id="Edit-description">
                    {this.state.EditDescPopUp ? <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Edit Description</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
                             </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <span className="float-left cc-help-info"><Trans>Item ID</Trans>: <span className="mx-1">{this.state.ItemId}</span><span visibility="hidden">{this.state.UniqId}</span></span>
                                    </div>
                                    <div className="col-12 edit-desc-field mb-4">
                                        <label className="catalog-label d-block"><Trans>Description</Trans></label>
                                        <textarea className="form-control custom-textarea" rows={5} id="DespNewValue" maxLength="254" value={this.state.Description} onChange={(e) => { this.handleDescription("Description", e.target.value) }} />
                                    </div>
                                    <div className="col-12 edit-desc-field mb-4">
                                        <label id="DescriptionValidMsg" className="DropDown-Font-Size errorcolour"></label>
                                    </div>

                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-4" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                        <button id="DescripUpBtn" className="btn btn-primary custom-btn-primary primary-btn px-4" onClick={() => this.LinelevelEmyEdit("", "", "DESCR254", "")} ><Trans>Update</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>

                {/* Edit description popup end */}
                <div className="modal" id="quickconfirm" data-backdrop="static" data-keyboard="false">
                    {this.state.strCplusitemid != "0" ? <QuickItemComponent key={qckKeycounter} itemid={this.state.txtItemID.trim()} closePopup={this.closePopup} cpitemid={this.state.strCplusitemid} {...this.props} /> : null}
                </div>
                {/*Favourite Item Pop up*/}
                <div className="modal" id="add-fav-popup" data-backdrop="static" data-keyboard="false">
                    {this.state.AddFavPUp ?
                        <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                            <div className="modal-content">
                                {/* Modal Header */}
                                <div className="modal-header">
									<h4 className="modal-title mt-1">Add Favorites</h4>
                                    <button type="button" className="btn close-btn" data-dismiss="modal">
                                        X
              </button>
                                </div>
                                {/* Modal body */}
                                {/*[04/08/22, CAS - 31, Dhevanesam R]*/}
                                <div className="modal-body">
                                    <div className="row mt-3">
                                        <div className="col-12 edit-desc-field mb-4">
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Item ID</Trans></label>
                                                <label className="col-md-7 quick-value" id="FavItemId" >{this.state.FavMFGPartNUMPopUp}</label>
                                            </div>
                                            {/* <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>SDI Category</Trans></label>
                                                <label className="col-md-7 quick-value" id="FavSDICatg" >{this.state.FavSDICatgPopUp}</label>
                                            </div>*/}
                                           
                                            <div className="row mb-3">
												<label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Category</Trans></label>
                                                {/*<div className="col-md-7">
                                                <textarea className="form-control custom-textarea" id="FavYourCatg" maxLength="40" rows={3} defaultValue={''} />
                                            </div>*/}
                                                <div className="float-left search-autocomplete col-md-7 home-search" style={{ zIndex: "9999" }}>
                                                    <Autocomplete
                                                        getItemValue={this.getItemValue}
                                                        items={this.state.bindCategory}
                                                        renderItem={this.renderItem}
                                                        value={this.state.FavYourCatgPopUp}
                                                        onChange={e => this.onChange(e, "CAT")}
                                                        onSelect={e => this.onSelect(e, "CAT")}
                                                        inputProps={{ placeholder: "Search", className: "form-control float-left" }}
                                                        maxLength="40"
                                                    />
                                                    <span className="errorspan1" style={{ fontSize: '10px' }} id="favordcatid" />
                                                    {this.state.categoryError ? <span className=" W-100 errorspan mr-3" style={{ fontSize: '15px' }}><Trans>Enter category</Trans></span> : null}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
												<label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Sub category</Trans></label>

                                                <div className="float-left search-autocomplete col-md-7 home-search">
                                                    <Autocomplete
                                                        getItemValue={this.getItemValue}
                                                        items={this.state.bindSubCategory}
                                                        renderItem={this.renderItem}
                                                        value={this.state.FavYourSubCatgPopup}
                                                        onChange={e => this.onChange(e, "SUBCAT")}
                                                        onSelect={e => this.onSelect(e, "SUBCAT")}
														inputProps={{ placeholder: "Search", className: "form-control float-left", id: "FavItemSubCatg", disabled: this.state.FavYourCatgPopUp.length > 0 ? false : true }}
                                                        maxLength="40"
                                                    />
                                                    <span className="errorspan1" style={{ fontSize: '10px' }} id="favordsubcatid" />
                                                    {this.state.subCategoryError ? <span className=" W-100 errorspan1 text-left " style={{ fontSize: '15px' }}><Trans>Enter sub category</Trans></span> : null}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
												<label className="col-md-5 quick-label"><Trans>Item description</Trans></label>
                                                <label className="col-md-7 quick-value" id="FavItemDescrip" >{this.state.FavItemDescripPopUp}</label>
                                            </div>
                                            <div className="row mb-3">
												<label className="col-md-5 quick-label"><Trans>Your description</Trans></label>
                                                <div className="col-md-7">
                                                    <textarea className="form-control custom-textarea" id="FavYourDescrip" maxLength="100" rows={5} defaultValue={this.state.FavYourDescripPopUp} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label">MFG</label>
                                                <label className="col-md-7 quick-value" id="FavMFG" >{this.state.FavMFGPopUp}</label>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Supplier Part Number</Trans></label>
                                                <label className="col-md-7 quick-value" id="FavMFGPartNUM" >{this.state.FavItemIdPopUp}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-center mb-2">
                                            {/*[11/18/21, PC_SP_02, Bug - 1811, Dhevanesam R]*/}
                                            <button className="btn btn-primary custom-btn-primary primary-btn px-4" id="FavSaveBtn" onClick={() => this.AddFavItem()}><Trans>Save</Trans></button>
                                        </div>
                                        <div visibility="false">
                                            <label id="txtUOMHide" hidden={true}>{this.state.txtUOMHidePopUp}</label>
                                            <label id="txtMfgItemidHide" hidden={true}>{this.state.txtMfgItemidHidePopUp}</label>
                                            <label id="txtItemidHide" hidden={true}>{this.state.txtItemidHidePopUp}</label>
                                            <label id="txtMFGHide" hidden={true}>{this.state.txtMFGHidePopUp}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                </div>

                <div className="modal" id="Cancel-confirmation">
                    {this.state.RemoveItemPUp ?
                        <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                            <div className="modal-content">
                                {/* Modal Header */}
                                <div className="modal-header">
                                    <h4 className="modal-title mt-1" />
                                    <button type="button" className="btn close-btn" data-dismiss="modal">
                                        X
              </button>
                                </div>
                                {/* Modal body */}
                                <div className="modal-body">
                                    <div className="row text-center">
                                        <div className="col-md-12 my-4">
                                            <span className="question-mark-icon delete-confirm-icon" />
                                        </div>
                                        <div className="col-12 mb-4">
                                            <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                            <label className="confirm-content d-block"><Trans>Do you want to remove this item?</Trans></label>
                                        </div>
                                        <div className="col-md-12 text-center mb-2">
                                            <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal"><Trans>No</Trans></button>
                                            <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.DeleteItemConfirmed()}><Trans>Yes</Trans></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                <div className="modal" id="Order-Placed" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                {/*<button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.OrderPlaced()}>
                                    X
              </button>*/}
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-4">
                                        <span className="question-mark-icon success-alert-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block">{this.state.OrderPlacedstatus}</label>
                                        <label className="confirm-label d-block"><Trans>Order Number</Trans>:<span className="mx-2">{this.state.NonCataOrderNum == "" || this.state.NonCataOrderNum == null ? this.state.PricedOrderNum : this.state.NonCataOrderNum}</span></label>

                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.OrderPlaced()}><Trans>Ok</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="addattachment">
                    {this.state.AddAttachPUp ? <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Add Attachment</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={this.clearAttaValid}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row">
                                    <label className="col-md-5"><Trans>Select file to attach</Trans></label>
                                    <div className="col-md-7">
                                        <label className="file">
                                            {/*[6/22/22, PC_AU_01, Bug - 2075, Dhevanesam R]*/}
                                            <input type="file" title=" " accept="application/pdf, image/jpeg, image/png" id="ShopCartcustomFile" name="filename" multiple aria-label="File browser example" onChange={this.handleAttachment} />
                                            <span className="file-custom" />
                                        </label>
                                        {this.bindAttachments()}
                                        {/*<span className="attachment-file-name pl-0 my-2 d-inline-block"><a href="#">Something.png</a><span className="attachment-remove-icon ml-1">X</span></span>*/}
                                        <span className="float-left err-msg text-danger" id="AttachError"></span>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2 text-right p-0">
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-4" data-dismiss="modal" onClick={this.clearAttaValid}><Trans>Cancel</Trans></button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={(e) => this.LinelevelEmyEdit("", "", "Attachment", "", e)}><Trans>Attach</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
                <div className="modal" id="line-increment" data-backdrop="static" data-keyboard="false">
                    {this.state.LineIncrePUp ? <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />

                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <div className="text-center mt-2">
                                            <img src="Images/confirm-question-mark.png" />
                                        </div>
                                        <div className="text-center my-4">
                                            <span className="info-label"><Trans>Would you like to use line numbers by increments of 10?</Trans></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-secondary custom-btn-custom-btn-secondary secondary-btn px-5 mr-3" data-dismiss="modal" onClick={() => this.incrementLineNo(0)}><Trans>No</Trans></button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.incrementLineNo(1)}><Trans>Yes</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
                <div className="modal" id="walletpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>My Wallet</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-3">
                                        <span className="question-mark-icon wallet-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-content d-block"><Trans>Select any one of your wallet account for your payment process</Trans></label>
                                        {this.BindCards()}
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-4" data-dismiss="modal" onClick={() => this.SetCardField(0)}><Trans>Cancel</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" data-dismiss="modal" onClick={() => this.SetCardField(1)}><Trans>Select</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="Fav-confirmation">
                    {this.state.FavOrderConfPUp ? <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                {/* <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.AddFavOrder("False")}>
                                    X
                                </button>*/}
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-4">
                                        <span className="question-mark-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block"><Trans>Do you want to make this as your favorite order?</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal" onClick={() => this.AddFavOrder("False")}><Trans>No</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.FavOrdCatPopup()}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
                <div className="modal" id="Success-alert" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"></h4>
                                <button type="button" className="btn close-btn d-none" data-dismiss="modal">
                                    X
                    </button>
                            </div>
                            <div className="modal-body">

                                <div className="row text-center">

                                    <div className="col-md-12 my-3">
                                        <span className="question-mark-icon success-alert-icon"></span>

                                    </div>


                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Success</Trans>!</label>
                                        <label className="confirm-content d-block"><Trans>Payment has been successfully completed</Trans></label>
                                        <label className="confirm-label d-block"><Trans>Order Number</Trans>:<span className="mx-2">{this.state.NonCataOrderNum == "" || this.state.NonCataOrderNum == null ? this.state.PricedOrderNum : this.state.NonCataOrderNum}</span></label>
                                        <label className="confirm-label d-block"><Trans>Reference Number</Trans>:<span className="mx-2">{this.state.ReferenceNumber}</span></label>
                                    </div>


                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.OrderPlaced()}><Trans>Done</Trans></button>
                                    </div>


                                </div>


                            </div>


                        </div>
                    </div>
                </div>
                <div className="modal" id="Cancel-btn">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-4">
                                        <span className="question-mark-icon delete-confirm-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block"><Trans>Are you sure you want to cancel your order?</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal"><Trans>No</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.CancelOrder()}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal orderstatus-popup" id="pdfPreview" data-backdrop="static" data-keyboard="false">
                    <PDFPreviewComponent key={OrderSuccessCOunt} base64URL={this.state.base64URL} PricedOrder={this.state.PricedOrderNum} Nonstock={this.state.NonCataOrderNum} PdfPage="1" pdfTitle={t("Cart Confirm Report")} {...this.props} />
                </div>
                {/*//[5/7/23, PC_ZE_85_APP_10, Dhevanesam R]*/}
                <div className="modal userid" id="ZipCode" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog  custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Zip Code</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.ZipCodeClose()}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-10 float-left my-5">
                                        <label className="float-left col-md-3 form-label catalog-label pl-0"><Trans>Zip Code</Trans></label>
                                        <div className="float-left col-md-9">
                                            <input type="text" className="form-control " placeholder={t("Enter Zip Code")} id="ZipCode_no" maxLength="10" />
                                            <span id="ZipCodeErrorId" className="DropDown-Font-Size errorcolour"><Trans>{this.state.zipCodeValidation}</Trans></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4 mr-2" onClick={() => this.ZipCodeClick()}><Trans>Ok</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="locationaccess" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered" style={{ maxWidth: "578px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Steps to enable location
                                    </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">X</button>
                                {/* Modal body */}
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="text-center my-2">
                                            <p className="text-left info-label text-danger w-100">Note : Please enable the location to use pickup functionality.</p>
                                            <p className="text-left info-label w-100">1.Click  <img src="Images/location-img.png"></img> The location icon in the top-right corner of the browser window.</p>
                                            <p className="text-left info-label w-100">2.Select "Always allow https://sdizeus2test.sdi.com to access your location" </p>
                                            <div className="text-left"><img src="Images/location-access-steps-1.png" style={{ width: "300px" }}></img></div>
                                            <p className="text-left info-label mt-2 w-100">3.Click the "Done" button</p>
                                            <p className="text-left info-label mt-2 w-100">4.To apply your updated settings to this site, reload the page</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-lg-5 px-3" data-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="alertpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="text-center mt-2">
                                            <h2 className="mt-1 fav-item-title"><Trans>Alert</Trans></h2>
                                        </div>
                                        <div className="text-center my-4">
                                            <span className="info-label"><Trans>{this.state.IsMultipleSupplier}</Trans></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" ><Trans>Ok</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="Charge-Code-popup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Charge Code</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            {/*[6/6/22, Bug - 2194, Dhevanesam R]*/}
                            <div className="modal-body">
                                {/*[8/30/22, Bug - 2549, Charge code UI issue fix - Poornima S*/}
                                <div className="float-left mb-2" ><Trans>Charge code for user</Trans>{" " + CookieService.getLocalStorageValues("Session_CONAME")}</div>
                                {/*<div className="float-right TextAlignLast mb-2" ></div>*/}
                                <div className="row mt-3 w-100" style={{"height":"500px"}}>
                                    <div className="col-12 edit-desc-field mb-4">
                                        {this.state.PlaceDD1Data.length > 1 || this.state.PlaceDD1Visible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD1Title}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="PlaceDD1"
                                                    isDisabled={this.state.PlaceDD1_disable}
                                                    className="DropDown-Font-Size"
                                                    value={this.state.PlaceDD1Value}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD1Value")}
                                                    options={this.state.PlaceDD1Data}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.PlaceDD2Data.length > 1 || this.state.PlaceDD2Visible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD2Title}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="PlaceDD2"
                                                    isDisabled={this.state.PlaceDD2_disable}
                                                    className="DropDown-Font-Size"
                                                    value={this.state.PlaceDD2Value}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD2Value")}
                                                    options={this.state.PlaceDD2Data}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.PlaceDD3Data.length > 1 || this.state.PlaceDD3Visible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD3Title}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="PlaceDD3"
                                                    isDisabled={this.state.PlaceDD3_disable}
                                                    className="DropDown-Font-Size"
                                                    value={this.state.PlaceDD3Value}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD3Value")}
                                                    options={this.state.PlaceDD3Data}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.dropCD1SegData.length > 1 || this.state.dropCD1SegVisible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD1SegTitle}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="dropCD1Seg"
                                                    className="DropDown-Font-Size"
                                                    isDisabled={this.state.dropCD1Seg_disable}
                                                    value={this.state.dropCD1SegValue}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "dropCD1SegValue")}
                                                    options={this.state.dropCD1SegData}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.dropCD2SegData.length > 1 || this.state.dropCD2SegVisible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD2SegTitle}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="dropCD2Seg"
                                                    className="DropDown-Font-Size"
                                                    isDisabled={this.state.dropCD2Seg_disable}
                                                    value={this.state.dropCD2SegValue}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "dropCD2SegValue")}
                                                    options={this.state.dropCD2SegData}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.dropCD3SegData.length > 1 || this.state.dropCD3SegVisible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD3SegTitle}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="dropCD3Seg"
                                                    isDisabled={this.state.dropCD3Seg_disable}
                                                    className="DropDown-Font-Size"
                                                    value={this.state.dropCD3SegValue}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "dropCD3SegValue")}
                                                    options={this.state.dropCD3SegData}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.dropEmpSegData.length > 1 || this.state.dropEmpSegVisible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCdropEmpSegTitle}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="dropEmpSeg"
                                                    isDisabled={this.state.dropEmpSeg_disable}
                                                    className="DropDown-Font-Size"
                                                    value={this.state.dropEmpSegValue}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "dropEmpSegValue")}
                                                    options={this.state.dropEmpSegData}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.dropItmSegData.length > 1 || this.state.dropItmSegVisible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCdropItmSegTitle}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="dropItmSeg"
                                                    className="DropDown-Font-Size"
                                                    isDisabled={this.state.dropItmSeg_disable}
                                                    value={this.state.dropItmSegValue}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "dropItmSegValue")}
                                                    options={this.state.dropItmSegData}
                                                />
                                            </div>
                                        </div> : null}
                                        {this.state.dropMchSegData.length > 1 || this.state.dropMchSegVisible == true ? <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>{this.state.CCdropMchSegTitle}</Trans></label>
                                            <div className="col-md-7">
                                                <Select
                                                    id="dropMchSeg"
                                                    isDisabled={this.state.dropMchSeg_disable}
                                                    className="DropDown-Font-Size"
                                                    value={this.state.dropMchSegValue}
                                                    onChange={(e) => this.handleChangePlaceDD1(e, "dropMchSegValue")}
                                                    options={this.state.dropMchSegData}
                                                />
                                            </div>
                                        </div> : null}

                                    </div>
                                    <div className="col-md-12 text-center mb-2 d-none" id="ChargeCodeLabelDiv">
                                        <label id="ChargeCodeLabel">***<Trans>All charge code segments must be selected</Trans>***</label>
                                    </div>

                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" id="ChargeCodeBtn" onClick={() => this.UpdateChargeCode()}><Trans>Submit</Trans></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*[3/15/2023]CAS -44  AddressFieldsModal modal is shows address fields to be filled by the user to validate it*/}
                <div className="modal" id="AddressFieldsModal" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Address</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.closeAddressPopup()}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                {/*[8/30/22, Bug - 2549, Charge code UI issue fix - Poornima S*/}

                                <div className="row mt-3 w-100">
                                    <div className="col-12 edit-desc-field mb-4">
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label">Street</label>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control" id="streetTB" placeholder="Enter street" maxLength="55" value={this.state.streetValue} onChange={(e) => this.onStreetChange(e)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label">Apartment number</label>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control" id="apartmentNoTB" placeholder="Enter apartment #(Optional)" maxLength="20" value={this.state.apartmentNoValue} onChange={(e) => this.onApartmentNoChange(e)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label">City</label>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control" id="cityTB" placeholder="Enter city" maxLength="30" value={this.state.cityValue} onChange={(e) => this.onCityChange(e)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label">State</label>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control" id="stateTB" placeholder="Enter state" maxLength="2" value={this.state.stateValue} onChange={(e) => this.onStateChange(e)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label">Zip code</label>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control" id="zipCodeTB" placeholder="Enter zip code" maxLength="12" value={this.state.zipCodeValue} onChange={(e) => this.OnHandleZipCode(e)} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label">Country</label>
                                            <div className="col-md-7">
                                                {/*<input type="text" className="form-control" id="" placeholder="Enter Country code" maxLength="3" value={this.state.countryValue} onChange={(e) => this.onCountryChange(e)} />*/}
                                                <Select
                                                    id="countryDD"
                                                    name="country"
                                                    className="DropDown-Font-Size"
                                                    onChange={(e) => this.onCountryChange(e)}
                                                    value={this.state.selectCountry}
                                                    defaultValue={countryOptions[0]}
                                                    options={countryOptions}
                                                />     
                                            </div>
                                        </div>
                                        <div className="row mb-3 float-left">
                                            <span className="float-left err-msg text-danger" id="AddressErrorMsg"></span>
                                            </div>

                                    </div>
                                   
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" id="validateAddressID" onClick={() => this.validateAddressClick()}><Trans>Validate</Trans></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*[3/15/2023]CAS -44  AddressModal modal is to show the user typed and google's recommended address with map to user*/}
                <div className="modal" id="AddressModal" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Confirm your address</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div classname="modal-body" style={{"overflow":"inherit"}}>

                                <div className="mt-3 w-100">
                                    <div className="col-12 edit-desc-field mb-4">
                                        <div className="row d-flex align-items-baseline">
                                            <input className="ml-2 mr-2" type="radio" name="radio" checked={this.state.typedAddress} id="userAddressBtn" onChange={() => this.selectedAddress("userAddress")} />
                                            <label className="AddressTitle" id="userAddressLbl">What you entered</label> 
                                        </div>
                                        <div className="row mb-3">                                           
                                            <label className="col-md-12 AddressLine" id="userAddressLbl">{this.state.userSelectedAddress}</label>                                            
                                        </div>
                                        <div className="row align-items-baseline">
                                            <input className="ml-2 mr-2" type="radio" name="radio" checked={this.state.recAddress} id="recomndAddressBtn" onChange={() => this.selectedAddress("recommendedAddress")} />
                                            <label className="AddressTitle" id="userAddressLbl">Recommended</label>
                                        </div>
                                        <div className="row mb-3">                                            
                                            <label className="col-md-12 AddressLine" id="recomndAddressLbl">{this.state.recomendedAddress}</label> 
                                        </div>
										{!isIE ? <div className="row mb-3 height-170">
											<GoogleMaps Latitude={this.state.validLatitude} Longitude={this.state.validLongitude} {...this.props} />
										</div> : null}

                                    </div>

                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" id="confirmUserAddress" onClick={() => this.confirmAddress()}><Trans>Confirm</Trans></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*work order popup starts here*/}

                <div className="modal fade work-order-info" id="work-order-details"   >
                    <div className="modal-dialog custom-work-order-width modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header sp-delv-header">
                                <h6 className="modal-title font-16 font-medium" id="exampleModalLongTitle">Work Order Info</h6>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
                                </button>
                            </div>
                            <div className="modal-body py-4">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label className="font-16 font-medium w-100 color-sec-black">Work Order #</label>
                                        <label className="font-16 font-regular" id="wo-num-id">{this.state.ValidatedWo}</label>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="font-16 font-medium w-100 color-sec-black">Description</label>
                                        <label className="font-16 font-regular" id="wo-desc-id">{this.state.workOrderDescription}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-center border-0 pt-0 pb-4">
                                <button type="button" className="btn custom-btn-primary px-4 font-16 font-medium" data-dismiss="modal">OK</button>
</div>
                        </div>
                    </div>
                </div> 
                <div className="modal userid" id="Fav_Order_Category" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog  custom-popup modal-dialog-centered">
                            <div className="modal-content">
                                {/* Modal Header */}
                                <div className="modal-header">
                                    <h4 className="modal-title mt-1"><Trans>Add to favorite Order</Trans></h4>
                                    <button type="button" className="btn close-btn" data-toggle="modal" data-target="#Fav-confirmation" onClick={() => this.favOrderCategoryClose()}>
                                        X
              </button>
                                </div>
                                {/* Modal body */}
                                <div className="p-3">
                                    <div className="row justify-content-center">
                                        {/*[9/8/22], Bug - 2599 UI is not proper for Add image, Dhevanesam R*/}
                                        <div className="col-md-12 float-left mt-3">
                                            <label className="float-left col-md-3 form-label catalog-label indicate-alert"><Trans>Category</Trans></label>
                                            <div className="float-left search-autocomplete col-md-9 home-search" style={{ zIndex: "9999" }}>
                                                <Autocomplete
                                                    getItemValue={this.getItemValue}
                                                    items={this.state.favOrderCatSugg}
                                                    renderItem={this.renderItem}
                                                    value={this.state.favOrdCat}
                                                    onChange={e => this.onChange(e, "FAVORDERCAT")}
                                                    onSelect={e => this.onSelect(e, "FAVORDERCAT")}
                                                wrapperProps={{ className: "cat-scroll" }}
                                                menuStyle={menuStyle}
                                                    inputProps={{ placeholder: "Search", className: "form-control float-left" }}
                                                />
                                                <span className="errormsg" style={{ fontSize: '14px', paddingRight: '111px' }} id="favordcatErr" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 float-left mt-3">
                                            <label className="float-left col-md-3 form-label catalog-label indicate-alert"><Trans>Sub Category</Trans></label>
                                            <div className="float-left search-autocomplete col-md-9 home-search" >
                                                <Autocomplete
                                                    getItemValue={this.getItemValue}
                                                    items={this.state.favOrdsubCategorySugg}
                                                    renderItem={this.renderItem}
                                                    value={this.state.favOrdSubCat}
                                                    onChange={e => this.onChange(e, "FAVORDERSUBCAT")}
                                                onSelect={e => this.onSelect(e, "FAVORDERSUBCAT")}
                                                wrapperProps={{ className: "cat-scroll" }}
                                                menuStyle={menuStyle}
                                                    inputProps={{ placeholder: "Search", className: "form-control float-left", disabled: this.state.favOrdCat.length > 0 ? false : true }}
                                                />
                                                <span className="errormsg" style={{ fontSize: '14px', paddingRight: '111px' }} id="favordsubcatErr" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 float-left mt-3">
                                            <label className="float-left col-md-3 form-label catalog-label"><Trans>Notes</Trans></label>
                                            <div className="float-left col-md-9">
                                                <textarea className="form-control custom-textarea" id="FavYourDescrip" maxLength="255" rows={5} name="favOrdDes" onChange={(e) => this.handleCatChange(e)} defaultValue={this.state.favOrdDes} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 float-left mt-3">
                                            <label className="float-left col-md-3 form-label catalog-label"><Trans>Add Image</Trans></label>
                                            <div className="col-md-8 custom-file mb-3 p-0" style={{ "marginLeft": "4%" }}>
                                                <div>
                                                    <input type="file" title=" " accept="image/jpeg, image/png" id="customFile" name="filename" 
                                                    onChange={this.handleAttachmentImg} onClick={this.onInputClick} />
                                                    <label className="custom-file-label" htmlFor="customFile"><Trans>Choose File</Trans></label>
                                                    {this.bindAttachmentsImg()}
                                                    <span className="errormsg" id="Favlblerror">{this.state.lblerror}</span>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 float-left mt-3 text-center">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4 mr-2" onClick={() => this.FavOrdCatOK()}><Trans>OK</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="modal" id="Imagealertpopup" data-backdrop="static" data-keyboard="false" >
                        <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                            <div className="modal-content">
                                {/* Modal body */}
                                <div className="modal-body">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12">
                                            <div className="text-center mt-2">
                                                <h2 className="mt-1 fav-item-title">Alert</h2>
                                            </div>
                                            <div className="text-center my-4">
                                                <span className="info-label">Note: You have an option to add image for your Order</span>
                                                <div>
                                                    <span className="info-label">Do you want to add Image?</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.ImgYesClick()}>Yes</button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.AddFavOrder("True")} style={{ marginLeft: "10%" }} >No</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
//[PC_07, 09/07/21, Author - Priyanka D]
export default withTranslation()(ShoppingCart);