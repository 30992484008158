//import { useCookies } from 'react-cookie';

let updateLocalStorageValues = (key, value) => {
    try {
        let data = {};
        if (localStorage.getItem("SDISession"))
            data = JSON.parse(atob(localStorage.getItem("SDISession")));

        data[key] = value;
        localStorage.setItem("SDISession", btoa(JSON.stringify(data)));
        return true;
    } catch (ex) {
        return false;
    }

}

let insertLocalStorageValues = (value) => {
    try {
        localStorage.setItem("SDISession", btoa(JSON.stringify(value)));
        return true;
    } catch (ex) {
        console.log("ex", ex);
        return false;
        
    }
}

let getLocalStorageValues = (key) => {
    try {
       let data = JSON.parse(atob(localStorage.getItem("SDISession")));
        return data[key];
    } catch (ex) {
        console.log("ex", ex);
        return false;
    }
}

let setCookieValues = (key, value, exdays) => {
    try {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = key + "=" + btoa(value) + ";" + expires + ";path=/";
    } catch (ex) {
        return false;
    }
}
let getCookieValue = (cname) => {
    try {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return atob(c.substring(name.length, c.length));
            }
        }
        return "";
    } catch (ex) {
      
        return "";
    }
}
//let removeCookieValues = (key) => {
//    const [removeCookie] = useCookies();
//    try {
//        removeCookie(key);
//    } catch (ex) {
//        return false;
//    }
//}
export { updateLocalStorageValues, insertLocalStorageValues, getLocalStorageValues, setCookieValues, getCookieValue }
