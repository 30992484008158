import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';

class PickingAssigntoStore extends EventEmitter {
    pageLoadResponse = "";
    lineDetailResponse = "";
    pickingAssignToResponse = "";
    exportToExcelResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };

    handlePickingAssignTo = (action) => {
        switch (action.action.type) {
            case "funcPageLoad": {
                this.pageLoadResponse = action.action.response;
                this.emit('funcPageLoad');
                break;
            }
            case "funcLineDetail": {
                this.lineDetailResponse = action.action.response;
                this.emit('funcLineDetail');
                break;
            }
            case "funcPickingAssign": {
                this.pickingAssignToResponse = action.action.response;
                this.emit('funcPickingAssign');
                break;
            }
            case "NOTIFY_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }
}
const objPickingAssigntoStore = new PickingAssigntoStore;
Dispatcher.register(objPickingAssigntoStore.handlePickingAssignTo.bind(objPickingAssigntoStore));
export default objPickingAssigntoStore;
