/**
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Quick item popup component. The component displays the Quick item popup details.
 * 
 * App Name: SDI-Walmart
 * Author: Chandrasekar
 * Created Date: 08/12/2020 
 */
import React, { Component } from 'react';
import * as CookieService from '../CookieService';
/**
 * Ref: QI_PC_05
 * Importing the action and store file and set to an object.
 */
import * as QuickItemAction from '../actions/QuickItemAction';
import QuickItemStore from '../stores/QuickItemStore';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
//[PC_07, 09/09/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
class QuickItemComponent extends Component {
    _isMounted = false;
    /**
    * Ref: QI_PC_07
    * Define the constructor that takes props as parameter
    * 
    * @param props
    */
    constructor(props) {
        super(props);
        this.assignQuickItemData = this.assignQuickItemData.bind(this);
        this.submitQuickItem = this.submitQuickItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitQuickItemResponse = this.submitQuickItemResponse.bind(this);
        /**
        * Ref: QI_PC_08
        * Set the initial values of the state variables
        */
        this.state = {
            lblItemID: "",
            lblItemdescr: "",
            lblitemmfg: "",
            lblitemmfgid: "",
            lblUOM: "",
            txtQOH: "",
            trPrice: "",
            lblItemCurrencyCodeVisible: "",
            lblItemCurrencySymbolVisible: "",
            lblItemCurrencyCode: "",
            lblItemCurrencySymbol: "",
            decPrice: "",
            txtQty: "",
            isLoaded: false,
            qtyValidationMsg: "",
            lblItemSupplier: "" // 12/28/2023 - Zeus 232 - INC0028559 Functionality added to Zeus when ordering Non-Catalog items to search catalog for MFG PN match back to catalog - Johnprinto
        }

    }
    /**
    * Ref: QI_PC_09
    * componentDidMount method will be first method which will be invoked
    * when Quick item entry popup is loaded
    */
    componentDidMount() {
        this._isMounted = true;
        console.log("componentDidMount", this.props);
        if (this.props.cpitemid != "0") {
            let getQuickItemBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                itemid: this.props.itemid,
                cpitemid: this.props.cpitemid,
                Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                Session_ShowPrice: CookieService.getLocalStorageValues("Session_ShowPrice"),
                Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
                Session_WOName: CookieService.getLocalStorageValues("WOName"),
                Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "
            };
            /**
            * Ref: QI_PC_11
            * Invoke the action to get the page load data from API.
            */
            QuickItemAction.getQuickItemDetails(getQuickItemBO);
        }
        /**
        * Ref: QI_PC_10
        * Define the listener methods
        */
        QuickItemStore.on("quickItemData", this.assignQuickItemData);
        QuickItemStore.on("submitQuickItem", this.submitQuickItemResponse);
        //QuickItemStore.on("ShopCartsubmitQuickItem", this.ShopCartsubmitQuickItemResponse);
    }
    /**
    * Ref: QI_PC_14
    * Listener method for submit response
    */
    submitQuickItemResponse = () => {
        let quickItemSubmitRes = JSON.parse(QuickItemStore.submitQuickItem);
        console.log("quickItemSubmitRes", quickItemSubmitRes[0].is_Success);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if(quickItemSubmitRes[0].is_Success == "True"){
            this.props.closePopup(1);
        }else{
            this.props.closePopup(2);
        }
    }
    //ShopCartsubmitQuickItemResponse = () => {
    //    let quickItemSubmitRes = JSON.parse(QuickItemStore.ShopCartsubmitQuickItem);
    //    console.log("quickItemSubmitRes", quickItemSubmitRes);
    //    this.props.closePopup(quickItemSubmitRes);
    //}
    /**
    * Ref: QI_PC_12
    * Submit button click funtion of quick item popup
    */
    submitQuickItem = () => {
        if (this.state.txtQty) {
            this.setState({ qtyValidationMsg: "" });
            let submitQuickItemBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                txtItemID: this.state.lblitemmfgid,// 12/28/2023 - Zeus 232 - INC0028559 Functionality added to Zeus when ordering Non-Catalog items to search catalog for MFG PN match back to catalog - Johnprinto
                intCpitemid: 0,//this.props.cpitemid,
                lblQuickqty: this.state.txtQty,
                Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
                Session_ImageLocPath: CookieService.getLocalStorageValues("Session_ImageLocPath"),
                Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
                Session_WOName: CookieService.getLocalStorageValues("WOName"),
                Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            //if (this.props.pageNo == "2") {
            //    QuickItemAction.ShopCartsubmitQuickItem(submitQuickItemBO);
            //}
            //else {
            QuickItemAction.submitQuickItem(submitQuickItemBO);
            //}

        } else {
            this.setState({ qtyValidationMsg: "Please enter quantity" });
        }
    }
    /**
     * Handle change method for quantity
     * @param e
     */
    handleChange = (e) => {
        debugger
        const re = SDIConstants.quantityRegEX;
        //[6/12/2023, Bug - 3707 & 3683, Dhevanesam R]
        if (e.target.value === '' || re.test(e.target.value)) {
            if (Math.round(e.target.value) != "0" || !e.target.value) {
                this.setState({ txtQty: e.target.value, qtyValidationMsg:"" });
            }
        }
    };
    /**
     * Ref: QI_PC_13
     * Handle change method for quantity
     */
    assignQuickItemData = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let quickItemResponse = JSON.parse(QuickItemStore.quickItemData);
        console.log("quickItemData", quickItemResponse);
        debugger;
        if (quickItemResponse.QuickItem.length > 0) {

            if (quickItemResponse.QuickItem[0].lblItemCurrencySymbol != null && quickItemResponse.QuickItem[0].lblItemCurrencySymbol != undefined && quickItemResponse.QuickItem[0].lblItemCurrencySymbol != "") {
                if (quickItemResponse.QuickItem[0].lblItemCurrencySymbol.trim() == "*") {
                    quickItemResponse.QuickItem[0].lblItemCurrencySymbol = "$";
                }
            }

            let quickItemData = quickItemResponse.QuickItem[0];
            if (this._isMounted) {
                this.setState(quickItemData);
                let Price = parseFloat(quickItemResponse.QuickItem[0].decPrice) ? parseFloat(quickItemResponse.QuickItem[0].decPrice) : 0
                if (Price > 0) {
                    Price = Price.toFixed(2)
                }
                else {
                    Price = quickItemResponse.QuickItem[0].decPrice
                }
                this.setState({ isLoaded: true, decPrice: Price == 0 || Price == 0.0 ? "Price on Request" : Price});
            }
        } else {
            this.props.closePopup(3);
        }
    };
    componentWillUnmount() {
        QuickItemStore.removeListener("quickItemData", this.assignQuickItemData);
        QuickItemStore.removeListener("submitQuickItem", this.submitQuickItemResponse);
        this._isMounted = false;
    };

    render() {
        const { t } = this.props;
        let disabledClass = this.state.isLoaded == false?`custombtn-disabled`: ``;
        return (

            <div className="modal-dialog modal-lg custom-popup modal-dialog-centered" data-backdrop="static" data-keyboard="false">
                <div className="modal-content">
                    {/* Modal Header */}
                    <div className="modal-header">
                        <h4 className="modal-title mt-1"><Trans>Quick Catalog Search</Trans></h4>
                        <button type="button" className="btn close-btn" onClick={() => this.props.closePopup("")}>
                            X
              </button>
                    </div>
                    {/* Modal body */}
                    <div className="modal-body">
                        <div className="row justify-content-center my-4">
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left"><Trans>Item ID</Trans></label>
                                    <label className="info-value col-md-6 p-0 float-left">{this.state.lblitemmfgid}</label>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left"><Trans>Item Description</Trans></label>
                                    <label className="info-value col-md-6 p-0 float-left order-status-workorder">{this.state.lblItemdescr}</label>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left"><Trans>Manufacturer</Trans></label>
                                    <label className="info-value col-md-6 p-0 float-left">{this.state.lblitemmfg}</label>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left"><Trans>Supplier</Trans></label>
                                    <label className="info-value col-md-6 p-0 float-left">{this.state.lblItemSupplier}</label>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left"><Trans>Supplier Part Number</Trans></label>
                                    <label className="info-value col-md-6 p-0 float-left">{this.state.lblItemID}</label>
                                </div>
                            </div>
                            {/*{this.state.trPrice.toLowerCase()==`true`?<div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left">Price</label>
                                    <label className="info-value col-md-6 p-0 float-left">
                                        {this.state.lblItemCurrencySymbolVisible != "" && this.state.lblItemCurrencySymbolVisible != null && this.state.lblItemCurrencySymbolVisible.toLowerCase() == `true`?this.state.lblItemCurrencySymbol + ` `:``}
                                        {this.state.decPrice}
                                        {this.state.lblItemCurrencyCodeVisible != "" && this.state.lblItemCurrencyCodeVisible != null && this.state.lblItemCurrencyCodeVisible.toLowerCase() == `true` ? this.state.lblItemCurrencyCode : ``}
                                    </label>
                                </div>
                            </div>:null}*/}
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left">UOM</label>
                                    <label className="info-value col-md-6 p-0 float-left">{this.state.lblUOM}</label>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left"><Trans>Quantity On Hand</Trans></label>
                                    <label className="info-value col-md-6 p-0 float-left">{this.state.txtQOH}</label>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left"><Trans>Unit Price</Trans></label>
                                    <label className="info-value col-md-6 p-0 float-left">{this.state.decPrice != "Price on Request" ? this.state.lblItemCurrencySymbol + " " + this.state.decPrice + " " + this.state.lblItemCurrencyCode : t("Price on request")}</label>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="col-md-12 float-left">
                                    <label className="info-label col-md-6 p-0 float-left "><Trans>Quantity</Trans></label>
                                    <input type="text" className={`form-control col-md-3 float-left popup-mobile-width ${this.state.qtyValidationMsg != "" ? "border-color-red" : ""}`} value={this.state.txtQty} maxLength="8" onChange={this.handleChange} />
                                    <button type="button" class="btn btn-labeled btn-primary custom-btn-primary primary-btn float-left ml-2 px-2 py-0 mr-2" onClick={this.submitQuickItem}><span class=""><span class="item-cart"></span></span></button>
                                    <span className="col-md-6 p-0 float-right err-msg text-danger"><Trans>{this.state.qtyValidationMsg}</Trans></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
//[PC_07, 09/09/21, Author - Dhevanesam R]
export default withTranslation()(QuickItemComponent);