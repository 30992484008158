import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as CookieService from '../CookieService';
import * as $ from 'jquery';
import * as MenuAction from './MenuAction';

const tokenExpired = async () => {
    try {
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)

    }
};

const pageLoadAction = () => {
    try {
        debugger;
        SDISVC.callGateway(
            SDIConstants.moduleNames.COOP_Form,
            SDIConstants.methodNames.COOP_PageLoad,
            JSON.stringify(""),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "coopPageLoad", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};

const submitClickAction = (submitBO) => {
    try {
        debugger;
        SDISVC.callGateway(
            SDIConstants.moduleNames.COOP_Form,
            SDIConstants.methodNames.COOP_Submit,
            JSON.stringify(submitBO).replaceAll(/'/g, "''"),//Zeus 257 - Fix single quote insertion issue - Johnprinto
            (data) => {
                if (data.success) {
                    debugger
                    if (data.response != "" && data.response) {
                        let contentData = { type: "coopSubmit", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};

const addAttachment = async (files, fileBO) => {
    try {
        SDISVC.uploadFile(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_AttachFiles,
            JSON.stringify(fileBO),
            files,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "coopAttachment", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const removeAttachment = async (removeBO) => {
    try {
        SDISVC.callGateway(
            SDIConstants.moduleNames.COOP_Form,
            SDIConstants.methodNames.COOP_RemoveFile,
            JSON.stringify(removeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "removeAttachment", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export { submitClickAction, removeAttachment, pageLoadAction, addAttachment }