/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/13/2020
 * 
 * Ref: FOM_PC_32
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
class FavOrderMainStore extends EventEmitter {
    pageLoadData = "";
    currentFavPageResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    cartOrderSuccess = "";
    deleteResponse = "";
    shareResponse = "";
    favOrderImageUpload = "";
    /**
     * Ref: FOM_PC_33
     * 
     * Declare the store call that extends "EventsEmitter"
     * Handle the response from action to component
     */
    handlFavOrderMainStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.FavOrder_Pageload: {
                this.pageLoadData = action.action.response;
                this.emit('favOrderload');
                break;
            }
            case "FAVORDER_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "FAVORDER_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVORDER_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVORDER_AddFavOrderItemsToCart": {
                this.cartOrderSuccess = action.action.response;
                this.emit('addFavOrder');
                break;
            }
            case SDIConstants.methodNames.FavOrder_RemoveFavOrder: {
                this.deleteResponse = action.action.response;
                this.emit('dltFavOrder');
                break;
            }
            case SDIConstants.methodNames.FavOrder_Share: {
                this.shareResponse = action.action.response;
                this.emit('shareResponse');
                break;
            }
            case "favOrderImageUpload": {
                this.favOrderImageUpload = action.action.response;
                this.emit('favOrderImageUpload');
                break;
            }
        }
    }
}
const objFavOrderMainStore = new FavOrderMainStore;
// Dispatcher code
Dispatcher.register(objFavOrderMainStore.handlFavOrderMainStore.bind(objFavOrderMainStore));
export default objFavOrderMainStore;