import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';

class ExpressPutAwayStore extends EventEmitter {
    pageLoadResponse = "";
    submitResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    /**         
    * PS_ZE_265_APP_10/Sathis/03/28/2024
    * This method invokes to handle express put away store.
    * */
    handleExpressPutAwayStore = (action) => {
        switch (action.action.type) {
            case "expPageLoad": {
                this.pageLoadResponse = action.action.response;
                debugger;
                this.emit('expPageLoad');
                break;
            }
            case "expSubmit": {
                this.submitResponse = action.action.response;
                debugger;
                this.emit('expSubmit');
                break;
            }
            case "NOTIFY_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }
}
const objExpressPutAwayStore = new ExpressPutAwayStore;
Dispatcher.register(objExpressPutAwayStore.handleExpressPutAwayStore.bind(objExpressPutAwayStore));
export default objExpressPutAwayStore;