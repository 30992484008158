import React, { Component } from 'react';
import * as $ from 'jquery';
import { withTranslation, Trans } from 'react-i18next';
import Select from 'react-select';
import swal from 'sweetalert';
import SDIConstants from '../SDIConstants';
import CoopFormPDFComponent from '../components/CoopFormPDFComponent'
import NonCatalogStore from '../stores/NonCatalogStore';
import * as NonCatalogAction from '../actions/NonCatalogAction';
import CoopFormStore from '../stores/CoopFormStore';
import * as CoopFormAction from '../actions/CoopFormAction';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
const countryOptions = [
    {
        value: 'US', label: 'US'
    },
    {
        value: 'CA', label: 'CA'
    },
    {
        value: 'MX', label: 'MX'
    }
]
var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
class CoopFormComponent extends Component {
    constructor(props) {
        super(props);
        this.bindShipToField = this.bindShipToField.bind(this);
        this.onChangeBillToZipCode = this.onChangeBillToZipCode.bind(this);
        this.pageLoad = this.pageLoad.bind(this);
        this.pageLoadResponse = this.pageLoadResponse.bind(this);
        this.state = {
            clientName: '',
            industry: { label: 'Select', value: '' },
            industryOther: '',
            industryDD: [{ label: 'Food and Beverage', value: 'Food and Beverage' },
            { label: 'Consumer Packaged Goods', value: 'Consumer Packaged Goods' },
            { label: 'Transportation/Utilities', value: 'Transportation/Utilities' },
            { label: 'Petro/Specialty Chem', value: 'Petro/Specialty Chem' }
                , { label: 'Private Equity', value: 'Private Equity' }
                , { label: 'Healthcare/Pharma', value: 'Healthcare/Pharma' }
                , { label: 'Education', value: 'Education' }
                , { label: 'Automotive', value: 'Automotive' }
                , { label: 'Industrial Equipment Mfg', value: 'Industrial Equipment Mfg' }
                , { label: 'Multi-site Retail/Restaurant', value: 'Multi-site Retail/Restaurant' }
                , { label: 'Others', value: 'Others' }
            ],
            industryErr: false,
            primaryName: '',
            primaryTitle: '',
            primaryEmail: '',
            primaryEmailErr: '',
            primaryPhoneNo: '',
            primaryPhoneNoErr: '',
            executiveName: '',
            executiveTitle: '',
            executiveEmail: '',
            executiveEmailErr: '',
            executivePhoneNo: '',
            executivePhoneNoErr: '',
            accPayableName: '',
            accPayableTitle: '',
            accPayableEmail: '',
            accPayableEmailErr: '',
            accPayablePhoneNo: '',
            accPayablePhoneNoErr: '',
            taxExempt: { label: 'Select', value: '' },
            taxExemptDD: [{ label: 'Select', value: '' }, { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }, { label: 'Partial', value: 'Partial' }],
            W9: "",
            companyRevenue: { label: 'Select', value: '' },
            estMRO: { label: 'Select', value: '' },
            revenueMRODD: [{ label: 'Select', value: '' }, { label: '<$100k', value: '<$100k' }, { label: '$100k-$500k', value: '$100k-$500k' }, { label: '$500k-$1M', value: '$500k-$1M' }, { label: '$1M-$5M', value: '$1M-$5M' },
            { label: '$5M-$20M', value: '$5M-$20M' }, { label: '$20-$50M', value: '$20-$50M' }, { label: '$50M-$100M', value: '$50M-$100M' }, { label: '$100M-$500M', value: '$100M-$500M' },
            { label: '$500M-$1B', value: '$500M-$1B' }, { label: '>$1B', value: '>$1B' }],
            attachMentPDF: { FileName: '', FilePath: '' },
            attachMentTax: { FileName: '', FilePath: '' },
            attachMentW9: { FileName: '', FilePath: '' },
            attachMentFlag: 1,
            coopID: '',
            shipToAddress: [{
                shipToStreet: '', shipToStreetErr: false,
                shipToApartmentNo: '',
                shipToCity: '', shipToCityErr: false,
                shipToState: '', shipToStateErr: false,
                shipToStateOptions: { label: '', value: '' },
                shipToPostal: '', shipToPostalErr: false,
                shipToCountry: { label: 'US', value: 'US' }, index: 0
            }],
            shipToCount: 1,
            billToStreet: '', billToApartmentNo: '', billToCity: '', billToState: '', billToStateErr: false, billToPostal: '', billToCountry: { label: 'US', value: 'US' },
            billToCountryErr: '', primaryPhNoExtension: "", executivePhNoExtension: "", accPhNoExtension: "", primaryPhNoCC: "1",
            executivePhNoCC: "1", accPhNoCC: "1"

        }

    }
    componentDidMount() {
        this.pageLoad();
        //Zeus 257 - Adding google tag manger - Johnprinto
        const script = document.createElement('script');
        script.innerHTML = `(function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KPC4C4H');`;
        const firstHeadElement = document.head.firstChild
        document.head.insertBefore(script, firstHeadElement);
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KPC4C4H" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        const firstBodyElement = document.body.firstChild
        document.body.insertBefore(noscript, firstBodyElement);

        CoopFormStore.on('pageLoadResponse', this.pageLoadResponse);
        CoopFormStore.on('attachmentResponse', this.loadAttachments);
        CoopFormStore.on('removeCoopAttachment', this.removeAttachmentResponse);
        CoopFormStore.on('submitResponse', this.submitBtnClickResponse);
    }
    componentWillUnmount() {
        CoopFormStore.removeListener('pageLoadResponse', this.pageLoadResponse);
        CoopFormStore.removeListener('attachmentResponse', this.loadAttachments);
        CoopFormStore.removeListener('removeCoopAttachment', this.removeAttachmentResponse);
        CoopFormStore.removeListener('submitResponse', this.submitBtnClickResponse);

    }

    pageLoad = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        CoopFormAction.pageLoadAction();
    }

    pageLoadResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let response = JSON.parse(CoopFormStore.pageLoadData);
        let errorDt = response.errorDt;
        if (errorDt[0].status == "success") {
            let stateDt = response.stateDt;
            if (stateDt != null && stateDt != undefined) {
                if (stateDt.length > 0) {
                    let stateOptionsUSADD = [];
                    let stateOptionsMEXDD = [];
                    let stateOptionsCADD = [];
                    let stateOptionsUSA = stateDt.filter((element, index) => element.COUNTRY == "USA");
                    stateOptionsUSA.forEach((value, index) => {
                        let stateObj = {
                            label: value.STATE,
                            value: value.STATE
                        }
                        stateOptionsUSADD.push(stateObj);
                    })
                    let stateOptionsMEX = stateDt.filter((element, index) => element.COUNTRY == "MEX");
                    stateOptionsMEX.forEach((value, index) => {
                        let stateObj = {
                            label: value.STATE,
                            value: value.STATE
                        }
                        stateOptionsMEXDD.push(stateObj);
                    })
                    let stateOptionsCA = stateDt.filter((element, index) => element.COUNTRY == "CAN");
                    stateOptionsCA.forEach((value, index) => {
                        let stateObj = {
                            label: value.STATE,
                            value: value.STATE
                        }
                        stateOptionsCADD.push(stateObj);
                    })
                    let tempArray = this.state.shipToAddress;
                    tempArray[0].shipToStateOptions = stateOptionsUSADD;
                    this.setState({ stateOptionsUSADD, stateOptionsMEXDD, stateOptionsCADD, billToStateOptions: stateOptionsUSADD, shipToAddress: tempArray })
                }
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Techincal issue please contact SDI help desk"
            });
        }

    }

    handleChange = (event) => {

        let locprimaryEmailErr = this.state.primaryEmailErr;
        let locprimaryPhoneNoErr = this.state.primaryPhoneNoErr;
        let locexecutiveEmailErr = this.state.executiveEmailErr;
        let locexecutivePhoneNoErr = this.state.executivePhoneNoErr;
        let locaccPayableEmailErr = this.state.accPayableEmailErr;
        let locaccPayablePhoneNoErr = this.state.accPayablePhoneNoErr;
        if (event.target.value.trim() == "") {
            event.target.value = "";
        }
        switch (event.target.id) {
            case "primaryEmail":
                if (!pattern.test(event.target.value)) {
                    locprimaryEmailErr = "Please enter valid email address";
                    $(`#${event.target.id}`).addClass('border-color-red')
                } else {
                    locprimaryEmailErr = "";
                    $(`#${event.target.id}`).removeClass('border-color-red')
                }
                break;
            case "primaryPhoneNo":
                event.target.value = event.target.value.trim();
                if (isNaN(event.target.value)) {
                    locprimaryPhoneNoErr = "Please enter valid phone number";
                    $(`#${event.target.id}`).addClass('border-color-red')
                    event.target.value = this.state.primaryPhoneNo;
                } else {
                    if (event.target.value.length < 10) {
                        locprimaryPhoneNoErr = "Please enter valid phone number";
                        $(`#${event.target.id}`).addClass('border-color-red')
                    } else {
                        locprimaryPhoneNoErr = "";
                        $(`#${event.target.id}`).removeClass('border-color-red')
                    }
                }
                break;
            case "primaryPhNoExtension":
                event.target.value = event.target.value.trim();
                if (isNaN(event.target.value)) {
                    event.target.value = this.state.primaryPhNoExtension;
                }
                break;
            case "executivePhNoExtension":
                event.target.value = event.target.value.trim();
                if (isNaN(event.target.value)) {
                    event.target.value = this.state.executivePhNoExtension;
                }
                break;
            case "accPhNoExtension":
                event.target.value = event.target.value.trim();
                if (isNaN(event.target.value)) {
                    event.target.value = this.state.accPhNoExtension;
                }
                break;
            case "executiveEmail":
                if (!pattern.test(event.target.value)) {
                    locexecutiveEmailErr = "Please enter valid email address";
                    $(`#${event.target.id}`).addClass('border-color-red')
                } else {
                    locexecutiveEmailErr = "";
                    $(`#${event.target.id}`).removeClass('border-color-red')
                }
                break;
            case "executivePhoneNo":
                event.target.value = event.target.value.trim();
                if (isNaN(event.target.value)) {
                    locexecutivePhoneNoErr = "Please enter valid phone number";
                    $(`#${event.target.id}`).addClass('border-color-red')
                    event.target.value = this.state.executivePhoneNo;
                } else {
                    if (event.target.value.length < 10) {
                        locexecutivePhoneNoErr = "Please enter valid phone number";
                        $(`#${event.target.id}`).addClass('border-color-red')
                    } else {
                        locexecutivePhoneNoErr = "";
                        $(`#${event.target.id}`).removeClass('border-color-red')
                    }
                }
                break;
            case "accPayableEmail":
                if (!pattern.test(event.target.value)) {
                    locaccPayableEmailErr = "Please enter valid email address";
                    $(`#${event.target.id}`).addClass('border-color-red')
                } else {
                    locaccPayableEmailErr = "";
                    $(`#${event.target.id}`).removeClass('border-color-red')
                }
                break;
            case "accPayablePhoneNo":
                event.target.value = event.target.value.trim();
                if (isNaN(event.target.value)) {
                    locaccPayablePhoneNoErr = "Please enter valid phone number";
                    $(`#${event.target.id}`).addClass('border-color-red')
                    event.target.value = this.state.accPayablePhoneNo;
                } else {
                    if (event.target.value.length < 10) {
                        locaccPayablePhoneNoErr = "Please enter valid phone number";
                        $(`#${event.target.id}`).addClass('border-color-red')
                    } else {
                        locaccPayablePhoneNoErr = "";
                        $(`#${event.target.id}`).removeClass('border-color-red')
                    }
                }
                break;
            default:
                if (event.target.value.trim() != "") {
                    $(`#${event.target.id}`).removeClass('border-color-red')
                }
                break;
        }
        this.setState({
            [event.target.id]: event.target.value, primaryEmailErr: locprimaryEmailErr,
            primaryPhoneNoErr: locprimaryPhoneNoErr, executiveEmailErr: locexecutiveEmailErr,
            executivePhoneNoErr: locexecutivePhoneNoErr, accPayableEmailErr: locaccPayableEmailErr,
            accPayablePhoneNoErr: locaccPayablePhoneNoErr
        });
    };
    bindShipToField = () => {
        return this.state.shipToAddress.map((value, index) => {
            return <>
                <div className="col-md-10 mb-3 mt-3">
                    <label className="catalog-label form-label">Address {index + 1}</label>
                </div>
                {index != 0 ?
                    // <button class="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" data-backdrop="static" data-keyboard="false"
                    // onClick={()=>this.rmvShipToField(index)}>
                    //     <img src="Images/remove-order.svg" class="removeorder-icon" alt="Remove address"/> </button>



                    <div class="col-md-2 float-left mt-3">
                        <div class="float-right mobile-catalog-btns">
                            <div class="d-inline justify-content-between align-items-center">
                                <button class="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" data-backdrop="static" data-keyboard="false"
                                    onClick={() => this.rmvShipToField(index)}
                                >
                                    <img src="Images/remove-order.svg" class="removeorder-icon" alt="Remove Order" /> Remove</button>
                            </div>
                        </div>
                    </div>
                    : null}
                <div className="col-md-4 mb-3">
                    <label className="catalog-label form-label mandatory-after ">Street</label>
                    <div className="">
                        <input type="text" className={`form-control ${this.state.shipToAddress[index].shipToStreetErr ? 'border-color-red' : ""}`} id={`shipToStreet${value.index}`} name='shipToStreet' placeholder="Enter street" maxLength="55" value={this.state.shipToAddress[index].shipToStreet} onChange={(e) => this.onChangeShipTo(e, index, 'shipToStreet')} />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label className="catalog-label form-label">Apartment No</label>
                    <div className="">
                        <input type="text" className={`form-control`} id={`shipToApartmentNo${value.index}`} name='shipToApartmentNo' placeholder="Enter apartment #(Optional)" maxLength="20" value={this.state.shipToAddress[index].shipToApartmentNo} onChange={(e) => this.onChangeShipTo(e, index, 'shipToApartmentNo')} />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label className="catalog-label form-label mandatory-after ">City</label>
                    <div className="">
                        <input type="text" className={`form-control ${this.state.shipToAddress[index].shipToCityErr ? 'border-color-red' : ""}`} id={`shipToCity${value.index}`} name='shipToCity' placeholder="Enter city" maxLength="30" value={this.state.shipToAddress[index].shipToCity} onChange={(e) => this.onChangeShipTo(e, index, 'shipToCity')} />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label className="catalog-label form-label mandatory-after w-100">Country</label>
                    <div className="p-0">
                        <Select
                            id={`shipToCountry${value.index}`}
                            name="shipToCountry"
                            className="DropDown-Font-Size"
                            onChange={(e) => this.handleShipToDDChange(e, index, "shipToCountry")}
                            value={this.state.shipToAddress[index].shipToCountry}
                            defaultValue={countryOptions[0]}
                            options={countryOptions}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label className="catalog-label form-label mandatory-after w-100">State</label>
                    <div className="p-0">
                        <Select
                            id={`shipToState${value.index}`}
                            name="shipToState"
                            className="DropDown-Font-Size"
                            onChange={(e) => this.handleShipToDDChange(e, index, "shipToState")}
                            value={this.state.shipToAddress[index].shipToState}
                            options={this.state.shipToAddress[index].shipToStateOptions}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    borderColor: this.state.shipToAddress[index].shipToStateErr ? "#FF0000" : "#ced4da",
                                    boxShadow: this.state.shipToAddress[index].shipToStateErr && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                    "&:hover": {
                                        ...provided,
                                        borderColor: this.state.shipToAddress[index].shipToStateErr ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                    }
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label className="catalog-label form-label mandatory-after ">Zip code</label>
                    <div className="">
                        <input type="text" className={`form-control ${this.state.shipToAddress[index].shipToPostalErr ? 'border-color-red' : ""}`} id={`shipToPostal${value.index}`} name='shipToPostal' placeholder="Enter zip code" maxLength="6" value={this.state.shipToAddress[index].shipToPostal} onChange={(e) => this.onChangeShipTo(e, index, 'shipToPostal')} />
                    </div>
                </div>

            </>
        })
    }
    clearFormValues = () => {
        this.setState({
            clientName: '',
            industry: { label: 'Select', value: '' },
            industryErr: false,
            primaryName: '',
            primaryTitle: '',
            primaryEmail: '',
            primaryPhoneNo: '',
            executiveName: '',
            executiveTitle: '',
            executiveEmail: '',
            executivePhoneNo: '',
            accPayableName: '',
            accPayableTitle: '',
            accPayableEmail: '',
            accPayablePhoneNo: '',
            taxExempt: { label: 'Select', value: ' ' },
            W9: "",
            companyRevenue: { label: 'Select', value: ' ' },
            estMRO: { label: 'Select', value: ' ' },
            attachMentPDF: '',
            attachMentTax: { FileName: '' },
            attachMentW9: { FileName: '' },
            attachMentFlag: 1,
            industryOther: '',
            shipToAddress: [{
                shipToStreet: '', shipToStreetErr: false,
                shipToApartmentNo: '',
                shipToCity: '', shipToCityErr: false,
                shipToState: '', shipToStateErr: false,
                shipToStateOptions: this.state.stateOptionsUSADD,
                shipToPostal: '', shipToPostalErr: false,
                shipToCountry: { label: 'US', value: 'US' },
            }],
            billToStreet: '', billToApartmentNo: '', billToCity: '', billToState: '', billToPostal: '', billToCountry: { label: 'US', value: 'US' },
            primaryPhNoExtension: "", executivePhNoExtension: "", accPhNoExtension: "", primaryPhNoCC: "1", billToStateOptions: this.state.stateOptionsUSADD,
            executivePhNoCC: "1", accPhNoCC: "1"
        });
        $('#attachMentTaxErr').addClass('hide')
        $('#attachMentW9Err').addClass('hide')
        $('#industryOtherField').addClass('d-none');

    };
    addNewShipToField = () => {
        let tempArray = this.state.shipToAddress;
        console.log("tempArray.length = ", tempArray.length)
        console.log("tempArray = ", tempArray)
        let fiedObj = {
            shipToStreet: '', shipToStreetErr: false,
            shipToApartmentNo: '',
            shipToCity: '', shipToCityErr: false,
            shipToState: '', shipToStateErr: false,
            shipToStateOptions: this.state.stateOptionsUSADD,
            shipToPostal: '', shipToPostalErr: false,
            shipToCountry: { label: 'US', value: 'US' },
            index: this.state.shipToCount
        }
        tempArray.push(fiedObj);
        this.setState({ shipToAddress: tempArray, shipToCount: this.state.shipToCount + 1 })
    }
    rmvShipToField = (rvmIndex) => {
        let tempArray = this.state.shipToAddress;
        tempArray.splice(rvmIndex, 1);
        this.setState({ shipToAddress: tempArray })
    }
    onChangeShipTo = (e, index, name) => {
        if (name == "shipToPostal") {
            let strZipCode = e.target.value.toUpperCase();
            const re = SDIConstants.zipcodeRegEx;
            //11/10/2023 4360 - Bug fix - Remove the border color when user enter the zip code - Johnprinto 
            if (re.test(strZipCode) || strZipCode === '') {
                let tempArray = this.state.shipToAddress;
                let errString = name + "Err";
                tempArray[index][name] = strZipCode;
                tempArray[index][errString] = false;
                $(`#${e.target.id}`).removeClass('border-color-red')
                this.setState({ shipToAddress: tempArray })
            }
        }
        else {
            if (e.target.value != " ") {
                let tempArray = this.state.shipToAddress;
                let errString = name + "Err";
                tempArray[index][name] = e.target.value;
                tempArray[index][errString] = false;
                $(`#${e.target.id}`).removeClass('border-color-red')
                this.setState({ shipToAddress: tempArray })
            }
        }
    }
    handleShipToDDChange = (e, index, name) => {
        let tempArray = this.state.shipToAddress;
        if (name == "shipToCountry") {
            let value = e.value
            let stateOptionsDD = value == "US" ? this.state.stateOptionsUSADD : value == "CA" ? this.state.stateOptionsCADD : this.state.stateOptionsMEXDD
            tempArray[index].shipToStateOptions = stateOptionsDD;
            tempArray[index].shipToCountry = e;
            tempArray[index].shipToState = "";
        }
        else if (name == "shipToState") {

            tempArray[index].shipToState = e;
            tempArray[index].shipToStateErr = false;
        }
        else {
            tempArray[index].shipToState = e;
        }
        this.setState({ shipToAddress: tempArray })
    }
    handleDDChange = (event, name) => {
        if (name == 'industry') {
            if (event.value.toLowerCase() == 'others') {
                $('#industryOtherField').removeClass('d-none');
            } else {
                $('#industryOtherField').addClass('d-none');
            }
            $('#industryOther').removeClass('border-color-red');
            this.setState({ [name]: event, industryErr: false, industryOther: '' });
        }
        else if (name == "billToCountry") {
            let value = event.value
            let stateOptionsDD = value == "US" ? this.state.stateOptionsUSADD : value == "CA" ? this.state.stateOptionsCADD : this.state.stateOptionsMEXDD
            this.setState({ [name]: event, billToStateOptions: stateOptionsDD, billToState: "" })
        }
        else if (name == "billToState") {

            $('#billToState').removeClass('border-color-red');
            this.setState({ [name]: event, billToStateErr: false });
        }
        else {
            this.setState({ [name]: event });
        }
    }
    onChangeBillToZipCode = (e) => {
        let strZipCode = e.target.value.toUpperCase();
        const re = SDIConstants.zipcodeRegEx;
        if (re.test(strZipCode) || strZipCode === '') {
            //11/10/2023 4360 - Bug fix - Remove the border color when user enter the zip code - Johnprinto 
            $('#billToPostal').removeClass('border-color-red');
            this.setState({ billToPostal: strZipCode })
        }
    }
    errDisplay = () => {
        const elementsWithSameName = document.querySelectorAll('[name="reqform"]');
        let hasReqFormClass = Array.from(elementsWithSameName).some(element => element.classList.contains('border-color-red'));
        console.log("Ele", hasReqFormClass)
        this.state.shipToAddress.forEach((element, index) => {
            if (element.shipToStreetErr || element.shipToCityErr || element.shipToStateErr
                || element.shipToPostalErr) {
                hasReqFormClass = true;
                return;
            }
        });
        if (hasReqFormClass || this.state.industryErr) {
            return "Please fill all the required fields";
        } else {
            return "";
        }
    }
    submitBtnClick = () => {
        const { t } = this.props;
        let locIndustryErr = "";
        let erroFlag = false;
        let locStateErr = "";

        let locprimaryEmailErr = this.state.primaryEmailErr;
        let locprimaryPhoneNoErr = this.state.primaryPhoneNoErr;
        let locexecutiveEmailErr = this.state.executiveEmailErr;
        let locexecutivePhoneNoErr = this.state.executivePhoneNoErr;
        let locaccPayableEmailErr = this.state.accPayableEmailErr;
        let locaccPayablePhoneNoErr = this.state.accPayablePhoneNoErr;
        if (this.state.clientName == "") {
            $("#clientName").addClass('border-color-red')
            // $('#clientName').focus();
            erroFlag = true;
        } else {
            $("#clientName").removeClass('border-color-red')
        }
        if (this.state.industry.value == "") {
            erroFlag = true;
            locIndustryErr = true
        } else {
            locIndustryErr = false
            if (this.state.industry.value.toLowerCase() == "others" && this.state.industryOther == "") {
                $("#industryOther").addClass('border-color-red')
                erroFlag = true;
            }
        }
        if (this.state.primaryName == "") {
            $("#primaryName").addClass('border-color-red')
            // if(!erroFlag){
            //     $('#primaryName').focus();
            // }
            erroFlag = true;
        } else {
            $("#primaryName").removeClass('border-color-red')
        }
        if (this.state.primaryTitle == "") {
            // if(!erroFlag){
            //     $('#primaryTitle').focus();
            // }
            $("#primaryTitle").addClass('border-color-red')
            erroFlag = true;
        } else {
            $("#primaryTitle").removeClass('border-color-red')
        }
        if (this.state.primaryEmail == "") {
            // if(!erroFlag){
            //     $('#primaryEmail').focus();
            // }
            $("#primaryEmail").addClass('border-color-red')
            erroFlag = true;
        } else {
            if (!pattern.test(this.state.primaryEmail)) {
                locprimaryEmailErr = "Please enter valid email address";
                $("#primaryEmail").addClass('border-color-red')
                // if(!erroFlag){
                //     $('#primaryEmail').focus();
                // }
                erroFlag = true;
            } else {
                locprimaryEmailErr = "";
                $("#primaryEmail").removeClass('border-color-red')
            }
        }
        if (this.state.primaryPhoneNo == "") {
            // if(!erroFlag){
            //     $('#primaryPhoneNo').focus();
            // }
            $("#primaryPhoneNo").addClass('border-color-red')
            erroFlag = true;
        } else {
            if (this.state.primaryPhoneNo.length < 10) {
                locprimaryPhoneNoErr = "Please enter valid phone number";
                $("#primaryPhoneNo").addClass('border-color-red')
                // if(!erroFlag){
                //     $('#primaryPhoneNo').focus();
                // }
                erroFlag = true;
            } else {
                locprimaryPhoneNoErr = "";
                $("#primaryPhoneNo").removeClass('border-color-red')
            }
        }
        if (this.state.executiveName == "") {
            $("#executiveName").addClass('border-color-red')
            // if(!erroFlag){
            //     $('#executiveName').focus();
            // }
            erroFlag = true;
        } else {
            $("#executiveName").removeClass('border-color-red')
        }
        if (this.state.executiveTitle == "") {
            $("#executiveTitle").addClass('border-color-red')
            // if(!erroFlag){
            //     $('#executiveTitle').focus();
            // }
            erroFlag = true;
        } else {
            $("#executiveTitle").removeClass('border-color-red')
        }
        if (this.state.executiveEmail == "") {
            $("#executiveEmail").addClass('border-color-red')
            // if(!erroFlag){
            //     $('#executiveEmail').focus();
            // }
            erroFlag = true;
        } else {
            if (!pattern.test(this.state.executiveEmail)) {
                locexecutiveEmailErr = "Please enter valid email address";
                $("#executiveEmail").addClass('border-color-red')
                // if(!erroFlag){
                //     $('#executiveEmail').focus();
                // }
                erroFlag = true;
            } else {
                locexecutiveEmailErr = "";
                $("#executiveEmail").removeClass('border-color-red')
            }
        }
        if (this.state.executivePhoneNo == "") {
            $("#executivePhoneNo").addClass('border-color-red')
            // if(!erroFlag){
            //     $('#executivePhoneNo').focus();
            // }
            erroFlag = true;
        } else {
            if (this.state.executivePhoneNo.length < 10) {
                locexecutivePhoneNoErr = "Please enter valid phone number";
                $("#executivePhoneNo").addClass('border-color-red')
                // if(!erroFlag){
                //     $('#executivePhoneNo').focus();
                // }
                erroFlag = true;
            } else {
                locexecutivePhoneNoErr = "";
                $("#executivePhoneNo").removeClass('border-color-red')
            }
        }
        if (this.state.accPayableName == "") {
            // if(!erroFlag){
            //     $('#accPayableName').focus();
            // }
            $("#accPayableName").addClass('border-color-red')
            erroFlag = true;
        } else {
            $("#accPayableName").removeClass('border-color-red')
        }
        if (this.state.accPayableTitle == "") {
            // if(!erroFlag){
            //     $('#accPayableTitle').focus();
            // }
            $("#accPayableTitle").addClass('border-color-red')
            erroFlag = true;
        } else {
            $("#accPayableTitle").removeClass('border-color-red')
        }
        if (this.state.accPayableEmail == "") {
            // if(!erroFlag){
            //     $('#accPayableEmail').focus();
            // }
            $("#accPayableEmail").addClass('border-color-red')
            erroFlag = true;
        } else {
            if (!pattern.test(this.state.accPayableEmail)) {
                locaccPayableEmailErr = "Please enter valid email address";
                $("#accPayableEmail").addClass('border-color-red')
                // if(!erroFlag){
                //     $('#accPayableEmail').focus();
                // }
                erroFlag = true;
            } else {
                locaccPayableEmailErr = "";
                $("#accPayableEmail").removeClass('border-color-red')
            }
        }
        if (this.state.accPayablePhoneNo == "") {
            // if(!erroFlag){
            //     $('#accPayablePhoneNo').focus();
            // }
            $("#accPayablePhoneNo").addClass('border-color-red')
            erroFlag = true;
        } else {
            if (this.state.accPayablePhoneNo.length < 10) {
                locaccPayablePhoneNoErr = "Please enter valid phone number";
                $("#accPayablePhoneNo").addClass('border-color-red')
                // if(!erroFlag){
                //     $('#accPayablePhoneNo').focus();
                // }
                erroFlag = true;
            } else {
                locaccPayablePhoneNoErr = "";
                $("#accPayablePhoneNo").removeClass('border-color-red')
            }
        }

        let tempShipToAddress = this.state.shipToAddress;
        tempShipToAddress.forEach((element, index) => {
            if (element.shipToStreet == "") {
                // if(!erroFlag){
                //     $(`shipToStreet${element.index}`).focus();
                // }
                erroFlag = true;
                tempShipToAddress[index].shipToStreetErr = true;
                // $(`#shipToStreet${element.index}`).addClass('border-color-red');
            } else {
                tempShipToAddress[index].shipToStreetErr = false;
                // $(`#shipToStreet${element.index}`).removeClass('border-color-red');
            }
            if (element.shipToCity == "") {
                // if(!erroFlag){
                //     $(`shipToCity${element.index}`).focus();
                // }
                erroFlag = true;
                tempShipToAddress[index].shipToCityErr = true;
                // $(`#shipToCity${element.index}`).addClass('border-color-red');
            } else {
                tempShipToAddress[index].shipToCityErr = false;
                // $(`#shipToCity${element.index}`).removeClass('border-color-red');
            }
            if (element.shipToState == "") {
                // if(!erroFlag){
                //     $(`shipToState${element.index}`).focus();
                // }
                erroFlag = true;
                tempShipToAddress[index].shipToStateErr = true;
                // $(`#shipToState${element.index}`).addClass('border-color-red');
            } else {
                tempShipToAddress[index].shipToStateErr = false;
                // $(`#shipToState${element.index}`).removeClass('border-color-red');
            }
            if (element.shipToPostal == "") {
                // if(!erroFlag){
                //     $(`shipToPostal${element.index}`).focus();
                // }
                erroFlag = true;
                tempShipToAddress[index].shipToPostalErr = true;
                // $(`#shipToPostal${element.index}`).addClass('border-color-red');
            } else {
                tempShipToAddress[index].shipToPostalErr = false;
                // $(`#shipToPostal${element.index}`).removeClass('border-color-red');
            }
        });
        if (this.state.billToStreet == "") {
            // if(!erroFlag){
            //     $('#billToStreet').focus();
            // }
            erroFlag = true;
            $("#billToStreet").addClass('border-color-red')
        } else {
            $("#billToStreet").removeClass('border-color-red')
        }
        if (this.state.billToCity == "") {
            // if(!erroFlag){
            //     $('#billToCity').focus();
            // }
            $("#billToCity").addClass('border-color-red')
            erroFlag = true;
        } else {
            $("#billToCity").removeClass('border-color-red')
        }

        if (this.state.billToState == "") {
            erroFlag = true;
            locStateErr = true
        } else {
            locStateErr = false
        }
        if (this.state.billToPostal == "") {
            // if(!erroFlag){
            //     $('#billToPostal').focus();
            // }
            $("#billToPostal").addClass('border-color-red')
            erroFlag = true;
        } else {
            $("#billToPostal").removeClass('border-color-red')
        }
        if (!erroFlag) {
            $('#saveBtn').trigger('click');
        }
        this.setState({
            industryErr: locIndustryErr, primaryEmailErr: locprimaryEmailErr,
            shipToAddress: tempShipToAddress,
            primaryPhoneNoErr: locprimaryPhoneNoErr, executiveEmailErr: locexecutiveEmailErr,
            executivePhoneNoErr: locexecutivePhoneNoErr, accPayableEmailErr: locaccPayableEmailErr,
            accPayablePhoneNoErr: locaccPayablePhoneNoErr, billToStateErr: locStateErr
        });

    };

    submitBtnClickResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let response = JSON.parse(CoopFormStore.submitData);
        response = response.responseDt;
        if (response[0].status == "success") {
            this.setState({ coopID: response[0].coopID });
            $('#Coop-Submit').modal('show');
            this.clearFormValues();
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Techincal issue please contact SDI help desk"
            });
        }

    };
    handleAttachment = (event, flag) => {
        const { t } = this.props;
        let files
        let fileBO = {
            filUpload: "fileBO"
        }
        if (flag != 1) {
            files = event.target.files;
            let filetype = files[0].type;
            if (!(filetype.toLowerCase().includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (filetype.toLowerCase().includes('application/msword')) || (filetype.toLowerCase().includes('pdf')))) {

                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "You can choose only the Word and PDF files!!"
                })
                return;
            }
        } else {
            files = event;
        }
        if (files.length > 0) {
            if (flag == 1) {
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                this.setState({ attachMentFlag: flag });
                CoopFormAction.addAttachment(files, fileBO);
            } else {
                files = event.target.files;
                let fileSize = files[0].size;
                if (fileSize > 2097152) {
                    //swal({
                    //    allowOutsideClick: false,
                    //    closeOnClickOutside: false,
                    //    text: "Your file size should be less than 2MB."
                    //})
                    if (flag == 2) {
                        $('#attachMentTaxErr').removeClass('hide')
                    } else {
                        $('#attachMentW9Err').removeClass('hide')
                    }
                }
                else {
                    if (flag == 2) {
                        $('#attachMentTaxErr').addClass('hide')
                    } else {
                        $('#attachMentW9Err').addClass('hide')
                    }
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    this.setState({ attachMentFlag: flag });
                    CoopFormAction.addAttachment(files, fileBO);
                }
            }
        }
    };
    loadAttachments = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        $(`#nAttachments`).val('');
        let isValid = CoopFormStore.attachmentData.indexOf("You have exceeded the length of total file names") >= 0 ? false : true;
        if (isValid) {
            let attachmentResponse = JSON.parse(CoopFormStore.attachmentData);
            if (this.state.attachMentFlag == 1) {
                let temArray = this.state.shipToAddress;
                let primaryPhNo = this.state.primaryPhNoCC.trim() + "|" + this.state.primaryPhoneNo + "|" + this.state.primaryPhNoExtension;
                let executivePhNo = this.state.executivePhNoCC.trim() + "|" + this.state.executivePhoneNo + "|" + this.state.executivePhNoExtension;
                let accPhNo = this.state.accPhNoCC.trim() + "|" + this.state.accPayablePhoneNo + "|" + this.state.accPhNoExtension;
                //delete temArray['shipToStreetErr'];
                //delete temArray['shipToCityErr'];
                //delete temArray['shipToStateErr'];
                //delete temArray['shipToPostalErr'];
                temArray.forEach((element, index) => {
                    temArray[index].shipToCountry = temArray[index].shipToCountry.value;
                    temArray[index].shipToState = temArray[index].shipToState.value;
                    temArray[index].shipToStreet = temArray[index].shipToStreet.trim();
                    temArray[index].shipToApartmentNo = temArray[index].shipToApartmentNo.trim();
                    temArray[index].shipToCity = temArray[index].shipToCity.trim();
                });
                this.setState({ attachMentPDF: attachmentResponse[0] });
                let submitBO = {
                    clientName: this.state.clientName.trim(),
                    industry: this.state.industry.value.toLowerCase() == "others" ? this.state.industryOther.trim() : this.state.industry.value,
                    primaryName: this.state.primaryName.trim(),
                    primaryTitle: this.state.primaryTitle.trim(),
                    primaryEmail: this.state.primaryEmail.trim(),
                    primaryPhoneNo: primaryPhNo,
                    executiveName: this.state.executiveName.trim(),
                    executiveTitle: this.state.executiveTitle.trim(),
                    executiveEmail: this.state.executiveEmail.trim(),
                    executivePhoneNo: executivePhNo.trim(),
                    accPayableName: this.state.accPayableName.trim(),
                    accPayableTitle: this.state.accPayableTitle.trim(),
                    accPayableEmail: this.state.accPayableEmail.trim(),
                    accPayablePhoneNo: accPhNo,
                    shipToAddress: temArray,
                    billToStreet: this.state.billToStreet.trim(),
                    billToApartmentNo: this.state.billToApartmentNo.trim(),
                    billToCity: this.state.billToCity.trim(),
                    billToState: this.state.billToState.value,
                    billToPostal: this.state.billToPostal.trim(),
                    billToCountry: this.state.billToCountry.value,
                    attachMentPDF: attachmentResponse[0].FilePath,
                    taxExempt: this.state.taxExempt.value,
                    attachMentTax: this.state.attachMentTax.FilePath,
                    W9: this.state.W9.trim(),
                    attachMentW9: this.state.attachMentW9.FilePath,
                    companyRevenue: this.state.companyRevenue.value,
                    estMRO: this.state.estMRO.value,
                };
                console.log("shiptoaddress", temArray);
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                CoopFormAction.submitClickAction(submitBO);
            } else if (this.state.attachMentFlag == 2) {
                this.setState({ attachMentTax: attachmentResponse[0] });
            } else if (this.state.attachMentFlag == 3) {
                this.setState({ attachMentW9: attachmentResponse[0] });
            }
        } else {
            this.setState({
                attachmentErrormsg: "You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names."
            });
        }
    };
    removeAttachments = (filepath, flag) => {
        this.setState({ attachMentFlag: flag });
        let removeBO = {
            filePath: filepath
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        CoopFormAction.removeAttachment(removeBO);
    }
    removeAttachmentResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let isValid = CoopFormStore.removeData.indexOf("success") >= 0 ? true : false;
        if (isValid) {
            let attachment = { FileName: '', FilePath: '' }
            if (this.state.attachMentFlag == 2) {
                this.setState({ attachMentTax: attachment });
            } else if (this.state.attachMentFlag == 3) {
                this.setState({ attachMentW9: attachment });
            }
        }
    };
    downloadAttachment = (e, attachment) => {
        e.preventDefault();
        let downloadBO = {
            fileName: attachment.FileName,
            filePath: attachment.FilePath
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        NonCatalogAction.downloadAttachment(downloadBO);
    };
    render() {
        const { t } = this.props;
        return (<React.Fragment>
            <div className="container-fluid content-margin"><div
                className="row non-catalog-req-form ml-2 pt-4"
            ><div className="col-md-12 page-heading mt-5"><h5 className="page-title">COOP
                Customer Registration</h5></div>
                <div
                    className="col-md-12 form-container mt-3    "
                ><span className="float-right"></span></div><div
                    className="col-md-4 mb-3"
                ><label className="catalog-label form-label mandatory-after">Client Name</label><div
                    className="p-0"
                ><input
                            type="text"
                            className="form-control"
                            id="clientName"
                            name='reqform'
                            maxLength="60"
                            placeholder="Enter client name "
                            value={this.state.clientName}
                            onChange={this.handleChange}
                        /></div></div>
                <div className="col-md-4 mb-3">
                    <label
                        className="catalog-label form-label mandatory-after w-100"
                    >Industry</label><div className="p-0"><div className="col-md-12 p-0">
                        <Select
                            className="DropDown-Font-Size changeevent"
                            value={this.state.industry}
                            onChange={(e) => { this.handleDDChange(e, "industry") }}
                            options={this.state.industryDD}
                            name="industry"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    borderColor: this.state.industryErr ? "#FF0000" : "#ced4da",
                                    boxShadow: this.state.industryErr && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                    "&:hover": {
                                        ...provided,
                                        borderColor: this.state.industryErr ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                    }
                                }),
                            }}
                        />
                    </div>
                    </div></div>
                <div
                    className="col-md-12 form-container mt-3 d-none"
                ><span className="float-right"></span></div>
                <div id='industryOtherField'
                    className="col-md-4 mb-3 d-none"
                ><label className="catalog-label form-label mandatory-after">Industry Name</label><div
                    className="p-0"
                ><input
                            type="text"
                            className="form-control"
                            id="industryOther"
                            name='reqform'
                            maxLength="60"
                            placeholder="Enter industry name"
                            value={this.state.industryOther}
                            onChange={this.handleChange}
                        /></div></div>
                <div className="col-md-12 mt-2"><label
                    className="font-14 font-bold"
                >Primary Contact (Day to Day)</label></div><div
                    className="col-md-4 mb-3"
                ><label
                    className="catalog-label form-label mandatory-after "
                >Name</label><input
                        type="text"
                        className="form-control"
                        maxLength="60"
                        placeholder="Enter name"
                        id="primaryName"
                        name='reqform'
                        value={this.state.primaryName}
                        onChange={this.handleChange}
                    /></div><div className="col-md-4 mb-3"><label
                        className="catalog-label form-label mandatory-after "
                    >Title</label><input
                        type="text"
                        className="form-control"
                        maxLength="60"
                        placeholder="Enter title"
                        id="primaryTitle"
                        name='reqform'
                        value={this.state.primaryTitle}
                        onChange={this.handleChange}
                    /></div><div className="col-md-4 mb-3"><label
                        className="catalog-label form-label mandatory-after "
                    >Email</label><div className="p-0"><input
                        type="text"
                        className="form-control"
                        maxLength="60"
                        placeholder="Enter email address"
                        id="primaryEmail"
                        name='reqform'
                        value={this.state.primaryEmail}
                        onChange={this.handleChange}
                    /></div>

                    <span id="primaryEmailErr" className="DropDown-Font-Size errorcolour"><Trans>{this.state.primaryEmailErr}</Trans></span>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="px-0">
                                <label className="mandatory-after form-label catalog-label ">Phone No </label>
                                <div className="p-0">
                                    <select className="form-control pl-1 pr-0" name="reqform" id="primaryPhNoCC" value={this.state.primaryPhNoCC} onChange={this.handleChange}>
                                        <option value="1">+1 US</option>
                                        <option value="1 ">+1 CA</option>
                                        <option value="52">+52 MX</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <label className="mandatory-after form-label catalog-label " style={{ visibility: "hidden" }}></label>
                                <div className="p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength="10"
                                        placeholder="Enter phone no"
                                        id="primaryPhoneNo"
                                        name='reqform'
                                        value={this.state.primaryPhoneNo}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-3 px-0">
                                <label className="mandatory-after form-label catalog-label " style={{ visibility: "hidden" }} ></label>
                                <div className="p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength={4}
                                        placeholder="Extension"
                                        id="primaryPhNoExtension"
                                        name="Extesnion"
                                        value={this.state.primaryPhNoExtension}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span id="primaryPhoneNoErr" className="DropDown-Font-Size errorcolour"><Trans>{this.state.primaryPhoneNoErr}</Trans></span>
                </div><div className="col-md-12 mt-4"><label
                    className="font-14 font-bold"
                >Executive Contact</label></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after "
                >Name</label><input
                        type="text"
                        className="form-control"
                        maxLength="60"
                        placeholder="Enter name"
                        id="executiveName"
                        value={this.state.executiveName}
                        onChange={this.handleChange}
                    /></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after "
                >Title</label><input
                        type="text"
                        className="form-control"
                        maxLength="60"
                        placeholder="Enter title"
                        id="executiveTitle"
                        name='reqform'
                        value={this.state.executiveTitle}
                        onChange={this.handleChange}
                    /></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after pr-4 "
                >Email</label><div className="p-0"><input
                    type="text"
                    className="form-control"
                    maxLength="60"
                    placeholder="Enter email address"
                    id="executiveEmail"
                    name='reqform'
                    value={this.state.executiveEmail}
                    onChange={this.handleChange}
                /></div>
                    <span id="executiveEmailErr" className="DropDown-Font-Size errorcolour"><Trans>{this.state.executiveEmailErr}</Trans></span>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="px-0">
                                <label className="mandatory-after form-label catalog-label ">Phone No </label>
                                <div className="p-0">
                                    <select className="form-control pl-1 pr-0" name="reqform" id="executivePhNoCC" value={this.state.executivePhNoCC} onChange={this.handleChange}>
                                        <option value="1">+1 US</option>
                                        <option value="1 ">+1 CA</option>
                                        <option value="52">+52 MX</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <label className="mandatory-after form-label catalog-label " style={{ visibility: "hidden" }}> </label>
                                <div className="p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength="10"
                                        placeholder="Enter phone no"
                                        id="executivePhoneNo"
                                        name='reqform'
                                        value={this.state.executivePhoneNo}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-3 px-0">
                                <label className="mandatory-after form-label catalog-label " style={{ visibility: "hidden" }} > </label>
                                <div className="p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength={4}
                                        placeholder="Extension"
                                        id="executivePhNoExtension"
                                        name="Extesnion"
                                        value={this.state.executivePhNoExtension}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span id="executivePhoneNoErr" className="DropDown-Font-Size errorcolour"><Trans>{this.state.executivePhoneNoErr}</Trans></span>
                </div><div className="col-md-12 mt-2"><label
                    className="font-14 font-bold"
                >Accounts Payable Contact</label></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after pr-4 "
                >Name</label><input
                        type="text"
                        className="form-control"
                        maxLength="60"
                        placeholder="Enter name"
                        id="accPayableName"
                        name='reqform'
                        value={this.state.accPayableName}
                        onChange={this.handleChange}
                    /></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after "
                >Title</label><input
                        type="text"
                        className="form-control"
                        maxLength="60"
                        placeholder="Enter title"
                        id="accPayableTitle"
                        name='reqform'
                        value={this.state.accPayableTitle}
                        onChange={this.handleChange}
                    /></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after "
                >Email</label><div className="p-0"><input
                    type="text"
                    className="form-control"
                    maxLength="60"
                    placeholder="Enter email address"
                    id="accPayableEmail"
                    name='reqform'
                    value={this.state.accPayableEmail}
                    onChange={this.handleChange}
                /></div>
                    <span id="accPayableEmailErr" className="DropDown-Font-Size errorcolour"><Trans>{this.state.accPayableEmailErr}</Trans></span>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="px-0">
                                <label className="mandatory-after form-label catalog-label ">Phone No </label>
                                <div className="p-0">
                                    <select className="form-control pl-1 pr-0" name="reqform" id="accPhNoCC" value={this.state.accPhNoCC} onChange={this.handleChange}>
                                        <option value="1">+1 US</option>
                                        <option value="1 ">+1 CA</option>
                                        <option value="52">+52 MX</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <label className="mandatory-after form-label catalog-label " style={{ visibility: "hidden" }}> </label>
                                <div className="p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength="10"
                                        placeholder="Enter phone no"
                                        id="accPayablePhoneNo"
                                        name='reqform'
                                        value={this.state.accPayablePhoneNo}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-3 px-0">
                                <label className="mandatory-after form-label catalog-label " style={{ visibility: "hidden" }} > </label>
                                <div className="p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength={4}
                                        placeholder="Extension"
                                        id="accPhNoExtension"
                                        name="Extesnion"
                                        value={this.state.accPhNoExtension}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span id="accPayablePhoneNoErr" className="DropDown-Font-Size errorcolour"><Trans>{this.state.accPayablePhoneNoErr}</Trans></span>
                </div>
                <div className="col-md-10 mt-2">
                    <label
                        className="font-14 font-bold"
                    >Ship to Addresses</label>
                </div>
                <div>
                </div>
                <div class="col-md-2 float-left pt-1">
                    <div class="float-right mobile-catalog-btns">
                        <div class="d-inline justify-content-between align-items-center">
                            <button class="btn btn-primary custom-btn-primary primary-btn px-3 ml-2"
                                onClick={() => this.addNewShipToField()}
                            ><img class="mr-2" src="images/add-icon.svg" alt="add-icon" />New address</button>
                        </div>
                    </div>
                </div>
                {this.bindShipToField()}
                <div className="col-md-12 mt-2"><label className="font-14 font-bold">Bill to
                    Address
                </label></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after pr-4 "
                >Street</label><input
                        type="text"
                        className="form-control"
                        maxLength="55"
                        placeholder="Enter street"
                        id="billToStreet"
                        name='reqform'
                        value={this.state.billToStreet}
                        onChange={this.handleChange}
                    /></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label "
                >Apartment No</label><input
                        type="text"
                        className="form-control"
                        maxLength="20"
                        placeholder="Enter apartment #(Optional)"
                        id="billToApartmentNo"
                        name='reqform'
                        value={this.state.billToApartmentNo}
                        onChange={this.handleChange}
                    /></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after "
                >City</label><div className="p-0"><input
                    type="text"
                    className="form-control"
                    maxLength="30"
                    placeholder="Enter city"
                    id="billToCity"
                    name='reqform'
                    value={this.state.billToCity}
                    onChange={this.handleChange}
                /></div></div>
                <div className="col-md-4 mb-3"><label
                    className="mandatory-after form-label catalog-label w-100"
                >Country</label><div className="p-0">
                        <Select
                            id={`billToCountry1`}
                            name="billToCountry"
                            className="DropDown-Font-Size"
                            onChange={(e) => this.handleDDChange(e, "billToCountry")}
                            value={this.state.billToCountry}
                            defaultValue={countryOptions[0]}
                            options={countryOptions}
                        />
                    </div></div>
                <div className="col-md-4 mb-3"><label
                    className="mandatory-after form-label catalog-label w-100"
                >State</label><div className="p-0">
                        <Select
                            id={`billToState`}
                            name="billToState"
                            className="DropDown-Font-Size"
                            onChange={(e) => this.handleDDChange(e, "billToState")}
                            value={this.state.billToState}
                            options={this.state.billToStateOptions}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    borderColor: this.state.billToStateErr ? "#FF0000" : "#ced4da",
                                    boxShadow: this.state.billToStateErr && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                    "&:hover": {
                                        ...provided,
                                        borderColor: this.state.billToStateErr ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                    }
                                }),
                            }}
                        />
                    </div></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label mandatory-after "
                >Zip code</label><div className="p-0"><input
                    type="text"
                    className="form-control"
                    maxLength="6"
                    placeholder="Enter zip code"
                    id="billToPostal"
                    name='reqform'
                    value={this.state.billToPostal}
                    onChange={this.onChangeBillToZipCode}
                /></div></div>
                <div className="col-md-12 mb-5" /><div className="col-md-4 mb-3"><label
                    className="form-label catalog-label w-100 font-14 font-bold"
                >Tax Exempt</label><div className="p-0">
                        <Select
                            className="DropDown-Font-Size changeevent"
                            value={this.state.taxExempt}
                            onChange={(e) => { this.handleDDChange(e, "taxExempt") }}
                            options={this.state.taxExemptDD}
                            name="taxExempt"

                        />
                    </div></div><div
                        className="col-md-4 mb-3"
                    ><label className="form-label catalog-label font-14 font-bold ">Tax Attachment</label><div
                        className="custom-file mb-3 p-0" style={{ "z-index": "0" }}
                    ><input
                            type="file"
                            multiple
                            className="custom-file-input"
                            title="You can upload only one file at a time. Please remove the current file before uploading a new one"
                            accept="application/pdf,.docx,.doc"
                            id="nAttachments"
                            name="filename"
                            disabled={this.state.attachMentTax.FileName == "" ? false : true}
                            onChange={(e) => { this.handleAttachment(e, 2) }}
                        /><label className="custom-file-label" htmlFor="customFile">Choose File</label>
                        <span id="attachMentTaxErr" className="DropDown-Font-Size errorcolour hide"><Trans>Your file should be less than 2MB </Trans></span>
                        {this.state.attachMentTax.FileName != "" ? <span className="attachment-file-name pl-0 my-2 pr-3 d-inline-block">
                            <a href="#" className="noncat-Attachment-overflow" data-toggle="tooltip" title={this.state.attachMentTax.FileName} onClick={(e) => this.downloadAttachment(e, this.state.attachMentTax)}>{this.state.attachMentTax.FileName}</a>
                            <span className="attachment-remove-icon ml-1" onClick={() => this.removeAttachments(this.state.attachMentTax.FilePath, 2)}>X</span>
                        </span> : null}
                        <div
                            className="err-msg text-danger w-100"
                        /></div></div><div className="col-md-4 mb-3"><label
                            className="catalog-label form-label font-14 font-bold "
                        >W-9 # (EIN #) </label><div className="p-0"><input
                            type="text"
                            className="form-control"
                            maxLength="60"
                            placeholder="Enter W-9 # (EIN #)"
                            id="W9"
                            value={this.state.W9}
                            onChange={this.handleChange}
                        /></div></div>
                <div className="col-md-4 mb-3"><label
                    className="form-label catalog-label font-14 font-bold "
                >W9 Attachment</label><div className="custom-file mb-3  p-0" style={{ "z-index": "0" }}>
                        <input
                            type="file"
                            multiple
                            className="custom-file-input"
                            title="You can upload only one file at a time. Please remove the current file before uploading a new one"
                            accept="application/pdf,.docx,.doc"
                            id="nAttachments"
                            name="filename"
                            disabled={this.state.attachMentW9.FileName == "" ? false : true}
                            onChange={(e) => { this.handleAttachment(e, 3) }}
                        />
                        <label className="custom-file-label" htmlFor="customFile">Choose File</label>
                        <span id="attachMentW9Err" className="DropDown-Font-Size errorcolour hide"><Trans>Your file should be less than 2MB </Trans></span>
                        {this.state.attachMentW9.FileName != "" ? <span className="attachment-file-name pl-0 my-2 pr-3 d-inline-block">
                            <a href="#" className="noncat-Attachment-overflow" data-toggle="tooltip" title={this.state.attachMentW9.FileName} onClick={(e) => this.downloadAttachment(e, this.state.attachMentW9)}>{this.state.attachMentW9.FileName}</a>
                            <span className="attachment-remove-icon ml-1" onClick={() => this.removeAttachments(this.state.attachMentW9.FilePath, 3)}>X</span>
                        </span> : null}
                        <div
                            className="err-msg text-danger w-100"
                        /></div></div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label font-14 font-bold w-100"
                >Overall Company Revenue</label><div className="p-0"><div className="col-md-12 p-0">
                    <Select
                        className="DropDown-Font-Size changeevent"
                        value={this.state.companyRevenue}
                        onChange={(e) => { this.handleDDChange(e, "companyRevenue") }}
                        options={this.state.revenueMRODD}
                        name="companyRevenue"
                    />
                </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3"><label
                    className="catalog-label form-label font-14 font-bold w-100"
                >Estimated MRO Spend</label><div className="p-0"><div className="col-md-12 p-0">
                    <Select
                        className="DropDown-Font-Size changeevent"
                        value={this.state.estMRO}
                        onChange={(e) => { this.handleDDChange(e, "estMRO") }}
                        options={this.state.revenueMRODD}
                        name="estMRO"
                    />
                </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <span class="float-right mr-4 my-3 errormsg">{this.errDisplay()}</span></div>
                <div className="col-md-12 float-left">
                    <div className="float-right mobile-catalog-btns">
                        <button
                            className="btn btn-secondary custom-btn-secondary secondary-btn px-4 mr-4"
                            onClick={() => this.clearFormValues()}
                        >Cancel</button>
                        <button
                            className="btn btn-primary custom-btn-primary primary-btn px-4 mr-4"
                            onClick={() => this.submitBtnClick()}
                        >Submit</button>
                        <button className="btn btn-primary custom-btn-primary primary-btn px-4 px-4 mr-4 hide" id='saveBtn'
                            onClick={
                                async () => {

                                    //$(`#${SDIConstants.SDILoader}`).removeClass('hide');
                                    let optField = [];

                                    if (this.state.taxExempt.value != "") {
                                        optField.push({ label: 'Tax Exempt', value: this.state.taxExempt.value })
                                    }
                                    if (this.state.W9 != "") {
                                        optField.push({ label: 'W-9 #(EIN #)', value: this.state.W9 })
                                    }
                                    if (this.state.companyRevenue.value != "") {
                                        optField.push({ label: 'Revenue', value: this.state.companyRevenue.value })
                                    }
                                    if (this.state.estMRO.value != "") {
                                        optField.push({ label: 'MRO Spend', value: this.state.estMRO.value })
                                    }

                                    const doc = <CoopFormPDFComponent
                                        clientName={this.state.clientName}
                                        industry={this.state.industry.value.toLowerCase() == "others" ? this.state.industryOther : this.state.industry.value}
                                        primaryName={this.state.primaryName}
                                        primaryTitle={this.state.primaryTitle}
                                        primaryEmail={this.state.primaryEmail}
                                        primaryPhoneNo={this.state.primaryPhNoExtension != null && this.state.primaryPhNoExtension.trim() != "" ? "+" + this.state.primaryPhNoCC.trim() + "  " + this.state.primaryPhoneNo + "-" + this.state.primaryPhNoExtension :
                                            "+" + this.state.primaryPhNoCC.trim() + "  " + this.state.primaryPhoneNo}
                                        executiveName={this.state.executiveName}
                                        executiveTitle={this.state.executiveTitle}
                                        executiveEmail={this.state.executiveEmail}
                                        executivePhoneNo={this.state.executivePhNoExtension != null && this.state.executivePhNoExtension.trim() != "" ? "+" + this.state.executivePhNoCC.trim() + "  " + this.state.executivePhoneNo + "-" + this.state.executivePhNoExtension :
                                            "+" + this.state.executivePhNoCC.trim() + "  " + this.state.executivePhoneNo}
                                        accPayableName={this.state.accPayableName}
                                        accPayableTitle={this.state.accPayableTitle}
                                        accPayableEmail={this.state.accPayableEmail}
                                        accPayablePhoneNo={this.state.accPhNoExtension != null && this.state.accPhNoExtension.trim() != "" ? "+" + this.state.accPhNoCC.trim() + "  " + this.state.accPayablePhoneNo + "-" + this.state.accPhNoExtension :
                                            "+" + this.state.accPhNoCC.trim() + "  " + this.state.accPayablePhoneNo}
                                        optField={optField}
                                        shipToAddress={this.state.shipToAddress}
                                        billToStreet={this.state.billToStreet}
                                        billToApartmentNo={this.state.billToApartmentNo}
                                        billToCity={this.state.billToCity}
                                        billToState={this.state.billToState.value}
                                        billToPostal={this.state.billToPostal}
                                        billToCountry={this.state.billToCountry.value}

                                    />;
                                    const asPdf = pdf([]);
                                    asPdf.updateContainer(doc);
                                    const blob = await asPdf.toBlob();
                                    saveAs(blob, 'Coop - Form.pdf');
                                    let b = new File([blob], 'Coop - Form.pdf');
                                    let files = [];
                                    files.push(b);
                                    this.handleAttachment(files, 1);
                                }
                            }
                        >Hiden Proceed</button>
                    </div>
                </div></div>

                <div className="modal" id="Coop-Submit" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-4">
                                        <span className="question-mark-icon success-alert-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-content d-block">Your response was submitted successfully</label>
                                        <label className="confirm-label d-block"><Trans>COOP ID</Trans>:<span className="mx-2">{this.state.coopID}</span></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal"><Trans>OK</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </React.Fragment>

        );
    };

};
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(CoopFormComponent);



