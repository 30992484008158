import * as CookieService from '../CookieService';
let PageTitle= CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0W01" ? "Pickup From Local Supplier" : "PunchOut"
const breadCrumpData = {
    Home: [{ pageName: "Home", pageURL: "/homepage" }],
    Punchout: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: PageTitle, pageURL: "/punchout" }],
    UserProfile: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "User Profile", pageURL: "/profile" }],
    TestPage: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "User Profile", pageURL: "/profile" }],
    AccessDenied: [],
    UnderConstruction: [],
    NonCatalog: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Non-Catalog Request", pageURL: "/" }],
    ShoppingCart: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Shopping Cart", pageURL: "/" }],
    ItemDetail: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "ItemDetail", pageURL: "/" }],
    SearchResults: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "SearchResults", pageURL: "/" }],
    UserProfile: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "UserProfile", pageURL: "/" }],
    FavoriteOrder: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Favorite Order Maintenance", pageURL: "/" }],
    ShopRedirect: [{ pageName: "Punchout", pageURL: "/Punchout" }],
    OrderApproval: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Order Approval", pageURL: "/" }],
    WaitingRequestorApproval: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Waiting Requestor Approval", pageURL: "/" }],
    RequestorApproval: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Waiting Requestor Approval", pageURL: "/needquote" }, { pageName: "Requestor Approval", pageURL: "/" }],
    Favorites: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Favorite Item Maintenance", pageURL: "/" }],
    Notifications: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "User Notification", pageURL: "/" }],
    OrderStatus: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Check Order Status", pageURL: "/" }],
    //[11/17/21, PC_BC_01, CAS - Receive Items - Bread Crumps Issue, Dhevanesam R] 
    Receive: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Receive Items", pageURL: "/" }],
    PIPost: [],
    Punchinend: [],
    PunchInRFQ: [],
    // Add Breadcrumb option for Acitver users Reports and Default PutAway Locations panel
    "Active Users - SDI/Customers": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Active Users - SDI/Customers", pageURL: "/" }],
    "Default PutAway Locations": [{ pageName: "Home", pageURL: " / homepage" }, { pageName: "Default PutAway Locations", pageURL: "/" }],
    "Supplier Call-In": [{ pageName: "Home", pageURL: " / homepage" }, { pageName: "Supplier Call-In", pageURL: "/" }],
    BinToBinTransfer: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Bin To Bin Transfer", pageURL: "/" }],
    "Stock Issues Cart": [{ pageName: "Home", pageURL: " / homepage" }, { pageName: "Stock Issues Cart", pageURL: "/" }],
    "InsiteonlineRMA": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Return To Inventory", pageURL: "/" }],
    "ManualStockAdjustment": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Manual Stock Adjustment", pageURL: "/" }],
    "Role Master": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Role Master", pageURL: "/RoleMaster" }],
    // Mythili Zeus-241 Product Replen panel
    "Product Replenishment": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Product Replenishment", pageURL: "/" }],
    ExpressPutAway: [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Express Putaway", pageURL: "/" }],
    // Mythili -- Zeus-40 SDI Enterprise customer master
    "Enterprise Customer Master": [{ pageName: "Home", pageURL: " / homepage" }, { pageName: "Enterprise Customer Master", pageURL: "/" }],
    "Material Storage Locations": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Material Storage Locations", pageURL: "/" }],
    "Program Master": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Program Master", pageURL: "/" }],
    "Picking Assign To": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "Picking Assign To", pageURL: "/" }],
    "Real time inventory": [{ pageName: "Home", pageURL: "/homepage" }, { pageName: "SearchResults", pageURL: "/searchresults" }, { pageName: "Real time inventory", pageURL: "/" }]

};
export default breadCrumpData;