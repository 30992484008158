/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Sushmitha
 * Created Date: 08/11/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: SC_PC_57
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as CookieService from '../CookieService';
import * as MenuAction from './MenuAction';

const tokenExpired = async () => {
    try {
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)

    }
};
/**
 * Ref: SC_PC_58
 * 
 * Getcartitem method gets called the LoadCartItem()
 * @param GetCartBO
 */
const Getcartitem = (GetCartBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_Pageload,
            JSON.stringify(GetCartBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "Cartitem", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: SC_PC_58
 * 
 * UpdateItemDesp method gets called the LinelevelEmyEdit()
 * @param UpdateItemDetails
 */
const UpdateItemDesp = (UpdateItemDetails) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_UpdateLineLevelItem,
            JSON.stringify(UpdateItemDetails),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateStatus", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: SC_PC_62
 * 
 * UpdateCartEmpCC method gets called the BindEmpCCToItemOL()
 * @param UpdateOrderBO
 */
const UpdateCartEmpCC = (UpdateOrderBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_UpdateFieldLevelItem,
            JSON.stringify(UpdateOrderBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateStatusOL", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: SC_PC_98
 * GetAddFavItemDetail method gets called the LinelevelFavEdit()
 * @param GetAddFavItemDetailBO
 */
const GetAddFavItemDetail = (GetAddFavItemDetailBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_GetAddToFavItemDetails,
            JSON.stringify(GetAddFavItemDetailBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FavItemDetails", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: SC_PC_99
 * updateFavsMenu method gets called the AddFavItem()
 * @param updateFavsMenuBO
 */
const updateFavsMenu = (updateFavsMenuBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_updateFavs,
            JSON.stringify(updateFavsMenuBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FavItemUpdate", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: SC_PC_58
 * 
 * DeleteCartItem method gets called the DeleteItemConfirmed()
 * @param DeleteCartItemBO
 */
const DeleteCartItem = (DeleteCartItemBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_DeleteFromUserCart,
            JSON.stringify(DeleteCartItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "deleteItem", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: SC_PC_71
 * 
 * SubmitOrder method gets called the AddFavOrder()
 * @param PlaceOrderBO
 */
const SubmitOrder = (PlaceOrderBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_BtnSubmit_Click,
            JSON.stringify(PlaceOrderBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PlaceOrder", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * 
 * OrderPdf method gets called the OrderPlacedStatus()
 * @param OrderPDFBO
 */
const OrderPdf = (OrderPDFBO) => {
    try {
        debugger;
        SDISVC.receiveFileBase64(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_CartConfirmFile,
            JSON.stringify(OrderPDFBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "OrderPDF", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Reused from Non catalog request page
 * 
 * QuickItemCheck method gets called the quickItemClick()
 * @param quickItemBO
 */
const QuickItemCheck = async (quickItemBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_QuickItemCheck,
            JSON.stringify(quickItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_QuickItemCheck", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Reused from Non catalog request page
 * 
 * addAttachment method gets called the handleAttachment()
 * @param files
 */
const addAttachment = async (files) => {
    try {
        SDISVC.uploadFile(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_AttachFiles,
            "",
            files,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_UploadAttachments", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: NC_PC_28
 * 
 * downloadAttachment method gets called from the on clicking the title of the file
 * @param downloadBO
 */
const downloadAttachment = async (downloadBO) => {
    try {
        SDISVC.downloadFile(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_DownloadFile,
            JSON.stringify(downloadBO),
            downloadBO.fileName,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {

                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: NC_PC_29
 * 
 * removeAttachment method gets called from when clicking the remove icon of the attachment
 * @param removeBO
 */
const removeAttachment = async (removeBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_RemoveFile,
            JSON.stringify(removeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_RemoveAttachment", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: SC_PC_73
 * 
 * CancelOrderItem method gets called from when cancel order button is clicked
 * @param CancelOrderBO
 */
const CancelOrderItem = (CancelOrderBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_btnCancel_Click,
            JSON.stringify(CancelOrderBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "CancelOrder", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * getCurrentFavPage method gets called on page load
 * @param getFavPageBO
 */
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const getTaxValue = (getTaxValueBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCartPayment,
            SDIConstants.methodNames.ShoppingCartPayment_getSiteTaxAmount,
            JSON.stringify(getTaxValueBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "TaxValue", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const getWalletValue = (getWalletValueBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCartPayment,
            SDIConstants.methodNames.ShoppingCartPayment_BindWalletData,
            JSON.stringify(getWalletValueBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "WalletValue", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const RemoveCard = (RemoveCardBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCartPayment,
            SDIConstants.methodNames.ShoppingCartPayment_DeleteUserCart,
            JSON.stringify(RemoveCardBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "RemoveCardValue", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const ProcessPayment = (ProcessPaymentBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCartPayment,
            SDIConstants.methodNames.ShoppingCartPayment_ProcessPayment,
            JSON.stringify(ProcessPaymentBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ProcessPaymentBO", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const AddCardDetails = (AddCardDetailsBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCartPayment,
            SDIConstants.methodNames.ShoppingCartPayment_UpdateCardDetails,
            JSON.stringify(AddCardDetailsBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "AddCardDetailsBO", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const GetchargeCOde = (GetchargeCOdeBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.BuildChrCD,
            SDIConstants.methodNames.BuildChrCD_Page_Load,
            JSON.stringify(GetchargeCOdeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "BuildChrCD", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
//[3/15/2023]CAS - 44 ValidateAddress method is to connect ith AddressValidate API method to validate the address entered by user in shopping cart - Poornima S
const ValidateAddress = (AddressBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_AddressValidate,
            JSON.stringify(AddressBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ValidAddress", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
//[5/7/23, PC_ZE_85_APP_12, Dhevanesam R]
const GraingerItem = (GetCartBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.GetGraingerAvailability,
            SDIConstants.methodNames.GetGraingerAvailability,
            JSON.stringify(GetCartBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "grainger", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
//[5/12/2023]Zeus -102 Default address enable 
const setDefaultAddress = (DefAddressBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_SetDefaultAddress,
            JSON.stringify(DefAddressBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "SetDefaultAddress", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
//[15/05/23, PS_EWVB_03, Zeus 112, Sathis. N]- EMCOR WO validation
const workOrderValidation = async (workOrderBO) => {
    try {
        debugger
        SDISVC.callAsync(
            SDIConstants.moduleNames.ServiceChannel,
            SDIConstants.methodNames.ServiceChannel_GetWorkOrderValidation,
            JSON.stringify(workOrderBO),
            (data) => {
                debugger
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "workorderValidate", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const addAttachmentImg = async (files) => {
    try {
        SDISVC.uploadFile(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_AttachFiles,
            "",
            files,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "favOrderAttachment", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const favOrderRemoveAttachments = async (removeBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_RemoveFile,
            JSON.stringify(removeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "favOrderRemoveAttachments", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

export {
    Getcartitem, UpdateItemDesp, UpdateCartEmpCC, GetAddFavItemDetail, RemoveCard, AddCardDetails,
    updateFavsMenu, DeleteCartItem, SubmitOrder, OrderPdf, CancelOrderItem, getWalletValue, ProcessPayment,
    QuickItemCheck, removeAttachment, downloadAttachment, addAttachment, deleteFavPage, insertFavPage, getCurrentFavPage, getTaxValue, GetchargeCOde, ValidateAddress, GraingerItem,
    setDefaultAddress, workOrderValidation, addAttachmentImg, favOrderRemoveAttachments
}