
import React, { Component } from 'react';
import * as MaterialStorageLocAction from '../actions/MaterialStorageLocAction';
import MaterialStorageLocStore from '../stores/MaterialStorageLocStore';
import * as CookieService from '../CookieService';
import * as $ from 'jquery';
import ReactPaginate from 'react-paginate';
import SDIConstants from '../SDIConstants';
import * as MenuAction from '../actions/MenuAction';
import { withTranslation, Trans } from 'react-i18next';
import Select from 'react-select';
import swal from 'sweetalert';

let searchTextGlobVar = "";
let currentPagePath;

class MaterialStorageLocComponent extends Component {
    constructor(props) {

        super(props);
        this.pageLoadResponse = this.pageLoadResponse.bind(this);
        this.submitBtnClickResponse = this.submitLocationResponse.bind(this);
        this.state = {
            filterDisplayName: "All",
            isFavorited: false,
            totalCount: 0,
            searchByOptions: [
                {
                    value: 'All', label: 'All'
                },
                {
                    value: 'STOR_LEVEL_1', label: 'Aisle'
                },
                {
                    value: 'STOR_LEVEL_2', label: 'Row'
                },
                {
                    value: 'STOR_LEVEL_3', label: 'Shelf'
                },
                {
                    value: 'STOR_LEVEL_4', label: 'Bin'
                }
            ],
            searchType: "All",
            searchValue: "",
            count: 0,
            reqCount: 10,
            pageNo: 1,
            pageCount: 0,
            sortColumn: "",
            sortValue: "",
            gridData: [],
            isNewLoc: false,
            newLocAis: "",
            newLocRow: "",
            newLocShf: "",
            newLocBin: "",
            DELETE_STORLOC: false,
            businessUnitOptions: [],
            businessUnitDDValue: "",
            businessUnit: "",
            storeAreaOptions: [],
            storeArea: "",
            areaDDValue: "",
            isValidBusinessUnit: true,
            isValidArea: true,
            isAreaDisabled: true,
            isSearchResult: false,
            isDisabled: true,
            isPopupImage: false,
            popupMessage: ""
        }
    }

    /**
     * PS_ZE_291_APP_11/Dhinesh/04/17/2024
     * Invoke this method on the click of Material Storage Location from menu bar.
     * */

    componentDidMount() {
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;

        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        }

        this.pageLoad("businessUnit", CookieService.getLocalStorageValues("Session_BUSUNIT"), 1, "", "", "");
        MaterialStorageLocAction.getCurrentFavPage(getFavPageBO);
        MaterialStorageLocStore.on('funcPageLoad', this.pageLoadResponse.bind(this));
        MaterialStorageLocStore.on('funcSubmit', this.submitLocationResponse.bind(this));
        MaterialStorageLocStore.on('favpage', this.loadFavPageResponse.bind(this));
        MaterialStorageLocStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    /**
    * PS_ZE_291_APP_14/Dhinesh/04/17/2024
    * This method invokes on page load,click of search button and change of business unit drop down.
    * */
    componentWillUnmount() {
        MaterialStorageLocStore.removeListener('funcPageLoad', this.pageLoadResponse.bind(this));
        MaterialStorageLocStore.removeListener('funcSubmit', this.submitLocationResponse.bind(this));
        MaterialStorageLocStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        MaterialStorageLocStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(MaterialStorageLocStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };

    updateFavPageResponse = () => {
        if (MaterialStorageLocStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(MaterialStorageLocStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(MaterialStorageLocStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };

    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Material Storage Location",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            MaterialStorageLocAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            MaterialStorageLocAction.deleteFavPage(deleteFavPageBO)
        }
    };

    /**
        * PS_ZE_291_APP_14/Dhinesh/04/17/2024
        * This method invokes on page load,click of search button and change of business unit drop down.
        * */
    pageLoad = (action, businessUnit, pageNo, sortColumn, sortValue, searchTextGlobVar) => {

        let pageLoadBO = {
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            empBusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            action: action,
            businessUnit: businessUnit,
            storeArea: this.state.storeArea,
            pageNo: pageNo,
            sortValue: sortValue,
            sortColumn: sortColumn,
            searchColumn: this.state.searchType,
            search: searchTextGlobVar.trim()
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        this.setState({ pageNo: pageNo })
        MaterialStorageLocAction.pageLoadAction(pageLoadBO)
    }

    /**
    * PS_ZE_291_APP_18/Dhinesh/04/17/2024
    * This method invokes on change of  business unit , store area and search filter
    * */
    onChangeDD = (e, name) => {
        if (name == "businessUnit") {
            if (this.state.businessUnit != e.value) {
                this.pageLoad("storeArea", e.value, 1, "", "", "");
                this.setState({
                    gridData: [],
                    businessUnitDDValue: e,
                    businessUnit: e.value,
                    isAreaDisabled: false,
                    isSearchResult: false
                })
            }

        }
        else if (name == "storeArea") {
            if (this.state.storeArea != e.value) {
                this.setState({
                    storeArea: e.value,
                    areaDDValue: e,
                    isSearchResult: false,
                    gridData: []
                })
            }
        }
    }

    /**
    * PS_ZE_291_APP_19/Dhinesh/04/17/2024
    * This method invokes on the click of search button 
    * */
    searchClick = () => {
        if (this.state.businessUnit) {
            this.setState({ isValidBusinessUnit: true })
            if (this.state.storeArea) {
                this.pageLoad("search", this.state.businessUnit, 1, "", "", "")
                this.setState({
                    isValidArea: true,
                    pageNo: 1, sortColumn: "", sortValue: "", searchType: "All", searchValue: "", isNewLoc: false
                })
            }
            else {
                this.setState({ isValidArea: false })
            }
        }
        else {
            this.setState({ isValidBusinessUnit: false })
        }
    }

    /**
    * PS_ZE_291_APP_19/Dhinesh/04/17/2024
    * This method response of the page load
    * */

    pageLoadResponse = () => {
        debugger
        const { t } = this.props;
        const response = JSON.parse(MaterialStorageLocStore.pageLoadResponse);
        const errorDt = response.errorDt;
        const businessUnitDD = response.businessUnitDt;
        const storeAreaDD = response.storeAreaDt;
        let gridData = response.gridDataDt;
        if (errorDt[0].errorMessage.toUpperCase() == "FALSE") {
            let count;
            if (businessUnitDD.length > 0) {
                let businessUnitDDvalue = [];
                if (businessUnitDD.length > 0) {
                    businessUnitDD.forEach((value, index) => {
                        let locObj = {
                            value: value.BUSINESS_UNIT,
                            label: value.BUSINESS_UNIT
                        }
                        businessUnitDDvalue.push(locObj);
                    });
                }
                this.setState({ businessUnitOptions: businessUnitDDvalue, businessUnitDDValue: "", businessUnit: "" })
            }
            if (storeAreaDD.length > 0) {
                let storeAreaDDvalue = [];
                if (storeAreaDD.length > 0) {
                    storeAreaDD.forEach((value, index) => {
                        let locObj = {
                            value: value.AREA,
                            label: value.AREA
                        }
                        storeAreaDDvalue.push(locObj);
                    });
                }
                this.setState({ storeAreaOptions: storeAreaDDvalue, areaDDValue: "", storeArea: "" });
            }

            if (gridData.length > 0) {
                count = gridData[0].TOTALRECORDS;
                let pg = Math.ceil((count > 0 ? count : 0) / this.state.reqCount);
                gridData.forEach((value, index) => {
                    value.isEditClicked = false;
                    value.editExisAis = value.STOR_LEVEL_1;
                    value.editExisRow = value.STOR_LEVEL_2;
                    value.editExisShf = value.STOR_LEVEL_3;
                    value.editExisBin = value.STOR_LEVEL_4;
                    if (value.DELETE_STORLOC_FLG == "FALSE") {
                        value.manualEdit = false;
                        value.DELETE_STORLOC_FLG = false;
                    }
                    else {
                        value.manualEdit = true;
                        value.DELETE_STORLOC_FLG = true;
                    }
                });
                this.setState({ gridData: gridData, pageCount: pg, isSearchResult: true, isNewLoc: false, count: count });
            }
            else if (this.state.businessUnit != "" && this.state.storeArea != "" && gridData.length == 0) {
                count = 0;
                this.setState({ count: count, isSearchResult: true, gridData: [] })
            }
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    handleSearch = () => {
        searchTextGlobVar = this.state.searchValue;
        this.pageLoad("Search", this.state.businessUnit, 1, "", "", searchTextGlobVar)
        this.setState({ pageNo: 1, sortColumn: "", sortValue: "" });
    }

    ClearSearch = () => {
        searchTextGlobVar = "";
        this.setState({ searchValue: searchTextGlobVar });
    }
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.handleSearch();
        };
    }
    onChangeSearch = (e) => {
        this.setState({ searchValue: e })
    }
    filterClick = (event, displayName, columnName) => {
        event.preventDefault();
        this.setState({ filterDisplayName: displayName, searchType: columnName });
    };

    bindDropdown = () => {
        return this.state.searchByOptions.map((values, index) => {
            return (
                <a className="dropdown-item" href="#" value={values.value} onClick={(e) => this.filterClick(e, values.label, values.value)}> <Trans>{values.label}</Trans> </a>
            )
        })
    }

    /**
    * PS_ZE_291_APP_22/Dhinesh/04/18/2024
    * This method invokes when on the click of new location button
    * */
    onNewClick = () => {
        if (this.state.isNewLoc === false) {
            let gridData = this.state.gridData;
            gridData.forEach((value, index) => {
                if (value.isEditClicked === true) {
                    value.isEditClicked = false;
                    value.manualEdit = value.DELETE_STORLOC_FLG;
                    value.editExisAis = value.STOR_LEVEL_1;
                    value.editExisRow = value.STOR_LEVEL_2;
                    value.editExisShf = value.STOR_LEVEL_3;
                    value.editExisBin = value.STOR_LEVEL_4;
                }
            });
            this.setState({
                isNewLoc: true,
                newLocAis: "",
                newLocBin: "",
                newLocShf: "",
                newLocRow: "",
                DELETE_STORLOC: false,
                gridData: gridData,
                isTextEmpty: false,
                isDisabled: true
            });
        }
    }

    /**
    * PS_ZE_291_APP_23/Dhinesh/04/18/2024
    * This method invokes when on the click of edit icon button
    * */

    onEditClick = (e, index) => {

        let gridData = this.state.gridData;

        gridData.forEach((value, i) => {
            if (i === index) {
                value.isEditClicked = true;
            }
            else {
                value.isEditClicked = false;
                value.manualEdit = value.DELETE_STORLOC_FLG;
                value.editExisAis = value.STOR_LEVEL_1;
                value.editExisRow = value.STOR_LEVEL_2;
                value.editExisShf = value.STOR_LEVEL_3;
                value.editExisBin = value.STOR_LEVEL_4;
            }
        });
        this.setState({
            gridData: gridData,
            isNewLoc: false,
            isTextEmpty: false,
            isDisabled: true
        });
    }

    /**
   * PS_ZE_291_APP_24/Dhinesh/04/18/2024
   * This method invokes when on the click of save icon in new location 
   * */
    insertClick = () => {
        if (this.state.newLocAis.trim() && this.state.newLocRow.trim() && this.state.newLocShf.trim() && this.state.newLocBin.trim()) {
            const submitBO = {
                sessionUserID: CookieService.getLocalStorageValues("Session_UserID"),
                sessionBusUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                sessionWebsiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                selectedBU: this.state.businessUnit,
                selectedArea: this.state.storeArea,
                isNewLoc: this.state.isNewLoc,
                selectedAisle: this.state.newLocAis.trim(),
                selectedRow: this.state.newLocRow.trim(),
                selectedShelf: this.state.newLocShf.trim(),
                selectedBin: this.state.newLocBin.trim(),
                selectedOldAisle: "",
                selectedOldRow: "",
                selectedOldShelf: "",
                selectedOldBin: "",
                oldDeleteStorage: "",
                DELETE_STORAGE_FLG: this.state.DELETE_STORLOC ? "N" : "Y"
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            MaterialStorageLocAction.submitLocationAction(submitBO);
        } else {
            this.setState({ isTextEmpty: true });
        }
    };

    /**
    * PS_ZE_291_APP_25/Dhinesh/04/18/2024
    * This method invokes when on the click of save icon in edit location 
    * */

    updateClick = (e, index) => {
        let { gridData } = this.state;
        if (gridData[index].editExisAis.trim() && gridData[index].editExisRow.trim() && gridData[index].editExisShf.trim() && gridData[index].editExisBin.trim()) {
            const submitBO = {
                sessionUserID: CookieService.getLocalStorageValues("Session_UserID"),
                sessionBusUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                sessionWebsiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                selectedBU: this.state.businessUnit,
                selectedArea: this.state.storeArea,
                isNewLoc: this.state.isNewLoc,
                selectedAisle: gridData[index].editExisAis.trim(),
                selectedRow: gridData[index].editExisRow.trim(),
                selectedShelf: gridData[index].editExisShf.trim(),
                selectedBin: gridData[index].editExisBin.trim(),
                selectedOldAisle: gridData[index].STOR_LEVEL_1,
                selectedOldRow: gridData[index].STOR_LEVEL_2,
                selectedOldShelf: gridData[index].STOR_LEVEL_3,
                selectedOldBin: gridData[index].STOR_LEVEL_4,
                oldDeleteStorage: gridData[index].DELETE_STORLOC_FLG ? "N" : "Y",
                DELETE_STORAGE_FLG: gridData[index].manualEdit ? "N" : "Y"

            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            MaterialStorageLocAction.submitLocationAction(submitBO);
        } else {
            this.setState({ isTextEmpty: true });
        }
    };

    /**
    * PS_ZE_291_APP_28/Dhinesh/04/18/2024
    * This method is the response of the save icon click
    * */
    submitLocationResponse = () => {
        const { t } = this.props;
        const response = JSON.parse(MaterialStorageLocStore.submitLocationResponse);
        if (response !== "Exception occurs") {
            if (response !== "Error in UPDATE query" && response !== "Error in INSERT query") {
                if (response !== "Material storage location already exists") {
                    if (response === "Location updated successfully") {
                        this.setState({ isPopupImage: true, popupMessage: "Location updated successfully!", isPageLoad: false });
                    } else {
                        this.setState({ isPopupImage: true, popupMessage: "New location added successfully!", isPageLoad: true });
                    }
                } else {
                    this.setState({ isPopupImage: false, popupMessage: "Location already exists!", isPageLoad: false });
                }
            }
            else if (response == "Error in UPDATE query") {
                this.setState({ isPopupImage: false, popupMessage: "Location cannot be updated!", isPageLoad: false });
            }
            else {
                this.setState({ isPopupImage: false, popupMessage: "Location cannot be added!", isPageLoad: false });
            }
            $(`#exp-popup`).modal('show')
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    /**
    * PS_ZE_291_APP_29/Dhinesh/04/18/2024
    * This method invokes when on change of input field in new location
    **/
    handleNewText(e, name) {
        switch (name) {
            case 'DELETE_STORLOC':
                this.setState({ [e.target.name]: e.target.checked });
                break;
            case 'newLocAis':
                this.setState({ newLocAis: e.target.value });
                if (e.target.value.trim() != "" && this.state.newLocRow.trim() != "" && this.state.newLocShf.trim() != "" && this.state.newLocBin.trim() != "") {
                    this.setState({ isTextEmpty: false });
                }
                break;
            case 'newLocRow':
                this.setState({ newLocRow: e.target.value });
                if (this.state.newLocAis.trim() != "" && e.target.value.trim() != "" && this.state.newLocShf.trim() != "" && this.state.newLocBin.trim() != "") {
                    this.setState({ isTextEmpty: false });
                }
                break;
            case 'newLocShf':
                this.setState({ newLocShf: e.target.value });
                if (this.state.newLocAis.trim() != "" && this.state.newLocRow.trim() != "" && e.target.value.trim() != "" && this.state.newLocBin.trim() != "") {
                    this.setState({ isTextEmpty: false });
                }
                break;
            default:
                this.setState({ newLocBin: e.target.value });
                if (this.state.newLocAis.trim() != "" && this.state.newLocRow.trim() != "" && this.state.newLocShf.trim() != "" && e.target.value.trim() != "") {
                    this.setState({ isTextEmpty: false });
                }
                break;
        }
    }


    /**
    * PS_ZE_291_APP_30/Dhinesh/04/18/2024
    * This method invokes when on change of input field in edit
    * */

    handleExistText = (e, index) => {
        const name = e.target.name; // This assumes that the name attribute is being used to identify the input.
        let { gridData } = this.state;
        if (name === "DELETE_STORLOC_FLG") {
            gridData[index].manualEdit = e.target.checked;
        }
        else if (name == "editExisAis") {
            this.state.gridData[index].editExisAis = e.target.value;
        }
        else if (name == "editExisRow") {
            this.state.gridData[index].editExisRow = e.target.value;
        }
        else if (name == "editExisShf") {
            this.state.gridData[index].editExisShf = e.target.value;
        }
        else {
            this.state.gridData[index].editExisBin = e.target.value;
        }
        if (
            (gridData[index].editExisAis != gridData[index].STOR_LEVEL_1) ||
            (gridData[index].editExisRow != gridData[index].STOR_LEVEL_2) ||
            (gridData[index].editExisShf != gridData[index].STOR_LEVEL_3) ||
            (gridData[index].editExisBin != gridData[index].STOR_LEVEL_4) || (gridData[index].manualEdit != gridData[index].DELETE_STORLOC_FLG)
        ) {
            this.setState({ gridData: gridData, isDisabled: false });
        }
        else {

            this.setState({ isDisabled: true, gridData: gridData });
        }
        if (gridData[index].editExisAis.trim() != "" && gridData[index].editExisRow.trim() != "" && gridData[index].editExisShf.trim() != "" && gridData[index].editExisBin.trim() != "") {
            this.setState({ isTextEmpty: false });
        }
    };

    /**
    * PS_ZE_291_APP_31/Dhinesh/04/18/2024
    * This method invokes when on cancel icon 
    * */
    handleCancel = (e, index, param) => {
        if (param == "edit") {
            let { gridData } = this.state;
            gridData[index].isEditClicked = false;
            gridData[index].manualEdit = gridData[index].DELETE_STORLOC_FLG;
            gridData[index].editExisAis = gridData[index].STOR_LEVEL_1;
            gridData[index].editExisRow = gridData[index].STOR_LEVEL_2;
            gridData[index].editExisShf = gridData[index].STOR_LEVEL_3;
            gridData[index].editExisBin = gridData[index].STOR_LEVEL_4;
            this.setState({ gridData: gridData, isTextEmpty: false })
        }
        else {
            this.setState({
                isNewLoc: false,
                newLocAis: "",
                newLocRow: "",
                newLocShf: "",
                newLocBin: "",
                DELETE_STORLOC: false,
                isTextEmpty: false
            })
        }

    }

    /**
    * PS_ZE_291_APP_33/Dhinesh/04/18/2024
    * This method invokes when on cancel icon 
    * */
    handleSorting = (sortColumn, sortValue) => {
        this.pageLoad("search", this.state.businessUnit, this.state.pageNo, sortColumn, sortValue, searchTextGlobVar);
        this.setState({ sortColumn: sortColumn, sortValue: sortValue })
    }

    /**         
     * PS_ZE_291_APP_32/Dhinesh/04/18/2024
     *This method invokes on the click of pagination 
     * */

    handlePagination = (data) => {
        let pageNo = data.selected + 1;
        this.pageLoad("search", this.state.businessUnit, pageNo, this.state.sortColumn, this.state.sortValue, searchTextGlobVar);

    };

    bindGridData = () => {
        if (this.state.gridData.length > 0) {
            return this.state.gridData.map((value, index) => {
                return (<React.Fragment key={index}>{value.isEditClicked == false ? <>
                    <tr>

                        <td className="text-nowrap grid-width">{value.STOR_LEVEL_1}</td>
                        <td className="text-nowrap grid-width">{value.STOR_LEVEL_2}</td>
                        <td className="text-nowrap grid-width">{value.STOR_LEVEL_3}</td>
                        <td className="text-nowrap grid-width">{value.STOR_LEVEL_4}</td>
                        <td>BIN</td>
                        <td className="text-center text-width-10">
                            <div className="custom-checkbox">
                                <input className="custom-checkbox cus-check del-icon-disable" type="checkbox" id="Activechk" name="Activechk" checked={value.DELETE_STORLOC_FLG} />
                            </div>
                        </td>
                        <td data-label="BIN" className="text-center text-width-10">
                            <div className="text-center" style={{ "borderBottom": "0px solid" }}><span onClick={(e) => this.onEditClick(e, index)}><img className="px-1" src="Images/edit-icon.png" alt="Edit" title="Edit" style={{ height: '17px' }} /></span></div>
                        </td>
                    </tr>
                </> : <>
                        <tr>
                            <td className="text-nowrap " >
                                <input type="text" className={`form-control w-75 grid-width ${this.state.isTextEmpty && value.editExisAis.trim() != "" ? 'mb-4' : 'mb-1'}`} maxLength="4" placeholder="Enter Value" name="editExisAis" value={value.editExisAis} onChange={e => this.handleExistText(e, index)} />
                                {this.state.isTextEmpty && value.editExisAis.trim() == "" ?
                                    <span className="color-validation text-nowrapn"><Trans>Please enter aisle</Trans></span> : null
                                }
                            </td>
                            <td className="text-nowrap ">
                                <input type="text" className={`form-control w-75 grid-width ${this.state.isTextEmpty && value.editExisRow.trim() != "" ? 'mb-4' : 'mb-1'}`} maxLength="4" placeholder="Enter Value" name="editExisRow" value={value.editExisRow} onChange={e => this.handleExistText(e, index)} />
                                {this.state.isTextEmpty && value.editExisRow.trim() == "" ?
                                    <span className="color-validation text-nowrapn"><Trans>Please enter row</Trans></span> : null
                                }
                            </td >
                            <td className="text-nowrap">
                                <input type="text" className={`form-control w-75 grid-width ${this.state.isTextEmpty && value.editExisShf.trim() != "" ? 'mb-4' : 'mb-1'}`} maxLength="4" placeholder="Enter Value" name="editExisShf" value={value.editExisShf} onChange={e => this.handleExistText(e, index)} />
                                {this.state.isTextEmpty && value.editExisShf.trim() == "" ?
                                    <span className="color-validation text-nowrapn"><Trans>Please enter shelf</Trans></span> : null
                                }
                            </td>
                            <td className="text-nowrap">
                                <input type="text" className={`form-control w-75 grid-width ${this.state.isTextEmpty && value.editExisBin.trim() != "" ? 'mb-4' : 'mb-1'}`} maxLength="4" placeholder="Enter Value" name="editExisBin" value={value.editExisBin} onChange={e => this.handleExistText(e, index)} />
                                {this.state.isTextEmpty && value.editExisBin.trim() == "" ?
                                    <span className="color-validation text-nowrapn"><Trans>Please enter bin</Trans></span> : null
                                }
                            </td>
                            <td>BIN</td>
                            <td className="text-center text-width-10">
                                <div className="custom-checkbox">
                                    <input className="custom-checkbox cus-check" type="checkbox" id={'Activechk' + index} name="DELETE_STORLOC_FLG" checked={value.manualEdit} onChange={e => this.handleExistText(e, index)} />

                                </div>
                            </td>
                            <td data-label="BIN" className="text-center text-width-10">
                                <div className="text-center px-0 text-nowrap" style={{ "borderBottom": "0px solid" }}>
                                    <img className={`px-1 mr-1 ${this.state.isDisabled ? 'del-icon-disable' : ''}`} id={`updateIcon${index + 1}`} src="Images/green-tick-icon.svg" alt="Save" title="Save" style={{ "height": '17px', "borderBottom": "0px solid" }} onClick={(e) => this.updateClick(e, index)} />
                                    <span onClick={e => this.handleCancel(e, index, "edit")}><img src="Images/red-cross-icon.svg" alt="Cancel" title="Cancel" style={{ "height": '17px', "borderBottom": "0px solid" }} /></span></div>
                            </td>
                        </tr>
                    </>}
                </React.Fragment>

                );
            })
        }
    }

    /****************************************************************HTML parts starts from here***********************************************************************/
    render() {
        const { t } = this.props;
        return (
            <>
                <div className="container-fluid content-section">
                    <div className="row">
                        <div className="container-fluid Ord-title" >
                            <div className="row">
                                <div className="col-md-12 page-heading">
                                    <h5 className="page-title"><Trans>Material Storage Locations</Trans></h5>
                                    <div className="fav-item-maintenance-icon mt-1">
                                        <input type="checkbox" id="checkbox-OrdStatus" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                        <label htmlFor="checkbox-OrdStatus">
                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Page Heading ends here*/}
                        <div className="col-md-12 mt-2 justify-content-center">
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label className="w-100 catalog-label mt-2 mr-3" htmlFor="reason"><Trans>Business Unit</Trans><span className="color-manditory">*</span></label>
                                    <Select
                                        options={this.state.businessUnitOptions}
                                        value={this.state.businessUnitDDValue}
                                        onChange={(e) => { this.onChangeDD(e, "businessUnit") }}
                                        inputProps={{ placeholder: "Select" }}
                                    />
                                    {!this.state.isValidBusinessUnit && this.state.businessUnit == "" ?
                                        <span className="font-12 font-regular color-validation"><Trans>Please select business unit</Trans></span> : null
                                    }

                                </div>
                                <div className="col-md-3 mb-4" >
                                    <label className="w-100 catalog-label mt-2 mr-3" htmlFor="business-unit"><Trans>Area</Trans><span className="color-manditory">*</span></label>
                                    <Select
                                        options={this.state.storeAreaOptions}
                                        value={this.state.areaDDValue}
                                        onChange={(e) => { this.onChangeDD(e, "storeArea") }}
                                        isDisabled={this.state.isAreaDisabled}
                                        inputProps={{ placeholder: "Select", className: "form-control" }}
                                    />
                                    {!this.state.isValidArea && this.state.storeArea == "" ?
                                        <span className="font-12 font-regular color-validation"><Trans>Please select area</Trans></span> : null
                                    }
                                </div>
                                <div className="col-md-4 mb-4 d-flex align-items-end justify-content-md-start justify-content-end">
                                    <button className={`btn btn-primary custom-resp-btn-width custom-btn-primary primary-btn px-3 rounded  ${(this.state.businessUnit == "" && !this.state.isValidBusinessUnit) ? 'margin-bottom-text' : (this.state.storeArea == "" && !this.state.isValidArea) ? 'margin-bottom-search' : 'margin-bottom-text1'}`} type="button" onClick={() => this.searchClick()}><Trans>Search</Trans></button>
                                </div>
                                {/* search start */}
                                {this.state.isSearchResult == true ? (
                                    <>
                                        <div className="col-md-12 mt-4">
                                            <div className="d-md-flex justify-content-between">
                                                <div className="mb-3 mb-md-0">
                                                    <h4 className="font-16 font-bold m-0 mt-2"><Trans>Storage details</Trans></h4>
                                                </div>
                                                <div className="d-grid d-md-flex justify-content-md-end align-items-center grid-resp-style">
                                                    <div>
                                                        <div className="input-group dropdown-search w-100">
                                                            <div className="input-group-prepend">
                                                                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.filterDisplayName}</Trans></button>
                                                                <div className="dropdown-menu">
                                                                    {this.bindDropdown()}
                                                                </div>
                                                            </div>
                                                            <input type="text" className="form-control" placeholder={t("Search")} value={this.state.searchValue} aria-label="Text input with dropdown button" maxLength="4" onKeyDown={(e) => this.searchKeyPress(e)} onChange={(e) => { this.onChangeSearch(e.target.value) }} />
                                                            {this.state.searchValue.length > 0 ? <span className="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} > </span> : null}
                                                            <span className="dropdown-search-icon" onClick={(e) => this.handleSearch()} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-primary custom-btn-primary primary-btn px-3 ml-1 mb-0 primary-btn-responsive d-md-flex text-nowrap align-items-center" onClick={() => this.onNewClick()}><img className="mr-2" src="images/add-icon.svg" alt="add-icon" /><Trans>New Location</Trans></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="table-responsive mt-2 mb-3 p-3">
                                            <table className="table split-delv-table FOM-grid mobileresponsive-grid">
                                                <thead>
                                                    <tr>
                                                        <th className="position-sort-icon pr-4"><Trans>Aisle</Trans><span className="sort-up ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_1", "ASC") }}><img src="Images/sort2.svg" alt="sort-up" /></span><span className="sort-down ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_1", "DESC") }}><img src="Images/sort1.svg" alt="sort-down" /></span></th>
                                                        <th className="position-sort-icon pr-4"><Trans>Row</Trans><span className="sort-up ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_2", "ASC") }}><img src="Images/sort2.svg" alt="sort-up" /></span><span className="sort-down ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_2", "DESC") }}><img src="Images/sort1.svg" alt="sort-down" /></span></th>
                                                        <th className="position-sort-icon pr-4"><Trans>Shelf</Trans><span className="sort-up ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_3", "ASC") }}><img src="Images/sort2.svg" alt="sort-up" /></span><span className="sort-down ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_3", "DESC") }}><img src="Images/sort1.svg" alt="sort-down" /></span></th>
                                                        <th className="position-sort-icon pr-4"><Trans>Bin</Trans><span className="sort-up ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_4", "ASC") }}><img src="Images/sort2.svg" alt="sort-up" /></span><span className="sort-down ml-2" onClick={() => { this.handleSorting("STOR_LEVEL_4", "DESC") }}><img src="Images/sort1.svg" alt="sort-down" /></span></th>
                                                        <th className="position-sort-icon"><Trans>Location type</Trans></th>
                                                        <th className="text-center text-width-10"><Trans>Active</Trans></th>
                                                        <th className="text-center text-width-10"><Trans>Action</Trans></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        this.state.isNewLoc ?
                                                            <tr>
                                                                <td className="text-nowrap">
                                                                    <input className={`form-control w-75 grid-width ${this.state.isTextEmpty && this.state.newLocAis.trim() != "" ? 'mb-4' : 'mb-1'}`} type="text" placeholder={t("Enter value")} maxLength="4" name="newLocAis" value={this.state.newLocAis} onChange={e => this.handleNewText(e, "newLocAis")} />
                                                                    {this.state.isTextEmpty && this.state.newLocAis.trim() == "" ?
                                                                        <span className="color-validation text-nowrapn"><Trans>Please enter aisle</Trans></span> : null
                                                                    }
                                                                </td>
                                                                <td className="text-nowrap">
                                                                    <input className={`form-control w-75 grid-width ${this.state.isTextEmpty && this.state.newLocRow.trim() != "" ? 'mb-4' : 'mb-1'}`} type="text" placeholder={t("Enter value")} maxLength="4" name="newLocRow" value={this.state.newLocRow} onChange={e => this.handleNewText(e, "newLocRow")} />
                                                                    {this.state.isTextEmpty && this.state.newLocRow.trim() == "" ?
                                                                        <span className="color-validation text-nowrapn"><Trans>Please enter row</Trans></span> : null
                                                                    }
                                                                </td>
                                                                <td className="text-nowrap ">
                                                                    <input className={`form-control w-75 grid-width ${this.state.isTextEmpty && this.state.newLocShf.trim() != "" ? 'mb-4' : 'mb-1'}`} type="text" placeholder={t("Enter value")} maxLength="4" name="newLocShf" value={this.state.newLocShf} onChange={e => this.handleNewText(e, "newLocShf")} />
                                                                    {this.state.isTextEmpty && this.state.newLocShf.trim() == "" ?
                                                                        <span className="color-validation text-nowrapn"><Trans>Please enter shelf</Trans></span> : null
                                                                    }
                                                                </td>
                                                                <td className="text-nowrap ">
                                                                    <input className={`form-control w-75 grid-width ${this.state.isTextEmpty && this.state.newLocBin.trim() != "" ? 'mb-4' : 'mb-1'}`} type="text" placeholder={t("Enter value")} maxLength="4" name="newLocBin" value={this.state.newLocBin} onChange={e => this.handleNewText(e, "newLocBin")} />
                                                                    {this.state.isTextEmpty && this.state.newLocBin.trim() == "" ?
                                                                        <span className="color-validation text-nowrapn"><Trans>Please enter bin</Trans></span> : null
                                                                    }
                                                                </td>
                                                                <td>BIN</td>
                                                                <td className="text-center text-width-10">
                                                                    <input className="custom-checkbox cus-check" type="checkbox" name="DELETE_STORLOC" checked={this.state.DELETE_STORLOC} onChange={e => this.handleNewText(e, "DELETE_STORLOC")} />
                                                                </td>
                                                                <td data-label="BIN" className="text-center text-width-10">
                                                                    <div className="text-center px-0 text-nowrap" style={{ "borderBottom": "0px solid" }}>
                                                                        <img className="px-1 mr-1" src="Images/green-tick-icon.svg" alt="Save" title="Save" style={{ "height": '17px', "borderBottom": "0px solid" }} onClick={e => this.insertClick()} />
                                                                        <span onClick={e => this.handleCancel(e, "", "new")}><img src="Images/red-cross-icon.svg" alt="Cancel" title="Cancel" style={{ "height": '17px', "borderBottom": "0px solid" }} /></span></div>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    }
                                                    {this.state.gridData.length > 0 ? this.bindGridData() : null
                                                    }
                                                </tbody>
                                            </table>
                                            {this.state.gridData.length === 0 ?
                                                <span>
                                                    <section className="site-undercontruction container text-center center-panel pt-4">
                                                        <div className="img-section">
                                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                                        </div>
                                                        <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                                        </p>
                                                    </section>
                                                </span> : null}
                                        </div>
                                        <div className="col-md-12">
                                            {this.state.count > 0 ? <>
                                                <div class="d-flex justify-content-between table-footer-responsive">
                                                    <div>
                                                        <span className="total-records mt-2 d-inline-block">
                                                            <Trans>Number of records</Trans><span> :</span><span className="ml-2">{this.state.count}</span>
                                                        </span>
                                                    </div>
                                                    {this.state.count > this.state.reqCount ? <div> <ReactPaginate
                                                        previousLabel={<span className="right-pagination-arrow" />}
                                                        nextLabel={<span className="left-pagination-arrow" />}
                                                        previousClassName={'page-item'}
                                                        nextClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextLinkClassName={'page-link'}
                                                        disabledClassName={'disabled'}
                                                        breakLabel={'...'}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link'}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={2}
                                                        onPageChange={this.handlePagination}
                                                        containerClassName={'custom-pagination pagination mb-5'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        activeClassName={'active'}
                                                        hrefBuilder={() => '#'}
                                                        forcePage={this.state.pageNo - 1}
                                                    /></div> : null}
                                                </div></> : null}
                                        </div>

                                    </>
                                ) : null}
                            </div>

                        </div>
                    </div>
                </div>
                {/*Modal Starts here*/}
                <div className="modal fade success-customer" id="exp-popup" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                        {this.state.isPopupImage ?
                                            <img className="mb-3" src="images/tick.svg" alt="tick" /> :
                                            <img className="mb-3" src="images/alert-Icon.svg" alt="alert" />
                                        }
                                        <p className="mb-1 font-14 font-regular color-light-black"><Trans>{this.state.popupMessage}</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            {/*button*/}
                            <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                {this.state.isPageLoad ?
                                    <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={() => this.pageLoad("Search", this.state.businessUnit, 1, "", "", "")} ><Trans>OK</Trans></button>
                                    :
                                    <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={() => this.pageLoad("search", this.state.businessUnit, this.state.pageNo, this.state.sortColumn, this.state.sortValue, searchTextGlobVar)} ><Trans>OK</Trans></button>}
                            </div>
                            {/*button*/}
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}
            </>
        );
    };

}
export default withTranslation()(MaterialStorageLocComponent);