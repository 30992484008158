
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as CookieService from '../CookieService';
import * as $ from 'jquery';

const pageLoad = (AddNewPunchinUserStoreBO) => {
    try {
        debugger;
        SDISVC.callGateway(
            SDIConstants.moduleNames.AddNewPunchinUser, 
            SDIConstants.methodNames.AddNewPunchinUser_Adduser,
            JSON.stringify(AddNewPunchinUserStoreBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;       
                        let contentData = { type: SDIConstants.methodNames.AddNewPunchinUser_Adduser, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const punchinsession = (PunchinBO) => {
    try {
        debugger;
        SDISVC.callGateway(
            SDIConstants.moduleNames.AddNewPunchinUser,
            SDIConstants.methodNames.AddNewPunchinUser_submit,
            JSON.stringify(PunchinBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;  
                        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
                        let contentData = { type: SDIConstants.methodNames.AddNewPunchinUser_submit, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
/**
 * Ref: LN_PC_17
 * 
 * loginClick method gets called from the loginClick() of the component.
 * It takes a parameters loginClickBO which is passed from the component file.
 * @param loginClickBO
 */
//****************************Punchin********************************************
const punchinClick = (loginClickBO) => {
    try {
        SDISVC.punchin(
            loginClickBO,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        CookieService.setCookieValues(SDIConstants.access_token, data.response.access_token, 30);
                        let userData = JSON.parse(data.response);
                        userData = userData.sessiontable
                        if (userData.length > 0) {
                            userData = userData[0];
                        }
                        console.log(userData)
                        //[11/26/21, CAS - 31, Dhevanesam R]
                        let newLang = CookieService.getLocalStorageValues("newlanguage");
                        console.log("logaclang1", newLang);
                        let localStorageSuccess = CookieService.insertLocalStorageValues(userData);
                        CookieService.updateLocalStorageValues("newlanguage", newLang);
                        console.log("logaclang", CookieService.getLocalStorageValues("newlanguage"));                        
                        console.log("Session_UserID", CookieService.getLocalStorageValues("Session_UserID"));
                        if (localStorageSuccess) {

                            let tokenUpdateBO = {
                                userID: CookieService.getLocalStorageValues("Session_UserID"),
                                busUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                                accessMode: 2,
                                deviceInfo: "WebAPP",
                                expirySeconds: data.response.expires_in
                            }
                            SDISVC.callSecureGateway(
                                SDIConstants.moduleNames.Login,
                                SDIConstants.methodNames.Login_TokenUpdate,
                                JSON.stringify(tokenUpdateBO),
                                (data) => {
                                    if (data.success) {
                                        if (data.response != "" && data.response) {
                                            console.log(data.response);
                                            let contentData = { type: SDIConstants.methodNames.Login_TokenUpdate, response: data.response };
                                            Dispatcher.dispatch(contentData);
                                        }
                                    }
                                });
                        }
                    }
                } else if (data.success == false) {
                    let contentData = { type: "Login_Error", response: data.error.responseText };
                    Dispatcher.dispatch(contentData);
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
//*******************************************
//******don't forget to remove the exporting part**************************
export { pageLoad, punchinsession, punchinClick}