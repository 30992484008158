import React, { Component } from 'react';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
import * as CookieService from '../CookieService';
import * as PunchInRFQConfirmAction from '../actions/PunchInConfirmAction';
import PunchInRFQConfirmStore from '../stores/PunchInRFQConfirmStore'
//[PC_07, 09/07/21, Author - Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
class PunchInRFQConfirm extends Component {
    constructor(props) {
        super(props);
        this.BuildConfirmPage = this.BuildConfirmPage.bind(this)
        this.state = {
            REQIDLabel: "", lblCartHeadCust: "", EmployeeName: "", lblworkorder: "", lblSubDate: "", Notes: "", Session_cart: [],
        }
    }
    componentDidMount() {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        const queryParams = new URLSearchParams(window.location.search)
        const QS_src = queryParams.get('REQID');
        let PunchInRFQConfirmBO = {
            UserID: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
            OrderNum: QS_src,
            UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
            SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_TestMode: CookieService.getLocalStorageValues("Session_TESTMODE"),
            Session_ARRParams: CookieService.getLocalStorageValues("ARRParams")
        }
        PunchInRFQConfirmAction.pageLoad(PunchInRFQConfirmBO)
        PunchInRFQConfirmStore.on('PageLaod', this.BuildConfirmPage);
    }
    componentWillUnmount() {
        PunchInRFQConfirmStore.removeListener('PageLaod', this.BuildConfirmPage);
    }
    BuildConfirmPage = () => {
        debugger
        //[4/22/22, CAS - 31, Dhevanesam R]
        const { t } = this.props;
        let ConfirmLoad = JSON.parse(PunchInRFQConfirmStore.ResponseValue).AlertTable;
        if (ConfirmLoad != null && ConfirmLoad != undefined && ConfirmLoad != []) {
            if (ConfirmLoad.length > 0) {
                if (ConfirmLoad[0].TestActivate != null && ConfirmLoad[0].TestActivate != undefined && ConfirmLoad[0].TestActivate != "") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(ConfirmLoad[0].TestActivate)
                    })
                    //alert(t(ConfirmLoad[0].TestActivate))
                }
                else if (ConfirmLoad[0].NotValidApprover != null && ConfirmLoad[0].NotValidApprover != undefined && ConfirmLoad[0].NotValidApprover != "") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(ConfirmLoad[0].NotValidApprover)
                    })
                    //alert(t(ConfirmLoad[0].NotValidApprover))
                }
                else if (ConfirmLoad[0].OrderLimit != null && ConfirmLoad[0].OrderLimit != undefined && ConfirmLoad[0].OrderLimit != "") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(ConfirmLoad[0].OrderLimit)
                    })
                    //alert(t(ConfirmLoad[0].OrderLimit))
                }
            }
        }
        let CartPrint = CookieService.getLocalStorageValues("CartPrint");
        let Session_cart = CookieService.getLocalStorageValues("Session_Cart");
        const queryParams = new URLSearchParams(window.location.search)
        const QS_src = queryParams.get('SHIPTO');
        const REQID = queryParams.get('REQID');
        let CoName = CookieService.getLocalStorageValues("Session_CONAME") != null && CookieService.getLocalStorageValues("Session_CONAME") != undefined ? CookieService.getLocalStorageValues("Session_CONAME") : " "
        let lblCartHeadCust = QS_src != null && QS_src != undefined ? CoName + " " + QS_src : CoName
        let REQIDLabel = REQID
        let EmployeeName = ""
        let lblworkorder = CookieService.getLocalStorageValues("ShopCWrkOrd");
        let lblSubDate = new Date();
        lblSubDate.setDate(lblSubDate.getDate())
        let SubDate = this.handledate(lblSubDate);
        SubDate = SubDate + "  " + lblSubDate.getHours() + ":" + lblSubDate.getMinutes() + ":" + lblSubDate.getSeconds()
        let Notes = ""
        if (CookieService.getLocalStorageValues("Session_SDIEMP") != null && CookieService.getLocalStorageValues("Session_SDIEMP") != undefined) {
            if (CookieService.getLocalStorageValues("Session_SDIEMP") == "SDI" && CookieService.getLocalStorageValues("Session_AGENTUSERID") == "") {
                if (CartPrint != null && CartPrint != undefined && CartPrint != []) {
                    if (CartPrint.length > 0) {
                        EmployeeName = CartPrint[0].Cartempid
                    }
                    else {
                        EmployeeName = CookieService.getLocalStorageValues("Session_USERNAME")
                    }
                }
                else {
                    EmployeeName = CookieService.getLocalStorageValues("Session_USERNAME")
                }
            }
            else {
                let UserDetails = JSON.parse(PunchInRFQConfirmStore.ResponseValue).UserDetails
                let FirstName = UserDetails[0].FIRST_NAME_SRCH == null || UserDetails[0].FIRST_NAME_SRCH == undefined || UserDetails[0].FIRST_NAME_SRCH == "" ? " " : UserDetails[0].FIRST_NAME_SRCH
                let LastName = UserDetails[0].LAST_NAME_SRCH == null || UserDetails[0].LAST_NAME_SRCH == undefined || UserDetails[0].LAST_NAME_SRCH == "" ? " " : UserDetails[0].LAST_NAME_SRCH
                EmployeeName = FirstName + " " + LastName;
            }
        }
        Notes = CookieService.getLocalStorageValues("Session_SCCartLineNotes")
        if (CartPrint != null && CartPrint != undefined && CartPrint != []) {
            if (CartPrint.length > 0) {
                Notes = CartPrint[0].Cartnotes
            }
        }
        if (Notes == null || Notes == undefined || Notes == "") {
            Notes = CookieService.getLocalStorageValues("Session_SCCartLineNotes")
        }
        window.scrollTo(0, 0)
        this.setState({
            Session_cart: Session_cart, Notes: Notes, EmployeeName: EmployeeName,
            lblSubDate: SubDate, lblworkorder: lblworkorder, REQIDLabel: REQIDLabel, lblCartHeadCust: lblCartHeadCust
        });
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    handledate = (date) => {
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear())
    }
    CartTable = () => {
        const { t } = this.props;
        if (this.state.Session_cart != null && this.state.Session_cart != undefined && this.state.Session_cart != []) {
            return this.state.Session_cart.map((val, index) => {
                let SiteCurrencySymbol = CookieService.getLocalStorageValues("SiteCurrencySymbol")
                let Pricevalue = val.Price
                let ItemTotal = 0
                if (Pricevalue != "Price on Request") {
                    Pricevalue = parseFloat(Pricevalue) ? parseFloat(Pricevalue) : 0
                    let QuantityAvail = parseFloat(val.Quantity) ? parseFloat(val.Quantity) : 0
                    if (Pricevalue != NaN && QuantityAvail != NaN && Pricevalue != 0 && QuantityAvail != 0) {
                        ItemTotal = Pricevalue * QuantityAvail;
                        ItemTotal = ItemTotal.toFixed(2)
                        ItemTotal = SiteCurrencySymbol + " " + ItemTotal
                        //ItemTotal = uniteprice;
                    }
                    let roundOffvalue = Pricevalue.toFixed(2)
                    Pricevalue = SiteCurrencySymbol + " " + roundOffvalue
                }
                else {
                    Pricevalue = t("Price on request")
                    ItemTotal = t("Price on request")
                }
                debugger
                let PricePO = parseFloat(val.PricePO) ? parseFloat(val.PricePO) : 0
                if (PricePO != "Price on Request" && PricePO != 0 && PricePO != "0") {
                    let roundOffvalue = PricePO.toFixed(2)
                    PricePO = SiteCurrencySymbol + " " + roundOffvalue
                }
                else {
                    PricePO = ""
                }

                return (<tr>
                    <td>{val.Itemid}</td>
                    <td>{val.ItemDescription}</td>
                    <td>{val.Manufacturer}</td>
                    <td>{val.Manufacturerpartnumber}</td>
                    <td>{val.UOM}</td>
                    <td>{val.Quantity}</td>
                    <td>{Pricevalue}</td>
                    <td>{ItemTotal}</td>
                    <td>{PricePO}</td>
                    <td>{val.ItemChgCode}</td>
                    <td>{val.EmpChgCode}</td>
                    <td>{val.RFQReq}</td>
                    <td>{val.MachineRow}</td>
                    <td>{val.TaxFlag}</td>
                    <td>{val.UDueDate}</td>
                </tr>)
            });
        }
    }
    OrderTotalCal = () => {

        let SiteCurrency = CookieService.getLocalStorageValues("SiteCurrencySymbol");
        let ItemTotal = 0;
        let OrderTotal = 0;
        let cartcount = this.state.Session_cart.length;
        if (cartcount != 0) {
            return this.state.Session_cart.map((val, index) => {
                let uniteprice = parseFloat(val.Price) ? parseFloat(val.Price) : 0
                let QuantityAvail = parseFloat(val.Quantity) ? parseFloat(val.Quantity) : 0
                if (val.Price.toUpperCase() != "PRICE ON REQUEST") {
                    if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
                        ItemTotal = uniteprice * QuantityAvail;
                        //ItemTotal = uniteprice;
                    }
                    OrderTotal = OrderTotal + ItemTotal;
                }
                let loopindex = index + 1;
                if (loopindex == cartcount) {
                    let roundOffvalue = OrderTotal.toFixed(2)
                    return (SiteCurrency + " " + roundOffvalue);
                }
            });
        }
        else {
            if (SiteCurrency != undefined && SiteCurrency != null && SiteCurrency != [] && SiteCurrency != "") {
                return (SiteCurrency[0].Symbol + " " + "0.00");
            }
        }

    }
    Redirect = (e, type) => {
        if (e) {
            e.preventDefault();
        }
        if (type == 1) {
            window.print();
        }
        else {
            this.props.history.push({
                pathname: `\HomePage`,
            });
        }
    }
    render() {
        //[11/26/21, CAS - 31, Priyanka D]
        //[4/22/22, CAS - 31, Dhevanesam R]
        return (
            <div className="container-fluid content-section mb-0">
                <div className="row">
                    {/* Loading starts */}
                    <div className="container-fluid content-margin mb-0">
                        <div className="row">
                            <div className="col-md-12 mx-auto d-block mt-3">
                                <h4 className="text-center"><Trans>SDI Marketplace</Trans></h4>
                            </div>
                            <div className="col-md-12 mx-auto d-block mt-3">
                                <h4 className="text-center"><Trans>SDIExchange - Request for Quote</Trans></h4>
                            </div>
                            <div className="col-md-7 Catalog-Items">
                                <div className="col-md-12 mb-2">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 Ipad-UI fav-maintence">
                                            <label className="catalog-label">{this.state.lblCartHeadCust}</label>
                                           
                                        </div>
                                        <div className="col-lg-12 col-md-12 Ipad-UI fav-maintence">
                                            <label className="catalog-label"><Trans>SDI Quote Reference Number</Trans>:</label>
                                            <label className="item-details-value pl-1">{this.state.REQIDLabel}</label>
                                        </div>
                                        <div className="col-lg-12 col-md-12 Ipad-UI fav-maintence">
                                            <label className="catalog-label"><Trans>Employee Name</Trans>:</label>
                                            <label className="item-details-value pl-1">{this.state.EmployeeName}</label>
                                        </div>
                                        <div className="col-lg-12 col-md-12 Ipad-UI fav-maintence">
                                            <label className="catalog-label"><Trans>Work Order Number</Trans>:</label>
                                            <label className="item-details-value pl-1">{this.state.lblworkorder}</label>
                                        </div>
                                        <div className="col-lg-12 col-md-12 Ipad-UI fav-maintence">
                                            <label className="catalog-label"><Trans>Submit Date</Trans>:</label>
                                            <label className="item-details-value pl-1">{this.state.lblSubDate}</label>
                                        </div>
                                        <div className="col-md-12 fav-maintence">
                                            <label className="catalog-label w-100"><Trans>Notes</Trans>:</label>
                                            <div className="col-lg-12 col-md-12 pl-0">
                                                <textarea className="form-control" maxLength="254" id="CusNotes" rows={4} value={this.state.Notes} readOnly={true} />
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="table-responsive ml-1 mb-1">
                                <div className="row">
                                    <div className="col-md-12 mx-auto d-block">
                                        <table className="table table-hover ZEUS_Grid usage-grid">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="position-sort-icon"><Trans>Item Number</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Description</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Manufacturer</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Manufacturer Part Number</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>unitofmeasure_key</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Quantity</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Price</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Ext Price</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Estimated Price</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Charge Code</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Requestor Name</Trans></th>
                                                    <th scope="col" className="position-sort-icon">RFQ</th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Asset ID</Trans></th>{/*PS_AID_FE_02-Zeus 122- Machine Number should changed to Asset ID-Sathis*/}
                                                     <th scope="col" className="position-sort-icon"><Trans>Tax Exempt</Trans></th>
                                                    <th scope="col" className="position-sort-icon"><Trans>Due Date</Trans></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.CartTable()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 col-md-12">
                                <div className="col-md-12">
                                    <div className="float-right shopping-order Font-Size16">
                                        <Trans>Order Total</Trans> <span className="price-rate">{this.OrderTotalCal()}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 col-md-12">
                                <div className="row mx-0 col-md-5 mt-3">
                                    <div className="col-md-12 pl-0">
                                        <div className="float-right shopping-order Font-Size16">
                                            <Trans>Approved By</Trans>: ____________________________
                                    </div>
                                    </div>
                                </div>
                                <div className="row mx-0 col-md-5 mt-3">
                                    <div className="col-md-12 pl-0">
                                        <div className="float-right shopping-order Font-Size16">
                                            <Trans>Received By</Trans>: ________________________________
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/*[5/10/22, CAS - 31, Dhevanesam R]*/} 
                            <div className="col-md-12 mx-auto d-block mt-3">
                                <h5 className="text-center"><Trans>Thank you for choosing</Trans> SDI "<Trans>Your MRO Source</Trans>"</h5>
                            </div>
                            <div className="col-md-12 mx-auto d-block mt-2 text-center">
                                <h7 className="text-center"><Trans>We will notify you once your quote is ready</Trans>.</h7>
                            </div>
                            <div className="row mx-0 col-md-12 mt-3 mb-5">
                                <div className="row mx-0 col-md-1">
                                    <div className="col-md-12 pl-0">
                                        <a href="#" className="float-left my-2 category-search-id Font-Size16 itemHyperlink Font-weight5" onClick={(e) => this.Redirect(e, 1)}>
                                            <Trans>Print</Trans></a>
                                    </div>
                                </div>
                                <div className="row mx-0 col-md-3">
                                    <div className="col-md-12 pl-0">
                                        <a href="#" className="float-left my-2 category-search-id Font-Size16 itemHyperlink Font-weight5" onClick={(e) => this.Redirect(e, 0)}>
                                            <Trans>Continue(No Print)</Trans></a>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-md-12 row mx-0 ml-5 mt-3">
                                <div className="col-md-12 ml-5">
                                    <a href="#" className="float-left my-2 category-search-id Font-Size16 itemHyperlink Font-weight5" onClick={(e) => this.Redirect(e, 1)}>
                                        Print</a>
                                </div>
                            </div>
                            <div className="col-md-12 row mx-0 ml-5 mt-1 mb-3">
                                <div className="col-md-12 ml-5">
                                    <a href="#" className="float-left my-2 category-search-id Font-Size16 itemHyperlink Font-weight5" onClick={(e) => this.Redirect(e, 0)}>
                                        Continue (No print)</a>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>)
    }
}
//[PC_07, 09/09/21, Author -  Priyanka D]
export default withTranslation()(PunchInRFQConfirm);


