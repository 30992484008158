import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';

class BinToBinStore extends EventEmitter {
	SearchItemData = "";
	TransferResData = "";
	updateFavPageResponse = { updateType: 0, responseStr: "" };

	handleBinToBinStore = (action) => {
		switch (action.action.type) {
			case "SearchItem": {
				this.SearchItemData = action.action.response;
				debugger;
				this.emit('SearchItem');
				break;
			}
			case "TransferConfSubmit": {
				this.TransferResData = action.action.response;
				debugger;
				this.emit('TransferConfSubmit');
				break;
			}
			case "NOTIFY_InsertFavPage": {

				this.updateFavPageResponse.updateType = 1;
				this.updateFavPageResponse.responseStr = action.action.response;
				this.emit('updateFavPage');
				break;
			}
			case "NOTIFY_DeleteFavPage": {
				this.updateFavPageResponse.updateType = 2;
				this.updateFavPageResponse.responseStr = action.action.response;
				this.emit('updateFavPage');
				break;
			}
			case "NOTIFY_CurrentFavPage": {
				this.currentFavPageResponse = action.action.response;
				this.emit('favpage');
				break;
			}
		}
	}
}
const objBinToBinStore = new BinToBinStore;
Dispatcher.register(objBinToBinStore.handleBinToBinStore.bind(objBinToBinStore));
export default objBinToBinStore;