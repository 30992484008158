import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class ItemDetailNewStore extends EventEmitter {
    TabOneRespList = "";
    addToCartList = "";
    TabClickList = "";
    FavitemAddList = "";
    ItemDataLoadList = "";
    handleItemDetailNewStore = (action) => {
        switch (action.action.type) {
            case "TabOneResp": {
                this.TabOneRespList = action.action.response;
                this.emit('TabOneResp');
                break;
            }
            case "ItemDataLoad": {
                this.ItemDataLoadList = action.action.response;
                this.emit('ItemDataLoad');
                break;
            }
            case "addToCart": {
                this.addToCartList = action.action.response;
                this.emit('addToCart');
                break;
            }
            case "TabClick": {
                this.TabClickList = action.action.response;
                this.emit('TabClick');
                break;
            }
            case "FavitemAdd": {
                this.FavitemAddList = action.action.response;
                this.emit('FavitemAdd');
                break;
            }            
        }
    }
}
const objItemDetailNewStore = new ItemDetailNewStore;
Dispatcher.register(objItemDetailNewStore.handleItemDetailNewStore.bind(objItemDetailNewStore));
export default objItemDetailNewStore;
