/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 08/12/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: QI_PC_16
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
/**
 * Ref: QI_PC_17
 * 
 * getQuickItemDetails method gets called from the componentDidMount() of the component.
 * It takes a parameters getquickItemBO which is passed from the component file.
 * @param getquickItemBO
 */
const getQuickItemDetails = async (getquickItemBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_getcpdata,
            JSON.stringify(getquickItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.NonCatalog_getcpdata, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: QI_PC_18
 * 
 * submitQuickItem method gets called on click of submit button of quick item popup
 * It takes a parameters submitQuickItemBO which is passed from the component file.
 * @param submitQuickItemBO
 */

const submitQuickItem = async (submitQuickItemBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_SubmitQuickEntry,
            JSON.stringify(submitQuickItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.NonCatalog_SubmitQuickEntry, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const ShopCartsubmitQuickItem = async (submitQuickItemBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_SubmitQuickEntry,
            JSON.stringify(submitQuickItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.ShoppingCart_SubmitQuickEntry, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export { getQuickItemDetails, submitQuickItem, ShopCartsubmitQuickItem}