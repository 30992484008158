/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Sushmitha M
 * Crated Date: 08/11/2020
 * 
 * Ref: SC_PC_73
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class ShoppingCartStore extends EventEmitter {
    CartitemList = "";
    UpdateMessage = "";
    UpdateMessageOL = "";
    FavItemDetailsAry = "";
    FavItemUpdateAry = "";
    deleteItemAry = "";
    PlaceOrderMsge = "";
    OrderPDFMsge = "";
    quickItemcheck = "";
    attachmentResponse = "";
    removeAttachmentResponse = "";
    CancelOrderMsge = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    currentFavPageResponse = "";
    TaxValue = "";
    WalletValue = "";
    RemoveCardValue = "";
    ProcessPaymentBO = "";
    GetchargeCOdeBO = "";
    AddCardDetailsBO = "";
    AddressValidation = "";
    graingerdata = "";
    DefaultAddress = "";
    woValidationData = "";
    favOrderImg = "";
    favOrderRemoveAttachments = "";
    /**
   * Ref: SC_PC_74
   * 
   * Declare the store call that extends "EventsEmitter"
   * Handle the response from action to component
   */
    handleShoppingCartStore = (action) => {
        debugger
        switch (action.action.type) {
            case "Cartitem": {
                this.CartitemList = action.action.response;
                this.emit('Cartitem');
                break;
            }
            case "UpdateStatus": {
                this.UpdateMessage = action.action.response;
                this.emit('UpdateStatus');
                break;
            }
            case "UpdateStatusOL": {
                this.UpdateMessageOL = action.action.response;
                this.emit('UpdateStatusOL');
                break;
            }
            case "FavItemDetails": {
                this.FavItemDetailsAry = action.action.response;
                this.emit('FavItemDetails');
                break;
            }
            case "FavItemUpdate": {
                this.FavItemUpdateAry = action.action.response;
                this.emit('FavItemUpdate');
                break;
            }
            case "deleteItem": {
                this.deleteItemAry = action.action.response;
                this.emit('deleteItem');
                break;
            }
            case "PlaceOrder": {
                this.PlaceOrderMsge = action.action.response;
                this.emit('PlaceOrder');
                break;
            }
            case "CancelOrder": {
                this.CancelOrderMsge = action.action.response;
                this.emit('CancelOrder');
                break;
            }
            case "OrderPDF": {
                this.OrderPDFMsge = action.action.response;
                this.emit('OrderPDF');
                break;
            }
            case "ShopCart_QuickItemCheck": {
                this.quickItemcheck = action.action.response;
                this.emit('quickItemCheck');
                break;
            }
            case "ShopCart_UploadAttachments": {
                this.attachmentResponse = action.action.response;
                this.emit('attachmentResponse');
                break;
            }
            case "ShopCart_RemoveAttachment": {
                this.removeAttachmentResponse = action.action.response;
                this.emit('rmvAttach');
                break;
            }
            case "ShopCart_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "ShopCart_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "ShopCart_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "TaxValue": {
                this.TaxValue = action.action.response;
                this.emit('TaxValue');
                break;
            }
            case "WalletValue": {
                this.WalletValue = action.action.response;
                this.emit('WalletValue');
                break;
            }
            case "RemoveCardValue": {
                this.RemoveCardValue = action.action.response;
                this.emit('RemoveCardValue');
                break;
            }
            case "ProcessPaymentBO": {
                this.ProcessPaymentBO = action.action.response;
                this.emit('ProcessPaymentBO');
                break;
            }
            case "AddCardDetailsBO": {
                this.AddCardDetailsBO = action.action.response;
                this.emit('AddCardDetailsBO');
                break;
            }
            case "BuildChrCD": {
                this.GetchargeCOdeBO = action.action.response;
                this.emit('GetchargeCOdeBO');
                break;
            }
            //[3/15/2023]CAS-44 ValidAddress case will handle the response of AddressValidate method in shopping cart BL and emit it to AddressValidation string of shoppingcart component
            case "ValidAddress": {
                this.AddressValidation = action.action.response;
                this.emit('ValidAddress');
                break;
            }
            //[5/7/23, PC_ZE_85_APP_14, Dhevanesam R]
            case "grainger": {
                this.graingerdata = action.action.response;
                this.emit('grainger');
                break;
            }
            //Z-102 Default address enable changes
            case "SetDefaultAddress": {
                this.DefaultAddress = action.action.response;
                this.emit('SetDefaultAddress');
                break;
            }
            //[15/05/23, PS_EWVB_05, Zeus 112, Sathis. N]- EMCOR WO validation
            case "workorderValidate": {
                this.woValidationData = action.action.response;
                this.emit('workorderValidate');
                break;
            }
            case "favOrderAttachment": {
                this.favOrderAttachment = action.action.response;
                this.emit('favOrderAttachment');
                break;
            }
            case "favOrderRemoveAttachments": {
                this.favOrderRemoveAttachments = action.action.response;
                this.emit('favOrderRemoveAttachments');
                break;
                break;
            }

        }
    }

}

const objShoppingCartStore = new ShoppingCartStore;
Dispatcher.register(objShoppingCartStore.handleShoppingCartStore.bind(objShoppingCartStore));
export default objShoppingCartStore;