import React, { Component } from 'react';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
//[PC_07, 09/07/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
const renderAccessDenied = (props) => {
    if (!$(`#${SDIConstants.SDILoader}`).hasClass("hide")) {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    return (<div className="container-fluid content-section">
        <div className="row">
            <section className="site-undercontruction container text-center center-panel">
                <p className="primaryinfo"><Trans>You don't have Permission to</Trans></p>
                <p className="secondaryinfo"><Trans>Access this page</Trans></p>
                <div className="img-section">
                    <img src="/images/denied.jpg" alt="imagefile" />
                </div>
            </section>
        </div>
    </div>)
}
const AcessDeniedComponent = renderAccessDenied;
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(AcessDeniedComponent);