//[5/18/2022]Zeus 107 auth0 - provider -with-history has client ID, domain name details of react Auth0 SDK and redirect URI(URL of application with which the auth0 SDK must interact) - Poornima S

import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { withRouter } from "react-router-dom";

class Auth0ProviderWithHistory extends React.Component {


    render() {
        return (
            <Auth0Provider
                domain="sdi-dev1.us.auth0.com"
                clientId="Zdf8h9OqpjmgU3wDNCZ22RyP7gfOYTSH"
                redirectUri="https://sdizeus2.sdi.com" 
            >
                {this.props.children}
            </Auth0Provider>
        );
    }
}

export default withRouter(Auth0ProviderWithHistory);