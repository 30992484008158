//[PC_01, 09/13/21, Author - Dhevanesam R]
import React, { Component } from 'react';

import Modal from 'react-awesome-modal';
import * as CookieService from '../CookieService';
import * as ReceiveItemsAction from '../actions/ReceiveItemsAction';
import ReceiveItemsStore from '../stores/ReceiveItemsStore';
import Autocomplete from 'react-autocomplete';
import Select from 'react-select';
import * as $ from 'jquery';
//import InputNumber from 'react-input-number';
import * as MenuAction from '../actions/MenuAction';
import SDIConstants from '../SDIConstants';
//import { MultiSelect } from 'multiselect-react-dropdown';
//import MultiSelect from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { DropdownMultiselectModule } from 'ng2-dropdown-multiselect';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { PODetail } from '../actions/OrderStatusAction';
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
//[11/23/21, PC_RI_02, CAS - Receive Items Panel Speed Issue, Dhevanesam R]
//import WindowedSelect from "react-windowed-select";
let currentPagePath;

//[11/26/21, CAS - 31, Dhevanesam R]
class ReceiveItemsComponent extends Component {
    constructor(props) {
        super(props);
        this.GetSiteDropDownData = this.GetSiteDropDownData.bind(this);
        this.ReceivingPageLoad = this.ReceivingPageLoad.bind(this);
        this.MMReceivingPODetail = this.MMReceivingPODetail.bind(this);
        this.GetPOAction = this.GetPOAction.bind(this);
        this.SumbitReceivingResponse = this.SumbitReceivingResponse.bind(this);
        this.SubmitReceive = this.SubmitReceive.bind(this);
        this.cancel = this.cancel.bind(this);
        this.state = {
            ShiptoList: [],
            VendorList: [],
            POList: [],
            defaultShipto: [],
            defaultVendor: [],
            defaultPO: [],
            duration: "0",
            POID: "",
            PODetailList: [],
            POTitleList: [],
            ShipviaList: [],
            query: '',
            targetPO: "",
            accordion: false,
            Response: [],
            //[09/21/21, PC_01, Dhevanesam R, Priyanka D] 
            defaultSite: [],
            SiteList: [],
            //[09/22/21, PC_07, Dhevanesam R, Priyanka D] 
            ImageArray: [],
            //[11/16/21, PC_RI_01, Bug - 1765, Dhevanesam R]
            sixMonths: true,
            twelveMonths: false,
            twoYear: false,
            corpBUList: ""
        }
    }
    componentWillUnmount() {
        //[09/22/21, PC_13, Dhevanesam R, Priyanka D] 
        ReceiveItemsStore.removeListener('GetData', this.GetSiteDropDownData);
        ReceiveItemsStore.removeListener('ReceivingPageLoad', this.ReceivingPageLoad);
        ReceiveItemsStore.removeListener('GetPODetails', this.MMReceivingPODetail);
        ReceiveItemsStore.removeListener('SumbitReceiving', this.SumbitReceivingResponse);
        //;

    }
    componentDidMount() {
        
        /*******NEW******wal-158******/
        const queryParams = new URLSearchParams(window.location.search)

        const orderno_noti = queryParams.get('ordno');
        //if (orderno_noti == "" || orderno_noti == null) {
        //    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        //}
        console.log("orderno_noti", orderno_noti)
        if (orderno_noti != "" && orderno_noti != null && orderno_noti != undefined) {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            this.notificationorderno(orderno_noti);
        }
        /***********************END**********/


        //Session_BUSUNIT = CookieService.getLocalStorageValues("Session_BUSUNIT");
        //currentPagePath = this.props.location.pathname;
        //currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;    

        //[11/16/21, PC_RI_01, Bug - 1765, Dhevanesam R]
        //$(`#duration${this.state.duration}`).prop("checked", true);        
        document.getElementById("duration0").checked = true;

        //[09/14/21, PC_06 Dhevanesam R, Priyanks D], "MMReceivingPODetail(PODetails)" should be invoked only in Apply Filter method.
        //let PODetails = {
        //    POID: "",
        //    BU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
        //    SHIPTO: "",
        //    DURATION: `${this.state.duration}`,
        //    VENDOR: ""
        //};
        //$(`#${SDIConstants.SDILoader}`).removeClass('hide');
        //ReceiveItemsAction.MMReceivingPODetail(PODetails);

        //[09/21/21, PC_03, Dhevanesam R, Priyanka D] 
        let GetDataBO = {
            BU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
        }
        /*DURATION: 6months send "0", 12months send "1", 2years send "2" */


        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        ReceiveItemsAction.GetData(GetDataBO);
        //;
        ///[11/29/23, Regression-6 Bug (4364) - created an object for the fav icon - Harshitha G]
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };

        ReceiveItemsAction.getCurrentFavPage(getFavPageBO);

        ReceiveItemsStore.on('favpage', this.loadFavPageResponse);
        ReceiveItemsStore.on('updateFavPage', this.updateFavPageResponse);
        ReceiveItemsStore.on('GetData', this.GetSiteDropDownData.bind(this));
        ReceiveItemsStore.on('ReceivingPageLoad', this.ReceivingPageLoad.bind(this));
        ReceiveItemsStore.on('GetPODetails', this.MMReceivingPODetail);

        ReceiveItemsStore.on('SumbitReceiving', this.SumbitReceivingResponse);

        //;


        //OrderStatusStore.on('PassThroughAuthe', this.PassThroughAutheResponse);
    }
    //[11/29/23, Regression-6 Bug (4364) - Shows the fav icon response on pageload - Harshitha G]
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(ReceiveItemsStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };

    //[11/29/23, Regression-6 Bug (4364) - update the fav icon response on pageload - Harshitha G]
    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ReceiveItemsStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(ReceiveItemsStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(ReceiveItemsStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };

    //[11/29/23, Regression-6 Bug (4364) - Handles the fav icon to insert & delete from Favourite page - Harshitha G]
    handleFavorite = (event) => {
        debugger;
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Receive Items",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            ReceiveItemsAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            ReceiveItemsAction.deleteFavPage(deleteFavPageBO)
        }
    };
    //[09/22/21, PC_12 Dhevanesam R, Priyanks D]
    GetSiteDropDownData() {
        
        let GetDataResponse = JSON.parse(ReceiveItemsStore.GetData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let siteList = GetDataResponse.SiteTable;
        console.log("GetDataResponse", GetDataResponse);
        let siteBUList = [];
        siteList.map((item, index) => {
            let siteBU = {
                value: item.BU,
                label: item.BU
            }
            siteBUList.push(siteBU);
        })
        this.setState({
            SiteList: siteBUList,
        })
    }

    ReceivingPageLoad() {
        
        let PageLoadResponse = JSON.parse(ReceiveItemsStore.PageLoadData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let vendorList = PageLoadResponse.ReceivePOVendTable;
        let shiptoList = PageLoadResponse.ReceiveLocTable;
        //[3/4/23, PS_RI_APP_02, Dhevanesam R]
        let corpBUListdt = PageLoadResponse.corpBUListTable;
        let corpBUListVal = "";
        console.log("PageLoadReceive", PageLoadResponse);

        let vendorNameList = [];
        let POList = [];
        let shipList = [];



        if (vendorList != null && vendorList != undefined) {
            vendorList.map((item, index) => {
                let VND = {
                    value: item.VENDOR_ID,
                    label: item.VENDORNAMEID

                };
                let PO = {
                    value: index,
                    label: item.PO_ID
                }

                vendorNameList.push(VND);

                POList.push(PO);

            }

            )
            vendorNameList = vendorNameList.filter((v, i, a) => a.findIndex(t => (t.value.trim().toUpperCase() === v.value.trim().toUpperCase())) === i)
        }

        if (shiptoList != null && shiptoList != undefined) {
            shiptoList.map((item, index) => {
                let Shipto = {
                    value: item.CUSTID,
                    label: item.LOCNAME
                }
                shipList.push(Shipto);
            })
        }
        if (corpBUListdt != null && corpBUListdt != undefined) {
            debugger
            corpBUListVal = corpBUListdt[0].BUList
        }
        this.setState({
            VendorList: vendorNameList, POList: POList, ShiptoList: shipList,
            Response: PageLoadResponse, corpBUList: corpBUListVal

        })

    }
    GetPOAction() {
         
        //[11/30/21, PC_RI_01, Bug - 1855, Dhevanesam R]
        const { t } = this.props
        //let revBol = false;
        console.log("defaultPO", this.state.defaultPO)
        if (this.state.defaultSite.label == " " || this.state.defaultSite.label == "" || this.state.defaultSite.label == undefined || this.state.defaultSite.label == null) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please select the Business Unit")
            })
            //alert(t("Please select the Business Unit"));
        }
        else {
            if (this.state.defaultPO.label == "" || this.state.defaultPO.label == undefined || this.state.defaultPO.label == " " || this.state.defaultPO.label == null) {
                if (this.state.POID == "" || this.state.POID == " " || this.state.POID == undefined || this.state.POID == null) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Please select a PO or enter any PO")
                    })
                   // alert(t("Please select a PO or enter any PO"));
                }
                else {
                    let PODetails = {
                        POID: this.state.POID.length > 0 ? this.state.POID : this.state.defaultPO.label,
                        BU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                        SHIPTO: this.state.defaultShipto.value == "0" ? "" : this.state.defaultShipto.value,
                        DURATION: this.state.duration,
                        VENDOR: this.state.defaultVendor.value == "0" ? "" : this.state.defaultVendor.value,
                        siteBU: this.state.defaultSite.value == "0" ? "" : this.state.defaultSite.value,
                        //[3/4/23, PS_RI_APP_03, Dhevanesam R]
                        corpBUList: this.state.corpBUList,
                        checkValidPO: this.state.POID.length > 0 ? true : false
                    };
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    ReceiveItemsAction.MMReceivingPODetail(PODetails);
                }
            }
            else {
                let PODetails = {
                    POID: this.state.POID.length > 0 ? this.state.POID : this.state.defaultPO.label,
                    BU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    SHIPTO: this.state.defaultShipto.value == "0" ? "" : this.state.defaultShipto.value,
                    DURATION: this.state.duration,
                    VENDOR: this.state.defaultVendor.value == "0" ? "" : this.state.defaultVendor.value,
                    siteBU: this.state.defaultSite.value == "0" ? "" : this.state.defaultSite.value,
                    //[3/4/23, PS_RI_APP_03, Dhevanesam R]
                    corpBUList: this.state.corpBUList,
                    checkValidPO: this.state.POID.length > 0 ? true : false
                };
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                ReceiveItemsAction.MMReceivingPODetail(PODetails);
            }
        }
        
    }
    MMReceivingPODetail() {   
        //[5/10/22, CAS - 31, Dhevanesam R]
        let PageLoadResponse = JSON.parse(ReceiveItemsStore.PODetailData);
        const { t } = this.props;
        if (PageLoadResponse == false) {
            debugger
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("PO is invalid")
            })
            this.setState({ PODetailList: [], POTitleList: [] })
            //alert(t("PO is invalid"));
            return;
        }
        $("#order-row-1").removeClass("show");
        if ($("#arrowAccordian").hasClass("accordion-arrow-change")) {
            $('#arrowAccordian').removeClass('accordion-arrow-change');
            $('#arrowAccordian').addClass('accordion-arrow');
        }
        let DSPOTable = PageLoadResponse.DSPOtable;
        console.log("DSPOTable", DSPOTable);
        let POTable = PageLoadResponse.POTable;
        console.log("POTable", POTable);
        let shipviaTable = PageLoadResponse.shipviaTable;
        let ImgArray = [];
        let ImagData = PageLoadResponse.ImgTable;
        if (ImagData != undefined) {
            ImgArray = ImagData;
        }
        let expiredPO = [];

        DSPOTable.map((item, index) => {
            if (parseInt(item.HQTYPO) - parseInt(item.HQTYLNACCPT) == 0) {
                expiredPO.push(item);
            }
            item.PO_QTY = '';
        })

        if (DSPOTable.length == expiredPO.length) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("PO is invalid")
            })
            this.setState({ PODetailList: [], POTitleList: [] })
        } else {
            let shipviaArr = [{ value: "", label: "-- Select --" }];
            shipviaTable.map((item, index) => {
                let Shipto = {
                    value: item.SHIP_TYPE_ID,
                    label: item.DESCRSHORT
                }
                shipviaArr.push(Shipto);
            })
            this.setState({
                PODetailList: DSPOTable,
                POTitleList: POTable,
                ShipviaList: shipviaArr,
                ImageArray: ImgArray
            })
        }
    }

    SumbitReceivingResponse = () => {
        //[8/26/22, Bug - 1763, PC_RI_04, Dhevanesam R]
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //[Zeus 266, 02/27/2024] - Refresing the PO and vendor DD - Kishore
        let vendorList = this.state.Response.ReceivePOVendTable;
        let vendorNameList = [];
        let POList = [];

        vendorList.map((item, index) => {
            let VND = {
                value: item.VENDOR_ID,
                label: item.VENDORNAMEID

            };
            let PO = {
                value: index,
                label: item.PO_ID
            }

            vendorNameList.push(VND);
            POList.push(PO);
        })

        vendorNameList = vendorNameList.filter((v, i, a) => a.findIndex(t => (t.value.trim().toUpperCase() === v.value.trim().toUpperCase())) === i)
        this.setState({ POTitleList: [], PODetailList: [], defaultVendor: [], defaultShipto: [], defaultPO: [], POID: "", VendorList: vendorNameList, POList: POList })
        let PageLoadResponse = ReceiveItemsStore.SumbitReceive;
        if (PageLoadResponse != "" && PageLoadResponse != " " && PageLoadResponse != undefined) {
            if (parseInt(PageLoadResponse) > 0) {
                //[3/4/23, PS_RI_APP_04, Dhevanesam R]
                let ReqID = t("Your Receiver Id");
                //alert(ReqID + " " + ":" + " " + PageLoadResponse);
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: ReqID + " " + ":" + " " + PageLoadResponse
                })
            }
            else {
                //alert(t("Error in submitting the PO"));
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error in submitting the PO")
                })
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Error in submitting the PO")
            })
            //alert(t("Error in submitting the PO"));
        }

    }
    SubmitReceive = () => {
        //[8/26/22, Bug - 1763, PC_RI_03, Dhevanesam R]
        const { t } = this.props;
        let POList = [];

        this.state.PODetailList.map((item, index) => {
            if (item.PO_QTY && parseFloat(item.PO_QTY) > 0) {
                let PODetails = {
                    PO_ID: this.state.PODetailList[index].PO_ID,
                    BUSINESS_UNIT_PO: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    USER_ID: CookieService.getLocalStorageValues("Session_UserID"),
                    LINE_NBR: this.state.PODetailList[index].LINE_NBR,
                    QTY_PO: this.state.PODetailList[index].PO_QTY,
                    //[3/8/22, PC_RI_01, CAS - Receiving Submission Issue, Dhevanesam R]
                    //[Zeus 266, 02/27/2024] - Additional BO objects for Table insertion - Kishore
                    BILL_OF_LADING: this.state.PODetailList[index].RECVTRACKNO.trim(),
                    Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                    ISA_RECV_EXCEPT: this.state.PODetailList[index].ISA_RECV_EXCEPT.trim(),
                    Vendor_ID: this.state.POTitleList[0].VENDOR_ID,
                    SHIPTO_ID: this.state.PODetailList[index].SHIPTO_ID,
                    ISA_ASN_SHIP_DT: this.state.PODetailList[index].ISA_ASN_SHIP_DT,
                    Descr254Mixed: this.state.PODetailList[index].DESCR254_MIXED,
                    MFG_ID: this.state.PODetailList[index].MFG_ID,
                    SCHED_NBR: this.state.PODetailList[index].SCHED_NBR,
                    INV_ITEM_ID: this.state.PODetailList[index].INV_ITEM_ID,
                    INV_STOCK_TYPE: this.state.PODetailList[index].INV_STOCK_TYPE,
                    hQTYLNACCPT: this.state.PODetailList[index].HQTYLNACCPT,
                    hQtyPO: this.state.PODetailList[index].HQTYPO,
                    UNIT_OF_MEASURE: this.state.PODetailList[index].UNIT_OF_MEASURE,
                    TariffCode: this.state.PODetailList[index].ISA_TARIFF_CODE.trim(),
                    MFG_ITM_ID: this.state.PODetailList[index].MFG_ITM_ID,
                    PO_BU: this.state.PODetailList[index].PO_BU,
                    SITE_BU: this.state.PODetailList[index].PO_BU,
                    CURRENCY_CD: this.state.PODetailList[index].CURRENCY_CD,
                    SHIP_CURRENCY: this.state.PODetailList[index].SHIP_CURRENCY,
                    SHIP_BASE_CURRENCY: this.state.PODetailList[index].SHIP_BASE_CURRENCY,
                    DIST_CURRENCY: this.state.PODetailList[index].DIST_CURRENCY,
                    DIST_BASE_CURRENCY: this.state.PODetailList[index].DIST_BASE_CURRENCY,
                    REQ_ID: this.state.PODetailList[index].REQ_ID,
                    REQ_LINE_NBR: this.state.PODetailList[index].REQ_LINE_NBR,
                    RECEIVER_ID: this.state.PODetailList[index].RECEIVER_ID.trim(),
                    Ship_via: this.state.PODetailList[index].RECVSHIPVIA.value,
                    ISA_RECEIVING_CODE: this.state.PODetailList[index].ISA_RECEIVING_CODE.trim(),
                    ERS_ACTION: this.state.PODetailList[index].ERS_ACTION,
                    Receiving_Type: CookieService.getLocalStorageValues("Session_ReceivingType"),
                    RECV_LN_NBR: this.state.PODetailList[index].RECV_LN_NBR.trim(),
                    RecvBarCodePrint: CookieService.getLocalStorageValues("Session_RecvBarCodePrint"),
                    HRCVDATE: this.state.PODetailList[index].HRCVDATE,
                    ISA_CUST_CHARGE_CD: this.state.PODetailList[index].ISA_CUST_CHARGE_CD,
                    ISA_WORK_ORDER_NO: this.state.PODetailList[index].ISA_WORK_ORDER_NO
                };
                POList.push(PODetails);

            }


        })
        if (POList.length < 1) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please enter quantity")
            })
            // alert(t("Please enter quantity"));
        }
        else {
            let PO = { "PoItemList": POList };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            ReceiveItemsAction.SubmitReceiving(PO);
        }


    }
    cancel = () => {
        //let vendorList = this.state.Response.ReceivePOVendTable;
        //let shiptoList = this.state.Response.ReceiveLocTable;

        //let vendorNameList = [];
        //let POList = [];
        //let shipList = [];



        //vendorList.map((item, index) => {
        //    let VND = {
        //        value: item.VENDOR_ID,
        //        label: item.VENDORNAMEID

        //    };
        //    let PO = {
        //        value: index,
        //        label: item.PO_ID
        //    }

        //    vendorNameList.push(VND);

        //    POList.push(PO);

        //}

        //)
        //vendorNameList = vendorNameList.filter((v, i, a) => a.findIndex(t => (t.value.trim().toUpperCase() === v.value.trim().toUpperCase())) === i)
        //shiptoList.map((item, index) => {
        //    let Shipto = {
        //        value: item.CUSTID,
        //        label: item.LOCNAME
        //    }
        //    shipList.push(Shipto);
        //})
        //this.setState({

        //    VendorList: vendorNameList,
        //    POList: POList, ShiptoList: shipList,
        //})
        //[11/16/21, PC_RI_04, Bug - 1765, Dhevanesam R]
        this.setState({ defaultPO: [], defaultShipto: [], defaultVendor: [], POID: "", VendorList: [], POList: [], ShiptoList: [] });
        let sixMonths = true;
        let twelveMonths = false;
        let twoYear = false;
        document.getElementById("duration0").checked = true;
        document.getElementById("duration1").checked = false;
        document.getElementById("duration2").checked = false;
        //[12/7/21, PC_RI_01, Bug - 1856, Dhevanesam R]
        this.setState({ defaultSite: "0", sixMonths: sixMonths, twelveMonths: twelveMonths, twoYear: twoYear, PODetailList: [] });
        //$("duration0").prop("checked", true);
    }
     //[09/22/21, PC_14 Dhevanesam R, Priyanka D]
    handleChangeSite = (values) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        //[12/7/21, PC_RI_01, Bug - 1856, Dhevanesam R]
        this.setState(
            { POID: "", defaultVendor: [], defaultPO: [], defaultShipto: [], PODetailList: [] })
        let GetMMReceivingPageLoadBO = {
            POID: "",
            BU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            SHIPTO: "",
            DURATION: `${this.state.duration}`,
            VENDOR: "",
            siteBU: values.value
        }
         
        ;
        ReceiveItemsAction.GetMMReceivingPageLoad(GetMMReceivingPageLoadBO);
        this.setState({ defaultSite: values});       
    }
    handleChangeShipto = (values) => {
        
        this.setState({ defaultPO: {} });
        let vendor = [];
        let po = [];
        let POTable = this.state.Response.ReceivePOVendTable;
        let shiptoid = values.value;
        
        if (this.state.defaultVendor != undefined && this.state.defaultVendor.value != undefined) {
            
            POTable.filter((x) => x.SHIPTO_ID == shiptoid && x.VENDOR_ID == this.state.defaultVendor.value).map((item, index) => {
                
                let obj = {
                    value: index,
                    label: item.PO_ID
                }
                po.push(obj);
            })
            this.setState({ POList: po })
        } else {


            POTable.filter((x) => x.SHIPTO_ID == shiptoid).map((item, index) => {
                
                let obj = {
                    value: item.VENDOR_ID,
                    label: item.VENDORNAMEID
                }                               
                vendor.push(obj);                
            }) 
            //[12/13/21, PC_RI_01, Bug - 1873, Dhevanesam R]
            let vendor1 = vendor.filter((ele, ind) => ind === vendor.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
            POTable.filter((x) => x.SHIPTO_ID == shiptoid).map((item, index) => {
                let obj = {
                    value: index,
                    label: item.PO_ID
                }
                po.push(obj);
            })
            this.setState({ VendorList: vendor1, POList: po })
        }

        this.setState(
            { defaultShipto: values, POID: "", defaultPO: [], defaultVendor: [] })
    }
    handleChangePO = (values) => {
        this.setState(
            { defaultPO: values, POID: "" })
    }
    handleChangeVendor = (values) => {
        this.setState({ defaultPO: [] });
        let vendorid = values.value;
        let shipto = [];
        let POTable = this.state.Response.ReceivePOVendTable;
        let po = [];
        let shiptolist = [];
        
        if (this.state.defaultShipto != undefined && this.state.defaultShipto.value != undefined) {
            
            POTable.filter((x) => x.VENDOR_ID == vendorid && x.SHIPTO_ID == this.state.defaultShipto.value).map((item, index) => {
                
                let obj = {
                    value: index,
                    label: item.PO_ID
                }
                po.push(obj);
            })
            this.setState({ POList: po })
        } else {



            POTable.filter((x) => x.VENDOR_ID == vendorid).map((item, index) => {
                shipto.push(item.SHIPTO_ID);
            })

            this.state.ShiptoList.forEach(x => {
                if (shipto.some(y => y == x.value)) {
                    let obj = {
                        value: x.value,
                        label: x.label
                    }
                    shiptolist.push(obj);
                }
            })
            POTable.filter((x) => x.VENDOR_ID == vendorid).map((item, index) => {
                let obj = {
                    value: index,
                    label: item.PO_ID
                }
                po.push(obj);
            })
            this.setState({ ShiptoList: shiptolist, POList: po })
        }
        this.setState(
            { defaultVendor: values, POID: "" })
    }
    handleChangePOSearch = (values) => {
        //[Zeus 266, 02/27/2024] - Refresing the PO and vendor DD - Kishore
        let vendorList = this.state.Response.ReceivePOVendTable;
        let vendorNameList = [];
        let POList = [];

        if (vendorList) {
            vendorList.map((item, index) => {
                let VND = {
                    value: item.VENDOR_ID,
                    label: item.VENDORNAMEID

                };
                let PO = {
                    value: index,
                    label: item.PO_ID
                }
                vendorNameList.push(VND);
                POList.push(PO);
            })
            vendorNameList = vendorNameList.filter((v, i, a) => a.findIndex(t => (t.value.trim().toUpperCase() === v.value.trim().toUpperCase())) === i)
        }

        this.setState(
            { POID: values.target.value.toUpperCase(), defaultVendor: [], defaultPO: [], defaultShipto: [], VendorList: vendorNameList, POList: POList })

    }
    handleChangePostID = (values) => {

        this.setState(
            { query: values.target.value })

    }
    handleChangeShipVia = (index, values) => {
        let PODetail = this.state.PODetailList;
        PODetail[index].RECVSHIPVIA = values;
        this.setState({ PODetailList: PODetail })
    }
    //[11/12/21, PC_RI_01, Bug - 1763, Dhevanesam R] 
    handlekeydown = (event) => {
        if (event.key === "-") {
            event.preventDefault();
        }
    }
    handleChangePOQTY = (index, values) => {
        let PODetail = this.state.PODetailList;
        let left = parseFloat((PODetail[index].HQTYPO - PODetail[index].HQTYLNACCPT).toFixed(4))
        let val = values.target.value;
        let regex = /^[+-]?0*([1-9]\d*|0)(\.\d{0,4})?$/;
        if (val != "") {
            if (parseFloat(val) <= left && regex.test(val)) {
                PODetail[index].PO_QTY = val;
            }
        }
        else {
            PODetail[index].PO_QTY = val;
        }
        this.setState({ PODetailList: PODetail })
    }
    handleChangeTRACKNO = (index, values) => {
        debugger
        let PODetail = this.state.PODetailList;
        //[2/22/23, Bug - 1763, Dhevanesam R]
        PODetail[index].RECVTRACKNO = values.target.value.trim();

        this.setState({ PODetailList: PODetail })
    }
    //[11/16/21, PC_RI_03, Bug - 1765, Dhevanesam R]
    handleChangeDURATION = (index, e) => {
        const { t } = this.props;
        let sixMonths = false
        let twelveMonths = false
        let twoYear = false
        if (this.state.defaultSite.label == " " || this.state.defaultSite.label == "" || this.state.defaultSite.label == undefined || this.state.defaultSite.label == null) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please select the Business Unit")
            })
            //alert(t("Please select the Business Unit"));
            sixMonths = true;
            twelveMonths = false;
            twoYear = false;
            document.getElementById("duration0").checked = true;
            document.getElementById("duration1").checked = false;
            document.getElementById("duration2").checked = false;
            e.preventDefault();

        }
        else {
            
            //$(`#duration${index}`).prop('check', 'checked');
            //this.setState({ duration: index })
            if (index == "0") {
                sixMonths = true;
                twelveMonths = false;
                twoYear = false;
                document.getElementById("duration0").checked = true;
                document.getElementById("duration1").checked = false;
                document.getElementById("duration2").checked = false;
            }
            else if (index == "1") {
                sixMonths = false;
                twelveMonths = true;
                twoYear = false;
                document.getElementById("duration0").checked = false;
                document.getElementById("duration1").checked = true;
                document.getElementById("duration2").checked = false;
            }
            else {
                sixMonths = false;
                twelveMonths = false;
                twoYear = true;
                document.getElementById("duration0").checked = false;
                document.getElementById("duration1").checked = false;
                document.getElementById("duration2").checked = true;
            }
            let GetMMReceivingPageLoadBO = {
                POID: "",
                BU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                SHIPTO: "",
                DURATION: `${index}`,
                VENDOR: "",
                siteBU: this.state.defaultSite.label
            }
            /*DURATION: 6months send "0", 12months send "1", 2years send "2" */

            $(`#${SDIConstants.SDILoader}`).removeClass('hide');

            ReceiveItemsAction.GetMMReceivingPageLoad(GetMMReceivingPageLoadBO);
            this.setState({ sixMonths: sixMonths, twelveMonths: twelveMonths, twoYear: twoYear })

        }
    }

    //handleChangeDURATION = (index, e) => {
    //    
    //    //let id = $(`#duration${index}`).val();
    //    //if (e.target.value != "6 Months"){}
    //    if (this.state.defaultSite.label == " " || this.state.defaultSite.label == "" || this.state.defaultSite.label == undefined || this.state.defaultSite.label == null) {
    //        //$(`#duration0`).prop("checked", true);         
    //        //this.setState({ duration: index })
    //        alert("Please select the Business Unit");
    //        $(`#duration${this.state.duration}`).prop("checked", false);
    //        e.preventDefault();
           
    //    }
    //    else { 
    //        
    //    $(`#duration${index}`).prop('check', 'checked');
    //    this.setState({ duration: index })
    //    let GetMMReceivingPageLoadBO = {
    //        POID: "",
    //        BU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
    //        SHIPTO: "",
    //        DURATION: `${index}`,
    //        VENDOR: "",
    //        siteBU: this.state.defaultSite.label
    //    }
    //    /*DURATION: 6months send "0", 12months send "1", 2years send "2" */

    //    $(`#${SDIConstants.SDILoader}`).removeClass('hide');

    //    ReceiveItemsAction.GetMMReceivingPageLoad(GetMMReceivingPageLoadBO);


    //}

    //}

    search = () => {
        
        const BASE_URL = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/textsearch/json?query=' + this.state.query + '&key=AIzaSyBck4pWT3uHaeUzqFGS9KwwT_QUAf2O0co';
        
        fetch(BASE_URL, {
            method: 'GET',
            headers: {

                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': 'Content-type',
                "Content-type": "application/json; charset=UTF-8",
                'Access-Control-Allow-Methods': 'POST, GET'
            }
        })

            .then(json => console.log("NOCORS", json));
    }
    handleAccordion = (event) => {

        //$('accordian-body').prop('aria-expanded', this.state.accordion);
        $(event.currentTarget).children().toggleClass('accordion-arrow-change');
        this.setState({ accordion: !this.state.accordion });
    }


    POHeaderGrid = () => {
        return this.state.POTitleList.map((item, index) => {
            return (
                <tr id={index}>

                    <td data-toggle="collapse" data-target="#order-row-1" className="accordion-toggle" id='headerAccordian' onClick={(e) => this.handleAccordion(e)}>
                        <span className="accordion-arrow" id="arrowAccordian"></span>
                    </td>
                    <td className="">{item.PO_ID}</td>
                    <td className="">{item.PO_DT}</td>
                    <td className="">{item.SHIPTO_ID}</td>
                    <td className="">{item.VENDOR_ID}</td>
                    <td className="">{item.VendorName}</td>
                    <td className="">{item.ADDRESS1}<br /> {item.ADDRESS2}, {item.CITY}, {item.STATE}, {item.POSTAL} </td>
                </tr>


            )
        })
    }


    POLineGrid = () => {
        return this.state.PODetailList.map((item, index) => {
            {/*[09/22/21, PC_07, Dhevanesam R, Priyanka D] */ }
            let ImageUrl = "";
            if (this.state.ImageArray != "" && this.state.ImageArray != null) {
                let ImageDet = this.state.ImageArray.filter((value, indexVal) => value.CUSTOMER_PART_NUMBER == item.INV_ITEM_ID && value.REQ_LINE_NBR == item.REQ_LINE_NBR);
                {/*[07/18/22, PC_RI_02, CAS - Receiving Image issue, Dhevanesam R]*/ }
                if (ImageDet[0] != "" && ImageDet[0] != null) {
                    ImageUrl = ImageDet[0].ImageUrl;
                }
            }
            item.RECVTRACKNO = item.RECVTRACKNO.trim()

            return (
                <tr className="p" id={index}>
                    <td colSpan="12" className="accordian-body collapse py-0" id="order-row-1" aria-expanded="true" style={{ "borderBottom": "1px solid #e7e7e9" }}>
                        <div className=" py-3">                                                                                                                                                                                                                                   <div className="w-100 float-left pb-3">
                            <div className="col-md-12">
                                <div className="row">
                                    {ImageUrl != "" ? <div className="col-lg-2 col-md-2 order-summary-image">
                                        <img src={ImageUrl} alt="Image" />
                                    </div> : <div className="col-lg-2 col-md-2 order-summary-image">
                                            <img src="/Images/noimage_new.png" alt="No_Image" />
                                        </div>}
                                    <div className="col-md-10  mb-1 pl-3">
                                        <div className="row">
                                            <div className="col-md-12 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Item Description</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.DESCR254_MIXED}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Received QTY</Trans></label>

                                                <input type="text" className="form-control" min="0" max={item.HQTYPO - item.HQTYLNACCPT} maxLength={15} disabled={item.HQTYPO - item.HQTYLNACCPT > 0 ? false : true} value={item.PO_QTY} onKeyDown={this.handlekeydown} onChange={(e) => this.handleChangePOQTY(index, e)} />
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Tracking Number</Trans></label>
                                                <input type="text" className="form-control " value={item.RECVTRACKNO} maxLength="30" disabled={item.HQTYPO - item.HQTYLNACCPT > 0 ? false : true} onChange={(e) => this.handleChangeTRACKNO(index, e)} />
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Ship Via</Trans></label>
                                                <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                                    {/*[11/23/21, PC_RI_03, CAS - Receive Items Panel Speed Issue, Dhevanesam R]*/}
                                                    <Select
                                                        isDisabled={item.HQTYPO - item.HQTYLNACCPT > 0 ? false : true}
                                                        className="DropDown-Font-Size"
                                                        id={"shipvia" + index}
                                                        value={item.RECVSHIPVIA}
                                                        onChange={(e) => this.handleChangeShipVia(index, e)}
                                                        options={this.state.ShipviaList}
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Ln</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.LINE_NBR}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Sc</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.SCHED_NBR}</label>
                                            </div><div className="col-md-2 pr-0 mb-2">
                                                <label className="catalog-label"><span><Trans>SDI Item ID</Trans></span><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.INV_ITEM_ID}</label>
                                            </div>

                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Bin Location</Trans> <span> :</span></label>
                                                <label className="item-details-value ml-1">{item.Bin_Location}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><span><Trans>Mfg Item ID</Trans></span><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.MFG_ITM_ID}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Vendor Item ID</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.ITM_ID_VNDR}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>PO QTY</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.HQTYPO}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Package QTY</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.CONVERSION_RATE}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Open QTY</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{parseFloat((item.HQTYPO - item.HQTYLNACCPT).toFixed(4))}</label>
                                            </div>

                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>UOM</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.UNIT_OF_MEASURE}</label>
                                            </div>


                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Ship Date</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.ISA_ASN_SHIP_DT}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Business Unit In</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.BUSINESS_UNIT_IN}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Employee Name</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.Emp_Name}</label>
                                            </div>

                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>PO Price</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1"> {item.PO_PRICE}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Currency</Trans>  <span>:</span> </label>
                                                <label className="item-details-value ml-1">{item.CURRENCY_CD}</label>
                                            </div>

                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Kit Flag</Trans> <span>:</span></label>
                                                <label className="item-details-value ml-1">{item.KIT_FLAG}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Work Order Number</Trans><span> :</span></label>
                                                <label className="item-details-value ml-1">{item.ISA_WORK_ORDER_NO}</label>
                                            </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                <label className="catalog-label"><Trans>Asset ID</Trans> :</label>{/*PS_AID_FE_03-Zeus 122- Machine Number should changed to Asset ID-Sathis*/}
                                                <label className="item-details-value ml-1">{item.ISA_MACHINE_NO}</label>
                                            </div>




                                            {/* <div className="col-md-4 pr-0 mb-2"> 
                                                    <label className="catalog-label d-block"> Enter Receiving Notes</label>
                                                    <textarea className="form-control custom-textarea" placeholder=""></textarea>
                                                </div>
                                            <div className="col-md-4 pr-0 mb-2">
                                                    <label className="catalog-label d-block">Enter Order Notes</label>
                                                    <textarea className="form-control custom-textarea" placeholder=""></textarea>
                                                </div> */}

                                        </div>






                                    </div>

                                </div>
                            </div>
                        </div>

                        </div>
                    </td>
                </tr>
            )
        })
    }
    
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        //const colourStyles = { control: (styles) => ({ ...styles }) };
        return (
            <div className="container-fluid content-section" >
                <div className="row">
                    <div id="accordion" className="col-lg-12 float-left mt-3 p-0">
                        <div className="col-lg-12 float-left px-0">
                            <div className="col-md-12 page-heading mb-3 float-left p-0">
                                <h5 className="page-title ml-3"><Trans>Receive Items</Trans></h5>
                                {/*[11/29/23, Regression-6 Bug (4364) - Added Fav Icon for Receive Items Panel - Harshitha G]*/}
                                <div className="fav-item-maintenance-icon mt-1">
                                    <input type="checkbox" id="checkbox-a" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-a">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div id="collapseOne" className="card-body collapse show" data-parent="#accordion" >
                            <div className="col-md-12 float-left ">
                                <div className="row ">
                                    {/*[6/21/22, Bug - 2063 & 2057, Dhevanesam R]*/}
                                    <div className="col-md-6 col-lg-3 border-left-style">
                                        <label className="catalog-label col-md-12 float-left p-0 form-label"><Trans>Select Duration</Trans></label>
                                        {/*[11/16/21, PC_RI_02, Bug - 1765, Dhevanesam R]*/}
                                        <div className="col-md-12 p-0 float-left">
                                            <label className="custom-radiobtn"><Trans>6 Months</Trans>
                                                <input type="radio" name="searchoption" checked={this.state.sixMonths} id="duration0" onChange={(e) => this.handleChangeDURATION("0", e)} />
                                                <span className="checkmark checkmark-org"></span>
                                            </label>
                                            <label className="custom-radiobtn"><Trans>12 Months</Trans>

                                                <input type="radio" name="searchoption" checked={this.state.twelveMonths} id="duration1" onChange={(e) => this.handleChangeDURATION("1", e)} />
                                                <span className="checkmark checkmark-org"></span>
                                            </label>
                                            <label className="custom-radiobtn"><Trans>2 Years</Trans>
                                                <input type="radio" name="searchoption" checked={this.state.twoYear} id="duration2" onChange={(e) => this.handleChangeDURATION("2", e)} />
                                                <span className="checkmark checkmark-org"></span>
                                            </label>
                                        </div>
                                    </div>

                                    {/*[09/21/21, PC_02, PC_08, Dhevanesam R, Priyanka D] */}
                                    {/*[11/12/21, PC_RI_01, CAS - BU Position Change, Dhevanesam R] */}
                                    {/*[6/21/22, Bug - 2063 & 2057, Dhevanesam R]*/}
                                    <div className="col-md-6 col-lg-8 float-left">                                      
                                        <div className="col-lg-4 col-md-12 mb-0 float-left p-0 custom-iphone-padding">
                                            <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label" style={{}}><Trans>Business Unit</Trans></label>
                                            <div className="col-lg-10 col-md-12 float-left p-0 col-8">
                                                {/*[11/23/21, PC_RI_03, CAS - Receive Items Panel Speed Issue, Dhevanesam R]*/}
                                                <Select
                                                    className="DropDown-Font-Size"
                                                    value={this.state.defaultSite}
                                                    onChange={(e) => this.handleChangeSite(e)}
                                                    options={this.state.SiteList}
                                                    //styles={{ control: (styles) => ({ ...styles , width : '200px !important' }) }}

                                                />

                                            </div>
                                        </div>

                                     </div>
                                    {/*[4/28/22, CAS - 31 UI issue, Dhevanesam R]*/}
                                    {/*[11/12/21, PC_RI_02, CAS - BU Position Change, Dhevanesam R] */}
                                    {/*[6/21/22, Bug - 2063 & 2057, Dhevanesam R]*/}
                                    {/*<div className="verticalline"></div>*/}
                                </div>
                                    
                                <div className="col-md-5 col-lg-3 mb-0 mt-4 float-left p-0 custom-iphone-padding">
                                        {/*[4/14/22, Bug - 2068, Dhevanesam R]*/}
                                    <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label"><Trans>PO Number</Trans></label>
                                    <input type="text" className="form-control col-lg-8 col-md-12" style={{ 'height': '38px' }} placeholder="" value={this.state.POID} onChange={(e) => this.handleChangePOSearch(e)} />                                
                                        {/*   <div className="col-md-12 px-0 mt-2 float-left">

                                                 <div className="custom-control custom-checkbox  mt-0 item-custom-control ml-0 float-left">
                                                <input type="checkbox" className="custom-control-input" id="customCheck-16a" name="example1" />
                                                            <label className="custom-control-label" htmlFor="customCheck-16a"></label>
                           
                                                        </div>
                                            <label className="catalog-label float-left mt-1 space-check ">Pad with leading zero (s)</label>

                                                    </div>*/}
                                </div>
                                {/*[6/21/22, Bug - 2063 & 2057, Dhevanesam R]*/}
                                <div className="col-md-1 float-left" style={{ 'right': '28px', 'margin-top': '65px' }}><Trans>(OR)</Trans></div>
                                {/*[4/28/22, CAS - 31 UI issue, Dhevanesam R]*/}
                                <div className="col-md-6 col-lg-8 float-left">
                                    <div className="col-lg-4 col-md-12 mb-0 mt-4 float-left p-0 custom-iphone-padding">
                                        <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label" style={{}}><Trans>Ship To</Trans></label>
                                        <div className="col-lg-10 col-md-12 float-left p-0 col-8">
                                            {/*[11/23/21, PC_RI_03, CAS - Receive Items Panel Speed Issue, Dhevanesam R]*/}
                                            <Select
                                                className="DropDown-Font-Size"
                                                value={this.state.defaultShipto}
                                                onChange={(e) => this.handleChangeShipto(e)}
                                                options={this.state.ShiptoList}
                                            />

                                        </div>
                                    </div>


                                    {/*[4/28/22, CAS - 31 UI issue, Dhevanesam R]*/}
                                    {/*[6/21/22, Bug - 2063 & 2057, Dhevanesam R]*/}
                                    <div className="col-lg-4 col-md-12 mb-0 mt-4 float-left p-0 custom-iphone-padding">
                                        <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label" style={{}}><Trans>Vendor</Trans> </label>
                                        <div className="col-lg-10 col-md-12 float-left p-0 col-8">
                                            {/*[11/23/21, PC_RI_03, CAS - Receive Items Panel Speed Issue, Dhevanesam R]*/}
                                            <Select
                                                className="DropDown-Font-Size"
                                                value={this.state.defaultVendor}
                                                onChange={(e) => this.handleChangeVendor(e)}
                                                options={this.state.VendorList}
                                            />
                                        </div>
                                    </div>
                                    {/*[4/28/22, CAS - 31 UI issue, Dhevanesam R]*/}
                                    {/*[6/21/22, Bug - 2063 & 2057, Dhevanesam R]*/}
                                    <div className="col-lg-4 col-md-12 mb-0 mt-4 float-left p-0 custom-iphone-padding">
                                        <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label" style={{}}><span><Trans>PO Number</Trans></span></label>
                                        <div className="col-lg-10 col-md-12 float-left p-0 col-8">
                                            {/*[11/23/21, PC_RI_03, CAS - Receive Items Panel Speed Issue, Dhevanesam R]*/}
                                            <Select
                                                className="DropDown-Font-Size"
                                                value={this.state.defaultPO}
                                                onChange={(e) => this.handleChangePO(e)}
                                                options={this.state.POList}
                                            />
                                        </div>
                                    </div>
                                    </div>
                            </div>
                            {/*[04/08/22, CAS - 31, Dhevanesam R]*/}
                            {/*[6/21/22, Bug - 2063 & 2057, Dhevanesam R]*/}
                            <div className="col-md-12  mb-4 mb-3 mt-4 border-bottom float-left pb-4 px-0">
                                <button className="btn btn-primary custom-btn-primary primary-btn float-right px-4 mr-1" onClick={() => this.GetPOAction()}><Trans>Apply Filter</Trans></button>
                                <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-3" onClick={() => this.cancel()}><Trans>Clear</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    {this.state.PODetailList.length > 0 ?
                        <div className="table-responsive">
                            <table className="table ZEUS_Grid chk-ordertbl">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className=" position-sort-icon"><Trans>PO Id</Trans></th>
                                        <th className=" position-sort-icon"><Trans>PO Date</Trans></th>
                                        <th className="position-sort-icon"><Trans>Ship to location</Trans></th>
                                        <th className=" position-sort-icon"><Trans>Vendor ID</Trans></th>
                                        <th className=" position-sort-icon"><Trans>Vendor</Trans></th>
                                        <th className=" position-sort-icon"><Trans>Ship To Address</Trans></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.POHeaderGrid()}
                                    {this.POLineGrid()}
                                </tbody>
                            </table>
                        </div> : null}
                    {this.state.PODetailList.length > 0 ? <><button className="btn btn-primary custom-btn-primary primary-btn float-right px-4 mr-1" onClick={() => this.SubmitReceive()} style={{ "marginBottom": "5%" }}><Trans>Submit</Trans></button></> : null}
                </div>

                {/*   <div className="col-md-12">

                                                    <span className="total-records mt-2 d-inline-block">
                                                        Number of Records:<span className="ml-2">6</span>
                                                    </span>
                                                    <ul className="custom-pagination pagination float-right">
                                                       <li className="page-item disabled">
                                                            <a className="page-link" href="#" tabIndex="-1"><span className="right-pagination-arrow"></span></a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">01</a></li>
                                                        <li className="page-item active">
                                                            <a className="page-link" href="#">02</a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">03</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">...</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">20</a></li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#"><span className="left-pagination-arrow"></span></a>
                                                        </li>
                                                    </ul>
                </div> */}

            </div>


        )


    }
}
export default withTranslation()(ReceiveItemsComponent);