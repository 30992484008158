import React, { Component } from 'react';
import Image from 'react-image-resizer';
import * as RealTimeInvAction from '../actions/RealTimeInvAction';
import RealTimeInvStore from '../stores/RealTimeInvStore';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import swal from 'sweetalert';
import { withTranslation, Trans } from 'react-i18next';

const style = {
    image: {
        position: 'sticky !important',
        display: 'block',
        left: '0px !important',
        top: '0px !important',
    },
}
let maxQty = 99999999;

class RealTimeInvComponent extends Component {
    constructor(props) {
        super(props);
        this.addtoCartSuccessRTI = this.addtoCartSuccessRTI.bind(this)
        this.state = {
            loginUserID: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            loginUserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
            txtItemID: '',
            quantity: '',
            ItemSupplierName: '',
            ItemClientPartNum: '',
            ItemPrice: '',
            ItemDescription: '',
            ItemMFGPartNumber: '',
            ItemManufacturerName: '',
            Supplier_ID: '',
            ItemUOM: '',
            ItemImage: '',
            ItemSDICatagory: '',
            SAP_Article_Number: '',
            RTIStore: [],
            searchKeyWord: '',
            isPageLoad: false,
        }
    }
    componentDidMount() {
        let ItemDetails = CookieService.getLocalStorageValues("RTI_Item_Data");
        const queryParams = new URLSearchParams(window.location.search)
        const qty = decodeURIComponent(queryParams.get('qty'));
        const searchKey = decodeURIComponent(queryParams.get('searchKey'));
        let RTIStore = [];
        // 7/1/2024 - Zeus 297 - 5149 - Browser back navigation issue fix - Johnprinto D
        if ((this.props.location.state && this.props.location.state.origin == 'SearchResults' || this.props.location.state.origin == 'ItemDetails') && ItemDetails != "") {
            RTIStore = CookieService.getLocalStorageValues("session_RTIStore");
            // [7/4/2024 - Zeus 297 - Sort the store value based on the distance - Johnprinto D]
            RTIStore = this.sortByDistance(RTIStore);
            RTIStore.forEach(storeItem => {
                if (qty > storeItem.qtyAvailable) {
                    storeItem.QuantityOrdered = storeItem.qtyAvailable;
                } else {
                    storeItem.QuantityOrdered = qty;
                }
            });
        } else {
            // 7/1/2024 - Zeus 297 - 5149 - Browser back navigation issue fix - Johnprinto D
            this.props.history.push({
                pathname: `/homepage`,
            });
        }

        this.setState({
            RTIStore: RTIStore,
            quantity: qty,
            searchKeyWord: searchKey,
            txtItemID: ItemDetails.customerPartnumber_CatalogZero ? ItemDetails.customerPartnumber_CatalogZero : " ",
            ItemSupplierName: ItemDetails.supplierName ? ItemDetails.supplierName : " ",
            ItemClientPartNum: ItemDetails.clientPartNumber ? ItemDetails.clientPartNumber : " ",
            ItemPrice: ItemDetails.ItemPrice ? ItemDetails.ItemPrice : 0,
            ItemDescription: ItemDetails.longdescriptionone && ItemDetails.longdescriptionone != "\N" ? ItemDetails.longdescriptionone : " ",
            ItemMFGPartNumber: ItemDetails.manfPartNumber ? ItemDetails.manfPartNumber : " ",
            ItemManufacturerName: ItemDetails.manufacturer ? ItemDetails.manufacturer : " ",
            Supplier_ID: ItemDetails.vendor_id ? ItemDetails.vendor_id : " ",
            ItemUOM: ItemDetails.UOM ? ItemDetails.UOM : " ",
            ItemSDICatagory: ItemDetails.UNSPSCCategory ? ItemDetails.UNSPSCCategory : " ",
            ItemImage: ItemDetails.Image,
            SAP_Article_Number: ItemDetails.customer_part_number_woprefix && ItemDetails.customer_part_number_woprefix != "\N" ? ItemDetails.customer_part_number_woprefix : " ",
        });
        RealTimeInvStore.on("addToCartRTI", this.addtoCartSuccessRTI);
        $(`#${SDIConstants.SDIRTILoader}`).addClass('hide');
    }
    // [7/4/2024 - Zeus 297 - Sort the store value based on the distance - Johnprinto D]
    sortByDistance = (arr) => {
        return arr.sort((a, b) => a.distance - b.distance);
    }
    toastMessage = (id) => {
        $(`#${id}`).removeClass('d-none');
        setTimeout(() => {
            $(`#${id}`).addClass('d-none');
        }, 3000);
    }
    componentWillUnmount() {
        RealTimeInvStore.removeListener("addToCartRTI", this.addtoCartSuccessRTI);
        CookieService.updateLocalStorageValues("RTI_Item_Data", "");
    }
    addToCartRTI = (supplierPartNumber, supplierID, supplierName, quantity, uom, rtiStore, rtiQty) => {
        const { t } = this.props;
        if (quantity == "") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Enter a valid Quantity")
            })
            return
        }
        let AddToCartBO = {
            RTIStore: rtiStore,
            RTIQty: rtiQty,
            txtItemID: this.state.txtItemID,
            Quantity: quantity,
            txtSupplier: supplierName,
            txtSuppPartNum: supplierPartNumber,
            txtEstPrice: this.state.ItemPrice,
            txtDesc: this.state.ItemDescription,
            txtMfgPartNum: this.state.ItemMFGPartNumber,
            txtMfgName: this.state.ItemManufacturerName,
            Supplier_ID: supplierID,
            txtQty: quantity,
            DropUOM: uom,
            rblcatalogVisible: false,
            Image_Url: this.state.ItemImage,
            Session_ImageLocPath: this.state.ItemImage,
            ItemUNSPSC: this.state.ItemSDICatagory,
            SAP: this.state.SAP_Article_Number,
            Session_BUSUNIT: this.state.loginUserBU,
            Session_USERID: this.state.loginUserID,
            filePath: "",
            Session_PUNCHIN: "",
            Session_Noncat: CookieService.getLocalStorageValues("Session_Noncat"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
            Session_ID: CookieService.getLocalStorageValues("Session_ID"),
            SOURCE: "RTI",
            Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "

        }
        RealTimeInvAction.addToCartItemsRTI(AddToCartBO);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');

    }
    addtoCartSuccessRTI = () => {
        const { t } = this.props;
        let AddCartResponse = JSON.parse(RealTimeInvStore.addToCartList);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let SuccessFlag = AddCartResponse.SuccessFLag;
        if (SuccessFlag[0].Status == "true" || SuccessFlag[0].Status == "True") {
            if (this.props.location.state.origin == "SearchResults") {
                this.props.history.push({
                    pathname: "/searchresults",
                    search: `?key=${this.state.searchKeyWord}&addToCartRTI=true`,
                    state: { origin: 'RealTimeInventory' }
                });
            } else {
                this.props.history.push({
                    pathname: `\itemdetailnew`,
                    search: `?ItemId=${this.state.txtItemID}&ManufacturePartNumber=${this.state.ItemMFGPartNumber}&SupplierID=${this.state.Supplier_ID}&addToCartRTI=true`,
                    state: { origin: 'RealTimeInventory' }
                });
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical Issue Contact SDI Customer Care")
            })
        }
    }
    qtyOnchange = (e, index, flag) => {
        let RTIStore = this.state.RTIStore;
        let quantity = RTIStore[index].QuantityOrdered;
        if (flag === "QTY_INCREASED") {
            RTIStore[index].QuantityOrdered = (isNaN(quantity) ? 0 : + quantity) + 1; // Increase the quantity for the given index
        } else if (flag === "QTY_DECREASED") {
            RTIStore[index].QuantityOrdered = (isNaN(quantity) ? 0 : + quantity) - 1; // Decrease the quantity for the given index
        } else {
            // 7/1/2024 - Zeus 297 - 5145 - Zeus 297 - Quantity accepts 9 char in RTI page - Johnprinto D
            if ((e.target.value == "") || (!isNaN(e.target.value) && parseInt(e.target.value) > 0 && parseInt(e.target.value) <= maxQty && parseInt(RTIStore[index].qtyAvailable) >= e.target.value)) {
                RTIStore[index].QuantityOrdered = e.target.value;  // Assign the value directly for the given index
            }
        }
        this.setState({ RTIStore });
    }
    bindRTIStore = () => {
        let isQtyMax = false;
        return this.state.RTIStore.map((value, index) => {
            let googleMapLink = `https://www.google.com/maps?q=${value.latitude},${value.longitude}`;
            let branchAddress = value.branchAddress
            let address = "-";
            if (branchAddress) {
                address = branchAddress.address1 + ", " + branchAddress.city + ", " + branchAddress.state + ", " + branchAddress.postalCode + ", " + branchAddress.country
            }
            if (!this.state.isPageLoad && (isQtyMax || value.qtyAvailable <= parseInt(this.state.quantity))) {
                isQtyMax = true;
                if (this.state.RTIStore.length == index + 1) {
                    this.toastMessage("rti-maxqty-toast")
                    this.setState({ isPageLoad: true })
                }
            }
            return <div className="col-lg-4 col-md-6 mb-4 mt-2">
                <div className="custom-card-shadow custom-radius px-3 py-4 h-100">
                    <h5 className="font-14 font-medium mb-3">{value.vendorName || "-"}</h5>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="font-14 font-medium w-100 mb-2 color-dark-black"><Trans>Address</Trans></label>
                            <span className="font-14 font-regulars w-100 mb-0 rti-color-light-black">{address}</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="font-14 font-medium w-100 mb-2 color-dark-black"><Trans>Distance</Trans></label>
                            <span className="font-14 font-regulars w-100 mb-0 rti-color-light-black">{Math.floor(value.distance)} Miles away</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="font-14 font-medium w-100 mb-2 color-dark-black"><Trans>Qty available</Trans></label>
                            <span className="font-14 font-regulars w-100 mb-0 rti-color-light-black">{value.qtyAvailable + " " + (value.unitOfMeasure || "-")}</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            {/*[7/29/2024 - Zeus-360 -Change “Closing time” to “Store Hours” - Shanmugapriya]*/}
                            <label className="font-14 font-medium w-100 mb-2 color-dark-black"><Trans>Store hours</Trans></label>
                            <span className="info-container">
                                <span className="font-14 font-regulars w-100 mb-0 rti-color-light-black">{ (value.branchHours && value.branchHours.replaceAll("|","").replaceAll("am","AM").replaceAll("pm","PM")) || "-"}</span>
                            </span>
                        </div>
                        <div className="col-md-6 mb-3 text-nowrap">
                            <span className="mr-1"><img className="mb-1" src="images/phone-icon.svg" alt="phone-icon" /></span>
                            <span className="font-regular font-14">{value.branchPhoneNumber ? <a href={`tel:${value.branchPhoneNumber}`} className="text-decoration-none">{value.branchPhoneNumber}</a> : "-"}</span>
                        </div>
                        <div className="col-md-6 mb-3 text-nowrap">
                            <span className="mr-1"><img className="mb-1" src="images/direction-icon.svg" alt="location-icon" /></span>
                            <span className="font-regular font-14">{value.latitude && value.longitude ?<a href={googleMapLink} target='_blank' className="text-decoration-none"><Trans>Driving direction</Trans></a> : "-"}</span>
                        </div>
                        <div className="col-md-12 mt-3 mb-4">
                            <span className="border-bottom w-100 d-flex" />
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="d-block">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <label className="font-12 font-medium label-color mb-0 "><Trans>Qty</Trans> :</label>
                                            <div className="quantity buttons_added" style={{ marginLeft: "1%", width: "25%", float: "left", marginRight: "15%" }}>
                                                <input type="button" className="minus font-bold" value="-" disabled={value.QuantityOrdered <= 1 || value.QuantityOrdered == ""} onClick={(e) => this.qtyOnchange(e, index, "QTY_DECREASED")} />
                                                <input type="text" value={value.QuantityOrdered} maxLength={8} class="minus" minLength="1" style={{ "width": "73%", "borderLeft": "0" }} onChange={(e) => this.qtyOnchange(e, index, "QTY_CHANGE")} />
                                                <input type="button" className="plus font-bold" value="+" disabled={value.QuantityOrdered >= maxQty || value.qtyAvailable <= value.QuantityOrdered} onClick={(e) => this.qtyOnchange(e, index, "QTY_INCREASED")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-custom-primary font-14 font-medium" onClick={() => this.addToCartRTI(value.vendorPartNumber || "-", value.vendorId || "-", value.vendorName || "-", value.QuantityOrdered, value.unitOfMeasure, value.branchNumber || "-", value.qtyAvailable)} ><img src="images/cart-icon.svg" alt="cart" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }
    pageBackClick = () => {
        if (this.props.location.state.origin == "SearchResults") {
            this.props.history.push({
                pathname: "/searchresults",
                search: `?key=${this.state.searchKeyWord}`,
                state: { origin: 'RealTimeInventory' }
            });
        } else {
            this.props.history.push({
                pathname: `\itemdetailnew`,
                search: `?ItemId=${this.state.txtItemID}&ManufacturePartNumber=${this.state.ItemMFGPartNumber}&SupplierID=${this.state.Supplier_ID}`,
                state: { origin: 'RealTimeInventory' }
            });
        }
    }

    render() {
        return (
            <div class="container-fluid">
                <div class="row">
                    {/*Page Heading starts here*/}
                    <div className="col-md-12 bg-white w-100 fixed-top fixed-rti-container" style={{ zIndex: 2 }}>
                        <div className="col-md-12 mt-4 mb-3 page-heading">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h5 className="page-title"><button className='btn m-0 p-0' onClick={() => this.pageBackClick()}><img className="mr-2 mb-1 cursor-pointer" src="images/heading-back-icon.svg" alt="back-icon" /></button><Trans>Real Time Inventory</Trans></h5>
                                <div>
                                    <button className="btn btn-custom-primary font-14 font-medium px-3" onClick={() => this.addToCartRTI(this.state.ItemClientPartNum, this.state.Supplier_ID, this.state.ItemSupplierName, this.state.quantity, this.state.ItemUOM, null, 0)}><Trans>Add for delivery</Trans></button>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-1">
                            <div className="col-md-12 mb-4 mt-2">
                                <div className="border custom-radius px-4 py-3">
                                    <div className="d-flex align-items-center">
                                        <div className="d-block mr-2">
                                            <div className="shadow-container justify-content-center d-flex align-items-center">
                                                <div className="row justify-content-center">
                                                    <Image
                                                        src={this.state.ItemImage}
                                                        width={60}
                                                        height={60}
                                                        style={style.image}
                                                        noImageSrc="/Images/noimage_new.png"
                                                    /></div>
                                            </div>
                                        </div>
                                        <div className="d-block ml-1">
                                            <h5 className="font-14 font-medium">{this.state.ItemMFGPartNumber}</h5>
                                            <span className="font-12 font-regular custom-label-color">{this.state.ItemDescription}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Page Heading ends here*/}
                    <div className="col-md-12 scrollable-rti-details" >
                        <div className="row mb-5 mx-1">
                            {/*supplier details starts here*/}
                            <div className="col-md-12 mb-3">
                                <h5 className="font-bold font-16 mb-0"><Trans>Supplier details</Trans></h5>
                            </div>
                            {/*supplier details ends here*/}
                            {this.bindRTIStore()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};
export default withTranslation()(RealTimeInvComponent);