import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class SearchResultsStore extends EventEmitter {
    SearchresultList = "";
    SuccessStatus = "";
    FavitemAddList = "";
    woValidationData = "";
    handleSearchResultsStore = (action) => {
        switch (action.action.type) {
            case "Searchresult": {
                this.SearchresultList = action.action.response;
                this.emit('Searchresult');
                break;
            }
            case "Status": {
                this.SuccessStatus = action.action.response;
                this.emit('Status');
                break;
            }
            case "FavitemAdd": {
                this.FavitemAddList = action.action.response;
                this.emit('FavitemAdd');
                break;
            }
           //[15/05/23, PS_EWVB_11, Zeus 112, Sathis. N]- EMCOR WO validation
            case "workordercheck": {
                this.woValidationData = action.action.response;
                this.emit('workordercheck');
                break;
            }

        }
    }

}

const objSearchResultsStore = new SearchResultsStore;
Dispatcher.register(objSearchResultsStore.handleSearchResultsStore.bind(objSearchResultsStore));
export default objSearchResultsStore;
