/*
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Item Details page component. The component displays the Item Details page details.
 * 
 * App Name: SDI-Walmart
 * Author: M.Sushmitha
 * Created Date: 08/29/2020 
 */
import React, { Component } from 'react';
import Image from 'react-image-resizer';
import * as ItemDetailNewAction from '../actions/ItemDetailNewAction';
import * as MenuAction from '../actions/MenuAction';
import ItemDetailNewStore from '../stores/ItemDetailNewStore';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import Autocomplete from 'react-autocomplete';
import swal from 'sweetalert';
//[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
import ShoppingCartStore from '../stores/ShoppingCartStore';
import * as SearchResultsAction from '../actions/SearchResultsAction';
import SearchResultsStore from '../stores/SearchResultsStore';
import * as FavItemMainAction from '../actions/FavItemMainAction';
import FavItemMainStore from '../stores/FavItemMainStore';
//import { Trans } from 'react-i18next';
//[PC_07, 09/07/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
let TabclickTitle = "";
let ItemImageValue = ""
//[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]
let CurrentLatitude = 0;
let CurrentLongitude = 0;
let maxQty = 99999999;
let percent = 0;
class ItemDetailNew extends Component {
    constructor(props) {
        super(props);
        this.AddToCartResponse = this.AddToCartResponse.bind(this)
        this.FavSuccess = this.FavSuccess.bind(this)
        this.COStatus = this.COStatus.bind(this);
        this.state = {
            Quantityvalue: 1,
            Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
            ItemId: "-",//Item Id -- H B
            ItemNoun: "-",// Item Noun -- H
            AttributeName: "-",// Attribute name -- B
            ItemfeatureDesc: "-",// Item Feature Description -- B
            ItemClientPartNum: "-",// Item Client Part Number -- Hidden field Data
            ItemManufacturerName: "-",// Item Manufacture Name -- H B
            ItemDescription: "-",//Item Description -- H B
            ItemClient: "-",// Item Client Description -- H
            ItemGroup: "-",// Item Group -- H
            ItemCatalogPath: "-",//Item catalog Path -- H
            ItemUOM: "-",// Item unit of measure -- H
            ItemCategory: "-",// Item Category -- H
            ItemModifiers: "-",// Item Modifier -- H
            ItemMFGPartNumber: "-",// Item Manufacture Part Number -- H B
            ItemUNSPSC: "-",//Item UNSPC -- H
            ItemType: "-",// Item Type -- H
            ItemQuantityAvail: "-",//Quantity availableon hand -- B
            ItemImage: [],// Item Image -- H
            AttributeValue: "-",// Attribute Value  -- B
            ItemCurrencyCOde: "-",// Item Currency Code
            ItemSDICatagory: "",
            ExtentDescription: "-",
            ItemPrice: "-",
            ShowExtentDespPopUp: 0,
            SiteCurrency: "",
            ItemSupplierName: CookieService.getLocalStorageValues("Search_supplierName"),
            DetailreaderState: [],
            IssueChargeCode: "",
            FirstChargeCode: "",
            HazardCode: "",
            InspectionRequired: "",
            EquipmentTag: "",
            Regrind: "",
            PurchasingDept: "",
            MachineControl: "",
            DrawingCode: "",
            MachineNum: "",
            StandardLaedTime: "",
            CriticalSpare: "",
            BUItemsState: [],
            CurrentYearState: [],
            PreviousYearState: [],
            IssueState: [],
            LocDataSetState: [],
            MultiSiteDisplayState: [],
            TransTableState: [],
            StoreRoomState: [],
            PurchaseState: [],
            StoreRoomTitle: "Store Room",
            StoreRoomDespTitle: "Store Room Description",
            CriticalSpareDV: "",
            MachineNumberDV: "",
            FavItem: false,
            ItemTypeFav: "",
            FavItemIdPopUp: "",
            FavSDICatgPopUp: "-",
            FavItemDescripPopUp: "",
            FavMFGPopUp: "",
            FavMFGPartNUMPopUp: "",
            txtUOMHidePopUp: "",
            txtMfgItemidHidePopUp: "",
            txtItemidHidePopUp: "",
            txtMFGHidePopUp: "",
            FavitemImage: "",
            VendorId: "",
            VendorItemid: "",
            ImageURL: "",
            ShipWIthin: "",
            //[3/11/22, PC_ID_01, CAS - 31, Dhevanesam R]
            ShipWIthinVal: "",
            PackageQuantity: "-",
            showprice: (CookieService.getLocalStorageValues("Session_ShowPrice") == "1" && CookieService.getLocalStorageValues("Session_ISAPriceBlock") != "Y" ? "0" : ""),
            PunchinUser: CookieService.getLocalStorageValues("Session_PUNCHINID") != null && CookieService.getLocalStorageValues("Session_PUNCHINID") != undefined && CookieService.getLocalStorageValues("Session_PUNCHINID") != "" && CookieService.getLocalStorageValues("Session_PUNCHINID") != " " ? true : false,
            SAP_Article_Number: "-",
            //[06/28/22,PC_ID_03, CAS - Client Attribute Display in Item Details Panel, Dhevanesam R]
            ClientAttVal: [],
            SiteCurrencyCode: "",
            ItemArray: [],
            UserType: "",
            category: [],
            bindCategory: [],
            yourFavCat: "",
            categoryError: false,
            subCategory: [],
            bindSubCategory: [],
            yourFavSubCat: "",
            subCategoryError: false,
            //[06/02/23,PS_ZE_125_APP_07, Johnprinto D]
            invItemFlag: false,
            Supplier_ID: "", //[06/29/23, Zeus 94], Kishore S - Adding the condition SupplierID to insert cart items
            WOmsg: "",
            ValidatedWo: CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("validatedWO") != "error" && CookieService.getLocalStorageValues("validatedWO") != "blockpopuprepeat" && CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != "null" ? CookieService.getLocalStorageValues("validatedWO") : "",
            //Z-297
            quantity : 1, isRTI  : false, addToCartRTI : false,
        }
    }
    componentDidMount() {
        //[7/14/2022] Getting itemdetails pageload data from search results page and passing it to laodItemDetails() - Poornima S
        this.locationEnable();
        let ItemArray = "";
        const queryParams = new URLSearchParams(window.location.search)
        if (this.props?.location?.state?.origin == 'SearchResults') {
            //ItemArray = this.props.location.state.ItemData;
            this.laodItemDetails(this.props.location.state.origin, ItemArray);
            this.setState({ ItemArray: ItemArray })
        }
        else {
            this.laodItemDetails(this.props?.location?.state?.origin, ItemArray);
        }
        let addToCartRTI = false;
        if(this.props?.location?.state?.origin == "RealTimeInventory"){
            if(queryParams.get("addToCartRTI") == "true"){
                addToCartRTI = true;
                //Remove the query params once we get a query params to avaoid the repeated condition check
                let newURL = window.location.href.replace("&addToCartRTI=true","");
                window.history.pushState('object', document.title, newURL);
                this.setState({addToCartRTI : addToCartRTI})
            }
        }
        ItemDetailNewStore.on("TabOneResp", this.BindTabOne.bind(this));
        ItemDetailNewStore.on("addToCart", this.AddToCartResponse);
        ItemDetailNewStore.on("TabClick", this.TabResponse.bind(this));
        ItemDetailNewStore.on('FavitemAdd', this.FavSuccess);
        ItemDetailNewStore.on('ItemDataLoad', this.ItemDetailsLoadResponse.bind(this));
        //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
        SearchResultsStore.on('workordercheck', this.woValidateResponse);
		ShoppingCartStore.on('CancelOrder', this.COStatus);
		FavItemMainStore.on('DeleteItem', this.DelFavResponse); 
    }
    componentWillUnmount() {
        ItemDetailNewStore.removeListener("TabOneResp", this.BindTabOne.bind(this));
        ItemDetailNewStore.removeListener("addToCart", this.AddToCartResponse);
        ItemDetailNewStore.removeListener("TabClick", this.TabResponse.bind(this));
        ItemDetailNewStore.removeListener('FavitemAdd', this.FavSuccess);
        ItemDetailNewStore.removeListener('ItemDataLoad', this.ItemDetailsLoadResponse.bind(this));
        //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
        SearchResultsStore.removeListener('workordercheck', this.woValidateResponse);
		ShoppingCartStore.removeListener('CancelOrder', this.COStatus);
		FavItemMainStore.removeListener('DeleteItem', this.DelFavResponse);
    }
    //[6/20/2024, Zeus - 297 - Real time inventory - Location enable check - Johnprinto D]
    locationEnable = () => {
        if (!navigator.geolocation) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Geolocation is not supported by your browser"
            });
        } else {
            navigator.geolocation.getCurrentPosition(
                this.handleSuccess,
                this.handleError
            );
        }
    }
    handleSuccess = (position) => {
        const { latitude, longitude } = position.coords;
        CurrentLatitude = latitude;
        CurrentLongitude = longitude;
    };
    handleError = (error) => {
        CurrentLatitude = 0;
        CurrentLongitude = 0;
    };
    toastMessage = (id)=>{
        $(`#${id}`).removeClass('d-none');
        setTimeout(() => {
            $(`#${id}`).addClass('d-none');
        }, 5000);
    }

    //[7/14/2022] AssignItemDetails() is to assign data to item details fields passed from search results - Poornima S
    AssignItemDetails = (HeaderDetail, ItemCatalogPath, ClientAtt, hazardCode, FavItemCheck) => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        debugger
        let ItemDetails = JSON.parse(decodeURIComponent(CookieService.getLocalStorageValues("Item_Data")));
        //[06/02/23,PS_ZE_125_APP_08, Johnprinto D]
        let invFlag = false;
        ////[06/02/23,PS_ZE_125_APP_09, Johnprinto D]
        if (ItemDetails.vendor_id == "IPM9999999") {
            invFlag = true;
        }
        let ExtendDesc = ItemDetails.ExtndDesc;
        let ExtendDescLink = 0
        if (ExtendDesc.length > 0 && ExtendDesc != "None") {
            ExtendDescLink = 1
        }
        if (ItemDetails != null || ItemDetails != "") {
            let ImageData = ItemDetails.Image;
            let FirstImage = ImageData[0];
            let imagePath = "";
            if (ItemDetails.Image && ItemDetails.Image != null && ItemDetails.Image != "") {
                ItemDetails.Image = ItemDetails.Image.replace('\\', '/');
                if (ItemDetails.Image.toLowerCase().indexOf("http") >= 0) {
                    imagePath = ItemDetails.Image;
                }
                else if (ItemDetails.Image == "/N") {
                    imagePath = `/Images/noimage_new.png`;
                }
                else {
                    imagePath = ItemDetails.Image.lastIndexOf(`/`) >= 0 ? ItemDetails.Image.substring(ItemDetails.Image.lastIndexOf("/") + 1, ItemDetails.Image.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = `/Images/${imagePath}`;
                    }
                }
            } else {
                imagePath = `/Images/noimage_new.png`;
            }

            ItemImageValue = imagePath

            let Price;
            if (ItemDetails.ItemPrice == 0 || ItemDetails.ItemPrice == 0.0) {
                Price = "Price on request";
            }
            else {
                //[8/5/2022]Instead of directly comparing ItemPrice with 0 assigned it to ItemPriceFloat and comparing it since ItemPrice will have price in string type after formatting it to 2 decimal pts from API
                let ItemPriceFloat = parseFloat(ItemDetails.ItemPrice) ?parseFloat(ItemDetails.ItemPrice) : 0
                if (ItemPriceFloat > 0) {
                    Price = ItemDetails.ItemPrice;
                }
                else {
                    Price = "Price on request";
                }
            }
            this.GetShipwithin(ItemDetails.ships_within)
            let Iconflag = true
            debugger
            this.setState({
                ItemTypeFav: "CAT",
                ImageURL: ItemDetails.Image != null && ItemDetails.Image != "" && ItemDetails.Image != undefined ? ItemDetails.Image : "-",
                ItemId: ItemDetails.customerPartnumber_CatalogZero != null && ItemDetails.customerPartnumber_CatalogZero != "" && ItemDetails.customerPartnumber_CatalogZero != undefined ? ItemDetails.customerPartnumber_CatalogZero : "-",
                ItemNoun: "-", ItemDetail: ItemDetails,
                ItemManufacturerName: ItemDetails.manufacturer != null && ItemDetails.manufacturer != "" && ItemDetails.manufacturer != undefined ? ItemDetails.manufacturer : "-",
                ItemfeatureDesc: ItemDetails.Description != null && ItemDetails.Description != "" && ItemDetails.Description != undefined ? ItemDetails.Description : "-",
                ItemClientPartNum: ItemDetails.clientPartNumber != null && ItemDetails.clientPartNumber != "" && ItemDetails.clientPartNumber != undefined ? ItemDetails.clientPartNumber : "-",
                ItemDescription: ItemDetails.longdescriptionone != null && ItemDetails.longdescriptionone != "" && ItemDetails.longdescriptionone != undefined && ItemDetails.longdescriptionone != "\N" ? ItemDetails.longdescriptionone : "-",
                AttributeName: ItemDetails.AttributeName != null && ItemDetails.AttributeName != "" && ItemDetails.AttributeName != undefined ? ItemDetails.AttributeName : null,
                AttributeValue: ItemDetails.AttributeValue != null && ItemDetails.AttributeValue != "" && ItemDetails.AttributeValue != undefined ? ItemDetails.AttributeValue : null,
                ItemClient: ItemDetails.clientDesc != null && ItemDetails.clientDesc != "" && ItemDetails.clientDesc != undefined ? ItemDetails.clientDesc : "-",
                ItemGroup: HeaderDetail.length > 0 && HeaderDetail[0].INV_ITEM_GROUP != undefined && HeaderDetail[0].INV_ITEM_GROUP != null && HeaderDetail[0].INV_ITEM_GROUP.trim() != ""  ? HeaderDetail[0].INV_ITEM_GROUP : "-",
                ItemCatalogPath: ItemCatalogPath[0].ItemCatalogPath != null && ItemCatalogPath[0].ItemCatalogPath != "" && ItemCatalogPath[0].ItemCatalogPath != undefined ? ItemCatalogPath[0].ItemCatalogPath : "-",
                ItemUOM: ItemDetails.UOM != null && ItemDetails.UOM != "" && ItemDetails.UOM != undefined ? ItemDetails.UOM : "-",
                ItemCategory: HeaderDetail.length > 0 ? HeaderDetail[0].CATEGORY_ID : "-",
                ItemModifiers: HeaderDetail.length > 0 ? HeaderDetail[0].IM_MODIFIER : "-",
                ItemMFGPartNumber: ItemDetails.manfPartNumber != null && ItemDetails.manfPartNumber != "" && ItemDetails.manfPartNumber != undefined ? ItemDetails.manfPartNumber : "-",
                ItemUNSPSC: ItemDetails.unspsc != null && ItemDetails.unspsc != "" && ItemDetails.unspsc != undefined ? ItemDetails.unspsc : "-",
                ItemSDICatagory: ItemDetails.UNSPSCCategory != null && ItemDetails.UNSPSCCategory != "" && ItemDetails.UNSPSCCategory != undefined ? ItemDetails.UNSPSCCategory : "-",
                ItemType: HeaderDetail.length > 0 ? HeaderDetail[0].INV_ITEM_TYPE : "-",
                //[06/02/23,PS_ZE_125_APP_10, Johnprinto D]
                //[06/02/23,PS_ZE_127_APP_05, Johnprinto D]
                ItemQuantityAvail: ItemDetails.CustomColQOH,
                //[06/02/23,PS_ZE_125_APP_11, Johnprinto D]
                invItemFlag: invFlag,
                ItemImage: imagePath,
                ItemCurrencyCOde: CookieService.getLocalStorageValues("Search_lblCurrencyCode"),
                ExtentDescription: ItemDetails.ExtndDesc != null && ItemDetails.ExtndDesc != "" && ItemDetails.ExtndDesc != undefined && ItemDetails.ExtndDesc != "\N" ? ItemDetails.ExtndDesc : "-",
                ShowExtentDespPopUp: ExtendDescLink,
                ItemSupplierName: ItemDetails.supplierName != null && ItemDetails.supplierName != "" && ItemDetails.supplierName != undefined ? ItemDetails.supplierName : "-",
                FavItem: FavItemCheck,
                SAP_Article_Number: ItemDetails.customer_part_number_woprefix != null && ItemDetails.customer_part_number_woprefix != "" && ItemDetails.customer_part_number_woprefix != undefined && ItemDetails.customer_part_number_woprefix != " " && ItemDetails.customer_part_number_woprefix != "\N" ? ItemDetails.customer_part_number_woprefix : "-",
                actual_qty: ItemDetails.actual_qty != null && ItemDetails.actual_qty != undefined ? ItemDetails.actual_qty : "", carticonFlag: Iconflag, mfg_part_std: ItemDetails.mfg_part_std,
                SiteCurrencyCode: ItemDetails.CurrencyCd,
                ItemPrice: Price.toLowerCase() != "price on request" ? CookieService.getLocalStorageValues("Site_Currency") + " " + Price + " " + ItemDetails.CurrencyCd : Price,
                ClientAttVal: ClientAtt,
                //[5/1/2023] Zeus 84 - PS_FE_IL_3 - Kishore
                UserType: CookieService.getLocalStorageValues("Session_SDIEMP"),
                HazardCode: hazardCode,
                Supplier_ID: ItemDetails.vendor_id != null && ItemDetails.vendor_id != "" && ItemDetails.vendor_id != undefined ? ItemDetails.vendor_id : "",
                PackageQuantity: ItemDetails.package_qty != null && ItemDetails.package_qty != "" && ItemDetails.package_qty != undefined ? ItemDetails.package_qty : "-",
            });

        }
    }

    //[7/14/2022] ItemDetailsLoadResponse() is a response method of ItemDetailsLoad()- Poornima S
    ItemDetailsLoadResponse = () => {
        debugger
        let ItemDetailLoadResponse = JSON.parse(ItemDetailNewStore.ItemDataLoadList);
        window.scrollTo(0, 0)
        //[5/1/2023] Zeus 84 - PS_FE_IL_1,PS_FE_IL_2,PS_FE_IL_3 - Kishore
        if (ItemDetailLoadResponse.ErrorTable[0].Error == "True") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical issue please contact SDI help desk"
            });
        }
        //[5/26/2023] Zeus 94 - FavItem SIT 1 Bug
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        debugger
        let FavItem = ItemDetailLoadResponse.hasOwnProperty('FavItemFlag') && ItemDetailLoadResponse.FavItemFlag[0].Flag == "True" ? true : false;
        let hazardCode = ItemDetailLoadResponse.hasOwnProperty('HazardCode') && ItemDetailLoadResponse.HazardCode[0].HAZ_CLASS_CD && ItemDetailLoadResponse.HazardCode[0].HAZ_CLASS_CD.trim() != "" ? ItemDetailLoadResponse.HazardCode[0].HAZ_CLASS_CD : "-";
        //[06/02/23,PS_ZE_125_APP_15, Johnprinto D]
        //let QuantityAvail = ItemDetailLoadResponse.QuantityAvail;
        let HeaderDetails = ItemDetailLoadResponse.HeaderDetails;
        let ItemCatalogPath = ItemDetailLoadResponse.ItemCatalogPath;
        let ClientAtt = ItemDetailLoadResponse.ClientAtt;
        //[06/02/23,PS_ZE_125_APP_16, Johnprinto D]
        this.AssignItemDetails(HeaderDetails, ItemCatalogPath, ClientAtt, hazardCode, FavItem);
    }


    laodItemDetails = (pagename, ItemData) => {

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        const queryParams = new URLSearchParams(window.location.search)
        const QS_src = decodeURIComponent(queryParams.get('ItemId'));
        const ManuPnum = decodeURIComponent(queryParams.get('ManufacturePartNumber'));
        const supplierID = decodeURIComponent(queryParams.get('SupplierID'));
        //[3/17/2023, PS_SR_URL_APP_05 - To remove catalog look up tree, Dhevanesam R]
        //const QS_CatItemID = queryParams.get('CatItemID');
        console.log(ManuPnum)
        console.log(QS_src)
        debugger
        let selectedUNSPSC = " ";
        this.setState({ ItemId: QS_src })
        let ItemType = CookieService.getLocalStorageValues("Session_Noncat")
        //[7 / 14 / 2022] Passing selectedUNSPSC to ItemDetailNewBO to get the catalog path if the origin is from search results - Poornima S
        if (pagename == "SearchResults") {
            ItemData = CookieService.getLocalStorageValues("Item_Data");
            if (ItemData != null && ItemData != "" && ItemData != undefined) {
                selectedUNSPSC = ItemData.unspsc != null && ItemData.unspsc != "" && ItemData.unspsc != undefined ? ItemData.unspsc : " ";
            }
        }
        if (ItemType != "NONCAT") {

            let ItemDetailNewBO = {
                Session_USERID: this.state.Login_UserId,
                Session_BUSUNIT: this.state.Login_UserBU,
                Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
                ItemID: QS_src,
                Manufacture_Part: ManuPnum,
                SupplierID: supplierID,
                Session_SITELANG: CookieService.getLocalStorageValues("Session_SITELANG"),
                Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_Noncat: CookieService.getLocalStorageValues("Session_Noncat"),
                Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                Query_ItemAttributeId: "",//CookieService.getLocalStorageValues("Search_ItemAttributeId"),
                Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
                //[3/17/2023, PS_SR_URL_APP_05 - To remove catalog look up tree, Dhevanesam R]
                //QS_CattreeItemID: QS_CatItemID,
                SelectedUNSPSC: selectedUNSPSC,//[7/14/2022] Passing selectedUNSPSC to ItemDetails() method to get the item catalog path - Poornima S
                Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
                Session_HOMESTORE: CookieService.getLocalStorageValues("Session_HOMESTORE"),
                Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "

            }
            if (pagename == "SearchResults") {
                ItemDetailNewAction.ItemDetailsLoad(ItemDetailNewBO)
            }
            else {
                ItemDetailNewAction.pageLoad(ItemDetailNewBO)
            } 

        }
        else {
            document.getElementById('item-details-tab').hidden = true
            document.getElementById('usage-tab').hidden = true
            document.getElementById('issues-tab').hidden = true
            document.getElementById('bin-location-tab').hidden = true
            document.getElementById('search-sites-tab').hidden = true
            document.getElementById('purchase-history-tab').hidden = true
            document.getElementById('transactions-tab').hidden = true
            let SuppllierName = CookieService.getLocalStorageValues("Search_supplierName");
            let ImageData = CookieService.getLocalStorageValues("Search_ThumbnailImage");
            let FirstImage = CookieService.getLocalStorageValues("Search_ThumbnailImage");
            let imagePath = "";
            if (FirstImage) {
                FirstImage = FirstImage.replace('\\', '/');
                if (FirstImage.toLowerCase().indexOf("http") >= 0) {
                    imagePath = FirstImage;
                } else {
                    imagePath = FirstImage.lastIndexOf(`/`) >= 0 ? FirstImage.substring(FirstImage.lastIndexOf("/") + 1, FirstImage.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = `/Images/${imagePath}`;
                    }
                }
            } else {
                imagePath = `/Images/noimage_new.png`;
            }
            ItemImageValue = imagePath
            debugger
            //[8/5/2022]Instead of directly comparing ItemPricevalue with 0 assigned it to ItemPricevalueFloat and comparing it since ItemPriceValue will have price in string type after formatting it to 2 decimal pts from API
            let ItemPricevalueFloat = CookieService.getLocalStorageValues("Search_lblpricetext")
            let ItemPricevalue;
            if (ItemPricevalueFloat != undefined && ItemPricevalueFloat != null && ItemPricevalueFloat != "") {
                debugger
                ItemPricevalueFloat = parseFloat(ItemPricevalueFloat) ? parseFloat(ItemPricevalueFloat) : 0
                if (ItemPricevalueFloat > 0) {
                    ItemPricevalue = CookieService.getLocalStorageValues("Search_lblpricetext");
                }
                else {
                    ItemPricevalue = "Price on request";
                }
            }
            this.setState({
                ItemTypeFav: "NONCAT",
                ItemId: CookieService.getLocalStorageValues("Search_lblproductidtext"),
                ItemManufacturerName: CookieService.getLocalStorageValues("Search_lblManufacturerName"),
                ItemfeatureDesc: CookieService.getLocalStorageValues("Search_lblFeaturestext"),
                ItemClientPartNum: CookieService.getLocalStorageValues("Search_txtClientPartNumber"),
                ItemDescription: CookieService.getLocalStorageValues("Search_lblDescriptionText"),
                AttributeName: CookieService.getLocalStorageValues("Search_AttributeName"),
                AttributeValue: CookieService.getLocalStorageValues("Search_AttributeValue"),
                ItemClient: CookieService.getLocalStorageValues("Search_lblClientDesc"),
                FavitemImage: imagePath,
                ItemGroup: "-",
                ItemCatalogPath: "-",
                ItemUOM: CookieService.getLocalStorageValues("Search_lblUOM"),
                ItemCategory: CookieService.getLocalStorageValues("Search_lblCategory"),
                ItemModifiers: "-",
                ItemMFGPartNumber: CookieService.getLocalStorageValues("Search_lblManufacturerPartNumber"),
                ItemUNSPSC: CookieService.getLocalStorageValues("Search_UNSPSC"),
                ItemSDICatagory: CookieService.getLocalStorageValues("Search_lblCategory"),
                ItemType: "-",
                ItemQuantityAvail: CookieService.getLocalStorageValues("Search_QuantiyOnHand"),
                ItemImage: imagePath,
                ItemCurrencyCOde: CookieService.getLocalStorageValues("Search_lblCurrencyCode"),
                ExtentDescription: CookieService.getLocalStorageValues("Search_btnShowExtndOraDesc"),
                ItemPrice: ItemPricevalue != "Price on request" ? CookieService.getLocalStorageValues("Site_Currency") + " " + ItemPricevalue : ItemPricevalue,
                FavItem: CookieService.getLocalStorageValues("Search_ItemFav"),
                VendorId: CookieService.getLocalStorageValues("Search_VendorId"),
                VendorItemid: CookieService.getLocalStorageValues("Search_Supplieritemid"),
                ImageURL: CookieService.getLocalStorageValues("Search_ThumbnailImage"),
                SiteCurrency: CookieService.getLocalStorageValues("Site_Currency"),
            })
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
    }
    BindTabOne = () => {
        debugger;
        let PageLoadResponse = JSON.parse(ItemDetailNewStore.TabOneRespList);
        window.scrollTo(0, 0)
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (PageLoadResponse.Error_Table && PageLoadResponse.Error_Table[0].Error == "True") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical issue please contact SDI help desk"
            });
            return;
        }
        if(this.state.addToCartRTI){
            this.toastMessage("success-toast");
        }
        let ItemPrice = PageLoadResponse.ItemPrice;
        let HeaderDetails = PageLoadResponse.HeaderDetails;
        let ItemCatalogPath = PageLoadResponse.ItemCatalogPath;
        let ItemData = PageLoadResponse.ItemData;
        let hazardCode = PageLoadResponse.HazardCode;
        //[06/28/22,PC_ID_04, CAS - Client Attribute Display in Item Details Panel, Dhevanesam R]
        let ClientAtt = PageLoadResponse.ClientAtt;
        let Update_Session = PageLoadResponse.Update_Session;
        //let QuantityAvail = PageLoadResponse.QuantityAvail;
        let SitecurrencyTable = PageLoadResponse.siteCurrencyTable;
        let Site_CurrencyValue = ""
        if (SitecurrencyTable != null && SitecurrencyTable != undefined) {
            if (SitecurrencyTable.length > 0) {
                Site_CurrencyValue = SitecurrencyTable[0].Symbol
            }
        }
        
        if (ItemData && ItemData.length > 0) {
            let ExtendDesc = ItemData[0].ExtndDesc;
            let ExtendDescLink = 0
            if (ExtendDesc.length > 0 && ExtendDesc != "None") {
                ExtendDescLink = 1
            }
            let ImageData = ItemData[0].ThumbnailImagefile;
            let FirstImage = ImageData[0];
            let imagePath = "";
            let invFlag = false;
            if (ItemData[0].vendor_id == "IPM9999999") {
                invFlag = true;
            }
            if (ItemData[0].ThumbnailImagefile) {
                ItemData[0].Image = ItemData[0].ThumbnailImagefile.replace('\\', '/');
                if (ItemData[0].Image.toLowerCase().indexOf("http") >= 0) {
                    imagePath = ItemData[0].ThumbnailImagefile;
                } else {
                    imagePath = ItemData[0].ThumbnailImagefile.lastIndexOf(`/`) >= 0 ? ItemData[0].ThumbnailImagefile.substring(ItemData[0].ThumbnailImagefile.lastIndexOf("/") + 1, ItemData[0].ThumbnailImagefile.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = `/Images/${imagePath}`;
                    }
                }
            } else {
                imagePath = `/Images/noimage_new.png`;
            }
            ItemImageValue = imagePath
            //if (ImageData) {
            //    ImageData = ImageData.replace('\\', '/');
            //    if (ImageData.toLowerCase().indexOf("http") >= 0) {
            //        imagePath = ImageData;
            //    } else {
            //        imagePath = ImageData.lastIndexOf(`/`) >= 0 ? ImageData.substring(ImageData.lastIndexOf("/") + 1, ImageData.length) : "noimage_new.png";
            //        if (imagePath) {
            //            imagePath = `/Images/${imagePath}`;
            //        }
            //    }
            //} else {
            //    imagePath = `/Images/noimage_new.png`;
            //}
            //[3/11/22, PC_ID_01, CAS - 31, Dhevanesam R]
            this.GetShipwithin(ItemData[0].ships_within)
            //console.log("GetShipwithin", message)
            //[10/13/21, PC_02, CAS - 36], Dhevanesam R
            //[8/5/2022]Instead of directly comparing ItemPricevalue with 0 assigned it to ItemPricevalueFloat and comparing it since ItemPriceValue will have price in string type after formatting it to 2 decimal pts from API
            let ItemPricevalueFloat = ItemData[0].ItemPrice.trim() != "" && ItemData[0].ItemPrice != null ? ItemData[0].ItemPrice : "Price on Request"
            let ItemPricevalue;
            if (ItemPricevalueFloat != undefined && ItemPricevalueFloat != null && ItemPricevalueFloat != "") {
                debugger
                ItemPricevalueFloat = parseFloat(ItemPricevalueFloat) ? parseFloat(ItemPricevalueFloat) : 0
                if (ItemPricevalueFloat > 0) {
                    ItemPricevalue = ItemData[0].ItemPrice
                }
                else {
                    ItemPricevalue = "Price on request"
                }
            }
            this.setState({
                ItemTypeFav: "CAT",
                addToCartRTI : false,
                ImageURL: ItemData[0].ThumbnailImagefile != null && ItemData[0].ThumbnailImagefile != "" && ItemData[0].ThumbnailImagefile != undefined ? ItemData[0].ThumbnailImagefile : "-",
                ItemId: ItemData[0].customerPartnumber_CatalogZero != null && ItemData[0].customerPartnumber_CatalogZero != "" && ItemData[0].customerPartnumber_CatalogZero != undefined ? ItemData[0].customerPartnumber_CatalogZero : "-",//[10/21/21, PC_ITM_2, CAS - 36], Poornima S: Changed Item Id value retriving field to ItemData[0].customerPartnumber_CatalogZero in ItemDetailComponent
                ItemNoun: "-", ItemDetail: ItemData[0],
                SiteCurrency: Site_CurrencyValue,
                ItemPrice: ItemPricevalue != "Price on request" ? Site_CurrencyValue + " " + ItemPricevalue : ItemPricevalue,
                PackageQuantity: ItemData[0].package_qty != null && ItemData[0].package_qty != "" && ItemData[0].package_qty != undefined ? ItemData[0].package_qty : "-",
                ItemManufacturerName: ItemData[0].manufacturer != null && ItemData[0].manufacturer != "" && ItemData[0].manufacturer != undefined ? ItemData[0].manufacturer : "-",
                ItemfeatureDesc: ItemData[0].description != null && ItemData[0].description != "" && ItemData[0].description != undefined ? ItemData[0].description : "-",
                ItemClientPartNum: ItemData[0].clientPartNumber != null && ItemData[0].clientPartNumber != "" && ItemData[0].clientPartNumber != undefined ? ItemData[0].clientPartNumber : "-",
                ItemDescription: ItemData[0].longdescriptionone != null && ItemData[0].longdescriptionone != "" && ItemData[0].longdescriptionone != undefined && ItemData[0].longdescriptionone != "\N" ? ItemData[0].longdescriptionone : "-",
                AttributeName: ItemData[0].AttributeName != null && ItemData[0].AttributeName != "" && ItemData[0].AttributeName != undefined ? ItemData[0].AttributeName : null,
                AttributeValue: ItemData[0].AttributeValue != null && ItemData[0].AttributeValue != "" && ItemData[0].AttributeValue != undefined ? ItemData[0].AttributeValue : null,
                ItemClient: ItemData[0].clientDesc != null && ItemData[0].clientDesc != "" && ItemData[0].clientDesc != undefined ? ItemData[0].clientDesc : "-",
                ItemGroup: HeaderDetails.length > 0 && HeaderDetails[0].INV_ITEM_GROUP != undefined && HeaderDetails[0].INV_ITEM_GROUP != null && HeaderDetails[0].INV_ITEM_GROUP.trim() != "" ? HeaderDetails[0].INV_ITEM_GROUP : "-",
                ItemCatalogPath: ItemCatalogPath[0].ItemCatalogPath != null && ItemCatalogPath[0].ItemCatalogPath != "" && ItemCatalogPath[0].ItemCatalogPath != undefined ? ItemCatalogPath[0].ItemCatalogPath : "-",
                ItemUOM: ItemData[0].UOM != null && ItemData[0].UOM != "" && ItemData[0].UOM != undefined ? ItemData[0].UOM : "-",
                ItemCategory: HeaderDetails.length > 0 ? HeaderDetails[0].CATEGORY_ID : "-",
                ItemModifiers: HeaderDetails.length > 0 ? HeaderDetails[0].IM_MODIFIER : "-",
                ItemMFGPartNumber: ItemData[0].manfPartnumber != null && ItemData[0].manfPartnumber != "" && ItemData[0].manfPartnumber != undefined ? ItemData[0].manfPartnumber : "-",
                ItemUNSPSC: ItemData[0].UNSPSC != null && ItemData[0].UNSPSC != "" && ItemData[0].UNSPSC != undefined ? ItemData[0].UNSPSC : "-",
                ItemSDICatagory: ItemData[0].UNSPSCCategory != null && ItemData[0].UNSPSCCategory != "" && ItemData[0].UNSPSCCategory != undefined ? ItemData[0].UNSPSCCategory : "-",
                ItemType: HeaderDetails.length > 0 ? HeaderDetails[0].INV_ITEM_TYPE : "-",
                ItemQuantityAvail: ItemData[0].quantityAvail,
                ItemImage: imagePath,
                ItemCurrencyCOde: CookieService.getLocalStorageValues("Search_lblCurrencyCode"),
                ExtentDescription: ItemData[0].ExtndDesc != null && ItemData[0].ExtndDesc != "" && ItemData[0].ExtndDesc != undefined && ItemData[0].ExtndDesc != "\N" ? ItemData[0].ExtndDesc : "-",
                ShowExtentDespPopUp: ExtendDescLink,
                ItemSupplierName: ItemData[0].supplierName != null && ItemData[0].supplierName != "" && ItemData[0].supplierName != undefined ? ItemData[0].supplierName : "-",
                FavItem: PageLoadResponse.FavItemFlag[0].Flag == "True" ? true : false,
                SAP_Article_Number: ItemData[0].customer_part_number_woprefix != null && ItemData[0].customer_part_number_woprefix != "" && ItemData[0].customer_part_number_woprefix != undefined && ItemData[0].customer_part_number_woprefix != " " && ItemData[0].customer_part_number_woprefix != "\N" ? ItemData[0].customer_part_number_woprefix : "-",
                ClientAttVal: ClientAtt,
                UserType: CookieService.getLocalStorageValues("Session_SDIEMP"),
                HazardCode: hazardCode.length > 0 && hazardCode[0].HAZ_CLASS_CD && hazardCode[0].HAZ_CLASS_CD.trim() != "" ? hazardCode[0].HAZ_CLASS_CD : "-",
                Supplier_ID: ItemData[0].vendor_id ? ItemData[0].vendor_id : "",
                invItemFlag: invFlag,
            })
        }
        else {
                /*[01/26/2024, Zeus 261] - Redirect to respective page if item is not available - Kishore*/
                const itemID = CookieService.getLocalStorageValues("Item_ManuPartNo");
                const redirectLocation = CookieService.getLocalStorageValues("Item_Origin");

                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: itemID + " is not available in the catalog"
                });

                if (redirectLocation == "ShoppingCart") {
                    this.props.history.push({
                        pathname: "/shoppingcart",
                    });
                }
                else if (redirectLocation == "OrderStatus") {
                    this.props.history.push({
                        pathname: "/orderstatus2",
                    });
                }
                else if (redirectLocation == "FavItemMain") {
                    this.props.history.push({
                        pathname: "/Favorites",
                    });
                }
                else if (redirectLocation == "HeaderMenu") {
                    this.props.history.push({
                        pathname: "/HomePage",
                    });
                }
                else if (redirectLocation == "FavOrder") {
                    this.props.history.push({
                        pathname: "/favorder",
                    });
                }
            }
    }
    GetShipwithin = (shipwithin) => {
        const { t } = this.props;
        //[5/30/2023, PS_ZE_125_APP_14, Johnprinto D]
        let ShipByDate = '';
        if (shipwithin == '' || shipwithin == undefined || shipwithin == null) {
            ShipByDate = 'NA'
            this.setState({
                ShipWIthin: ShipByDate
            })
            return 0;
        }
        let count = 0;
        let dateVal = new Date()
        console.log("today's date", dateVal)
        console.log("no.of days", shipwithin)
        while (count < shipwithin) {
            if (dateVal.getDay() != 0 && dateVal.getDay() != 6) // Skip weekends
            {
                count++;
            }
            dateVal.setDate(dateVal.getDate() + 1);
        }
        //dateVal.setDate(dateVal.getDate() - 1);
        ShipByDate = dateVal.toLocaleDateString();
        this.setState({
            ShipWIthin: ShipByDate          
        })
        
    }
     //[06/28/22,PC_ID_05, CAS - Client Attribute Display in Item Details Panel, Dhevanesam R]
    BindClientAttribute = () => {
        let clientAttributeNameLocal = [];
        if (this.state.ClientAttVal != null) {
            clientAttributeNameLocal = this.state.ClientAttVal
        }
        if (clientAttributeNameLocal != null) {
            if ((clientAttributeNameLocal.length != 0 && clientAttributeNameLocal.length != 0)) {
                return clientAttributeNameLocal.map((val, index) => {
                    return (
                        <div className="row mb-3" key={index}>                            
                            <div className="col-md-12 col-lg-2 catalog-label">{val.Client_Att_Name}</div>
                            <div className="col-md-12 col-lg-10 catalog-value">{val.Client_Att_Val}</div>
                        </div>)
                });
            }
        }

    }

     //[5/10/22, CAS - 31, Dhevanesam R]
    BindAttribute = () => {
        //[06/29/22,PC_ID_07, Bug - 2309, Dhevanesam R]
        let AttributeNameLocal = [];
        let AttributeValueLocal = [];
        AttributeNameLocal = this.state.AttributeName != null && this.state.AttributeName != "-" && this.state.AttributeName != " " && this.state.AttributeName != "" && this.state.AttributeName != undefined ? this.state.AttributeName.split("|") : null;
        AttributeValueLocal = this.state.AttributeValue != null && this.state.AttributeValue != "-" && this.state.AttributeValue != " " && this.state.AttributeValue != "" && this.state.AttributeValue != undefined ? this.state.AttributeValue.split("|") : null;
        console.log("Name", AttributeNameLocal)
        console.log("Value", AttributeValueLocal)
        let hasValue = false;
        if (AttributeNameLocal && AttributeValueLocal) {
            if ((AttributeNameLocal.length != 0 && AttributeValueLocal.length != 0) && (AttributeNameLocal.length == AttributeValueLocal.length)) {
                return AttributeNameLocal.map((val, index) => {
                    //[ 06/26/2023 - PS_ZEUS_143_APP_01 - Johnprinto D]
                    if (!AttributeValueLocal[index].includes("NULL") && val != "NULL" && AttributeValueLocal[index].trim() != "") {
                        hasValue = true;
                        return (
                            <div className="row mb-3" key={index}>
                                <div className="col-md-12 col-lg-2 catalog-label">{val}</div>
                                <div className="col-md-12 col-lg-10 catalog-value">{AttributeValueLocal[index]}</div>
                            </div>)
                    } else if (index == AttributeNameLocal.length - 1 && hasValue == false) {
                            return (<div className="row mb-3">
                                <div className="col-md-12 col-lg-2 catalog-label"></div>
                                <div className="col-md-12 col-lg-10 catalog-value"><Trans>No attributes avaliable</Trans></div>
                            </div>)
                    } else {
                        return (<></>);
                    }
                    
                });
            }
        }

    }
    /*[11/22/21, PC_SC_02, 1830,1831], Priyanka D*/

    Replace = (replace_string, replace_char, replacement_char) => {
        debugger
        let i;
        for (i = 0; i < replace_string.length; i++) {
            if (replace_string.charAt(i) == replace_char) {
                replace_string = replace_string.substring(0, i) + replacement_char + replace_string.substring(i + 1);

            }
        }
        return replace_string;
    }

    //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]
        qtyOnchange = (e,flag) =>{

            let Quantityvalue = this.state.Quantityvalue;
            if (flag === "QTY_INCREASED") {
                Quantityvalue = (isNaN(Quantityvalue) ? 0 : +Quantityvalue) + 1; // Increase the quantity for the given index
            } else if (flag === "QTY_DECREASED") {
                Quantityvalue = (isNaN(Quantityvalue) ? 0 : +Quantityvalue) - 1; // Decrease the quantity for the given index
            } else {
                if ((e.target.value == "") || (!isNaN(e.target.value) && parseInt(e.target.value) > 0 && parseInt(e.target.value) <= maxQty)) {
                    Quantityvalue = e.target.value; // Assign the value directly for the given 
                }
            }
            this.setState({Quantityvalue});
        }
        isRTIItem = (isPickUpCheck) => {
            const { t } = this.props;
            if (this.state.Quantityvalue == "") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Enter a valid Quantity")
                })
                return
            }
            let isPickupVendor = false;
            if (isPickUpCheck) {
                let pickupVendor = CookieService.getLocalStorageValues("pickupVendor");
                isPickupVendor = pickupVendor && pickupVendor.some((value) => { if (value.VENDOR_ID == this.state.Supplier_ID) { return true } });
            }
             //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
             if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                 if (this.state.Login_UserBU.toUpperCase() == "I0631") {
                     if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("validWO") != "clearWO" && CookieService.getLocalStorageValues("validWO") != " " && CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null) {
                         //[11/30/2023 - Zeus 237 - Checking current item's suppplier ID is existing  RemoveVNDR list]
                         if (CookieService.getLocalStorageValues("RemoveVNDR").includes(this.state.ItemDetail.vendor_id.toLowerCase())) {
                             if (this.state.ItemDetail != null && this.state.ItemDetail != undefined) {
                                 if (this.state.ItemDetail.vendor_id.toLowerCase() == "em00000001") {
                                     swal({
                                         allowOutsideClick: false,
                                         closeOnClickOutside: false,
                                         text: t("Unable to add Grainger item using your current work order number")
                                     });
                                 } else if (this.state.ItemDetail.vendor_id.toLowerCase() == "em00000026") {
                                     swal({
                                         allowOutsideClick: false,
                                         closeOnClickOutside: false,
                                         text: t("Unable to submit Diversified supplier item using your current work order number")
                                     });
                                 } else {
                                     swal({
                                         allowOutsideClick: false,
                                         closeOnClickOutside: false,
                                         text: t("Unable to add " + this.state.ItemDetail.vendor + " item using your current work order number")
                                     });
                                 }
                                 return;
                             }
                         }
                     }
                     else {
                         debugger
                         $('#WorkOrder').modal('show');
                         return;
                     }
                 }
             }
            if(isPickupVendor){
                if(CurrentLatitude != 0 && CurrentLongitude != 0){
                    this.AddToCart(isPickupVendor)
                }else{
                    $('#locationaccess').modal('show');
                }
            }else{
                this.AddToCart(isPickupVendor)
            }
    }
    AddToCart = (isRTI) => {
        const { t } = this.props;
        // let quantity = 0
        // quantity = $('#QuantityTxt').val();
        // let quantityValid = quantity % 1
        let Pricevalue
        if (this.state.ItemPrice != null && this.state.ItemPrice != undefined) {
            /*[11/22/21, PC_SC_01, 1830,1831], Priyanka D*/
            Pricevalue = this.Replace(this.state.ItemPrice, "$", "");
        }
        let Itemid = this.state.ItemId != null && this.state.ItemId != undefined && this.state.ItemId != "" ? this.state.ItemId : "P"
        if (Itemid.length < 50) {
           //[8/4/2023, Bug - 3956, Dhevanesam R]
           
                //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]
                if(isRTI){
                    $(`#${SDIConstants.SDIRTILoader}`).removeClass('hide');
                    $("#SDIRTILoader-text1").removeClass('hide');
                    $("#SDIRTILoader-text2").addClass('hide');
                    let timerId;
                    percent = 0;
                    $('#pay').attr('disabled', true);
                    $('#load').css('width', '0px');
                    timerId = setInterval(function () {
                        percent += 5;
                        $('#load').css('width', percent + '%');
                        if (percent >= 90) {
                            clearInterval(timerId);
                            $('#pay').attr('disabled', false);
                        }

                        if (percent == 40) {
                            $("#SDIRTILoader-text1").addClass('hide');
                            $("#SDIRTILoader-text2").removeClass('hide');
                        }
                    }, 400);
                    $("#item-details-component").addClass('hide');
                }else{
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                }
                let AddToCartBO = {
                    IsRTI: isRTI,
                    CurrentLatitude : CurrentLatitude,
                    CurrentLongitude: CurrentLongitude,
                    txtItemID: this.state.ItemId,
                    Quantity: this.state.Quantityvalue,
                    txtSupplier: this.state.ItemSupplierName,
                    txtSuppPartNum: this.state.ItemClientPartNum,
                    //[10/13/21, PC_05, CAS - 36], Dhevanesam R
                    txtEstPrice: Pricevalue != null && Pricevalue != undefined && Pricevalue != 0 && Pricevalue != "0" && Pricevalue != "" ? Pricevalue : 0,
                    txtDesc: this.state.ItemDescription,
                    txtMfgPartNum: this.state.ItemMFGPartNumber,
                    txtMfgName: this.state.ItemManufacturerName,
                    Supplier_ID: this.state.Supplier_ID, //[06/29/2023, Zeus-94] - Kishore S, Added supplier_id to check and insert cart item
                    txtQty: this.state.Quantityvalue,
                    DropUOM: this.state.ItemUOM,
                    rblcatalogVisible: false,
                    Image_Url: this.state.FavitemImage,
                    filePath: "",
                    Session_PUNCHIN: "",
                    Session_Noncat: CookieService.getLocalStorageValues("Session_Noncat"),
                    Session_BUSUNIT: this.state.Login_UserBU,
                    Session_USERID: this.state.Login_UserId,
                    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                    Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                    Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                    Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                    Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                    Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                    Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                    Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                    Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                    Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                    Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                    Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                    Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
                    Session_ImageLocPath: this.state.ItemImage,
                    ItemUNSPSC: this.state.ItemSDICatagory,
                    SAP: this.state.SAP_Article_Number == "-" ? " " : this.state.SAP_Article_Number,
                    Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                    Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
                    Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                    Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "

                }
                console.log("ItemSDICatagory", this.state.ItemSDICatagory)
                console.log("ItemSDICatagory session", CookieService.getLocalStorageValues("Search_lblCategory"))
            ItemDetailNewAction.addToCartItems(AddToCartBO);
            //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]
            this.setState({isRTI})
        }
        else {
            //****NEW*****ADDED(1/2/2021)****
                let it = this.state.ItemId;
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Item") + " " + it + " " + (t("is not added to cart due to technical issue. Contact SDI customer care"))
                })
                //[6/4/2023, Bug 3682, Dhevanesam R]
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                //alert(t("Item") + " " + it + " " + (t("is not added to cart due to technical issue. Contact SDI customer care")));
            



            //*****END************************
        }

    }
    // ZEUS - 297 - method to pause the RTI loader for few seconds - Johnprinto D
    delayedExecution = async () => {
        let pauseTime = percent <= 60 ? 3000 : 0;
        await new Promise(resolve => setTimeout(resolve, pauseTime));
    }
    AddToCartResponse = async () => {
        const { t } = this.props;
        let AddCartResponse = JSON.parse(ItemDetailNewStore.addToCartList);
        //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]
        if (this.state.isRTI) {
            // ZEUS - 297 - method to pause the RTI loader for few seconds - Johnprinto D
            await this.delayedExecution();
            $(`#${SDIConstants.SDIRTILoader}`).addClass('hide');
            $("#SDIRTILoader-text1").removeClass('hide');
            $("#SDIRTILoader-text2").addClass('hide');
            $("#item-details-component").removeClass('hide');
        }else{
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        let SuccessFlag = AddCartResponse.SuccessFLag;
        let Update_Session = AddCartResponse._FlagDt;
         //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]
         if((AddCartResponse.length>0 && AddCartResponse[0].hasOwnProperty("RTILocation") && JSON.parse(AddCartResponse[0].RTILocation) != "")){
            let itemDetailsObj = {
                customerPartnumber_CatalogZero :this.state.ItemId, supplierName : this.state.ItemSupplierName,
                clientPartNumber : this.state.ItemClientPartNum,ItemPrice : this.state.ItemPrice,
                longdescriptionone : this.state.ItemDescription,manfPartNumber : this.state.ItemMFGPartNumber,
                manufacturer : this.state.ItemManufacturerName,
                vendor_id : this.state.Supplier_ID,
                UOM : this.state.ItemUOM,Image : this.state.ItemImage,
                ItemSDICatagory : this.state.UNSPSCCategory,
                customer_part_number_woprefix : this.state.SAP_Article_Number
            }
                
            CookieService.updateLocalStorageValues("RTI_Item_Data", itemDetailsObj);
            CookieService.updateLocalStorageValues("session_RTIStore", JSON.parse(AddCartResponse[0].RTILocation));            
            CookieService.updateLocalStorageValues("RTI_BackScreen","ItemDetail")
            CookieService.updateLocalStorageValues("RTI_BackURL",`\itemdetailnew?ItemId=${this.state.ItemId}&ManufacturePartNumber=${this.state.ItemMFGPartNumber}&SupplierID=${this.state.Supplier_ID}`);

            this.props.history.push({
                pathname: `\RealTimeInv`,
                search: `?qty=${this.state.Quantityvalue}`,
                state: { origin: 'ItemDetails' }
            });
            return;
        }
        if (SuccessFlag[0].Status == "true" || SuccessFlag[0].Status == "True") {
            if (Update_Session[0].dtCM != [] && Update_Session[0].dtCM != "") {
                CookieService.updateLocalStorageValues("Session_dtCM", Update_Session[0].dtCM);
            }
            if (Update_Session[0].Session_gotStock != null && Update_Session[0].Session_gotStock != "") {
                CookieService.updateLocalStorageValues("Session_gotStock", Update_Session[0].Session_gotStock);
            }
            if (Update_Session[0].Session_gotNSTK != null && Update_Session[0].Session_gotNSTK != "") {
                CookieService.updateLocalStorageValues("Session_gotNSTK", Update_Session[0].Session_gotNSTK);
            }
            if (Update_Session[0].Session_CARTFAIL != null && Update_Session[0].Session_CARTFAIL != "") {
                CookieService.updateLocalStorageValues("Session_CARTFAIL", Update_Session[0].Session_CARTFAIL);
            }
            MenuAction.refreshMasterData();
            //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]
            if(this.state.isRTI){
                this.toastMessage("rti-unsuccess-toast");
            }else{
                this.toastMessage("success-toast");
            }
            this.setState({ Quantityvalue: 1 });
        }
        else {
            if (Update_Session[0].Session_CARTFAIL != null && Update_Session[0].Session_CARTFAIL != "") {
                CookieService.updateLocalStorageValues("Session_CARTFAIL", Update_Session[0].Session_CARTFAIL);
            }
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical Issue Contact SDI Customer Care")
            })            
            //alert(t("Technical Issue Contact SDI Customer Care"));
        }
    }
    TabClick = (TabName) => {
        TabclickTitle = TabName;
        //[7/11/22, PC_ID_01, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R
        let TabClickBO = {
            Session_USERID: this.state.Login_UserId,
            Session_BUSUNIT: this.state.Login_UserBU,
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            ItemID: this.state.ItemId,
            TabName: TabName,
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
            Session_SDIEmp: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_DEPTIDN1: CookieService.getLocalStorageValues("Session_DeptIdN1"),
            CurrentPage_Url: "/SDIXZEUS/itemdetailnew",
            txtMfgPartNum: this.state.ItemMFGPartNumber,
            txtMfgName: this.state.ItemManufacturerName,
        }
        ItemDetailNewAction.TabClick(TabClickBO);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    TabResponse = () => {
        debugger;
        let TabClickList = JSON.parse(ItemDetailNewStore.TabClickList);

        switch (TabclickTitle) {
            case "ITM": {
                let DetailreaderTable = [];
                DetailreaderTable = TabClickList.Detailreader;
                let BUItemsTable = [];
                BUItemsTable = TabClickList.BUItems;
                let LabelValueTable = [];
                LabelValueTable = TabClickList.LabelValue;
                let COlumnTilteTable = [];
                COlumnTilteTable = TabClickList.COlumnTilte;
                let IssueChargeCodeL = "";
                let FirstChargeCodeL = "";
                let HazardCodeL = "";
                let InspectionRequiredL = "";
                let EquipmentTagL = "";
                let RegrindL = "";
                let PurchasingDeptL = "";
                let MachineControlL = "";
                let DrawingCodeL = "";
                let MachineNumL = "";
                let StandardLaedTimeL = "";
                let CriticalSpareL = "";
                if (DetailreaderTable != null && DetailreaderTable != undefined) {
                    if (DetailreaderTable.length > 0) {
                        IssueChargeCodeL = DetailreaderTable[0].ISA_CUST_CHARGE_CD;
                        FirstChargeCodeL = DetailreaderTable[0].ISA_SET_CHARGE_CD;
                        HazardCodeL = DetailreaderTable[0].HAZ_CLASS_CD;
                        InspectionRequiredL = DetailreaderTable[0].INSPECT_CD == "Y" ? "Yes" : "No";
                        EquipmentTagL = DetailreaderTable[0].ISA_ETAG_CODE;
                        RegrindL = DetailreaderTable[0].INSPECT_CD == "Y" ? "Yes" : "No";
                        PurchasingDeptL = DetailreaderTable[0].ISA_PDS_CODE;
                        MachineControlL = DetailreaderTable[0].ISA_MACHINE_NO;
                        DrawingCodeL = DetailreaderTable[0].ISA_DRAWING_CODE;
                        StandardLaedTimeL = DetailreaderTable[0].STD_LEAD;
                    }

                }
                if (LabelValueTable) {
                    LabelValueTable.map((val, index) => {
                        MachineNumL = val['Machine Number'];
                        CriticalSpareL = val['Critical Spare'];
                    });
                }
                this.setState({
                    BUItemsState: BUItemsTable,
                    IssueChargeCode: IssueChargeCodeL,
                    FirstChargeCode: FirstChargeCodeL,
                    HazardCode: HazardCodeL,
                    InspectionRequired: InspectionRequiredL,
                    EquipmentTag: EquipmentTagL,
                    Regrind: RegrindL,
                    PurchasingDept: PurchasingDeptL,
                    MachineControl: MachineControlL,
                    DrawingCode: DrawingCodeL,
                    MachineNum: MachineNumL,
                    StandardLaedTime: StandardLaedTimeL,
                    CriticalSpare: CriticalSpareL,
                })
                break
            }
            case "USE": {
                let CurrentYear = TabClickList.Table1
                let PreviousYear = TabClickList.dtPrevious
                this.setState({ CurrentYearState: CurrentYear, PreviousYearState: PreviousYear })
                break
            }
            case "ISS": {
                let IssueTable = TabClickList.IssuesReader
                this.setState({ IssueState: IssueTable })
                break
            }
            case "BIN": {
                let LocDataSetTable = TabClickList.LocDataSet
                this.setState({ LocDataSetState: LocDataSetTable })
                break
            }
            case "SITE": {
                let MultiSiteDisplayTable = TabClickList.MultiSiteDisplay
                this.setState({ MultiSiteDisplayState: MultiSiteDisplayTable })
                break
            }
            case "CYCTRANS": {
                let TransTable = TabClickList.TransDetails
                let StoreRoomTable = TabClickList.StoreRoom
                this.setState({ TransTableState: TransTable, StoreRoomState: StoreRoomTable })
                break
            }
            case "CYCPURC": {
                let PurchaseTable = TabClickList.Table
                this.setState({ PurchaseState: PurchaseTable })
                break
            }
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    //[7/11/22, PC_ID_11, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R
    BindBUItems = () => {
        if (this.state.BUItemsState) {
            return this.state.BUItemsState.map((val, index) => {
                return (<tr>
                    <td>{val.BUSINESS_UNIT}</td>
                    <td>{val.BUSINESS_UNIT}</td>
                    <td>{val.BUSINESS_UNIT}</td>
                    <td>{val.NO_REPLENISH_FLG}</td>
                    <td>{val.REORDER_POINT}</td>
                    <td>{val.QTY_MAXIMUM}</td>
                    <td>{val.QTY_OPEN}</td>
                    <td>{val.QTY_ONHAND}</td>
                </tr>)
            });
        }
    }
    UsageTabBind = () => {
        if (this.state.CurrentYearState && this.state.PreviousYearState) {
            let PreviousYear = this.state.PreviousYearState;
            return this.state.CurrentYearState.map((val, index) => {
                return (
                    <tr>
                        <td>{val['Current Year']}</td>
                        <td>{val.Quantity}</td>
                        <td>{PreviousYear[index]['Previous Year']}</td>
                        <td>{PreviousYear[index].Quantity}</td>
                    </tr>

                )

            });
        }
    }
    IssueTabBind = () => {
        if (this.state.IssueState) {
            return this.state.IssueState.map((val, index) => {
                return (<tr>
                    <td>{val.TranDate}</td>
                    <td>{val.Type}</td>
                    <td>{val.EmpID}</td>
                    <td>{val.Work_Order}</td>
                    <td>{val.Name}</td>
                    <td>{val.ChargeCode}</td>
                    <td />
                </tr>)
            });
        }
    }
    BinLocationBind = () => {
        if (this.state.LocDataSetState) {
            return this.state.LocDataSetState.map((val, index) => {
                //[6/27/2024]Zeus 329 Retriving appropriate bin location details from PS_PHYSICAL_INV table and changed the column names as per that - Poornima S
                return (<tr>
                    <td>{(val.BUSINESS_UNIT != null && val.BUSINESS_UNIT != undefined) ? val.BUSINESS_UNIT : ""}</td>
                    <td>{(val.DESCR254 != null && val.DESCR254 != undefined) ? val.DESCR254 : ""}</td>
                    <td>{(val.STORAGE_AREA != null && val.STORAGE_AREA != undefined) ? val.STORAGE_AREA : ""}</td>
                    <td>{(val.STOR_LEVEL_1 != null && val.STOR_LEVEL_1 != undefined) ? val.STOR_LEVEL_1 : ""}</td>
                    <td>{(val.STOR_LEVEL_2 != null && val.STOR_LEVEL_2 != undefined) ? val.STOR_LEVEL_2 : ""}</td>
                    <td>{(val.STOR_LEVEL_3 != null && val.STOR_LEVEL_3 != undefined) ? val.STOR_LEVEL_3 : ""}</td>
                    <td>{(val.STOR_LEVEL_4 != null && val.STOR_LEVEL_4 != undefined) ? val.STOR_LEVEL_4 : ""}</td>
                    <td>{val.QTY != null ? val.QTY + " " + val.UNIT_OF_MEASURE : 0}</td>
                    <td>{(val.SERIAL_ID != null && val.SERIAL_ID != undefined) ? val.SERIAL_ID : ""}</td>
                </tr>)
            });
        }
    }
    //[7/11/22, PC_ID_04, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R
    MultiSiteBind = () => {
        if (this.state.MultiSiteDisplayState) {
            return this.state.MultiSiteDisplayState.map((val, index) => {
                return (<tr>
                    <td>{val.INVITEMID}</td>
                    <td>{val.DESCRIPTION}</td>
                    <td>{val.UOM}</td>
                    <td>{val.QOH}</td>
                    <td>{val.sitename}</td>
                </tr>)
            });
        }
    }
    //[7/11/22, PC_ID_06, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R
    //[7/14/22, PC_ID_01, Bug - 2380 Catalog Item Details_ In Purchase History_ In Zeus 1.0 the values in Quantity column is displayed in decimal whereas in cascade its not displayed in decimal, Dhevanesam R
    PurchaseTabBind = () => {
        debugger
        if (this.state.PurchaseState) {
            return this.state.PurchaseState.map((val, index) => {
                return (<tr>
                    <td>{val.INV_ITEM_ID}</td>
                    <td>{val.PO_ID}</td>
                    <td>{val.LINE_NBR}</td>
                    <td>{val.CANCEL_STATUS}</td>
                    <td>{val.PO_DT}</td>
                    <td>{val.DUE_DT}</td>
                    <td>{val.QTY_PO.toFixed(4)}</td>
                    <td>{val.NET_RCVD_QTY}</td>
                </tr>)
            });
        }
    }
    TransTabBind = () => {
        if (this.state.TransTableState) {
            let StoreRoomStateL = []
            StoreRoomStateL = this.state.StoreRoomState
            return this.state.TransTableState.map((val, index) => {
                return (<tr key={index}>
                    <td>{val.TRANSACTION_DATE.substring(0, 10)}</td>
                    <td>{val.UNIT_MEASURE_STD}</td>
                    <td>{val.QTY}</td>
                    <td>{val.TRANSACTION_GROUP}</td>
                    <td>{val.ORDER_NO}</td>
                    <td>{val.ORDER_INT_LINE_NO}</td>
                    <td>{val.ORDER_NO}</td>
                    <td>{val.ISA_WORK_ORDER_NO}</td>
                    <td>{val.OPRID1}</td>
                    <td>{val.OPRID}</td>
                    <td>{this.state.StoreRoomState ? StoreRoomStateL[0].StoreRoom : ""}</td>
                    <td>{this.state.StoreRoomState ? StoreRoomStateL[0].StoreRoomDesc : ""}</td>
                    <td>{val.RECEIVER_ID}</td>
                    <td>{val.RECV_LN_NBR}</td>
                    <td>{val.ISA_CUST_PO_LINE}</td>
                </tr>)
            });
        }
    }
    sortingColumns = (id, Gridtype, Column, Sortingtype, event) => {
        debugger
        if (event) {
            event.preventDefault();
        }
        let ClassAvailable = document.getElementById(id).className;
        console.log("ClassAvailable", ClassAvailable)
        //if (ClassAvailable == "position-sort-icon") {
        //    $('#' + id).addClass('down');
        //    Sortingtype = "down"
        //}
        //else if (ClassAvailable == "position-sort-icon up") {
        //    $('#' + id).addClass('down');
        //    $('#' + id).removeClass('up');
        //    Sortingtype = "down"
        //}
        //else if (ClassAvailable == "position-sort-icon down") {
        //    $('#' + id).addClass('up');
        //    $('#' + id).removeClass('down');
        //    Sortingtype = "up"
        //}
        if (Gridtype == 1) {
            let BuitemsValue = this.state.BUItemsState;
            let sorted = []
            if (Sortingtype == "down") {
                if (Column == "Owned") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.BUSINESS_UNIT - y.BUSINESS_UNIT;
                    });
                }
                else if (Column == "Store") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.BUSINESS_UNIT - y.BUSINESS_UNIT;
                    });
                }
                else if (Column == "StroeDescp") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.BUSINESS_UNIT - y.BUSINESS_UNIT;
                    });
                }
                else if (Column == "Replenish") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.NO_REPLENISH_FLG - y.NO_REPLENISH_FLG;
                    });
                }
                else if (Column == "Reorder") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.REORDER_POINT - y.REORDER_POINT;
                    });
                }
                else if (Column == "Maxi") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.QTY_MAXIMUM - y.QTY_MAXIMUM;
                    });
                }
                else if (Column == "Quantity") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.QTY_ONHAND - y.QTY_ONHAND;
                    });
                }
                else if (Column == "QTYOpenPO") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return x.QTY_OPEN - y.QTY_OPEN;
                    });
                }
            }
            else {
                if (Column == "Owned") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.BUSINESS_UNIT - x.BUSINESS_UNIT;
                    });
                }
                else if (Column == "Store") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.BUSINESS_UNIT - x.BUSINESS_UNIT;
                    });
                }
                else if (Column == "StroeDescp") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.BUSINESS_UNIT - x.BUSINESS_UNIT;
                    });
                }
                else if (Column == "Replenish") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.NO_REPLENISH_FLG - x.NO_REPLENISH_FLG;
                    });
                }
                else if (Column == "Reorder") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.REORDER_POINT - x.REORDER_POINT;
                    });
                }
                else if (Column == "Maxi") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.QTY_MAXIMUM - x.QTY_MAXIMUM;
                    });
                }
                else if (Column == "Quantity") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.QTY_ONHAND - x.QTY_ONHAND;
                    });
                }
                    //[7/11/22, PC_ID_12, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R
                else if (Column == "QTYOpenPO") {
                    sorted = BuitemsValue.sort(function (x, y) {
                        return y.QTY_OPEN - x.QTY_OPEN;
                    });
                }
            }
            console.log("sorting", BuitemsValue);
            this.setState({
                BUItemsState: sorted
            })
        }
        //[7/14/22, PC_ID_01, Bug - 2378 Catalog Item Details_ In Purchase History_ On clicking Up arrow column values are sorted in descending order and on clicking Down arrow column values are sorted in descending order, Dhevanesam R
        else if (Gridtype == 2) {

            let sorted = []
            if (Sortingtype == "up") {
                if (Column == "ItemId") {
                    //sorted = this.state.PurchaseState.sort(function (x, y) {
                    //    return x.INV_ITEM_ID - y.INV_ITEM_ID;
                    //});
                    sorted = this.state.PurchaseState.sort(function (a, b) {

                        var va = (a.INV_ITEM_ID === null) ? "" : "" + a.INV_ITEM_ID,
                            vb = (b.INV_ITEM_ID === null) ? "" : "" + b.INV_ITEM_ID;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });

                }
                else if (Column == "POID") {
                    //sorted = this.state.PurchaseState.sort(function (x, y) {
                    //    return x.PO_ID - y.PO_ID;
                    //});
                    sorted = this.state.PurchaseState.sort(function (a, b) {

                        var va = (a.PO_ID === null) ? "" : "" + a.PO_ID,
                            vb = (b.PO_ID === null) ? "" : "" + b.PO_ID;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });
                }
                else if (Column == "LineNBR") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return x.LINE_NBR - y.LINE_NBR;
                    });
                }
                else if (Column == "CancelStatus") {
                    // sorted = this.state.PurchaseState.sort((a, b) => a.CANCEL_STATUS.localeCompare(b.CANCEL_STATUS))
                    //sorted = this.state.PurchaseState.sort(function (x, y) {
                    //    return x.CANCEL_STATUS - y.CANCEL_STATUS;
                    //});
                    sorted = this.state.PurchaseState.sort(function (a, b) {

                        var va = (a.CANCEL_STATUS === null) ? "" : "" + a.CANCEL_STATUS,
                            vb = (b.CANCEL_STATUS === null) ? "" : "" + b.CANCEL_STATUS;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });
                }
                else if (Column == "PODate") {
                    //sorted = this.state.PurchaseState.sort(function (x, y) {
                    //    return x.PO_DT.substring(0, 10) - y.PO_DT.substring(0, 10);
                    //});
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return new Date(x.PO_DT) - new Date(y.PO_DT);
                    });
                }
                    //[7/11/22, PC_ID_07, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R
                else if (Column == "DueDate") {
                    //sorted = this.state.PurchaseState.sort(function (x, y) {
                    //    return x.PO_DT.substring(0, 10) - y.PO_DT.substring(0, 10);
                    //});
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return new Date(x.DUE_DT) - new Date(y.DUE_DT);
                    });
                }
                else if (Column == "Quantity") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return x.QTY_PO - y.QTY_PO;
                    });
                }
                else if (Column == "NetRec") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return x.NET_RCVD_QTY - y.NET_RCVD_QTY;
                    });
                }
            }
            else {
                if (Column == "ItemId") {
                    sorted = this.state.PurchaseState.sort(function (a, b) {

                        var va = (a.INV_ITEM_ID === null) ? "" : "" + a.INV_ITEM_ID,
                            vb = (b.INV_ITEM_ID === null) ? "" : "" + b.INV_ITEM_ID;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "POID") {
                    sorted = this.state.PurchaseState.sort(function (a, b) {

                        var va = (a.PO_ID === null) ? "" : "" + a.PO_ID,
                            vb = (b.PO_ID === null) ? "" : "" + b.PO_ID;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "LineNBR") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return y.LINE_NBR - x.LINE_NBR;
                    });
                }
                else if (Column == "CancelStatus") {
                    //sorted = this.state.PurchaseState.sort((a, b) => b.CANCEL_STATUS.localeCompare(a.CANCEL_STATUS))
                    //sorted = this.state.PurchaseState.sort(function (x, y) {
                    //    return y.CANCEL_STATUS - x.CANCEL_STATUS;
                    //});

                    sorted = this.state.PurchaseState.sort(function (a, b) {

                        var va = (a.CANCEL_STATUS === null) ? "" : "" + a.CANCEL_STATUS,
                            vb = (b.CANCEL_STATUS === null) ? "" : "" + b.CANCEL_STATUS;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "PODate") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return new Date(y.PO_DT) - new Date(x.PO_DT);
                    });
                }
                    //[7/11/22, PC_ID_07, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R
                else if (Column == "DueDate") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return new Date(y.DUE_DT) - new Date(x.DUE_DT);
                    });
                }
                else if (Column == "Quantity") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return y.QTY_PO - x.QTY_PO;
                    });
                }
                else if (Column == "NetRec") {
                    sorted = this.state.PurchaseState.sort(function (x, y) {
                        return y.NET_RCVD_QTY - x.NET_RCVD_QTY;
                    });
                }
            }
            console.log("sorting", sorted);
            this.setState({
                PurchaseState: sorted
            })
        }
        else {
            let sorted = []
            if (Sortingtype == "down") {
                if (Column == "Date") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return new Date(x.TRANSACTION_DATE) - new Date(y.TRANSACTION_DATE);
                    });
                }
                else if (Column == "UOM") {
                    //sorted = this.state.TransTableState.sort((a, b) => a.UNIT_MEASURE_STD.localeCompare(b.UNIT_MEASURE_STD))
                    //sorted = this.state.TransTableState.sort(function (x, y) {
                    //    return x.UNIT_MEASURE_STD - y.UNIT_MEASURE_STD;
                    //});
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.UNIT_MEASURE_STD === null) ? "" : "" + a.UNIT_MEASURE_STD,
                            vb = (b.UNIT_MEASURE_STD === null) ? "" : "" + b.UNIT_MEASURE_STD;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });
                }
                else if (Column == "Qty") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return x.QTY - y.QTY;
                    });
                }
                else if (Column == "Group") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return x.TRANSACTION_GROUP - y.TRANSACTION_GROUP;
                    });
                }
                else if (Column == "Order") {
                    //sorted = this.state.TransTableState.sort(function (x, y) {
                    //    return x.ORDER_NO - y.ORDER_NO;
                    //});
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.ORDER_NO === null) ? "" : "" + a.ORDER_NO,
                            vb = (b.ORDER_NO === null) ? "" : "" + b.ORDER_NO;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });
                }
                else if (Column == "Line") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return x.ORDER_INT_LINE_NO - y.ORDER_INT_LINE_NO;
                    });
                }
                else if (Column == "PO") {
                    //sorted = this.state.TransTableState.sort(function (x, y) {
                    //    return x.ORDER_NO - y.ORDER_NO;
                    //});
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.ORDER_NO === null) ? "" : "" + a.ORDER_NO,
                            vb = (b.ORDER_NO === null) ? "" : "" + b.ORDER_NO;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });
                }
                else if (Column == "Word") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return x.ISA_WORK_ORDER_NO - y.ISA_WORK_ORDER_NO;
                    });
                }
                else if (Column == "Entered") {

                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.OPRID1 === null) ? "" : "" + a.OPRID1,
                            vb = (b.OPRID1 === null) ? "" : "" + b.OPRID1;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });
                }
                else if (Column == "Trans") {
                    //sorted = this.state.TransTableState.sort((a, b) => a.OPRID.localeCompare(b.OPRID))
                    //sorted = this.state.TransTableState.sort(function (x, y) {
                    //    return x.OPRID - y.OPRID;
                    //});
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.OPRID === null) ? "" : "" + a.OPRID,
                            vb = (b.OPRID === null) ? "" : "" + b.OPRID;

                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    });

                }
                else if (Column == "Receiver") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return x.RECEIVER_ID - y.RECEIVER_ID;
                    });
                }
                else if (Column == "Line2") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return x.RECV_LN_NBR - y.RECV_LN_NBR;
                    });
                }
                else if (Column == "POLine") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return x.ISA_CUST_PO_LINE - y.ISA_CUST_PO_LINE;
                    });
                }
            }
            else {
                if (Column == "Date") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return new Date(y.TRANSACTION_DATE) - new Date(x.TRANSACTION_DATE);
                    });
                }
                else if (Column == "UOM") {
                    //sorted = this.state.TransTableState.sort(function (x, y) {
                    //    return y.UNIT_MEASURE_STD - x.UNIT_MEASURE_STD;
                    //});
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.UNIT_MEASURE_STD === null) ? "" : "" + a.UNIT_MEASURE_STD,
                            vb = (b.UNIT_MEASURE_STD === null) ? "" : "" + b.UNIT_MEASURE_STD;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "Qty") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return y.QTY - x.QTY;
                    });
                }
                else if (Column == "Group") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return y.TRANSACTION_GROUP - x.TRANSACTION_GROUP;
                    });
                }
                else if (Column == "Order") {
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.ORDER_NO === null) ? "" : "" + a.ORDER_NO,
                            vb = (b.ORDER_NO === null) ? "" : "" + b.ORDER_NO;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "Line") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return y.ORDER_INT_LINE_NO - x.ORDER_INT_LINE_NO;
                    });
                }
                else if (Column == "PO") {
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.ORDER_NO === null) ? "" : "" + a.ORDER_NO,
                            vb = (b.ORDER_NO === null) ? "" : "" + b.ORDER_NO;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "Word") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return y.ISA_WORK_ORDER_NO - x.ISA_WORK_ORDER_NO;
                    });
                }
                else if (Column == "Entered") {

                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.OPRID1 === null) ? "" : "" + a.OPRID1,
                            vb = (b.OPRID1 === null) ? "" : "" + b.OPRID1;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "Trans") {
                    //sorted = this.state.TransTableState.sort((a, b) =>
                    //    b.OPRID.localeCompare(a.OPRID))
                    //sorted = this.state.TransTableState.sort(function (x, y) {
                    //    return y.OPRID - x.OPRID;
                    //});
                    sorted = this.state.TransTableState.sort(function (a, b) {

                        var va = (a.OPRID === null) ? "" : "" + a.OPRID,
                            vb = (b.OPRID === null) ? "" : "" + b.OPRID;

                        return va > vb ? -1 : (va === vb ? 0 : 1);
                    });
                }
                else if (Column == "Receiver") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return y.RECEIVER_ID - x.RECEIVER_ID;
                    });
                }
                else if (Column == "Line2") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return y.RECV_LN_NBR - x.RECV_LN_NBR;
                    });
                }
                else if (Column == "POLine") {
                    sorted = this.state.TransTableState.sort(function (x, y) {
                        return y.ISA_CUST_PO_LINE - x.ISA_CUST_PO_LINE;
                    });
                }
            }
            console.log("sorting", sorted);
            this.setState({
                TransTableState: sorted
            })
        }
    }
    sortingColumnsempty = (event) => {
        if (event) {
            event.preventDefault();
        }
    }
    BindFavForm = (favexist) => {

        //let Itemtypevalue = CookieService.getLocalStorageValues("Search_lblproductidtext") == "" || CookieService.getLocalStorageValues("Search_lblproductidtext") == null ? "CAT" : "NONCAT"
        if (!favexist) {

            let FavSDICatg = this.state.ItemSDICatagory == null || this.state.ItemSDICatagory == "" || this.state.ItemSDICatagory == "-" || this.state.ItemSDICatagory == " " ? "UnClassified" : this.state.ItemSDICatagory;
            this.setState({
                FavItemIdPopUp: this.state.ItemId, FavSDICatgPopUp: FavSDICatg,
                FavItemDescripPopUp: this.state.ItemfeatureDesc, FavMFGPopUp: this.state.ItemManufacturerName,
                FavMFGPartNUMPopUp: this.state.ItemMFGPartNumber, txtUOMHidePopUp: this.state.ItemUOM,
                txtMfgItemidHidePopUp: this.state.ItemMFGPartNumber,
                txtItemidHidePopUp: this.state.ItemId, txtMFGHidePopUp: this.state.Supplier_ID, FavitemImage: this.state.ImageURL,
            })

            document.getElementById("txtUOMHide").hidden = true
            document.getElementById("txtMfgItemidHide").hidden = true
            document.getElementById("txtItemidHide").hidden = true
            document.getElementById("txtMFGHide").hidden = true
            document.getElementById("ItemType").hidden = true
            $('#add-fav-popup').modal('show');
            //[PS_FE_GC_08, Zeus 94, Kishore] - Fetching the category and subcategory values from the cookie
            let allCategory = []
            let categories = CookieService.getLocalStorageValues("FavItem_Cat")
            if (categories != null && categories != "" && categories != undefined) {
                categories.map((val, index) => {
                    allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
                });
                allCategory = allCategory.sort((a, b) =>
                    a.value > b.value ? 1 : -1,
                );
            }
            debugger;
            let subCategory = []
            let allSubCategory = []
            let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
            if (subCategories != null && subCategories != "" && subCategories != undefined) {
                subCategories.map((val, index) => {
                    if (val.ISA_FAVS_SUBCATEGORY != null) {
                        subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                    }
                });
            }
            if (subCategory.length > 0) {
                allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
                allSubCategory = allSubCategory.sort((a, b) =>
                    a.value > b.value ? 1 : -1,
                );
            }
            this.setState({ category: allCategory, bindCategory: allCategory, categoryError: false, subCategoryError: false, subCategory: allSubCategory, bindSubCategory: [] });
            

		}
		else {
			let deleteFavBO = {
				ItemId: this.state.ItemId,
				Supplier_ID: this.state.Supplier_ID,
				MFG: this.state.ItemManufacturerName,
				MFGPartNo: this.state.ItemMFGPartNumber,
				Session_USERID: this.state.Login_UserId,
				Session_BUSUNIT: this.state.Login_UserBU
			}
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
			FavItemMainAction.DeletFavItem(deleteFavBO);

		}
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
	//[Zeus 172, 08/23/2023] - Item Unfavorite response - Kishore
	DelFavResponse = () => {
		const { t } = this.props;
		let Response = JSON.parse(FavItemMainStore.PageLoadData);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');

		if (Response.ErrorTable[0].error != "True") {

			if (Response.ResultFlag[0].DeleteResult == "True") {

				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Favorite item removed successfully")
				});
				MenuAction.refreshMasterData();
				$('#FavYourDescrip').val('');
				this.setState({ FavItem: false, yourFavCat: "", yourFavSubCat: "" })
			}
			else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Technical Issue Try again")
				});
			}
		}
		else {
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Technical Issue Try again")
			});
		}
	}
    AddfavItem = () => {
        debugger;
        let itemtypevalue = this.state.ItemTypeFav
        let ItemDescrip = $('#FavYourDescrip').val()
        let ItemCatagory = this.state.yourFavCat;
        let ItemSubCategory = this.state.yourFavSubCat;
        let Pricevalue
        if (this.state.ItemPrice != null && this.state.ItemPrice != undefined) {
            debugger
            Pricevalue = this.state.ItemPrice.replaceAll("$", "")
        }
        if (itemtypevalue == "CAT") {
            //[PS_FE_GC_15 , Zeus 94, Kishore ] - Category and sub category validation
            if (ItemCatagory != null && ItemCatagory != undefined && ItemSubCategory != null && ItemSubCategory != undefined) {
                if (ItemCatagory.trim() != "" && ItemSubCategory.trim() != "") {
                    let FavItemBo = {
                        Session_BUSUNIT: this.state.Login_UserBU,
                        Session_USERID: this.state.Login_UserId,
                        Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                        Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                        Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                        Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                        Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                        Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                        Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                        Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                        Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                        Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                        Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                        Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                        Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                        Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                        Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
                        ItemId: this.state.FavItemIdPopUp,
                        Supplier_ID: this.state.txtMFGHidePopUp != undefined && this.state.txtMFGHidePopUp != null ? this.state.txtMFGHidePopUp : "",
                        Manufacturer: this.state.FavMFGPopUp,
                        YourDesc: ItemDescrip != " " && ItemDescrip != "" ? ItemDescrip.toUpperCase() : "",
                        YourCat: ItemCatagory != " " && ItemCatagory != "" ? ItemCatagory.toUpperCase() : "",
                        YourSubCat: ItemSubCategory != " " && ItemSubCategory != "" ? ItemSubCategory.toUpperCase() :  "",
                        SDI_Catagory: this.state.FavSDICatgPopUp,
                        txtItemidHide: this.state.txtItemidHidePopUp,
                        txtMFGHide: this.state.txtMFGHidePopUp,
                        txtUOMHide: this.state.txtUOMHidePopUp,
                        txtMfgItemidHide: this.state.txtMfgItemidHidePopUp,
                        SAP: this.state.SAP_Article_Number == "-" ? " " : this.state.SAP_Article_Number,
                        Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                        Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")
                    }
                    ItemDetailNewAction.catfavItem(FavItemBo)
                    $('#add-fav-popup').modal('hide');
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                }
                else {
                    this.setState({ categoryError: ItemCatagory.trim() == "" ? true : false, subCategoryError: ItemSubCategory.trim() == "" ? true : false });
                }
            }
            else {
                this.setState({ categoryError: ItemCatagory == null || ItemCatagory == undefined ? true : false, subCategoryError: ItemSubCategory == null || ItemSubCategory == undefined ? true : false });
            }

        }
        else {
            if (ItemCatagory.trim() != "" && ItemCatagory != null && ItemCatagory != undefined) {
                let FavItemBo = {
                    ItemIdType: "NONCAT",
                    YourDesc: ItemDescrip,
                    YourCat: ItemCatagory,
                    Item_Description: this.state.FavItemDescripPopUp,
                    Item_MFG: this.state.FavMFGPopUp,
                    Session_BUSUNIT: this.state.Login_UserBU,
                    Session_USERID: this.state.Login_UserId,
                    MFG_PartNumber: this.state.FavMFGPartNUMPopUp,
                    img_name: this.state.FavitemImage,
                    UOM: this.state.txtUOMHidePopUp,
                    supplierId: this.state.txtMFGHidePopUp,
                    supplierPartNo: this.state.txtMfgItemidHidePopUp,
                    UnitPrice: Pricevalue != null && Pricevalue != undefined && Pricevalue != 0 && Pricevalue != "0" && Pricevalue != "" ? Pricevalue : "Price on request",
                    SDI_Catagory: this.state.FavSDICatgPopUp
                }
                ItemDetailNewAction.NoncatfavItem(FavItemBo)
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            }
            else {
                this.setState({ categoryError: true});
            }
        }
    }
    FavSuccess = () => {
        debugger;
        const { t } = this.props;
        let Status = JSON.parse(ItemDetailNewStore.FavitemAddList);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        console.log("Status", Status)
        let Favstatus = Status.ErrorMsg
        if (Favstatus.length > 0) {
            if (Favstatus[0].Error_Msg == "Favorite item inserted sucessfully") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Favorite item inserted successfully")
                });
                this.setState({ FavItem: true })
                MenuAction.refreshMasterData()
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Technical Issue Try again")
                });
                //alert(t("Technical Issue Try again"))
            }
        }
    }
    SetQuantity = (event) => {

        const re = SDIConstants.quantityRegEX;
        console.log("Quantity", event.target.value)
        if (event.target.value === '' || re.test(event.target.value)) {
            if (event.target.value != "0") {
                //Quantityvalidate = 0
                this.setState({ Quantityvalue: event.target.value });
            }
        }
    };
    handleProfileFormChange = (event) => {

        const re = SDIConstants.quantityRegEX;
        console.log("Quantity", event.target.value)
        if (event.target.value === '' || re.test(event.target.value)) {
            if (event.target.value != "0") {
                this.setState({ [event.target.name]: event.target.value });
            }
        }
    };
    bindImage = () => {
        const style = {
            image: {
                position: 'sticky !important',
                display: 'block',
                left: '0px !important',
                top: '0px !important',
            },
        }
        let imageurl = this.state.ItemImage
        console.log("imageurl", imageurl)
        return (
            <Image
                id={"image1"}
                src={imageurl}
                width={540}
                height={573}
                style={style.image}
            //noImageSrc="/Images/noimage_new.png"
            />)
    }
    getItemValue(item) {
        return `${item.value}`;
    }
    renderItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );

    }
    //[PS_FE_GC_09, Zeus 94, Kishore] - Onselect event handling for categorya and sub category fields
    onSelect(val, name) {
        debugger;
        let result = [];
        if (name == "CAT") {
            if (val != "") {
                this.state.subCategory.map((x, i) => {
                    if (val.toUpperCase() == x.category) {
                        result.push({ label: x.value, value: x.value })
                    }
                }
                )
            }
            this.setState({
				yourFavCat: val, bindSubCategory: result, categoryError: false, yourFavSubCat: val != this.state.yourFavCat ? "" : this.state.yourFavSubCat
            });
        }
        else {
            this.setState({
                yourFavSubCat: val, subCategoryError: false
            });
        }
    }
    //[PS_FE_GC_11, Zeus 94, Kishore] - OnChange event for handling category and sub-category fields
    onChange = (e, name) => {
        debugger;
        let val = e.target.value.toUpperCase()
        let len = e.target.value
        let category = [];
        let subCategory = [];
        let re = /^[a-zA-Z0-9_-\s\x20 &, :;=?@#|'<>.{}"*()%!`%^$+~[/\]\\]+$/;
        if (val === '' || re.test(val)) {
            if (name == "CAT") {
                if (e.target.value == "") {
                    this.setState({
						yourFavCat: e.target.value, bindCategory: this.state.category, bindSubCategory: [], yourFavSubCat: ""
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.category != undefined && this.state.category != null) {
                        if (this.state.category.length != 0) {
                            category = this.state.category.filter(name => name.label.toUpperCase().includes(val))

                        }
                    }
                    if (e.target.value != "") {
                        this.state.subCategory.map((x, i) => {
                            if (e.target.value.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
						yourFavCat: e.target.value, bindCategory: category, bindSubCategory: subCategory, categoryError: false, yourFavSubCat: ""
                    });
                }
            }
            else {
                if (e.target.value == "") {
                    if (this.state.yourFavCat != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.yourFavCat.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
                        yourFavSubCat: e.target.value, bindSubCategory: subCategory
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.yourFavCat != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.yourFavCat.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        }
                        )
                        subCategory = subCategory.filter(name => name.label.toUpperCase().includes(val))
                    }
                    this.setState({
                        yourFavSubCat: e.target.value, bindSubCategory: subCategory, subCategoryError: false
                    });
                }
            }
        }
    }

	CloseFavPopup = () => {
		$('#FavYourDescrip').val('');
        this.setState({ yourFavCat: "", yourFavSubCat: "" });
    }
    //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
    WOClose = () => {
        //[05/31/2023, PS_ZE_123_APP_08, Dhevanesam R]
        document.getElementById("WONUM").value = this.state.ValidatedWo;
        $('#WorkOrder').modal('hide');
    }
    WOClick = () => {
        debugger
        let workOrder = $('#WONUM').val()
        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
            if (workOrder != "" && workOrder != null && workOrder != undefined) {
                if (this.state.disableWo != true) {
                    let workOrderBO = {
                        WorkOrder: workOrder,
                        BusinessUnit: this.state.Login_UserBU
                    }
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    SearchResultsAction.workOrderValidation(workOrderBO);
                    //if (this.state.Login_UserBU == "I0635" && !workOrder.includes(':')) {
                    //    CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                    //    CookieService.updateLocalStorageValues("validWO", "");
                    //    CookieService.updateLocalStorageValues("WOName", "");
                    //    this.setState({ WOmsg: "Please enter valid work order number" });
                    //    $(`#WONUM`).val('');
                    //}
                    //else {
                    //    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    //    SearchResultsAction.workOrderValidation(workOrderBO);
                    //}
                }
            }
            else {
                //[05/31/2023, PS_ZE_123_APP_07, Dhevanesam R]
                this.setState({ WOmsg: "Please enter work order number" });
            }
        }
        else {
            this.setState({ WOmsg: "", ValidatedWo: workOrder })
            CookieService.updateLocalStorageValues("validatedWO", workOrder);
            CookieService.updateLocalStorageValues("validWO", workOrder);
            CookieService.updateLocalStorageValues("WOName", "");
            $('#WorkOrder').modal('hide');
        }
    }
    woValidateResponse = () => {
        debugger
        const { t } = this.props;
        let workOrder = $('#WONUM').val()
        let woValidationData = SearchResultsStore.woValidationData;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (woValidationData != undefined && woValidationData != null && woValidationData != "" && woValidationData != "null") {
            if (woValidationData != "error occured in server" && woValidationData != "error occured" && woValidationData != "authorization required"
                && woValidationData != "internal server error" && woValidationData != "invalid") {
                let responeData = JSON.parse(SearchResultsStore.woValidationData);
                debugger
                if (responeData[0].WOVALIDATE != null && responeData[0].WOVALIDATE != "") {
                    if (responeData[0].WOVALIDATE == "valid") {
                        //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
                        //if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                        if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                    this.CancelOrder();
                                }
                            }
                        //}
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].WONAME);
                        CookieService.updateLocalStorageValues("workorderLocation", responeData[0].LOCATION);
						if (this.state.Login_UserBU.toUpperCase() == "I0631") {
							debugger
							CookieService.updateLocalStorageValues("validWO", workOrder);
							CookieService.updateLocalStorageValues("WOName", responeData[0].WONAME);
							//[11/30/2023 - Zeus 237 - REMOVE_VNDR_ID value which we will get it as array of object and convert into array ]
							let rmvndr = responeData.map(function (obj) {
								return obj.REMOVE_VNDR_ID;
							});
							CookieService.updateLocalStorageValues("RemoveVNDR", rmvndr);
							if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("validWO") != " " && CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null) {

								//[11/30/2023 - Zeus 237 - Checking current item's suppplier ID is existing  RemoveVNDR list]
								if (rmvndr.includes(this.state.ItemDetail.vendor_id.toLowerCase())) {
									if (this.state.ItemDetail != null && this.state.ItemDetail != undefined) {
										if (this.state.ItemDetail.vendor_id.toLowerCase() == "em00000001") {
											swal({
												allowOutsideClick: false,
												closeOnClickOutside: false,
												text: t("Unable to add Grainger item using your current work order number")
											});
										} else if (this.state.ItemDetail.vendor_id.toLowerCase() == "em00000026") {
											swal({
												allowOutsideClick: false,
												closeOnClickOutside: false,
												text: t("Unable to submit Diversified supplier item using your current work order number")
											});
										} else {
											swal({
												allowOutsideClick: false,
												closeOnClickOutside: false,
												text: t("Unable to add " + this.state.ItemDetail.vendor + " item using your current work order number")
											});
										}
										$('#WorkOrder').modal('hide');
										this.setState({ WOmsg: "", ValidatedWo: "" })
										$(`#WONUM`).val('');
										$(`#${SDIConstants.SDILoader}`).addClass('hide');
										return;
									}
								}
								else {
                                    this.isRTIItem(true)
								}

							}


						}
                        else {
                            CookieService.updateLocalStorageValues("validWO", "");
                            CookieService.updateLocalStorageValues("WOName", "");
                        }
                        this.setState({ WOmsg: "", ValidatedWo: workOrder })
                        $('#WorkOrder').modal('hide');
                    }
                    /*else if (responeData[0].WOVALIDATE == "invalid") {
                        CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        $(`#WONUM`).val('');
                        this.setState({ WOmsg: "Please enter valid work order number" })
                    }
                    else {
                        CookieService.updateLocalStorageValues("validatedWO", "error");
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        $(`#WONUM`).val('');
                        alert("Error occured in Work Order Validation");
                    }*/
                }
                /*else {
                    if (woValidationData != "valid") {
                        //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
                        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                            if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "") {
                                if (CookieService.getLocalStorageValues("validatedWO") != workOrder) {
                                    this.CancelOrder();
                                }
                            }
                        }
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", responeData[0].assetNumber);
                        this.setState({ WOmsg: "", ValidatedWo: workOrder })
                        $('#WorkOrder').modal('hide');
                    }
                }*/

            }
            else if (woValidationData == "invalid") {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workorderLocation", "");
                $(`#WONUM`).val('');
                this.setState({ WOmsg: "Please enter valid work order number" })
            }
            else {
                CookieService.updateLocalStorageValues("validatedWO", "error");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workorderLocation", "");
                $(`#WONUM`).val('');
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error occured in work order validation")
                })
            }
        }
    }
    CancelOrder = () => {
        debugger
        let cancelorderBo = {
            Session_UserID: this.state.Login_UserId,
            Session_BUSUNIT: this.state.Login_UserBU,
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        ShoppingCartAction.CancelOrderItem(cancelorderBo);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    COStatus = () => {
        const { t } = this.props;
        if (this.state.Login_UserBU.toUpperCase() != "I0631") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        let CancelResponse = JSON.parse(ShoppingCartStore.CancelOrderMsge);
        if (CancelResponse == null || CancelResponse == "" || CancelResponse == " ") {
            this.refreshMenu();
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Issue in clearing the cart")
            });
        }
    }
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        MenuAction.getMasterData(masterBO);
    };
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        const { t } = this.props;
        console.log("ItemImageValue", ItemImageValue)
        return (
            <div className="container-fluid content-margin Top-margin" id="item-details-component">
                <div className="row">
                    <div className="col-md-12 page-heading mb-3">
                        <h5 className="page-title"><Trans>{this.state.ItemTypeFav == "CAT" ? "Catalog Item Details" : "Non Catalog Item Details"}</Trans></h5>
                    </div>
                    <div className="col-md-5 catalog-img-border">
                        {/*<img src={this.state.ItemImage} alt="Catalog Image" className="w-100 img-fluid catalog-img-border" />
                        <Image
                            src={ItemImageValue}
                            width={540}
                            height={573}
                            noImageSrc="/Images/noimage_new.png"
                        />*/}
                        {this.bindImage()}
                    </div>

                    <div className="col-md-7 Catalog-Items">
                        <div className="row">

                            {/*[8/9/22, Bug - 2347, PC_ID_01, Dhevanesam R]*/}
                            {/*2534 - UI issue in item details page - Dhamotharan P (8/24/2022)*/}
                            <div className="fav-item-maintenance-icon" style={{ "marginTop": "5px" }}>
                                <input type="checkbox" className="checknow" id="checknow-11" checked={this.state.FavItem} onChange={() => this.BindFavForm(this.state.FavItem)} data-backdrop="static" data-keyboard="false" />
                                <label htmlFor="checknow-11">
                                    <svg className="heart-svg heart-icon-view" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </div> 
                            {/*2534 -  UI issue in item details page - Dhamotharan P*/}
                            <div className="col-md-10" style={{ "width": "80%" }}>
                                {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                <span className="catalog-title-id" >{this.state.ItemMFGPartNumber}</span>
                                {this.state.ItemQuantityAvail == "ORO" ?
                                    <span className="oro-item" style={{ "top": "-8px", "font-size": "17px", "padding": "3px 7px" }}>O</span> : null}
                                {this.state.ItemQuantityAvail == "VMI" ?
                                    <span className="oro-item vmi-item" style={{ "top": "-8px", "font-size": "17px", "padding": "3px 7px" }}>V</span> : null}
                            </div>
                        </div>
                        {/*[4/22/22, CAS - 31, Dhevanesam R]*/}
                        {this.state.showprice == "0" ? <div className="row mb-2"><div className="col-md-12 mt-2 float-right"><span className="catalog-title-id itemPrice float-right"
                        ><Trans>Unit Price</Trans>: {this.state.ItemPrice == "Price on request" ? t("Price on request") : this.state.ItemPrice}</span></div> </div>: null}
                        
                        {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                        <div className="row mb-3 d-none">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>ID</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value catalog-id">{this.state.ItemId}</div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Noun</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemNoun}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Manufacturer</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemManufacturerName}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Description</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemfeatureDesc}</div>
                        </div>
                        {/*[10/13/21, PC_01, CAS - 36], Dhevanesam R*/}
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Client</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemClient}</div>
                        </div>
                        <div className="row mb-3">
                            {/*[11/17/21, PC_ID_01, Bug - 1797, Dhevanesam R]*/}
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Item Group</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemGroup}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Catalog Path</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemCatalogPath}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>unitofmeasure_key</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemUOM}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Category</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemCategory}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Modifiers</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemModifiers}</div>
                        </div>
                        <div className="row mb-3">
                            {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Supplier Part Number</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemId}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label">UNSPSC</div>
                            <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemUNSPSC}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 col-lg-2 catalog-label"><Trans>Item Type</Trans></div>
                            <div className="col-md-12 col-lg-10 catalog-value">
                                <span>{this.state.ItemType}</span>
                                {this.state.ShowExtentDespPopUp == 1 ?
                                    <a className="float-right helptext-link attachment-file-name" href="#" data-toggle="modal" data-target="#extended-client"><Trans>Show Extended Description</Trans></a> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs catalog-tabs-mobile catalog-tabs mt-4" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="catalog-details-tab" data-toggle="tab" href="#catalog-details" role="tab" aria-controls="catalog-details" aria-selected="true"><Trans>{this.state.ItemTypeFav == "CAT" ? "Catalog Details" : "Non Catalog Details"}</Trans></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="search-sites-tab" data-toggle="tab" href="#search-sites" role="tab" aria-controls="search-sites" aria-selected="false" onClick={() => this.TabClick("SITE")}><Trans>Search Sites</Trans></a>
                    </li>
                    {this.state.UserType == 'SDI' ?
                        <>
                            <li className="nav-item">
                                <a className="nav-link" id="bin-location-tab" data-toggle="tab" href="#bin-location" role="tab" aria-controls="bin-location" aria-selected="false" onClick={() => this.TabClick("BIN")}><Trans>Bin Location</Trans></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="purchase-history-tab" data-toggle="tab" href="#purchase-history" role="tab" aria-controls="purchase-history" aria-selected="false" onClick={() => this.TabClick("CYCPURC")}><Trans>Purchase History</Trans></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="transactions-tab" data-toggle="tab" href="#transactions" role="tab" aria-controls="transactions" aria-selected="false" onClick={() => this.TabClick("CYCTRANS")}><Trans>Transactions</Trans></a>
                            </li>
                        </>
                        : null}
                </ul>
                <div className="tab-content catalog-tab-content Catalog-Items" id="myTabContent">
                    <div className="tab-pane p-3 fade show active" id="catalog-details" role="tabpanel" aria-labelledby="catalog-details-tab">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Product Description</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemDescription}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Features</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemfeatureDesc}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Item ID</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemMFGPartNumber}</div>
                                </div>
                                <div className="row mb-3">
                                    {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Supplier Part Number</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemId}</div>
                                </div>
                                <div className="row mb-3">
                                    {/*//[2/18/22, CAS - 31, Dhevanesam R]*/}
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>MFG name</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemManufacturerName}</div>
                                </div>
                                {/*//[06/02/23,PS_ZE_125_APP_13, Johnprinto D]*/}
                                {/*//[07/03/24,PS_ZE_286_APP_01, Madhumitha K-Hide the Quantity available label for ORO Items]*/}

                                {this.state.invItemFlag == true && this.state.ItemQuantityAvail != "ORO"?<div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Quantity Available</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{isNaN(this.state.ItemQuantityAvail) == true ? "0" : this.state.ItemQuantityAvail}</div>
                                </div>:<></>}
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Supplier</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.ItemSupplierName}</div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Package Quantity</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.PackageQuantity}</div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>SAP Article Number</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.SAP_Article_Number}</div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Hazard Code</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.HazardCode}</div>
                                </div>
                                {/*[3/11/22, PC_ID_01, CAS - 31, Dhevanesam R]*/}
                                {/*[3/11/22, PS_ZE_125_APP_12, Johnprinto D]*/}
                                <div className="row mb-3">
                                    {/*<div className="col-md-12 col-lg-2 catalog-label"><Trans>Usually ships</Trans> <Trans>{this.state.ShipWIthinVal}</Trans> <Trans>{this.state.ShipWIthin}</Trans> <Trans>days</Trans></div>*/}
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Ships By</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value">{this.state.ShipWIthin}</div>
                                </div>

                                {this.state.showprice == "0" ? <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Price</Trans></div>
                                    <div className="col-md-12 col-lg-10 catalog-value itemdetailPrice">{this.state.ItemPrice == "Price on request" ? t("Price on request") : this.state.ItemPrice}</div>
                                    {this.state.ItemSupplierName == "ORS NASCO" ? <div className="col-md-12 col-lg-10 catalog-value GreenColour"><Trans>This price is subject to confirmation by supplier</Trans></div> : null}
                                </div> : null}
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label"><Trans>Total Order Qty</Trans></div>
                                    <div className="quantity buttons_added" style={{marginLeft : "1%",width: "30%", float: "left" ,marginRight: "15%"}}>
                                        <input type="button" className="minus" value="-" disabled={this.state.Quantityvalue <= 1 || this.state.Quantityvalue == ""} onClick={(e) => this.qtyOnchange(e, "QTY_DECREASED")}/>
                                        <input type="number" value={this.state.Quantityvalue} maxLength={8} step=" 1" class="minus" min=" 1" max=" 2" pattern="" inputmode="" style={{ "width": "50px", "borderLeft": "0" }} onChange={(e) => this.qtyOnchange(e, "QTY_CHANGE")} />
                                        <input type="button" className="plus" value="+" disabled={this.state.Quantityvalue >= maxQty} onClick={(e) => this.qtyOnchange(e, "QTY_INCREASED")} />
                                        <button className="btn btn-custom-primary font-14 font-medium ml-1" onClick={()=>this.isRTIItem(true)}><img src="images/cart-icon.svg" alt="cart" /></button>

                                        {/* <button className="btn btn-primary custom-btn-primary primary-btn px-4 Mobile-Item-btn" onClick={() => this.AddToCart()}>
                                            <span className="item-cart mr-2" />
                                            <Trans>Add to Cart</Trans>
                                        </button> */}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label-title"><Trans>Attributes</Trans></div>
                                </div>
                                {this.state.AttributeName != null && this.state.AttributeValue != null && this.state.AttributeName != undefined && this.state.AttributeValue != undefined && this.state.AttributeName != "" && this.state.AttributeValue != "" ?
                                    this.BindAttribute() : <div className="row mb-3">
                                        <div className="col-md-12 col-lg-2 catalog-label"></div>
                                        <div className="col-md-12 col-lg-10 catalog-value"><Trans>No attributes avaliable</Trans></div>
                                    </div>}
                                {/*//[06/28/22,PC_ID_06, CAS - Client Attribute Display in Item Details Panel, Dhevanesam R]*/}
                                <div className="row mb-3">
                                    <div className="col-md-12 col-lg-2 catalog-label-title"><Trans>Client Attributes</Trans></div>
                                </div>
                                {this.state.ClientAttVal != null && this.state.ClientAttVal != undefined && this.state.ClientAttVal != "" ?
                                    this.BindClientAttribute() : <div className="row mb-3">
                                        <div className="col-md-12 col-lg-2 catalog-label"></div>
                                        <div className="col-md-12 col-lg-10 catalog-value"><Trans>No client attributes available</Trans></div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-3 fade" id="item-detail" role="tabpanel" aria-labelledby="item-details-tab">
                        <div className="row">
                            <div className="table-responsive">
                                {this.state.BUItemsState.length != 0 ?<table className="table table-hover ZEUS_Grid">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="position-sort-icon"><Trans>Owned By</Trans><span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "Owned", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "Owned", "down")} /></span></th>
                                            <th scope="col" className="position-sort-icon"> <Trans>Store Room</Trans> <span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "Store", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "Store", "down")} /></span></th>
                                            <th scope="col" className="position-sort-icon"> <Trans>Store Room Description</Trans> <span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "StroeDescp", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "StroeDescp", "down")} /></span></th>
                                            <th scope="col" className="position-sort-icon"> <Trans>Replenish</Trans> <span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "Replenish", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "Replenish", "down")} /></span></th>
                                            <th scope="col" className="position-sort-icon"> <Trans>Reorder Paint</Trans> <span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "Reorder", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "Reorder", "down")} /></span></th>
                                            <th scope="col" className="position-sort-icon"> <Trans>Maximum</Trans> <span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "Maxi", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "Maxi", "down")} /></span></th>
                                            {/*//[7/11/22, PC_ID_12, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R*/}
                                            <th scope="col" className="position-sort-icon"> <Trans>QTY Open PO</Trans> <span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "QTYOpenPO", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "QTYOpenPO", "down")} /></span></th>
                                            <th scope="col" className="position-sort-icon"> <Trans>Quantity Available</Trans> <span className="sort-up ml-1 d-none"><img src="Images/sort2.svg" onClick={() => this.sortingColumns("1", "Quantity", "up")} /></span><span className="sort-down ml-1 d-none"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("1", "Quantity", "down")} /></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.BindBUItems()}
                                    </tbody>
                                </table> : <span><Trans>No Records to display</Trans></span>}
                            </div>
                        </div>
                        <br></br>
                        <h4 className="item-title"><Trans>Item More Details</Trans></h4>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    {/*[11/17/21, PC_ID_03, Bug - 1797, Dhevanesam R]*/}
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Issue Charge Code</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.IssueChargeCode}</div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>First Charge Code</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.FirstChargeCode}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Hazard Code</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.HazardCode}</div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label"><Trans>Inspection Required</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.InspectionRequired}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Equipment Tag</Trans>(ETAG) :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.EquipmentTag}</div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Regrind</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.Regrind}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Purchasing Dept Spec</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.PurchasingDept}</div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Machine Control Num</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.MachineControl}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Drawing Code</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.DrawingCode}</div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Machine Number</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.MachineNum}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Standard Leadtime</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.StandardLaedTime}</div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-md-4 catalog-label Item-Tab2-label pr-0"><Trans>Critical Spare</Trans> :</div>
                                    <div className="col-md-8 catalog-value Item-Tab2-Value">{this.state.CriticalSpare}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-3 fade" id="usage" role="tabpanel" aria-labelledby="usage-tab">
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table table-hover ZEUS_Grid usage-grid last-row-total">
                                    <thead>
                                        <tr>
                                            <th scope="col"><Trans>Current Year</Trans></th>
                                            <th scope="col"><Trans>Quantity</Trans></th>
                                            <th scope="col"><Trans>Previous Year</Trans></th>
                                            <th scope="col"><Trans>Quantity</Trans></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.UsageTabBind()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-3 fade" id="issues" role="tabpanel" aria-labelledby="issues-tab">
                        <div className="row">
                            <div className="table-responsive">
                                {this.state.IssueState != [] && this.state.IssueState != null && this.state.IssueState != undefined ?
                                    <div>{this.state.IssueState.length != 0 ? <table className="table table-hover ZEUS_Grid">
                                        <thead>
                                            <tr>
                                                <th scope="col"><Trans>Transaction Date</Trans></th>
                                                <th scope="col"><Trans>type</Trans></th>
                                                <th scope="col"><Trans>Quantity</Trans></th>
                                                <th scope="col"><Trans>Employee ID</Trans></th>
                                                <th scope="col"><Trans>workorder_key</Trans></th>
                                                <th scope="col"><Trans>Name</Trans></th>
                                                <th scope="col"><Trans>Charge Code</Trans></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.IssueTabBind()}
                                        </tbody>
                                    </table> : <span><Trans>No Records to display</Trans></span>}</div> : <span><Trans>No Records to display</Trans></span>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-3 fade" id="bin-location" role="tabpanel" aria-labelledby="bin-location-tab">
                        <div className="row">
                            <div className="table-responsive">
                                {this.state.LocDataSetState != [] && this.state.LocDataSetState != null && this.state.LocDataSetState != undefined ?
                                    <div>{this.state.LocDataSetState.length != 0 ? <table className="table table-hover ZEUS_Grid usage-grid">
                                        <thead>
                                            <tr>
                                                <th scope="col"><Trans>Owner</Trans></th>
                                                <th scope="col"><Trans>Description</Trans></th>
                                                <th scope="col"><Trans>Crib</Trans></th>
                                                <th scope="col"><Trans>Aisle</Trans></th>
                                                <th scope="col"><Trans>Row</Trans></th>
                                                <th scope="col"><Trans>Shelf</Trans></th>
                                                <th scope="col"><Trans>Bin</Trans></th>
                                                <th scope="col"><Trans>Quantity</Trans></th>
                                                <th scope="col"><Trans>Lot/Serial Number</Trans></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.BinLocationBind()}
                                        </tbody>
                                    </table> : <span><Trans>There are no bin locations to display for this item</Trans>.</span>}</div>
                                    : <span><Trans>There are no bin locations to display for this item</Trans>.</span>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-3 fade" id="search-sites" role="tabpanel" aria-labelledby="search-sites-tab">
                        <div className="row">
                            <div className="table-responsive">
                                {this.state.MultiSiteDisplayState != null && this.state.MultiSiteDisplayState != [] && this.state.MultiSiteDisplayState != undefined ?
                                    <div>
                                        {this.state.MultiSiteDisplayState.length > 0 ?
                                            <table className="table table-hover ZEUS_Grid usage-grid">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"><Trans>Item ID</Trans></th>
                                                        <th scope="col"><Trans>Description</Trans></th>
                                                        <th scope="col"><Trans>unitofmeasure_key</Trans></th>
                                                        <th scope="col"><Trans>Quantity Available</Trans></th>
                                                        <th scope="col"><Trans>Site Name</Trans></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.MultiSiteBind()}
                                                </tbody>
                                            </table> : <span><Trans>Related site data not available at this time.</Trans></span>}</div>
                                    : <span><Trans>Related site data not available at this time.</Trans></span>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-3 fade" id="purchase-history" role="tabpanel" aria-labelledby="purchase-history-tab">
                        <div className="row">
                            {this.state.PurchaseState != [] && this.state.PurchaseState != null && this.state.PurchaseState != undefined ? <div className="table-responsive">
                                {this.state.PurchaseState.length > 0 ? <table className="table table-hover ZEUS_Grid usage-grid">
                                    <thead>
                                        <tr>
                                            <th scope="col" id="PT1" className="position-sort-icon"><Trans>Item ID</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT1", "2", "ItemId", "up", e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT1", "2", "ItemId", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT1", "2", "ItemId", "down")} /></span></th>
                                            <th scope="col" id="PT2" className="position-sort-icon"><Trans>PO Id</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT2", "2", "POID", "up", e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT2", "2", "POID", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT2", "2", "POID", "down")} /></span></th>
                                            <th scope="col" id="PT3" className="position-sort-icon"><Trans>Line NBR</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT3", "2", "LineNBR", "up", e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT3", "2", "LineNBR", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT3", "2", "LineNBR", "down")} /></span></th>
                                            <th scope="col" id="PT4" className="position-sort-icon"><Trans>Cancel Status</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT4", "2", "CancelStatus", "up", e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT4", "2", "CancelStatus", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT4", "2", "CancelStatus", "down")} /></span></th>
                                            <th scope="col" id="PT5" className="position-sort-icon"><Trans>PO Date</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT5", "2", "PODate", "up", e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT5", "2", "PODate", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT5", "2", "PODate", "down")} /></span></th>
                                            {/*[7/11/22, PC_ID_07, CAS - Item details - Lead time, Search other sites, Purchase history display (Same as in Zeus 1.0), Dhevanesam R*/}
                                            <th scope="col" id="PT8" className="position-sort-icon"><Trans>Due Date</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT8", "2", "DueDate", "up", e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT8", "2", "DueDate", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT8", "2", "DueDate", "down")} /></span></th>
                                            <th scope="col" id="PT6" className="position-sort-icon"><Trans>Quantity</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT6", "2", "Quantity", "up", e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT6", "2", "Quantity", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT6", "2", "Quantity", "down")} /></span></th>
                                            <th scope="col" id="PT7" className="position-sort-icon"><Trans>Net Received Quantity</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("PT7", "2", "NetRec", "up",e)}><img src="Images/sort2.svg" onClick={() => this.sortingColumns("PT7", "2", "NetRec", "up")} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("PT7", "2", "NetRec", "down")} /></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.PurchaseTabBind()}
                                    </tbody>
                                </table> : <span><Trans>Related site data not available at this time.</Trans></span>}
                            </div> : null}
                        </div>
                    </div>
                    {/*[5/10/22, CAS - 31, Dhevanesam R]*/}
                    <div className="tab-pane p-3 fade" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
                        <div className="row">
                            {this.state.TransTableState != [] && this.state.TransTableState != null && this.state.TransTableState != undefined ? <div className="table-responsive">
                                {this.state.TransTableState.length > 0 ? <table className="table table-hover ZEUS_Grid usage-grid">
                                    <thead>
                                        <tr>
                                            <th scope="col" id="TT1" className="position-sort-icon"><Trans>Transaction Date</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT1", "3", "Date", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT1", "3", "Date", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT1", "3", "Date", "down")} /></span></th>
                                            <th scope="col" id="TT2" className="position-sort-icon"><Trans>unitofmeasure_key</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT2", "3", "UOM", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT2", "3", "UOM", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT2", "3", "UOM", "down")} /></span></th>
                                            <th scope="col" id="TT3" className="position-sort-icon"><Trans>Quantity</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT3", "3", "Qty", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT3", "3", "Qty", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT3", "3", "Qty", "down")} /></span></th>
                                            <th scope="col" id="TT4" className="position-sort-icon"><Trans>Group</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT4", "3", "Group", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT4", "3", "Group", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT4", "3", "Group", "down")} /></span></th>
                                            <th scope="col" id="TT5" className="position-sort-icon"><Trans>Order Number</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT5", "3", "Order", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT5", "3", "Order", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT5", "3", "Order", "down")} /></span></th>
                                            <th scope="col" id="TT6" className="position-sort-icon"><Trans>Line #</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT6", "3", "Line", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT6", "3", "Line", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT6", "3", "Line", "down")} /></span></th>
                                            <th scope="col" id="TT7" className="position-sort-icon"><Trans>PO Number</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT7", "3", "PO", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT7", "3", "PO", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT7", "3", "PO", "down")} /></span></th>
                                            <th scope="col" id="TT8" className="position-sort-icon"><Trans>workorder_key</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT8", "3", "Word", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT8", "3", "Word", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT8", "3", "Word", "down")} /></span></th>
                                            <th scope="col" id="TT9" className="position-sort-icon"><Trans>Operator Entered By</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT9", "3", "Entered", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT9", "3", "Entered", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT9", "3", "Entered", "down")} /></span></th>
                                            <th scope="col" id="TT10" className="position-sort-icon"><Trans>Trans OperID</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT10", "3", "Trans", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT10", "3", "Trans", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT10", "3", "Trans", "down")} /></span></th>
                                            <th scope="col" id="TT11" className="position-sort-icon"><Trans>Store Room</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumnsempty(e)}><img src="Images/sort2.svg" /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" /></span></th>
                                            <th scope="col" id="TT12" className="position-sort-icon"><Trans>Store Room Description</Trans> <a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumnsempty(e)}><img src="Images/sort2.svg" /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" /></span></th>
                                            <th scope="col" id="TT13" className="position-sort-icon"><Trans>Receiver ID</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT13", "3", "Receiver", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT13", "3", "Receiver", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT13", "3", "Receiver", "down")} /></span></th>
                                            <th scope="col" id="TT14" className="position-sort-icon"><Trans>Receiver Line</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT14", "3", "Line2", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT14", "3", "Line2", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT14", "3", "Line2", "down")} /></span></th>
                                            <th scope="col" id="TT15" className="position-sort-icon"><Trans>PO Line</Trans><a href="#" className="sort-up ml-1" onClick={(e) => this.sortingColumns("TT15", "3", "POLine", "up", e)}><img src="Images/sort2.svg" onClick={(e) => this.sortingColumns("TT15", "3", "POLine", "up", e)} /></a><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={() => this.sortingColumns("TT15", "3", "POLine", "down")} /></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.TransTabBind()}
                                    </tbody>
                                </table> : <span><Trans>Related site data not available at this time.</Trans></span>}
                            </div> : <span><Trans>Related site data not available at this time.</Trans></span>}
                        </div>
                    </div>
                </div>
                <div className="modal" id="extended-client">
                    <div className="modal-dialog custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Extended Client Description</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <label className="extended-label d-block"></label>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="extended-content d-block">
                                            {this.state.ExtentDescription}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*//[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]*/}
                <div className="modal userid" id="WorkOrder" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog  custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Work Order Number</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.WOClose()}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-10 my-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label catalog-label"><Trans>workorder_key</Trans><span> #</span></label>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control " placeholder="Enter work order number" id="WONUM" name="WorkOrderNo" disabled={this.state.disableWo} maxLength="80" defaultValue={this.state.ValidatedWo} />
                                                {this.state.WOmsg != "" ? <span id="WOError" className="DropDown-Font-Size errorcolour"><Trans>{this.state.WOmsg}</Trans></span> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    {CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? <span className=" W-50 text-left " style={{ fontSize: '15px' }}>*<Trans>The items in the cart will be removed by changing the WO</Trans></span> : null}
                                    <button className={`btn btn-primary custom-btn-primary primary-btn px-3  ${CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? ' mt-3' : ''}`} id="WOOK" onClick={() => this.WOClick()}><Trans>Submit</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* Z-279 */}
                 <div className="modal" id="locationaccess" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered" style={{ maxWidth: "578px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Steps to enable location
                                    </h5>
                                    <button type="button" className="btn close-btn" data-dismiss="modal">X</button>
                                {/* Modal body */}
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="text-center my-2">
                                            <p className="text-left info-label text-danger w-100">Note : Please enable location to view Real Time Inventory pickup stores.</p>
                                            <p className="text-left info-label w-100">1.Click  <img src="Images/location-img.png"></img> The location icon in the top-right corner of the browser window.</p>
                                            <p className="text-left info-label w-100">2.Select "Always allow https://sdizeus2test.sdi.com to access your location" </p>
                                            <div className="text-left"><img src="Images/location-access-steps-1.png" style={{ width: "300px" }}></img></div>
                                            <p className="text-left info-label mt-2 w-100">3.Click the "Done" button</p>
                                            <p className="text-left info-label mt-2 w-100">4.To apply your updated settings to this site, reload the page</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-2 text-align-right">
                                    <button className="btn custom-rti-button px-lg-5 px-3 mx-3 font-bold" data-dismiss="modal" onClick={() => this.isRTIItem(false)}>Add for delivery</button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-lg-5 px-4 font-bold" data-dismiss="modal" onClick={()=> this.locationEnable()}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Favourite Item Pop up*/}
                <div className="modal" id="add-fav-popup" data-backdrop="static" data-keyboard="false">

                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Add Favorites</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() =>this.CloseFavPopup()}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row mt-3">
                                    <div className="col-12 edit-desc-field mb-4">
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>Item ID</Trans></label>

                                            {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                            <label className="col-md-7 quick-value" id="FavItemId" >{this.state.FavMFGPartNUMPopUp}</label>
                                        </div>
                                        {/*<div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>SDI Category</Trans></label>
                                            <label className="col-md-7 quick-value" id="FavSDICatg" >{this.state.FavSDICatgPopUp}</label>
                                        </div>*/}
                                        <div className="row mb-3">
											<label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Category</Trans></label>
                                            {/*<div className="col-md-7">
                                                <textarea className="form-control custom-textarea" id="FavYourCatg" maxLength="40" rows={3} defaultValue={''} />
                                            </div>*/}
                                            <div className="float-left search-autocomplete col-md-7 home-search" style={{ zIndex: "9999" }}>
                                                <Autocomplete
                                                    getItemValue={this.getItemValue}
                                                    items={this.state.bindCategory}
                                                    renderItem={this.renderItem}
                                                    value={this.state.yourFavCat}
                                                    onChange={e => this.onChange(e, "CAT")}
                                                    onSelect={e => this.onSelect(e, "CAT")}
                                                    inputProps={{ placeholder: "Search", className: "form-control float-left" }}
                                                    maxLength="40"
                                                />
                                                <span className="errorspan1" style={{ fontSize: '10px' }} id="favordcatid" />
												{this.state.categoryError ? <span className=" W-100 errorspan mr-3" style={{ fontSize: '15px' }}>Enter category</span> : null}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
											<label className="col-md-5 quick-label "><span style={{ "color": "red" }}>*</span><Trans>Sub category</Trans></label>

                                            <div className="float-left search-autocomplete col-md-7 home-search">
                                                <Autocomplete
                                                    getItemValue={this.getItemValue}
                                                    items={this.state.bindSubCategory}
                                                    renderItem={this.renderItem}
                                                    value={this.state.yourFavSubCat}
                                                    onChange={e => this.onChange(e, "SUBCAT")}
                                                    onSelect={e => this.onSelect(e, "SUBCAT")}
													inputProps={{ placeholder: "Search", className: "form-control float-left", disabled: this.state.yourFavCat.length > 0 ? false : true }}
                                                    maxLength="40"
                                                />
                                                <span className="errorspan1" style={{ fontSize: '10px' }} id="favordsubcatid" />
												{this.state.subCategoryError ? <span className=" W-100 errorspan1 text-left " style={{ fontSize: '15px' }}>Enter sub category</span> : null}
                                            </div>
                                        </div>

                                        <div className="row mb-3">
											<label className="col-md-5 quick-label"><Trans>Item description</Trans></label>
                                            <label className="col-md-7 quick-value" id="FavItemDescrip" >{this.state.FavItemDescripPopUp}</label>
                                        </div>
                                        <div className="row mb-3">
											<label className="col-md-5 quick-label"><Trans>Your description</Trans></label>
                                            <div className="col-md-7">
                                                <textarea className="form-control custom-textarea" id="FavYourDescrip" maxLength="100" rows={5} defaultValue={''} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
											<label className="col-md-5 quick-label"><Trans>MFG</Trans></label>
                                            <label className="col-md-7 quick-value" id="FavMFG" >{this.state.FavMFGPopUp}</label>
                                        </div>
                                        <div className="row mb-3">

                                            {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                            <label className="col-md-5 quick-label"><Trans>Supplier Part Number</Trans></label>
                                            <label className="col-md-7 quick-value" id="FavMFGPartNUM" >{this.state.FavItemIdPopUp}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" id="FavSaveBtn" onClick={() => this.AddfavItem()}><Trans>Save</Trans></button>
                                    </div>
                                    <div>
                                        <label id="txtUOMHide" >{this.state.txtUOMHidePopUp}</label>
                                        <label id="txtMfgItemidHide" >{this.state.txtMfgItemidHidePopUp}</label>
                                        <label id="txtItemidHide" >{this.state.txtItemidHidePopUp}</label>
                                        <label id="txtMFGHide" >{this.state.txtMFGHidePopUp}</label>
                                        <label id="ItemType" >{this.state.ItemTypeFav}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};
export default withTranslation()(ItemDetailNew);