import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class OrderStatusStore extends EventEmitter {
    PageLoadData = "";
    ApprovalHistoryData = "";
    PODetailData = "";
    cartOrderSuccess = "";
    Excel = "";
    PassThroughAuthe = "";
    ApproverList = "";
    RemainderData = "";
    OrderNotes = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleOrderStatusStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.OrderStatus_PageLoad: {
                this.PageLoadData = action.action.response;
                debugger;
                this.emit('OrdStatusload');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_btnSavePrefs_Click: {
                this.PageLoadData = action.action.response;
                debugger;
                this.emit('SavePreference');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_ApprovalHistory: {
                this.PageLoadData = action.action.response;
                debugger;
                this.emit('ApprovalHistory');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_RetrieveOrderData: {
                this.PageLoadData = action.action.response;
                debugger;
                this.emit('data');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_PoDetails: {
                this.PODetailData = action.action.response;
                this.emit('POdetail');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_ReorderItems: {
                this.cartOrderSuccess = action.action.response;
                debugger;
                this.emit('BuyAgain');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_ExportToExcel: {
                this.Excel = action.action.response;
                debugger;
                this.emit('Export');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_OrderDetail: {
                this.Excel = action.action.response;
                debugger;
                this.emit('OrderDetail');
                break;
            }
            case SDIConstants.methodNames.OrderStatus_ShipTo: {
                this.Excel = action.action.response;
                debugger;
                this.emit('shipto');
                break;
            }
            case "ORDERSTS_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "ORDERSTS_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "ORDERSTS_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "HeaderData": {
                this.HeaderData = action.action.response;
                this.emit('HeaderData');
                break;
            }
            case "LineData": {
                this.LineData = action.action.response;
                this.emit('LineData');
                break;
            }
            case "CancelRequest": {
                this.CancelRequest = action.action.response;
                this.emit('CancelRequest');
                break;
            }
            //[5/9/2023]Zeus -100 PTA link for incident button API call - Poornima
            case "PassThroughAuthe": {
                debugger
                this.PassThroughAuthe = action.action.response;
                this.emit('PassThroughAuthe');
                break;
            }
            case "ApproverList": {
                this.ApproverList = action.action.response;
                this.emit('ApproverList');
                break;
            }
            case "ApprovalRemainder": {
                this.RemainderData = action.action.response;
                this.emit('ApprovalRemainder');
                break;
            }
            //[3/27/2024]Z 268 OrderNotes case to receive order notes from action and to send to component
            case "OrderNotes": {
                this.OrderNotes = action.action.response;
                this.emit('OrderNotes');
                break;
            }

        }
    }
}
const objordStatusStore = new OrderStatusStore;
// Dispatcher code
Dispatcher.register(objordStatusStore.handleOrderStatusStore.bind(objordStatusStore));
export default objordStatusStore;