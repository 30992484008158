/*
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Change Password page component. The component displays the Change Password page details.
 * 
 * App Name: SDI-Walmart
 * Author: M.Sushmitha
 * Created Date: 09/01/2020 
 */
/*
 * CP_PC_01
 * */
import React, { Component } from 'react';
import * as ChangePasswordAction from '../actions/ChangePasswordAction';
import ChangePasswordStore from '../stores/ChangePasswordStore';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import { withTranslation, Trans } from 'react-i18next';
import * as CookieService from '../CookieService';
import swal from 'sweetalert';
class ChangePassword extends Component {
    /**
     * CP_PC_03
     * Declare a “constructor” method with “props” as its parameters.
     */
    constructor(props) {
        super(props);
        /**
     * CP_PC_04
     * */
        this.RespMessage = this.RespMessage.bind(this);
        this.state = {
            CHangePassword: true,
            LoginUserId: "",
            OldPass: "",
            NewPass: "",
            CNewPass: "",
            OPWError: "",
            NPWError: "",
            ConfirmPWError: "",
            ErrorMessage: ""
        }
    }
    /**
     * CP_PC_05
     * */
    componentDidMount() {
        //const queryParams = new URLSearchParams(window.location.search)
        //const QS_src = queryParams.get('USERID');
        //this.setState({ LoginUserId: QS_src })
        //console.log("queryParams", queryParams)
        //console.log("QS_src", QS_src)
        /**
     * CP_PC_06
     * */
        
        ChangePasswordStore.on("ChangePwd", this.RespMessage);
    }
    componentWillUnmount() {
        ChangePasswordStore.removeListener("ChangePwd", this.RespMessage.bind(this));
    }
    /**
     * CP_PC_08 && CP_PC_12
     * */
    UpdatePassword = () => {
        const { t } = this.props;
        let OldPassword = $('#Oldpass').val()
        let NewPassword = $('#NewPass').val()
        let ConfirmPassword = $('#ConNewPass').val()
        
        let OPWErrMsg = "";
        let NPWErrMsg = "";
        let ErrorMsg = "";
        let ConfirmPWErrMsg = "";
        let validate = 0
        if (!OldPassword) {
            OPWErrMsg = "Old Password is required";
            validate = 1
        }
        else {
            OPWErrMsg = ""
        }
        if (!NewPassword) {
            NPWErrMsg = "New Password is required";
            validate = 1
        }
        else {
            NPWErrMsg = "";
            let firstletter1 = NewPassword.slice(0, 1)
            let Numtest = NewPassword.match(/[0-9]/g)
            if (NewPassword.length < 6) {
                validate = 1
                NPWErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!";
            }
            else if ((/[a-zA-Z]/).test(firstletter1) == false || Numtest == null || Numtest == undefined || Numtest == "") {
                validate = 1
                NPWErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!";
            }
            else {
                NPWErrMsg = "";
            }
        }
        if (!ConfirmPassword) {
            ConfirmPWErrMsg = "Confirm Password is required";
            validate = 1
        }
        else {
            ConfirmPWErrMsg = "";
            let firstletter = ConfirmPassword.slice(0, 1)
            let NumtestC = ConfirmPassword.match(/[0-9]/g)
            if (ConfirmPassword.length < 6) {
                validate = 1
                ConfirmPWErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"
            }
            else if ((/[a-zA-Z]/).test(firstletter) == false || NumtestC == null || NumtestC == undefined || NumtestC == "") {
                validate = 1
                ConfirmPWErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!"
            }
            else {
                ConfirmPWErrMsg = "";
            }
        }
        if (ConfirmPassword != NewPassword && ConfirmPassword) {
            ErrorMsg = "Password doesn't match"
            validate = 1
        }
        else {
            ErrorMsg = ""
        }
        if (validate == 0) {
            const queryParams = new URLSearchParams(window.location.search)
            const QS_src = queryParams.get('USERID');
            this.setState({ LoginUserId: QS_src })
            console.log("queryParams", queryParams)
            console.log("QS_src", QS_src)
            let ChangePassBo = {
                Session_UserID: QS_src,
                Old_Pwd: OldPassword,
                New_Pwd: NewPassword
            }
            ChangePasswordAction.UpdateBtn_clk(ChangePassBo)
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
        this.setState({ OPWError: OPWErrMsg, NPWError: NPWErrMsg, ConfirmPWError: ConfirmPWErrMsg, ErrorMessage: ErrorMsg })
    }
    /**
     * CP_PC_09 && CP_PC_10 && CP_PC_11
     * */
    RespMessage = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let Validate = JSON.parse(ChangePasswordStore.ChangePwdList);
        console.log("Validate", Validate)
        let MesageTable = Validate.MessageTable
        if (MesageTable[0].OldPassword_Not_Exist != null) {
            document.getElementById("ErrorMsg").innerHTML = (t(MesageTable[0].OldPassword_Not_Exist));
        }
        else {         
            //alert(t(MesageTable[0].Success_Message));
            CookieService.updateLocalStorageValues("ShowNormalLogin", true);
            /*this.props.history.push({
                pathname: '/',
            });*/
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t(MesageTable[0].Success_Message)
            }).then(() => {
                this.props.history.push({
                    pathname: '/',
                });
            });
        }
    }
    CancelClick = () => {
        this.props.history.push({
            pathname: '/',
        });
    }
    handleProfileFormChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    render() {
        //[11/26/21, CAS - 31, Priyanka D]
        const { t } = this.props;
        $(".login-control").on('keyup change', function (event) {
            
            let id = $(this).attr('id');
            let targetval = $('#' + id).val();
            switch (id) {
                case "Oldpass": {
                    if (targetval == "") {
                        document.getElementById("ErrorMsg1").innerHTML = (t("Old Password is required"));
                    }
                    else {
                        document.getElementById("ErrorMsg1").innerHTML = "";
                    }
                    break;
                }
                case "NewPass": {
                    if (targetval == "") {
                        document.getElementById("ErrorMsg2").innerHTML = (t("New Password is required"));
                    }
                    else {
                        document.getElementById("ErrorMsg2").innerHTML = "";
                        let firstletter1 = targetval.slice(0, 1)
                        let Numtest = targetval.match(/[0-9]/g)
                        if (targetval.length < 6) {
                            document.getElementById("ErrorMsg2").innerHTML = (t("Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"));
                        }
                        else if ((/[a-zA-Z]/).test(firstletter1) == false || Numtest == null || Numtest == undefined || Numtest == "") {
                            document.getElementById("ErrorMsg2").innerHTML = (t("Password must contain at least 1 number and 1 letter - must start with a letter!"));
                        }
                        else {
                            document.getElementById("ErrorMsg2").innerHTML = "";
                        }
                    }
                    break;
                }
                case "ConNewPass": {
                    if (targetval == "") {
                        document.getElementById("ErrorMsg3").innerHTML = (t("Confirm Password is required"));
                    }
                    else {
                        document.getElementById("ErrorMsg3").innerHTML = "";
                        let firstletter = targetval.slice(0, 1)
                        let NumtestC = targetval.match(/[0-9]/g)
                        if (targetval.length < 6) {
                            document.getElementById("ErrorMsg3").innerHTML = (t("Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"))
                        }
                        else if ((/[a-zA-Z]/).test(firstletter) == false || NumtestC == null || NumtestC == undefined || NumtestC == "") {
                            document.getElementById("ErrorMsg3").innerHTML = (t("Password must contain at least 1 number and 1 letter - must start with a letter!"))
                        }
                        else {
                            document.getElementById("ErrorMsg3").innerHTML = "";
                        }
                    }
                    break;
                }
            }
        });
        return (this.state.CHangePassword == true ? < React.Fragment >
            <p className="text-white mt-4 mb-3 font-24 font-thin"><Trans>Change Password</Trans></p>
            <div className="Password position-relative"><input type="password" id="Oldpass" className="form-control font-12 login-control" placeholder={t("Enter Old Password")} name="OldPass" maxLength="10" value={this.state.OldPass} onChange={this.handleProfileFormChange}/>
                <div className="mc-Margin pb-3"><span className="errorcolour DropDown-Font-Size float-left text-left" id="ErrorMsg1">{t(this.state.OPWError)}  </span></div>
            </div>
            <div className="Password position-relative"><input type="password" id="NewPass" className="form-control font-12 login-control" placeholder={t("Enter New Password")} name="NewPass" maxLength="10" value={this.state.NewPass} onChange={this.handleProfileFormChange}/>
                <div className="mc-Margin pb-3"><span className="errorcolour DropDown-Font-Size float-left text-left" id="ErrorMsg2">{t(this.state.NPWError)}   </span></div>
            </div>
            <div className="Password position-relative"><input type="password" id="ConNewPass" className="form-control font-12 login-control" placeholder={t("Enter Confirm Password")} name="CNewPass" maxLength="10" value={this.state.CNewPass} onChange={this.handleProfileFormChange}/>
                <div className="mc-Margin pb-3"><span className="errorcolour DropDown-Font-Size float-left text-left" id="ErrorMsg3"> {t(this.state.ConfirmPWError)}  </span></div>
            </div>
            
            <div className="mb-3 pb-2">
                <span className="errorcolour DropDown-Font-Size float-left text-left" id="ErrorMsg"> {t(this.state.ErrorMessage)}  </span>
                </div>
            <div className="row mb-3">
                <div className="col-md-6 mb-3">
                    <button className="btn reg-cancel-btn w-100" onClick={() => this.CancelClick()}><Trans>Cancel</Trans></button>
                </div>
                <div className="col-md-6">
                    <button className="btn btn-primary custom-btn-primary primary-btn w-100 wallet-btn" onClick={() => this.UpdatePassword()}><Trans>Update</Trans></button>
                </div>
            </div>
        </React.Fragment > : null)
    }
}
export default withTranslation()(ChangePassword);

