import React, { Component } from 'react';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
//[PC_07, 09/07/21, Author - Priyanka D]
import { withTranslation, Trans } from 'react-i18next'
const renderUnderConstruction = (props) => {
    if (!$(`#${SDIConstants.SDILoader}`).hasClass("hide")) {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    return (<div className="container-fluid content-section">
        <div className="row">
            <section className="site-undercontruction container text-center center-panel">
                <p className="primaryinfo"><Trans>Site Under Construction</Trans></p>
                <p className="secondaryinfo"><Trans>Will be Back Soon</Trans>!</p>
                <div className="img-section">
                    <img src="/Images/underconstruction.png" alt="imagefile" />
                </div>
                <div className="text-center my-3">
                    <button className="btn btn-primary custom-btn-primary primary-btn" onClick={() => {
                        props.history.push({
                            pathname: '/',
                        });
                    }} ><Trans>Back To Home</Trans></button>
                </div>
            </section>
        </div>
    </div>)
}
const UnderConstructionComponent = renderUnderConstruction;
//[PC_07, 09/07/21, Author - Priyanka D]
export default withTranslation()(UnderConstructionComponent);