//[05/24/2023, PC_ZE_87_APP_02 - Johnprinto D]
import React, { Component, useState, useEffect } from 'react';
import LoginStore from '../stores/LoginStore';
import PunchInStore from '../stores/PunchinStore';
import AddNewPunchInStore from '../stores/AddNewPunchinUserStore';
import * as CookieService from '../CookieService';
//[PC_07, 09/07/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
const FooterComponent = (props)=> {
    
    /*if (CookieService.getLocalStorageValues("Session_SITE_CONTACTS")) {
        localStorage.setItem("SDI_SITECONTACTS", CookieService.getLocalStorageValues("Session_SITE_CONTACTS"));
    }*/
    //[05/24/2023, PC_ZE_87_APP_03 - Johnprinto D]
    let n = CookieService.getLocalStorageValues("Session_PHONENUM") != undefined && CookieService.getLocalStorageValues("Session_PHONENUM") != ""
        && CookieService.getLocalStorageValues("Session_PHONENUM") != null ? CookieService.getLocalStorageValues("Session_PHONENUM") : '';
    let m = CookieService.getLocalStorageValues("Session_EMAILID") != undefined && CookieService.getLocalStorageValues("Session_EMAILID") != ""
        && CookieService.getLocalStorageValues("Session_EMAILID") != null ? CookieService.getLocalStorageValues("Session_EMAILID") : '';
    const [numState, setNumState] = useState(n);
    const [emailState, setEmailState] = useState(m);
    //[05/24/2023, PC_ZE_87_APP_04 - Johnprinto D]
	useEffect(() => {
		LoginStore.on('contactChange', handleChange); // Listening for the change event
		//[Zeus 208, 11/24/2023] - Footer component not rendered bug fix - Kishore
		PunchInStore.on('contactChange', handleChange);
		AddNewPunchInStore.on('contactChange', handleChange);
		return () => {
			LoginStore.removeListener('contactChange', handleChange); // Cleaning up the event listener on component unmount
			PunchInStore.removeListener('contactChange', handleChange);
			AddNewPunchInStore.removeListener('contactChange', handleChange);
		};
	},[]);
    //[05/24/2023, PC_ZE_87_APP_05 - Johnprinto D]
    const handleChange = () => {
        setNumState(CookieService.getLocalStorageValues("Session_PHONENUM"));
        setEmailState(CookieService.getLocalStorageValues("Session_EMAILID"));
    };
    /*console.log("Page Render for page load",CookieService.getLocalStorageValues("Session_PHONENUM"));
    et SITE_CONTACTS = localStorage.SDI_SITECONTACTS; 
    let phonetxt1 = "(215) 633-1900";
    let hyperlink1Txt = "contact sdi customer care";
    let hyperlink1Lnk = "mailto:FieldOps.Helpdesk@sdi.com"
    if (SITE_CONTACTS) {
       let siteContacts = SITE_CONTACTS.split("|");
        phonetxt1 = siteContacts(1);
        hyperlink1Txt = siteContacts(2);
        hyperlink1Lnk = siteContacts(3);
    }*/
    //[11/26/21, CAS - 31, Dhevanesam R]
    return (<footer className="footer">
        <div className="col-md-12 float-left">
            <div className="footer-copyrights float-left">
            </div>
            <span className="leftwrap">{`© 2013-${new Date().getFullYear().toString()} sdi,`} <Trans>inc. All Rights Reserved</Trans></span>
            <div className="Wrapfooter">
                {/*[05/24/2023, PC_ZE_87_APP_06 - Johnprinto D]*/}
                {numState != "" ? <>
                <a href={"tel:" + numState} className="footer-contacts text-decoration-none"><Trans>call us </Trans>{numState}</a>
                <span className="footer-slash mx-1">/</span> </> : null}
                <a href="https://www.sdi.com/" className="footer-contacts">http://www.sdi.com/</a>
                {/*[05/24/2023, PC_ZE_87_APP_06 - Johnprinto D]*/}
                {emailState != "" ? <>
                    <span className="footer-slash mx-1">/</span>
                    {/*[05/24/2023, PC_ZE_87_APP_06 - Johnprinto D]*/}
                    <a href={"mailto:" + emailState} className="footer-contacts"><Trans>contact sdi customer care</Trans></a>
                </> : null}
            </div>
        </div>
    </footer>)
}
//const FooterComponent = footerContent;
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(FooterComponent);
//const EnhancedFooterComponent = withTranslation()(FooterComponent);
//export default EnhancedFooterComponent;