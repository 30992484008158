import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
import * as MenuAction from './MenuAction';
//[09/21/21, PC_05, Dhevanesam R, Priyanka D] 
const GetData = (GetDataBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Receiving,
            SDIConstants.methodNames.GetData,
            JSON.stringify(GetDataBO),

            (data) => {
                //[09/22/21, PC_10 Dhevanesam R, Priyanks D]
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.GetData, response: data.response };
                        debugger;                        
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const GetMMReceivingPageLoad = (MMReceivingPageLoadBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Receiving,
            SDIConstants.methodNames.ReceivingPageLoad,
            JSON.stringify(MMReceivingPageLoadBO),

            (data) => {
                if (data.success) {
                    //debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.ReceivingPageLoad, response: data.response };
                        //debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

//[11/29/23, Regression-6 Bug (4364) - To get the response on pageload - Harshitha G] 
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        debugger;
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: "Receive_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

//[11/29/23, Regression-6 Bug (4364) - To insert the page in favourite pages - Harshitha G]
const insertFavPage = async (insertFavPageBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "Receive_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

//[11/29/23, Regression-6 Bug (4364) - To delete the page from favourite pages - Harshitha G]
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "Receive_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};



const MMReceivingPODetail = (MMReceivingPODetailBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Receiving,
            SDIConstants.methodNames.getPODetails,
            JSON.stringify(MMReceivingPODetailBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    debugger
                    if (data.response != "" && data.response) {
                        //debugger;
                        let contentData = { type: SDIConstants.methodNames.getPODetails, response: data.response };
                        Dispatcher.dispatch(contentData);
                    } else {
                        //[5/10/22, CAS - 31, Dhevanesam R]
                        let contentData = { type: SDIConstants.methodNames.getPODetails, response: false };
                        Dispatcher.dispatch(contentData);
                        
                    }
                }
                else if (data.success == false) {

                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}


const SubmitReceiving = async (SubmitReceivingPageBO) => {
    try {                
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Receiving,
            SDIConstants.methodNames.submitReceiving,
            JSON.stringify(SubmitReceivingPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = {
                            type: SDIConstants.methodNames.submitReceiving, response: data.response
                        };
                        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                        Dispatcher.dispatch(contentData);

                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const tokenExpired = async () => {
    try {
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)

    }
};
const getPassThroughAutheURl = (getPassThroughAutheURlBO) => {
    try {
        //debugger
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.PassThroughAuthe,
            SDIConstants.methodNames.PassThroughAuthe_PassAutheInitialize,
            JSON.stringify(getPassThroughAutheURlBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PassThroughAuthe", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {

                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};


export {
    getPassThroughAutheURl, GetMMReceivingPageLoad, SubmitReceiving, MMReceivingPODetail, GetData, getCurrentFavPage, insertFavPage, deleteFavPage
}