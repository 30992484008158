import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
import * as MenuAction from './MenuAction';
const FavItemPageLoad = (favItemBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.FavItemMaintenance_PageLoad,
            JSON.stringify(favItemBO),

            (data) => {
                if (data.success) {
                    debugger;
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavItemMaintenance_PageLoad, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}



const NewFavItem = (favItemBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.FavItemMaintenance_txtid_TextChanged,
            JSON.stringify(favItemBO),

            (data) => {
                if (data.success) {
                    debugger;
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavItemMaintenance_txtid_TextChanged, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const InsertFav = (InsertFavItemBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.FavItemMaintenance_InsertFavItem,
            JSON.stringify(InsertFavItemBO),

            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavItemMaintenance_InsertFavItem, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const DeletFavItem = (deleteFavBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.FavItemMaintenance_DeleteFavItem,
            JSON.stringify(deleteFavBO),

            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavItemMaintenance_DeleteFavItem, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const UpdateFavItem = (deleteFavBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.FavItemMaintenance_UpdateFavItem,
            JSON.stringify(deleteFavBO),

            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavItemMaintenance_UpdateFavItem, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}


const AddToCartFavItem = (AddtoCartBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.Menu_AddFavItemtoCart,
            JSON.stringify(AddtoCartBO),

            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Menu_AddFavItemtoCart, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVITEM_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVITEM_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVITEM_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
 //[7/10/2023, PS_ZE_142_APP_13, Dhevanesam R]
const ShareItemButtonClickAction = async (favItemShareBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.ShareFavItem,
            JSON.stringify(favItemShareBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.ShareFavItem, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

export { FavItemPageLoad, NewFavItem, InsertFav, DeletFavItem, UpdateFavItem, AddToCartFavItem, deleteFavPage, insertFavPage, getCurrentFavPage, ShareItemButtonClickAction}