import React, { Component } from 'react';
import * as $ from 'jquery';
import { saveAs } from 'file-saver';
import * as CookieService from '../CookieService';
import * as PDFPreviewAction from '../actions/PDFPreviewAction';
import PDFPreviewStore from '../stores/PDFPreviewStore';
import SDIConstants from '../SDIConstants';
//[PC_07, 09/09/21, Author - Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
let OrderSuccessCOunt = 0
class PDFPreviewComponent extends Component {
    constructor(props) {
        super(props);
        this.BuildPDF = this.BuildPDF.bind(this);
        this.state = {
            Base64UrlState : this.props.base64URL,
            Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
        }
    }
    componentDidMount() {
        if (this.props.PricedOrder == "" || this.props.PricedOrder == null || this.props.Nonstock == "" || this.props.Nonstock == null || this.props.PdfPage != "1") {
            $('#Quote').addClass('d-none')
            $('#Processing').addClass('d-none')
        }
        else {
            $('#Processing').removeClass('d-none')
            $('#Quote').removeClass('d-none')
        }
        PDFPreviewStore.on("OrderPDF", this.BuildPDF);
    }
    componentWillUnmount() {
        PDFPreviewStore.removeListener("OrderPDF", this.BuildPDF);
    }
    CLosepopup = () => {
        if (this.props.PdfPage == "1") {
            this.props.history.push({
                pathname: `\HomePage`,
            });
        }
        //zEUS 11 STOCK ISSUES CART[To stay in stock issues cart page on clicking close button in cart confirm pop up][Vishalini 11/26/2023]
        else if (this.props.PdfPage == "2") {
            this.props.history.push({
                pathname: `\STKIssuesCart`,
            });
        }
    }
    OpenPDF = (e, OrderType) => {
        debugger
        e.preventDefault();
        if (OrderType == 1) {
            let cartFileBo = {
                Session_UserID: this.state.Login_UserId,
                Session_BUSUNIT: this.state.Login_UserBU,
                OrderNO: this.props.PricedOrder,//"NY00446489",
                fileName: ""
            }
            PDFPreviewAction.GetOrderPdf(cartFileBo)
            console.log("OrderNum", this.props.PricedOrder);
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
        else {
            let cartFileBo = {
                Session_UserID: this.state.Login_UserId,
                Session_BUSUNIT: this.state.Login_UserBU,
                OrderNO: this.props.Nonstock,//"NY00446489",
                fileName: ""
            }
            PDFPreviewAction.GetOrderPdf(cartFileBo)
            console.log("OrderNum", this.props.Nonstock);
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
    }
    BuildPDF = () => {
        OrderSuccessCOunt = OrderSuccessCOunt + 1
        let OrderPdf = PDFPreviewStore.OrderPDFMsge;
        this.setState({ Base64UrlState: OrderPdf })
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    downloadPDF = (e) => {
        e.preventDefault();
        let sliceSize = 512
        const byteCharacters = atob(this.state.Base64UrlState.split(',')[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: "application/pdf" });
        saveAs(blob, "SDIOrderStatusReport.pdf");
    }
    render() {
        debugger
        console.log("PDF", this.props.base64URL)
        return (
            <div className="modal-dialog modal-xl custom-popup modal-dialog-centered">
                <div className="modal-content">
                    {/* Modal Header */}
                    <div className="modal-header">
                        <h4 className="modal-title mt-1">{this.props.pdfTitle}</h4>
                        <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.CLosepopup()}>
                            X
                     </button>
                    </div>
                    {/* Modal body */}
                    <div className="modal-body">
                        <div className="col-md-12 float-left p-0" id="pdfBody">
                            {/*  <a href={this.props.base64URL} className="float-right" title="Download" download="SDIOrderStatusReport.pdf">Download</a> */}
                            <button className="float-left pl-2 pr-2 d-none PDF-btn" title="Processing" id="Processing" onClick={(e) => this.OpenPDF(e, 1)}><Trans>Order Submitted for Processing</Trans></button>
                            <button className="float-left pl-2 pr-2 d-none PDF-btn" title="Quote" id="Quote" onClick={(e) => this.OpenPDF(e, 2)}><Trans>Order Submitted for Quote</Trans></button>
                            <a href="#" className="float-right" title="Download" onClick={(e)=> this.downloadPDF(e)}><Trans>Download</Trans></a>
                            <object className="pdf-preview custom-scroll" data={this.state.Base64UrlState} type="application/pdf" ></object>
                        </div>
                    </div>
                </div>
            </div>);
    }
}
//[PC_07, 09/09/21, Author -  Priyanka D]
export default withTranslation()(PDFPreviewComponent);

