import React, { Component } from 'react';
import * as CookieService from '../CookieService';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
import { withTranslation, Trans } from 'react-i18next';
import PDFPreviewComponent from './PDFPreviewComponent';
import Autocomplete from 'react-autocomplete';
import Select from 'react-select';
import * as STKIssuesCartAction from '../actions/STKIssuesCartAction';
import STKIssuesCartStore from '../stores/STKIssuesCartStore';
import Image from 'react-image-resizer';
import * as MenuAction from '../actions/MenuAction';
import { breakpoints } from '@material-ui/system';
import swal from 'sweetalert';


let isFromLink = false;
let currentPagePath;
let tempEMPEmail = "";
let OrderSuccessCOunt = 0

class STKIssuesCartComponent extends Component {
    constructor(props) {
        super(props);
        this.STKIssueCartPageload = this.STKIssueCartPageload.bind(this);
        this.invItemSearch = this.invItemSearch.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.handleDDChange = this.handleDDChange.bind(this);
        this.state = {
            cartArr: [],
            MachineTable: [],
            ShowMachineNumber: true,
            ValidatedWo: "",
            woStyle: "",
            EmployeeRequired: false,
            WORequired: false,
            CCRequired: false,
            WOorCCRequired: false,
            AssetId: "",
            disableWo: false,
            Login_UserBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            OrderEmpyId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            EmplyeeNameDD: [],
            SelectedEmplabel: "Select",
            SelectedEmp: { value: "", label: "Select" },
            SelectedAssetID: { value: "", label: "Select" },
            NewItemID: "",
            StatusErrVal: "", ErrorMsgVal: "",
            NewItemDetail: [],
            RemoveIndex: "",
            ERR: false,
            ValidWO: false, base64URL: "",
            SubmitButtonClick: false,
            ErrStatement: "",
            CUST_NOTES: "",
            ItemCheck: false,
            isFavorited: false,
            ItemIdCheck: false,
            InvalidItemId: false,
            EmployeeErr: false,
            MachineTable: [],
            ChargeCodeTitle: "Order charge code",
            WorkOrderTitle: "Work order#",
            OLEmplyIdLabel: "Employee ID",
            OlChargeCOde: "",
            ChargeCodeMultiple: false,
            OrdLevelChrgCode: false,
            isEMPDDChange: false,
            BackOrderCheck: false,
            BackOrderEnabled: false,
            EmpEmail: "",
            CCPlaceDD1Title: "",
            UpdatedChargeSelected: "", UpdatedOlChargeCOde: "",
            UpdatedPlaceDD1Index: 0, UpdatedPlaceDD2Index: 0, UpdatedPlaceDD3Index: 0, UpdateddropCD1SegIndex: 0, UpdateddropCD2SegIndex: 0, UpdateddropCD3SegIndex: 0, UpdatedPlaceDD1Value: [],
            UpdatedPlaceDD2Value: [], UpdatedPlaceDD3Value: [], UpdateddropCD1SegValue: [], UpdateddropCD2SegValue: [],
            UpdateddropCD3SegValue: [], UpdateddropEmpSegValue: [], UpdateddropItmSegValue: [], UpdateddropMchSegValue: [],
            UpdateddropCD1SegData: [], UpdateddropCD2SegData: [], UpdateddropCD3SegData: [], UpdateddropEmpSegData: [], UpdateddropItmSegData: [], UpdateddropMchSegData: [],
            UpdatedPlaceDD1Data: [], UpdatedPlaceDD2Data: [], UpdatedPlaceDD3Data: [],
            PlaceDD1Index: 0, PlaceDD2Index: 0, PlaceDD3Index: 0, dropCD1SegIndex: 0, dropCD2SegIndex: 0, dropCD3SegIndex: 0,
            CCPlaceDD2Title: "", CCPlaceDD3Title: "", CCdropCD1SegTitle: "", CCdropCD2SegTitle: "", CCdropCD3SegTitle: "", CCdropEmpSegTitle: "", CCdropItmSegTitle: "",
            CCdropMchSegTitle: "", PlaceDD1Value: [], PlaceDD1Data: [], PlaceDD2Value: [], PlaceDD2Data: [], PlaceDD3Value: [], PlaceDD3Data: [],
            dropCD1SegValue: [], dropCD1SegData: [], dropCD2SegValue: [], dropCD2SegData: [], dropCD3SegValue: [], dropCD3SegData: [], dropEmpSegValue: [], dropEmpSegData: [],
            dropItmSegValue: [], dropItmSegData: [], dropMchSegValue: [], dropMchSegData: [], dropCD1SegVisible: false, dropCD2SegVisible: false, dropCD3SegVisible: false,
            PlaceDD1Visible: false, PlaceDD2Visible: false, PlaceDD3Visible: false, dropMchSegVisible: false, dropEmpSegVisible: false, dropItmSegVisible: false, ChargeSelected: "",
            ChargeCodeLevel: 0, PlaceDD1_disable: false, PlaceDD2_disable: false, PlaceDD3_disable: false, dropCD1Seg_disable: false, dropCD2Seg_disable: false, dropCD3Seg_disable: false,
            dropEmpSeg_disable: false, dropItmSeg_disable: false, dropMchSeg_disable: false,
            default: { label: "Select", value: "" }, Errormsg: "", ContentClassName: isFromLink ? "container-fluid" : "container-fluid content-section", delay: 100,
            result: 'No result', results: [], facingMode: "environment", RemoveItemPUp: false,
            suggestionWo: [],

        }
    }
    //PS_ZE_11_API_09 [Zeus 11 stock issues cart] First hits when Page gets loaded[Vishalini] - 11/26/2023
    componentDidMount() {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (this.state.Login_UserBU == 'I0635' || this.state.Login_UserBU == 'I0631') {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Access denied"
            });
            this.props.history.push({
                pathname: '/homepage',
                state: { origin: 'Inventory' }
            });

            return;
        }
        if (CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT") == null || CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT") == undefined ||
            CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT") == "" || CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT") == " ") {
            //let cribID = CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT");
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please select crib from user profile"
            });
            this.props.history.push({
                pathname: '/profile',
                state: { origin: 'Inventory' }
            });

            return;
        }

        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        if (!isFromLink) {
            STKIssuesCartAction.getCurrentFavPage(getFavPageBO);
        }
        if (isFromLink) {
            //localStorage.setItem("isFromLink", isFromLink);
            //CookieService.updateLocalStorageValues("Session_UserID", mob_Session_USERID)
            //CookieService.setCookieValues(SDIConstants.access_token, Token)
            //this.ValidateToken();
        } else {
            this.STKIssueCartPageload()
        }
        STKIssuesCartStore.on('STKIssuesCartPageLoad', this.STKIssuesCartPageLoadResponse);
        //STKIssuesCartStore.on('ValidateToken', this.ValidateResponse);       
        STKIssuesCartStore.on('InvItemSearch', this.invItemSearchResponse);
        STKIssuesCartStore.on('STKWOResponse', this.workOrderResponse);
        //STKIssuesCartStore.on('WOFail', this.WOFail);
        STKIssuesCartStore.on('SubmitSTK', this.submitResponse);
        STKIssuesCartStore.on("OrderPDF", this.cartConfirmPDFResponse.bind(this));
        STKIssuesCartStore.on('GetchargeCOdeBO', this.chargeCodeResponse);
        STKIssuesCartStore.on('favpage', this.loadFavPageResponse);
        STKIssuesCartStore.on('updateFavPage', this.updateFavPageResponse);
        //Zeus 335-Niranjana-6/20/24-To check any values is present in the  local storage ,If present assign the value to cartArr
        let cart = this.state.cartArr.length > 0 ? this.state.cartArr : CookieService.getLocalStorageValues("STKCart") != null && CookieService.getLocalStorageValues("STKCart") != undefined ? CookieService.getLocalStorageValues("STKCart") : [];
        this.setState({ cartArr: cart });
        if (isFromLink) {
            document.getElementById("ContentDiv").style.marginTop = "44px"
        } else {
            document.getElementById("ContentDiv").style.marginTop = "130px";
            document.getElementById("ContentDiv").style.paddingBottom = "60px";
        }
    }

    //PS_ZE_11_API_38 [Zeus 11 stock issues cart] To remove the listeners[Vishalini] - 11/26/2023
    componentWillUnmount() {
        STKIssuesCartStore.removeListener('STKIssuesCartPageLoad', this.STKIssuesCartPageLoadResponse);
        //STKIssuesCartStore.removeListener('ValidateToken', this.ValidateResponse);
        STKIssuesCartStore.removeListener('InvItemSearch', this.invItemSearchResponse);
        STKIssuesCartStore.removeListener('STKWOResponse', this.workOrderResponse);
        //STKIssuesCartStore.removeListener('WOFail', this.WOFail);
        STKIssuesCartStore.removeListener('SubmitSTK', this.submitResponse);
        STKIssuesCartStore.removeListener("OrderPDF", this.cartConfirmPDFResponse.bind(this));
        STKIssuesCartStore.removeListener('GetchargeCOdeBO', this.chargeCodeResponse);
        STKIssuesCartStore.removeListener('favpage', this.loadFavPageResponse);
        STKIssuesCartStore.removeListener('updateFavPage', this.updateFavPageResponse);
    }

    //PS_ZE_11_API_09 [Zeus 11 stock issues cart]Invoke action pageload method to bring page load data[Vishalini] - 11/26/2023
    STKIssueCartPageload() {
        let BO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_ACTIVEUSERS: CookieService.getLocalStorageValues("Session_ACTIVEUSERS")
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        STKIssuesCartAction.GetSTKIssueCartPageload(BO)
    }

    //PS_ZE_11_API_12 [Zeus 11 stock issues cart]Bind the page load response from backend to frontend[Vishalini] - 11/26/2023
    STKIssuesCartPageLoadResponse = () => {
        let PageLoadResponse = JSON.parse(STKIssuesCartStore.PageLoadData);
        //console.log("Disablewo", this.state.disableWo)
        //console.log("Disablewo1", CookieService.getLocalStorageValues("workOrderDisable"))
        let EmpId = PageLoadResponse.EmpData;
        let MultiCHargeCode = false;
        let LocEmplyeeNameDD = []
        let LocAssetIDDD = []
        let OLEmplyIdLabelLocal = "Employee ID";
        let locEmployeeRequired = false;
        let locWORequired = false;
        let locCCRequired = false;
        let locWOorCCRequired = false;
        let machinenumdata = [];
        let MAchineNumber = true;
        let assetID = "";

        console.log("PageLoadResponse", PageLoadResponse)
        if (PageLoadResponse.hasOwnProperty('Exception')) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Something went wrong please try again"
            }).then(() => {
                window.location.href = '/';
            });
        }
        else {
            if (EmpId && EmpId.length > 0) {
                EmpId.forEach((value, index) => {
                    let Emp = {
                        value: value.EMP_ID,
                        label: value.ISA_EMPLOYEE_NAME
                    }
                    LocEmplyeeNameDD.push(Emp)
                })
            }
            //if (PageLoadResponse && PageLoadResponse.ChargeCodeData) {
            //    if (PageLoadResponse.ChargeCodeData.length > 0) {
            //        MultiCHargeCode = true;
            //    }
            //}
            if (PageLoadResponse && PageLoadResponse.ChargeCodeData) {
                if (PageLoadResponse.ChargeCodeData.length > 0) {
                    MultiCHargeCode = false;

                    // Assuming PageLoadResponse.ChargeCodeData is an array of objects
                    for (let i = 0; i < PageLoadResponse.ChargeCodeData.length; i++) {
                        const rowData = PageLoadResponse.ChargeCodeData[i];

                        // Check the specified columns for empty or whitespace-only values
                        if (

                            rowData.ISA_CHG_CD_SEG_1.trim() !== "" ||
                            rowData.ISA_CHG_CD_SEG_2.trim() !== "" ||
                            rowData.ISA_CHG_CD_SEG_3.trim() !== "" ||
                            rowData.ISA_CHG_CD_SEG_4.trim() !== "" ||
                            rowData.ISA_CHG_CD_SEG_5.trim() !== "" ||
                            rowData.ISA_CHG_CD_SEP.trim() !== ""
                        ) {
                            MultiCHargeCode = true;
                            // can break out of the loop if any column is found to be empty or whitespace-only
                            break;
                        }
                    }
                }
            }

            let WorkOrderTitle = this.state.WorkOrderTitle;
            let ChargeCodeTitle = this.state.ChargeCodeTitle;

            // Employee ID required condition           
            if (CookieService.getLocalStorageValues("Session_SDIEMP") == "SDI" && CookieService.getLocalStorageValues("Session_AGENTUSERID") == "") {
                OLEmplyIdLabelLocal = "*Employee ID (by name)";
                locEmployeeRequired = true;

            }
            //Work Order or charge code reruired
            if (CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") != null && CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTWO_CHGCDREQ").toUpperCase() == "Y") {
                    WorkOrderTitle = "Work order#";
                    ChargeCodeTitle = "Order charge code";
                    locWOorCCRequired = true;
                }
            }
            //else {
            // if (CookieService.getLocalStorageValues("Session_CHARGECODE") == "Y") {
            if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != null && CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != "" && CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") != " ") {
                    if (CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ") == 1
                        || CookieService.getLocalStorageValues("Session_CARTCHGCDREQ").toUpperCase() == "Y") {
                        ChargeCodeTitle = "*Order charge code";
                        locCCRequired = true;
                    }
                }
            }
            // }

            // if (CookieService.getLocalStorageValues("Session_WORKORDER") == "Y") {
            if (CookieService.getLocalStorageValues("Session_CARTWOREQ") != null && CookieService.getLocalStorageValues("Session_CARTWOREQ") != undefined) {
                if (CookieService.getLocalStorageValues("Session_CARTWOREQ") == "1" || CookieService.getLocalStorageValues("Session_CARTWOREQ").toUpperCase() == "Y") {
                    WorkOrderTitle = "*Work Order#";
                    locWORequired = true;
                }
            }
            // }
            // }


            if (PageLoadResponse && PageLoadResponse.MachineNum) {
                console.log('PageLoadResponse.MachineNum:', PageLoadResponse.MachineNum);
                if (PageLoadResponse.MachineNum != null && PageLoadResponse.MachineNum != undefined) {
                    if (PageLoadResponse.MachineNum.length > 0) {
                        if (PageLoadResponse.MachineNum.length > 1000) {
                            PageLoadResponse.MachineNum.length = 1000;
                        }
                        assetID = PageLoadResponse.MachineNum;

                        assetID.forEach((value, index) => {
                            let newAssetID = {
                                value: value.ASSET_ID,
                                label: value.ASSET_ID
                            }
                            LocAssetIDDD.push(newAssetID)
                        })
                    }
                    else {
                        MAchineNumber = false
                    }
                }
                else {
                    MAchineNumber = false
                }
            }
            else {
                MAchineNumber = false
            }

            console.log("PageLoadResponse", LocEmplyeeNameDD)
            this.setState({
                EmplyeeNameDD: LocEmplyeeNameDD, EmployeeRequired: locEmployeeRequired, CCRequired: locCCRequired, WORequired: locWORequired, WOorCCRequired: locWOorCCRequired, OLEmplyIdLabel: OLEmplyIdLabelLocal, ChargeCodeTitle: ChargeCodeTitle,
                WorkOrderTitle: WorkOrderTitle, ChargeCodeMultiple: MultiCHargeCode, MachineTable: LocAssetIDDD, ShowMachineNumber: MAchineNumber
            });
        }
    }

    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(STKIssuesCartStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        if (STKIssuesCartStore.updateFavPageResponse.updateType == 1) {

            let response = JSON.parse(STKIssuesCartStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }

        } else {
            let response = JSON.parse(STKIssuesCartStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
        // isPostBack = false;
        //this.refreshMenu();
        MenuAction.refreshMasterData()
    };
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Stock Issue",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            STKIssuesCartAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            STKIssuesCartAction.deleteFavPage(deleteFavPageBO)
        }
    };

    //PS_ZE_11_API_39 [Zeus 11 stock issues cart]Onclick of cc button get invoked[Vishalini] - 11/26/2023
    getChargeCode = (Type, ID, Ordlvl) => {
        let CCArray = [];
        let DD1;
        let DD2;
        let DD3;
        if (ID != null && ID != "" && ID != " " && ID != undefined) {
            //ID.includes("--") ? CCArray = ID.split("--") : CCArray = ID.split("-");
            //var DD1 = CCArray[0];
            //var DD2 = CCArray[1];
            //var DD3 = CCArray[2];
            //Assigning index

            // Assigning index
            DD1 = this.state.PlaceDD1Index !== 0 && this.state.PlaceDD1Data[this.state.PlaceDD1Index] ? this.state.PlaceDD1Data[this.state.PlaceDD1Index].value : this.state.dropCD1SegIndex !== 0 && this.state.dropCD1SegData[this.state.dropCD1SegIndex] ? this.state.dropCD1SegData[this.state.dropCD1SegIndex].value : null;
            DD2 = this.state.PlaceDD2Index !== 0 && this.state.PlaceDD2Data[this.state.PlaceDD2Index] ? this.state.PlaceDD2Data[this.state.PlaceDD2Index].value : this.state.dropCD2SegIndex !== 0 && this.state.dropCD2SegData[this.state.dropCD2SegIndex] ? this.state.dropCD2SegData[this.state.dropCD2SegIndex].value : null;
            DD3 = this.state.PlaceDD3Index !== 0 && this.state.PlaceDD3Data[this.state.PlaceDD3Index] ? this.state.PlaceDD3Data[this.state.PlaceDD3Index].value : this.state.dropCD3SegIndex !== 0 && this.state.dropCD3SegData[this.state.dropCD3SegIndex] ?
                this.state.dropCD3SegData[this.state.dropCD3SegIndex].value : null;

        }

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        //this.setState({ ChargeCodeLevel: Type })        

        if (ID != null && ID != "" && ID != " " && ID != undefined) {
            this.setState({ PlaceDD1Value: DD1, PlaceDD2Value: DD2, PlaceDD3Value: DD3, ChargeSelected: ID, dropCD1SegValue: DD1, dropCD2SegValue: DD2, dropCD3SegValue: DD3 });
        }

        if (Ordlvl == "Ordlvl") {
            this.setState({ OrdLevelChrgCode: true });
        }

        let BuildChrCDBO = {
            UserId: this.state.Login_UserId,
            UserBU: this.state.Login_UserBU,
            dropCD1SegValue: this.state.dropCD1SegValue.value,
            dropCD2SegValue: this.state.dropCD2SegValue.value,
            dropCD3SegValue: this.state.dropCD3SegIndex !== 0 && this.state.dropCD3SegData[this.state.dropCD3SegIndex] ? this.state.dropCD3SegData[this.state.dropCD3SegIndex].value : "",
            dropCD1SegFilled: this.state.dropCD1SegIndex !== 0 ? true : false,
            dropCD2SegFilled: this.state.dropCD1SegIndex !== 0 ? true : false,
            dropCD1Seg_Visible: this.state.dropCD1SegVisible,
            dropCD2Seg_Visible: this.state.dropCD2SegVisible,
            dropCD3Seg_Visible: this.state.dropCD3SegVisible,
        }
        STKIssuesCartAction.GetchargeCOde(BuildChrCDBO)
    }

    //PS_ZE_11_API_42 [Zeus 11 stock issues cart]Bind the response from backend for charge code[Vishalini] - 11/26/2023
    chargeCodeResponse = () => {
        debugger
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let GetchargeCOdeBO = JSON.parse(STKIssuesCartStore.GetchargeCOdeBO);

        let ChargeCOdeTable = GetchargeCOdeBO.ChargeCOdeTable
        let ChargeCOdeType = GetchargeCOdeBO.ChargeCOdeType
        let CCArray = [];


        let ChargeSelected = "";
        //var DD1 = [];
        //var DD2 = [];
        //var DD3 = [];
        let DD1;
        let DD2;
        let DD3;
        let PlaceDD1Val = "";
        let PlaceDD2Val = "";
        let PlaceDD3Val = "";
        let PlaceDDdefault = ""
        if (this.state.ChargeSelected != null && this.state.ChargeSelected != "" && this.state.ChargeSelected != " " && ChargeSelected != undefined) {
            //this.state.ChargeSelected.includes("--") ? CCArray = this.state.ChargeSelected.split("--") : CCArray = this.state.ChargeSelected.split("-");
            //var DD1 = CCArray[0];
            //var DD2 = CCArray[1];
            //var DD3 = CCArray[2];
            //Assigning index
            // Assigning index
            DD1 = this.state.PlaceDD1Index !== 0 && this.state.PlaceDD1Data[this.state.PlaceDD1Index] ? this.state.PlaceDD1Data[this.state.PlaceDD1Index].value : this.state.dropCD1SegIndex !== 0 && this.state.dropCD1SegData[this.state.dropCD1SegIndex] ? this.state.dropCD1SegData[this.state.dropCD1SegIndex].value : null;
            DD2 = this.state.PlaceDD2Index !== 0 && this.state.PlaceDD2Data[this.state.PlaceDD2Index] ? this.state.PlaceDD2Data[this.state.PlaceDD2Index].value : this.state.dropCD2SegIndex !== 0 && this.state.dropCD2SegData[this.state.dropCD2SegIndex] ? this.state.dropCD2SegData[this.state.dropCD2SegIndex].value : null;
            DD3 = this.state.PlaceDD3Index !== 0 && this.state.PlaceDD3Data[this.state.PlaceDD3Index] ? this.state.PlaceDD3Data[this.state.PlaceDD3Index].value : this.state.dropCD3SegIndex !== 0 && this.state.dropCD3SegData[this.state.dropCD3SegIndex] ? this.state.dropCD3SegData[this.state.dropCD3SegIndex].value : null;


        }
        //added condition
        if (ChargeCOdeTable[0].PlaceDD1_Visible == "True" || ChargeCOdeTable[0].PlaceDD2_Visible == "True" || ChargeCOdeTable[0].PlaceDD3_Visible == "True") {
            if (ChargeCOdeTable[0].PlaceDD1Value != null && ChargeCOdeTable[0].PlaceDD1Value != " " && ChargeCOdeTable[0].PlaceDD1Value != "" && ChargeCOdeTable[0].PlaceDD1Value != undefined) {
                PlaceDD1Val = ChargeCOdeTable[0].PlaceDD1Value

            } else if ((DD1 != 0 && DD1 != null && DD1 != "" && DD1 != " " && DD1 != undefined) && (this.state.OrdLevelChrgCode != true || (this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "" && this.state.OlChargeCOde != " " && this.state.OlChargeCOde != null))) {
                PlaceDD1Val = DD1;
            }
            if (ChargeCOdeTable[0].PlaceDD2Value != null && ChargeCOdeTable[0].PlaceDD2Value != " " && ChargeCOdeTable[0].PlaceDD2Value != "" && ChargeCOdeTable[0].PlaceDD2Value != undefined) {
                PlaceDD2Val = ChargeCOdeTable[0].PlaceDD2Value
            } else if (DD2 != 0 && DD2 != null && DD2 != "" && DD2 != " " && DD2 != undefined && (this.state.OrdLevelChrgCode != true || (this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "" && this.state.OlChargeCOde != " " && this.state.OlChargeCOde != null))) {
                PlaceDD2Val = DD2;
            }
            if (ChargeCOdeTable[0].PlaceDD3Value != null && ChargeCOdeTable[0].PlaceDD3Value != " " && ChargeCOdeTable[0].PlaceDD3Value != "" && ChargeCOdeTable[0].PlaceDD3Value != undefined) {
                PlaceDD3Val = ChargeCOdeTable[0].PlaceDD3Value
            } else if (DD3 != 0 && DD3 != null && DD3 != "" && DD3 != " " && DD3 != undefined && (this.state.OrdLevelChrgCode != true || (this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "" && this.state.OlChargeCOde != " " && this.state.OlChargeCOde != null))) {
                PlaceDD3Val = DD3;
            }
        }

        let PlaceDD1 = this.buildCCDropdown(GetchargeCOdeBO.PlaceDD1, PlaceDDdefault)

        let PlaceDD1Data = PlaceDD1.ReturnTable;
        PlaceDD1Data = PlaceDD1Data.filter((item, index) => item.value == PlaceDD1Val);
        let PlaceDD2 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.PlaceDD2, ChargeCOdeTable[0].PlaceDD2Value) : ""
        let PlaceDD3 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.PlaceDD3, ChargeCOdeTable[0].PlaceDD3Value) : ""
        let dropCD1Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.dropCD1Seg, ChargeCOdeTable[0].dropCD1SegValue) : ""
        let dropCD2Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.dropCD2Seg, ChargeCOdeTable[0].dropCD2SegValue) : ""
        let dropCD3Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.dropCD3Seg, ChargeCOdeTable[0].dropCD3SegValue) : ""
        let dropEmpSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.dropEmpSeg, ChargeCOdeTable[0].dropEmpSegValue) : ""
        let dropItmSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.dropItmSeg, ChargeCOdeTable[0].dropItmSegValue) : ""
        let dropMchSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildCCDropdown(GetchargeCOdeBO.dropMchSeg, ChargeCOdeTable[0].dropMchSegValue) : ""

        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD1Val, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD2Val, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD3Val, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD1SegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD2SegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD3SegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropEmpSegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropItmSegValue, ChargeSelected) : ""
        ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropMchSegValue, ChargeSelected) : ""

        let PlaceDD2Data = PlaceDD2.ReturnTable;
        PlaceDD2Data = PlaceDD2Data.filter((item, index) => item.value == PlaceDD2Val);
        let PlaceDD3Data = PlaceDD3.ReturnTable;
        PlaceDD3Data = PlaceDD3Data.filter((item, index) => item.value == PlaceDD3Val);

        let dropCD1SegValue = dropCD1Seg.ReturnTable;
        dropCD1SegValue = dropCD1SegValue.filter((item, index) => item.value == DD1);
        let dropCD2SegValue = dropCD2Seg.ReturnTable;
        dropCD2SegValue = dropCD2SegValue.filter((item, index) => item.value == DD2);
        let dropCD3SegValue = dropCD3Seg.ReturnTable;
        dropCD3SegValue = dropCD3SegValue.filter((item, index) => item.value == DD3);

        if (ChargeCOdeTable[0].ShowAllFieldFillLabel == "True") {
            $('#ChargeCodeLabelDiv').removeClass('d-none')
            $('#ChargeCodeLabel').removeClass('errorcolour')
        }
        else {
            $('#ChargeCodeLabelDiv').addClass('d-none')
        }



        this.setState({

            OrdLevelChrgCode: false,
            PlaceDD1Value: PlaceDD1Data, PlaceDD1Data: PlaceDD1.ReturnTable, ChargeSelected: ChargeSelected,
            PlaceDD2Value: PlaceDD2Data, PlaceDD2Data: PlaceDD2.ReturnTable,
            PlaceDD3Value: PlaceDD3Data, PlaceDD3Data: PlaceDD3.ReturnTable,
            dropCD1SegValue: dropCD1Seg.SelectedValue !== null && dropCD1Seg.SelectedValue !== undefined ? dropCD1Seg.SelectedValue : dropCD1SegValue, dropCD1SegData: dropCD1Seg.ReturnTable,
            dropCD2SegValue: dropCD2Seg.SelectedValue !== null && dropCD2Seg.SelectedValue !== undefined ? dropCD2Seg.SelectedValue : dropCD2SegValue, dropCD2SegData: dropCD2Seg.ReturnTable,
            dropCD3SegValue: dropCD3Seg.SelectedValue !== null && dropCD3Seg.SelectedValue !== undefined ? dropCD3Seg.SelectedValue : dropCD3SegValue, dropCD3SegData: dropCD3Seg.ReturnTable,
            dropEmpSegValue: dropEmpSeg.SelectedValue, dropEmpSegData: dropEmpSeg.ReturnTable,
            dropItmSegValue: dropItmSeg.SelectedValue, dropItmSegData: dropItmSeg.ReturnTable,
            dropMchSegValue: dropMchSeg.SelectedValue, dropMchSegData: dropMchSeg.ReturnTable,
            CCPlaceDD1Title: ChargeCOdeTable[0].PlaceDD1, CCPlaceDD2Title: ChargeCOdeTable[0].PlaceDD2, CCPlaceDD3Title: ChargeCOdeTable[0].PlaceDD3,
            CCdropCD1SegTitle: ChargeCOdeTable[0].dropCD1Seg, CCdropCD2SegTitle: ChargeCOdeTable[0].dropCD2Seg, CCdropCD3SegTitle: ChargeCOdeTable[0].dropCD3Seg,
            CCdropEmpSegTitle: ChargeCOdeTable[0].dropEmpSeg, CCdropItmSegTitle: ChargeCOdeTable[0].dropItmSeg, CCdropMchSegTitle: ChargeCOdeTable[0].dropMchSeg,
            dropCD1SegVisible: ChargeCOdeTable[0].dropCD1Seg_Visible == "True" ? true : false,
            dropCD2SegVisible: ChargeCOdeTable[0].dropCD2Seg_Visible == "True" ? true : false,
            dropCD3SegVisible: ChargeCOdeTable[0].dropCD3Seg_Visible == "True" ? true : false,
            PlaceDD1Visible: ChargeCOdeTable[0].PlaceDD1_Visible == "True" ? true : false,
            PlaceDD2Visible: ChargeCOdeTable[0].PlaceDD2_Visible == "True" ? true : false,
            PlaceDD3Visible: ChargeCOdeTable[0].PlaceDD3_Visible == "True" ? true : false,
            dropEmpSegVisible: ChargeCOdeTable[0].dropEmpSeg_Visible == "True" ? true : false,
            dropItmSegVisible: ChargeCOdeTable[0].dropItmSeg_Visible == "True" ? true : false,
            dropMchSegVisible: ChargeCOdeTable[0].dropMchSeg_Visible == "True" ? true : false, dropCD1Seg_disable: ChargeCOdeTable[0].dropCD1Seg_Enable == "False" ? true : false,
            dropCD2Seg_disable: ChargeCOdeTable[0].dropCD2Seg_Enable == "False" ? true : false, dropCD3Seg_disable: ChargeCOdeTable[0].dropCD3Seg_Enable == "False" ? true : false,
            PlaceDD1_disable: ChargeCOdeTable[0].PlaceDD1_Enable == "False" ? true : false, PlaceDD2_disable: ChargeCOdeTable[0].PlaceDD2_Enable == "False" ? true : false,
            PlaceDD3_disable: ChargeCOdeTable[0].PlaceDD3_Enable == "False" ? true : false, dropEmpSeg_disable: ChargeCOdeTable[0].dropEmpSeg_Enable == "False" ? true : false,
            dropItmSeg_disable: ChargeCOdeTable[0].dropItmSeg_Enable == "False" ? true : false, dropMchSeg_disable: ChargeCOdeTable[0].dropMchSeg_Enable == "False" ? true : false,
        })
        $('#Charge-Code-popup').modal('show');

    }

    //PS_ZE_11_API_43.1 [Zeus 11 stock issues cart]Concatinates with each dropdown value[Vishalini] - 11/26/2023
    buildChargecodevalue = (CCValue, ChargeSelect) => {
        let ChargeSelected = ChargeSelect
        if (CCValue != null && CCValue != "" && CCValue != undefined) {
            if (ChargeSelected != "" && ChargeSelected != null && ChargeSelected != undefined) {
                ChargeSelected = ChargeSelected + "-" + CCValue
            }
            else {
                ChargeSelected = CCValue
            }
        }
        if (ChargeSelected == "-") {
            ChargeSelected = "";
        }
        return (ChargeSelected)
    }

    //PS_ZE_11_API_43 [Zeus 11 stock issues cart]Build the cc dropdown[Vishalini] - 11/26/2023
    buildCCDropdown = (DropDownValue, DefaultValue) => {
        const { t } = this.props;
        let ReturnTable = []
        let SelectedValue = []
        if (DropDownValue != null && DropDownValue != undefined) {
            if (DropDownValue.length > 0) {
                let defaulttype = "ISA_CHGCD_USR1_SEG"
                DropDownValue.map((val, index) => {

                    if (index == 0) {
                        if (val.ISA_CHGCD_USR2_SEG != undefined) {
                            defaulttype = "ISA_CHGCD_USR2_SEG"
                        }
                        else if (val.ISA_CHGCD_USR3_SEG != undefined) {
                            defaulttype = "ISA_CHGCD_USR3_SEG"
                        }
                        else if (val.ISA_CUST_CHARGE_CD != undefined) {
                            defaulttype = "ISA_CUST_CHARGE_CD"
                        }
                        else if (val.ISA_CHGCD_ITEM_SEG != undefined) {
                            defaulttype = "ISA_CHGCD_ITEM_SEG"
                        }
                        //.log("defaulttype", defaulttype)
                        let defaultvalue = {
                            value: "",
                            label: t("--Select Charge Code--")
                        }
                        ReturnTable.push(defaultvalue)
                    }
                    let localArray = {
                        value: val.SEQ,
                        label: val[defaulttype]
                    }
                    if (val.SEQ == DefaultValue) {
                        SelectedValue = localArray
                    }
                    ReturnTable.push(localArray)
                });
            }
        }
        let ReturnTableValue = {
            ReturnTable: ReturnTable,
            SelectedValue: SelectedValue
        }
        return (ReturnTableValue)
    }

    //PS_ZE_11_API_45 [Zeus 11 stock issues cart]On change of cc dropdown[Vishalini] - 11/26/2023
    handleChangePlaceDD1 = (selectedOption, Title) => {

        debugger;
        let ChargeSelected = this.state.ChargeSelected;
        if (Title == "PlaceDD2Value" || Title == "PlaceDD3Value") {
            if (ChargeSelected != "" && ChargeSelected != " " && ChargeSelected != null && ChargeSelected != undefined || (this.state.PlaceDD1Data.length == 0)) {
                $('#ChargeCodeErrorDiv').addClass('d-none');
            }
            else {
                $('#ChargeCodeErrorDiv').removeClass('d-none');
                $('#ChargeCodeErrorLabel').addClass('errorcolour');
                return;
            }
        }
        else if (Title == "PlaceDD1Value") {
            $('#ChargeCodeErrorDiv').addClass('d-none');
            // $('#ChargeCodeErrorLabel').addClass('errorcolour');
        }

        let CCArray = [];
        let DD1;
        let DD2;
        let DD3;
        let locdropCD1SegIndex = 0;
        let locdropCD2SegIndex = 0;
        let locdropCD3SegIndex = 0;
        let locPlaceDD1Index = this.state.PlaceDD1Index !== 0 ? this.state.PlaceDD1Index : 0;
        let locPlaceDD2Index = this.state.PlaceDD2Index !== 0 ? this.state.PlaceDD2Index : 0;
        let locPlaceDD3Index = this.state.PlaceDD3Index !== 0 ? this.state.PlaceDD3Index : 0;
        locdropCD1SegIndex = this.state.dropCD1SegIndex !== 0 ? this.state.dropCD1SegIndex : 0;
        //locdropCD2SegIndex = this.state.dropCD2SegIndex !== 0 ? this.state.dropCD2SegIndex : 0;
        //locdropCD3SegIndex = this.state.dropCD3SegIndex !== 0 ? this.state.dropCD3SegIndex : 0;
        let dropCD1SegFilled = this.state.dropCD1SegValue.value == undefined ? false : true
        let dropCD2SegFilled = this.state.dropCD2SegValue.value == undefined ? false : true
        if (Title == "dropCD2SegValue") {
            if (selectedOption.value !== "Select") {
                locdropCD2SegIndex = this.state.dropCD2SegIndex !== 0 ? this.state.dropCD2SegIndex : 0;
            }
        }
        if (Title == "dropCD3SegValue") {
            if (selectedOption.value !== "Select") {
                locdropCD3SegIndex = this.state.dropCD3SegIndex !== 0 ? this.state.dropCD3SegIndex : 0;
            }
        }
        if (ChargeSelected != "" && ChargeSelected != " " && ChargeSelected != null && ChargeSelected != undefined) {
            // ChargeSelected.includes("--") ? CCArray = ChargeSelected.split("--") : CCArray = ChargeSelected.split("-");
            // DD1 = CCArray[0];
            // DD2 = CCArray[1];
            //DD3 = CCArray[2];
            // Assigning index
            DD1 = this.state.PlaceDD1Index !== 0 && this.state.PlaceDD1Data[this.state.PlaceDD1Index] ? this.state.PlaceDD1Data[this.state.PlaceDD1Index].value : this.state.dropCD1SegIndex !== 0 && this.state.dropCD1SegData[this.state.dropCD1SegIndex] ? this.state.dropCD1SegData[this.state.dropCD1SegIndex].value : null;
            DD2 = this.state.PlaceDD2Index !== 0 && this.state.PlaceDD2Data[this.state.PlaceDD2Index] ? this.state.PlaceDD2Data[this.state.PlaceDD2Index].value : this.state.dropCD2SegIndex !== 0 && this.state.dropCD2SegData[this.state.dropCD2SegIndex] ? this.state.dropCD2SegData[this.state.dropCD2SegIndex].value : null;
            DD3 = this.state.PlaceDD3Index !== 0 && this.state.PlaceDD3Data[this.state.PlaceDD3Index] ? this.state.PlaceDD3Data[this.state.PlaceDD3Index].value : this.state.dropCD3SegIndex !== 0 && this.state.dropCD3SegData[this.state.dropCD3SegIndex] ? this.state.dropCD3SegData[this.state.dropCD3SegIndex].value : null;

            if (Title == "PlaceDD1Value") {
                //Finding index
                locPlaceDD1Index = this.state.PlaceDD1Data.findIndex(
                    (option) => option.value === selectedOption.value
                );
                if (DD1 != 0 && DD1 != null && DD1 != undefined && DD1 != "" && DD1 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD1, selectedOption.value);
                } else if (DD2 != 0 && DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
                    ChargeSelected = selectedOption.value + "-" + ChargeSelected;
                } else {
                    ChargeSelected = selectedOption.value
                }
            }
            else if (Title == "PlaceDD2Value") {
                locPlaceDD2Index = this.state.PlaceDD2Data.findIndex(
                    (option) => option.value === selectedOption.value
                );
                if (DD2 != 0 && DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD2, selectedOption.value);
                } else if (DD3 != 0 && DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
                    ChargeSelected = selectedOption.value + "-" + ChargeSelected;
                } else {
                    ChargeSelected = ChargeSelected + "-" + selectedOption.value
                }
            }
            else if (Title == "PlaceDD3Value") {
                locPlaceDD3Index = this.state.PlaceDD3Data.findIndex(
                    (option) => option.value === selectedOption.value
                );
                if (DD3 != 0 && DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD3, selectedOption.value);
                } else {
                    ChargeSelected = ChargeSelected + "-" + selectedOption.value
                }
            }
            if (Title == "dropCD1SegValue") {
                locdropCD1SegIndex = this.state.dropCD1SegData.findIndex(
                    (option) => option.value === selectedOption.value
                );
                if (DD1 != 0 && DD1 != null && DD1 != undefined && DD1 != "" && DD1 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD1, selectedOption.value);
                } else if (DD2 != 0 && DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
                    ChargeSelected = selectedOption.value + "-" + ChargeSelected;
                }
                else {
                    ChargeSelected = selectedOption.value
                    //dropCD1SegValue = selectedOption.value
                }
                dropCD1SegFilled = true
            }
            else if (Title == "dropCD2SegValue") {
                locdropCD2SegIndex = this.state.dropCD2SegData.findIndex(
                    (option) => option.value === selectedOption.value
                );
                if (DD2 != 0 && DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD2, selectedOption.value);
                } else if (DD3 != 0 && DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
                    ChargeSelected = selectedOption.value + "-" + ChargeSelected;
                }
                else {
                    ChargeSelected = ChargeSelected + "-" + selectedOption.value
                    // dropCD2SegValue = ChargeSelected + "-" + selectedOption.value
                }
                dropCD2SegFilled = true
            }
            else if (Title == "dropCD3SegValue") {
                locdropCD3SegIndex = this.state.dropCD3SegData.findIndex(
                    (option) => option.value === selectedOption.value
                );
                if (DD3 != 0 && DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
                    ChargeSelected = ChargeSelected.replaceAll(DD3, selectedOption.value);
                }
                else {
                    ChargeSelected = ChargeSelected + "-" + selectedOption.value
                    // dropCD3SegValue = ChargeSelected + "-" + selectedOption.value
                }
            }

        } else {
            ChargeSelected = selectedOption.value
            if (Title == "PlaceDD1Value") {
                //Finding index
                locPlaceDD1Index = this.state.PlaceDD1Data.findIndex(
                    (option) => option.value === selectedOption.value
                );
            }
            else if (Title == "PlaceDD2Value") {
                locPlaceDD2Index = this.state.PlaceDD2Data.findIndex(
                    (option) => option.value === selectedOption.value
                );
            }
            else if (Title == "PlaceDD3Value") {
                locPlaceDD3Index = this.state.PlaceDD3Data.findIndex(
                    (option) => option.value === selectedOption.value
                );
            }
        }
        //.log(`Option selected:`, selectedOption);

        let dropCD1SegValue = this.state.dropCD1SegValue.value == undefined ? "" : this.state.dropCD1SegValue.value
        let dropCD2SegValue = this.state.dropCD2SegValue.value == undefined ? "" : this.state.dropCD2SegValue.value
        let dropCD3SegValue = this.state.dropCD3SegValue.value == undefined ? "" : this.state.dropCD3SegValue.value
       
        if (Title == "dropCD1SegValue") {
            locdropCD1SegIndex = this.state.dropCD1SegData.findIndex(
                (option) => option.value === selectedOption.value
            );
            if (Title == "dropCD1SegValue") {
                dropCD1SegValue = selectedOption.value
            }
            else if (this.state.dropCD1SegValue != []) {
                dropCD1SegValue = this.state.dropCD1SegValue.value
            }
            dropCD1SegFilled = true
        }
        else if (Title == "dropCD2SegValue") {
            locdropCD2SegIndex = this.state.dropCD2SegData.findIndex(
                (option) => option.value === selectedOption.value
            );
            if (Title == "dropCD2SegValue") {
                dropCD2SegValue = selectedOption.value
            }
            else if (this.state.dropCD1SegValue != []) {
                dropCD2SegValue = this.state.dropCD2SegValue.value
            }
            dropCD2SegFilled = true
            dropCD3SegValue = "";
        }
        else if (Title == "dropCD3SegValue") {
            locdropCD3SegIndex = this.state.dropCD3SegData.findIndex(
                (option) => option.value === selectedOption.value
            );
            if (Title == "dropCD3SegValue") {
                dropCD3SegValue = selectedOption.value
            }
            else if (this.state.dropCD1SegValue != []) {
                dropCD3SegValue = this.state.dropCD3SegValue.value
            }
        }
        let locArray = []
        locArray.push(selectedOption)
        this.setState({
            [Title]: locArray, ChargeSelected: ChargeSelected, OlChargeCOde: ChargeSelected, PlaceDD1Index: locPlaceDD1Index,
            PlaceDD2Index: locPlaceDD2Index, PlaceDD3Index: locPlaceDD3Index, dropCD1SegIndex: locdropCD1SegIndex, dropCD2SegIndex: locdropCD2SegIndex,
            dropCD3SegIndex: locdropCD3SegIndex
        });
        if (Title == "dropCD1SegValue" || Title == "dropCD2SegValue") {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            let BuildChrCDBO = {
                UserId: this.state.Login_UserId,
                UserBU: this.state.Login_UserBU,
                dropCD1SegValue: dropCD1SegValue,
                dropCD2SegValue: dropCD2SegValue,
                dropCD3SegValue: dropCD3SegValue,
                dropCD1SegFilled: dropCD1SegFilled,
                dropCD2SegFilled: dropCD2SegFilled,
                dropCD1Seg_Visible: this.state.dropCD1SegVisible,
                dropCD2Seg_Visible: this.state.dropCD2SegVisible,
                dropCD3Seg_Visible: this.state.dropCD3SegVisible,
            }
            STKIssuesCartAction.GetchargeCOde(BuildChrCDBO)
        }
    };

    //PS_ZE_11_API_46 [Zeus 11 stock issues cart]Invoke binding method to cc textbox[Vishalini] - 11/26/2023
    updateChargeCode = () => {
        debugger
        let Validate = true
        Validate = this.validateChargeCode(this.state.PlaceDD1Visible, this.state.PlaceDD1Value)
        Validate = Validate == true ? this.validateChargeCode(this.state.PlaceDD2Visible, this.state.PlaceDD2Value) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.PlaceDD3Visible, this.state.PlaceDD3Value) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropCD1SegVisible, this.state.dropCD1SegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropCD2SegVisible, this.state.dropCD2SegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropCD3SegVisible, this.state.dropCD3SegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropItmSegVisible, this.state.dropItmSegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropMchSegVisible, this.state.dropMchSegValue) : Validate
        Validate = Validate == true ? this.validateChargeCode(this.state.dropEmpSegVisible, this.state.dropEmpSegValue) : Validate
        //.log("ChargeValidate", Validate)
        if (Validate == true) {
            if (this.state.ChargeCodeLevel == 0) {
                this.bindCCToItemOL("ChargeCode", "", this.state.OrderEmpyId)
            }
            else {
                this.LinelevelEmyEdit(this.state.ItemId, this.state.UniqId, "ItemChgCode", "")
            }
            $('#Charge-Code-popup').modal('hide');
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        else {
            $('#ChargeCodeLabel').addClass('errorcolour');
            //if (!this.state.OlChargeCOde || !this.state.ChargeSelected) {
            // $('#ChargeCodeLabel1').removeClass('d-none');
            //$('#ChargeCodeLabelDiv').removeClass('d-none');
            //  $('#ChargeCodeLabel1').addClass('errorcolour');
            //}
        }
    }

    //PS_ZE_11_API_46 [Zeus 11 stock issues cart]To validate cc dropdown as value or not[Vishalini] - 11/26/2023
    validateChargeCode = (ChargeCodeDisplayed, ChargeCode) => {
        debugger
        let Validate = true
        if (ChargeCodeDisplayed) {
            if (ChargeCode == [] || ChargeCode.length == 0) {
                Validate = false
            }
            else if (ChargeCode.length > 0) {
                if (ChargeCode[0].value == "") {
                    Validate = false
                }
            }
        }
        return (Validate)
    }

    //[Zeus 11 stock issues cart]Method to Set state the OlChargeCOde to get binded in textbox[Vishalini] - 11/26/2023
    bindCCToItemOL = (FieldType, DueDateOL, e) => {
        debugger
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let ValidChargeCode = true
        // let childItems;
        let newvalueFilled;
        let EmpID = e;
        let ItemDetailBo = [];
        let OlChargeCOde = this.state.OlChargeCOde
        let isEMPDDCh = false;
        switch (FieldType) {
            case "ChargeCode": {
                //childItems = this.state.CartItems.filter((item, index) => item.ItemChgCode == null || item.ItemChgCode == "");
                newvalueFilled = this.state.ChargeSelected == "" || this.state.ChargeSelected == null || this.state.ChargeSelected == undefined ? this.state.OlChargeCOde : this.state.ChargeSelected;
                //filetoupdate = "ITEM_CHG_CODE";
                if (newvalueFilled != null && newvalueFilled != undefined && newvalueFilled != "") {
                    let newvalueFilledL = newvalueFilled
                    let CheckChargeCOde = newvalueFilledL.replaceAll(/(\r\n|\n|\r)/gm, "")
                    if (CheckChargeCOde != null && CheckChargeCOde != undefined && CheckChargeCOde != "") {
                        if (CheckChargeCOde.length == 0) {
                            ValidChargeCode = false
                        }
                    }
                    else {
                        ValidChargeCode = false
                    }
                }
                else {
                    ValidChargeCode = false
                }
                OlChargeCOde = newvalueFilled;
                break;
            }
        }
        debugger
        let ErrorMsg = OlChargeCOde.trim() === '' ? this.state.ErrStatement : '';

        this.setState({
            UpdatedOlChargeCOde: OlChargeCOde, ErrStatement: ErrorMsg,
            UpdatedChargeSelected: this.state.ChargeSelected, UpdatedPlaceDD1Index: this.state.PlaceDD1Index,
            UpdatedPlaceDD2Index: this.state.PlaceDD2Index, UpdatedPlaceDD3Index: this.state.PlaceDD3Index, UpdateddropCD1SegIndex: this.state.dropCD1SegIndex, UpdatedropCD2SegIndex: this.state.dropCD2SegIndex,
            UpdateddropCD3SegIndex: this.state.dropCD3SegIndex, UpdatedPlaceDD1Value: this.state.PlaceDD1Value,
            UpdatedPlaceDD2Value: this.state.PlaceDD2Value, UpdatedPlaceDD3Value: this.state.PlaceDD3Value, UpdateddropCD1SegValue: this.state.dropCD1SegValue, UpdateddropCD2SegValue: this.state.dropCD2SegValue,
            UpdateddropCD3SegValue: this.state.dropCD3SegValue, UpdateddropEmpSegValue: this.state.dropEmpSegValue, UpdateddropItmSegValue: this.state.dropItmSegValue, UpdateddropMchSegValue: this.state.dropMchSegValue,
            UpdateddropCD1SegData: this.state.dropCD1SegData, UpdateddropCD2SegData: this.state.dropCD2SegData, UpdateddropCD3SegData: this.state.dropCD3SegData,
            UpdateddropEmpSegData: this.state.dropEmpSegData, UpdateddropItmSegData: this.state.dropItmSegData, UpdateddropMchSegData: this.state.dropMchSegData,
            UpdatedPlaceDD1Data: this.state.PlaceDD1Data, UpdatedPlaceDD2Data: this.state.PlaceDD2Data, UpdatedPlaceDD3Data: this.state.PlaceDD3Data
        })

    }

    //PS_ZE_11_API_14 [Zeus 11 stock issues cart]On click of search icon in pop up and invoke action method[Vishalini] - 11/26/2023
    invItemSearch = () => {
        let valid = true;
        let newitem = "";
        let truncatedItemId = '';
        if (truncatedItemId === newitem) {
            if ($("#NewItemID").val().trim() != "" && $("#NewItemID").val() != null && $("#NewItemID").val() != undefined) {
                newitem = $("#NewItemID").val().trim();
                this.state.cartArr.forEach((item, index) => {
                    truncatedItemId = item.ItemId.substring(3);
                    if (item.ItemId == newitem || truncatedItemId == newitem) {
                        valid = false
                        this.setState({ ItemCheck: true, ItemIdCheck: false, InvalidItemId: false });
                    }
                    //else if (truncatedItemId == newitem) {
                    //    valid = false
                    //    this.setState({ ItemCheck: true, ItemIdCheck: false, InvalidItemId: false });
                    //}
                })
            }
            else {
                valid = false
                this.setState({ ItemIdCheck: true, ItemCheck: false, InvalidItemId: false });
            }
            if (valid) {
                let BO = {
                    ItemId: newitem.toUpperCase(),
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                    Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
                    Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                    Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                    Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                    Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                    Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                    Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                    Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                    Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                    Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                    Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                    Session_AMAZONSEARCH: CookieService.getLocalStorageValues("Session_AMAZONSEARCH"),
                    SOURCE: "WEB",
                    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                    Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
                    Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                    COSINGED: true,
                    Location: CookieService.getLocalStorageValues("Session_STATE") != null ? CookieService.getLocalStorageValues("Session_STATE") : "",
                    ShipToID: CookieService.getLocalStorageValues("HOME_STORE") != null && CookieService.getLocalStorageValues("HOME_STORE") != undefined && CookieService.getLocalStorageValues("HOME_STORE") != "" ? CookieService.getLocalStorageValues("HOME_STORE") : " ",
                }
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                STKIssuesCartAction.ItemSearchAction(BO);
            }

        }
    }


    //PS_ZE_11_API_19 [Zeus 11 stock issues cart]Brings the item deatils response and set state in NewItemDetail array[Vishalini] - 11/26/2023
    invItemSearchResponse = () => {
        let Response = JSON.parse(STKIssuesCartStore.InvSearchData);
        let LocItemDetail = Response.ItemDetail
        let Error = ""
        const { t } = this.props;
        if (Response.hasOwnProperty('Exception')) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Something went wrong please try again")
            })
        }
        else {
            if (LocItemDetail != undefined && LocItemDetail != null) {
                if (LocItemDetail.length > 0) {
                    let locinvalid = false
                    if (LocItemDetail[0].ItemId == "" || LocItemDetail[0].ItemId == null || LocItemDetail[0].ItemId == undefined) {
                        Error = "No data found"
                        //if (LocItemDetail[0].ZeroQty == "True") {
                        //    Error = "Qty not available"
                        //}

                        locinvalid = true
                    }

                    this.setState({ NewItemDetail: LocItemDetail, ItemCheck: false, ItemIdCheck: false, InvalidItemId: locinvalid, Errormsg: Error });

                }
            }
        }

    }

    //[Zeus 11 stock issues cart]On change method of back order[Vishalini] - 11/26/2023
    handlebackordercheck = (event, index) => {
        const { cartArr } = this.state;
        if (event.target.checked) {
            // cartArr[index].BackOrderEnabled = true;
            cartArr[index].BackOrderCheck = true;
        }
        else {
            cartArr[index].BackOrderCheck = false;
        }
        this.setState({ cartArr });
    };

    //[Zeus 11 stock issues cart]On change method of asset id dropdown[Vishalini] - 11/26/2023
    handleDDChange = (e, name) => {
        this.setState({ [name]: e });
    }
    //PS_ZE_11_API_32 - 36 [Zeus 11 stock issues cart]On change method of qty, employee, asset id textbox, remove, WO, NOTES, CC[Vishalini] - 11/26/2023
    OnChangeField = (name, event, index) => {
        const re = SDIConstants.quantityRegEX;

        switch (name) {
            case "EMP": {
                this.setState({ SelectedEmp: event, EmployeeErr: false });
                break;
            }
            case "QTY": {
                let QuantityvalidateSuccess = false

                let { cartArr } = this.state;
                const re = SDIConstants.quantityRegEX;
                let txtQTY
                if (re.test(event.target.value)) {
                    txtQTY = Math.round(event.target.value);
                    console.log("txtQTY", txtQTY)
                    if (txtQTY !== 0) {
                        QuantityvalidateSuccess = true
                    }
                }
                else if (!event.target.value) {
                    QuantityvalidateSuccess = true
                }
                if (QuantityvalidateSuccess) {
                    cartArr[index].QTY = event.target.value;
                    const QtyAvailable = parseFloat(cartArr[index].QtyAvailable);
                    const inputValue = parseFloat(event.target.value);
                    if (cartArr[index].QTY === "") {
                        cartArr[index].BackOrderEnabled = false;
                        cartArr[index].BackOrderCheck = false;
                    }
                    else if ((inputValue !== "") && (QtyAvailable < inputValue || QtyAvailable === 0)) {
                        cartArr[index].BackOrderEnabled = true;
                        cartArr[index].BackOrderCheck = true;
                    }
                    else {
                        cartArr[index].BackOrderEnabled = false;
                        cartArr[index].BackOrderCheck = false;
                    }
                    this.setState({ cartArr });
                }
                break;
            }
            //case "BackOrderChk": {
            //    let { cartArr } = this.state;
            //    if (event.target.checked) {
            //        cartArr[index].BackOrderEnabled = true;
            //        cartArr[index].BackOrderCheck = true; 
            //    }
            //    else {
            //        //cartArr[index].BackOrderEnabled = false;
            //        cartArr[index].BackOrderCheck = false;
            //    }
            //    this.setState({ cartArr });
            //    break;
            //}
            case "Remove": {
                let { cartArr } = this.state
                cartArr.splice(index, 1)
                this.setState({ cartArr });

                break;
            }
            case "Word_Order": {
                let value = event.target.value.toUpperCase().trim();
                this.setState({
                    ValidatedWo: value
                });
                break;
            }
            case "CUST_NOTES": {
                this.setState({ CUST_NOTES: event.target.value });
                //console.log("WO#", this.state.ValidatedWo);
                break;
            }
            case "ASSET_ID": {
                this.setState({ AssetId: event.target.value });
                break;
            }
            case "CHARGE_CODE": {
                let inputValue = event.target.value;
                let ErrorMsg = inputValue.trim() === '' ? this.state.ErrStatement : '';
                this.setState({ OlChargeCOde: inputValue, ErrStatement: ErrorMsg });
                break;
            }
        }


    }

    //handleChargecodechange = (event, Type, LineNo, ItemIDE) => {
    //    if (Type == 0) {
    //        this.setState({ OlChargeCOde: event.target.value })
    //    }
    //    //else {
    //    //    let { CartItems } = this.state;
    //    //    let index = CartItems.findIndex(x => x.Itemid == ItemIDE && x.UniqNum == LineNo);
    //    //    CartItems[index].ItemChgCode = event.target.value;
    //    //    this.setState(CartItems);
    //    //}
    //}

    //[Zeus 11 stock issues cart]On click of add to cart button in pop up[Vishalini] - 11/26/2023
    insertInCart = () => {
        let loccart = [];
        let { cartArr } = this.state
        let cartlength = this.state.cartArr.length
        if (this.state.NewItemDetail.length > 0) {

            let cart = {
                ItemId: this.state.NewItemDetail[0].ItemId,
                DESCR254: this.state.NewItemDetail[0].DESCR254,
                MFG: this.state.NewItemDetail[0].MFG,
                MFGID: this.state.NewItemDetail[0].MFGID,
                INV_ITEM_TYPE: this.state.NewItemDetail[0].INV_ITEM_TYPE,
                UOM: this.state.NewItemDetail[0].UOM,
                QtyAvailable: this.state.NewItemDetail[0].QtyAvailable,
                QTY: "",
                ThumbnailImageFile: this.state.NewItemDetail[0].ThumbnailImageFile,
                QTYError: false,
                VendorId: this.state.NewItemDetail[0].VendorID,
                BackOrderCheck: false,
                BackOrderEnabled: false
            }
            //loccart.push(cart)
            cartArr[cartlength] = cart
        }

        this.setState({ cartArr, NewItemDetail: [] });// NewItemDetail: [] 
        //this.setState({ NewItemDetail: []  });
        $("#NewItemID").val('')
        //Zeus 335-Niranjana-6/20/24-To store the values of "cartArr" in the LocalStorage
        CookieService.updateLocalStorageValues("STKCart", cartArr);
        $('#Add-New-Item').modal('hide');
    }

    //PS_ZE_11_API_22 [Zeus 11 stock issues cart]On click of validate icon in work order textbox[Vishalini] - 11/26/2023
    handleWoValidation = () => {
        let workOrder = this.state.ValidatedWo;
        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder").toUpperCase() == "Y") {
            if (workOrder != "" && workOrder != null && workOrder != undefined && this.state.Login_UserBU != "I0206") {
                if (this.state.disableWo != true) {
                    let workOrderBO = {
                        WorkOrder: workOrder,
                        BusinessUnit: this.state.Login_UserBU
                    }
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    STKIssuesCartAction.GetWorkOrderValidate(workOrderBO);
                }
            }
            else if (workOrder != "" && workOrder != null && workOrder != undefined && this.state.Login_UserBU == "I0206") {
                let locErrStatement = "";
                if (workOrder.length !== 9 && workOrder.length !== 13) {
                    locErrStatement = "Work Order# should be in the '99-999999' or '99-999999-999' format.";
                } else if (workOrder.length === 9) {
                    const regex = /^\d{2}-\d{6}$/;
                    if (!regex.test(workOrder)) {
                        locErrStatement = "Work Order# should be in the '99-999999' format.";
                        this.toastMessage();
                        this.setState({
                            ERR: true, ErrStatement: locErrStatement,
                            ValidatedWo: "",
                            woStyle: "INVALIDWO"
                        })
                    }
                } else if (workOrder.length === 13) {
                    const regex = /^\d{2}-\d{6}-\d{3}$/;
                    if (!regex.test(workOrder)) {
                        locErrStatement = "Work Order# should be in the '99-999999-999' format.";
                        this.toastMessage();
                        this.setState({
                            ERR: false, ErrStatement: locErrStatement,
                            ValidatedWo: "",
                            woStyle: "INVALIDWO"
                        })
                    }
                }
                else {
                    let workOrderBO = {
                        WorkOrder: workOrder,
                        BusinessUnit: this.state.Login_UserBU
                    }
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    STKIssuesCartAction.GetWorkOrderValidate(workOrderBO);
                }
            }
            else {
                this.toastMessage();
                this.setState({
                    ValidatedWo: "",
                    AssetId: "",
                    woStyle: "EMPTYWO"
                })
            }
        }
    }

    //[Zeus 11 stock issues cart]On click of enter in work order textbox[Vishalini] - 11/26/2023
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.handleWoValidation();
        };
    }
    //PS_ZE_11_API_22 [Zeus 11 stock issues cart]On change of work order[Vishalini] - 11/26/2023
    handleOnChangeWoText = (e) => {
        let value = e.target.value.toUpperCase().trim();
        let locErrormsg = this.state.ErrStatement;
        if (value != undefined && value != null && value != "") {
            locErrormsg = "";
        }
        this.setState({
            ValidatedWo: value, ErrorMsg: locErrormsg
        });
    }

    //[Zeus 11 stock issues cart]To display the toast message[Vishalini] - 11/26/2023
    toastMessage = () => {
        try {
            $("#WOValidation").addClass('d-block');
            setTimeout(() => {
                $("#WOValidation").removeClass('d-block');
            }, 3000);

        } catch (ex) {
            console.log(ex)
        }
    };

    //PS_ZE_11_API_18 [Zeus 11 stock issues cart]On change of work order[Vishalini] - 11/26/2023
    workOrderResponse = () => {
        const { t } = this.props;

        let workorder = this.state.ValidatedWo;
        let woValidationData = STKIssuesCartStore.STKWOResponse;
        //let responeData = JSON.parse(STKIssuesCartStore.STKWOResponse);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (woValidationData != undefined && woValidationData != null && woValidationData != "" && woValidationData != "null") {
            if (woValidationData != "error occured in server" && woValidationData != "error occured" && woValidationData != "authorization required"
                && woValidationData != "internal server error") {
                if (woValidationData == "invalid") {
                    let StatusErr = "";
                    let ErrorMsg = "";
                    this.toastMessage();
                    this.setState({
                        StatusErrVal: StatusErr,
                        ErrorMsgVal: ErrorMsg,
                        ValidatedWo: "",
                        disableWo: false,
                        woStyle: "INVALIDWO",
                    })
                }
                else {
                    let responeData = JSON.parse(STKIssuesCartStore.STKWOResponse);

                    if (responeData[0].WOVALIDATE != null && responeData[0].WOVALIDATE != "") {
                        if (responeData[0].WOVALIDATE == "valid") {
                            let StatusErr = "";
                            let ErrorMsg = "";
                            this.setState({
                                StatusErrVal: StatusErr,
                                ErrStatement: ErrorMsg,
                                disableWo: true,
                                ValidatedWo: workorder,
                                woStyle: "VALIDWO",

                            })
                        }
                        //else if (woValidationData == "invalid") {
                        //let StatusErr = "";
                        //let ErrorMsg = "";
                        //this.toastMessage();
                        //this.setState({
                        //    StatusErrVal: StatusErr,
                        //    ErrorMsgVal: ErrorMsg,                       
                        //    ValidatedWo: "", 
                        //    disableWo: false,
                        //    woStyle: "INVALIDWO",
                        //})
                        //}

                    }
                }

            }
            else {
                debugger

                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error occured in work order validation")
                });
                this.setState({
                    ValidatedWo: "",
                    AssetId: "",
                    //  workOrderDescription: "",
                    StatusErrVal: "",
                    ErrorMsgVal: ""
                })
            }
        }
    }

    //[Zeus 11 stock issues cart]On click of remove button[Vishalini] - 11/26/2023
    removeItem = (Index) => {
        debugger
        this.setState({ RemoveIndex: Index, RemoveItemPUp: true });
        $('#Cancel-confirmation').modal('show');
    }

    //[Zeus 11 stock issues cart]On click of cancel button[Vishalini] - 11/26/2023
    CancelOrder = () => {
        this.setState({ cartArr: [], ErrStatement: "", ERR: false, ValidWO: false, ValidatedWo: "", woStyle: "", SelectedEmp: { value: "", label: "Select" }, AssetId: "", OlChargeCOde: "", disableWo: false, CUST_NOTES: "", SelectedAssetID: { value: "", label: "Select" } });
        CookieService.updateLocalStorageValues("STKCart", []);
    }

    //PS_ZE_11_API_19 [Zeus 11 stock issues cart]On click of submit button gets invoked[Vishalini] - 11/26/2023
    submitValidation = () => {
        let Successvalidation = true;
        let qtyError = false;
        let ErrorMsg = "";
        let locEmployeeIDErr = false;

        //Quantity required validation
        if (this.state.cartArr.length > 0) {
            this.state.cartArr.forEach((item, index) => {
                if ((item.QTY == "" || item.QTY == null || item.QTY == undefined) || parseInt(item.QTY) == 0) {
                    qtyError = true;
                    Successvalidation = false;
                    ErrorMsg = "Please enter qty for the item";
                    //ErrorMsg = "Cannot process issue, Quantity Cannot be blank or zero";                   
                    return
                }
            });
            if (qtyError == false) {
                Successvalidation = true;
                this.setState({ ERR: false, ErrStatement: "", SubmitButtonClick: true });
            }
            else {
                this.setState({ ERR: true, ErrStatement: ErrorMsg, EmployeeErr: false, SubmitButtonClick: false });
                return;
            }
        }

        //Employee ID required validation
        if ((this.state.EmployeeRequired == true) && (this.state.SelectedEmp.value == undefined || this.state.SelectedEmp.value == null || this.state.SelectedEmp.value == "")) {
            locEmployeeIDErr = true;

        }
        if (locEmployeeIDErr == true) {
            Successvalidation = false;
            this.setState({ EmployeeErr: locEmployeeIDErr, SubmitButtonClick: false });
            return;
        }

        let CCRequiredValidate = false
        let WORequiredValidate = false
        let CCorWORequiredValidate = false
        let EmptyChargecodevalue = false
        let EmptyWO = false

        //Validation for Charge Code Required in Enterprise table flag        
        if ((this.state.CCRequired == true) && (this.state.OlChargeCOde == null || this.state.OlChargeCOde == undefined || this.state.OlChargeCOde == "")) {
            CCRequiredValidate = true;
        }

        //Validation for work order Required in Enterprise table flag        
        if ((this.state.WORequired == true) && (this.state.ValidatedWo == null || this.state.ValidatedWo == undefined || this.state.ValidatedWo == "")) {
            WORequiredValidate = true;
        }

        //Validation for Charge Code or Work Order Required
        // if (!this.state.OlChargeCOde || this.state.OlChargeCOde == null || this.state.OlChargeCOde == undefined || this.state.OlChargeCOde != " " || this.state.OlChargeCOde == "") {
        if (!this.state.OlChargeCOde) {
            EmptyChargecodevalue = true
        }
        if (!this.state.ValidatedWo) {
            EmptyWO = true
        }


        if (this.state.WOorCCRequired == true) {
            if (EmptyChargecodevalue == false || EmptyWO == false) {
                CCorWORequiredValidate = false;
                Successvalidation = true;
                ErrorMsg = "";
            }
            else if (EmptyChargecodevalue == true && EmptyWO == true) {
                CCorWORequiredValidate = true;
                Successvalidation = false;
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                ErrorMsg = "Please enter work order number or charge code";
                this.setState({ woStyle: "" });
            }

        }

        else if (this.state.WorkOrderTitle == "*Work Order#" && (WORequiredValidate == true && CCorWORequiredValidate == false)) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            Successvalidation = false;
            this.setState({ woStyle: "EMPTYWO" });
            this.toastMessage();
        }
        else if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder").toUpperCase() == "Y" && (this.state.disableWo == false) && (EmptyWO == false)) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            Successvalidation = false;
            this.setState({ woStyle: "INVALIDWO" });
            this.toastMessage();
        }

        //else if (CCorWORequiredValidate == true) {
        //    Successvalidation = false
        //    $(`#${SDIConstants.SDILoader}`).addClass('hide');      
        //    ErrorMsg = "Please enter work order number or charge code";
        //}
        else if (CCRequiredValidate == true) {
            Successvalidation = false
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            if (this.state.ChargeCodeMultiple == true) {
                ErrorMsg = "Please select charge code";
            }
            else {
                ErrorMsg = "Please fill charge code";
            }
        }

        if (Successvalidation == true) {
            this.setState({ ERR: false, ErrStatement: "", SubmitButtonClick: true });
            this.Submit()
        }
        else {
            this.setState({ ERR: true, ErrStatement: ErrorMsg, SubmitButtonClick: false });
        }

    }

    //PS_ZE_11_API_20 - 21 [Zeus 11 stock issues cart]Method to collect cart array values and invokve submit action method[Vishalini] - 11/26/2023
    Submit = () => {
        let STKCartItems = []
        console.log("ValidatedWo", this.state.ValidatedWo)
        console.log("cartArr", this.state.cartArr)
        let IsNotBackOrderCheck = false
        let assetID = "";
        if (this.state.MachineTable.length > 0) {
            assetID = this.state.SelectedAssetID.value;
        }
        else {
            assetID = this.state.AssetId;
        }

        if (this.state.cartArr.length > 0) {
            this.state.cartArr.forEach((item, index) => {
                if (item.BackOrderEnabled == true && item.BackOrderCheck == false) {
                    IsNotBackOrderCheck = true;
                }
                let arr = {
                    ItemId: item.ItemId,
                    Qty: item.QTY,
                    Price: item.Price,
                    Description: item.DESCR254,
                    UOM: item.UOM,
                    VendorId: item.VendorId,
                    MFG: item.MFG,
                    MFG_Part: item.MFGID,
                    ImageFile: (item.ThumbnailImageFile == "" || item.ThumbnailImageFile == "\N" || item.ThumbnailImageFile == "\\N" || item.ThumbnailImageFile == null || item.ThumbnailImageFile == undefined) ? "Images\noimage_new.png" : item.ThumbnailImageFile,
                    BackOrderCheck: IsNotBackOrderCheck,
                    OlChargeCOde: this.state.OlChargeCOde,
                    AssetId: assetID,
                    AvailQty: item.QtyAvailable
                }
                STKCartItems.push(arr)
            })

        }
        console.log("STKCartItems", STKCartItems)

        let BO = {
            Session_ID: CookieService.getLocalStorageValues("Session_ID"),
            SOURCE: "WEB",
            Session_UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_MANORDNO: CookieService.getLocalStorageValues("Session_MANORDNO"),
            Session_ORDERPREFIX: CookieService.getLocalStorageValues("Session_ORDERPREFIX"),
            Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
            punchinSessionID: "",
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
            Session_EMAIL: CookieService.getLocalStorageValues("Session_EMAIL"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
            Session_TestMode: CookieService.getLocalStorageValues("Session_TESTMODE"),
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            CurrentPage_Url: "/SDIXZEUS/ShoppingCart",
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_CARTFAIL: CookieService.getLocalStorageValues("Session_CARTFAIL"),
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            Session_ISABudgetaryLimit: CookieService.getLocalStorageValues("Session_ISABudgetaryLimit"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_ValidateWorkOrder: CookieService.getLocalStorageValues("Session_ValidateWorkOrder"),
            Validation_Status: "True",
            chkLineNumFlag: "",
            Session_ShowPrice: CookieService.getLocalStorageValues("Session_ShowPrice"),
            Session_PHONE: CookieService.getLocalStorageValues("Session_PHONE"),
            Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
            Session_RFQSITE: CookieService.getLocalStorageValues("Session_RFQSite"),
            Session_ISACstVendor: CookieService.getLocalStorageValues("Session_ISACstVendor"),
            Session_ISACstPrice: CookieService.getLocalStorageValues("Session_ISACstPrice"),
            Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
            Order_WorkOrder: this.state.ValidatedWo,
            Order_Customer_Notes: this.state.CUST_NOTES,
            Order_EmployeeCC: this.state.SelectedEmp.value,
            Order_Type: "",
            Order_Customer_Reference: "",
            Order_ShipTo: CookieService.getLocalStorageValues("HOME_STORE") != null && CookieService.getLocalStorageValues("HOME_STORE") != undefined && CookieService.getLocalStorageValues("HOME_STORE") != "" ? CookieService.getLocalStorageValues("HOME_STORE") : " ",
            Location2: " ",
            USER_CHAR2: " ",
            ISA_USER2: " ",
            USER_CHAR1: " ",
            Order_MachineNum: assetID,
            Order_ChargeCode: this.state.OlChargeCOde,
            STKIssuesCartItems: STKCartItems,
            Crib: CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT")


        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        STKIssuesCartAction.SubmitAction(BO);


    }

    //PS_ZE_11_API_25 [Zeus 11 stock issues cart]Fetches the response from backend and invoke order palced pop up[Vishalini] - 11/26/2023
    submitResponse = () => {
        let Response = JSON.parse(STKIssuesCartStore.SubmitResponse);
        console.log("Response", Response)
        const { t } = this.props;
        if (Response.hasOwnProperty('Exception') || (Response == "")) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Something went wrong please try again")
            })

        }
        else {
            if (Response != "") {
                $('#Order-Placed').modal('show');
                this.setState({
                    OrderNo: Response, ValidWO: false, ValidatedWo: "", woStyle: "", SelectedEmp: { value: "", label: "Select" }, AssetId: "", disableWo: false, CUST_NOTES: "",
                    SelectedAssetID: { value: "", label: "Select" }, cartArr: [], ErrStatement: "", ERR: false,
                    ChargeSelected: "", OlChargeCOde: "", PlaceDD1Index: 0, PlaceDD2Index: 0, PlaceDD3Index: 0, dropCD1SegIndex: 0, dropCD2SegIndex: 0, dropCD3SegIndex: 0,
                    PlaceDD1Value: [], PlaceDD1Data: [], PlaceDD2Value: [], PlaceDD2Data: [], PlaceDD3Value: [], PlaceDD3Data: [],
                    dropCD1SegValue: [], dropCD1SegData: [], dropCD2SegValue: [], dropCD2SegData: [], dropCD3SegValue: [], dropCD3SegData: [],
                    dropEmpSegValue: [], dropEmpSegData: [], dropItmSegValue: [], dropItmSegData: [], dropMchSegValue: [], dropMchSegData: [],
                    UpdatedChargeSelected: "", UpdatedOlChargeCOde: "", UpdatedPlaceDD1Index: 0,
                    UpdatedPlaceDD2Index: 0, UpdatedPlaceDD3Index: 0, UpdateddropCD1SegIndex: 0, UpdatedropCD2SegIndex: 0,
                    UpdateddropCD3SegIndex: 0, UpdatedPlaceDD1Value: [],
                    UpdatedPlaceDD2Value: [], UpdatedPlaceDD3Value: [], UpdateddropCD1SegValue: [], UpdateddropCD2SegValue: [],
                    UpdateddropCD3SegValue: [], UpdateddropEmpSegValue: [], UpdateddropItmSegValue: [], UpdateddropMchSegValue: []
                });
                CookieService.updateLocalStorageValues("STKCart", []);
            }
        }


    }

    //PS_ZE_11_API_26 [Zeus 11 stock issues cart]On click of "ok" in order palced pop up to invoke action method for cart confirm report[Vishalini] - 11/26/2023
    OrderPDF = () => {
        let cartFileBo = {
            Session_UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            OrderNO: this.state.OrderNo,//"NY00446489",
            fileName: ""
        }
        STKIssuesCartAction.GetCartConfirmPDF(cartFileBo)
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }

    //PS_ZE_11_API_31 [Zeus 11 stock issues cart]Response method for cart confirm report[Vishalini] - 11/26/2023
    cartConfirmPDFResponse = () => {
        const { t } = this.props;
        OrderSuccessCOunt = OrderSuccessCOunt + 1
        let OrderPdf = STKIssuesCartStore.OrderPDFMsge;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        $('#pdfPreview').modal('show');
        this.setState({ base64URL: OrderPdf })
    }
    ClearWO = () => {
        debugger
        this.setState({
            ValidatedWo: "", disableWo: false
        });
    }
    ClearCC = () => {
        this.setState({
            ChargeSelected: "", OlChargeCOde: "", PlaceDD1Index: 0, PlaceDD2Index: 0, PlaceDD3Index: 0, dropCD1SegIndex: 0, dropCD2SegIndex: 0, dropCD3SegIndex: 0,
            PlaceDD1Value: [], PlaceDD1Data: [], PlaceDD2Value: [], PlaceDD2Data: [], PlaceDD3Value: [], PlaceDD3Data: [],
            dropCD1SegValue: [], dropCD1SegData: [], dropCD2SegValue: [], dropCD2SegData: [], dropCD3SegValue: [], dropCD3SegData: [],
            dropEmpSegValue: [], dropEmpSegData: [], dropItmSegValue: [], dropItmSegData: [], dropMchSegValue: [], dropMchSegData: [],
            UpdatedChargeSelected: "", UpdatedOlChargeCOde: "", UpdatedPlaceDD1Index: 0,
            UpdatedPlaceDD2Index: 0, UpdatedPlaceDD3Index: 0, UpdateddropCD1SegIndex: 0, UpdatedropCD2SegIndex: 0,
            UpdateddropCD3SegIndex: 0, UpdatedPlaceDD1Value: [],
            UpdatedPlaceDD2Value: [], UpdatedPlaceDD3Value: [], UpdateddropCD1SegValue: [], UpdateddropCD2SegValue: [],
            UpdateddropCD3SegValue: [], UpdateddropEmpSegValue: [], UpdateddropItmSegValue: [], UpdateddropMchSegValue: []

        });
    }
    handleCancelClick = () => {
        this.setState({
            OlChargeCOde: this.state.UpdatedOlChargeCOde,
            ChargeSelected: this.state.UpdatedChargeSelected, PlaceDD1Index: this.state.UpdatedPlaceDD1Index,
            PlaceDD2Index: this.state.UpdatedPlaceDD2Index, PlaceDD3Index: this.state.UpdatedPlaceDD3Index, dropCD1SegIndex: this.state.UpdateddropCD1SegIndex,
            dropCD2SegIndex: this.state.UpdatedropCD2SegIndex,
            dropCD3SegIndex: this.state.UpdateddropCD3SegIndex, PlaceDD1Value: this.state.UpdatedPlaceDD1Value,
            PlaceDD2Value: this.state.UpdatedPlaceDD2Value, PlaceDD3Value: this.state.UpdatedPlaceDD3Value, dropCD1SegValue: this.state.UpdateddropCD1SegValue, dropCD2SegValue: this.state.UpdateddropCD2SegValue,
            dropCD3SegValue: this.state.UpdateddropCD3SegValue, dropEmpSegValue: this.state.UpdateddropEmpSegValue, dropItmSegValue: this.state.UpdateddropItmSegValue, dropMchSegValue: this.state.UpdateddropMchSegValue,
            dropCD1SegData: this.state.UpdateddropCD1SegData, dropCD2SegData: this.state.UpdateddropCD2SegData, dropCD3SegData: this.state.UpdateddropCD3SegData,
            dropEmpSegData: this.state.UpdateddropEmpSegData, dropItmSegData: this.state.UpdateddropItmSegData, dropMchSegData: this.state.UpdateddropMchSegData,
            PlaceDD1Data: this.state.UpdatedPlaceDD1Data, PlaceDD2Data: this.state.UpdatedPlaceDD2Data, PlaceDD3Data: this.state.UpdatedPlaceDD3Data
        });
    };
    removeSpace = (e) => {
        $("#NewItemID").val((e.target.value).trim());
    }
    keyPress = (e) => {
        if (e.keyCode == 13) {
            if ($("#NewItemID").val() != "") {
                this.invItemSearch();
            }
            else {
                this.setState({ ItemIdCheck: true, ItemCheck: false, InvalidItemId: false });
            }

        }
    }
    CloseButtonClick = () => {
        $('#Add-New-Item').modal('hide');
        $("#NewItemID").val('')
        this.setState({ NewItemDetail: [], ItemCheck: false, ItemIdCheck: false, InvalidItemId: false });
    }
    BindSTKCart = () => {
        const style = {
            image: {
                position: 'sticky',
                display: 'block',
                left: '0px !important',
                top: '0px !important',
            },
        }
        let RemoveKey = "  Remove"
        console.log("cartarr", this.state.cartArr)
        let cart = this.state.cartArr.length > 0 ? this.state.cartArr : [];
        if (this.state.cartArr.length > 0) {
            return this.state.cartArr.map((val, index) => {
                const { t } = this.props;
                return (
                    <div className="row">
                        <div className="col-lg-12 mt-3 mb-4">
                            <div className="row mx-0 mb-3 py-3 item-box" key={val.ItemId + index}>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-1 custom-fav-image pr-0 mb-2">
                                            <Image
                                                id={`Img${val.ItemId}${index}id`}
                                                src={val.ThumbnailImageFile}
                                                width={120}
                                                height={130}
                                                style={style.image}
                                            //  noImageSrc="/Images/noimage_new.png"
                                            />
                                        </div>

                                        <div className="col-md-11 custom-fav-container">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label className="item-details-value">{val.DESCR254}</label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <label className="catalog-label">ID :</label>
                                                                    <label className="item-details-value ml-2" id={`Item${val.ItemId}${index}id`}>{val.ItemId}</label>
                                                                </div>
                                                                <div className="col-md-4 pr-0">
                                                                    <label className="catalog-label">UOM :</label>
                                                                    <label className="item-details-value ml-2" id={`Uom${val.ItemId}id`}>{val.UOM}</label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox item-custom-control">
                                                                    <input type="checkbox" className="custom-control-input" checked={val.BackOrderCheck} onChange={(e) => this.handlebackordercheck(e, index)} disabled={!val.BackOrderEnabled} id={`backorderchk-${index}`} />

                                                                    {/*  <input type="checkbox" className="custom-control-input" checked={this.isLineChecked(index)} disabled={!this.shouldEnableCheckbox(index)} name={`example1`} onChange={() => this.handlebackordercheck(val.ItemId)} id={`backorderchk-${index}`} />  */}
                                                                    <label className="custom-control-label catalog-label priority-checkbox pl-2" htmlFor={`backorderchk-${index}`}><Trans>BackOrder</Trans> <span><img className="ml-1" src="images/info-icon-sdi.svg" alt="info-icon" data-toggle="tooltip" data-placement="top" title={t("Enabling this option indicates it as a backorder.")} /></span></label>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <label className="catalog-label"><Trans>MFG :</Trans></label>
                                                                    <label className="item-details-value ml-2" id={`RFQ${val.Itemid}${val.MFG}id`}>{val.MFG}</label>
                                                                </div>

                                                                <div className="col-md-4 pr-0">
                                                                    <label className="catalog-label"><Trans>MFG part#</Trans><span> :</span></label>
                                                                    <label className="item-details-value ml-2" id={`Man${val.ItemId}${val.MFGID}id`}>{val.MFGID}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <label className="catalog-label"><Trans>Type</Trans><span> :</span></label>
                                                                    <label className="item-details-value ml-2" id={`Type${val.ItemId}${val.INV_ITEM_TYPE}id`}>{val.INV_ITEM_TYPE}</label>
                                                                </div>
                                                                <div className="col-md-4 pr-0">
                                                                    <label className="catalog-label"><Trans>Available qty</Trans><span> :</span></label>
                                                                    <label className="item-details-value ml-2" id={`Type${val.ItemId}id`}>{val.QtyAvailable}</label>
                                                                </div>
                                                                {/* <div className="col-md-4 d-sm-block d-lg-none d-md-none">
                                                                    <div className="custom-control custom-checkbox my-0 mx-0 item-custom-control text-nowrap">
                                                                        <input type="checkbox" className="custom-control-input" id="customCheck7" name="example1" />
                                                                        <label className="custom-control-label catalog-label priority-checkbox pl-2" htmlFor="customCheck7">BackOrder <span><img className="ml-1" src="images/info-icon-sdi.svg" alt="info-icon" data-toggle="tooltip" data-placement="top" title="The quantity entered exceeds the available quantity, thus classifying it as a backorder." /></span></label>
                                                                    </div>
                                                                </div> */}
                                                            </div>

                                                        </div>
                                                        <button className="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" onClick={e => this.removeItem(index)} data-backdrop="static" data-keyboard="false">
                                                            <img src="Images/remove-order.svg" className="removeorder-icon" alt="Remove Order" />
                                                            <Trans>{RemoveKey}</Trans>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 OSPD-prices">
                                                    <div className="float-right">
                                                        <div className="w-100 float-left mb-2">
                                                            <div className="float-right" style={{ "marginTop": "45px" }}>
                                                                <span className="float-left m-2 ">Qty : </span>
                                                                <input type="text" value={val.QTY} title={val.QTY} id={'Quantity' + index} className="form-control float-left catalog-inline-qty" maxLength="8" style={{ "width": "50px" }} onChange={(e) => this.OnChangeField("QTY", e, index)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/*  {this.state.cartArr.length > 0 ?
                                <div className="col-md-12 col-sm-auto col-auto my-3 text-right">
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-2" data-toggle="modal" data-target="#Cancel-btn" data-backdrop="static" data-keyboard="false"><Trans>Cancel</Trans><span> </span><Trans>Order</Trans></button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn" data-backdrop="static" data-keyboard="false" onClick={() =>  this.SubmitValidation()} ><Trans>Submit</Trans><span> </span><Trans>Order</Trans></button>
                                </div> : null} */}

                        </div>
                    </div>

                );
            });


        }
    }
    BindNewItemDetail = () => {

        if (this.state.NewItemDetail.length > 0) {
            if (this.state.NewItemDetail[0].ItemId != "" && this.state.NewItemDetail[0].ItemId != null && this.state.NewItemDetail[0].ItemId != undefined) {
                $('#savebuttonid').show();

                return (
                    <>
                        <div className="row mb-3">
                            <label className="col-md-5 quick-label"><Trans>Item ID</Trans></label>
                            <label className="col-md-7 quick-value">{this.state.NewItemDetail[0].ItemId == "" ? "-" : this.state.NewItemDetail[0].ItemId}</label>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-5 quick-label"><Trans>Description</Trans></label>
                            <label className="col-md-7 quick-value">{this.state.NewItemDetail[0].DESCR254 == "" ? "-" : this.state.NewItemDetail[0].DESCR254}</label>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-5 quick-label"><Trans>MFG</Trans></label>
                            <label className="col-md-7 quick-value">{this.state.NewItemDetail[0].MFG}</label>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-5 quick-label"><Trans>MFG part number</Trans></label>
                            <label className="col-md-7 quick-value">{this.state.NewItemDetail[0].MFGID}</label>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-5 quick-label"><Trans>Type</Trans></label>
                            <label className="col-md-7 quick-value">{this.state.NewItemDetail[0].INV_ITEM_TYPE}</label>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-5 quick-label"><Trans>UOM</Trans></label>
                            <label className="col-md-7 quick-value">{this.state.NewItemDetail[0].UOM}</label>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-5 quick-label"><Trans>Qty available</Trans></label>
                            <label className="col-md-7 quick-value">{this.state.NewItemDetail[0].QtyAvailable}</label>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 mb-2 text-center" id="savebuttonid">
                                {/*<button className="btn btn-secondary custom-btn-secondary secondary-btn px-4">Close</button>*/}
                                {/*  {$("#NewItemId").val() == "" ? null :*/}
                                {this.state.NewItemDetail[0].QtyAvailable != null && this.state.NewItemDetail[0].QtyAvailable != "" ?
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4" onClick={() => this.insertInCart()}><Trans>Add to cart</Trans></button> : null}
                            </div>
                        </div>
                    </>);
                //}
                //})
            }
        }
    }
    render() {
        const { t } = this.props;

        return (
            <div>
                <div className={this.state.ContentClassName} id="ContentDiv">


                    <div className="row">
                        {/* Shopping Cart starts */}

                        <div className="col-md-12 mt-3 page-heading">
                            <h5 className="page-title"><Trans>Stock Issues Cart</Trans></h5>
                            {!isFromLink ?
                                <div className="fav-item-maintenance-icon mt-1">
                                    <input type="checkbox" id="checkbox-a" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-a">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                </div> : null}
                        </div>
                        <div className="col-lg-12  mb-5">
                            <h5 className="font-16 font-bold mt-4 mb-3">Order details</h5>
                            <div className="row" id="order-row1" style={{}}>
                                <div className="col-md-8 float-left">

                                    <div className="col-md-6 mb-3 float-left p-0 custom-iphone-padding" id="workorderOL">
                                        <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label">{t(this.state.WorkOrderTitle)}
                                            {/* {CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? <span className="info-container">
                                                <img className="ml-2" src="Images/info-icon.png" />
                                                <span className="Grid-info-icon text-left sdi-fede-tooltippickup "><Trans>The items in the cart will be removed by changing the WO</Trans></span>
                                            </span> : null}  */}
                                        </label>
                                        <div className="float-left search-autocomplete col-md-6 p-0 home-search sdi-package" >
                                            {/*  {this.state.Login_UserBU == 'I0635' ?
                                                <Autocomplete
                                                    getItemValue={this.getItemWoValue}
                                                    items={this.state.suggestionWo}
                                                    renderItem={this.renderWoItem}
                                                    value={this.state.ValidatedWo}
                                                    onChange={e => this.handleOnChangeWo(e)}
                                                    onSelect={(e) => this.onSelectItem(e)}
                                                    disabled={this.state.disableWo}
                                                    inputProps={{ placeholder: "Enter work order", className: "form-control float-left ", id: "#OLWorkOrderTB", disabled: this.state.disableWo, onKeyDown: this.searchKeyPress, style: { padding: '0px 57px 0px 10px', borderColor: this.state.woStyle == "EMPTYWO" || this.state.woStyle == "INVALIDWO" || this.state.woStyle == "MISMATCHWO" ? '#f44336' : '#ced4da' } }}
                                                    maxLength="50"
                                                /> : */}
                                            <input type="text" className="form-control " placeholder={t("Enter work order")} id="#OLWorkOrderTB" name="WorkOrderNo" disabled={this.state.disableWo} maxLength="80" onKeyDown={(e) => this.searchKeyPress(e)} onChange={e => this.handleOnChangeWoText(e)} value={this.state.ValidatedWo} title={this.state.ValidatedWo} style={{ padding: "0px 57px 0px 10px", borderColor: this.state.woStyle == "EMPTYWO" || this.state.woStyle == "INVALIDWO" || this.state.woStyle == "MISMATCHWO" ? "#f44336" : "#ced4da" }} />
                                            {/*   } */}

                                            {this.state.ValidatedWo != undefined && this.state.ValidatedWo != "null" && this.state.ValidatedWo != "" && this.state.ValidatedWo != "error" &&
                                                this.state.ValidatedWo != "blockpopuprepeat" ?
                                                <> < span className="search-cancel-header cursor-pointer"
                                                    style={{
                                                        right:
                                                            CookieService.getLocalStorageValues("Session_ValidateWorkOrder") !== "Y"
                                                                ? '5px'
                                                                : undefined,
                                                    }}
                                                    onClick={() => this.ClearWO()} > </span>
                                                </> : null

                                            }
                                            {CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ?
                                                <span onClick={() => this.handleWoValidation()} className="shopping-validate-icon" />
                                                : null}

                                        </div>
                                        <span className={`info-container ${this.state.woStyle != "" && this.state.woStyle != "VALIDWO" ? "" : "d-none"}`}>
                                            <img className="ml-3 mt-2" style={{ "width": "4%" }} src="Images/validation-icon.svg" />
                                            <span id="WOValidation" className="Grid-info-icon text-left sdi-fede-tooltippickup text-danger" style={{ "margin": "6px 0px 0px 39px" }}>
                                                {this.state.woStyle == "EMPTYWO" ? t("Please enter a work order number")
                                                    : this.state.woStyle == "INVALIDWO" ? t("Please enter a valid work order number")
                                                        : this.state.woStyle == "MISMATCHWO" ? t("Please enter a valid work order and user combination") : ""}</span>
                                        </span>
                                    </div>

                                    <div className="col-md-6 mb-4 float-left p-auto p-lg-0" id="PanelChargeCOde">
                                        <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label">{t(this.state.ChargeCodeTitle)}</label>
                                        {this.state.ChargeCodeMultiple == true ? <div className="col-lg-6 col-md-12 float-left px-0 col-12">
                                            <div className="col-lg-10 col-md-10 float-left px-0 col-12 CardDiv">
                                                <input type="text" className="form-control" style={{ paddingRight: '30px' }} placeholder={t("Select charge code")} id="OLChargeCode" readOnly value={this.state.OlChargeCOde} title={this.state.OlChargeCOde} maxLength="40" onChange={(e) => this.OnChangeField("CHARGE_CODE", e)} disabled={!this.state.OlChargeCOde} />
                                                {this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "null" && this.state.OlChargeCOde != "" ?
                                                    <> < span className="search-cancel-header cursor-pointer" onClick={() => this.ClearCC()} style={{ right: '5px', width: '20px' }}  > </span>
                                                    </> : null

                                                }
                                            </div>
                                            <div className="col-lg-2 col-md-2 float-left px-0 CardImageDiv">
                                                <button className="btn btn-primary custom-btn-primary primary-btn float-right" data-backdrop="static" data-keyboard="false" onClick={() => this.getChargeCode(0, this.state.OlChargeCOde, "Ordlvl")}>CC</button>
                                            </div>
                                        </div> : <div className="col-lg-6 col-md-12 float-left px-0 col-12 mr-1">
                                                <input type="text" className="form-control" style={{ paddingRight: '30px' }} placeholder={t("Enter charge code")} id="OLChargeCode" value={this.state.OlChargeCOde} title={this.state.OlChargeCOde} maxLength="40" onChange={(e) => this.OnChangeField("CHARGE_CODE", e)} />
                                                {this.state.OlChargeCOde != undefined && this.state.OlChargeCOde != "null" && this.state.OlChargeCOde != "" ?
                                                    <> < span className="search-cancel-header cursor-pointer" onClick={() => this.ClearCC()} style={{ right: '5px' }} > </span>
                                                    </> : null

                                                }
                                            </div>
                                        }

                                    </div>
                                    <div className="col-md-6 mb-4 float-left p-auto p-lg-0" id="EmployeeIDDIV">
                                        <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label" id="OLEmplyIdLabel">{t(this.state.OLEmplyIdLabel)}</label>
                                        <span className="col-lg-6 col-md-12 float-left px-0 col-12 mr-1" style={{ "fontSize": "12px", }}><Select
                                            isDisabled={this.state.EmpIDDisable}
                                            id="OLEmplyDD"
                                            name="SelectedEmp"
                                            onChange={(e) => this.OnChangeField("EMP", e)}
                                            value={this.state.SelectedEmp}
                                            options={this.state.EmplyeeNameDD}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    borderColor: this.state.EmployeeErr ? "#FF0000" : "#ced4da",
                                                    boxShadow: (this.state.EmployeeErr) && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                                    "&:hover": {
                                                        ...provided,
                                                        borderColor: this.state.EmployeeErr ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                                    }
                                                }),
                                            }}
                                        />
                                            {this.state.EmployeeErr ? <span className="font-12 font-regular color-validation">
                                                <Trans>Please select the employee ID</Trans>
                                           </span> : null}
                                        </span>


                                    </div>
                                    <div className="col-md-6 mb-4 float-left p-auto p-lg-0" id="MachinenumberDiv">
                                        <label className="col-lg-4 col-md-12 float-left p-0 catalog-label form-label"><Trans>Asset ID</Trans></label>
                                        <div className="col-lg-6 float-left p-0 col-md-12 col-12">
                                            <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                                {this.state.MachineTable.length > 0 ? <span className="col-lg-12 float-left p-0 col-md-12 col-12" style={{ "fontSize": "12px", }}><Select
                                                    id="OLMachDD"
                                                    name="SelectedAssetID"
                                                    maxLength="20"
                                                    onChange={(e) => this.handleDDChange(e, "SelectedAssetID")}
                                                    value={this.state.SelectedAssetID}
                                                    options={this.state.MachineTable}
                                                /></span> : <input type="text" className="form-control" id="OLMachDD" placeholder={t("Enter asset ID")} maxLength="20" value={this.state.AssetId} onChange={(e) => this.OnChangeField("ASSET_ID", e)} />
                                                }
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-md-4 px-3px-1 item-details-value w-100 Mobile-descrip">
                                    <div className="row">
                                        <label className="catalog-label w-100 px-3"><Trans>Customer notes</Trans></label>
                                        <div className="col-md-12">
                                            <textarea className="form-control" maxLength="254" id="CusNotes" value={this.state.CUST_NOTES} onChange={(e) => this.OnChangeField("CUST_NOTES", e)} rows={4} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.WOorCCRequired ? <span className=" W-100 text-left" style={{ fontSize: '13px', fontWeight: 'bold' }}><Trans>* Required field - work order# or charge code is required</Trans></span> : null}
                            {this.state.ERR ? <span className=" W-100 errorspan1  mr-3" style={{ fontSize: '15px', marginTop: '30px' }}><Trans>{this.state.ErrStatement}</Trans></span> : null}

                        </div>

                        {/*<div className="col-md-12 col-sm-auto col-auto text-right my-3">


                                            {this.state.cartArr.length > 5 ?
                                                <div className="col-md-12 col-sm-auto col-auto my-3 text-right">
                                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-2" data-toggle="modal" data-target="#Cancel-btn" data-backdrop="static" data-keyboard="false"><Trans>Cancel</Trans><span> </span><Trans>order_key</Trans></button>
                                                    <button className="btn btn-primary custom-btn-primary primary-btn " onClick={() => this.SubmitValidation()} data-backdrop="static" data-keyboard="false"  ><Trans>Submit</Trans><span> </span><Trans>order_key</Trans></button>
                                                </div> : null}
                                            {this.state.ERR ? <span className=" W-100 errorspan1  mr-3" style={{ fontSize: '15px' }}>{this.state.ErrStatement}</span> : null}
                        </div>*/}
                        {/*</td>*/}

                        <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className="font-16 font-bold mb-0"><Trans>Cart items</Trans></h5>
                                </div>
                                <div>
                                    <button className="btn btn-primary custom-btn-primary primary-btn" data-toggle="modal" data-target="#Add-New-Item" data-backdrop="static" data-keyboard="false"><Trans>+ New item</Trans></button>
                                </div>
                            </div>

                            {this.BindSTKCart()}

                            {this.state.showprice != "0" ? <div className="row mx-0 d-none">
                                <div className="col-md-12">
                                    <div className="float-right shopping-order">
                                        Order Total <span className="price-rate"></span>
                                    </div>
                                </div>
                            </div> : null}

                            {this.state.cartArr.length === 0 && (

                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="row mx-0 mb-1 py-3 item-box">
                                            <div className="col-md-12 text-center">
                                                <img src="images/empty-cart-icon.svg" alt="empty-cart" />
                                                <p className="font-medium font-14 mb-0"><Trans>Your cart is empty !</Trans></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.cartArr.length > 0 ?
                                <div className="col-md-12 col-sm-auto col-auto my-3 text-right">
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-2" data-toggle="modal" data-target="#Cancel-btn" data-backdrop="static" data-keyboard="false"><Trans>Cancel</Trans><span> </span><Trans>Order</Trans></button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn" data-backdrop="static" data-keyboard="false" onClick={() => this.submitValidation()} ><Trans>Submit</Trans><span> </span><Trans>Order</Trans></button>
                                </div> : null}


                        </div>


                        <div className="modal fade add-to-cart pl-0" tabIndex={-1} role="dialog" aria-hidden="true" id="Add-New-Item">
                            <div className="modal-dialog modal-dialog-centered add-to-cart-custom-width" role="document">
                                <div className="modal-content">
                                    {/* Modal Header */}
                                    <div className="modal-header sp-delv-header align-items-center">
                                        <h5 className="modal-title mt-1"><Trans>Add new item to cart</Trans></h5>
                                        <button type="button" className="btn close-btn" data-dismiss="modal"
                                            aria-label="Close" onClick={e => this.CloseButtonClick()}>
                                            X
              </button>
                                    </div>
                                    {/* Modal body */}
                                    <div className="modal-body pt-4 px-4">
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <div className="input-group charge-code-group mb-5">


                                                    <input type="text" className="form-control charge-code-control" placeholder={t("Enter item ID")} maxLength="18" id="NewItemID" onChange={(e) => this.removeSpace(e)} onKeyDown={this.keyPress} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" onClick={() => this.invItemSearch()}>
                                                            <span className="shopping-search-icon" />
                                                        </span>
                                                    </div>

                                                    {this.state.ItemCheck ? <span className=" W-100 errorspan1 text-left mr-3 mt-2" style={{ fontSize: '15px' }}><Trans>The item is already in the cart</Trans></span> : null}
                                                    {this.state.ItemIdCheck ? <span className=" W-100 errorspan1 text-left mr-3 mt-2" style={{ fontSize: '15px' }}><Trans>Please enter item ID</Trans></span> : null}
                                                    {this.state.InvalidItemId ? <span className=" W-100 errorspan1 text-left mr-3 mt-2" style={{ fontSize: '15px' }}><Trans>{this.state.Errormsg}</Trans></span> : null}



                                                </div>

                                                {this.BindNewItemDetail()}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade delete-popup" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered custom-delete-width" role="document">
                                <div className="modal-content py-2">
                                    {/* Modal Header */}
                                    {<div className="modal-header">
                                        <h4 className="modal-title mt-1" />
                                        <button type="button" className="btn close-btn" data-dismiss="modal">
                                            X</button>
                                    </div>}
                                    {/* Modal body */}
                                    <div className="modal-body pb-0">
                                        <div className="row">
                                            {/*<div className="col-md-12 my-4">
                                                    <span className="question-mark-icon delete-confirm-icon" />
                                                </div> */}
                                            <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                                <img className="mb-3" src="images/trash.svg" alt="delete" />
                                                <h5 className="mb-2 font-16 font-bold color-light-black"><Trans>Confirm</Trans></h5>
                                                <p className="mb-1 font-13 font-regular color-light-black"><Trans>Do you want to remove this item ?</Trans></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 d-flex justify-content-center">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>No</Trans></button>
                                        <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={(e) => this.OnChangeField("Remove", e, this.state.RemoveIndex)}  ><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="modal" id="Order-Placed" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                                <div className="modal-content">
                                    {/* Modal Header */}
                                    {/*  <div className="modal-header">
                                            <h4 className="modal-title mt-1" />
                                            <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.OrderPlaced()}>
                                    X
              </button>
                                </div>  * /} 
                                        {/* Modal body */}
                                    <div className="modal-body" style={{ marginRight: '30px', marginLeft: '30px' }}>
                                        <div className="row text-center">
                                            <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                                <img className="mb-3" src="images/tick.svg" alt="tick" />
                                                <p className="mb-1 font-14 font-bold color-light-black"><Trans>You have successfully placed the order!</Trans></p>
                                            </div>


                                            <div className="col-12 mb-4">
                                                {/* <label className="confirm-label d-block"><Trans>You have successfully placed the order!</Trans></label>
                                                    <label className="confirm-content d-block"></label>*/}
                                                <label className="font-14 font-bold d-block"><Trans>Order</Trans><span> </span><Trans>No</Trans><span>:</span><span className="mx-2">{this.state.OrderNo}</span></label>
                                            </div>
                                            <div className="col-md-12 text-center mb-2">
                                                <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={e => this.OrderPDF()} ><Trans>OK</Trans></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal" id="Cancel-confirmation">
                            {this.state.RemoveItemPUp ?
                                <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                                    <div className="modal-content">
                                        {/* Modal Header */}
                                        {/* <div className="modal-header">
                                            <h4 className="modal-title mt-1" />
                                            <button type="button" className="btn close-btn" data-dismiss="modal">
                                                X
              </button>
                                        </div> */}
                                        {/* Modal body */}
                                        <div className="modal-body">
                                            <div className="row text-center">
                                                <div className="col-md-12 my-4">
                                                    <span className="question-mark-icon delete-confirm-icon" />
                                                </div>
                                                <div className="col-12 mb-4">
                                                    <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                                    <label className="confirm-content d-block"><Trans>Do you want to remove this item?</Trans></label>
                                                </div>
                                                <div className="col-md-12 text-center mb-2">
                                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal"><Trans>No</Trans></button>
                                                    <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={(e) => this.OnChangeField("Remove", e, this.state.RemoveIndex)}><Trans>Yes</Trans></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>


                        <div className="modal fade delete-popup" id="Cancel-btn" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered custom-delete-width" role="document">
                                <div className="modal-content py-2" style={{ marginRight: '30px', marginLeft: '30px' }}>
                                    {/* Modal Header 
                                        <div className="modal-header">
                                            <h4 className="modal-title mt-1" />
                                            <button type="button" className="btn close-btn" data-dismiss="modal">
                                                X
              </button>
                                        </div> */}
                                    {/* Modal body */}
                                    <div className="modal-body pb-0">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                <img className="mb-3" src="images/trash.svg" alt="delete" />
                                                <h5 className="mb-2 font-16 font-bold color-light-black"><Trans>Confirm</Trans></h5>
                                                <p className="mb-1 font-13 font-regular color-light-black"><Trans>Are you sure you want to cancel your order?</Trans></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 d-flex justify-content-center">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>No</Trans></button>
                                        <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={(e) => this.CancelOrder()}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal orderstatus-popup" id="pdfPreview" data-backdrop="static" data-keyboard="false">
                            <PDFPreviewComponent key={OrderSuccessCOunt} base64URL={this.state.base64URL} PdfPage="2" pdfTitle={t("Cart Confirm Report")} {...this.props} />
                        </div>
                        <div className="modal" id="Charge-Code-popup" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                                <div className="modal-content">
                                    {/* Modal Header */}
                                    <div className="modal-header">
                                        <h4 className="modal-title mt-1"><Trans>Charge Code</Trans></h4>
                                        <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.handleCancelClick()}>
                                            X
              </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="float-left mb-2" ><Trans>Charge code for user</Trans>{" " + CookieService.getLocalStorageValues("Session_CONAME")}</div>
                                        {/*<div className="float-right TextAlignLast mb-2" ></div>*/}
                                        <div className="row mt-3 w-100" style={{ "height": "500px" }}>
                                            <div className="col-12 edit-desc-field mb-4">
                                                {this.state.PlaceDD1Data.length > 1 || this.state.PlaceDD1Visible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD1Title}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="PlaceDD1"
                                                            placeholder="Select"
                                                            isDisabled={this.state.PlaceDD1_disable}
                                                            className="DropDown-Font-Size"
                                                            value={this.state.PlaceDD1Value}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD1Value")}
                                                            options={this.state.PlaceDD1Data}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.PlaceDD2Data.length > 1 || this.state.PlaceDD2Visible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD2Title}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="PlaceDD2"
                                                            placeholder="Select"
                                                            isDisabled={this.state.PlaceDD2_disable}
                                                            className="DropDown-Font-Size"
                                                            value={this.state.PlaceDD2Value}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD2Value")}
                                                            options={this.state.PlaceDD2Data}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.PlaceDD3Data.length > 1 || this.state.PlaceDD3Visible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD3Title}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="PlaceDD3"
                                                            placeholder="Select"
                                                            isDisabled={this.state.PlaceDD3_disable}
                                                            className="DropDown-Font-Size"
                                                            value={this.state.PlaceDD3Value}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD3Value")}
                                                            options={this.state.PlaceDD3Data}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.dropCD1SegData.length > 1 || this.state.dropCD1SegVisible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD1SegTitle}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="dropCD1Seg"
                                                            placeholder="Select"
                                                            className="DropDown-Font-Size"
                                                            isDisabled={this.state.dropCD1Seg_disable}
                                                            value={this.state.dropCD1SegValue}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "dropCD1SegValue")}
                                                            options={this.state.dropCD1SegData}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.dropCD2SegData.length > 1 || this.state.dropCD2SegVisible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD2SegTitle}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="dropCD2Seg"
                                                            placeholder="Select"
                                                            className="DropDown-Font-Size"
                                                            isDisabled={this.state.dropCD2Seg_disable}
                                                            value={this.state.dropCD2SegValue}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "dropCD2SegValue")}
                                                            options={this.state.dropCD2SegData}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.dropCD3SegData.length > 1 || this.state.dropCD3SegVisible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD3SegTitle}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="dropCD3Seg"
                                                            placeholder="Select"
                                                            isDisabled={this.state.dropCD3Seg_disable}
                                                            className="DropDown-Font-Size"
                                                            value={this.state.dropCD3SegValue}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "dropCD3SegValue")}
                                                            options={this.state.dropCD3SegData}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.dropEmpSegData.length > 1 || this.state.dropEmpSegVisible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCdropEmpSegTitle}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="dropEmpSeg"
                                                            placeholder="Select"
                                                            isDisabled={this.state.dropEmpSeg_disable}
                                                            className="DropDown-Font-Size"
                                                            value={this.state.dropEmpSegValue}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "dropEmpSegValue")}
                                                            options={this.state.dropEmpSegData}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.dropItmSegData.length > 1 || this.state.dropItmSegVisible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCdropItmSegTitle}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="dropItmSeg"
                                                            placeholder="Select"
                                                            className="DropDown-Font-Size"
                                                            isDisabled={this.state.dropItmSeg_disable}
                                                            value={this.state.dropItmSegValue}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "dropItmSegValue")}
                                                            options={this.state.dropItmSegData}
                                                        />
                                                    </div>
                                                </div> : null}
                                                {this.state.dropMchSegData.length > 1 || this.state.dropMchSegVisible == true ? <div className="row mb-3">
                                                    <label className="col-md-5 quick-label"><Trans>{this.state.CCdropMchSegTitle}</Trans></label>
                                                    <div className="col-md-7">
                                                        <Select
                                                            id="dropMchSeg"
                                                            placeholder="Select"
                                                            isDisabled={this.state.dropMchSeg_disable}
                                                            className="DropDown-Font-Size"
                                                            value={this.state.dropMchSegValue}
                                                            onChange={(e) => this.handleChangePlaceDD1(e, "dropMchSegValue")}
                                                            options={this.state.dropMchSegData}
                                                        />
                                                    </div>
                                                </div> : null}

                                            </div>
                                            {/* <div className="col-md-12 text-center mb-2" id="ChargeCodeLabelDiv">
                                                <label id="ChargeCodeLabel">***<Trans>All charge code segments must be selected</Trans>***</label>
                                                <label id="ChargeCodeLabel1" className="d-none" style={{ display: 'inline-block'}}><span>Please select charge code</span></label>
                                            </div> */}
                                            <div className="col-md-12 text-center mb-2 d-none" id="ChargeCodeErrorDiv">
                                                <label id="ChargeCodeErrorLabel"><span>Please select segments by order</span></label>
                                            </div>

                                            <div className="col-md-12 text-center mb-2 d-none" id="ChargeCodeLabelDiv">
                                                <label id="ChargeCodeLabel">***<Trans>All charge code segments must be selected</Trans>***</label>
                                                {/* <div className="d-none" id="ChargeCodeLabel1">
                                                    <label id="ChargeCodeLabel2">
                                                        <span>Please select charge code</span>
                                                    </label>
                                                </div> */}
                                            </div>


                                            <div className="col-md-12 text-center mb-2">
                                                <button className="btn btn-primary custom-btn-primary primary-btn px-4" id="ChargeCodeBtn" onClick={() => this.updateChargeCode()}><Trans>Submit</Trans></button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>

                </div>
            </div>
        );
    };


}
export default withTranslation()(STKIssuesCartComponent);