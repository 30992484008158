import { saveAs } from 'file-saver';
import * as $ from 'jquery';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//[10/29/21, CAS - Safari Browser - Crystal Report Issue, PC_01, Dhevanesam R]
import { isMobile, isSafari } from 'react-device-detect';
//import { Trans } from 'react-i18next';
//[PC_07, 09/09/21, Author - Dhevanesam R]
import { Trans, withTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';
//import { DropdownMultiselectModule } from 'ng2-dropdown-multiselect';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactPaginate from 'react-paginate';
import * as MenuAction from '../actions/MenuAction';
import * as OrderStatusAction from '../actions/OrderStatusAction';
import * as CookieService from '../CookieService';
import SDIConstants, { redirectToItemDetails } from '../SDIConstants';
import OrderStatusStore from '../stores/OrderStatusStore';
import PDFPreviewComponent from './PDFPreviewComponent';
import swal from 'sweetalert';
import Copy from 'copy-to-clipboard';
let currentPagePath;
let searchTxt = "";
let searchTrTxt = "";
let searchTrOption = "";
let FilterPopupBoolean = false;
let searchTrBy = "OrderNo";
let searchTrCriteria = "";
let Addr = "";
let isPostback = false;
let isPrioritySearch = false;
let Session_BUSUNIT = "";
let OrderSuccessCOunt = 0
let radio = "ExactValue";
let radiochange = false;
//[11/26/21, CAS - 31, Dhevanesam R]
class OrderStatusComponent extends Component {
    /**
     * OS_PC_03
     * Declare a “constructor” method with “props” as its parameters.
     */
    constructor(props) {
        super(props);
        this.assignPref = this.assignPref.bind(this);
        this.SavePreferenceClick = this.SavePreferenceClick.bind(this);
        this.Sorting = this.Sorting.bind(this);
        this.FileExport = this.FileExport.bind(this);
        this.data = this.data.bind(this);
        this.ApplyFilter = this.ApplyFilter.bind(this);
        this.PODetail = this.PODetail.bind(this);
        this.ApplyFilterbutton = this.ApplyFilterbutton.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.Approvalhistory = this.Approvalhistory.bind(this);
        this.bindApproveHistoryPopupDetail = this.bindApproveHistoryPopupDetail.bind(this);
        this.clearButtonClick = this.clearButtonClick.bind(this);
        this.addFavItemsToCart = this.addFavItemsToCart.bind(this);
        this.PODetailPopup = this.PODetailPopup.bind(this);
        this.AnykeyPress = this.AnykeyPress.bind(this);
        this.AddedToCart = this.AddedToCart.bind(this);
        this.searchTr = this.searchTr.bind(this);
        this.GetHeaderDetails = this.GetHeaderDetails.bind(this);
        this.GetOrderLineDetails = this.GetOrderLineDetails.bind(this);
        this.PassThroughAutheResponse = this.PassThroughAutheResponse.bind(this);
        //[8/2/2024 - Zeus-317 - Set the records per page value in browser cookies - Shanmugapriya]
        this.handleRecCountChange = this.handleRecCountChange.bind(this);
        this.state = {
            FilterPopupModal: false,
            ApprovalPopup: false,
            ShipmentBOLPopup: false,
            PODetailPopup: false,
            filterDisplayName: "All", STrDisplayName: "Order Number",
            ReportDateField: "X",
            OrderTypeField: "All",
            ReportTypeField: "Site",
            EmpIdField: "",
            BusUnitField: "",
            SearchOption: "ExactValue", isSearch: true,
            sSearchCriteria: "", searchColumn: "All",
            sortType: "DESC",
            //[5/30/22, PC_OS_02, CAS - Order status data pull issue, Dhevanesam R]
            //[8/2/2024 - Zeus-317 - Set the records per page value in browser cookies - Shanmugapriya ]
            sortColumn: "ORD_DATE", PageNo: 1, totalCount: 0, pageCount: 0, totArr: [], checkbox: false, searchAval: false,
            RecCount: CookieService.getCookieValue("RecCount") ? CookieService.getCookieValue("RecCount") : 10,
            BusUnitDropDown: [], CompanyBU: "",
            EmpIdDropDown: [], Emplist: [], EmpListPush: [], OrderStatusDD: [], status: "",
            ThirdPartyBU: [], isFilter: false, isExpand: false, isPOExpand: true,
            SavedPrefTable: [],
            FlagData: [], hiddenFlag: [],
            GridArr: [], GridArr2: [], DetailTable: [], POData: [], VenderDetail: [], shipmentBOL: [], shipmentAddr: [], shipmentpopup: [], usernamedetail: [], packingSlip: [],
            ApprovalhistoryArr: [],
            selectedEmpId: [],
            OFromDate: null, OrderDateBoolean: false, NeedByDateBoolean: false, SearchTrFromDate: "", SearchTrToDate: "", alert: Date,
            OToDate: null,
            buyCheckedItembool: false,
            NFromDate: "", blockprice: CookieService.getLocalStorageValues("Session_ISAPriceBlock"),
            NToDate: null, EmpString: "", showprice: CookieService.getLocalStorageValues("Session_ShowPrice"), EV: true, MN: false, DR: false,
            ApprovalHistoryL: "", POGridArr: [], isFilterOpen: false, isFavorited: false, lblTest: "", lblVersion: "", priorityFlag: 0, base64URL: "", AddressDetails: [],
            HeaderGridArray: [], OrdertotalCount: 0, LineDetailsGrid: [], SearchedTxt: "", CancelItemReasonDrop: "Select",
            IsLineLevel: false, LineNoList: [], BuyAgainArray: [], cancelBtnStatuses: "",
            Pickup_VendorList: [], Pickup_store_tbl: [], OrderStatus: " ", OrderCategory: " ", ApproverData: [], CurrentUserName: "", CurrentUserLimit: "", NextApprover: "", NextApproverLimit: "", ApproverPopupFlag: true,
            ApproverListPopup: [], headerNotes: "", RemainderOrder: "", OrderApprover: "", Alternates: [], OrderedForData: [], RemainderApprover: "", ApproverListUser: "", OLEmployee: "", RemainderLineItems:"",
            orderStatusList:[], orderStatusDD:[],orderStatusSelectedList:[], empIDDDList:[],//02/22/24 - Zeus 274 - intialize the variables - Johnprinto
            //ExactValueRR: false, dateRangeRR:false, MonthRR:false,
            cncReasonNeeded: [], Comments: "", disable_CommentsSave: true, NewOrderNotes: [], UniqueOrderStatus: [], notesModalLbl: "",
            CommentsModalName: ""
        }
    }
    componentWillUnmount() {
        //[21/05/2023 - PS_ZE_118_APP_4 - Johnprinto.D]
        if (this.props.location.hasOwnProperty('state') && this.props.location.state != undefined && this.props.location.state.hasOwnProperty('origin')) {
            if (this.props.location.state.hasOwnProperty('origin')) {
                this.props.location.state.origin = '';
            }
        }
        OrderStatusStore.removeListener('OrdStatusload', this.assignPref);
        //debugger;
        OrderStatusStore.removeListener('SavePreference', this.saveprefresponse);
        OrderStatusStore.removeListener('ApprovalHistory', this.bindApproveHistoryPopupDetail);
        OrderStatusStore.removeListener('POdetail', this.PODetail);
        OrderStatusStore.removeListener('data', this.data);
        OrderStatusStore.removeListener('BuyAgain', this.AddedToCart);
        OrderStatusStore.removeListener('Export', this.FileExport);
        OrderStatusStore.removeListener('OrderDetail', this.OrderDetailRsp);
        OrderStatusStore.removeListener('shipto', this.shiptoRsp);
        OrderStatusStore.removeListener('favpage', this.loadFavPageResponse);
        OrderStatusStore.removeListener('updateFavPage', this.updateFavPageResponse);
        OrderStatusStore.removeListener('HeaderData', this.HeaderDataResponse);
        OrderStatusStore.removeListener('LineData', this.LineDataResponse);
        OrderStatusStore.removeListener('CancelRequest', this.CancelRequestResponse);
        OrderStatusStore.removeListener('PassThroughAuthe', this.PassThroughAutheResponse);
        OrderStatusStore.removeListener('ApproverList', this.approverListResponse);
        OrderStatusStore.removeListener('ApprovalRemainder', this.RemainderResponse);
        OrderStatusStore.removeListener('OrderNotes', this.OrderNotesResp);
    }
    componentDidMount() {
        $("#priorityDiv").hide();
        $("#sFT").hide();
        $("#sdrp").hide();
        $("#OR").hide();
        $("#status-dropdown").hide();
        $("#SearchTrTxt").show();
        Session_BUSUNIT = CookieService.getLocalStorageValues("Session_BUSUNIT");
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        OrderStatusAction.getCurrentFavPage(getFavPageBO);
        debugger
        let OrderStatusPageLoadBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        OrderStatusAction.GetOrderStatusPageLoad(OrderStatusPageLoadBO);
        //debugger;
        OrderStatusStore.on('OrdStatusload', this.assignPref.bind(this));
        //debugger;
        OrderStatusStore.on('SavePreference', this.saveprefresponse);
        OrderStatusStore.on('ApprovalHistory', this.bindApproveHistoryPopupDetail);
        OrderStatusStore.on('POdetail', this.PODetail);
        OrderStatusStore.on('data', this.data);
        OrderStatusStore.on('BuyAgain', this.AddedToCart);
        OrderStatusStore.on('Export', this.FileExport);
        OrderStatusStore.on('OrderDetail', this.OrderDetailRsp);
        OrderStatusStore.on('shipto', this.shiptoRsp);
        OrderStatusStore.on('favpage', this.loadFavPageResponse.bind(this));
        OrderStatusStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
        OrderStatusStore.on('HeaderData', this.HeaderDataResponse);
        OrderStatusStore.on('LineData', this.LineDataResponse);
        OrderStatusStore.on('CancelRequest', this.CancelRequestResponse);
        OrderStatusStore.on('PassThroughAuthe', this.PassThroughAutheResponse);
        OrderStatusStore.on('ApproverList', this.approverListResponse);
        OrderStatusStore.on('ApprovalRemainder', this.RemainderResponse);
        OrderStatusStore.on('OrderNotes', this.OrderNotesResp);
    }

    //[3/27/2024] Z 268 HandleComments() onchange method of comments text to set the target value to Comments state variable - Poornima S
    HandleComments = (e) => {
        let btndisable = this.state.disable_CommentsSave;
        if (this.state.cncReasonNeeded.length > 0) {
            if (e.target.value.trim() != "" && this.state.CancelItemReasonDrop != "Select") {
                btndisable = false;
            }
            else {
                btndisable = true;
            }
        }
        else if (e.target.value.trim() != "") {
            btndisable = false;
        }
        else {
            btndisable = true;
        }
        this.setState({ Comments: e.target.value, disable_CommentsSave: btndisable })
    }

    //[3/27/2024]Z 268 OrderNotesResp() is the response method after gettinotes from backend
    OrderNotesResp = () => {
        const { t } = this.props;
        let OrderNotesResponse = JSON.parse(OrderStatusStore.OrderNotes);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

        if ((OrderNotesResponse.ErrorTable[0].isErrorOccured) && (OrderNotesResponse.ErrorTable[0].isErrorOccured.trim() != "")) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical issue in displaying the notes")
            })
            return;
        }

        let uniqueOrderNotes = OrderNotesResponse.OrderNotes.filter((obj, index, self) => {
            return self.findIndex(item => item.STATUS.substring(0, 2) === obj.STATUS.substring(0, 2)) === index;
        });

        this.setState({ NewOrderNotes: OrderNotesResponse.OrderNotes, UniqueOrderStatus: uniqueOrderNotes })

        $('#OrderNotesModal').modal('show');

    }

    //[3/27/2024]Z 268 closeNotesModal() triggered while clicking close icon and cancel button of order notes modal
    closeNotesModal = () => {
        $('#OrderNotesModal').modal('hide');
        this.setState({ NewOrderNotes: [], UniqueOrderStatus: [] })
    }

    //[4/18/2023]Order status header, line level SP integration and Cancel order changes - Poornima S
    CancelRequestResponse = () => {
        debugger
        const { t } = this.props;
        let Orderlist = this.state.OrderNoList;
        let OrderNo = Orderlist[0];
        let HeaderGridArr = this.state.HeaderGridArray;
        let LineGridArr = this.state.LineDetailsGrid;
        let cancelResponse = JSON.parse(OrderStatusStore.CancelRequest);
        let CancelRequest = cancelResponse[0].result
        let OrderStatus = cancelResponse[0].ORD_STATUS
        if (CancelRequest == "Success") {
            $('#CanceledItemSuccess').modal('show');
            $('.header-arrow').addClass('accordion-arrow-change');
            $('.content-row').removeClass('d-none');
            $('.header-arrow').removeClass('accordion-arrow-change');
            $('.content-row').addClass('d-none');
            if (this.state.IsLineLevel == false) {
                HeaderGridArr.forEach((val) => {

                    if (val.ORDER_NO == OrderNo) {
                        val.ORDER_STATUS = OrderStatus;
                        if (val.ISA_LINE_STATUS != "CRE" && val.ISA_LINE_STATUS != "QTS" && val.ISA_LINE_STATUS != "QTW" && val.ISA_LINE_STATUS != "CNC") {
                            val.ISA_LINE_STATUS = "PNC";
                        }
                        else {
                            val.ISA_LINE_STATUS = "CNC";
                        }
                        //[3/27/2024]Z 268 If notes is inserted successfully while canceling then that notes will be returned in isNotesInserted, assing it to ISA_LINE_NOTES displays Notes icon against that order.
                        if (cancelResponse[0].isNotesInserted) {
                            val.ISA_LINE_NOTES = cancelResponse[0].isNotesInserted;
                        }
                    }
                });
                this.setState({ HeaderGridArray: HeaderGridArr });
            }
            else {
                let LineList = this.state.LineNoList;
                let lineitems = HeaderGridArr.filter((x) => x.ORDER_NO == OrderNo);
                if (lineitems.length == 1) {
                    HeaderGridArr.forEach((val) => {
                        if (val.ORDER_NO == OrderNo && LineList[0].LineNo == val.ISA_INTFC_LN) {
                            val.ORDER_STATUS = OrderStatus;
                            if (val.ISA_LINE_STATUS != "CRE" && val.ISA_LINE_STATUS != "QTS" && val.ISA_LINE_STATUS != "QTW" && val.ISA_LINE_STATUS != "CNC") {
                                val.ISA_LINE_STATUS = "PNC";
                            }
                            else {
                                val.ISA_LINE_STATUS = "CNC";
                            }
                        }
                    });
                }
                else {
                    let FilArrGrid = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == this.state.OrderNoList[0] && x.ISA_INTFC_LN == this.state.LineNoList[0].LineNo);
                    let HeaderArraySplice = [];
                    let OrderfilteredItem = [];
                    let HeaderStatusArr = [];
                    if (FilArrGrid.length > 0) {
                        HeaderGridArr.forEach((itemarray, indexarray) => {
                            if (FilArrGrid[0].ISA_INTFC_LN != itemarray.ISA_INTFC_LN) {
                                HeaderArraySplice.push(itemarray);
                            }
                        });
                    }
                    HeaderArraySplice.forEach((value, index) => {
                        OrderfilteredItem = LineGridArr.filter((x) => x.ORDER_NO == value.ORDER_NO && x.LINE_NBR == value.ISA_INTFC_LN);
                        if (OrderfilteredItem.length > 0) {
                            if (OrderfilteredItem[0].ISA_LINE_STATUS == "CNC" || OrderfilteredItem[0].ISA_LINE_STATUS == "PNC") {
                            }
                            else {
                                HeaderStatusArr.push(value)
                            }
                        }
                    });

                    HeaderGridArr.forEach((val) => {
                        if (val.ORDER_NO == OrderNo && LineList[0].LineNo == val.ISA_INTFC_LN) {
                            val.ORDER_STATUS = OrderStatus;
                            if (val.ISA_LINE_STATUS != "CRE" && val.ISA_LINE_STATUS != "QTS" && val.ISA_LINE_STATUS != "QTW" && val.ISA_LINE_STATUS != "CNC") {
                                val.ISA_LINE_STATUS = "PNC";
                            }
                            else {
                                val.ISA_LINE_STATUS = "CNC";
                            }
                        }
                    });

                }
                this.setState({ HeaderGridArray: HeaderGridArr });
            }
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical issue in sending the mail")
            })
            //alert("Technical issue in sending the mail")
            $('#CanceledItemSuccess').modal('hide');
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
    }

    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(OrderStatusStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        if (OrderStatusStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(OrderStatusStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(OrderStatusStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
        //this.refreshMenu();
    };

    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Check Order Status",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            OrderStatusAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            OrderStatusAction.deleteFavPage(deleteFavPageBO)
        }
    };

    data = () => {
        debugger;
        let PageLoadResponse = JSON.parse(OrderStatusStore.PageLoadData);
        console.log("totArr", PageLoadResponse);
        //$("#priorityDiv").hide();
        let count = PageLoadResponse.Count[0].TOTALCOUNT;
        let pg = Math.ceil((PageLoadResponse.Count[0].TOTALCOUNT > 0 ? PageLoadResponse.Count[0].TOTALCOUNT : 0) / this.state.RecCount);
        //let countno = count[0].COUNT(1);
        console.log("totArr", count);
        let applygrid = false;
        let FlagTable = [];
        //[5/7/23, PC_ZE_85_APP_18, Dhevanesam R]
        let pickup_store_tbl = PageLoadResponse.Pickup_store_tbl.length > 0 ? PageLoadResponse.Pickup_store_tbl : [];
        let pickup_VendorList = PageLoadResponse.Pickup_VendorList.length > 0 ? PageLoadResponse.Pickup_VendorList : [];
        if (PageLoadResponse.ds.length > 0 || this.state.searchAval) {
            applygrid = true;
        }
        if (PageLoadResponse.FlagTable) {
            FlagTable = PageLoadResponse.FlagTable;
        }
        //debugger;
        let total = PageLoadResponse.Count.length > 0 ? PageLoadResponse.Count[0].TOTALCOUNT : 0
        console.log("search", this.state.searchAval)
        let addressArray;
        if (PageLoadResponse.AddressDetails != null && PageLoadResponse.AddressDetails != undefined) {
            addressArray = PageLoadResponse.AddressDetails;
        }

        //To Get Item ID 
        //[5/30/22, PC_OS_02, CAS - Order status data pull issue, Dhevanesam R]
        //if (PageLoadResponse) {
        //    if (PageLoadResponse.ds) {
        //        if (PageLoadResponse.ds.length > 0) {
        //            PageLoadResponse.ds.forEach((item, index) => {
        //                let OrderfilteredItem = [];
        //                if (PageLoadResponse.LineItemIDs)
        //                    OrderfilteredItem = PageLoadResponse.LineItemIDs.filter((x) => x.ORDER_NO == item.ORDER_NO && x.BUSINESS_UNIT_OM == item.BUSINESS_UNIT_OM && x.ISA_INTFC_LN == item.LINE_NBR);
        //                if (OrderfilteredItem.length > 0) {
        //                    item.INV_ITEM_ID = OrderfilteredItem[0].ISA_ITEM;
        //                }
        //            });
        //        }
        //    }
        //}

        //////////
        this.setState({ GridArr: PageLoadResponse.ds, GridArr2: PageLoadResponse.ds2, totalCount: total, pageCount: pg, searchAval: applygrid, isExpand: false, shipmentpopup: FlagTable, AddressDetails: addressArray, OrdertotalCount: 0, Pickup_store_tbl: pickup_store_tbl, Pickup_VendorList: pickup_VendorList });
        // this.setState({ GridArr2: PageLoadResponse.ds2 });
        ////debugger;
        debugger;
        //let search=$('#searchTxt').val();
        if (PageLoadResponse.ds == "") {
            const { t } = this.props;
            if (!FilterPopupBoolean && searchTxt == "") {
                if (!this.state.OrderDateBoolean && !this.state.NeedByDateBoolean) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("no data found")
                    })
                    //alert(t("no data found"));
                }
            }

            console.log("no data");
            //this.setState({ OFromDate: "", OToDate: "", NFromDate: "", NToDate: "", sFromDate: "", sToDate: "", alert: "" });
            this.setState({ alert: "" });
            //_____________________________
            //FilterPopupBoolean = false;
            //_____________________________
        }
        // this.setState({  });
        //// //debugger;
        // this.setState({ pageCount: Math.ceil((PageLoadResponse.Count[0].TOTALCOUNT > 0 ? PageLoadResponse.Count[0].TOTALCOUNT : 0) / this.state.RecCount) });
        //GridArr.Item.forEach((value, index) => {
        //    value.checked = false;
        //});

        //debugger;
    }


    assignPref = () => {
        let PageLoadResponse = JSON.parse(OrderStatusStore.PageLoadData);

        const { t } = this.props;
        if (PageLoadResponse.ErrorTable[0].error == "True") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical issue try again")
            }).then(() => {
                window.location.href = '/HomePage';
            });
            return;
        }
        let environmentDataResponse;
        environmentDataResponse = JSON.parse(localStorage.getItem("SDIEnvironmentData"));
        if (environmentDataResponse.length > 0) {
            environmentDataResponse = environmentDataResponse[0];
        }
        //02/22/24 - Zeus 274 - intialize the variables - Johnprinto
        let statusDD = [];
        let originalStatusDD = [];
        if (PageLoadResponse.Order_Status_Filter != undefined && PageLoadResponse.Order_Status_Filter != null && PageLoadResponse.Order_Status_Filter.length > 0) {
            //02/22/24 - Zeus 274 - Loop the Order status and push the object value to the array to display the order status option - Johnprinto
            PageLoadResponse.Order_Status_Filter.forEach( value => {
                statusDD.push({value: value.MAIN_STATUS,label: value.MAIN_STATUS})
            });
            originalStatusDD = statusDD;
        }
        console.log("buhist", environmentDataResponse);
        let length = [];
        let originalEMP = [];
        length = PageLoadResponse.sisterSites;
        let EMP = [];
        //[5/7/23, PC_ZE_85_APP_18, Dhevanesam R]
        let pickup_store_tbl = PageLoadResponse.Pickup_store_tbl.length > 0 ? PageLoadResponse.Pickup_store_tbl : [];
        let pickup_VendorList = PageLoadResponse.Pickup_VendorList.length > 0 ? PageLoadResponse.Pickup_VendorList : [];
      
        let originalString = "";

        let Thrid = [];
        if (PageLoadResponse.EmpListTable) {
            PageLoadResponse.EmpListTable.map((val, index) => {

                let third = {
                    value: val.ISA_EMPLOYEE_ID,
                    label: val.EMP_TEXT,

                }

                EMP.push(third)
            });
        }
        //02/22/24 - Zeus 274 - Store the original ordered employee drop down value - Johnprinto
        originalEMP = EMP;

        let ReportDate = "X";
        let OrderType = "All";
        let ReportType = "Site";
        let EMPString = "";
        let BU = "";
        //[Zeus-141, 06/26/2023] - Fetching order status save reference data
        //02/22/24 - Zeus 274 - Create a temprory variable value to store the selected order status - Johnprinto
        let tempSelectedStatus = "";
let selectedStatus = [];
        let OrderCategorySP = " ";
        //Array.prototype.push.apply(arr1, arr2);
        if (PageLoadResponse.SavedPrefTable != "") {
            ReportDate = PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL1;
            OrderType = PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL3;
            ReportType = PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL5;
            EMPString = PageLoadResponse.SavedPrefTable[0].ISA_LIST_EMPL_ID;
            tempSelectedStatus = PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL11 && PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL11.trim() && PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL11 != "Select" ? PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL11 : ""; // 4/16/2024 Zeus 274 - Check if select option is in the preference option - Johnprinto D
            OrderCategorySP = PageLoadResponse.SavedPrefTable[0].ISA_ATTRLBL10;


            if (PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL5 == "Company") {
                BU = CookieService.getLocalStorageValues("Session_BUSUNIT");
            }
            else {
                BU = PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL6 != null && PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL6.trim() != "" ? PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL6 : CookieService.getLocalStorageValues("Session_BUSUNIT");
            }

        } else {
            BU = CookieService.getLocalStorageValues("Session_BUSUNIT");
            OrderType = "My";
        }
        let MultiSelectValue = [];
        if (EMPString != " ") {
            let separatedArray = [];
            separatedArray = EMPString.split(',');
            console.log("splice arr", separatedArray);

            separatedArray.map((val, index) => {
                let ChildSiteValue = EMP.filter((item, index) => item.value == val);
                console.log("ChildSiteValue", ChildSiteValue);
                if (ChildSiteValue.length > 0) {
                    let localArray = {
                        value: ChildSiteValue[0].value,
                        label: ChildSiteValue[0].label
                    }
                    MultiSelectValue.push(localArray)
                }
            });
            //02/22/24 - Zeus 274 - Rearrange the employee drop down value like selected option displayed in the begining and rest of the status value - Johnprinto
            EMP = [...MultiSelectValue, ...EMP].filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.value === obj.value
                ))
            );
}
        //02/22/24 - Zeus 274 - Rearrange the status drop down value like selected option displayed in the begining and rest of the status value - Johnprinto
        if(tempSelectedStatus){
            tempSelectedStatus = tempSelectedStatus.split(',');
            tempSelectedStatus.forEach(value => {
                selectedStatus.push({value: value,label: value})
            });
            statusDD = [...selectedStatus,...statusDD].filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.value === obj.value
                ))
            );

        }
        let busUnits = PageLoadResponse.sisterSites.map(item => `'${item.BUSINESS_UNIT}'`).join(',');
        //[Zeus 244, 12/18/2023] - Assigned BU values for Login order status navigation
        let loadBU = "";
        if (ReportType == "Company") {
            loadBU = busUnits;
        }
        else {
            loadBU = PageLoadResponse.SavedPrefTable.length > 0 && PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL6 != null && PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL6.trim() != "" ? "'" + PageLoadResponse.SavedPrefTable[0].ISA_ATTRVAL6 + "'" : "'" + CookieService.getLocalStorageValues("Session_BUSUNIT") + "'";
        }

        let lblVersion = "";
        let lblTest = "";
        let version = "";
        if (environmentDataResponse.length > 0) {
            environmentDataResponse = environmentDataResponse[0];
            lblVersion = environmentDataResponse.lblVersion;
            lblTest = environmentDataResponse.lblTest;
            let ver = [];
            ver = lblVersion.split(' _Z_  :: SUAT :: ');
            version = ver[0] + ver[1];
        }

        //[Zeus-173, 8/8/2023] - Kishore - Fetch Approvers popup data
        let user = "";
        let userLimit = "";
        let approver = "";
        let approverLimit = "";
        let popupFlag = true;
        if (PageLoadResponse.ApproverData != null && PageLoadResponse.ApproverData.length > 0) {
            user = PageLoadResponse.ApproverData[0].CURRENTUSER;
            approver = PageLoadResponse.ApproverData[0].NEXTAPPROVER;
            userLimit = PageLoadResponse.ApproverData[0].CURRENTUSERLIMIT;
            approverLimit = PageLoadResponse.ApproverData[0].NEXTAPPROVERLIMIT;
        }
        else {
            popupFlag = false;
        }

        let workOrder = "";
        let isSrch = true;
        let filterName = "All";
        if (this.props.location.hasOwnProperty('state') && this.props.location.state != undefined && this.props.location.state.hasOwnProperty('origin')) {
            if (this.props.location.state.origin != undefined && this.props.location.state.origin == 'Login') {
                workOrder = this.props.location.state.WorkOrder;
                //[Zeus-140, 06/16/2023]- Workorder filter prefill in Filter search dropdown - Kishore
                if (workOrder != "" && workOrder != null && workOrder != undefined) {
                    OrderType = "All";
                    selectedStatus = [];
                    OrderCategorySP = " ";
                    ReportDate = "X";
                    isSrch = false;
                    filterName = "Work Order #";
                    $(`#ReportDateDD`).prop('disabled', true);
                    $(`#OrderTypeDD`).prop('disabled', true);
                    //$(`#applyfilterid`).prop('disabled', true);
                    //$(`#ReprotTypeDD`).prop('disabled', true);
                    //$(`#BusUnitDD`).prop('disabled', true);
                    $(`#OrderStatus`).prop('disabled', true);
                    $(`#OrderCategory`).prop('disabled', true);
                    //[Zeus 244, 12/18/2023] - Assigned BU values for Login order status navigation
                    this.GetHeaderDetails(false, workOrder, false, "All", "ISA_WORK_ORDER_NO", loadBU);
                }
                else {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                }
            }
            else {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
            }
        }
        else {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        //[7/12/2023, PS_ZE_142_APP_20, Dhevanesam R]
        MenuAction.refreshMasterData()
        this.setState({
            BusUnitDropDown: PageLoadResponse.sisterSites,
            Pickup_store_tbl: pickup_store_tbl, Pickup_VendorList: pickup_VendorList, ReportDateField: ReportDate,
            OrderTypeField: OrderType, ReportTypeField: ReportType, EmpString: EMPString, CompanyBU: busUnits,
            BusUnitField: BU, searchAval: false, lblTest: lblTest, lblVersion: version, SearchedTxt: workOrder, isSearch: isSrch,
            EmpListPush: MultiSelectValue, EmpIdDropDown: EMP, empIDDDList : originalEMP, filterDisplayName: filterName, orderStatusList: originalStatusDD, orderStatusDD: statusDD, orderStatusSelectedList: selectedStatus, OrderCategory: OrderCategorySP,
            CurrentUserName: user, CurrentUserLimit: userLimit, NextApprover: approver, NextApproverLimit: approverLimit, ApproverPopupFlag: popupFlag
        });



    }

    OrderDetailRsp = () => {
        const { t } = this.props;
        let PageLoadResponse = OrderStatusStore.Excel;
        let PDFCssVal = "";
        //debugger;
        OrderSuccessCOunt = OrderSuccessCOunt + 1
        //$('#pdfPreview').modal('show');        
        console.log("OrderDetailRsp", PageLoadResponse);
        //[10/29/21, CAS - Safari Browser - Crystal Report Issue, PC_03, Dhevanesam R]
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        debugger
        //[3/14/22, CAS - Safari Browser - Crystal Report Issue, PC_05, Dhevanesam R]
        if (isSafari || isMobile) {
            debugger;
            let sliceSize = 512
            const byteCharacters = atob(PageLoadResponse.split(',')[1]);
            console.log("byteCharactersord", byteCharacters)
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);

            }
            console.log("byteArraysord", byteArrays)
            const blob = new Blob(byteArrays, { type: "application/pdf" });
            console.log("blobord", blob)
            saveAs(blob, "SDIOrderStatusReport.pdf");
            console.log("downloaded")
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("The report is downloaded successfully")
            })
            //alert("The report is downloaded successfully")
            PDFCssVal = "PDFCssDisplayNone"
        }
        else {
            PDFCssVal = "modal" + " " + "orderstatus-popup" + " " + "PDFCssDisplayBlock"
            $('#pdfPreview').modal('show');
            this.setState({
                base64URL: PageLoadResponse
            })
        }
        //let win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1005,height=500,top=" + (window.screen.height - 200) + ",left=" + (window.screen.width - 300));
        //win.document.body.innerHTML = '<iframe width = "100%" height = "100%" src = "' + PageLoadResponse + '"></iframe>';  
        this.setState({ PDFCss: PDFCssVal });
    }


    AddedToCart = () => {
        const { t } = this.props;
        let addFavOrderdataRes = JSON.parse(OrderStatusStore.cartOrderSuccess);
        if (addFavOrderdataRes) {
            if (addFavOrderdataRes.Table1.length > 0) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Item added to cart successfully")
                })
                //alert(t("Item added to cart successfully"));
                addFavOrderdataRes = addFavOrderdataRes.Table1[0];

                CookieService.updateLocalStorageValues("Session_CARTFAIL", addFavOrderdataRes.Session_CARTFAIL);
                //$(`#${SDIConstants.SDILoader}`).addClass('hide');

                this.RedirectTo("/shoppingcart");
            }
        }
    }

    RedirectTo = (pageName) => {
        let queryParamindex = pageName.indexOf("?");
        let pagePath = pageName;
        let queryParams = "";
        if (queryParamindex > 0) {
            pagePath = pageName.substring(0, queryParamindex);
            queryParams = pageName.substring(queryParamindex, pageName.length);
        };

        //event.preventDefault();
        this.props.history.push({
            pathname: pagePath,
            search: queryParams
        });
    };

    FileExport = () => {
        let addFavOrderdataRes = JSON.parse(OrderStatusStore.Excel);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //debugger;
        //alert("file downloaded");
    }
    shiptoRsp = () => {
        let PageLoadResponse = JSON.parse(OrderStatusStore.Excel);
        if (PageLoadResponse) {
            if (PageLoadResponse.TotalData.length > 0) {
                this.setState({ shipmentBOL: PageLoadResponse.TotalData, shipmentAddr: PageLoadResponse.ResultanString });
                $('#shipmentBOL').modal('show');
            }
            if (PageLoadResponse.usernamedetail.length > 0) {


                this.setState({ usernamedetail: PageLoadResponse.usernamedetail });
                $('#shipmentpopup').modal('show');


            }
        }

    }
    PODetail = () => {
        let PageLoadResponse = JSON.parse(OrderStatusStore.PODetailData);
        this.setState({ DetailTable: PageLoadResponse.DetailTable, VenderDetail: PageLoadResponse.VenderDetail, POData: PageLoadResponse.POData, packingSlip: PageLoadResponse.packingSlip });
        $('po-details').modal('show');
        $('.tryH').removeClass('accordion-arrow-change');
        $('.try').addClass('d-none');
        $('.tryH').addClass('accordion-arrow-change');
        $('.try').removeClass('d-none');
        //this.setState({ PODetailPopup: true });
        console.log("potable", PageLoadResponse.POData);
    }
    //****************Apply Filter button click*********************************
    validate = () => {

        let alertdate = "";

        if (this.state.SearchTrFromDate && this.state.SearchTrToDate) {
            if (this.state.SearchTrFromDate > this.state.SearchTrToDate) {
                alertdate = "start date should not be greater then end date";
                this.setState({ alert: alertdate });
                return false;
            }
            else {
                return true;
            }
        }
        return true;
    }
    date = () => {
        let sFD = new Date();
        let sTD = new Date();

        if (this.state.SearchTrFromDate == "" && this.state.SearchTrToDate == "") {
            let today = new Date();
            let before = new Date();
            console.log("today", today);
            //from = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
            sTD = ((today.getMonth() > 8) ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '/' + ((today.getDate() > 9) ? today.getDate() : ('0' + today.getDate())) + '/' + today.getFullYear()
            //before=this.handledate(from);
            before.setMonth(today.getMonth() - 6);
            sFD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            //debugger;

        }
        else if (this.state.SearchTrFromDate == "" && this.state.SearchTrToDate != "") {
            let TrT = new Date(this.state.SearchTrToDate);
            let before = new Date();

            //debugger;
            before.setMonth(TrT.getMonth() - 3);
            sFD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            sTD = ((TrT.getMonth() > 8) ? (TrT.getMonth() + 1) : ('0' + (TrT.getMonth() + 1))) + '/' + ((TrT.getDate() > 9) ? TrT.getDate() : ('0' + TrT.getDate())) + '/' + TrT.getFullYear()

            //debugger;
        }
        else if (this.state.SearchTrFromDate && !this.state.SearchTrToDate) {

            let TrF = new Date(this.state.SearchTrFromDate);
            let before = new Date();
            //TrF = this.state.SearchTrFromDate;
            //debugger;
            before.setMonth(TrF.getMonth() + 3);
            sTD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            //debugger;
            sFD = ((TrF.getMonth() > 8) ? (TrF.getMonth() + 1) : ('0' + (TrF.getMonth() + 1))) + '/' + ((TrF.getDate() > 9) ? TrF.getDate() : ('0' + TrF.getDate())) + '/' + TrF.getFullYear()
        }
        else {
            let TrF = new Date(this.state.SearchTrFromDate);
            let TrT = new Date(this.state.SearchTrToDate);
            let before = new Date();
            let check = new Date();
            let checkT = new Date();
            //TrF = this.state.SearchTrFromDate;
            //debugger;
            before.setMonth(TrF.getMonth() + 3);
            checkT = ((TrT.getMonth() > 8) ? (TrT.getMonth() + 1) : ('0' + (TrT.getMonth() + 1))) + '/' + ((TrT.getDate() > 9) ? TrT.getDate() : ('0' + TrT.getDate())) + '/' + TrT.getFullYear()

            check = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            //debugger;
            if (checkT <= check) {
                sFD = this.state.SearchTrFromDate;
                sTD = this.state.SearchTrToDate;
            }
            //sFD = ((TrF.getMonth() > 8) ? (TrF.getMonth() + 1) : ('0' + (TrF.getMonth() + 1))) + '/' + ((TrF.getDate() > 9) ? TrF.getDate() : ('0' + TrF.getDate())) + '/' + TrF.getFullYear()
            else {
                this.setState({ SearchTrFromDate: "", SearchTrToDate: "", alert: "" });
            }
            //debugger;
            //this.setState({ SearchTrFromDate: "", SearchTrToDate: "", alert: "" });
        }
        return [sFD, sTD];
    }
    abovemonth = () => {
        const { t } = this.props;
        let sFD = new Date();
        let sTD = new Date();
        let alertsearchdate = "";
        let monthRag = 3;
        debugger;
        /*if (this.state.SearchOption == "ExactValue") */
        if (radio == "ExactValue" || radio == "dateRange") {
            monthRag = 3;
        }
        else if (radio == "6month") {
            monthRag = 6
        }
        if (this.state.SearchTrFromDate == "" && this.state.SearchTrToDate != "") {
            let TrT = new Date(this.state.SearchTrToDate);
            let before = new Date();

            debugger;
            before.setMonth(TrT.getMonth() - monthRag);
            sFD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            sTD = ((TrT.getMonth() > 8) ? (TrT.getMonth() + 1) : ('0' + (TrT.getMonth() + 1))) + '/' + ((TrT.getDate() > 9) ? TrT.getDate() : ('0' + TrT.getDate())) + '/' + TrT.getFullYear()

            //debugger;
        }
        else if (this.state.SearchTrFromDate && !this.state.SearchTrToDate) {

            let TrF = new Date(this.state.SearchTrFromDate);
            let before = new Date();
            //TrF = this.state.SearchTrFromDate;
            //debugger;
            before.setMonth(TrF.getMonth() + monthRag);
            sTD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            //debugger;
            sFD = ((TrF.getMonth() > 8) ? (TrF.getMonth() + 1) : ('0' + (TrF.getMonth() + 1))) + '/' + ((TrF.getDate() > 9) ? TrF.getDate() : ('0' + TrF.getDate())) + '/' + TrF.getFullYear()
        }
        else if (!this.state.SearchTrFromDate && !this.state.SearchTrToDate) {
            let today = new Date();
            let before = new Date();
            console.log("today", today);
            //from = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
            sTD = ((today.getMonth() > 8) ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '/' + ((today.getDate() > 9) ? today.getDate() : ('0' + today.getDate())) + '/' + today.getFullYear()
            //before=this.handledate(from);
            before.setMonth(today.getMonth() - monthRag);
            sFD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            //debugger;

        }

        else {
            if (!radiochange) {
                let TrF = new Date(this.state.SearchTrFromDate);
                let TrT = new Date(this.state.SearchTrToDate);
                //let TrF = this.handledate(this.state.SearchTrFromDate);
                //let TrT =  this.handledate(this.state.SearchTrToDate) ;
                let before = new Date();
                let check = new Date();
                let checkT = new Date();
                let checkF = new Date();
                let today = new Date();
                let to = new Date();
                //TrF = this.state.SearchTrFromDate;
                debugger;
                console.log("TrF", TrF);
                console.log("TrT", TrT);
                before.setMonth(TrF.getMonth() + monthRag);
                to = ((today.getMonth() > 8) ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '/' + ((today.getDate() > 9) ? today.getDate() : ('0' + today.getDate())) + '/' + today.getFullYear()

                checkT = ((TrT.getMonth() > 8) ? (TrT.getMonth() + 1) : ('0' + (TrT.getMonth() + 1))) + '/' + ((TrT.getDate() > 9) ? TrT.getDate() : ('0' + TrT.getDate())) + '/' + TrT.getFullYear()
                checkF = ((TrF.getMonth() > 8) ? (TrF.getMonth() + 1) : ('0' + (TrF.getMonth() + 1))) + '/' + ((TrF.getDate() > 9) ? TrF.getDate() : ('0' + TrF.getDate())) + '/' + TrF.getFullYear()

                check = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
                debugger;
                let months;
                months = (new Date(this.state.SearchTrToDate).getDate() - new Date(this.state.SearchTrFromDate).getDate()) / 30 +
                    new Date(this.state.SearchTrToDate).getMonth() - new Date(this.state.SearchTrFromDate).getMonth() +
                    (12 * (new Date(this.state.SearchTrToDate).getFullYear() - new Date(this.state.SearchTrFromDate).getFullYear()));

                //let diff = (TrT.getTime() - TrF.getTime()) / 1000;
                //    diff /= (60 * 60 * 24 * 7 * 4);
                //    //return Math.abs(Math.round(diff));
                //console.log("diff", Math.round(diff));
                if (months <= 3) {
                    sFD = this.handledate(this.state.SearchTrFromDate);
                    sTD = this.handledate(this.state.SearchTrToDate);
                }
                //if (checkT <= check) {
                //    if (checkT <= sTD) {
                //        sFD = this.handledate(this.state.SearchTrFromDate);
                //        sTD = this.handledate(this.state.SearchTrToDate);
                //    }
                //    else {
                //        sFD = this.handledate(this.state.SearchTrFromDate);
                //        sTD = to
                //    }
                //}

                else {
                    sFD = ""; sTD = ""; alertsearchdate = "date should be within 3 month";
                    this.setState({ SearchTrFromDate: "", SearchTrToDate: "", alert: "date should be within 3 month" });
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Date range should be within 3 months")
                    })
                    //alert(t("Date range should be within 3 months"));
                    //return false;
                }
            }
            else {
                let today = new Date();
                let before = new Date();
                console.log("today", today);
                //from = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
                sTD = ((today.getMonth() > 8) ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '/' + ((today.getDate() > 9) ? today.getDate() : ('0' + today.getDate())) + '/' + today.getFullYear()
                //before=this.handledate(from);
                before.setMonth(today.getMonth() - monthRag);
                sFD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
                //debugger;
                this.setState({ SearchTrFromDate: sFD, SearchTrToDate: sTD, alert: "" });
            }
            //sFD = ((TrF.getMonth() > 8) ? (TrF.getMonth() + 1) : ('0' + (TrF.getMonth() + 1))) + '/' + ((TrF.getDate() > 9) ? TrF.getDate() : ('0' + TrF.getDate())) + '/' + TrF.getFullYear()

            //debugger;
            //this.setState({ SearchTrFromDate: "", SearchTrToDate: "", alert: "" });
        }
        return [sFD, sTD, alertsearchdate];
    }
    exactvalue = () => {
        let sFD = new Date();
        let sTD = new Date();

        if (this.state.SearchTrFromDate == "" && this.state.SearchTrToDate == "") {
            let today = new Date();
            let before = new Date();
            console.log("today", today);
            //from = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
            sTD = ((today.getMonth() > 8) ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '/' + ((today.getDate() > 9) ? today.getDate() : ('0' + today.getDate())) + '/' + today.getFullYear()
            //before=this.handledate(from);
            before.setMonth(today.getMonth() - 6);
            sFD = ((before.getMonth() > 8) ? (before.getMonth() + 1) : ('0' + (before.getMonth() + 1))) + '/' + ((before.getDate() > 9) ? before.getDate() : ('0' + before.getDate())) + '/' + before.getFullYear()
            //debugger;

        }
        return [sFD, sTD];
    }

    //[8/2/2024 - Zeus-317 - Set the records per page value in browser cookies - Shanmugapriya]
    handleRecCountChange = (event) => {
        const newRecCount = parseInt(event.target.value);
        let totalItems = this.state.totalCount;
        let pg = Math.ceil(totalItems / newRecCount);
        this.setState({ RecCount: newRecCount, PageNo: 1, pageCount: pg });
        CookieService.setCookieValues("RecCount", newRecCount, 50 * 365);
    }

    ApplyFilter = (pg, RecCount, searchColumn, searchTxt, sTrBy, sTrCriteria, sortColumn, sortType, FilterPopupBoolean) => {
        debugger
        const { t } = this.props;
        let isvalid = this.validate();
        if (isvalid) {
            let BusUnits = "";
            let OFD = "";
            let OTD = "";
            let NFD = "";
            let NTD = "";
            let sFD = "";
            let sTD = "";
            let from = new Date();
            let to = new Date();
            let TotalBu = [];
            let ReportTypeField = "";
            let srTxt = searchTxt;
            let srCol = searchColumn;
            TotalBu = this.state.CompanyBU;
            let alertLocal = "";
            //debugger;
            if (srTxt == "") {
                srCol = "";
            }
            //let lblTest = "";
            //let lblVersion = "";

            //lblTest = environmentDataResponse.lblTest;
            //lblVersion = environmentDataResponse.lblVersion;
            debugger;
            console.log("srTxt", srTxt);
            if (TotalBu != "") {
                debugger;
                console.log("BusUnits1", CookieService.getLocalStorageValues("Session_BUSUNIT"));
                if (this.state.ReportTypeField == "Site") {
                    if (this.state.BusUnitField == " ") {
                        BusUnits = CookieService.getLocalStorageValues("Session_BUSUNIT");
                        console.log("BusUnits", BusUnits);
                        ReportTypeField = "Site";
                    }
                    else {
                        //[5/30/22, PC_OS_02, CAS - Order status data pull issue, Dhevanesam R]
                        if (this.state.BusUnitField != null && this.state.BusUnitField != "") {
                            BusUnits = this.state.BusUnitField;
                        }
                        else {
                            BusUnits = CookieService.getLocalStorageValues("Session_BUSUNIT");
                        }

                        ReportTypeField = this.state.ReportTypeField;
                    }

                }
                else if (this.state.ReportTypeField == "Company") {
                    BusUnits = this.state.CompanyBU;
                    ReportTypeField = this.state.ReportTypeField;
                }
                else {
                    ReportTypeField = "Site";
                    BusUnits = CookieService.getLocalStorageValues("Session_BUSUNIT");
                }

            }
            else {
                //if (this.state.ReportTypeField == "Site") {
                //    BusUnits = CookieService.getLocalStorageValues("Session_BUSUNIT");
                //}
                //if (this.state.ReportTypeField == "Company") {

                //    BusUnits = "'" + CookieService.getLocalStorageValues("Session_BUSUNIT") + "'";
                //}
                ReportTypeField = "Site";
                BusUnits = CookieService.getLocalStorageValues("Session_BUSUNIT");
                console.log("BusUnits", BusUnits);
            }
            console.log("BusUnits", BusUnits);
            debugger;
            if (FilterPopupBoolean) {
                if (this.state.OrderDateBoolean) {
                    //OFD = this.state.OFromDate;
                    //OTD = this.state.OToDate;
                    OFD = this.state.OFromDate ? this.handledate(this.state.OFromDate) : "";
                    OTD = this.state.OToDate ? this.handledate(this.state.OToDate) : "";
                    //document.getElementById("searchTxt").innerText = "";
                    //$('#searchTxt').val('');
                    //srTxt = "";
                    //srCol = "";
                }
                if (this.state.NeedByDateBoolean) {
                    //NFD = this.state.NFromDate;
                    //NTD = this.state.NToDate;
                    NFD = this.state.NFromDate ? this.handledate(this.state.NFromDate) : "";
                    NTD = this.state.NToDate ? this.handledate(this.state.NToDate) : "";
                    //document.getElementById("searchTxt").innerText = "";
                    //$('#searchTxt').val('');
                    //srTxt = "";
                    //srCol = "";
                }
            }
            //debugger;
            console.log("f", this.state.SearchTrFromDate);
            console.log("t", this.state.SearchTrToDate);
            console.log("SearchOption", this.state.SearchOption);
            debugger;
            if (this.state.ReportDateField == "99") {
                if (this.state.SearchTrFromDate && this.state.SearchTrToDate) {
                    if (searchTrBy == "PriorityOrders" || searchTrBy == "OrderStatus" || this.state.DR) {
                        //let diff = (this.state.SearchTrToDate.getTime() - this.state.SearchTrFromDate.getTime()) / 1000;
                        //diff /= (60 * 60 * 24 * 7 * 4);
                        ////return Math.abs(Math.round(diff));
                        //console.log("diff", Math.abs(Math.round(diff)));
                        let months;
                        months = (new Date(this.state.SearchTrToDate).getDate() - new Date(this.state.SearchTrFromDate).getDate()) / 30 +
                            new Date(this.state.SearchTrToDate).getMonth() - new Date(this.state.SearchTrFromDate).getMonth() +
                            (12 * (new Date(this.state.SearchTrToDate).getFullYear() - new Date(this.state.SearchTrFromDate).getFullYear()));

                        if (months <= 3) {
                            sFD = this.state.SearchTrFromDate ? this.handledate(this.state.SearchTrFromDate) : "";
                            sTD = this.state.SearchTrToDate ? this.handledate(this.state.SearchTrToDate) : "";
                        }
                        else {
                            swal({
                                allowOutsideClick: false,
                                closeOnClickOutside: false,
                                text: t("Date range should be within 3 months")
                            })
                            //alert(t("Date range should be within 3 months"));
                            return;
                        }
                    }
                    else if (searchTrCriteria == "") {
                        let months;
                        months = (new Date(this.state.SearchTrToDate).getDate() - new Date(this.state.SearchTrFromDate).getDate()) / 30 +
                            new Date(this.state.SearchTrToDate).getMonth() - new Date(this.state.SearchTrFromDate).getMonth() +
                            (12 * (new Date(this.state.SearchTrToDate).getFullYear() - new Date(this.state.SearchTrFromDate).getFullYear()));


                        console.log("month", months);
                        if (months <= 3) {
                            sFD = this.state.SearchTrFromDate ? this.handledate(this.state.SearchTrFromDate) : "";
                            sTD = this.state.SearchTrToDate ? this.handledate(this.state.SearchTrToDate) : "";
                        }

                        else {
                            swal({
                                allowOutsideClick: false,
                                closeOnClickOutside: false,
                                text: t("Date range should be within 3 months")
                            })
                            //alert(t("Date range should be within 3 months"));
                            return;
                        }
                    }
                    else if (searchTrCriteria != "") {
                        //if ((radio == "6month" || radio == "ExactValue"))
                        let EXV;
                        let MNT;
                        let DTR;
                        if ((this.state.MN || this.state.EV)) {
                            swal({
                                allowOutsideClick: false,
                                closeOnClickOutside: false,
                                text: t("Select date range or search by criteria")
                            })
                            //alert(t("Select date range or search by criteria"));
                            searchTrCriteria = "";
                            if (searchTrBy == "OrderStatus") {
                                $("#status-dropdown").val('select');
                            }
                            else if (searchTrBy == "PriorityOrders") {
                                $("#SearchTrTxt").val('Priority');
                                searchTrCriteria = "Priority";
                            }
                            else {
                                $('#SearchTrTxt').val('');
                                radio = "ExactValue"

                                $('#SearchTrTxt').val('');
                                $("#status-dropdown").hide();
                                $("#SearchTrTxt").show();
                                searchTrCriteria = "";
                                radio = "ExactValue"
                                this.setState({});
                                EXV = true;
                                MNT = false;
                                DTR = false;
                                //document.getElementById("exval").checked = true;                        
                                $("#6month").attr('disabled', false);
                                $("#dateRag").attr('disabled', false);
                                $("#exval").attr('disabled', false);
                                //this.setState({ SearchTrFromDate: "", SearchTrToDate: "" });
                            }

                            this.setState({ SearchTrFromDate: "", SearchTrToDate: "", EV: EXV, MN: MNT, DR: DTR });
                            radiochange = false;

                            return;
                        }
                    }
                }
                else if (this.state.SearchTrFromDate || this.state.SearchTrToDate) {
                    let EXV;
                    let MNT;
                    let DTR;
                    if (searchTrCriteria != "" || (this.state.MN || this.state.EV)) {
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: t("Select date range or search by criteria")
                        })
                        //alert(t("Select date range or search by criteria"));
                        searchTrCriteria = "";
                        if (searchTrBy == "OrderStatus") {
                            $("#status-dropdown").val('select');
                        }
                        else if (searchTrBy == "PriorityOrders") {
                            $("#SearchTrTxt").val('Priority');
                            searchTrCriteria = "Priority";
                        }
                        else {
                            $('#SearchTrTxt').val('');
                            radio = "ExactValue"

                            $('#SearchTrTxt').val('');
                            $("#status-dropdown").hide();
                            $("#SearchTrTxt").show();
                            searchTrCriteria = "";
                            radio = "ExactValue"
                            this.setState({});
                            EXV = true;
                            MNT = false;
                            DTR = false;
                            //document.getElementById("exval").checked = true;                        
                            $("#6month").attr('disabled', false);
                            $("#dateRag").attr('disabled', false);
                            $("#exval").attr('disabled', false);
                        }

                        this.setState({ SearchTrFromDate: "", SearchTrToDate: "", EV: EXV, MN: MNT, DR: DTR });
                        radiochange = false;

                        return;
                    }
                }
                else {
                    let val = this.abovemonth();

                    sFD = val[0];
                    sTD = val[1];
                    alertLocal = val[2];
                    console.log("sFD", sFD);
                    console.log("sTD", sTD);

                    if (alertLocal != "") {
                        return;
                    }
                }
                //if (searchTrBy != "" && searchTrCriteria != "") {
                //    if (this.state.SearchOption == "ExactValue" || this.state.SearchOption == "6month") {
                //        if (this.state.SearchTrFromDate == "" || this.state.SearchTrToDate == "") {
                //            let val = this.abovemonth();
                //            sFD = val[0];
                //            sTD = val[1];
                //            console.log("sFD", sFD);
                //            console.log("sTD", sTD);
                //        }

                //        let val = this.abovemonth();
                //        sFD = val[0];
                //        sTD = val[1];
                //    }
                //    else {
                //        let val = this.abovemonth();
                //        sFD = val[0];
                //        sTD = val[1];
                //    }

                //}
                //else {
                //    let val = this.abovemonth();
                //    sFD = val[0];
                //    sTD = val[1];
                //}
            }

            console.log("list", this.state.EmpListPush);
            let Emplist = []
            if (this.state.EmpListPush.length > 0) {
                this.state.EmpListPush.map((val, index) => {
                    Emplist.push({ EmpId: val.value })
                });
            }
            console.log("emplist", Emplist);

            console.log("from", CookieService.getLocalStorageValues("Session_UserID"));
            //debugger;
            let OrderStatusDataBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),


                //eRptTimePeriod: "99", 
                eRptSite: ReportTypeField,
                sApprovalReport: "",
                eSearchOption: this.state.SearchOption,
                eSearchBy: srCol,
                strLocations: "",
                sApprovalsBU: BusUnits,
                sBusUnits: BusUnits,
                bShopCartSPC1: false,
                sSearchCriteria: srTxt,
                eRptTimePeriod: this.state.ReportDateField,
                sFromDate: sFD, searchTrBy: searchTrBy, searchTrCriteria: searchTrCriteria,
                sToDate: sTD,
                eOrdersToInclude: this.state.OrderTypeField,
                sSortField: sortColumn,
                sortType: sortType,
                sApprovalReport: "",
                sGroupList: Emplist,
                CurrentPage: pg,
                NeededRecCount: RecCount,
                FilterSearch: FilterPopupBoolean, FilterOrdDate: this.state.OrderDateBoolean, OFromDate: OFD, OToDate: OTD, FilterNeedDate: this.state.NeedByDateBoolean, NFromDate: NFD, NToDate: NTD,
                bUnlimitedSearch: false, sQueryUnlimitedResults: ""

            }

            console.log("searchTrCriteria", searchTrCriteria);
            //debugger;
            if (pg > 0) {
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                $('.header-arrow').addClass('accordion-arrow-change');
                $('.content-row').removeClass('d-none');
                $('.header-arrow').removeClass('accordion-arrow-change');
                $('.content-row').addClass('d-none');
                OrderStatusAction.RetriveData(OrderStatusDataBO);
            }
            else {
                //debugger;
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                OrderStatusAction.ExportData(OrderStatusDataBO);
            }
        }
    }

    ExportToExcel = () => {
        this.GetHeaderDetails(false, this.state.SearchedTxt, true, this.state.OrderTypeField, "")
    }
    searchTr = () => {
        const { t } = this.props;
        if (this.state.ReportDateField == "99") {
            debugger;
            if (searchTrCriteria == "select") {
                if (searchTrBy == "OrderStatus") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("select Order status")
                    })
                    //alert(t("select Order status"));
                    return false;
                }
            }

            if ((searchTrCriteria == "")) {

                if (searchTrBy == "OrderStatus") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("select Order status")
                    })
                    //alert(t("select Order status"));
                    return false;
                }

                else if ((!this.state.SearchTrFromDate) && (!this.state.SearchTrToDate)) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("select any criteria")
                    })
                    //alert(t("select any criteria"));
                    return false;
                }
                else if ((!this.state.SearchTrFromDate && this.state.SearchTrToDate)) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("invalid from date")
                    })
                    //alert(t("invalid from date"));
                    return false;
                }
                else if ((this.state.SearchTrFromDate && !this.state.SearchTrToDate)) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("invalid to date")
                    })
                    //alert(t("invalid to date"));
                    return false;
                }

                else {
                    if (radiochange) {
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: t("select any criteria")
                        })
                        // alert(t("select any criteria"));
                        return false;
                    }
                    else {
                        return true;
                        //this.ApplyFilterbutton();
                    }

                }

            }

            else if ($('#dateRag').is(':checked')) {
                if (!this.state.SearchTrFromDate && !this.state.SearchTrToDate) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Please select From and To Date")
                    })
                    //alert(t("Please select From and To Date"));
                    this.setState({ alert: "" });
                    return false;
                }
                else if ((!this.state.SearchTrFromDate) && (!this.state.SearchTrToDate)) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("select any criteria")
                    })
                    //alert(t("select any criteria"));
                    return false;
                }
                else if ((!this.state.SearchTrFromDate && this.state.SearchTrToDate)) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("invalid from date")
                    })
                    //alert(t("invalid from date"));
                    return false;
                }
                else if ((this.state.SearchTrFromDate && !this.state.SearchTrToDate)) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("invalid to date")
                    })
                    //alert(t("invalid to date"));
                    return false;
                }
                else if (searchTrCriteria == "") {
                    //($('#SearchTrTxt').val() == "")
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Please enter search criteria")
                    })
                    //alert(t("Please enter search criteria"));
                    return false;
                }
                else {
                    return true;
                    //this.ApplyFilterbutton();
                }

            }
            //else if (searchTrBy == "OrderStatus") {
            //    if (searchTrCriteria == "0") {
            //        alert("please select Order Status");
            //    }
            //}

            else {
                return true;
                // this.ApplyFilterbutton();
            }
        }

        else {
            return true;
            //this.ApplyFilterbutton();
        }
    }
    ApplyFilerCheckforgroup = () => {
        debugger;
        const { t } = this.props;
        //let criteria = $(`#SearchTrTxt`).val().trim();
        console.log("criteria", searchTrCriteria);
        console.log("ApplyFilerCheckforgroup from", this.state.SearchTrFromDate);
        console.log("ApplyFilerCheckforgroup to", this.state.SearchTrToDate);

        if (this.state.ReportDateField != "X") {
            if (this.state.OrderTypeField != "Select") {
                if (this.state.ReportTypeField != "Select") {
                    if (this.state.BusUnitField != "Select") {

                        if (this.state.OrderTypeField == "Group") {
                            if (this.state.EmpListPush.length > 0) {
                                if (this.searchTr()) {
                                    this.ApplyFilterbutton();
                                }

                            }
                            else {
                                swal({
                                    allowOutsideClick: false,
                                    closeOnClickOutside: false,
                                    text: t("Please select the employee ID")
                                })
                                //alert(t("Please select the employee ID"));
                            }
                        }


                        else if (this.state.ReportDateField == "99") {
                            if (this.searchTr()) {
                                this.ApplyFilterbutton();
                            }
                        }
                        else if (this.state.ReportDateField != "99") {

                            this.ApplyFilterbutton();

                        }

                        // if (this.state.ReportDateField == "99") {
                        //    debugger;
                        //    if ((searchTrCriteria == "")) {

                        //        if (searchTrBy == "OrderStatus") {
                        //            alert("select Order status");
                        //        }

                        //        else if ((this.state.SearchTrFromDate == "") && (this.state.SearchTrToDate == "")) {
                        //            alert("select any criteria");
                        //        }
                        //        else if ((this.state.SearchTrFromDate == "" && this.state.SearchTrToDate != "")) {
                        //            alert("invalid from date");
                        //        }
                        //        else if ((this.state.SearchTrFromDate != "" && this.state.SearchTrToDate == "")) {
                        //            alert("invalid to date");
                        //        }

                        //        else {
                        //            if (radiochange) {
                        //                alert("select any criteria");
                        //            }
                        //            else {
                        //                this.ApplyFilterbutton();
                        //            }

                        //        }

                        //    }

                        //    else if ($('#dateRag').is(':checked')) {
                        //        if (this.state.SearchTrFromDate == "" && this.state.SearchTrToDate == "") {
                        //            alert("Please select From and To Date");
                        //            this.setState({ alert: "" });
                        //            return;
                        //        }
                        //        else if ((this.state.SearchTrFromDate == "") && (this.state.SearchTrToDate == "")) {
                        //            alert("select any criteria");
                        //            return;
                        //        }
                        //        else if ((this.state.SearchTrFromDate == "" && this.state.SearchTrToDate != "")) {
                        //            alert("invalid from date");
                        //            return;
                        //        }
                        //        else if ((this.state.SearchTrFromDate != "" && this.state.SearchTrToDate == "")) {
                        //            alert("invalid to date");
                        //            return;
                        //        }
                        //        else if (searchTrCriteria == "") {
                        //            //($('#SearchTrTxt').val() == "")
                        //            alert("Please enter search criteria");
                        //        }
                        //        else {
                        //            this.ApplyFilterbutton();
                        //        }

                        //    }
                        //    //else if (searchTrBy == "OrderStatus") {
                        //    //    if (searchTrCriteria == "0") {
                        //    //        alert("please select Order Status");
                        //    //    }
                        //    //}

                        //    else {
                        //        this.ApplyFilterbutton();
                        //    }
                        //}

                        //else {
                        //    this.ApplyFilterbutton();
                        //}
                    }
                    else {
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: t("Select any Business Unit")
                        })
                        //alert(t("Select any Business Unit"));
                    }
                }
                else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Select any Report Type")
                    })
                    //alert(t("Select any Report Type"));
                }
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Select any Order Type")
                })
                //alert(t("Select any Order Type"));
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Select any Report(by order date)")
            })
            //alert(t("Select any Report (by order date)"));
        }
    }


    ApplyFilterbutton = () => {
        debugger
        //[5/30/22, PC_OS_02, CAS - Order status data pull issue, Dhevanesam R]
        let sortColumn = "ORD_DATE";//"ORDER_NO";
        let sortType = "DESC";//"ASC";
        let pageNo = 1;
        searchTxt = "";
        if (this.state.ReportDateField == "99") {
            //this.dateCheck();
        }

        //searchTxt = $(`#searchTxt`).val().trim();
        let searchCol = "";
        if (searchTxt != "") {
            searchCol = this.state.searchColumn;
        }

        //this.setState({ searchColumn: "" });
        this.ApplyFilter(pageNo,
            this.state.RecCount,
            searchCol,
            searchTxt, searchTrBy, searchTrCriteria,
            sortColumn,
            sortType, FilterPopupBoolean
        );
        this.setState({ sortColumn: sortColumn, sortType: sortType, searchAval: false, PageNo: pageNo });
    }

    //[4/18/2023]Order status header, line level SP integration and Cancel order changes - Poornima S
    CancelItemClosePopUp = (Levels) => {
        if (Levels == "LineItem") {
            $('#CancelItem-confirmation').modal('hide');
        }
        else {
            $('#CancelOrder-confirmation').modal('hide');
        }
    }

    CancelItemSuccessClosePopUp = () => {
        $('#CanceledItemSuccess').modal('hide');
    }

   
    CancelItemReasonClosePopUp = () => {
        this.setState({ CancelItemReasonDrop: "Select", Comments: "", disable_CommentsSave: true });
        $('#CanceledItemReason').modal('hide');

    }

    CancelItemReasonConfirmPopUp = () => {
        $('#CanceledItemReason').modal('hide');
        debugger
        const { t } = this.props;
        let CancelDrop = this.state.CancelItemReasonDrop;

        let displayName = "";
        if (CookieService.getLocalStorageValues("Session_USERNAME")) {
            displayName = CookieService.getLocalStorageValues("Session_USERNAME").indexOf(",") >= 0 ? CookieService.getLocalStorageValues("Session_USERNAME").split(",")[1] + " " + CookieService.getLocalStorageValues("Session_USERNAME").split(",")[0] : CookieService.getLocalStorageValues("Session_USERNAME");
            if (CookieService.getLocalStorageValues("Session_AGENTUSERID") != "" && CookieService.getLocalStorageValues("Session_AGENTUSERID")) {
                if (CookieService.getLocalStorageValues("Session_USERTYPE") == "ADMINX") {
                    displayName = displayName + CookieService.getLocalStorageValues("Session_CONAME");
                } else {
                    displayName = displayName + CookieService.getLocalStorageValues("Session_AGENTUSERNAME");
                }
            }
        };
        if (CancelDrop == "Other, please describe") {
            CancelDrop = this.state.Comments;
        }
        let CancelRequestBO = {
            OrderNoList: this.state.OrderNoList,
            ItemList: this.state.LineNoList,
            Reason: CancelDrop == "Select" ? "" : CancelDrop,
            Name: displayName,
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            ItemLevel: this.state.ItemLevel,
            UserId: CookieService.getLocalStorageValues("Session_UserID"),
            //[06/29/2023 - PC_ZEUS_150_APP_01 - Johnprinto D]
            BU: CookieService.getLocalStorageValues("Session_BUSUNIT") != undefined && CookieService.getLocalStorageValues("Session_BUSUNIT") != null && CookieService.getLocalStorageValues("Session_BUSUNIT") != "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : "",
            Comments: this.state.Comments,
            NotesStatus: "CNC"
        };
        this.setState({ CancelItemReasonDrop: "Select", Comments: "", disable_CommentsSave: true });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        OrderStatusAction.CancelRequest(CancelRequestBO);
    }

    //[3/27/2024]Z 268 handleReasonChange() is triggered on change of reason dropdown and to enable or disable save button based on the comments and reason
    handleReasonChange = (e) => {
        let btndisable = this.state.disable_CommentsSave;
        if (this.state.cncReasonNeeded.length > 0) {
            if (e.target.value != "Select" && this.state.Comments.trim() != "") {
                btndisable = false;
            }
            else {
                btndisable = true;
            }
        }
        else {
            btndisable = true;
        }
        this.setState({ CancelItemReasonDrop: e.target.value, disable_CommentsSave: btndisable });

    }


    CancelItemConfirmed = (ItemLevel) => {
        debugger
        this.setState({ ItemLevel: ItemLevel });
        let OrderNoList = this.state.OrderNoList;
        let LineNoList = this.state.LineNoList;
        let FilArrGrid = []
        if (ItemLevel == "LineItem") {
            FilArrGrid = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == OrderNoList[0] && x.ISA_INTFC_LN == LineNoList[0].LineNo && (x.ISA_LINE_STATUS != "CRE" && x.ISA_LINE_STATUS != "QTS" && x.ISA_LINE_STATUS != "QTW" && x.ISA_LINE_STATUS != "CNC"));
        }
        else {
            FilArrGrid = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == OrderNoList[0] && (x.ISA_LINE_STATUS != "CRE" && x.ISA_LINE_STATUS != "QTS" && x.ISA_LINE_STATUS != "QTW" && x.ISA_LINE_STATUS != "CNC"));
        }

        $('#CanceledItemReason').modal('show');
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

        let CommentsModal = FilArrGrid.length > 0 ? "Reason for cancel" : "Cancel comments"
        this.setState({ cncReasonNeeded: FilArrGrid, CommentsModalName: CommentsModal })
    }
    onAccordianClick = (event, index, OrderNo, BU) => {
        debugger
        let classname = document.getElementById(`contentrow-${index}`).className;
        if (classname.includes("d-none")) {
            $('.header-arrow').addClass('accordion-arrow-change');
            $('.content-row').removeClass('d-none');
            $('.header-arrow').removeClass('accordion-arrow-change');
            $('.content-row').addClass('d-none');
            this.setState({ LineDetailsGrid: [] });
        }

        this.GetOrderLineDetails(event, index, OrderNo, classname, BU);

    };

    GetOrderLineDetails = (event, index, OrderNo, classname, BU) => {
        debugger
        $(event.currentTarget).children().toggleClass('accordion-arrow-change');
        $(`#contentrow-${index}`).toggleClass('d-none');
        $('tr').removeClass('tr-border');
        $(event.currentTarget).parent('tr').addClass('tr-border');
        let OrderList = [];
        OrderList.push({ OrderId: OrderNo });

        if (classname.includes("d-none")) {
            let LineDataBO = {
                Order_No: OrderList,
                Bus_Unit: BU,
                Session_HOMESTORE: CookieService.getLocalStorageValues("Session_HOMESTORE")
            }
            OrderStatusAction.LineData(LineDataBO);
        }

    }

    GetHeaderDetails = (IsApplyFilter, SrchTxt, IsExportToExcel, orderType, IsWorkOrder, loadBU = "") => {
        debugger
        let FDate = "";
        let TDate = "";
        const { t } = this.props;
        if (IsApplyFilter) {

            if (this.state.ReportDateField == "X") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Select any report(by order date)")
                })
                //alert("Select any report(by order date)");
                return;
            }
            else if (this.state.ReportDateField == "99") {
                if ((this.state.SearchTrFromDate == "" && this.state.SearchTrToDate == "") || (this.state.SearchTrFromDate == null && this.state.SearchTrToDate == null)) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Select A Date Range")
                    })
                    //alert("Select A Date Range");
                    return;
                }
                else if (this.state.SearchTrFromDate == "" || this.state.SearchTrFromDate == null) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Please Select From Date")
                    })
                    //alert("Please Select From Date");
                    return;
                }
                else if (this.state.SearchTrToDate == "" || this.state.SearchTrToDate == null) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Please Select to date")
                    })
                    //alert("Please select to date");
                    return;
                }
                else {

                    let months;
                    months = (new Date(this.state.SearchTrToDate).getDate() - new Date(this.state.SearchTrFromDate).getDate()) / 30 +
                        new Date(this.state.SearchTrToDate).getMonth() - new Date(this.state.SearchTrFromDate).getMonth() +
                        (12 * (new Date(this.state.SearchTrToDate).getFullYear() - new Date(this.state.SearchTrFromDate).getFullYear()));

                    if (this.state.SearchTrFromDate && this.state.SearchTrToDate) {
                        if (this.state.SearchTrFromDate > this.state.SearchTrToDate) {
                            swal({
                                allowOutsideClick: false,
                                closeOnClickOutside: false,
                                text: t("Start date should not be greater then end date")
                            })
                            //alert("Start date should not be greater then end date");
                            return;
                        }
                        else if (months > 3) {
                            swal({
                                allowOutsideClick: false,
                                closeOnClickOutside: false,
                                text: t("Date range should be within 3 months")
                            })
                            //alert("Date range should be within 3 months");
                            return;
                        }
                        else {
                            FDate = this.state.SearchTrFromDate ? this.handledate(this.state.SearchTrFromDate) : "";
                            TDate = this.state.SearchTrToDate ? this.handledate(this.state.SearchTrToDate) : "";
                        }
                    }

                }

            }

        }

        let Emplist = [];
        let BusUnit = "";
        //[Zeus 244, 12/18/2023] - Assigned BU values for Login order status navigation and filters for Report type and Site BU
        if (this.state.ReportTypeField == "Site") {
            BusUnit = this.state.BusUnitField != null && this.state.BusUnitField.trim() != "" ? "'" + this.state.BusUnitField + "'" : loadBU;
        }
        else {
            BusUnit = this.state.CompanyBU;
        }

        if (orderType == "Group") {
            if (this.state.EmpListPush.length == 0) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Please Select Any Employee Name")
                })
                //alert('Please Select Any Employee Name');
                return;
            }
            else if (this.state.EmpListPush.length > 0) {
                this.state.EmpListPush.map((val, index) => {
                    Emplist.push({ EmpId: val.value })
                });
            }
        }
//02/22/24 - Zeus 274 - To convert an order status string into a multiple value filter string - Johnprinto
        let arrStatus = [];
        let strStatus = "";
        if(this.state.orderStatusSelectedList.length > 0){
            this.state.orderStatusSelectedList.forEach(value =>{
                arrStatus.push(value.value);
            })
            strStatus = "'" + arrStatus.join("','") + "'";
        }

        if (IsExportToExcel) {
            let ExcelBO;
            //[07/07/23,PC_INC0023782_APP_1, Johnprinto D]
            if (this.state.ReportDateField == "99") {
                FDate = this.state.SearchTrFromDate ? this.handledate(this.state.SearchTrFromDate) : "";
                TDate = this.state.SearchTrToDate ? this.handledate(this.state.SearchTrToDate) : "";
            }
            ExcelBO = {
                sFromDate: FDate,
                sToDate: TDate,
                eRptTimePeriod: this.state.ReportDateField,
                eOrdersToInclude: orderType,
                Order_Status: strStatus,
                Order_Category: this.state.OrderCategory == " " ? "" : this.state.OrderCategory,
                sGroupList: Emplist,
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                searchTrBy: this.state.searchColumn,
                searchTrCriteria: SrchTxt.toUpperCase(),
                Session_BUSUNIT: BusUnit
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            OrderStatusAction.ExportData(ExcelBO);
        }
        else {
            //[09 /08/2023] PS_SR_Bug_4126_API_01 - SearchedTxt state variable as empty when user click the apply filter button - Johnprinto D
            this.setState({ LineDetailsGrid: [], HeaderGridArray: [], PageNo: 1, SearchedTxt: SrchTxt });

            let HeaderBO;
            HeaderBO = {
                FromDate: FDate,
                ToDate: TDate,
                SelectedReport: this.state.ReportDateField,
                OrderType: orderType,
                OrdCat: this.state.OrderCategory == " " ? "" : this.state.OrderCategory,
                OrdStatus: strStatus,
                sGroupList: Emplist,
                UserId: CookieService.getLocalStorageValues("Session_UserID"),
                SearchType: IsWorkOrder == "" ? this.state.searchColumn : IsWorkOrder,
                SearchContent: SrchTxt.toUpperCase(),
                Bus_Unit: BusUnit
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            OrderStatusAction.HeaderData(HeaderBO);
        }
    }


    handlePriority = (value) => {
        debugger;
        this.setState({ priorityFlag: value });
        let priorityFlag = ``;
        if (value == 1) {
            priorityFlag = `R`;
        } else {
            priorityFlag = ` `;
        }
        this.handleSearch(true, value);
    }



    //[Zeus-140, 06/16/23]-Existing unused function and now used for onclick event of the Filter Search Dropdown -Kishore
    filterClick = (event, displayName, columnName) => {
        event.preventDefault();
        this.setState({ filterDisplayName: displayName, searchColumn: columnName });
    };

    handleSearch = (isPriority, priorityFlag) => {
        debugger;
        let pageNo = 1;
        if (isPriority) {
            isPrioritySearch = true;
            if (priorityFlag == 1) {
                searchTxt = "R";
            }
            else {
                searchTxt = " ";
            }

        } else {
            isPrioritySearch = false;
            searchTxt = $(`#searchTxt`).val();
        }
        //[5/30/22, PC_OS_02, CAS - Order status data pull issue, Dhevanesam R]
        let sortColumn = "ORD_DATE";
        let sortType = "DESC";
        //searchTxt = $(`#searchTxt`).val();
        debugger;
        let searchCol = "";
        if (searchTxt != "") {
            searchCol = this.state.searchColumn;
            console.log("searchCol", searchCol);
            if (searchCol != "LINE_PRIOR") {
                searchTxt = $(`#searchTxt`).val();
            }
        }
        this.ApplyFilter(pageNo,
            this.state.RecCount,
            searchCol,
            searchTxt, searchTrBy, searchTrCriteria,
            sortColumn,
            sortType, FilterPopupBoolean
        );
        //debugger;
        console.log("search", searchCol);
        this.setState({ PageNo: pageNo, isSearch: true, searchAval: true });
        console.log("search", this.state.searchAval);
    }

    saveprefresponse = () => {
        const { t } = this.props;
        let PageLoadResponse = JSON.parse(OrderStatusStore.PageLoadData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        //this.setState({ FlagData: PageLoadResponse.TotalCount });
        let flagdata = "False";
        let flagResponse = "";
        flagdata = PageLoadResponse[0].ErrorOccur;
        flagResponse = PageLoadResponse[0].lblNoData;
        debugger;
        console.log("saveprrferece", flagResponse);
        if (flagdata == "True") {
            if (flagResponse == "Employee List is too long.") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Employee List is too long")
                })
                //alert(t("Employee List is too long"));
            }
            if (flagResponse == "Error updating record from SDIX_RPT_PRESETS") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error updating record from SDIX_RPT_PRESETS")
                })
                //alert(t("Error updating record from SDIX_RPT_PRESETS"));
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Your Selection Criteria Preferences were saved successfully!")
            })
            //alert(t("Your Selection Criteria Preferences were saved successfully!"))
        }
        console.log("falg", flagdata);
        console.log("falg", flagResponse);
    }

    BusUnitDropdownmap = () => {
        //debugger;
        return this.state.BusUnitDropDown.map((value, ID) => {
            return (
                <option key={value.BUSINESS_UNIT} value={value.BUSINESS_UNIT}>{value.DESCRIPTION}</option>
            )

        });
    }
    // bindOSDropdown = () => {
        //     debugger;
        //     return this.state.OrderStatusDD.map((value, ID) => {
            //         return (
                //             <option value={value.MAIN_STATUS}>{value.MAIN_STATUS}</option>
            //         )

        //     });
    // }

    handleOrderStatus = (e) => {
        this.setState({ OrderStatus: e.target.value });
    }

    handleOrderCategory = (e) => {
        this.setState({ OrderCategory: e.target.value });
    }

    EmpIdDropdownmap = () => {

        //return this.state.EmpIdDropDown.map((value, ID) => {
        //    return (
        //        <option value={value.ISA_EMPLOYEE_NAME}>{value.ISA_EMPLOYEE_NAME}</option>
        //    )

        //});
        console.log("error", this.state.EmpIdDropDown);


    }


    SavePrefCheckForGroup = () => {
        const { t } = this.props;
        if (this.state.ReportDateField == "X") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Select Report Date Field")
            })
            // alert(t("Select Report Date Field"));
            return;
        }
        else if (this.state.ReportDateField == "99") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "You cannot save preferred dates"
            });
            // alert("You cannot save preferred dates");
            return;
        }
        if (this.state.ReportTypeField == "Company") {
            if (this.state.BusUnitField != CookieService.getLocalStorageValues("Session_BUSUNIT")) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Cannot save other Business unit for Report type - Company consolidated")
                })
                //alert(t("Can not save other Business unit for Report type - Company consolidated"));
                return;
            }
        }
        if (this.state.OrderTypeField == "Group") {
            if (this.state.EmpListPush.length > 0) {
                this.SavePreferenceClick();
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Select Employee")
                })
                //alert(t("Select Employee"));
                return;
            }
        }

        else {
            this.SavePreferenceClick();
        }
    }


    //***********************SavePreference Click************************
    SavePreferenceClick = () => {
        let MultiSitelist = []
        if (this.state.OrderTypeField == "Group") {
            if (this.state.EmpListPush.length > 0) {
                this.state.EmpListPush.map((val, index) => {
                    MultiSitelist.push({ EmpId: val.value })
                });
            }
        }
        console.log("empist", MultiSitelist);
        //debugger;
//02/22/24 - Zeus 274 - To convert an order status array into a single string to insert the preferences table - Johnprinto
        let arrStatus = [];
        let strStatus = "";
        if(this.state.orderStatusSelectedList){
            this.state.orderStatusSelectedList.forEach(value =>{
                arrStatus.push(value.value);
            })
            strStatus = arrStatus.join(",");
        }
        let SavePrefereceBO = {

            Report: this.state.ReportDateField,
            Order_Type: this.state.OrderTypeField,
            Report_Type: this.state.ReportTypeField,
            Selected_Site_Bu: this.state.BusUnitField,
            OrderStatus: strStatus,
            OrderCategory: this.state.OrderCategory,
            Emplist: MultiSitelist,
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_EMAIL: CookieService.getLocalStorageValues("Session_EMAIL"),
        }
        //debugger;
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        OrderStatusAction.SavePreference(SavePrefereceBO);
        console.log("savepref", this.state.EmpListPush);

    }
    //*****************************SavePreferenceEnd**************************************


    handleChange = (event) => {
        debugger;
        //$("#SearchTrTxt").show();
        //$("#status-dropdown").hide();
        //$('#SearchTrTxt').val('');
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.value == "Group") {
            this.setState({ EmpListPush: [] });
        }
        $("#sFT").hide();
        $("#sdrp").hide();
        $("#OR").hide();
        if (event.target.name == "ReportDateField" && event.target.value != "99") {
            this.setState({ SearchTrFromDate: "", SearchTrToDate: "" });
            searchTrCriteria = "";
            $('#SearchTrTxt').val('');
            $("#status-dropdown").hide();
            $("#SearchTrTxt").show();
            searchTrBy = "OrderNo";
            radiochange = false;

            searchTrCriteria = "";
            radio = "ExactValue"


            $('#ex').removeClass('disable-radio-label');
            $('#6m').removeClass('disable-radio-label');
            $('#dr').removeClass('disable-radio-label');

            // document.getElementById("exval").checked = true;

            //document.getElementById("exval").disabled = false;
            $("#6month").attr('disabled', false);
            $("#dateRag").attr('disabled', false);
            $("#exval").attr('disabled', false);
            this.setState({ SearchTrFromDate: "", SearchTrToDate: "", EV: true, MN: false, DR: false });
        }
        if (event.target.value == "99" || this.state.ReportDateField == "99") {
            $("#status-dropdown").hide();
            $("#SearchTrTxt").show();
            $("#sFT").show();
            $("#sdrp").show();
            $("#OR").show();
            $('#SearchTrTxt').val('');

            //document.getElementById("exval").checked = true;

            this.setState({ STrDisplayName: "Order Number", EV: true, MN: false, DR: false });
        }
        if (event.target.name == "ReportDateField" && event.target.value != "99") {
            $("#sFT").hide();
            $("#sdrp").hide();
            $("#OR").hide();
        }
        //if (event.target.value != "99") {
        //    this.setState({ SearchTrFromDate: "", SearchTrToDate: "" });
        //    searchTrCriteria = "";
        //    $('#SearchTrTxt').val('');
        //    $("#status-dropdown").hide();

        //    $("#sFT").hide();
        //    $("#sdrp").hide();
        //    $("#OR").hide();
        //    //$("#SearchTrTxt").show();
        //    searchTrBy = "OrderNo";
        //    radiochange = false;
        //}
        //if (this.state.ReportDateField == "99") {
        //    $("#sFT").show();
        //    $("#sdrp").show();
        //    $("#OR").show();
        //}
        //if (event.target.value == "99") {
        //    $("#status-dropdown").hide();
        //    $("#SearchTrTxt").show(); 

        //    $("#sFT").show();
        //    $("#sdrp").show();
        //    $("#OR").show();
        //    $('#SearchTrTxt').val('');
        //}


    };
    //addFavItemsToCart = () => {
    //    const { t } = this.props;
    //    let itemsSelected = this.state.GridArr.filter((selected) => selected.checked == true);
    //    let itemsToAdd = [];
    //    if (itemsSelected.length > 0) {
    //        itemsSelected.forEach((value) => {
    //            itemsToAdd.push({
    //                Order_Number: value.ORDER_NO, Business_unit_om: value.BUSINESS_UNIT_OM, INV_ITEM_ID: value.INV_ITEM_ID, Quantity: value.QTY_REQ,
    //                ISA_INTFC_LN: value.LINE_NBR,
    //                ISA_MFG_FREEFORM: value.ISA_MFG_FREEFORM, MFG_ITM_ID: value.MFG_ITM_ID, Descr: value.DESCR254, price: value.ISA_SELL_PRICE
    //            });
    //            //******NEWOFF**(2/24/2021)**
    //        });
    //        let addFavOrderItemsBO = {
    //            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
    //            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
    //            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
    //            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
    //            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
    //            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
    //            LineItems: itemsToAdd
    //        }; //debugger;
    //        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    //        OrderStatusAction.addFavOrderItemsTocart(addFavOrderItemsBO);
    //    }
    //    else {
    //        alert(t("Please select any item to buy again"));
    //    }
    //};

    //[Zeus-140, 06/16/23]-Sorting issue fix using Bubble Sort method - Kishore
    sort = (Arr, indexval, nextindexval, Type) => {
        let temp = []
        let size = Arr.length
        let i, j = 0;

        if (Type == "ASC") {
            for (i = 0; i < size; i++) {

                for (j = i + 1; j < size; j++) {

                    if (eval(indexval) > eval(nextindexval)) {
                        temp = Arr[i];
                        Arr[i] = Arr[j];
                        Arr[j] = temp;
                    }
                }
            }
            return Arr;
        } else {
            for (i = 0; i < size; i++) {

                for (j = i + 1; j < size; j++) {

                    if (eval(indexval) < eval(nextindexval)) {
                        temp = Arr[i];
                        Arr[i] = Arr[j];
                        Arr[j] = temp;
                    }
                }
            }
            return Arr;
        }
    }


    Sorting = (sort, name) => {
        debugger
        let data = []
        if (sort == "DESC") {
            switch (name) {
                case "ORDER_NO": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (Number(a.ORDER_NO.toLowerCase()) > Number(b.ORDER_NO.toLowerCase())) ? 1 : -1);
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ORDER_NO", "Arr[j].ORDER_NO", "DESC")
                    break;
                }
                case "ISA_WORK_ORDER_NO": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (Number(a.ISA_WORK_ORDER_NO) > Number(b.ISA_WORK_ORDER_NO)) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ISA_WORK_ORDER_NO", "Arr[j].ISA_WORK_ORDER_NO", "DESC")
                    break;
                }
                /*case "SHIPTO_ID": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (Number(a.SHIPTO_ID) > Number(b.SHIPTO_ID)) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].SHIPTO_ID", "Arr[j].SHIPTO_ID", "DESC")
                    break;
                }*/
                case "WODESC": {
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].WODESC", "Arr[j].WODESC", "DESC")
                    break;
                }
                case "ENTEREDBYNAME": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (a.OPRID_ENTERED_BY.toLowerCase() > b.OPRID_ENTERED_BY.toLowerCase()) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ENTEREDBYNAME", "Arr[j].ENTEREDBYNAME", "DESC")
                    break;
                }
                //case "ORD_DATE": {
                //    data = this.state.HeaderGridArray.sort((a, b) => (new Date(a.ORD_DATE).getDate() > new Date(b.ORD_DATE).getDate())(a.ORD_DATE > b.ORD_DATE) ? 1 : -1)
                //    break;
                //}
                //[Zeus 140, 06/16/23]-Sorting method for asset ID Column - Kishore
                case "ISA_MACHINE_NO": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (a.ISA_MACHINE_NO.toLowerCase() > b.ISA_MACHINE_NO.toLowerCase()) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ISA_MACHINE_NO", "Arr[j].ISA_MACHINE_NO", "DESC")
                    break;
                }



            }
        }
        else {
            switch (name) {
                case "ORDER_NO": {
                    // data = this.state.HeaderGridArray.sort((a, b) => (Number(b.ORDER_NO.toLowerCase()) > Number(a.ORDER_NO.toLowerCase())) ? 1 : -1);
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ORDER_NO", "Arr[j].ORDER_NO", "ASC")
                    break;
                }
                case "ISA_WORK_ORDER_NO": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (Number(b.ISA_WORK_ORDER_NO) > Number(a.ISA_WORK_ORDER_NO)) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ISA_WORK_ORDER_NO", "Arr[j].ISA_WORK_ORDER_NO", "ASC")
                    break;
                }
                /*case "SHIPTO_ID": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (Number(a.SHIPTO_ID) > Number(b.SHIPTO_ID)) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].SHIPTO_ID", "Arr[j].SHIPTO_ID", "ASC")
                    break;
                }*/
                case "WODESC": {
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].WODESC", "Arr[j].WODESC", "ASC")
                    break;
                }
                case "ENTEREDBYNAME": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (b.OPRID_ENTERED_BY.toLowerCase() > a.OPRID_ENTERED_BY.toLowerCase()) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ENTEREDBYNAME", "Arr[j].ENTEREDBYNAME", "ASC")
                    break;
                }
                //case "ORD_DATE": {
                //    data = this.state.HeaderGridArray.sort((a, b) => (new Date(b.ORD_DATE).getDate() > new Date(a.ORD_DATE).getDate()) ? 1 : -1)
                //    break;
                //}
                case "ISA_MACHINE_NO": {
                    //data = this.state.HeaderGridArray.sort((a, b) => (b.ISA_MACHINE_NO.toLowerCase() > a.ISA_MACHINE_NO.toLowerCase()) ? 1 : -1)
                    data = this.sort(this.state.HeaderGridArray, "Arr[i].ISA_MACHINE_NO", "Arr[j].ISA_MACHINE_NO", "ASC")
                    break;
                }



            }



        }
        this.setState({ HeaderGridArray: data })
    }


    addFavItemsToCart = () => {
        debugger
        const { t } = this.props;
        //1898 - Unable to add Items from different pages through buy again - Deepthi
        let buyagainItems = this.state.BuyAgainArray;
        let itemsSelected = this.state.BuyAgainArray.filter((selected) => selected.checked == true);
        let itemsToAdd = [];
        if (itemsSelected.length > 0) {
            itemsSelected.forEach((value) => {
                itemsToAdd.push({
                    Order_Number: value.ORDER_NO, Business_unit_om: value.BUSINESS_UNIT_OM, INV_ITEM_ID: value.INV_ITEM_ID, Quantity: value.QTY_REQ,
                    ISA_INTFC_LN: value.LINE_NBR,
                    ISA_MFG_FREEFORM: value.ISA_MFG_FREEFORM, MFG_ITM_ID: value.MFG_ITM_ID, Descr: value.DESCR254, price: value.ISA_SELL_PRICE
                });
            });

            if (itemsToAdd.length > 0) {
                let addFavOrderItemsBO = {
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                    LineItems: itemsToAdd,
                    Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                    ReOrderFrom: "ORDERSTATUS",
                    Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "

                };
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                OrderStatusAction.addFavOrderItemsTocart(addFavOrderItemsBO);
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please select any item to buy again")
            })
            //alert("Please select any item to buy again");            
        }
    };


    //*****************ApprovalHistory*****************************
    Approvalhistory = (value) => {
        //debugger;
        $('#order-approval-history').modal('show');
        let bu = [];
        let sitebu;
        bu = this.state.BusUnitDropDown;
        console.log("bud", this.state.BusUnitDropDown);
        debugger;
        if (bu.length > 0) {
            bu.forEach((val) => {
                if (val.BUSINESS_UNIT == CookieService.getLocalStorageValues("Session_BUSUNIT"))
                    sitebu = val.DESCRIPTION;
            })
        }
        let lblTest = "";
        let lblVersion = "";
        let environmentDataResponse;
        debugger;
        environmentDataResponse = JSON.parse(localStorage.getItem("SDIEnvironmentData"));
        environmentDataResponse = environmentDataResponse[0];
        console.log("buhist", environmentDataResponse);
        lblTest = environmentDataResponse.lblTest;
        let websiteid = CookieService.getLocalStorageValues("Session_WEBSITEID");
        lblVersion = environmentDataResponse.lblVersion;
        let ver = [];
        ver = lblVersion.split(' _Z_  :: SUAT :: ');
        console.log("BU", lblTest);
        console.log("BU", lblVersion);
        console.log("BUV", ver[0] + ver[1]);
        this.setState({
            ApprovalPopup: true,

            ApprovalHistoryL: {
                Version: ver[0] + ver[1],
                BU: lblTest,
                //[4/26/2023] more details pop up fields change as per Walmart - Poornima S
                CURRENCY: value.CURRENCY,
                REV_DUE_DATE: !value.REV_DUE_DT1 ? "-" : value.REV_DUE_DT1,
                SHIPDOC: this.shiptobind(value),
                APPROVALBY: !value.OPRID_APPROVED_BY ? "-" : value.OPRID_APPROVED_BY,
                PRICE: !value.PURCH_PRICE1 ? "-" : value.PURCH_PRICE1,
                SUPPLIER: !value.SUPPLIER ? "-" : value.SUPPLIER,
                MFG: !value.MFG_ID ? "-" : value.MFG_ID,
                UNIT: !value.UNIT_OF_MEASURE ? "-" : value.UNIT_OF_MEASURE,
                CONAME: !value.CO_NAME ? "-" : value.CO_NAME

            }
        });
        let ApprovalHistoryBO = {
            sOrderNo: value.ORDER_NO,
            LoadId: value.ORDER_NO,
            sBu: this.state.ReportTypeField,
            ENTERED_BY_USERID: value.ENTEREDEMPNAME


        }
        //debugger;
        OrderStatusAction.ApprovalHistory(ApprovalHistoryBO);
    }
    //*************************************************************

    //************************Approvalhistory popupdetail******************
    bindApproveHistoryPopupDetail = () => {
        let HistoryResponse = JSON.parse(OrderStatusStore.PageLoadData);
        this.setState({ ApprovalhistoryArr: HistoryResponse.ApprvHist });

        //this.setState({
        //    ApprovalPopup: true,

        //})
    }

    HeaderDataResponse = () => {
        debugger
        const { t } = this.props;
        let HeaderDataRespnse = JSON.parse(OrderStatusStore.HeaderData);
        let checkqty = false;

        let cancelBtnList = HeaderDataRespnse.CancelBtnFlags;
        let locCancelBtnStatuses = "";
        if (cancelBtnList != undefined && cancelBtnList != null) {
            locCancelBtnStatuses = cancelBtnList[0].CancelBtnRow;
        }

        let locHeaderDet = HeaderDataRespnse.v_result_set;
        if (locHeaderDet.length > 0) {
            let count = locHeaderDet.length;

            let linecount = locHeaderDet.length;
            let uniqueorder = locHeaderDet.filter((v, i, a) => a.findIndex(t => (t.ORDER_NO.trim().toUpperCase() === v.ORDER_NO.trim().toUpperCase())) === i);
            let ordercount = uniqueorder.length;
            let pg = Math.ceil((ordercount > 0 ? ordercount : 0) / this.state.RecCount);
            let total = ordercount > 0 ? ordercount : 0;
            locHeaderDet.forEach((value, index) => {
                value.checked = false;
                if (value.SHIPTO_ID.trim() == "-" || value.SHIPTO_ID.trim() == "-0") {
                    value.SHIPTO_ID = "See line details";
                }
            });
            let Buyagaindata = this.state.BuyAgainArray
            if (Buyagaindata.length > 0) {
                Buyagaindata.forEach((Buyvalue, index) => {
                    locHeaderDet.forEach((value, index) => {
                        if (value.ORDER_NO === Buyvalue.ORDER_NO && value.LINE_NBR == Buyvalue.LINE_NBR) {
                            value.checked = true;
                        }
                    });
                });
            }
            //Zeus 11 - Stock issue panel[Hiding buy again button for STK items][Change by Vishalini]
            //Zeus-241 - Product Replenishmet panel -- Hiding buy again button
            const atLeastOneNotSTK = locHeaderDet.some(value => value.LINEFIELD !== "STK" && value.LINEFIELD !== "REPLN");
            let locbuyCheckedItembool = false;
            if (atLeastOneNotSTK) {
                locbuyCheckedItembool = true;
            }
            else {
                locbuyCheckedItembool = false;
            }
            console.log("Search Value....", this.state.SearchedTxt);
            //[21/05/2023 - PS_ZE_118_APP_6  - Johnprinto D]
            this.setState({ OrdertotalCount: ordercount, buyCheckedItembool: locbuyCheckedItembool, totalCount: total, pageCount: pg, HeaderGridArray: locHeaderDet, SearchedTxt: this.state.SearchedTxt, isExpand: false, searchAval: true, LineDetailsGrid: [], cancelBtnStatuses: locCancelBtnStatuses, searchTxt, PageNo: 1 });

        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Data not found")
            })
            //alert("Data Not Found");
            //$(`#ReportDateDD`).prop('disabled', false);
            //$(`#OrderTypeDD`).prop('disabled', false);
            //$(`#ReprotTypeDD`).prop('disabled', false);
            //$(`#BusUnitDD`).prop('disabled', false);
            //$(`#ClearID`).prop('disabled', false);
            //$(`#savePref`).prop('disabled', false);
            //$(`#applyfilterid`).prop('disabled', false); 
            //$(`#OrderStatus`).prop('disabled', false);
            //$(`#OrderCategory`).prop('disabled', false);

            //[21/05/2023 - PS_ZE_118_APP_6  - Johnprinto D]
            this.setState({ OrdertotalCount: 0, totalCount: 0, pageCount: 0, HeaderGridArray: [], isExpand: false, searchAval: false, LineDetailsGrid: [], cancelBtnStatuses: "", PageNo: 1 });
        }


    }


    AnykeyHeaderPress(e) {
        debugger
        if (e.keyCode == 13) {
            this.handleHeaderSearch();
        }
    };

    divclear = (event) => {
        debugger
        $(`#sFT`).hide();
        this.setState({
            SearchedTxt: event.target.value, isSearch: true, ReportDateField: "X", OrderTypeField: "All", ReportTypeField: "Site", OrderStatusSearch: "", SearchTrFromDate: "", SearchTrToDate: "", EmpIdField: "", BusUnitField: CookieService.getLocalStorageValues("Session_BUSUNIT"), OrderStatus:" ", OrderCategory:" ", orderStatusSelectedList: []
        });
    }

    ClearSearch = () => {
        document.getElementById("searchTxt").value = "";
        $(`#ReportDateDD`).prop('disabled', false);
        $(`#OrderTypeDD`).prop('disabled', false);
        //$(`#ReprotTypeDD`).prop('disabled', false);
        //$(`#BusUnitDD`).prop('disabled', false);
        $(`#OrderStatus`).prop('disabled', false);//[Zeus-141, 06/26/2023] - show order status and Order category dropdown data
        $(`#OrderCategory`).prop('disabled', false);
        //$(`#applyfilterid`).prop('disabled', false); //No need to disable or enable the Apply filter button, just need to hide it
        //$(`#ClearID`).prop('disabled', false);
        //$(`#savePref`).prop('disabled', false);        
        this.setState({ SearchedTxt: "", isSearch: true });

    }

    handleSearchTxt = (event) => {
        debugger
        if (event != undefined) {
            if (event.target.value != "") {
                this.setState({ SearchedTxt: event.target.value.trim(), isSearch: false, ReportDateField: "X", OrderTypeField: "All", OrderStatusSearch: "", SearchTrFromDate: "", SearchTrToDate: "", EmpIdField: "", OrderStatus:" ", OrderCategory:" ", orderStatusSelectedList:[]})
                $(`#ReportDateDD`).prop('disabled', true);
                $(`#OrderTypeDD`).prop('disabled', true);
                //$(`#ReprotTypeDD`).prop('disabled', true);
                //$(`#applyfilterid`).prop('disabled', true);  
                //$(`#BusUnitDD`).prop('disabled', true);  
                //$(`#ClearID`).prop('disabled', true);
                //$(`#savePref`).prop('disabled', true);
                $(`#OrderStatus`).prop('disabled', true);//[Zeus-141, 06/26/2023] - hide order status and Order category dropdown data
                $(`#OrderCategory`).prop('disabled', true);
            } else {
                $(`#ReportDateDD`).prop('disabled', false);
                $(`#OrderTypeDD`).prop('disabled', false);
                $(`#ReprotTypeDD`).prop('disabled', false);
                $(`#BusUnitDD`).prop('disabled', false);
                $(`#OrderStatus`).prop('disabled', false);
                $(`#OrderCategory`).prop('disabled', false);
                //$(`#applyfilterid`).prop('disabled', false); 
                //$(`#ClearID`).prop('disabled', false);
                //$(`#savePref`).prop('disabled', false);
                //[21/05/2023 - PS_ZE_118_APP_5  - Johnprinto D]
                this.setState({ isSearch: true, SearchedTxt: ""});
            }
        }
        else {
            let srchtxt = $('#SearchTrTxt').val();
            if (srchtxt === undefined) {
                srchtxt = "";
            }
            if (srchtxt != "") {
                $(`#ReportDateDD`).prop('disabled', true);
                $(`#OrderTypeDD`).prop('disabled', true);
                $(`#ReprotTypeDD`).prop('disabled', true);
                $(`#BusUnitDD`).prop('disabled', true);
                $(`#OrderStatus`).prop('disabled', true);
                $(`#OrderCategory`).prop('disabled', true);
                //$(`#applyfilterid`).prop('disabled', true);  
                //$(`#ClearID`).prop('disabled', true);
                //$(`#savePref`).prop('disabled', true);
                this.setState({ isSearch: false });
            }
        }
    }

    handleHeaderSearch = () => {
        debugger
        let pageNo = 1;
        searchTxt = $(`#searchTxt`).val();

        this.GetHeaderDetails(false, this.state.SearchedTxt, false, this.state.OrderTypeField, "");
        this.setState({ PageNo: pageNo, searchAval: true });
    }

    //[Zeus 244, 12/18/2023] - Onclick search field function
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.handleHeaderSearch();
        };
    }
    //**********************POPOPUp****************************************
    PODetailPopup = (value) => {
        debugger
        let PODetailBO = {
            sPOID: value.PO_ID1,
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP")
        }
        //debugger;
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        OrderStatusAction.PODetail(PODetailBO);

    }
    //********************************End**********************************
    ShipmentBOlPopup = (OrderItem, OrderDetail) => {
        let pop = false;
        if (this.state.FlagTable.length > 0) {
            let pop = this.state.FlagTable.filter((item, index) => item.sOrderNo == OrderDetail.ORDER_NO && item.popup == "True");
            if (pop.lenght > 0) {
                pop = true;
            }
        }


        let ShipmentBOLBO = {
            signpopup: pop,
            OrderNo: OrderItem.ORDER_NO,
            LoadId: OrderItem.LOAD_ID,
            ENTERED_BY_USERID: OrderDetail.OPRID_ENTERED_BY,

            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),

        }
        //debugger;
        OrderStatusAction.shipmentBOL(ShipmentBOLBO);
    }

    //pushEmpId = (e) => {
    //    selectedEmpId
    //}
    //handleBusUnitChange = (e) => {

    //    this.setState({ BusUnitField: e.target.value });
    //}
    //*****************sorting icon click***************************
    //Sorting = (sortType, sortColumn) => {
    //    let searchCol
    //    if (this.state.isSearch) {
    //        searchCol = this.state.searchColumn;
    //        if (searchCol == "LINE_PRIOR") {
    //            if (this.state.priorityFlag == 1) {
    //                searchTxt = `R`;
    //            }
    //            else if (this.state.priorityFlag == 2) {
    //                searchTxt = ` `;
    //            }
    //            else {
    //                searchCol = "";
    //                searchTxt = ` `;
    //            }

    //        }
    //        else {
    //            searchTxt = $(`#searchTxt`).val().trim()
    //        }


    //    }
    //    if (this.state.searchTrTxt !== "") {

    //    }
    //    //this.setState({ sSearchCriteria: sc });
    //    let pageNo = 1;
    //    this.ApplyFilter(this.state.PageNo,
    //        this.state.RecCount,
    //        this.state.searchColumn,
    //        searchTxt, searchTrBy, searchTrCriteria,
    //        sortColumn,
    //        sortType, FilterPopupBoolean
    //    );
    //    this.setState({ pageNo: pageNo, sortColumn: sortColumn, sortType: sortType });
    //}
    handleSTrTxt = (event) => {
        //this.setState({ searchC: [event.traget.value] });
        searchTrCriteria = $('#SearchTrTxt').val();
        //debugger;
    }
    //handleSearchTxt = (event) => {
    //    this.setState({ isSearch: false });
    //}
    handlePageClick = (data) => {
        debugger;
        let pageNo = data.selected + 1;

        this.setState({ PageNo: pageNo, isExpand: false });
        //if (this.state.isSearch) {
        //    if (isPrioritySearch) {
        //        let radioVal = $("input[name='priorityOption']:checked").val();
        //        if (radioVal == `1`) {
        //            searchTxt = `R`;
        //        }
        //        else {
        //            searchTxt = ` `;
        //        }

        //    }
        //    else {
        //        searchTxt = $(`#searchTxt`).val().trim();
        //    }
        //    //searchTxt = $(`#searchTxt`).val().trim();
        //}
        //this.ApplyFilter(pageNo,
        //    this.state.RecCount,
        //    this.state.searchColumn,
        //    searchTxt, searchTrBy, searchTrCriteria,
        //    this.state.sortColumn,
        //    this.state.sortType, FilterPopupBoolean
        //);
        //console.log("totArr", pageNo);
        ////debugger;

    };

    shiptobind = (OrderDetail) => {
        let ShiptoValue = this.state.GridArr2.filter((item, index) => item.ORDER_NO == OrderDetail.ORDER_NO && item.ORDER_INT_LINE_NO == OrderDetail.LINE_NBR);
        //debugger;

        return ShiptoValue.map((Item, index) => {
            //debugger;
            return (
                <a href="#" data-toggle="modal" className="float-none" data-target="#shipmentBOL" onClick={(e) => this.ShipmentBOlPopup(Item, OrderDetail)} >{Item.SHIP_DATE}</a>
            );
        })
    }

    selectOrder = (index) => {
        let { GridArr } = this.state;
        GridArr[index].checked = GridArr[index].checked == true ? false : GridArr[index].checked == false ? true : true;
        this.setState(GridArr);
    }

    OrderStatusPopup = (value) => {
        //$('.orderstatus-popup').modal('show');
        let OrderStatusBO = {
            sOrderNo: value.ORDER_NO,

            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),

            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")

        }
        //debugger;
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        OrderStatusAction.OrderStatusDetail(OrderStatusBO);
    }
    //onAccordianClick = (event, index) => {
    //    $(event.currentTarget).children().toggleClass('accordion-arrow-change');
    //    $(`#contentrow-${index}`).toggleClass('d-none');
    //    $('tr').removeClass('tr-border');
    //    $(event.currentTarget).parent('tr').addClass('tr-border');

    //};
    onAccordianPOClick = (event, index) => {
        this.setState({ isPOExpand: false });
        $(event.currentTarget).children().toggleClass('accordion-arrow-change');
        $(`#contentPOrow-${index}`).toggleClass('d-none');
        $('tr').removeClass('tr-border');
        $(event.currentTarget).parent('tr').addClass('tr-border');

    };

    //[Zeus 178, 08/22/2023] - Onlick event of approver chain button
    GetApproversList = (EmpID, OrderNo, lineItems, WONo) => {

        let lineItemID = lineItems.findIndex((x) => x.ISA_LINE_STATUS == 'QTW')
        lineItemID = lineItemID == -1 ? 0 : lineItemID;
        let ApproverListBO = {
            EmployeeID: EmpID,
            EmployeeBU: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            ApproverListUser: lineItems[lineItemID].USERID,
            NextApprover: lineItems[lineItemID].NEXTAPPROVER,
            WONo: WONo,
            OrderNo: OrderNo
        }

        this.setState({ RemainderOrder: OrderNo, OrderApprover: lineItems[lineItemID].NEXTAPPROVER, ApproverListUser: lineItems[lineItemID].USERID, OLEmployee: EmpID, RemainderLineItems: lineItems[lineItemID].APR_CHAIN_LINEITEMID });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        OrderStatusAction.GetApproversPopupList(ApproverListBO);

    }

    approverListResponse = () => {
        let approverList = JSON.parse(OrderStatusStore.ApproverList);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

        if (approverList.ErrorTable[0].error == "True") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical issue try again"
            })
            return;
        }

        let OrderedForData = approverList.FinalAlternates.filter((val) => val.USERID == this.state.OLEmployee);
        let Alternates = approverList.FinalAlternates != undefined && approverList.FinalAlternates != null ? approverList.FinalAlternates : [];
        this.setState({ ApproverListPopup: approverList.ApproverList, Alternates: Alternates, OrderedForData: OrderedForData })
        $('.approver-chain-details').modal('show')
    }
    //[3/27/2024]Z 268 displayNotes() calls action method to get notes against that order or line item
    displayNotes = (event, order_no, line_no) => {
        event.preventDefault();
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let noteslbl = line_no == "0" ? "Order notes" : "Item notes";
        let GetNotesBO = {
            order_no: order_no,
            line_no: line_no
        }
        OrderStatusAction.GetOrderNotes(GetNotesBO);

        this.setState({ notesModalLbl: noteslbl })
    }

    copyNotes = (notes) => {
        debugger
        Copy(notes);
        try {
            $.toast({
                text: 'Order notes copied to clip board.',
                position: {
                    top: 150,
                    bottom: 90,
                    left: 650
                },
                stack: false,
                loader: false
            });

        } catch (ex) {
            console.log(ex)
        }
    }

    //[3/27/2024] Z 268 - BindNotesTypeLabel() & bindNotes() maps and binds the notes retrieved against that order or line item
    BindNotesTypeLabel = () => {
        return this.state.UniqueOrderStatus.map((val, index) => {
            let showBorderLbl = false;
            let isLastNotes = false;
            let isCNCNotes = false;
            let NotesType = "";
            NotesType = this.state.UniqueOrderStatus[index].STATUS.substring(0, 2) == "NE" ? "Order notes" : this.state.UniqueOrderStatus[index].STATUS.substring(0, 2) == "QS" ? "Requestor notes" : this.state.UniqueOrderStatus[index].STATUS.substring(0, 2) == "QW" ? "Budgetary notes" : "Cancel notes";
            if ((index == this.state.UniqueOrderStatus.length - 1)) {
                if (this.state.UniqueOrderStatus[index].STATUS == "CNC") {
                    isCNCNotes = true;
                } else {
                    isLastNotes = true;
                }
            }
            else {
                showBorderLbl = true;
            }

            let NotesDetails = this.state.NewOrderNotes.filter((X) => X.STATUS.substring(0, 2) == val.STATUS.substring(0, 2));
            return (
                <>
                    <div className="col-md-12 mb-3">
                        <div className={`${showBorderLbl ? 'border rounded px-3 py-3' : isLastNotes ? 'current-user-style rounded custom px-3 py-3' : isCNCNotes ? 'border-red rounded px-3 py-3' : ''}`}>
                            <div class="row">
                                <div class="col-md-12 mb-1">
                                    <h5 class="font-16 font-bold">{NotesType}</h5>
                                </div>
                                <>
                                    {this.bindNotes(NotesDetails)}
                                </>
                            </div>
                        </div>
                    </div>
                </>
            )
        });
    }

    bindNotes = (NotesDetails) => {
        return NotesDetails.map((val, index) => {
            let showNotesType = false;
            if ((val.NOTES_LABEL) && ((index == 0) || (NotesDetails[index - 1].NOTES_LABEL != val.NOTES_LABEL))) {
                showNotesType = true;
            }
            const date = new Date(val.DT_TIMESTAMP);
            return (
                <>
                    {showNotesType ? <div class="col-md-12 mb-1">
                        <h5 class="Font-14 font-bold">{val.NOTES_LABEL}</h5>
                    </div> : null}

                    <div class="col-md-4 mb-2">
                        <span class="mr-1"><img src="images/date-time-icon.svg" alt="date/time" /></span>
                        <span class="font-12 font-regular Order-color-light-black">{date.toLocaleString()}</span>
                    </div>
                    <div class="col-md-8 mb-2">
                        <span class="mr-1"><img src="images/user-id-icon.svg" alt="user-id" /></span>
                        <span class="font-12 font-regular Order-color-light-black">{val.ENTERED_BY}</span>
                    </div>
                    <div class="col-md-12">
                        <label class="w-100 Font-14 font-medium mb-1">Notes:</label>
                        <span class="Font-14 font-regular word-wrap-break Order-color-light-black">{val.ISA_LINE_NOTES}</span>
                    </div>
                    {(NotesDetails.length > 1) && (index != NotesDetails.length - 1) ? <span class="border-bottom w-100 my-3 mx-3"></span> : null}
                </>)
        });
    }


    //[Zeus 190, 09/05/2023] - Notfiy approver list bind - Kishore
    bindApproverList = () => {
        if (this.state.ApproverListPopup.length > 0) {
            let length = this.state.ApproverListPopup.length;
            let OrderedFor = this.state.OrderedForData;
            return this.state.ApproverListPopup.map((val, index) => {
                return (
                    <>
                        {index == 0 ?
                            <div className="custom-approver-card-style current-user-style custom-mb">
                                <div className="d-flex align-items-center justify-content-start px-4">
                                    <div className="d-block mr-2">
                                        <img className="" src="images/user-icon.svg" alt="user-icon" />
                                    </div>
                                    <div className="d-block text-left">
                                        <p className="font-medium font-12 mb-0 mt-3 text-black" data-toggle="tooltip" title={OrderedFor[0].USERNAME} data-label="OrderedForName">{OrderedFor[0].USERNAME.length > 15 ? `${OrderedFor[0].USERNAME.substring(0, 15)}...` : OrderedFor[0].USERNAME}</p>
                                        <p className="mb-3">
                                            <span className="font-regular font-10 color-grey mr-1">
                                                Budgetary Limit:
                                        </span>
                                            <span className="Font-14 font-bold text-black">${OrderedFor[0].USERLIMIT}</span>
                                        </p>
                                    </div>
                                </div>
                            </div> : null}
                        {OrderedFor[0].USERID != val.ISA_EMPLOYEE_ID ?
                            val.ISA_EMPLOYEE_ID == this.state.OrderApprover ?
                                <div
                                    className="custom-approver-card-style attachment-remove-icon collapsed active card-after"
                                    data-toggle="collapse"
                                    data-target="#multiCollapseExample1"
                                    id="OrderApprover"
                                >
                                    <div className="d-flex align-items-center justify-content-start pl-4 pr-3">
                                        <div className="d-block mr-2">
                                            <img className="" src="images/user-icon.svg" alt="user-icon" />
                                        </div>
                                        <div className="d-block text-left w-100">
                                            <p className="font-medium font-12 mb-0 mt-3 text-black d-flex justify-content-between" data-toggle="tooltip" title={val.CURRENTUSER} data-label="CURRENTUSER">
                                                {val.CURRENTUSER.length > 15 ? `${val.CURRENTUSER.substring(0, 15)}...` : val.CURRENTUSER}
                                                <span>
                                                    <a className="" onClick={() => this.RemainderPopup(val.ISA_EMPLOYEE_ID)}>
                                                        <img src="images/notification.svg" alt="notification" />
                                                    </a>
                                                </span>
                                            </p>
                                            <p className="mb-3">
                                                <span className="font-regular font-10 color-grey mr-1">
                                                    Budgetary Limit:
                                        </span>
                                                <span className="Font-14 font-bold text-black">${val.CURRENTUSERLIMIT}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="custom-approver-card-style card-after">
                                    <div className="d-flex align-items-center justify-content-start px-4">
                                        <div className="d-block mr-2">
                                            <img className="" src="images/user-icon.svg" alt="user-icon" />
                                        </div>
                                        <div className="d-block text-left">
                                            <p className="font-medium font-12 mb-0 mt-3 text-black" data-toggle="tooltip" title={val.CURRENTUSER} data-label="CURRENTUSER">
                                                {val.CURRENTUSER.length > 15 ? `${val.CURRENTUSER.substring(0, 15)}...` : val.CURRENTUSER}</p>
                                            <p className="mb-3">
                                                <span className="font-regular font-10 color-grey mr-1">
                                                    Budgetary Limit:
        </span>
                                                <span className="Font-14 font-bold text-black">${val.CURRENTUSERLIMIT}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            : null}
                        {this.state.Alternates.length > 1 && val.ISA_EMPLOYEE_ID == this.state.OrderApprover ?
                            < div className="collapse multi-collapse w-100" id="multiCollapseExample1">
                                <div className="text-center">
                                    <h6 className="Font-14 font-medium text-black mb-3">Alternate Approver</h6>
                                </div>
                                <div className="card card-body border-0 pt-0 mb-4" style={{ 'padding-right': '40px' }}>
                                    <div className="row justify-content-center">
                                        {this.bindAlternates()}
                                    </div>
                                </div>
                            </div> : null
                        }
                        {val.NEXTAPPROVERLIMIT == null ?
                            val.ISA_IOL_APR_EMP_ID == this.state.OrderApprover ?
                                <div className="custom-approver-card-style card-after final-approver-style collapsed active attachment-remove-icon" id="OrderApprover" data-toggle="collapse" data-target="#multiCollapseExample1">
                                    <div className="d-flex align-items-center justify-content-start pl-4 pr-3">
                                        <div className="d-block mr-2">
                                            <img className="" src="images/user-icon.svg" alt="user-icon" />
                                        </div>
                                        <div className="d-block text-left w-100">
                                            <p className="font-medium font-12 mb-0 mt-3 text-black d-flex justify-content-between" data-toggle="tooltip" title={val.NEXTAPPROVER} data-label="NextApprover">
                                                {val.NEXTAPPROVER.length > 15 ? `${val.NEXTAPPROVER.substring(0, 15)}...` : val.NEXTAPPROVER}
                                                <span>
                                                    <a className="" onClick={() => this.RemainderPopup(val.ISA_IOL_APR_EMP_ID)}>
                                                        <img src="images/notification.svg" alt="notification" />
                                                    </a>
                                                </span>
                                            </p>
                                            <p className="mb-3">
                                                <span className="font-regular font-10 color-grey mr-1">
                                                    Budgetary Limit:
        </span>
                                                <span className="Font-14 font-bold text-black">-</span>
                                            </p>
                                        </div>
                                    </div>
                                </div> :
                                <div className="custom-approver-card-style card-after final-approver-style">
                                    <div className="d-flex align-items-center justify-content-start px-4">
                                        <div className="d-block mr-2">
                                            <img className="" src="images/user-icon.svg" alt="user-icon" />
                                        </div>
                                        <div className="d-block text-left">
                                            <p className="font-medium font-12 mb-0 mt-3 text-black" data-toggle="tooltip" title={val.NEXTAPPROVER} data-label="NextApprover">
                                                {val.NEXTAPPROVER.length > 15 ? `${val.NEXTAPPROVER.substring(0, 15)}...` : val.NEXTAPPROVER}</p>
                                            <p className="mb-3">
                                                <span className="font-regular font-10 color-grey mr-1">
                                                    Budgetary Limit:
        </span>
                                                <span className="font-14 font-bold text-black">-</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            : null}
                        {this.state.Alternates.length > 1 && val.ISA_IOL_APR_EMP_ID == this.state.OrderApprover && val.NEXTAPPROVERLIMIT == null ?
                            < div className="collapse multi-collapse w-100" id="multiCollapseExample1">
                                <div className="text-center">
                                    <h6 className="Font-14 font-medium text-black mb-3">Alternate Approver</h6>
                                </div>
                                <div className="card card-body border-0 pt-0 mb-4" style={{ 'padding-right': '40px' }}>
                                    <div className="row justify-content-center">
                                        {this.bindAlternates()}
                                    </div>
                                </div>
                            </div> : null
                        }

                    </>)
            });
        }

    }
    //[Zeus 190, 09/05/2023] - Notfiy alternate approver list bind - Kishore
    bindAlternates = () => {

        return this.state.Alternates.map((val, index) => {

            return (<>
                {
                    val.USERID != this.state.OLEmployee ?
                        <div className="col-md-4 col-lg-3 col-12 mb-3 custom-responsive attachment-remove-icon" onClick={() => this.RemainderPopup(val.USERID)}>
                            <div className="custom-alternate-approver-card-style">
                                <div className="d-flex align-items-center justify-content-start pl-3">
                                    <div className="d-block mr-2">
                                        <img className="" src="images/user-icon.svg" alt="user-icon" />
                                    </div>
                                    <div className="d-block text-left" style={{ 'width': '100%' }}>
                                        <p className="font-medium font-12 mb-0 mt-3 text-black" data-toggle="tooltip" title={val.USERNAME} data-label="AlternatesName">{val.USERNAME.length > 15 ? `${val.USERNAME.substring(0, 15)}...` : val.USERNAME}</p>
                                        <p className="mb-3" style={{ 'white-space': 'nowrap' }}>
                                            <span className="font-regular font-10 color-grey mr-1">
                                                Budgetary Limit:
                                                        </span>
                                            <span className="font-13 font-bold text-black">{val.USERLIMIT != null ? `$${val.USERLIMIT}` : "-"}</span>
                                        </p>
                                    </div>
                                    <div className="d-block pr-2 pt-1 custom-height">
                                        <a className="">
                                            <img src="images/notification.svg" alt="notification" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }</>
            )
        })
    }
    //[Zeus 190, 09/05/2023] - Notfiy approver confirmation popup display - Kishore
    RemainderPopup = (Approver) => {
        $('.approver-chain-details').modal('hide');
        $('.remaind-alternate-approver').modal('show');
        this.setState({ RemainderApprover: Approver });
    }

    sendRemiander = () => {
        let RemainderBO = {
            Approver: this.state.RemainderApprover,
            OrderNo: this.state.RemainderOrder,
            OLEmployee: this.state.ApproverListUser,
            LineItems: this.state.RemainderLineItems,
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_IOLServer: CookieService.getLocalStorageValues("Session_IOLServer"),
            Session_cplusServer: CookieService.getLocalStorageValues("Session_cplusServer"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            SESSION_SITE_CURRENCY: ""
        }
        if (!$('#OrderApprover').hasClass('collapsed') && $('#multiCollapseExample1').hasClass('show')) {
            $("#OrderApprover").addClass("collapsed");
            $('#multiCollapseExample1').removeClass('show');
        }
        $('.remaind-alternate-approver').modal('hide');
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        OrderStatusAction.RemainderOrderAction(RemainderBO);
    }
    //[Zeus 190, 09/05/2023] - Notfiy approver Remainder response - Kishore
    RemainderResponse = () => {
        let RemainderResponse = JSON.parse(OrderStatusStore.RemainderData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (RemainderResponse.ErrorTable[0].error == "True") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical issue try again"
            })
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Approver notified successfully"
            })
        }

    }

    closeApproverspopup = () => {
        if (!$('#OrderApprover').hasClass('collapsed') && $('#multiCollapseExample1').hasClass('show')) {
            $("#OrderApprover").addClass("collapsed");
            $('#multiCollapseExample1').removeClass('show');
        }
    }

    LineDataResponse = () => {
        debugger
        let LineResponse = JSON.parse(OrderStatusStore.LineData)
        let locgrid = LineResponse.v_result_set;
        let approvers = [];
        if (LineResponse.v_result_approver != undefined || LineResponse.v_result_approver != null) {
            approvers = LineResponse.v_result_approver;
        }
        let linegrid = [];
        if (locgrid.length > 0) {
            locgrid.forEach((x) => {
                let linedetails = this.state.HeaderGridArray.filter(line => {
                    return line.ORDER_NO == x.ORDER_NO && line.ISA_INTFC_LN == x.LINE_NBR;
                });
                if (linedetails.length > 0) {
                    linegrid.push(x);
                }
            });
        }
        this.setState({ LineDetailsGrid: linegrid, ApproverData: approvers });
    }

    selectbuyagainOrder = (value) => {
        debugger
        let locGridArr = this.state.HeaderGridArray;
        let locbuyagain = this.state.BuyAgainArray;
        let filterArr;

        if (locbuyagain.length > 0) {
            filterArr = locbuyagain.findIndex(t => (t.ORDER_NO === value.ORDER_NO && t.LINE_NBR == value.LINE_NBR));
        };

        let I = locGridArr.findIndex(t => (t.ORDER_NO === value.ORDER_NO && t.ISA_INTFC_LN == value.LINE_NBR));
        locGridArr[I].checked = locGridArr[I].checked == true ? false : locGridArr[I].checked == false ? true : true;
        let NextIndex = I + 1;

        if (locGridArr[I].checked == true) {
            if (filterArr == -1 || filterArr == undefined) {
                let buyagaiobj = {
                    ORDER_NO: value.ORDER_NO,
                    BUSINESS_UNIT_OM: value.BUSINESS_UNIT_OM,
                    INV_ITEM_ID: value.ITEM_ID,
                    QTY_REQ: value.QTY_REQ,
                    LINE_NBR: value.LINE_NBR,
                    ISA_MFG_FREEFORM: value.ISA_MFG_FREEFORM,
                    MFG_ITM_ID: value.MFG_ITM_ID,
                    DESCR254: locGridArr[I].DESCR254,
                    ISA_SELL_PRICE: value.ISA_SELL_PRICE,
                    checked: locGridArr[I].checked
                }
                locbuyagain.push(buyagaiobj);
            }

        }
        else {
            let Index = this.state.BuyAgainArray.findIndex(t => (t.ORDER_NO === value.ORDER_NO && t.ISA_INTFC_LN == value.LINE_NBR));
            this.state.BuyAgainArray.splice(Index, 1);
            locbuyagain = this.state.BuyAgainArray;
        }


        this.setState({ BuyAgainArray: locbuyagain, HeaderGridArray: locGridArr });

    }

    CancelLineLevelItem = (value, Levels) => {
        debugger
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (Levels == "Line") {
            let FilArrGridLN = [];
            let FilArrGridCC = [];
            let lnVal = value.LINE_NBR + 1;
            console.log("lnVal", lnVal)
            let GridArrResponse = [];
            GridArrResponse = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == value.ORDER_NO)
            GridArrResponse.forEach((valueGrid, index) => {
                if (FilArrGridLN.length == 0) {
                    FilArrGridLN = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == value.ORDER_NO && x.ISA_INTFC_LN == value.LINE_NBR)
                    if (FilArrGridLN.length > 0) {
                        if (FilArrGridCC.length == 0) {
                            FilArrGridCC = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == value.ORDER_NO && x.ISA_INTFC_LN == lnVal)
                            if (FilArrGridCC.length > 0) {
                                FilArrGridCC.forEach((valueFilArr, index) => {
                                    if (valueFilArr.DESCR254.includes("Core Charges")) {
                                        FilArrGridLN.push(FilArrGridCC[0]);
                                    }
                                })

                            }
                        }
                    }
                }

            });
            let OrderNo = []
            let LineNumber = []
            if (value != null && value != "") {
                OrderNo.push(value.ORDER_NO);
            }
            if (FilArrGridLN.length > 0) {
                FilArrGridLN.forEach((valueFil, index) => {
                    if (valueFil != null && valueFil != "") {
                        LineNumber.push({
                            LineNo: valueFil.ISA_INTFC_LN.toString(),
                            ItemId: valueFil.ITEM_ID != null && valueFil.ITEM_ID != undefined ? valueFil.ITEM_ID.toString() : valueFil.ITEM_ID_VEND != null && valueFil.ITEM_ID_VEND != undefined ? valueFil.ITEM_ID_VEND.toString() : "",
                            LineStatus: valueFil.ISA_LINE_STATUS.toString(),
                            WorkOrder: valueFil.ISA_WORK_ORDER_NO.toString(),
                            EnteredBy: valueFil.ISA_EMPLOYEE_ID.toString()
                        });
                    }
                });
            }
            this.setState({ OrderNoList: OrderNo, LineNoList: LineNumber, IsLineLevel: true });
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            $('#CancelItem-confirmation').modal('show')
        }
        else {

            let FilArrGrid = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == value.ORDER_NO && x.ISA_LINE_STATUS != "CNC")
            let OrderNo = []
            let LineNumber = []
            if (value != null && value != "") {
                OrderNo.push(value.ORDER_NO);
            }
            if (FilArrGrid.length > 0) {
                FilArrGrid.forEach((valueFil, index) => {
                    if (valueFil != null && valueFil != "") {
                        LineNumber.push({
                            LineNo: valueFil.ISA_INTFC_LN.toString(),
                            ItemId: valueFil.ITEM_ID != null && valueFil.ITEM_ID != undefined ? valueFil.ITEM_ID.toString() : valueFil.ITEM_ID_VEND != null && valueFil.ITEM_ID_VEND != undefined ? valueFil.ITEM_ID_VEND.toString() : "",
                            LineStatus: valueFil.ISA_LINE_STATUS.toString(),
                            WorkOrder: valueFil.ISA_WORK_ORDER_NO.toString()// [05/10/2024 - Zeus 267 - Fix Work is not passing the header level cancellation - Johnprinto D]
                        });
                    }
                });
            }
            this.setState({ OrderNoList: OrderNo, LineNoList: LineNumber, IsLineLevel: false });
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            $('#CancelOrder-confirmation').modal('show')
        }
    }

    bindLineGriddata = (OrderNo, WorkOrderNo) => {
        debugger

        if (this.state.LineDetailsGrid.length > 0) {
            return this.state.LineDetailsGrid.map((value, index) => {

                let checkboxL = false;
                let imagePath = "";
                let status = "";
                let st = "";
                let color = "";
                let apoinfo = "";
                let tracking_num = "-";
                let tracking_link = "-";
                let carrier = "-";
                let IMG_URL = "";
                let tracklist = "";
                let FreightProv = "";
                let trackshipvia = "";
                let SignedBy = "-";
                let buyAgainBool = true;
                let hasCancelStatus = false;
                let shiptoOrAddress = "";
                let shipToLabelName = "Ship To"
                let cancelBtnStatuses = this.state.cancelBtnStatuses;
                if (cancelBtnStatuses.includes(value.ISA_LINE_STATUS)) {
                    hasCancelStatus = true;
                }
                let linedetails = this.state.HeaderGridArray.filter(line => {
                    return line.ORDER_NO == value.ORDER_NO && line.ISA_INTFC_LN == value.LINE_NBR;
                });
                //Commented this part because new logic for fetching next approver has implemented
                /*let NextAppr = this.state.EmpIdDropDown.filter((x) => x.value == value.NEXT_APPR)
                let NA = ""
                let nxt
                if (NextAppr.length > 0) {
                    if (NextAppr[0].value != undefined) {
                        nxt = NextAppr[0].label.split("-")
                        NA = nxt[0]
                    }

                }
                else {
                    if (value.NEXT_APPR == CookieService.getLocalStorageValues("Session_UserID")) {
                        NA = CookieService.getLocalStorageValues("Session_USERNAME")
                    }
                }*/
                //[Zeus-140, 06/28/2023] - Fetching and assigning next approver
                let NA = "";
                let NAData = [];
                if (this.state.ApproverData.length > 0) {
                    NAData = this.state.ApproverData.filter((x) => x.ISA_INTFC_LN == value.LINE_NBR);
                    if (NAData.length > 0) {
                        NAData.map((val) => {
                            if (val.ISA_LINE_STATUS == "QTS") {
                                NA = val.EMPLOYEE_NAME;
                            }
                            else {
                                NA = val.NEXTAPPROVER;
                            }
                        })
                    }
                    else {
                        NA = "-";
                    }
                }
                else {
                    NA = "-";
                }

                if (linedetails[0].APOINFO != null && linedetails[0].APOINFO != undefined) {
                    apoinfo = linedetails[0].APOINFO.split(',');
                    tracklist = linedetails[0].APOINFO.split(',');
                    if (apoinfo[9] != "" && apoinfo[9] != undefined && apoinfo[9] != null) {
                        if (apoinfo[9].trim().length > 0) { tracking_num = apoinfo[9]; }
                    }
                    if (apoinfo[10] != "" && apoinfo[10] != undefined && apoinfo[10] != null) {
                        if (apoinfo[10].trim().length > 0) { carrier = apoinfo[10]; }
                    }
                    if (apoinfo[11] != "" && apoinfo[11] != undefined && apoinfo[11] != null) {
                        if (apoinfo[11].trim().length > 0) {
                            tracking_link = apoinfo[11];
                            console.log("tracking_link ", tracking_link);
                        }
                    }
                    if (apoinfo[12] != "" && apoinfo[12] != undefined && apoinfo[12] != null) {
                        if (apoinfo[12].trim().length > 0) {
                            SignedBy = apoinfo[12];
                        }
                    }

                }

                trackshipvia = carrier;
                // //;
                tracking_num = tracking_num.replace("||", "")

                let imgarr = [];
                let pdfarr = [];

                if (linedetails[0].IMAGE_URL != null && linedetails[0].IMAGE_URL != "" && linedetails[0].IMAGE_URL != undefined) {
                    if (linedetails[0].IMAGE_URL.indexOf('|') !== -1) {
                        //debugger
                        let img = linedetails[0].IMAGE_URL.split('|');

                        img.forEach((x) => {
                            if (x.indexOf('.pdf') != -1) {
                                pdfarr.push(x);
                            } else {
                                let obj = { original: x, thumbnail: x };
                                imgarr.push(obj);
                            }
                        })
                    } else {
                        //debugger
                        let obj = { original: linedetails[0].IMAGE_URL, thumbnail: linedetails[0].IMAGE_URL };
                        imgarr.push(obj);
                    }
                }
                console.log('imgarr', imgarr);
                let valitemdesc = value.DESCR254;
                if (linedetails[0].DESCR254 != null || linedetails[0].DESCR254 != undefined) {
                    linedetails[0].DESCR254 = linedetails[0].DESCR254.includes("&apos;") ? linedetails[0].DESCR254.replaceAll("&apos;", "'") : linedetails[0].DESCR254
                    if (linedetails[0].DESCR254.toUpperCase().includes("RECOMMENDED SUPPLIER")) {
                        let ind = linedetails[0].DESCR254.toUpperCase().indexOf("RECOMMENDED SUPPLIER");
                        let removedreco = linedetails[0].DESCR254.slice(0, ind);
                        valitemdesc = removedreco;
                    }
                    else if (linedetails[0].DESCR254.toUpperCase().includes("SUPPLIER:")) {
                        let ind = linedetails[0].DESCR254.toUpperCase().indexOf("SUPPLIER");
                        let removedreco = linedetails[0].DESCR254.slice(0, ind);
                        valitemdesc = removedreco;

                    } else if (linedetails[0].DESCR254.toUpperCase().includes("-INVITEMICON-")) {
                        valitemdesc = linedetails[0].DESCR254.toUpperCase().split("-INVITEMICON-")
                        valitemdesc = valitemdesc[0];
                    }
                    else {
                        valitemdesc = linedetails[0].DESCR254;
                    }
                }
                debugger
                //[4/26/2023] - Ship to or Address has to be displayed based on its availability - Poornima S

                if (value.ADDRESS != null && value.ADDRESS != "") {
                    shipToLabelName = "Address";
                    shiptoOrAddress = value.ADDRESS;
                }
                else {
                    shipToLabelName = "Ship To";
                    shiptoOrAddress = linedetails[0].SHIPTO_ID != "See line details" ? linedetails[0].SHIPTO_ID : "-";
                }

                //[5/7/23, PC_ZE_85_APP_19, Dhevanesam R]
                if (this.state.Pickup_VendorList.length > 0) {
                    this.state.Pickup_VendorList.map((i) => {
                        if (value.SUPPLIER != "" && value.SUPPLIER != " " && value.SUPPLIER != undefined && value.SUPPLIER != null) {
                            if ((value.LOCATION2 != "" && value.LOCATION2 != " " && value.LOCATION2 != undefined && value.LOCATION2 != null)) {
                                debugger
                                shipToLabelName = "Pickup"
                                //[7/6/2023]Bug 3879 - PS_CO_PU_02 Pick up store not displayed for grainger item - Poornima S
                                shiptoOrAddress = "Pick-Up";
                                if (this.state.Pickup_store_tbl.length > 0) {
                                    this.state.Pickup_store_tbl.forEach((val, index) => {
                                        if ((val.VNDR_LOC == value.LOCATION2 && val.NAME1.toUpperCase() == value.SUPPLIER.toUpperCase() && val.BUSINESS_UNIT_OM == value.BUSINESS_UNIT_OM)) {
                                            shiptoOrAddress = val.ADDRESS1 + "," + val.CITY + "," + val.STATE;
                                        }
                                    })
                                }
                            }
                            else if (value.SUPPLIER.toUpperCase().includes("LOWE'S") || value.SUPPLIER.toUpperCase().includes("HOME DEPOT")) {
                                shipToLabelName = "Pickup"
                                shiptoOrAddress = "Pick-Up";
                            }
                        }
                    })
                }
                //[5/15/23, PC_ZE_85_APP_24, Dhevanesam R]
                if (value.SUPPLIER != "" && value.SUPPLIER != " " && value.SUPPLIER != undefined && value.SUPPLIER != null && value.LINE_FIELD_C6 != "" && value.LINE_FIELD_C6 != " " && value.LINE_FIELD_C6 != undefined && value.LINE_FIELD_C6 != null) {
                    if (value.LINE_FIELD_C6 == "PCH" && (value.SUPPLIER.toUpperCase().includes("LOWE'S") || value.SUPPLIER.toUpperCase().includes("HOME DEPOT"))) {
                        buyAgainBool = false;
                    }
                }
                //ZEUS 11 STOCK ISSUES CART(To hide buy agin checkbox for stock issue orders)[Vishalini]
                //Zeus-241 - Product Replenishmet panel -- to hide buy again check box
                if (value.LINE_FIELD_C6 == "STK" || value.LINE_FIELD_C6 == "REPLN") {
                    buyAgainBool = false;
                }
                //19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto
                let supplierPartNo = '';
                if (value.INV_ITEM_ID && value.INV_ITEM_ID.trim()) {
                    supplierPartNo = value.INV_ITEM_ID;
                } else if (value.ITM_ID_VNDR && value.ITM_ID_VNDR.trim()) {
                    supplierPartNo = value.ITM_ID_VNDR;
                } else {
                    supplierPartNo = value.ITEM_ID;
                }
                return (
                    <tr className="p">
                        <td colSpan={12} className="hiddenRow">
                            <div className="accordian-body" id="order-row-1">

                                <div className="row align-items-center pt-2">

                                    {/*[01/26/2024, Zeus 261] - Invoke redirectToItemDetails() to navigate to Item details page - Kishore*/}
                                    <div className="ord_img col-md-2 text-center back-icon" onClick={() => { if (value.LINE_FIELD_C6 && value.LINE_FIELD_C6.trim() != "" && value.LINE_FIELD_C6 != 'NSTK' && value.LINE_FIELD_C6 != 'PCH') { redirectToItemDetails(supplierPartNo, value.MFG_ITM_ID, value.SUPPLIERID, "OrderStatus", this.props) } }}>
                                        {imgarr.length > 1 ? <img className="ord_img_img" onError={(e) => { e.target.src = "/Images/noimage_new.png" }} id={`LineImg-${value.LINE_NBR}-${index}`} src={imgarr[0].original} onClick={() => this.imageclicked(imgarr)} style={{ "width": '120px', "height": '130px' }} /> : imgarr.length == 0 ? <img src="/Images/noimage_new.png" onError={(e) => { e.target.src = "/Images/noimage_new.png" }} style={{ "width": '120px', "height": '130px' }} /> : <img src={imgarr[0].original} onError={(e) => { e.target.src = "/Images/noimage_new.png" }} style={{ "width": '120px', "height": '130px' }} />}
                                        {/*<div className="ord_img_icon"><img src="/Images/info-icon.png" id={`LineImg-${value.LINE_NBR}-${index}`} onError={(e) => { e.target.src = "/Images/noimage_new.png" }} style={{ "width": "25px", "height": "25px" }} /></div>*/}
                                    </div>

                                    <div className="col-md-10">

                                        <div className="row">

                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`Line#L-${value.LINE_NBR}-${index}`}><Trans>Line</Trans> <span> # :</span></label>
                                                <label className="item-details-value" id={`Line#-${value.LINE_NBR}-${index}`}>{value.LINE_NBR}</label>
                                            </div>

                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`ItemKeyL-${value.Item_NBR}-${index}`}><Trans>Item ID</Trans><span> :</span></label>
                                                {!value.LINE_FIELD_C6 || value.LINE_FIELD_C6.trim() == "" || value.LINE_FIELD_C6 == 'NSTK' || value.LINE_FIELD_C6 == 'PCH' ?
                                                    <label className="item-details-value" id={`ItemKey-${value.MFG_ITM_ID}-${index}`}>{value.MFG_ITM_ID}</label>
                                                    :
                                                    <label className="item-details-value hyper-link back-icon" id={`ItemKey-${value.MFG_ITM_ID}-${index}`} onClick={() => redirectToItemDetails(supplierPartNo, value.MFG_ITM_ID, value.SUPPLIERID, "OrderStatus", this.props)}>{value.MFG_ITM_ID}</label>
                                                }
                                                {value.ItemType == "ORO" ?
                                                    <span className="oro-item" >O</span> : null}
                                                {value.ItemType == "VMI" ?
                                                    <span className="oro-item vmi-item" >V</span> : null}
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`ItemKeyL-${value.Item_NBR}-${index}`}><Trans>Business Unit</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`ItemKey-${value.Item_NBR}-${index}`}>{value.BUSINESS_UNIT_OM}</label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`QTYL${value.LINE_NBR}-${index}`}>QTY :</label>
                                                <label className="item-details-value" id={`QTY${value.LINE_NBR}-${index}`}>{value.QTY_REQ}</label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`QTYRecvL-${value.LINE_NBR}-${index}`}>QTY Received :</label>
                                                <label className="item-details-value" id={`QTYRecv-${value.LINE_NBR}-${index}`}>{value.QTY_RECEIVED}</label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`OrderForL-${value.LINE_NBR}-${index}`}><Trans>Ordered For</Trans><span> :</span></label>
                                                <label className="item-details-value " id={`OrderFor-${value.LINE_NBR}-${index}`}>{value.EMPNAME}</label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`StatusL-${value.LINE_NBR}-${index}`}><Trans>Status</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`Status-${value.LINE_NBR}-${index}`}>{value.LINE_STATUS == null || value.LINE_STATUS.Trim == "" ? 'Unknown' : value.LINE_STATUS}</label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`PriorityL-${value.LINE_NBR}-${index}`}><Trans>Priority</Trans> <span> :</span></label>
                                                <label className="item-details-value priority-alert" id={`Priority-${value.LINE_NBR}-${index}`}> {value.LINE_PRIOR}</label>
                                            </div>
                                            {/*[5/15/23, PC_ZE_85_APP_24, Dhevanesam R]*/}
                                            {buyAgainBool ? <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" htmlFor={`OrderStatus-${value.LINE_NBR}-${index + 1}`} id={`OrderStatus-${value.LINE_NBR}-${index + 1}`} ><Trans>Buy again </Trans><span>:</span></label>
                                                <input type="checkbox" className="item-details-value my-1" checked={linedetails[0].checked} id={`OrderStatus-${value.LINE_NBR}-${index + 1}`} name="example1" onChange={() => this.selectbuyagainOrder(value)} />
                                            </div> : null}

                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`SignedByL${value.LINE_NBR}-${index}`}><Trans>Signed By</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`SignedBy${value.LINE_NBR}-${index}`}>{SignedBy}</label>
                                            </div>

                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`Tracking#-${value.LINE_NBR}-${index}`}><Trans>Tracking</Trans><span> # :</span></label>

                                                {tracking_num.length == 1 || tracking_link.length < 7 ?
                                                    <label id={`TrackingL-${value.LINE_NBR}-${index}`} className="item-details-value">{tracking_num}</label> :
                                                    <a id={`Tracking-${value.LINE_NBR}-${index}`} href={tracking_link} target="_blank">{tracking_num} </a>
                                                }
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`HistoryL-${value.LINE_NBR}-${index}`}><Trans>More Details</Trans> <span> :</span></label>
                                                <a href="#" id={`History-${value.LINE_NBR}-${index}`} data-toggle="modal" data-target="#order-approval-history" data-backdrop="static" data-keyboard="false" className="history-icon ml-1" onClick={(e) => this.Approvalhistory(value)} />
                                            </div>

                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`POIDL-${value.LINE_NBR}-${index}`}><Trans>PO ID:</Trans></label>
                                                <a href="#" id={`POID-${value.LINE_NBR}-${index}`} className="order-id" data-toggle="modal" data-target="#po-details" onClick={(e) => this.PODetailPopup(value)}>{value.PO_ID1}</a>
                                                <div>
                                                    {value.CANCELLED_PO != "" && value.CANCELLED_PO != null && value.CANCELLED_PO != undefined ? <span id={`POID-${value.LINE_NBR}-${index}1`} style={{ "color": "red" }} className="order-id" data-toggle="modal" data-target="#po-details" onClick={(e) => this.PODetailPopup(value.CANCELLED_PO)}><del style={{ "cursor": "pointer" }}>{value.CANCELLED_PO}</del></span> : null}
                                                </div>
                                            </div>

                                            {/*23/7/2024 - Zeus 349 - Swap Required by date and Expected delivery date - Shanmugapriya*/}
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`RequiredByDateL-${value.LINE_NBR}${index}`}><Trans>Required By Date</Trans><span> :</span></label>
                                                <label className="item-details-value " id={`RequiredByDate-${value.LINE_NBR}${index}`}>{value.REQ_DATE}</label>
                                            </div> 

                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`NextApproverL-${value.LINE_NBR}-${index}`}><Trans>Next Approver</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`NextApprover-${value.LINE_NBR}-${index}`}>{NA} </label>
                                            </div>

                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`Supplier-${value.LINE_NBR}-${index}`}><Trans>Supplier</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`Supplier-${value.LINE_NBR}-${index}`}>{value.SUPPLIER != null && value.SUPPLIER.trim() != "" ? value.SUPPLIER : "-"} </label>
                                            </div>
                                            {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`Supplierpartno-${value.LINE_NBR}-${index}`}><Trans>Supplier</Trans> <Trans>Part</Trans><span> # :</span></label>
                                                <label className="item-details-value" id={`Supplierpartno-${value.LINE_NBR}-${index}`}>{supplierPartNo != null && supplierPartNo.trim() ? supplierPartNo : "-"} </label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`DescriptionL-${value.LINE_NBR}-${index}`}><Trans>Description</Trans><span> :</span></label>
                                                <label className="item-details-value desc-break" id={`Description-${value.LINE_NBR}-${index}`}>{valitemdesc} </label>

                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`Pick-UpL-${value.LINE_NBR}-${index}`}><Trans>{shipToLabelName}</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`Pick-Up-${value.LINE_NBR}-${index}`}>{shiptoOrAddress}</label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`DeliveryPt-${value.LINE_NBR}-${index}`}><Trans>Delivery Point</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`DeliveryPt-${value.LINE_NBR}-${index}`}>{value.ISA_UNLOADING_PT}</label>
                                            </div>
                                            <div className="col-md-3 mb-2">
                                                <label className="catalog-label mr-2" id={`Location-${value.LINE_NBR}-${index}`}><Trans>Location</Trans><span> :</span></label>
                                                <label className="item-details-value" id={`Location-${value.LINE_NBR}-${index}`}>{value.BILL_TO_STORE && value.BILL_TO_STORE.trim() != "" ? value.BILL_TO_STORE : "-"}</label>
                                            </div>
                                            {value.ISA_LINE_NOTES ?
                                                <div className="col-md-3 mb-2">
                                                    <label className="catalog-label mr-2" id={`Notes-${value.LINE_NBR}-${index}`}><Trans>Notes</Trans><span> :</span></label>
                                                    <a href="" className="item-details-value category-search-result back-icon" id={`Notes-${value.LINE_NBR}-${index}`} onClick={(e) => this.displayNotes(e, value.ORDER_NO, value.LINE_NBR)}>Read Notes</a>
                                                </div> : null} 
                                            {linedetails[0].IMAGE_URL == null ? null : linedetails[0].IMAGE_URL.indexOf('.pdf') != -1 ? pdfarr.length > 0 ? <div className="col-md-3 pr-0 mb-2">

                                                <label className="catalog-label mr-2" id={`pdfarrL-${value.LINE_NBR}-${index}`}><Trans>Attachment</Trans><span> :</span></label>
                                                <label className="item-details-value">
                                                    {pdfarr.map((x, Index) => {
                                                        return (
                                                            <a style={{ "marginRight": "5px", "textDecoration": "underLine" }} id={`pdfarr-${value.LINE_NBR}-${index}`} href={x} download target="_blank">File{Index + 1}</a>
                                                        )
                                                    })}
                                                </label>
                                            </div> : <div className="col-md-6 mb-2">
                                                    <label className="catalog-label mr-2" id={`AttachmentL-${value.LINE_NBR}-${index}`}><Trans>Attachments</Trans><span> :</span></label>
                                                    <a style={{ "textDecoration": "underLine" }} href={linedetails[0].IMAGE_URL} id={`Attachment-${value.LINE_NBR}-${index}`} download target="_blank">File</a>
                                                </div> : null}
                                        </div>

                                    </div>

                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-labeled btn-primary custom-btn-primary primary-btn mr-2" id={`Pass-${value.LINE_NBR}-${index}`} id="PassAuthe" onClick={() => this.PassAutheLine(value, WorkOrderNo)}>Incident</button>

                                        <div class="d-inline-block">
                                            {hasCancelStatus == false ? <div>
                                                <button className="btn btn-labeled btn-primary custom-btn-primary primary-btn mr-4 " id={`cancel-${value.LINE_NBR}-${index}`} id="CancelOrderBtn" onClick={() => this.CancelLineLevelItem(value, "Line")}>Cancel</button>
                                            </div> : null}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </td>
                    </tr>

                )
            });

        }

    }

    bindHeaderDataGrid = () => {
        debugger
        if (this.state.HeaderGridArray.length > 0) {
            let locGridArr = this.state.HeaderGridArray.filter((v, i, a) => a.findIndex(t => (t.ORDER_NO.trim().toUpperCase() === v.ORDER_NO.trim().toUpperCase())) === i);
            let status;
            let st;
            let color;
            let imagePath;

            return locGridArr.map((value, index) => {
                let orderNo = value.ORDER_NO;
                let orderArray = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == orderNo);
                let lineItem = this.state.HeaderGridArray.filter((x) => x.ORDER_NO == orderNo);
                let hasCancelStatus = false;
                let hasApprovalChain = false;
                let cancelBtnStatuses = this.state.cancelBtnStatuses;
                orderArray.forEach((val, ind) => {
                    if (cancelBtnStatuses.includes(val.ISA_LINE_STATUS)) {
                        hasCancelStatus = true;
                    }
                    if (value.LINE_STATUS == 'QTW' && val.ISA_LINE_STATUS == 'QTW') {
                        hasApprovalChain = true;
                        value.NEXTAPPROVER = val.NEXTAPPROVER;
                    }
                })
                if (!hasApprovalChain || !value.NEXTAPPROVER || !value.NEXTAPPROVER.trim()) {
                    hasApprovalChain = false;
                }

                //[8/2/2024 - Zeus-317 - Set the records per page value in browser cookies - Shanmugapriya]
                if (index < parseInt(this.state.PageNo) * this.state.RecCount && index >= parseInt(this.state.PageNo - 1) * this.state.RecCount) {

                    if (value.ORDER_STATUS != "" && value.ORDER_STATUS != null) {
                        status = value.ORDER_STATUS;
                        st = status.split('^')[1];
                        color = status.split('^')[4];
                        if (color.toUpperCase() == "RED") {
                            color = "btn-red"
                        }
                        else if (color.toUpperCase() == "YELLOW") {
                            color = "btn-yellow"
                        }
                        else if (color.toUpperCase() == "GREEN") {
                            color = "btn-green"
                        }

                        imagePath = ((status.split('^')[3]) == 1 ? "images/chain0.png" :
                            (status.split('^')[3]) == 2 ? "images/chain1.png" :
                                (status.split('^')[3]) == 3 ? "images/chain2.png" :
                                    (status.split('^')[3]) == 4 ? "images/chain3.png" :
                                        (status.split('^')[3]) == 5 ? "images/chain4.png" : "images/chain6.png")

                    }
                    else {
                        imagePath = "images/chain0.png";
                        st = "Unknown";
                        color = "btn-gray";
                    }
                    return (
                        <React.Fragment key={value.ORDER_NO + index}>
                            {value.ORDER_NO != "" ? <>
                                <tr style={{ "height": "75px" }}>
                                    <td className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, index, value.ORDER_NO, value.BU)} >
                                        <span className="accordion-arrow header-arrow" id={`arrow-${index}`} />
                                    </td>
                                    <td className="d-sm-none d-md-table-cell" style={{ "white-space": "nowrap" }}><div ><a href="#" className="order-id" data-toggle="modal" id={`OrderNo-${index}`} data-backdrop="static" data-keyboard="false" data-target=".orderstatus-popup" onClick={(e) => this.OrderStatusPopup(value)} >{value.ORDER_NO}</a>
                                        {value.ISA_LINE_NOTES ?
                                            <a href="#" className="orderNotes" data-toggle="modal" id={`orderNotes-${index}`} onClick={(e) => this.displayNotes(e, value.ORDER_NO, 0)} ><img src="Images/notes-icon.svg" style={{ "width": '20px', "margin-left": '6px' }} alt="close"></img></a> : null}
                                    </div></td>

                                    <td className="d-none d-sm-none d-md-table-cell order-status-workorder" ><div style={{ width: '85px' }} id={`WO#-${index}`}>{value.ISA_WORK_ORDER_NO}</div></td>
                                    <td className="d-none d-sm-none d-md-table-cell" ><div style={{ width: '80px' }} id={`ASSETID-${index}`}>{value.ISA_MACHINE_NO}</div></td>
                                    <td className="d-none d-sm-none d-md-table-cell" ><div style={{ width: '110px' }} data-toggle="tooltip" title={value.WODESC} data-label="WODESC" id={`WODESC-${index}`}>{value.WODESC != null && value.WODESC.length > 15 ? `${value.WODESC.substring(0, 13)}...` : value.WODESC}</div></td>

                                    {/*<td className="d-none d-sm-none d-md-table-cell"><div style={{ width: '110px' }} id={`billtostore-${index}`}>{value.SHIPTO_ID.trim() != "-" && value.SHIPTO_ID.trim() != "-0" ? value.SHIPTO_ID :  "See line details" }</div></td>*/}

                                    <td className="d-none d-sm-none d-md-table-cell"><div style={{ 'word-break': 'break-word' }} data-toggle="tooltip" title={value.ENTEREDBYNAME} data-label="ENTEREDBYNAME" id={`ENTEREDEMPNAME-${index}`}>{value.ENTEREDBYNAME.length > 15 ? `${value.ENTEREDBYNAME.substring(0, 11)}...` : value.ENTEREDBYNAME}</div></td>
                                    <td className="d-none d-sm-none d-md-table-cell"><div style={{ width: '80px' }} id={`ORD_DATE-${index}`}>{value.ORD_DATE}</div></td>{/*23/7/2024 - Zeus 349 - Swap Required by date and Expected delivery date - Shanmugapriya*/}
                                    <td className="d-none d-sm-none d-md-table-cell"><div style={{ width: '80px' }} id={`EXP_DELIVERY_DATE-${index}`}>{value.EXPECTED_DATE}</div></td>

                                    <>
                                        <td className="d-none d-sm-none d-md-table-cell">
                                            <div className="float-left text-nowrap  mt-2">

                                                <img src={imagePath} onError={(e) => { e.target.src = "/Images/noimage_new.png" }} id={`StatusImg-${index}`} width="100" alt="status-img" className="status-img" style={{ width: '141px', marginTop: '-4px', marginRight: '0px', "height": "12px" }} />

                                                <button className={`btn ${color} `} id={`StatusId-${index}`} style={{ pointerEvents: "none", fontSize: '13px', padding: '4px 15px', "margin-top": '-4px' }}>{st}</button>
                                            </div>

                                        </td>
                                    </>
                                    <td className="d-none d-sm-none d-md-table-cell text-center">
                                        <div className="dropdown">
                                            <button className="btn dots-style" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src="images/3-dots.svg" alt="3-dots" />
                                            </button>
                                            <div className="dropdown-menu action-dropdown-style ml-5 px-2" aria-labelledby="dropdownMenuButton">
                                                {hasApprovalChain ? <a className="dropdown-item dropdown-style Font-14 font-medium cursor-pointer" onClick={(e) => this.GetApproversList(value.ISA_EMPLOYEE_ID, value.ORDER_NO, lineItem, value.ISA_WORK_ORDER_NO)}>
                                                    <img src="images/approver-chain.svg" className="approver-chain ps-1" alt="approver-chain" />
                                                    Approval Chain
                                                     </a> : null}
                                                {/*[21/12/2023] PS_OS-03 Zeus 235 added the incident button & it's functionalities - Harshitha */}
                                                <a className={`dropdown-item dropdown-style Font-14 font-medium cursor-pointer ${hasApprovalChain ? 'border-top' : ''}`} onClick={(e) => this.PassAutheLine(value, value.ISA_WORK_ORDER_NO)}>
                                                    <img src="images/Incident.svg" className="incident-style ps-1" alt="incident" />
                                                    Incident
                                                    </a>
                                                {!hasCancelStatus ? <a className={`dropdown-item dropdown-style Font-14 font-medium cursor-pointer ${!hasCancelStatus ? 'border-top' : ''}`} id={`${value.ORDER_NO}cancel-${index}`} onClick={(e) => this.CancelLineLevelItem(value, "Order")}>
                                                    <img src="images/cancel-order.svg" className="cancel-order ps-1" alt="cancel-order" />
                                                    Cancel Order
                                                    </a> : null}
                                            </div>
                                        </div>
                                    </td>
                                    {/*<td className="d-none d-sm-none d-md-table-cell">
                                        <div className="text-nowrap">
                                            {value.LINE_STATUS == 'QTW' && value.NEXTAPPROVER != null && value.NEXTAPPROVER.trim() != "" ? <button className="btn btn-primary custom-btn-primary primary-btn" onClick={(e) => this.GetApproversList(value.USERID, value.ISA_EMPLOYEE_ID, value.NEXTAPPROVER, value.ORDER_NO, lineItem)}>Approval chain</button> : null}
                                        </div>
                                    </td>
                                    <td className="d-none d-sm-none d-md-table-cell"> {hasCancelStatus == false ? <div>
                                        <button id={`${value.ORDER_NO}cancel-${index}`} className="btn btn-labeled btn-primary custom-btn-primary primary-btn float-left py-0 mr-2 mb-2 mt-2 btn-continue" id="CancelOrderBtn" onClick={(e) => this.CancelLineLevelItem(value, "Order")}>Cancel</button>
                                    </div> : null}</td>*/}


                                </tr>
                                <tr className="p d-none content-row" id={`contentrow-${index}`}>
                                    <td colSpan="12" >
                                        {this.state.LineDetailsGrid.length > 0 ? <div className="accordian-body" id="order-row-1">
                                            <div className="row justify-content-center">
                                                <div className="col-md-12">
                                                    <table className="table ZEUS_Grid FOM-grid chk-ordertbl mt-3">
                                                        <tbody>

                                                            <h4 className="item-details-title mb-3"><Trans>Item Details</Trans></h4>
                                                            {this.bindLineGriddata(value.ORDER_NO, value.ISA_WORK_ORDER_NO)}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div> :
                                            <div style={{ "display": "flex", "justifyContent": "center" }} id="RotatingLinesId">
                                                <RotatingLines
                                                    strokeColor="#fcb913"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="50"
                                                    visible={true} />
                                            </div>}
                                    </td>
                                </tr>
                            </> : null}
                        </React.Fragment>
                    )


                }
            });

        }
    }

    //[5/9/2023]Zeus -100 PTA link for incident button API call - Poornima
    PassAutheLine = (val, WorkOrderNo) => {
        debugger
        let customFields = []
        customFields.push({
            customFieldName: "order_number",
            customFieldValue: val.ORDER_NO
        })
        let PassAutheBO = {
            UserID: CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            UserBu: CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            EmailID: CookieService.getLocalStorageValues("Session_EMAIL"),
            IsThirdParty: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") != "0" && CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") != null ? true : false,
            PassWord: "",
            OrderNumber: val.ORDER_NO,
            PONumber: val.PO_ID1,
            HomepageRequest: false,
            customFields: customFields,
            WorkOrder: WorkOrderNo //[10/16/2023]Zeus 215 passing Workorder num to OSC - Poornima S
        }
        OrderStatusAction.getPassThroughAutheURl(PassAutheBO)
    }
    PassThroughAutheResponse = () => {
        debugger
        console.log("OrderStatusStore.PassThroughAuthe", OrderStatusStore.PassThroughAuthe)
        let URl = JSON.parse(OrderStatusStore.PassThroughAuthe)
        console.log("URl", URl)
        window.open(URl);
        window.preventDefault();
    }

    //***********GridBind********************************
    bindDataGrid = () => {
        debugger
        let locAddress = "_";
        let shipToLabelName;
        let shiptoOrAddress;
        const { t } = this.props;
        if (this.state.GridArr.length > 0) {
            console.log("grid invitemid", this.state.GridArr)
            debugger
            return this.state.GridArr.map((value, index) => {
                let checkboxL = false;
                debugger;
                if (Session_BUSUNIT == value.BUSINESS_UNIT_OM) {
                    if ((value.LINEFIELD == "STK") || (value.LINEFIELD == "ZEUS") || (value.LINEFIELD == "PCH") || (value.LINEFIELD == "RFQ") || (value.LINEFIELD == "NSTK")) {
                        checkboxL = true;
                    }

                }
                if (this.state.AddressDetails != null && this.state.AddressDetails != undefined) {
                    console.log("addressloc", this.state.AddressDetails)
                    locAddress = this.state.AddressDetails.filter((X) => X.ORDER_NO == value.ORDER_NO);
                    console.log("address", locAddress)
                }
                if (value.SHIPTO_ID != null && value.SHIPTO_ID != "") {
                    if (value.SHIPTO_ID.trim() != "_") {
                        shipToLabelName = "Ship To";
                        shiptoOrAddress = value.SHIPTO_ID;
                    }
                    else {
                        shipToLabelName = "Address";
                        shiptoOrAddress = locAddress[0].ADDRESS;
                    }
                }
                else {
                    shipToLabelName = "Address";
                    shiptoOrAddress = locAddress[0].ADDRESS;
                }
                //[5/7/23, PC_ZE_85_APP_19, Dhevanesam R]
                if (this.state.Pickup_VendorList.length > 0) {
                    this.state.Pickup_VendorList.map((i) => {
                        if (value.SUPPLIER != "" && value.SUPPLIER != " " && value.SUPPLIER != undefined && value.SUPPLIER != null) {
                            if ((value.LOCATION2 != "" && value.LOCATION2 != " " && value.LOCATION2 != undefined && value.LOCATION2 != null)) {
                                shipToLabelName = "Pickup"
                                if (this.state.Pickup_store_tbl.length > 0) {
                                    this.state.Pickup_store_tbl.forEach((val, index) => {
                                        if ((val.VNDR_LOC == value.LOCATION2 && val.NAME1.toUpperCase() == value.SUPPLIER.toUpperCase() && val.BUSINESS_UNIT_OM == value.BUSINESS_UNIT_OM)) {
                                            shiptoOrAddress = val.ADDRESS1 + "," + val.CITY + "," + val.STATE;
                                        }
                                        if (value.SUPPLIER.toUpperCase().includes("GRAINGER") || value.SUPPLIER.toUpperCase().includes("DIVERSIFIED SUPPLY")) {
                                            shiptoOrAddress = "Pick-Up";
                                        }
                                    })
                                }
                                else {
                                    shiptoOrAddress = "Pick-Up";
                                }
                            }
                            else {
                                if (value.SUPPLIER.toUpperCase().includes("LOWE'S") || value.SUPPLIER.toUpperCase().includes("HOME DEPOT")) {
                                    shipToLabelName = "Pickup"
                                    shiptoOrAddress = "Pick-Up";
                                }
                            }
                        }

                    })

                }


                return (
                    <React.Fragment key={value.ORDER_NO + index}>

                        <tr>



                            <td className="text-center">
                                {checkboxL ?
                                    <div className="custom-control custom-checkbox item-custom-control">

                                        <input type="checkbox" className="custom-control-input" id={`OrderStatus${index + 1}`} name="example1" onChange={() => this.selectOrder(index)} />
                                        <label className="custom-control-label" htmlFor={`OrderStatus${index + 1}`} />
                                    </div> : null}
                            </td>
                            <td className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, index)} >
                                <span className="accordion-arrow header-arrow" id={`arrow-${index}`} />
                            </td>
                            <td className="d-sm-none d-md-table-cell"><a href="#" className="order-id" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target=".orderstatus-popup" onClick={(e) => this.OrderStatusPopup(value)}>{value.ORDER_NO}</a></td>
                            <td>{value.LINE_NBR}</td>
                            <td>{value.A_MY_STATUS}</td>
                            <td className="d-none d-sm-none d-md-table-cell" >{value.INV_ITEM_ID}</td>
                            <td className="d-none d-sm-none d-md-table-cell" >{value.ISA_WORK_ORDER_NO}</td>
                            <td className="d-none d-sm-none d-md-table-cell">{value.BUSINESS_UNIT_OM}</td>
                            <td className="d-none d-sm-none d-md-table-cell priority-alert">{value.LINE_PRIOR}</td>
                            <td className="d-none d-sm-none d-md-table-cell">{value.ENTEREDEMPNAME}</td>
                            <td className="d-none d-sm-none d-md-table-cell">{value.OPRID_ENTERED_BY}</td>
                            <td className="d-none d-sm-none d-md-table-cell"><a href="#" className="order-id" data-toggle="modal" data-target="#po-details" onClick={(e) => this.PODetailPopup(value)}>{value.PO_ID1}</a></td>
                        </tr>
                        {/*[4/20/22, CAS -31, Dhevanesam R]*/}
                        <tr className="p d-none content-row" id={`contentrow-${index}`}>
                            <td colSpan={12} className="hiddenRow " id="order-row-1" >
                                <div className="py-1" id={`order-row-${index}`}>
                                    <h4 className="item-details-title pl-5 grid-padding-section"><Trans>Item Details</Trans></h4>
                                    <div className="row mx-0 pb-3">
                                        <div className="col-md-12 pl-5 grid-padding-section">
                                            <div className="row">
                                                <div className="col-md-7 mb-1 responsive-iphone-grid">
                                                    <div className="row">
                                                        <div className="col-12 mobile-fieldscontainer p-0">
                                                            <div className="col-12 mobile-fields responsive-iphone-grid">
                                                                <label className="catalog-label float-none"><Trans>Item ID</Trans>:</label>
                                                                <label className="item-details-value float-none">{value.INV_ITEM_ID}</label>
                                                            </div>
                                                            <div className="col-12 mobile-fields">
                                                                <label className="catalog-label float-none"><Trans>workorder_key</Trans>:</label>
                                                                <label className="item-details-value float-none">{value.ISA_WORK_ORDER_NO}</label>
                                                            </div>
                                                            <div className="col-12 mobile-fields">
                                                                <label className="catalog-label float-none"><Trans>Business Unit</Trans>:</label>
                                                                <label className="item-details-value float-none">{value.BUSINESS_UNIT_OM}</label>
                                                            </div>
                                                            <div className="col-12 mobile-fields">
                                                                <label className="catalog-label float-none"><Trans>Priority</Trans>:</label>
                                                                <label className="item-details-value float-none">{value.LINE_PRIOR}</label>
                                                            </div>
                                                            <div className="col-12 mobile-fields">
                                                                <label className="catalog-label float-none"><Trans>Order Entered by</Trans>:</label>
                                                                <label className="item-details-value float-none">{value.ENTEREDEMPNAME}</label>
                                                            </div>
                                                            <div className="col-12 mobile-fields">
                                                                <label className="catalog-label float-none"><Trans>Ordered For</Trans>:</label>
                                                                <label className="item-details-value float-none">{value.OPRID_ENTERED_BY}</label>
                                                            </div>
                                                            <div className="col-12 mobile-fields">
                                                                <label className="catalog-label float-none"><Trans>PO Id</Trans>:</label>
                                                                <a href="#" className="order-id" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#po-details" onClick={(e) => this.PODetailPopup(value)}>{value.PO_ID1}</a>


                                                            </div>
                                                        </div>
                                                        {/*04/11/22, CAS - Order Status UI Issue, Dhevanesam R*/}
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2"><Trans>Order Date</Trans>:</label>
                                                            <label className="item-details-value float-none">{value.ORD_DATE}</label>
                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>qty</Trans>:</label>
                                                            <label className="item-details-value float-none">{value.QTY_REQ}</label>
                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>QTY Received</Trans>:</label>
                                                            <label className="item-details-value float-none">{value.QTY_RECEIVED}</label>
                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Delivery Date</Trans>:</label>
                                                            <label className="item-details-value float-none">{value.REQ_DATE}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5 mb-1 responsive-iphone-grid">
                                                    <div className="row">
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Revised Due Date</Trans>:</label>
                                                            <label className="item-details-value float-none">{!value.REV_DUE_DT1 ? "-" : value.REV_DUE_DT1}</label>
                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Approved By</Trans>:</label>
                                                            <label className="item-details-value float-none pl-1">{value.OPRID_APPROVED_BY}</label>
                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Currency</Trans>:</label>
                                                            <label className="item-details-value float-none pl-1">{value.CURRENCY}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 mb-1 responsive-iphone-grid">
                                                    <div className="row">
                                                        <div className="col-md-6 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-left p-0 descrip"><Trans>Description</Trans>:</label>
                                                            {/*5/23/22, PC_OS_01, Bug - 2141, Dhevanesam R*/}
                                                            <label className="item-details-value col-md-8 pl-1 p-0 float-none" style={{ "word-wrap": "break-word" }}>{value.DESCR254}</label>
                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Machine Number</Trans>:</label>
                                                            <label className="item-details-value float-none pl-1">{value.ISA_MACHINE_NO}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5 mb-1 responsive-iphone-grid">
                                                    <div className="row">
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Ship Document</Trans>:</label>
                                                            {this.shiptobind(value)}
                                                        </div>

                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Tracking Number</Trans>:</label>
                                                            {value.A_TRACK_SHIPVIA == "UPS" ?
                                                                <label className="item-details-value float-none">{value.A_TRACK_NO}</label> :
                                                                <a href={"http://wwwapps.ups.com/WebTracking/processInputRequest?HTMLVersion=5.0&sort_by=status&term_warn=yes&tracknums_displayed=5&TypeOfInquiryNumber=T&loc=en_US&InquiryNumber1= " + value.A_TRACK_NO + "&InquiryNumber2=&InquiryNumber3=&InquiryNumber4=&InquiryNumber5=&AgreeToTermsAndConditions=yes&track.x=25&track.y=9"} target="_blank">{value.A_TRACK_NO} </a>
                                                            }
                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>History</Trans>:</label>
                                                            <a href="#" data-toggle="modal" data-target="#order-approval-history" data-backdrop="static" data-keyboard="false" className="history-icon float-none" onClick={(e) => this.Approvalhistory(value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 mb-1 responsive-iphone-grid">
                                                    <div className="row">
                                                        <div className="col-md-6 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-left p-0 descrip">{t(shipToLabelName)}:</label>
                                                            <label className="item-details-value col-md-8 pl-1 p-0 float-none">{shiptoOrAddress}</label>

                                                        </div>
                                                        <div className="col-md-6 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>Charge Code</Trans>:</label>
                                                            <label className="item-details-value col-md-8 pl-1 p-0 float-none">{value.ISA_CUST_CHARGE_CD}</label>

                                                        </div>
                                                        <div className="col-md-3 pr-0 mb-2 fav-maintence responsive-iphone-grid">
                                                            <label className="catalog-label mr-2 float-none"><Trans>RFQ order and line number</Trans>:</label>
                                                            <label className="item-details-value float-none pl-1">{value.REF_ORDER_AND_LINE_NO}</label>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            });
        }
    }
    PoStatus = (val) => {


        debugger;
        if (this.state.POData.length > 0) {
            let status = "Active";
            let value = val.CANCEL_STATUS;
            console.log("cancel status", value);
            debugger;
            switch (value) {
                case "A":
                    status = "Active"
                    break;
                //return <td>Active</td>
                case 'C':
                    status = "Closed"
                    break;
                //return <td>Closed</td>
                case 'D':
                    status = "Denied"
                    break;
                case 'H':
                    status = "Hold"
                    break;
                case 'O':
                    status = "Open"
                    break;
                case 'P':
                    status = "Pending"
                    break;
                case 'X':
                    status = "Canceled"
                    break;

            }
            return (
                <td>{status}</td>
            );
        }
    }

    PoHdStatus = () => {

        if (this.state.POData.length > 0) {
            console.log("status", this.state.POData[0].PO_STATUS);
            let Hdstatus = "Initial";
            let val = this.state.POData[0].PO_STATUS;
            switch (val) {
                case 'A':
                    Hdstatus = "Approved"
                    break;
                case 'C':
                    Hdstatus = "Complete"
                    break;
                case 'D':
                    Hdstatus = "Dispatched"
                    break;
                case 'DA':
                    Hdstatus = "Denied"
                    break;
                case 'I':
                    Hdstatus = "Initial"
                    break;
                case 'LD':
                    Hdstatus = "Line Denied"
                    break;
                case 'O':
                    Hdstatus = "Open"
                    break;
                case 'PA':
                    Hdstatus = "Pend Appr"
                    break;
                case 'PX':
                    Hdstatus = "Pend Cncl"
                    break;
                case 'X':
                    Hdstatus = "Canceled"
                    break;


            }
            return (
                <label className="info-value pl-1">{Hdstatus}</label>

            );
        }
    }




    //*****************GridBind End****************************
    //************************************PoDettil grid bind********************
    bindPODataGrid = () => {
        debugger;
        if (this.state.POData.length > 0) {
            return this.state.POData.map((value, index) => {

                return (
                    <>
                        <tr>
                            {/* <td data-toggle="collapse" data-target="#order-row-1-popup" className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, index)}>*/}
                            <td className="accordion-toggle" onClick={(e) => this.onAccordianPOClick(e, index)} >
                                <span className="accordion-arrow header-arrow tryH" id={`arrowPOHeader-${index}`} />
                            </td>
                            <td>{value.REQ_LINE_NBR}</td>
                            <td>{value.LINE_NBR}</td>
                            <td className="d-none d-sm-none d-md-table-cell">{value.SCHED_NBR}</td>
                            {this.PoStatus(value)}
                            <td className="d-none d-sm-none d-md-table-cell">{value.ORIG_DUE_DT}</td>
                            <td className="d-none d-sm-none d-md-table-cell">{value.DUE_DT}</td>
                            <td className="d-none d-sm-none d-md-table-cell">{value.req_by_date}</td>
                            <td className="text-right d-none d-sm-none d-md-table-cell">{value.QTY_PO}</td>
                            <td className="text-right d-none d-sm-none d-md-table-cell">{value.QTY_LN_RECVD == " " ? "0.00" : value.QTY_LN_RECVD}</td>
                            <td className="text-right d-none d-sm-none d-md-table-cell">{value.QTY_LN_REJCT == " " ? "0.00" : value.QTY_LN_REJCT}</td>
                            {this.state.showprice != "0" && this.state.blockprice == "N" ? <td className="text-right">{value.purchase_price}</td> : null}
                        </tr>
                        <tr className="p d-none content-row try" id={`contentPOrow-${index}`}>
                            <td colSpan={12} className="hiddenRow">
                                <div className="py-1">
                                    <div className="row mx-0 pb-3">
                                        <div className="col-md-12 pl-5">
                                            <div className="row">
                                                <div className="col-md-12 mb-1">
                                                    <div className="row mx-0 pb-3">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-12 mb-1">
                                                                    <div className="row">
                                                                        <div class="col-12 mobile-fieldscontainer p-0">
                                                                            <div class="col-12 mobile-fields">
                                                                                <label class="catalog-label"><Trans>Sch Number</Trans>:</label>
                                                                                <label class="item-details-value">{value.SCHED_NBR}</label>
                                                                            </div>
                                                                            <div class="col-12 mobile-fields">
                                                                                <label class="catalog-label"><Trans>Original Due Date</Trans>:</label>
                                                                                <label class="item-details-value">{value.ORIG_DUE_DT}</label>
                                                                            </div>
                                                                            <div class="col-12 mobile-fields">
                                                                                <label class="catalog-label"><Trans>Delivery Date</Trans>:</label>
                                                                                <label class="item-details-value">{value.DUE_DT}</label>
                                                                            </div>
                                                                            <div class="col-12 mobile-fields">
                                                                                <label class="catalog-label"><Trans>Request by Date</Trans>:</label>
                                                                                <label class="item-details-value ">{value.req_by_date}</label>
                                                                            </div>
                                                                            <div class="col-12 mobile-fields">
                                                                                <label class="catalog-label"><Trans>PO QTY</Trans>:</label>
                                                                                <label class="item-details-value">{value.QTY_PO}</label>
                                                                            </div>
                                                                            <div class="col-12 mobile-fields">
                                                                                <label class="catalog-label"><Trans>Received QTY</Trans>:</label>
                                                                                <label class="item-details-value">{value.QTY_LN_RECVD == " " ? "0.00" : value.QTY_LN_RECVD}</label>
                                                                            </div>
                                                                            <div class="col-12 mobile-fields">
                                                                                <label class="catalog-label"><Trans>Reject QTY</Trans>:</label>
                                                                                <label class="item-details-value">{value.QTY_LN_REJCT == " " ? "0.00" : value.QTY_LN_REJCT}</label>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.showprice != "0" && this.state.blockprice == "N" ?
                                                                            <div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                                <label className="catalog-label float-none"><Trans>Ext Price</Trans>:</label>
                                                                                <label className="item-details-value float-none pl-1">{value.ext_price}</label>
                                                                            </div> : null}
                                                                        <div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Item ID</Trans>:</label>
                                                                            <label className="item-details-value float-none pl-1">{value.INV_ITEM_ID == " " ? "Non-Stock" : value.INV_ITEM_ID}</label>
                                                                        </div>

                                                                        <div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Tracking Number</Trans>:</label>
                                                                            <a href={"http://wwwapps.ups.com/WebTracking/processInputRequest?HTMLVersion=5.0&sort_by=status&term_warn=yes&tracknums_displayed=5&TypeOfInquiryNumber=T&loc=en_US&InquiryNumber1= " + value.ISA_ASN_TRACK_NO + "&InquiryNumber2=&InquiryNumber3=&InquiryNumber4=&InquiryNumber5=&AgreeToTermsAndConditions=yes&track.x=25&track.y=9"} target="_blank" >{value.ISA_ASN_TRACK_NO} </a>

                                                                        </div>
                                                                        <div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Ship Via</Trans>:</label>
                                                                            <label className="item-details-value float-none">{value.ISA_ANS_SHIP_VIA}
                                                                            </label></div><div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Ship Date</Trans>:</label>
                                                                            <label className="item-details-value float-none">{value.ISA_ANS_SHIP_DT}
                                                                            </label></div><div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Invoice</Trans>:</label>
                                                                            <label className="item-details-value float-none">{value.invoice_id}
                                                                            </label></div><div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Invoice Amount</Trans>:</label>
                                                                            <label className="item-details-value float-none" >{value.invoice_amt}</label></div>
                                                                        <div className="col-md-4 pr-0 responsive-iphone-grid"><label className="catalog-label float-none"><Trans>Invoice Date</Trans>:</label>
                                                                            <label href="#" className="item-details-value float-none">{value.invoice_date}</label></div>
                                                                        <div className="col-md-4 pr-0 responsive-iphone-grid"><label className="catalog-label float-none"><Trans>Packing Slips</Trans>:</label>
                                                                            {this.packingSlip(value)}
                                                                        </div>
                                                                        <div className="col-md-4 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Manufacturer</Trans>:</label>
                                                                            <label className="item-details-value float-none pl-1">{value.MFG_ID}</label>
                                                                        </div>
                                                                        <div className="col-md-6 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Manufacturer Part Number</Trans>:</label>
                                                                            <label className="item-details-value float-none pl-1">{value.MFG_ITM_ID}</label>
                                                                        </div>
                                                                        <div className="col-md-12 pr-0 responsive-iphone-grid">
                                                                            <label className="catalog-label float-none"><Trans>Employee Name</Trans>:</label>
                                                                            <label className="item-details-value float-none">{value.OPRID_ENTERED_BY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 mb-1 ">
                                                                    <div className="row">
                                                                        <div className="col-md-12 pr-0">
                                                                            <label className="item-details-value w-100">{value.DESCR254_MIXED}</label>
                                                                        </div>
                                                                    </div></div>
                                                            </div>
                                                        </div></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </>
                )
            });
        }
        if (this.state.POData.length == 0) {
            return (
                //[5/10/22, CAS - 31, Dhevanesam R]
                <span className="noitemdisplay" id="StatusMsg"><Trans>No Records to display</Trans></span>
            );
        }
    }

    packingSlip = (OrderDetail) => {
        let packingSlip = this.state.packingSlip.filter((item, index) => item.INV_ITEM_ID == OrderDetail.INV_ITEM_ID);
        return packingSlip.map((Item, index) => {
            //debugger;
            if (Item.Packslip == true) {
                return (
                    <label className="item-details-value"><a href="#"><img src="Images/history.png" /></a></label>
                );
            }
        })
    }
    PODetailhd = () => {
        if (this.state.VenderDetail.length > 0) {
            //debugger;
            let VenderDetail = this.state.VenderDetail;
            console.log("address", this.state.VenderDetail);
            let separatedArray = [];
            let Phone = [];
            //[5/30/22, PC_OS_05, CAS - Order status data pull issue, Dhevanesam R]
            if (this.state.VenderDetail[0].lblPOVendorPhoneData != null && this.state.VenderDetail[0].lblPOVendorPhoneData != "") {
                Phone = this.state.VenderDetail[0].lblPOVendorPhoneData.split('<br />');
            }
            //Phone = this.state.VenderDetail[0].lblPOVendorPhoneData.split('<br />');
            if (this.state.VenderDetail[0].sAddress != null && this.state.VenderDetail[0].sAddress != "") {
                separatedArray = this.state.VenderDetail[0].sAddress.split('<br />');
            }
            //separatedArray = this.state.VenderDetail[0].sAddress.split('<br />');
            console.log("splice arr", separatedArray);
            //for (let value in VenderDetail) {
            //    let addr = VenderDetail[value].sAddress;
            return (<div>

                <div className="col-md-12 col-lg-3 float-left">
                    <div className="row">
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Vendor ID</Trans>:</label>
                            <label className="info-value pl-1">{this.state.VenderDetail[0].sVendorID}</label>
                        </div>
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Vendor Location</Trans>:</label>
                            <label className="info-value pl-1">{this.state.VenderDetail[0].sVndrLoc}</label>
                        </div>
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Addr Seq</Trans>:</label>
                            <label className="info-value pl-1" >{this.state.VenderDetail[0].lblPOVendorAddrSeqData}</label>
                        </div>
                    </div>
                </div>
                {/*[4/26/2023]Displaying Vendor details separately -Poornima S*/}
                <div className="col-md-12 col-lg-3 float-left">
                    <div className="row">
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Vendor</Trans>:</label>
                            <label className="info-value pl-1">{this.state.VenderDetail[0].lblPOVendorNameData} </label>
                        </div>
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Vendor Address</Trans>:</label>
                            <label className="info-value pl-1">{separatedArray}</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-3 float-left">
                    <div className="row">
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Vendor Contact</Trans>:</label>
                            <label className="info-value col-md-12 p-0 mb-0">{Phone}</label>
                        </div>
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Vendor Name</Trans>:</label>
                            <label className="info-value col-md-12 p-0 mb-0">{this.state.VenderDetail[0].lblPOVendorContactData}</label>
                        </div>
                    </div>
                </div>
            </div>
            );
            //}
        }
    }

    POData = () => {
        if (this.state.POData.length > 0) {
            //return this.state.POData.map((value, index) => {
            return (
                <div className="col-md-12 col-lg-3 float-left">
                    <div className="row">
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>SDI PO</Trans>:</label>
                            <label className="info-value pl-1">{this.state.POData[0].PO_ID}</label>
                        </div>
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Order Number</Trans>:</label>
                            <label className="info-value pl-1">{this.state.POData[0].ORDER_NO}</label>
                        </div>
                        <div className="col-md-12 float-left p-0">
                            <label className="info-label"><Trans>Work Order Number</Trans>:</label>
                            <label className="info-value pl-1" >{this.state.POData[0].ISA_WORK_ORDER_NO}</label>
                        </div>
                    </div>
                </div>
            );
        }
        //})
    }
    onselect = (value, index) => {
        let emlist = [];
        let string = "";

        emlist.push({
            EmpId: value
        });
        //debugger;
    }

    shipmentAdd = () => {
        return this.state.shipmentAddr.map((value, index) => {
            return (<div className="row mb-3">
                <div className="col-md-3"><p className="cc-help-info"><Trans>From</Trans>:</p>
                    <label className="col-md-12 quick-label p-0">{value.lblShipFromDesc}</label>
                    <p className="col-md-12 quick-value p-0">{value.lblShipFromAdd}</p>
                </div>
                <div className="col-md-6"><p className="cc-help-info"><Trans>To</Trans>:</p>
                    <div className="row"><span className="col-md-2 float-left" /><label className="col-md-10 quick-label float-left">{value.lblShipToDesc}</label></div>
                    <div className="row"> <span className="col-md-2 float-left"><Trans>Address</Trans>:</span><p className="col-md-10 quick-value float-left">{value.lblShipToAdd}</p>
                    </div>
                </div>
                <div className="col-md-3"><p className="cc-help-info float-right"><Trans>Stop ID</Trans>:{value.SHIPTO_ID}</p></div>
            </div>);
        })
    }

    bindShipmentBol = () => {
        let ship = this.state.shipmentBOL[0];
        //ship = this.state.shipmentBOL[0];
        //debugger;
        let LOAD_ID = "";
        //if (this.state.shipmentBOL.length > 0) {
        //     LOAD_ID = this.state.shipmentBOL[0].LOAD_ID;

        //    //debugger;
        //    console.log("ship", LOAD_ID);
        //}
        //if (this.state.shipmentAddr.length > 0) {
        //    LOAD_ID = this.state.shipmentBOL[0].LOAD_ID;

        //    //debugger;
        //    console.log("ship", LOAD_ID);
        //}
        let proof = this.state.shipmentAddr[0].strURLForI0260;
        console.log("ship", this.state.shipmentAddr[0].strURLForI0260);
        console.log("ship", proof);
        //return this.state.shipmentBOL.map((value, index) => {
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <span className="float-left cc-help-info"><Trans>Load ID</Trans> : </span>
                        <span className="float-right cc-help-info"><Trans>Trucking / Delivery From</Trans></span>
                    </div>
                    <div className="col-12 mb-4">

                        <div className="row mb-3">
                            <div className="col-md-3"><p className="cc-help-info"><Trans>From</Trans>:</p>
                                <label className="col-md-12 quick-label p-0">{this.state.shipmentAddr[0].lblShipFromDesc}</label>
                                <p className="col-md-12 quick-value p-0">{this.state.shipmentAddr[0].lblShipFromAdd}</p>
                            </div>
                            <div className="col-md-6"><p className="cc-help-info"><Trans>To</Trans>:</p>
                                <div className="row"><span className="col-md-2 float-left" /><label className="col-md-10 quick-label float-left">{this.state.shipmentAddr[0].lblShipToDesc}</label></div>
                                <div className="row"> <span className="col-md-2 float-left"><Trans>Address</Trans>:</span><p className="col-md-10 quick-value float-left">{this.state.shipmentAddr[0].lblShipToAdd}</p>
                                </div>
                            </div>
                            <div className="col-md-3"><p className="cc-help-info float-right"><Trans>Stop ID</Trans>:{this.state.shipmentAddr[0].SHIPTO_ID}</p></div>
                        </div>

                        <div className="orderStat-LineCSS">
                            <table className="table table-borderless shipment-tblborderless">
                                <thead>
                                    <tr>
                                        <th><Trans>Shipping Date</Trans>:</th>
                                        <th><Trans>Priority</Trans>:</th>
                                        <th><Trans>ROC</Trans>:</th>
                                        <th><Trans>District</Trans>:</th>
                                        <th><Trans>BLDG#</Trans>:</th>
                                        <th><Trans>Trade</Trans>:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.shipmentBOL[0].LOAD_DT}</td>
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-6">
                                <label><Trans>Notes</Trans></label>
                                <textarea className="form-control" readOnly defaultValue={""} />
                            </div>
                            <div className="col-md-6">
                                <label><Trans>Delivery</Trans></label>
                                <textarea className="form-control" readOnly defaultValue={""} value={this.state.shipmentBOL[0].NOTES_1000} />
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table shipment-table">
                                <thead>
                                    <tr>
                                        <th><Trans>Order Number</Trans> &amp; <Trans>Work Order Number</Trans></th>
                                        <th><Trans>Ordered By</Trans></th>
                                        <th><Trans>Ship Container</Trans></th>
                                        <th><Trans>type</Trans></th>
                                        <th><Trans>Comments</Trans></th>
                                        <th><Trans>Fragile Item Signature</Trans></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.shipmentGrid()}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 p-0 shipment-content">
                            <div className="col-md-3 col-12">
                                <p className="cc-help-info"><Trans>Summary</Trans>:</p>
                                <div className="row">
                                    <label className="col-md-5 col-5"><Trans>Truck Number</Trans>:</label>
                                    <label className="col-md-7 col-7"></label>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-5"><Trans>Driver</Trans>:</label>
                                    <label className="col-md-7 col-7">{this.state.shipmentBOL[0].DRIVER_ID}</label>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-5"><Trans>Received By</Trans>:</label>
                                    <div className="col-md-7 col-7">
                                        <label className="d-block">{this.state.shipmentAddr[0].lblReceiver}</label>
                                        {this.state.shipmentAddr[0].lnkPOD ? <>
                                            <a href={`${proof}`} target="_blank" ><Trans>[proof-of-delivery]</Trans></a> </> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="float-left">
                                <label></label>
                            </div>
                            <div className="float-right">
                                <Trans>Date Delivered</Trans> : {this.state.shipmentBOL[0].DELIVERED_DT}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-right mb-2">
                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" data-dismiss="modal"><Trans>Close</Trans></button>
                    </div>
                </div>
            </div>);
        //})
    }
    proofD = (e) => {
        e.preventDefault();
        let proof = this.state.shipmentAddr[0].strURLForI0260;
        var handle = window.open('proof');
    }

    shipmentGrid = () => {
        return this.state.shipmentBOL.map((value, index) => {
            return (
                <tr>
                    <td><p>{value.ORDER_NO}</p>
                        <p>{value.WORK_ORDER_NUM}</p></td>
                    <td>{value.OPRID_ENTERED_BY}</td>
                    <td>{value.SHIP_CNTR_ID}</td>
                    <td>{value.CONTAINER_TYPE}</td>
                    <td ></td>
                    <td></td>
                </tr>);

        })
    }

    convertdate = (date) => {
        var dateConverted;
        if (date != null && date != "" && date != " ") {
            var dateString = date;
            var dataSplit = dateString.split('/');
            console.log("Dateformated", dataSplit);
            //debugger;
            if (dataSplit[2].split(" ").length > 1) {

                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[0] - 1, dataSplit[1], hora[0], hora[1]);

            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[0] - 1, dataSplit[1]);
            }
        }
        console.log("Dateformated01", dateConverted);
        return (dateConverted)
    }
    FromOrderDate = e => {
        //debugger;
        let dateOL = this.handledate(e);
        if (e != "") {
            this.setState({ OFromDate: dateOL, OrderDateBoolean: true });
        }
        console.log("formateDate", dateOL);
        console.log("Date", this.state.isFilter);
        console.log("Date", e);
    }
    ToOrderDate = e => {
        //debugger;
        //  let formateddate = this.convertdate(e);
        let dateOL = this.handledate(e);
        if (e !== null) {
            this.setState({ OToDate: dateOL, OrderDateBoolean: true });
        }
        //this.setState({ OToDate: dateOL, OrderDateBoolean: true });
        console.log("formatetODate", dateOL);

    }
    handledate = (date) => {
        if (date != null) {
            return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear())
        }
    }
    FromNeedDate = e => {
        let dateOL = this.handledate(e);
        if (dateOL !== null) {
            this.setState({ NFromDate: dateOL, NeedByDateBoolean: true });
        }
        console.log("formatefromDate", dateOL);

    }
    ToNeedDate = e => {
        let dateOL = this.handledate(e);
        if (dateOL !== null) {
            this.setState({ NToDate: dateOL, NeedByDateBoolean: true });
        }
        console.log("formatetODate", dateOL);
    }
    AdvancedFilter = () => {
        $('advanced-filter').modal('show');


        //$(".dropdown-menu").click(function (event) {
        //    event.stopPropagation();
        //});




        //this.setState({ isFilter: true });
        //debugger;
    }
    ApplyButtonClick = () => {
        if (this.state.OrderDateBoolean || this.state.NeedByDateBoolean) {
            FilterPopupBoolean = true;
        }
        else {
            FilterPopupBoolean = false;
        }
        console.log("buttonclick", FilterPopupBoolean);

        let pageNo = 1;
        this.setState({ PageNo: pageNo });//****NEW***ADDED(2/10/2021)***
        this.ApplyFilter(pageNo,
            this.state.RecCount,
            this.state.searchColumn,
            searchTxt, searchTrBy, searchTrCriteria,
            this.state.sortColumn,
            this.state.sortType, FilterPopupBoolean
        );
        //this.clearButtonClick();
        this.filtertoggle();
    }
    clearButtonClick = () => {
        this.setState({ NeedByDateBoolean: false, OrderDateBoolean: false, OFromDate: "", OToDate: "", NFromDate: "", NToDate: "", PageNo: 1 });
        FilterPopupBoolean = false;
        //[5/30/22, PC_OS_02, CAS - Order status data pull issue, Dhevanesam R]
        let sortColumn = "ORD_DATE";//"ORDER_NO";
        let sortType = "DESC";//"ASC";
        let pageNo = 1;
        debugger;

        //$("#searchTxt").val('');
        if (this.state.ReportDateField == "99") {
            //this.dateCheck();
        }

        //searchTxt = $(`#searchTxt`).val().trim();
        let searchCol = "";
        if (searchTxt != "") {
            searchCol = this.state.searchColumn;
            if (searchCol == "LINE_PRIOR") {
                if (this.state.priorityFlag == 1) {
                    searchTxt = `R`;
                }
                else if (this.state.priorityFlag == 2) {
                    searchTxt = ` `;
                }
                else {
                    searchCol = "";
                    searchTxt = ` `;
                }

            }
            else {
                searchTxt = $(`#searchTxt`).val();
            }
        }

        //this.setState({ searchColumn: "" });
        this.ApplyFilter(pageNo,
            this.state.RecCount,
            searchCol,
            searchTxt, searchTrBy, searchTrCriteria,
            sortColumn,
            sortType, FilterPopupBoolean
        );
        //this.setState({ sortColumn: sortColumn, sortType: sortType, searchAval: false, PageNo: pageNo });
        this.filtertoggle();
    }



    handleRadioButton = (value) => {
        //e.preventDefault();
        let sFD = new Date();
        let sTD = new Date();
        let val = [];
        debugger;
        if (value == "dateRange") {
            //document.getElementById("dateRag1").checked = true;
            //document.getElementById("6month").checked = false;
            //document.getElementById("exval").checked = false;
            //$("#6month").attr('disabled', true);
            //$("#exval").attr('disabled', true);

            //document.getElementById("6month").disabled = true;
            //document.getElementById("dateRag").Checked = true;
            radio = "dateRange"
            radiochange = true;
            this.setState({ EV: false, MN: false, DR: true });
            //radio = "ExactValue"
            //val = this.abovemonth()
            //sFD = val[0];
            //sTD = val[1];
            //sFD = Date.parse(val[0]);
            //sFD = new Date(sFD);
            //sTD = Date.parse(val[1]);
            //sTD = new Date(sTD);
            //this.setState({ SearchTrFromDate: sFD, SearchTrToDate: sTD });
            //this.abovemonth()

            //sFD = val[0] ? this.handledate(val[0]) : "";
            //sTD = val[1] ? this.handledate(val[1]) : "";
            //this.setState({ SearchTrFromDate: sFD, SearchTrToDate: sTD });

        }
        else if (value == "6month") {
            //document.getElementById("6month1").checked = true;
            //document.getElementById("6month").checked = false;
            //document.getElementById("exval").checked = false;
            //$("#6month").attr('disabled', true);
            //$("#exval").attr('disabled', true);
            //$('.6m').addClass('.disable-radio-label');
            //document.getElementById("6month").disabled = true;
            //document.getElementById("dateRag").defaultChecked = true;
            radio = "6month"
            radiochange = true;
            this.setState({ EV: false, MN: true, DR: false });
            //val = this.abovemonth()
            //sFD = val[0];
            //sTD = val[1];
            //sFD = Date.parse(val[0]);
            //sFD = new Date(sFD);
            //sTD = Date.parse(val[1]);
            //sTD = new Date(sTD);
            this.setState({ SearchTrFromDate: "", SearchTrToDate: "" });


        }
        else {
            // document.getElementById("exval1").checked = true;
            //[5/30/22, PC_OS_03, CAS - Order status data pull issue, Dhevanesam R]
            radio = "ExactValue"
            radiochange = true;
            this.setState({ EV: true, MN: false, DR: false });
            this.setState({ SearchTrFromDate: "", SearchTrToDate: "" });
        }
        console.log("SEARCHoPTION", value);
        this.setState({ SearchOption: value });

    }
    SearchTrToDatebind = (e) => {
        let dateOL = this.handledate(e);
        if (dateOL !== null) {
            this.setState({ SearchTrToDate: dateOL });
        }
        console.log("formatefromDate", dateOL);
    }
    SearchTrFromDatebind = (e) => {
        let dateOL = this.handledate(e);
        if (dateOL !== null) {
            this.setState({ SearchTrFromDate: dateOL });
        }
        console.log("formatefromDate", dateOL);
    }


    STrDropDown = (event, displayName, columnName) => {
        event.preventDefault();
        searchTrBy = columnName;
        radiochange = false;
        debugger;
        let sFD = new Date();
        let sTD = new Date();
        let val = [];
        this.setState({ STrDisplayName: displayName });
        console.log("searchColumn", columnName);
        debugger;
        if (displayName == "Order Status") {
            //document.getElementById("dateRag").checked = true;


            //$('#6month').addClass('hide');
            //$('#exval').addClass('hide');
            radiochange = true;
            $("#SearchTrTxt").val('');
            $("#SearchTrTxt").hide();
            $("#status-dropdown").show();
            searchTrCriteria = "";
            $("#status-dropdown").val('select');
            $('#6m').addClass('disable-radio-label');
            $('#dr').addClass('disable-radio-label');
            $('#ex').addClass('disable-radio-label');
            $("#6month").attr('disabled', true);
            $("#exval").attr('disabled', true);

            //document.getElementById("6month").disabled = true;
            //document.getElementById("exval").disabled = true;
            //$("#6month").attr('disabled', true);
            //$("#exval").attr('disabled', true);
            //document.getElementById("dateRag").defaultChecked = true;
            radio = "dateRange"
            val = this.abovemonth()
            sFD = val[0];
            sTD = val[1];
            sFD = Date.parse(val[0]);
            sFD = new Date(sFD);
            sTD = Date.parse(val[1]);
            sTD = new Date(sTD);
            this.setState({ SearchTrFromDate: sFD, SearchTrToDate: sTD, EV: false, MN: false, DR: true });
            //let val = this.abovemonth();
            //this.setState({ SearchTrFromDate: val[0], SearchTrToDate: val[1] });
            //debugger;
        }
        else if (displayName == "Priority Orders") {
            searchTrCriteria = "Priority";
            radiochange = true;
            $("#SearchTrTxt").val('Priority');
            $("#status-dropdown").hide();
            $("#SearchTrTxt").show();
            $('#ex').removeClass('disable-radio-label');
            $('#6m').addClass('disable-radio-label');
            $('#dr').addClass('disable-radio-label');

            //document.getElementById("SearchTrTxt").value = "Priority";

            //document.getElementById("exval").checked = true;

            //document.getElementById("6month").disabled = true;
            //document.getElementById("dateRag").disabled = true;
            $("#6month").attr('disabled', true);
            $("#dateRag").attr('disabled', true);
            //document.getElementById("dateRag").checked = true;

            //this.setState({ SearchOption: "ExactValue" });
            radio = "ExactValue"
            val = this.abovemonth()
            sFD = val[0];
            sTD = val[1];
            sFD = Date.parse(val[0]);
            sFD = new Date(sFD);
            sTD = Date.parse(val[1]);
            sTD = new Date(sTD);
            this.setState({ SearchTrFromDate: sFD, SearchTrToDate: sTD, EV: true, MN: false, DR: false });
            console.log("from", sFD);
            console.log("to", sTD);
            //let val = this.abovemonth();
            //this.setState({ SearchTrFromDate: val[0], SearchTrToDate: val[1] });
        }
        else {
            $('#SearchTrTxt').val('');
            $("#status-dropdown").hide();
            $("#SearchTrTxt").show();
            searchTrCriteria = "";
            radio = "ExactValue"

            //document.getElementById("exval").defaultChecked = true;
            //document.getElementById("6month").disabled = false;
            //document.getElementById("dateRag").disabled = false;
            $('#ex').removeClass('disable-radio-label');
            $('#6m').removeClass('disable-radio-label');
            $('#dr').removeClass('disable-radio-label');

            // document.getElementById("exval").checked = true;

            //document.getElementById("exval").disabled = false;
            $("#6month").attr('disabled', false);
            $("#dateRag").attr('disabled', false);
            $("#exval").attr('disabled', false);
            this.setState({ SearchTrFromDate: "", SearchTrToDate: "", EV: true, MN: false, DR: false });

        }
    }
    /*handleOrderStatus = (event) => {
        searchTrCriteria = event.target.value;
        console.log("handleChange", searchTrCriteria);
    }*/
    handleTrSearch = () => {
        let pageNo = 1;
        //[5/30/22, PC_OS_02, CAS - Order status data pull issue, Dhevanesam R]
        let sortColumn = "ORD_DATE";
        let sortType = "DESC";
        searchTrCriteria = $(`#SearchTrTxt`).val().trim();
        this.ApplyFilter(pageNo,
            this.state.RecCount,
            this.state.searchColumn,
            searchTxt, searchTrBy, searchTrCriteria,
            sortColumn,
            sortType, FilterPopupBoolean
        );
        //debugger;
        console.log("search", searchTrCriteria);
        this.setState({ PageNo: pageNo, isSearch: true, searchAval: true });
        console.log("search", this.state.searchAval);
    }

    handleEmplist = (values,selected) => {
        //02/22/24 - Zeus 274 - Rearrange the employee drop down value like selected option displayed in the begining and rest of the status value - Johnprinto
        let selectedEmp = selected.action == "select-option" ? values.reverse() : values ;
        let arrEmpID = [...selectedEmp, ...this.state.empIDDDList].filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.value === obj.value
            ))
        );
        this.setState({ EmpListPush: selectedEmp, EmpIdDropDown : arrEmpID});
    }

    handleStatusChange = (values,selected) => {
        //02/22/24 - Zeus 274 - Rearrange the status drop down value like selected option displayed in the begining and rest of the status value - Johnprinto
        let selectedStatus = selected.action == "select-option" ? values.reverse() : values ;
        let arrOrderStatus = [...selectedStatus,...this.state.orderStatusList].filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.value === obj.value
            ))
        );
        this.setState({ orderStatusSelectedList : selectedStatus, orderStatusDD : arrOrderStatus});
    }

    filtertoggle = (e) => {
        if (e)
            e.preventDefault();
        this.setState({ isFilterOpen: this.state.isFilterOpen == true ? false : true });
    };

    expandAll = () => {
        if (this.state.isExpand == false) {
            this.setState({ isExpand: true });
            $('.header-arrow').removeClass('accordion-arrow-change');
            $('.content-row').addClass('d-none');
            $('.header-arrow').addClass('accordion-arrow-change');
            $('.content-row').removeClass('d-none');
        } else {
            this.setState({ isExpand: false });
            $('.header-arrow').addClass('accordion-arrow-change');
            $('.content-row').removeClass('d-none');
            $('.header-arrow').removeClass('accordion-arrow-change');
            $('.content-row').addClass('d-none');

        }
    }

    POExpandAll = () => {
        if (this.state.isPOExpand == false) {
            this.setState({ isPOExpand: true });
            $('.tryH').removeClass('accordion-arrow-change');
            $('.try').addClass('d-none');
            $('.tryH').addClass('accordion-arrow-change');
            $('.try').removeClass('d-none');
        }
    }
    POCollapseAll = () => {
        this.setState({ isPOExpand: false });
        $('.tryH').addClass('accordion-arrow-change');
        $('.try').removeClass('d-none');
        $('.tryH').removeClass('accordion-arrow-change');
        $('.try').addClass('d-none');
    }
    AnykeyPress = (e) => {
        if (e.keyCode == 13) {
            this.handleSearch(false, "");
        }
    };

    historyGridBind = () => {
        if (this.state.ApprovalhistoryArr) {
            return this.state.ApprovalhistoryArr.map((value, ID) => {
                return (
                    <tr>
                        <td>{value.Approved_By_USERID}</td>
                        <td>{value.Approved_By}</td>
                        <td>{value.Approval_Status}</td>
                        <td>{value.Approval_Type}</td>
                        <td>{value.Date_DT}</td>
                    </tr>
                )

            });
        }
    }
    shippopup = () => {
        if (this.state.usernamedetail) {
            return (
                <div className="col-12 edit-desc-field mb-4">
                    <div className="row mb-3">
                        <label className="col-md-5 quick-label"><Trans>First Name</Trans></label>
                        <label className="col-md-7 quick-value">{this.state.usernamedetail.sFirstName}</label>
                    </div>
                    <div className="row mb-3">
                        <label className="col-md-5 quick-label"><Trans>Last Name</Trans></label>
                        <label className="col-md-7 quick-value">{this.state.usernamedetail.sLastName}</label>
                    </div>
                    <div className="row mb-3">
                        <label className="col-md-5 quick-label"><Trans>Loaded Container</Trans></label>
                        <label className="col-md-7 quick-value">{this.state.usernamedetail.LoadedContainer}</label>
                    </div>
                    <div className="row mb-3">
                        <label className="col-md-5 quick-label"><Trans>Total Count</Trans></label>
                        <label className="col-md-7 quick-value">{this.state.usernamedetail.TotalCnt}</label>
                    </div>


                </div>
            )
        }
    }
    handleDatePicker = (date, name) => {
        //date.preventDefault();
        debugger;
        if ((name == "OFromDate" || name == "OToDate") && (date != "")) {
            this.setState({ OrderDateBoolean: true });
        }
        else if ((name == "NFromDate" || name == "NToDate") && (date != "")) {

            this.setState({ NeedByDateBoolean: true });
        }
        let dateOL = this.handledate(date);
        this.setState({
            [name]: date
        });

        console.log("Date", date);
        console.log("Date", dateOL);
    };

    //$(".dropdown-menu").click(function(event) {
    //    event.stopPropagation();
    //}
    //handleRadio = (e, value) => {
    //    e.preventDefault();

    //    let radioBtn1 = false
    //    let radioBtn2 = false
    //    let radioBtn3 = false
    //    if (value == "ExactValue") {
    //        radioBtn1 = true
    //    }
    //    else if (value == "6month") {
    //        radioBtn2 = true
    //    }
    //    else if (value == "dateRange") {
    //        radioBtn3 = true
    //    }
    //    this.setState({ ExactValueRR: radioBtn1, MonthRR: radioBtn2, dateRangeRR: radioBtn3})
    //}
    render() {
        //[11/26/21, CAS - 31, Priyanka D]
        const { t } = this.props;
        return (

            <div>


                <div className="container-fluid content-section custom-mb">
                    <div className="row">

                        {/* Check Order Status starts */}
                        <div className="container-fluid content-margin">
                            <div className="row">
                                <div className="col-md-12 page-heading mb-3">
                                    <h5 className="page-title"><Trans>Check Order Status</Trans></h5>
                                    {this.state.ApproverPopupFlag ? <button className="btn btn-primary custom-btn-primary primary-btn mr-2 ml-3 float-right" data-toggle="modal" data-target=".my-approver-details">My Approver</button> : null}
                                    <div className="fav-item-maintenance-icon mt-1">
                                        <input type="checkbox" id="checkbox-OrdStatus" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                        <label htmlFor="checkbox-OrdStatus">
                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    {/*[4/14/22, Bug - 2069, Dhevanesam R]*/}
                                    <div className="row  mb-4">
                                        {/*[Zeus 244, 12/18/2023] - Report type and site BU filter alignment*/}
                                        {this.state.BusUnitDropDown.length > 2 ? <>
                                            <div className="col-md-2 mb-3">
                                                <label className="catalog-label"><Trans>Report Type</Trans></label>

                                                <select className="form-control" id="ReprotTypeDD" name="ReportTypeField" value={this.state.ReportTypeField} onChange={this.handleChange}>
                                                    {/*<option value="Select">{t("Select Report Type")}</option>*/}
                                                    <option value="Site">{t("Site Report")}</option>
                                                    <option value="Company">{t("Company-Consolidated Report")}</option>
                                                </select>
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label className="catalog-label"><Trans>Site Business Unit</Trans></label>
                                                <select className="form-control" name="BusUnitField" id="BusUnitDD" value={this.state.BusUnitField} onChange={this.handleChange}>
                                                    {/*<option value="Select">{t("Select Business Unit")}</option>*/}
                                                    {/*<option value="Site">Select Business Unit</option>*/}
                                                    {this.BusUnitDropdownmap()}
                                                </select>
                                            </div></> : null}
                                        <div className="col-md-2 mb-3">
                                            <label className="catalog-label" style={{ "width": "309px" }}><Trans>Select Report (by Order date)</Trans></label>
                                            <select className="form-control" id="ReportDateDD" name="ReportDateField" value={this.state.ReportDateField} onChange={this.handleChange}>

                                                <option value="X">{t("Select Report")}</option>
                                                <option value="7">{t("Last 7 Days")}</option>
                                                <option value="15">{t("Last 15 Days")}</option>
                                                <option value="30">{t("Last 30 Days")}</option>
                                                <option value="0">{t("This Month")}</option>
                                                <option value="-1">{t("Last Month")}</option>
                                                <option value="99">{t("Custom Dates")}</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="catalog-label"><Trans>Order Type</Trans></label>
                                            <select className="form-control" name="OrderTypeField" id="OrderTypeDD" value={this.state.OrderTypeField} onChange={this.handleChange}>
                                                <option value="All">{t("All Orders")}</option>
                                                <option value="My">{t("My Orders")}</option>
                                                <option value="Open">{t("Open Orders")}</option>
                                                <option value="Group">{t("Group Orders")}</option>
                                            </select>
                                        </div>
                                        {/*[04/08/22, CAS - 31, Dhevanesam R]*/}
                                        {this.state.OrderTypeField == "Group" ?
                                            <div className="col-md-2 mb-3">
                                                <label className="catalog-label w-100"><Trans>Employee ID</Trans></label>
                                                {/*<DropdownMultiselectModule
                                                    options={this.state.EmpIdDropDown}
                                                    displayName="ISA_EMPLOYEE_NAME"
                                                    id="EMP_ID"
                                                    value={this.state.EmpListPush}
                                                    onChange={this.handleEmplist}
                                                    labelledBy={"Select"}
                                                    className="w-100 DropDown-Font-Size float-left custom-height eid"
                                                />*/}
                                                {/* <MultiSelect
                                                    options={this.state.EmpIdDropDown}
                                                    displayName="ISA_EMPLOYEE_NAME"
                                                    id="EMP_ID"
                                                    value ={this.state.EmpListPush}
                                                    onChange={this.handleEmplist}
                                                    labelledBy={"Select"}
                                                    className="w-100 DropDown-Font-Size float-left custom-height eid"
                                                />*/}
                                                <ReactMultiSelectCheckboxes
                                                    options={this.state.EmpIdDropDown}
                                                    className="multi multiDDWidth"
                                                    id="EMP_ID"
                                                    value={this.state.EmpListPush}
                                                    onChange={this.handleEmplist}
                                                    labelledBy={"Select"}

                                                />


                                            </div>

                                            : null}
                                        {/*//[Zeus-141, 06/26/2023] - order status and Order category dropdown data*/}
                                        <div className="col-md-2 mb-3">
                                            <label className="catalog-label"><Trans>Order Status</Trans></label>
                                            {/* 02/22/24 - Zeus 274 - Order status drop down - Johnprinto */}
                                            {!this.state.isSearch ? <select className="form-control search-color" name="OrderStatusSearch" id="OrderStatus" disabled>
                                                <option value=" " >--Select Status--</option>
                                                </select> :
                                            <ReactMultiSelectCheckboxes
                                                    options={this.state.orderStatusDD}
                                                    className="multi multiDDWidth Font13 orderstatus-multi"
                                                    id="STATUS_DD"
                                                    value={this.state.orderStatusSelectedList}
                                                    onChange={this.handleStatusChange}
                                                    placeholderButtonLabel={"--Select Status--"}
                                                />}
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="catalog-label"><Trans>Order Category</Trans></label>
                                            <select className="form-control search-color" name="OrderCategorySearch" id="OrderCategory" value={this.state.OrderCategory} onChange={(e) => this.handleOrderCategory(e)}>
                                                <option value=" ">--Select Category--</option>
                                                <option value="SHIP">Shipment</option>
                                                <option value="PICKUP">Pick-Up</option>
                                            </select>
                                        </div>






                                    </div>
                                    <div className="row mb-3" >
                                        {/* {this.state.ReportDateField == "99" ?*/}
                                        <>
                                            <div className="col-md-3 pr-0 middle-field mb-3" id="sFT">
                                                <div className="col-md-6 float-left mb-4 p-0 ssd">
                                                    <label className="catalog-label"><Trans>From</Trans></label>
                                                    {/*<DatePicker className="form-control "
                                                        id="OrderByFromDate"
                                                        fixedHeight
                                                      
                                                        placeholderText="MM/DD/YYYY"
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        //dropdownMode="select"
                                                        //minDate={new Date()}
                                                        value={this.state.SearchTrFromDate}
                                                        onChange={this.SearchTrFromDatebind}

                                                    />*/}
                                                    <DatePicker className="form-control place"
                                                        id="stFrom"
                                                        fixedHeight
                                                        placeholderText="MM/DD/YYYY"
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        selected={this.state.SearchTrFromDate}
                                                        maxDate={this.state.SearchTrToDate}
                                                        onChange={(e) => this.handleDatePicker(e, "SearchTrFromDate")}
                                                    //value={this.state.FromDate}
                                                    />
                                                    <span className="calendar-icon filter-calendar-icon custom-datepicker float-left" onClick={() => $(`#stFrom`).focus()} />

                                                </div>
                                                {/*[4/14/22, Bug - 2069, Dhevanesam R]*/}
                                                <div className="col-md-6 float-left mb-4 pr-0 pl-1 ssd section-content-padding">
                                                    <label className="catalog-label"><Trans>To</Trans> <span className="info-container"><img className="info-searchpage" src="Images/info-icon.png" /><span className="info-content info-position IphTo" style={{ "top": "-24px" }}><Trans>Date range is limited to 3 months</Trans></span></span></label>
                                                    {/*<DatePicker className="form-control "
                                                        id="OrderByToDate"
                                                        fixedHeight
                                                        
                                                        placeholderText="MM/DD/YYYY"
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        //dropdownMode="select"
                                                        //minDate={new Date()}
                                                        value={this.state.SearchTrToDate}
                                                        onChange={this.SearchTrToDatebind}

                                                    />*/}
                                                    <DatePicker className="form-control place "
                                                        id="stTo"
                                                        fixedHeight
                                                        placeholderText="MM/DD/YYYY"
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        selected={this.state.SearchTrToDate}
                                                        minDate={this.state.SearchTrFromDate}
                                                        onChange={(e) => this.handleDatePicker(e, "SearchTrToDate")}
                                                    //value={this.state.ToDate}
                                                    />
                                                    <span className="calendar-icon filter-calendar-icon custom-datepicker float-left" onClick={() => $(`#stTo`).focus()} />

                                                </div>
                                            </div>
                                        </>
                                        {/*Start of commenting Search transaction UI*/}
                                        {/*<div className="col-md-3 middle-field mt-4 mb-3 text-center" id="OR"><Trans>(OR)</Trans>
                                            </div>*/}

                                        {/*    <div className="col-md-3 pr-0 left-field mb-3" id="sdrp">                                            
                                            <label className="catalog-label"><Trans>Search by</Trans> <span className="info-container"><img className="info-searchpage" src="Images/info-icon.png" /><span className="info-content infoleft IphSearchBy" style={{ "min-width": "277px" }}><Trans>Make sure report type is site report, when ordered for selected in search by dropdown</Trans></span></span></label>                                            
                                            <div className="input-group dropdown-search float-left mb-2" style={{ "top": "5px", "width": "102%" }}>
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ><Trans>{this.state.STrDisplayName}</Trans></button>
                                                    <div className="dropdown-menu" style={{}}>
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.STrDropDown(e, "Order Number", "OrderNo")}><Trans>Order Number</Trans></a>
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.STrDropDown(e, "Work Order Number", "WorkOrderNumberPenn")}><Trans>Work Order Number</Trans></a>
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.STrDropDown(e, "Item Description", "ItemDescription")}><Trans>Item Description</Trans></a>
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.STrDropDown(e, "Ordered For", "OrderedFor")}><Trans>Ordered For</Trans></a>
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.STrDropDown(e, "Priority Orders", "PriorityOrders")}><Trans>Priority Orders</Trans></a>
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.STrDropDown(e, "Order Status", "OrderStatus")}><Trans>Order Status</Trans></a>
                                                    </div>
                                                </div>
                                                
                                                <input type="text" className="form-control " placeholder={t("Search")} aria-label="Text input with dropdown button" onBlur={this.handleSTrTxt} id="SearchTrTxt" />
                                                
                                                <select className="form-control search-color" name="searchTrCriteria" id="status-dropdown" value={this.state.searchTrCriteria} onChange={this.handleOrderStatus}>
                                                   
                                                    <option value="select">{t("--Select Status--")}</option>
                                                    {this.OrderStatusDropdown()}
                                                </select>

                                            </div>
                                            <div className="col-md-12 p-0 float-left">
                                                <label className="custom-radiobtn" id="ex"><Trans>Search exact value</Trans>
                                                    <input type="radio" name="searchoption" id="exval" checked={this.state.EV} onChange={() => this.handleRadioButton("ExactValue")} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                                <label className="custom-radiobtn" id="6m"><Trans>Search containing value but limit to last 6 months</Trans>
                                                    <input type="radio" name="searchoption" id="6month" className="6month" checked={this.state.MN} onChange={() => this.handleRadioButton("6month")} />
                                                    <span className="checkmark checkmark-org " />
                                                </label>
                                                <label className="custom-radiobtn" id="dr"><Trans>Search containing value limit to date range entered above</Trans>
                                                    <input type="radio" name="searchoption" id="dateRag" checked={this.state.DR} onChange={() => this.handleRadioButton("dateRange")} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                            </div>                                            
                                        </div>*/}
                                        {/*End of commenting Search transaction UI*/}

                                        {/*[Zeus-140, 06/16/2023]-Need to hide the Apply filter button along with Clear btn - Kishore*/}
                                        <div className="col-md-12 my-3">
                                            {this.state.isSearch ? <> <button className="btn btn-secondary custom-btn-secondary float-right secondary-btn ml-4" id="ClearID" style={{ width: '90px' }} onClick={(e) => this.divclear(e)}><Trans>Clear</Trans></button>
                                                <button className="btn btn-primary custom-btn-primary primary-btn float-right px-2" id="applyfilterid" onClick={e => this.GetHeaderDetails(true, "", false, this.state.OrderTypeField, "")}><Trans>Apply Filter</Trans></button></> : null}
                                        </div>
                                    </div>
                                    <div class="row border-bottom justify-content-between border-top mb-3">
                                        <div class="input-group dropdown-search parent-order-status float-left mb-3 mt-3 order-filter-width ml-3" style={{ width: '20%' }}>
                                            {/*//[21/05/2023 - PS_ZE_118_APP_3  - Johnprinto D]*/}
                                            {/*//[06/16/2023, Zeus 140]-Added filter search dropdown field*/}
                                            <div className="input-group-prepend">
                                                <button className="btn btn-outline-secondary dropdown-toggle pri-btn" style={{ 'border-radius': '3px 0 0 3px' }} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.filterDisplayName}</button>
                                                <div className="dropdown-menu" style={{ 'z-index': '1' }}>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "All", "All")}>All</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order #", "ORDER_NO")}>Order #</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Work Order #", "ISA_WORK_ORDER_NO")}>Work Order #</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Asset ID", "ISA_MACHINE_NO")}>Asset ID</a>
                                                </div>
                                            </div>
                                            <input type="text" className="form-control pr-5 order-status-search" placeholder={t("Search")} aria-label="Text input with dropdown button" id="searchTxt" value={this.state.SearchedTxt} onChange={this.handleSearchTxt} onKeyDown={(e) => this.searchKeyPress(e)} />
                                            <span className={this.state.SearchedTxt != null && this.state.SearchedTxt != "" && this.state.SearchedTxt != " " ? "search-cancel-header cursor-pointer" : "d-none"} style={{ "top": "11px", "right": "24px" }} onClick={() => this.ClearSearch()}></span>
                                            <span className="dropdown-search-icon" onClick={(e) => this.handleHeaderSearch()} />
                                        </div>

                                        <div class="float-right mr-4 mt-3 mb-3">
                                            {this.state.isSearch ? <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 ml-2" id="savePref" onClick={this.SavePrefCheckForGroup}><Trans>Save Preference</Trans></button> : null}
                                        </div>
                                    </div>
                                    {this.state.searchAval ?
                                        <div className="row">
                                            <div className="col-md-12 mb-2">
                                                <div className="indicate-alert float-left mt-2 mb-2">
                                                    <Trans>Indicates Alternate Requestor Approver</Trans>
                                                </div>
                                                {/*[8/9/22, Bug - 2366, PC_OS_01, Dhevanesam R]*/}
                                                <div className="float-right" style={{ "marginRight": "-6px" }}>{this.state.OrdertotalCount > 0 ? <>
                                                    {this.state.buyCheckedItembool && (
                                                        <button className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" onClick={this.addFavItemsToCart}>
                                                            <Trans>Buy Checked Items</Trans>
                                                        </button>
                                                    )}
                                                    {/*<button className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" onClick={this.expandAll}>{t(!this.state.isExpand ? `Expand All` : `Collapse All`)}</button>*/}
                                                    <button className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" onClick={this.ExportToExcel}><Trans>Export to Excel</Trans></button></> : null}



                                                    {/*<div className="input-group dropdown-search float-left mb-2 order-filter-width" style={{ "width": "307px"}}>
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-secondary dropdown-toggle pri-btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t(this.state.filterDisplayName)}</button>
                                                            <div className="dropdown-menu" style={{}}>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "All", "All")}>{t("All")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Business Unit", "BUSINESS_UNIT_OM")}>{t("Business Unit")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order #", "ORDER_NO")}>{t("Order #")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Work Order", "ISA_WORK_ORDER_NO")}>{t("Work Order")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order Entered By", "ENTEREDEMPNAME")}>{t("Order Entered by")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Ordered For", "OPRID_ENTERED_BY")}>{t("Ordered For")}</a>                                                                
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Item id", "INV_ITEM_ID")}>{t("Item ID")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Priority", "LINE_PRIOR")}>{t("Priority")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "PO Id", "PO_ID1")}>{t("PO Id")}</a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Status", "LINE_STATUS")}>{t("Status")}</a>
                                                            </div>
                                                        </div>
                                                        {this.state.filterDisplayName != "Priority" ? <>
                                                            <input type="text" className="form-control  newsearchborderright" placeholder={t("Search")} aria-label="Text input with dropdown button" id="searchTxt" onKeyDown={this.AnykeyPress} onChange={this.handleSearchTxt} />
                                                            <div className="input-group-append newsearchdiv">
                                                                <span className="input-group-text search-box newsearch  " id="basic-text1">
                                                                    <i className="fas fa-search text-grey" aria-hidden="true" /></span>
                                                            </div>
                                                            <span className="dropdown-search-icon cursor-pointer iconchange" onClick={() => this.handleSearch(false, "")} id="searchIcon" />
                                                        </> :
                                                            <div className="float-left priority-dropdown" id="priorityDiv">
                                                                <label className="custom-radiobtn"><Trans>Priority</Trans>
                                                                    <input type="radio" checked={this.state.priorityFlag == 1 ? true : false} value="1" name="priorityOption" onChange={() => this.handlePriority(1)} />
                                                                    <span className="checkmark checkmark-org" />
                                                                </label>
                                                                <label className="custom-radiobtn"><Trans>Non-priority</Trans>
                                                                    <input type="radio" checked={this.state.priorityFlag == 2 ? true : false} value="2" name="priorityOption" onChange={() => this.handlePriority(2)} />
                                                                    <span className="checkmark checkmark-org" />
                                                                </label>
                                                            </div>}
                                                    </div> */}
                                                    {/*[4/20/22, CAS -31, Dhevanesam R]*/}
                                                    {/* Start of commenting Advanced filter*/}
                                                    {/*<div className="dropdown float-right">
                                                        <a href="#" className="float-right filter-icon mt-1 mx-2" title="Advanced Filter" onClick={(e) => this.filtertoggle(e)} ><img src="images/filter-icon.png" alt="Filter Icon" />
                                                        </a>
                                                        <div className={`dropdown-menu order-maintenance-filter m-0 mobile-filter-ch ${this.state.isFilterOpen ? 'd-block' : ''}`} id="advanced-filter">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-md-12 p-0 pb-2 border-bottom mb-3">
                                                                        <span className="filter-title pl-3"><Trans>Advanced Filter</Trans></span>
                                                                        <button type="button" className="filter-close close pr-2 filter-btn" onClick={(e) => this.filtertoggle(e)}><span className="filter-close-icon" /></button>
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <label className="catalog-label col-md-12 float-left mb-0"><Trans>Order Date</Trans></label>
                                                                        <div className="col-md-6 float-left mb-4">
                                                                            <label className="catalog-label mb-0"><Trans>From</Trans></label>
                                                                           
                                                                            <DatePicker className="form-control"
                                                                                id="OF"
                                                                                fixedHeight
                                                                                placeholderText="MM/DD/YYYY"
                                                                                dateFormat="MM/dd/yyyy"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                selected={this.state.OFromDate}
                                                                                maxDate={this.state.OToDate}
                                                                                onChange={(e) => this.handleDatePicker(e, "OFromDate")}
                                                                            //value={this.state.FromDate}
                                                                            />
                                                                            <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#OF`).focus()} />

                                                                        </div>
                                                                        <div className="col-md-6 float-left mb-4">
                                                                            <label className="catalog-label mb-0 w-100"><Trans>To</Trans></label>
                                                                            
                                                                            <DatePicker className="form-control"
                                                                                id="OT"
                                                                                fixedHeight
                                                                                placeholderText="MM/DD/YYYY"
                                                                                dateFormat="MM/dd/yyyy"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                selected={this.state.OToDate}
                                                                                minDate={this.state.OFromDate}
                                                                                onChange={(e) => this.handleDatePicker(e, "OToDate")}
                                                                            />
                                                                            <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#OT`).focus()} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <label className="catalog-label col-md-12 float-left mb-0"><Trans>Delivery Date</Trans></label>
                                                                        <div className="col-md-6 float-left mb-4">
                                                                            <label className="catalog-label mb-0"><Trans>From</Trans></label>
                                                                            
                                                                            <DatePicker className="form-control "
                                                                                id="NF"
                                                                                fixedHeight
                                                                                placeholderText="MM/DD/YYYY"
                                                                                dateFormat="MM/dd/yyyy"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                selected={this.state.NFromDate}
                                                                                maxDate={this.state.NToDate}
                                                                                onChange={(e) => this.handleDatePicker(e, "NFromDate")}
                                                                            //value={this.state.FromDate}
                                                                            />
                                                                            <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#NF`).focus()} />

                                                                        </div>
                                                                        <div className="col-md-6 float-left mb-4 ">
                                                                            <label className="catalog-label mb-0 w-100"><Trans>To</Trans></label>
                                                                            <DatePicker className="form-control "
                                                                                id="NT"
                                                                                fixedHeight
                                                                                placeholderText="MM/DD/YYYY"
                                                                                dateFormat="MM/dd/yyyy"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                selected={this.state.NToDate}
                                                                                minDate={this.state.NFromDate}
                                                                                onChange={(e) => this.handleDatePicker(e, "NToDate")}
                                                                            />
                                                                            <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#NT`).focus()} />

                                                                        </div>
                                                                    </div>                                                                   
                                                                    <div className="col-md-12 mb-2">
                                                                        <div className="float-right">
                                                                            <button className="btn btn-primary filter-clear-btn px-3 mr-2 filter-btn" onClick={this.clearButtonClick}><Trans>Clear</Trans></button>
                                                                            <button className="btn btn-primary custom-btn-primary filter-btn primary-btn wallet-btn px-3" onClick={this.ApplyButtonClick}><Trans>Apply</Trans></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>*/}
                                                    {/* End of commenting Advanced filter*/}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="orderStat-LineCSS">
                                                    <table className="table ZEUS_Grid FOM-grid chk-ordertbl">
                                                        <thead>
                                                            <tr>
                                                                <th />
                                                                <th className="d-sm-none d-md-table-cell position-sort-icon"><Trans>Order #</Trans><span className="orderstatus-sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ORDER_NO")}><img src="Images/sort2.svg" /></span><span className="orderstatus-sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ORDER_NO")}><img src="Images/sort1.svg" /></span></th>
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Work Order #</Trans><span className="orderstatus-sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ISA_WORK_ORDER_NO")}><img src="Images/sort2.svg" /></span><span className="orderstatus-sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ISA_WORK_ORDER_NO")}><img src="Images/sort1.svg" /></span></th>
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Asset ID</Trans><span className="orderstatus-sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ISA_MACHINE_NO")}><img src="Images/sort2.svg" /></span><span className="orderstatus-sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ISA_MACHINE_NO")} ><img src="Images/sort1.svg" /></span></th>
                                                                {/*<th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Ship To</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "SHIPTO_ID")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "SHIPTO_ID")} ><img src="Images/sort1.svg" /></span></th>*/}
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>WO Description</Trans><span className="orderstatus-sort-up ml-1" onClick={(e) => this.Sorting("ASC", "WODESC")}><img src="Images/sort2.svg" /></span><span className="orderstatus-sort-down ml-1" onClick={(e) => this.Sorting("DESC", "WODESC")} ><img src="Images/sort1.svg" /></span></th>
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Order Entered by</Trans><span className="orderstatus-sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ENTEREDBYNAME")}><img src="Images/sort2.svg" /></span><span className="orderstatus-sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ENTEREDBYNAME")} ><img src="Images/sort1.svg" /></span></th>
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Order Date</Trans></th>
                                                                {/*23/7/2024 - Zeus 349 - Swap Required by date and Expected delivery date - Shanmugapriya*/}
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon exp-date"><Trans>Expected Delivery Date</Trans></th>
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Status</Trans></th>
                                                                <th className="d-none d-sm-none d-md-table-cell position-sort-icon text-center"><Trans>Action</Trans></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.totalCount > 0 ? this.bindHeaderDataGrid() :
                                                                (

                                                                    <tr>
                                                                        <td colSpan="11">
                                                                            <section className="site-undercontruction container text-center center-panel pt-4">
                                                                                <div className="img-section">
                                                                                    <img src="images/nosearch.jpg" alt="imagefile" />
                                                                                </div>
                                                                                <p className="search-result-content pt-4"><Trans>Sorry, we couldn’t find any search result</Trans>
                                                                                </p>
                                                                            </section>
                                                                        </td>
                                                                    </tr>)}
                                                            {/*{this.bindDataGrid()}*/}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/*[8/2/2024 - Zeus-317 - Set the records per page value in browser cookies - Shanmugapriya] */}
                                            {this.state.OrdertotalCount > 0 ? <>
                                                <div className="col-md-12">
                                                    <span className="total-records mt-3 d-inline-block">
                                                        <Trans>Number of Records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
                                                        <select class="form-control itemPerPage item-per-page mt-3 d-inline" value={this.state.RecCount} onChange={this.handleRecCountChange}>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                    </span>
                                                    {this.state.OrdertotalCount > this.state.RecCount ? <ReactPaginate
                                                        previousLabel={<span className="right-pagination-arrow" />}
                                                        nextLabel={<span className="left-pagination-arrow" />}
                                                        previousClassName={'page-item'}
                                                        nextClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextLinkClassName={'page-link'}
                                                        disabledClassName={'disabled'}
                                                        breakLabel={'...'}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link'}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={2}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={'custom-pagination pagination'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        activeClassName={'active'}
                                                        hrefBuilder={() => '#'}
                                                        forcePage={this.state.PageNo - 1}
                                                    /> : null}
                                                </div></> : null}

                                        </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal" id="order-approval-history">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Order Details</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
                               </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        {/* <span className="float-left cc-help-info"><Trans>Order</Trans><span> </span> <Trans>Details</Trans></span>*/}
                                        <span className="float-right cc-help-info">{this.state.ApprovalHistoryL.CONAME} </span>
                                    </div>
                                    <div className="row" style={{ flexWrap: 'nowrap', width: '70%' }}>
                                        <div className="col-md-12 edit-desc-field mb-4 " style={{ marginLeft: '5%', marginRight: '0%' }}>

                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Unit of measure</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.UNIT == null || this.state.ApprovalHistoryL.UNIT == "" || this.state.ApprovalHistoryL.UNIT == " " ? "-" : this.state.ApprovalHistoryL.UNIT}</label>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Price/Unit</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.PRICE == null || this.state.ApprovalHistoryL.PRICE == "" || this.state.ApprovalHistoryL.PRICE == " " ? "-" : this.state.ApprovalHistoryL.PRICE}</label>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Supplier</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.SUPPLIER == null || this.state.ApprovalHistoryL.SUPPLIER == "" || this.state.ApprovalHistoryL.SUPPLIER == " " ? "-" : this.state.ApprovalHistoryL.SUPPLIER}</label>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Manufacturer</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.MFG == null || this.state.ApprovalHistoryL.MFG == "" || this.state.ApprovalHistoryL.MFG == " " ? "-" : this.state.ApprovalHistoryL.MFG}</label>
                                            </div>

                                        </div>
                                        <div className="col-md-12 edit-desc-field mb-4" style={{ margin: '0%' }}>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Currency</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.CURRENCY == null || this.state.ApprovalHistoryL.CURRENCY == "" || this.state.ApprovalHistoryL.CURRENCY == " " ? "-" : this.state.ApprovalHistoryL.CURRENCY}</label>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><span>Rev. </span><Trans>Due date</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.REV_DUE_DATE == null || this.state.ApprovalHistoryL.REV_DUE_DATE == "" || this.state.ApprovalHistoryL.REV_DUE_DATE == " " ? "-" : this.state.ApprovalHistoryL.REV_DUE_DATE}</label>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Approved By</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.APPROVALBY == null || this.state.ApprovalHistoryL.APPROVALBY == "" || this.state.ApprovalHistoryL.APPROVALBY == " " ? "-" : this.state.ApprovalHistoryL.APPROVALBY}</label>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-5 quick-label"><Trans>Ship Doc</Trans></label>
                                                <label className="col-md-7 quick-value">{this.state.ApprovalHistoryL.SHIPDOC == null || this.state.ApprovalHistoryL.SHIPDOC == "" || this.state.ApprovalHistoryL.SHIPDOC == " " ? "-" : this.state.ApprovalHistoryL.SHIPDOC}</label>
                                            </div>

                                        </div>
                                    </div>
                                    {this.state.ApprovalhistoryArr.length > 0 ?
                                        <div className="col-lg-12 float-left ">
                                            <div className="table-responsive">
                                                <table className="table ZEUS_Grid FOM-grid">
                                                    <thead>
                                                        <tr>
                                                            <th><Trans>Approved By User ID</Trans></th>
                                                            <th><Trans>Approved By</Trans></th>
                                                            <th><Trans>Approval Status</Trans></th>
                                                            <th><Trans>Approval Type</Trans></th>
                                                            <th><Trans>Date</Trans></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.historyGridBind()}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null}

                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" data-dismiss="modal"><Trans>Close</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="shipmentpopup">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Proof of delivery</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
                               </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <span className="float-left cc-help-info"></span>
                                        <span className="float-right cc-help-info">{CookieService.getLocalStorageValues("Session_CONAME")} </span>
                                    </div>
                                    {this.state.usernamedetail.length > 0 ? this.shippopup() : null}


                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-4" data-dismiss="modal"><Trans>Close</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal" id="po-details" >
                    <div className="modal-dialog modal-xl custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>PO Details</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="col-md-12 p-0">
                                    <span className="float-right cc-help-info ">{CookieService.getLocalStorageValues("Session_CONAME")} </span>
                                </div>
                                <div className="col-md-12 p-0 float-left my-3">
                                    <div className="row mb-3">
                                        <h5 className="col-md-12 info-label "><Trans>PO Details</Trans></h5>
                                    </div>
                                    {this.state.VenderDetail[0] != undefined || this.state.VenderDetail.length > 0 ?

                                        this.POData() : null}

                                    {this.state.VenderDetail[0] != undefined || this.state.VenderDetail.length > 0 ?


                                        this.PODetailhd() : null}
                                </div>
                                <div className="col-md-12 float-left mb-3 p-0">
                                    <label className="info-label pl-1 "><Trans>PO Header Status</Trans>:</label>
                                    {this.PoHdStatus()}

                                </div>
                                <div className="col-md-12 mb-2 float-left p-0">
                                    <button className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" onClick={this.POExpandAll}><Trans>Expand All</Trans></button>
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn float-left px-4 mr-3" onClick={this.POCollapseAll} ><Trans>Collapse All</Trans></button>
                                </div>
                                <div className="col-lg-12 float-left p-0">
                                    <div className="table-responsive">
                                        <table className="table ZEUS_Grid FOM-grid chk-ordertbl">
                                            <thead>
                                                <tr>
                                                    <th />
                                                    <th><Trans>Req Line #</Trans></th>
                                                    <th><Trans>Line #</Trans></th>
                                                    <th className="d-none d-sm-none d-md-table-cell"><Trans>Sch Number</Trans></th>
                                                    <th><Trans>Line Status</Trans></th>
                                                    <th className="d-none d-sm-none d-md-table-cell "><Trans>Original Due Date</Trans></th>
                                                    {/*[5/10/22, CAS - 31, Dhevanesam R]*/}
                                                    <th className="d-none d-sm-none d-md-table-cell "><Trans>Delivery Date</Trans></th>
                                                    <th className="d-none d-sm-none d-md-table-cell "><Trans>Request by Date</Trans></th>
                                                    <th className="text-right d-none d-sm-none d-md-table-cell "><Trans>PO QTY</Trans></th>
                                                    <th className="text-right d-none d-sm-none d-md-table-cell "><Trans>Received QTY</Trans></th>
                                                    <th className="text-right d-none d-sm-none d-md-table-cell "><Trans>Reject QTY</Trans></th>
                                                    {this.state.showprice != "0" && this.state.blockprice == "N" ? <th className="text-right"><Trans>Purchase Price</Trans></th> : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.bindPODataGrid()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center float-left mb-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4" data-dismiss="modal"><Trans>Close</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="modal" id="shipmentBOL">
                    <div className="modal-dialog modal-xl custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Shipment BOL</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            {this.state.shipmentBOL[0] != undefined ?
                                this.bindShipmentBol() : null}
                        </div>
                    </div>
                </div>
                <div className={this.state.PDFCss} id="pdfPreview" data-backdrop="static" data-keyboard="false" style={{ display: "none" }}>
                    <PDFPreviewComponent key={OrderSuccessCOunt} base64URL={this.state.base64URL} pdfTitle={t("Order Status Report")} {...this.props} />
                </div>

                <div className="modal" id="CancelItem-confirmation">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.CancelItemClosePopUp("LineItem")}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block"><Trans>Do you want to cancel this item ?</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal" onClick={() => this.CancelItemClosePopUp("LineItem")}><Trans>No</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.CancelItemConfirmed("LineItem")}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="CancelOrder-confirmation">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.CancelItemClosePopUp("OrderItem")}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block"><Trans>Do you want to cancel this order ?</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal" onClick={() => this.CancelItemClosePopUp("OrderItem")}><Trans>No</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.CancelItemConfirmed("OrderItem")}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="CanceledItemSuccess">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.CancelItemSuccessClosePopUp()}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Order Cancellation</Trans></label>
                                        <label className="confirm-content d-block"><Trans>SDI will make every effort to cancel your order before it Ships. Please contact Support for any inquiries about this order.</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={() => this.CancelItemSuccessClosePopUp()}><Trans>Ok</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="CanceledItemReason">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h5 class="modal-title" >{this.state.CommentsModalName}</h5>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.CancelItemReasonClosePopUp()}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row">
                                    {this.state.cncReasonNeeded.length > 0 ? <div className="col-12 mb-4">
                                        <label className="catalog-label">Reason</label>
                                        <select className="form-control" name="CancelItemReasonDrop" id="CancelItemReasonDrop" value={this.state.CancelItemReasonDrop} onChange={this.handleReasonChange} >
                                            <option value="Select">Select</option>
                                            <option value="Part is no longer needed">Part is no longer needed</option>
                                            <option value="Wrong Item Ordered">Wrong Item Ordered</option>
                                            <option value="Located part elsewhere?">Located part elsewhere?</option>
                                            <option value="Other, please describe">Other, please describe</option>
                                        </select>
                                        <br></br>
                                    </div> : null}

                                    <div class="col-md-12 mb-3">
                                        <label class="catalog-label" for="comments">Comments</label><span className="color-manditory">*</span>
                                        <textarea class="form-control" id="CancelComment" placeholder="Enter comments" rows="3" maxLength="254" value={this.state.Comments} onChange={(e) => this.HandleComments(e)}></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer border-0 pt-1 mb-2 px-4">
                                <button class="btn btn-secondary custom-btn-secondary secondary-btn mr-2 px-4 py-2" data-dismiss="modal" onClick={() => this.CancelItemReasonClosePopUp()}>Dismiss</button>
                                <button class="btn btn-custom-primary font-15 font-medium px-4 py-2" id="SaveCancelComments" disabled={this.state.disable_CommentsSave} onClick={e => this.CancelItemReasonConfirmPopUp()}>Save</button>
                            </div>

                        </div>
                    </div>
                </div>

                {/* My Approver Modal Starts here*/}
                <div
                    className="modal fade my-approver-details"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered custom-success-width"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header sp-delv-header align-items-center">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Approver Info
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <img src="Images/close-icon.svg" alt="close" />
                                </button>
                            </div>
                            <div className="modal-body pb-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-center d-flex flex-column justify-content-center align-items-center mt-2">
                                            {/*Current user start here*/}
                                            <p className="Font-14 font-bold mb-2 text-black">
                                                Current User
                                            </p>
                                            <div className="custom-approver-card-style next-approver-card-after w-55 pr-4 pl-4">
                                                <div className="d-flex align-items-center justify-content-center float-left">
                                                    <div className="d-block mr-2">
                                                        <img
                                                            className=""
                                                            src="Images/user-icon.svg"
                                                            alt="user-icon"
                                                        />
                                                    </div>
                                                    <div className="d-block text-left">
                                                        <p className="font-medium font-12 mb-0 mt-3 text-black order-status-workorder" data-toggle="tooltip" title={this.state.CurrentUserName} data-label="CURRENTUSER">
                                                            {this.state.CurrentUserName.length > 19 ? `${this.state.CurrentUserName.substring(0, 19)}...` : this.state.CurrentUserName}
                                                        </p>
                                                        <p className="mb-3">
                                                            <span className="font-regular font-10 color-grey mr-1">
                                                                Budgetary Limit:
                                                            </span>
                                                            <span className="Font-14 font-bold text-black">
                                                                ${this.state.CurrentUserLimit}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*Current user ends here*/}
                                            {/*Next Approver starts here*/}
                                            <p className="Font-14 font-bold mb-2 text-black">
                                                Next Approver
                                            </p>
                                            <div className="custom-approver-card-style w-55 pr-4 pl-4">
                                                <div className="d-flex align-items-center justify-content-center float-left">
                                                    <div className="d-block mr-2">
                                                        <img
                                                            className=""
                                                            src="Images/user-icon.svg"
                                                            alt="user-icon"
                                                        />
                                                    </div>
                                                    <div className="d-block text-left">
                                                        <p className="font-medium font-12 mb-0 mt-3 text-black order-status-workorder" data-toggle="tooltip" title={this.state.NextApprover} data-label="NEXTAPPROVER">
                                                            {this.state.NextApprover.length > 19 ? `${this.state.NextApprover.substring(0, 19)}...` : this.state.NextApprover}
                                                        </p>
                                                        <p className="mb-3">
                                                            <span className="font-regular font-10 color-grey mr-1">
                                                                Budgetary Limit:
                                                            </span>
                                                            <span className="Font-14 font-bold text-black">
                                                                {this.state.NextApproverLimit != null ? `$${this.state.NextApproverLimit}` : "-"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*Next Approver ends here*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*My Approver Modal Ends here*/}
                {/*Header notes popup starts here*/}
                <div className="modal fade work-order-info" id="header-notes" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog custom-work-order-width modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header sp-delv-header">
                                <h6 className="modal-title font-medium font-16" id="exampleModalLongTitle">Order Notes</h6>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            <div className="modal-body py-4">
                                <div className="row">
                                    {/*Header notes starts here*/}
                                    <div className="col-md-12" style={{ "float": 'right' }}>
                                        <span style={{ "margin-top": "7px" }} onClick={() => this.copyNotes(this.state.headerNotes)}><img className="px-1" src="Images/CopyImage.png" alt="Copy" title="Copy" style={{ height: '20px', "float": 'right' }} /></span>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="font-16 font-regular modal-inner-label-value" style={{ "word-wrap": 'break-word', "width": '100%' }}>{this.state.headerNotes}</label>
                                    </div>
                                    {/*Header notes ends here*/}
                                </div>
                            </div>
                            {/*footer starts here*/}
                            <div className="modal-footer d-flex justify-content-center border-0 pt-0 pb-4">
                                <button type="button" className="btn custom-btn-primary px-4 font-16 font-medium" data-dismiss="modal">OK</button>
                            </div>
                            {/*footer starts here*/}
                        </div>
                    </div>
                </div>
                {/*Header Notes popup end here*/}
                {/*!--Approver List Modal Starts here-->*/}
                <div className="modal fade approver-chain-details pl-0" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered approver-custom-width modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header sp-delv-header align-items-center">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Approval Chain
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => this.closeApproverspopup()}
                                >
                                    <img src="Images/close-icon.svg" alt="close" />
                                </button>
                            </div>
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-center d-flex flex-column justify-content-center align-items-center mt-2">
                                            {this.bindApproverList()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*!--Approver List Modal Ends here-->*/}

                {/*!--Remainder Modal Starts here-->*/}
                <div
                    className="modal fade remaind-alternate-approver"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content py-2">
                            {/*Delete Starts here*/}
                            <div className="modal-body pb-0">
                                <div className="row">
                                    <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img
                                            className="mb-3"
                                            src="images/question-icon.svg"
                                            alt="question-icon"
                                        />
                                        <h5 className="mb-2 font-16 font-bold color-light-black">
                                            Confirm
                                        </h5>
                                        <p className="mb-1 font-13 font-regular color-light-black">
                                            Are you sure you want to notify this approver?
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/*Delete ends here*/}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button
                                    className="btn btn-custom-secondary font-15 font-medium mr-2 mb-2 px-4 py-2"
                                    data-dismiss="modal" onClick={() => this.closeApproverspopup()}
                                >
                                    No
                                </button>
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" onClick={() => this.sendRemiander()}>
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*!--Remainder Modal Ends here-->*/}
                {/*[3/27/2024]Z 268 - Notes display modal starts here*/}
                <div class="modal fade order-notes pl-0" tabindex="-1" role="dialog" id="OrderNotesModal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered ordernotes-custom-width  modal-dialog-scrollable" role="document">
                        <div class="modal-content border-0">
                            <div class="modal-header sp-delv-header align-items-center">
                                <h5 class="modal-title" id="exampleModalLongTitle">{this.state.notesModalLbl}</h5>
                                <button type="button" className="btn close-btn" data-dismiss="modal" >X</button>
                            </div>

                            <div class="modal-body pt-4">
                                <div class="row">
                                    {this.BindNotesTypeLabel()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*[3/27/2024]Z 268 - Notes display modal ends here*/}

            </div>
        );




    };


};
//[PC_07, 09/09/21, Author - Dhevanesam R]
export default withTranslation()(OrderStatusComponent);