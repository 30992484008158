import * as $ from 'jquery';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import swal from 'sweetalert';
import * as CoopLandingAction from '../actions/CoopLandingAction';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import CoopLandingStore from '../stores/CoopLandingStore';
let currentPagePath;

class coopLandingComponent extends Component {
    constructor(props) {

        super(props);
        this.pageLoadResponse = this.pageLoadResponse.bind(this);
        this.state = {
            availableSuppliers: [],
            enrolledSuppliers: []
        }
    }
    /**
    * PS_ZE_305_APP_05/Sathis/05/10/2024
    * Invoke this method on the click of coopLanding from menu bar.
    * */
    componentDidMount() {
        this.pageLoad();
        CoopLandingStore.on('coopClientPageLoad', this.pageLoadResponse.bind(this));
    }
    /**
    * PS_ZE_305_APP_06/Sathis/05/10/2024
    * Invoke this method while redirect to other panels from coopLanding.
    * */
    componentWillUnmount() {
        CoopLandingStore.removeListener('coopClientPageLoad', this.pageLoadResponse.bind(this));
    }

    /****************************************************************Action methods starts here***********************************************************************/


    /**
    * PS_ZE_305_APP_07/Sathis/05/10/2024
    * This method invokes on page loaD.
    * */
    pageLoad = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const clientURL = queryParams.get('client');
        let pageLoadBO = {
            clientURL: clientURL,
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        CoopLandingAction.pageLoadAction(pageLoadBO)
    }

    /**
    * PS_ZE_305_APP_08/Sathis/05/10/2024
    * This method invokes to pageload response
    * */
    pageLoadResponse = () => {
        const { t } = this.props;
        const response = JSON.parse(CoopLandingStore.pageLoadResponse);
        const errorDt = response.errorDt;
        const clientDetailsDt = response.clientDetailsDt;
        let availableSuppliers = []
        let enrolledSuppliers = []
        if (errorDt[0].errorMessage.toUpperCase() == "FALSE") {
            if (clientDetailsDt.length > 0) {
                const clientName = clientDetailsDt[0].CLIENT_NAME;
                this.props.displayClientName(clientName);
                clientDetailsDt.forEach((value) => {
                    if (value.STATUS === "A") {
                        let obj = {
                            image: value.VENDOR_IMG,
                            URL: value.ENROLLMENT_URL
                        }
                        availableSuppliers.push(obj);
                    }
                    else if (value.STATUS === "E") {
                        let obj = {
                            image: value.VENDOR_IMG,
                            URL: value.VENDOR_URL
                        }
                        enrolledSuppliers.push(obj);
                    }
                });

                this.setState({ enrolledSuppliers, availableSuppliers });

            }
            else {
                $(`#coopLanding-warning-popup`).modal('show')
            }

        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            })
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    bindSuppliers = (supplierDetails) => {
        return supplierDetails.map((supplier, index) => (
            <div className="col-md-4 col-lg-2 sd-custom-col">
                <div className="sdi-vendor-card  d-flex justify-content-center align-items-center mb-3">
                    <a href={supplier.URL}><img src={'images/' + supplier.image} alt="Supplier Image" /></a>
                </div>
            </div>
        ));
    };
    /**
    * PS_ZE_305_APP_09/Sathis/05/10/2024
    * This method invokes to bind HTML.
    * */
    render() {
        const { t } = this.props;
        return (
            <>
                <div className="container-fluid content-margin " style={{ 'margin-top': '70px' }} >
                    <div className="row justify-content-center">
                        {/*Page Heading starts here*/}
                        {this.state.enrolledSuppliers.length > 0 ?
                            <div className="col-md-12 mt-3">
                                <h5 className="page-title mb-3 page-heading font-18 sdi-coop-font">Enrolled suppliers</h5>
                                <div className="row">
                                    {this.bindSuppliers(this.state.enrolledSuppliers)}
                                </div>
                            </div> : null}

                        {this.state.availableSuppliers.length > 0 ?
                            <div className="col-md-12 mt-3">
                                <h5 className="page-title mb-3 page-heading font-18 sdi-coop-font" style={{ 'margin-top': '80px' }}>Available suppliers</h5>
                                <div className="row">
                                    {this.bindSuppliers(this.state.availableSuppliers)}
                                </div>
                            </div> : null}

                    </div>
                </div>



                {/*Modal Starts here*/}
                <div className="modal fade success-customer" id="coopLanding-warning-popup" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img className="mb-3" src="images/alert-Icon.svg" alt="alert" />
                                        <p className="mb-1 font-14 font-regular color-light-black">Invalid client</p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

            </>
        );
    };
}
export default withTranslation()(coopLandingComponent);