/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/17/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class ShopRedirectStore extends EventEmitter {
    shopRedirectData = "";
    /**
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleShopRedirectStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.Shopredirect_AddPunchoutDatatoCart: {
                this.shopRedirectData = action.action.response;
                this.emit('shopRedirect');
                break;
            }
        }
    }
}
const objShopRedirectStore = new ShopRedirectStore;
// Dispatcher code
Dispatcher.register(objShopRedirectStore.handleShopRedirectStore.bind(objShopRedirectStore));
export default objShopRedirectStore;