/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 08/17/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: PO_PC_15
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
/**
 * Ref: PO_PC_16
 * 
 * getPunchoutSites method gets called from the componentWillMount() of the component.
 * It takes a parameters getPunchoutBO which is passed from the component file.
 * @param getPunchoutBO
 */
const getPunchoutSites = async (getPunchoutBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Punchout,
            SDIConstants.methodNames.Punchout_PageLoad,
            JSON.stringify(getPunchoutBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Punchout_PageLoad, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: PO_PC_17
 * 
 * punchoutLogoClick method gets called on click of punchout logo
 * It takes a parameters punchoutLogoBO which is passed from the component file.
 * @param punchoutLogoBO
 */
const punchoutLogoClick = async (punchoutLogoBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Punchout,
            SDIConstants.methodNames.Punchout_LogoClick,
            JSON.stringify(punchoutLogoBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Punchout_LogoClick, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * getCurrentFavPage method gets called on page load
 * @param getFavPageBO
 */
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PUNCHOUT_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PUNCHOUT_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PUNCHOUT_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export {
    getPunchoutSites,
    punchoutLogoClick,
    getCurrentFavPage,
    insertFavPage,
    deleteFavPage };