/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/22/2020
 * 
 * Ref: PO_PC_19
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';

const getReqApprovals = async (reqBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_PageLoad,
            JSON.stringify(reqBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_PageLoad, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const getReqApprovalsUnauth = async (reqBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_PageLoad,
            JSON.stringify(reqBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_PageLoad, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const approveSingleOrder = async (approveSingleBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_ApproveSingleLineClickEvent,
            JSON.stringify(approveSingleBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveSingleLineClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const approveSingleOrderUnAuth = async (approveSingleBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_ApproveSingleLineClickEvent,
            JSON.stringify(approveSingleBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveSingleLineClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const declineSingleOrder = async (declineSingleBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_DeclineSingleLineClickEvent,
            JSON.stringify(declineSingleBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveSingleLineClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const declineSingleOrderUnAuth = async (declineSingleBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_DeclineSingleLineClickEvent,
            JSON.stringify(declineSingleBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveSingleLineClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const approveMultiOrder = async (approveMultiBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_ApproveClickEvent,
            JSON.stringify(approveMultiBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const approveMultiOrderUnAuth = async (approveMultiBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_ApproveClickEvent,
            JSON.stringify(approveMultiBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const declineMultiOrder = async (declineMultiBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_DeclineClickEvent,
            JSON.stringify(declineMultiBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const declineMultiOrderUnAuth = async (declineMultiBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_DeclineClickEvent,
            JSON.stringify(declineMultiBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_ApproveClickEvent, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * getCurrentFavPage method gets called on page load
 * @param getFavPageBO
 */
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "REQAPP_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "REQAPP_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "REQAPP_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

//[1/22/2024]Z 256 DeclineReason() is to update the reason for decline in quote reject table - Poornima S
const DeclineReason = async (DeclineReasonBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_DeclineReason,
            JSON.stringify(DeclineReasonBO),
            (data) => {
                debugger
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_DeclineReason, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const DeclineReasonUnAuth = async (DeclineReasonBO) => {
    try {
        SDISVC.callGateway(
            SDIConstants.moduleNames.RequestorApproval,
            SDIConstants.methodNames.RequestorApproval_DeclineReason,
            JSON.stringify(DeclineReasonBO),
            (data) => {
                debugger
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RequestorApproval_DeclineReason, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

export {
    getReqApprovals,
    getReqApprovalsUnauth,
    getCurrentFavPage,
    insertFavPage,
    deleteFavPage,
    approveSingleOrder,
    approveSingleOrderUnAuth,
    declineSingleOrder,
    declineSingleOrderUnAuth,
    approveMultiOrder,
    approveMultiOrderUnAuth,
    declineMultiOrder,
    declineMultiOrderUnAuth,
    DeclineReason,
    DeclineReasonUnAuth
}