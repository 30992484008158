import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
    width: '100%',
    height: '170px'
};
//[3/15/2023] CAS-44 GoogleMaps component is to bind map in shopping cart for a valid address provided by the customer
export class MapContainer extends Component {
    render() {
        return (
            <Map
                google={this.props.google}
                zoom={15}
                style={mapStyles}
                center={
                    {
                        lat: this.props.Latitude,
                        lng: this.props.Longitude
                    }
                }
            >
            <Marker
                position={
                    {
                        lat: this.props.Latitude,
                        lng: this.props.Longitude
                    }
                }
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDO0u-Tk58A19L3r2EoDf9I50Ch3p77IQc'
})(MapContainer);