import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as CookieService from '../CookieService';
import * as MenuAction from './MenuAction';

const tokenExpired = async () => {
    try {
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)

    }
};

const GetpageLoad = (GetSearchBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Searchpage,
            SDIConstants.methodNames.Searchpage_Page_Load,
            JSON.stringify(GetSearchBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "Searchresult", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const AddCart = (AddCartBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Searchpage,
            SDIConstants.methodNames.Searchpage_AddToCart_Click,
            JSON.stringify(AddCartBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "Status", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const catfavItem = (catfavItemBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.FavItemMaintenance_InsertFavItem,
            JSON.stringify(catfavItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FavitemAdd", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const NoncatfavItem = (NoncatfavItemBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavItemMaintenance,
            SDIConstants.methodNames.FavItemMaintenance_AddNonCatalogFavItem,
            JSON.stringify(NoncatfavItemBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FavitemAdd", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
     //[15/05/23, PS_EWVB_11, Zeus 112, Sathis. N]- EMCOR WO validation
const workOrderValidation = async (workOrderBO) => {
    try {
        debugger
        SDISVC.callAsync(
            SDIConstants.moduleNames.ServiceChannel,
            SDIConstants.methodNames.ServiceChannel_GetWorkOrderValidation ,
            JSON.stringify(workOrderBO),
            (data) => {
                debugger
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "workordercheck", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }                    
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};


export { GetpageLoad, AddCart, catfavItem, NoncatfavItem, workOrderValidation}
