
import React, { Component } from 'react';
import * as RoleMasterAction from '../actions/RoleMasterAction';
import RoleMasterStore from '../stores/RoleMasterStore';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import ReactPaginate from 'react-paginate';
import * as MenuAction from '../actions/MenuAction';
import { withTranslation, Trans } from 'react-i18next';
import CheckboxTree from 'react-checkbox-tree';
import MultiSelect from "react-multi-select-component";
import Select from 'react-select';
import swal from 'sweetalert';

let currentPagePath;


const options = [

    {
        value: 'ALL', label: 'All'
    },
    {
        value: 'ROLE ID', label: 'Role ID'
    },
    {
        value: 'ROLE NAME', label: 'Role name'
    },
    {
        value: 'USER TYPE', label: 'User type'
    },
    {
        value: 'BUSINESS UNIT', label: 'Business unit'
    },
    {
        value: 'LANDING PAGE', label: 'Landing page'
    }
]
let searchTextGlobVar = "";
class RoleMasterComponent extends Component {
    constructor(props) {


        super(props);
        this.state = {
            filterDisplayName: "All",
            businessUnitOption: [],
            businessUnitLabel: "",
            sessionUserType: "",
            businessUnit: [],
            oldRoleName: "",
            action: "",
            roleNum: "",
            roleType: "Customer",
            roleListOption: [],
            roleList: "",
            roleListLabel: [],
            landingPage: "",
            landingPagePriv: "",
            oldBusinessUnit: "",
            isFavorited: false,
            landingPageLabel: "",
            landingPageOption: [],
            landingPagePrivileage: "",
            oldRoleType: "",
            programtreePriv: [],
            PrivTree: [],
            checked: [],
            expanded: [],
            PrivTreeDisable: false,
            prevBusinessUnit: "",
            totalCount: 0,
            searchByOptions: options,
            searchType: "ALL",
            searchValue: "",
            reqCount: 10,
            pageNo: 1,
            sortColumn: "ROLENUM",
            sortValue: "DESC",
            pageLoadData: [],
            deleteRole: "",
            isInvalid: false,
            isError: false,
            roleName: "",
            displayAction: "",
            sisterSite: false
        }
    };


    componentDidMount() {

        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        this.pageLoad(this.state.pageNo, this.state.sortColumn, this.state.sortValue, searchTextGlobVar);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        RoleMasterAction.getCurrentFavPage(getFavPageBO);

        RoleMasterStore.on('pageLoad', this.pageLoadResponse.bind(this));
        RoleMasterStore.on('addNewRole', this.addNewRoleResponse.bind(this));
        RoleMasterStore.on('editRole', this.editRoleResponse.bind(this));
        RoleMasterStore.on('sistersiteBU', this.oncheckSistersiteBUResponse.bind(this));
        RoleMasterStore.on('saveRole', this.saveRoleResponse.bind(this));
        RoleMasterStore.on('deleteRole', this.deleteRoleResponse.bind(this));
        RoleMasterStore.on('favpage', this.loadFavPageResponse.bind(this));
        RoleMasterStore.on('updateFavPage', this.updateFavPageResponse.bind(this));

    }

    componentWillUnmount() {

        RoleMasterStore.removeListener('pageLoad', this.pageLoadResponse.bind(this));
        RoleMasterStore.removeListener('addNewRole', this.addNewRoleResponse.bind(this));
        RoleMasterStore.removeListener('editRole', this.editRoleResponse.bind(this));
        RoleMasterStore.removeListener('sistersiteBU', this.oncheckSistersiteBUResponse.bind(this));
        RoleMasterStore.removeListener('saveRole', this.saveRoleResponse.bind(this));
        RoleMasterStore.removeListener('deleteRole', this.deleteRoleResponse.bind(this));
        RoleMasterStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        RoleMasterStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
        //Zeus-310-Niranjana/05 / 28 / 2024 The searchTextGlobVar is emptied while unmounting//    
        searchTextGlobVar = "";  

    }

    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(RoleMasterStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        if (RoleMasterStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(RoleMasterStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(RoleMasterStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };

    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Role Master",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            RoleMasterAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            RoleMasterAction.deleteFavPage(deleteFavPageBO)
        }
    };

    /**         
     * PS_ZE_35_APP_14/Dhinesh/02/05/2024
     * Invoke this method to get page load data
     * */

    pageLoad = (pageNo, sortColumn, sortValue, searchTextGlobVar) => {

        $('#RoleForm').addClass('hide');
        let pageLoadBO = {
            userType: CookieService.getLocalStorageValues("Session_SDIEMP"),
            businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            empBusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            searchValue: searchTextGlobVar.trim(),
            searchType: this.state.searchType,
            pageNo: pageNo,
            sortValue: sortValue,
            sortColumn: sortColumn
        }
        let userType = CookieService.getLocalStorageValues("Session_SDIEMP")

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        RoleMasterAction.pageLoadAction(pageLoadBO);
        this.setState({ sessionUserType: userType });
    }

    /**         
     * PS_ZE_35_APP_17/Dhinesh/02/05/2024
     * This method invokes to handle page load response
     * */
    pageLoadResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RoleMasterStore.pageLoadResponse);
        let PageloadDt = response.PageLoadDt
        let errorDt = response.errorDt
        if (errorDt == undefined || errorDt == null) {
            let count;
            if (PageloadDt != undefined && PageloadDt.length === 0) {
                count = 0;
            }
            else {
                count = PageloadDt[0].TOTALRECORDS;
            }
            let pg = Math.ceil((count > 0 ? count : 0) / this.state.reqCount);
            this.setState({
                pageLoadData: PageloadDt, totalCount: count, pageCount: pg
            })
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

    }

    /**         
     * PS_ZE_35_APP_18/Dhinesh/02/05/2024
     *This method invokes on the click of add role and copy role button 
     * */
    addNewRoleClick = (params, action) => {
        $('#RoleGrid').addClass('hide');
        $('#RoleForm').removeClass('hide');
        let newRoleBO = {
            userType: CookieService.getLocalStorageValues("Session_SDIEMP"),
            businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            empBusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            action: params
        }
        $('#roleList').addClass('hide');
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        RoleMasterAction.addNewRoleAction(newRoleBO);
        this.setState({ displayAction: action })
    }
    /**         
     * PS_ZE_35_APP_21/Dhinesh/02/05/2024
     * This method invokes to handle add role and copy role button response
     * */
    addNewRoleResponse = () => {

        const { t } = this.props;
        let response = JSON.parse(RoleMasterStore.addNewRoleResponse);
        let businessUnitDt = response.businessunitDt;
        let rolelistDt = response.roleListDt;
        let ProgramTree = response.programtreeDt;
        let landingPage = response.landingPageDt;
        let errorDt = response.errorDt;
        if (errorDt == undefined || errorDt == null) {
            let businessUnitOption = [];
            let businessUnitOptionSelected = [];
            let landingPageOption = [{
                value: "",
                label: "Select",
                privilege: ""
            }];
            let roleListOption = [{
                value: "",
                label: "Select"

            }];

            if (businessUnitDt != undefined && businessUnitDt != null && businessUnitDt != "") {
                if (businessUnitDt.length > 0) {
                    businessUnitDt.forEach((value, index) => {
                        let locObj = {
                            value: value.BUSINESS_UNIT,
                            label: value.DESCRIPTION
                        }
                        businessUnitOption.push(locObj);
                    });
                    this.setState({ businessUnitOption: businessUnitOption, businessUnitLabel: businessUnitOptionSelected })
                }
            }

            if (landingPage != undefined && landingPage != null && landingPage != "") {
                if (landingPage.length > 0) {
                    landingPage.forEach((value, index) => {
                        let locObj = {
                            value: value.ISA_NAVIGATIONURL,
                            label: value.ISA_IDENTIFIER_NAME,
                            privilege: value.SECURITYALIAS
                        }
                        landingPageOption.push(locObj);
                    });
                    this.setState({ landingPageOption, landingPage: landingPageOption[0] })
                }
            }

            if (rolelistDt != undefined && rolelistDt != null && rolelistDt != "") {
                $('#roleList').removeClass('hide');
                if (rolelistDt.length > 0) {
                    rolelistDt.forEach((value, index) => {
                        let locObj = {
                            value: value.ROLENUM,
                            label: value.ROLES
                        }
                        roleListOption.push(locObj);
                    });
                    this.setState({ roleListOption, roleListLabel: roleListOption[0] })
                }
            }
            let TreeValueList = []
            if (ProgramTree != undefined && ProgramTree != null && ProgramTree != "") {

                let Parenttree = ProgramTree.filter((item, index) => item.ISA_PARENT_IDENT == null);
                ProgramTree = ProgramTree.filter((x) => x.ISA_IDENTIFIER != 1 && x.ISA_IDENTIFIER != 156 && x.ISA_IDENTIFIER != 319 && x.ISA_IDENTIFIER != 320);
                let CatTreeWithChild = ProgramTree.filter((x) => x.ISA_CATEGORY_ID != null && x.ISA_CATEGORY_ID != "");
                CatTreeWithChild = CatTreeWithChild.sort((p1, p2) => (p1.ISA_CATEGORY_ID > p2.ISA_CATEGORY_ID) ? 1 : (p1.ISA_CATEGORY_ID < p2.ISA_CATEGORY_ID) ? -1 : 0);
                let WithoutCategory = ProgramTree.filter((x) => x.ISA_CATEGORY_ID == null || x.ISA_CATEGORY_ID == "");
                CatTreeWithChild = CatTreeWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_CATEGORY_ID === v.ISA_CATEGORY_ID)) === i);
                if (CatTreeWithChild.length > 0) {
                    CatTreeWithChild.forEach((catVal, catInd) => {
                        let ParentTree = [];
                        let ParentValue = [];
                        let ParentWithChild = [];
                        let WithoutParent = [];
                        ParentTree = ProgramTree.filter((x) => x.ISA_CATEGORY_ID == catVal.ISA_CATEGORY_ID);
                        WithoutParent = ParentTree.filter((x) => x.ISA_PARENT_IDENT == null || x.ISA_PARENT_IDENT == "");
                        ParentWithChild = ParentTree.filter((x) => x.ISA_PARENT_IDENT != null && x.ISA_PARENT_IDENT != "");
                        ParentWithChild = ParentWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_PARENT_IDENT === v.ISA_PARENT_IDENT)) === i);
                        if (ParentWithChild.length > 0) {
                            ParentWithChild.forEach((parentVal, parentInd) => {
                                let ChildTree = [];
                                let ChildValue = [];
                                ChildTree = ProgramTree.filter((x) => x.ISA_PARENT_IDENT == parentVal.ISA_PARENT_IDENT);
                                if (ChildTree.length > 0) {
                                    ChildTree.forEach((childVal, childInd) => {
                                        let localArray;
                                        if (childVal.ACTIVE == 1) {
                                            localArray = {
                                                value: childVal.SECURITYALIAS,
                                                label: childVal.ISA_IDENTIFIER_NAME,
                                                key: childVal.ISA_IDENTIFIER,
                                                Parent: childVal.ISA_PARENT_IDENT
                                            };
                                            ChildValue.push(localArray);
                                        }
                                        else {
                                            localArray = {
                                                value: childVal.SECURITYALIAS,
                                                label: childVal.ISA_IDENTIFIER_NAME,
                                                key: childVal.ISA_IDENTIFIER,
                                                //disabled: true,
                                                className: 'disabled-child',
                                                showCheckbox: false,
                                                Parent: childVal.ISA_PARENT_IDENT
                                            };
                                            ChildValue.push(localArray);
                                        }
                                    });
                                }

                                let parentLocObj = {
                                    value: parentVal.PARENTSECALIAS,
                                    label: parentVal.ISA_PARENT_NAME + "(" + parentVal.PARENTSECALIAS + ")",
                                    children: ChildValue,
                                    key: parentVal.ISA_PARENT_IDENT,
                                    Parent: parentVal.ISA_CATEGORY_ID
                                }
                                ParentValue.push(parentLocObj);
                            });
                        }
                        if (WithoutParent.length > 0) {
                            WithoutParent.forEach((parentVal, parentInd) => {
                                let parentLocObj = {
                                    value: parentVal.SECURITYALIAS,
                                    label: parentVal.ISA_IDENTIFIER_NAME,
                                    children: "0",
                                    key: parentVal.ISA_IDENTIFIER,
                                    Parent: parentVal.ISA_CATEGORY_ID
                                }
                                ParentValue.push(parentLocObj);
                            });
                        };
                        let catLocObj = {
                            value: catVal.CATSECALIAS,
                            label: catVal.ISA_CATEGORY_NAME + "(" + catVal.CATSECALIAS + ")",
                            children: ParentValue,
                            key: catVal.ISA_CATEGORY_ID,
                            Parent: "0"
                        }
                        TreeValueList.push(catLocObj);
                    });
                }
                if (WithoutCategory.length > 0) {
                    WithoutCategory.forEach((catVal, catInd) => {
                        let catLocObj;
                        if (catVal.ACTIVE == 1) {
                            catLocObj = {
                                value: catVal.SECURITYALIAS,
                                label: catVal.ISA_IDENTIFIER_NAME,
                                children: "0",
                                key: catVal.ISA_IDENTIFIER,
                                Parent: "0"
                            }
                            TreeValueList.push(catLocObj);
                        }
                        else {
                            catLocObj = {
                                value: catVal.SECURITYALIAS,
                                label: catVal.ISA_IDENTIFIER_NAME,
                                children: "0",
                                key: catVal.ISA_IDENTIFIER,
                                Parent: "0"
                            }
                            TreeValueList.push(catLocObj);
                        }
                    })
                }

            }
            this.setState({ programtreePriv: TreeValueList, PrivTree: ProgramTree })

        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        console.log(this.state.programtreePriv, "Programtree")
    }

    /**         
    * PS_ZE_35_APP_22/Dhinesh/02/05/2024
    *This method invokes on the click of edit icon
    * */
    editRole = (roleNumber, action) => {
        let editRoleBO = {
            userType: CookieService.getLocalStorageValues("Session_SDIEMP"),
            roleNum: roleNumber,
            action: action,
            businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            empBusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        $('#RoleGrid').addClass('hide');
        $('#RoleForm').removeClass('hide');
        $('#roleList').addClass('hide');

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        RoleMasterAction.editRoleAction(editRoleBO);
        this.setState({ action: action });
    }

    /**         
    * PS_ZE_35_APP_22/Dhinesh/02/05/2024
    * This method invokes to handle edit icon response
    * */

    editRoleResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RoleMasterStore.editRoleResponse);
        let errorDt = response.errorDt;
        let editdetailDt = response.editdetailDt;
        let editprogramDt = response.editprogramDt;
        let businessunitDt = response.businessunitDt;
        let ProgramTree = response.progamtreeDt;
        let landingPage = response.landingPageDt;
        const availableMenu = SDIConstants.availablePages;
        if (errorDt == undefined || errorDt == null) {
            let businessUnitOption = [];
            let businessUnitOptionSelected = [];
            let rolePage = "";
            let businessUnit = [];
            let landingOption = [];
            let regex = /\.aspx$/;
            let roleType = "";
            console.log(this.state.landingPageOption, "Value")
            if (editdetailDt) {
                let roleNum = editdetailDt[0].ROLENUM;
                let roleName = editdetailDt[0].ROLENAME;
                businessUnit.push(editdetailDt[0].BUSINESS_UNIT);
                roleType = editdetailDt[0].ROLETYPE;
                let option = (editdetailDt[0].ROLEPAGE) ? editdetailDt[0].ROLEPAGE.trim() : editdetailDt[0].ROLEPAGE;
                if (option != null) {
                    if (regex.test(option)) {
                        let optionSplit = option.split(".");
                        let finOption = optionSplit[0];
                        let rolePageValue = '/' + finOption;
                        if (availableMenu.some((page) => page.pageURL.toLowerCase() === rolePageValue.toLowerCase())) {
                            rolePage = rolePageValue;
                        }
                        else {
                            rolePage = option;
                        }
                    }
                    else {
                        rolePage = option;
                    }
                }

                let landingPageRepop = this.state.landingPageOption;
                if (landingPageRepop != undefined && landingPageRepop != null && landingPageRepop != "") {
                    landingPageRepop.forEach((value, index) => {
                        if (value.value == rolePage) {
                            let localObj = {
                                value: value.value,
                                label: value.label,
                                privilege: value.privilege
                            }
                            landingOption.push(localObj);
                        }
                    })
                }

                if (roleType == "SDI") {
                    $('#HideBusinessUnit').addClass('hide');
                    $('#HideSistersite').addClass('hide');
                    this.setState({ businessUnit: [] })
                }
                else {
                    $('#HideBusinessUnit').removeClass('hide');
                    $('#HideSistersite').removeClass('hide');
                    this.setState({ businessUnit: businessUnit })
                }
                if (this.state.action == "ROLELIST") {
                    $('#roleList').removeClass('hide');
                    $('#HideBusinessUnit').removeClass('hide');
                    $('#HideSistersite').removeClass('hide');
                    this.setState({ roleName: "", businessUnit: "", roleType: "Customer", landingPage: rolePage, displayAction: "Copy Role", /*oldRoleName: roleName,*/ landingPagePriv: rolePage });
                }
                else {
                    this.setState({ roleNum: roleNum, roleName: roleName, roleType: roleType, landingPage: rolePage, displayAction: roleName, oldRoleName: roleName, landingPagePriv: rolePage, oldRoleType: roleType, oldBusinessUnit: businessUnit });
                }
            }

            if (editprogramDt != undefined && editprogramDt != null && editprogramDt != "") {
                let checked = []
                editprogramDt.forEach((item) => {
                    checked.push(item.ALIAS_NAME);
                });
                this.setState({ checked: checked, expanded: checked })
            }

            if (businessunitDt != undefined && businessunitDt != null && businessunitDt != "") {
                if (businessunitDt.length > 0) {
                    businessunitDt.forEach((value, index) => {
                        let locObj = {
                            value: value.BUSINESS_UNIT,
                            label: value.DESCRIPTION
                        }
                        businessUnitOption.push(locObj);
                        if (this.state.action == "EDITROLE") {
                            if (value.BUSINESS_UNIT.includes(businessUnit)) {
                                let localObj = {
                                    value: value.BUSINESS_UNIT,
                                    label: value.DESCRIPTION
                                }
                                businessUnitOptionSelected.push(localObj);
                            }
                        }
                    });

                    this.setState({ businessUnitOption: businessUnitOption, businessUnitLabel: businessUnitOptionSelected })
                }
            }

            if (landingPage != undefined && landingPage != null && landingPage != "") {
                if (landingPage.length > 0) {
                    let landingPageOption = [{
                        value: "",
                        label: "Select",
                        privilege: ""
                    }];
                    landingPage.forEach((value, index) => {
                        let locObj = {
                            value: value.ISA_NAVIGATIONURL,
                            label: value.ISA_IDENTIFIER_NAME,
                            privilege: value.SECURITYALIAS
                        }
                        landingPageOption.push(locObj);
                        if (value.ISA_NAVIGATIONURL == rolePage) {
                            let localObj = {
                                value: value.ISA_NAVIGATIONURL,
                                label: value.ISA_IDENTIFIER_NAME,
                                privilege: value.SECURITYALIAS
                            }
                            landingOption.push(localObj);
                        }
                    });

                    this.setState({
                        landingPageOption
                    })
                }
            }
            if (ProgramTree != undefined && ProgramTree != null && ProgramTree != "") {
                let TreeValueList = []
                let Parenttree = ProgramTree.filter((item, index) => item.ISA_PARENT_IDENT == null);
                ProgramTree = ProgramTree.filter((x) => x.ISA_IDENTIFIER != 1 && x.ISA_IDENTIFIER != 156 && x.ISA_IDENTIFIER != 319 && x.ISA_IDENTIFIER != 320);
                let CatTreeWithChild = ProgramTree.filter((x) => x.ISA_CATEGORY_ID != null && x.ISA_CATEGORY_ID != "");
                CatTreeWithChild = CatTreeWithChild.sort((p1, p2) => (p1.ISA_CATEGORY_ID > p2.ISA_CATEGORY_ID) ? 1 : (p1.ISA_CATEGORY_ID < p2.ISA_CATEGORY_ID) ? -1 : 0);
                let WithoutCategory = ProgramTree.filter((x) => x.ISA_CATEGORY_ID == null || x.ISA_CATEGORY_ID == "");
                CatTreeWithChild = CatTreeWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_CATEGORY_ID === v.ISA_CATEGORY_ID)) === i);
                if (CatTreeWithChild.length > 0) {
                    CatTreeWithChild.forEach((catVal, catInd) => {
                        let ParentTree = [];
                        let ParentValue = [];
                        let ParentWithChild = [];
                        let WithoutParent = [];
                        ParentTree = ProgramTree.filter((x) => x.ISA_CATEGORY_ID == catVal.ISA_CATEGORY_ID);
                        WithoutParent = ParentTree.filter((x) => x.ISA_PARENT_IDENT == null || x.ISA_PARENT_IDENT == "");
                        ParentWithChild = ParentTree.filter((x) => x.ISA_PARENT_IDENT != null && x.ISA_PARENT_IDENT != "");
                        ParentWithChild = ParentWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_PARENT_IDENT === v.ISA_PARENT_IDENT)) === i);
                        if (ParentWithChild.length > 0) {
                            ParentWithChild.forEach((parentVal, parentInd) => {
                                let ChildTree = [];
                                let ChildValue = [];
                                ChildTree = ProgramTree.filter((x) => x.ISA_PARENT_IDENT == parentVal.ISA_PARENT_IDENT);
                                if (ChildTree.length > 0) {
                                    ChildTree.forEach((childVal, childInd) => {
                                        let localArray;
                                        if (childVal.ACTIVE == 1) {
                                            localArray = {
                                                value: childVal.SECURITYALIAS,
                                                label: childVal.ISA_IDENTIFIER_NAME,
                                                key: childVal.ISA_IDENTIFIER,
                                                Parent: childVal.ISA_PARENT_IDENT
                                            };
                                            ChildValue.push(localArray);
                                        }
                                        else {
                                            localArray = {
                                                value: childVal.SECURITYALIAS,
                                                label: childVal.ISA_IDENTIFIER_NAME,
                                                key: childVal.ISA_IDENTIFIER,
                                                //disabled: true,
                                                className: 'disabled-child',
                                                showCheckbox: false,
                                                Parent: childVal.ISA_PARENT_IDENT
                                            };
                                            ChildValue.push(localArray);
                                        }
                                    });
                                }

                                let parentLocObj = {
                                    value: parentVal.PARENTSECALIAS,
                                    label: parentVal.ISA_PARENT_NAME + "(" + parentVal.PARENTSECALIAS + ")",
                                    children: ChildValue,
                                    key: parentVal.ISA_PARENT_IDENT,
                                    Parent: parentVal.ISA_CATEGORY_ID
                                }
                                ParentValue.push(parentLocObj);
                            });
                        }
                        if (WithoutParent.length > 0) {
                            WithoutParent.forEach((parentVal, parentInd) => {
                                let parentLocObj = {
                                    value: parentVal.SECURITYALIAS,
                                    label: parentVal.ISA_IDENTIFIER_NAME,
                                    children: "0",
                                    key: parentVal.ISA_IDENTIFIER,
                                    Parent: parentVal.ISA_CATEGORY_ID
                                }
                                ParentValue.push(parentLocObj);
                            });
                        };
                        let catLocObj = {
                            value: catVal.CATSECALIAS,
                            label: catVal.ISA_CATEGORY_NAME + "(" + catVal.CATSECALIAS + ")",
                            children: ParentValue,
                            key: catVal.ISA_CATEGORY_ID,
                            Parent: "0"
                        }
                        TreeValueList.push(catLocObj);
                    });
                }
                if (WithoutCategory.length > 0) {
                    WithoutCategory.forEach((catVal, catInd) => {
                        let catLocObj;
                        if (catVal.ACTIVE == 1) {
                            catLocObj = {
                                value: catVal.SECURITYALIAS,
                                label: catVal.ISA_IDENTIFIER_NAME,
                                children: "0",
                                key: catVal.ISA_IDENTIFIER,
                                Parent: "0"
                            }
                            TreeValueList.push(catLocObj);
                        }
                        else {
                            catLocObj = {
                                value: catVal.SECURITYALIAS,
                                label: catVal.ISA_IDENTIFIER_NAME,
                                children: "0",
                                key: catVal.ISA_IDENTIFIER,
                                Parent: "0"
                            }
                            TreeValueList.push(catLocObj);
                        }
                    })
                }
                this.setState({ programtreePriv: TreeValueList, PrivTree: ProgramTree })
            }
            this.setState({ landingPage: landingOption[0] });
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    //This method is invoke when the Privilege is checked in the privileges tree .
    OnCheckTree = (checked, target) => {
        let landingPageOption;

        // Check if the unchecked value is equal to landingPageValue
        if (
            this.state.landingPage &&
            this.state.landingPage.privilege !== undefined &&
            target.value !== undefined &&
            target.value === this.state.landingPage.privilege
        ) {
            landingPageOption = [{
                value: "",
                label: "Select",
                privilege: ""
            }];
            this.setState({ landingPage: landingPageOption, landingPagePriv: "" })
        } else if (target.children && Array.isArray(target.children) && target.checked == false) {
            target.children.forEach((child, childindex) => {
                if (
                    child.value !== undefined &&
                    this.state.landingPage &&
                    this.state.landingPage.privilege !== undefined &&
                    child.value === this.state.landingPage.privilege
                ) {
                    landingPageOption = [{
                        value: "",
                        label: "Select",
                        privilege: ""
                    }];
                    this.setState({ landingPage: landingPageOption, landingPagePriv: "" })
                }
                else if (child.children && Array.isArray(child.children) && target.checked == false) {
                    child.children.forEach((grandchild, grandindex) => {
                        if (
                            this.state.landingPage &&
                            this.state.landingPage.privilege !== undefined &&
                            grandchild.value === this.state.landingPage.privilege
                        ) {
                            landingPageOption = [{
                                value: "",
                                label: "Select",
                                privilege: ""
                            }];
                            this.setState({ landingPage: landingPageOption, landingPagePriv: "" })
                        }
                    });
                }
            });
        }

        this.setState({ checked });
    };






    OnChangeDropdown = (name, event) => {

        let locBusinessUnit = [];

        if (name === "roleList") {
            if (event.value !== "") {
                let roleList = event.value;
                this.editRole(roleList, "ROLELIST");
                this.setState({
                    roleList: roleList, roleListLabel: event
                });
            }
            else {
                this.setState({
                    checked: [], landingPagePriv: "",
                    landingPage: [{
                        value: "",
                        label: "Select",
                        privilege: ""
                    }],
                    roleList: "",
                    roleListLabel: [{
                        value: "",
                        label: "Select"

                    }]
                });
            }
        }
        else if (name === "businessUnit") {

            event.forEach((selected) => {
                locBusinessUnit.push(selected.value);
            })
            this.setState({
                businessUnitLabel: event, businessUnit: locBusinessUnit, sisterSite: false
            });

        }
        else if (name === "landingPage") {
            let checked = [...this.state.checked]; // Create a copy to avoid mutating state directly


            // Add the new privilege to the checked array
            if (event.value !== "" && !checked.includes(event.privilege) && event.privilege != "FAVORD" && event.privilege != "FAVITEM" && event.privilege != "USERPROF" && event.privilege != "HOME") {
                checked.push(event.privilege);
            }

            this.setState({
                landingPage: event,
                landingPagePriv: event.value,
                checked: checked
            });
        }

        console.log(locBusinessUnit);
    };

    onchangeText = (name, event) => {
        if (name == "roleName") {

            let roleName = event;
            this.setState({
                roleName: roleName
            });

        }
        else if (name == "search") {

            let searchValue = event;
            this.setState({
                searchValue: searchValue
            });
        }
    }

    onchangeRadio = (event) => {
        let roleType = event.target.value
        if (roleType == "SDI") {
            $('#HideBusinessUnit').addClass('hide');
            $('#HideSistersite').addClass('hide');
        }
        else {
            $('#HideBusinessUnit').removeClass('hide');
            $('#HideSistersite').removeClass('hide');
        }
        this.setState({ roleType: roleType });
    }

    /**         
     * PS_ZE_35_APP_35/Dhinesh/02/05/2024
     *This method invokes on the click of sorting icon 
     * */

    handlePagination = (data) => {
        let pageNo = data.selected + 1;
        this.pageLoad(pageNo, this.state.sortColumn, this.state.sortValue, searchTextGlobVar);
        // Zeus-310 - Thilak mani Chand - /05/28/2024 This update the current PageNumber Data  //
        this.setState({ pageNo: pageNo });
    };

    /**         
     * PS_ZE_35_APP_34/Dhinesh/02/05/2024
     *This method invokes on the click of sorting icon 
     * */
    sorting = (sortColumn, sortValue) => {
        this.pageLoad(this.state.pageNo, sortColumn, sortValue, searchTextGlobVar);
        this.setState({ sortColumn: sortColumn, sortValue: sortValue })
    }

    /**         
     * PS_ZE_35_APP_26 /Dhinesh/02/05/2024
     *This method invokes on the click of edit icon
     * */
    oncheckSistersiteBU = (event) => {
        const prevBusinessUnit = this.state.prevBusinessUnit;
        if (event.target.checked == true) {
            if (this.state.businessUnit.length > 0) {
                let sistersiteBO = {
                    businessUnit: this.state.businessUnit,
                    empBusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    userID: CookieService.getLocalStorageValues("Session_UserID"),
                    websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID")
                }

                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                RoleMasterAction.sistersiteBUAction(sistersiteBO);
            }
            else {
                this.setState({ isInvalid: true });
            }
        }
        else {
            let prevBusinessOption = [];
            if (prevBusinessUnit != undefined && prevBusinessUnit != null && prevBusinessUnit != "") {
                prevBusinessUnit.forEach((value, index) => {

                    prevBusinessOption.push(value.value);

                })
                this.setState({ businessUnitLabel: prevBusinessUnit, sisterSite: false, businessUnit: prevBusinessOption })
            }

        }
    }

    /**         
   * PS_ZE_35_APP_29/Dhinesh/02/05/2024
   * This method invokes to handle apply to all sistersite checkbox response
   * */
    oncheckSistersiteBUResponse = () => {
        const { t } = this.props;
        let sistersite = [];
        let objBusinessUnit = [];
        let response = JSON.parse(RoleMasterStore.oncheckSistersiteBUResponse);
        let sistersiteBUDt = response.sisterSiteDt;
        let errorDt = response.errorDt;
        if (errorDt == undefined || errorDt == null) {
            if (sistersiteBUDt != undefined && sistersiteBUDt != null && sistersiteBUDt != "") {
                let prev = this.state.businessUnitLabel;
                if (sistersiteBUDt.length > 0) {
                    sistersiteBUDt.forEach((value, index) => {
                        let localObj = {
                            value: value.BUSINESS_UNIT,
                            label: value.DESCRIPTION
                        }
                        sistersite.push(localObj);
                        objBusinessUnit.push(value.BUSINESS_UNIT);
                    });

                    console.log(objBusinessUnit, "objBusinessUnit")
                    this.setState({ businessUnitLabel: sistersite, businessUnit: objBusinessUnit, sisterSite: true, prevBusinessUnit: prev })
                }
            }


        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    /**         
     * This method invokes to handle pageload while clicking search icon response
     * */
    handleHeaderSearch = () => {
        searchTextGlobVar = this.state.searchValue;
        let pageNo = 1;
        let sortColumn = "ROLENUM";
        let sortValue = "DESC";
        this.pageLoad(pageNo, sortColumn, sortValue, searchTextGlobVar)
        this.setState({ pageNo: pageNo, sortColumn: sortColumn, sortValue: sortValue });
    }
    ClearSearch = () => {
        this.setState({ searchValue: "" })
    }
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.handleHeaderSearch();
        };
    }

    /**         
     * PS_ZE_35_APP_31/Dhinesh/02/05/2024
     *This method invokes on the click of yes from deletepopup
     * */

    handlePopupshow = (roleNum) => {
        this.setState({ deleteRole: roleNum })
        $(`#DeletePopup`).modal('show')

    }

    deleteRole = () => {
        let deleteRoleNumBO = {
            roleNum: this.state.deleteRole,
            empBusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        RoleMasterAction.deleteRoleAction(deleteRoleNumBO);
    }

    /**         
     * PS_ZE_35_APP_33/Dhinesh/02/05/2024
     * This method invokes to handle delete icon response
     * */
    deleteRoleResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RoleMasterStore.deleteRoleResponse);
        let errorDt = response.errorDt;
        let deleteRoleDt = response.deleteRoleDt
        let isActive = deleteRoleDt[0].v_count
        let deletedetail = deleteRoleDt[0].v_deletedetail
        let deletepriv = deleteRoleDt[0].v_deletepriv
        if (errorDt == undefined || errorDt == null) {
            $(`#DeletePopup`).modal('hide')
            if (isActive == 0 && deletedetail > 0 && deletepriv > 0) {
                $(`#deleteSuccess`).modal('show')
                // Zeus-310-Thilak mani Chand /05/28/2024 It navigates to the first page  after deleting from any pages   //
                this.setState({ pageNo: 1 });
                this.pageLoad(1, this.state.sortColumn, this.state.sortValue, searchTextGlobVar);
            }
             
            else {
                $(`#alertDelete`).modal('show')
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }


    filterClick = (event, displayName, columnName) => {
        event.preventDefault();
        this.setState({ filterDisplayName: displayName, searchType: columnName });
    };

    /**         
     * PS_ZE_35_APP_36/Dhinesh/02/05/2024
     * This method invokes to handle clicking Expand all , collapse all, deselect all, select all response
     * */

    PrivTreeAction = (Key) => {
        let PrivTreeList = this.state.PrivTree
        let checkvalues = []
        switch (Key) {
            case "E": {
                PrivTreeList.map((val, index) => {

                    let iscat = PrivTreeList.filter((x) => x.ISA_CATEGORY_ID == val.ISA_CATEGORY_ID);
                    let isParent = PrivTreeList.filter((x) => x.ISA_PARENT_IDENT == val.ISA_PARENT_IDENT);
                    if (iscat.length > 0) {
                        let ispresent = checkvalues.some((x) => x == val.CATSECALIAS);
                        if (checkvalues.length == 0 || ispresent == false) {
                            checkvalues.push(val.CATSECALIAS)
                        }

                    }
                    if (isParent.length > 0) {
                        let ispresent = checkvalues.some((x) => x == val.PARENTSECALIAS);
                        if (checkvalues.length == 0 || ispresent == false) {
                            checkvalues.push(val.PARENTSECALIAS)
                        }
                    }

                });
                this.setState({ expanded: checkvalues, isExpandAll: false })
                break
            }
            case "C": {
                this.setState({ expanded: checkvalues, isExpandAll: true })
                break
            }
            case "D": {

                let landingPageOption = [{
                    value: "",
                    label: "Select",
                    privilege: ""
                }];
                this.setState({ checked: checkvalues, landingPage: landingPageOption })
                break
            }
            case "S": {

                PrivTreeList.map((val, index) => {
                    checkvalues.push(val.SECURITYALIAS)

                });
                this.setState({ checked: checkvalues })
                break
            }
        }
    }

    toastMessage = () => {
        try {
            $("#ProgramTreeValidation").addClass('d-block');
            $("#ProgramTreeValidation").addClass('erroriconpop');
            setTimeout(() => {
                $("#ProgramTreeValidation").removeClass('d-block');
                $("#ProgramTreeValidation").removeClass('erroriconpop');
            }, 3000);

        } catch (ex) {
            console.log(ex)
        }
    };

    /**         
     * PS_ZE_35_APP_40/Dhinesh/02/05/2024
     *This method invokes on the click of save button
     * */
    //zeus-310 /Niranjana/05/28/2024 This condition  checks whether the length of state variable 'checked'is greater than  or equal to 1//         
    saveRoleClick = () => {

        if (this.state.checked.length <= 1 || (this.state.businessUnit.length == 0 && this.state.roleType == "Customer") || this.state.roleName.trim() == "" || (this.state.roleList == "" && this.state.displayAction == "Copy Role")) {

            if (this.state.roleList == "" && this.state.displayAction == "Copy Role") {
                this.setState({ isError: true });
            }

            if (this.state.checked.length <= 1) {
                this.toastMessage();
            }


            this.setState({ isInvalid: true });

        }
        else {


            let SaveRoleBO = {
                userType: CookieService.getLocalStorageValues("Session_SDIEMP"),
                businessUnit: this.state.businessUnit,
                roleName: this.state.roleName.toUpperCase().trim(),
                roleNum: this.state.roleNum,
                oldRoleType: this.state.oldRoleType,
                roleType: this.state.roleType,
                landingPage: this.state.landingPagePriv,
                oldRoleName: this.state.oldRoleName,
                programTreePriv: this.state.checked,
                empBusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                userID: CookieService.getLocalStorageValues("Session_UserID"),
                websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
             privTreeVal: this.state.PrivTree.map(val => val.SECURITYALIAS).join(',')
            };

            this.setState({ isInvalid: false });
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            RoleMasterAction.saveRoleAction(SaveRoleBO);
        }

    }

    /**         
     * PS_ZE_35_APP_43/Dhinesh/02/05/2024
     * This method invokes to handle save click response
     * */

    saveRoleResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RoleMasterStore.saveRoleResponse);
        let errorDt = response.errorDt;
        let alertDt = response.AlertDt;
        let validateDt = response.ValidateDt;
        let saveRoleDt = response.saveRoleDt;
        if (errorDt == undefined || errorDt == null) {
            if (alertDt.length === 0 || alertDt[0]["ROLENUM"] === null || alertDt[0]["ROLENUM"] === undefined) {
                if ((saveRoleDt[0].v_programpriv > 0 && saveRoleDt[0].v_rowaffected_exist > 0 && saveRoleDt[0].v_rowaffected_delete > 0) || (saveRoleDt[0].v_rowaffected_nonexist > 0 && saveRoleDt[0].v_programpriv_nonexist > 0)) {
                    $(`#saveupdate`).modal('show');

                }
                else {

                    $(`#saveinsert`).modal('show');

                }
                let pageNo = 1;
                let sortColumn = "ROLENUM";
                let sortValue = "DESC";
                searchTextGlobVar = "";
                this.pageLoad(pageNo, sortColumn, sortValue, searchTextGlobVar);
                $('#RoleGrid').removeClass('hide');
                $('#RoleForm').addClass('hide');
                this.clear();
                this.setState({ pageNo: pageNo, sortColumn: sortColumn, sortValue: sortValue, searchValue: searchTextGlobVar })
            }
            else {
                $(`#savealert`).modal('show');
            }

        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');


    }

    /**         
     *This method invokes on the click of clear and back  button  
     * */

    clear = () => {
        $('#RoleGrid').removeClass('hide');
        $('#HideBusinessUnit').removeClass('hide');
        $('#RoleForm').addClass('hide');
        $('#HideSistersite').removeClass('hide');
        this.setState({
            displayAction: "", checked: [], expanded: [], roleNum: "", roleType: "Customer", roleList: "", businessUnit: [], businessUnitLabel: "", landingPage: "", landingPagePriv: "", landingPageOption: [], roleName: "", oldRoleName: "", oldRoleType: "", displayAction: "", isError: false, isInvalid: false, sisterSite: false, action: "", prevBusinessUnit: ""
        })
    }

    bindDropdown = () => {
        return this.state.searchByOptions.map((values, index) => {
            return (
                <a className="dropdown-item" href="#" value={values.value} onClick={(e) => this.filterClick(e, values.label, values.value)}> <Trans>{values.label}</Trans> </a>
            )
        })
    }

    /**         
     * PS_ZE_35_APP_17/Dhinesh/02/05/2024
     *This method invokes on the render part to bind grid data
     * */
    bindGridData = () => {
        const { t } = this.props;
        if (this.state.pageLoadData != undefined) {
            if (this.state.pageLoadData.length > 0) {
                return this.state.pageLoadData.map((value, index) => {
                    return (
                        <tbody>
                            {/*row-1 starts here*/}
                            <tr id={index}>
                                <td>{value.ROLENUM}</td>
                                <td >{value.ROLENAME}</td>
                                <td>{value.ROLETYPE}</td>
                                <td>{value.BUSINESS_UNIT && value.BUSINESS_UNIT.trim() ? value.BUSINESS_UNIT : '-'}</td>
                                <td>{value.ROLEPAGE && value.ROLEPAGE.trim() !== '' ? value.ROLEPAGE : '-'}</td>
                                <td className="text-center text-nowrap">
                                    {/*<a href="#"><img className="mr-3" src="images/edit-icon.svg" alt="edit" /></a><a href="#"><img src="images/delete.svg" alt="delete" /></a>*/}
                                    <button className="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" onClick={(e) => { this.editRole(value.ROLENUM, "EDITROLE") }} data-backdrop="static" data-keyboard="false">
                                        <img src="images/edit-icon.svg" className="removeorder-icon" alt="Remove Order" />
                                    </button>
                                    <button className="btn remove-order" data-toggle="modal" data-target="#Cancel-confirmation" onClick={(e) => { this.handlePopupshow(value.ROLENUM) }} data-backdrop="static" data-keyboard="false">
                                        <img src="Images/remove-order.svg" className="removeorder-icon" alt="Remove Order" />
                                    </button>
                                </td>
                            </tr>
                            {/*row-1 ends here*/}
                        </tbody>

                    )
                })
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="content-section">
                <div id="RoleGrid">
                    <title>Role Master</title>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="stylesheet" type="text/css" href="css/sdiframework.css" />
                    <link rel="stylesheet" type="text/css" href="css/sdistyles.css" />
                    <div className="container-fluid content-section">
                        <div className="row">
                            {/*Page Heading starts here*/}
                            <div className="col-md-12 mt-3 page-heading">
                                <h5 className="page-title"><Trans>Role Master</Trans></h5>
                                <div className="fav-item-maintenance-icon mt-1">
                                    <input type="checkbox" id="checkbox-1" className="checknow" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-1">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            {/*Page Heading ends here*/}
                            {/*Grid starts here*/}

                            <div className="col-md-12 mt-4">
                                {/*search and add button*/}
                                <div className="d-grid d-md-flex justify-content-md-end align-items-center grid-resp-style">
                                    <div>
                                        <div className="input-group dropdown-search w-100">
                                            <div className="input-group-prepend">
                                                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.filterDisplayName}</Trans></button>
                                                <div className="dropdown-menu">

                                                    {this.bindDropdown()}
                                                </div>
                                            </div>
                                            <input type="text" className="form-control" placeholder={t("Search")} aria-label="Text input with dropdown button" value={this.state.searchValue} style={{ 'padding-right': '50px' }} onKeyDown={(e) => this.searchKeyPress(e)} onChange={(e) => { this.onchangeText("search", e.target.value) }} />
                                            {this.state.searchValue.length > 0 ? <span class="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} > </span> : null}
                                            <span className="dropdown-search-icon" onClick={(e) => this.handleHeaderSearch()} />
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-3 ml-1 mb-0 primary-btn-responsive" onClick={() => this.addNewRoleClick("ADDROLE", "Add New Role")} ><img className="mr-2" src="images/add-icon.svg" alt="add-icon" /><Trans>Add role</Trans></button>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-3 ml-1 mb-0 primary-btn-responsive" onClick={() => this.addNewRoleClick("COPYROLE", "Copy Role")}><Trans>Copy role</Trans></button>
                                    </div>
                                </div>
                                {/*search and add button*/}
                                {/*Table starts here*/}
                                {this.state.pageLoadData.length > 0 ?
                                    <div className="table-responsive mt-2">
                                        <table className="table ZEUS_Grid FOM-grid mobileresponsive-grid">
                                            <thead>
                                                <tr>
                                                    <th className="position-sort-icon"><Trans>Role ID</Trans><span className="sort-up ml-1"><img src="Images/sort2.svg" alt="sort-up" onClick={() => { this.sorting("ROLENUM", "ASC") }} /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" alt="sort-down" onClick={() => { this.sorting("ROLENUM", "DESC") }} /></span></th>
                                                    <th className="position-sort-icon"><Trans>Role name</Trans><span className="sort-up ml-1"><img src="Images/sort2.svg" alt="sort-up" onClick={() => { this.sorting("ROLENAME", "ASC") }} /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" alt="sort-down" onClick={() => { this.sorting("ROLENAME", "DESC") }} /></span></th>
                                                    <th className="position-sort-icon"><Trans>User type</Trans><span className="sort-up ml-1"><img src="Images/sort2.svg" alt="sort-up" onClick={() => { this.sorting("ROLETYPE", "ASC") }} /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" alt="sort-down" onClick={() => { this.sorting("ROLETYPE", "DESC") }} /></span></th>
                                                    <th className="position-sort-icon"><Trans>Business unit</Trans><span className="sort-up ml-1"><img src="Images/sort2.svg" alt="sort-up" onClick={() => { this.sorting("BUSINESS_UNIT", "ASC") }} /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" alt="sort-down" onClick={() => { this.sorting("BUSINESS_UNIT", "DESC") }} /></span></th>
                                                    <th className="position-sort-icon"><Trans>Landing page</Trans><span className="sort-up ml-1"><img src="Images/sort2.svg" alt="sort-up" onClick={() => { this.sorting("ROLEPAGE", "ASC") }} /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" alt="sort-down" onClick={() => { this.sorting("ROLEPAGE", "DESC") }} /></span></th>
                                                    <th className="text-center"><Trans>Action</Trans></th>
                                                </tr>
                                            </thead>
                                            {this.bindGridData()}
                                        </table>
                                    </div> :
                                    <span> {
                                        <section className="site-undercontruction container text-center center-panel pt-4">
                                            <div className="img-section">
                                                <img src="images/nosearch.jpg" alt="imagefile" />
                                            </div>
                                            <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                            </p>
                                        </section>
                                    }
                                    </span>
                                }
                                {/*Table ends here*/}
                                <div className="col-md-12">
                                    {this.state.totalCount > 0 ? <>
                                        <div class="d-flex justify-content-between table-footer-responsive">
                                            <div>
                                                <span className="total-records mt-2 d-inline-block">
                                                    <Trans>Number of records</Trans><span> :</span><span className="ml-2">{this.state.totalCount}</span>
                                                </span>
                                            </div>
                                            {this.state.totalCount > this.state.reqCount ? <div> <ReactPaginate
                                                previousLabel={<span className="right-pagination-arrow" />}
                                                nextLabel={<span className="left-pagination-arrow" />}
                                                previousClassName={'page-item'}
                                                nextClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                nextLinkClassName={'page-link'}
                                                disabledClassName={'disabled'}
                                                breakLabel={'...'}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link'}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={2}
                                                onPageChange={this.handlePagination}
                                                containerClassName={'custom-pagination pagination'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                activeClassName={'active'}
                                                hrefBuilder={() => '#'}
                                                forcePage={this.state.pageNo - 1}
                                            /></div> : null}
                                        </div></> : null}
                                </div>

                            </div>

                            {/*Grid ends here*/}
                        </div>
                    </div>
                </div>

                {/*Add new role starts here */}
                <div id="RoleForm">

                    <div className="container-fluid content-margin">
                        <div className="row">
                            {/*Page Heading starts here*/}
                            <div className="col-md-12 mt-3 page-heading sdi-mob-mt  ">
                                <h5 className="page-title"><img class="mr-1" src="images/back-icon.svg" alt="back-icon" onClick={(e) => { this.clear() }} /><Trans>{this.state.displayAction}</Trans></h5>
                            </div>
                            {/*Page Heading ends here*/}
                            {/*Form starts here*/}

                            <div className="col-md-6 col-lg-3 mt-4" id="roleList">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label id="roles-list" className="font-14 font-medium text-black mb-0 mt-2  mandatory-after"><Trans>Roles list</Trans></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Select
                                            options={this.state.roleListOption}
                                            value={this.state.roleListLabel}
                                            onChange={(e) => { this.OnChangeDropdown("roleList", e) }}
                                            inputProps={{ placeholder: "Select" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    borderColor: (this.state.isError && this.state.roleList == "" && this.state.displayAction == "Copy Role") ? "#FF0000" : "#ced4da",
                                                    boxShadow: (this.state.isError && this.state.roleList == "" && this.state.displayAction == "Copy Role") && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                                    "&:hover": {
                                                        ...provided,
                                                        borderColor: (this.state.isError && this.state.roleList == "" && this.state.displayAction == "Copy Role") ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
                                                    }
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-4">
                                <div className="row">
                                    <div className="col-md-4 pr-0">
                                        <label id="role-name" className={`font-14 font-medium text-black mandatory-after mb-0 mt-2 `}><Trans>Role name</Trans></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className={`form-control custom-form-style ${this.state.isInvalid && this.state.roleName === "" ? 'border-color-red' : ''}`} maxLength="30" placeholder={t("Enter role name")} value={this.state.roleName} onChange={(e) => { this.onchangeText("roleName", e.target.value) }} />
                                    </div>
                                </div>
                            </div>

                            {this.state.sessionUserType == "SDI" ?
                                <div className="col-md-6 col-lg-3 mt-4" >
                                    <div className="row">
                                        <div className="col-md-4 col-lg-5 ">
                                            <label id="user-type" className="font-14 font-medium text-black mandatory-after mb-0 mt-2"><Trans>User type</Trans></label>
                                        </div>
                                        <div className="col-md-8 col-lg-7 mt-2">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="Customer" defaultValue="option1" value="Customer" checked={this.state.roleType === "Customer" ? true : false} onChange={(e) => { this.onchangeRadio(e) }} />
                                                <label className="form-check-label font-12 font-regular" htmlFor="Customer"><Trans>Customer</Trans></label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="sdi" defaultValue="option2" value="SDI" checked={this.state.roleType === "SDI" ? true : false} onChange={(e) => { this.onchangeRadio(e) }} />
                                                <label className="form-check-label font-12 font-regular" htmlFor="sdi"><Trans>SDI</Trans></label>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}



                            <div className="col-md-6 col-lg-3 mt-4" id="HideBusinessUnit">
                                <div className="row">
                                    <div className="col-md-4 ">
                                        <label id="business-unit" className="font-14 font-medium text-black mandatory-after mb-0 mt-2 "><Trans>Business unit</Trans></label>
                                    </div>
                                    <div className="col-md-8 ">
                                        <MultiSelect
                                            options={this.state.businessUnitOption}
                                            value={this.state.businessUnitLabel}
                                            onChange={(e) => { this.OnChangeDropdown("businessUnit", e) }}
                                            labelledBy={"Select"}
                                            className={this.state.isInvalid && this.state.businessUnit.length === 0 && this.state.roleType === "Customer" ? "rolemaster-multiselect" : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-4">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label id="landing-page" className="font-14 font-medium text-black mb-0 mt-2 "><Trans>Landing page</Trans></label>
                                    </div>
                                    <div className="col-md-8 ">
                                        <Select
                                            options={this.state.landingPageOption}
                                            value={this.state.landingPage}
                                            onChange={(e) => { this.OnChangeDropdown("landingPage", e) }}
                                            inputProps={{ placeholder: "Select" }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-4" id="HideSistersite">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="sites" onChange={(e) => { this.oncheckSistersiteBU(e) }} checked={this.state.sisterSite} />
                                    <label className="form-check-label font-12 font-regular" htmlFor="sites"><Trans>Apply to all sister sites</Trans></label>
                                </div>
                            </div>
                            {/*Form ends here*/}
                            {/*Program starts here*/}
                            <div className="col-md-12 mt-4">
                                <div className="d-grid d-md-flex justify-content-md-between align-items-center grid-resp-style">
                                    <div>
                                        <h5 className="font-bold font-16"><Trans>Programs</Trans>

                                            <span className={`info-container `}>
                                                <img className="ml-2" style={{ "width": "14px" }} src="images/info-icon-sdi.svg" />
                                                <span id="ProgramTreeValidation" className="Grid-info-icon text-left sdi-fede-tooltippickup " style={{ "margin": "6px 0px 0px 39px" }}><Trans>Select atleast two programs</Trans> </span>
                                            </span>
                                        </h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-primary custom-btn-primary primary-btn px-3 mb-3" onClick={() => this.PrivTreeAction("E")} ><Trans>Expand all</Trans></button>
                                        <button type="button" className="btn btn-primary custom-btn-primary primary-btn px-3 ml-3 mb-3" onClick={() => this.PrivTreeAction("C")}><Trans>Collapse all</Trans></button>
                                        <button type="button" className="btn btn-primary custom-btn-primary primary-btn px-3 ml-3 mb-3" onClick={() => this.PrivTreeAction("S")}><Trans>Select all</Trans></button>
                                        <button type="button" className="btn btn-primary custom-btn-primary px-3 ml-3 mb-3" onClick={() => this.PrivTreeAction("D")}><Trans>Deselect all</Trans></button>
                                    </div>
                                </div>
                                <CheckboxTree
                                    nodes={this.state.programtreePriv}
                                    iconsClass="fa4"
                                    showNodeIcon={true}
                                    checked={this.state.checked}
                                    expanded={this.state.expanded}
                                    onCheck={this.OnCheckTree}
                                    onExpand={expanded => this.setState({ expanded })}
                                    disabled={this.state.PrivTreeDisable}
                                    icons={{
                                        check: <span className="rct-icon rct-icon-check" />,
                                        uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                        halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                        expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                        expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                        expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                        collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                        parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                        parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                        leaf: <span className="rct-icon rct-icon-leaf" />,
                                    }}

                                />

                                {/*Accordion body starts here*/}
                            </div>
                            {this.state.isInvalid && (this.state.businessUnit == "" || this.state.roleName == "" || this.state.roleList == "") ?
                                <span className="float-right text-right mr-3 mt-3 errormsg">
                                    <Trans>Please fill all the required fields</Trans>
                                </span> : null}
                            {/*Program ends here*/}
                            {/*Footer starts here*/}
                            <div className="col-md-12 my-4">
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-secondary custom-btn-secondary secondary-btn" onClick={(e) => { this.clear() }} ><Trans>Cancel</Trans></button>
                                    <button type="button" className="btn btn-primary custom-btn-primary primary-btn px-3 ml-3 mb-0" onClick={() => this.saveRoleClick()}><Trans>Save</Trans></button>
                                </div>
                            </div>
                            {/*Footer ends here*/}
                        </div>
                    </div>
                </div>


                <div className="modal fade delete-role" Id="DeletePopup" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered custom-rolemaster-width" role="document">
                        <div className="modal-content py-2">
                            {/* Delete Starts here */}
                            <div className="modal-body pb-0">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                        <img className="mb-3" src="images/trash.svg" alt="delete" />
                                        <h5 className="mb-2 font-16 font-bold color-light-black"><Trans>Confirm</Trans></h5>
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>Are you sure you want to delete this role?</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/* Delete ends here */}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button className="btn btn-custom-secondary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>No</Trans></button>
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" onClick={() => { this.deleteRole() }}><Trans>Yes</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Ends here */}

                {/*Modal Starts here*/}
                <div className="modal fade alert-role-popup" Id="alertDelete" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered custom-response-width" role="document">
                        <div className="modal-content py-2">
                            {/*alert Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img className="mb-3" src="images/alert-Icon.svg" alt="alert" />
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>This role cannot be deleted as it is applied to users.</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*alert end here*/}
                            {/*button*/}
                            <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>OK</Trans></button>
                            </div>
                            {/*button*/}
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

                {/*Modal for delete Starts here*/}
                <div className="modal fade save" tabIndex={-1} role="dialog" aria-hidden="true" id="deleteSuccess">
                    <div className="modal-dialog modal-dialog-centered custom-response-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img className="mb-2" src="images/tick.svg" alt="tick" />
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>Role has been deleted successfully!</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>OK</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

                {/*Modal save Starts here*/}
                <div className="modal fade save" tabIndex={-1} role="dialog" aria-hidden="true" id="saveinsert">
                    <div className="modal-dialog modal-dialog-centered custom-response-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img className="mb-2" src="images/tick.svg" alt="tick" />
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>New role has been created successfully!</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>OK</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

                {/*Modal save Starts here*/}
                <div className="modal fade save" tabIndex={-1} role="dialog" aria-hidden="true" id="saveupdate">
                    <div className="modal-dialog modal-dialog-centered custom-response-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img className="mb-2" src="images/tick.svg" alt="tick" />
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>Role details updated successfully!</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>OK</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

                {/*Modal Starts here*/}
                <div className="modal fade alert-role-popup" tabIndex={-1} role="dialog" aria-hidden="true" id="savealert">
                    <div className="modal-dialog modal-dialog-centered custom-successpop-width custom-response-width" role="document">
                        <div className="modal-content py-2">
                            {/*alert Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img className="mb-3" src="images/alert-Icon.svg" alt="alert" />
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>Role name already exists!</Trans> <span className="font-medium"></span></p>
                                    </div>
                                </div>
                            </div>
                            {/*alert end here*/}
                            {/*button*/}
                            <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>OK</Trans></button>
                            </div>
                            {/*button*/}
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

            </div>
        )

    }
}
export default withTranslation()(RoleMasterComponent);