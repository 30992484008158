/*
* [Ref] - Denotes Pseudo Code Reference  
*  
* This component is the Search results page component. The component displays the Search results page details.
* 
* App Name: SDI-Walmart
* Author: M.Sushmitha
* Created Date: 09/02/2020 
*/
import React, { Component } from 'react';
import Image from 'react-image-resizer';
import * as SearchResultsAction from '../actions/SearchResultsAction';
import SearchResultsStore from '../stores/SearchResultsStore';
import * as MenuAction from '../actions/MenuAction';
import SDIConstants from '../SDIConstants';
import CheckboxTree from 'react-checkbox-tree';
import ReactPaginate from 'react-paginate';
import * as CookieService from '../CookieService';
import * as $ from 'jquery';
import Autocomplete from 'react-autocomplete';
import swal from 'sweetalert';
//[PC_07, 09/08/21, Author - Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
import { BrowserView, MobileView, isIE, isMobile } from 'react-device-detect';
//[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
import ShoppingCartStore from '../stores/ShoppingCartStore';
import * as FavItemMainAction from '../actions/FavItemMainAction';
import FavItemMainStore from '../stores/FavItemMainStore';
let catagoryfilterApplied = 0
let CatalogType = false
let ClientType = false
let CurrentLatitude = 0;
let CurrentLongitude = 0;
let maxQty = 99999999;
let percent = 0;
class SearchResults extends Component {

    constructor(props) {
        super(props);
        this.LoadSearchResults = this.LoadSearchResults.bind(this);
        this.BuildSearchResults = this.BuildSearchResults.bind(this);
        this.bindSearchResults = this.bindSearchResults.bind(this);
        this.FavSuccess = this.FavSuccess.bind(this)
        this.AddtocartSuccess = this.AddtocartSuccess.bind(this)
        this.Search = this.Search.bind(this)
        this.AnykeyPress = this.AnykeyPress.bind(this)
        //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
        this.COStatus = this.COStatus.bind(this);
        console.log("sample_termsaccepte", CookieService.getLocalStorageValues("sample_termsaccepted"))
        this.state = {
            Terms: CookieService.getLocalStorageValues("sample_termsaccepted") == false ? false : true,
            DescriptionType: 1,
            showprice: (CookieService.getLocalStorageValues("Session_ShowPrice") == "1" && CookieService.getLocalStorageValues("Session_ISAPriceBlock") != "Y" ? "0" : ""),
            showpagination: false,
            ShowSampleMessage: false,
            AddFavPUp: false,
            FavitemImage: "",
            ItemType: "",
            FavItemIdPopUp: "",
            FavSDICatgPopUp: "-",
            FavItemDescripPopUp: "",
            FavMFGPopUp: "",
            FavMFGPartNUMPopUp: "",
            txtUOMHidePopUp: "",
            txtMfgItemidHidePopUp: "",
            txtItemidHidePopUp: "",
            txtMFGHidePopUp: "",
            Manufacture: [],
            AttributeTree: [],
            Attrichecked: [],
            Manuchecked: [],
            SearchKeyWord: "",
            PageLoadSearchResults: [],
            OrderCatalogItem: [],
            OrderNonCatalogItem: [],
            FilteredSearchResults: [],            
            pageload: 0,
            checked: [],
            expanded: [],
            FilterValuedata: [],
            filterAppliedType: "All",
            FavoritesMenu: [],
            expanded: [],
            pageNo: 1,
            filterApplied: 0,
            filterAttri: 0,
            filterManu: 0,
            pageCount: 0,
            itemperpage: 10,
            TotalItemCount: 0,
            FavoritesSubMenu: [],
            ManufactureTable: [],
            CatagoryTable: [],
            GridType: 1,
            FilterValue: 1,
            DidyouMean1: "",
            DidyouMean2: "",
            DidyouMean3: "",
            SupplierChecked: [],
            SupplierTree: [], URLUNSPSC: "", URLRoot: " ",
            Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
            Site_Currency: "",
            StoreRoom: true, 
            MarketPlace: true, ShowDidyoumean: false, Supplier: "", ItemUnitPrice: "", 
            PunchinUser: CookieService.getLocalStorageValues("Session_PUNCHINID") != null && CookieService.getLocalStorageValues("Session_PUNCHINID") != undefined && CookieService.getLocalStorageValues("Session_PUNCHINID") != "" && CookieService.getLocalStorageValues("Session_PUNCHINID") != " " ? true : false, FavSAP: "",
            /*[10/13/21, PC_SR_04 CAS - 32], Priyanka D*/
            CurrencyCd: "",
            category: [],
            bindCategory: [],
            yourFavCat: "",
            categoryError: false,
            subCategory: [],
            bindSubCategory: [],
            yourFavSubCat: "",
            subCategoryError: false,
            WOmsg: "",
            ValidatedWo: CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("validatedWO") != "error" && CookieService.getLocalStorageValues("validatedWO") != "blockpopuprepeat" && CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != "null" ? CookieService.getLocalStorageValues("validatedWO") :"", 
            boolWOEmpty: false,
            suggestionWo: [],
            workOrderDescription: CookieService.getLocalStorageValues("workOrderDescription") != "" && CookieService.getLocalStorageValues("workOrderDescription") != null && CookieService.getLocalStorageValues("workOrderDescription") != undefined ? CookieService.getLocalStorageValues("workOrderDescription") : "",
            disableWo: CookieService.getLocalStorageValues("workOrderDisable") != "" && CookieService.getLocalStorageValues("workOrderDisable") != null && CookieService.getLocalStorageValues("workOrderDisable") != undefined ? CookieService.getLocalStorageValues("workOrderDisable") : false,
            selectAllMfg: true, selectAllAtt: true, selectAllSup: true, manufactureCount: "", attributeCount: "", supplierCount: "", attributeChildCount: "", //[08/04/2023, PS_ZE_171_APP_04 -Add Select all & Clear buttons in Post filter, Sathis.N]
            searchTxt: "",  //[08/04/2023, PS_ZE_171_APP_04 -Add Select all & Clear buttons in Post filter, Sathis.N]
            selectedItemIndex : 0, isRTI  : false, addToCartRTI : false 
            //[3/17/2023, PS_SR_URL_APP_06 - To remove catalog look up tree, Dhevanesam R]
            //CattreeItemID: "", CatTreeFlag:""PageloadcatalogTree: [], ParentLevel: 4, CatalogTreeRoot: "  >  ", CatalogLookup: [], CatalogChange: false, CatalogRoot1: CookieService.getLocalStorageValues("CatalogNODE1"), CatalogRoot2: CookieService.getLocalStorageValues("CatalogNODE2"), CatalogRoot3: CookieService.getLocalStorageValues("CatalogNODE3"), CatalogRoot4: CookieService.getLocalStorageValues("CatalogNODE4"), CatalogRoot1Value: CookieService.getLocalStorageValues("Catalog_Node1"), CatalogRoot2Value: CookieService.getLocalStorageValues("Catalog_Node2"),
            //CatalogRoot3Value: CookieService.getLocalStorageValues("Catalog_Node3"), CatalogRoot4Value: CookieService.getLocalStorageValues("Catalog_Node4"), ParentLevel: "", CatalogTree: [],
            
        }
    }
    componentDidMount() {
        //if (this.state.pageload == 0) {
        //    const queryParams = new URLSearchParams(window.location.search)
        //    const QS_src = queryParams.get('key');
        //    console.log(QS_src)
        //    this.setState({ SearchKeyWord: QS_src })
        //}
        this.locationEnable();
        this.LoadSearchResults()
        //[05/10/2023 -  Zues 110 - Bug fix - Remove the bind() method for fix method call looping ]
        SearchResultsStore.on('Searchresult', this.BuildSearchResults);
        SearchResultsStore.on('Status', this.AddtocartSuccess);
        SearchResultsStore.on('FavitemAdd', this.FavSuccess);
        SearchResultsStore.on('workordercheck', this.woValidateResponse);
        //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
        ShoppingCartStore.on('CancelOrder', this.COStatus);
        FavItemMainStore.on('DeleteItem', this.DelFavResponse);
    }
    componentWillUnmount() {
        SearchResultsStore.removeListener('Searchresult', this.BuildSearchResults);
        SearchResultsStore.removeListener('Status', this.AddtocartSuccess);
        SearchResultsStore.removeListener('FavitemAdd', this.FavSuccess);
        SearchResultsStore.removeListener('workordercheck', this.woValidateResponse);
        //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
        ShoppingCartStore.removeListener('CancelOrder', this.COStatus);
        FavItemMainStore.removeListener('DeleteItem', this.DelFavResponse);
    }
    //[6/20/2024, Zeus - 297 - Real time inventory - Location enable check - Johnprinto D]
    locationEnable = () => {
        if (!navigator.geolocation) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Geolocation is not supported by your browser"
            });
        } else {
            navigator.geolocation.getCurrentPosition(
                this.handleSuccess,
                this.handleError
            );
        }
    }
    handleSuccess = (position) => {
        const { latitude, longitude } = position.coords;
        CurrentLatitude = latitude;
        CurrentLongitude = longitude;
    };
    handleError = (error) => {
        CurrentLatitude = 0;
        CurrentLongitude = 0;
    };
    toastMessage = (id)=>{
        $(`#${id}`).removeClass('d-none');
        setTimeout(() => {
            $(`#${id}`).addClass('d-none');
        }, 3000);
    }
    LoadSearchResults = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let CatagoryList = []
        if (catagoryfilterApplied == 1) {
            CatagoryList = this.state.checked
        }
        const queryParams = new URLSearchParams(window.location.search)
        const QS_src = queryParams.get('key');
        let regexStr = /^[^a-zA-Z0-9]+$/;
        let regex = new RegExp(regexStr);
        //[3/17/2023, PS_SR_URL_APP_07 - To remove catalog look up tree, Dhevanesam R]
        //const QS_Unspsc = queryParams.get('unspsc');
        const QS_Root = queryParams.get('Home');
        //const QS_CatTree = queryParams.get('CatTree');//[10/12/21, PC_US_9, CAS - 36], Poornima S

        //console.log("Session_ShowPrice", CookieService.getLocalStorageValues("Session_ShowPrice"))
        //console.log("Session_ISAPriceBlock", CookieService.getLocalStorageValues("Session_ISAPriceBlock"))
        let addToCartRTI = false;
        if(this.props?.location?.state?.origin == "RealTimeInventory"){
            if(queryParams.get("addToCartRTI") == "true"){
                addToCartRTI = true;
                //Remove the query params once we get a query params to avaoid the repeated condition check
                let newURL = window.location.href.split("&")[0];
                window.history.pushState('object', document.title, newURL);
            }
        }
        this.setState({
            SearchKeyWord: QS_src, URLRoot: QS_Root != null && QS_Root != "" && QS_Root != undefined ? QS_Root : "no", addToCartRTI : addToCartRTI
            //CatTreeFlag: QS_CatTree, URLUNSPSC: QS_Unspsc
        })
        let SearchBO = {
            Session_USERID: this.state.Login_UserId,
            Session_BUSUNIT: this.state.Login_UserBU,
            AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
            CurrentPage_URL: "/SDIXZEUS/searchResults",
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_SearchQuery: QS_src,
            Session_AMAZONSEARCH: CookieService.getLocalStorageValues("Session_AMAZONSEARCH"),
            SearchValue: QS_src,
            Query_SITE: "",
            Query_USERID: this.state.Login_UserId,
            Query_Key: QS_src,
            Query_Opt: "",
            chbCat: true,
            chbNonCat: true,
            Catagorylist: CatagoryList,
            Session_SITELANG: CookieService.getLocalStorageValues("Session_SITELANG"),
            //Query_CatTree: QS_CatTree, //[10/12/21, PC_US_9, CAS - 36], Poornima S
            Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[10/29/21, PC_SL_01, CAS - 36], Poornima S  //Added Grainger search yes or no to get those items in search
            //Query_UNSPSC: QS_Unspsc, 
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
            //[5/30/2023, PS_ZE_125_APP_01, Johnprinto D]
            Session_HOMESTORE: CookieService.getLocalStorageValues("Session_HOMESTORE"),
            Session_ID: CookieService.getLocalStorageValues("Session_ID"),
            //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
            Session_WO: CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("validWO") != " " ? CookieService.getLocalStorageValues("validWO") : "",
            Session_WOName: CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null && CookieService.getLocalStorageValues("WOName") != "" && CookieService.getLocalStorageValues("WOName") != " " ? CookieService.getLocalStorageValues("WOName") : "",

        }
        if (!regex.test(QS_src)) {
            SearchResultsAction.GetpageLoad(SearchBO)
        }
        else {
            this.setState({
                PageLoadSearchResults: []
            })
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
    }
    BuildSearchResults = () => {
        debugger
        let PageLaod_Data = JSON.parse(SearchResultsStore.SearchresultList);
        console.log("PageLaod_Data", PageLaod_Data)
        console.log("Session_ShowPrice", CookieService.getLocalStorageValues("Session_ShowPrice"))
        console.log("Session_ISAPriceBlock", CookieService.getLocalStorageValues("Session_ISAPriceBlock"))
        let Favmenu = PageLaod_Data.FavMenu;
        let showpaginationL = false
        let Favsub = PageLaod_Data.FavSubMenu;
        //[3/17/2023, PS_SR_URL_APP_08 - To remove catalog look up tree, Dhevanesam R]
        //let TradeTree = PageLaod_Data.TradeCatalogList;
        //let TradeValue = []
        let Searchdata = PageLaod_Data.SearchItemTable;
        let AttributeValue = []
        let Attributelist = PageLaod_Data.Session_dctm;
        let attributeChildCount = 0;  //[08/04/2023, PS_ZE_171_APP_11 - to get attribute filder children length, Sathis.N]
        let ManuValue = []
        let Manufacturelist = PageLaod_Data.ManufactureTable;
        let SitecurrencyTable = PageLaod_Data.siteCurrencyTable;
        //let CatItemDT = PageLaod_Data.CatItemDT;
        let Site_CurrencyValue = ""
        if (SitecurrencyTable != null && SitecurrencyTable != undefined) {
            if (SitecurrencyTable.length > 0) {
                Site_CurrencyValue = SitecurrencyTable[0].Symbol
            }
        }

        //let catItemID=""
        //if (CatItemDT != null && CatItemDT != undefined) {
        //    catItemID = CatItemDT[0].CatItemID
        //}

        //let CatalogLookup = []
        //if (this.state.URLRoot == "yes" && CookieService.getLocalStorageValues("Session_IPMSite")!="Y") {
        //    // Searchdata = Searchdata.filter((val, idx) => val.UNSPSC == this.state.URLUNSPSC || val.UNSPSCCategory == this.state.SearchKeyWord)
        //    CatalogLookup = PageLaod_Data.CatalogdtTop            
        //}
        let ShowDidyoumean = false
        let DidyouMean1L = ""
        let DidyouMean2L = ""
        let DidyouMean3L = ""
        //[07/22/22, PC_SR_02, CAS - Did you mean issue, Dhevanesam R]
        let DidyoumeanJSON1
        let DidyoumeanJSON2
        let DidyoumeanJSON3
        let Didyoumean = PageLaod_Data.DidyoumeanTable
        if (Didyoumean != null && Didyoumean != undefined) {
            debugger
            DidyouMean1L = Didyoumean[0].Did_you_Mean == null || Didyoumean[0].Did_you_Mean == "" || Didyoumean[0].Did_you_Mean == undefined ? "" : JSON.parse(Didyoumean[0].Did_you_Mean)
            DidyouMean2L = Didyoumean[1].Did_you_Mean == null || Didyoumean[1].Did_you_Mean == "" || Didyoumean[1].Did_you_Mean == undefined ? "" : JSON.parse(Didyoumean[1].Did_you_Mean)
            DidyouMean3L = Didyoumean[2].Did_you_Mean == null || Didyoumean[2].Did_you_Mean == "" || Didyoumean[2].Did_you_Mean == undefined ? "" : JSON.parse(Didyoumean[2].Did_you_Mean)
            debugger
            if (DidyouMean1L != "") {
                DidyoumeanJSON1 = DidyouMean1L.suggestion[0]
                DidyoumeanJSON2 = DidyouMean1L.suggestion[1]
                DidyoumeanJSON3 = DidyouMean1L.suggestion[2]
            }
            if (DidyouMean2L != "") {
                DidyoumeanJSON1 = DidyouMean2L.suggestion[0]
                DidyoumeanJSON2 = DidyouMean2L.suggestion[1]
                DidyoumeanJSON3 = DidyouMean2L.suggestion[2]
            }
            if (DidyouMean3L != "") {
                DidyoumeanJSON1 = DidyouMean3L.suggestion[0]
                DidyoumeanJSON2 = DidyouMean3L.suggestion[1]
                DidyoumeanJSON3 = DidyouMean3L.suggestion[2]
            }
            console.log("DidUMeansugg", DidyoumeanJSON1)
            if (DidyoumeanJSON1 != undefined || DidyoumeanJSON2 != undefined || DidyoumeanJSON3 != undefined) {
                if (DidyoumeanJSON1 != "" || DidyoumeanJSON2 != "" || DidyoumeanJSON3 != "") {
                    ShowDidyoumean = true
                }
            }
        }
    
        let DescriptionPref = CookieService.getLocalStorageValues("Session_PROD_DESCPREFERENCE");
        let DescripType = 1
        if (DescriptionPref == "P") {
            CatalogType = false
            ClientType = true
            //document.getElementById("ClientDesp").checked = true
            //document.getElementById("CatalogDesp").checked = false
            DescripType = 0
        }
        else {
            CatalogType = true
            ClientType = false
            //document.getElementById("ClientDesp").checked = false
            //document.getElementById("CatalogDesp").checked = true
        }
        // console.log("DescriptionPref", DescriptionPref)
        let SRtype = false
        let SMtype = false
        debugger
        Searchdata.every((val) => {
            //[03/03/22, PC_SR_3, CAS - 42], Poornima S: SR & SM type search items filter issue fix
            if (val.customerPartnumber != null && val.customerPartnumber != undefined && val.customerPartnumber != "") {
                if (val.customerPartnumber.length >= 3) {
                    if (val.customerPartnumber_CatalogZero != null && val.customerPartnumber_CatalogZero != undefined && val.customerPartnumber_CatalogZero != "" && val.customerPartnumber.substring(0, 3) != "SDI" && val.CatlgOrNotCatlg == "1") {
                        SRtype = true
                    }
                    else {
                        SMtype = true
                    }
                    if (SRtype == true && SMtype == true) {
                        return false;
                    }
                }
            }

        });
                
        let UpdateSession = PageLaod_Data.Update_Session;
        if (Searchdata != undefined && Searchdata != null) {
            let filterSearchData = Searchdata.filter((val, idx) => idx < 10)
            let itemperpageval = this.state.itemperpage
            let PageCountList = this.getpagecount(Searchdata.length, 10)
            if (PageCountList > 1) {
                showpaginationL = true
            }
            //[3/17/2023, PS_SR_URL_APP_09 - To remove catalog look up tree, Dhevanesam R]
            //if (TradeTree != null && TradeTree!=undefined) {
            //    TradeTree.map((val, index) => {
            //        let ParentNode = {
            //            value: val.FIELDNAME,
            //            label: val.DESCR,
            //            key: val.FIELDNAME,
            //            disabled: false
            //        }
            //        TradeValue.push(ParentNode)
            //    });
            //}
            if (Manufacturelist != null && Manufacturelist!=undefined) {
                Manufacturelist.map((val, index) => {
                    let ParentNode = {
                        value: val.manufacturer,
                        label: val.manufacturer
                    }
                    ManuValue.push(ParentNode)
                });
            } 
            if (Attributelist != null && Attributelist != undefined) {
                let AttributeDropdown = Attributelist
                let filteredAttributes = AttributeDropdown.filter((v, i, a) => a.findIndex(t => (t.AttributeName === v.AttributeName)) === i)

                let sortedAttribute = filteredAttributes.sort(function (a, b) {
                    if (a.AttributeName > b.AttributeName) {
                        return 1;
                    }
                    if (b.AttributeName > a.AttributeName) {
                        return -1;
                    }
                    return 0;
                });
                //console.log("Attributelist", sortedAttribute)
                //console.log("filteredAttributes", filteredAttributes)
                //console.log("Attributelist", Attributelist)
                sortedAttribute.map((val, index) => {

                    //[ 06/26/2023 - PC_ZEUS_143_APP_02 - Johnprinto D]
                    let childAttribut = Attributelist.filter((item, index) => item.AttributeName == val.AttributeName && item.AttributeName !== "NULL" && !item.AttributeValue.includes("NULL") && item.AttributeValue.trim() != "");
                    let DuplicateAttribute = childAttribut.filter((v, i, a) => a.findIndex(t => (t.AttributeValue === v.AttributeValue)) === i)
                    let ChildrenValue = []
                    if (DuplicateAttribute.length > 0) {
                        DuplicateAttribute.map((value, index) => {
                            let localArray = {
                                value: value.AttributeValue + "|" + value.AttributeName,
                                label: value.AttributeValue,
                                Parent: value.AttributeName
                            }
                            ChildrenValue.push(localArray)
                            attributeChildCount++
                        })
                        let ParentNode = {
                            value: val.AttributeName,
                            label: val.AttributeName,
                            Parent: "0",
                            children: ChildrenValue,
                            showCheckbox: false,
                        }
                        AttributeValue.push(ParentNode)
                    }

                });
            }

            let SupplierFIlterValue = []
            if (Searchdata != null && Searchdata != undefined) {
                let SortedSupplier = []
                if (Searchdata.length > 0) {
                    SortedSupplier = Searchdata.filter((v, i, a) => a.findIndex(t => (t.supplierName.trim().toUpperCase() === v.supplierName.trim().toUpperCase())) === i)
                }
                SortedSupplier = SortedSupplier.sort(function (a, b) {
                    if (a.supplierName > b.supplierName) {
                        return 1;
                    }
                    if (b.supplierName > a.supplierName) {
                        return -1;
                    }
                    return 0;
                });
                SortedSupplier.map((val, index) => {
                    let SupplierName = {
                        value: val.supplierName,
                        label: val.supplierName
                    }
                    SupplierFIlterValue.push(SupplierName)
                })
            }
            //let AmaZon = {
            //    AttributeName: "Length|Width|Height|Weight|",
            //    AttributeValue: "4.88 Inches|5.63 Inches|0.51 Inches|0.229375 Pounds|",
            //    CategoryID: "0",
            //    CategoryName: "MUSIC",
            //    CatlgOrNotCatlg: null,
            //    CurrencyCd: "USD",
            //    CustomColItemID: "B00000JHDR",
            //    CustomColItemID_1: "0",
            //    CustomColQOH: "2",
            //    Description: "It's All On U, Vol. 1",
            //    ExtndDesc: "It's All On U, Vol. 1",
            //    Id: null,
            //    Image: "https://m.media-amazon.com/images/I/61BP21l4YSL.jpg",
            //    ItemAttributeId: "0",
            //    ItemPrice: "9.99",
            //    QuantityOrdered: null,
            //    SupplierPartAuxiliaryID: "It2nc7PHYSW2X%2Bh8zFlrwWe5DTDZl9IQpLChUCDCC3F8TCP1FfEErMe862jtcagXCJzpTQsvFzTk5Q7L3KQ%2FYiq4S7sn2NnN%2BqZ7pWDkNwo%3D",
            //    ThumbnailImagefile: "https://m.media-amazon.com/images/I/61BP21l4YSL.jpg",
            //    UNSPSC: "",
            //    UNSPSCCategory: "MUSIC",
            //    UOM: "EA",
            //    brand: "Cash Money",
            //    clientDesc: "It's All On U, Vol. 1",
            //    clientPartNumber: "",
            //    customerPartnumber: "",
            //    customerPartnumber_CatalogZero: "",
            //    longdescriptionone: "It's All On U, Vol. 1",
            //    manfPartNumber: "unknown",
            //    manufacturer: "Cash Money",
            //    notInCatalogPrice: null,
            //    package_qty: null,
            //    qty_on_hand: null,
            //    shipping_specs: null,
            //    ships_within: null,
            //    supplierName: "AMAZON",
            //    supplier_part_number: null,
            //    vendor_id: "39777",
            //}
            //Searchdata.push(AmaZon)
            let allCategory = []
            let categories = CookieService.getLocalStorageValues("FavItem_Cat")
            if (categories != null && categories != "" && categories != undefined) {
                categories.map((val, index) => {
                    allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
                });
                allCategory = allCategory.sort((a, b) =>
                    a.value > b.value ? 1 : -1,
                );
            }
            debugger;
            let subCategory = []
            let allSubCategory = []
            let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
            if (subCategories != null && subCategories != "" && subCategories != undefined) {
                subCategories.map((val, index) => {
                    if (val.ISA_FAVS_SUBCATEGORY != null) {
                        subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                    }
                });
            }
            if (subCategory.length > 0) {
                allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
                allSubCategory = allSubCategory.sort((a, b) =>
                    a.value > b.value ? 1 : -1,
                );
            }
        
            this.setState({
                PageLoadSearchResults: Searchdata, FilteredSearchResults: filterSearchData, FilterValuedata: Searchdata, MarketPlace: SMtype, StoreRoom: SRtype,
                FavoritesSubMenu: Favsub, ManufactureTable: Manufacturelist, pageCount: PageCountList, TotalItemCount: Searchdata.length, pageload: 1,
                filterAppliedType: "All", Manufacture: ManuValue, AttributeTree: AttributeValue, SupplierTree: SupplierFIlterValue,
                showpagination: showpaginationL, DescriptionType: DescripType, DidyouMean1: DidyoumeanJSON1, DidyouMean2: DidyoumeanJSON2,
                DidyouMean3: DidyoumeanJSON3, Site_Currency: Site_CurrencyValue, ShowDidyoumean: ShowDidyoumean, category: allCategory, bindCategory: allCategory, categoryError: false, subCategoryError: false, subCategory: allSubCategory, bindSubCategory: [], attributeChildCount: attributeChildCount  
                //CatalogTree: TradeValue, PageloadcatalogTree: TradeTree, CattreeItemID: catItemID, CatalogLookup: CatalogLookup 
            })
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if(this.state.addToCartRTI){
            this.toastMessage("success-toast");
            this.setState({addToCartRTI : false});
        }
    }
    bindSearchResults = () => {
        debugger
        return this.state.FilteredSearchResults.map((val, indexval) => {

            let ItemIndex = indexval
            let imagePath = "";
            if (val.ThumbnailImagefile) {
                val.Image = val.ThumbnailImagefile.replace('\\', '/');
                if (val.Image.toLowerCase().indexOf("http") >= 0) {
                    imagePath = val.ThumbnailImagefile;
                } else {
                    imagePath = val.ThumbnailImagefile.lastIndexOf(`/`) >= 0 ? val.ThumbnailImagefile.substring(val.ThumbnailImagefile.lastIndexOf("/") + 1, val.ThumbnailImagefile.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = `/Images/${imagePath}`;
                    }
                }
            } else {
                imagePath = `/Images/noimage_new.png`;
            }
            let FavItem = false
            let favsubitem = []
            favsubitem = this.state.FavoritesSubMenu
            if (favsubitem.length > 0) {
                //let FavItem = false
                favsubitem.map((item, index) => {
                    let CLIENT_MFG_PART = item.CLIENT_MFG_PART == null || item.CLIENT_MFG_PART == undefined || item.CLIENT_MFG_PART == "" ? " " : item.CLIENT_MFG_PART
                    let CUSTOMERITEMID = item.CUSTOMERITEMID == null || item.CUSTOMERITEMID == undefined || item.CUSTOMERITEMID == "" ? " " : item.CUSTOMERITEMID
                    let MANUFACTURER = item.MANUFACTURER == null || item.MANUFACTURER == undefined || item.MANUFACTURER == "" ? " " : item.MANUFACTURER
                    let SUPPLIER_ID = item.SupplierID == null || item.SupplierID == undefined || item.SupplierID == "" ? " " : item.SupplierID
                    let Manufacturer1 = val.manufacturer == null || val.manufacturer == undefined || val.manufacturer == "" ? " " : val.manufacturer
                    let ItemidFav = val.clientPartNumber == null || val.clientPartNumber == undefined || val.clientPartNumber == "" ? " " : val.clientPartNumber //[10/21/21, PC_ITM_1, CAS - 36], Poornima S: Changed Item Id value retriving field from val.Id to val.clientPartNumber in SearchComponent
                    let Manufacturerpartnumber = val.manfPartNumber == null || val.manfPartNumber == undefined || val.manfPartNumber == "" ? " " : val.manfPartNumber
                    let vendor_id = val.vendor_id == null || val.vendor_id == undefined || val.vendor_id == "" ? " " : val.vendor_id //[06/29/2023, Zeus-94], Kishore S - Displaying Favorites based on Supplier ID
                    
                    if (CUSTOMERITEMID.toUpperCase() == ItemidFav.toUpperCase() && CLIENT_MFG_PART.toUpperCase() == Manufacturerpartnumber.toUpperCase()
                        && MANUFACTURER.toUpperCase() == Manufacturer1.toUpperCase() && SUPPLIER_ID.trim().toUpperCase() == vendor_id.trim().toUpperCase()) {
                        FavItem = true
                    }
                })
            }
            let QuantityOnhand = ""
            let QuantityOnhandL = []
            if (val.CustomColQOH != null) {
                let valqtg = val.CustomColQOH
                QuantityOnhandL = valqtg.match(/[a-zA-Z0-9]/);
                //console.log("QuantityOnhand", QuantityOnhandL)
                if (QuantityOnhandL != null && QuantityOnhandL != undefined) {
                    if (QuantityOnhandL.length > 0) {
                        if (QuantityOnhandL[0] != "Y" && QuantityOnhandL[0] != "y" && QuantityOnhandL[0] == 0) {
                            QuantityOnhand = ""
                            //console.log("QuantityOnhand588758", QuantityOnhand)
                        }
                        else {
                            QuantityOnhand = QuantityOnhandL[0]
                        }
                    }
                }
            }
            let message = this.GetShipwithin(val.ships_within)
            const style = {
                image: {
                    position: 'sticky !important',
                    display: 'block',
                    left: '0px !important',
                    top: '0px !important',
                },
            }
            
            //Converting the item price string to float & if it is less than 0, bind "0.0" else bind price with 2 points & comma separator
            let UnitPriceFloat = parseFloat(val.ItemPrice) ? parseFloat(val.ItemPrice) : 0
            let UnitPrice = val.ItemPrice;
            if (UnitPriceFloat < 0) {
                UnitPrice = "0.00"
            }
            
            let descriptionvalue = val.Description != "\N" || val.Description != null ? val.Description.trim() : "NA"
            //[10/12/21, PC_US_10, CAS - 36], Poornima S
            let clientdescription = val.clientDesc != "\N" || val.clientDesc != null ? val.clientDesc.trim() : "NA"
            return (

                <div className="col-lg-3 col-md-6 col-sm-6 col-12 product-section" key={indexval}>
                    <div className="gridlayout-widget">
                        <div className="col-12 order-summary-image category-search-image" >
                            <button className="border image-background px-0" onClick={() => this.showitemdetails(indexval, val.clientPartNumber, val.ItemAttributeId, FavItem)}>
                                <Image
                                    id={"image" + ItemIndex}
                                    src={imagePath}
                                    width={219}
                                    height={216}
                                    style={style.image}
                                    noImageSrc="/Images/noimage_new.png"

                                />
                            </button>
                            {/*<img src={imagePath} alt="fav_item" onClick={() => this.showitemdetails(indexval, val.clientPartNumber, val.ItemAttributeId, FavItem)} />*/}
                            <div className="fav-item-maintenance-icon gridfav-icon">
                                <input type="checkbox" className="checknow" id={ItemIndex} checked={FavItem} onChange={(e) => this.BindFavForm(e, FavItem, val.ItemAttributeId)} data-backdrop="static" data-keyboard="false" />
                                <label htmlFor={ItemIndex}>
                                    <svg className="heart-svg heart-icon-view" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg" data-backdrop="static" data-keyboard="false">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </div> 
                        </div>
                        <span className="col-12 fav-item-title float-left">
                            <a href="#" className="float-left my-2 category-search-id itemHyperlink" onClick={(e) => this.showitemdetails(indexval, val.clientPartNumber, val.ItemAttributeId, FavItem, e)}>{val.supplierName == "AMAZON" ? val.CustomColItemID : val.clientPartNumber}</a>
                            {/*message.Sampletest != "" && (QuantityOnhand == "" || QuantityOnhand == 0 || QuantityOnhand == "0") ? <span className="info-container ml-2">
                                <img className="info-searchpage my-3" src="Images/info-icon.png" />
                                <span className="Grid-info-icon">{message.Sampletest}</span>
                            </span> : null*/}
                            {/*//[07/04/23,PS_ZEUS_127_APP_02, Johnprinto D]*/}
                            {val.CustomColQOH == "ORO" ?
                                <span className="oro-item" style={{ "top": "7px", "margin-left": "7px" }}>O</span> : null}
                            {val.CustomColQOH == "VMI" ?
                                <span className="oro-item vmi-item" style={{ "top": "7px", "margin-left": "7px" }}>V</span> : null}
                        </span>
                        <label className="col-md-12 item-details-value">
                            {this.state.DescriptionType == "1" || this.state.DescriptionType == 1 ? descriptionvalue.trim() : clientdescription.trim()}
                        </label>
                        {/*//[06/02/23,PS_ZE_125_APP_03, Johnprinto D]*/}
                        {/*//[07/04/23,PS_ZEUS_127_APP_02, Johnprinto D]*/}
                        {/*//[07/03/24,PS_ZE_286_APP_01, Madhumitha K-Hide the Quantity available label for ORO Items]*/}

                        {val.vendor_id == "IPM9999999" && val.CustomColQOH != "ORO" ? <span className="col-md-12 float-left item-detail-suppleir h6 mt-1 Font-weight5"><Trans>Quantity Available</Trans>:<span className="mx-1 item-detail-suppleir Font-weight1">{isNaN(val.CustomColQOH) == true ? "0" : val.CustomColQOH}</span></span> : null}
                        {/* Zeus - 242 */}
                        <span className="col-md-12 float-left item-detail-suppleir h6 mt-1 Font-weight5"><Trans>Supplier</Trans> <Trans>Part</Trans># : <span className="mx-1 item-detail-suppleir Font-weight1">{val.clientPartNumber}</span></span>
                        <span className="col-md-12 float-left item-detail-suppleir h6 mt-1 Font-weight5"><Trans>Supplier</Trans>:<span className="mx-1 item-detail-suppleir Font-weight1">{val.supplierName}</span></span>

                        {this.state.showprice == "0" ? <span className="col-md-12 float-left item-detail-suppleir h6 mt-2 Font-weight5"><Trans>Price</Trans>:<span className="mx-1 item-detail-suppleir Font-weight1">{this.state.Site_Currency + " " + UnitPrice}</span></span> : null}
                        {/*[5/30/2023, PS_ZE_125_APP_02, Johnprinto D] */}
                        <span className="col-md-12 float-left item-detail-suppleir h6 mt-1 Font-weight5"><Trans>Ships By</Trans>:<span className="mx-1 item-detail-suppleir Font-weight1">{message}</span></span>
                        <div className="col-md-12 float-left pb-2">
                            <div className="float-left">
                                {/*******NNEW*(30/12/2020)****val.ItemPrice-->UnitPrice********/}
                            
                                    <span className="float-left my-2 search-rate d-none">{this.state.Site_Currency + " " + UnitPrice}</span> 
                            </div>
                            <div className="float-left my-2">
                                <span className="float-left mt-2 price-calc"><Trans>Quantity</Trans>:</span>
                                {/* //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D] */}
                                <div className="quantity buttons_added" style={{marginLeft : "1%",width: "30%", float: "left" ,marginRight: "15%"}}>
                                    <input type="button" className="minus font-bold" value="-" disabled={val.QuantityOrdered <= 1 || val.QuantityOrdered == ""} onClick={(e) => this.qtyOnchange(e, indexval, "QTY_DECREASED")}/>
                                    <input type="text" value={val.QuantityOrdered} maxLength={8} class="minus" minLength="1" style={{ "width": "73%", "borderLeft": "0" }} onChange={(e) => this.qtyOnchange(e, indexval, "QTY_CHANGE")} />
                                    <input type="button" className="plus font-bold" value="+" disabled={val.QuantityOrdered >= maxQty} onClick={(e) => this.qtyOnchange(e, indexval, "QTY_INCREASED")} />
                                    <button className="btn btn-custom-primary font-14 font-medium ml-2" onClick={()=>this.isRTIItem(indexval, true)}><img src="images/cart-icon.svg" alt="cart" /></button>
                                </div>
                                {/* <input type="text" value={val.QuantityOrdered == null || val.QuantityOrdered.trim() == "" ? "" : val.QuantityOrdered} className="form-control float-left catalog-inline-qty" maxLength="8" onChange={(e) => this.AddOrder(e, indexval, val.clientPartNumber, val.ItemAttributeId, val.manfPartNumber, val.vendor_id)} />
                                <a className="search-results-cart-button float-left ml-2" onClick={(e) => this.Addtocart(e)}><span className="cart-icon" /></a> */}
                            </div>
                        </div>
                    </div>
                </div>

            )

        });

    }

    bindSearchResultsGridView = () => {
        debugger
        const { t } = this.props;
        return this.state.FilteredSearchResults.map((val, indexval) => {

            let ItemIndex = indexval
            let imagePath = "";
            if (val.ThumbnailImagefile) {
                val.Image = val.ThumbnailImagefile.replace('\\', '/');
                if (val.Image.toLowerCase().indexOf("http") >= 0) {
                    imagePath = val.ThumbnailImagefile;
                } else {
                    imagePath = val.ThumbnailImagefile.lastIndexOf(`/`) >= 0 ? val.ThumbnailImagefile.substring(val.ThumbnailImagefile.lastIndexOf("/") + 1, val.ThumbnailImagefile.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = `/Images/${imagePath}`;
                    }
                }
            } else {
                imagePath = `/Images/noimage_new.png`;
            }
            debugger;
            let QuantityOnhand = ""
            let QuantityOnhandL = []
            if (val.CustomColQOH != null) {
                let valqtg = val.CustomColQOH
                QuantityOnhandL = valqtg.match(/[a-zA-Z0-9]/);
                //console.log("QuantityOnhand", QuantityOnhandL)
                if (QuantityOnhandL != null && QuantityOnhandL != undefined) {
                    if (QuantityOnhandL.length > 0) {
                        if (QuantityOnhandL[0] != "Y" && QuantityOnhandL[0] != "y" && QuantityOnhandL[0] == 0) {
                            QuantityOnhand = ""
                            //console.log("QuantityOnhand588758", QuantityOnhand)
                        }
                        else if (QuantityOnhandL[0] = !0) {
                            QuantityOnhand = val.CustomColQOH
                        }
                    }
                }
            }


            let vendorlogo = ""
            if (val.vendor_id) {
                let vendorvalue = val.vendor_id + ".png"
                vendorlogo = `/Images/${vendorvalue}`;
            }

            let FavItem = false
            let favsubitem = []
            favsubitem = this.state.FavoritesSubMenu
            if (favsubitem.length > 0) {
                //let FavItem = false
                favsubitem.map((item, index) => {
                    let CLIENT_MFG_PART = item.CLIENT_MFG_PART == null || item.CLIENT_MFG_PART == undefined || item.CLIENT_MFG_PART == "" ? " " : item.CLIENT_MFG_PART
                    let CUSTOMERITEMID = item.CUSTOMERITEMID == null || item.CUSTOMERITEMID == undefined || item.CUSTOMERITEMID == "" ? " " : item.CUSTOMERITEMID
                    let MANUFACTURER = item.MANUFACTURER == null || item.MANUFACTURER == undefined || item.MANUFACTURER == "" ? " " : item.MANUFACTURER
                    let SUPPLIER_ID = item.SupplierID == null || item.SupplierID == undefined || item.SupplierID == "" ? " " : item.SupplierID
                    let Manufacturer1 = val.manufacturer == null || val.manufacturer == undefined || val.manufacturer == "" ? " " : val.manufacturer
                    let ItemidFav = val.clientPartNumber == null || val.clientPartNumber == undefined || val.clientPartNumber == "" ? " " : val.clientPartNumber
                    let Manufacturerpartnumber = val.manfPartNumber == null || val.manfPartNumber == undefined || val.manfPartNumber == "" ? " " : val.manfPartNumber
                    let vendor_id = val.vendor_id == null || val.vendor_id == undefined || val.vendor_id == "" ? " " : val.vendor_id

                    //Added 'AND' to check fav items //Fav icon is not removed for items in search results which are deleted from fav items page - Poornima S
                    if (CUSTOMERITEMID.toUpperCase() == ItemidFav.toUpperCase() && CLIENT_MFG_PART.toUpperCase() == Manufacturerpartnumber.toUpperCase()
                        && MANUFACTURER.toUpperCase() == Manufacturer1.toUpperCase() && SUPPLIER_ID.trim().toUpperCase() == vendor_id.trim().toUpperCase()) {
                        FavItem = true
                    }
                })
            }
            let message = this.GetShipwithin(val.ships_within)
            const style = {
                image: {
                    position: 'sticky !important',
                    display: 'block',
                    left: '0px !important',
                    top: '0px !important',
                },
            }
            //Converting the item price string to float & if it is less than 0, bind "0.0" else bind price with 2 points & comma separator
            let UnitPriceFloat = parseFloat(val.ItemPrice) ? parseFloat(val.ItemPrice) : 0
            let UnitPrice = val.ItemPrice;
            if (UnitPriceFloat < 0) {
                UnitPrice = "0.00"
            }

            /*[11/23/21, PC_SR_01 BUG 1824], Poornima S*/          
            let CurrencyCd = ""
            if (val.CurrencyCd != null && val.CurrencyCd != undefined && val.CurrencyCd != "") {
                CurrencyCd = val.CurrencyCd
            }
            else {
                CurrencyCd = " "
            }

            let showvendorlogo = false
            if (CookieService.getLocalStorageValues("Session_SDIEMP") != null && CookieService.getLocalStorageValues("Session_SDIEMP") != undefined) {
                if (CookieService.getLocalStorageValues("Session_SDIEMP") == "SDI") {
                    showvendorlogo = true
                }
            }
            let descriptionvalue = val.Description != "\N" || val.Description != null ? val.Description.trim() : "NA"
            //[10/12/21, PC_US_10, CAS - 36], Poornima S
            let clientdescription = val.clientDesc != "\N" || val.clientDesc != null ? val.clientDesc.trim() : "NA"
            //[2/15/22, PC_SRSM_1, CAS - 42], Poornima S
            let lblSearchType=""
            if (val.customerPartnumber != null && val.customerPartnumber != undefined && val.customerPartnumber != "") {
                if (val.customerPartnumber.length >= 3) {
                    if (val.customerPartnumber_CatalogZero != null && val.customerPartnumber_CatalogZero != undefined && val.customerPartnumber_CatalogZero != "" && val.customerPartnumber.substring(0, 3) != "SDI") {
                        lblSearchType = "SR"
                    }
                    else {
                        lblSearchType = "SM"
                    }
                }
                else {
                    lblSearchType = "SR"
                }
            }

            if (val.CatlgOrNotCatlg == "1") {
                //do nothing
            }
            else {
                lblSearchType = "SM"
            }

            return (
            
                <div className="row mb-1 py-2" key={indexval}>
                    <div className="col-md-4 col-lg-2 order-summary-image category-search-image category-search-image-height" onClick={() => this.showitemdetails(indexval, val.clientPartNumber, val.ItemAttributeId, FavItem)}>
                        <div className="row justify-content-center">
                            <Image
                                src={imagePath}
                                width={150}
                                height={150}
                                style={style.image}
                                noImageSrc="/Images/noimage_new.png"
                            /></div>
                        {/*<img src={imagePath} alt="fav_item" onClick={() => this.showitemdetails(indexval, val.clientPartNumber, val.ItemAttributeId, FavItem)} />*/}
                    </div>
                    <div className="col-md-8 col-lg-10 mobile-catalog mobile-catalog-search">
                        <div className="row">
                            <div className="col-lg-9 p-0 search-content">
                                <div className="col-md-12 float-left content-spacing">
                                    <span className="fav-item-title">
                                        {/* Zeus 242 */}
                                        <a href="#" className="float-left mt-2 category-id category-search-id itemHyperlink" onClick={(e) => this.showitemdetails(indexval, val.clientPartNumber, val.ItemAttributeId, FavItem, e)}>{val.supplierName == "AMAZON" ? val.CustomColItemID : val.manfPartNumber}</a>
                                    </span>
                                    {CookieService.getLocalStorageValues("Session_DeliveryDateFlag") != "Y" ? <span className="catalog-search-result mr-2 catalog-search-result-sr ml-2">{lblSearchType}</span> : null}
                                    {/*//[07/04/23,PS_ZEUS_127_APP_04, Johnprinto D]*/}
                                    {val.CustomColQOH == "ORO" ?
                                        <span className="oro-item">O</span> : null}
                                    {val.CustomColQOH == "VMI" ?
                                        <span className="oro-item vmi-item">V</span> : null}
                                    <div className="fav-item-maintenance-icon">
                                        <input type="checkbox" className="checknow" id={ItemIndex} checked={FavItem} onChange={(e) => this.BindFavForm(e, FavItem, val.ItemAttributeId)} data-backdrop="static" data-keyboard="false" />
                                        <label htmlFor={ItemIndex} className="mb-0">
                                            <svg className="heart-svg heart-icon heart-icon-view" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg" data-backdrop="static" data-keyboard="false">
                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </label>
                                    </div> 
                                </div>
                                <label className="col-md-12 item-details-value content-width float-left Hideoverflow">
                                    {this.state.DescriptionType == "1" || this.state.DescriptionType == 1 ? descriptionvalue.trim() : clientdescription.trim()}
                                </label>
                                <div className="col-md-12 float-left">
                                    <div className="row">
                                        <div className="col-md-9 fav-maintence custom-search-content">
                                            <label className="catalog-label float-left">MFG:</label>
                                            <label className="item-details-value ml-2 Search-Manufac-label" data-toggle="tooltip" title={val.manufacturer}>{val.manufacturer}</label>
                                        </div>
                                        {/*<div className="col-md-5 fav-maintence custom-search-content">
                                            <label className="catalog-label float-none">MFG Part Number:</label>
                                            <label className="item-details-value  ml-2 Search-ManuPart-label" data-toggle="tooltip" title={val.manfPartNumber}>{val.manfPartNumber}</label>
                                        </div>*/}
                                        <div className="col-md-3 fav-maintence custom-search-content">
                                            <label className="catalog-label float-none">UOM:</label>
                                            <label className="item-details-value ml-2 float-none">{val.UOM}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 float-left">
                                    <div className="row">
                                        <div className="col-md-9 float-left custom-search-content">
                                            <div className="fav-maintence">
                                                {/* Zeus 242 */}
                                                <label className="catalog-label float-left"><Trans>Supplier</Trans> <Trans>Part</Trans># : </label>
                                                <label className="item-details-value  ml-2 Search-ManuPart-label" data-toggle="tooltip" title={val.clientPartNumber}>{val.clientPartNumber}</label>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/*[07/04/2023, Zeus-91, Kishore S] */}
                                <div className="col-md-12 float-left custom-search-content">
                                    <div className="fav-maintence">
                                        <label className="catalog-label float-none"><Trans>Supplier</Trans>:</label>
                                        <label className="item-details-value  ml-2 card-product-add-cart-panel mr-2">{val.supplierName}</label>
                                        {/*  {message.Sampletest != "" && (QuantityOnhand == "" || QuantityOnhand == 0 || QuantityOnhand == "0") ? <span className="info-container">
                                            <img className="info-searchpage" src="Images/info-icon.png" />
                                            <span className="info-content info-position">{message.Sampletest}</span>
                                        </span> : null}*/}

                                    </div>
                                </div>
                                {/*[5/30/2023, PS_ZE_125_APP_03, Johnprinto D] */}
                                <div className="col-md-12 float-left">
                                    <div className="row">
                                        <div className="col-md-9 float-left custom-search-content">
                                            <div className="fav-maintence">
                                                <label className="catalog-label float-left"><Trans>Ships By</Trans>:</label>
                                                <label className="item-details-value  ml-2 Search-ManuPart-label">{message}</label>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/* <div className="col-md-12 float-left custom-search-content">

                                    {QuantityOnhand != "" && QuantityOnhand != 0 && QuantityOnhand != "0" ? <span className="qty-count item-details-value">Qty Available:<span className="mx-1 item-details-value">{QuantityOnhand}</span>
                                    </span> : null}



                                    <img className="vendor-logo-img" src={vendorlogo}/>
                                </div>*/}
                                {/*//[06/02/23,PS_ZE_125_APP_05, Johnprinto D]*/}
                                {/*//[07/03/24,PS_ZE_286_APP_01, Madhumitha K-Hide the Quantity available label for ORO Items]*/}

                                {val.vendor_id == "IPM9999999" && val.CustomColQOH != "ORO"  ? <div className="col-md-12 float-left">
                                    <div className="row">
                                        <div className="col-md-9 float-left custom-search-content">
                                            <div className="fav-maintence">
                                                <label className="catalog-label float-none"><Trans>Quantity Available</Trans>:</label>
                                                <label className="item-details-value  ml-2">
                                                    {/*//[07/04/23,PS_ZEUS_127_APP_03, Johnprinto D]*/}
                                                    {isNaN(val.CustomColQOH) == true ? "0" : val.CustomColQOH}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                                {showvendorlogo == true && val.CatlgOrNotCatlg != "NA" ? <div className="col-md-12 float-left" style={{ "display": "none" }}>
                                    <div className="row">
                                        <div className="col-md-9 float-left custom-search-content mt-2">
                                            <div className="fav-maintence">
                                                <label className="catalog-label float-none"><Trans>Vendor</Trans>:</label>
                                                <label className="item-details-value  ml-2">
                                                    {val.CatlgOrNotCatlg != "NA" ? <img className="vendor-logo-img" src={vendorlogo} /> : ""}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                            <div className="col-lg-3 pl-0 OSPD-prices">
                                <div className="float-right">
                                    <div className="w-100 float-left">
                                        {this.state.showprice == "0" ? <div className="float-right">
                                            {/*[10/13/21, PC_SR_06 CAS - 32], Priyanka D*/}
                                            <span className="float-left my-2 search-rate">{t("Price")}</span>
                                            <span className="float-left my-2 search-rate">{UnitPriceFloat > 0 ? `: ${this.state.Site_Currency} ${UnitPrice}  ${CurrencyCd} ` : t("Priceonrequest")}</span>
                                            {/**<span className="float-left my-2 search-rate"> { "Price: " + this.state.Site_Currency + " " + UnitPrice }</span>***/}
                                        </div> : null}
                                    </div>
                                    <div className="w-100 float-left mb-2 my-2">
                                        <div className="float-right">
                                            <span className="float-left mt-2 price-calc"><Trans>Quantity</Trans>:</span>
                                            {/* //[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D] */}
                                            <div className="quantity buttons_added" style={{marginLeft : "1%",width: "30%", float: "left" ,marginRight: "15%"}}>
                                                <input type="button" className="minus font-bold" value="-" disabled={val.QuantityOrdered <= 1} onClick={(e) => this.qtyOnchange(e, indexval, "QTY_DECREASED")}/>
                                                <input type="text" value={val.QuantityOrdered} maxLength={8} class="minus" minLength="1" style={{ "width": "67%", "borderLeft": "0" }} onChange={(e) => this.qtyOnchange(e, indexval, "QTY_CHANGE")} />
                                                <input type="button" className="plus font-bold" value="+" disabled={val.QuantityOrdered >= maxQty} onClick={(e) => this.qtyOnchange(e, indexval, "QTY_INCREASED")} />
                                                <button className="btn btn-custom-primary font-14 font-medium ml-2" onClick={()=>this.isRTIItem(indexval, true)}><img src="images/cart-icon.svg" alt="cart" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        });

    }
    qtyOnchange = (e,index,flag) =>{
        let FilteredSearchResults = this.state.FilteredSearchResults;
        let quantity = FilteredSearchResults[index].QuantityOrdered;
        if (flag === "QTY_INCREASED") {
            FilteredSearchResults[index].QuantityOrdered = (isNaN(quantity) ? 0 : +quantity) + 1;
            // FilteredSearchResults[index].QuantityOrdered = (isNaN(parseInt(FilteredSearchResults[index].QuantityOrdered)) ? 0 : parseInt(FilteredSearchResults[index].QuantityOrdered)) + 1; // Increase the quantity for the given index
        } else if (flag === "QTY_DECREASED") {
            FilteredSearchResults[index].QuantityOrdered = (isNaN(quantity) ? 0 : +quantity) - 1;
            // FilteredSearchResults[index].QuantityOrdered = (isNaN(parseInt(FilteredSearchResults[index].QuantityOrdered)) ? 0 : parseInt(FilteredSearchResults[index].QuantityOrdered)) - 1; // Decrease the quantity for the given index
        } else {
            if ((e.target.value == "") || (!isNaN(e.target.value) && parseInt(e.target.value) > 0 && parseInt(e.target.value) <= maxQty)) {
                FilteredSearchResults[index].QuantityOrdered = e.target.value; // Assign the value directly for the given index
            }
        }
        this.setState({FilteredSearchResults});
    }
    // Z-297
    isRTIItem = (index, isPickUpCheck) => {

        const { t } = this.props;
        let isPickupVendor = false;
        if (this.state.FilteredSearchResults[index].QuantityOrdered == "") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Enter a valid Quantity")
            })
            return
        }
        if (isPickUpCheck) {
            let pickupVendor = CookieService.getLocalStorageValues("pickupVendor");
            isPickupVendor = pickupVendor && pickupVendor.some((value) => { if (value.VENDOR_ID === this.state.FilteredSearchResults[index].vendor_id) { return true } })
        }

        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
            if (this.state.Login_UserBU.toUpperCase() == "I0631") {
                if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("validWO") != " " && CookieService.getLocalStorageValues("validWO") != "clearWO" && CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null) {

                }
                else {
                    this.setState({ boolWOEmpty: true })
                    $('#WorkOrder').modal('show');
                    return;
                }
            }
        }

        if(isPickupVendor){
            if(CurrentLatitude != 0 && CurrentLongitude != 0){
                this.Addtocart(index, true)
            }else{
                $('#locationaccess').modal('show');
                {/* //[7/1/2024, Zeus - 297 - 5150 - Item and Qty not reflected correctly - Johnprinto D] */ }
                this.setState({isRTI:true, selectedItemIndex : index })
            }
        } else {
            this.Addtocart(index, false)
            this.setState({isRTI:false})
        }
    }

    GetShipwithin = (shipwithin) => {
        //[5/30/2023, PS_ZE_125_APP_06, Johnprinto D]
        if (shipwithin == '' || shipwithin == undefined || shipwithin == null) {
            let ShipByDate = 'NA'
            return ShipByDate
        }
        let count = 0;
        let dateVal = new Date()
        console.log("today's date", dateVal)
        console.log("no.of days", shipwithin)
        while (count < shipwithin) {
            if (dateVal.getDay() != 0 && dateVal.getDay() != 6) // Skip weekends
            {
                count++;
            }
            dateVal.setDate(dateVal.getDate() + 1);
        }
        //dateVal.setDate(dateVal.getDate() - 1);
        let ShipByDate = dateVal.toLocaleDateString();
        return ShipByDate;
        /*let day = "5"
        let Sampletest = ""
        let symbol = "within"
        if (shipwithin != null && shipwithin != undefined) {
            let symbol1 = shipwithin.match(/[+-<>]/);

            let Number = shipwithin.match(/(\d+)/);
            if (symbol1 && Number) {
                if (Number.length > 0 && symbol1.length > 0) {
                    if (Number[0] != "0") {
                        day = Number[0]
                    }
                    if (symbol1[0] == ">" || symbol1[0] == "+") {
                        symbol = "after"
                    }
                    else if (symbol1[0] == "<" || symbol1[0] == "-") {
                        symbol = "within"
                    }
                    Sampletest = "Usually ships " + symbol + " " + day + " days"
                }
                else {
                    Sampletest = "Usually ships " + symbol + " " + day + " days"
                }
            }
            else {
                Sampletest = "Usually ships " + symbol + " " + day + " days"
            }
        }
        else {
            Sampletest = "Usually ships " + symbol + " " + day + " days"
        }
        return ({ Sampletest })*/
    }
    GridChange = (Type) => {

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let itempercurrent = this.state.itemperpage
        let itemperpageval = 10
        if (itempercurrent == 10 || itempercurrent == 12) {
            itemperpageval = Type == 0 ? 12 : 10
        }
        else if (itempercurrent == 20 || itempercurrent == 24) {
            itemperpageval = Type == 0 ? 24 : 20
        }
        else {
            itemperpageval = Type == 0 ? 60 : 50
        }
        let pagenum = this.state.pageNo
        let showpaginationL = false
        let PageLSearchResults = this.state.PageLoadSearchResults
        let FilterValuedataL = this.state.FilterValuedata
        let startpagecount = 0
        let PageCountList = 0
        startpagecount = (pagenum - 1) * itemperpageval
        let endcount = 0
        endcount = (pagenum * itemperpageval) - 1
        let filterSearchData = []
        let ItemTotalCOunt = 0
        let filterAppliedvalue = this.state.filterApplied
        if (filterAppliedvalue == 0) {
            filterSearchData = PageLSearchResults.filter((val, idx) => idx < itemperpageval)
            ItemTotalCOunt = PageLSearchResults.length
            PageCountList = this.getpagecount(ItemTotalCOunt, itemperpageval)
        }
        else {
            filterSearchData = FilterValuedataL.filter((val, idx) => idx < itemperpageval)
            ItemTotalCOunt = FilterValuedataL.length
            PageCountList = this.getpagecount(ItemTotalCOunt, itemperpageval)
        }
        if (PageCountList > 1) {
            showpaginationL = true
        }
        this.setState({
            GridType: Type, FilteredSearchResults: filterSearchData, pageNo: 1, pageCount: PageCountList,
            TotalItemCount: ItemTotalCOunt, itemperpage: itemperpageval, showpagination: showpaginationL
        })
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
        //[08/04/2023, PS_ZE_171_APP_10 - to remove showSample parameter, Sathis.N]
    ApplyFilter = (SearchKeyWord, FilterType, ManufacApplied, AttributeApplied, supplierfilter, StoreRoom, MarketPlace) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let searchvalue = SearchKeyWord
        let filterAppliedL = this.state.filterApplied
        let filterText = this.state.filterAppliedType
        //let StoreRoom = this.state.StoreRoom
        //let MarketPlace = this.state.MarketPlace
        let PageLSearchResults = this.state.PageLoadSearchResults
        let filteredArray = this.state.FilterValuedata
        let finalfilterarray = []
        let BindGrid = []
        let FilterValueDatatable = PageLSearchResults
        let PageCountList = 0
        finalfilterarray = PageLSearchResults
        let SrchFilter = []
        //[8/15/2022]Bug 1832 - Checking whether the browser is IE if yes then the filter segment without replace method will be executed since IE won't support replaceall()
        if (isIE) {
            if (searchvalue != "") {
                let filterValue = []
                if (FilterType == 1) {
                    filterAppliedL = 1
                    filterText = "All"
                    //[04/06/22, PC_SR_01, Bug - 2020, Dhevanesam R]
                    let D = 0
                    for (D = 0; D < FilterValueDatatable.length; D++) {
                        let Testsearch = FilterValueDatatable[D].Description.split('\n').join('');
                        Testsearch = Testsearch.toLowerCase();
                        let clientDescSearch = FilterValueDatatable[D].clientDesc.split('\n').join('');
                        clientDescSearch = clientDescSearch.toLowerCase();
                        let searchdate = searchvalue.split('\n').join('');
                        searchdate = searchdate.toLowerCase();
                        Testsearch = Testsearch.split(' ').join('');
                        clientDescSearch = clientDescSearch.split(' ').join('');
                        searchdate = searchdate.split(' ').join('');
                        if (FilterValueDatatable[D].UOM.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()) ||
                            FilterValueDatatable[D].manufacturer.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())
                            || Testsearch.includes(searchdate) || clientDescSearch.includes(searchdate) ||
                            FilterValueDatatable[D].clientPartNumber.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()) ||
                            FilterValueDatatable[D].supplierName.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())) {
                            filterValue.push(FilterValueDatatable[D])
                        }
                    }
                }
                else if (FilterType == 2) {
                    filterAppliedL = 1
                    // Zeus 242
                    filterText = "Supplier Part Number"
                    filterValue = finalfilterarray.filter((val, idx) => val.clientPartNumber.split('\n').join('').toLowerCase().includes(searchvalue.split('\n').join('').toLowerCase()))
                }
                else if (FilterType == 3) {
                    //[04/06/22, PC_SR_01, Bug - 2020, Dhevanesam R]
                    filterAppliedL = 1
                    filterText = "Description"
                    let D = 0
                    for (D = 0; D < FilterValueDatatable.length; D++) {
                        let Testsearch = FilterValueDatatable[D].Description.split('\n').join('');
                        Testsearch = Testsearch.toLowerCase();
                        let clientDescSearch = FilterValueDatatable[D].clientDesc.split('\n').join('');
                        clientDescSearch = clientDescSearch.toLowerCase();
                        let searchdate = searchvalue.split('\n').join('');
                        searchdate = searchdate.toLowerCase();
                        Testsearch = Testsearch.split(' ').join('');
                        clientDescSearch = clientDescSearch.split(' ').join('');
                        searchdate = searchdate.split(' ').join('');
                        if (Testsearch.includes(searchdate) || clientDescSearch.includes(searchdate)) {
                            filterValue.push(FilterValueDatatable[D])
                        }
                    }
                }
                else if (FilterType == 4) {
                    filterAppliedL = 1
                    filterText = "MFG Name"
                    filterValue = finalfilterarray.filter((val, idx) => val.manufacturer.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()))
                }
                else if (FilterType == 5) {
                    filterAppliedL = 1
                    // Zeus 242
                    filterText = "Item ID"
                    filterValue = finalfilterarray.filter((val, idx) => val.manfPartNumber.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()))
                }
                else if (FilterType == 6) {
                    filterAppliedL = 1
                    filterText = "Supplier"
                    filterValue = finalfilterarray.filter((val, idx) => val.supplierName.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()))
                }
                else if (FilterType == 7) {
                    filterAppliedL = 1
                    filterText = "UOM"
                    filterValue = finalfilterarray.filter((val, idx) => val.UOM.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()))
                }
                else {                    
                    filterText = "All"
                    //[04/06/22, PC_SR_01, Bug - 2020, Dhevanesam R]
                    let D = 0
                    for (D = 0; D < FilterValueDatatable.length; D++) {
                        let Testsearch = FilterValueDatatable[D].Description.split('\n').join('');
                        Testsearch = Testsearch.toLowerCase();
                        let clientDescSearch = FilterValueDatatable[D].clientDesc.split('\n').join('');
                        clientDescSearch = clientDescSearch.toLowerCase();
                        let searchdate = searchvalue.split('\n').join('');
                        searchdate = searchdate.toLowerCase();
                        Testsearch = Testsearch.split(' ').join('');
                        clientDescSearch = clientDescSearch.split(' ').join('');
                        searchdate = searchdate.split(' ').join('');
                        if (FilterValueDatatable[D].UOM.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()) ||
                            FilterValueDatatable[D].manufacturer.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())
                            || Testsearch.includes(searchdate) || clientDescSearch.includes(searchdate) ||
                            FilterValueDatatable[D].clientPartNumber.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase()) ||
                            FilterValueDatatable[D].supplierName.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.split(' ').join('').toLowerCase().includes(searchvalue.split(' ').join('').toLowerCase())) {
                            filterValue.push(FilterValueDatatable[D])
                        }
                    }
                    filterAppliedL = 1
                }
                FilterValueDatatable = filterValue
            }
            else {
                FilterValueDatatable = finalfilterarray
            }
        }
        else {
            if (searchvalue != "") {
                let filterValue = []
                if (FilterType == 1) {
                    filterAppliedL = 1
                    filterText = "All"
                    
                    //[04/06/22, PC_SR_01, Bug - 2020, Dhevanesam R]
                    let D = 0
                    for (D = 0; D < FilterValueDatatable.length; D++) {
                        let Testsearch = FilterValueDatatable[D].Description.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        let clientDescSearch = FilterValueDatatable[D].clientDesc.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        let searchdate = searchvalue.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        Testsearch = Testsearch.replaceAll(" ", "").toLowerCase()
                        clientDescSearch = clientDescSearch.replaceAll(" ", "").toLowerCase()
                        searchdate = searchvalue.replaceAll(" ", "").toLowerCase()
                        if (FilterValueDatatable[D].UOM.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()) ||
                            FilterValueDatatable[D].manufacturer.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())
                            || Testsearch.includes(searchdate) || clientDescSearch.includes(searchdate) ||
                            FilterValueDatatable[D].clientPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()) ||
                            FilterValueDatatable[D].supplierName.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())) {
                            filterValue.push(FilterValueDatatable[D])
                        }
                    }
                }
                else if (FilterType == 2) {
                    filterAppliedL = 1
                    filterText = "Supplier Part Number"
                    filterValue = finalfilterarray.filter((val, idx) => val.clientPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()))
                }
                else if (FilterType == 3) {
                    //[04/06/22, PC_SR_01, Bug - 2020, Dhevanesam R]
                    filterAppliedL = 1
                    filterText = "Description"
                    let D = 0
                    for (D = 0; D < FilterValueDatatable.length; D++) {
                        let Testsearch = FilterValueDatatable[D].Description.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        let clientDescSearch = FilterValueDatatable[D].clientDesc.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        let searchdate = searchvalue.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        Testsearch = Testsearch.replaceAll(" ", "").toLowerCase()
                        clientDescSearch = clientDescSearch.replaceAll(" ", "").toLowerCase()
                        searchdate = searchvalue.replaceAll(" ", "").toLowerCase()
                        if (Testsearch.includes(searchdate) || clientDescSearch.includes(searchdate)) {
                            filterValue.push(FilterValueDatatable[D])
                        }
                    }
                }
                else if (FilterType == 4) {
                    filterAppliedL = 1
                    filterText = "MFG Name"
                    filterValue = finalfilterarray.filter((val, idx) => val.manufacturer.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()))
                }
                else if (FilterType == 5) {
                    filterAppliedL = 1
                    filterText = "Item ID"
                    filterValue = finalfilterarray.filter((val, idx) => val.manfPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()))
                }
                else if (FilterType == 6) {
                    filterAppliedL = 1
                    filterText = "Supplier"
                    filterValue = finalfilterarray.filter((val, idx) => val.supplierName.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()))
                }
                else if (FilterType == 7) {
                    filterAppliedL = 1
                    filterText = "UOM"
                    filterValue = finalfilterarray.filter((val, idx) => val.UOM.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()))
                }
                else {                    
                    filterText = "All"
                    //[04/06/22, PC_SR_01, Bug - 2020, Dhevanesam R]
                    let D = 0
                    for (D = 0; D < FilterValueDatatable.length; D++) {
                        let Testsearch = FilterValueDatatable[D].Description.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        let clientDescSearch = FilterValueDatatable[D].clientDesc.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        let searchdate = searchvalue.replaceAll(/(\r\n|\n|\r)/gm, "").toLowerCase()
                        Testsearch = Testsearch.replaceAll(" ", "").toLowerCase()
                        clientDescSearch = clientDescSearch.replaceAll(" ", "").toLowerCase()
                        searchdate = searchvalue.replaceAll(" ", "").toLowerCase()
                        if (FilterValueDatatable[D].UOM.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()) ||
                            FilterValueDatatable[D].manufacturer.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())
                            || Testsearch.includes(searchdate) || clientDescSearch.includes(searchdate) ||
                            FilterValueDatatable[D].clientPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase()) ||
                            FilterValueDatatable[D].supplierName.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())
                            || FilterValueDatatable[D].manfPartNumber.replaceAll(" ", "").toLowerCase().includes(searchvalue.replaceAll(" ", "").toLowerCase())) {
                            filterValue.push(FilterValueDatatable[D])
                        }
                    }
                    filterAppliedL = 1
                }
                FilterValueDatatable = filterValue
            }
            else {
                FilterValueDatatable = finalfilterarray
            }
        }
        
        if (StoreRoom != true && MarketPlace == true) {   
            //[03/03/22, PC_SR_1, CAS - 42], Poornima S: SR & SM type search items filter issue fix
            FilterValueDatatable.forEach((val) => {
                if (val.customerPartnumber != null && val.customerPartnumber != undefined && val.customerPartnumber != "") {
                    if (val.customerPartnumber.length >= 3) {
                        if (val.customerPartnumber_CatalogZero != null && val.customerPartnumber_CatalogZero != undefined && val.customerPartnumber_CatalogZero != "" && val.customerPartnumber.substring(0, 3) != "SDI" && val.CatlgOrNotCatlg == "1") {

                        }
                        else {
                            SrchFilter.push(val);
                        }
                    }
                }

            });
            filterAppliedL = 1
            FilterValueDatatable = SrchFilter
        }
        else if (StoreRoom == true && MarketPlace != true) {   
            //[03/03/22, PC_SR_2, CAS - 42], Poornima S: SR & SM type search items filter issue fix
            FilterValueDatatable.forEach((val) => {
                if (val.customerPartnumber != null && val.customerPartnumber != undefined && val.customerPartnumber != "") {
                    if (val.customerPartnumber.length >= 3) {
                        if (val.customerPartnumber_CatalogZero != null && val.customerPartnumber_CatalogZero != undefined && val.customerPartnumber_CatalogZero != "" && val.customerPartnumber.substring(0, 3) != "SDI" && val.CatlgOrNotCatlg == "1") {
                            SrchFilter.push(val);
                        }
                    }
                    else {
                        SrchFilter.push(val);
                    }
                }

            });
            filterAppliedL = 1
            FilterValueDatatable = SrchFilter
        }
        else if (StoreRoom != true && MarketPlace != true) {
            filterAppliedL = 1
            FilterValueDatatable = []
        }
        else {
            FilterValueDatatable = FilterValueDatatable
        }
    /**** Commenting started /PS_ZE_171_APP_10/--- Info item field is already commented so commenting this unused part****/
        /*if (ShowSample == true) {
            let filterSearchData1 = [];
            let M = 0
            for (M = 0; M < FilterValueDatatable.length; M++) {
                let QtyOnHand = null
                let Testquant = ""
                let QuantityOnhand = ""
                let QuantityOnhandL = []
                if (FilterValueDatatable[M].CustomColQOH != null) {
                    let valqtg = FilterValueDatatable[M].CustomColQOH
                    QuantityOnhandL = valqtg.match(/[a-zA-Z0-9]/);
                    //console.log("QuantityOnhand", QuantityOnhandL)
                    if (QuantityOnhandL != null && QuantityOnhandL != undefined) {
                        if (QuantityOnhandL.length > 0) {
                            if (QuantityOnhandL[0] != "Y" && QuantityOnhandL[0] != "y" && QuantityOnhandL[0] == 0) {
                                QuantityOnhand = ""
                                //console.log("QuantityOnhand588758", QuantityOnhand)
                            }
                            else {
                                QuantityOnhand = QuantityOnhandL[0]
                            }
                        }
                    }
                }
                //if (FilterValueDatatable[M].CustomColQOH == null || FilterValueDatatable[M].CustomColQOH == undefined) {
                //    Testquant = "N"
                //    QtyOnHand = "0"//Testquant.match(/[1-9]/)
                //}
                //else {
                //    QtyOnHand = FilterValueDatatable[M].CustomColQOH.match(/[0-9]/)
                //    Testquant = FilterValueDatatable[M].CustomColQOH
                //}
                if (QuantityOnhand == "" || QuantityOnhand == "0" || QuantityOnhand == 0) {
                    filterSearchData1.push(FilterValueDatatable[M])
                }
            }
            //filterSearchData1 = FilterValueDatatable.filter((val, idx) => val.qty_on_hand.match(/[nN]/) != null && val.qty_on_hand.match(/[nN]/) != undefined)
            FilterValueDatatable = filterSearchData1
        }
        */
                        /**** Commenting Ended /PS_ZE_171_APP_10/ --- Info item field is already commented so commenting this unused part****/
        //else {
        //    let filterSearchData1 = [];
        //    let M = 0
        //    for (M = 0; M < FilterValueDatatable.length; M++) {
        //        let QtyOnHand = null
        //        let Testquant = ""
        //        let QuantityOnhand = ""
        //        let QuantityOnhandL = []
        //        if (FilterValueDatatable[M].CustomColQOH != null) {
        //            let valqtg = FilterValueDatatable[M].CustomColQOH
        //            QuantityOnhandL = valqtg.match(/[a-zA-Z0-9]/);
        //            //console.log("QuantityOnhand", QuantityOnhandL)
        //            if (QuantityOnhandL != null && QuantityOnhandL != undefined) {
        //                if (QuantityOnhandL.length > 0) {
        //                    if (QuantityOnhandL[0] != "Y" && QuantityOnhandL[0] != "y" && QuantityOnhandL[0] == 0) {
        //                        QuantityOnhand = ""
        //                        //console.log("QuantityOnhand588758", QuantityOnhand)
        //                    }
        //                    else {
        //                        QuantityOnhand = QuantityOnhandL[0]
        //                    }
        //                }
        //            }
        //        }

        //        //if (FilterValueDatatable[M].CustomColQOH == null || FilterValueDatatable[M].CustomColQOH == undefined) {
        //        //    Testquant = "N"
        //        //    QtyOnHand = "0"//Testquant.match(/[1-9]/)
        //        //}
        //        //else {
        //        //    QtyOnHand = FilterValueDatatable[M].CustomColQOH.match(/[0-9]/)
        //        //    Testquant = FilterValueDatatable[M].CustomColQOH
        //        //}
        //        if (QuantityOnhand != "" && QuantityOnhand != "0" && QuantityOnhand != 0) {
        //            filterSearchData1.push(FilterValueDatatable[M])
        //        }
        //    }
        //    //filterSearchData1 = FilterValueDatatable.filter((val, idx) => val.qty_on_hand.match(/[nN]/) != null && val.qty_on_hand.match(/[nN]/) != undefined)
        //    FilterValueDatatable = filterSearchData1
        //}
        //if (ShowSample == false) {
        //    let filterSearchData12 = [];
        //    filterSearchData12 = FilterValueDatatable.filter((val, idx) => val.ships_within == null || val.ships_within == undefined)
        //    FilterValueDatatable = filterSearchData12
        //}
        if (ManufacApplied != undefined && ManufacApplied.length > 0) {
            var i = 0
            var j = 0
            let filterSearchData = []
            for (i = 0; i < ManufacApplied.length; i++) {
                filterAppliedL = 1
                for (j = 0; j < FilterValueDatatable.length; j++) {
                    if (ManufacApplied[i] == FilterValueDatatable[j].manufacturer) {
                        filterSearchData.push(FilterValueDatatable[j])
                    }
                }
            }
            FilterValueDatatable = filterSearchData
        }
        if (supplierfilter != undefined && supplierfilter.length > 0) {
            var i = 0
            var j = 0
            let filterSearchData = []
            for (i = 0; i < supplierfilter.length; i++) {
                filterAppliedL = 1
                for (j = 0; j < FilterValueDatatable.length; j++) {
                    if (supplierfilter[i] == FilterValueDatatable[j].supplierName) {
                        filterSearchData.push(FilterValueDatatable[j])
                    }
                }
            }
            FilterValueDatatable = filterSearchData
        }
        if (AttributeApplied != undefined && AttributeApplied.length > 0) {

            var k = 0
            var l = 0
            let filterSearchData = []
            let finalAttributeListApplied = []
            AttributeApplied.map((val, index) => {
                let AppliedAttribute = val.split("|");
                let applieddata = {
                    AttributeName: AppliedAttribute[1],
                    AttributeValue: AppliedAttribute[0],
                }
                finalAttributeListApplied.push(applieddata)
            })
            console.log("AttributeNameLocal", finalAttributeListApplied)

            for (l = 0; l < FilterValueDatatable.length; l++) {                
                let RemoveemptyAttri = FilterValueDatatable[l].AttributeName.split("|");
                let RemoveEmptyAttriValue = FilterValueDatatable[l].AttributeValue.split("|");
                let AttributeNameLocal = RemoveemptyAttri.filter((val, idx) => val != "" && val != null)
                let AttributeValueLocal = RemoveEmptyAttriValue.filter((val, idx) => val != "" && val != null)
                let ItemAttribute = []
                //[7/11/2023, PS_ZE_155_APP_01, Dhevanesam R]
                AttributeNameLocal.map((attri4, index2) => {
                    let itemAttributeElement = {
                        AttributeName: attri4.trim(),
                        AttributeValue: AttributeValueLocal[index2].trim()
                    }
                    ItemAttribute.push(itemAttributeElement)
                })
                console.log("ItemAttribute", ItemAttribute)
                let ItemAttributeSelected = []
                ItemAttribute.map((attri, index2) => {
                    let SameAttribute = finalAttributeListApplied.filter((val, idex) => val.AttributeName == attri.AttributeName && val.AttributeValue == attri.AttributeValue)
                    if (SameAttribute != null && SameAttribute != undefined) {
                        if (SameAttribute.length > 0) {
                            //ItemAttributeSelected.push(SameAttribute[0])
                            //[10/07/21, PC_01, Bug ID - 1725, Dhevanesam R]                            
                            filterSearchData.push(FilterValueDatatable[l])
                        }
                    }
                })
                console.log("ItemAttributeSelected", ItemAttributeSelected)
                //if (JSON.stringify(ItemAttributeSelected) === JSON.stringify(ItemAttribute)) {
                //    filterSearchData.push(FilterValueDatatable[l])
                //}
                //var isSame = finalAttributeListApplied.every((o, i) => Object.keys(o).length === Object.keys(ItemAttribute[i]).length && Object.keys(o).every(k => o[k] === ItemAttribute[i][k]));
                //console.log("isSame", isSame)

                //finalAttributeListApplied.map((attri, index2) => {
                //    debugger
                //    let SameAttribute = finalAttributeListApplied.filter((val, idex) => val.AttributeName == attri.AttributeName)
                //    AttributeNameLocal.map((attri4, index2) => {
                //        debugger
                //        if (attri4 == attri.AttributeName) {
                //            SameAttribute.map((attri1, index3) => {
                //                debugger
                //                AttributeValueLocal.map((attri2, index4) => {
                //                    debugger
                //                    if (attri1.AttributeValue == attri2) {
                //                        filterSearchData.push(FilterValueDatatable[l])
                //                    }
                //                })
                //            })
                //        }
                //    })
                //})
                //AttributeNameLocal.sort(function (a, b) {
                //    if (a > b) {
                //        return 1;
                //    }
                //    if (b > a) {
                //        return -1;
                //    }
                //    return 0;
                //});
                //AttributeApplied.sort(function (a, b) {
                //    if (a > b) {
                //        return 1;
                //    }
                //    if (b > a) {
                //        return -1;
                //    }
                //    return 0;
                //});

                ////console.log("AttributeApplied", AttributeApplied)
                //if (AttributeNameLocal) {
                //    var isSame = AttributeNameLocal.length === AttributeApplied.length && AttributeNameLocal.every((o, i) => Object.keys(o).length === Object.keys(AttributeApplied[i]).length && Object.keys(o).every(k => o[k] === AttributeApplied[i][k]));
                //    //console.log("isSame", isSame)
                //    if (isSame) {
                //        filterSearchData.push(FilterValueDatatable[l])
                //    }
                //}
            }

            //PC_SRpP_01 Bug 2021 Search result not w.r.t attribute, so changed Item ID to clientParnum - Poornima S
            let FinalAttriFilter = filterSearchData.filter((v, i, a) => a.findIndex(t => (t.clientPartNumber === v.clientPartNumber || t.manfPartNumber === v.manfPartNumber)) === i)

            //for (k = 0; k < AttributeApplied.length; k++) {
            //    filterAppliedL = 1
            //    
            //    for (l = 0; l < FilterValueDatatable.length; l++) {
            //        if (AttributeApplied[k] == FilterValueDatatable[l].UNSPSCCategory) {
            //            filterSearchData.push(FilterValueDatatable[l])
            //        }
            //    }
            //}
            FilterValueDatatable = FinalAttriFilter
        }        
        BindGrid = FilterValueDatatable.filter((val, idx) => idx < this.state.itemperpage)
        PageCountList = this.getpagecount(FilterValueDatatable.length, this.state.itemperpage)
        let showpaginationL = false
        if (PageCountList > 1) {
            showpaginationL = true
        }
        this.setState({
            FilterValuedata: FilterValueDatatable, FilteredSearchResults: BindGrid, pageCount: PageCountList, filterApplied: filterAppliedL, TotalItemCount: FilterValueDatatable.length,
            filterAppliedType: filterText, filterValue: FilterType, showpagination: showpaginationL, pageNo: 1
        })
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    FilterType = (FilterType, FilterText) => {
        debugger;
        this.setState({ FilterValue: FilterType, filterAppliedType: FilterText })
        //let ShowSample = $('#customCheckInfo').is(":checked") ? true : false
        //let searchvalue = $('#searchTb').val()
        //let ManuFilter = []
        //ManuFilter = this.state.Manuchecked
        //let AttriFilter = []
        //AttriFilter = this.state.Attrichecked
        //this.ApplyFilter(searchvalue, FilterType, ManuFilter, AttriFilter, ShowSample)
    }
    Search = () => {

        let ShowSample = $('#customCheckInfo').is(":checked") ? true : false
        let searchvalue = $('#searchTb').val()
        let FilterType = this.state.FilterValue
        let ManuFilter = []
        ManuFilter = this.state.Manuchecked
        let AttriFilter = []
        AttriFilter = this.state.Attrichecked
        let supplierfilter = []
        supplierfilter = this.state.SupplierChecked
        this.ApplyFilter(searchvalue, FilterType, ManuFilter, AttriFilter, supplierfilter, this.state.StoreRoom, this.state.MarketPlace)
    }
    onChangeSearch = (e) => {
        this.setState({ searchTxt: e.target.value })
    }
    ClearSearch = () => {
        this.setState({ searchTxt: "" })
    }
    AnykeyPress(e) {
        debugger
        if (e.keyCode == 13) {
            this.Search();
        }
    };
    handlePageClick = (data) => {

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let pageNo = data.selected + 1;
        let itemperpageval = this.state.itemperpage
        console.log("pageNo", pageNo)
        let PageLSearchResults = this.state.PageLoadSearchResults
        let FilterValuedataL = this.state.FilterValuedata
        let startpagecount = 0
        let PageCountList = 0
        startpagecount = (pageNo - 1) * itemperpageval
        let endcount = 0
        endcount = (pageNo * itemperpageval) - 1
        let filterSearchData = []
        let ItemTotalCOunt = 0
        let filterAppliedvalue = this.state.filterApplied
        if (filterAppliedvalue == 0) {
            filterSearchData = PageLSearchResults.filter((val, idx) => idx >= startpagecount && idx <= endcount)
            ItemTotalCOunt = PageLSearchResults.length
            PageCountList = this.getpagecount(ItemTotalCOunt, itemperpageval)
        }
        else {
            filterSearchData = FilterValuedataL.filter((val, idx) => idx >= startpagecount && idx <= endcount)
            ItemTotalCOunt = FilterValuedataL.length
            PageCountList = this.getpagecount(ItemTotalCOunt, itemperpageval)
        }
        let showpaginationL = false
        if (PageCountList > 1) {
            showpaginationL = true
        }
        this.setState({ FilteredSearchResults: filterSearchData, pageNo: pageNo, pageCount: PageCountList, TotalItemCount: ItemTotalCOunt, showpagination: showpaginationL })

        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    };
    getpagecount = (arraycount, itemperpage) => {
        let PageCountList = 0
        let itemperpageval = itemperpage
        PageCountList = Math.floor(arraycount / itemperpageval)
        console.log(PageCountList)
        let remainder = arraycount % itemperpageval
        console.log(remainder)
        let Pagecount = 0
        Pagecount = parseInt(PageCountList.toFixed(0))
        console.log(Pagecount)
        PageCountList = remainder > 0 ? PageCountList + 1 : PageCountList
        console.log(PageCountList)
        return (PageCountList)
    }
    handlePageCOunt = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let itemperpageval = $('#itemperpagecount').val()
        let pagenum = this.state.pageNo
        let ItemTotalCOunt = 0
        let PageLSearchResults = this.state.PageLoadSearchResults
        let FilterValuedataL = this.state.FilterValuedata
        let startpagecount = 0
        let PageCountList = 0
        startpagecount = 0
        let endcount = 0
        endcount = itemperpageval
        let filterSearchData = []
        let filterAppliedvalue = this.state.filterApplied
        if (filterAppliedvalue == 0) {
            filterSearchData = PageLSearchResults.filter((val, idx) => idx < itemperpageval)
            ItemTotalCOunt = PageLSearchResults.length
            PageCountList = this.getpagecount(ItemTotalCOunt, itemperpageval)
        }
        else {
            filterSearchData = FilterValuedataL.filter((val, idx) => idx < itemperpageval)
            ItemTotalCOunt = FilterValuedataL.length
            PageCountList = this.getpagecount(ItemTotalCOunt, itemperpageval)
        }
        let showpaginationL = false
        if (PageCountList > 1) {
            showpaginationL = true
        }
        this.setState({ FilteredSearchResults: filterSearchData, pageNo: 1, itemperpage: itemperpageval, pageCount: PageCountList, TotalItemCount: ItemTotalCOunt, showpagination: showpaginationL })
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    OnCheckTree = checked => {
        this.setState(
            { checked },
            () => console.log(`SelectTree:`, this.state.checked)
        );
    }
    OnCheckManuTree = Manuchecked => {
//[08/04/2023, PS_ZE_171_APP_07 - to display select all unselect all based on count, Sathis.N]
        let countFilter = ""
        let selectAll = this.state.selectAllMfg;
        if (Manuchecked.length > 0) {
            countFilter = Manuchecked.length;
        }
        if (this.state.Manufacture.length == countFilter) {
            selectAll = false;
        }
        else {
            selectAll = true;
        }
        this.setState(
            { Manuchecked, manufactureCount: countFilter, selectAllMfg: selectAll },
            () => console.log(`SelectTree:`, this.state.Manuchecked)
        );
        let ShowSample = $('#customCheckInfo').is(":checked") ? true : false
        let searchvalue = $('#searchTb').val()
        let FilterType = this.state.FilterValue
        let ManuFilter = []
        ManuFilter = Manuchecked
        let AttriFilter = []
        AttriFilter = this.state.Attrichecked
        let supplierfilter = []
        supplierfilter = this.state.SupplierChecked
        this.ApplyFilter(searchvalue, FilterType, ManuFilter, AttriFilter, supplierfilter, this.state.StoreRoom, this.state.MarketPlace)
    }
    OnCheckAttriTree = Attrichecked => {
//[08/04/2023, PS_ZE_171_APP_08 - to display select all unselect all based on count, Sathis.N]
        let countFilter = "";
        let selectAll = this.state.selectAllAtt;
        if (Attrichecked.length > 0) {
            countFilter = Attrichecked.length;
        }

        if (this.state.attributeChildCount == countFilter) {
            selectAll = false;
        }
        else {
            selectAll = true;
        }
        this.setState(
            { Attrichecked, attributeCount: countFilter, selectAllAtt: selectAll },
            () => console.log(`SelectTree:`, this.state.Attrichecked)
        );
        console.log("Attrichecked", Attrichecked)
        let ShowSample = $('#customCheckInfo').is(":checked") ? true : false
        let searchvalue = $('#searchTb').val()
        let FilterType = this.state.FilterValue
        let ManuFilter = []
        ManuFilter = this.state.Manuchecked
        let AttriFilter = []
        AttriFilter = Attrichecked
        let supplierfilter = []
        supplierfilter = this.state.SupplierChecked
        this.ApplyFilter(searchvalue, FilterType, ManuFilter, AttriFilter, supplierfilter, this.state.StoreRoom, this.state.MarketPlace)
    }
    OnCheckSupplierTree = SupplierChecked => {
//[08/04/2023, PS_ZE_171_APP_09 - to display select all unselect all based on count, Sathis.N]
        let countFilter = "";
        let selectAll = this.state.selectAllSup;
        if (SupplierChecked.length > 0) {
            countFilter = SupplierChecked.length;
        }

        if (this.state.SupplierTree.length == countFilter) {
            selectAll = false;
        }
        else {
            selectAll = true;
        }
        this.setState(
            { SupplierChecked, supplierCount: countFilter, selectAllSup: selectAll },
            () => console.log(`SelectTree:`, this.state.SupplierChecked)
        );

        let ShowSample = $('#customCheckInfo').is(":checked") ? true : false
        let searchvalue = $('#searchTb').val()
        let FilterType = this.state.FilterValue
        let ManuFilter = []
        ManuFilter = this.state.Manuchecked
        let AttriFilter = []
        AttriFilter = this.state.Attrichecked
        let supplierfilter = []
        supplierfilter = SupplierChecked
        this.ApplyFilter(searchvalue, FilterType, ManuFilter, AttriFilter, supplierfilter, this.state.StoreRoom, this.state.MarketPlace)
    }
    //[3/17/2023, PS_SR_URL_APP_10 - To remove catalog look up tree, Dhevanesam R]
    //CHeckAll = (type) => {
    //    let checkedvalue = []
    //    let TradeTree = this.state.PageloadcatalogTree
    //    if (type == 1) {
    //        if (TradeTree != null) {
    //            TradeTree.map((val, index) => {
    //                checkedvalue.push(val.FIELDNAME)
    //            });
    //        }
    //    }
    //    this.setState({ checked: checkedvalue })
    //}
    ApplyCatagoryFilter = () => {
        catagoryfilterApplied = 1
        this.LoadSearchResults()
        $('#searchTb').val("")
    }




    Addtocart = (index, isRTI) => {
        const { t } = this.props;

        let OrderNonCatalogItemL = this.state.FilteredSearchResults[index];
        let ItemDetails = {
            ItemId: OrderNonCatalogItemL.clientPartNumber,
            txtSupplier: OrderNonCatalogItemL.supplierName,
            txtSupplierID: OrderNonCatalogItemL.vendor_id,
            txtSuppPartNum: OrderNonCatalogItemL.supplier_part_number,
            txtEstPrice: OrderNonCatalogItemL.ItemPrice.toLowerCase().trim() == "price on request" ? 0 : OrderNonCatalogItemL.ItemPrice,
            txtDesc: OrderNonCatalogItemL.Description,
            txtMfgPartNum: OrderNonCatalogItemL.manfPartNumber,
            txtMfgName: OrderNonCatalogItemL.manufacturer,
            txtQty: OrderNonCatalogItemL.QuantityOrdered,
            image_url: OrderNonCatalogItemL.ThumbnailImagefile,
            DropUOM: OrderNonCatalogItemL.UOM,
            rblcatalogVisible: false,
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            filePath: null,
            EMPCC: "",
            DUeDate: null,
            Type: "",
            MachineNo: "",
            Notes: "",
            Priority: "",
            ItemUNSPSC: OrderNonCatalogItemL.unspsc != "" ? OrderNonCatalogItemL.unspsc : "",
            sdicat: OrderNonCatalogItemL.UNSPSCCategory != "" ? OrderNonCatalogItemL.UNSPSCCategory : "",
            SAP_ARTCILE_NUMBER: OrderNonCatalogItemL.customer_part_number_woprefix
        }
        if (OrderNonCatalogItemL) {
            if (isRTI) {
                $(`#${SDIConstants.SDIRTILoader}`).removeClass('hide');
                $("#SDIRTILoader-text1").removeClass('hide');
                $("#SDIRTILoader-text2").addClass('hide');
                let timerId;
                percent = 0;
                $('#pay').attr('disabled', true);
                $('#load').css('width', '0px');
                timerId = setInterval(function () {
                    percent += 5;
                    $('#load').css('width', percent + '%');
                    if (percent >= 90) {
                        clearInterval(timerId);
                        $('#pay').attr('disabled', false);
                    }
                    if (percent == 40) {
                        $("#SDIRTILoader-text1").addClass('hide');
                        $("#SDIRTILoader-text2").removeClass('hide');
                    }
                }, 400);
                $("#search-results-component").addClass('hide');
            } else {
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            }
            let Addcartbo = {
                ItemDetails: [],
                NonItemDetails: [ItemDetails],
                Session_BUSUNIT: this.state.Login_UserBU,
                Session_USERID: this.state.Login_UserId,
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
                Session_ImageLocPath: "",
                Session_PUNCHIN: "False",
                IsRTI: isRTI,
                CurrentLatitude: CurrentLatitude,
                CurrentLongitude: CurrentLongitude,
                Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
                Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "

            }
            this.setState({ isRTI: isRTI, selectedItemIndex: index })
            SearchResultsAction.AddCart(Addcartbo)
        }
    }
    // ZEUS - 297 - method to pause the RTI loader for few seconds - Johnprinto D
    delayedExecution = async () => {
        let pauseTime = percent <= 60 ? 3000 : 0;
        await new Promise(resolve => setTimeout(resolve, pauseTime));
    }
    AddtocartSuccess = async () => {
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        debugger
        const { t } = this.props;
        let Status = JSON.parse(SearchResultsStore.SuccessStatus);
        if (this.state.isRTI) {
            // ZEUS - 297 - method to pause the RTI loader for few seconds - Johnprinto D
            await this.delayedExecution();
            $(`#${SDIConstants.SDIRTILoader}`).addClass('hide');
            $("#SDIRTILoader-text1").removeClass('hide');
            $("#SDIRTILoader-text2").addClass('hide');
            $("#search-results-component").removeClass('hide');
        } else {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        if ((Status.length > 0 && Status[0].hasOwnProperty("RTILocation") && JSON.parse(Status[0].RTILocation) != "")) {
            CookieService.updateLocalStorageValues("session_RTIStore", JSON.parse(Status[0].RTILocation));
            CookieService.updateLocalStorageValues("RTI_Item_Data", this.state.FilteredSearchResults[this.state.selectedItemIndex]);
            CookieService.updateLocalStorageValues("RTI_BackScreen", "SearchResults")
            CookieService.updateLocalStorageValues("RTI_BackURL", `\searchresults?key=${this.state.SearchKeyWord}`)

            this.props.history.push({
                pathname: `\RealTimeInv`,
                search: `?qty=${this.state.FilteredSearchResults[this.state.selectedItemIndex].QuantityOrdered}&searchKey=${this.state.SearchKeyWord}`,
                state: { origin: 'SearchResults' }
            });
            return;
        }
        let itemstatus = Status._FlagDt
        //[05/11/2023 - ZEUS-105 - PS_SB_1 - Create an object for invoke getMasterData]
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""

        };
        //[05/11/2023 - ZEUS-105 - PS_SB_2 - Empty the Quentity empty field]
        let FilteredSearch = this.state.FilteredSearchResults;
        FilteredSearch.map(item => { item.QuantityOrdered = "1"; return item });
        //[06/15/2023 - PC_ZEUS_143_APP_05 - Johnprinto D]
        let PageLoadSearch = this.state.PageLoadSearchResults;
        PageLoadSearch.map(item => { item.QuantityOrdered = "1"; return item });
        console.log('OrderNonCatalogItemL', FilteredSearch);
        let iteminsertstatus = Status.SuccessFLag
        if (itemstatus.length > 0) {
            if (itemstatus[0].is_Success == "True" || itemstatus[0].is_Success == "true") {
                CookieService.updateLocalStorageValues("Session_SearchValue", this.state.SearchKeyWord);
                let itemidnotinserted = ""
                if (iteminsertstatus != null && iteminsertstatus != undefined && iteminsertstatus != "") {
                    iteminsertstatus.map((val, index) => {
                        if (val.Status != "true") {
                            if (itemidnotinserted != "") {
                                itemidnotinserted = itemidnotinserted + "," + val.ItemId
                            }
                            else {
                                itemidnotinserted = itemidnotinserted + val.ItemId
                            }
                        }
                    });
                }
                //[12/28/21, CAS - 31, Dhevanesam R]
                if (itemidnotinserted != null && itemidnotinserted != undefined && itemidnotinserted != "") {
                    //alert(t("Item ") + itemidnotinserted + t(" is not added to cart due to technical issue. Contact SDI customer care."))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Item ") + itemidnotinserted + t(" is not added to cart due to technical issue. Contact SDI customer care.")
                    })
                }
                //[05/11/2023 - ZEUS-105 - PS_SB_3 - Refresh the shopping cart data and display the alert message]
                MenuAction.getMasterData(masterBO);
                //[06/15/2023 - PC_ZEUS_143_APP_06 - Johnprinto D]
                if (this.state.isRTI) {
                    this.toastMessage("rti-unsuccess-toast");
                } else {
                    this.toastMessage("success-toast");
                }
                this.setState({ FilteredSearchResults: FilteredSearch, PageLoadSearchResults: PageLoadSearch, OrderNonCatalogItem: [], isRTI: false });
            }
            else {
                CookieService.updateLocalStorageValues("Session_SearchValue", this.state.SearchKeyWord);
                let itemidnotinserted = ""
                let validate = false
                if (iteminsertstatus != null && iteminsertstatus != undefined && iteminsertstatus != "") {
                    iteminsertstatus.map((val, index) => {
                        if (val.Status != "true") {
                            if (itemidnotinserted != "") {
                                itemidnotinserted = itemidnotinserted + "," + val.ItemId
                            }
                            else {
                                itemidnotinserted = itemidnotinserted + val.ItemId
                            }
                        }
                        else if (validate != true && val.Status == "true") {
                            validate = true
                        }
                    });
                }
                //[12/28/21, CAS - 31, Dhevanesam R]
                if (itemidnotinserted != null && itemidnotinserted != undefined && itemidnotinserted != "") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Item ") + itemidnotinserted + t(" is not added to cart due to technical issue. Contact SDI customer care.")
                    })
                    //alert(t("Item ") + itemidnotinserted + t(" is not added to cart due to technical issue. Contact SDI customer care."))
                }
                if (validate == true) {
                    //[05/11/2023 - ZEUS-105 - PS_SB_4 - Refresh the shopping cart data and display the alert message]
                    MenuAction.getMasterData(masterBO);
                    //[06/15/2023 - PC_ZEUS_143_APP_07 - Johnprinto D]
                    this.setState({ FilteredSearchResults: FilteredSearch, PageLoadSearchResults: PageLoadSearch, OrderNonCatalogItem: [] , isRTI : false});
                    if(this.state.isRTI){
                        this.toastMessage("rti-unsuccess-toast");
                    }else{
                        this.toastMessage("success-toast");
                    }
                
                }
                else if (itemidnotinserted == null || itemidnotinserted == undefined || itemidnotinserted == "") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Technical Issue Contact SDI Customer Care")
                    })
                    //alert(t("Technical Issue Contact SDI Customer Care"))
                }
            }
        }
    }
    BindFavForm = (e, favexist, Itemtype) => {
        let FilteredSearch = this.state.FilteredSearchResults
        let SelectedValue = []
        SelectedValue = FilteredSearch[e.target.id]
        console.log("SelectedValue", SelectedValue)
        let Itemtypevalue = Itemtype == "" || Itemtype == null ? "CAT" : "NONCAT"
        if (!favexist) {
            if (SelectedValue != [] && SelectedValue != null) {
                let FavSDICatg = SelectedValue.UNSPSCCategory == null || SelectedValue.UNSPSCCategory == "" || SelectedValue.UNSPSCCategory == " " ? "UnClassified" : SelectedValue.UNSPSCCategory;
                this.setState({
                    /*[10/28/21, PC_SR_01 Bug-1827], Priyanka D*/
                    FavItemIdPopUp: SelectedValue.supplierName == "AMAZON" ? SelectedValue.CustomColItemID : SelectedValue.clientPartNumber, FavSDICatgPopUp: FavSDICatg,
                    FavItemDescripPopUp: SelectedValue.Description, FavMFGPopUp: SelectedValue.manufacturer,
                    FavMFGPartNUMPopUp: SelectedValue.manfPartNumber, txtUOMHidePopUp: SelectedValue.UOM,
                    txtMfgItemidHidePopUp: SelectedValue.manfPartNumber, Supplier: SelectedValue.supplierName, ItemUnitPrice: SelectedValue.ItemPrice,
                    txtItemidHidePopUp: SelectedValue.Id, txtMFGHidePopUp: SelectedValue.vendor_id, ItemType: Itemtypevalue, FavitemImage: SelectedValue.ThumbnailImagefile, FavSAP: SelectedValue.customer_part_number_woprefix
                })
                document.getElementById("txtUOMHide").hidden = true
                document.getElementById("txtMfgItemidHide").hidden = true
                document.getElementById("txtItemidHide").hidden = true
                document.getElementById("txtMFGHide").hidden = true
                document.getElementById("ItemType").hidden = true
                $('#add-fav-popup').modal('show');
                $('#FavYourCatg').val('');
                $('#FavYourDescrip').val('');
                //[PS_FE_GC_08, Zeus 94, Kishore] - Fetching the category and subcategory values from the cookie
                let allCategory = []
                let categories = CookieService.getLocalStorageValues("FavItem_Cat")
                if (categories != null && categories != "" && categories != undefined) {
                    categories.map((val, index) => {
                        allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
                    });
                    allCategory = allCategory.sort((a, b) =>
                        a.value > b.value ? 1 : -1,
                    );
                }
                debugger;
                let subCategory = []
                let allSubCategory = []
                let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
                if (subCategories != null && subCategories != "" && subCategories != undefined) {
                    subCategories.map((val, index) => {
                        if (val.ISA_FAVS_SUBCATEGORY != null) {
                            subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                        }
                    });
                }
                if (subCategory.length > 0) {
                    allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
                    allSubCategory = allSubCategory.sort((a, b) =>
                        a.value > b.value ? 1 : -1,
                    );
                }
                this.setState({ category: allCategory, bindCategory: allCategory, categoryError: false, subCategoryError: false, subCategory: allSubCategory, bindSubCategory: [], yourFavCat: "", yourFavSubCat: "" });

                
            }
        }
        else {
            debugger;
            let deleteFavBO = {
                ItemId: SelectedValue.clientPartNumber,
                Supplier_ID: SelectedValue.vendor_id,
                MFG: SelectedValue.manufacturer,
                MFGPartNo: SelectedValue.manfPartNumber,
                Session_USERID: this.state.Login_UserId,
                Session_BUSUNIT: this.state.Login_UserBU
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            FavItemMainAction.DeletFavItem(deleteFavBO);
        }
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    //[Zeus 172, 08/23/2023] - Item Unfavorite response - Kishore
    DelFavResponse = () => {
        const { t } = this.props;
        let Response = JSON.parse(FavItemMainStore.PageLoadData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

        if (Response.ErrorTable[0].error != "True") {

            if (Response.ResultFlag[0].DeleteResult == "True") {

                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Favorite item removed successfully")
                });
                MenuAction.refreshMasterData();
                this.setState({ FavoritesSubMenu: Response.FavSubMenu })
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Technical Issue Try again")
                });
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical Issue Try again")
            });
        }
    }
    AddfavItem = () => {
        debugger
        let itemtypevalue = this.state.ItemType
        let ItemDescrip = $('#FavYourDescrip').val()
        let ItemCatagory = this.state.yourFavCat;
        let ItemSubCategory = this.state.yourFavSubCat;
        if (itemtypevalue == "CAT") {
            //[PS_FE_GC_15 , Zeus 94, Kishore ] - Category and sub category validation
            if (ItemCatagory != null && ItemCatagory != undefined && ItemSubCategory != null && ItemSubCategory != undefined) {
                if (ItemCatagory.trim() != "" && ItemSubCategory.trim() != "") {
                    let FavItemBo = {
                        Session_BUSUNIT: this.state.Login_UserBU,
                        Session_USERID: this.state.Login_UserId,
                        Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                        Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                        Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                        Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                        Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                        Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                        Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                        Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                        Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                        Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                        Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                        Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                        Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                        Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                        Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
                        ItemId: this.state.FavItemIdPopUp == null || this.state.FavItemIdPopUp == undefined || this.state.FavItemIdPopUp == "" || this.state.Supplier == "AMAZON" ? "NONCAT-01" : this.state.FavItemIdPopUp,
                        YourDesc: ItemDescrip != " " && ItemDescrip != "" ? ItemDescrip.toUpperCase() : "",
                        YourCat: ItemCatagory != " " && ItemCatagory != "" ? ItemCatagory.toUpperCase() : "",
                        YourSubCat: ItemSubCategory != " " && ItemSubCategory != "" ? ItemSubCategory.toUpperCase() : "",
                        SDI_Catagory: this.state.FavSDICatgPopUp,
                        txtItemidHide: this.state.txtItemidHidePopUp,
                        Supplier_ID: this.state.txtMFGHidePopUp != undefined && this.state.txtMFGHidePopUp != null ? this.state.txtMFGHidePopUp : "",
                        Manufacturer: this.state.FavMFGPopUp != undefined && this.state.FavMFGPopUp != null ? this.state.FavMFGPopUp : "",//[06/29/2023, Zeus-94]- Kishore S - Checking supplier ID while adding to fav in Search result
                        txtUOMHide: this.state.txtUOMHidePopUp,
                        txtMfgItemidHide: this.state.txtMfgItemidHidePopUp,
                        SAP: this.state.FavSAP,
                        Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[10/29/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                        Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")
                    }
                    SearchResultsAction.catfavItem(FavItemBo)
                    $('#add-fav-popup').modal('hide');
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                }
                else {
                    this.setState({ categoryError: ItemCatagory.trim() == "" ? true : false, subCategoryError: ItemSubCategory.trim() == "" ? true : false });
                }
            }
            else {
                this.setState({ categoryError: ItemCatagory == null || ItemCatagory == undefined ? true : false, subCategoryError: ItemSubCategory == null || ItemSubCategory == undefined ? true : false });
            }
        }
        else {
            if (ItemCatagory.trim() != "" && ItemCatagory != null && ItemCatagory != undefined) {
                let FavItemBo = {
                    ItemIdType: "NONCAT",
                    YourDesc: ItemDescrip,
                    YourCat: ItemCatagory,
                    Item_Description: this.state.FavItemDescripPopUp,
                    Item_MFG: this.state.FavMFGPopUp,
                    Session_BUSUNIT: this.state.Login_UserBU,
                    Session_USERID: this.state.Login_UserId,
                    MFG_PartNumber: this.state.FavMFGPartNUMPopUp,
                    img_name: this.state.FavitemImage,
                    UOM: this.state.txtUOMHidePopUp,
                    supplierId: this.state.txtMFGHidePopUp,
                    supplierPartNo: this.state.txtMfgItemidHidePopUp,
                    UnitPrice: this.state.ItemUnitPrice != null && this.state.ItemUnitPrice != undefined && this.state.ItemUnitPrice != 0 && this.state.ItemUnitPrice != "0" && this.state.ItemUnitPrice != "" ? this.state.ItemUnitPrice : "Price on request",
                    SDI_Catagory: this.state.FavSDICatgPopUp,
                    SAP: this.state.FavSAP
                }
                SearchResultsAction.NoncatfavItem(FavItemBo)
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            }
            else {
                this.setState({ categoryError: true });
            }
        }
    }
    FavSuccess = () => {
        debugger
        const { t } = this.props;
        let Status = JSON.parse(SearchResultsStore.FavitemAddList);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        console.log("Status", Status)
        let Favstatus = Status.ErrorMsg
        if (Favstatus.length > 0) {
            if (Favstatus[0].Error_Msg == "Favorite item inserted sucessfully") {
                //e.preventDefault();
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Favorite item inserted successfully"
                });
                //alert(t("Favorite item inserted sucessfully"))
                let favsub = Status.FavSubMenu
                MenuAction.refreshMasterData();
            
                this.setState({ FavoritesSubMenu: favsub, yourFavCat:"",yourFavSubCat:"" });
                
            }
            else {
                //e.stopImmediatePropagation();
                //e.preventDefault();
                //[PC_SR_01, 05/26/22, CAS - Adding items as fav issue, Dhevanesam R]
                if (Favstatus[0].Validation_Error_Msg != "" && Favstatus[0].Validation_Error_Msg != " " && Favstatus[0].Validation_Error_Msg != null) {
                    //alert(t(Favstatus[0].Validation_Error_Msg))
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t(Favstatus[0].Validation_Error_Msg)
                    })
                }
                else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Technical Issue Try again")
                    })
                    //alert(t("Technical Issue Try again"));
                }
                
            }
        }
    }
    showitemdetails = (index, ItemId, Itemtype, FavItem, event) => {

        if (event) {
            event.preventDefault();
        }

        let FilteredSearch = this.state.FilteredSearchResults
        let SelectedValue = []
        SelectedValue = FilteredSearch[index]
        let Itemtypevalue = Itemtype == "" || Itemtype == null ? "CAT" : "NONCAT"
        let Manufactpartnum = SelectedValue.manfPartNumber
        let supID = SelectedValue.vendor_id;
        CookieService.updateLocalStorageValues("Session_Noncat", Itemtypevalue);
        if (Itemtypevalue != "NONCAT") {
            CookieService.updateLocalStorageValues("Search_ItemFav", FavItem);
            CookieService.updateLocalStorageValues("Site_Currency", this.state.Site_Currency);
            CookieService.updateLocalStorageValues("Site_Currency", this.state.Site_Currency);
            CookieService.updateLocalStorageValues("Item_Data", encodeURIComponent(JSON.stringify(SelectedValue)));
            ItemId = encodeURIComponent(ItemId);
            Manufactpartnum = encodeURIComponent(Manufactpartnum);
            supID = encodeURIComponent(supID);
            //[3/17/2023, PS_SR_URL_APP_12 - To remove catalog look up tree, Dhevanesam R]
            this.props.history.push({
                pathname: `\itemdetailnew`,
                search: `?ItemId=${ItemId}&ManufacturePartNumber=${Manufactpartnum}&SupplierID=${supID}`,
                //&CatItemID=${this.state.CattreeItemID}`,
                state: { origin: 'SearchResults' }//[7/14/2022] Pasing origin and SelectedValue as props data to item details page to bind them without API call
            });
        }
        else {
            CookieService.updateLocalStorageValues("Site_Currency", this.state.Site_Currency);
            CookieService.updateLocalStorageValues("Search_ItemFav", FavItem);
            CookieService.updateLocalStorageValues("Search_supplierName", SelectedValue.supplierName);
            CookieService.updateLocalStorageValues("Search_lblproductidtext", SelectedValue.CustomColItemID);
            CookieService.updateLocalStorageValues("Search_lblManufacturerName", SelectedValue.manufacturer);
            CookieService.updateLocalStorageValues("Search_lblFeaturestext", SelectedValue.Description);
            CookieService.updateLocalStorageValues("Search_txtClientPartNumber", SelectedValue.CustomColItemID);
            CookieService.updateLocalStorageValues("Search_lblDescriptionText", SelectedValue.longdescriptionone);
            CookieService.updateLocalStorageValues("Search_AttributeName", SelectedValue.AttributeName);
            CookieService.updateLocalStorageValues("Search_AttributeValue", SelectedValue.AttributeValue);
            CookieService.updateLocalStorageValues("Search_lblClientDesc", SelectedValue.clientDesc);
            CookieService.updateLocalStorageValues("Search_lblUOM", SelectedValue.UOM);
            CookieService.updateLocalStorageValues("Search_lblCategory", SelectedValue.UNSPSCCategory);
            CookieService.updateLocalStorageValues("Search_lblManufacturerPartNumber", SelectedValue.manfPartNumber);
            CookieService.updateLocalStorageValues("Search_UNSPSC", SelectedValue.unspsc);
            CookieService.updateLocalStorageValues("Search_QuantiyOnHand", SelectedValue.CustomColQOH);
            CookieService.updateLocalStorageValues("Search_ThumbnailImage", SelectedValue.ThumbnailImagefile);
            CookieService.updateLocalStorageValues("Search_lblCurrencyCode", SelectedValue.CurrencyCd);
            CookieService.updateLocalStorageValues("Search_btnShowExtndOraDesc", SelectedValue.ExtndDesc);
            CookieService.updateLocalStorageValues("Search_lblpricetext", SelectedValue.ItemPrice);
            CookieService.updateLocalStorageValues("Search_VendorId", SelectedValue.vendor_id);
            CookieService.updateLocalStorageValues("Search_Supplieritemid", SelectedValue.supplier_part_number);
            this.props.history.push({
                pathname: `\itemdetailnew`,
                search: `?ItemId=${ItemId}&ManufacturePartNumber=${Manufactpartnum}`,
                state: { ItemData: SelectedValue }
            });
        }
    }
    showsample = () => {
        let ShowSample = $('#customCheckInfo').is(":checked") ? true : false
        this.setState({
            ShowSampleMessage: ShowSample
        })
        let searchvalue = $('#searchTb').val()
        let FilterType = this.state.FilterValue
        let ManuFilter = []
        ManuFilter = this.state.Manuchecked
        let AttriFilter = []
        AttriFilter = this.state.Attrichecked
        let supplierfilter = []
        supplierfilter = this.state.SupplierChecked
        this.ApplyFilter(searchvalue, FilterType, ManuFilter, AttriFilter, supplierfilter, this.state.StoreRoom, this.state.MarketPlace)

    }
    marketPlaceChange = (Type) => {
        debugger
        let StoreRoom = this.state.StoreRoom
        let MarketPlace = this.state.MarketPlace
        if (Type == 0) {
            if (StoreRoom != true) {
                StoreRoom = true
            }
            else {
                StoreRoom = false
            }
        }
        else {
            if (MarketPlace != true) {
                MarketPlace = true
            }
            else {
                MarketPlace = false
            }

        }
        this.setState({ StoreRoom: StoreRoom, MarketPlace: MarketPlace })
        let ShowSample = $('#customCheckInfo').is(":checked") ? true : false
        let searchvalue = $('#searchTb').val()
        let FilterType = this.state.FilterValue
        let ManuFilter = []
        ManuFilter = this.state.Manuchecked
        let AttriFilter = []
        AttriFilter = this.state.Attrichecked
        let supplierfilter = []
        supplierfilter = this.state.SupplierChecked
        this.ApplyFilter(searchvalue, FilterType, ManuFilter, AttriFilter, supplierfilter, StoreRoom, MarketPlace)
    }
    descriptioncahange = (Type) => {
        debugger
        if (Type != 1) {
            CatalogType = false
            ClientType = true
        }
        else {
            CatalogType = true
            ClientType = false
        }
        this.setState({
            DescriptionType: Type
        })
    }

    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        MenuAction.getMasterData(masterBO);
    };
    DidyouMeanClick = (key, event) => {
        if (event) {
            event.preventDefault();
        }
        if (key != null && key != "" && key != undefined) {
            this.props.history.push({
                pathname: `\searchresults`,
                search: `?key=${key}`
            });
            this.LoadSearchResults()
        }
    }
    mobAdvfiltershow = (event) => {
        if (event) {
            event.preventDefault();
        }
        let ClassAvailable = document.getElementById("Advancefilterdiv").className;
        if (ClassAvailable == 'col-sm-4 col-md-4 col-lg-3 my-3 mobile-filter') {
            $('#Advancefilterdiv').addClass('mobile-filter-show');

        }
        else {
            $('#Advancefilterdiv').removeClass('mobile-filter-show');
        }

    }
    ShowFIlter = (key) => {
        debugger;
        if (key == 1) {
            let ClassAvailable = document.getElementById("Manufilter").className;
            // $('#ManufilterIcon').toggleClass('collapsed');
            //[10/26/21, PC_06, IE 11 issue, Dhevanesam R]
            /*[11/23/21, PC_SR_01, 1848], Priyanka D*/
            if (ClassAvailable == 'manuFilterCss p-3') {
                $('#Manufilter').addClass('d-none');
                $('#ManufilterIcon').addClass('accordion-heading');
                $('#ManufilterIcon').removeClass('accordian-close');
                $('#headingtwo').removeClass('filter-card-bg');
            }
            else {
                $('#Manufilter').removeClass('d-none');

                $('#ManufilterIcon').removeClass('accordion-heading');
                $('#ManufilterIcon').addClass('accordian-close');
                $('#headingtwo').addClass('filter-card-bg');
                $('#headingthree').removeClass('filter-card-bg');
                $('#headingfour').removeClass('filter-card-bg');
                //[11/23/21, PC_SR_01, CAS - Attribute Pre Filter Accordian Issue, Dhevanesam R]
                if (this.state.AttributeTree.length != 0) {
                    let AttrClass = document.getElementById("AttriFilter").className;
                    if (AttrClass == 'col-md-16 pl-3') {
                        $('#AttriFilter').addClass('d-none'); $('#AttriFilterIcon').addClass('accordion-heading');
                        $('#AttriFilterIcon').removeClass('accordian-close');
                    }
                }
                let Supplierclass = document.getElementById("SupplierFilter").className;
                if (Supplierclass == 'col-md-12 mt-3') {
                    $('#SupplierFilter').addClass('d-none');

                    $('#SupplierFilterIcon').addClass('accordion-heading');
                    $('#SupplierFilterIcon').removeClass('accordian-close');
                }
            }
        }
        else if (key == 2) {
            let ClassAvailable = document.getElementById("AttriFilter").className;
            if (ClassAvailable == 'col-md-16 pl-3') {
                $('#AttriFilter').addClass('d-none');
                $('#AttriFilterIcon').addClass('accordion-heading');
                $('#AttriFilterIcon').removeClass('accordian-close');
                $('#headingthree').removeClass('filter-card-bg');
            }
            else {
                $('#AttriFilter').removeClass('d-none');
                //$('#AttriFilterIcon').removeClass('collapsed');
                $('#AttriFilterIcon').removeClass('accordion-heading');
                $('#AttriFilterIcon').addClass('accordian-close');
                $('#headingthree').addClass('filter-card-bg');
                $('#headingtwo').removeClass('filter-card-bg');
                $('#headingfour').removeClass('filter-card-bg');
                let Manuclass = document.getElementById("Manufilter").className;
                if (Manuclass == 'manuFilterCss p-3') {
                    $('#Manufilter').addClass('d-none');
                    //$('#ManufilterIcon').addClass('collapsed');
                    $('#ManufilterIcon').addClass('accordion-heading');
                    $('#ManufilterIcon').removeClass('accordian-close');
                }
                let Supplierclass = document.getElementById("SupplierFilter").className;
                if (Supplierclass == 'col-md-12 mt-3') {
                    $('#SupplierFilter').addClass('d-none');
                    $('#SupplierFilterIcon').addClass('accordion-heading');
                    $('#SupplierFilterIcon').removeClass('accordian-close');

                }
            }
        }
        else if (key == 3) {
            let ClassAvailable = document.getElementById("SupplierFilter").className;
            if (ClassAvailable == 'col-md-12 mt-3') {
                $('#SupplierFilter').addClass('d-none');
                $('#SupplierFilterIcon').addClass('accordion-heading');
                $('#SupplierFilterIcon').removeClass('accordian-close');
                $('#headingfour').removeClass('filter-card-bg');
            }
            else {
                $('#SupplierFilter').removeClass('d-none');
                $('#SupplierFilterIcon').removeClass('accordion-heading');
                $('#SupplierFilterIcon').addClass('accordian-close');
                $('#headingfour').addClass('filter-card-bg');
                $('#headingthree').removeClass('filter-card-bg');
                $('#headingtwo').removeClass('filter-card-bg');
                let Manuclass = document.getElementById("Manufilter").className;
                if (Manuclass == 'manuFilterCss p-3') {
                    $('#Manufilter').addClass('d-none');
                    $('#ManufilterIcon').addClass('accordion-heading');
                    $('#ManufilterIcon').removeClass('accordian-close');
                }
                let AttrClass = document.getElementById("AttriFilter").className;
                if (AttrClass == 'col-md-16 pl-3') {
                    $('#AttriFilter').addClass('d-none');
                    $('#AttriFilterIcon').addClass('accordion-heading');
                    $('#AttriFilterIcon').removeClass('accordian-close');
                }
            }
        }
    }
    getItemValue(item) {
        return `${item.value}`;
    }
    renderItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );

    }
    //[PS_FE_GC_09, Zeus 94, Kishore] - Onselect event handling for categorya and sub category fields
    onSelect(val, name) {
        debugger;
        let result = [];
        if (name == "CAT") {
            if (val != "") {
                this.state.subCategory.map((x, i) => {
                    if (val.toUpperCase() == x.category) {
                        result.push({ label: x.value, value: x.value })
                    }
                }
                )
            }
            this.setState({
                yourFavCat: val, bindSubCategory: result, categoryError: false, yourFavSubCat: val != this.state.yourFavCat ? "" : this.state.yourFavSubCat
            });
        }
        else {
            this.setState({
                yourFavSubCat: val, subCategoryError: false
            });
        }
    }
    //[PS_FE_GC_11, Zeus 94, Kishore] - OnChange event for handling category and sub-category fields
    onChange = (e, name) => {
        debugger;
        let val = e.target.value.toUpperCase()
        let len = e.target.value
        let category = [];
        let subCategory = [];
        let re = /^[a-zA-Z0-9_-\s\x20 &, :;=?@#|'<>.{}"*()%!`%^$+~[/\]\\]+$/;
        if (val === '' || re.test(val)) {
            if (name == "CAT") {
                if (e.target.value == "") {
                    this.setState({
                        yourFavCat: e.target.value, bindCategory: this.state.category, bindSubCategory: [], yourFavSubCat: ""
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.category != undefined && this.state.category != null) {
                        if (this.state.category.length != 0) {
                            category = this.state.category.filter(name => name.label.toUpperCase().includes(val))

                        }
                    }
                    if (e.target.value != "") {
                        this.state.subCategory.map((x, i) => {
                            if (e.target.value.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
                        yourFavCat: e.target.value, bindCategory: category, bindSubCategory: subCategory, categoryError: false, yourFavSubCat: ""
                    });
                }
            }
            else {
                if (e.target.value == "") {
                    if (this.state.yourFavCat != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.yourFavCat.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
                        yourFavSubCat: e.target.value, bindSubCategory: subCategory
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.yourFavCat != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.yourFavCat.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        }
                        )
                        subCategory = subCategory.filter(name => name.label.toUpperCase().includes(val))
                    }
                    this.setState({
                        yourFavSubCat: e.target.value, bindSubCategory: subCategory, subCategoryError: false
                    });
                }
            }
        }
    }

    WOpopup = () => {
        this.setState({
            WOmsg: ""
        })
        $('#WorkOrder').modal('show');
    }
    //[15/05/23, PS_EWVB_010, Zeus 112, Sathis. N]- handle wo validation on submit click
    WOClick = () => {
        debugger
        let workOrder = this.state.ValidatedWo;
        let blanketWo = CookieService.getLocalStorageValues("blanketWo");
        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
            let EMP_EMAIL = CookieService.getLocalStorageValues("Session_EMP_EMAIL");
            if (EMP_EMAIL == undefined || EMP_EMAIL == null || EMP_EMAIL.trim() == "") {
                EMP_EMAIL = CookieService.getLocalStorageValues("Session_EMAIL");
            }
            let sunetID = EMP_EMAIL.split("@");
            if (workOrder != "" && workOrder != null && workOrder != undefined) {
                if (this.state.disableWo != true) {
                    if (blanketWo == undefined || !blanketWo.includes(workOrder)) {
                        let workOrderBO = {
                            WorkOrder: workOrder,
                            BusinessUnit: this.state.Login_UserBU,
                            sunetID: sunetID[0]
                        }

                        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                        SearchResultsAction.workOrderValidation(workOrderBO);
                    }
                    else {
                        //if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                        if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                this.CancelOrder();
                                this.setState({
                                    clearCart: "true"
                                })
                            }
                        }
                        //}
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workOrderDescription", this.state.workOrderDescription);
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        $('#WorkOrder').modal('hide');
                        this.setState({
                            ValidatedWo: workOrder,
                            stanfordAssetId: "",
                            disableWo: true,
                            WOmsg: ""

                        })
                    }
                }
                
            }
            else {
                //[05/31/2023, PS_ZE_123_APP_07, Dhevanesam R]
                this.setState({ WOmsg: "Please enter work order number" });
            }
        }
        else {
            this.setState({ WOmsg: "", ValidatedWo: workOrder, disableWo: true })
            CookieService.updateLocalStorageValues("validatedWO", workOrder);
            CookieService.updateLocalStorageValues("validWO", workOrder);
            CookieService.updateLocalStorageValues("WOName", "");
            CookieService.updateLocalStorageValues("workOrderDisable", true);
            $('#WorkOrder').modal('hide');
        }
    }
        //[15/05/23, PS_EWVB_14, Zeus 112, Sathis. N]- close select wo popup
    WOClose = () => {
        //[05/31/2023, PS_ZE_123_APP_08, Dhevanesam R]
        //document.getElementById("WONUM").value = this.state.ValidatedWo;
        $('#WorkOrder').modal('hide');
        if (this.state.disableWo == false) {
            this.setState({ ValidatedWo: "" })
        }
    }
        //[15/05/23, PS_EWVB_015, Zeus 112, Sathis. N]- clear values in wo field
    ClearWO = () => {
//[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
        CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
        CookieService.updateLocalStorageValues("validWO", "clearWO");
        CookieService.updateLocalStorageValues("WOName", "");
        CookieService.updateLocalStorageValues("stanfordAssetId", "");
        CookieService.updateLocalStorageValues("workOrderDescription", "");
        CookieService.updateLocalStorageValues("workOrderDisable", false);
        //$(`#WONUM`).val('');
        this.setState({
            ValidatedWo: "", stanfordAssetId: "", disableWo: false, workOrderDescription: ""
        });
    }
    //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
    CancelOrder = () => {
        debugger
        let cancelorderBo = {
            Session_UserID: this.state.Login_UserId,
            Session_BUSUNIT: this.state.Login_UserBU,
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        ShoppingCartAction.CancelOrderItem(cancelorderBo);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    COStatus = () => {
        const { t } = this.props;
        if (this.state.Login_UserBU.toUpperCase() != "I0631") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        else {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
        let CancelResponse = JSON.parse(ShoppingCartStore.CancelOrderMsge);
        if (CancelResponse == null || CancelResponse == "" || CancelResponse == " ") {
            this.refreshMenu();
            //[06/15/2023 - PC_ZEUS_143_APP_04 - Johnprinto D]
            let FilteredSearch = this.state.FilteredSearchResults;
            FilteredSearch.map(item => { item.QuantityOrdered = ""; return item });
            this.setState({ OrderNonCatalogItem: [], FilteredSearchResults: FilteredSearch })
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Issue in clearing the cart")
            });
        }
    }
    //[6/2/2023, Zeus - 75 Tech orders for US Bank Work Orders can order from Diversified Supply, Dhevanesam R]
    woValidateResponse = () => {
        debugger
        const { t } = this.props;
        //let workOrder = $('#WONUM').val()
        let workOrder = this.state.ValidatedWo;
        let woValidationData = SearchResultsStore.woValidationData;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (woValidationData != undefined && woValidationData != null && woValidationData != "" && woValidationData != "null") {
            if (woValidationData != "error occured in server" && woValidationData != "error occured" && woValidationData != "authorization required"
                && woValidationData != "internal server error" && woValidationData != "invalid") {
                let responeData = JSON.parse(SearchResultsStore.woValidationData);
                debugger
                if (responeData[0].WOVALIDATE != null && responeData[0].WOVALIDATE != "") {
                    if (responeData[0].WOVALIDATE == "valid") {
                        //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
                        //if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                        if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                this.CancelOrder();
                                }
                            }
                        //}
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].WONAME);
                        CookieService.updateLocalStorageValues("workorderLocation", responeData[0].LOCATION);
                        if (this.state.Login_UserBU.toUpperCase() == "I0631") {
                            debugger
                            let OrderNonCatalogItemL = [];
                            CookieService.updateLocalStorageValues("validWO", workOrder);
                            CookieService.updateLocalStorageValues("WOName", responeData[0].WONAME);
                            //[11/30/2023 - Zeus 237 - Show supplier on search and punchout based on table value - Johnprinto]

                            let rmvndr = responeData.map(function (obj) {
                                return obj.REMOVE_VNDR_ID;
                            });
                            CookieService.updateLocalStorageValues("RemoveVNDR", rmvndr);
                            if (this.state.boolWOEmpty == true) {
                                if (CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null) {

                                    //if (CookieService.getLocalStorageValues("WOName").trim().toUpperCase() == "US BANK") {
                                    let rmvndrID = "";
                                    let rmvndrName = "";
                                    if (this.state.OrderNonCatalogItem != null && this.state.OrderNonCatalogItem != undefined) {
                                            this.state.OrderNonCatalogItem.forEach((value, index) => {
                                                if (!rmvndr.includes(value.txtSupplierID.trim().toLowerCase())) {
                                                    OrderNonCatalogItemL.push(value);
                                                } else if (rmvndrID == "") {
                                                    rmvndrID = value.txtSupplierID.trim().toLowerCase();
                                                    rmvndrName = value.txtSupplier.trim();
                                                }
                                            });
                                        }

                                    if (OrderNonCatalogItemL.length != this.state.OrderNonCatalogItem.length) {
                                        let errorMsg = ""
                                        if (rmvndrID == "em00000001") {
                                            errorMsg = "Unable to submit Grainger item using your current work order number";
                                        } else if (rmvndrID == "em00000026") {
                                            errorMsg = "Unable to submit Diversified item using your current work order number";
                                        } else {
                                            errorMsg = "Unable to submit " + rmvndrName + "  supplier item using your current work order number";
                                        }
                                            swal({
                                                allowOutsideClick: false,
                                                closeOnClickOutside: false,
                                                text: t(errorMsg)
                                        }).then(() => {
                                            //[08/04/2023, PS_ZE_171_APP_12 - state variables are update to remove count and change unselect all to select all in post filter, Sathis.N]
                                            this.setState({
                                                OrderNonCatalogItem: [], boolWOEmpty: false, WOmsg: "", ValidatedWo: workOrder, pageNo: 1, disableWo: true, workOrderDescription: responeData[0].WONAME,
                                                selectAllMfg: true, selectAllAtt: true, selectAllSup: true, manufactureCount: "", attributeCount: "", supplierCount: "", SupplierChecked: [], Attrichecked: [], Manuchecked: []})
                                            this.LoadSearchResults();
                                        });
                                        $('#WorkOrder').modal('hide');
                                        return;

                                    }
                                    else {
                                        //OrderNonCatalogItemL = this.state.OrderNonCatalogItem
                                        $('#WorkOrder').modal('hide');
                                        this.setState({
                                            OrderNonCatalogItem: [], boolWOEmpty: false, pageNo: 1,
                                            selectAllMfg: true, selectAllAtt: true, selectAllSup: true, manufactureCount: "", attributeCount: "", supplierCount: "", SupplierChecked: [], Attrichecked: [], Manuchecked: [] })
                                        this.LoadSearchResults();
                                        //this.Addtocart();
                                    }

                                    //}
                                    //else {
                                    //OrderNonCatalogItemL = this.state.OrderNonCatalogItem
                                    // $('#WorkOrder').modal('hide');
                                    // this.setState({
                                    //     OrderNonCatalogItem: [], boolWOEmpty: false, pageNo: 1, SupplierChecked: [], Attrichecked: [], Manuchecked: [],
                                    //     selectAllMfg: true, selectAllAtt: true, selectAllSup: true, manufactureCount: "", attributeCount: "", supplierCount: ""})
                                    // this.LoadSearchResults();
                                    //this.Addtocart();
                                    // }
                                }
                            }
                            else {
                                //[06/15/2023 - PC_ZEUS_143_APP_03 - Johnprinto D]
                                this.setState({
                                    pageNo: 1, OrderNonCatalogItem: [],
                                    selectAllMfg: true, selectAllAtt: true, selectAllSup: true, manufactureCount: "", attributeCount: "", supplierCount: "", SupplierChecked: [], Attrichecked: [], Manuchecked: [] })
                                this.LoadSearchResults();
                            }
                        }
                        else {
                            debugger
                            CookieService.updateLocalStorageValues("validWO", "");
                            CookieService.updateLocalStorageValues("WOName", "");
                        }
                        this.setState({
                            WOmsg: "", ValidatedWo: workOrder, disableWo: true, workOrderDescription: responeData[0].WONAME
                        })
                        $('#WorkOrder').modal('hide');
                    }
                    //else if (responeData[0].WOVALIDATE == "invalid") {
                    //    debugger
                    //    CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                    //    CookieService.updateLocalStorageValues("stanfordAssetId", "");
                    //    CookieService.updateLocalStorageValues("validWO", "clearWO");
                    //    CookieService.updateLocalStorageValues("WOName", "");

                    //    //$(`#WONUM`).val('');
                    //    this.setState({ WOmsg: "Please enter valid work order number", ValidatedWo: "" })
                    //}
                    //else {
                    //    CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                    //    CookieService.updateLocalStorageValues("stanfordAssetId", "");
                    //    CookieService.updateLocalStorageValues("validWO", "clearWO");
                    //    CookieService.updateLocalStorageValues("WOName", "");
                    //    //$(`#WONUM`).val('');
                    //    //alert("Error occured in work order validation");
                    //    swal({
                    //        allowOutsideClick: false,
                    //        closeOnClickOutside: false,
                    //        text: t("Error occured in work order validation")
                    //    })
                    //    this.setState({ ValidatedWo: "" });
                    //}
                }
                else {
                    //if (woValidationData != "valid") {
                        //[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]
                        //if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
                        //    if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "") {
                        //        if (CookieService.getLocalStorageValues("validatedWO") != workOrder) {
                        //            this.CancelOrder();
                        //        }
                        //    }
                        //}
                    if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                        if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                this.CancelOrder();
                            }
                        }
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", responeData[0].assetNumber);
                        CookieService.updateLocalStorageValues("validWO", "");
                        CookieService.updateLocalStorageValues("WOName", "");
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].workOrderOpDesc);
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        this.setState({
                        WOmsg: "", ValidatedWo: workOrder, disableWo: true, workOrderDescription: responeData[0].workOrderOpDesc
                    })
                    $('#WorkOrder').modal('hide');
                    //}
                }
                
            }
            else if (woValidationData == "invalid") {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("workorderLocation", "");
                //$(`#WONUM`).val('');
                this.setState({
                    WOmsg: "Please enter valid work order number", ValidatedWo: "", workOrderDescription: ""
                })
                }
            else {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("workorderLocation", "");
                $('#WorkOrder').modal('hide');
                //$(`#WONUM`).val('');
                //alert("Error occured in work order validation");
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error occured in work order validation")
                });
                this.setState({
                    ValidatedWo: "", workOrderDescription: ""
                })
            }
        }
    }
    getItemWoValue(item) {
        return `${item.value + '~' + item.desc}`;
    }
    renderWoItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white', width: '200px' }}>
                {item.label}
            </div>
        );

    }
    handleOnChangeWo(e) {
        debugger
        let value = e.target.value.toUpperCase().trim();
        if (value.length < 81) {
            this.setState({
                ValidatedWo: value
            });
            let suggestionWo = CookieService.getLocalStorageValues("blanketWoAndDesc");
            let filteredWo = [];
            if (value == "") {
                this.setState({
                    ValidatedWo: value, suggestionWo: []
                });

            }
            else {
                filteredWo = suggestionWo.filter(name => name.label.includes(value))
                this.setState({
                    ValidatedWo: value, suggestionWo: filteredWo
                });
            }
        }
        /*if (suggestionWo == "" || value=="") {
            this.handleWoValidation(value);
        }*/

    }

    onSelectItem = (event) => {
        debugger
        let split = event.split("~");
        this.setState({
            ValidatedWo: split[0], workOrderDescription: split[1]
        });
    }

    wodescPopup = () => {
        $('#work-order-details-search').modal('show');
    }

    handleOnChangeWoText(e) {
        let value = e.target.value.toUpperCase().trim();
        this.setState({
            ValidatedWo: value
        });
    }

    //[08/04/2023, PS_ZE_171_APP_05 -method invoke on the click of Select all, Sathis.N]
    selectAll = (selectedCheckBox) => {
        debugger
        let countFilter = "";
        let searchValue = $('#searchTb').val();
        let manuCheckedVal = [];
        let attriCheckedVal = [];
        let supplierCheckedVal = [];
        let expandArray = [];
        if (this.state.selectAllMfg == true) {
            if (selectedCheckBox == 'manufacture') {
                if (this.state.Manufacture.length > 0) {
                    this.state.Manufacture.map((val, index) => {
                        manuCheckedVal.push(val.value)
                    });
                }
                if (manuCheckedVal.length > 0) {
                    countFilter = manuCheckedVal.length;
                }
                this.setState({ Manuchecked: manuCheckedVal, selectAllMfg: false, manufactureCount: countFilter })
                this.ApplyFilter(searchValue, this.state.FilterValue, manuCheckedVal, this.state.Attrichecked, this.state.SupplierChecked, this.state.StoreRoom, this.state.MarketPlace)
            }
        }
        else {
            if (selectedCheckBox == 'manufacture') {
                this.setState({ Manuchecked: [], selectAllMfg: true, manufactureCount: "" });
                this.ApplyFilter(searchValue, this.state.FilterValue, manuCheckedVal, this.state.Attrichecked, this.state.SupplierChecked, this.state.StoreRoom, this.state.MarketPlace)
            }
        }
        if (this.state.selectAllAtt == true) {
            if (selectedCheckBox == 'attribute') {
                if (this.state.AttributeTree.length > 0) {
                    this.state.AttributeTree.map((val, index) => {
                        expandArray.push(val.value)
                        val.children.map((valueChild, indexChild) => {
                            attriCheckedVal.push(valueChild.value)
                        });
                    });
                }
                if (attriCheckedVal.length > 0) {
                    countFilter = attriCheckedVal.length;
                }
                this.setState({ Attrichecked: attriCheckedVal, selectAllAtt: false, attributeCount: countFilter, expanded: expandArray })
                this.ApplyFilter(searchValue, this.state.FilterValue, this.state.Manuchecked, attriCheckedVal, this.state.SupplierChecked, this.state.StoreRoom, this.state.MarketPlace)
            }
        }
        else {
            if (selectedCheckBox == 'attribute') {
                this.setState({ Attrichecked: [], selectAllAtt: true, attributeCount: "", expanded: [] });
                this.ApplyFilter(searchValue, this.state.FilterValue, this.state.Manuchecked, attriCheckedVal, this.state.SupplierChecked, this.state.StoreRoom, this.state.MarketPlace)
            }
        }
        if (this.state.selectAllSup == true) {
            if (selectedCheckBox == 'supplier') {
                if (this.state.SupplierTree.length > 0) {
                    this.state.SupplierTree.map((val, index) => {
                        supplierCheckedVal.push(val.value)
                    });
                }
                if (supplierCheckedVal.length > 0) {
                    countFilter = supplierCheckedVal.length;
                }
                this.setState({ SupplierChecked: supplierCheckedVal, selectAllSup: false, supplierCount: countFilter })
                this.ApplyFilter(searchValue, this.state.FilterValue, this.state.Manuchecked, this.state.Attrichecked, supplierCheckedVal, this.state.StoreRoom, this.state.MarketPlace)
            }
        }
        else {
            if (selectedCheckBox == 'supplier') {
                this.setState({ SupplierChecked: [], selectAllSup: true, supplierCount: "", expanded: [] });
                this.ApplyFilter(searchValue, this.state.FilterValue, this.state.Manuchecked, this.state.Attrichecked, supplierCheckedVal, this.state.StoreRoom, this.state.MarketPlace)
            }
        }
    }
    //[08/04/2023, PS_ZE_171_APP_06 -method invoke on the click of clear button in post filter, Sathis.N]
    clearPostFilter = (selectedCheckbox) => {
        debugger
        let searchValue = $('#searchTb').val();
        let manuFilter = [];
        let attriFilter = [];
        let supplierFilter = [];
        if (selectedCheckbox == 'manufacture') {
            this.setState({ Manuchecked: [], selectAllMfg: true, manufactureCount: "" });
            this.ApplyFilter(searchValue, this.state.FilterValue, manuFilter, this.state.Attrichecked, this.state.SupplierChecked, this.state.StoreRoom, this.state.MarketPlace)
        }
        else if (selectedCheckbox == 'attribute') {
            this.setState({ Attrichecked: [], selectAllAtt: true, attributeCount: "", expanded: [] });
            this.ApplyFilter(searchValue, this.state.FilterValue, this.state.Manuchecked, attriFilter, this.state.SupplierChecked, this.state.StoreRoom, this.state.MarketPlace)
        }
        else if (selectedCheckbox == 'supplier') {
            this.setState({ SupplierChecked: [], selectAllSup: true, supplierCount: "" });
            this.ApplyFilter(searchValue, this.state.FilterValue, this.state.Manuchecked, this.state.Attrichecked, supplierFilter, this.state.StoreRoom, this.state.MarketPlace)
        }
        //[09/20/2023, PS_APP_181_02 - To clear all the selected filter values Sathis N]
        else if (selectedCheckbox == 'All') {
            this.setState({
                Manuchecked: [], Attrichecked: [], SupplierChecked: [], expanded: [], selectAllMfg: true, selectAllAtt: true, selectAllSup: true,
                manufactureCount: "", attributeCount: "", supplierCount: ""
            });
            this.ApplyFilter(searchValue, this.state.FilterValue, manuFilter, attriFilter, supplierFilter, this.state.StoreRoom, this.state.MarketPlace)
        }
    }
    //[3/17/2023, PS_SR_URL_APP_02 - To remove catalog look up tree, Dhevanesam R]
    /***************************-----Catalog look up tree method call starts from here------******************/
    //buildCataloglookup = () => {
    //    debugger
    //    if (this.state.CatalogLookup != null && this.state.CatalogLookup != undefined && this.state.CatalogLookup != []) {
    //        let RemoveDuplicate = this.state.CatalogLookup.filter((v, i, a) => a.findIndex(t => (t.SEGMENTID === v.SEGMENTID && t.SLEVELDESC === v.SLEVELDESC)) === i)
    //        RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //            if (a.SLEVELDESC > b.SLEVELDESC) {
    //                return 1;
    //            }
    //            if (b.SLEVELDESC > a.SLEVELDESC) {
    //                return -1;
    //            }
    //            return 0;
    //        });
    //        return RemoveDuplicate.map((Lookup, index) => {
    //            let SecondLevel = this.state.CatalogLookup.filter((item, index) => item.SEGMENTID == Lookup.SEGMENTID && item.SLEVELDESC == Lookup.SLEVELDESC);
    //            let expand = false
    //            if (this.state.CatalogRoot1 == Lookup.SEGMENTID && this.state.CatalogRoot1Value == Lookup.SLEVELDESC) {
    //                expand = true
    //            }
    //            debugger
    //            return (
    //                <div className="card border-0 " key={"Root" + index}>
    //                    <div className="card-header p-0 CatalohLookupDiv" id={"headingOneS" + index}>
    //                        <h5 className="mb-0 accordion-header CatalohLookupHTag" onClick={() => this.BuildCatalogPath(1, Lookup.SEGMENTID, Lookup.SLEVELDESC, "", "", "", "", "", "")}>
    //                            {Lookup.SLEVELDESC + "(" + (SecondLevel.length) + ")"}
    //                        </h5>
    //                        {expand == true ? <span className="accordion-heading accordion-icon-position" onClick={() => this.BuildCatalogPath(1, Lookup.SEGMENTID, Lookup.SLEVELDESC, "", "", "", "", "", "")} data-toggle="collapse" data-target={"#collapseOneS" + index} aria-expanded="true" aria-controls="collapseOneS" /> :
    //                            <span className="accordion-heading accordion-icon-position collapsed" onClick={() => this.BuildCatalogPath(1, Lookup.SEGMENTID, Lookup.SLEVELDESC, "", "", "", "", "", "")} data-toggle="collapse" data-target={"#collapseOneS" + index} aria-expanded="false" aria-controls="collapseOneS" />}
    //                    </div>
    //                    {expand == true ? <div id={"collapseOneS" + index} className="collapse show" aria-labelledby={"headingOneS" + index} data-parent="#accordion" style={{}}>
    //                        <div className="card-body m-0 p-0" id={"child1S" + index}>
    //                            {this.BuildSecondLevel(SecondLevel, index)}
    //                        </div>
    //                    </div> : <div id={"collapseOneS" + index} className="collapse d-none" aria-labelledby={"headingOneS" + index} data-parent="#accordion" style={{}}>
    //                            <div className="card-body m-0 p-0" id={"child1S" + index}>
    //                                {this.BuildSecondLevel(SecondLevel, index)}
    //                            </div>
    //                        </div>}
    //                </div>
    //            )

    //        });
    //    }
    //}
    //BuildSecondLevel = (SecendlevelArray, indexvalue) => {
    //    debugger
    //    let RemoveDuplicate = SecendlevelArray.filter((v, i, a) => a.findIndex(t => (t.FAMILYID === v.FAMILYID && t.FLEVELDESC === v.FLEVELDESC)) === i)
    //    RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //        if (a.FLEVELDESC > b.FLEVELDESC) {
    //            return 1;
    //        }
    //        if (b.FLEVELDESC > a.FLEVELDESC) {
    //            return -1;
    //        }
    //        return 0;
    //    });
    //    return RemoveDuplicate.map((Lookup, index) => {
    //        let ThirdLevel = SecendlevelArray.filter((item, index) => item.FAMILYID == Lookup.FAMILYID && item.FLEVELDESC == Lookup.FLEVELDESC);
    //        let expand = false
    //        if (this.state.CatalogRoot2 == Lookup.FAMILYID && this.state.CatalogRoot2Value == Lookup.FLEVELDESC) {
    //            expand = true
    //        }
    //        debugger
    //        //console.log("ThirdLevel", ThirdLevel)
    //        return (<div className="card border-0" key={"Root1" + index}>
    //            <div className="card-header p-0">
    //                <h5 className="mb-0 accordion-header accordion-headerl1 ml-2 CatalohLookupHTag2" onClick={() => this.BuildCatalogPath(2, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, "", "", "", "")}>{Lookup.FLEVELDESC + "(" + (ThirdLevel.length) + ")"}</h5>
    //                {expand == true ? <span className="accordion-heading accordion-headingl1" data-toggle="collapse" data-target={"#collapseOneAB" + index} aria-expanded="true" onClick={() => this.BuildCatalogPath(2, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, "", "", "", "")} /> :
    //                    <span className="accordion-heading accordion-headingl1 collapsed" data-toggle="collapse" data-target={"#collapseOneAB" + index} aria-expanded="false" onClick={() => this.BuildCatalogPath(2, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, "", "", "", "")} />}
    //            </div>
    //            {expand == true ? <div className="card-body collapse show p-0" data-parent={"#child1S" + indexvalue} id={"collapseOneABS" + index}>
    //                {this.BuildThirdLevel(ThirdLevel, indexvalue, index)}
    //            </div> : <div className="card-body collapse p-0 d-none" data-parent={"#child1S" + indexvalue} id={"collapseOneABS" + index}>
    //                    {this.BuildThirdLevel(ThirdLevel, indexvalue, index)}
    //                </div>}
    //        </div>)
    //    });

    //}
    //BuildThirdLevel = (SecendlevelArray, indexvalue, index1) => {
    //    debugger
    //    let RemoveDuplicate = SecendlevelArray.filter((v, i, a) => a.findIndex(t => (t.CLASSID === v.CLASSID && t.CLEVELDESC === v.CLEVELDESC)) === i)
    //    RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //        if (a.CLEVELDESC > b.CLEVELDESC) {
    //            return 1;
    //        }
    //        if (b.CLEVELDESC > a.CLEVELDESC) {
    //            return -1;
    //        }
    //        return 0;
    //    });
    //    return RemoveDuplicate.map((Lookup, index) => {
    //        let ThirdLevel = SecendlevelArray.filter((item, index) => item.CLASSID == Lookup.CLASSID && item.CLEVELDESC == Lookup.CLEVELDESC);
    //        let expand = false
    //        if (this.state.CatalogRoot3 == Lookup.CLASSID && this.state.CatalogRoot3Value == Lookup.CLEVELDESC) {
    //            expand = true
    //        }
    //        debugger
    //        return (<div className="card border-0" key={"Root2" + index}>
    //            <div className="card-header p-0 ">
    //                <h5 className="mb-0 accordion-header accordion-headerl2  ml-3 CatalohLookupHTag3" onClick={() => this.BuildCatalogPath(3, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, Lookup.CLASSID, Lookup.CLEVELDESC, "", "")}>{Lookup.CLEVELDESC + "(" + (ThirdLevel.length) + ")"}</h5>
    //                {expand == true ? <span className="accordion-heading accordion-headingl2" data-toggle="collapse" aria-expanded="true" data-target={"#collapseOneA" + index} onClick={() => this.BuildCatalogPath(3, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, Lookup.CLASSID, Lookup.CLEVELDESC, "", "")} /> :
    //                    <span className="accordion-heading accordion-headingl2 collapsed" data-toggle="collapse" aria-expanded="false" data-target={"#collapseOneA" + index} onClick={() => this.BuildCatalogPath(3, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, Lookup.CLASSID, Lookup.CLEVELDESC, "", "")} />}
    //            </div>
    //            {expand == true ? <div className="card-body collapse show p-0 " data-parent={"#collapseOneABS" + index1} id={"collapseOneAS" + index}>
    //                <ul className="p-0">
    //                    {this.BuildFourLevel(ThirdLevel)}
    //                </ul>
    //            </div> : <div className="card-body collapse p-0 d-none" data-parent={"#collapseOneABS" + index1} id={"collapseOneAS" + index}>
    //                    <ul className="p-0">
    //                        {this.BuildFourLevel(ThirdLevel)}
    //                    </ul>
    //                </div>}
    //        </div>)
    //    });

    //}
    //BuildFourLevel = (ThirdLevelArray) => {
    //    debugger
    //    let RemoveDuplicate = ThirdLevelArray.filter((v, i, a) => a.findIndex(t => (t.COMMODITYID === v.COMMODITYID && t.MLEVELDESC === v.MLEVELDESC)) === i)
    //    RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //        if (a.MLEVELDESC > b.MLEVELDESC) {
    //            return 1;
    //        }
    //        if (b.MLEVELDESC > a.MLEVELDESC) {
    //            return -1;
    //        }
    //        return 0;
    //    });
    //    return RemoveDuplicate.map((Lookup, index) => {
    //        let ThirdLevel = ThirdLevelArray.filter((item, index) => item.COMMODITYID == Lookup.COMMODITYID && item.MLEVELDESC == Lookup.MLEVELDESC);
    //        return (
    //            <li className="list-group-item inner-list-item ml-5" key={"Root3" + index} onClick={() => this.BuildCatalogPath(4, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, Lookup.CLASSID, Lookup.CLEVELDESC, Lookup.COMMODITYID, Lookup.MLEVELDESC)}>{Lookup.MLEVELDESC + "(" + (ThirdLevel.length) + ")"}</li>
    //        )
    //    });
    //}   
    //[12/29/21, CAS - 31, Dhevanesam R]
    //BuildCatalogPath = (ParentLevel, Node1, Node1Value, Node2, Node2Value, Node3, Node3Value, Node4, Node4Value) => {
    //    debugger
    //    this.setState({
    //        CatalogRoot1: Node1, CatalogRoot2: Node2, CatalogRoot3: Node3, CatalogRoot4: Node4, CatalogTreeRoot: "  >  ",
    //        CatalogRoot1Value: Node1Value, CatalogRoot2Value: Node2Value, CatalogRoot3Value: Node3Value, CatalogRoot4Value: Node4Value, ParentLevel: ParentLevel,
    //        CatalogChange: true

    //    })
    //    if (ParentLevel == 4) {
    //        CookieService.updateLocalStorageValues("Catalog_Node1", Node1Value);
    //        CookieService.updateLocalStorageValues("Catalog_Node2", Node2Value);
    //        CookieService.updateLocalStorageValues("Catalog_Node3", Node3Value);
    //        CookieService.updateLocalStorageValues("Catalog_Node4", Node4Value);
    //        CookieService.updateLocalStorageValues("CatalogNODE1", Node1);
    //        CookieService.updateLocalStorageValues("CatalogNODE2", Node2);
    //        CookieService.updateLocalStorageValues("CatalogNODE3", Node3);
    //        CookieService.updateLocalStorageValues("CatalogNODE4", Node4);
    //        this.props.history.push({
    //            pathname: `\searchresults`,
    //            search: `?key=${Node4Value}&unspsc=${Node4}&Home=yes&CatTree=yes` //[10/13/21, PC_US_12, CAS - 36], Poornima S
    //        });
    //        window.location.reload(false);
    //    }
    //}
    //CatalogHeaderLink = (event, Node) => {
    //    if (event) {
    //        event.preventDefault();
    //    }
    //    let Node1 = this.state.CatalogRoot1
    //    let Node2 = this.state.CatalogRoot2
    //    let Node3 = this.state.CatalogRoot3
    //    let Node4 = this.state.CatalogRoot4
    //    let Node1Value = this.state.CatalogRoot1Value
    //    let Node2Value = this.state.CatalogRoot2Value
    //    let Node3Value = this.state.CatalogRoot3Value
    //    let Node4Value = this.state.CatalogRoot4Value
    //    let ParentLevel = Node
    //    if (Node == 0) {
    //        Node1 = ""
    //        Node2 = ""
    //        Node3 = ""
    //        Node4 = ""
    //        Node1Value = ""
    //        Node2Value = ""
    //        Node3Value = ""
    //        Node4Value = ""
    //    }
    //    else if (Node == 1) {
    //        Node2 = ""
    //        Node3 = ""
    //        Node4 = ""
    //        Node2Value = ""
    //        Node3Value = ""
    //        Node4Value = ""
    //    }
    //    else if (Node == 2) {
    //        Node3 = ""
    //        Node4 = ""
    //        Node3Value = ""
    //        Node4Value = ""
    //    }
    //    else if (Node == 3) {
    //        Node4 = ""
    //        Node4Value = ""
    //    }
    //    this.setState({
    //        CatalogRoot1: Node1, CatalogRoot2: Node2, CatalogRoot3: Node3, CatalogRoot4: Node4, CatalogTreeRoot: "  >  ",
    //        CatalogRoot1Value: Node1Value, CatalogRoot2Value: Node2Value, CatalogRoot3Value: Node3Value, CatalogRoot4Value: Node4Value, ParentLevel: ParentLevel,
    //        CatalogChange: true

    //    })
    //}
    /***************************-----Catalog look up tree method calls ends here------******************/
    render() {
        //[11/26/21, CAS - 31, Priyanka D]
        const { t } = this.props;
        return (<React.Fragment>
            <div className="container-fluid content-margin Top-margin" id='search-results-component'>
            <div className="row">
                    <div className="col-md-12 page-heading mb-3">
                        <h5 className="page-title CatPath-margin-top"><Trans>Search Result</Trans></h5>
                        {this.state.Terms == false ? <h7 className="errorcolour page-title ml-5"><Trans>Please agree the terms and conditions</Trans></h7> : null}
                    </div>
                    {this.state.PageLoadSearchResults.length < 1 && this.state.ShowDidyoumean == false ? <div className="col-md-12 mb-3 float-left">
                        <h5 className="search-category d-block title-font"><Trans>You searched for</Trans>: {this.state.SearchKeyWord}</h5></div> : null}
                    {this.state.PageLoadSearchResults.length < 1 && this.state.ShowDidyoumean != false ? <div><div className="col-md-12 mb-3 float-left">
                        <h5 className="search-category d-block title-font"><Trans>You searched for</Trans>: {this.state.SearchKeyWord}</h5></div>
                        <div className="col-md-12 float-left mb-3">
                            <h6 className="float-left  mb-0 search-description Didyoumean"><Trans>Did you mean</Trans>:</h6><a href="#" className="float-left suggested-txt mx-2" onClick={(e) => this.DidyouMeanClick(this.state.DidyouMean1, e)}>{this.state.DidyouMean1}</a>
                            <a href="#" className="float-left suggested-txt mx-2" onClick={(e) => this.DidyouMeanClick(this.state.DidyouMean2, e)}>{this.state.DidyouMean2}</a>
                            <a href="#" className="float-left suggested-txt mx-2" onClick={(e) => this.DidyouMeanClick(this.state.DidyouMean3, e)}>{this.state.DidyouMean3}</a></div></div> : null}
                    {this.state.PageLoadSearchResults.length < 1 ? (<section className="site-undercontruction container text-center center-panel pt-4">

                        <div className="img-section">
                            <img src="images/nosearch.jpg" alt="imagefile" />
                        </div>
                        <p className="search-result-content pt-4"><Trans>No Search Result found</Trans>
                        </p>
                    </section>) :

                        <div className="col-md-12 pr-0">

                            {/*/***************************-----Catalog look up tree HTML starts here------******************
                            {this.state.URLRoot == "yes" && this.state.CatalogChange == true ? <div className="col-md-12">
                                {this.state.ParentLevel == "" || this.state.ParentLevel == null || this.state.ParentLevel == undefined ? <h7 className="float-left"><Trans>Catalog</Trans></h7> : <a href="#" className="float-left" onClick={(e) => this.CatalogHeaderLink(e, 0)}><Trans>Catalog</Trans></a>}
                                {this.state.ParentLevel == "1" || this.state.ParentLevel == 1 ? <h7 className="float-left pl-1">{this.state.CatalogTreeRoot + this.state.CatalogRoot1Value}</h7> : <div className="float-left">{this.state.ParentLevel == "2" || this.state.ParentLevel > 1 ? <span className="pl-1">{this.state.CatalogTreeRoot}</span> : null}<a href="#" onClick={(e) => this.CatalogHeaderLink(e, 1)}>{this.state.CatalogRoot1Value}</a></div>}
                                {this.state.ParentLevel == "2" || this.state.ParentLevel == 2 ? <h7 className="float-left pl-1">{this.state.CatalogTreeRoot + this.state.CatalogRoot2Value}</h7> : <div className="float-left">{this.state.ParentLevel == "3" || this.state.ParentLevel > 2 ? <span className="pl-1">{this.state.CatalogTreeRoot}</span> : null}<a href="#" onClick={(e) => this.CatalogHeaderLink(e, 2)}>{this.state.CatalogRoot2Value}</a></div>}
                                {this.state.ParentLevel == "3" || this.state.ParentLevel == 3 ? <h7 className="float-left pl-1">{this.state.CatalogTreeRoot + this.state.CatalogRoot3Value}</h7> : <div className="float-left">{this.state.ParentLevel == "4" || this.state.ParentLevel > 3 ? <span className="pl-1">{this.state.CatalogTreeRoot}</span> : null}<a href="#" onClick={(e) => this.CatalogHeaderLink(e, 3)}>{this.state.CatalogRoot3Value}</a></div>}
                                {this.state.ParentLevel == "4" || this.state.ParentLevel == 4 ? <h7 className="float-left pl-1">{this.state.CatalogTreeRoot + this.state.CatalogRoot4Value}</h7> : null}
                            </div> : null}
                            {this.state.URLRoot == "yes" && this.state.CatalogChange == false ? <div className="col-md-12">
                                {/*PC_SRpP_02 Bug 2022 Cat tree path UI issue, so added 0 as a 2nd argument for CatalogHeaderLink() - Poornima S*/}
                            {/*<a href="#" className="float-left" onClick={(e) => this.CatalogHeaderLink(e,0)}><Trans>Catalog</Trans></a>
                                <div className="float-left">
                                    <span className="pl-1">{this.state.CatalogTreeRoot}</span>
                                    <a href="#" onClick={(e) => this.CatalogHeaderLink(e, 1)}>{this.state.CatalogRoot1Value}</a>
                                </div>
                                <div className="float-left">
                                    <span className="pl-1">{this.state.CatalogTreeRoot}</span>
                                    <a href="#" onClick={(e) => this.CatalogHeaderLink(e, 2)}>{this.state.CatalogRoot2Value}</a>
                                </div><div className="float-left">
                                    <span className="pl-1">{this.state.CatalogTreeRoot}</span>
                                    <a href="#" onClick={(e) => this.CatalogHeaderLink(e, 3)}>{this.state.CatalogRoot3Value}</a>
                                </div><div className="float-left">
                                    {/*<span className="pl-1">{this.state.CatalogTreeRoot}</span>*/}
                            {/*<h7 className="float-left pl-1">{this.state.CatalogTreeRoot + this.state.CatalogRoot4Value}</h7>
                                </div>
                            </div> : null}
                            /***************************-----Catalog look up tree HTML ends here------*******************/}

                            {/*[04/08/22, CAS - 31, Dhevanesam R]*/}
                            <div className="row col-md-12 pr-0 pl-0">
                                {this.state.PageLoadSearchResults.length > 0 ? (<div className="col-sm-4 col-md-4 col-lg-3 my-3 mobile-filter" id="Advancefilterdiv">
                                    <div id="accordion" className="accordion-container filters-search">
                                        <h6 className="accordion-title mb-0"><Trans>Filter</Trans></h6>
                                        <div className="card border-0">
                                            <div className="card-header custom-card-reset search-filter-index-reset card-custom-padding">
                                                <div className="w-100 input-group dropdown-search">
                                                    <div className="input-group-prepend">
                                                        <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.filterAppliedType}</Trans></button>
                                                        <div className="dropdown-menu" style={{ "z-index": "1" }}>
                                                            <a className="dropdown-item" onClick={() => this.FilterType(1, "All")}><Trans>All</Trans></a>
                                                            {/* Zeus - 242 */}
                                                            <a className="dropdown-item" onClick={() => this.FilterType(5, "Item ID")}><Trans>Item ID</Trans></a>
                                                            <a className="dropdown-item" onClick={() => this.FilterType(3, "Description")}><Trans>Description</Trans></a>
                                                            <a className="dropdown-item" onClick={() => this.FilterType(4, "MFG name")}><Trans>MFG name</Trans></a>
                                                            <a className="dropdown-item" onClick={() => this.FilterType(6, "Supplier")}><Trans>Supplier</Trans></a>
                                                            {/* Zeus - 242 */}
                                                            <a className="dropdown-item" onClick={() => this.FilterType(2, "Supplier Part Number")}><Trans>Supplier Part Number</Trans></a>
                                                            <a className="dropdown-item" onClick={() => this.FilterType(7, "UOM")}>UOM</a>
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control pr-5" placeholder={t("Search")} aria-label="Text input with dropdown button" value={this.state.searchTxt} onChange={(e) => this.onChangeSearch(e)} id="searchTb" onKeyDown={this.AnykeyPress} />
                                                    {this.state.searchTxt.length > 0 ? <span class="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} > </span> : null}
                                                    <span className="dropdown-search-icon" onClick={() => this.Search()} />
                                                </div>
                                                <label className="custom-radiobtn my-3"><Trans>Catalog description</Trans>
                                                    <input type="radio" id="CatalogDesp" checked={CatalogType} name="radio1" onChange={() => this.descriptioncahange(1)} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                                <label className="custom-radiobtn my-3"><Trans>Client description</Trans>
                                                    <input type="radio" id="ClientDesp" checked={ClientType} name="radio1" onChange={() => this.descriptioncahange(0)} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>

                                                {/*<div className="custom-control custom-checkbox my-2 item-custom-control d-inline-block">
                                                    <input type="checkbox" className="custom-control-input" id="StoreRoom" name="example16" onChange={() => this.marketPlaceChange(0)} />
                                                    <label className="custom-control-label priority-checkbox filter-checkbox" htmlFor="StoreRoom">Store Room</label>
                                                    <input type="checkbox" className="custom-control-input" id="MarketPlace" name="example15" onChange={() => this.marketPlaceChange(1)} />
                                                    <label className="custom-control-label priority-checkbox filter-checkbox" htmlFor="MarketPlace">Market Place</label>
                                                </div>
                                                <label className="custom-radiobtn my-3">Store Room
                        <input type="checkbox" id="StoreRoom" checked={this.state.StoreRoom} name="radio3" onChange={() => this.marketPlaceChange(1)} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                                <label className="custom-radiobtn my-3">Market Place
                        <input type="checkbox" id="MarketPlace" checked={this.state.MarketPlace} name="radio4" onChange={() => this.marketPlaceChange(0)} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>*/}
                                            </div>
                                            {this.state.URLRoot != "yes" && CookieService.getLocalStorageValues("Session_DeliveryDateFlag") != "Y" ? <div style={{ width: "100 %", float: "left" }}><div className={`${this.state.manufactureCount > 0 || this.state.attributeCount > 0 || this.state.supplierCount > 0 ? 'custom-card-reset' : ''} custom-control custom-checkbox my-2 p-0 item-custom-control d-inline-block col-md-12`}>
                                                <div className="ml-2 pl-4 float-left w-100">
                                                    <div className="col-md-12 px-0 mx-0 float-left mt-2">
                                                        <input type="checkbox" className="custom-control-input" id="StoreRoom" name="example16" checked={this.state.StoreRoom} onChange={() => this.marketPlaceChange(0)} />
                                                        <label className="custom-control-label priority-checkbox filter-checkbox" htmlFor="StoreRoom"><Trans>SDI store room</Trans></label>
                                                    </div>
                                                    <div className="col-md-12 px-0 mx-0 float-right mt-4 mb-2">
                                                        <input type="checkbox" className="custom-control-input" id="MarketPlace" name="example15" checked={this.state.MarketPlace} onChange={() => this.marketPlaceChange(1)} />
                                                        <label className="custom-control-label priority-checkbox filter-checkbox" htmlFor="MarketPlace"><Trans>SDI market place</Trans></label>
                                                    </div>
                                                </div>
                                            </div> </div> : null}
                                            {/*//[ 06/26/2023 - PC_ZEUS_143_APP_08 - Johnprinto D]*/}
                                            {/*<div className="card-header custom-card-reset card-custom-padding">
                                                <div className="custom-control custom-checkbox my-2 item-custom-control d-inline-block">
                                                    <input type="checkbox" className="custom-control-input" id="customCheckInfo" name="example1" onChange={() => this.showsample()} />
                                                    <label className="custom-control-label priority-checkbox filter-checkbox" htmlFor="customCheckInfo"><Trans>Info Item</Trans></label>
                                                </div>
                                                <span className="info-container">{}
                                                    <img className="info-icon-searchpage" src="Images/info.svg" />
                                                </span>
                                            </div>*/}
                                        </div>
                                        {/*/***************************-----Catalog look up tree HTML starts from here------******************
                                            <div className="card border-0 d-none">
                                            <div className="card-header p-0" id="headingOne1">
                                                <h5 className="mb-0 accordion-header "><Trans>Catalog Lookup</Trans></h5>
                                                <span className="accordion-heading collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" />
                                            </div>
                                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne1" data-parent="#accordion" style={{}}>
                                                <div className="input-group-prepend">
                                                    <button className="btn catagoryFilterBtn" type="button" onClick={() => this.CHeckAll(1)}><Trans>Check All</Trans></button>
                                                    <button className="btn catagoryFilterBtn" type="button" onClick={() => this.CHeckAll(0)}><Trans>Decheck All</Trans></button>
                                                    <button className="btn catagoryFilterBtn" type="button" onClick={() => this.ApplyCatagoryFilter()}><Trans>Apply</Trans></button>
                                                </div>
                                                <div className="col-md-12">
                                                    <CheckboxTree
                                                        nodes={this.state.CatalogTree}
                                                        iconsClass="fa4"
                                                        showNodeIcon={true}
                                                        checked={this.state.checked}
                                                        expanded={this.state.expanded}
                                                        onCheck={this.OnCheckTree}
                                                        onExpand={expanded => this.setState({ expanded })}
                                                        icons={{
                                                            check: <span className="rct-icon rct-icon-check" />,
                                                            uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                            halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                            expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                            expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                            parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                            parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                            leaf: <span className="rct-icon rct-icon-leaf" />,
                                                        }}

                                                    />
                                                </div>
                                            </div>

                                        </div>*/}
                                        {/*{this.state.CatalogLookup != null && this.state.CatalogLookup != undefined && this.state.CatalogLookup != [] && this.state.URLRoot == "yes" ? <div>
                                            {this.state.CatalogLookup.length > 0 ? <div id="accordion" className="accordion-container CatalogLookUp">
                                                <h6 className="accordion-title mb-0"><Trans>Catalog Lookup</Trans></h6>
                                                {this.buildCataloglookup()}
                                            </div> : null}
                                        </div> : null}/***************************-----Catalog look up tree method calls ends here------*******************/}
                                        {/*[09/20/2023, PS_APP_181_01 - To create clear button Sathis N]*/}
                                        {this.state.manufactureCount > 0 || this.state.attributeCount > 0 || this.state.supplierCount > 0 ?
                                            <div style={{ "margin-bottom": "7px", "margin-top": "2px" }} className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-6 w-100">
                                                        <button className={` btn btn-primary custom-btn-primary p-1 float-right ${CookieService.getLocalStorageValues("Session_DeliveryDateFlag") != "Y" ? '':'mt-2'}`} id="common-clearAll" onClick={() => this.clearPostFilter("All")}>Clear all</button>
                                                    </div>
                                                </div>
                                            </div> : null}
                                        <div className="card border-0 ">
                                            <div className="card-header p-0" id="headingtwo">
                                                <h5 className="mb-0 accordion-header "><Trans>Manufacturer</Trans></h5>
                                                <span className="accordion-heading collapsed" onClick={() => this.ShowFIlter(1)} id="ManufilterIcon" />
                                                {this.state.manufactureCount > 0 ? <span className="filtercnt cntalign" style={{ "padding": "4px 0px", "width": "27px", "height": "25px", "border-radius": "62%" }}>{this.state.manufactureCount}</span> : null}
                                            </div>
                                            {/*<div id="collapsetwo" className="collapse" aria-labelledby="headingOne1" data-parent="#accordion" style={{}}>*/}
                                            {/*[10/26/21, PC_06, IE 11 issue, Dhevanesam R]*/}
                                            {/*[08 / 04 / 2023, PS_ZE_171_APP_01 - Display Select all, clear buttons and count, Sathis.N]*/}
                                            <div className="manuFilterCss p-3 d-none" id="Manufilter">
                                                <div class="col-md-14">
                                                    <div class="row mb-3">
                                                        <div class="col-md-5 float-left px-2">
                                                            <a className="filterlink p-0" id="manufilterSelectAll" onClick={() => this.selectAll('manufacture')} style={{ color: "#007bff", textDecoration: "underline", cursor: "pointer" }}><Trans>{this.state.selectAllMfg == true ? "Select all" : "Unselect all"}</Trans></a>
                                                        </div>
                                                        <div class="col-md-7 float-right SearchResultpx-1 SearchResultpx-4">
                                                            <a className="filterlink p-0" id="manufilterClear" onClick={() => this.clearPostFilter('manufacture')} style={{ color: "#007bff", textDecoration: "underline", cursor: "pointer" }}><Trans>Clear</Trans></a>

                                                        </div>
                                                    </div>
                                                </div>
                                                <CheckboxTree
                                                    nodes={this.state.Manufacture}
                                                    iconsClass="fa4"
                                                    showNodeIcon={true}
                                                    checked={this.state.Manuchecked}
                                                    onCheck={this.OnCheckManuTree}
                                                    icons={{
                                                        check: <span className="rct-icon rct-icon-check" />,
                                                        uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                        halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                        expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                        expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                        expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                        collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                        parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                        parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                        leaf: <span className="rct-icon rct-icon-leaf" />,
                                                    }}

                                                />
                                            </div>
                                            {/*  </div>*/}
                                        </div>
                                        {/*[PC_01, CAS-36], Priyanka D*/}
                                        {this.state.AttributeTree.length != 0 ?
                                            <div className="card border-0">
                                                <div className="card-header p-0" id="headingthree">
                                                    <h5 className="mb-0 accordion-header "><Trans>Attribute</Trans></h5>
                                                    <span className="accordion-heading collapsed" onClick={() => this.ShowFIlter(2)} id="AttriFilterIcon" />
                                                    {this.state.attributeCount > 0 ? <span className="filtercnt cntalign" style={{ "padding": "4px 0px", "width": "27px", "height": "25px", "border-radius": "62%" }}>{this.state.attributeCount}</span> : null}
                                                </div>
                                                {/* <div id="collapsethree" className="collapse" aria-labelledby="headingOne1" data-parent="#accordion" style={{}}>*/}
                                                {/*[8/19/22, PC_SR_01, CAS - Footer issue in IE when clicking attribute & manf in search results is clicked., Dhevanesam R]*/}
                                                {/*[08/04/2023, PS_ZE_171_APP_02 - Display Select all, clear buttons and count, Sathis.N]*/}
                                                <div className="col-md-16 pl-3 d-none" id="AttriFilter">
                                                    <div class="col-md-14">
                                                        <div class="row pt-3">
                                                            <div class="col-md-5 float-left px-2">
                                                                <a className="filterlink p-0" id="AttriFilterSelectAll" onClick={() => this.selectAll('attribute')} style={{ color: "#007bff", textDecoration: "underline", cursor: "pointer" }}><Trans>{this.state.selectAllAtt == true ? "Select all" : "Unselect all"}</Trans></a>
                                                            </div>
                                                            <div class="col-md-7 float-right  SearchResultpx-1 SearchResultpx-4">
                                                                <a className="filterlink p-0" id="AttriFilterClear" onClick={() => this.clearPostFilter('attribute')} style={{ color: "#007bff", textDecoration: "underline", cursor: "pointer" }}><Trans>Clear</Trans></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <CheckboxTree
                                                        nodes={this.state.AttributeTree}
                                                        iconsClass="fa4"
                                                        showNodeIcon={true}
                                                        checked={this.state.Attrichecked}
                                                        expanded={this.state.expanded}
                                                        onExpand={expanded => this.setState({ expanded })}
                                                        onCheck={this.OnCheckAttriTree}
                                                        icons={{
                                                            check: <span className="rct-icon rct-icon-check" />,
                                                            uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                            halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                            expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                            expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                            parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                            parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                            leaf: <span className="rct-icon rct-icon-leaf" />,
                                                        }}

                                                    />
                                                </div>
                                                {/*  </div>*/}
                                            </div> : null}
                                        {/*[5/10/2023, PS_ZE_103_01, Dhevanesam R]*/}
                                        {this.state.SupplierTree.length != 0 ?
                                            <div className="card border-0">
                                                <div className="card-header p-0" id="headingfour">
                                                    <h5 className="mb-0 accordion-header "><Trans>Supplier</Trans></h5>
                                                    <span className="accordion-heading collapsed" onClick={() => this.ShowFIlter(3)} id="SupplierFilterIcon" />
                                                    {this.state.supplierCount > 0 ? <span className="filtercnt cntalign" style={{ "padding": "4px 0px", "width": "27px", "height": "25px", "border-radius": "62%" }}> {this.state.supplierCount}</span> : null}
                                                </div>
                                                {/* <div id="collapsethree" className="collapse" aria-labelledby="headingOne1" data-parent="#accordion" style={{}}>*/}
                                                {/*[08/04/2023, PS_ZE_171_APP_03 - Display Select all, clear buttons and count, Sathis.N]*/}
                                                <div className="col-md-12 mt-3 d-none" id="SupplierFilter">
                                                    <div class="col-md-14">
                                                        <div class="row mb-3">
                                                            <div class="col-md-5 float-left px-2">
                                                                <a className="filterlink p-0" id="SupplierFilterSelectAll" onClick={() => this.selectAll('supplier')} style={{ color: "#007bff", textDecoration: "underline", cursor: "pointer" }}><Trans>{this.state.selectAllSup == true ? "Select all" : "Unselect all"}</Trans></a>
                                                            </div>
                                                            <div class="col-md-7 float-right SearchResultpx-1 SearchResultpx-4">
                                                                <a className="filterlink p-0" id="SupplierFilterClear" onClick={() => this.clearPostFilter('supplier')} style={{ color: "#007bff", textDecoration: "underline", cursor: "pointer" }}><Trans>Clear</Trans></a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <CheckboxTree
                                                        nodes={this.state.SupplierTree}
                                                        iconsClass="fa4"
                                                        showNodeIcon={true}
                                                        checked={this.state.SupplierChecked}
                                                        onCheck={this.OnCheckSupplierTree}
                                                        icons={{
                                                            check: <span className="rct-icon rct-icon-check" />,
                                                            uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                            halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                            expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                            expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                            parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                            parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                            leaf: <span className="rct-icon rct-icon-leaf" />,
                                                        }}

                                                    />
                                                </div>
                                                {/*  </div>*/}
                                            </div> : null}
                                    </div>
                                </div>) : null}
                                <div className="col-sm-8 col-md-8 col-lg-9 mt-3 pr-0">
                                    <div className="row">
                                        <div className="col-md-12 pl-0">
                                            <div className="float-left">
                                                {this.state.URLRoot != "yes" ? <h5 className="search-category d-block title-font float-left">
                                                    <Trans>You searched for</Trans> :
                </h5> : null}
                                                {/*<span class="ml-1 search-category-value light-gray-text">{this.state.SearchKeyWord}</span>*/}
                                                <div className="float-left">
                                                    <h5 className="ml-1 search-category d-block title-font light-gray-text">{this.state.SearchKeyWord}
                                                    </h5>
                                                </div>
                                                {this.state.showpagination ? <span className="list-count w-100 float-left"><span
                                                    className="mr-1">{this.state.itemperpage}</span>
                                                    <Trans>Items In</Trans><span className="mx-1">1</span>
                                                    <Trans>Page</Trans> (<span className="mr-1">{this.state.TotalItemCount}</span>
                                                    <Trans>Items In</Trans><span className="mx-1">{this.state.pageCount}</span>
                                                    <Trans>Page</Trans> )
                </span> :
                                                    <span className="list-count w-100 float-left"><span className="mr-1">{this.state.TotalItemCount}</span>
                                                        <Trans>Items In</Trans><span className="mx-1">{this.state.pageCount}</span>
                                                        <Trans>Page</Trans>
                                                    </span>}
                                            </div>
                                            <ul className="custom-pagination pagination float-right mb-2 mt-1 mobile-pagination">
                                                {this.state.showpagination ?
                                                    <ReactPaginate previousLabel={<span className="right-pagination-arrow" />}
                                                        nextLabel={<span className="left-pagination-arrow" />}
                                                        previousClassName={'page-item'}
                                                        nextClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextLinkClassName={'page-link'}
                                                        disabledClassName={'disabled'}
                                                        breakLabel={'...'}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link'}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={2}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={'custom-pagination pagination'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        activeClassName={'active'}
                                                        hrefBuilder={() => '#'}
                                                        forcePage={this.state.pageNo - 1}
                                                    /> : null}
                                            </ul>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row justify-content-between align-items-center mt-2">
                                                {(this.state.disableWo != false) ?
                                                    <div className="col-md-12 px-0">
                                                        <div className="d-block mb-2">
                                                            <span className="font-14 font-medium color-sec-black">Work Order # - </span><span
                                                                className="font-14 font-regular modal-inner-label-value" style={{
                                                                    "font-size": "14px"
                                                                }}>{this.state.ValidatedWo}</span>
                                                        </div>
                                                        <div className="d-block">
                                                            <span className="font-14 font-medium color-sec-black">Description -</span>{this.state.workOrderDescription != "" && this.state.workOrderDescription != null &&
                                                                this.state.workOrderDescription != undefined ?
                                                                this.state.workOrderDescription.length < 100 ? <span
                                                                    className="font-14 font-regular modal-inner-label-value" style={{ "font-size": "14px" }}>
                                                                    {this.state.workOrderDescription}</span> : <span
                                                                        className="font-16 font-regular modal-inner-label-value" style={{
                                                                            "font-size": "14px"
                                                                        }}>{this.state.workOrderDescription.substring(0, 100)}...<button type="button"
                                                                            className="view-more" data-toggle="modal" onClick={() => this.wodescPopup()}>View more</button></span> : null
                                                            }
                                                        </div>
                                                    </div> : null}
                                                <div class="my-3">
                                                    <button className="btn btn-primary custom-btn-primary primary-btn " style={{ padding: "5px" }}
                                                        onClick={(e) => this.WOpopup()}> Select WO</button>
                                                    {this.state.disableWo != false ? <><button
                                                        className="btn btn-primary custom-btn-primary primary-btn " style={{
                                                            padding: "5px",
                                                            marginLeft: "4px"
                                                        }} onClick={(e) => this.ClearWO()}> Clear WO</button></> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <a href="#" className="float-right mx-2 filter-icon-toggle" onClick={(e) => this.mobAdvfiltershow(e)}>
                                                <img src="images/filter-icon.png" alt="Filter Icon" />
                                            </a>
                                            {this.state.FilterValuedata.length > 0 ? <div>
                                                <a className="layout-icons float-right" onClick={() => this.GridChange(0)} title="Grid view">
                                                    {this.state.GridType != 1 ? <img src="images/grid-active.svg" alt="grid Icon" /> : <img src="images/grid.svg" alt="grid Icon" />}
                                                </a>
                                                <a className="mx-2 layout-icons float-right" onClick={() => this.GridChange(1)} title="List view">
                                                    {this.state.GridType == 1 ? <img src="images/list-active.svg" alt="List Icon" /> : <img src="images/list.svg" alt="List Icon" />}
                                                </a>
                                            </div> : null}

                                        </div>
                                        <div className="col-md-12 my-3">
                                            {this.state.FilterValuedata.length > 0 ? <div>
                                                {this.state.GridType == 1 ? this.bindSearchResultsGridView() :
                                                    <div className="row mb-1 py-2 gridlayout-container">{this.bindSearchResults()}</div>
                                                }</div> : (<section className="site-undercontruction container text-center center-panel pt-4">
                                                    <div className="img-section">
                                                        <img src="images/nosearch.jpg" alt="imagefile" />
                                                    </div>
                                                    <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                                    </p>
                                                </section>)}


                                        </div>

                                        <div className="col-md-12 border-top">
                                            {this.state.showpagination ? <span className="list-count float-left mt-3"><span className="mr-1">{this.state.itemperpage}</span><Trans>Items In</Trans><span className="mx-1">1</span><Trans>Page</Trans> (<span className="mr-1">{this.state.TotalItemCount}</span><Trans>Items In</Trans><span className="mx-1">{this.state.pageCount}</span><Trans>Page</Trans> )</span> :
                                                <span className="list-count"><span className="mr-1">{this.state.TotalItemCount}</span><Trans>Items In</Trans><span className="mx-1">{this.state.pageCount}</span><Trans>Page</Trans> </span>}
                                            {this.state.FilterValuedata.length > 10 ? <div>
                                                {this.state.GridType == 1 ? <select className="form-control float-left itemPerPage item-per-page" id="itemperpagecount" defaultValue={'10'} onChange={() => this.handlePageCOunt()}>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                </select> : <select className="form-control float-left itemPerPage item-per-page" id="itemperpagecount" defaultValue={'12'} onChange={() => this.handlePageCOunt()}>
                                                        <option value="12">12</option>
                                                        <option value="24">24</option>
                                                        <option value="60">60</option>
                                                    </select>}</div> : null}
                                            <ul className="custom-pagination pagination float-right mb-2 mt-3">
                                                {this.state.showpagination ? <ReactPaginate
                                                    previousLabel={<span className="right-pagination-arrow" />}
                                                    nextLabel={<span className="left-pagination-arrow" />}
                                                    previousClassName={'page-item'}
                                                    nextClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextLinkClassName={'page-link'}
                                                    disabledClassName={'disabled'}
                                                    breakLabel={'...'}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link'}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'custom-pagination pagination'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    activeClassName={'active'}
                                                    hrefBuilder={() => '#'}
                                                    forcePage={this.state.pageNo - 1}
                                                /> : null}
                                            </ul>
                                        </div>
                                        {/* {this.state.FilterValuedata.length > 1 ? <div className="col-md-12 my-3">
                                            <button className="btn btn-primary custom-btn-primary primary-btn float-right" onClick={(e) => this.Addtocart(e)}><Trans>Add Multiple Items</Trans></button>
                                        </div> : null} */}
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            {/*[05/29/2023, PS_ZE_123_APP_05, Dhevanesam R]*/}
            <div className="modal userid" id="WorkOrder" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog  custom-popup modal-dialog-centered">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title mt-1"><Trans>Work Order Number</Trans></h4>
                            <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.WOClose()}>
                                X
            </button>
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">
                            <div className="row justify-content-center">
                                <div className="col-md-10 my-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label catalog-label"><Trans>workorder_key</Trans><span> #</span></label>
                                        <div className="float-left search-autocomplete col-md-7 home-search " style={{ zIndex: "9999" }}>
                                            {this.state.Login_UserBU == 'I0635' ?
                                                <Autocomplete
                                                    getItemValue={this.getItemWoValue}
                                                    items={this.state.suggestionWo}
                                                    renderItem={this.renderWoItem}
                                                    value={this.state.ValidatedWo}
                                                    onChange={e => this.handleOnChangeWo(e)}
                                                    onSelect={(e) => this.onSelectItem(e)}
                                                    disabled={this.state.disableWo}
                                                    inputProps={{ placeholder: "Enter Work Order", className: "form-control float-left", id: "#OLWorkOrderTB", disabled: this.state.disableWo }}
                                                    maxLength="50"
                                                /> : <input type="text" className="form-control " placeholder="Enter Work Order" id="#OLWorkOrderTB" name="WorkOrderNo" disabled={this.state.disableWo} maxLength="80" onChange={e => this.handleOnChangeWoText(e)} value={this.state.ValidatedWo} />}
                                            {this.state.WOmsg != "" ? <span id="WOError" className="DropDown-Font-Size errorcolour"><Trans>{this.state.WOmsg}</Trans></span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mb-2">
                                {CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? <span className=" W-50 text-left " style={{ fontSize: '15px' }}>*<Trans>The items in the cart will be removed by changing the WO</Trans></span> : null}
                                <button className={`btn btn-primary custom-btn-primary primary-btn px-3  ${CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? ' mt-3' : ''}`} id="WOOK" onClick={() => this.WOClick()}><Trans>Submit</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Favourite Item Pop up*/}
            <div className="modal" id="add-fav-popup" data-backdrop="static" data-keyboard="false">

                <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title mt-1"><Trans>Add Favorites</Trans></h4>
                            <button type="button" className="btn close-btn" data-dismiss="modal">
                                X
            </button>
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">
                            <div className="row mt-3">
                                <div className="col-12 edit-desc-field mb-4">
                                    <div className="row mb-3">
                                        <label className="col-md-5 quick-label"><Trans>Item ID</Trans></label>
                                        {/* Zeus - 232 */}
                                        <label className="col-md-7 quick-value" id="FavItemId" >{this.state.FavMFGPartNUMPopUp}</label>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-5 quick-label "><span style={{ "color": "red" }}>*</span><Trans>Category</Trans></label>
                                        {/*<div className="col-md-7">
                                                <textarea className="form-control custom-textarea" id="FavYourCatg" maxLength="40" rows={3} defaultValue={''} />
                                            </div>*/}
                                        <div className="float-left search-autocomplete col-md-7 home-search" style={{ zIndex: "9999" }}>
                                            <Autocomplete
                                                getItemValue={this.getItemValue}
                                                items={this.state.bindCategory}
                                                renderItem={this.renderItem}
                                                value={this.state.yourFavCat}
                                                onChange={e => this.onChange(e, "CAT")}
                                                onSelect={e => this.onSelect(e, "CAT")}
                                                inputProps={{ placeholder: "Search", className: "form-control float-left" }}
                                                maxLength="40"
                                            />
                                            <span className="errorspan1" style={{ fontSize: '10px' }} id="favordcatid" />
                                            {this.state.categoryError ? <span className=" W-100 errorspan mr-3" style={{ fontSize: '15px' }}>Enter category</span> : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Sub category</Trans></label>

                                        <div className="float-left search-autocomplete col-md-7 home-search">
                                            <Autocomplete
                                                getItemValue={this.getItemValue}
                                                items={this.state.bindSubCategory}
                                                renderItem={this.renderItem}
                                                value={this.state.yourFavSubCat}
                                                onChange={e => this.onChange(e, "SUBCAT")}
                                                onSelect={e => this.onSelect(e, "SUBCAT")}
                                                inputProps={{ placeholder: "Search", className: "form-control float-left", disabled: this.state.yourFavCat.length > 0 ? false : true }}
                                                maxLength="40"
                                            />
                                            <span className="errorspan1" style={{ fontSize: '10px' }} id="favordsubcatid" />
                                            {this.state.subCategoryError ? <span className=" W-100 errorspan1 text-left " style={{ fontSize: '15px' }}>Enter sub category</span> : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-5 quick-label"><Trans>Item description</Trans></label>
                                        <label className="col-md-7 quick-value" id="FavItemDescrip" >{this.state.FavItemDescripPopUp}</label>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-5 quick-label"><Trans>Your description</Trans></label>
                                        <div className="col-md-7">
                                            <textarea className="form-control custom-textarea" id="FavYourDescrip" maxLength="100" rows={5} defaultValue={''} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-5 quick-label">MFG</label>
                                        <label className="col-md-7 quick-value" id="FavMFG" >{this.state.FavMFGPopUp}</label>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-5 quick-label"><Trans>Supplier Part Number</Trans> : </label>
                                        <label className="col-md-7 quick-value" id="FavMFGPartNUM" >{this.state.FavItemIdPopUp}</label>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4" id="FavSaveBtn" onClick={() => this.AddfavItem()}><Trans>Save</Trans></button>
                                </div>
                                <div>
                                    <label id="txtUOMHide" >{this.state.txtUOMHidePopUp}</label>
                                    <label id="txtMfgItemidHide" >{this.state.txtMfgItemidHidePopUp}</label>
                                    <label id="txtItemidHide" >{this.state.txtItemidHidePopUp}</label>
                                    <label id="txtMFGHide" >{this.state.txtMFGHidePopUp}</label>
                                    <label id="ItemType" >{this.state.ItemType}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*work order popup starts here*/}
            <div className="modal fade work-order-info" id="work-order-details-search" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog custom-work-order-width modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header sp-delv-header">
                            <h6 className="modal-title font-medium font-16" id="exampleModalLongTitle">Work Order Info</h6>
                            <button type="button" className="btn close-btn" data-dismiss="modal">
                                X
            </button>
                        </div>
                        <div className="modal-body py-4">
                            <div className="row">
                                {/*work order starts here*/}
                                <div className="col-md-12 mb-3">
                                    <label className="font-16 font-medium w-100 color-sec-black">Work Order #</label>
                                    <label className="font-16 font-regular modal-inner-label-value">{this.state.ValidatedWo}</label>
                                </div>
                                <div className="col-md-12">
                                    <label className="font-16 font-medium w-100 color-sec-black">Description</label>
                                    <label className="font-16 font-regular modal-inner-label-value">{this.state.workOrderDescription}</label>
                                </div>
                                {/*work order ends here*/}
                            </div>
                        </div>
                        {/*footer starts here*/}
                        <div className="modal-footer d-flex justify-content-center border-0 pt-0 pb-4">
                            <button type="button" className="btn custom-btn-primary px-4 font-16 font-medium" data-dismiss="modal">OK</button>
                        </div>
                        {/*footer starts here*/}
                    </div>
                </div>
            </div>
            {/*work order popup end here*/}
            {/* Z-279 */}
            <div className="modal" id="locationaccess" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered" style={{ maxWidth: "578px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Steps to enable location
                                    </h5>
                                    <button type="button" className="btn close-btn" data-dismiss="modal">X</button>
                                {/* Modal body */}
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="text-center my-2">
                                            <p className="text-left info-label text-danger w-100">Note : Please enable location to view Real Time Inventory pickup stores.</p>
                                            <p className="text-left info-label w-100">1.Click  <img src="Images/location-img.png"></img> The location icon in the top-right corner of the browser window.</p>
                                            <p className="text-left info-label w-100">2.Select "Always allow https://sdizeus2test.sdi.com to access your location" </p>
                                            <div className="text-left"><img src="Images/location-access-steps-1.png" style={{ width: "300px" }}></img></div>
                                            <p className="text-left info-label mt-2 w-100">3.Click the "Done" button</p>
                                            <p className="text-left info-label mt-2 w-100">4.To apply your updated settings to this site, reload the page</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-2 text-align-right">
                                <button className="btn custom-rti-button px-lg-5 px-3 mx-3 font-bold" data-dismiss="modal" onClick={() => this.isRTIItem(this.state.selectedItemIndex, false)}>Add for delivery</button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-lg-5 px-4 font-bold" data-dismiss="modal" onClick={()=> this.locationEnable()}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment >
        );
    };
}
//[PC_07, 09/08/21, Author - Priyanka D]
export default withTranslation()(SearchResults);
