/**
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Non Catalog request page component. The component displays the Non Catalog request page details.
 * 
 * App Name: SDI-Walmart
 * Author: Chandrasekar
 * Created Date: 08/11/2020 
 */
import React, { Component } from 'react';
/**
 * Ref: NC_PC_02
 * Importing the action and store file and set to an object.
 */
import * as NonCatalogAction from '../actions/NonCatalogAction';
import NonCatalogStore from '../stores/NonCatalogStore';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import QuickItemComponent from '../components/QuickItemComponent';
import * as MenuAction from '../actions/MenuAction';
//[PC_07, 09/09/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
//window.jQuery = $;
//require("jquery-toast-plugin/dist/jquery.toast.min.js");
//require("jquery-toast-plugin/dist/jquery.toast.min.css");
let qckKeycounter = 0;
let type = 0;
let rmvAttachindex = -1;
let currentPagePath;
let Session_ZEUSNOCATALOGSITE;
class NonCatalogComponent extends Component {
    /**
    * Ref: NC_PC_04
    * Define the constructor that takes props as parameter
    * 
    * @param props
    */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.saveNonCatalog = this.saveNonCatalog.bind(this);
        this.quickItemClick = this.quickItemClick.bind(this);
        this.closePopup = this.closePopup.bind(this);
        //this.checkCatalog = this.checkCatalog.bind(this);
        this.clearFormValues = this.clearFormValues.bind(this);
        this.handleAttachment = this.handleAttachment.bind(this);
        this.loadAttachments = this.loadAttachments.bind(this);
        this.downloadAttachment = this.downloadAttachment.bind(this);
        this.bindAttachments = this.bindAttachments.bind(this);
        this.removeAttachmentResponse = this.removeAttachmentResponse.bind(this);
        this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
        this.updateFavPageResponse = this.updateFavPageResponse.bind(this);
        this.handleFavorite = this.handleFavorite.bind(this);
        this.handleQuantity = this.handleQuantity.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
        /**
       * Ref: NC_PC_05
       * Set the initial values of the state variables
       */
        this.state = {
            mfgPartNumber: "",
            mfgName: "",
            recommendedSupplier: "",
            supplierPrtNbr: "",
            description: "",
            quantity: "",
            uom: "",
            estimatedPrice:"",
            unitOfMeasureData: [],
            attachmentPathArr: [],
            isInvalid: false,
            txtItemID: "",
            quickItemVisible: false,
            strCplusitemid: "0",
            attachmentErrormsg: "",
            isFavorited: false,
            Page_ID: "",
            islblInfo1Visible:false

        }
    };
    /**
   * Ref: NC_PC_06
   * componentwillMount method will be first method which will be invoked
   * when Non Catalog page is loaded
   */
    componentDidMount() {
        debugger
        console.log("hasReqApprovalCheck", CookieService.getLocalStorageValues("hasReqApprovalCheck"));
        $("#quickItemSuccess").hide();
        $(`#nonCatalogSaveSuccess`).hide();
        Session_ZEUSNOCATALOGSITE = CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE");
        let nonCatalogBO = {
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP")
        }
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');

        /**
        * Ref: NC_PC_08
        * Invoke the action to get the page load data from API.
        */
        NonCatalogAction.getUnitOfMeasures(nonCatalogBO);
        /**
        * Invoke the action to check the current page is favorited
        */
        NonCatalogAction.getCurrentFavPage(getFavPageBO);
        /*
         * Ref: NC_PC_07
         * Define the listener methods
         */
        NonCatalogStore.on('load', this.loadUnitOfMeasures);
        NonCatalogStore.on('save', this.saveSuccess);
        NonCatalogStore.on('quickItemCheck', this.loadQuickItem);
        //NonCatalogStore.on('checkCatalogResponse', this.loadCheckCatalog);
        NonCatalogStore.on('attachmentResponse', this.loadAttachments);
        NonCatalogStore.on('rmvAttach', this.removeAttachmentResponse);
        /*
         * Favorite page response listeners
         */
        NonCatalogStore.on('favpage', this.loadFavPageResponse);
        NonCatalogStore.on('updateFavPage', this.updateFavPageResponse);
    }
    componentWillUnmount() {
        NonCatalogStore.removeListener('load', this.loadUnitOfMeasures);
        NonCatalogStore.removeListener('save', this.saveSuccess);
        NonCatalogStore.removeListener('quickItemCheck', this.loadQuickItem);
        //NonCatalogStore.removeListener('checkCatalogResponse', this.loadCheckCatalog);
        NonCatalogStore.removeListener('attachmentResponse', this.loadAttachments);
        NonCatalogStore.removeListener('rmvAttach', this.removeAttachmentResponse);
        NonCatalogStore.removeListener('favpage', this.loadFavPageResponse);
        NonCatalogStore.removeListener('updateFavPage', this.updateFavPageResponse);
    }
    /*
     * Listener method for the response of Save/Delete favorite page
     */
    updateFavPageResponse = () => {

        if (NonCatalogStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(NonCatalogStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(NonCatalogStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    /*
     * Handle change method for favorite page checkbox
     */
    handleFavorite = (event) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        this.setState({ isFavorited: event.target.checked });
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Non Catalog Request",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            NonCatalogAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            NonCatalogAction.deleteFavPage(deleteFavPageBO)
        }
    };
    /*
     * Listener method for loading favorite page icon
     */
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(NonCatalogStore.currentFavPageResponse);
        console.log("favPageResponse", favPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    /*
     * Common method to refresh the master data in menu
     */
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        MenuAction.getMasterData(masterBO);
    };
    /**
    * Closepopup method for closing Quick item popup
    * @param message
    */
    closePopup = (message) => {
        const { t } = this.props;
        $('#quickconfirm').modal('hide');
        console.log("Hit", message);
        let uomDefaultValue = this.state.unitOfMeasureData.filter((item) => item.UNIT_OF_MEASURE == "EA").length > 0 ? "EA" : this.state.unitOfMeasureData[0].UNIT_OF_MEASURE;
        if (message == 1) {
            $("#quickItemSuccess").show();
            $("#quickItemSuccessID").text(this.state.mfgPartNumber.toLocaleUpperCase());
            $(`#nonCatalogSaveSuccess`).hide();
            //  alert(`Item ${this.state.txtItemID} has been added to cart`); 
            this.setState({
                mfgPartNumber: "", mfgName: "", recommendedSupplier: "", supplierPrtNbr: "", description: "", quantity: "", uom: uomDefaultValue, attachmentPathArr: [],
                txtItemID: "", strCplusitemid: "0", attachmentErrormsg: "", estimatedPrice: "", isInvalid: false
            });
            this.refreshMenu();
        } else if (message == 2) {
            this.setState({
                mfgPartNumber: "", mfgName: "", recommendedSupplier: "", supplierPrtNbr: "", description: "", quantity: "", uom: uomDefaultValue, attachmentPathArr: [],
                txtItemID: "", strCplusitemid: "0", attachmentErrormsg: "", estimatedPrice: "", isInvalid: false
            });
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical Issue Contact SDI Customer Care")
            })
        }
    };
    /*
     * Ref: NC_PC_13
     * Listener method of remove attachment response
     */
    removeAttachmentResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let isValid = NonCatalogStore.removeAttachmentResponse.indexOf("success") >= 0 ? true : false;
        if (isValid) {
            let tempAttachments = this.state.attachmentPathArr;
            tempAttachments.splice(rmvAttachindex, 1);
            this.setState({ attachmentPathArr: tempAttachments });
            rmvAttachindex = -1;
        }
    };
    /*
     * Ref: NC_PC_17
     * Listener method of loading attachments
     */
    loadAttachments = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        $(`#nAttachments`).val('');
        let isValid = NonCatalogStore.attachmentResponse.indexOf("You have exceeded the length of total file names") >= 0 ? false : true;
        if (isValid) {
            let attachmentResponse = JSON.parse(NonCatalogStore.attachmentResponse);
            let tempAttachments = this.state.attachmentPathArr;
            tempAttachments = tempAttachments.concat(attachmentResponse);
            this.setState({ attachmentPathArr: tempAttachments });
            console.log("tempAttachments", tempAttachments);
        } else {
            //[6/3/22, Bug - Bug - 2183,2181 and 2180, Dhevanesam R]
            this.setState({
                attachmentErrormsg: "You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names."
            });
        }
    };
    /** 
    * Ref: NC_PC_10
    * Handle method for attachments on change
    * @param event
    */
    handleAttachment = (event) => {
        const { t } = this.props;
        let files = event.target.files;
        console.log("event", files);
        //[6/22/22, PC_AU_02, Bug - 2075, Dhevanesam R]
        let filetype = files[0].type;
        if (!(filetype.toLowerCase().includes('jpg') || (filetype.toLowerCase().includes('png')) || (filetype.toLowerCase().includes('jpeg')) || (filetype.toLowerCase().includes('pdf')))) {
            //alert("You can choose only the Image and PDF files!!");
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "You can choose only the Image and PDF files!!"
            })
            return;
        }
        if (files.length > 0) {
            this.setState({ attachmentErrormsg: "" });
            if (this.state.attachmentPathArr.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    let isDuplicate = this.state.attachmentPathArr.filter((attach) => attach.FileName == files[i].name).length > 0 ? true : false;
                    //[6/3/22, Bug - Bug - 2183,2181 and 2180, Dhevanesam R]
                    if (isDuplicate) {
                        this.setState({ attachmentErrormsg: "Selected file is already attached, please try again" });
                        return false;
                    }
                }
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            NonCatalogAction.addAttachment(files);
        }

    };
    /**
    * Ref: NC_PC_12
    * Listener method of downloading attachments
    * @param e
    * @param attachment
    */
    downloadAttachment = (e, attachment) => {
        e.preventDefault();
        let downloadBO = {
            fileName: attachment.FileName,
            filePath: attachment.FilePath
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        NonCatalogAction.downloadAttachment(downloadBO);
    };
    /**
     * Ref: NC_PC_13
     * On click method of removing attachments
     * @param filepath
     * @param index
     */
    removeAttachments = (filepath,index) => {
        console.log(filepath);
        rmvAttachindex = index
        let removeBO = {
            filePath: filepath
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        NonCatalogAction.removeAttachment(removeBO);
    }
    /**
    * Binding attachments using map function
    */
    bindAttachments = () => {
        return this.state.attachmentPathArr.map((attachment,index) => {

            return (
                <span className="attachment-file-name pl-0 my-2 pr-3 d-inline-block" key={index}>
                    <a href="#" className="noncat-Attachment-overflow" data-toggle="tooltip" title={attachment.FileName} onClick={(e) => this.downloadAttachment(e,attachment)}>{attachment.FileName}</a>
                    <span className="attachment-remove-icon ml-1" onClick={() => this.removeAttachments(attachment.FilePath, index)}>X</span>
                </span>
            );
        });
    };
    /**
     * Ref:NC_PC_16
     * Listner method for check catalog response
     */
    //Start of commenting[10/16/23, Zeus - 218] - Code is commented because Check catalog functionality is been removed from the Panel
    /*loadCheckCatalog = () => {
        const { t } = this.props;
        let checkCatalogResponse = JSON.parse(NonCatalogStore.checkCatalogResponse).Table1[0];
        if (!checkCatalogResponse.strcustomerItemID || checkCatalogResponse.strcustomerItemID == "") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Item does not exist in the catalog")
            })
            //alert(t("Item does not exist in the catalog"));
        } else {
            this.setState({ txtItemID: checkCatalogResponse.strItemID, strCplusitemid: checkCatalogResponse.strcustomerItemID });
            $('#quickconfirm').modal('show');
        }
        console.log("checkCatalogResponse", checkCatalogResponse);
        qckKeycounter = qckKeycounter + 1;
    }*/
    // End of commenting
    /**
     * Ref:NC_PC_19
     * Listner method for quick item check response
     */
    loadQuickItem = () => {
        const { t } = this.props;
        let quickItemChkRes = JSON.parse(NonCatalogStore.quickItemcheck)[0];
        qckKeycounter = qckKeycounter + 1;
        if (quickItemChkRes.strCplusitemid != "0") {
            $('#quickconfirm').modal('show');
            this.setState({ strCplusitemid: quickItemChkRes.strCplusitemid })
        } else {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            //Bug-2291 In search quick item entry field - validation message is displaying wrongly " Inv_Itm_No" [7/4/2022] Dhamotharan P
            //alert(t("Invalid item id"));
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Invalid item id")
            })
        }
        // this.setState({ quickItemVisible: true });
    }
    /**
    * Reusable method to clear form values
    */
    clearFormValues = () => {
        $("#quickItemSuccess").hide();
        $(`#nonCatalogSaveSuccess`).hide();
        let uomDefaultValue = this.state.unitOfMeasureData.filter((item) => item.UNIT_OF_MEASURE == "EA").length > 0 ? "EA" : this.state.unitOfMeasureData[0].UNIT_OF_MEASURE;
        this.setState({
            mfgPartNumber: "",
            mfgName: "",
            recommendedSupplier: "",
            supplierPrtNbr: "",
            description: "",
            quantity: "",
            uom: uomDefaultValue,
            attachmentPathArr: [],
            txtItemID: "",
            strCplusitemid: "0",
            attachmentErrormsg: "",
            estimatedPrice:"",
            isInvalid: false
        });
    };
    /**
     * Ref:NC_PC_18
     * Listener method after saving non catalog request to cart
     */
    saveSuccess = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let saveResponse = JSON.parse(NonCatalogStore.saveResponse);
        console.log("saveResponse", saveResponse);
        if (type == 1) {
            this.clearFormValues();
            debugger;
            $(`#nonCatalogSaveSuccess`).show();
            this.refreshMenu();
        } else {
            this.props.history.push({
                pathname: "/shoppingcart",
            });
        }
    };
    /**
    * Ref:NC_PC_14
    * Click method of quick item entry
    */
    quickItemClick = () => {
        const { t } = this.props;
        $("#quickItemSuccess").hide();
        // 12/18/2023 - Zeus 232 - INC0028559 Functionality added to Zeus when ordering Non-Catalog items to search catalog for MFG PN match back to catalog - Johnprinto
        if (this.state.mfgPartNumber.trim()) {
            //let quickItemBO = {
            //    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            //    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            //    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            //    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            //    txtItemID: this.state.txtItemID.trim(),
            //    Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            //    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            //    Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE")
            //};
            qckKeycounter = qckKeycounter + 1;
            this.setState({ strCplusitemid: qckKeycounter })//quickItemChkRes.strCplusitemid
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            $('#quickconfirm').modal('show');
            //NonCatalogAction.QuickItemCheck(quickItemBO);
        }
    }
    /**
     * Ref:NC_PC_09
     * Click method of Check catalog click
     */
    //Start of commenting[10/16/23, Zeus-218] - Code is commented because Check catalog functionality is been removed from the Panel
    /* checkCatalog = (event) => {
         const { t } = this.props;
         event.preventDefault();
         if (this.state.mfgName && this.state.mfgPartNumber) {
             let checkCatalogBO = {
                 txtMfgName: this.state.mfgName.trim(),
                 txtMfgPartNum: this.state.mfgPartNumber.trim(),
                 txtItemID: this.state.txtItemID.trim(),
                 Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
             };
             $(`#${SDIConstants.SDILoader}`).removeClass('hide');
             NonCatalogAction.checkCatalogClick(checkCatalogBO);
         } else {
             //alert(t("Req MFG"));
             swal({
                 allowOutsideClick: false,
                 closeOnClickOutside: false,
                 text: t("Req MFG")
             })
         }
     };*/
    //End of commenting
    /**
    * Ref:NC_PC_15
    * Listener method for loading unit of measures data
    */
    loadUnitOfMeasures = () => {
        debugger;
        let pageLoadResponse = JSON.parse(NonCatalogStore.pageLoadData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        console.log("pageLoadResponse", pageLoadResponse);
        if (pageLoadResponse.FlagData.length > 0) {
            let islblInfo1VisibleRes = pageLoadResponse.FlagData[0].islblInfo1Visible.toLowerCase() == `true`;
            this.setState({ islblInfo1Visible: islblInfo1VisibleRes });
        }

        if (pageLoadResponse.Data) {
            var obj = {};
            for (var i = 0, len = pageLoadResponse.Data.length; i < len; i++)
                obj[pageLoadResponse.Data[i]['DESCR']] = pageLoadResponse.Data[i];

            pageLoadResponse.Data = new Array();
            for (var key in obj)
                pageLoadResponse.Data.push(obj[key]);
            let uomDefaultValue = pageLoadResponse.Data.filter((item) => item.UNIT_OF_MEASURE == "EA").length > 0 ? "EA" : pageLoadResponse.Data[0].UNIT_OF_MEASURE;

            this.setState({ unitOfMeasureData: pageLoadResponse.Data, uom: uomDefaultValue });
        }
    };
    /**
     * Ref:NC_PC_20
     * Bind method for UOM dropdown
     */
    bindUOMDropdown = () => {
        return this.state.unitOfMeasureData.map((uom, index) => {
            return (<option key={index} value={uom.UNIT_OF_MEASURE}>{uom.DESCR}</option>)
        });
    };
    /**
     * Handle method for quantity textbox
     */
    handleQuantity = (e) => {
        const re = SDIConstants.quantityRegEX;
        if (e.target.value === '' || (re.test(e.target.value) && e.target.name == "quantity") || (e.target.name == "estimatedPrice")) {
            //[6/12/2023, Bug - 3707 & 3683, Dhevanesam R]
            if (Math.round(e.target.value) != "0" || !e.target.value) {
                if (e.target.name == "quantity") {
                    this.setState({ quantity: e.target.value });
                }
                else {

                    let value = e.target.value.replace(/[^0-9.]/g, '')
                    let value1 = value

                    if (value1.replace(/[^.]/g, "").length < 2) {
                        this.setState({ estimatedPrice: value });
                    }
                }
            }
        }
    };
    /**
     * Handle method for all form input controls
     */
    handleChange = (event) => {
        $("#quickItemSuccess").hide();
        this.setState({ [event.target.name]: event.target.value });
        console.log("value",event.target.value);
    };
    /**
     * Click method for cancel button
     */
    cancelClick = () => {
        this.props.history.push({
            pathname: "/HomePage",
        });
    };
    /**
     * Ref:NC_PC_11
     * Click method for Save and Save/More buttons
     * @param saveType
     */
    saveNonCatalog = (saveType) => {
        debugger;
        const { t } = this.props;
        $(`#nonCatalogSaveSuccess`).hide();
        if (!this.state.mfgPartNumber || !this.state.mfgName || !this.state.quantity || !this.state.description || !this.state.uom) {
            this.setState({ isInvalid: true });
            return false;
        }
        let attachmentStr = "";
        if (this.state.attachmentPathArr.length > 0) {
            attachmentStr = this.state.attachmentPathArr.map(e => e.FilePath).join("|");
            if (attachmentStr.length > 254) {
                //[6/3/22, Bug - Bug - 2183,2181 and 2180, Dhevanesam R]
                this.setState({
                    attachmentErrormsg: "You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names."
                });
                return false;
            }
        }
        let savenonCatalogBO = {
            txtSupplier: this.state.recommendedSupplier.trim(),
            txtSuppPartNum: this.state.supplierPrtNbr.trim(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN"),
            txtEstPrice: this.state.estimatedPrice == "" || this.state.estimatedPrice == null || this.state.estimatedPrice == undefined ? "0" : this.state.estimatedPrice,
            txtDesc: this.state.description.trim(),
            txtMfgPartNum: this.state.mfgPartNumber.trim(),
            txtMfgName: this.state.mfgName.trim(),
            txtQty: this.state.quantity,
            DropUOM: this.state.uom,
            rblcatalogVisible: false,
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            filePath: attachmentStr,

        };
        this.setState({ isInvalid: false });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        NonCatalogAction.saveNonCatalog(savenonCatalogBO);
        type = saveType;
    };

    /**
     * Ref:NC_PC_20
     * Render method to bind the controls
     */
    //[04/08/22, CAS - 31, Dhevanesam R]
    render() {
        //[11/26/21, CAS - 31, Priyanka D]
        const { t } = this.props;
        return (
            <div className="container-fluid content-section">
                <div className="row">
                    <div className="container-fluid content-margin">
                        <div className="row non-catalog-req-form">
                            <div className="col-md-12 page-heading mb-3">
                                <h5 className="page-title"><Trans>Non-Catalog Request Entry</Trans></h5>
                                <div className="fav-item-maintenance-icon mt-1">
                                    <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-1">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 form-container">
                                <span className="float-right indicate-alert"><Trans>Required_key</Trans></span>
                            </div>
                            {/* {Session_ZEUSNOCATALOGSITE!=`Y`?<div className="col-md-12 mb-4">
                                <div className="col-md-4 input-group charge-code-group mr-3">
                                     <input type="text" className="form-control charge-code-control" maxLength="100" placeholder={t("SearchQuickItem_key")} value={this.state.txtItemID} name="txtItemID" onChange={this.handleChange}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <span className="shopping-search-icon" data-backdrop="static" data-keyboard="false" onClick={this.quickItemClick}/>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div> : null}*/}
                            {/** 12/28/2023 - Zeus 232 - INC0028559 Functionality added to Zeus when ordering Non-Catalog items to search catalog for MFG PN match back to catalog - Johnprinto */}
                            <span className="float-left err-msg text-danger col-md-12 text-center bold mb-3" id="quickItemSuccess">{t(`Item`) + " "}<span id="quickItemSuccessID"></span> {" " + t(`has been added to cart`)}</span>
                            <span className="float-left err-msg text-danger col-md-12 text-center bold mb-3" id="nonCatalogSaveSuccess"><Trans>Item has been added to cart</Trans></span>

                            {/*[4/20/22, CAS -31, Dhevanesam R]*/}
                            <div className="col-md-6 mb-4">
                                {/*[PC_01, Priyanka D, 09/17/21]Reduce the width of the labels inorder to reduce the space beween the labels and its textboxes */}
                                <label className="float-left col-md-5 mandatory-after form-label catalog-label" style={{ width: "187px" }}><Trans>Manufacturer Name</Trans></label>
                                <div className="float-left col-md-7 p-0">
                                    <input type="text" className={`form-control ${this.state.isInvalid && this.state.mfgName == "" ? 'border-color-red' : ''}`} name="mfgName" maxLength="30" placeholder={t("Enter Name")} value={this.state.mfgName} onChange={this.handleChange} />
                                    {/*{this.state.isInvalid && this.state.mfgName == "" ? <span className="float-left err-msg text-danger"><Trans>Please enter Manufacturer Name</Trans></span> : null}*/}
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 mandatory-after form-label catalog-label" style={{ width: "187px" }}><Trans>Manufacturer Part Number</Trans></label>
                                <div className="float-left col-md-7 p-0">
                                    {/** 12/28/2023 - Zeus 232 - INC0028559 Functionality added to Zeus when ordering Non-Catalog items to search catalog for MFG PN match back to catalog - Johnprinto */}
                                    <input type="text" className={`form-control ${this.state.isInvalid && this.state.mfgPartNumber == "" ? 'border-color-red' : ''}`} name="mfgPartNumber" maxLength="50" value={this.state.mfgPartNumber} placeholder={t("Enter Value")} onChange={this.handleChange} onBlur={this.quickItemClick} />
                                    {/*{this.state.isInvalid && this.state.mfgPartNumber == "" ? <span className="float-left err-msg text-danger"><Trans>Please enter Manufacturer Part Number</Trans></span> : null}*/}
                                    {/*{Session_ZEUSNOCATALOGSITE != `Y` ? <p className="float-left col-md-12 p-0 helptext my-2"><a href="#" className="helptext-link mr-1" onClick={this.checkCatalog}><Trans>Check Catalog</Trans></a> (<Trans>MFG Part Number and MFG Name Required</Trans>)</p> : null}*/}
                                </div>
                            </div>
                            {/*[10/08/21, PC_01, Bug ID - 1733, Dhevanesam R]*/}
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 form-label catalog-label" style={{ width: "187px" }}><Trans>Recommended Supplier</Trans></label>
                                {/*[12/13/23, PC_NC_01, Zeus - 231, Changed the char length to 50 from 20 - Harshitha G]*/}
                                <input type="text" className="float-left col-md-7 form-control" maxLength="50" placeholder={t("Enter Value")} value={this.state.recommendedSupplier} name="recommendedSupplier" onChange={this.handleChange}  />
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 form-label catalog-label" style={{ width: "187px"}}><Trans>Supplier Part Number</Trans></label>
                                <input type="text" className="float-left col-md-7 form-control" maxLength="100" placeholder={t("Enter Value")}  name="supplierPrtNbr" value={this.state.supplierPrtNbr} onChange={this.handleChange}  />
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 mandatory-after form-label catalog-label" style={{ width: "187px" }}><Trans>Description</Trans></label>
                                <div className="float-left col-md-7 p-0">
                                    <textarea className={`form-control custom-textarea ${this.state.isInvalid && this.state.description == "" ? 'border-color-red' : ''}`} maxLength="200" placeholder={t("Enter Description")} name="description" value={this.state.description} onChange={this.handleChange} />
                                    {/*{this.state.isInvalid && this.state.description == "" ? <span className="float-left err-msg text-danger"><Trans>Please enter Description</Trans></span> : null}*/}
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 mandatory-after form-label catalog-label" style={{ width: "187px"}}><Trans>Quantity</Trans></label>
                                <div className="float-left col-md-7 p-0">
                                    <input type="text" className={`form-control ${this.state.isInvalid && this.state.quantity == "" ? 'border-color-red' : ''}`} maxLength="8" placeholder={t("Enter Value")} name="quantity" value={this.state.quantity} onChange={this.handleQuantity} />
                                    {/*{this.state.isInvalid && this.state.quantity == "" ? <span className="float-left err-msg text-danger"><Trans>Please enter quantity</Trans></span> : null}*/}
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 form-label catalog-label" style={{ width: "187px" }}><Trans>unitofmeasure_key</Trans></label>
                                <div className="float-left col-md-7 p-0">
                                    <select className="form-control" name="uom" value={this.state.uom} onChange={this.handleChange}>
                                        {this.bindUOMDropdown()}
                                    </select>
                                    {this.state.islblInfo1Visible ? <span className="float-left err-msg text-danger"><Trans>Warning! Default UOM list displayed - no cross reference data available for the current Business Unit</Trans></span> : null}
                                    {/* {this.state.isInvalid && this.state.uom == "" ? <span className="float-left err-msg text-danger">Please select UOM</span> : null}*/}
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 form-label catalog-label" style={{ width: "187px" }}><Trans>Attachment</Trans></label>
                                {/* <input type="file" class="float-left col-md-7 form-control"/> */}
                                <div className="col-md-7 custom-file mb-3 p-0">
                                    {/*[6/22/22, PC_AU_01, Bug - 2075, Dhevanesam R]*/}
                                    <input type="file" multiple className="custom-file-input" title=" " accept="application/pdf, image/jpeg, image/png" id="nAttachments" name="filename"  onChange={this.handleAttachment} />
                                    <label className="custom-file-label" htmlFor="customFile">{t("Choose File")}</label>
                                    {this.bindAttachments()}
                                    {/*[6/3/22, Bug - Bug - 2183,2181 and 2180, Dhevanesam R]*/}
                                    <div className="err-msg text-danger w-100 "><Trans>{this.state.attachmentErrormsg}</Trans></div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="float-left col-md-5 form-label catalog-label" style={{ width: "187px" }}><Trans>Estimated Price</Trans></label>
                                {/*[09/08/2023] PS_SR_Bug_4141_APP_01 - 4141 - Bug fix - Johnprinto */}
                                <input type="text" className="float-left col-md-7 form-control" maxLength="10" placeholder={t("Enter Estimated Price")} value={this.state.estimatedPrice} name="estimatedPrice" onChange={this.handleQuantity} />
                            </div>
                            {this.state.isInvalid && (this.state.mfgName == "" || this.state.mfgPartNumber == "" || this.state.description == "" || this.state.quantity == "") ?
                                <div className="col-md-6">
                                    <span className="float-right mr-3 mt-3 errormsg">
                                        <Trans>Please fill all the required fields</Trans>
                                    </span>
                                </div> : null}
                            <div className="col-md-12 float-left">
                                <div className="float-right mobile-catalog-btns">
                                    {/*2552 - Font style needs to be change in ipad - Dhamotharan P (added px-2)*/}
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn float-none non-catalog-mobile-btn px-2 mr-3" onClick={this.cancelClick}><Trans>Cancel</Trans></button>
                                    <button className="btn btn-primary custom-save-btn primary-btn float-none non-catalog-mobile-btn px-2 mr-3" onClick={this.clearFormValues}><Trans>reset_key</Trans></button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn float-none non-catalog-mobile-btn px-2 mr-3" onClick={() => this.saveNonCatalog(1)}><Trans>Save/More</Trans></button>
                                    {/*[8/26/22, CAS - Non catalog - Save/Done button UI issue, PS_NC_01, Dhevanesam R]*/}
                                    <button className="btn btn-primary custom-btn-primary primary-btn float-none non-catalog-mobile-btn px-2 mr-3" onClick={() => this.saveNonCatalog(2)}><Trans>Save/Done</Trans></button>
                                </div>
                            </div>
                            {/*[5/10/22, CAS - 31, Dhevanesam R]*/}
                            <p className="NC-helptext col-md-12 float-left my-5"><Trans>** Non-Catalog Orders require an additional process and you will be notified via email when your quote is available. At which point you will need to come back to this catalog to approve for final procession.**</Trans></p>
                        </div>
                    </div>
                </div>
                <div className="modal" id="quickconfirm" data-backdrop="static" data-keyboard="false">
                    {/** 12/28/2023 - Zeus 232 - INC0028559 Functionality added to Zeus when ordering Non-Catalog items to search catalog for MFG PN match back to catalog - Johnprinto */}
                    {this.state.strCplusitemid && this.state.strCplusitemid != "0" ? <QuickItemComponent key={qckKeycounter} itemid={this.state.mfgPartNumber.trim()} closePopup={this.closePopup} cpitemid={this.state.strCplusitemid} pageNo={1} {...this.props} /> : null}
                </div>
            </div>);
    };
};
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(NonCatalogComponent);