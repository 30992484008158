//10/18/23  PC_EC_06 Mythili -- Zeus-40 SDI Enterprise customer master	
//import nessecessary packages
import React, { Component } from 'react';
import * as EnterpriseCustAction from '../actions/EnterpriseCustAction';
import EnterpriseCustStore from '../stores/EnterpriseCustStore';
import * as $ from 'jquery';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import Select from 'react-select';
import Autocomplete from 'react-autocomplete';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation, Trans } from 'react-i18next'
import * as MenuAction from '../actions/MenuAction';
import swal from 'sweetalert';
import Copy from 'copy-to-clipboard';
import ReactPaginate from 'react-paginate';
import { format, parseISO } from 'date-fns';
let currentPagePath;
let inValidFields = [];
//EnterpriseCustComponent statrs
class EnterpriseCustComponent extends Component {
    //Intialize state variables with their default value
    constructor(props) {
        super(props);
        this.handleFavorite = this.handleFavorite.bind(this);
        this.bindGrid = this.bindGrid.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.getItemValue = this.getItemValue.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.handleAccordionToggle = this.handleAccordionToggle.bind(this);
        this.state = {
            enterpriseGridData: [],
            filteredBUs: [],
            isFilter: false,
            loadAvailBU: [],
            loadAvailBUValue: [],
            loadselectedvalue: "",
            filterDisplayName: "All",
            searchCriteria: "ALL",
            searchTxt: "",
            fieldsDetailsData: [],
            btnName: "",
            headerName: "",
            PageNo: 1,
            totalCount: 0,
            countPerpage: 10,
            pageCount: 0,
            fieldDetailsDD: [],
            fieldName: "",
            SetId: " ",
            CustId: " ",
            IsaBusinessUnit: " ",
            IsaCompanyId: " ",
            IsaCplusProdview: " ",
            IsaStartingItemId: 0,
            IsaLastItemId: 0,
            IsaTotalNumExp: 0,
            IsaCurrentExport: 0,
            IsaLastExportDt: null,
            IsaCustomerCodes: " ",
            IsaItemIdLen: 0,
            IsaItemIdMode: " ",
            IsaTotalFldSize: 0,
            IsaSiteEmail: " ",
            IsaItemAddEmail: " ",
            IsaStockReqEmail: " ",
            IsaNonStkReqEmail: " ",
            IsaInstkOutEmail: " ",
            IsaSitePrinter: " ",
            IsaItmAddPrinter: " ",
            IsaStockPrinter: " ",
            IsaNonStkPrinter: " ",
            NextWebReqId: 0,
            IsaOrdApprType: " ",
            IsaOrdBudgetFlg: " ",
            IsaApprNstkFlag: "N",
            IsaReceivingDate: null,
            IsaReceivingType: " ",
            IsaStdNstkSite: "N",
            IsaCustPrfxFlag: "N",
            IsaCustPrefix: " ",
            IsaShopcartPage: " ",
            IsaLppFlag: "N",
            IsaIsolTaxFlag: " ",
            IsaIsolPunchout: "N",
            IsaMobilePicking: "N",
            IsaMobilePutaway: "N",
            IsaMobileIssue: "N",
            IsaPwExpireDays: 0,
            IsaAutoasMaxLns: 0,
            IsaLastStatSend: null,
            IsaUseOroIoh: "N",
            IsaMobPkOm: "N",
            IsaMobPkIn: "N",
            IsaMobUseCc: "N",
            IsaCatalogId: " ",
            IsaAutocribDb: " ",
            IsaAutocribUser: " ",
            IsaAutocribPwd: " ",
            ShipToFlg: "N",
            IsaLaneNbr: " ",
            LastUpdOprid: CookieService.getLocalStorageValues("Session_UserID"),
            LastUpdDttm: null,
            IsaTrackDbGuid: " ",
            IsaTrackDbType: " ",
            IsaTrackDbUsr: " ",
            IsaTrackDbPssw: " ",
            IsaTrackDbCust: " ",
            IsaCartChgCdReq: "N",
            IsaCartWoReq: "N",
            IsaWoChgOpt: "N",
            IsaPriceDispreq: "N",
            IsaRcvBarcode: "N",
            IsaValidateWo: "N",
            IsaOroAsStock: "N",
            IsaSigpadDispatc: "N",
            IsaSigpadIssue: "N",
            IsaRfqOnly: "N",
            ClientLogoImage: " ",
            IsaSearchSvr: " ",
            IsaSearchDb: " ",
            IsaKitPrinting: "N",
            IsaZeroEmail: " ",
            IsaImageName: " ",
            IsaSiteLang: "N",
            IsaItemPrefix: " ",
            IsaClientSvsEml: " ",
            IsaCartTaxFlag: " ",
            IsaTreeHold: "N",
            IsaSdiCat: "N",
            ReqOroBlk: "N",
            OroPunchChk: "N",
            IsaSdiexchApprvl: "N",
            IsaCustintApprvl: "N",
            ApprovalType: " ",
            PoReqBox: "N",
            IsaAsnReceipt: "N",
            IsaPoIntfcChng: "N",
            IsaSdixReceipt: "N",
            IsaPoChngOut: "N",
            BuStatus: "2",
            DeptId: " ",
            IsaOroUseLead: "N",
            IsaAmznSearch: "N",
            IsaManOrderNo: " ",
            IsaMdmUser: "N",
            IsaOrdnlnrenum: "N",
            IsaBypRqstrAppr: "N",
            IsaRecvPrice: "N",
            ApprvalThreshold: 0,
            IsaShortPick: "N",
            IsaCstVendor: " ",
            IsaCstPrice: 0,
            IsaBypApprvRush: "N",
            IsaPartList: "N",
            IsaShpdocRcv: " ",
            Isa3msrch: "N",
            IsaOrsnascoSrch: "N",
            IsaCappSrch: "N",
            IsaImpSrch: "N",
            IsaMScSrch: "N",
            IsaUseRfStatus: "N",
            ProformaFlag: "N",
            StagingFlag: "N",
            StaufferSrch: "N",
            AutoAssignFlg: "N",
            ZeusSite: "N",
            IsaCredCardProc: "N",
            IsaRfqSite: "N",
            IsaEmployeePrn: "N",
            IsaStkIol: " ",
            IsaContractFlag: "N",
            IsaSiteBudLimit: "N",
            IsaCrossSiteAcc: "N",
            IsaGraingerSrch: "N",
            IsaAddCatalog: "N",
            IsaIpmSite: "N",
            IsaQuoteExpDays: 0,
            IsaLabelPrinter: "N",
            IsaEffdtDay: 0,
            IsaAddrSelect: "N",
            IsaLeadDays: 0,
            IsaUnloadDisplay: "N",
            IsaIpmCore: "N",
            placeHolder: "",
            dBfieldName: "",
            fieldID: "",
            maxLength: "",
            IsMandatory: "",
            type: "",
            column: "",
            popUpText: "",
            openAccordionIndex: null,
            inValidSearchField: false
        };
    }
    ////10/18/23  PC_EC_07 Mythili -- Zeus-40 SDI Enterprise customer master	
    //invoke pageload methods and add listerners for pageLoadResponse and InsertOrUpdateResponse
    componentDidMount() {
        debugger;
        EnterpriseCustStore.on('PageLoadResponse', this.pageLoadResponse);
        EnterpriseCustStore.on('InsertOrUpdateResponse', this.insertOrUpdateCustDetails);

        EnterpriseCustStore.on('favpage', this.loadFavPageResponse.bind(this));
        EnterpriseCustStore.on('updateFavPage', this.updateFavPageResponse.bind(this));

        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        EnterpriseCustAction.getCurrentFavPage(getFavPageBO);

        this.pageLoad();
    }
    ////10/18/23  PC_EC_08 Mythili -- Zeus-40 SDI Enterprise customer master	
    //remove the listeners PageLoadResponse InsertOrUpdateResponse+ while un mounting
    componentWillUnmount() {
        EnterpriseCustStore.removeListener('PageLoadResponse', this.pageLoadResponse);
        EnterpriseCustStore.removeListener('InsertOrUpdateResponse', this.insertOrUpdateCustDetails);

        EnterpriseCustStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        EnterpriseCustStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    //to handle removing page from favorites
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Enterprise customer master",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            EnterpriseCustAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            EnterpriseCustAction.deleteFavPage(deleteFavPageBO)
        }
    };
    //to get if the page is favorite or not
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(EnterpriseCustStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    // to addthe page to favorite list
    updateFavPageResponse = () => {
        if (EnterpriseCustStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(EnterpriseCustStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(EnterpriseCustStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    ////10/18/23  PC_EC_09 Mythili -- Zeus-40 SDI Enterprise customer master	
    //Page load method to populate the grid and get the search fields and bu dropdown
    pageLoad = () => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        debugger;
        let pageLoadBO = {
            userId: CookieService.getLocalStorageValues("Session_UserID"),
            searchBy: "",
            searchValue: "",
            isFilter: this.state.isFilter
        };
        EnterpriseCustAction.pageload(pageLoadBO);
        $("#EC_Form").hide();
    };
    // PC_EC_11 to assign the returned values in respective state variables from pageLoad method
    pageLoadResponse = () => {
        debugger;
        let pageLoadBU = JSON.parse(EnterpriseCustStore.PageLoadResponse);
        if (pageLoadBU != undefined && pageLoadBU != null && pageLoadBU != "") {
            if (pageLoadBU.hasOwnProperty('Exception')) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Technical issue please contact SDI help desk"
                }).then(() => {
                    window.location.href = '/';
                });
            }
            else {
                debugger;
                let gridResultLoc = pageLoadBU.v_grid_result;
                if (gridResultLoc != undefined && gridResultLoc != null && gridResultLoc != "") {

                    this.setState({
                        filteredBUs: gridResultLoc,
                        totalCount: gridResultLoc.length
                    });
                    if (this.state.isFilter != true) {
                        this.setState({
                            enterpriseGridData: gridResultLoc,
                            fieldsDetailsData: pageLoadBU.v_fieldDetails_result
                        });
                    }
                    $("#EC_Form").hide();
                    let totalCountLoc = this.state.filteredBUs.length;
                    let pg = Math.ceil((totalCountLoc > 0 ? totalCountLoc : 0) / this.state.countPerpage);
                    this.setState({ pageCount: pg });
                }
                else {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (pageLoadBU.hasOwnProperty('Exception')) {
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: "Technical issue please contact SDI help desk"
                        }).then(() => {
                            window.location.href = '/';
                        });
                    }
                    this.setState({
                        totalCount: 0
                    });
                }
                let locArr1 = [];
                let flddetailLoc = pageLoadBU.v_fieldDetails_result;
                if (flddetailLoc != undefined && flddetailLoc != null && flddetailLoc != "") {

                    flddetailLoc.map((value) => {
                        let locArr = { label: value.FIELD_NAME, value: value.FIELD_ID };
                        locArr1.push(locArr);
                    })

                    this.setState({
                        fieldDetailsDD: locArr1
                    });
                }
                else {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (pageLoadBU.hasOwnProperty('Exception')) {
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: "Technical issue please contact SDI help desk"
                        }).then(() => {
                            window.location.href = '/';
                        });
                    }
                }
                locArr1 = [];
                let AvailBULoc = pageLoadBU.v_AvailBU_result;
                if (AvailBULoc != undefined && AvailBULoc != null && AvailBULoc != "") {

                    AvailBULoc.map((value) => {
                        let locArr = { label: value.BUDESC, value: value.BUSINESS_UNIT };
                        locArr1.push(locArr);
                    })

                    this.setState({
                        loadAvailBU: locArr1
                    });
                }
                else {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (pageLoadBU.hasOwnProperty('Exception')) {
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: "Technical issue please contact SDI help desk"
                        }).then(() => {
                            window.location.href = '/';
                        });
                    }
                }
            }

        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical issue please contact SDI help desk"
            }).then(() => {
                window.location.href = '/';
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    };
    handleAccordionToggle = (index) => {
        this.setState((prevState) => ({
            openAccordionIndex: prevState.openAccordionIndex === index ? null : index,
        }));
        $(`#overall-details-tab${index}`).click();
    };
    // PC_EC_12 to bind the grid based on the data from the ENTERPRISE table
    bindGrid = () => {
        debugger;

        return this.state.filteredBUs.map((BuList, index) => {

            if (index < parseInt(this.state.PageNo) * 10 && index >= parseInt(this.state.PageNo - 1) * 10) {
                return (
                    <React.Fragment key={index}>
                        < tr key={BuList.ISA_BUSINESS_UNIT + "-parent-" + index}>

                            <td className={`accordion-toggle ${this.state.openAccordionIndex === index ? '' : 'collapsed'}`}
                                data-toggle="collapse"
                                data-target={`#order-row-${index}`}
                                onClick={() => this.handleAccordionToggle(index)}
                                aria-expanded={this.state.openAccordionIndex === index}
                            >
                                <span className="accordion-arrow accordion-arrow-change"></span>
                            </td>
                            <td>{BuList.ISA_BUSINESS_UNIT}</td>
                            <td>{BuList.ISA_COMPANY_ID}</td>
                            <td>{BuList.CUST_ID}</td>
                            <td>{BuList.SETID}</td>
                            <td className="text-center"><a ><img src="images/edit-icon.svg" alt="edit" onClick={(e) => this.handleEditClick(e, BuList)} id="btnEdit" data-toggle="tooltip" data-placement="top" title="Edit" /></a></td>
                        </tr >

                        <tr className="p">
                            <td colSpan="6" className="hiddenRow border-bottom-0">
                                <div className={`accordion-body collapse py-1 px-3 border-bottom ${this.state.openAccordionIndex === index ? 'show' : ''}`}
                                    id={`order-row-${index}`}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="nav nav-pills catalog-tabs-mobile catalog-tabs border-top-0 border-right-0 border-left-0" id={`myTab${index}`} role="tablist">
                                                <li className="nav-item mx-0">
                                                    <a className="nav-link rounded-0 bg-white ml-0 active font-medium color-unactive color-active-black" id={`overall-details-tab${index}`} data-toggle="tab" href={`#overall-details${index}`} role="tab" aria-controls="overall-details" aria-selected="true">Overall details</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link rounded-0 bg-white font-medium color-unactive color-active-black" id={`flags-tab${index}`} data-toggle="tab" href={`#flags${index}`} role="tab" aria-controls="flags" aria-selected="false">Flags</a>
                                                </li>
                                            </ul>
                                            {/*tab ends here*/}
                                            {/*tab Inner content starts here*/}
                                            <div className="tab-content Catalog-Items w-100" id={`myTabContent${index}`}>
                                                {/*Overall Details Tab*/}
                                                <div className="tab-pane p-3 fade active show" id={`overall-details${index}`} role="tabpanel">
                                                    {/*details*/}
                                                    <div className="row custom-bg py-3">
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Approval threshold :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.APPRVALTHRESHOLD == " " ? " " : BuList.APPRVALTHRESHOLD}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Approval type :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.APPROVAL_TYPE == " " ? " " : BuList.APPROVAL_TYPE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Auto crib DB :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_AUTOCRIB_DB == " " ? " " : BuList.ISA_AUTOCRIB_DB}</span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Auto crib password :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_AUTOCRIB_PWD == " " ? " " : BuList.ISA_AUTOCRIB_PWD}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Auto crib user :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_AUTOCRIB_USER == " " ? " " : BuList.ISA_AUTOCRIB_USER}</span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Auto max LNS :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_AUTOAS_MAX_LNS == " " ? " " : BuList.ISA_AUTOAS_MAX_LNS}</span>
                                                        </div>


                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Cart tax flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CART_TAX_FLAG == " " ? " " : BuList.ISA_CART_TAX_FLAG}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Catalog ID :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CATALOG_ID == " " ? " " : BuList.ISA_CATALOG_ID}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Client logo :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.CLIENTLOGOIMAGE == " " ? " " : BuList.CLIENTLOGOIMAGE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium float-left">Client SVS email :</span>
                                                            <span className="font-14 font-regular ml-1 txt-ellipsis" data-toggle="tooltip" data-placement="top" title={BuList.ISA_CLIENTSVS_EML == " " ? " " : BuList.ISA_CLIENTSVS_EML}>{BuList.ISA_CLIENTSVS_EML == " " ? " " : BuList.ISA_CLIENTSVS_EML}</span>
                                                            <img src="images/copy.svg" alt="copy-icon" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => this.CopyEmail(BuList.ISA_CLIENTSVS_EML)} className={BuList.ISA_CLIENTSVS_EML == " " ? "d-none" : "disp-hide"} />
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">CPLUS prodview :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CPLUS_PRODVIEW == " " ? " " : BuList.ISA_CPLUS_PRODVIEW}</span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">CST price :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CST_PRICE == " " ? " " : BuList.ISA_CST_PRICE}</span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">CST vendor :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CST_VENDOR == " " ? " " : BuList.ISA_CST_VENDOR}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Current export :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CURRENT_EXPORT == " " ? " " : BuList.ISA_CURRENT_EXPORT}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Customer code :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CUSTOMER_CODES == " " ? " " : BuList.ISA_CUSTOMER_CODES}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Customer prefix :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CUST_PREFIX == " " ? " " : BuList.ISA_CUST_PREFIX}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Dept ID :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.DEPTID == " " ? " " : BuList.DEPTID}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Image name :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_IMAGE_NAME == " " ? " " : BuList.ISA_IMAGE_NAME}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium float-left">In stock req. email :</span>
                                                            <span className="font-14 font-regular ml-1 txt-ellipsis" data-toggle="tooltip" data-placement="top" title={BuList.ISA_INSTKOUT_EMAIL == " " ? "" : BuList.ISA_INSTKOUT_EMAIL}>{BuList.ISA_INSTKOUT_EMAIL == " " ? " " : BuList.ISA_INSTKOUT_EMAIL}</span>
                                                            <img src="images/copy.svg" alt="copy-icon" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => this.CopyEmail(BuList.ISA_INSTKOUT_EMAIL)} className={BuList.ISA_INSTKOUT_EMAIL == " " ? "d-none" : "disp-hide"} />
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium float-left">Item add email :</span>
                                                            <span className="font-14 font-regular ml-1 txt-ellipsis" data-toggle="tooltip" data-placement="top" title={BuList.ISA_ITEMADD_EMAIL == " " ? "" : BuList.ISA_ITEMADD_EMAIL}>{BuList.ISA_ITEMADD_EMAIL == " " ? " " : BuList.ISA_ITEMADD_EMAIL}</span>
                                                            <img src="images/copy.svg" alt="copy-icon" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => this.CopyEmail(BuList.ISA_ITEMADD_EMAIL)} className={BuList.ISA_ITEMADD_EMAIL == " " ? "d-none" : "disp-hide"} />
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Item add printer :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ITMADD_PRINTER == " " ? " " : BuList.ISA_ITMADD_PRINTER}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Item ID length :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ITEMID_LEN == " " ? " " : BuList.ISA_ITEMID_LEN}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Item ID mode :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ITEMID_MODE == " " ? " " : BuList.ISA_ITEMID_MODE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Item prefix :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ITEM_PREFIX == " " ? " " : BuList.ISA_ITEM_PREFIX}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">ISOL tax flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ISOL_TAX_FLAG == " " ? " " : BuList.ISA_ISOL_TAX_FLAG}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Lane number :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_LANE_NBR == " " ? " " : BuList.ISA_LANE_NBR}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Lead days :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_LEAD_DAYS == " " ? " " : BuList.ISA_LEAD_DAYS}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Last export date :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_LAST_EXPORT_DT == " " || BuList.ISA_LAST_EXPORT_DT == null ? " " : new Date(BuList.ISA_LAST_EXPORT_DT).toLocaleDateString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric'
                                                            })}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Last item ID :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.IsaLastItemId == " " ? " " : BuList.IsaLastItemId}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Last status send :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_LAST_STAT_SEND == " " || BuList.ISA_LAST_STAT_SEND == null ? " " : new Date(BuList.ISA_LAST_STAT_SEND).toLocaleString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                                hour12: true
                                                            })}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Manual order number :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MAN_ORDER_NO == " " ? " " : BuList.ISA_MAN_ORDER_NO}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Next webreq ID :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.NEXT_WEBREQ_ID == " " ? " " : BuList.NEXT_WEBREQ_ID}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium float-left">Non-Stock req. email :</span>
                                                            <span className="font-14 font-regular ml-1 txt-ellipsis" data-toggle="tooltip" data-placement="top" title={BuList.ISA_NONSKREQ_EMAIL == " " ? "" : BuList.ISA_NONSKREQ_EMAIL}>{BuList.ISA_NONSKREQ_EMAIL == " " ? " " : BuList.ISA_NONSKREQ_EMAIL}</span>
                                                            <img src="images/copy.svg" alt="copy-icon" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => this.CopyEmail(BuList.ISA_NONSKREQ_EMAIL)} className={BuList.ISA_NONSKREQ_EMAIL == " " ? "d-none" : "disp-hide"} />
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Non-Stock printer :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_NONSTK_PRINTER == " " ? " " : BuList.ISA_NONSTK_PRINTER}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Order approval type :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ORD_APPR_TYPE == " " ? " " : BuList.ISA_ORD_APPR_TYPE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">ORD budget :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ORD_BUDGET_FLG == " " ? " " : BuList.ISA_ORD_BUDGET_FLG}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Password expiry days :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_PW_EXPIRE_DAYS == " " ? " " : BuList.ISA_PW_EXPIRE_DAYS}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Receiving date :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_RECEIVING_DATE == " " || BuList.ISA_RECEIVING_DATE == null ? " " : new Date(BuList.ISA_RECEIVING_DATE).toLocaleDateString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric'
                                                            })}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Receiving type :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_RECEIVING_TYPE == " " ? " " : BuList.ISA_RECEIVING_TYPE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Search DB :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SEARCH_DB == " " ? " " : BuList.ISA_SEARCH_DB}</span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Search SVR :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SEARCH_SVR == " " ? " " : BuList.ISA_SEARCH_SVR}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Shipping document receive :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SHPDOC_RCV == " " ? " " : BuList.ISA_SHPDOC_RCV}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Shopping cart page :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SHOPCART_PAGE == " " ? " " : BuList.ISA_SHOPCART_PAGE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium float-left">Site email :</span>
                                                            <span className="font-14 font-regular ml-1 txt-ellipsis" data-toggle="tooltip" data-placement="top" title={BuList.ISA_SITE_EMAIL == " " ? "" : BuList.ISA_SITE_EMAIL}>{BuList.ISA_SITE_EMAIL == " " ? " " : BuList.ISA_SITE_EMAIL} </span>
                                                            <img src="images/copy.svg" alt="copy-icon" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => this.CopyEmail(BuList.ISA_SITE_EMAIL)} className={BuList.ISA_SITE_EMAIL == " " ? "d-none" : "disp-hide"} />
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Site printer :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SITE_PRINTER == " " ? " " : BuList.ISA_SITE_PRINTER}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Starting item ID :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_STARTINGITEMID == " " ? " " : BuList.ISA_STARTINGITEMID}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Stock IOL :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_STK_IOL == " " ? " " : BuList.ISA_STK_IOL}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Stock printer :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_NONSTK_PRINTER == " " ? " " : BuList.ISA_NONSTK_PRINTER}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium float-left">Stock req. email :</span>
                                                            <span className="font-14 font-regular ml-1 txt-ellipsis" data-toggle="tooltip" data-placement="top" title={BuList.ISA_STOCKREQ_EMAIL == " " ? "" : BuList.ISA_STOCKREQ_EMAIL}>{BuList.ISA_STOCKREQ_EMAIL == " " ? " " : BuList.ISA_STOCKREQ_EMAIL} </span>
                                                            <img src="images/copy.svg" alt="copy-icon" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => this.CopyEmail(BuList.ISA_STOCKREQ_EMAIL)} className={BuList.ISA_STOCKREQ_EMAIL == " " ? "d-none" : "disp-hide"} />
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Total field size :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TOTAL_FLD_SIZE == " " ? " " : BuList.ISA_TOTAL_FLD_SIZE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Total num. exp. :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TOTAL_NUM_EXP == " " ? " " : BuList.ISA_TOTAL_NUM_EXP}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Track DB cust :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TRACK_DB_CUST == " " ? " " : BuList.ISA_TRACK_DB_CUST}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Track DB GU ID :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TRACK_DB_GUID == " " ? " " : BuList.ISA_TRACK_DB_GUID}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Track DB type :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TRACK_DB_TYPE == " " ? " " : BuList.ISA_TRACK_DB_TYPE}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Track DB user :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TRACK_DB_USR == " " ? " " : BuList.ISA_TRACK_DB_USR}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Track DB password :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TRACK_DB_PSSW == " " ? " " : BuList.ISA_TRACK_DB_PSSW}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <span className="font-14 font-medium float-left" >Zero email :</span>
                                                            <span className="font-14 font-regular ml-1 txt-ellipsis" data-toggle="tooltip" data-placement="top" title={BuList.ISA_ZERO_EMAIL == " " ? "" : BuList.ISA_ZERO_EMAIL}>{BuList.ISA_ZERO_EMAIL == " " ? " " : BuList.ISA_ZERO_EMAIL}</span>
                                                            <img src="images/copy.svg" alt="copy-icon" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => this.CopyEmail(BuList.ISA_ZERO_EMAIL)} className={BuList.ISA_ZERO_EMAIL == " " ? "d-none" : "disp-hide"} />
                                                        </div>
                                                    </div>
                                                    {/*details*/}
                                                </div>
                                                {/*Overall Details Tab*/}
                                                {/*Flags Tab*/}
                                                <div className="tab-pane p-3 fade" id={`flags${index}`} role="tabpanel">
                                                    {/*flags*/}
                                                    <div className="row custom-bg py-3">
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Add to catalog :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ADD_CATALOG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Address flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ADDR_SELECT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Amazon search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_AMZN_SEARCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Annual budgetary limit :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SITE_BUD_LIMIT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">ASN receipt :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ASN_RECEIPT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Auto assign :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.AUTO_ASSIGN_FLG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Block price required :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_PRICEDISPREQ === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Business unit status :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.BU_STATUS === "1" ? "Active" : "Inactive"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Bypass approval rush :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_BYP_APPRV_RUSH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Bypass requestor approval :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_BYP_RQSTR_APPR === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Capp search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CAPP_SRCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Cart charge code required :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CARTCHGCDREQ === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Cart work order required :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CARTWOREQ === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Contract flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CONTRACT_FLAG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Credit card process :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CRED_CARD_PROC === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Cross site access :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CROSS_SITE_ACC === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Cust-In approval :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CUSTINT_APPRVL === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Customer prefix flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_CUST_PRFX_FLAG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Delivery point :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_UNLOAD_DISPLAY === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Employee PRN :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_EMPLOYEE_PRN === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Grainger search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_GRAINGER_SRCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">IMP search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_IMP_SRCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">IPM site :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_IPM_SITE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Kit printing :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_KIT_PRINTING === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Label printer :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_LABEL_PRINTER === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">LPP flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_LPP_FLAG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">MDM user :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MDM_USER === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Mobile issue :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MOBILE_ISSUE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Mobile pick IN :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MOB_PK_OM === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Mobile pick OM :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MOB_PK_OM === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Mobile picking :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MOBILE_PICKING === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Mobile putaway :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MOBILE_PUTAWAY === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Mobile use CC :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MOB_USE_CC === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">MSC search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_MSC_SRCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Non-Stock approval flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_APPR_NSTK_FLAG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Order line Number :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ORDLNRENUM === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">ORO as stock :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ORO_AS_STOCK === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">ORO punchin check :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ORO_USE_LEAD === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">ORO use lead :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ORO_PUNCH_CHK === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">ORS NASCO search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ORSNASCO_SRCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Part list :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_PART_LIST === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">PO change out :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_PO_CHNG_OUT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">PO-INTF change :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_PO_INTFC_CHNG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">PO req. box :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.PO_REQ_BOX === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Proforma flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.PROFORMA_FLAG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Punchout flag:</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_ISOL_PUNCHOUT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Receiving price :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_RECV_PRICE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Receiving barcode :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_RCVBARCODE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Req. ord. blk :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.REQ_ORO_BLK === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">RFQ email :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_QUOTE_EXP_DAYS === 1 ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">RFQ only :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_RFQ_ONLY === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">RFQ site :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_RFQ_SITE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">SDI cart :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SDI_CAT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">SDI exchange approval :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SDIEXCH_APPRVL === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">SDI exchange receipt :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SDIX_RECEIPT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Ship to flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.SHIP_TO_FLG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Short pick :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SHORT_PICK === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Show required by date :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_IPM_CORE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Sigpad. dispatch :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SIGPAD_DISPATC === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Sigpad. issue :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SIGPAD_ISSUE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Site lang :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_SITE_LANG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Staging flag :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.STAGING_FLAG === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Stauffer search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.STAUFFER_SRCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">STD NSTK site :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_STD_NSTK_SITE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Tree hold :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_TREE_HOLD === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">User ORO IOH :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_USE_ORO_IOH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">USE-RF status :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_USE_RF_STATUS === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Validate work order :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_VALIDATE_WO === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Work order/ charge code required:</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_WO_CHG_OPT === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 mb-3">
                                                            <span className="font-14 font-medium">Zeus2 site :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ZEUS_SITE === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <span className="font-14 font-medium">3M search :</span>
                                                            <span className="font-14 font-regular ml-1">{BuList.ISA_3M_SRCH === "Y" ? "Yes" : "No"}</span>
                                                        </div>
                                                    </div>
                                                    {/*flags*/}
                                                </div>
                                                {/*Flags Tab*/}
                                            </div>
                                            {/*tab Inner content ends here*/}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </React.Fragment>
                );
            }
        });

    }
    // PC_EC_25 to copy the emailpopulated in th egrid on click of copy icon
    CopyEmail = (notes) => {
        debugger;
        Copy(notes);
        try {
            $.toast({
                text: 'Email copied to clip board..',
                position: {
                    top: 150,
                    bottom: 90,
                    left: 650
                },
                stack: false,
                loader: false
            });

        } catch (ex) {
            console.log(ex)
        }
    }
    // PC_EC_17 to increment the page number on page click
    handlePageClick = (data) => {
        debugger;
        let pageNo = data.selected + 1;
        this.setState({ PageNo: pageNo, openAccordionIndex: null });
        window.scrollTo(0, 0);
    };
    // PC_EC_18 to display the empty form to add new customer
    handleNewCustClick = () => {
        this.removeValidation();
        this.setState({
            btnName: "Submit",
            headerName: "Add new customer",
            SetId: " ",
            CustId: " ",
            IsaBusinessUnit: " ",
            IsaCompanyId: " ",
            IsaCplusProdview: " ",
            IsaStartingItemId: 0,
            IsaLastItemId: 0,
            IsaTotalNumExp: 0,
            IsaCurrentExport: 0,
            IsaLastExportDt: null,
            IsaCustomerCodes: " ",
            IsaItemIdLen: 0,
            IsaItemIdMode: " ",
            IsaTotalFldSize: 0,
            IsaSiteEmail: " ",
            IsaItemAddEmail: " ",
            IsaStockReqEmail: " ",
            IsaNonStkReqEmail: " ",
            IsaInstkOutEmail: " ",
            IsaSitePrinter: " ",
            IsaItmAddPrinter: " ",
            IsaStockPrinter: " ",
            IsaNonStkPrinter: " ",
            NextWebReqId: 0,
            IsaOrdApprType: " ",
            IsaOrdBudgetFlg: " ",
            IsaApprNstkFlag: "N",
            IsaReceivingDate: null,
            IsaReceivingType: " ",
            IsaStdNstkSite: "N",
            IsaCustPrfxFlag: "N",
            IsaCustPrefix: " ",
            IsaShopcartPage: " ",
            IsaLppFlag: "N",
            IsaIsolTaxFlag: " ",
            IsaIsolPunchout: "N",
            IsaMobilePicking: "N",
            IsaMobilePutaway: "N",
            IsaMobileIssue: "N",
            IsaPwExpireDays: 0,
            IsaAutoasMaxLns: 0,
            IsaLastStatSend: null,
            IsaUseOroIoh: "N",
            IsaMobPkOm: "N",
            IsaMobPkIn: "N",
            IsaMobUseCc: "N",
            IsaCatalogId: " ",
            IsaAutocribDb: " ",
            IsaAutocribUser: " ",
            IsaAutocribPwd: " ",
            ShipToFlg: "N",
            IsaLaneNbr: " ",
            LastUpdOprid: CookieService.getLocalStorageValues("Session_UserID"),
            LastUpdDttm: null,
            IsaTrackDbGuid: " ",
            IsaTrackDbType: " ",
            IsaTrackDbUsr: " ",
            IsaTrackDbPssw: " ",
            IsaTrackDbCust: " ",
            IsaCartChgCdReq: "N",
            IsaCartWoReq: "N",
            IsaWoChgOpt: "N",
            IsaPriceDispreq: "N",
            IsaRcvBarcode: "N",
            IsaValidateWo: "N",
            IsaOroAsStock: "N",
            IsaSigpadDispatc: "N",
            IsaSigpadIssue: "N",
            IsaRfqOnly: "N",
            ClientLogoImage: " ",
            IsaSearchSvr: " ",
            IsaSearchDb: " ",
            IsaKitPrinting: "N",
            IsaZeroEmail: " ",
            IsaImageName: " ",
            IsaSiteLang: "N",
            IsaItemPrefix: " ",
            IsaClientSvsEml: " ",
            IsaCartTaxFlag: " ",
            IsaTreeHold: "N",
            IsaSdiCat: "N",
            ReqOroBlk: "N",
            OroPunchChk: "N",
            IsaSdiexchApprvl: "N",
            IsaCustintApprvl: "N",
            ApprovalType: " ",
            PoReqBox: "N",
            IsaAsnReceipt: "N",
            IsaPoIntfcChng: "N",
            IsaSdixReceipt: "N",
            IsaPoChngOut: "N",
            BuStatus: "2",
            DeptId: " ",
            IsaOroUseLead: "N",
            IsaAmznSearch: "N",
            IsaManOrderNo: " ",
            IsaMdmUser: "N",
            IsaOrdnlnrenum: "N",
            IsaBypRqstrAppr: "N",
            IsaRecvPrice: "N",
            ApprvalThreshold: 0,
            IsaShortPick: "N",
            IsaCstVendor: " ",
            IsaCstPrice: 0,
            IsaBypApprvRush: "N",
            IsaPartList: "N",
            IsaShpdocRcv: " ",
            Isa3msrch: "N",
            IsaOrsnascoSrch: "N",
            IsaCappSrch: "N",
            IsaImpSrch: "N",
            IsaMScSrch: "N",
            IsaUseRfStatus: "N",
            ProformaFlag: "N",
            StagingFlag: "N",
            StaufferSrch: "N",
            AutoAssignFlg: "N",
            ZeusSite: "N",
            IsaCredCardProc: "N",
            IsaRfqSite: "N",
            IsaEmployeePrn: "N",
            IsaStkIol: " ",
            IsaContractFlag: "N",
            IsaSiteBudLimit: "N",
            IsaCrossSiteAcc: "N",
            IsaGraingerSrch: "N",
            IsaAddCatalog: "N",
            IsaIpmSite: "N",
            IsaQuoteExpDays: 0,
            IsaLabelPrinter: "N",
            IsaEffdtDay: 0,
            IsaAddrSelect: "N",
            IsaLeadDays: 0,
            IsaUnloadDisplay: "N",
            IsaIpmCore: "N",
            inValidSearchField: false
        });
        $("#EC_Grid").hide();
        $("#EC_Form").show();
        debugger;
        $("#buDiv").show();
        $("#business-unit").addClass('marginTop');
        $("#business-unit2").addClass('marginTop');
        $("#business-unit").removeClass('validation');
        $("#IsaCplusProdview").removeClass('validation');
        $("#CustId").removeClass('validation');
        $("#SetId").removeClass('validation');
        $("#IsaCompanyId").removeClass('validation');
        $("#spbusiness-unit").hide();
        $("#spprodview").hide();
        $("#spcust-id").hide();
        $("#spset-id").hide();
        $("#spcompany-id").hide();
        $("#IsaInstkOutEmail").removeClass('validation');
        $("#IsaItemAddEmail").removeClass('validation');
        $("#IsaNonStkReqEmail").removeClass('validation');
        $("#IsaSiteEmail").removeClass('validation');
        $("#IsaStockReqEmail").removeClass('validation');
        $("#IsaZeroEmail").removeClass('validation');
        $("#IsaClientSvsEml").removeClass('validation');
        $("#spin-stock").hide();
        $("#spadd-email").hide();
        $("#spnonstock").hide();
        $("#spsite-email").hide();
        $("#spstock-email").hide();
        $("#spzero-email").hide();
        $("#spsvs-email").hide();
        $("#contentDetails3").show();
        $("#contentDetails4").hide();
        $("#contentDetails1").show();
        $("#contentDetails2").hide();
        $("#overall-details-tab").click();
        this.handleClearLogo();
        window.scrollTo(0, 0);
    };
    // PC_EC_27 to show the grid when cancle or back button is clicked in the form
    handleCancel = () => {
        debugger;
        this.removeValidation();
        $("#business-unit").removeClass('validation');
        $("#IsaCplusProdview").removeClass('validation');
        $("#CustId").removeClass('validation');
        $("#SetId").removeClass('validation');
        $("#IsaCompanyId").removeClass('validation');
        $("#EC_Grid").show();
        $("#EC_Form").hide();
        $("#overall-details-tab").click();
        this.setState({
            isFilter: false
        });
        this.handleSearchClear();
        this.pageLoad();
        this.setState({
            fieldName: "",
            loadAvailBUValue: [],
            openAccordionIndex: null
        });
        window.scrollTo(0, 0);
    };
    // PC_EC_13 set the filtering option choosed
    handleFiltering = (event, displayName, columnName) => {
        this.setState({ filterDisplayName: displayName, searchCriteria: columnName });
        window.scrollTo(0, 0);
    };
    searchKeyPress = (event) => {

        if (event.keyCode == 13) {
            this.filtering();
        };
        window.scrollTo(0, 0);
    };
    // PC_EC_14 to get the filtered data of Bus based on filters criteria and value
    filtering = () => {
        debugger;
        let pageLoadBO = {
            userId: CookieService.getLocalStorageValues("Session_UserID"),
            searchBy: this.state.searchCriteria,
            searchValue: this.state.searchTxt,
            isFilter: true
        };
        this.setState({
            isFilter: true,
            PageNo: 1,
            openAccordionIndex: null
        });
        EnterpriseCustAction.pageload(pageLoadBO);
        window.scrollTo(0, 0);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    };
    // PC_EC_15 on click of the icon, clears the filter values and calls page load
    handleSearchClear = () => {
        document.getElementById("searchTxt").value = "";
        let totalCountLoc = this.state.enterpriseGridData.length;
        let pg = Math.ceil((totalCountLoc > 0 ? totalCountLoc : 0) / this.state.countPerpage);
        this.setState({
            searchTxt: "",
            filteredBUs: this.state.enterpriseGridData,
            isFilter: false,
            pageCount: pg,
            totalCount: totalCountLoc,
            openAccordionIndex: null
        });
        window.scrollTo(0, 0);
    };
    // PC_EC_16 used to sort the data in the grid
    sort = (Arr, indexval, nextindexval, Type) => {
        let temp = []
        let size = Arr.length
        let i, j = 0;

        if (Type == "ASC") {
            for (i = 0; i < size; i++) {

                for (j = i + 1; j < size; j++) {

                    if (eval(indexval) > eval(nextindexval)) {
                        temp = Arr[i];
                        Arr[i] = Arr[j];
                        Arr[j] = temp;
                    }
                }
            }
            return Arr;
        } else {
            for (i = 0; i < size; i++) {

                for (j = i + 1; j < size; j++) {

                    if (eval(indexval) < eval(nextindexval)) {
                        temp = Arr[i];
                        Arr[i] = Arr[j];
                        Arr[j] = temp;
                    }
                }
            }
            return Arr;
        }
    };
    Sorting = (sort, name) => {

        // Add your sorting logic here
        let data = []
        switch (name) {
            case "ISA_BUSINESS_UNIT": {
                data = this.sort(this.state.filteredBUs, "Arr[i].ISA_BUSINESS_UNIT", "Arr[j].ISA_BUSINESS_UNIT", sort)
                break;
            }
            case "ISA_COMPANY_ID": {
                data = this.sort(this.state.filteredBUs, "Arr[i].ISA_COMPANY_ID", "Arr[j].ISA_COMPANY_ID", sort)
                break;
            }
            case "SETID": {
                data = this.sort(this.state.filteredBUs, "Arr[i].SETID", "Arr[j].SETID", sort)
                break;
            }
            case "CUST_ID": {
                data = this.sort(this.state.filteredBUs, "Arr[i].CUST_ID", "Arr[j].CUST_ID", sort)
                break;
            }
            default: {
                data = this.sort(this.state.filteredBUs, "Arr[i].LASTUPDDTTM", "Arr[j].LASTUPDDTTM", "DESC");
            }
        }
        this.setState({
            filteredBUs: data,
            openAccordionIndex: null
        });
        window.scrollTo(0, 0);
    };

    // PC_EC_26 sets the filter text on the sate varible searchTxt
    handleSearchTxt = (event) => {
        debugger;
        let txtLoc = event.target.value;
        if (txtLoc.startsWith(' ')) {
            txtLoc = txtLoc.slice(1); // Remove leading space
        }
        if (event.target.value != "") {
            this.setState({
                searchTxt: txtLoc
            });
        }
        else {
            this.setState({
                searchTxt: ""
            });
        }
        window.scrollTo(0, 0);
    };
    // PC_EC_19 on click of the edit button, displayed the form with the respective BUs data.
    handleEditClick = (e, BuList) => {
        debugger;
        this.removeValidation();
        let EditBU = this.state.filteredBUs.filter((x) => x.ISA_BUSINESS_UNIT == BuList.ISA_BUSINESS_UNIT);
        if (EditBU.length == 1) {
            let hedaerNameLoc = BuList.ISA_BUSINESS_UNIT + " " + BuList.ISA_COMPANY_ID;
            this.setState({
                btnName: "Update",
                headerName: hedaerNameLoc,
                SetId: BuList.SETID,
                CustId: BuList.CUST_ID,
                IsaBusinessUnit: BuList.ISA_BUSINESS_UNIT,
                IsaCompanyId: BuList.ISA_COMPANY_ID,
                IsaCplusProdview: BuList.ISA_CPLUS_PRODVIEW,
                IsaStartingItemId: BuList.ISA_STARTINGITEMID,
                IsaLastItemId: BuList.ISA_LASTITEMID,
                IsaTotalNumExp: BuList.ISA_TOTAL_NUM_EXP,
                IsaCurrentExport: BuList.ISA_CURRENT_EXPORT,
                IsaLastExportDt: BuList.ISA_LAST_EXPORT_DT,
                IsaCustomerCodes: BuList.ISA_CUSTOMER_CODES,
                IsaItemIdLen: BuList.ISA_ITEMID_LEN,
                IsaItemIdMode: BuList.ISA_ITEMID_MODE,
                IsaTotalFldSize: BuList.ISA_TOTAL_FLD_SIZE,
                IsaSiteEmail: BuList.ISA_SITE_EMAIL,
                IsaItemAddEmail: BuList.ISA_ITEMADD_EMAIL,
                IsaStockReqEmail: BuList.ISA_STOCKREQ_EMAIL,
                IsaNonStkReqEmail: BuList.ISA_NONSKREQ_EMAIL,
                IsaInstkOutEmail: BuList.ISA_INSTKOUT_EMAIL,
                IsaSitePrinter: BuList.ISA_SITE_PRINTER,
                IsaItmAddPrinter: BuList.ISA_ITMADD_PRINTER,
                IsaStockPrinter: BuList.ISA_STOCK_PRINTER,
                IsaNonStkPrinter: BuList.ISA_NONSTK_PRINTER,
                NextWebReqId: BuList.NEXT_WEBREQ_ID,
                IsaOrdApprType: BuList.ISA_ORD_APPR_TYPE,
                IsaOrdBudgetFlg: BuList.ISA_ORD_BUDGET_FLG,
                IsaApprNstkFlag: BuList.ISA_APPR_NSTK_FLAG,
                IsaReceivingDate: BuList.ISA_RECEIVING_DATE,
                IsaReceivingType: BuList.ISA_RECEIVING_TYPE,
                IsaStdNstkSite: BuList.ISA_STD_NSTK_SITE,
                IsaCustPrfxFlag: BuList.ISA_CUST_PRFX_FLAG,
                IsaCustPrefix: BuList.ISA_CUST_PREFIX,
                IsaShopcartPage: BuList.ISA_SHOPCART_PAGE,
                IsaLppFlag: BuList.ISA_LPP_FLAG,
                IsaIsolTaxFlag: BuList.ISA_ISOL_TAX_FLAG,
                IsaIsolPunchout: BuList.ISA_ISOL_PUNCHOUT,
                IsaMobilePicking: BuList.ISA_MOBILE_PICKING,
                IsaMobilePutaway: BuList.ISA_MOBILE_PUTAWAY,
                IsaMobileIssue: BuList.ISA_MOBILE_ISSUE,
                IsaPwExpireDays: BuList.ISA_PW_EXPIRE_DAYS,
                IsaAutoasMaxLns: BuList.ISA_AUTOAS_MAX_LNS,
                IsaLastStatSend: BuList.ISA_LAST_STAT_SEND,
                IsaUseOroIoh: BuList.ISA_USE_ORO_IOH,
                IsaMobPkOm: BuList.ISA_MOB_PK_OM,
                IsaMobPkIn: BuList.ISA_MOB_PK_IN,
                IsaMobUseCc: BuList.ISA_MOB_USE_CC,
                IsaCatalogId: BuList.ISA_CATALOG_ID,
                IsaAutocribDb: BuList.ISA_AUTOCRIB_DB,
                IsaAutocribUser: BuList.ISA_AUTOCRIB_USER,
                IsaAutocribPwd: BuList.ISA_AUTOCRIB_PWD,
                ShipToFlg: BuList.SHIP_TO_FLG,
                IsaLaneNbr: BuList.ISA_LANE_NBR,
                LastUpdOprid: CookieService.getLocalStorageValues("Session_UserID"),
                LastUpdDttm: BuList.LASTUPDDTTM,
                IsaTrackDbGuid: BuList.ISA_TRACK_DB_GUID,
                IsaTrackDbType: BuList.ISA_TRACK_DB_TYPE,
                IsaTrackDbUsr: BuList.ISA_TRACK_DB_USR,
                IsaTrackDbPssw: BuList.ISA_TRACK_DB_PSSW,
                IsaTrackDbCust: BuList.ISA_TRACK_DB_CUST,
                IsaCartChgCdReq: BuList.ISA_CARTCHGCDREQ,
                IsaCartWoReq: BuList.ISA_CARTWOREQ,
                IsaWoChgOpt: BuList.ISA_WO_CHG_OPT,
                IsaPriceDispreq: BuList.ISA_PRICEDISPREQ,
                IsaRcvBarcode: BuList.ISA_RCVBARCODE,
                IsaValidateWo: BuList.ISA_VALIDATE_WO,
                IsaOroAsStock: BuList.ISA_ORO_AS_STOCK,
                IsaSigpadDispatc: BuList.ISA_SIGPAD_DISPATC,
                IsaSigpadIssue: BuList.ISA_SIGPAD_ISSUE,
                IsaRfqOnly: BuList.ISA_RFQ_ONLY,
                ClientLogoImage: BuList.CLIENTLOGOIMAGE,
                IsaSearchSvr: BuList.ISA_SEARCH_SVR,
                IsaSearchDb: BuList.ISA_SEARCH_DB,
                IsaKitPrinting: BuList.ISA_KIT_PRINTING,
                IsaZeroEmail: BuList.ISA_ZERO_EMAIL,
                IsaImageName: BuList.ISA_IMAGE_NAME,
                IsaSiteLang: BuList.ISA_SITE_LANG,
                IsaItemPrefix: BuList.ISA_ITEM_PREFIX,
                IsaClientSvsEml: BuList.ISA_CLIENTSVS_EML,
                IsaCartTaxFlag: BuList.ISA_CART_TAX_FLAG,
                IsaTreeHold: BuList.ISA_TREE_HOLD,
                IsaSdiCat: BuList.ISA_SDI_CAT,
                ReqOroBlk: BuList.REQ_ORO_BLK,
                OroPunchChk: BuList.ORO_PUNCH_CHK,
                IsaSdiexchApprvl: BuList.ISA_SDIEXCH_APPRVL,
                IsaCustintApprvl: BuList.ISA_CUSTINT_APPRVL,
                ApprovalType: BuList.APPROVAL_TYPE,
                PoReqBox: BuList.PO_REQ_BOX,
                IsaAsnReceipt: BuList.ISA_ASN_RECEIPT,
                IsaPoIntfcChng: BuList.ISA_PO_INTFC_CHNG,
                IsaSdixReceipt: BuList.ISA_SDIX_RECEIPT,
                IsaPoChngOut: BuList.ISA_PO_CHNG_OUT,
                BuStatus: BuList.BU_STATUS,
                DeptId: BuList.DEPTID,
                IsaOroUseLead: BuList.ISA_ORO_USE_LEAD,
                IsaAmznSearch: BuList.ISA_AMZN_SEARCH,
                IsaManOrderNo: BuList.ISA_MAN_ORDER_NO,
                IsaMdmUser: BuList.ISA_MDM_USER,
                IsaOrdnlnrenum: BuList.ISA_ORDLNRENUM,
                IsaBypRqstrAppr: BuList.ISA_BYP_RQSTR_APPR,
                IsaRecvPrice: BuList.ISA_RECV_PRICE,
                ApprvalThreshold: BuList.APPRVALTHRESHOLD,
                IsaShortPick: BuList.ISA_SHORT_PICK,
                IsaCstVendor: BuList.ISA_CST_VENDOR,
                IsaCstPrice: BuList.ISA_CST_PRICE,
                IsaBypApprvRush: BuList.ISA_BYP_APPRV_RUSH,
                IsaPartList: BuList.ISA_PART_LIST,
                IsaShpdocRcv: BuList.ISA_SHPDOC_RCV,
                Isa3msrch: BuList.ISA_3M_SRCH,
                IsaOrsnascoSrch: BuList.ISA_ORSNASCO_SRCH,
                IsaCappSrch: BuList.ISA_CAPP_SRCH,
                IsaImpSrch: BuList.ISA_IMP_SRCH,
                IsaMScSrch: BuList.ISA_MSC_SRCH,
                IsaUseRfStatus: BuList.ISA_USE_RF_STATUS,
                ProformaFlag: BuList.PROFORMA_FLAG,
                StagingFlag: BuList.STAGING_FLAG,
                StaufferSrch: BuList.STAUFFER_SRCH,
                AutoAssignFlg: BuList.AUTO_ASSIGN_FLG,
                ZeusSite: BuList.ZEUS_SITE,
                IsaCredCardProc: BuList.ISA_CRED_CARD_PROC,
                IsaRfqSite: BuList.ISA_RFQ_SITE,
                IsaEmployeePrn: BuList.ISA_EMPLOYEE_PRN,
                IsaStkIol: BuList.ISA_STK_IOL,
                IsaContractFlag: BuList.ISA_CONTRACT_FLAG,
                IsaSiteBudLimit: BuList.ISA_SITE_BUD_LIMIT,
                IsaCrossSiteAcc: BuList.ISA_CROSS_SITE_ACC,
                IsaGraingerSrch: BuList.ISA_GRAINGER_SRCH,
                IsaAddCatalog: BuList.ISA_ADD_CATALOG,
                IsaIpmSite: BuList.ISA_IPM_SITE,
                IsaQuoteExpDays: BuList.ISA_QUOTE_EXP_DAYS,
                IsaLabelPrinter: BuList.ISA_LABEL_PRINTER,
                IsaEffdtDay: BuList.ISA_EFFDT_DAY,
                IsaAddrSelect: BuList.ISA_ADDR_SELECT,
                IsaLeadDays: BuList.ISA_LEAD_DAYS,
                IsaUnloadDisplay: BuList.ISA_UNLOAD_DISPLAY,
                IsaIpmCore: BuList.ISA_IPM_CORE,
                inValidSearchField: false

            });
            $("#EC_Grid").hide();
            $("#EC_Form").show();
            $("#buDiv").hide();
            $("#buDiv2").hide();
            $("#business-unit").addClass('marginTop');
            $("#business-unit2").addClass('marginTop');
            $("#business-unit").removeClass('validation');
            $("#IsaCplusProdview").removeClass('validation');
            $("#CustId").removeClass('validation');
            $("#SetId").removeClass('validation');
            $("#IsaCompanyId").removeClass('validation');
            $("#spbusiness-unit").hide();
            $("#spprodview").hide();
            $("#spcust-id").hide();
            $("#spset-id").hide();
            $("#spcompany-id").hide();
            $("#IsaInstkOutEmail").removeClass('validation');
            $("#IsaItemAddEmail").removeClass('validation');
            $("#IsaNonStkReqEmail").removeClass('validation');
            $("#IsaSiteEmail").removeClass('validation');
            $("#IsaStockReqEmail").removeClass('validation');
            $("#IsaZeroEmail").removeClass('validation');
            $("#IsaClientSvsEml").removeClass('validation');
            $("#spin-stock").hide();
            $("#spadd-email").hide();
            $("#spnonstock").hide();
            $("#spsite-email").hide();
            $("#spstock-email").hide();
            $("#spzero-email").hide();
            $("#spsvs-email").hide();
            $("#contentDetails3").show();
            $("#contentDetails4").hide();
            $("#contentDetails1").show();
            $("#contentDetails2").hide();
            $("#overall-details-tab").click();
            window.scrollTo(0, 0);
        };
    };
    //PC_EC_20
    //to handle the values changes of the business unit drop down and set it in state variables
    handleOnChange = (e) => {
        debugger;
        $("#business-unit").removeClass('validation');
        $("#spbusiness-unit").hide();
        this.setState({
            loadAvailBUValue: e
        });
    };
    removeValidation = () => {
        debugger;
        if (inValidFields.length > 0) {
            inValidFields.forEach((item, index) => {
                debugger;
                let errorId = "#sp" + item;
                let fieldloc = "#" + item;
                $(fieldloc).removeClass('validation');
                $(errorId).hide();
            })
        }
        inValidFields = [];
    };
    addValidation = () => {
        debugger;
        if (inValidFields.length > 0) {
            inValidFields.forEach((item, index) => {
                debugger;
                let errorId = "#sp" + item;
                let fieldloc = "#" + item;
                $(fieldloc).addClass('validation');
                $(errorId).show();
            })
        }
    };
    //to handle the values changes of the other fields and set it in state variables
    handleOnChangeFields = (e) => {
        debugger;
        let fieldType = e.target.type;
        let fieldID = e.target.name;
        let fieldValue = e.target.value;
        let jsonRegex = SDIConstants.jsonRegex;
        let errorId = "#sp" + fieldID;
        let fieldloc = "#" + fieldID;
        if (fieldType == "text") {
            switch (fieldID) {
                case "business-unit": {
                    if (this.state.loadAvailBUValue == "" || this.state.loadAvailBUValue == " ") {
                        $("#business-unit").addClass('validation');
                        $("#spbusiness-unit").show();
                    }
                    else {
                        $("#business-unit").removeClass('validation');
                        $("#spbusiness-unit").hide();
                    }
                    break;
                }
                case "CustId": {
                    if (e.target.value == "" || e.target.value == " ") {
                        $("#CustId").addClass('validation');
                        $("#spcust-id").show();
                    }
                    else {
                        $("#CustId").removeClass('validation');
                        $("#spcust-id").hide();
                    }
                    break;
                }
                case "IsaCplusProdview": {
                    if (e.target.value == "" || e.target.value == " ") {
                        $("#IsaCplusProdview").addClass('validation');
                        $("#spprodview").show();
                    }
                    else {
                        $("#IsaCplusProdview").removeClass('validation');
                        $("#spprodview").hide();
                    }
                    break;
                }
                case "SetId": {
                    if (e.target.value == "" || e.target.value == " ") {
                        $("#SetId").addClass('validation');
                        $("#spset-id").show();
                    }
                    else {
                        $("#SetId").removeClass('validation');
                        $("#spset-id").hide();
                    }
                    break;
                }
                case "IsaCompanyId": {
                    if (e.target.value == "" || e.target.value == " ") {
                        $("#IsaCompanyId").addClass('validation');
                        $("#spcompany-id").show();
                    }
                    else {
                        $("#IsaCompanyId").removeClass('validation');
                        $("#spcompany-id").hide();
                    }
                    break;
                }
                case "IsaInstkOutEmail": {
                    $("#IsaInstkOutEmail").removeClass('validation');
                    $("#spin-stock").hide();
                    break;
                }
                case "IsaItemAddEmail": {
                    $("#IsaItemAddEmail").removeClass('validation');
                    $("#spadd-email").hide();
                    break;
                }
                case "IsaNonStkReqEmail": {
                    $("#IsaNonStkReqEmail").removeClass('validation');
                    $("#spnonstock").hide();
                    break;
                }
                case "IsaSiteEmail": {
                    $("#IsaSiteEmail").removeClass('validation');
                    $("#spsite-email").hide();
                    break;
                }
                case "IsaStockReqEmail": {
                    $("#IsaStockReqEmail").removeClass('validation');
                    $("#spstock-email").hide();
                    break;
                }
                case "IsaZeroEmail": {
                    $("#IsaZeroEmail").removeClass('validation');
                    $("#spzero-email").hide();
                    break;
                }
                case "IsaClientSvsEml": {
                    $("#IsaClientSvsEml").removeClass('validation');
                    $("#spsvs-email").hide();
                    break;
                }
            }

            if (jsonRegex.test(fieldValue)) {

                $(fieldloc).addClass('validation');
                $(errorId).show();
                inValidFields.push(fieldID);

            }
            else {
                inValidFields = inValidFields.filter(item => item !== fieldID);
                $(fieldloc).removeClass('validation');
                $(errorId).hide();
                //switch (fieldID) {
                //    case "CustId": {
                //        if (e.target.value == "" || e.target.value == " ") {
                //            $("#CustId").addClass('validation');
                //            $("#spcust-id").show();
                //        }
                //        else {
                //            $("#CustId").removeClass('validation');
                //            $("#spcust-id").hide();
                //        }
                //        break;
                //    }
                //    case "IsaCplusProdview": {
                //        if (e.target.value == "" || e.target.value == " ") {
                //            $("#IsaCplusProdview").addClass('validation');
                //            $("#spprodview").show();
                //        }
                //        else {
                //            $("#IsaCplusProdview").removeClass('validation');
                //            $("#spprodview").hide();
                //        }
                //        break;
                //    }
                //    case "SetId": {
                //        if (e.target.value == "" || e.target.value == " ") {
                //            $("#SetId").addClass('validation');
                //            $("#spset-id").show();
                //        }
                //        else {
                //            $("#SetId").removeClass('validation');
                //            $("#spset-id").hide();
                //        }
                //        break;
                //    }
                //    case "IsaCompanyId": {
                //        if (e.target.value == "" || e.target.value == " ") {
                //            $("#IsaCompanyId").addClass('validation');
                //            $("#spcompany-id").show();
                //        }
                //        else {
                //            $("#IsaCompanyId").removeClass('validation');
                //            $("#spcompany-id").hide();
                //        }
                //        break;
                //    }

                //}            
            }
        }
        this.setState({
            [e.target.name]: e.target.value
        });

    };
    // to vaidate and set data to the text field when searched
    handleOnChangeSearchFields = (e) => {
        debugger;
        let fieldType = e.target.type;
        let fieldID = e.target.name;
        let fieldValue = e.target.value;
        let jsonRegex = SDIConstants.jsonRegex;
        let errorId = "#sp" + fieldID;
        let fieldloc = "#" + fieldID;
        let inValidSearchFieldLoc = false;

        if (fieldType == "text") {

            if (jsonRegex.test(fieldValue)) {
                $(fieldloc).addClass('validation');
                $(errorId).show();
                inValidFields.push(fieldID);
                inValidSearchFieldLoc = true;
            }
            else {
                inValidFields = inValidFields.filter(item => item !== fieldID);
                $(fieldloc).removeClass('validation');
                $(errorId).hide();
                inValidSearchFieldLoc = false;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            inValidSearchField: inValidSearchFieldLoc
        });

    };

    //to handle the values changes of the selected radio buttons and set it in state variables
    handleOnChangeSeleRadio = (e) => {
        let tempName = e.target.name;
        tempName = tempName.slice(0, -1);
        this.setState({
            [tempName]: e.target.value
        });
    }
    //PC_EC_21
    //to get the selected item lable of the serach fields autocomplete in the form
    getItemValue(item) {
        debugger;
        return `${item.label}`;
    };
    // to add the fileds options in the serach fields autocomplete in the form
    renderItem(item, isHighlighted) {


        if (item.label == "No options") {
            return (
                <div key={item.label} style={{
                    background: 'white', height: '30px', overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap', cursor: 'pointer', width: '200px', fontSize: 'medium', color: 'grey', paddingLeft: '70px', paddingTop: '5px', paddingBottom: '5px'
                }}>
                    {item.label}
                </div>
            );
        }
        else {
            return (
                <div key={item.label} style={{
                    background: isHighlighted ? 'lightgray' : 'white', height: '30px', overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap', cursor: 'pointer', width: '200px', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px'
                }}>
                    {item.label}
                </div>
            );
        }



    };
    // to set the values of selected fields autocomplete in state varibles for displaying
    onSelect(val) {
        debugger;
        let flddetailLoc = this.state.fieldsDetailsData;
        let locArr1 = [];
        let type = "";
        let searchValue = "";
        if (val.startsWith(' ')) {
            val = val.slice(1); // Remove leading space
        }
        if (flddetailLoc != undefined && flddetailLoc != null && flddetailLoc != "") {
            let filteredFldDetails = flddetailLoc.filter((x) => x.FIELD_NAME == val);
            filteredFldDetails.map((value) => {
                let locArr = {
                    label: value.FIELD_NAME, id: value.FIELD_ID, maxLength: value.MAX_LENGTH,
                    value: value.FIELD_ID, IsMandatory: value.IS_MANDATORY, type: value.FIELD_TYPE, column: value.TABLE_COLUMN, placeHolder: value.PLACE_HOLDER
                };
                locArr1.push(locArr);
                type = value.FIELD_TYPE;
                searchValue = value.FIELD_ID;
                this.setState({
                    placeHolder: value.PLACE_HOLDER,
                    dBfieldName: value.FIELD_NAME,
                    fieldID: value.FIELD_ID,
                    maxLength: value.MAX_LENGTH,
                    IsMandatory: value.IS_MANDATORY,
                    type: value.FIELD_TYPE,
                    column: value.TABLE_COLUMN
                });
            })
            console.log(locArr1);
            let inValidSearchFieldLoc = false;
            if (val == "No options") {
                this.setState({
                    fieldName: ""
                });
            }
            else {
                if (type == "Radiobutton") {
                    //$("#contentDetails1").hide();
                    $("#contentDetails3").hide();
                    $("#contentDetails4").show();
                    $("#flags-tab").click();
                }
                else {
                    debugger;
                    let isInvalidField = inValidFields.filter(item => item.includes(searchValue));
                    if (isInvalidField.length > 0) {
                        inValidSearchFieldLoc = true;
                    }
                    else {
                        inValidSearchFieldLoc = false;
                    }
                    $("#contentDetails1").hide();
                    $("#contentDetails2").show();
                    $("#overall-details-tab").click();
                }

                this.setState({
                    fieldName: val,
                    inValidSearchField: inValidSearchFieldLoc
                });
            }
        }
    };
    // to get the filtered fields based on the charecters entered
    onChange(e) {
        let fieldValue = e.target.value;
        if (fieldValue.startsWith(' ')) {
            fieldValue = fieldValue.slice(1); // Remove leading space
        }
        this.setState({
            fieldName: fieldValue
        });
        this.retrieveDataAsynchronously(fieldValue);
    };
    retrieveDataAsynchronously = async (searchText) => {

        debugger;
        let autoCompleteDataRes = [];
        let responseData = this.state.fieldsDetailsData;
        if (this.state.btnName == "Update") {
            responseData = responseData.filter(value => value.FIELD_NAME !== "Business unit");
        }
        let filteredData = responseData.filter((item) => item.FIELD_NAME.toLowerCase().includes(searchText.toLowerCase())
        );
        let suggestionsStr = filteredData.filter((item) => item != null);
        if (suggestionsStr.length == 0) {
            autoCompleteDataRes.push({ label: "No options", value: "" });
        }
        else {
            suggestionsStr.forEach((sugg, index) => {
                autoCompleteDataRes.push({ label: sugg.FIELD_NAME, value: sugg.FIELD_ID });
            });
        }


        this.setState({ fieldDetailsDD: autoCompleteDataRes });
    }

    // PC_EC_22 to handle the clear button of the serach filed autocomplte
    clearSearch = (action) => {
        let flddetailLoc = [];
        let locArr1 = [];
        if (this.state.fieldsDetailsData != undefined && this.state.fieldsDetailsData != null && this.state.fieldsDetailsData != "") {
            flddetailLoc = this.state.fieldsDetailsData;
            if (this.state.btnName == "Update") {
                flddetailLoc = flddetailLoc.filter(value => value.FIELD_NAME !== "Business unit");
            }
            flddetailLoc.map((value) => {
                let locArr = { label: value.FIELD_NAME, value: value.FIELD_ID };
                locArr1.push(locArr);
            })
        }

        if (action != "TabClick") {
            if (this.state.type == "Radiobutton") {
                $("#contentDetails2").hide();
                $("#contentDetails4").hide();
                $("#contentDetails3").show();
                $("#contentDetails1").show();
                $("#flags-tab").click();
            }
            else {
                $("#contentDetails2").hide();
                $("#contentDetails4").hide();
                $("#contentDetails3").show();
                $("#contentDetails1").show();
                $("#overall-details-tab").click();
            }
        }
        else {
            $("#contentDetails2").hide();
            $("#contentDetails4").hide();
            $("#contentDetails3").show();
            $("#contentDetails1").show();
        }
        this.setState({
            placeHolder: "",
            dBfieldName: "",
            fieldID: "",
            maxLength: "",
            IsMandatory: "",
            type: "",
            column: "",
            fieldDetailsDD: locArr1,
            fieldName: ""
        });
    }
    // PC_EC_29 validates the mandatory fields and email fields on submit or update click
    validateFields = (e) => {
        //debugger;
        let isValidData = false;
        if (this.state.btnName == "Submit") {
            if (this.state.loadAvailBUValue == "" || this.state.loadAvailBUValue == " ") {
                isValidData = true;
                $("#business-unit").addClass('validation');
                $("#spbusiness-unit").show();
            }
            else {
                $("#business-unit").removeClass('validation');
                $("#spbusiness-unit").hide();
            }
        }
        if (this.state.CustId.trim() == "") {
            isValidData = true;
            $("#CustId").addClass('validation');
            $("#spcust-id").show();
        }
        else {
            $("#CustId").removeClass('validation');
            $("#spcust-id").hide();
        }

        if (this.state.IsaCplusProdview.trim() == "") {
            isValidData = true;
            $("#IsaCplusProdview").addClass('validation');
            $("#spprodview").show();
        }
        else {
            $("#IsaCplusProdview").removeClass('validation');
            $("#spprodview").hide();
        }

        if (this.state.SetId.trim() == "") {
            isValidData = true;
            $("#SetId").addClass('validation');
            $("#spset-id").show();
        }
        else {
            $("#SetId").removeClass('validation');
            $("#spset-id").hide();
        }
        if (this.state.IsaCompanyId.trim() == "") {
            isValidData = true;
            $("#IsaCompanyId").addClass('validation');
            $("#spcompany-id").show();
        }
        else {
            $("#IsaCompanyId").removeClass('validation');
            $("#spcompany-id").hide();
        }
        return isValidData;
    };
    validateEmailFields = (e) => {
        let isNotValidEmail = false;
        if (this.state.IsaInstkOutEmail.trim() != "") {
            let invalidChar = inValidFields.filter(item => item.includes('IsaInstkOutEmail'));
            if (invalidChar.length == 0) {
                if (this.validateEmails(this.state.IsaInstkOutEmail.trim())) {
                    $("#IsaInstkOutEmail").addClass('validation');
                    $("#spin-stock").show();
                    isNotValidEmail = true;
                }
            }
        }
        if (this.state.IsaItemAddEmail.trim() != "") {
            let invalidChar = inValidFields.filter(item => item.includes('IsaItemAddEmail'));
            if (invalidChar.length == 0) {
                if (this.validateEmails(this.state.IsaItemAddEmail.trim())) {
                    $("#IsaItemAddEmail").addClass('validation');
                    $("#spadd-email").show();
                    isNotValidEmail = true;
                }
            }
        }
        if (this.state.IsaNonStkReqEmail.trim() != "") {
            let invalidChar = inValidFields.filter(item => item.includes('IsaNonStkReqEmail'));
            if (invalidChar.length == 0) {
                if (this.validateEmails(this.state.IsaNonStkReqEmail.trim())) {
                    $("#IsaNonStkReqEmail").addClass('validation');
                    $("#spnonstock").show();
                    isNotValidEmail = true;
                }
            }
        }
        if (this.state.IsaSiteEmail.trim() != "") {
            let invalidChar = inValidFields.filter(item => item.includes('IsaSiteEmail'));
            if (invalidChar.length == 0) {
                if (this.validateEmails(this.state.IsaSiteEmail.trim())) {
                    $("#IsaSiteEmail").addClass('validation');
                    $("#spsite-email").show();
                    isNotValidEmail = true;
                }
            }
        }
        if (this.state.IsaStockReqEmail.trim() != "") {
            let invalidChar = inValidFields.filter(item => item.includes('IsaStockReqEmail'));
            if (invalidChar.length == 0) {
                if (this.validateEmails(this.state.IsaStockReqEmail.trim())) {
                    $("#IsaStockReqEmail").addClass('validation');
                    $("#spstock-email").show();
                    isNotValidEmail = true;
                }
            }
        }
        if (this.state.IsaZeroEmail.trim() != "") {
            let invalidChar = inValidFields.filter(item => item.includes('IsaZeroEmail'));
            if (invalidChar.length == 0) {
                if (this.validateEmails(this.state.IsaZeroEmail.trim())) {
                    $("#IsaZeroEmail").addClass('validation');
                    $("#spzero-email").show();
                    isNotValidEmail = true;
                }
            }
        }
        if (this.state.IsaClientSvsEml.trim() != "") {
            let invalidChar = inValidFields.filter(item => item.includes('IsaClientSvsEml'));
            if (invalidChar.length == 0) {
                if (this.validateEmails(this.state.IsaClientSvsEml.trim())) {
                    $("#IsaClientSvsEml").addClass('validation');
                    $("#spsvs-email").show();
                    isNotValidEmail = true;
                }
            }
        }
        return isNotValidEmail;
    };
    // PC_EC_30 validates email fields on submit or update click
    validateEmails = (email) => {
        //debugger;
        let emailList = email.replace(/;+$/, '');
        let emailArray = emailList.split(';');
        let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        let isValid = emailArray.every(email => emailRegex.test(email.trim()));
        return !(isValid);
    };

    // to handle the values selected in a date picker of the form
    handleDatePicker = (date, name) => {
        this.setState({
            [name]: date
        });
        console.log("Date", date);
    };
    // PC_EC_24 to clear the selected logo
    handleClearLogo = () => {
        $("#btnBrowesLogo").show();
        $("#lblLogo").hide();
        $("#btnClrLogo").hide();
        document.getElementById('fileInput').value = '';
        this.setState({
            ClientLogoImage: ""
        });
    };
    // PC_EC_23 to save the logo file's name
    handleAttachment = (event) => {
        debugger;
        const files = event.target.files
        let isValidType = false;
        let isValidLength = false;
        if (files.length > 0) {
            const fileType = files[0].type.toLowerCase();

            if (fileType.includes('jpg') || fileType.includes('png') || fileType.includes('jpeg') || fileType.includes('svg')) {
                isValidType = true;
            } else {
                isValidType = false;
                this.setState({
                    ClientLogoImage: ""
                });
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "You can choose only files of type .jpg, .jpeg .png, .svg"
                });
            }
            if (isValidType) {
                if (files[0].name.length > 20) {
                    this.setState({
                        ClientLogoImage: ""
                    });
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "File name should not exceed 20 characters"
                    });
                }
                else {
                    isValidLength = true
                }
            }

            if (isValidType && isValidLength) {
                this.setState({
                    ClientLogoImage: files[0].name
                });
                console.log(files[0].name);
                $("#btnBrowesLogo").hide();
                $("#lblLogo").show();
                $("#btnClrLogo").show();
            }
        }
    };
    // PC_EC_28to insert or update the values in the form
    submitBUOrUpdateBU = () => {
        debugger;
        let kit = this.state.IsaKitPrinting;
        let loadedBu = this.state.loadAvailBUValue;
        let bu = "";
        let isNotValidDataLoc = this.validateFields();
        this.addValidation();
        let isNotValidEmailsLoc = this.validateEmailFields();
        if (this.state.btnName == "Submit") {
            debugger;
            bu = loadedBu.value;
        }
        else {
            bu = this.state.headerName.substring(0, 5);
        }
        if (isNotValidDataLoc == false && isNotValidEmailsLoc == false && inValidFields.length == 0) {
            let EnterpriseBO = {
                Action: this.state.btnName,
                SetId: this.state.SetId,
                CustId: this.state.CustId,
                IsaBusinessUnit: bu,
                IsaCompanyId: this.state.IsaCompanyId,
                IsaCplusProdview: this.state.IsaCplusProdview,
                IsaStartingItemId: this.state.IsaStartingItemId == "" ? 0 : parseInt(this.state.IsaStartingItemId),
                IsaLastItemId: this.state.IsaLastItemId == "" ? 0 : parseInt(this.state.IsaLastItemId),
                IsaTotalNumExp: this.state.IsaTotalNumExp == "" ? 0 : parseInt(this.state.IsaTotalNumExp),
                IsaCurrentExport: this.state.IsaCurrentExport == "" ? 0 : parseInt(this.state.IsaCurrentExport),
                IsaLastExportDt: this.state.IsaLastExportDt ? format(new Date(this.state.IsaLastExportDt), 'MM/dd/yyyy hh:mm:ss a') : null,
                IsaCustomerCodes: this.state.IsaCustomerCodes == "" ? " " : this.state.IsaCustomerCodes,
                IsaItemIdLen: this.state.IsaItemIdLen == "" ? 0 : parseInt(this.state.IsaItemIdLen),
                IsaItemIdMode: this.state.IsaItemIdMode == "" ? " " : this.state.IsaItemIdMode,
                IsaTotalFldSize: this.state.IsaTotalFldSize == "" ? 0 : parseInt(this.state.IsaTotalFldSize),
                IsaSiteEmail: this.state.IsaSiteEmail == "" ? " " : this.state.IsaSiteEmail,
                IsaItemAddEmail: this.state.IsaItemAddEmail == "" ? " " : this.state.IsaItemAddEmail,
                IsaStockReqEmail: this.state.IsaStockReqEmail == "" ? " " : this.state.IsaStockReqEmail,
                IsaNonStkReqEmail: this.state.IsaNonStkReqEmail == "" ? " " : this.state.IsaNonStkReqEmail,
                IsaInstkOutEmail: this.state.IsaInstkOutEmail == "" ? " " : this.state.IsaInstkOutEmail,
                IsaSitePrinter: this.state.IsaSitePrinter == "" ? " " : this.state.IsaSitePrinter,
                IsaItmAddPrinter: this.state.IsaItmAddPrinter == "" ? " " : this.state.IsaItmAddPrinter,
                IsaStockPrinter: this.state.IsaStockPrinter == "" ? " " : this.state.IsaStockPrinter,
                IsaNonStkPrinter: this.state.IsaNonStkPrinter == "" ? " " : this.state.IsaNonStkPrinter,
                NextWebReqId: this.state.NextWebReqId == "" ? 0 : parseInt(this.state.NextWebReqId),
                IsaOrdApprType: this.state.IsaOrdApprType == "" ? " " : this.state.IsaOrdApprType,
                IsaOrdBudgetFlg: this.state.IsaOrdBudgetFlg == "" ? " " : this.state.IsaOrdBudgetFlg,
                IsaApprNstkFlag: this.state.IsaApprNstkFlag,
                IsaReceivingDate: this.state.IsaReceivingDate ? format(new Date(this.state.IsaReceivingDate), 'MM/dd/yyyy hh:mm:ss a') : null,
                IsaReceivingType: this.state.IsaReceivingType == "" ? " " : this.state.IsaReceivingType,
                IsaStdNstkSite: this.state.IsaStdNstkSite,
                IsaCustPrfxFlag: this.state.IsaCustPrfxFlag,
                IsaCustPrefix: this.state.IsaCustPrefix == "" ? " " : this.state.IsaCustPrefix,
                IsaShopcartPage: this.state.IsaShopcartPage == "" ? " " : this.state.IsaShopcartPage,
                IsaLppFlag: this.state.IsaLppFlag,
                IsaIsolTaxFlag: this.state.IsaIsolTaxFlag,
                IsaIsolPunchout: this.state.IsaIsolPunchout,
                IsaMobilePicking: this.state.IsaMobilePicking,
                IsaMobilePutaway: this.state.IsaMobilePutaway,
                IsaMobileIssue: this.state.IsaMobileIssue,
                IsaPwExpireDays: this.state.IsaPwExpireDays == "" ? 0 : parseInt(this.state.IsaPwExpireDays),
                IsaAutoasMaxLns: this.state.IsaAutoasMaxLns == "" ? 0 : parseInt(this.state.IsaAutoasMaxLns),
                IsaLastStatSend: this.state.IsaLastStatSend ? format(new Date(this.state.IsaLastStatSend), 'MM/dd/yyyy hh:mm:ss a') : null,
                IsaUseOroIoh: this.state.IsaUseOroIoh,
                IsaMobPkOm: this.state.IsaMobPkOm,
                IsaMobPkIn: this.state.IsaMobPkIn,
                IsaMobUseCc: this.state.IsaMobUseCc,
                IsaCatalogId: this.state.IsaCatalogId == "" ? " " : this.state.IsaCatalogId,
                IsaAutocribDb: this.state.IsaAutocribDb == "" ? " " : this.state.IsaAutocribDb,
                IsaAutocribUser: this.state.IsaAutocribUser == "" ? " " : this.state.IsaAutocribUser,
                IsaAutocribPwd: this.state.IsaAutocribPwd == "" ? " " : this.state.IsaAutocribPwd,
                ShipToFlg: this.state.ShipToFlg,
                IsaLaneNbr: this.state.IsaLaneNbr == "" ? " " : this.state.IsaLaneNbr,
                LastUpdOprid: CookieService.getLocalStorageValues("Session_UserID"),
                LastUpdDttm: this.state.LastUpdDttm == "" ? " " : this.state.LastUpdDttm,
                IsaTrackDbGuid: this.state.IsaTrackDbGuid == "" ? " " : this.state.IsaTrackDbGuid,
                IsaTrackDbType: this.state.IsaTrackDbType == "" ? " " : this.state.IsaTrackDbType,
                IsaTrackDbUsr: this.state.IsaTrackDbUsr == "" ? " " : this.state.IsaTrackDbUsr,
                IsaTrackDbPssw: this.state.IsaTrackDbPssw == "" ? " " : this.state.IsaTrackDbPssw,
                IsaTrackDbCust: this.state.IsaTrackDbCust == "" ? " " : this.state.IsaTrackDbCust,
                IsaCartChgCdReq: this.state.IsaCartChgCdReq,
                IsaCartWoReq: this.state.IsaCartWoReq,
                IsaWoChgOpt: this.state.IsaWoChgOpt,
                IsaPriceDispreq: this.state.IsaPriceDispreq,
                IsaRcvBarcode: this.state.IsaRcvBarcode,
                IsaValidateWo: this.state.IsaValidateWo,
                IsaOroAsStock: this.state.IsaOroAsStock,
                IsaSigpadDispatc: this.state.IsaSigpadDispatc,
                IsaSigpadIssue: this.state.IsaSigpadIssue,
                IsaRfqOnly: this.state.IsaRfqOnly,
                ClientLogoImage: this.state.ClientLogoImage == "" ? " " : this.state.ClientLogoImage,
                IsaSearchSvr: this.state.IsaSearchSvr == "" ? " " : this.state.IsaSearchSvr,
                IsaSearchDb: this.state.IsaSearchDb == "" ? " " : this.state.IsaSearchDb,
                IsaKitPrinting: this.state.IsaKitPrinting,
                IsaZeroEmail: this.state.IsaZeroEmail == "" ? " " : this.state.IsaZeroEmail,
                IsaImageName: this.state.IsaImageName == "" ? " " : this.state.IsaImageName,
                IsaSiteLang: this.state.IsaSiteLang,
                IsaItemPrefix: this.state.IsaItemPrefix == "" ? " " : this.state.IsaItemPrefix,
                IsaClientSvsEml: this.state.IsaClientSvsEml == "" ? " " : this.state.IsaClientSvsEml,
                IsaCartTaxFlag: this.state.IsaCartTaxFlag == "" ? " " : this.state.IsaCartTaxFlag,
                IsaTreeHold: this.state.IsaTreeHold,
                IsaSdiCat: this.state.IsaSdiCat,
                ReqOroBlk: this.state.ReqOroBlk,
                OroPunchChk: this.state.OroPunchChk,
                IsaSdiexchApprvl: this.state.IsaSdiexchApprvl,
                IsaCustintApprvl: this.state.IsaCustintApprvl,
                ApprovalType: this.state.ApprovalType == "" ? " " : this.state.ApprovalType,
                PoReqBox: this.state.PoReqBox,
                IsaAsnReceipt: this.state.IsaAsnReceipt,
                IsaPoIntfcChng: this.state.IsaPoIntfcChng,
                IsaSdixReceipt: this.state.IsaSdixReceipt,
                IsaPoChngOut: this.state.IsaPoChngOut,
                BuStatus: this.state.BuStatus,
                DeptId: this.state.DeptId == "" ? " " : this.state.DeptId,
                IsaOroUseLead: this.state.IsaOroUseLead,
                IsaAmznSearch: this.state.IsaAmznSearch,
                IsaManOrderNo: this.state.IsaManOrderNo,
                IsaMdmUser: this.state.IsaMdmUser,
                IsaOrdnlnrenum: this.state.IsaOrdnlnrenum,
                IsaBypRqstrAppr: this.state.IsaBypRqstrAppr,
                IsaRecvPrice: this.state.IsaRecvPrice,
                ApprvalThreshold: this.state.ApprvalThreshold == "" ? 0 : parseInt(this.state.ApprvalThreshold),
                IsaShortPick: this.state.IsaShortPick == "" ? " " : this.state.IsaShortPick,
                IsaCstVendor: this.state.IsaCstVendor == "" ? " " : this.state.IsaCstVendor,
                IsaCstPrice: this.state.IsaCstPrice == "" ? 0 : parseInt(this.state.IsaCstPrice),
                IsaBypApprvRush: this.state.IsaBypApprvRush == "" ? " " : this.state.IsaBypApprvRush,
                IsaPartList: this.state.IsaPartList,
                IsaShpdocRcv: this.state.IsaShpdocRcv == "" ? " " : this.state.IsaShpdocRcv,
                Isa3msrch: this.state.Isa3msrch == "" ? " " : this.state.Isa3msrch,
                IsaOrsnascoSrch: this.state.IsaOrsnascoSrch,
                IsaCappSrch: this.state.IsaCappSrch,
                IsaImpSrch: this.state.IsaImpSrch,
                IsaMScSrch: this.state.IsaMScSrch,
                IsaUseRfStatus: this.state.IsaUseRfStatus,
                ProformaFlag: this.state.ProformaFlag,
                StagingFlag: this.state.StagingFlag,
                StaufferSrch: this.state.StaufferSrch,
                AutoAssignFlg: this.state.AutoAssignFlg,
                ZeusSite: this.state.ZeusSite,
                IsaCredCardProc: this.state.IsaCredCardProc,
                IsaRfqSite: this.state.IsaRfqSite,
                IsaEmployeePrn: this.state.IsaEmployeePrn,
                IsaStkIol: this.state.IsaStkIol,
                IsaContractFlag: this.state.IsaContractFlag,
                IsaSiteBudLimit: this.state.IsaSiteBudLimit,
                IsaCrossSiteAcc: this.state.IsaCrossSiteAcc,
                IsaGraingerSrch: this.state.IsaGraingerSrch,
                IsaAddCatalog: this.state.IsaAddCatalog,
                IsaIpmSite: this.state.IsaIpmSite,
                IsaQuoteExpDays: this.state.IsaQuoteExpDays,
                IsaLabelPrinter: this.state.IsaLabelPrinter,
                IsaEffdtDay: this.state.IsaEffdtDay == "" ? 0 : parseInt(this.state.IsaEffdtDay),
                IsaAddrSelect: this.state.IsaAddrSelect,
                IsaLeadDays: this.state.IsaLeadDays == "" ? 0 : parseInt(this.state.IsaLeadDays),
                IsaUnloadDisplay: this.state.IsaUnloadDisplay,
                IsaIpmCore: this.state.IsaIpmCore
            }
            debugger;
            console.log(EnterpriseBO);
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            EnterpriseCustAction.insertOrUpdateCustDetails(EnterpriseBO);

        }
        if (isNotValidEmailsLoc == true || isNotValidDataLoc == true || inValidFields.length != 0) {
            this.clearSearch(" ");
            $("#overall-details-tab").click();
        }
        window.scrollTo(0, 0);
    };
    // PC_EC_31 to acknowledge the insertion's and updation's success
    insertOrUpdateCustDetails = () => {
        debugger;
        let ResultResponse = JSON.parse(EnterpriseCustStore.InsertOrUpdateResponse);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ResultResponse.hasOwnProperty('Exception')) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical issue please contact SDI help desk"
            });
        } else {
            debugger;
            if (ResultResponse != undefined && ResultResponse != null && ResultResponse != "") {
                let popUpTextLoc = "";
                if (ResultResponse.RowAffected == 1) {
                    this.setState({
                        SetId: " ",
                        CustId: " ",
                        IsaBusinessUnit: " ",
                        IsaCompanyId: " ",
                        IsaCplusProdview: " ",
                        IsaStartingItemId: 0,
                        IsaLastItemId: 0,
                        IsaTotalNumExp: 0,
                        IsaCurrentExport: 0,
                        IsaLastExportDt: null,
                        IsaCustomerCodes: " ",
                        IsaItemIdLen: 0,
                        IsaItemIdMode: " ",
                        IsaTotalFldSize: 0,
                        IsaSiteEmail: " ",
                        IsaItemAddEmail: " ",
                        IsaStockReqEmail: " ",
                        IsaNonStkReqEmail: " ",
                        IsaInstkOutEmail: " ",
                        IsaSitePrinter: " ",
                        IsaItmAddPrinter: " ",
                        IsaStockPrinter: " ",
                        IsaNonStkPrinter: " ",
                        NextWebReqId: 0,
                        IsaOrdApprType: " ",
                        IsaOrdBudgetFlg: " ",
                        IsaApprNstkFlag: "N",
                        IsaReceivingDate: null,
                        IsaReceivingType: " ",
                        IsaStdNstkSite: "N",
                        IsaCustPrfxFlag: "N",
                        IsaCustPrefix: " ",
                        IsaShopcartPage: " ",
                        IsaLppFlag: "N",
                        IsaIsolTaxFlag: " ",
                        IsaIsolPunchout: "N",
                        IsaMobilePicking: "N",
                        IsaMobilePutaway: "N",
                        IsaMobileIssue: "N",
                        IsaPwExpireDays: 0,
                        IsaAutoasMaxLns: 0,
                        IsaLastStatSend: null,
                        IsaUseOroIoh: "N",
                        IsaMobPkOm: "N",
                        IsaMobPkIn: "N",
                        IsaMobUseCc: "N",
                        IsaCatalogId: " ",
                        IsaAutocribDb: " ",
                        IsaAutocribUser: " ",
                        IsaAutocribPwd: " ",
                        ShipToFlg: "N",
                        IsaLaneNbr: " ",
                        LastUpdOprid: CookieService.getLocalStorageValues("Session_UserID"),
                        LastUpdDttm: null,
                        IsaTrackDbGuid: " ",
                        IsaTrackDbType: " ",
                        IsaTrackDbUsr: " ",
                        IsaTrackDbPssw: " ",
                        IsaTrackDbCust: " ",
                        IsaCartChgCdReq: "N",
                        IsaCartWoReq: "N",
                        IsaWoChgOpt: "N",
                        IsaPriceDispreq: "N",
                        IsaRcvBarcode: "N",
                        IsaValidateWo: "N",
                        IsaOroAsStock: "N",
                        IsaSigpadDispatc: "N",
                        IsaSigpadIssue: "N",
                        IsaRfqOnly: "N",
                        ClientLogoImage: " ",
                        IsaSearchSvr: " ",
                        IsaSearchDb: " ",
                        IsaKitPrinting: "N",
                        IsaZeroEmail: " ",
                        IsaImageName: " ",
                        IsaSiteLang: "N",
                        IsaItemPrefix: " ",
                        IsaClientSvsEml: " ",
                        IsaCartTaxFlag: " ",
                        IsaTreeHold: "N",
                        IsaSdiCat: "N",
                        ReqOroBlk: "N",
                        OroPunchChk: "N",
                        IsaSdiexchApprvl: "N",
                        IsaCustintApprvl: "N",
                        ApprovalType: " ",
                        PoReqBox: "N",
                        IsaAsnReceipt: "N",
                        IsaPoIntfcChng: "N",
                        IsaSdixReceipt: "N",
                        IsaPoChngOut: "N",
                        BuStatus: "2",
                        DeptId: " ",
                        IsaOroUseLead: "N",
                        IsaAmznSearch: "N",
                        IsaManOrderNo: " ",
                        IsaMdmUser: "N",
                        IsaOrdnlnrenum: "N",
                        IsaBypRqstrAppr: "N",
                        IsaRecvPrice: "N",
                        ApprvalThreshold: 0,
                        IsaShortPick: "N",
                        IsaCstVendor: " ",
                        IsaCstPrice: 0,
                        IsaBypApprvRush: "N",
                        IsaPartList: "N",
                        IsaShpdocRcv: " ",
                        Isa3msrch: "N",
                        IsaOrsnascoSrch: "N",
                        IsaCappSrch: "N",
                        IsaImpSrch: "N",
                        IsaMScSrch: "N",
                        IsaUseRfStatus: "N",
                        ProformaFlag: "N",
                        StagingFlag: "N",
                        StaufferSrch: "N",
                        AutoAssignFlg: "N",
                        ZeusSite: "N",
                        IsaCredCardProc: "N",
                        IsaRfqSite: "N",
                        IsaEmployeePrn: "N",
                        IsaStkIol: " ",
                        IsaContractFlag: "N",
                        IsaSiteBudLimit: "N",
                        IsaCrossSiteAcc: "N",
                        IsaGraingerSrch: "N",
                        IsaAddCatalog: "N",
                        IsaIpmSite: "N",
                        IsaQuoteExpDays: 0,
                        IsaLabelPrinter: "N",
                        IsaEffdtDay: 0,
                        IsaAddrSelect: "N",
                        IsaLeadDays: 0,
                        IsaUnloadDisplay: "N",
                        IsaIpmCore: "N",
                        loadAvailBUValue: [],
                        PageNo: 1,
                        openAccordionIndex: null

                    });
                    if (this.state.btnName == 'Submit') {
                        popUpTextLoc = "New customer has been created successfully!";
                    }
                    else {
                        popUpTextLoc = "Customer details has been updated successfully!";
                    }
                    this.setState({
                        popUpText: popUpTextLoc
                    });
                    $('#modalPopupSucsess').modal('show');
                }
                else {
                    if (this.state.btnName == 'Submit') {
                        popUpTextLoc = "Business unit details cannot be submitted!";
                    }
                    else {
                        popUpTextLoc = "Business unit details cannot be updated!";
                    }
                    this.setState({
                        popUpText: popUpTextLoc
                    });
                    $('#modalPopupAlert').modal('show');
                }

            }
            else {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Technical issue please contact SDI help desk"
                });
            }

        }
    };
    handleNumberFields = (event) => {
        debugger;
        const re = SDIConstants.quantityRegEX;
        console.log("Quantity", event.target.value)
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ [event.target.name]: event.target.value });
        }
    };

    render() {
        return (
            <>
                <div className="container-fluid content-section enterprise content-margin">
                    <div className="row">
                        {/*Page Heading starts here*/}
                        <div className="col-md-12 mt-3 page-heading">
                            <h5 className="page-title">Enterprise Customer Master</h5>
                            <div className="fav-item-maintenance-icon mt-1">
                                <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                <label htmlFor="checkbox-1">
                                    <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </div>
                        </div>
                        {/*Page Heading ends here*/}
                        {/*Grid starts here*/}
                        {this.state.filteredBUs.length > 0 ?
                            <div className="col-md-12 mt-4" id="EC_Grid" >
                                {/*search and add button*/}
                                <div className="d-flex justify-content-end align-items-center">
                                    <div>
                                        <div className="input-group dropdown-search">
                                            <div className="input-group-prepend">
                                                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.filterDisplayName}</button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" onClick={(e) => this.handleFiltering(e, "All", "ALL")}>All</a>
                                                    <a className="dropdown-item" onClick={(e) => this.handleFiltering(e, "Business unit", "ISA_BUSINESS_UNIT")} >Business unit</a>
                                                    <a className="dropdown-item" onClick={(e) => this.handleFiltering(e, "Company ID", "ISA_COMPANY_ID")}>Company ID</a>
                                                    <a className="dropdown-item" onClick={(e) => this.handleFiltering(e, "Cust ID", "CUST_ID")}>Cust ID</a>
                                                    <a className="dropdown-item" onClick={(e) => this.handleFiltering(e, "Set ID", "SETID")}>Set ID</a>
                                                </div>
                                            </div>
                                            <input type="text" className="form-control pr-5 order-status-search" placeholder="Search" aria-label="Text input with Cust ID button" value={this.state.searchTxt} id="searchTxt" onChange={this.handleSearchTxt} onKeyDown={(e) => this.searchKeyPress(e)} />
                                            <span className={this.state.searchTxt != null && this.state.searchTxt != "" && this.state.searchTxt != " " ? "search-cancel-header cursor-pointer" : "d-none"} style={{ "top": "11px", "right": "24px" }} onClick={() => this.handleSearchClear()}></span>
                                            <span className="dropdown-search-icon" onClick={(e) => this.filtering()} />
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-3 ml-3 mb-0" onClick={this.handleNewCustClick}><img className="mr-2" src="images/add-icon.svg" alt="add-icon" />New <span className="custom-responsive-none">Customer</span></button>
                                    </div>
                                </div>
                                {/*search and add button*/}
                                {/*Table starts here*/}
                                <div className="table-responsive mt-2">
                                    {this.state.totalCount == 0 ?
                                        <section className="site-undercontruction container text-center center-panel pt-4">
                                            <div className="img-section">
                                                <img src="images/nosearch.jpg" alt="imagefile" />
                                            </div>
                                            <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                            </p>
                                        </section>
                                        :
                                        <table className="table ZEUS_Grid FOM-grid mobileresponsive-grid">
                                            <thead>
                                                <tr>
                                                    <th />
                                                    <th className="position-sort-icon header-font pr-3">Business unit<span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.Sorting("ASC", "ISA_BUSINESS_UNIT")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.Sorting("DESC", "ISA_BUSINESS_UNIT")} /></span></th>
                                                    <th className="position-sort-icon header-font pr-3">Company ID<span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.Sorting("ASC", "ISA_COMPANY_ID")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.Sorting("DESC", "ISA_COMPANY_ID")} /></span></th>
                                                    <th className="position-sort-icon header-font pr-3">Cust ID<span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.Sorting("ASC", "CUST_ID")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.Sorting("DESC", "CUST_ID")} /></span></th>
                                                    <th className="position-sort-icon header-font pr-3">Set ID<span className="sort-icon-up ml-1"><img src="Images/sort2-en.svg" alt="sort-up" onClick={(e) => this.Sorting("ASC", "SETID")} /></span><span className="sort-icon-down ml-1"><img src="Images/sort1-en.svg" alt="sort-down" onClick={(e) => this.Sorting("DESC", "SETID")} /></span></th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.bindGrid()}
                                            </tbody>

                                        </table>}
                                </div>
                                {this.state.totalCount > 0 ?
                                    <div className="col-md-12">
                                        <span className="total-records mt-2 d-inline-block">
                                            <Trans>Number of records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
                                        </span>
                                        {this.state.totalCount > this.state.countPerpage ? <ReactPaginate
                                            previousLabel={<span className="right-pagination-arrow" />}
                                            nextLabel={<span className="left-pagination-arrow" />}
                                            previousClassName={'page-item'}
                                            nextClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextLinkClassName={'page-link'}
                                            disabledClassName={'disabled'}
                                            breakLabel={'...'}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'custom-pagination pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                            hrefBuilder={() => '#'}
                                            forcePage={this.state.PageNo - 1}
                                        /> : null}
                                    </div> : null}
                                {/*Table ends here*/}
                            </div> : null}

                        {/*Grid ends here*/}
                        {/*Form starts here*/}
                        <div id="EC_Form" className="w-100">
                            <title>{this.state.headerName}</title>
                            <meta charSet="UTF-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="stylesheet" type="text/css" href="css/sdiframework.css" />
                            <link rel="stylesheet" type="text/css" href="css/sdistyles.css" />
                            <div className="container-fluid">
                                <div className="row">
                                    {/*Page Heading starts here*/}
                                    <div className="col-md-12 mt-3 page-heading">
                                        <h5 className="page-title"><a className="mr-2" onClick={this.handleCancel}><img style={{ marginBottom: '5px' }} src="images/back-icon.svg" alt="back-icon" /></a>{this.state.headerName}</h5>
                                    </div>
                                    {/*Page Heading ends here*/}
                                    {/*Tab starts here*/}
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center my-3 border-bottom">
                                            <div>
                                                <ul className="nav nav-pills catalog-tabs-mobile catalog-tabs border-0" id="myTab" role="tablist">
                                                    <li className="nav-item mx-0">
                                                        <a className="nav-link rounded-0 bg-white ml-0 active font-medium color-unactive color-active-black" id="overall-details-tab" onClick={() => this.clearSearch("TabClick")} data-toggle="tab" href="#overall-details" role="tab" aria-controls="overall-details" aria-selected="true">Overall details</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link rounded-0 bg-white font-medium color-unactive color-active-black" id="flags-tab" onClick={() => this.clearSearch("TabClick")} data-toggle="tab" href="#flags" role="tab" aria-controls="flags" aria-selected="false">Flags</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <div className="input-group position-relative custom-responsive ">
                                                    <Autocomplete getItemValue={this.getItemValue} items={this.state.fieldDetailsDD}
                                                        renderItem={this.renderItem} value={this.state.fieldName} onChange={this.onChange} onSelect={this.onSelect}
                                                        inputProps={{ placeholder: "Search", className: "form-control custom-search pr-5 order-status-search cursor-pointer" }}
                                                        wrapperStyle={{ position: 'relative', display: 'inline-block', zIndex: '1' }}
                                                        menuStyle={{
                                                            position: 'absolute',
                                                            top: '100%',
                                                            left: 0,
                                                            backgroundColor: 'white',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '4px',
                                                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                            boxSizing: 'border-box',
                                                            maxHeight: '200px',
                                                            overflowY: 'auto',
                                                            overflowX: 'hidden',
                                                            padding: '10px !important',
                                                            maxWidth: '-webkit-fill-available'
                                                        }}
                                                    />
                                                    <span className={this.state.fieldName.trim().length > 0 ? "search-cancel1 cursor-pointer custom-search-cancel" : "d-none"} onClick={() => this.clearSearch(" ")}></span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*tab Inner content starts here*/}
                                        <div className="tab-content w-100" id="myTabContent">
                                            {/*Overall Details Form Tab*/}
                                            <div className="tab-pane py-1 fade active show" id="overall-details" role="tabpanel">
                                                <div className="row" id="contentDetails1">
                                                    <div className="col-md-4 mb-3" id="buDiv">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="business-unit">Business unit <span className="color-manditory">*</span></label>
                                                        <span style={{ "fontSize": "14px" }} className="bu-drop">
                                                            <Select id="business-unit" className="bu-drop" name="business-unit" placeholder="Select business unit" value={this.state.loadAvailBUValue} options={this.state.loadAvailBU} onChange={(e) => this.handleOnChange(e)} />
                                                        </span>

                                                        <span className="font-12 font-regular color-validation" id="spbusiness-unit">Please select the business unit</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="company-id">Company ID <span className="color-manditory">*</span></label>
                                                        <input type="text" maxLength="10" className="form-control" id="IsaCompanyId" placeholder="Enter company ID" name="IsaCompanyId" value={this.state.IsaCompanyId == " " ? "" : this.state.IsaCompanyId} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spcompany-id">Please enter the company ID</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaCompanyId">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="prodview">CPLUS prodview <span className="color-manditory">*</span></label>
                                                        <input type="text" maxLength="10" className="form-control" id="IsaCplusProdview" placeholder="Enter CPLUS prodview" name="IsaCplusProdview" value={this.state.IsaCplusProdview == " " ? "" : this.state.IsaCplusProdview} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spprodview">Please enter the CPLUS prodview</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaCplusProdview">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="cust-id">Cust ID <span className="color-manditory">*</span></label>
                                                        <input type="text" maxLength="15" className="form-control" id="CustId" placeholder="Enter cust ID" name="CustId" value={this.state.CustId == " " ? "" : this.state.CustId} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spcust-id">Please enter the cust ID</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spCustId">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="set-id">Set ID <span className="color-manditory">*</span></label>
                                                        <input type="text" maxLength="5" className="form-control" id="SetId" placeholder="Enter set ID" name="SetId" value={this.state.SetId == " " ? "" : this.state.SetId} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spset-id">Please enter the set ID</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spSetId">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-12 mt-4">
                                                        <h5 className="font-14 font-bold">Additional information</h5>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="approval-threshold">Approval threshold</label>
                                                        <input type="text" className="form-control" id="approval-threshold" placeholder="Enter approval threshold" maxLength="10" name="ApprvalThreshold" value={this.state.ApprvalThreshold == "0" ? "" : this.state.ApprvalThreshold} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="approval-type">Approval type</label>
                                                        <input type="text" className="form-control" id="ApprovalType" placeholder="Enter approval type" maxLength="4" name="ApprovalType" value={this.state.ApprovalType == " " ? "" : this.state.ApprovalType} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spApprovalType">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="crib-db">Auto crib DB</label>
                                                        <input type="text" className="form-control" id="IsaAutocribDb" placeholder="Enter auto crib DB" name="IsaAutocribDb" maxLength="50" value={this.state.IsaAutocribDb == " " ? "" : this.state.IsaAutocribDb} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaAutocribDb">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="crib-password">Auto crib password</label>
                                                        <input type="text" className="form-control" id="IsaAutocribPwd" placeholder="Enter auto crib password" maxLength="20" name="IsaAutocribPwd" value={this.state.IsaAutocribPwd == " " ? "" : this.state.IsaAutocribPwd} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaAutocribPwd">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="crib-user">Auto crib user</label>
                                                        <input type="text" className="form-control" id="IsaAutocribUser" placeholder="Enter auto crib user" name="IsaAutocribUser" maxLength="50" value={this.state.IsaAutocribUser == " " ? "" : this.state.IsaAutocribUser} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaAutocribUser">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="auto-max">Auto max LNS</label>
                                                        <input type="text" className="form-control" id="auto-max" placeholder="Enter auto max LNS" name="IsaAutoasMaxLns" maxLength="10" value={this.state.IsaAutoasMaxLns == "0" ? "" : this.state.IsaAutoasMaxLns} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="cart-tax-flag">Cart tax flag</label>
                                                        <input type="text" className="form-control" id="IsaCartTaxFlag" placeholder="Enter cart tax flag" name="IsaCartTaxFlag" maxLength="1" value={this.state.IsaCartTaxFlag == " " ? "" : this.state.IsaCartTaxFlag} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaCartTaxFlag">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="catalog-id">Catalog ID</label>
                                                        <input type="text" className="form-control" id="IsaCatalogId" placeholder="Enter catalog ID" name="IsaCatalogId" maxLength="30" value={this.state.IsaCatalogId == " " ? "" : this.state.IsaCatalogId} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaCatalogId">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp w-100">Client logo</label>
                                                        <div className={this.state.ClientLogoImage.trim().length == 0 ? "browse-btn" : "d-none"}>
                                                            <button type="button" className="font-medium font-14 btn btn-primary custom-btn-primary primary-btn" id="btnBrowesLogo"><img src="images/upload-icon.svg" alt="upload" className="mr-1" /> Browse</button>
                                                            <input type="file" name="myfile" multiple={false} title={this.state.ClientLogoImage} value="" onChange={(e) => this.handleAttachment(e)} id="fileInput" accept=".jpg, .jpeg, .png, .svg" />
                                                        </div>
                                                        <div className="browse-btn" style={{ marginLeft: "10px" }}>
                                                            <span name="ClientLogoImage" className={this.state.ClientLogoImage.trim().length > 0 ? "choosen-filename" : "d-none"} id="lblLogo">{this.state.ClientLogoImage}</span>
                                                            <span className={this.state.ClientLogoImage.trim().length > 0 ? "attachment-remove-icon ml-1" : "d-none"} onClick={() => this.handleClearLogo()}>X</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="svs-email">Client SVS email</label>
                                                        <input type="text" className="form-control" id="IsaClientSvsEml" placeholder="Enter client SVS email" name="IsaClientSvsEml" maxLength="120" value={this.state.IsaClientSvsEml == " " ? "" : this.state.IsaClientSvsEml} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spsvs-email">Please enter valid email ID(s)</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaClientSvsEml">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="cst-price">CST price</label>
                                                        <input type="text" className="form-control" id="cst-price" placeholder="Enter CST price" name="IsaCstPrice" maxLength="10" value={this.state.IsaCstPrice == "0" ? "" : this.state.IsaCstPrice} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="cst-vendor">CST vendor</label>
                                                        <input type="text" className="form-control" id="IsaCstVendor" placeholder="Enter CST vendor" name="IsaCstVendor" maxLength="10" value={this.state.IsaCstVendor == " " ? "" : this.state.IsaCstVendor} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaCstVendor">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="current-export">Current export</label>
                                                        <input type="text" className="form-control" id="current-export" placeholder="Enter current export" name="IsaCurrentExport" maxLength="10" value={this.state.IsaCurrentExport == "0" ? "" : this.state.IsaCurrentExport} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="customer-code">Customer code</label>
                                                        <input type="text" className="form-control" id="IsaCustomerCodes" placeholder="Enter customer code" name="IsaCustomerCodes" maxLength="1" value={this.state.IsaCustomerCodes == " " ? "" : this.state.IsaCustomerCodes} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaCustomerCodes">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="cust-prefix">Customer prefix</label>
                                                        <input type="text" className="form-control" id="IsaCustPrefix" placeholder="Enter customer prefix" name="IsaCustPrefix" maxLength="3" value={this.state.IsaCustPrefix == " " ? "" : this.state.IsaCustPrefix} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaCustPrefix">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="dept-id">Dept ID</label>
                                                        <input type="text" className="form-control" id="DeptId" placeholder="Enter dept ID" name="DeptId" maxLength="10" value={this.state.DeptId == " " ? "" : this.state.DeptId} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spDeptId">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="image-name">Image name</label>
                                                        <input type="text" className="form-control" id="IsaImageName" placeholder="Enter image name" name="IsaImageName" maxLength="30" value={this.state.IsaImageName == " " ? "" : this.state.IsaImageName} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaImageName">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="in-stock">In stock req. email</label>
                                                        <input type="text" className="form-control" id="IsaInstkOutEmail" placeholder="Enter in stock req. email" name="IsaInstkOutEmail" maxLength="120" value={this.state.IsaInstkOutEmail == " " ? "" : this.state.IsaInstkOutEmail} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spin-stock">Please enter valid email ID(s)</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaInstkOutEmail">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="isol-tax">ISOL tax flag</label>
                                                        <input type="text" className="form-control" id="IsaIsolTaxFlag" placeholder="Enter ISOL tax flag" name="IsaIsolTaxFlag" maxLength="1" value={this.state.IsaIsolTaxFlag == " " ? "" : this.state.IsaIsolTaxFlag} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaIsolTaxFlag">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="add-email">Item add email</label>
                                                        <input type="text" className="form-control" id="IsaItemAddEmail" placeholder="Enter item add email" name="IsaItemAddEmail" maxLength="120" value={this.state.IsaItemAddEmail == " " ? "" : this.state.IsaItemAddEmail} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spadd-email">Please enter valid email ID(s)</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaItemAddEmail">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="item-add">Item add printer</label>
                                                        <input type="text" className="form-control" id="IsaItmAddPrinter" placeholder="Enter item add printer" name="IsaItmAddPrinter" maxLength="40" value={this.state.IsaItmAddPrinter == " " ? "" : this.state.IsaItmAddPrinter} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaItmAddPrinter">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="item-prefix">Item prefix</label>
                                                        <input type="text" className="form-control" id="IsaItemPrefix" placeholder="Enter item prefix" name="IsaItemPrefix" maxLength="3" value={this.state.IsaItemPrefix == " " ? "" : this.state.IsaItemPrefix} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaItemPrefix">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="item-id">Item ID length</label>
                                                        <input type="text" className="form-control" id="item-id" placeholder="Enter item ID length" name="IsaItemIdLen" maxLength="10" value={this.state.IsaItemIdLen == "0" ? "" : this.state.IsaItemIdLen} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="id-mode">Item ID mode</label>
                                                        <input type="text" className="form-control" id="IsaItemIdMode" placeholder="Enter item ID mode" name="IsaItemIdMode" maxLength="1" value={this.state.IsaItemIdMode == " " ? "" : this.state.IsaItemIdMode} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaItemIdMode">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="lane-number">Lane number</label>
                                                        <input type="text" className="form-control" id="IsaLaneNbr" placeholder="Enter lane number" name="IsaLaneNbr" maxLength="31" value={this.state.IsaLaneNbr == " " ? "" : this.state.IsaLaneNbr} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaLaneNbr">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3 ssd1">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="last-export-date">Last export date</label>
                                                        <DatePicker fixedHeight className="form-control place" id="last-export-date" dateFormat="MM/dd/yyyy" placeholderText="MM/DD/YYYY" name="IsaLastExportDt" onChange={(e) => this.handleDatePicker(e, "IsaLastExportDt")} selected={this.state.IsaLastExportDt == " " || this.state.IsaLastExportDt == "" || this.state.IsaLastExportDt == null ? "" : new Date(this.state.IsaLastExportDt)} />
                                                        <span className="calendar-icon filter-calendar-icon  float-left" style={{ "top": "45px" }} onClick={() => $(`#last-export-date`).focus()} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="last-item">Last item ID</label>
                                                        <input type="text" className="form-control" id="last-item" placeholder="Enter last item ID" name="IsaLastItemId" maxLength="10" value={this.state.IsaLastItemId == "0" ? "" : this.state.IsaLastItemId} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3 ssd1">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="last-stat-send">Last status send</label>
                                                        <DatePicker fixedHeight className="form-control place" id="last-stat-send" dateFormat="MM/dd/yyyy" placeholderText="MM/DD/YYYY" name="IsaLastStatSend" onChange={(e) => this.handleDatePicker(e, "IsaLastStatSend")} selected={this.state.IsaLastStatSend == " " || this.state.IsaLastStatSend == "" || this.state.IsaLastStatSend == null ? "" : new Date(this.state.IsaLastStatSend)} />
                                                        <span className="calendar-icon filter-calendar-icon  float-left" style={{ "top": "45px" }} onClick={() => $(`#last-stat-send`).focus()} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="lead-days">Lead days</label>
                                                        <input type="text" className="form-control" id="lead-days" placeholder="Enter lead days" name="IsaLeadDays" maxLength="10" value={this.state.IsaLeadDays == "0" ? "" : this.state.IsaLeadDays} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="order-number">Manual order number</label>
                                                        <input type="text" className="form-control" id="IsaManOrderNo" placeholder="Enter manual order number" name="IsaManOrderNo" maxLength="1" value={this.state.IsaManOrderNo == " " ? "" : this.state.IsaManOrderNo} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaManOrderNo">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="next-webreq">Next webreq ID</label>
                                                        <input type="text" className="form-control" id="next-webreq" placeholder="Enter next webreq ID" name="NextWebReqId" maxLength="10" value={this.state.NextWebReqId == "0" ? "" : this.state.NextWebReqId} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="no-stock">Non-Stock printer</label>
                                                        <input type="text" className="form-control" id="IsaNonStkPrinter" placeholder="Enter non-stock printer" name="IsaNonStkPrinter" maxLength="40" value={this.state.IsaNonStkPrinter == " " ? "" : this.state.IsaNonStkPrinter} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaNonStkPrinter">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="nonstock">Non-Stock req. email</label>
                                                        <input type="text" className="form-control" id="IsaNonStkReqEmail" placeholder="Enter non-stock req. email" name="IsaNonStkReqEmail" maxLength="120" value={this.state.IsaNonStkReqEmail == " " ? "" : this.state.IsaNonStkReqEmail} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spnonstock">Please enter valid email ID(s)</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaNonStkReqEmail">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="ord-budget">ORD budget</label>
                                                        <input type="text" className="form-control" id="IsaOrdBudgetFlg" placeholder="Enter ORD budget" name="IsaOrdBudgetFlg" maxLength="1" value={this.state.IsaOrdBudgetFlg == " " ? "" : this.state.IsaOrdBudgetFlg} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaOrdBudgetFlg">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="order-approval">Order approval type</label>
                                                        <input type="text" className="form-control" id="IsaOrdApprType" placeholder="Enter order appoval Type" name="IsaOrdApprType" maxLength="1" value={this.state.IsaOrdApprType == " " ? "" : this.state.IsaOrdApprType} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaOrdApprType">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="password-expiry">Password expiry days</label>
                                                        <input type="text" className="form-control" id="IsaPwExpireDays" placeholder="Enter password expiry days" name="IsaPwExpireDays" maxLength="10" value={this.state.IsaPwExpireDays == "0" ? "" : this.state.IsaPwExpireDays} onChange={(e) => this.handleNumberFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaPwExpireDays">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3 ssd1">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="receiving-date">Receiving date</label>
                                                        <DatePicker fixedHeight className="form-control place" id="receiving-date" dateFormat="MM/dd/yyyy" placeholderText="MM/DD/YYYY" name="IsaReceivingDate" onChange={(e) => this.handleDatePicker(e, "IsaReceivingDate")} selected={this.state.IsaReceivingDate == " " || this.state.IsaReceivingDate == "" || this.state.IsaReceivingDate == null ? "" : new Date(this.state.IsaReceivingDate)} />
                                                        <span className="calendar-icon filter-calendar-icon  float-left" style={{ "top": "45px" }} onClick={() => $(`#receiving-date`).focus()} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="receiving-type">Receiving type</label>
                                                        <input type="text" className="form-control" id="IsaReceivingType" placeholder="Enter receiving type" maxLength="1" name="IsaReceivingType" value={this.state.IsaReceivingType == " " ? "" : this.state.IsaReceivingType} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaReceivingType">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="search-db">Search DB</label>
                                                        <input type="text" className="form-control" id="IsaSearchDb" placeholder="Enter search DB" name="IsaSearchDb" maxLength="30" value={this.state.IsaSearchDb == " " ? "" : this.state.IsaSearchDb} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaSearchDb">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="search-svr">Search SVR</label>
                                                        <input type="text" className="form-control" id="IsaSearchSvr" placeholder="Enter search SVR" name="IsaSearchSvr" maxLength="30" value={this.state.IsaSearchSvr == " " ? "" : this.state.IsaSearchSvr} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaSearchSvr">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="ship-receieve">Shipping document receive</label>
                                                        <input type="text" className="form-control" id="IsaShpdocRcv" placeholder="Enter shipping document receive" name="IsaShpdocRcv" maxLength="1" value={this.state.IsaShpdocRcv == " " ? "" : this.state.IsaShpdocRcv} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaShpdocRcv">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="cart-page">Shopping cart page</label>
                                                        <input type="text" className="form-control" id="IsaShopcartPage" placeholder="Enter shopping cart page" name="IsaShopcartPage" maxLength="30" value={this.state.IsaShopcartPage == " " ? "" : this.state.IsaShopcartPage} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaShopcartPage">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="site-email">Site email</label>
                                                        <input type="text" className="form-control" id="IsaSiteEmail" placeholder="Enter site email" name="IsaSiteEmail" maxLength="120" value={this.state.IsaSiteEmail == " " ? "" : this.state.IsaSiteEmail} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spsite-email">Please enter valid email ID(s)</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaSiteEmail">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="site-printer">Site printer</label>
                                                        <input type="text" className="form-control" id="IsaSitePrinter" placeholder="Enter site printer" name="IsaSitePrinter" maxLength="40" value={this.state.IsaSitePrinter == " " ? "" : this.state.IsaSitePrinter} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaSitePrinter">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="start-item">Starting item ID</label>
                                                        <input type="text" className="form-control" id="start-item" placeholder="Enter starting item ID" name="IsaStartingItemId" maxLength="10" value={this.state.IsaStartingItemId == "0" ? "" : this.state.IsaStartingItemId} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="stock-iol">Stock IOL</label>
                                                        <input type="text" className="form-control" id="IsaStkIol" placeholder="Enter stock IOL" name="IsaStkIol" maxLength="3" value={this.state.IsaStkIol == " " ? "" : this.state.IsaStkIol} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaStkIol">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="stock-printer">Stock printer</label>
                                                        <input type="text" className="form-control" id="IsaStockPrinter" placeholder="Enter stock printer" name="IsaStockPrinter" maxLength="40" value={this.state.IsaStockPrinter == " " ? "" : this.state.IsaStockPrinter} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaStockPrinter">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="stock-email">Stock req. email</label>
                                                        <input type="text" className="form-control" id="IsaStockReqEmail" placeholder="Enter stock req. email" name="IsaStockReqEmail" maxLength="120" value={this.state.IsaStockReqEmail == " " ? "" : this.state.IsaStockReqEmail} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spstock-email">Please enter valid email ID(s)</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaStockReqEmail">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="total-filed">Total field size</label>
                                                        <input type="text" className="form-control" id="total-filed" placeholder="Enter total field size" name="IsaTotalFldSize" maxLength="10" value={this.state.IsaTotalFldSize == "0" ? "" : this.state.IsaTotalFldSize} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="total-num">Total num. exp</label>
                                                        <input type="text" className="form-control" id="total-num" placeholder="Enter total num. exp" name="IsaTotalNumExp" maxLength="10" value={this.state.IsaTotalNumExp == "0" ? "" : this.state.IsaTotalNumExp} onChange={(e) => this.handleNumberFields(e)} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="track-cust">Track DB cust</label>
                                                        <input type="text" className="form-control" id="IsaTrackDbCust" placeholder="Enter track DB cust" name="IsaTrackDbCust" maxLength="20" value={this.state.IsaTrackDbCust == " " ? "" : this.state.IsaTrackDbCust} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaTrackDbCust">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="track-id">Track DB GU ID</label>
                                                        <input type="text" className="form-control" id="IsaTrackDbGuid" placeholder="Enter track DB GU ID" name="IsaTrackDbGuid" maxLength="36" value={this.state.IsaTrackDbGuid == " " ? "" : this.state.IsaTrackDbGuid} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaTrackDbGuid">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="track-password">Track DB password</label>
                                                        <input type="text" className="form-control" id="IsaTrackDbPssw" placeholder="Enter track DB password" name="IsaTrackDbPssw" maxLength="32" value={this.state.IsaTrackDbPssw == " " ? "" : this.state.IsaTrackDbPssw} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaTrackDbPssw">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="track-type">Track DB type</label>
                                                        <input type="text" className="form-control" id="IsaTrackDbType" placeholder="Enter track DB type" name="IsaTrackDbType" maxLength="32" value={this.state.IsaTrackDbType == " " ? "" : this.state.IsaTrackDbType} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaTrackDbType">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="track-user">Track DB user</label>
                                                        <input type="text" className="form-control" id="IsaTrackDbUsr" placeholder="Enter track DB user" name="IsaTrackDbUsr" maxLength="32" value={this.state.IsaTrackDbUsr == " " ? "" : this.state.IsaTrackDbUsr} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaTrackDbUsr">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label text-nowarp" htmlFor="zero-email">Zero email</label>
                                                        <input type="text" className="form-control" id="IsaZeroEmail" placeholder="Enter zero email" name="IsaZeroEmail" maxLength="120" value={this.state.IsaZeroEmail == " " ? "" : this.state.IsaZeroEmail} onChange={(e) => this.handleOnChangeFields(e)} />
                                                        <span className="font-12 font-regular color-validation" id="spzero-email">Please enter valid email ID(s)</span>
                                                        <span className="font-12 font-regular color-validation hide" id="spIsaZeroEmail">Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                    </div>
                                                    {/*button section*/}
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end my-5">
                                                            <button className="btn btn-secondary custom-btn-secondary secondary-btn mb-1 mr-2" onClick={this.handleCancel}>Cancel</button>
                                                            <button className="btn btn-primary custom-btn-primary primary-btn" onClick={this.submitBUOrUpdateBU}>{this.state.btnName}</button>
                                                        </div>
                                                    </div>
                                                    {/*button section*/}
                                                </div>
                                                <div id="contentDetails2" style={{ "height": "400px" }}>
                                                    {this.state.type == "Dropdown" ?
                                                        <div className="col-md-4 mb-3" id="buDiv2">
                                                            <label className="form-label catalog-label text-nowarp w-100" htmlFor="business-unit">Business unit <span className="color-manditory">*</span></label>
                                                            <span style={{ "fontSize": "14px" }}>
                                                                <Select id="business-unit2" name="business-unit2" className="w-100" placeholder="Select business unit" value={this.state.loadAvailBUValue} options={this.state.loadAvailBU} onChange={(e) => this.handleOnChange(e)} />
                                                            </span>
                                                        </div>
                                                        : null}
                                                    {this.state.type == "Textbox" ?
                                                        <div className="tab-content w-100">
                                                            <div className="tab-pane py-1 fade active show">
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label catalog-label text-nowarp" htmlFor="approval-threshold">{this.state.dBfieldName} <span className={this.state.IsMandatory == "Y" ? "color-manditory" : "d-none"}>*</span></label>
                                                                    <input type="text" className={`form-control ${this.state.inValidSearchField ? 'validation' : ''}`} maxLength={this.state.maxLength} id="fieldSearched" placeholder={this.state.placeHolder} name={this.state.fieldID} value={this.state[this.state.fieldID] == " " ? "" : this.state[this.state.fieldID]} onChange={(e) => this.handleOnChangeSearchFields(e)} />
                                                                    <span className={`font-12 font-regular color-validation ${this.state.inValidSearchField ? '' : 'hide'}`} id="spfieldSearched" >Enter a value that doesn't include these characters &#123; &#125; &#91; &#93; &quot; , : & '</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {this.state.type == "TextboxN" ?
                                                        <div className="tab-content w-100">
                                                            <div className="tab-pane py-1 fade active show">
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label catalog-label text-nowarp" htmlFor="approval-threshold">{this.state.dBfieldName} <span className={this.state.IsMandatory == "Y" ? "color-manditory" : "d-none"}>*</span></label>
                                                                    <input type="text" className="form-control" maxLength="10" id={this.state.fieldID} placeholder={this.state.placeHolder} name={this.state.fieldID} value={this.state[this.state.fieldID] == "0" ? "" : this.state[this.state.fieldID]} onChange={(e) => this.handleNumberFields(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {this.state.type == "Datepicker" ?
                                                        <div className="tab-content Catalog-Items w-100">
                                                            <div className="tab-pane py-1 fade active show">
                                                                <div className="col-md-4 mb-3 ssd2">
                                                                    <label className="form-label catalog-label text-nowarp" htmlFor="last-export-date">{this.state.dBfieldName}</label>
                                                                    <DatePicker fixedHeight className="form-control place" id={this.state.fieldID} dateFormat="MM/dd/yyyy" placeholderText="MM/DD/YYYY" name={this.state.fieldID} onChange={(e) => this.handleDatePicker(e, this.state.fieldID)} selected={this.state[this.state.fieldID] == " " || this.state[this.state.fieldID] == "" || this.state[this.state.fieldID] == null ? "" : new Date(this.state[this.state.fieldID])} />
                                                                    <span className="calendar-icon filter-calendar-icon  float-left" style={{ "top": "45px" }} onClick={() => $(`#${this.state.fieldID}`).focus()} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {this.state.type == "Fileupload" ?
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label catalog-label text-nowarp w-100">Client logo</label>
                                                            <div className={this.state.ClientLogoImage.trim().length == 0 ? "browse-btn" : "d-none"}>
                                                                <button type="button" className="font-medium font-14 btn btn-primary custom-btn-primary primary-btn" id="btnBrowesLogo"><img src="images/upload-icon.svg" alt="upload" className="mr-1" /> Browse</button>
                                                                <input type="file" name="myfile" multiple={false} title={this.state.ClientLogoImage} value="" onChange={(e) => this.handleAttachment(e)} id="fileInput" accept=".jpg, .jpeg, .png, .svg" />
                                                            </div>
                                                            <div className="browse-btn" style={{ marginLeft: "10px" }}>
                                                                <span name="ClientLogoImage" className={this.state.ClientLogoImage.trim().length > 0 ? "choosen-filename" : "d-none"} id="lblLogo">{this.state.ClientLogoImage}</span>
                                                                <span className={this.state.ClientLogoImage.trim().length > 0 ? "attachment-remove-icon ml-1" : "d-none"} onClick={() => this.handleClearLogo()}>X</span>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {/*button section*/}
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end my-5">
                                                            <button className="btn btn-secondary custom-btn-secondary secondary-btn mb-1 mr-2" onClick={this.handleCancel}>Cancel</button>
                                                            <button className="btn btn-primary custom-btn-primary primary-btn" onClick={this.submitBUOrUpdateBU}>{this.state.btnName}</button>
                                                        </div>
                                                    </div>
                                                    {/*button section*/}
                                                </div>
                                            </div>
                                            {/*Overall Details Form Tab*/}
                                            {/*Flags Tab Form*/}
                                            <div className="tab-pane py-1 fade" id="flags" role="tabpanel">
                                                <div className="row" id="contentDetails3">
                                                    <div className="col-md-4 mb-3" id="rbISA_ADD_CATALOG">
                                                        <label className="form-label catalog-label w-100">Add to catalog</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaAddCatalog" value="Y" checked={this.state.IsaAddCatalog == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaAddCatalog" value="N" checked={this.state.IsaAddCatalog != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_ADD_FLAG">
                                                        <label className="form-label catalog-label w-100">Address flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaAddrSelect" value="Y" checked={this.state.IsaAddrSelect == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaAddrSelect" value="N" checked={this.state.IsaAddrSelect != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_AMZN_SEARCH">
                                                        <label className="form-label catalog-label w-100">Amazon search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaAmznSearch" value="Y" checked={this.state.IsaAmznSearch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaAmznSearch" value="N" checked={this.state.IsaAmznSearch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SITE_BUD_Limit">
                                                        <label className="form-label catalog-label w-100">Annual budgetary limit</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaSiteBudLimit" value="Y" checked={this.state.IsaSiteBudLimit == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaSiteBudLimit" value="N" checked={this.state.IsaSiteBudLimit != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_ASN_RECEIPT">
                                                        <label className="form-label catalog-label w-100">ASN receipt</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaAsnReceipt" value="Y" checked={this.state.IsaAsnReceipt == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaAsnReceipt" value="N" checked={this.state.IsaAsnReceipt != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbAUTO_ASSIGN_FLG">
                                                        <label className="form-label catalog-label w-100">Auto assign</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="AutoAssignFlg" value="Y" checked={this.state.AutoAssignFlg == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="AutoAssignFlg" value="N" checked={this.state.AutoAssignFlg != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbPriceDispReq">
                                                        <label className="form-label catalog-label w-100">Block price required</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaPriceDispreq" value="Y" checked={this.state.IsaPriceDispreq == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaPriceDispreq" value="N" checked={this.state.IsaPriceDispreq != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbBU_STATUS">
                                                        <label className="form-label catalog-label w-100">Business unit status</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Active
                                                            <input type="radio" name="BuStatus" value="1" checked={this.state.BuStatus == "1" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">Inactive
                                                            <input type="radio" name="BuStatus" value="2" checked={this.state.BuStatus != "1" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_BYP_APPRV_RUSH">
                                                        <label className="form-label catalog-label w-100">Bypass approval rush</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaBypApprvRush" value="Y" checked={this.state.IsaBypApprvRush == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaBypApprvRush" value="N" checked={this.state.IsaBypApprvRush != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_BYP_RQSTR_APPR">
                                                        <label className="form-label catalog-label w-100">Bypass requestor approval</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaBypRqstrAppr" value="Y" checked={this.state.IsaBypRqstrAppr == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaBypRqstrAppr" value="N" checked={this.state.IsaBypRqstrAppr != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_CAPP_SRCH">
                                                        <label className="form-label catalog-label w-100">Capp search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaCappSrch" value="Y" checked={this.state.IsaCappSrch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaCappSrch" value="N" checked={this.state.IsaCappSrch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbCartChReq">
                                                        <label className="form-label catalog-label w-100">Cart charge code required</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaCartChgCdReq" value="Y" checked={this.state.IsaCartChgCdReq == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaCartChgCdReq" value="N" checked={this.state.IsaCartChgCdReq != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbCartWOReq">
                                                        <label className="form-label catalog-label w-100">Cart work order required</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaCartWoReq" value="Y" checked={this.state.IsaCartWoReq == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaCartWoReq" value="N" checked={this.state.IsaCartWoReq != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_CONTRACT_FLAG">
                                                        <label className="form-label catalog-label w-100">Contract flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaContractFlag" value="Y" checked={this.state.IsaContractFlag == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaContractFlag" value="N" checked={this.state.IsaContractFlag != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_CRED_CARD_PROC">
                                                        <label className="form-label catalog-label w-100">Credit card process</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaCredCardProc" value="Y" checked={this.state.IsaCredCardProc == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaCredCardProc" value="N" checked={this.state.IsaCredCardProc != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_CROSS_SITE_ACC">
                                                        <label className="form-label catalog-label w-100">Cross site access</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaCrossSiteAcc" value="Y" checked={this.state.IsaCrossSiteAcc == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaCrossSiteAcc" value="N" checked={this.state.IsaCrossSiteAcc != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_CUSTINT_APPRVL">
                                                        <label className="form-label catalog-label w-100">Cust-In approval</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaCustintApprvl" value="Y" checked={this.state.IsaCustintApprvl == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaCustintApprvl" value="N" checked={this.state.IsaCustintApprvl != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbCustPrefix">
                                                        <label className="form-label catalog-label w-100">Customer prefix flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaCustPrfxFlag" value="Y" checked={this.state.IsaCustPrfxFlag == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaCustPrfxFlag" value="N" checked={this.state.IsaCustPrfxFlag != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_UNL_FLAG">
                                                        <label className="form-label catalog-label w-100">Delivery point</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaUnloadDisplay" value="Y" checked={this.state.IsaUnloadDisplay == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaUnloadDisplay" value="N" checked={this.state.IsaUnloadDisplay != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_EMPLOYEE_PRN">
                                                        <label className="form-label catalog-label w-100">Employee PRN</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaEmployeePrn" value="Y" checked={this.state.IsaEmployeePrn == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaEmployeePrn" value="N" checked={this.state.IsaEmployeePrn != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_GRAINGER_SRCH">
                                                        <label className="form-label catalog-label w-100">Grainger search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaGraingerSrch" value="Y" checked={this.state.IsaGraingerSrch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaGraingerSrch" value="N" checked={this.state.IsaGraingerSrch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_IMP_SRCH">
                                                        <label className="form-label catalog-label w-100">IMP search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaImpSrch" value="Y" checked={this.state.IsaImpSrch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaImpSrch" value="N" checked={this.state.IsaImpSrch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_IPM_FLAG">
                                                        <label className="form-label catalog-label w-100">IPM site</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaIpmSite" value="Y" checked={this.state.IsaIpmSite == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaIpmSite" value="N" checked={this.state.IsaIpmSite != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_KIT_PRINTING">
                                                        <label className="form-label catalog-label w-100">Kit printing</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaKitPrinting" value="Y" checked={this.state.IsaKitPrinting == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaKitPrinting" value="N" checked={this.state.IsaKitPrinting != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_LABEL_FLAG">
                                                        <label className="form-label catalog-label w-100">Label printer</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaLabelPrinter" value="Y" checked={this.state.IsaLabelPrinter == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaLabelPrinter" value="N" checked={this.state.IsaLabelPrinter != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbLpp">
                                                        <label className="form-label catalog-label w-100">LPP flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaLppFlag" value="Y" checked={this.state.IsaLppFlag == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaLppFlag" value="N" checked={this.state.IsaLppFlag != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_MDM_USER">
                                                        <label className="form-label catalog-label w-100">MDM user</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMdmUser" value="Y" checked={this.state.IsaMdmUser == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMdmUser" value="N" checked={this.state.IsaMdmUser != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbMobIss">
                                                        <label className="form-label catalog-label w-100">Mobile issue</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMobileIssue" value="Y" checked={this.state.IsaMobileIssue == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMobileIssue" value="N" checked={this.state.IsaMobileIssue != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbMobPikIN">
                                                        <label className="form-label catalog-label w-100">Mobile pick IN</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMobPkIn" value="Y" checked={this.state.IsaMobPkIn == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMobPkIn" value="N" checked={this.state.IsaMobPkIn != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbMobPikOM">
                                                        <label className="form-label catalog-label w-100">Mobile pick OM</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMobPkOm" value="Y" checked={this.state.IsaMobPkOm == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMobPkOm" value="N" checked={this.state.IsaMobPkOm != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbmobilePick">
                                                        <label className="form-label catalog-label w-100">Mobile picking</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMobilePicking" value="Y" checked={this.state.IsaMobilePicking == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMobilePicking" value="N" checked={this.state.IsaMobilePicking != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbMobPut">
                                                        <label className="form-label catalog-label w-100">Mobile putaway</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMobilePutaway" value="Y" checked={this.state.IsaMobilePutaway == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMobilePutaway" value="N" checked={this.state.IsaMobilePutaway != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbMobUseCC">
                                                        <label className="form-label catalog-label w-100">Mobile Use CC</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMobUseCc" value="Y" checked={this.state.IsaMobUseCc == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMobUseCc" value="N" checked={this.state.IsaMobUseCc != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_MSC_SRCH">
                                                        <label className="form-label catalog-label w-100">MSC search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaMScSrch" value="Y" checked={this.state.IsaMScSrch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaMScSrch" value="N" checked={this.state.IsaMScSrch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_APPR_NSTK_FLAG">
                                                        <label className="form-label catalog-label w-100">Non-Stock approval flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaApprNstkFlag" value="Y" checked={this.state.IsaApprNstkFlag == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaApprNstkFlag" value="N" checked={this.state.IsaApprNstkFlag != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_ORDLNRENUM">
                                                        <label className="form-label catalog-label w-100">Order line Number</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaOrdnlnrenum" value="Y" checked={this.state.IsaOrdnlnrenum == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaOrdnlnrenum" value="N" checked={this.state.IsaOrdnlnrenum != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbOroStkReq">
                                                        <label className="form-label catalog-label w-100">ORO as stock</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaOroAsStock" value="Y" checked={this.state.IsaOroAsStock == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaOroAsStock" value="N" checked={this.state.IsaOroAsStock != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbORO_PUNCH_CHK">
                                                        <label className="form-label catalog-label w-100">ORO punchin check</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="OroPunchChk" value="Y" checked={this.state.OroPunchChk == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="OroPunchChk" value="N" checked={this.state.OroPunchChk != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_ORO_USE_LEAD">
                                                        <label className="form-label catalog-label w-100">ORO use lead</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaOroUseLead" value="Y" checked={this.state.IsaOroUseLead == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaOroUseLead" value="N" checked={this.state.IsaOroUseLead != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_ORSNASCO_SRCHY">
                                                        <label className="form-label catalog-label w-100">ORS NASCO search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaOrsnascoSrch" value="Y" checked={this.state.IsaOrsnascoSrch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaOrsnascoSrch" value="N" checked={this.state.IsaOrsnascoSrch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_PART_LIST">
                                                        <label className="form-label catalog-label w-100">Part list</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaPartList" value="Y" checked={this.state.IsaPartList == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaPartList" value="N" checked={this.state.IsaPartList != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_PO_CHNG_OUT">
                                                        <label className="form-label catalog-label w-100">PO change out</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaPoChngOut" value="Y" checked={this.state.IsaPoChngOut == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaPoChngOut" value="N" checked={this.state.IsaPoChngOut != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_PO_INTFC_CHNG">
                                                        <label className="form-label catalog-label w-100">PO-INTF change</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaPoIntfcChng" value="Y" checked={this.state.IsaPoIntfcChng == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaPoIntfcChng" value="N" checked={this.state.IsaPoIntfcChng != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbPO_REQ_BOX">
                                                        <label className="form-label catalog-label w-100">PO req. box</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="PoReqBox" value="Y" checked={this.state.PoReqBox == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="PoReqBox" value="N" checked={this.state.PoReqBox != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbPROFORMA_FLAG">
                                                        <label className="form-label catalog-label w-100">Proforma flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="ProformaFlag" value="Y" checked={this.state.ProformaFlag == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="ProformaFlag" value="N" checked={this.state.ProformaFlag != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbPunchoutflg">
                                                        <label className="form-label catalog-label w-100">Punchout flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaIsolPunchout" value="Y" checked={this.state.IsaIsolPunchout == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaIsolPunchout" value="N" checked={this.state.IsaIsolPunchout != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_RECV_PRICE">
                                                        <label className="form-label catalog-label w-100">Receiving price</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaRecvPrice" value="Y" checked={this.state.IsaRecvPrice == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaRecvPrice" value="N" checked={this.state.IsaRecvPrice != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbRcvBarCode">
                                                        <label className="form-label catalog-label w-100">Receiving barcode</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaRcvBarcode" value="Y" checked={this.state.IsaRcvBarcode == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaRcvBarcode" value="N" checked={this.state.IsaRcvBarcode != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbREQ_ORO_BLK">
                                                        <label className="form-label catalog-label w-100">Req. ord. blk</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="ReqOroBlk" value="Y" checked={this.state.ReqOroBlk == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="ReqOroBlk" value="N" checked={this.state.ReqOroBlk != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_RFQ_FLAG">
                                                        <label className="form-label catalog-label w-100">RFQ email</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaQuoteExpDays" value="1" checked={this.state.IsaQuoteExpDays == "1" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaQuoteExpDays" value="0" checked={this.state.IsaQuoteExpDays != "1" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_RFQ_ONLY">
                                                        <label className="form-label catalog-label w-100">RFQ only</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaRfqOnly" value="Y" checked={this.state.IsaRfqOnly == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaRfqOnly" value="N" checked={this.state.IsaRfqOnly != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_RFQ_SITE">
                                                        <label className="form-label catalog-label w-100">RFQ site</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaRfqSite" value="Y" checked={this.state.IsaRfqSite == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaRfqSite" value="N" checked={this.state.IsaRfqSite != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SDI_CAT">
                                                        <label className="form-label catalog-label w-100">SDI cart</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaSdiCat" value="Y" checked={this.state.IsaSdiCat == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaSdiCat" value="N" checked={this.state.IsaSdiCat != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SDIEXCH_APPRVL">
                                                        <label className="form-label catalog-label w-100">SDI exchange approval</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaSdiexchApprvl" value="Y" checked={this.state.IsaSdiexchApprvl == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaSdiexchApprvl" value="N" checked={this.state.IsaSdiexchApprvl != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SDIX_RECEIPT">
                                                        <label className="form-label catalog-label w-100">SDI exchange receipt</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaSdixReceipt" value="Y" checked={this.state.IsaSdixReceipt == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaSdixReceipt" value="N" checked={this.state.IsaSdixReceipt != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbShipTo">
                                                        <label className="form-label catalog-label w-100">Ship to flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="ShipToFlg" value="Y" checked={this.state.ShipToFlg == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="ShipToFlg" value="N" checked={this.state.ShipToFlg != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SHORT_PICK">
                                                        <label className="form-label catalog-label w-100">Short pick</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaShortPick" value="Y" checked={this.state.IsaShortPick == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaShortPick" value="N" checked={this.state.IsaShortPick != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_IPM_CORE">
                                                        <label className="form-label catalog-label w-100">Show required by date</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaIpmCore" value="Y" checked={this.state.IsaIpmCore == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaIpmCore" value="N" checked={this.state.IsaIpmCore != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SIGPAD_DISPATC">
                                                        <label className="form-label catalog-label w-100">Sigpad. dispatch</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaSigpadDispatc" value="Y" checked={this.state.IsaSigpadDispatc == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaSigpadDispatc" value="N" checked={this.state.IsaSigpadDispatc != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SIGPAD_ISSUE">
                                                        <label className="form-label catalog-label w-100">Sigpad. issue</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaSigpadIssue" value="Y" checked={this.state.IsaSigpadIssue == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaSigpadIssue" value="N" checked={this.state.IsaSigpadIssue != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_SITE_LANG">
                                                        <label className="form-label catalog-label w-100">Site lang</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaSiteLang" value="Y" checked={this.state.IsaSiteLang == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaSiteLang" value="N" checked={this.state.IsaSiteLang != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbSTAGING_FLAG">
                                                        <label className="form-label catalog-label w-100">Staging flag</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="StagingFlag" value="Y" checked={this.state.StagingFlag == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="StagingFlag" value="N" checked={this.state.StagingFlag != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbSTAUFFER_SRCH">
                                                        <label className="form-label catalog-label w-100">Stauffer search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="StaufferSrch" value="Y" checked={this.state.StaufferSrch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="StaufferSrch" value="N" checked={this.state.StaufferSrch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>

                                                    <div className="col-md-4 mb-3" id="rbNonStock">
                                                        <label className="form-label catalog-label w-100">STD NSTK site</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaStdNstkSite" value="Y" checked={this.state.IsaStdNstkSite == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaStdNstkSite" value="N" checked={this.state.IsaStdNstkSite != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_TREE_HOLD">
                                                        <label className="form-label catalog-label w-100">Tree hold</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaTreeHold" value="Y" checked={this.state.IsaTreeHold == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaTreeHold" value="N" checked={this.state.IsaTreeHold != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbUSEOROIOH">
                                                        <label className="form-label catalog-label w-100">User ORO IOH</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaUseOroIoh" value="Y" checked={this.state.IsaUseOroIoh == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaUseOroIoh" value="N" checked={this.state.IsaUseOroIoh != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label catalog-label w-100">USE-RF status</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaUseRfStatus" value="Y" checked={this.state.IsaUseRfStatus == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaUseRfStatus" value="N" checked={this.state.IsaUseRfStatus != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_VALIDATE_WO">
                                                        <label className="form-label catalog-label w-100">Validate work order</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaValidateWo" value="Y" checked={this.state.IsaValidateWo == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaValidateWo" value="N" checked={this.state.IsaValidateWo != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbWOChgOpt">
                                                        <label className="form-label catalog-label w-100">Work order/ charge code required</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaWoChgOpt" value="Y" checked={this.state.IsaWoChgOpt == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaWoChgOpt" value="N" checked={this.state.IsaWoChgOpt != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbZEUS_SITE">
                                                        <label className="form-label catalog-label w-100">Zeus2 site</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="ZeusSite" value="Y" checked={this.state.ZeusSite == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="ZeusSite" value="N" checked={this.state.ZeusSite != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 mb-3" id="rbISA_3M_SRCH">
                                                        <label className="form-label catalog-label w-100">3M search</label>
                                                        <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="Isa3msrch" value="Y" checked={this.state.Isa3msrch == "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                        <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="Isa3msrch" value="N" checked={this.state.Isa3msrch != "Y" ? true : false} onChange={(e) => this.handleOnChangeFields(e)} />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                    {/*button section*/}
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end my-5">
                                                            <button className="btn btn-secondary custom-btn-secondary secondary-btn mb-1 mr-2" onClick={this.handleCancel}>Cancel</button>
                                                            <button className="btn btn-primary custom-btn-primary primary-btn" onClick={this.submitBUOrUpdateBU}>{this.state.btnName}</button>
                                                        </div>
                                                    </div>
                                                    {/*button section*/}
                                                </div>
                                                <div className="row" id="contentDetails4">
                                                    {this.state.dBfieldName != "RFQ email" && this.state.dBfieldName != "Business unit status" ?
                                                        <div className="tab-content Catalog-Items w-100">
                                                            <div className="tab-pane py-1 fade active show">
                                                                <div className="col-md-4 mb-3" >
                                                                    <label className="form-label search-field-label w-100" style={{ "maxWidth": "100%" }}>{this.state.dBfieldName}</label>
                                                                    <label className="custom-radiobtn mr-2 my-2">Yes
                                                                        <input type="radio" name={this.state.fieldID + "1"} value="Y" checked={this.state[this.state.fieldID] == "Y" ? true : false} onChange={(e) => this.handleOnChangeSeleRadio(e)} />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                    <label className="custom-radiobtn mr-2 my-2">No
                                                                        <input type="radio" name={this.state.fieldID + "1"} value="N" checked={this.state[this.state.fieldID] != "Y" ? true : false} onChange={(e) => this.handleOnChangeSeleRadio(e)} />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {this.state.dBfieldName == "RFQ email" ?
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label search-field-label w-100" style={{ "maxWidth": "100%" }}>RFQ email</label>
                                                            <label className="custom-radiobtn mr-2 my-2">Yes
                                                            <input type="radio" name="IsaQuoteExpDays1" value="1" checked={this.state.IsaQuoteExpDays == "1" ? true : false} onChange={(e) => this.handleOnChangeSeleRadio(e)} />
                                                                <span className="checkmark" />
                                                            </label>
                                                            <label className="custom-radiobtn mr-2 my-2">No
                                                            <input type="radio" name="IsaQuoteExpDays1" value="0" checked={this.state.IsaQuoteExpDays != "1" ? true : false} onChange={(e) => this.handleOnChangeSeleRadio(e)} />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                        : null
                                                    }
                                                    {this.state.dBfieldName == "Business unit status" ?
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label search-field-label w-100" style={{ "maxWidth": "100%" }}>Business unit status</label>
                                                            <label className="custom-radiobtn mr-2 my-2">Active
                                                            <input type="radio" name="BuStatus1" value="1" checked={this.state.BuStatus == "1" ? true : false} onChange={(e) => this.handleOnChangeSeleRadio(e)} />
                                                                <span className="checkmark" />
                                                            </label>
                                                            <label className="custom-radiobtn mr-2 my-2">Inactive
                                                            <input type="radio" name="BuStatus1" value="2" checked={this.state.BuStatus != "1" ? true : false} onChange={(e) => this.handleOnChangeSeleRadio(e)} />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                        : null}
                                                    <div className="tab-content Catalog-Items w-100">
                                                        <div className="tab-pane py-1 fade active show">
                                                            {/*button section*/}
                                                            <div className="col-md-12">
                                                                <div className="d-flex justify-content-end my-5">
                                                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mb-1 mr-2" onClick={this.handleCancel}>Cancel</button>
                                                                    <button className="btn btn-primary custom-btn-primary primary-btn" onClick={this.submitBUOrUpdateBU}>{this.state.btnName}</button>
                                                                </div>
                                                            </div>
                                                            {/*button section*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*Flags Tab Form*/}

                                        </div>
                                        {/*Tab Inner Content ends here*/}
                                    </div>
                                    {/*Tab ends here*/}
                                </div>
                            </div>
                        </div>
                        {/*Form ends here*/}
                        {/*Modal Starts here*/}
                        <div className="modal fade alert-popup pr-0" tabIndex="-1" role="dialog" aria-hidden="true" id="modalPopupAlert">
                            <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                                <div className="modal-content py-2">
                                    <div className="modal-body pb-2">
                                        <div className="row">
                                            <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                                <img className="mb-3" src="images/alert-Icon.svg" alt="alert" />
                                                <p className="mb-1 font-14 font-medium color-light-black">{this.state.popUpText}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                        <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={this.handleCancel}>OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade success-customer pr-0" tabIndex="-1" role="dialog" aria-hidden="true" id="modalPopupSucsess" data-backdrop="static">
                            <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                                <div className="modal-content py-2">
                                    <div className="modal-body pb-2">
                                        <div className="row">

                                            <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                                <img className="mb-3" src="images/tick.svg" alt="tick" />
                                                <p className="mb-1 font-14 font-medium color-light-black">{this.state.popUpText}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                        <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={this.handleCancel}>OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </>);
    };
};
export default withTranslation()(EnterpriseCustComponent);
