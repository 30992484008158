import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
import * as MenuAction from './MenuAction';
import * as CookieService from '../CookieService';
const GetSTKIssueCartPageload = (favItemBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.STKIssuesCart,
            SDIConstants.methodNames.STKIssuesCart_PageLoad,
            JSON.stringify(favItemBO),

            (data) => {
                if (data.success) {
                    debugger;
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.STKIssuesCart_PageLoad, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const ItemSearchAction = (favItemBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.STKIssuesCart,
            SDIConstants.methodNames.STKIssuesCart_ItemSearch,
            JSON.stringify(favItemBO),

            (data) => {
                if (data.success) {
                    debugger;
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.STKIssuesCart_ItemSearch, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const GetWorkOrderValidate = async (getFavPageBO) => {
    try {
        SDISVC.callAsync(
            SDIConstants.moduleNames.ServiceChannel,
            SDIConstants.methodNames.ServiceChannel_GetWorkOrderValidation,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        $(`#${SDIConstants.SDILoader}`).addClass('hide');
                        let contentData = { type: "STKWOResponse", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const SubmitAction = (favItemBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.STKIssuesCart,
            SDIConstants.methodNames.STKIssuesCart_Submit,
            JSON.stringify(favItemBO),

            (data) => {
                if (data.success) {
                    debugger;
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.STKIssuesCart_Submit, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const GetchargeCOde = (GetchargeCOdeBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.BuildChrCD,
            SDIConstants.methodNames.BuildChrCD_Page_Load,
            JSON.stringify(GetchargeCOdeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "BuildChrCD", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const GetCartConfirmPDF = (OrderPDFBO) => {
    try {
        debugger;
        SDISVC.receiveFileBase64(
            SDIConstants.moduleNames.ShoppingCart,
            SDIConstants.methodNames.ShoppingCart_CartConfirmFile,
            JSON.stringify(OrderPDFBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "OrderPDF", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVITEM_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVITEM_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVITEM_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export { GetSTKIssueCartPageload, ItemSearchAction, GetWorkOrderValidate, SubmitAction, GetCartConfirmPDF, GetchargeCOde, insertFavPage, deleteFavPage, getCurrentFavPage }