/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 08/17/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: SR_PC_11
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
/**
 * Ref: SR_PC_12
 * 
 * addPunchoutItems method gets called from the componentWillMount() of the component.
 * It takes a parameters addPunchoutBO which is passed from the component file.
 * @param addPunchoutBO
 */
const addPunchoutItems = async (addPunchoutBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Shopredirect,
            SDIConstants.methodNames.Shopredirect_AddPunchoutDatatoCart,
            JSON.stringify(addPunchoutBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Shopredirect_AddPunchoutDatatoCart, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export { addPunchoutItems}