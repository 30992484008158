/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 07/30/2020
 * 
 * Ref: LN_PC_19
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
/**
 * Ref: LN_PC_20
 * 
 * Declare the store call that extends "EventsEmitter"
 */
class LoginStore extends EventEmitter {
    loginData = "";
    loginSuccess = "";
    loginError = "";
    SSOFlagResp = "";
    woValidationData = "";
     /**
     * handleLoginStore method takes action as parameter.
     * Based on the action we will dispatch the data from the action.
     */
    handleLoginStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.Login_Pageload: {
                this.loginData = action.action.response;
                this.emit('load');
                break;
            }
            case SDIConstants.methodNames.Login_TokenUpdate: {
                this.loginSuccess = action.action.response;
                this.emit('success');
                //[05/24/2023, PC_ZE_87_APP_01 - Johnprinto D]
                this.emit('contactChange');
                break;
            }
            case "Login_Error": {
                this.loginError = action.action.response;
                this.emit('error');
                break;
            }
            case "SSOLoginCheck": {
                this.SSOFlagResp = action.action.response;
                this.emit('SSOFlagResp');
                break;
            }
            //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
            case "ValidateWO": {
                this.woValidationData = action.action.response;
                this.emit('ValidateWO');
                break;
            }
        }
    }
}
/**
 * Ref: LN_PC_21
 * Register the dispatcher using the initialized object .
 */
const objLoginStore = new LoginStore;
// Dispatcher code
Dispatcher.register(objLoginStore.handleLoginStore.bind(objLoginStore));
export default objLoginStore;