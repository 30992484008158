/*
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the User Profile page component. The component displays the User Profile page details.
 * 
 * App Name: SDI-Walmart
 * Author: M.Sushmitha
 * Created Date: 08/21/2020 
 */
import React, { Component } from 'react';
/* Ref no: UP_PC_01
 * ProfileAction has the action method used to call the API method for data response.
 * */
import * as ProfileAction from '../actions/ProfileAction';
/*Ref no: UP_PC_01
 * ProfileStore has the store case whhich emits the action response and invokes the response method.
 * */
import ProfileStore from '../stores/ProfileStore';
/*
 * MenuAction has the action method used to call the API method for data response.
 * When an item is added to cart or the page is made to favorite.Menu bar has to reloaded.
 * For this purpose manuaction is used in Shoppingcartcomponent.
 * */
import * as MenuAction from '../actions/MenuAction';
/*
 * The below react plugin are used for the Multi select drop down used in the user profile tab 1
 * React checkbox tree is used for the user privileges tree.
 * */
import MultiSelect from "react-multi-select-component";
import CheckboxTree from 'react-checkbox-tree';
/*
 * CookieService is used for the Usage of the local database values in the component. 
 * The Local database is used for maintaining the login user falgs 
 * 
 * */
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
/*
 * react select plugin in place of select drop down in the user profile, in place of normal down drop.
 * This plugin has the search value feature too.
 * */
import Select from 'react-select';
/*
 *Import the Jquery to use jquery feature for binding the value to the field and so on.
 */
import * as $ from 'jquery';
//[PC_07, 09/13/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";

//[5/11/2023]Z-102- Importing googlesMaps component to display maps in shopping cart after validating user's address
import GoogleMaps from './GoogleMaps';
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
import ShoppingCartStore from '../stores/ShoppingCartStore';
import swal from 'sweetalert';

/*
 *Thes are global varaiable used for validation check ,disable or enable a field etc. 
 * */
let TabclickTitle = "";
let AddTSTUSERSuccess = 1;
let MultiSiteCHeckB = false;
//let DisableTypeDD = false;
let currentPagePath;
let approvalAccordian = 0;
let checkboxCount = 0;

const countryOptions = [
    {
        value: 'USA', label: 'USA'
    },
    {
        value: 'CAN', label: 'CAN'
    },
    {
        value: 'MEX', label: 'MEX'
    }
]

//let AccountActive = 0;
//[11/26/21, CAS - 31, Dhevanesam R]
class Profile extends Component {
    /**
     * UP_PC_03
     * Declare a “constructor” method with “props” as its parameters.
     * @param props
   */
	constructor(props) {

		super(props);

		this.handleFavorite = this.handleFavorite.bind(this);
		this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
		this.updateFavPageResponse = this.updateFavPageResponse.bind(this);
		this.BuildUserProfile = this.BuildUserProfile.bind(this);
		this.refreshMenu = this.refreshMenu.bind(this);
		this.BuildUserProfileForm = this.BuildUserProfileForm.bind(this);
		this.TabClick = this.TabClick.bind(this);
		this.BuildTabClick = this.BuildTabClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.BuildUserProfileDetails = this.BuildUserProfileDetails.bind(this);
		this.handleChangeSite = this.handleChangeSite.bind(this);

		this.ShowThirdParty = this.ShowThirdParty.bind(this);
		this.handleChangeTab2Site = this.handleChangeTab2Site.bind(this);
		this.handleChangeTab2Role = this.handleChangeTab2Role.bind(this);
		this.handleChangeDepart = this.handleChangeDepart.bind(this);
		this.handleChangeUserROle = this.handleChangeUserROle.bind(this);
		this.handleMultiSiteDD = this.handleMultiSiteDD.bind(this);
		this.OnCheckTree = this.OnCheckTree.bind(this);
		this.handleChangeAppro = this.handleChangeAppro.bind(this);
		this.handleChangeShipto = this.handleChangeShipto.bind(this);
		this.handleChangeDepartPref = this.handleChangeDepartPref.bind(this);
		this.handleChangeCrib = this.handleChangeCrib.bind(this);

		this.handleChangeEmplyee = this.handleChangeEmplyee.bind(this);
		this.UserTypeRadio = this.UserTypeRadio.bind(this);
		this.BuildSearchOnUserTypeChg = this.BuildSearchOnUserTypeChg.bind(this);
		this.ChgPwd = this.ChgPwd.bind(this);
		this.MultiSiteCheckBtn = this.MultiSiteCheckBtn.bind(this);
		this.BindMultiSiteDD = this.BindMultiSiteDD.bind(this);
		this.btnSave_Click = this.btnSave_Click.bind(this);
		this.UpdateUserProfile = this.UpdateUserProfile.bind(this);
		this.AddUser = this.AddUser.bind(this);
		this.EditUser = this.EditUser.bind(this);
		this.ShowEditMessage = this.ShowEditMessage.bind(this);
		this.VendorIdChaneg = this.VendorIdChaneg.bind(this);

		this.BindVendorSite = this.BindVendorSite.bind(this);
		this.OnchangePrivType = this.OnchangePrivType.bind(this);
		this.BindPrivilegeOnType = this.BindPrivilegeOnType.bind(this);
		this.BindPrivilegeOnRole = this.BindPrivilegeOnRole.bind(this);
		this.updatePrivilege = this.updatePrivilege.bind(this);
		this.ShowUpdateMesPri = this.ShowUpdateMesPri.bind(this);
		this.UpdateApprovals = this.UpdateApprovals.bind(this);
		this.ShowApprovalStatus = this.ShowApprovalStatus.bind(this);
		this.ShowBudApprovalStatus = this.ShowBudApprovalStatus.bind(this);
		this.UpdateOSE = this.UpdateOSE.bind(this);
		this.ShowOSEMessage = this.ShowOSEMessage.bind(this);
		this.UpdatePref = this.UpdatePref.bind(this);

		this.ShowPrefMessage = this.ShowPrefMessage.bind(this);
		this.UpdateCrib = this.UpdateCrib.bind(this);
		this.ShowMobMessage = this.ShowMobMessage.bind(this);
		this.UpdateZeus = this.UpdateZeus.bind(this);
		this.ShowZeusMessage = this.ShowZeusMessage.bind(this);
		this.LoadApprover = this.LoadApprover.bind(this);
		this.BuildReqApprovalForm = this.BuildReqApprovalForm.bind(this);
		this.BuildBudApprovalForm = this.BuildBudApprovalForm.bind(this);
		this.UpdateTST = this.UpdateTST.bind(this);

		this.ShowTrackMessage = this.ShowTrackMessage.bind(this);
		this.BindSelectUserTrackData = this.BindSelectUserTrackData.bind(this);
		this.COStatus = this.COStatus.bind(this);
		this.PrivTreeAction = this.PrivTreeAction.bind(this);
		this.ShowActivateStatus = this.ShowActivateStatus.bind(this)
		this.handleProfileFormChange = this.handleProfileFormChange.bind(this);
		this.BuildChargeCOdeData = this.BuildChargeCOdeData.bind(this);
		this.handleDelCheckOnchange = this.handleDelCheckOnchange.bind(this);
        /*
         * UP_PC_04
        * All the below mentioned State variable are used in the component to bind,update,show popup and so on.
        * */
		this.state = {
			Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
			Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
			AccountActive: 0,
			SelectUserDetails: [],
			VendorIdValidate: 0,
			PrivTree: [],
			DisableTypeDD: false,
			MultiSiteChecked: false,
			DisablePrivRole: false,
			adduser: false,
			isFavorited: false,
			selectedMultiSites: [],
			ChangePasswordEnable: "N",
			ManuallyAddUserId: "",
			ExistReqAppr: "",
			ExistBudAppr: "",
			UserRoleDD: [],
			SelectUserRole: [],
			SiteTitleValue: "",
			SelectedUserId: "",
			selectedOption: [],
			selectSiteID: "",
			SiteList: [],
			MultiSite: [],
			MultiSiteSelected: [],
			SearchUser: [],
			Depart: [],
			ThirdPartyList: [],
			AutoAssignFlag: "",
			FirstName: "",
			LastName: "",
			LoginHideuserId: "",
			HideuserId: "",
			PhoneNum: "",
			EmailAddres: "",
			LoginUserType: "",
			LoginUserRole: "",
			UserType: "",
			UserRole: "",
			DepartMent: [],
			SelectSite: [],
			MultiSiteEnable: "N",
			UserActiveStatus: "",
			EmployeeAccountActiveStatus: "",
			VendorGroup: "",
			VendorId: "",
			Tab2SiteList: [],
			Tab2RoleList: [],
			SelectROleList: [],
			Tab2AlacartList: [],
			Tab2RoleTree: [],
			Tab2BindTree: [],
			Tab2SiteTreeValue: [],
			SelectTab2Site: [],
			checked: [],
			expanded: [],
			ApproverList: [],
			ExistApproveValue: [],
			ExistAppLimit: "",
			//[8/10/22, Bug - 2349, PS_UP_01, Dhevanesam R]
			initialApprover: "",
			initialBudAppLimit: "",
			DepartPref: [],
			DepartmentPref: [], ShiptoList: [], Shipto: [], CribValue: [],
			CribList: [], TSTEmployeeValue: [], TSTDefaultValue: [], TSTUserID: "", PageAction: "EDIT", SelectUsertype: "", SelectedThirdParty: "",
			PrivTreeDisable: false, GroupID: "", SDIEMp: false, Customer: false, vendor: false, PassNTB: "", RoleExist: "", Tab2SiteTitle: "",
			ZeusTitle: "", PageLoadSiteList: [], MultiSiteExist: "N", CCPlaceDD1Title: "",
			CCPlaceDD2Title: "", CCPlaceDD3Title: "", CCdropCD1SegTitle: "", CCdropCD2SegTitle: "", CCdropCD3SegTitle: "", CCdropEmpSegTitle: "", CCdropItmSegTitle: "",
			CCdropMchSegTitle: "", PlaceDD1Value: [], PlaceDD1Data: [], PlaceDD2Value: [], PlaceDD2Data: [], PlaceDD3Value: [], PlaceDD3Data: [],
			dropCD1SegValue: [], dropCD1SegData: [], dropCD2SegValue: [], dropCD2SegData: [], dropCD3SegValue: [], dropCD3SegData: [], dropEmpSegValue: [], dropEmpSegData: [],
			dropItmSegValue: [], dropItmSegData: [], dropMchSegValue: [], dropMchSegData: [], dropCD1SegVisible: false, dropCD2SegVisible: false, dropCD3SegVisible: false,
			PlaceDD1Visible: false, PlaceDD2Visible: false, PlaceDD3Visible: false, dropMchSegVisible: false, dropEmpSegVisible: false, dropItmSegVisible: false, ChargeSelected: "", showPassword: false, showPassword1: false,
			//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
			//[4/27/22, Bug - 2071, Dhevanesam R
			//[5/10/22, CAS - 31, Dhevanesam R]
			//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
			ApprovLabelVal: "", ApprovBtnTxt: "", StatusMsgErrorVal: "", StatusMsgErrValCrib: "", TSTErrorVal: "", EnterFirstNameErr: "", EnterLastNameErr: "", EnterEmailNameErr: "", EnterPhoneNameErr: "", EnterPassNameErr: "", EnterConPassNameErr: "", InActEmpLbl: "In-Active Employee Account", InActAccLbl: "In-Active Account", ErrorMagSiteErr: "", ErrorMegSiteErr: "", EmpLabelErr: "", AcctLabelErr: "",
            showEditIcon: false, recomendedAddress: "", userSelectedAddress: "", stateValue: "", cityValue: "", streetValue: "", countryValue: "USA", zipCodeValue: "",
			isValidStreet: "", isValidCity: "", isValidState: "", isValidZipCode: "", isValidCountryCode: "", validLongitude: "", validLatitude: "",
			userAddress: "", disableShipto: false, userAddressSelect: false, recAddressSelect: false, recAddress: true, typedAddress: false, selectCountry: countryOptions[0],
			typedStreet: "", typedCity: "", typedState: "", typedZipCode: "", typedCountry: "", countryObj: countryOptions[0],
			showAddressField: CookieService.getLocalStorageValues("Session_AddressFlag"), existStreet: "", existState: "", existCity: "", existCountry: "", existPostal: "", HomeStore: [], HomeStoreDD: [],
			isExistingAddress: false, existApartmentNo: "", apartmentNoValue: "", typedApartmentNo: "", isRecAddressSelected: false, isUserAddressSelected: false,
			responseIsValidStreet: "", responseIsValidCity: "", responseIsValidState: "", responseIsValidZipCode: "", responseIsValidCountryCode: "",
			responseTypedStreet: "", responseTypedCity: "", responseTypedState: "", responseTypedZipCode: "", responseTypedCountry: "",
			CurrentUserName: "", CurrentUserLimit: "", NextApprover: "", NextApproverLimit: "", ApproverPopupFlag: true, selectedApprvValue: [], updateAltBudgetary : [],
			delAltBudCheck: [], delAltBudIcon: [],
			userProfileErr : "",
			isExpandAll: true
		}
	}
    /*
     * RF No: UP_PC_05
     * componentDidMount is the method which is loaded and exceute first.
     * When the User profile page loaded.
     * */
	componentDidMount() {
        /*
         *  RF No: UP_PC_06
         *  BuildUserProfile is the method used to get the pageload data from the database.
         * */
        if (this.props.location.state != null && this.props.location.state != undefined) {
            if (this.props.location.state.origin == 'Inventory') {
                this.TabClick("MOB")
                $(`#mobility`).addClass('active')
                $(`#user-details-tab`).removeClass('active')
                $(`#mobility-link`).addClass('active show')
                $(`#user-details`).removeClass('active show')
            }
        }
		this.BuildUserProfile();
        /*
        * RF No: UP_PC_08 && UP_PC_09 &&  UP_PC_10
        * This emit call is done when the GetEditUser action has the response.
        * */
		ProfileStore.on("UserDetail", this.BuildUserProfileForm.bind(this));
        /*
        * RF No: UP_PC_11
        * This emit call is done when the GetSearchUserOnUTypeChange action has the response.
        * */
		ProfileStore.on("RadioUserType", this.BuildSearchOnUserTypeChg.bind(this));
        /*
        * RF No: UP_PC_12
        * This emit call is done when the GetSearchUsers action has the response.
        * */
		ProfileStore.on("SelectUser", this.BuildUserProfileDetails);
        /*
        * RF No: UP_PC_13
        * This emit call is done when the GetVendorGroup action has the response.
        * */
		ProfileStore.on("VendorIdCheck", this.BindVendorSite.bind(this));
        /*
        * RF No: UP_PC_14 &&  UP_PC_16
        * This emit call is done when the UpdateUserDetails action has the response.
        * */
		ProfileStore.on("AddUser", this.ShowEditMessage);
        /*
        * RF No:UP_PC_15
        * This emit call is done when the GetMultiSite action has the response.
        * */
		ProfileStore.on("MultiSite", this.BindMultiSiteDD.bind(this));
        /*
        * RF No: UP_PC_17 &&  UP_PC_35 &&  UP_PC_33 &&  UP_PC_30 &&  UP_PC_26 &&  UP_PC_28
        * This emit call is done when the GetTabBasedDetails action has the response.
        * */
		ProfileStore.on("TabClick", this.BuildTabClick.bind(this));
        /*
        * RF No: UP_PC_18
        * This emit call is done when the GetPrivilegesType action has the response.
        * */
		ProfileStore.on("PrivilegeType", this.BindPrivilegeOnType.bind(this));
        /*
        * RF No:UP_PC_19
        * This emit call is done when the GetPrivilegeRole action has the response.
        * */
		ProfileStore.on("PrivilegeRole", this.BindPrivilegeOnRole.bind(this));
        /*
        * RF No: UP_PC_20
        * This emit call is done when the UpdatePrivilege action has the response.
        * */
		ProfileStore.on("UpdatePrivilege", this.ShowUpdateMesPri);
        /*
        * RF No: UP_PC_23
        * This emit call is done when the GetReqApprovals action has the response.
        * */
		ProfileStore.on("LoadReqApprovals", this.BuildReqApprovalForm.bind(this));
        /*
        * RF No: UP_PC_21
        * This emit call is done when the GetBudApprovals action has the response.
        * */
		ProfileStore.on("LoadBudApprovals", this.BuildBudApprovalForm.bind(this));
        /*
         * RF No: UP_PC_24
         * This emit call is done when the UpdateReqApp action has the response.
         * */
		ProfileStore.on("UpdateReqApprovals", this.ShowApprovalStatus.bind(this));
        /*
        * RF No: UP_PC_22
        * This emit call is done when the UpdateBudgetApp action has the response.
        * */
		ProfileStore.on("UpdateApprovals", this.ShowBudApprovalStatus.bind(this));
        /*
        * RF No: UP_PC_27
        * This emit call is done when the UpdateOSE action has the response.
        * */
		ProfileStore.on("UpdateOSE", this.ShowOSEMessage.bind(this));
        /*
        * RF No: UP_PC_29
        * This emit call is done when the UpdatePreferences action has the response.
        * */
		ProfileStore.on("UpdatePref", this.ShowPrefMessage);
        /*
        * RF No: UP_PC_31
        * This emit call is done when the GetSearchUserTrack action has the response.
        * */
		ProfileStore.on("Selecttrack", this.BindSelectUserTrackData.bind(this));
        /*
        * RF No: UP_PC_32
        * This emit call is done when the AddTrackUser action has the response.
        * */
		ProfileStore.on("AddTrack", this.ShowTrackMessage.bind(this));
        /*
        * RF No: UP_PC_34
        * This emit call is done when the UpdateCrib action has the response.
        * */
		ProfileStore.on("UpdateCrib", this.ShowMobMessage.bind(this));
        /*
        * RF No: UP_PC_36
        * This emit call is done when the UpdateZeus action has the response.
        * */
		ProfileStore.on("UpdateZeus", this.ShowZeusMessage.bind(this));
        /*
        * RF No: UP_PC_64
        * This emit call is done when the GetThirdParty action has the response.
        * */
		ProfileStore.on("ThirdParty", this.ShowThirdParty.bind(this));
        /*
         * This emit call is done when the getCurrentFavPage action has the response.
         * */
		ProfileStore.on('favpage', this.loadFavPageResponse);
        /*
         * This emit call is done when the insertFavPage,deleteFavPage action has the response.
         * */
		ProfileStore.on('updateFavPage', this.updateFavPageResponse);
        /*
         * RF No: UP_PC_37
         * This emit call is done when the ActivateSite, DeActivateSite, ActivateEmpyAcc, DeActivateEmpyAcc, action has the response.
         * */
		ProfileStore.on("ActivateSiteAcct", this.ShowActivateStatus);
		ProfileStore.on("GetchargeCOdeBO", this.BuildChargeCOdeData);
        /*
        * [5/11/2023]CAS-44 This emit call is done when the ValidateAddress action has the response.
        * */
		ShoppingCartStore.on('ValidAddress', this.validateAddressResponse);
		ProfileStore.on('LoadAltBudApprovals', this.BuildAltBudApprovalForm.bind(this));
		ProfileStore.on('UpdateAltBudApprovals', this.ShowAltBudApprovalStatus.bind(this));
	}
    /*
     * componentWillUnmount is used to remove the memory leakage.
     * */
	componentWillUnmount() {

		ProfileStore.removeListener("UserDetail", this.BuildUserProfileForm.bind(this));
		ProfileStore.removeListener("RadioUserType", this.BuildSearchOnUserTypeChg.bind(this));
		ProfileStore.removeListener("SelectUser", this.BuildUserProfileDetails);
		ProfileStore.removeListener("VendorIdCheck", this.BindVendorSite.bind(this));
		ProfileStore.removeListener("AddUser", this.ShowEditMessage);
		//[5/24/22, PC_TFS_01, Bug 2157], Poornima S//Sister sites not available alert is displayed multiple times even after clicking 'OK'
		ProfileStore.removeListener("MultiSite", this.BindMultiSiteDD);
		ProfileStore.removeListener("TabClick", this.BuildTabClick.bind(this));
		ProfileStore.removeListener("PrivilegeType", this.BindPrivilegeOnType.bind(this));
		ProfileStore.removeListener("PrivilegeRole", this.BindPrivilegeOnRole.bind(this));
		ProfileStore.removeListener("UpdatePrivilege", this.ShowUpdateMesPri);
		ProfileStore.removeListener("LoadReqApprovals", this.BuildReqApprovalForm.bind(this));
		ProfileStore.removeListener("LoadBudApprovals", this.BuildBudApprovalForm.bind(this));
		ProfileStore.removeListener("UpdateReqApprovals", this.ShowApprovalStatus.bind(this));
		ProfileStore.removeListener("UpdateApprovals", this.ShowBudApprovalStatus.bind(this));
		ProfileStore.removeListener("UpdateOSE", this.ShowOSEMessage.bind(this));
		ProfileStore.removeListener("UpdatePref", this.ShowPrefMessage);
		ProfileStore.removeListener("Selecttrack", this.BindSelectUserTrackData.bind(this));
		ProfileStore.removeListener("AddTrack", this.ShowTrackMessage.bind(this));
		ProfileStore.removeListener("UpdateCrib", this.ShowMobMessage.bind(this));
		ProfileStore.removeListener("UpdateZeus", this.ShowZeusMessage.bind(this));
		ProfileStore.removeListener("ThirdParty", this.ShowThirdParty.bind(this));
		ProfileStore.removeListener('favpage', this.loadFavPageResponse);
		ProfileStore.removeListener('updateFavPage', this.updateFavPageResponse);
		ProfileStore.removeListener("ActivateSiteAcct", this.ShowActivateStatus);
		ProfileStore.removeListener("GetchargeCOdeBO", this.BuildChargeCOdeData);
		ShoppingCartStore.removeListener('ValidAddress', this.validateAddressResponse);
		ProfileStore.removeListener('LoadAltBudApprovals', this.BuildAltBudApprovalForm.bind(this));
		ProfileStore.removeListener('UpdateAltBudApprovals', this.ShowAltBudApprovalStatus.bind(this));
	}
    /*
     * Ref no: UP_PC_51
     * In BuildUserProfile method the page load action call is made to fetch the action response from the api.
     * */
	BuildUserProfile = () => {
		//document.getElementsByClassName("rct-option-expand-all").setAttribute("id", "ExpnadBtn")
		//document.getElementsByClassName("rct-option-collapse-all").setAttribute("id", "CollapeBtn")
        /**
        * Invoke the action to check the current page is favorited
        */
		currentPagePath = this.props.location.pathname;
		currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
		let getFavPageBO = {
			Session_USERID: this.state.Login_UserId,
			pageURL: currentPagePath.toLowerCase(),
			Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
		}
		ProfileAction.getCurrentFavPage(getFavPageBO);
		let UserProfileBO = {
			Session_USERID: this.state.Login_UserId,
			Session_BUSUNIT: this.state.Login_UserBU,
			Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
			Session_ThirdParty_CompanyID: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID"),
			Session_TrackDBType: CookieService.getLocalStorageValues("Session_TrackDBType"),
			Query_IsVendor: false,
			Query_IsMexicoVendor: false,
			Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
			Session_APPRTYPE: CookieService.getLocalStorageValues("Session_APPRTYPE"),
		}
		ProfileAction.GetEditUser(UserProfileBO);
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
	}

	//[5/11/2023]Z-102 Response returned from Google API after validating the address will be handled in validateAddressResponse ()
	validateAddressResponse = () => {
		//debugger
		const { t } = this.props;
		let validAddress = JSON.parse(ShoppingCartStore.AddressValidation);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		if (validAddress != null && validAddress != "" && validAddress != undefined) {
			validAddress = JSON.parse(validAddress)
			let hasUnconfirmedComponents = validAddress.result.verdict.hasUnconfirmedComponents
			console.log("hasUnconfirmedComponents", hasUnconfirmedComponents)
			if (hasUnconfirmedComponents != undefined) {
				document.getElementById("AddressErrorMsg").innerText = "Plese enter a valid address"
			}
			else {
				document.getElementById("AddressErrorMsg").innerText = ""
				let uspsDataAddress = validAddress.result.uspsData != undefined ? validAddress.result.uspsData : validAddress.result.address.postalAddress // PS_SC_ST_01 //[05/03/23, PS_SC_AV_01, 3416], Sathis N//condition for validate mexican and canadian adresses
				let standardAddress = uspsDataAddress.standardizedAddress != undefined ? uspsDataAddress.standardizedAddress : uspsDataAddress
				let postalAddress = validAddress.result.address.postalAddress
				let geocode = validAddress.result.geocode
				let location = geocode.location
				$('#AddressFieldsModal').modal('hide');
                let street = standardAddress.firstAddressLine != undefined ? standardAddress.firstAddressLine.toUpperCase() : standardAddress.addressLines[0].toUpperCase()
                let city = standardAddress.city != undefined ? standardAddress.city.toUpperCase() : standardAddress.locality.toUpperCase()
                let state = standardAddress.state != undefined ? standardAddress.state.toUpperCase() : standardAddress.administrativeArea.toUpperCase()
                let zipCode = standardAddress.zipCode != undefined ? standardAddress.zipCode.toUpperCase() : standardAddress.postalCode.toUpperCase()
                //[Zeus 293, 04/09/2024] -Zipcode extension concatenation - Kishore
                let extZipCode = standardAddress.zipCodeExtension != undefined ? "-" + standardAddress.zipCodeExtension.toUpperCase() : "";
                zipCode = zipCode + extZipCode;
                let countryCode = (postalAddress.regionCode ? (postalAddress.regionCode.toLowerCase() == "us" ? "USA" : postalAddress.regionCode.toLowerCase() == "ca" ? "CAN" : postalAddress.regionCode.toLowerCase() == "mx" ? "MEX" : postalAddress.regionCode.toUpperCase()) : "");
                let concateApartmentNo = this.state.apartmentNoValue.trim() != "" ? ", " + this.state.apartmentNoValue : ""
                let userAddress = this.state.streetValue + concateApartmentNo + ", " + this.state.cityValue + ", " + this.state.stateValue + ", " + this.state.zipCodeValue + ", ";
                userAddress = userAddress + (this.state.countryValue ? (this.state.countryValue.toLowerCase() == "us" ? "USA" : this.state.countryValue.toLowerCase() == "ca" ? "CAN" : this.state.countryValue.toLowerCase() == "mx" ? "MEX" : this.state.countryValue.toUpperCase()) : "") + ".";
                let recAddress = street + concateApartmentNo + ", " + city + ", " + state + ", " + zipCode + ", ";
                recAddress = recAddress + countryCode + ".";
                this.setState({
					responseIsValidStreet: street, responseIsValidCity: city, responseIsValidState: state, responseIsValidZipCode: zipCode, responseIsValidCountryCode: countryCode,
					userSelectedAddress: userAddress, recomendedAddress: recAddress,
					validLatitude: location.latitude, validLongitude: location.longitude,
                    responseTypedStreet: this.state.streetValue, responseTypedCity: this.state.cityValue, responseTypedZipCode: this.state.zipCodeValue, responseTypedState: this.state.stateValue, responseTypedCountry: this.state.countryValue,
                    recAddress: true, typedAddress: false,//Zeus 293 - SIT - 1 4970 - Make recommanded address as by default selected option Bug fix - Johnprinto D
				})
				$('#AddressModal').modal('show');
			}
			console.log("validAddressResp", validAddress)
		}
		else {
			document.getElementById("AddressErrorMsg").innerText = (t("Please enter a valid address"));
		}

	};

    /*
     * Handle change method for favorite page checkbox
     */
	handleFavorite = (event) => {
		this.setState({ isFavorited: event.target.checked });
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		if (event.target.checked == true) {
			let insertFavPageBO = {
				Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
				pageURL: currentPagePath.toLowerCase(),
				pageTitle: "User Profile",
				Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
			}
			ProfileAction.insertFavPage(insertFavPageBO)
		} else {
			let deleteFavPageBO = {
				Page_ID: this.state.Page_ID
			}
			ProfileAction.deleteFavPage(deleteFavPageBO)
		}
	};
    /*
     * Listener method for loading favorite page icon
     */
	loadFavPageResponse = () => {
		let favPageResponse = JSON.parse(ProfileStore.currentFavPageResponse);
		console.log("favPageResponse", favPageResponse);
		if (favPageResponse.length > 0) {
			favPageResponse = favPageResponse[0];
			this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
		};
	};
    /*
    * Listener method for the response of Save/Delete favorite page
    */
	updateFavPageResponse = () => {
		if (ProfileStore.updateFavPageResponse.updateType == 1) {
			let response = JSON.parse(ProfileStore.updateFavPageResponse.responseStr)[0];
			if (response.is_Success == "True") {
				$(`#${SDIConstants.SDILoader}`).addClass('hide');
				this.setState({ isFavorited: true, Page_ID: response.Page_ID });
				MenuAction.toastMessage(1);
			}
		} else {
			let response = JSON.parse(ProfileStore.updateFavPageResponse.responseStr)[0];
			if (response.success == "true") {
				$(`#${SDIConstants.SDILoader}`).addClass('hide');
				this.setState({ isFavorited: false });
				MenuAction.toastMessage(2);
			}
		}
		this.refreshMenu();
	};
    /*
     * This method is used to refresh the menu bar.
     * */
	refreshMenu = () => {
		let masterBO = {
			Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
			Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
			Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
			Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
			isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
			isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
			isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
			isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
			isFirstTime: false,
			//6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
			Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
		};
		MenuAction.getMasterData(masterBO);
	};

	handleAddressSelect = () => {
		$('#AddressFieldsModal').modal('show');
		//debugger
		if (this.state.userAddress != "") {
			if (this.state.isRecAddressSelected) {
				document.getElementById("AddressErrorMsg").innerHTML = "";
				this.setState({ stateValue: this.state.isValidState, cityValue: this.state.isValidCity, streetValue: this.state.isValidStreet, countryValue: this.state.isValidCountryCode, zipCodeValue: this.state.isValidZipCode, selectCountry: this.state.countryObj, apartmentNoValue: this.state.typedApartmentNo });
			}
			else if (this.state.isUserAddressSelected) {
				document.getElementById("AddressErrorMsg").innerHTML = "";
				this.setState({ stateValue: this.state.typedState, cityValue: this.state.typedCity, streetValue: this.state.typedStreet, countryValue: this.state.typedCountry, zipCodeValue: this.state.typedZipCode, selectCountry: this.state.countryObj, apartmentNoValue: this.state.typedApartmentNo });
			}
            else if (this.state.isExistingAddress == true) {
                document.getElementById("AddressErrorMsg").innerHTML = "";
                let countryObj = this.state.existCountry.trim() == "USA" ? countryOptions[0] : this.state.existCountry.trim() == "CAN" ? countryOptions[1] : countryOptions[2];
                this.setState({ stateValue: this.state.existState.trim(), cityValue: this.state.existCity.trim(), streetValue: this.state.existStreet.trim(), countryValue: this.state.existCountry.trim(), zipCodeValue: this.state.existPostal.trim(), selectCountry: countryObj, apartmentNoValue: this.state.existApartmentNo != null ? this.state.existApartmentNo : "" });
            }
        }
        else {
            document.getElementById("AddressErrorMsg").innerText = "";
            this.setState({ stateValue: "", cityValue: "", streetValue: "", countryValue: "USA", zipCodeValue: "", selectCountry: countryOptions[0], apartmentNoValue: "" })
        }

	}

	//[5/11/2023]Z-102 onStateChange is hit on change on state text box in address fields modal to convert the characters to upper and to allow alphabets alone
	onStateChange = (e) => {
		let strState = e.target.value.toUpperCase();
		const re = SDIConstants.AlphabetsReg;
		if (re.test(strState) || strState === ' ' || strState === '') {
			this.setState({ stateValue: strState })
		}
	}

	//[5/11/2023]Z-102 onCityChange is hit on change on city text box in address fields modal to convert the characters to upper
	onCityChange = (e) => {
		let strCity = e.target.value.toUpperCase();
		this.setState({ cityValue: strCity })
	}

	//[5/11/2023]Z-102 onCountryChange is hit on change on country text box in address fields modal to convert the characters to upper and to allow alphabets alone
	onCountryChange = (e) => {
		let newObj = e;
		let country = e.value;
		this.setState({ selectCountry: newObj, countryValue: country })
	}

	//[5/11/2023]Z-102 OnHandleZipCode is hit on change on zip code text box in address fields modal to validate that it is a zip code
	OnHandleZipCode = (e) => {
		let strZipCode = e.target.value.toUpperCase();
		const re = SDIConstants.zipcodeRegEx;
		if (re.test(strZipCode) || strZipCode === ' ' || strZipCode === '') {
			this.setState({ zipCodeValue: strZipCode })
		}
	}

	//[5/11/2023]Z-102 onStreetChange is hit on change on street text box in address fields modal to convert the characters to upper
	onStreetChange = (e) => {
		let strStreet = e.target.value.toUpperCase();
		this.setState({ streetValue: strStreet })
	}
	
	onApartmentNoChange = (e) => {
		let apartmentNo = e.target.value.toUpperCase();
		this.setState({ apartmentNoValue: apartmentNo })
	}

	closeAddressPopup = () => {
		document.getElementById("AddressErrorMsg").innerText = "";
        this.setState({ stateValue: "", cityValue: "", selectCountry: countryOptions[0], countryValue: "USA", zipCodeValue: "", streetValue: "", apartmentNoValue: "" })
	}

	//[5/11/2023]Z-102 selectedAddress() is hit on change of address type radio button, flag is set based on the address type selected by the user
	selectedAddress = (Addresstype) => {
		//debugger;
		if (Addresstype == "recommendedAddress") {
			$("#userAddressBtn").prop("checked", false);
			$("#recomndAddressBtn").prop("checked", true);
			this.setState({ typedAddress: false, recAddress: true });
		}
		else {
			$("#userAddressBtn").prop("checked", true);
			$("#recomndAddressBtn").prop("checked", false);
			this.setState({ typedAddress: true, recAddress: false })
		}

	}

	//[5/11/2023]Z-102 confirmAddress is to store the selected address details in appropriate state variables once the user clicks confirm button in the address pop up
	confirmAddress = () => {
		let isDefAddress = false;
		const { t } = this.props;
		$('#AddressModal').modal('hide');
		if (this.state.recAddress == true) {
			if (this.state.userAddress == this.state.recomendedAddress) {
				isDefAddress = true;
			}
			document.getElementById("isValidAddress").innerText = (t("Valid address"));
			this.setState({
				userAddress: this.state.recomendedAddress, disableShipto: true, userSelectedAddress: "", countryObj: this.state.selectCountry, isDefAddressChckd: isDefAddress, isExistingAddress: false, isRecAddressSelected: true, isUserAddressSelected: false, typedApartmentNo: this.state.apartmentNoValue,
				isValidStreet: this.state.responseIsValidStreet, isValidCity: this.state.responseIsValidCity, isValidState: this.state.responseIsValidState, isValidZipCode: this.state.responseIsValidZipCode, isValidCountryCode: this.state.responseIsValidCountryCode
			});
		}
		else {
			if (this.state.userAddress == this.state.userSelectedAddress) {
				isDefAddress = true;
			}
			document.getElementById("isValidAddress").innerText = "";
			this.setState({
				userAddress: this.state.userSelectedAddress, disableShipto: true, recomendedAddress: "", countryObj: this.state.selectCountry, isDefAddressChckd: isDefAddress, isExistingAddress: false, isRecAddressSelected: false, isUserAddressSelected: true, typedApartmentNo: this.state.apartmentNoValue,
				typedStreet: this.state.responseTypedStreet, typedCity: this.state.responseTypedCity, typedState: this.state.responseTypedState, typedZipCode: this.state.responseTypedZipCode, typedCountry: this.state.responseTypedCountry
			});
		}
	}

	//[5/11/2023]Z-102 validateAddressClick() will be hit when validate button in the address fields popup is clicked
	validateAddressClick = () => {
		//debugger
		const { t } = this.props;
		if (this.state.stateValue.trim() == "" || this.state.cityValue.trim() == "" || this.state.streetValue.trim() == "" || this.state.countryValue.trim() == "" || this.state.zipCodeValue.trim() == "") {
			document.getElementById("AddressErrorMsg").innerHTML = (t("Please enter a valid address"));
		}
		else {
			document.getElementById("AddressErrorMsg").innerHTML = "";
			let locarr = []
			let addressLine = this.state.streetValue
			locarr.push(addressLine)
			let Address = {
				regionCode: this.state.countryValue,
				locality: this.state.cityValue,
				administrativeArea: this.state.stateValue,
				postalCode: this.state.zipCodeValue,
				addressLines: locarr
			}
			let AddressBO = {
				address: Address
			}
			ShoppingCartAction.ValidateAddress(AddressBO);
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		}
	}


	//[5/11/2023]Z-102 clearAddress() is to clear the address field and state variables values when clear address is clicked
	clearAddress = (e) => {
		e.preventDefault();
		document.getElementById("isValidAddress").innerText = "";
		document.getElementById("AddressErrorMsg").innerHTML = "";
		this.setState({
            recomendedAddress: "", userSelectedAddress: "", stateValue: "", cityValue: "", streetValue: "", countryValue: "USA", zipCodeValue: "",
			isValidStreet: "", isValidCity: "", isValidState: "", isValidZipCode: "", isValidCountryCode: "", userAddress: "", recAddress: true, typedAddress: false,
			disableShipto: false, apartmentNoValue: "", selectCountry: countryOptions[0]
		})
	}

    /*
     * Ref no:UP_PC_52
     * This method is intialize by the store emit.
     * In this method the user profile tab 1 from details are based up on the login user type and role.
     * The drop down are binded with the stroe response value.
     * */
	BuildUserProfileForm = () => {
		//debugger
		const { t } = this.props;
		let PageloadDetails = JSON.parse(ProfileStore.UserDetailOBO);
		console.log("PageloadDetails", PageloadDetails);
		let FirstNameL = "";
		let lastNameL = "";
		let PhoneL = "";
		let DepartL = "";
		let userTypeL = "";
		let userRoleL = "";
		let HideuseridL = "";
		let EmailAddL = "";
		let Site = "";
		let SelectedUserIdL = "";
		let ActiveStatus = "";
		let MultiSiteEnableL = "";
		let GroupIdL = "";
		let GroupTable = [];
		let SiteList = [];
		let MultiSiteList = [];
		let ManuallyAddUserIdL = "";
		let MultiSiteChecked = [];
		let searchUserL = [];
		let DepartmentL = [];
		let UserDetails = [];
		let ThirdPartyListL = [];
		let VendorIdL = "";
		let VendorGroupL = "";
		let selectedOptionL = [];
		let SiteValue = "";
		let Departvalue = "";
		let SiteTitle = "";
		let btnActivateAccount = "";
		let btnInactivateAccount = "";
		let btnEmplInactivateAccount = "";
		let btnEmplActivateAccount = "";
		let ShowEmplLabel = "";
		let ShowAcctLabel = "";
		let SelectedThirdPartyL = "";
		let EmployeeActiveStatus = "";
		let ShowEmplyActiveLabel = false
		let ShowActiveLabel = false
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		let EnterFirstNameErrMsg = "";
		let EnterLastNameErrMsg = "";
		let EnterPhoneNameErrMsg = "";
		let EnterEmailNameErrMsg = "";
		let EmpLabelErrMsg = "";
		let AcctLabelErrMsg = "";

		UserDetails = PageloadDetails.UserProfileDeatils;
		if (UserDetails.length > 0) {
			SelectedUserIdL = UserDetails[0].Employee_ID;
			SelectedThirdPartyL = UserDetails[0].ThirdParty_Id
			userTypeL = UserDetails[0].UserType;
			userRoleL = UserDetails[0].UserRole;
			FirstNameL = UserDetails[0].First_Name;
			lastNameL = UserDetails[0].Last_Name;
			PhoneL = UserDetails[0].Phone_No;
			//PC_PCpP_02 - Bug 2036 - User not able to edit phone num with hyphen so restricting it here - Poornima S 4/7/2022
			if (PhoneL.includes('-')) {
				PhoneL = PhoneL.replace(/-/g, '');
			}
			EmailAddL = UserDetails[0].Email_Address;
			DepartL = UserDetails[0].Departement;
			HideuseridL = UserDetails[0].Hide_User_ID;
			ActiveStatus = UserDetails[0].active_status;
			EmployeeActiveStatus = UserDetails[0].Employee_active_status;
			Site = UserDetails[0].Businees_Unit;
			MultiSiteEnableL = UserDetails[0].Enable_MultiSite;
			VendorIdL = UserDetails[0].MainvndrID;
			btnActivateAccount = UserDetails[0].btnActivateAccount;
			btnInactivateAccount = UserDetails[0].btnInactivateAccount;
			btnEmplInactivateAccount = UserDetails[0].btnEmplInactivateAccount;
			btnEmplActivateAccount = UserDetails[0].btnEmplActivateAccount;
			ShowEmplLabel = UserDetails[0].lblEmplAccountDisabled;
			ShowAcctLabel = UserDetails[0].lblAccountDisabled;
			ShowEmplyActiveLabel = UserDetails[0].lblEmplAccountVisible;
			ShowActiveLabel = UserDetails[0].lblAccountVisible;
			VendorGroupL = UserDetails[0]['Vendor Name'];
			ThirdPartyListL = PageloadDetails.ThirdPartytable;
			searchUserL = PageloadDetails.SearchUsertable;
			SiteList = PageloadDetails.SisterSiteTable;
			MultiSiteList = PageloadDetails.Multisite;
			MultiSiteChecked = PageloadDetails.MultiSite_Checked;
			DepartmentL = PageloadDetails.DepartmentTable;
			GroupTable = PageloadDetails.GroupTable;
			ManuallyAddUserIdL = PageloadDetails.AddUserFlag[0].AUTO_ASSIGN_FLG
			//if (GroupTable.length > 0) {
			SiteTitle = UserDetails[0].txtGroup;
			GroupIdL = UserDetails[0].txtGroupID;
			//}
			//PC_PCpP_01 - Bug 2035 - Validation msg displayed even after changing user - Poornima S 4/7/2022
			//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
			if (FirstNameL.trim() == "") {
				//document.getElementById("ErrorMagFirstName").innerHTML = (t("Please enter first Name"));
				EnterFirstNameErrMsg = "Please enter first Name";
			}
			else {
				//document.getElementById("ErrorMagFirstName").innerHTML = "";
				EnterFirstNameErrMsg = "";
			}
			if (lastNameL.trim() == "") {
				EnterLastNameErrMsg = "Please enter last name";
			}
			else {
				EnterLastNameErrMsg = "";
			}
			if (PhoneL.trim() == "") {
				EnterPhoneNameErrMsg = "Please enter phone number";
			}
			else {
				EnterPhoneNameErrMsg = "";
			}
			if (EmailAddL.trim() == "") {
				EnterEmailNameErrMsg = "Please enter email address";
			}
			else {
				EnterEmailNameErrMsg = "";
			}
		}
		console.log("SiteTitle", SiteTitle)
		let SDIEMp = false
		let CustomerL = false
		let vendor = false
		if (VendorIdL == null) {
			VendorIdL = ""
		}
		if (GroupTable != null && GroupTable != undefined) {
			if (GroupTable.length > 0) {
				GroupIdL = GroupTable[0].txtGroupID
			}
		}
		let Hidetab = []
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		let InActEmpLblVal = "";
		let InActAccLblVal = "";
		Hidetab = PageloadDetails.HideTabList
		if (Hidetab[0].APPTab.toUpperCase() == "TRUE") {
			$('#approvalsTab').addClass('hide');
		}
		if (Hidetab[0].OSETab.toUpperCase() == "TRUE") {
			$('#orderSETab').addClass('hide');
		}
		if (Hidetab[0].PREFTab.toUpperCase() == "TRUE") {
			$('#preferencesTab').addClass('hide');
		}
		if (Hidetab[0].SDITrackTab.toUpperCase() == "TRUE") {
			$('#sditrackTab').addClass('hide');
		}
		if (Hidetab[0].UPVLTab.toUpperCase() == "TRUE") {
			$('#privilegesTab').addClass('hide');
		}
		if (Hidetab[0].ZeusTab.toUpperCase() == "TRUE") {
			$('#zeusTab').addClass('hide');
		}

		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		let conditionvalue = "false"
		if (btnEmplActivateAccount.toUpperCase() == conditionvalue.toUpperCase() && btnEmplInactivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
			$('#EmpyAcctBtn').addClass('hide');
		}
		else {
			if (btnEmplActivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
				InActEmpLblVal = "In-Activate Employee Account"
			}
			else {
				InActEmpLblVal = "Activate Employee Account"
			}
		}
		if (btnActivateAccount.toUpperCase() == conditionvalue.toUpperCase() && btnInactivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
			$('#AcctBtn').addClass('hide');
		}
		else {
			if (btnActivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
				InActAccLblVal = "In-Activate Account"
			}
			else {
				InActAccLblVal = "Activate Account"
			}
		}
		if (ShowActiveLabel.toUpperCase() == conditionvalue.toUpperCase()) {
			$('#AcctLabel').addClass('hide');
		}
		else {
			AcctLabelErrMsg = ShowAcctLabel;
		}
		if (ShowEmplyActiveLabel.toUpperCase() == conditionvalue.toUpperCase()) {
			$('#EmpLabel').addClass('hide');
		}
		else {
			EmpLabelErrMsg = ShowEmplLabel;
		}
		MultiSiteCHeckB = MultiSiteEnableL.toUpperCase() == "Y" || MultiSiteEnableL.toLowerCase() == "y" ? true : false
		$('#PassDiv').addClass('hide');
		$('#ConfPassDiv').addClass('hide');
		$('#EditMode').addClass('hide');
		if (MultiSiteEnableL.toUpperCase() == "Y" || MultiSiteEnableL.toLowerCase() == "y") {
			//document.getElementById("MultiSelectUP").checked = true;
			MultiSiteCHeckB = true
		}
		else {
			$('#MultiSelectDD').addClass('hide');
			MultiSiteEnableL = "N"
		}
		if (userTypeL != "S") {
			$('#DepartDiv').addClass('hide');
			$('#UserTypeDiv').addClass('hide');
		}
		if (userTypeL != "V") {
			$('#VendorDiv').addClass('hide');
		}
		if (userTypeL == "S") {
			SDIEMp = true
			CustomerL = false
			vendor = false
			// document.getElementById("UtypeSDI").checked = true;
		}
		else if (userTypeL == "C") {
			CustomerL = true
			vendor = false
			SDIEMp = false
			//document.getElementById("UtypeCustomer").checked = true;
		}
		else {
			vendor = true
			CustomerL = false
			SDIEMp = false
			//document.getElementById("UtypeVendor").checked = true;
		}
		if (userRoleL != null) {
			if (userRoleL.toLowerCase() == "user" || userRoleL.toUpperCase() == "USER") {
				$('#AddUserBtn').addClass('hide');
				$('#SearchDIv').addClass('hide');
			}
			if (userRoleL.toLowerCase() != "super") {
				$('#UserTypeDiv').addClass('hide');
			}
		}
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let SearchDDlist = [];
		searchUserL.map((val, index) => {

			let localArray = {
				value: val.ISA_EMPLOYEE_ID,
				label: val.USERANDBU
			}
			if (val.ISA_EMPLOYEE_ID == SelectedUserIdL) {
				selectedOptionL.push(localArray)
			}
			SearchDDlist.push(localArray)
		});
		let SiteListDD = []
		console.log("Site", Site)
		SiteList.map((val, index) => {

			let localArray = {
				value: val.GROUPID,
				label: val.GROUPNAME
			}
			if (val.GROUPID == Site) {
				SiteValue = localArray
			}
			if (val.GROUPID != undefined && val.GROUPNAME != undefined) {
				SiteListDD.push(localArray)
			}
		});
		if (SiteListDD.length == 0) {
			SiteList.map((val, index) => {

				let localArray = {
					value: val.BUSINESS_UNIT,
					label: val.DESCRIPTION
				}
				if (val.BUSINESS_UNIT == Site) {
					SiteValue = localArray
				}
				SiteListDD.push(localArray)
			});
		}
		//[2/18/22, CAS - 31, Dhevanesam R]
		let DepartmentLocal = []
		DepartmentL.map((val, index) => {
			if (index == 0) {
				let localArray1 = {
					value: "",
					label: (t("-----Select Department-----"))
				}
				DepartmentLocal.push(localArray1)
				if (val.DEPT_ID != DepartL) {
					Departvalue = localArray1
				}
			}
			let localArray = {
				value: val.DEPT_ID,
				label: val.DEPT_NAME
			}
			if (val.DEPT_ID == DepartL) {
				Departvalue = localArray
			}
			DepartmentLocal.push(localArray)
		});
		let Usertypes = []
		if (userRoleL.toUpperCase() == "CORPADMIN") {
			userRoleL = "CORP ADMIN"
			$('#MultiDiv').addClass('hide');
		}
		//[Zeus 130, 6/8/23] - Kishore S - Added supervisor role to customers
		if (userRoleL.toUpperCase() == "SUPER" || userRoleL.toUpperCase() == "CORP ADMIN") {
			Usertypes = [{ Role: "Super" }, { Role: "Corp Admin" }, { Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
		}
		else if (userRoleL.toUpperCase() == "ADMIN" && userTypeL == "S") {
			Usertypes = [{ Role: "Corp Admin" }, { Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
		}
		else {
			Usertypes = [{ Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
		}
		let UserROleLocal = [];
		let UserRoleSelect = [];
		if (CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") == "0") {
			//debugger
			if (ThirdPartyListL.length > 0 && userTypeL == "C") {

				ThirdPartyListL.map((ThirdParty, index1) => {

					if (index1 == 0) {
						Usertypes.map((val, index2) => {
							let localArray = {
								value: val.Role,
								label: val.Role,
								key: Site,
								Role: val.Role,
								Third: "0",
							}
							if (userTypeL == "S" && val.Role != "Corp Admin" && val.Role !="Supervisor") {
								UserROleLocal.push(localArray)
							}
							if (userTypeL == "C" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (userTypeL == "V" && val.Role != "Corp Admin" && val.Role != "Super" && val.Role != "Supervisor") {
								UserROleLocal.push(localArray)
							}
							if (val.Role.toUpperCase() == userRoleL) {
								UserRoleSelect = localArray
							}
						});
					}
					Usertypes.map((val, index3) => {

						let localArray = {
							value: ThirdParty.COMPANY_NAME + "  " + val.Role,
							label: ThirdParty.COMPANY_NAME + "  " + val.Role,
							key: Site,
							Role: val.Role,
							Third: ThirdParty.THIRDPARTY_COMP_ID,
						}
						if (userTypeL == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
							UserROleLocal.push(localArray)
						}
						if (userTypeL == "C" && val.Role != "Super" && val.Role != "Corp Admin") {
							UserROleLocal.push(localArray)
						}
						if (userTypeL == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
							UserRoleSelect = localArray
						}
					});
				});
			}
			else {
				Usertypes.map((val, index2) => {

					let localArray = {
						value: val.Role,
						label: val.Role,
						key: Site,
						Role: val.Role,
						Third: "0",
					}
					if (userTypeL == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
						UserROleLocal.push(localArray)
					}
					if (userTypeL == "C" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (userTypeL == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (val.Role.toUpperCase() == userRoleL) {
						UserRoleSelect = localArray
					}
				});
			}
		}
		else {
			//[10/06/21, PC_01, Bug ID - 1724, Priyanka D]            
			console.log("ThirdPartyListL", ThirdPartyListL);
			if (ThirdPartyListL.length > 0) {
				let childItems = ThirdPartyListL.filter((item, index) => item.THIRDPARTY_COMP_ID == CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID"));

				console.log("childItems", childItems);
				childItems.map((ThirdParty, index1) => {

					Usertypes.map((val, index2) => {

						let localArray = {
							value: ThirdParty.COMPANY_NAME + "  " + val.Role,
							label: ThirdParty.COMPANY_NAME + "  " + val.Role,
							key: Site,
							Role: val.Role,
							Third: ThirdParty.THIRDPARTY_COMP_ID,
						}
						if (userTypeL == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
							UserROleLocal.push(localArray)
						}
						if (userTypeL == "C" && val.Role != "Super") {
							UserROleLocal.push(localArray)
						}
						if (userTypeL == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
							UserROleLocal.push(localArray)
						}
						if (val.Role.toUpperCase() == userRoleL) {
							UserRoleSelect = localArray
						}
					});
				});

			} else {
				//debugger;
				Usertypes.map((val, index2) => {

					let localArray = {
						value: val.Role,
						label: val.Role,
						key: Site,
						Role: val.Role,
						Third: "0",
					}
					if (userTypeL == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
						UserROleLocal.push(localArray)
					}
					if (userTypeL == "C" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (userTypeL == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (val.Role.toUpperCase() == userRoleL) {
						UserRoleSelect = localArray
					}
				});
			}
		}
		//debugger
		let MultiSlectList = []
		MultiSiteList.map((val, index) => {
			if (val.BUSINESS_UNIT == Site) {
				let localArray = {
					value: val.BUSINESS_UNIT,
					label: val.DESCRIPTION,
					disabled: true
				}
				SiteTitle = val.DESCRIPTION;
				MultiSlectList.push(localArray)
			}
			else {
				let localArray = {
					value: val.BUSINESS_UNIT,
					label: val.DESCRIPTION
				}
				MultiSlectList.push(localArray)
			}
		});
		let MultiSelectValue = []
		MultiSiteChecked.map((val, index) => {
			//debugger
			let ChildSiteValue = MultiSiteList.filter((item, index) => item.BUSINESS_UNIT == val.BUSINESS_UNIT);
			//Multisite DD binding issue for blackr1 in user profile -Dhamotharan P (8/22/2022)
			if (ChildSiteValue.length != 0) {
				let localArray = {
					value: ChildSiteValue[0].BUSINESS_UNIT,
					label: ChildSiteValue[0].DESCRIPTION
				}
				MultiSelectValue.push(localArray)
			}


		});
		let DisableTypeDDL = false
		if (userRoleL.toUpperCase() == "USER") {
			DisableTypeDDL = true
		}
		else if (userRoleL.toUpperCase() == "ADMIN" && userTypeL == "S") {
			DisableTypeDDL = true
		}
		console.log("MultiSlectList", MultiSlectList)
		console.log("MultiSlectList", UserRoleSelect)
		console.log("Site", SiteTitle)
		console.log("Site", SiteValue)
		console.log("Site", SiteListDD)
		if (SiteTitle == null) {
			SiteTitle = ""
		}
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		this.setState({
			SelectedUserId: SelectedUserIdL, selectedOption: selectedOptionL, FirstName: FirstNameL, LastName: lastNameL, PhoneNum: PhoneL, EmailAddres: EmailAddL, MultiSiteEnable: MultiSiteEnableL, MultiSiteExist: MultiSiteEnableL,
			VendorGroup: VendorGroupL, VendorId: VendorIdL, SelectSite: SiteValue, UserType: userTypeL, UserRole: userRoleL, DepartMent: Departvalue, LoginHideuserId: HideuseridL, HideuserId: HideuseridL,
			UserActiveStatus: ActiveStatus, EmployeeAccountActiveStatus: EmployeeActiveStatus, SearchUser: SearchDDlist, MultiSite: MultiSlectList, MultiSiteSelected: MultiSelectValue, Depart: DepartmentLocal, SiteList: SiteListDD,
			ThirdPartyList: ThirdPartyListL, SelectUserRole: UserRoleSelect, UserRoleDD: UserROleLocal, SiteTitleValue: SiteTitle, selectSiteID: Site, LoginUserRole: userRoleL, LoginUserType: userTypeL,
			SelectUsertype: userTypeL, ManuallyAddUserId: ManuallyAddUserIdL, SelectedThirdParty: SelectedThirdPartyL, MultiSiteChecked: MultiSiteCHeckB, GroupID: GroupIdL,
			SDIEMp: SDIEMp, vendor: vendor, Customer: CustomerL, DisableTypeDD: DisableTypeDDL, PageLoadSiteList: SiteList, EnterFirstNameErr: EnterFirstNameErrMsg, EnterEmailNameErr: EnterEmailNameErrMsg,
			EnterLastNameErr: EnterLastNameErrMsg, EnterPhoneNameErr: EnterPhoneNameErrMsg, EmpLabelErr: EmpLabelErrMsg, AcctLabelErr: AcctLabelErrMsg, InActEmpLbl: InActEmpLblVal
		})
		console.log("Role", this.state.SelectUserRole)
	}
    /*
     * Ref no:UP_PC_44 && UP_PC_45
     * This method is invoked when the tab is click.
     * Api call is method by using the action GetTabBasedDetails() to fetch the data with respect to the tab name.
     * */
	TabClick = (TabName) => {

		if (TabName == "APP") {
			if (document.getElementById("customCheckk")) {
				document.getElementById("customCheckk").checked = false;
			}
			let n = ['BudReq', 'AltReq', 'AltBud'];
			let len = 2
			while (len != -1) {
				let accElement = document.getElementById(`arrow-${n[len]}`);
				let classTest = accElement.classList.contains("accordion-arrow-change");
				if (classTest) {
					$(`#arrow-${n[len]}`).toggleClass('accordion-arrow-change');
					$(`#contentrow-${n[len]}`).addClass('d-none');
				}
				len--;
			}

		}
		if (TabName == "UD") {
			//document.getElementById('StatusMsg').innerText = "";
			let StatusMsgError = "";
			this.setState({ StatusMsgErrorVal: StatusMsgError })
		}
		else {
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');

			let loginuserrole = this.state.LoginUserRole.toUpperCase()
			let userrole = this.state.UserRole.toUpperCase()
			let SiteValue = ""
			SiteValue = this.state.selectSiteID
			if (this.state.SiteList.length > 0) {
				SiteValue = this.state.SelectSite.value
			}
			else {
				SiteValue = this.state.GroupID
			}
			if (loginuserrole == "CORP ADMIN") {
				loginuserrole = "CORPADMIN"
			}
			if (userrole == "CORP ADMIN") {
				userrole = "CORPADMIN"
			}
			approvalAccordian = loginuserrole == "SUPER" || loginuserrole == "ADMIN" || loginuserrole == "SUPERVISOR" ? 1 : 2;
			let TabclickBo = {
				TabName: TabName,
                UserId: this.state.SelectedUserId == "" || this.state.SelectedUserId == null ? this.state.Login_UserId : this.state.SelectedUserId,
				Page_Action: "EDIT",
				Session_USERTYPE: loginuserrole,
				Query_IsMexicoVendor: false,
				Session_ThirdParty_CompanyID: this.state.SelectedThirdParty,
				Session_UserRole: this.state.LoginUserType.toUpperCase(),
				Session_EMAIL: CookieService.getLocalStorageValues("Session_EMAIL"),
				Session_BUSUNIT: this.state.Login_UserBU,
				Session_UserId: this.state.Login_UserId,
				Query_IsVendor: false,
				SiteValue: SiteValue,
				UserType: this.state.UserType.toUpperCase(),
				UserRole: userrole,
				Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
			}
			TabclickTitle = TabName;
			ProfileAction.GetTabBasedDetails(TabclickBo)
			this.setState({ RoleExist: "", isExpandAll: true })
		}
	}
    /*
     * Ref no:UP_PC_82 && UP_PC_79 && UP_PC_70 && UP_PC_66 && UP_PC_85 && UP_PC_88 && UP_PC_95
     * Bind tab based data
     * 
     * */
	BuildTabClick = () => {
		//debugger
		const { t } = this.props;
		let Tabdetails = JSON.parse(ProfileStore.TabClickBO);
		console.log("Tabdetails", Tabdetails);
		//document.getElementById('StatusMsg').innerText = ""
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = ""
		this.setState({ StatusMsgErrorVal: StatusMsgError })
		switch (TabclickTitle) {
			case "UPVL": {
				let ProgramTree = [];
				let AlacartTree = [];
				let ROleBasedTree = [];
				let ROleList = [];
				let EXistrole = [];
				let SiteList = [];
				let Disrole = false
				let Tab2SiteTitleL = ""
				ProgramTree = Tabdetails.ProgramsList;
				AlacartTree = Tabdetails.AlacartProgramTable;
				ROleBasedTree = Tabdetails.RoleProgramTable;
				ROleList = Tabdetails.RoleDDList;
				EXistrole = Tabdetails.UserRoleExist;
				SiteList = Tabdetails.SisterSiteList;
				let SiteValue = []
				let SiteListDD = []
				if (SiteList != null && SiteList != undefined) {
					if (SiteList.length > 0) {
						//debugger
						SiteList.map((val, index) => {
							let localArray = {
								value: val.GROUPID,
								label: val.GROUPNAME
							}
							if (val.GROUPID == this.state.selectSiteID) {
								SiteValue = localArray
								Tab2SiteTitleL = val.GROUPNAME
							}
							SiteListDD.push(localArray)
						});
						if (SiteListDD != null && SiteListDD != undefined) {
							//debugger
							if (SiteListDD.length > 0) {
								if (SiteListDD[0].value == undefined) {
									SiteListDD = []
									SiteList.map((val, index) => {
										let localArray = {
											value: val.BUSINESS_UNIT,
											label: val.DESCRIPTION
										}
										if (val.BUSINESS_UNIT == this.state.selectSiteID) {
											SiteValue = localArray
											Tab2SiteTitleL = val.DESCRIPTION
										}
										SiteListDD.push(localArray)
									});
								}
							}
						}
					}
				}
				let RoleValue = ""
				let RoleListDD = []
				ROleList.map((val, index) => {
					let localArray = {
						value: val.ROLENUM,
						label: val.ROLENAME
					}
					if (EXistrole != undefined) {
						if (EXistrole.length > 0) {
							if (val.ROLENUM == EXistrole[0].RoleId) {
								RoleValue = localArray
							}
						}
					}
					RoleListDD.push(localArray)
				});
				let TreeValueList = []
				let Parenttree = ProgramTree.filter((item, index) => item.ISA_PARENT_IDENT == null);
				ProgramTree = ProgramTree.filter((x) => x.ISA_IDENTIFIER != 1 && x.ISA_IDENTIFIER != 156 && x.ISA_IDENTIFIER != 319 && x.ISA_IDENTIFIER != 320);
				let CatTreeWithChild = ProgramTree.filter((x) => x.ISA_CATEGORY_ID != null && x.ISA_CATEGORY_ID != "");
				CatTreeWithChild = CatTreeWithChild.sort((p1, p2) => (p1.ISA_CATEGORY_ID > p2.ISA_CATEGORY_ID) ? 1 : (p1.ISA_CATEGORY_ID < p2.ISA_CATEGORY_ID) ? -1 : 0);
				let WithoutCategory = ProgramTree.filter((x) => x.ISA_CATEGORY_ID == null || x.ISA_CATEGORY_ID == "");
				CatTreeWithChild = CatTreeWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_CATEGORY_ID === v.ISA_CATEGORY_ID)) === i);
				if (CatTreeWithChild.length > 0) {
					CatTreeWithChild.forEach((catVal, catInd) => {
						let ParentTree = [];
						let ParentValue = [];
						let ParentWithChild = [];
						let WithoutParent = [];
						ParentTree = ProgramTree.filter((x) => x.ISA_CATEGORY_ID == catVal.ISA_CATEGORY_ID);
						WithoutParent = ParentTree.filter((x) => x.ISA_PARENT_IDENT == null || x.ISA_PARENT_IDENT == "");
						ParentWithChild = ParentTree.filter((x) => x.ISA_PARENT_IDENT != null && x.ISA_PARENT_IDENT != "");
						ParentWithChild = ParentWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_PARENT_IDENT === v.ISA_PARENT_IDENT)) === i);
						if (ParentWithChild.length > 0) {
							ParentWithChild.forEach((parentVal, parentInd) => {
								let ChildTree = [];
								let ChildValue = [];
								ChildTree = ProgramTree.filter((x) => x.ISA_PARENT_IDENT == parentVal.ISA_PARENT_IDENT);
								if (ChildTree.length > 0) {
									ChildTree.forEach((childVal, childInd) => {
										let localArray;
										if (childVal.ACTIVE == 1) {
											localArray = {
												value: childVal.SECURITYALIAS,
												label: childVal.ISA_IDENTIFIER_NAME,
												key: childVal.ISA_IDENTIFIER,
												Parent: childVal.ISA_PARENT_IDENT
											};
											ChildValue.push(localArray);
										}
										else {
											localArray = {
												value: childVal.SECURITYALIAS,
												label: childVal.ISA_IDENTIFIER_NAME,
												key: childVal.ISA_IDENTIFIER,
												//disabled: true,
												className: 'disabled-child',
												showCheckbox: false,
												Parent: childVal.ISA_PARENT_IDENT
											};
											ChildValue.push(localArray);
										}
									});
								}

								let parentLocObj = {
									value: parentVal.PARENTSECALIAS,
									label: parentVal.ISA_PARENT_NAME + "(" + parentVal.PARENTSECALIAS + ")",
									children: ChildValue,
									key: parentVal.ISA_PARENT_IDENT,
									Parent: parentVal.ISA_CATEGORY_ID
								}
								ParentValue.push(parentLocObj);
							});
						}
						if (WithoutParent.length > 0) {
							WithoutParent.forEach((parentVal, parentInd) => {
								let parentLocObj = {
									value: parentVal.SECURITYALIAS,
									label: parentVal.ISA_IDENTIFIER_NAME,
									children: "0",
									key: parentVal.ISA_IDENTIFIER,
									Parent: parentVal.ISA_CATEGORY_ID
								}
								ParentValue.push(parentLocObj);
							});
						};
						let catLocObj = {
							value: catVal.CATSECALIAS,
							label: catVal.ISA_CATEGORY_NAME + "(" + catVal.CATSECALIAS + ")",
							children: ParentValue,
							key: catVal.ISA_CATEGORY_ID,
							Parent: "0"
						}
						TreeValueList.push(catLocObj);
					});
				}
				if (WithoutCategory.length > 0) {
					WithoutCategory.forEach((catVal, catInd) => {
						let catLocObj;
						if (catVal.ACTIVE == 1) {
							catLocObj = {
								value: catVal.SECURITYALIAS,
								label: catVal.ISA_IDENTIFIER_NAME,
								children: "0",
								key: catVal.ISA_IDENTIFIER,
								Parent: "0"
							}
							TreeValueList.push(catLocObj);
						}
						else {
							catLocObj = {
								value: catVal.SECURITYALIAS,
								label: catVal.ISA_IDENTIFIER_NAME,
								children: "0",
								key: catVal.ISA_IDENTIFIER,
								Parent: "0"
							}
							TreeValueList.push(catLocObj);
						}
					})
				}
				let checkvalues = []
				let PrivTreeDisableL = false
				if (EXistrole != undefined) {
					if (EXistrole.length > 0 && EXistrole[0].RoleId != null) {
						if (ROleBasedTree.length > 0) {
							ROleBasedTree.forEach((val, index) => {
								//if (val.ISA_PARENT_IDENT == null) {
								//    checkvalues.push(val.SECURITYALIAS)
								//}
								//else {
								checkvalues.push(val.ALIAS_NAME)
								//}
							});
						}
						document.getElementById("Role").checked = true;
						PrivTreeDisableL = true
						$('#SelPrivBtn').addClass('hide');
						$('#DeSelPrivBtn').addClass('hide');
					}
					else {
						if (AlacartTree.length > 0) {
							AlacartTree.forEach((val, index) => {
								//if (val.ISA_PARENT_IDENT == null) {
								//    checkvalues.push(val.SECURITYALIAS)
								//}
								//else {
								checkvalues.push(val.SECURITYALIAS)
								//}
								Disrole = true
							});

						}

						document.getElementById("Alacart").checked = true;
						$('#PriviRoleDD').attr("disabled", true)
					}
				}
				$('#PrivTypeDIv').removeClass('hide');
				$('#PrivtopButton').removeClass('hide');
				$('#PrivProgramTree').removeClass('hide');
				$('#PrivProgramTitle').removeClass('hide');
				$('#CollapseALL').removeClass('hide');
				$('#ExpandALL').removeClass('hide');
				if (this.state.LoginUserRole.toUpperCase() == "CORP ADMIN") {
					$('#PrivTypeDIv').addClass('hide');
					$('#PrivtopButton').addClass('hide');
					$('#PrivProgramTree').addClass('hide');
					$('#PrivProgramTitle').addClass('hide');
				}
				if (this.state.UserType == "V") {
					Tab2SiteTitleL = "SUPPLIER"
					$('#CollapseALL').addClass('hide');
					$('#ExpandALL').addClass('hide');
				}
				console.log("RoleValue", RoleValue)
				debugger
				if (RoleValue == null || RoleValue == undefined || RoleValue == "") {
					debugger
					document.getElementById("Alacart").checked = true;
					$('#PriviRoleDD').attr("disabled", true);
					PrivTreeDisableL = false
					Disrole = true
					$('#SelPrivBtn').removeClass('hide');
					$('#DeSelPrivBtn').removeClass('hide');
				}
				this.setState({
					Tab2SiteList: SiteListDD, SelectTab2Site: SiteValue, Tab2RoleList: RoleListDD, SelectROleList: RoleValue, Tab2BindTree: TreeValueList,
					checked: checkvalues, expanded: checkvalues, DisablePrivRole: Disrole, PrivTree: ProgramTree, PrivTreeDisable: PrivTreeDisableL, Tab2SiteTitle: Tab2SiteTitleL
				})
				break;
			}
			//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
			case "APP": {
				if (this.state.LoginUserRole.toUpperCase() == "SUPER" || this.state.LoginUserRole.toUpperCase() == "ADMIN" || this.state.LoginUserRole.toUpperCase() == "SUPERVISOR") {
					//document.getElementById("ApprLabel").innerText = (t("Budgetary Approval by"))
					let ApproverList = Tabdetails.BudApproverDD;
					let ExistApprover = [];
					ExistApprover = Tabdetails.ExistBudApprover
					let ApproveValue = []
					let ExistApp = []
					let LimitValue = ""

					let ExistBudApprL = "";
					$('#budApproval').removeClass('hide');
					let n = ['BudReq', 'AltReq', 'AltBud'];
					let len = 2
					while (len != -1) {
						let accElement = document.getElementById(`arrow-${n[len]}`);
						let classTest = accElement.classList.contains("accordion-arrow-change");
						if (classTest) {
							$(`#arrow-${n[len]}`).toggleClass('accordion-arrow-change');
							$(`#contentrow-${n[len]}`).addClass('d-none');
						}
						len--;
					}
					let BudReqElement = document.getElementById("arrow-BudReq");
					let classTest = BudReqElement.classList.contains("accordion-arrow-change");
					if (!classTest) {
						$('#arrow-BudReq').toggleClass('accordion-arrow-change');
					}
					$('#contentrow-BudReq').removeClass('d-none');
					if (ApproverList != null && ApproverList != undefined) {
						ApproverList.map((val, index) => {
							if (index == 0) {
								let localArray1 = {
									value: 0,
									label: t("--------Select Approver-------")
								}
								ApproveValue.push(localArray1)
								if (ExistApprover.length == 0 || ApproverList.indexOf(ExistApprover[0].ISA_IOL_APR_EMP_ID) == -1) {
									ExistApp = localArray1
									$('#removeApprover').addClass('hide');
								}
							}
							let localArray = {
								value: val.ISA_EMPLOYEE_ID,
								label: val.EMP_TEXT
							}
							if (ExistApprover.length > 0) {
								if (val.ISA_EMPLOYEE_ID == ExistApprover[0].ISA_IOL_APR_EMP_ID) {
									ExistApp = localArray
									LimitValue = ExistApprover[0].ISA_IOL_APR_LIMIT
									ExistBudApprL = ExistApprover[0].ISA_IOL_APR_EMP_ID
									$('#BUdLimitData').val(LimitValue)
									//[8/10/22, Bug - 2349, PS_UP_03, Dhevanesam R]
									$('#removeApprover').removeClass('hide');
								}
							}
							ApproveValue.push(localArray)
						});
					}
					let user = "";
					let userLimit = "";
					let approver = "";
					let approverLimit = "";
					let popupFlag = true;
					if (Tabdetails.ApproverData != null && Tabdetails.ApproverData.length > 0) {
						user = Tabdetails.ApproverData[0].CURRENTUSER;
						approver = Tabdetails.ApproverData[0].NEXTAPPROVER;
						userLimit = Tabdetails.ApproverData[0].CURRENTUSERLIMIT;
						approverLimit = Tabdetails.ApproverData[0].NEXTAPPROVERLIMIT;
					}
					else {
						popupFlag = false;
					}
					//document.getElementById('ReqBtn').innerText = (t("Set Requestor Approval"))
					if (Tabdetails.BudApproverData != null && Tabdetails.BudApproverData.length > 0) {
						$('#altBudApproval').removeClass('hide');
					} else {
						$('#altBudApproval').addClass('hide');
					}
					//$('#BudLimitDiv').removeClass('hide');
					//$('#BudBtn').removeClass('hide');
					//let Buttype = document.getElementById('ReqBtn').innerText
					//if (Buttype == "Set Requestor Approval") {
					//    document.getElementById('ReqBtn').innerText = "Set Budgetary Approval"
					//    $('#BudLimitDiv').addClass('hide');
					//}
					//else {
					//    document.getElementById('ReqBtn').innerText = "Set Requestor Approval"
					//    $('#BudLimitDiv').removeClass('hide');
					//}
					this.setState({
						ApproverList: ApproveValue, ExistApproveValue: ExistApp, ExistAppLimit: LimitValue, initialBudAppLimit: LimitValue, ExistBudAppr: ExistBudApprL, ApprovLabelVal: "Budgetary Approval by", ApprovBtnTxt: "Set Requestor Approval",
						CurrentUserName: user, CurrentUserLimit: userLimit, NextApprover: approver, NextApproverLimit: approverLimit, ApproverPopupFlag: popupFlag
					})
				}
				else {
					//$('#BudLimitDiv').addClass('hide');
					//$('#BudBtn').addClass('hide');
					//document.getElementById("ApprLabel").innerText = (t("Alternate Requestor Approval by"))
					let ApproverList = Tabdetails.ReqApproverDD;
					let ExistApprover = [];
					let ExistReqApprL = "";
					ExistApprover = Tabdetails.ExistReqApprover
					let ApproveValue = []
					let ExistApp = []
					$('#budApproval').addClass('hide');
					let n = ['BudReq', 'AltReq', 'AltBud'];
					let len = 2
					while (len != -1) {
						let accElement = document.getElementById(`arrow-${n[len]}`);
						let classTest = accElement.classList.contains("accordion-arrow-change");
						if (classTest) {
							$(`#arrow-${n[len]}`).toggleClass('accordion-arrow-change');
							$(`#contentrow-${n[len]}`).addClass('d-none');
						}
						len--;
					}
					let AltReqElement = document.getElementById("arrow-AltReq");
					let classTest = AltReqElement.classList.contains("accordion-arrow-change");
					if (!classTest) {
						$('#arrow-AltReq').toggleClass('accordion-arrow-change');
					}
					$('#contentrow-AltReq').removeClass('d-none');
					if (ApproverList != null && ApproverList != undefined) {
						ApproverList.map((val, index) => {
							if (index == 0) {
								let localArray1 = {
									value: 0,
									label: t("--------Select Approver-------")
								}
								ApproveValue.push(localArray1)
								if (ExistApprover.length == 0 || ApproverList.indexOf(ExistApprover[0].ISA_REQ_APR_ALT) == -1) {
									ExistApp = localArray1
									$('#removeApprover').addClass('hide');
								}
							}
							let localArray = {
								value: val.ISA_EMPLOYEE_ID,
								label: val.EMP_TEXT
							}
							if (ExistApprover.length > 0) {
								if (val.ISA_EMPLOYEE_ID == ExistApprover[0].ISA_REQ_APR_ALT) {
									ExistApp = localArray
									ExistReqApprL = ExistApprover[0].ISA_REQ_APR_ALT
									//[8/10/22, Bug - 2349, PS_UP_03, Dhevanesam R]
									$('#removeApprover').removeClass('hide');
								}
							}
							ApproveValue.push(localArray)
						});
					}
					let user = "";
					let userLimit = "";
					let approver = "";
					let approverLimit = "";
					let popupFlag = true;
					if (Tabdetails.ApproverData != null && Tabdetails.ApproverData.length > 0) {
						user = Tabdetails.ApproverData[0].CURRENTUSER;
						approver = Tabdetails.ApproverData[0].NEXTAPPROVER;
						userLimit = Tabdetails.ApproverData[0].CURRENTUSERLIMIT;
						approverLimit = Tabdetails.ApproverData[0].NEXTAPPROVERLIMIT;
					}
					else {
						popupFlag = false;
					}
					if (Tabdetails.BudApproverData != null && Tabdetails.BudApproverData.length > 0) {
						$('#altBudApproval').removeClass('hide');
					} else {
						$('#altBudApproval').addClass('hide');
					}
					this.setState({
						ApproverList: ApproveValue, ExistApproveValue: ExistApp, ExistReqAppr: ExistReqApprL, ApprovLabelVal: "Alternate Requestor Approval by",
						CurrentUserName: user, CurrentUserLimit: userLimit, NextApprover: approver, NextApproverLimit: approverLimit, ApproverPopupFlag: popupFlag
					})
				}
				break;
			}
            case "OSE": {
                //Added the ASN and ready for Picking - Dhinesh R(Zeus-35)//
				document.getElementById("chbCRE").checked = false;
				document.getElementById("chbAPR").checked = false;
				document.getElementById("chbDLF").checked = false;
				document.getElementById("chbCNC").checked = false;
				document.getElementById("chbRCP").checked = false;
				document.getElementById("chbRCF").checked = false;
				document.getElementById("chbRFC").checked = false;
				document.getElementById("chbRFR").checked = false;
				document.getElementById("chbRFA").checked = false;
				document.getElementById("chbQTR").checked = false;
				document.getElementById("chbQTA").checked = false;
				document.getElementById("chbVND").checked = false;
				document.getElementById("chbCST").checked = false;
				document.getElementById("chbQTS").checked = false;
				document.getElementById("chbQTC").checked = false;
                document.getElementById("chbQTW").checked = false;
                document.getElementById("chbPKA").checked = false;
                document.getElementById("chbASN").checked = false;
				let HasTable = Tabdetails.Hashtable
				let RoleExist = Tabdetails.ROleExist
				//if (RoleExist[0].ROleExist == "FALSE") {
				if (HasTable != null && HasTable != undefined) {
					if (HasTable.length > 0) {
						HasTable.map((val, index) => {
							switch (val.Key) {
								case "EMAILCRE01": {
									document.getElementById("chbCRE").checked = true;
									break;
								}
								case "EMAILQTW02": {
									document.getElementById("chbQTW").checked = true;
									break;
								}
								case "EMAILQTC03": {
									document.getElementById("chbQTC").checked = true;
									break;
								}
								case "EMAILQTS04": {
									document.getElementById("chbQTS").checked = true;
									break;
								}
								case "EMAILCST05": {
									document.getElementById("chbCST").checked = true;
									break;
								}
								case "EMAILVND06": {
									document.getElementById("chbVND").checked = true;
									break;
								}
								case "EMAILAPR07": {
									document.getElementById("chbAPR").checked = true;
									break;
								}
								case "EMAILQTA08": {
									document.getElementById("chbQTA").checked = true;
									break;
								}
								case "EMAILQTR09": {
									document.getElementById("chbQTR").checked = true;
									break;
								}
								case "EMAILRFA10": {
									document.getElementById("chbRFA").checked = true;
									break;
								}
								case "EMAILRFR11": {
									document.getElementById("chbRFR").checked = true;
									break;
								}
								case "EMAILRFC12": {
									document.getElementById("chbRFC").checked = true;
									break;
								}
								case "EMAILRCF13": {
									document.getElementById("chbRCF").checked = true;
									break;
								}
								case "EMAILRCP14": {
									document.getElementById("chbRCP").checked = true;
									break;
								}
								case "EMAILCNC15": {
									document.getElementById("chbCNC").checked = true;
									break;
								}
								case "EMAILDLF16": {
									document.getElementById("chbDLF").checked = true;
									break;
                                }
                                case "EMAILPKA17": {
                                    document.getElementById("chbPKA").checked = true;
                                    break;
                                }
                                case "EMAILASN18": {
                                    document.getElementById("chbASN").checked = true;
                                    break;
                                }
							}
						});
						//$("#OSESubmitBtn").attr("disabled", "false");
					}
				}
				if (RoleExist[0].ROleExist != "FALSE") {
					//$("#OSESubmitBtn").attr("disabled", "true");
					//$('#OSESubmitBtn').add()

					this.setState({ RoleExist: RoleExist[0].ROleExist })
				}
				break;
			}
			//[4/13/22, Bug - 2047 & 2048, Dhevanesam R
			case "PREF": {
				let ShiptoList = Tabdetails.ShipToTable
				let defaultShip = []
				defaultShip = Tabdetails.Default_ShipTo
				let DepartList = []
				let PrefTable = []
				let DepartDefault = []
				let DepartListvalue = []
				PrefTable = Tabdetails.PreferenceTable
				$('#DepartPrefDiv').removeClass('hide');

				let userDefAddress = "";
				let street = "";
				let state = "";
				let city = "";
				let country = "";
				let postal = "";
				let apartmentNo = "";
				let existAddress = false;
				debugger
				if (Tabdetails.UserAddress != undefined && Tabdetails.UserAddress != null) {
                    if (Tabdetails.UserAddress.length > 0) {
                        let concateApartmentNo = Tabdetails.UserAddress[0].ADDRESS2 != null && Tabdetails.UserAddress[0].ADDRESS2.trim() != "" ? ", " + Tabdetails.UserAddress[0].ADDRESS2 : "";
                        userDefAddress = Tabdetails.UserAddress[0].STREET + concateApartmentNo + ", " + Tabdetails.UserAddress[0].CITY + ", " + Tabdetails.UserAddress[0].STATE + ", " + Tabdetails.UserAddress[0].POSTAL + ", ";
                        userDefAddress = userDefAddress + (Tabdetails.UserAddress[0].COUNTRY.toLowerCase() == "us" ? "USA" : Tabdetails.UserAddress[0].COUNTRY.toLowerCase() == "ca" ? "CAN" : Tabdetails.UserAddress[0].COUNTRY.toLowerCase() == "mx" ? "MEX" : Tabdetails.UserAddress[0].COUNTRY.toUpperCase()) + ".";
                        street = Tabdetails.UserAddress[0].STREET;
                        city = Tabdetails.UserAddress[0].CITY;
                        state = Tabdetails.UserAddress[0].STATE;
                        postal = Tabdetails.UserAddress[0].POSTAL;
                        country = Tabdetails.UserAddress[0].COUNTRY.toLowerCase() == "us" ? "USA" : Tabdetails.UserAddress[0].COUNTRY.toLowerCase() == "ca" ? "CAN" : Tabdetails.UserAddress[0].COUNTRY.toLowerCase() == "mx" ? "MEX" : Tabdetails.UserAddress[0].COUNTRY.toUpperCase();
                        apartmentNo = Tabdetails.UserAddress[0].ADDRESS2 != null && Tabdetails.UserAddress[0].ADDRESS2.trim() != "" ? Tabdetails.UserAddress[0].ADDRESS2 : "";
                        existAddress = true;
                    }
				}

				if (this.state.UserType.toUpperCase() == "S") {
					DepartList = Tabdetails.DepartList
					if (DepartList != null && DepartList != undefined) {
						if (DepartList.length > 0) {
							DepartList.map((val, index) => {
								if (index == 0) {
									let localArray1 = {
										value: "",
										label: t("-----Select Department-----")
									}
									DepartListvalue.push(localArray1)
									if (val.DEPT_ID != PrefTable[0].ISA_DEPT) {
										DepartDefault = localArray1
									}
								}
								let localArray = {
									value: val.DEPT_ID,
									label: val.DEPT_NAME
								}
								if (val.DEPT_ID == PrefTable[0].ISA_DEPT) {
									DepartDefault = localArray
								}
								DepartListvalue.push(localArray)
							});
						}
					}
				}
				else {
					$('#DepartPrefDiv').addClass('hide');
				}
				{/*[02/21/22, CAS - 31, Dhevanesam R]*/ }
				let defaultShipto = [];
				let shiptolistValue = [];
				let preferredHomeStore = { value: "", label: "--Select home store--" };
				let homeStoreDD = [{ value: "", label: "--Select home store--" }];
				if (ShiptoList) {
					ShiptoList.map((val, index) => {
						if (index == 0) {
							let localArray1 = {
								value: "",
								label: t("Select Ship To")
							}
							shiptolistValue.push(localArray1)
							if (val.CUSTID != PrefTable[0].SHIPTO_DEFAULT) {
								defaultShipto = localArray1
							}
						}
						let localArray = {
							value: val.CUSTID,
							label: val.LOCNAME
						}
						if (val.CUSTID == PrefTable[0].SHIPTO_DEFAULT) {
							defaultShipto = localArray
						}
						shiptolistValue.push(localArray)
					});

					ShiptoList.map((val, index) => {

						let localArrayHomeStore = {
							value: val.CUSTID,
							label: val.LOCNAME
						}
						if (PrefTable[0].DefaultHomeStore != undefined && PrefTable[0].DefaultHomeStore != null) {
							if (val.CUSTID == PrefTable[0].DefaultHomeStore) {
								preferredHomeStore = localArrayHomeStore
							}
						}

						homeStoreDD.push(localArrayHomeStore)
					});
				}
				this.setState({
					DepartmentPref: DepartDefault, DepartPref: DepartListvalue, ShiptoList: shiptolistValue,
					Shipto: defaultShipto,
					existStreet: street,
					existState: state,
					existCity: city,
					existCountry: country,
					existPostal: postal,
					existApartmentNo: apartmentNo,
					isExistingAddress: existAddress,
					HomeStore: preferredHomeStore,
					HomeStoreDD: homeStoreDD
				})
				if (PrefTable[0].ISA_PROD_DISPLAY != null) {
					if (PrefTable[0].ISA_PROD_DISPLAY == "P") {
						document.getElementById("ClientDesp").checked = true;
						document.getElementById("CataDesp").checked = false;
					}
					else {
						document.getElementById("CataDesp").checked = true;
						document.getElementById("ClientDesp").checked = false;
					}
				}
				else {
					document.getElementById("CataDesp").checked = true;
					document.getElementById("ClientDesp").checked = false;
				}
				if (PrefTable[0].ISA_PRICE_BLOCK != null && PrefTable[0].ISA_PRICE_BLOCK != "" && PrefTable[0].ISA_PRICE_BLOCK != " ") {
					document.getElementById("PriceDisplay").checked = true;
				}

				document.getElementById("PriceDisplay").disabled = false
				if (this.state.LoginUserRole.toUpperCase() != "SUPER") {
					document.getElementById("PriceDisplay").disabled = true
				}
				this.buildChargeCode(Tabdetails)

				this.setState({ userAddress: userDefAddress });
				break;
			}
			case "MOB": {
				let CribListdata = Tabdetails.GribTable
				let ExistCrib = Tabdetails.GribAlreadyExist
				let DefaultCrib = []
				let CribListValue = []
				const { t } = this.props
				//document.getElementById('CribValidate').innerText = ""
				//[4/27/22, Bug - 2071, Dhevanesam R
				let StatusMsgErrCrib = "";
				CribListdata.map((val, index) => {
					if (index == 0) {
						let DefaultValue = {
							value: "",
							label: t("Select crib")
						}
						DefaultCrib = DefaultValue
						CribListValue.push(DefaultValue)
					}
					let localArray = {
						value: val.BUUNIT,
						label: val.BUUNIT
					}
					if (val.BUUNIT == ExistCrib[0].GribAlreadyExist) {
						DefaultCrib = localArray
					}
					CribListValue.push(localArray)
				});
				//[4/27/22, Bug - 2071, Dhevanesam R
				this.setState({
					CribValue: DefaultCrib,
					CribList: CribListValue,
					StatusMsgErrValCrib: StatusMsgErrCrib
				})
				break;
			}
			case "ZEUS": {
				let ZeusStatus = Tabdetails.ZeusTable
				let ZeusLabel = ZeusStatus[0].Zeus_Disabled != null ? ZeusStatus[0].Zeus_Disabled : ZeusStatus[0].Zeus_Enabled
				this.setState({ ZeusTitle: ZeusLabel })
				break;
			}
			//[5/10/22, CAS - 31, Dhevanesam R]
			case "TST": {
				let TSTValue = Tabdetails.ADDUserFormTable
				let TSTValueUser = Tabdetails.SDITrackDetails
				let EmployeeList = Tabdetails.EmployeeList
				let EmployeeValue = []
				let EmployeeListData = []
				EmployeeList.map((val, index) => {
					let localArray = {
						value: val.ISA_EMPLOYEE_ID,
						label: val.USERANDBU
					}
					if (val.ISA_EMPLOYEE_ID == TSTValue[0].OtherUserID) {
						EmployeeValue.push(localArray)
					}
					EmployeeListData.push(localArray)
				});
				this.setState({
					TSTEmployeeValue: EmployeeListData,
					TSTDefaultValue: EmployeeValue
				})
				let TSTUserIDValue = TSTValueUser[0].SDITrack_UserID
				let ErrorMessage = ""
				let TSTError = ""
				if (TSTValue[0].OtherUserBUMessage != null || TSTValue[0].OtherUserNoBU != null) {
					ErrorMessage = TSTValue[0].OtherUserBUMessage != null ? TSTValue[0].OtherUserBUMessage : TSTValue[0].OtherUserNoBU
					//document.getElementById("TSTError").innerText = (t(ErrorMessage));
					TSTError = ErrorMessage
					$('#AddTSTUserDiv').addClass('hide');
					$('#AddUserDiv').addClass('hide');
				}
				document.getElementById("TSTUserName").innerText = TSTValue[0].OtherUserID;
				document.getElementById("TSTUSERID1").innerText = TSTUserIDValue;
				document.getElementById("TSTuserBu").innerText = TSTValue[0].BusinessUnit;
				document.getElementById("TSTCurentUser").innerText = TSTValueUser[0].Current_User;
				document.getElementById("LoginUserId").innerText = TSTValueUser[0].SDITrack_UserID;
				document.getElementById("LoginUserADDOn").innerText = TSTValueUser[0].AddedUserDate;
				document.getElementById("LoginUserGuid").innerText = TSTValueUser[0].AddedUserGUID;
				$('#ADDEDUSERDiv').addClass('hide')
				this.setState({
					TSTUserID: TSTUserIDValue, TSTErrorVal: TSTError
				})
				break;
			}
		}
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
	buildChargeCode = (GetchargeCOdeBO) => {
		debugger
		console.log("GetchargeCOdeBO", GetchargeCOdeBO)
		let ChargeCOdeTable = GetchargeCOdeBO.ChargeCOdeTable
		let ChargeCOdeType = GetchargeCOdeBO.ChargeCOdeType
		if (ChargeCOdeType != null && ChargeCOdeType != undefined) {
			if (ChargeCOdeType.length > 0) {
				$('#Chargelabel').removeClass('d-none')
			}
		}
		let PlaceDD1 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.PlaceDD1, ChargeCOdeTable[0].PlaceDD1Value) : ""
		console.log("PlaceDD1", PlaceDD1)
		let PlaceDD2 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.PlaceDD2, ChargeCOdeTable[0].PlaceDD2Value) : ""
		let PlaceDD3 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.PlaceDD3, ChargeCOdeTable[0].PlaceDD3Value) : ""
		let dropCD1Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD1Seg, ChargeCOdeTable[0].dropCD1SegValue) : ""
		let dropCD2Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD2Seg, ChargeCOdeTable[0].dropCD2SegValue) : ""
		let dropCD3Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD3Seg, ChargeCOdeTable[0].dropCD3SegValue) : ""
		let dropEmpSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropEmpSeg, ChargeCOdeTable[0].dropEmpSegValue) : ""
		let dropItmSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropItmSeg, ChargeCOdeTable[0].dropItmSegValue) : ""
		let dropMchSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropMchSeg, ChargeCOdeTable[0].dropMchSegValue) : ""
		if (ChargeCOdeTable[0].dropCD1Seg_Visible == "True" && ChargeCOdeTable[0].dropCD2Seg_Visible == "True") {
			ChargeCOdeTable[0].dropCD3Seg_Visible = "False"
		}
		if (ChargeCOdeTable[0].PlaceDD1_Visible == "True" && ChargeCOdeTable[0].PlaceDD2_Visible == "True") {
			ChargeCOdeTable[0].PlaceDD3_Visible = "False"
		}
		this.setState({
			PlaceDD1Value: PlaceDD1.SelectedValue, PlaceDD1Data: PlaceDD1.ReturnTable,
			PlaceDD2Value: PlaceDD2.SelectedValue, PlaceDD2Data: PlaceDD2.ReturnTable,
			PlaceDD3Value: PlaceDD3.SelectedValue, PlaceDD3Data: PlaceDD3.ReturnTable,
			dropCD1SegValue: dropCD1Seg.SelectedValue, dropCD1SegData: dropCD1Seg.ReturnTable,
			dropCD2SegValue: dropCD2Seg.SelectedValue, dropCD2SegData: dropCD2Seg.ReturnTable,
			dropCD3SegValue: dropCD3Seg.SelectedValue, dropCD3SegData: dropCD3Seg.ReturnTable,
			dropEmpSegValue: dropEmpSeg.SelectedValue, dropEmpSegData: dropEmpSeg.ReturnTable,
			dropItmSegValue: dropItmSeg.SelectedValue, dropItmSegData: dropItmSeg.ReturnTable,
			dropMchSegValue: dropMchSeg.SelectedValue, dropMchSegData: dropMchSeg.ReturnTable,
			CCPlaceDD1Title: ChargeCOdeTable[0].PlaceDD1, CCPlaceDD2Title: ChargeCOdeTable[0].PlaceDD2, CCPlaceDD3Title: ChargeCOdeTable[0].PlaceDD3,
			CCdropCD1SegTitle: ChargeCOdeTable[0].dropCD1Seg, CCdropCD2SegTitle: ChargeCOdeTable[0].dropCD2Seg, CCdropCD3SegTitle: ChargeCOdeTable[0].dropCD3Seg,
			CCdropEmpSegTitle: ChargeCOdeTable[0].dropEmpSeg, CCdropItmSegTitle: ChargeCOdeTable[0].dropItmSeg, CCdropMchSegTitle: ChargeCOdeTable[0].dropMchSeg,
			dropCD1SegVisible: ChargeCOdeTable[0].dropCD1Seg_Visible == "True" ? true : false,
			dropCD2SegVisible: ChargeCOdeTable[0].dropCD2Seg_Visible == "True" ? true : false,
			dropCD3SegVisible: ChargeCOdeTable[0].dropCD3Seg_Visible == "True" ? true : false,
			PlaceDD1Visible: ChargeCOdeTable[0].PlaceDD1_Visible == "True" ? true : false,
			PlaceDD2Visible: ChargeCOdeTable[0].PlaceDD2_Visible == "True" ? true : false,
			PlaceDD3Visible: ChargeCOdeTable[0].PlaceDD3_Visible == "True" ? true : false,
			dropEmpSegVisible: ChargeCOdeTable[0].dropEmpSeg_Visible == "True" ? true : false,
			dropItmSegVisible: ChargeCOdeTable[0].dropItmSeg_Visible == "True" ? true : false,
			dropMchSegVisible: ChargeCOdeTable[0].dropMchSeg_Visible == "True" ? true : false,
		})


	}
	buildDropDownValue = (DropDownValue, DefaultValue) => {
		debugger
		//[5/10/22, CAS - 31, Dhevanesam R]
		const { t } = this.props
		let ReturnTable = []
		let SelectedValue = []
		if (DropDownValue != null && DropDownValue != undefined) {
			if (DropDownValue.length > 0) {
				let defaulttype = "ISA_CHGCD_USR1_SEG"
				DropDownValue.map((val, index) => {

					if (index == 0) {
						if (val.ISA_CHGCD_USR2_SEG != undefined) {
							defaulttype = "ISA_CHGCD_USR2_SEG"
						}
						else if (val.ISA_CHGCD_USR3_SEG != undefined) {
							defaulttype = "ISA_CHGCD_USR3_SEG"
						}
						else if (val.ISA_CUST_CHARGE_CD != undefined) {
							defaulttype = "ISA_CUST_CHARGE_CD"
						}
						else if (val.ISA_CHGCD_ITEM_SEG != undefined) {
							defaulttype = "ISA_CHGCD_ITEM_SEG"
						}
						console.log("defaulttype", defaulttype)
						let defaultvalue = {
							value: "",
							label: t("--Select Charge Code--")
						}
						ReturnTable.push(defaultvalue)
					}
					let localArray = {
						value: val.SEQ,
						label: val[defaulttype]
					}
					if (val.SEQ == DefaultValue) {
						SelectedValue = localArray
					}
					ReturnTable.push(localArray)
				});
			}
		}
		let ReturnTableValue = {
			ReturnTable: ReturnTable,
			SelectedValue: SelectedValue
		}
		return (ReturnTableValue)
	}
	handleChangePlaceDD1 = (selectedOption, Title) => {
		debugger
		let ChargeSelected = this.state.ChargeSelected
		if (ChargeSelected == null || ChargeSelected == "" || ChargeSelected == undefined) {
			ChargeSelected = selectedOption.value
		}
		else {
			ChargeSelected = ChargeSelected + "-" + selectedOption.value
		}
		this.setState({ [Title]: selectedOption, ChargeSelected: ChargeSelected });
		console.log(`Option selected:`, selectedOption);

		let dropCD1SegValue = this.state.dropCD1SegValue.value == undefined ? "" : this.state.dropCD1SegValue.value
		let dropCD2SegValue = this.state.dropCD2SegValue.value == undefined ? "" : this.state.dropCD2SegValue.value
		let dropCD3SegValue = this.state.dropCD3SegValue.value == undefined ? "" : this.state.dropCD3SegValue.value
		let dropCD1SegFilled = this.state.dropCD1SegValue.value == undefined ? false : true
		let dropCD2SegFilled = this.state.dropCD2SegValue.value == undefined ? false : true
		if (Title == "dropCD1SegValue") {
			if (Title == "dropCD1SegValue") {
				dropCD1SegValue = selectedOption.value
			}
			else if (this.state.dropCD1SegValue != []) {
				dropCD1SegValue = this.state.dropCD1SegValue.value
			}
			dropCD1SegFilled = true
		}
		else if (Title == "dropCD2SegValue") {
			if (Title == "dropCD2SegValue") {
				dropCD2SegValue = selectedOption.value
			}
			else if (this.state.dropCD1SegValue != []) {
				dropCD2SegValue = this.state.dropCD2SegValue.value
			}
			dropCD2SegFilled = true
		}
		else if (Title == "dropCD3SegValue") {
			if (Title == "dropCD3SegValue") {
				dropCD3SegValue = selectedOption.value
			}
			else if (this.state.dropCD1SegValue != []) {
				dropCD3SegValue = this.state.dropCD3SegValue.value
			}
		}
		if (Title == "dropCD1SegValue") {
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
			let BuildChrCDBO = {
				UserId: this.state.Login_UserId,
				UserBU: this.state.Login_UserBU,
				dropCD1SegValue: dropCD1SegValue,
				dropCD2SegValue: dropCD2SegValue,
				dropCD3SegValue: dropCD3SegValue,
				dropCD1SegFilled: dropCD1SegFilled,
				dropCD2SegFilled: dropCD2SegFilled,
				dropCD1Seg_Visible: this.state.dropCD1SegVisible,
				dropCD2Seg_Visible: this.state.dropCD2SegVisible,
				dropCD3Seg_Visible: this.state.dropCD3SegVisible,
			}
			ProfileAction.GetchargeCOde(BuildChrCDBO)
		}
	};
	BuildChargeCOdeData = () => {
		let GetchargeCOdeBO = JSON.parse(ProfileStore.GetchargeCOdeBO);
		this.buildChargeCode(GetchargeCOdeBO)
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
    /*
    * Ref no:UP_PC_41
    * 
    * This method is called when the search user drop down selected value is changed
    * */
	handleChange = (values) => {
		this.setState(
			{ selectedOption: values })
		console.log('Option selected:', this.state.selectedOption);
		let UserROle = this.state.LoginUserRole
		if (this.state.LoginUserRole.toUpperCase() == "CORP ADMIN") {
			UserROle = "CORPADMIN"
		}
		let SearchBO = {
			Selected_UserID: values.value,
			Session_USERID: this.state.Login_UserId,
			Session_BUSUNIT: this.state.Login_UserBU,
			Session_UserRole: UserROle,
			Selected_UserType: this.state.LoginUserType,
			Session_ThirdParty_CompanyID: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID"),
			Query_IsMexicoVendor: false,
			Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
			Query_IsVendor: false,
			Session_APPRTYPE: CookieService.getLocalStorageValues("Session_APPRTYPE")
		}
		ProfileAction.GetSearchUsers(SearchBO);
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		//this.BuildSearch(values);
	};
    /*
    * Ref no:UP_PC_52
    * 
    * Bind the user details in the user profile form.
    * */
	BuildUserProfileDetails = () => {
		let SelectUserDetails = JSON.parse(ProfileStore.SelectUserBO);
		console.log("SelectUserDetails", SelectUserDetails)
		let FirstNameL = "";
		let lastNameL = "";
		let PhoneL = "";
		let DepartL = "";
		let userTypeL = "";
		let userRoleL = "";
		let HideuseridL = "";
		let EmailAddL = "";
		let Site = "";
		let SelectedUserIdL = "";
		let ActiveStatus = "";
		let MultiSiteEnableL = "";
		let GroupTable = [];
		let SiteList = [];
		let MultiSiteList = [];
		let MultiSiteChecked = [];
		let searchUserL = [];
		let DepartmentL = [];
		let UserDetails = [];
		let ThirdPartyListL = [];
		let VendorIdL = "";
		let VendorGroupL = "";
		let selectedOptionL = [];
		let SiteValue = "";
		let Departvalue = "";
		let SiteTitle = "";
		let btnActivateAccount = "";
		let btnInactivateAccount = "";
		let btnEmplInactivateAccount = "";
		let ShowActiveLabel = false;
		let ShowEmplyActiveLabel = false;
		let btnEmplActivateAccount = "";
		let ShowEmplLabel = "";
		let ShowAcctLabel = "";
		let SelectedThirdPartyL = "";
		let GroupIdL = "";
		UserDetails = SelectUserDetails.UserProfileDeatils;
		let EmployeeActiveStatus = "";
		let ErrorTable = SelectUserDetails.Error_Table;
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		let EnterFirstNameErrMsg = "";
		let EnterLastNameErrMsg = "";
		let EnterPhoneNameErrMsg = "";
		let EnterEmailNameErrMsg = "";
		let EmpLabelErrMsg = "";
		let AcctLabelErrMsg = "";
		let InActEmpLblVal = "";
		let InActAccLblVal = "";

		const { t } = this.props
		if (ErrorTable != null && ErrorTable != undefined && ErrorTable.length > 0) {
			if (ErrorTable.length > 0) {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t(ErrorTable[0].UserExist)
				})
				//alert(t(ErrorTable[0].UserExist))
			}
		}
		else {
			if (UserDetails.length > 0) {
				if (UserDetails.length > 0) {
					SelectedUserIdL = UserDetails[0].Employee_ID;
					SelectedThirdPartyL = UserDetails[0].ThirdParty_Id;
					userTypeL = UserDetails[0].UserType;
					userRoleL = UserDetails[0].UserRole;
					FirstNameL = UserDetails[0].First_Name;
					lastNameL = UserDetails[0].Last_Name;
					PhoneL = UserDetails[0].Phone_No;
					//PC_PCpP_02 - Bug 2036 - User not able to edit phone num with hyphen so restricting it here - Poornima S 4/7/2022
					if (PhoneL.includes('-')) {
						PhoneL = PhoneL.replace(/-/g, '');
					}
					EmailAddL = UserDetails[0].Email_Address;
					DepartL = UserDetails[0].Departement;
					HideuseridL = UserDetails[0].Hide_User_ID;
					ActiveStatus = UserDetails[0].active_status;
					EmployeeActiveStatus = UserDetails[0].Employee_active_status;
					Site = UserDetails[0].Businees_Unit;
					MultiSiteEnableL = UserDetails[0].Enable_MultiSite;
					VendorIdL = UserDetails[0].MainvndrID;
					btnActivateAccount = UserDetails[0].btnActivateAccount;
					btnInactivateAccount = UserDetails[0].btnInactivateAccount;
					btnEmplInactivateAccount = UserDetails[0].btnEmplInactivateAccount;
					btnEmplActivateAccount = UserDetails[0].btnEmplActivateAccount;
					ShowEmplLabel = UserDetails[0].lblEmplAccountDisabled;
					ShowAcctLabel = UserDetails[0].lblAccountDisabled;
					VendorGroupL = UserDetails[0]['Vendor Name'];
					ThirdPartyListL = SelectUserDetails.ThirdPartytable;
					SiteList = SelectUserDetails.SisterSiteTable;
					MultiSiteList = SelectUserDetails.Multisite;
					MultiSiteChecked = SelectUserDetails.MultiSite_Checked;
					DepartmentL = SelectUserDetails.DepartmentTable;
					GroupTable = SelectUserDetails.GroupTable;
					ShowActiveLabel = UserDetails[0].lblAccountVisible;
					ShowEmplyActiveLabel = UserDetails[0].lblEmplAccountVisible;
					//if (GroupTable.length > 0) {
					SiteTitle = UserDetails[0].txtGroup;
					GroupIdL = UserDetails[0].txtGroupID;
					//}
					//PC_PCpP_01 - Bug 2035 - Validation msg displayed even after changing user - Poornima S 4/7/2022
					//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
					if (FirstNameL.trim() == "") {
						//document.getElementById("ErrorMagFirstName").innerHTML = (t("Please enter first Name"));
						EnterFirstNameErrMsg = "Please enter first Name";
					}
					else {
						//document.getElementById("ErrorMagFirstName").innerHTML = "";
						EnterFirstNameErrMsg = "";
					}
					if (lastNameL.trim() == "") {
						EnterLastNameErrMsg = "Please enter last name";
					}
					else {
						EnterLastNameErrMsg = "";
					}
					if (PhoneL.trim() == "") {
						EnterPhoneNameErrMsg = "Please enter phone number";
					}
					else {
						EnterPhoneNameErrMsg = "";
					}
					if (EmailAddL.trim() == "") {
						EnterEmailNameErrMsg = "Please enter email address";
					}
					else {
						EnterEmailNameErrMsg = "";
					}
				}
				if (userTypeL != "S") {

					$('#DepartDiv').addClass('hide');

				}
				else {
					$('#DepartDiv').removeClass('hide');
				}
				if (VendorIdL == null) {
					VendorIdL = ""
				}
				else {
					$('#VendorGroupValue').removeClass('errorcolour');
					$('#VendorGroupValue').addClass('GreenColour');
				}
				let conditionvalue = "false"
				if (GroupTable != null && GroupTable != undefined) {
					if (GroupTable.length > 0) {
						GroupIdL = GroupTable[0].txtGroupID
					}
				}
				//$('#EmpyAcctBtn').removeClass('hide');
				//$('#AcctBtn').removeClass('hide');
				//$('#AcctLabel').removeClass('hide');
				//$('#EmpLabel').removeClass('hide');

				//if (btnEmplActivateAccount.toUpperCase() == conditionvalue.toUpperCase() && btnEmplInactivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
				//    $('#EmpyAcctBtn').addClass('hide');
				//}
				//else {
				if (btnEmplActivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
					InActEmpLblVal = "In-Activate Employee Account"
				}
				else {
					InActEmpLblVal = "Activate Employee Account"
				}
				//}
				//if (btnActivateAccount.toUpperCase() == conditionvalue.toUpperCase() && btnInactivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
				//    $('#AcctBtn').addClass('hide');
				//}
				//else {
				if (btnActivateAccount.toUpperCase() == conditionvalue.toUpperCase()) {
					InActAccLblVal = "In-Activate Account"
				}
				else {
					InActAccLblVal = "Activate Account"
				}
				//}
				//if (ShowAcctLabel.toUpperCase() == conditionvalue.toUpperCase()) {
				//    $('#AcctLabel').addClass('hide');
				//}
				//if (ShowAcctLabel != null && ShowAcctLabel != undefined && ShowAcctLabel != "") {
				//    document.getElementById("AcctLabel").innerText = ShowAcctLabel;
				//    $('#AcctLabel').removeClass('hide')
				//}
				//if (ShowEmplLabel.toUpperCase() == conditionvalue.toUpperCase()) {
				//    $('#EmpLabel').addClass('hide');
				//}
				//if (ShowEmplLabel != null && ShowEmplLabel != undefined && ShowEmplLabel != "") {
				//    document.getElementById("EmpLabel").innerText = ShowEmplLabel;
				//    $('#EmpLabel').removeClass('hide')
				//}
				//if (ShowEmpyAcct.toUpperCase() == conditionvalue.toUpperCase()) {
				//    $('#EmpyAcctBtn').addClass('hide');
				//}
				//if (ShowAcct.toUpperCase() == conditionvalue.toUpperCase()) {
				//    $('#AcctBtn').addClass('hide');
				//}
				if (ShowActiveLabel.toUpperCase() == conditionvalue.toUpperCase()) {
					$('#AcctLabel').addClass('hide');
				}
				else {
					AcctLabelErrMsg = ShowAcctLabel;
					$('#AcctLabel').removeClass('hide')
				}
				if (ShowEmplyActiveLabel.toUpperCase() == conditionvalue.toUpperCase()) {
					$('#EmpLabel').addClass('hide');
				}
				else {
					EmpLabelErrMsg = ShowEmplLabel;
					$('#EmpLabel').removeClass('hide')
				}
				let Usertypes = []
				if (userRoleL == "CORPADMIN") {
					userRoleL = "CORP ADMIN"
				}
				//[Zeus 130, 6/8/23] - Kishore S - Added supervisor role to customers
				if (userRoleL.toUpperCase() == "SUPER" || userRoleL.toUpperCase() == "CORP ADMIN") {
					Usertypes = [{ Role: "Super" }, { Role: "Corp Admin" }, { Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
				}
				else {
					Usertypes = [{ Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
				}
				if (this.state.LoginUserRole.toUpperCase() == "SUPER") {
					Usertypes = [{ Role: "Super" }, { Role: "Corp Admin" }, { Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
				}
				if (this.state.ChangePasswordEnable == "Y") {
					$('#PassDiv').addClass('hide');
					$('#ConfPassDiv').addClass('hide');
					$('#ChangePasBtn').removeClass('hide');
				}
				let UserROleLocal = []
				let UserRoleSelect = []

				console.log("SelectedThirdPartyL", SelectedThirdPartyL)
				let loginThirdpartyid = CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID")
				if (loginThirdpartyid == "0" || loginThirdpartyid == null) {
					if (ThirdPartyListL.length > 0 && userTypeL == "C" && (loginThirdpartyid == "0" || loginThirdpartyid == null)) {

						ThirdPartyListL.map((ThirdParty, index1) => {
							if (index1 == 0) {
								Usertypes.map((val, index2) => {
									let localArray = {
										value: val.Role + index2,
										label: val.Role,
										key: Site,
										Role: val.Role,
										Third: "0",
									}
									if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
										UserROleLocal.push(localArray)
									}
									if (this.state.SelectUsertype == "C" && val.Role != "Super") {
										UserROleLocal.push(localArray)
									}
									if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
										UserROleLocal.push(localArray)
									}
									if (val.Role.toUpperCase() == userRoleL) {
										UserRoleSelect = localArray
									}
								});
							}
							Usertypes.map((val, index3) => {
								let localArray = {
									value: ThirdParty.COMPANY_NAME + val.Role + index3,
									label: ThirdParty.COMPANY_NAME + "  " + val.Role,
									key: Site,
									Role: val.Role,
									Third: ThirdParty.THIRDPARTY_COMP_ID,
								}
								if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
									UserROleLocal.push(localArray)
								}
								if (this.state.SelectUsertype == "C" && val.Role != "Super" && val.Role != "Corp Admin") {
									UserROleLocal.push(localArray)
								}
								if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
									UserROleLocal.push(localArray)
								}
								if (val.Role.toUpperCase() == userRoleL && SelectedThirdPartyL == ThirdParty.THIRDPARTY_COMP_ID) {
									UserRoleSelect = localArray
								}
							});
						});
					}
					else {

						Usertypes.map((val, index2) => {
							let localArray = {
								value: val.Role + index2,
								label: val.Role,
								key: Site,
								Role: val.Role,
								Third: "0",
							}
							if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "C" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (val.Role.toUpperCase() == userRoleL) {
								UserRoleSelect = localArray
							}
						});
					}
				}
				else {

					let childItems = ThirdPartyListL.filter((item, index) => item.THIRDPARTY_COMP_ID == SelectedThirdPartyL);
					childItems.map((ThirdParty, index1) => {
						Usertypes.map((val, index2) => {
							let localArray = {
								value: ThirdParty.COMPANY_NAME + index2,
								label: ThirdParty.COMPANY_NAME + "  " + val.Role,
								key: Site,
								Role: val.Role,
								Third: ThirdParty.THIRDPARTY_COMP_ID,
							}
							if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "C" && val.Role != "Corp Admin" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (val.Role.toUpperCase() == userRoleL) {
								UserRoleSelect = localArray
							}
						});
					});
				}
				let MultiSlectList = []
				let MultiSelectValue = []
				MultiSiteCHeckB = false
				//MultiSiteCHeckB = MultiSiteEnableL.toUpperCase() == "Y" || MultiSiteEnableL.toLowerCase() == "y" ? true : false
				if (MultiSiteEnableL.toUpperCase() == "Y" || MultiSiteEnableL.toLowerCase() == "y") {
					//document.getElementById("MultiSelectUP").checked = true;
					MultiSiteCHeckB = true
					MultiSiteList.map((val, index) => {
						if (val.BUSINESS_UNIT == Site) {
							let localArray = {
								value: val.BUSINESS_UNIT,
								label: val.DESCRIPTION,
								disabled: true
							}
							SiteTitle = val.DESCRIPTION;
							MultiSlectList.push(localArray)
						}
						else {
							let localArray = {
								value: val.BUSINESS_UNIT,
								label: val.DESCRIPTION
							}
							MultiSlectList.push(localArray)
						}
					});

					MultiSiteChecked.map((val, index) => {
						debugger
						let ChildSiteValue = MultiSiteList.filter((item, index) => item.BUSINESS_UNIT == val.BUSINESS_UNIT);

						let localArray = {
							value: ChildSiteValue[0].BUSINESS_UNIT,
							label: ChildSiteValue[0].DESCRIPTION
						}
						MultiSelectValue.push(localArray)

					});
					$('#MultiSelectDD').removeClass('hide');
				}
				else {
					$('#MultiSelectDD').addClass('hide');
					//document.getElementById("MultiSelectUP").checked = false;
					MultiSiteEnableL = "N"
				}
				let SiteListDD = []
				console.log("Site", Site)
				if (this.state.SelectUsertype != "V") {
					SiteList.map((val, index) => {

						let localArray = {
							value: val.GROUPID,
							label: val.GROUPNAME
						}
						if (val.GROUPID == Site) {
							SiteValue = localArray
						}
						if (val.GROUPID != undefined && val.GROUPNAME != undefined) {
							SiteListDD.push(localArray)
						}
					});
					if (SiteListDD.length == 0) {
						SiteList.map((val, index) => {

							let localArray = {
								value: val.BUSINESS_UNIT,
								label: val.DESCRIPTION
							}
							if (val.BUSINESS_UNIT == Site) {
								SiteValue = localArray
							}
							SiteListDD.push(localArray)
						});
					}
				}
				else {
					let localArray = {
						value: UserDetails[0].Businees_Unit,
						label: UserDetails[0].Businees_Unit
					}
					SiteValue = localArray
					SiteListDD = this.state.SiteList
				}

				//[4/13/22, Bug - 2047 & 2048, Dhevanesam R
				let DepartmentLocal = []
				if (DepartmentL.length > 0) {
					DepartmentL.map((val, index) => {
						if (index == 0) {
							let localArray1 = {
								value: "",
								label: t("-----Select Department-----")
							}
							DepartmentLocal.push(localArray1)
							if (val.DEPT_ID != DepartL) {
								Departvalue = localArray1
							}
						}
						let localArray = {
							value: val.DEPT_ID,
							label: val.DEPT_NAME
						}
						if (val.DEPT_ID == DepartL) {
							Departvalue = localArray
						}
						DepartmentLocal.push(localArray)
					});
				}
				if (SiteTitle == null) {
					SiteTitle = ""
				}
				console.log("UserROleLocal", UserROleLocal)
				//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
				this.setState({
					SelectedUserId: SelectedUserIdL, FirstName: FirstNameL, LastName: lastNameL, PhoneNum: PhoneL, EmailAddres: EmailAddL, MultiSiteEnable: MultiSiteEnableL, MultiSiteExist: MultiSiteEnableL,
					VendorGroup: VendorGroupL, VendorId: VendorIdL, UserType: userTypeL, UserRole: userRoleL, DepartMent: Departvalue, HideuserId: HideuseridL,
					UserActiveStatus: ActiveStatus, EmployeeAccountActiveStatus: EmployeeActiveStatus, MultiSite: MultiSlectList, MultiSiteSelected: MultiSelectValue, Depart: DepartmentLocal, SiteList: SiteListDD,
					ThirdPartyList: ThirdPartyListL, SelectUserRole: UserRoleSelect, UserRoleDD: UserROleLocal, SiteTitleValue: SiteTitle, selectSiteID: Site, SelectSite: SiteValue,
					SelectedThirdParty: SelectedThirdPartyL, MultiSiteChecked: MultiSiteCHeckB, GroupID: GroupIdL, EnterFirstNameErr: EnterFirstNameErrMsg,
					EnterEmailNameErr: EnterEmailNameErrMsg, EnterLastNameErr: EnterLastNameErrMsg, EnterPhoneNameErr: EnterPhoneNameErrMsg, EmpLabelErr: EmpLabelErrMsg, AcctLabelErr: AcctLabelErrMsg, InActEmpLbl: InActEmpLblVal, InActAccLbl: InActAccLblVal,
					userProfileErr:""
				})
			}

		}
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
    /*
    * Ref no:UP_PC_204
    * This method is invoked when the Site value is changed
    * 
    * */
	handleChangeSite = SelectSite => {
		this.setState(
			{ SelectSite: SelectSite, selectSiteID: SelectSite.value },
			() => {
				console.log(`Option Site:`, this.state.SelectSite)
			}
		);
        //[10/11/23, Bug - 4277, Fix the enable site validation message displayed below the drop down, Johnprinto]
		if(this.state.ErrorMagSiteErr != "" && SelectSite.value.toUpperCase() != "SELECT"){
            let errorMSG = this.handleProfileValidation("SiteName");
            if(errorMSG == false){
                this.setState({userProfileErr: "", ErrorMagSiteErr :""});
            }else{
                this.setState({ ErrorMagSiteErr :""});
            }
        }else{
            if(SelectSite.value.toUpperCase() == "SELECT"){
                this.setState({ ErrorMagSiteErr :" ", userProfileErr: "Please fill all the required fields"});
            }
        }
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
        let ThirdPartyBo = {
			SiteValue: SelectSite.value,
			Session_ThirdParty_CompanyID: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID"),
			SelectedUserId: this.state.SelectedUserId,
			Page_Action: this.state.PageAction,
		}
		ProfileAction.GetThirdParty(ThirdPartyBo)
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
	};
    /*
    * Ref no:UP_PC_204
    * 
    * Bind the third party roles
    * */
	ShowThirdParty = () => {
		//[5/10/22, CAS - 31, Dhevanesam R]
		const { t } = this.props;
		let ThirdParty = JSON.parse(ProfileStore.LoadThirdParty);
		let DisableTypeDDL = false
		console.log("ThirdParty", ThirdParty)
		let MultiSiteList = []
		MultiSiteList = ThirdParty.Multisite
		let MultiSiteChecked = []
		MultiSiteChecked = ThirdParty.MultiSite_Checked
		let MultiSlectList = []
		//let SelectSiteValue = [];
		//SelectSiteValue = this.state.SelectSite;
		let Site = this.state.selectSiteID
		if (this.state.SiteList.length > 0) {
			Site = this.state.SelectSite.value
		}
		else {
			Site = this.state.GroupID
		}
		//if (SelectSiteValue.length == 0) {
		//    Site = this.state.selectSiteID
		//}
		//else {
		//    Site = SelectSiteValue.value
		//}
		let ThirdPartyListL = []
		ThirdPartyListL = ThirdParty.ThirdPartytable
		let Usertypes = []
		let UserROleLocal = []
		let UserRoleSelect = []
		let MultiSelectValue = []
		let SelectedThirdPartyL = CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID")
		//[Zeus 130, 6/8/23] - Kishore S - Added supervisor role to customers
		if ((this.state.UserRole.toUpperCase() == "SUPER" || this.state.UserRole.toUpperCase() == "CORP ADMIN") && this.state.PageAction != "ADD") {
			Usertypes = [{ Role: "Super" }, { Role: "Corp Admin" }, { Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
		}
		else {
			Usertypes = [{ Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
		}
		if (this.state.LoginUserRole.toUpperCase() == "SUPER" || (this.state.LoginUserType == "S" && this.state.LoginUserRole.toUpperCase() == "ADMIN")) {
			Usertypes = [{ Role: "Super" }, { Role: "Corp Admin" }, { Role: "Admin" }, { Role: "Supervisor" }, { Role: "User" }]
		}
		$('#MultiSelectDD').removeClass('hide');
		let UserRoleL = this.state.SelectUserRole
		if (SelectedThirdPartyL == "0" || SelectedThirdPartyL == null) {
			if (ThirdPartyListL.length > 0 && this.state.Customer == true && (SelectedThirdPartyL == "0" || SelectedThirdPartyL == null)) {

				ThirdPartyListL.map((ThirdParty, index1) => {
					if (index1 == 0) {
						Usertypes.map((val, index2) => {
							let localArray = {
								value: val.Role + index2,
								label: val.Role,
								key: Site,
								Role: val.Role,
								Third: "0",
							}
							if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "C" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Super" && val.Role != "Supervisor") {
								UserROleLocal.push(localArray)
							}
							//if (val.Role.toUpperCase() == this.state.UserRole) {
							//    UserRoleSelect = localArray
							//}
							if (UserRoleL.Role.toUpperCase() == val.Role.toUpperCase()) {
								UserRoleSelect = localArray
							}
							if ((UserRoleL.Role == "Super") && this.state.SelectUsertype == "C" && val.Role == "Corp Admin") {
								UserRoleSelect = localArray
							}
							if ((UserRoleL.Role == "Corp Admin") && this.state.SelectUsertype == "S" && val.Role == "Super") {
								UserRoleSelect = localArray
							}
							if ((UserRoleL.Role == "Corp Admin" || UserRoleL.Role == "Super") && this.state.SelectUsertype == "V" && val.Role == "Admin") {
								UserRoleSelect = localArray
							}
						});
					}
					Usertypes.map((val, index3) => {
						let localArray = {
							value: ThirdParty.COMPANY_NAME + val.Role + index3,
							label: ThirdParty.COMPANY_NAME + "  " + val.Role,
							key: Site,
							Role: val.Role,
							Third: ThirdParty.THIRDPARTY_COMP_ID,
						}
						if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
							UserROleLocal.push(localArray)
						}
						if (this.state.SelectUsertype == "C" && val.Role != "Super" && val.Role != "Corp Admin") {
							UserROleLocal.push(localArray)
						}
						if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
							UserROleLocal.push(localArray)
						}
						//if (val.Role.toUpperCase() == this.state.UserRole) {
						//    UserRoleSelect = localArray
						//}
					});
				});
			}
			else {

				Usertypes.map((val, index2) => {
					let localArray = {
						value: val.Role + index2,
						label: val.Role,
						key: Site,
						Role: val.Role,
						Third: "0",
					}
					if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "C" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					//if (val.Role.toUpperCase() == this.state.UserRole) {
					//    UserRoleSelect = localArray
					//}
					//if (this.state.Customer == true && this.state.UserRole == "SUPER") {

					//}
					if (UserRoleL.Role.toUpperCase() == val.Role.toUpperCase()) {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.Role == "Super") && this.state.SelectUsertype == "C" && val.Role == "Corp Admin") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.Role == "Corp Admin") && this.state.SelectUsertype == "S" && val.Role == "Super") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.Role == "Corp Admin" || UserRoleL.Role == "Super") && this.state.SelectUsertype == "V" && val.Role == "Admin") {
						UserRoleSelect = localArray
					}
				});
			}
		}
		else {

			let childItems = ThirdPartyListL.filter((item, index) => item.THIRDPARTY_COMP_ID == SelectedThirdPartyL);
			childItems.map((ThirdParty, index1) => {
				Usertypes.map((val, index2) => {
					let localArray = {
						value: ThirdParty.COMPANY_NAME + index2,
						label: ThirdParty.COMPANY_NAME + "  " + val.Role,
						key: Site,
						Role: val.Role,
						Third: ThirdParty.THIRDPARTY_COMP_ID,
					}
					if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "C" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					//if (val.Role.toUpperCase() == this.state.UserRole) {
					//    UserRoleSelect = localArray
					//}
					if (UserRoleL.Role.toUpperCase() == val.Role.toUpperCase()) {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.Role == "Super") && this.state.SelectUsertype == "C" && val.Role == "Corp Admin") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.Role == "Corp Admin") && this.state.SelectUsertype == "S" && val.Role == "Super") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.Role == "Corp Admin" || UserRoleL.Role == "Super") && this.state.SelectUsertype == "V" && val.Role == "Admin") {
						UserRoleSelect = localArray
					}
				});
			});
		}
		console.log("UserRoleSelect", UserRoleSelect)
		console.log("UserROleLocal", UserROleLocal)
		let MultisSIteCheckedvalue = false
		if (MultiSiteList.length > 0 && MultiSiteChecked.length > 0) {
			MultiSiteList.map((val, index) => {
				if (val.BUSINESS_UNIT == Site) {
					let localArray = {
						value: val.BUSINESS_UNIT,
						label: val.DESCRIPTION,
						disabled: true
					}
					MultiSlectList.push(localArray)
				}
				else {
					let localArray = {
						value: val.BUSINESS_UNIT,
						label: val.DESCRIPTION
					}
					MultiSlectList.push(localArray)
				}
			});
			MultisSIteCheckedvalue = true
			// document.getElementById("MultiSelectUP").checked = true;
			if (MultiSiteChecked.length > 0) {
				MultiSiteChecked.map((val, index) => {
					let ChildSiteValue = MultiSiteList.filter((item, index) => item.BUSINESS_UNIT == val.BUSINESS_UNIT);

					let localArray = {
						value: ChildSiteValue[0].BUSINESS_UNIT,
						label: ChildSiteValue[0].DESCRIPTION
					}
					MultiSelectValue.push(localArray)

				});
			}

		}
		else {

			//document.getElementById("MultiSelectUP").checked = false;
			$('#MultiSelectDD').addClass('hide');
		}
		//[5/10/22, CAS - 31, Dhevanesam R]
		let UserROleLocalArray = UserROleLocal
		if (this.state.PageAction == "ADD") {
			UserRoleSelect = {
				value: "Select",
				label: t("Select Type"),
				key: "",
				Role: "",
				Third: "",
			}
			UserROleLocalArray = []
			UserROleLocal.map((val, index2) => {
				debugger
				if (index2 == 0) {
					UserROleLocalArray.push(UserRoleSelect)
				}
				UserROleLocalArray.push(val)
			})
		}
		this.setState({
			MultiSite: MultiSlectList, MultiSiteSelected: MultiSelectValue, MultiSiteChecked: MultisSIteCheckedvalue,
			ThirdPartyList: ThirdPartyListL, SelectUserRole: UserRoleSelect, UserRoleDD: UserROleLocalArray, adduser: false, DisableTypeDD: DisableTypeDDL
		})
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
    /*
    * This method is invoke when the Site is selected in the Privileges tab.
    * */
	handleChangeTab2Site = SelectTab2Site => {
		this.setState(
			{ SelectTab2Site },
			() => console.log(`Option SiteTab2:`, this.state.SelectTab2Site)
		);
	};
    /*
    * This method is invoke when the Role is slected in the Privileges tab.
    * */
	handleChangeTab2Role = SelectROleList => {
		this.setState(
			{ SelectROleList },
			() => console.log(`Option Role:`, this.state.SelectROleList)
		);
		let selectrole = SelectROleList.value
		let GetPrivilegeRoleBO = {
			UserRoleSelected: selectrole
		}
		ProfileAction.GetPrivilegeRole(GetPrivilegeRoleBO)
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
	};
    /*
    * This method is invoke when the Department is slected in the User profile tab.
    * */
	handleChangeDepart = DepartMent => {
		this.setState(
			{ DepartMent },
			() => console.log(`Option Site:`, this.state.DepartMent)
		);
	};
    /*
    * This method is invoke when the Role is slected in the User profile tab.
    * */
	handleChangeUserROle = SelectUserRole => {
		this.setState(
			{ SelectUserRole },
			() => console.log(`Option role:`, this.state.SelectUserRole)
		);
		if (this.state.ErrorMegSiteErr == " " && SelectUserRole.value.toUpperCase() != "SELECT") {
			let errorMSG = this.handleProfileValidation("TypeName");
			if (errorMSG == false) {
				this.setState({ userProfileErr: "", ErrorMegSiteErr: "" });
			} else {
				this.setState({ ErrorMegSiteErr: "" });
			}
		} else {
			if (SelectUserRole.value.toUpperCase() == "SELECT") {
				this.setState({ ErrorMegSiteErr: " ", userProfileErr: "Please fill all the required fields" });
			}
		}

	};
    /*
    * This method is invoke when the MultiSite is slected in the user Profile tab.
    * */
	handleMultiSiteDD = (values) => {
		let selectedMulti = [];
		values.forEach((selected) => {
			selectedMulti.push({ BUSINESS_UNIT: selected.value });
		})
		this.setState({ MultiSiteSelected: values, selectedMultiSites: selectedMulti });
		console.log("Multisite", selectedMulti)
		console.log("MultisiteValue", values)
	}
    /*
    * This method is invoke when the Privilege is checked in the privileges tree in the privileges tab.
    * */
	OnCheckTree = checked => {

		this.setState(
			{ checked },
			() => console.log(`SelectTree:`, this.state.checked)
		);
		console.log("Checkedvale", checked)
	}
    /*
     * This method is invoke when the Approver is changed in the Approvals tab.
     * */
	handleChangeAppro = ExistApproveValue => {
		debugger
		const { t } = this.props
		//[8/10/22, Bug - 2349, PS_UP_04, Dhevanesam R]
		if (ExistApproveValue.value == 0) {
			this.setState({ ExistAppLimit: "", ExistApproveValue });
			$('#removeApprover').addClass('hide');
		}
		else {
			if (approvalAccordian == 2) {
				if (ExistApproveValue.value == this.state.ExistReqAppr) {
					$('#removeApprover').removeClass('hide');
					this.setState({ ExistAppLimit: this.state.initialBudAppLimit, ExistApproveValue });
				}
				else {
					this.setState({ ExistAppLimit: "", ExistApproveValue });

					$('#removeApprover').addClass('hide');
				}
			}
			else if (approvalAccordian == 3) {  //[09/05/22, PS_ZE_192_APP_02,Sathis]-Trigger on the click of alternate budgetary
				let updateAltBudgetary = [];
				if (ExistApproveValue.length < 11) {
					if (ExistApproveValue.length > 0) {
						updateAltBudgetary = ExistApproveValue;
					}
					this.setState({ selectedApprvValue: ExistApproveValue, updateAltBudgetary });
				}
				else {
					try {
						$.toast({
							text: 'You already selected the maximum number of approvers',
							position: {
								top: 150,
								bottom: 90,
								left: 650
							},
							stack: false,
							loader: false
						});
					} catch (ex) {
						console.log(ex)
					}
				}
			}
			else {
				this.setState({ ExistApproveValue });
			}
			//else {
			//    if (ExistApproveValue.value == this.state.ExistBudAppr) {
			//        $('#removeApprover').removeClass('hide');
			//        this.setState({ ExistAppLimit: this.state.initialBudAppLimit });
			//    }
			//    else {
			//        this.setState({ ExistAppLimit: "" });

			//        $('#removeApprover').addClass('hide');
			//    }
			//}
		}
	};
    /*
     * This method is invoke when the Ship to is changed in the Preference tab.
     * */
	handleChangeShipto = Shipto => {
		this.setState(
			{ Shipto },
			() => console.log(`Option Approver:`, this.state.Shipto)
		);
	};
    /*
     * This method is invoke when the Department is changed in the Preference tab.
     * */
	handleChangeDepartPref = DepartmentPref => {
		this.setState(
			{ DepartmentPref },
			() => console.log(`Option Approver:`, this.state.DepartmentPref)
		);
	};
    /*
    * This method is invoke when the Crib is changed.
    * */

	handleChangeHomeStore = HomeStore => {
		this.setState({ HomeStore });
	};

	handleChangeCrib = CribValue => {
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		let CribFieldErrMsg = "";
		this.setState(
			{ CribValue },
			() => console.log(`Option Approver:`, this.state.CribValue)
		);
		if (CribValue == "" || CribValue == null || CribValue == undefined) {
			CribFieldErrMsg = "Please select a crib";
		}
		else {
			CribFieldErrMsg = "";
		}
		this.setState({
			StatusMsgErrValCrib: CribFieldErrMsg
		})
	};
    /*
    * This method is invoke when the User is changed in the SDI Track.
    * */
	handleChangeEmplyee = TSTDefaultValue => {
		this.setState(
			{ TSTDefaultValue },
			() => console.log(`Option Approver:`, this.state.TSTDefaultValue)
		);
		let SelectedUserId = TSTDefaultValue.value
		if (SelectedUserId != "" && SelectedUserId != null) {
			let TSTSelectedBO = {
				SelectedUser: SelectedUserId
			}
			ProfileAction.GetSearchUserTrack(TSTSelectedBO)
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		}
	};
    /*
    * Ref no: UP_PC_40
    * 
    * This is event method invoke when user type radio is select changed
    * */
	UserTypeRadio = (UserType) => {
		debugger;
		if (UserType != "V") {
			$('#VendorDiv').addClass('hide');
			$('#MultiDiv').removeClass('hide');
		}
		if (UserType == "S") {
			$('#DepartDiv').removeClass('hide');
		}
		let SDIEMp = false
		let CustomerL = false
		let vendor = false
		if (UserType == "S") {
            SDIEMp = true
            //[12/26/23], Bug - 4284, Hided the Vendor ID div for SDI employee site, PC_UP_04, Harshitha G
            $('#VendorDiv').addClass('hide');
			CustomerL = false
			vendor = false
			document.getElementById("UtypeSDI").checked = true;
			document.getElementById("UtypeCustomer").checked = false;
			document.getElementById("UtypeVendor").checked = false;
		}
		else if (UserType == "C") {
            SDIEMp = false
            //[12/26/23], Bug - 4284, Hided the Vendor ID div for SDI employee site, PC_UP_04, Harshitha G
            $('#VendorDiv').addClass('hide');
			CustomerL = true
			vendor = false
			document.getElementById("UtypeSDI").checked = false;
			document.getElementById("UtypeCustomer").checked = true;
			document.getElementById("UtypeVendor").checked = false;
		}
		else {
			SDIEMp = false
            vendor = true
            //[12/26/23], Bug - 4284, Removed highlighting the Vendor ID in red color in Vendor site, PC_UP_02, Harshitha G
            $('#VendorID').removeClass('border-color-red');
            //[11/29/23, Regression-5 Bug (4285) - Added Vendor id - Harshitha G]
            $('#VendorDiv').removeClass('hide');
			CustomerL = false
			document.getElementById("UtypeSDI").checked = false;
			document.getElementById("UtypeCustomer").checked = false;
			document.getElementById("UtypeVendor").checked = true;
		}
		//[4/13/22, Bug - 2047 & 2048, Dhevanesam R
		if (this.state.PageAction == "ADD") {
			debugger
			const { t } = this.props;
			//[4/27/22, Bug - 2071, Dhevanesam R
			let StatusMsgError = "";
			document.getElementById("VendorGroupValue").innerText = "";
			document.getElementById("MultiSelectUP").checked = false;
			let DisableTypeDDL = this.state.SiteList.length > 0 ? true : false
			//[3/29/22, CAS - User Profile - Ph num error msg display issue, PC_UP_01, Dhevanesam R]
			document.getElementById("ErrorMagPhone").className = "DropDown-Font-Size errorcolour PhErrorCssDisplayNone";
			//if (this.state.SelectUsertype == "V") {
			//    SiteList = [{ GROUPID: "ISA00", GROUPNAME: "ISA00" }, { GROUPID: "SDM00", GROUPNAME: "SDM00" }, { GROUPID: "WAL00", GROUPNAME: "WAL00" }]
			//    $('#VendorDiv').removeClass('hide');
			//    $('#MultiDiv').addClass('hide');
			//    $('#DepartDiv').addClass('hide');
			//}
			//SiteList.map((val, index) => {

			//    let localArray = {
			//        value: val.GROUPID,
			//        label: val.GROUPNAME
			//    }
			//    if (index == 0 && this.state.SelectUsertype == "V") {
			//        let localArray1 = {
			//            value: "1",
			//            label: "Select Site"
			//        }
			//        SiteValue = localArray1
			//    }
			//    if (val.GROUPID == this.state.selectSiteID) {
			//        SiteValue = localArray
			//    }
			//    SiteListDD.push(localArray)
			//});
			//if (this.state.SelectUsertype == "C") {
			//    $('#DepartDiv').addClass('hide');
			//}
			let UserTypeChange = {
				Session_USERID: this.state.Login_UserId,
				Session_BUSUNIT: this.state.Login_UserBU,
				Session_ThirdParty_CompanyID: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID"),
				Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
				Session_UserRole: this.state.LoginUserType,
				Page_Action: this.state.PageAction,
				Query_IsVendor: false,
				Selected_UserType: UserType
			}

			ProfileAction.GetSearchUserOnUTypeChange(UserTypeChange)
			let Departmentdefault = {
				value: "",
				label: t("-----Select Department-----")

			}
			//[5/10/22, CAS - 31, Dhevanesam R]
			let siteclear = {
				value: "Select",
				label: t("Select Site")
			}
			let Selectvalue = {
				value: "Select",
				label: t("Select Type"),
				key: "",
				Role: "",
				Third: "",
			}
			$('#MultiSelectDD').addClass('hide');
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
			//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
			this.setState({
				SelectedUserId: "", FirstName: "", LastName: "",
				EmailAddres: "", PhoneNum: "", PassNTB: "", ConPasNTB: "", PageAction: "ADD", ChangePasswordEnable: "Y", adduser: true,
				VendorId: "", DisableTypeDD: DisableTypeDDL, SelectUsertype: UserType, SDIEMp: SDIEMp, vendor: vendor, Customer: CustomerL, DepartMent: Departmentdefault,
				MultiSiteChecked: false, MultiSiteSelected: [], SelectSite: siteclear, SelectUserRole: Selectvalue, StatusMsgErrorVal: StatusMsgError, EnterFirstNameErr: "", EnterConPassNameErr: "", EnterLastNameErr: "", EnterEmailNameErr: "",
				EnterPhoneNameErr: "", EnterPassNameErr: "", ErrorMagSiteErr: "", ErrorMegSiteErr: "", EmpLabelErr: "", AcctLabelErr: "", userProfileErr: ""
			})
		}
		else {
			let UserTypeChange = {
				Session_USERID: this.state.Login_UserId,
				Session_BUSUNIT: this.state.Login_UserBU,
				Session_ThirdParty_CompanyID: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID"),
				Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
				Session_UserRole: this.state.LoginUserType,
				Page_Action: this.state.PageAction,
				Query_IsVendor: false,
				Selected_UserType: UserType
			}
			let MultiSiteCheckedL = this.state.MultiSiteChecked
			if (this.state.MultiSiteExist.toUpperCase() != "Y") {
				$('#MultiSelectDD').addClass('hide');
				MultiSiteCheckedL = false
			}
			ProfileAction.GetSearchUserOnUTypeChange(UserTypeChange)
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
			this.setState({
				SelectUsertype: UserType, SDIEMp: SDIEMp, vendor: vendor, Customer: CustomerL, MultiSiteChecked: MultiSiteCheckedL
			})
		}
	}
    /*
    * Ref no: UP_PC_56
    * 
    * In this method search user drop down is binded
    * */
	BuildSearchOnUserTypeChg = () => {
		debugger
		const { t } = this.props
		let RadioType = JSON.parse(ProfileStore.RadioUserTypeBO);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		console.log("RadioType", RadioType)
		let SearchDDlist = [];
		let selectedOptionL = [];
		let searchUserL = [];
		searchUserL = RadioType.SearchDropDownTable
		searchUserL.map((val, index) => {
			let localArray = {
				value: val.ISA_EMPLOYEE_ID,
				label: val.USERANDBU
			}
			if (index == 1) {
				let localArray1 = {
					value: "1",
					label: "<< Select User >>"
				}
				selectedOptionL = localArray1
			}
			if (val.ISA_EMPLOYEE_ID == this.state.SelectedUserId) {
				selectedOptionL = localArray
			}
			SearchDDlist.push(localArray)
		});
		let SiteListDD = []
		let SiteList = []
		let SiteValue = []
		SiteList = RadioType.SisterSiteTable
		if (this.state.ChangePasswordEnable == "Y") {
			$('#PassDiv').addClass('hide');
			$('#ConfPassDiv').addClass('hide');
			$('#ChangePasBtn').removeClass('hide');
		}
		if (this.state.PageAction == "ADD") {
			$('#PassDiv').removeClass('hide');
			$('#ConfPassDiv').removeClass('hide');
			$('#ChangePasBtn').addClass('hide');
		}
		if (this.state.SelectUsertype == "V") {
			SiteList = [{ GROUPID: "ISA00", GROUPNAME: "ISA00" }, { GROUPID: "SDM00", GROUPNAME: "SDM00" }, { GROUPID: "WAL00", GROUPNAME: "WAL00" }]
			$('#VendorDiv').removeClass('hide');
			$('#MultiDiv').addClass('hide');
			$('#DepartDiv').addClass('hide');
		}
		if (this.state.SelectUsertype == "C") {
			$('#DepartDiv').addClass('hide');
		}
		SiteList.map((val, index) => {

			let localArray = {
				value: val.GROUPID,
				label: val.GROUPNAME
			}
			if (index == 0 && this.state.SelectUsertype == "V") {
				let localArray1 = {
					value: "1",
					label: t("Select Site")
				}
				SiteValue = localArray1
			}
			if (val.GROUPID == this.state.selectSiteID) {
				SiteValue = localArray
			}
			SiteListDD.push(localArray)
		});
		let UserROleLocal = [];
		let UserRoleSelect = [];
		let ThirdPartyListL = this.state.ThirdPartyList
		console.log("ThirdPartyListL", ThirdPartyListL)
		let Usertypes = []
		//[Zeus 130, 6/8/23] - Kishore S - Added supervisor role to customers
		if (this.state.LoginUserRole.toUpperCase() == "SUPER" || this.state.LoginUserRole.toUpperCase() == "CORP ADMIN") {
			Usertypes = [{ Role: "Supervisor" }, { Role: "Super" }, { Role: "Corp Admin" }, { Role: "Admin" }, { Role: "User" }]
		}
		else {
			Usertypes = [{ Role: "Supervisor" }, { Role: "Admin" }, { Role: "User" }]
		}
		let UserRoleL = this.state.UserRole
		//if (this.state.PageAction == "ADD") {
		//    UserRoleL = {
		//        value: "Select",
		//        label: "Select Type",
		//        key: "",
		//        Role: "",
		//        Third: "",
		//    }
		//}
		let SelectedThirdPartyL = CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID")
		if (SelectedThirdPartyL == "0" || SelectedThirdPartyL == null) {
			if (ThirdPartyListL.length > 0 && this.state.Customer == true && (SelectedThirdPartyL == "0" || SelectedThirdPartyL == null)) {
				ThirdPartyListL.map((ThirdParty, index1) => {
					if (index1 == 0) {
						debugger
						Usertypes.map((val, index2) => {
							let localArray = {
								value: val.Role + index2,
								label: val.Role,
								key: this.state.selectSiteID,
								Role: val.Role,
								Third: "0",
							}
							if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "C" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}
							if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
								UserROleLocal.push(localArray)
							}

							if ((UserRoleL.toUpperCase() == "SUPER" || UserRoleL.toUpperCase() == "ADMIN") && this.state.SelectUsertype == "C" && val.Role == "Corp Admin") {
								UserRoleSelect = localArray
							}
							if ((UserRoleL.toUpperCase() == "CORP ADMIN" || UserRoleL.toUpperCase() == "ADMIN") && this.state.SelectUsertype == "S" && val.Role == "Super") {
								UserRoleSelect = localArray
							}
							if ((UserRoleL.toUpperCase() == "CORP ADMIN" || UserRoleL.toUpperCase() == "SUPER" || UserRoleL.toUpperCase() == "SUPERVISOR") && this.state.SelectUsertype == "V" && val.Role == "Admin") {
								UserRoleSelect = localArray
							}
							if (UserRoleL.toUpperCase() == "SUPER" && this.state.SelectUsertype == "C" && val.Role == "Admin") {
								UserRoleSelect = localArray
							}
							if (UserRoleL.toUpperCase() == val.Role.toUpperCase()) {
								UserRoleSelect = localArray
							}
							if (UserRoleL.toUpperCase() == "SUPERVISOR" && this.state.SelectUsertype == "S" && val.Role == "Admin") {
								UserRoleSelect = localArray
							}
						});
					}
					Usertypes.map((val, index3) => {
						let localArray = {
							value: ThirdParty.COMPANY_NAME + index1 + index3,
							label: ThirdParty.COMPANY_NAME + "  " + val.Role,
							key: this.state.selectSiteID,
							Role: val.Role,
							Third: ThirdParty.THIRDPARTY_COMP_ID,
						}
						if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Super") {
							UserROleLocal.push(localArray)
						}
						if (this.state.SelectUsertype == "C" && val.Role != "Super" && val.Role != "Corp Admin") {
							UserROleLocal.push(localArray)
						}
						if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Super") {
							UserROleLocal.push(localArray)
						}
						//if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Super") {
						//    UserRoleSelect = localArray
						//}
						//if (this.state.SelectUsertype == "C" && val.Role != "Super" && val.Role != "Corp Admin") {
						//    UserRoleSelect = localArray
						//}
						//if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Super") {
						//    UserRoleSelect = localArray
						//}

					});
				});
				console.log("UserROleLocal", UserROleLocal)
			}
			else {
				Usertypes.map((val, index2) => {
					let localArray = {
						value: val.Role + index2,
						label: val.Role,
						key: this.state.selectSiteID,
						Role: val.Role,
						Third: "0",
					}
					if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "C" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					debugger
					if ((UserRoleL.toUpperCase() == "SUPER" || UserRoleL.toUpperCase() == "ADMIN") && this.state.SelectUsertype == "C" && val.Role == "Corp Admin") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.toUpperCase() == "CORP ADMIN" || UserRoleL.toUpperCase() == "Admin") && this.state.SelectUsertype == "S" && val.Role == "Super") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL.toUpperCase() == "CORP ADMIN" || UserRoleL.toUpperCase() == "SUPER" || UserRoleL.toUpperCase() == "SUPERVISOR") && this.state.SelectUsertype == "V" && val.Role == "Admin") {
						UserRoleSelect = localArray
					}
					if (UserRoleL.toUpperCase() == val.Role.toUpperCase()) {
						UserRoleSelect = localArray
					}
					if (UserRoleL.toUpperCase() == "SUPERVISOR" && this.state.SelectUsertype == "S" && val.Role == "Admin") {
						UserRoleSelect = localArray
					}
				});
			}
		}
		else {
			let childItems = ThirdPartyListL.filter((item, index) => item.THIRDPARTY_COMP_ID == CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID"));
			childItems.map((ThirdParty, index1) => {
				Usertypes.map((val, index2) => {
					let localArray = {
						value: ThirdParty.COMPANY_NAME + index2,
						label: ThirdParty.COMPANY_NAME + "  " + val.Role,
						key: this.state.selectSiteID,
						Role: val.Role,
						Third: ThirdParty.THIRDPARTY_COMP_ID,
					}
					if (this.state.SelectUsertype == "S" && val.Role != "Corp Admin" && val.Role != "Supervisor") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "C" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if (this.state.SelectUsertype == "V" && val.Role != "Corp Admin" && val.Role != "Supervisor" && val.Role != "Super") {
						UserROleLocal.push(localArray)
					}
					if ((UserRoleL == "Super" || UserRoleL == "Admin") && this.state.SelectUsertype == "C" && val.Role == "Corp Admin") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL == "Corp Admin" || UserRoleL == "Admin") && this.state.SelectUsertype == "S" && val.Role == "Super") {
						UserRoleSelect = localArray
					}
					if ((UserRoleL == "Corp Admin" || UserRoleL == "Super" || UserRoleL.toUpperCase() == "SUPERVISOR") && this.state.SelectUsertype == "V" && val.Role == "Admin") {
						UserRoleSelect = localArray
					}
					if (UserRoleL.toUpperCase() == val.Role.toUpperCase()) {
						UserRoleSelect = localArray
					}
					if (UserRoleL.toUpperCase() == "SUPERVISOR" && this.state.SelectUsertype == "S" && val.Role == "Admin") {
						UserRoleSelect = localArray
					}
				});
			});
		}
		//[5/10/22, CAS - 31, Dhevanesam R]
		if (this.state.PageAction == "ADD") {
			SiteValue = {
				value: "Select",
				label: t("Select Site")
			}
			UserRoleSelect = {
				value: "Select",
				label: t("Select Type"),
				key: "",
				Role: "",
				Third: "",
			}

		}

		//[10/11/23, Bug - 4278, Fix the site validation is displayed even the site data is filled, Johnprinto]
		if (SiteValue && SiteValue.label.toUpperCase() != "SELECT SITE" && this.state.ErrorMagSiteErr == " ") {
			this.setState({ ErrorMagSiteErr: "", userProfileErr: this.handleProfileValidation("SiteName") ? "Please fill all the required fields" : "" });
		}
		this.setState({
			SearchUser: SearchDDlist, selectedOption: selectedOptionL, SiteList: SiteListDD, SelectSite: SiteValue, SelectUserRole: UserRoleSelect, UserRoleDD: UserROleLocal,
		})

	}
    /*
    * Ref no:UP_PC_92
    * 
    * Show the Change password fields
    * */
	ChgPwd = () => {
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		this.setState({ ChangePasswordEnable: "Y" });
		$('#PassDiv').removeClass('hide');
		$('#ConfPassDiv').removeClass('hide');
		$('#ChangePasBtn').addClass('hide');
		let EnterPassNameErrMsg = ""
		let EnterConPassNameErrMsg = ""
		this.setState({ PassNTB: "", ConPasNTB: "", EnterPassNameErr: EnterPassNameErrMsg, EnterConPassNameErr: EnterConPassNameErrMsg })
	}
    /*
    * Ref no: UP_PC_43
    * 
    * Multisite checkbox change event call
    * */
	MultiSiteCheckBtn = () => {
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		const { t } = this.props;
		let ErrorMagSiteErrMsg = ""
		let UserRoleSelected = this.state.SelectUserRole
		let SelectSiteValue = this.state.SelectSite
		let SiteValue = ""
		SiteValue = this.state.selectSiteID
		if (this.state.SiteList.length > 0) {
			SiteValue = this.state.SelectSite.value
		}
		else {
			SiteValue = this.state.GroupID
		}
		console.log(SiteValue)
		let EnableMulti = "N"
		console.log("SelectSiteValue", SiteValue)
		let OLPriorityOrder = $('#MultiSelectUP').is(":checked") ? true : false
		if (SiteValue != "Select") {
			ErrorMagSiteErrMsg = ""
			if (OLPriorityOrder == true) {
				let MultiSiteCheckedBo = {
					Selected_UserType: this.state.SelectUsertype,
					Session_UserRole: UserRoleSelected.Role,
					MultiSiteChecked: true,
					Query_Mexico: "NO",
					SelectedUserBu: SiteValue
				}
				ProfileAction.GetMultiSite(MultiSiteCheckedBo)
				EnableMulti = "N"

				$(`#${SDIConstants.SDILoader}`).removeClass('hide');
			}
			else {

				$('#MultiSelectDD').addClass('hide');
			}
		}
		else {
			OLPriorityOrder = false
			ErrorMagSiteErrMsg = "Please select site"
		}
		debugger
		this.setState({ MultiSiteChecked: OLPriorityOrder, MultiSiteEnable: EnableMulti, ErrorMagSiteErr: ErrorMagSiteErrMsg })
	}
    /*
    * Ref no:UP_PC_58
    * 
    * MultiSite dropdown is binded 
    * */
	BindMultiSiteDD = () => {
		const { t } = this.props;
		debugger
		let MultiSite = JSON.parse(ProfileStore.MultiSiteBO);
		let SelectSiteValue = this.state.SelectSite;
		let Site = ""
		//if (SelectSiteValue.length == 0) {
		//Site = this.state.selectSiteID
		//}
		//else {
		//    Site = SelectSiteValue[0].value
		//}
		if (this.state.SiteList.length > 0) {
			Site = this.state.SelectSite.value
		}
		else {
			Site = this.state.GroupID
		}
		console.log("MultiSite", MultiSite)
		let MultiSiteListdata = MultiSite.Table
		let MultiSlectList = []
		let MultiSelectValue = []

		MultiSiteListdata.map((val, index) => {
			if (val.BUSINESS_UNIT == Site) {
				let localArray = {
					value: val.BUSINESS_UNIT,
					label: val.DESCRIPTION,
					disabled: true
				}
				//SiteTitle = val.DESCRIPTION;
				MultiSlectList.push(localArray)
				MultiSelectValue.push(localArray)
			}
			else {
				let localArray = {
					value: val.BUSINESS_UNIT,
					label: val.DESCRIPTION
				}
				MultiSlectList.push(localArray)
			}
		});
		if (this.state.MultiSiteEnable == "N" || this.state.PageAction == "ADD") {
			$('#MultiSelectDD').removeClass('hide');
		}
		//[12/2/21, PC_UP_01, CAS - User Profile - Multisite dropdown load issue, Dhevanesam R]
		if (MultiSlectList.length > 0) {
			this.setState({
				MultiSite: MultiSlectList, MultiSiteSelected: MultiSelectValue, MultiSiteChecked: true
			})
		}
		else {
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Sister sites are not available for this selected BU " + Site)
			})
			//alert("Sister sites are not available for this selected BU " + this.state.SelectSite.label.substring(0, 5));
			$('#MultiSelectDD').addClass('hide');
			this.setState({
				MultiSiteChecked: false
			})

		}
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
	handleProfileValidation = (fieldName) => {
		let EnterFirstNameErrMsg = this.state.EnterFirstNameErr;
		let EnterLastNameErrMsg = this.state.EnterLastNameErr;
		let EnterEmailNameErrMsg = this.state.EnterEmailNameErr;
		let EnterPhoneNameErrMsg = this.state.EnterPhoneNameErr;
		let EnterPassNameErrMsg = this.state.EnterPassNameErr;
		let EnterConPassNameErrMsg = this.state.EnterConPassNameErr;
		let ErrorMagSiteErrMsg = this.state.ErrorMagSiteErr;
		let ErrorMegSiteErrMsg = this.state.ErrorMegSiteErr;
		switch (fieldName) {
			case "FirstName": {
				EnterFirstNameErrMsg = ""
				break;
			}
			case "LastName": {
				EnterLastNameErrMsg = "";
				break;
			}
			case "EmailAddres": {
				EnterEmailNameErrMsg = "";
				break;
			}
			case "PhoneNum": {
				EnterPhoneNameErrMsg = "";
				break;
			}
			case "PassNTB": {
				EnterPassNameErrMsg = "";
				break;
			}
			case "ConPasNTB": {
				EnterConPassNameErrMsg = "";
				break;
			}
			case "SiteName": {
				ErrorMagSiteErrMsg = "";
				break;
			}
			case "TypeName": {
				ErrorMegSiteErrMsg = "";
				break;
			}
		}
		if (EnterFirstNameErrMsg == " " || EnterLastNameErrMsg == " " || EnterEmailNameErrMsg == " " || EnterPhoneNameErrMsg == " "
			|| EnterPassNameErrMsg == " " || EnterConPassNameErrMsg == " " || ErrorMagSiteErrMsg == " " || ErrorMegSiteErrMsg == " ") {
			return true;
		}else{
			return false;
		}

	}
    /*
    * Ref no:UP_PC_59
    * This method is invoked when the save button is clicked in the user profile tab
    * validation is clicked
    * */
	btnSave_Click = () => {
		debugger
		const { t } = this.props;
		let FirstNameVal = $('#FirstNTB').val();
		let lastNameVal = $('#LastNTB').val();
		let PhoneNum = $('#PhoneNumNTB').val();
		let EmailIdValue = $('#EmailNTB').val();
		let PasswordValue = $('#PassNTB').val();
		let COnfirmPasValue = $('#ConPasNTB').val();
		let VendorIDVALUE = $('#VendorID').val();
		{/*[3/20/2023, PS_CAS_47_02, Dhevanesam R*/ }
		$('#ManuallyUserIdTb').val('');
		document.getElementById("ErrorMagUserId").innerText = "";
		let SelectUserRoleAdd = this.state.SelectUserRole
		let UserTypeSelected = this.state.SelectUsertype;
		let role = SelectUserRoleAdd.value
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		let EnterFirstNameErrMsg = "";
		let EnterPassNameErrMsg = "";
		let EnterLastNameErrMsg = "";
		let EnterEmailNameErrMsg = "";
		let EnterPhoneNameErrMsg = "";
		let EnterConPassNameErrMsg = "";
		let ErrorMagSiteErrMsg = "";
		let ErrorMegSiteErrMsg = "";
		let locuserProfileErr = ""
		if (role == null || role == "" || role == undefined) {
			role = "SELECT"
		}
		let ErrorValidation = 0
		let EmpErrorValidation = 0;
		if ((!VendorIDVALUE || this.state.VendorIdValidate != 0) && UserTypeSelected == "V") {
			ErrorValidation = 1
			if (!VendorIDVALUE) {
				document.getElementById("VendorGroupValue").innerHTML = "Please enter the vendor ID";
				$('#VendorGroupValue').addClass('errorcolour');
				$('#VendorGroupValue').removeClass('GreenColour');
				$('#VendorID').addClass('border-color-red');
			}
			else {
				document.getElementById("VendorGroupValue").innerHTML = "Entered Vendor ID is Invalid";
				$('#VendorGroupValue').addClass('errorcolour');
				$('#VendorGroupValue').removeClass('GreenColour');
				$('#VendorID').addClass('border-color-red');
			}
		}
		else {
			document.getElementById("VendorGroupValue").innerHTML = "";
			$('#VendorGroupValue').addClass('GreenColour');
			$('#VendorID').removeClass('border-color-red');
		}
		if (!FirstNameVal) {
			EmpErrorValidation = 1;
			ErrorValidation = 1
			//document.getElementById("ErrorMagFirstName").innerHTML = (t("Please enter first Name"));
			EnterFirstNameErrMsg = " ";
		}
		else {
			//document.getElementById("ErrorMagFirstName").innerHTML = "";
			EnterFirstNameErrMsg = "";
		}
		if (role.toUpperCase() == "SELECT") {
			EmpErrorValidation = 1;
			ErrorValidation = 1
			ErrorMegSiteErrMsg = " ";
		}
		else {
			ErrorMegSiteErrMsg = "";
		}
		if (!lastNameVal) {
			EmpErrorValidation = 1
			ErrorValidation = 1
			EnterLastNameErrMsg = " ";
		}
		else {
			EnterLastNameErrMsg = "";
		}
		if (!EmailIdValue) {
			EmpErrorValidation = 1
			ErrorValidation = 1
			// EnterEmailNameErrMsg = "Please enter email address";
			EnterEmailNameErrMsg = " ";
		}
		else {
			EnterEmailNameErrMsg = "";
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(EmailIdValue)) {
				ErrorValidation = 1
				EnterEmailNameErrMsg = "Please enter valid email address";

			}
		}
		//[3/29/22, CAS - User Profile - Ph num error msg display issue, PC_UP_01, Dhevanesam R]
		if (!PhoneNum) {
			//debugger
			EmpErrorValidation = 1
			ErrorValidation = 1
			// EnterPhoneNameErrMsg = "Please enter phone number";
			EnterPhoneNameErrMsg = " ";
			document.getElementById("ErrorMagPhone").className = "DropDown-Font-Size errorcolour ";
		}
		else {
			EnterPhoneNameErrMsg = "";
			if (PhoneNum.length < 10) {
				ErrorValidation = 1
				EnterPhoneNameErrMsg = "Please enter valid phone Number";
				document.getElementById("ErrorMagPhone").className = "DropDown-Font-Size errorcolour";
			}

		}
		if (!PasswordValue && this.state.ChangePasswordEnable == "Y") {
			EmpErrorValidation = 1;
			ErrorValidation = 1
			EnterPassNameErrMsg = " ";
		}
		else {
			EnterPassNameErrMsg = "";
			let firstletter2 = PasswordValue.slice(0, 1)
			if ((this.state.ChangePasswordEnable == "Y" || this.state.PageAction == "ADD")) {
				if (PasswordValue.length < 6) {
					ErrorValidation = 1
					EnterPassNameErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"
				}
				else if ((/[a-zA-Z]/).test(firstletter2) == false || PasswordValue.match(/[0-9]/g) == null || PasswordValue.match(/[0-9]/g) == undefined || PasswordValue.match(/[0-9]/g) == "") {
					ErrorValidation = 1
					EnterPassNameErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!"
				}
				else {
					EnterPassNameErrMsg = "";
				}
			}
		}
		if (!PasswordValue && (this.state.ChangePasswordEnable == "Y" || this.state.PageAction == "ADD")) {
			EmpErrorValidation = 1;
			ErrorValidation = 1
			// EnterPassNameErrMsg = "Please enter password";
			EnterPassNameErrMsg = " ";
		}
		else {
			EnterPassNameErrMsg = "";
			let firstletter = PasswordValue.slice(0, 1)
			if ((this.state.ChangePasswordEnable == "Y" || this.state.PageAction == "ADD")) {
				if (PasswordValue.length < 6) {
					ErrorValidation = 1
					EnterPassNameErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"
				}
				else if ((/[a-zA-Z]/).test(firstletter) == false || PasswordValue.match(/[0-9]/g) == null || PasswordValue.match(/[0-9]/g) == undefined || PasswordValue.match(/[0-9]/g) == "") {
					ErrorValidation = 1
					EnterPassNameErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!"
				}
				else {
					EnterPassNameErrMsg = "";
				}
			}
		}
		if (!COnfirmPasValue && (this.state.ChangePasswordEnable == "Y" || this.state.PageAction == "ADD")) {
			EmpErrorValidation = 1;
			ErrorValidation = 1
			// EnterConPassNameErrMsg = "Please enter confirm password";
			EnterConPassNameErrMsg = " ";
		}
		else if (PasswordValue != COnfirmPasValue && (this.state.ChangePasswordEnable == "Y" || this.state.PageAction == "ADD")) {
			ErrorValidation = 1
			EnterConPassNameErrMsg = "Password doesn't match";
		}
		else {
			EnterConPassNameErrMsg = "";
			let firstletter1 = COnfirmPasValue.slice(0, 1)
			if ((this.state.ChangePasswordEnable == "Y" || this.state.PageAction == "ADD")) {
				if (COnfirmPasValue.length < 6) {
					ErrorValidation = 1
					EnterConPassNameErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"
				}
				else if ((/[a-zA-Z]/).test(firstletter1) == false || COnfirmPasValue.match(/[0-9]/g) == null || COnfirmPasValue.match(/[0-9]/g) == undefined || COnfirmPasValue.match(/[0-9]/g) == "") {
					ErrorValidation = 1
					EnterConPassNameErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!"
				}
				else {
					EnterConPassNameErrMsg = "";
				}
			}
		}
		let SelectSiteValue = this.state.SelectSite;
		let SiteSelected = ""
		//SiteSelected = SelectSiteValue.value
		//if (this.state.SiteList.length > 1) {
		//    SiteSelected = SelectSiteValue.value
		//}
		//else if (this.state.PageAction != "ADD"){
		//    SiteSelected = this.state.GroupID
		//}
		debugger
		if (this.state.SiteList.length > 0) {
			SiteSelected = SelectSiteValue.value
		}
		else {
			SiteSelected = this.state.GroupID
		}
		console.log("SiteSelected", SiteSelected)
		if ((SiteSelected == "" || SiteSelected.toUpperCase() == "SELECT" || SiteSelected == "1") && this.state.SiteList.length > 0) {
			EmpErrorValidation = 1;
			ErrorValidation = 1
			// ErrorMagSiteErrMsg = "Please select site";
			ErrorMagSiteErrMsg = " ";
		}
		else {
			ErrorMagSiteErrMsg = "";
		}
		//let UserTypeSelected = this.state.SelectUsertype;
		if (this.state.SelectUsertype != "V" && this.state.ManuallyAddUserId != "V" && this.state.ManuallyAddUserId != "A" && ErrorValidation == 0 && this.state.ManuallyAddUserId != "" && this.state.PageAction == "ADD") {
			$('#UserIdModal').modal('show');
		}
		else if (ErrorValidation == 0) {
			this.UpdateUserProfile()
		}
		else if (EmpErrorValidation == 1) {
			locuserProfileErr = "Please fill all the required fields"
		}

		this.setState({
			userProfileErr: locuserProfileErr,EnterFirstNameErr: EnterFirstNameErrMsg, EnterConPassNameErr: EnterConPassNameErrMsg, EnterLastNameErr: EnterLastNameErrMsg, EnterEmailNameErr: EnterEmailNameErrMsg, EnterPhoneNameErr: EnterPhoneNameErrMsg, EnterPassNameErr: EnterPassNameErrMsg, ErrorMagSiteErr: ErrorMagSiteErrMsg, ErrorMegSiteErr: ErrorMegSiteErrMsg
		})
	}
    /*
    * Ref no:UP_PC_60
    * 
    * In this method the UpdateUserDetails action method is invoked to update or add user.
    * */
	UpdateUserProfile = () => {
		//debugger
		const { t } = this.props;
		window.scrollTo(0, 0)
		let UserIdTb = $('#TBUserid').val();
		let FirstNameVal = $('#FirstNTB').val();
		let lastNameVal = $('#LastNTB').val();
		let PhoneNum = $('#PhoneNumNTB').val();
		let EmailIdValue = $('#EmailNTB').val();
		let PasswordValue = $('#PassNTB').val();
		let ManuallyUserId = $('#ManuallyUserIdTb').val();
		let VendorId = $('#VendorID').val();
		let UserTypeSelected = this.state.SelectUsertype;
		let MultiSiteEnable = $('#MultiSelectUP').is(":checked") ? "TRUE" : "FALSE";
		let MultiSitelist = []
		if (MultiSiteEnable == "TRUE") {

			if (this.state.selectedMultiSites.length > 0) {
				this.state.selectedMultiSites.map((val, index) => {
					MultiSitelist.push(val.BUSINESS_UNIT)
				});
			}
		}
		//if (MultiSitelist != null && MultiSitelist != undefined) {
		//    if (MultiSitelist.length < 1) {
		//        $('#MultiSelectDD').addClass('hi');

		//    }
		//}
		let ValidationMsg = true
		if (this.state.PageAction == "ADD" && UserTypeSelected != "V") {
			//debugger
			{/*[3/20/2023, PS_CAS_47_03, Dhevanesam R*/ }
			if (ManuallyUserId.trim() == "" || ManuallyUserId == null || ManuallyUserId == undefined) {
				document.getElementById("ErrorMagUserId").innerHTML = (t("Please enter user id"));
				ValidationMsg = false
			}
		}

		let DepartmentAdd = []
		DepartmentAdd = this.state.DepartMent
		let Depart = "";
		if (UserTypeSelected == "S") {
			Depart = DepartmentAdd.value;
		}
		if (this.state.PageAction == "ADD") {
			if (this.state.LoginUserRole == "CORP ADMIN" || this.state.LoginUserRole == "ADMIN" || this.state.LoginUserRole == "SUPERVISOR") {
				UserTypeSelected = "C"
			}
		}
		let SelectUserRoleAdd = this.state.SelectUserRole
		let ThirdpartyId = SelectUserRoleAdd.Third;
		let SelectSiteValue = this.state.SelectSite;
		let SiteSelected = ""
		SiteSelected = SelectSiteValue.value
		if (this.state.SiteList.length > 1) {
			SiteSelected = SelectSiteValue.value
		}
		else {
			SiteSelected = this.state.GroupID
		}
		console.log("SiteSelected", SiteSelected)
		let UserROleSelected = SelectUserRoleAdd.Role;
		if (UserROleSelected.toUpperCase() == "CORP ADMIN") {
			UserROleSelected = "CORPADMIN"
		}
		let LoginRole = this.state.LoginUserRole
		if (this.state.LoginUserRole == "CORP ADMIN") {
			LoginRole = "CORPADMIN"
		}
		if (ValidationMsg == true) {
			if (this.state.PageAction == "ADD") {
				$('#UserIdModal').modal('hide');
			}
			let updateprofileBo = {
				Session_UserID: this.state.Login_UserId,
				lblUserIDHide: this.state.LoginHideuserId,
				UserID: UserIdTb,
				ManuallyEntered_UserId: ManuallyUserId.toUpperCase(),
				ThirdParty_CompanyID: ThirdpartyId,
				VendorID: VendorId,
				SiteValue: SiteSelected,
				UserType: UserTypeSelected,
				Session_UserType: this.state.LoginUserType,
				UserRole: UserROleSelected,
				Session_Role: LoginRole,
				FirstName: FirstNameVal,
				LastName: lastNameVal,
				UserEmailID: EmailIdValue,
				UserPhoneNum: PhoneNum,
				UserDepart: Depart,
				Page_Action: this.state.PageAction,
				Query_IsVendor: "False",
				Query_IsMexico: "False",
				Query_IsMexicoVendor: "False",
				MultiSiteChecked: MultiSiteEnable,
				MultiSite: MultiSitelist,
				PassWord: PasswordValue,
				Flag_AddUser: this.state.ManuallyAddUserId,

			}
			console.log("updateprofileBo", updateprofileBo)
			let OLPriorityOrder = $('#MultiSelectUP').is(":checked") ? true : false
			if (OLPriorityOrder == true) {
				CookieService.updateLocalStorageValues("Session_MULTISITEUSER", "Y");
			}
			else {
				CookieService.updateLocalStorageValues("Session_MULTISITEUSER", " ");
			}
			//**********END****
			ProfileAction.UpdateUserDetails(updateprofileBo)
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		}
	}
    /*
    * Ref no: UP_PC_62
    * 
    * this method is invoke when add user button is clicked.
    * */
	AddUser = () => {
		//debugger
		//[5/10/22, CAS - 31, Dhevanesam R]
		const { t } = this.props;
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		$('#VendorDiv').addClass('hide');
		$('#SearchDIv').addClass('hide');
		$('#MultiSelectDD').addClass('hide');
		$('#EmpyAcctBtn').addClass('hide');
		$('#AcctBtn').addClass('hide');
		$('#EmpLabel').addClass('hide');
		$('#AcctLabel').addClass('hide');
		$('#EditMode').removeClass('hide');
		$('#AddUserBtn').addClass('hide');
		$('#approvalsTab').addClass('hide');
		$('#privilegesTab').addClass('hide');
		$('#orderSETab').addClass('hide');
		$('#preferencesTab').addClass('hide');
		$('#mobilityTab').addClass('hide');
		$('#zeusTab').addClass('hide');
		$('#sditrackTab').addClass('hide');
		$('#PassDiv').val('');
		$('#ConfPassDiv').val('');
		$('#ManuallyUserIdTb').val('');
		{/*[3/20/2023, PS_CAS_47_02, Dhevanesam R*/ }
		document.getElementById("ErrorMagUserId").innerText = "";
		document.getElementById("VendorGroupValue").innerText = "";
		document.getElementById("MultiSelectUP").checked = false;
		let SelectTypeL = this.state.SelectUsertype
		let SiteListDD = this.state.SiteList
		//[3/29/22, CAS - User Profile - Ph num error msg display issue, PC_UP_01, Dhevanesam R]
		document.getElementById("ErrorMagPhone").className = "DropDown-Font-Size errorcolour";
		//[5/10/22, CAS - 31, Dhevanesam R]
		if (this.state.LoginUserRole.toUpperCase() == "SUPER" && this.state.LoginUserType.toUpperCase() == "S") {
			SelectTypeL = "S"
			SiteListDD = []
			let SiteListvalue = this.state.PageLoadSiteList
			SiteListvalue.map((val, index) => {
				if (index == 0) {
					let siteLocal = {
						value: "Select",
						label: t("Select Site")
					}
					SiteListDD.push(siteLocal)
				}
				let localArray = {
					value: val.GROUPID,
					label: val.GROUPNAME
				}
				if (val.GROUPID != undefined && val.GROUPNAME != undefined) {
					SiteListDD.push(localArray)
				}
			});
			if (SiteListDD.length == 0) {
				SiteListvalue.map((val, index) => {
					if (index == 0) {
						let siteLocal = {
							value: "Select",
							label: t("Select Site")
						}
						SiteListDD.push(siteLocal)
					}
					let localArray = {
						value: val.BUSINESS_UNIT,
						label: val.DESCRIPTION
					}
					SiteListDD.push(localArray)
				});
			}
		}
		if (this.state.ChangePasswordEnable == "N") {
			$('#PassDiv').removeClass('hide');
			$('#ConfPassDiv').removeClass('hide');
			$('#ChangePasBtn').addClass('hide');
		}
		let Rolelist = this.state.UserRoleDD
		let Userroleddl = []
		if (this.state.LoginUserType == "S") {
			if (this.state.LoginUserRole == "CORP ADMIN" || this.state.LoginUserRole == "ADMIN") {
				$('#DepartDiv').addClass('hide');
			}
		}
		if (SelectTypeL == "V") {
			$('#VendorDiv').removeClass('hide');
			$('#MultiDiv').addClass('hide');
			$('#DepartDiv').addClass('hide');
		}
		let SiteSelected = ""
		//SiteSelected = SelectSiteValue.value
		let SelectSiteValue = this.state.SelectSite;
		if (this.state.SiteList.length > 1) {
			SiteSelected = SelectSiteValue.value
		}
		else {
			SiteSelected = this.state.GroupID
		}
		// let Selectvalue = []
		Rolelist.map((val, index) => {
			// //debugger
			if (index == 0 && this.state.LoginUserRole == "ADMIN" && this.state.LoginUserType == "S") {
				let localArray = {
					value: "Corp Admin",
					label: "Corp Admin",
					key: SiteSelected,
					Role: "Corp Admin",
					Third: "0",
				}
				Userroleddl.push(localArray)

			}
			if (this.state.LoginUserRole == "CORP ADMIN" && val.value != "Corp Admin") {
				Userroleddl.push(val)
			}
			if (this.state.LoginUserRole == "SUPER" && val.value == "Super") {
				Userroleddl.push(val)
			}

			if (val.value != "Corp Admin" && val.value != "Super") {
				Userroleddl.push(val)
			}
		});
		console.log("LoginUserRole", this.state.LoginUserRole)
		console.log("Userroleddl", Userroleddl)
		let DisableTypeDDL = this.state.SiteList.length > 0 ? true : false
		//let SiteListDD = this.state.SiteList
		//if (SiteListDD.length > 0) {
		//    SiteListDD.map((val, index) => {
		//        if (index == 0) {
		//            let localArray = {
		//                value: "Select",
		//                label: "Select "
		//            }
		//            Userroleddl.push(localArray)
		//            Selectvalue = localArray
		//        }
		//            let localArray = {
		//                value: val.BUSINESS_UNIT,
		//                label: val.DESCRIPTION
		//            }
		//            if (val.BUSINESS_UNIT == Site) {
		//                SiteValue = localArray
		//            }
		//            SiteListDD.push(localArray)
		//        });

		//}
		//[4/13/22, Bug - 2047 & 2048, Dhevanesam R
		let ThirdPartyListL = this.state.ThirdPartyList
		//const { t } = this.props;
		let Usertypes = [{ Role: "Admin" }, { Role: "User" }, { Role: "Supervisor" }]
		let UserROleLocal = [];
		let UserRoleSelect = [];
		if (this.state.LoginUserType == "S" && this.state.LoginUserRole.toUpperCase() == "ADMIN") {
			if (CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") == "0") {
				//debugger
				if (ThirdPartyListL.length > 0) {

					ThirdPartyListL.map((ThirdParty, index1) => {

						if (index1 == 0) {
							Usertypes.map((val, index2) => {
								if (index2 == 0) {
									let localArray1 = {
										value: "Corp Admin",
										label: "Corp Admin",
										key: SiteSelected,
										Role: "Corp Admin",
										Third: "0",
									}
									UserROleLocal.push(localArray1)
								}
								let localArray = {
									value: val.Role,
									label: val.Role,
									key: this.state.selectSiteID,
									Role: val.Role,
									Third: "0",
								}
								UserROleLocal.push(localArray)

							});
						}
						Usertypes.map((val, index3) => {

							let localArray = {
								value: ThirdParty.COMPANY_NAME + "  " + val.Role,
								label: ThirdParty.COMPANY_NAME + "  " + val.Role,
								key: this.state.selectSiteID,
								Role: val.Role,
								Third: ThirdParty.THIRDPARTY_COMP_ID,
							}
							UserROleLocal.push(localArray)

						});
					});
				}
				else {
					Usertypes.map((val, index2) => {
						if (index2 == 0) {
							let localArray1 = {
								value: "Corp Admin",
								label: "Corp Admin",
								key: SiteSelected,
								Role: "Corp Admin",
								Third: "0",
							}
							UserROleLocal.push(localArray1)
						}
						let localArray = {
							value: val.Role,
							label: val.Role,
							key: this.state.selectSiteID,
							Role: val.Role,
							Third: "0",
						}
						UserROleLocal.push(localArray)

					});
				}
			}

		}
		else {
			UserROleLocal = Userroleddl
		}
		let site = {
			value: "Select",
			label: t("Select Site")
		}

		let Selectvalue = {
			value: "Select",
			label: t("Select Type"),
			key: "",
			Role: "",
			Third: "",
		}
		//[4/13/22, Bug - 2047 & 2048, Dhevanesam R
		let Departmentdefault = {
			value: "",
			label: t("-----Select Department-----")
		}

		let SDIEMp = this.state.SDIEMp
		let CustomerL = this.state.Customer
		let vendor = this.state.vendor

		if (this.state.LoginUserType == "S") {
			SDIEMp = true
			CustomerL = false
			vendor = false
			document.getElementById("UtypeSDI").checked = true;
			document.getElementById("UtypeCustomer").checked = false;
			document.getElementById("UtypeVendor").checked = false;

		}
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		this.setState({
			SelectedUserId: "", FirstName: "", LastName: "", SelectUsertype: SelectTypeL, SiteList: SiteListDD,
			EmailAddres: "", PhoneNum: "", PassNTB: "", ConPasNTB: "", PageAction: "ADD", ChangePasswordEnable: "Y", adduser: true, UserRoleDD: UserROleLocal, SelectUserRole: Selectvalue,
			SelectSite: site, VendorId: "", DepartMent: Departmentdefault, DisableTypeDD: DisableTypeDDL, MultiSiteChecked: false, SDIEMp: SDIEMp, Customer: CustomerL,
			vendor: vendor, StatusMsgErrorVal: StatusMsgError, EnterFirstNameErr: "", EnterPhoneNameErr: "", EnterLastNameErr: "", EnterEmailNameErr: "",
			EnterPassNameErr: "", EnterConPassNameErr: "", ErrorMagSiteErr: "", ErrorMegSiteErr: "", EmpLabelErr: "", AcctLabelErr: "", userProfileErr:""
		})
	}
    /*
     * Ref no: UP_PC_63
    * To reload the page
    * 
    * */
	EditUser = () => {
		window.location.reload(false);
	}
	/*[7/21/22, CAS -2298 User Profile_ UI issues, Dhamotharan P]*/
	handleClickShowPassword = (values) => {
		this.setState({ showPassword: !this.state.showPassword })
	};
	handleClickShowPassword1 = (values) => {
		this.setState({ showPassword1: !this.state.showPassword1 })
	};
	handleMouseDownPassword = (event) => {
		event.preventDefault();

	};
	handlePaste = (e) => {
		e.preventDefault();
	};
	handleKeyDown = (e) => {
		//debugger
		let val = e.key;
		if (e.key === " " || e.key === "'" || e.key === '"') {
			e.preventDefault();
		}

	};
    /*
    * Ref no: UP_PC_61
    * 
    * This method is invoked when the action response for update user details is return.
    * The update status is shown as message to the user
    * */
	ShowEditMessage = () => {
		//debugger
		const { t } = this.props
		let edituser = JSON.parse(ProfileStore.AddUserBO);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		console.log("edituser", edituser)
		window.scrollTo(0, 0)
		//document.getElementById('StatusMsg').innerText = ""
		let StatusMsgError = "";
		if (edituser.Table1.length > 0) {
			let ErrorTable = edituser.Table1;
			//***NEW****(1/18/2021)
			//debugger
			let OLPriorityOrder = $('#MultiSelectUP').is(":checked") ? true : false
			if (OLPriorityOrder == true) {
				CookieService.updateLocalStorageValues("Session_MULTISITEUSER", "Y");
			}
			else {
				CookieService.updateLocalStorageValues("Session_MULTISITEUSER", " ");
			}
			//**********END****
			if (ErrorTable[0].PasswordExist == "Error - password has already been used") {
				$('#PassDiv').val('');
				$('#ConfPassDiv').val('');
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Error - password has already been used")
				})
				//alert(t("Error - password has already been used"))
			}
			else if (ErrorTable[0].UserIdExist == "User ID already exists") {
				$('#ManuallyUserIdTb').val('');
				//alert(t("User ID already exists"))
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("User ID already exists")
				})
			}
			else if (ErrorTable[0].UserIdFormatError != null) {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t(ErrorTable[0].UserIdFormatError)
				})
				//alert(ErrorTable[0].UserIdFormatError)
			}
			else if (ErrorTable[0].InvalidUserId == "Invalid User ID") {
				$('#ManuallyUserIdTb').val('');
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Invalid User ID")
				})
				//alert(t("Invalid User ID"))
			}
			else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Technical issue")
				})
				//alert(t("Technical issue"))
			}
		}
		else {
			//[4/27/22, Bug - 2071, Dhevanesam R
			let UpdateSuccess = edituser.Table2
			if (UpdateSuccess.length > 0) {
				if (UpdateSuccess[0].AddUserMessage != null) {
					StatusMsgError = (t(UpdateSuccess[0].AddUserMessage))
					let Rolelist = this.state.UserRoleDD
					let Userroleddl = []
					Rolelist.map((val, index) => {
						Userroleddl.push(val)
					});
					let DisableTypeDDL = this.state.SiteList.length > 0 ? true : false
					//[5/10/22, CAS - 31, Dhevanesam R]
					let site = {
						value: "Select",
						label: t("Select Site")
					}
					let Selectvalue = {
						value: "Select",
						label: t("Select Type"),
						key: "",
						Role: "",
						Third: "",
					}
					let Departmentdefault = {
						value: "",
						label: t("-----Select Department-----")
					}

					$('#PassDiv').val('');
					$('#ConfPassDiv').val('');
					$('#ManuallyUserIdTb').val('');
					//***NEW****(1/18/2021)
					//debugger
					//[11 / 9 / 21, PC_UP_01, Bug - 1743, Dhevanesam R]

					//let OLPriorityOrder = $('#MultiSelectUP').is(":checked") ? true : false
					//if (OLPriorityOrder == true) {
					//    CookieService.updateLocalStorageValues("Session_MULTISITEUSER", "Y");
					//}
					//else {
					//    CookieService.updateLocalStorageValues("Session_MULTISITEUSER", " ");
					//}
					$('#MultiSelectDD').addClass('hide');
					//debugger
					{/*[3/20/2023, PS_CAS_47_02, Dhevanesam R*/ }
					document.getElementById("ErrorMagUserId").innerText = "";

					//**********END****
					this.setState({
						SelectedUserId: "", FirstName: "", LastName: "",
						EmailAddres: "", PhoneNum: "", PassNTB: "", ConPasNTB: "", PageAction: "ADD", ChangePasswordEnable: "Y", adduser: true, UserRoleDD: Userroleddl, SelectUserRole: Selectvalue,
						SelectSite: site, VendorId: "", DisableTypeDD: DisableTypeDDL, DepartMent: Departmentdefault, MultiSiteChecked: false, SDIEMp: false, Customer: false, vendor: false, StatusMsgErrorVal: StatusMsgError
					})
					//if (this.state.PageAction == "ADD") {
					//    window.location.reload(false);
					//}
				}
				else {
					StatusMsgError = (t(UpdateSuccess[0].EditUserMessage))
					this.setState({
						StatusMsgErrorVal: StatusMsgError
					})
				}
			}
		}


	}
    /*
    * Ref no:UP_PC_42
    * 
    * This method is invoke when the value is entered into the vendor id text box.
    * */
	VendorIdChaneg = () => {
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		const { t } = this.props;
		let VendorId = $('#VendorID').val();
		if (VendorId == "" || VendorId == null) {
			document.getElementById('VendorGroupValue').innerText = "Enter the vendor ID"
			$('#VendorGroupValue').addClass('errorcolour');
			$('#VendorID').addClass('border-color-red');
		}
		else {
			let VendorIDBo = {
				Entered_VendorId: VendorId
			}
			$('#VendorID').removeClass('border-color-red');
			ProfileAction.GetVendorGroup(VendorIDBo);
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		}
	}
    /*
    * Ref no:UP_PC_57
    * 
    * In this method the vendor id check response is binded to the Vendorgroupvalue label.
    * */
	BindVendorSite = () => {
		const { t } = this.props
		let VendorGroupData = JSON.parse(ProfileStore.VendorIdCheckBO);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let VendorExist = []
		VendorExist = VendorGroupData.dsVendorExist
		if (VendorExist.length > 0) {
			document.getElementById('VendorGroupValue').innerText = VendorExist[0].Vendor_CompanyName
			$('#VendorGroupValue').removeClass('errorcolour');
			$('#VendorGroupValue').addClass('GreenColour');

			this.setState({ VendorIdValidate: 0 })
		}
		else {
			let errortable = VendorGroupData.Error_Table
			document.getElementById('VendorGroupValue').innerText = errortable[0].VendorId_NotExist
			$('#VendorGroupValue').addClass('errorcolour');
			$('#VendorGroupValue').removeClass('GreenColour');
			this.setState({ VendorIdValidate: 1 })
		}
	}
    /*
    * Ref no:UP_PC_46
    * This method is invoked when the Privilege type is changed.
    * The action method GetPrivilegesType is invoked to get the data using api method.
    * */
	OnchangePrivType = (Type) => {
		//[4/27/22, Bug - 2071, Dhevanesam R
		//document.getElementById('StatusMsg').innerText = ""
		let StatusMsgError = "";
		if (Type != "1") {
			$('#SelPrivBtn').removeClass('hide');
			$('#DeSelPrivBtn').removeClass('hide');
			let SiteSelected = this.state.SelectTab2Site.value
			let UserTypeSelected = this.state.SelectUsertype;
			let UserROleSelected = this.state.SelectUserRole.Role;
			let PrivilegeTypeBO = {
				PriviTypeIndex: Type,
				UserRoleSelected: "",
				UserId: this.state.SelectedUserId,
				Page_Action: this.state.PageAction,
				Query_IsMexicoVendor: "False",
				Query_IsVendor: "False",
				SiteValue: SiteSelected,
				UserType: UserTypeSelected,
				UserRole: UserROleSelected,
			}
			ProfileAction.GetPrivilegesType(PrivilegeTypeBO)
			this.setState({ DisablePrivRole: true, StatusMsgErrorVal: StatusMsgError, isExpandAll: true })
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		}
		else {
			$('#SelPrivBtn').addClass('hide');
			$('#DeSelPrivBtn').addClass('hide');
			let checkedvalue = []
			this.setState({ DisablePrivRole: false, PrivTreeDisable: true, checked: checkedvalue, StatusMsgErrorVal: StatusMsgError })
		}
	}
    /*
    * This method is invoked when the privileges type action response is emitted
    * If Alacart is the type then the privileges tree is checked with the alacrt privileges
    * 
    * */
	BindPrivilegeOnType = () => {
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let PrivilegeTypeBO = JSON.parse(ProfileStore.PrivilegeTypeBO);
		console.log("PrivilegeTypeBO", PrivilegeTypeBO)
		let checkvalues = []
		let AlacartProgramTableValue = PrivilegeTypeBO.AlacartProgramTable
		AlacartProgramTableValue.map((val, index) => {
			//if (val.ISA_PARENT_IDENT == null) {
			//    checkvalues.push(val.ISA_IDENTIFIER)
			//}
			//else {
			checkvalues.push(val.SECURITYALIAS)
			// }
		});
		this.setState({
			checked: checkvalues, expanded: checkvalues, PrivTreeDisable: false
		})
	}
    /*
    * Ref no:UP_PC_68
    * 
    * This method is invoked when the store emit the data for Role privileges
    * Privileges tree is checked with role based privileges
    * */
	BindPrivilegeOnRole = () => {
		let PrivilegeTypeBO = JSON.parse(ProfileStore.PrivilegeRoleBO);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		//[4/27/22, Bug - 2071, Dhevanesam R
		//document.getElementById('StatusMsg').innerText = ""
		let StatusMsgError = "";
		console.log("PrivilegeTypeBO", PrivilegeTypeBO)
		let checkvalues = []
		let RoleDataProgramTableValue = PrivilegeTypeBO.RoleDataProgramTable
		RoleDataProgramTableValue.map((val, index) => {
			//if (val.ISA_PARENT_IDENT == null) {
			//    checkvalues.push(val.ISA_IDENTIFIER)
			//}
			//else {
			checkvalues.push(val.ALIAS_NAME)
			//}
		});
		this.setState({
			checked: checkvalues, expanded: checkvalues, PrivTreeDisable: true, StatusMsgErrorVal: StatusMsgError
		})
	}
    /*
    * Ref no:UP_PC_67
    * 
    * This method is invoked when the submit button is clicked in the privileges tab.
    * */
	updatePrivilege = () => {
		const { t } = this.props
		let PriviIndex = "0"
		let selectrole = ""
		let RoleChecked = $('#Role').is(":checked") ? "True" : "False"
		let checkedlist = []
		let privilegelist = []
		let SelectSiteValue = this.state.SelectSite;
		let Sitedata = ""
		Sitedata = SelectSiteValue.value
		window.scrollTo(0, 0)
		if (this.state.SiteList.length > 0) {
			Sitedata = SelectSiteValue.value
		}
		else {
			Sitedata = this.state.GroupID
		}
		//let Sitedata = this.state.selectSiteID
		let PrivilegeTree = this.state.Tab2BindTree
		console.log("PrivilegeTree", PrivilegeTree)
		let PrivTreeL = this.state.PrivTree
		checkedlist = this.state.checked
		let FinalPriv = []
		let ParentPriv = PrivTreeL.filter((item, index) => item.ISA_PARENT_IDENT == null);
		checkedlist.map((val, index) => {
			let PriviTree = {
				NOdeVaue: val
			}
			privilegelist.push(PriviTree)
		})
		if (RoleChecked == "True") {
			PriviIndex = "1"
			selectrole = this.state.SelectROleList.value
		}
		if (privilegelist.length > 1) {
			let UpdatePriviBo = {
				UserId: this.state.SelectedUserId,
				Session_UserId: this.state.Login_UserId,
				PriviTypeIndex: PriviIndex,
				UserRoleSelected: selectrole,
				PrivilegeTree: privilegelist,
				Session_BUSUNIT: this.state.Login_UserBU,
				Session_EMAIL: CookieService.getLocalStorageValues("Session_EMAIL"),
				SiteValue: Sitedata,
				Session_USERTYPE: this.state.LoginUserType
			}
			ProfileAction.UpdatePrivilege(UpdatePriviBo);

			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		}
		//[4/27/22, Bug - 2071, Dhevanesam R
		else {
			let StatusMsgError = ""
			if (RoleChecked == "True") {
				StatusMsgError = "Please select a role.";
			}
			else {
				StatusMsgError = "Please select at least one program.";
			}
			this.setState({
				StatusMsgErrorVal: StatusMsgError
			})
		}
	}
    /*
    * Ref no:UP_PC_69
    * In this method Privileges update status is showmn.
    * 
    * */
	ShowUpdateMesPri = () => {
		const { t } = this.props
		let UpdatePrivile = JSON.parse(ProfileStore.UpdatePrivilegeBO);
		let RedirectPage = []
		RedirectPage = UpdatePrivile.Redirect_Page
		let SuccessTable = []
		SuccessTable = UpdatePrivile.SuceessMessage
		if (RedirectPage.length > 0) {
			$(`#${SDIConstants.SDILoader}`).addClass('hide');
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Update Failed")
			})
			//alert(t("Update Failed"));
		}
		else {
			//localStorage.setItem("SDIMenu", "")
			//[4/27/22, Bug - 2071, Dhevanesam R
			MenuAction.refreshMenuItems();
			let StatusMsgError = ""
			StatusMsgError = SuccessTable[0].UpdateSuccess
			this.setState({
				StatusMsgErrorVal: StatusMsgError
			})
		}
	}
    /*
    * Ref no:UP_PC_71 && UP_PC_73
    * 
    * This method is call when submit button in the approvals tab is clicked.
    * */
	UpdateApprovals = (selectedApprovers) => {
		const { t } = this.props
		//[4/27/22, Bug - 2071, Dhevanesam R
		//document.getElementById('StatusMsg').innerText = ""
		let StatusMsgError = ""
		// $(`#${SDIConstants.SDILoader}`).removeClass('hide');
		//let Buttype = document.getElementById('ReqBtn').innerText
		//console.log(Buttype)
		let validation = true
		let SiteSelected = ""
		window.scrollTo(0, 0)
		if (this.state.SiteList.length > 0) {
			SiteSelected = this.state.SelectSite.value
		}
		else {
			SiteSelected = this.state.GroupID
		}
		console.log("ApprSiteSelected", SiteSelected)
		let DeleteAppr = $('#customCheckk').is(":checked") ? "TRUE" : "FALSE";
		let BudLimitset = $('#BUdLimitData').val()
		let Selectedaprover = approvalAccordian == 3 ? selectedApprovers : this.state.ExistApproveValue.value
		console.log("Selectedaprover", Selectedaprover)
		//[4/27/22, Bug - 2071, Dhevanesam R
		if (Selectedaprover == "0" || Selectedaprover == undefined) {
			StatusMsgError = "A valid approval name must be selected";
			validation = false
			this.setState({
				StatusMsgErrorVal: StatusMsgError
			})
			// $(`#${SDIConstants.SDILoader}`).addClass('hide');
		}
		//[4/13/22, Bug - 2071, Dhevanesam R]
		if (validation) {
			//[4/13/22, Bug - 2071, Dhevanesam R
			//if (Buttype == "Définir l’approbation du demandeur" || Buttype == "Establecer la aprobación del solicitante") {
			//    Buttype = "Set Requestor Approval"
			//}
			//else if (Buttype == "Définir l’approbation budgétaire" || Buttype == "Establecer aprobación presupuestaria") {
			//    Buttype = "Set Budgetary Approval"
			//}
			if (approvalAccordian == 1) {
				let UpdBudAppBo = {
					UserId: this.state.SelectedUserId,
					Session_UserId: this.state.Login_UserId,
					Session_UserRole: this.state.LoginUserRole.toUpperCase(),
					SiteValue: SiteSelected,
					BudApp_Selected: Selectedaprover,
					BudLimit: BudLimitset,
					Delete_Checked: DeleteAppr,
					Session_WEBSITED: CookieService.getLocalStorageValues("Session_WEBSITED"),
					AppExist: this.state.ExistBudAppr,
				}
				$(`#${SDIConstants.SDILoader}`).removeClass('hide');
				ProfileAction.UpdateBudgetApp(UpdBudAppBo)
				// let DeleteAppr = $('#customCheckk').is(":checked") ? "TRUE" : "FALSE";
				//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
				if (DeleteAppr == "TRUE") {
					let localArray1 = {
						value: 0,
						label: t("--------Select Approver-------"),
					}
					//document.getElementById("customCheckk").checked = false;
					//[8/10/22, Bug - 2349, PS_UP_05, Dhevanesam R]
					this.setState({ ExistApproveValue: localArray1, ExistAppLimit: "", initialApprover: Selectedaprover, initialBudAppLimit: "" })
					if (document.getElementById("customCheckk")) {
						document.getElementById("customCheckk").checked = false;
					}
				}
			}
			else if (approvalAccordian == 2) {
				let UpdReqAppBo = {
					UserId: this.state.SelectedUserId,
					Session_UserId: this.state.Login_UserId,
					Session_UserRole: this.state.LoginUserRole.toUpperCase(),
					SiteValue: SiteSelected,
					ReqApp_Selected: Selectedaprover,
					Delete_Checked: DeleteAppr,
					Session_WEBSITED: CookieService.getLocalStorageValues("Session_WEBSITED"),
					AppExist: this.state.ExistApprover,
				}
				$(`#${SDIConstants.SDILoader}`).removeClass('hide');
				ProfileAction.UpdateReqApp(UpdReqAppBo)
				// let DeleteAppr = $('#customCheckk').is(":checked") ? "TRUE" : "FALSE";
				//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
				if (DeleteAppr == "TRUE") {
					let localArray1 = {
						value: 0,
						label: t("--------Select Approver-------"),
					}
					//document.getElementById("customCheckk").checked = false;
					//[8/10/22, Bug - 2349, PS_UP_05, Dhevanesam R]
					this.setState({ ExistApproveValue: localArray1, ExistAppLimit: "", initialApprover: Selectedaprover, initialBudAppLimit: "" })
					if (document.getElementById("customCheckk")) {
						document.getElementById("customCheckk").checked = false;
					}
				}
			} else if (approvalAccordian == 3) {
				let tempArray = [];  //[09/05/22, PS_ZE_192_APP_03,Sathis]-Trigger on the click of submit, delete from alternate budgetary
				let existApp = [];
				checkboxCount = 0;
				Selectedaprover.forEach((object,index) => {
					if (object.value != " ") {
						tempArray.push(object.value);
						existApp.push(object);
					}
					$(`#AltBudgCheckbox${index + 1}`).prop('checked', false);
					$(`#AltBudgDelIcon${index + 1}`).removeClass('del-icon-disable');

				})
				$('#AltBudgMultiselect').prop('checked', false);
				let selectedAltBudg = Array(10).fill(' ')
				for (let i = 0; i < tempArray.length; i++) {
					selectedAltBudg[i] = tempArray[i];
				}
                /*let combinedArray = [...existApp, ...this.state.ApproverList];
                let uniqueSet = new Set(combinedArray);
                let approverList = Array.from(uniqueSet);*/
				let UpdReqAppBo = {
					UserId: this.state.SelectedUserId,
					Session_UserId: this.state.Login_UserId,
					Session_UserRole: this.state.LoginUserRole.toUpperCase(),
					SiteValue: SiteSelected,
					ReqApp_Selected: selectedAltBudg,
					Delete_Checked: selectedApprovers == this.state.updateAltBudgetary? "FALSE" : "TRUE" ,
					Session_WEBSITED: CookieService.getLocalStorageValues("Session_WEBSITED"),
					AppExist: this.state.ExistApprover,
				}
				$(`#${SDIConstants.SDILoader}`).removeClass('hide');
				ProfileAction.UpdateAltBudApp(UpdReqAppBo)

				this.setState({
					ExistApproveValue: existApp, selectedApprvValue: existApp, updateAltBudgetary: existApp,
					delAltBudCheck: existApp, delAltBudIcon: existApp, isMultiSelectChecked:false})
			}

		}
	}
    /*
    * Ref no:UP_PC_72
    * 
    * In this method requestor approval update status is showmn.
    * */
	ShowApprovalStatus = () => {

		const { t } = this.props
		let UpdateStatusL = JSON.parse(ProfileStore.UpdateReqApprovalsBO);
		let UpdateStatus = UpdateStatusL.Table1
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		console.log("UpdateStatus", UpdateStatus)
		if (UpdateStatus[0].DeleteSuccess != null) {
			StatusMsgError = UpdateStatus[0].DeleteSuccess;
			$(`#removeApprover`).addClass('hide');
		}
		else if (UpdateStatus[0].DeleteFailed != null) {
			StatusMsgError = UpdateStatus[0].DeleteFailed
		}
		else if (UpdateStatus[0].UpdateSuccess != null) {
			StatusMsgError = UpdateStatus[0].UpdateSuccess;
			//[8/10/22, Bug - 2349, PS_UP_06, Dhevanesam R]
			$(`#removeApprover`).removeClass('hide');
			this.setState({ ExistReqAppr: this.state.ExistApproveValue.value });
		}
		else {
			StatusMsgError = UpdateStatus[0].InsertFailed;
			$(`#removeApprover`).removeClass('hide');
			this.setState({ ExistReqAppr: this.state.ExistApproveValue.value });
		}
		let DeleteAppr = $('#customCheckk').is(":checked") ? "TRUE" : "FALSE";
		//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
		if (DeleteAppr == "TRUE") {
			let localArray1 = {
				value: 0,
				label: t("--------Select Approver-------"),
			}
			//document.getElementById("customCheckk").checked = false;            
			this.setState({ ExistApproveValue: localArray1, ExistAppLimit: "", initialBudAppLimit: ""})
			if (document.getElementById("customCheckk")) {
				document.getElementById("customCheckk").checked = false;
			}
		}
		//[4/27/22, Bug - 2071, Dhevanesam R
		this.setState({
			StatusMsgErrorVal: StatusMsgError
		})
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
    /*
    * Ref no:UP_PC_74
    * 
    * In this method budgetary approval update status is showmn.
    * */
	ShowBudApprovalStatus = () => {
		const { t } = this.props
		let UpdateStatusL = JSON.parse(ProfileStore.UpdateApprovalsBO);
		let UpdateStatus = UpdateStatusL.Table1
		console.log("UpdateStatus", UpdateStatus)
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		if (UpdateStatus[0].DeleteSuccess != null) {
			StatusMsgError = UpdateStatus[0].DeleteSuccess;
			$(`#removeApprover`).addClass('hide');
		}
		else if (UpdateStatus[0].DeleteFailed != null) {
			StatusMsgError = UpdateStatus[0].DeleteFailed
		}
		else if (UpdateStatus[0].UpdateSuccess != null) {
			StatusMsgError = UpdateStatus[0].UpdateSuccess;
			//[8/10/22, Bug - 2349, PS_UP_07, Dhevanesam R]
			$(`#removeApprover`).removeClass('hide');
			//this.setState({ ExistReqAppr: this.state.ExistApproveValue.value });
			this.setState({ ExistBudAppr: this.state.ExistApproveValue.value });
		}
		else {
			StatusMsgError = UpdateStatus[0].InsertFailed;
			$(`#removeApprover`).removeClass('hide');
			//this.setState({ ExistReqAppr: this.state.ExistApproveValue.value });
			this.setState({ ExistBudAppr: this.state.ExistApproveValue.value });
		}
		let user = "";
		let userLimit = "";
		let approver = "";
		let approverLimit = "";
		let popupFlag = true;
		if (UpdateStatusL.ApproverData != null && UpdateStatusL.ApproverData.length > 0) {
			user = UpdateStatusL.ApproverData[0].CURRENTUSER;
			approver = UpdateStatusL.ApproverData[0].NEXTAPPROVER;
			userLimit = UpdateStatusL.ApproverData[0].CURRENTUSERLIMIT;
			approverLimit = UpdateStatusL.ApproverData[0].NEXTAPPROVERLIMIT;
		}
		else {
			popupFlag = false;
		}
		let DeleteAppr = $('#customCheckk').is(":checked") ? "TRUE" : "FALSE";
		//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
		if (DeleteAppr == "TRUE") {
			let localArray1 = {
				value: 0,
				label: t("--------Select Approver-------"),
			}

			this.setState({ ExistApproveValue: localArray1, ExistAppLimit: "", initialBudAppLimit: "" })
			if (document.getElementById("customCheckk")) {
				document.getElementById("customCheckk").checked = false;
			}
		}
		//[4/27/22, Bug - 2071, Dhevanesam R
		this.setState({
			StatusMsgErrorVal: StatusMsgError,
			CurrentUserName: user, CurrentUserLimit: userLimit, NextApprover: approver, NextApproverLimit: approverLimit, ApproverPopupFlag: popupFlag
		})
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
    /*
    * Ref no:UP_PC_80
    * 
    * This method is called when Submit in Order status email tab is click to update the Order status email in the database.
    * */
	UpdateOSE = () => {
		const { t } = this.props
		//[4/27/22, Bug - 2071, Dhevanesam R
		//document.getElementById('StatusMsg').innerText = ""
		let StatusMsgError = "";
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		let SiteSelected = ""
		if (this.state.SiteList.length > 0) {
			SiteSelected = this.state.SelectSite.value
		}
		else {
			SiteSelected = this.state.GroupID
		}
		window.scrollTo(0, 0)
		let Validate = true
		if (this.state.RoleExist == "" || Validate) {
			let UpdOSEBO = {
				UserId: this.state.SelectedUserId,
				Session_UserId: this.state.Login_UserId,
				Session_BUSUNIT: this.state.Login_UserBU,
				Session_EMAIL: CookieService.getLocalStorageValues("Session_EMAIL"),
				Session_UserRole: this.state.LoginUserRole,
				SiteValue: SiteSelected,
				chbCRE: $('#chbCRE').is(":checked") ? "TRUE" : "FALSE",
				chbQTW: $('#chbQTW').is(":checked") ? "TRUE" : "FALSE",
				chbQTC: $('#chbQTC').is(":checked") ? "TRUE" : "FALSE",
				chbQTS: $('#chbQTS').is(":checked") ? "TRUE" : "FALSE",
				chbCST: $('#chbCST').is(":checked") ? "TRUE" : "FALSE",
				chbVND: $('#chbVND').is(":checked") ? "TRUE" : "FALSE",
				chbAPR: $('#chbAPR').is(":checked") ? "TRUE" : "FALSE",
				chbQTA: $('#chbQTA').is(":checked") ? "TRUE" : "FALSE",
				chbQTR: $('#chbQTR').is(":checked") ? "TRUE" : "FALSE",
				chbRFA: $('#chbRFA').is(":checked") ? "TRUE" : "FALSE",
				chbRFR: $('#chbRFR').is(":checked") ? "TRUE" : "FALSE",
				chbRFC: $('#chbRFC').is(":checked") ? "TRUE" : "FALSE",
				chbRCF: $('#chbRCF').is(":checked") ? "TRUE" : "FALSE",
				chbRCP: $('#chbRCP').is(":checked") ? "TRUE" : "FALSE",
				chbCNC: $('#chbCNC').is(":checked") ? "TRUE" : "FALSE",
                chbDLF: $('#chbDLF').is(":checked") ? "TRUE" : "FALSE",
                chbPKA: $('#chbPKA').is(":checked") ? "TRUE" : "FALSE",
                chbASN: $('#chbASN').is(":checked") ? "TRUE" : "FALSE",
                //Added the ASN and ready for Picking - Dhinesh R(Zeus-35)//
			}
			ProfileAction.UpdateOSE(UpdOSEBO);
		}
		//[4/27/22, Bug - 2071, Dhevanesam R
		else {
			StatusMsgError = "Role is assigned so order status email cannot be updated"
			$(`#${SDIConstants.SDILoader}`).addClass('hide');
		}
		this.setState({
			StatusMsgErrorVal: StatusMsgError
		})
	}
    /*
    * Ref no: UP_PC_81
    * In this method Order Status Email update status is showmn.
    * 
    * */
	ShowOSEMessage = () => {
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let UpdateStatusL = JSON.parse(ProfileStore.UpdateOSEBO);
		console.log(UpdateStatusL)
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		let UpdateStatus = UpdateStatusL.Table1
		if (UpdateStatus[0].UpdateSuccess != null) {
			StatusMsgError = UpdateStatus[0].UpdateSuccess
		}
		else {
			StatusMsgError = "Techincal Issue"
		}
		this.setState({
			StatusMsgErrorVal: StatusMsgError
		})
	}
    /*
    * Ref no:UP_PC_83
    * 
    * This method is called when Submit in Preference tab is click to update the Preference in the database.
    * */
	UpdatePref = () => {
		//document.getElementById('StatusMsg').innerText = ""
		//[4/27/22, Bug - 2071, Dhevanesam R
		window.scrollTo(0, 0)
		//debugger
		let StatusMsgError = "";
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		let DescripTypeValue = $('#ClientDesp').is(":checked") ? "Client" : "C";
		let BlockPrice = $('#PriceDisplay').is(":checked") ? "True" : "False";
		let Departmentvalue = ""
		let ShiptoValue = ""
		if (this.state.DepartmentPref != []) {
			Departmentvalue = this.state.DepartmentPref.value
		}
		if (this.state.Shipto != []) {
			ShiptoValue = this.state.Shipto.value
		}
		let validate = true

		let Child1Value = ""
		let Child2Value = ""
		if (this.state.dropCD1SegVisible == true && this.state.dropCD2SegVisible == true) {

			if (this.state.dropCD1SegValue == [] || this.state.dropCD1SegValue == 0 || this.state.dropCD2SegValue == [] || this.state.dropCD2SegValue == 0) {
				validate = false
			}
			else {
				if (this.state.dropCD1SegValue.value == "" || this.state.dropCD2SegValue.value == "") {
					validate = false
				}
				else {
					Child1Value = this.state.dropCD1SegValue.value
					Child2Value = this.state.dropCD2SegValue.value
				}
			}
		}
		else if (this.state.PlaceDD1Visible == true && this.state.PlaceDD2Visible) {

			if (this.state.PlaceDD1Value == [] || this.state.PlaceDD1Value == 0 || this.state.PlaceDD2Value == [] || this.state.PlaceDD2Value == 0) {
				validate = false
			}
			else {
				if (this.state.PlaceDD1Value.value == "" || this.state.PlaceDD2Value.value == "") {
					validate = false
				}
				else {
					Child1Value = this.state.PlaceDD1Value.value
					Child2Value = this.state.PlaceDD2Value.value
				}
			}
		}
		else if (this.state.dropCD1SegVisible == true || this.state.dropCD2SegVisible) {
			if (this.state.dropCD1SegVisible == true) {

				if (this.state.dropCD1SegValue == [] || this.state.dropCD1SegValue == 0) {
					validate = false
				}
				else {
					if (this.state.dropCD1SegValue.value == "") {
						validate = false
					}
					else {
						Child1Value = this.state.dropCD1SegValue.value
					}
				}
			}
			else {

				if (this.state.dropCD2SegValue == [] || this.state.dropCD2SegValue == 0) {
					validate = false
				}
				else {
					if (this.state.dropCD2SegValue.value == "") {
						validate = false
					}
					else {
						Child1Value = this.state.dropCD2SegValue.value
					}
				}
			}
		}
		else if (this.state.PlaceDD1Visible == true || this.state.PlaceDD2Visible) {
			if (this.state.PlaceDD1Visible == true) {

				if (this.state.PlaceDD1Value == [] || this.state.PlaceDD1Value == 0) {
					validate = false
				}
				else {
					if (this.state.PlaceDD1Value.value == "") {
						validate = false
					}
					else {
						Child1Value = this.state.PlaceDD1Value.value
					}
				}
			}
			else {

				if (this.state.PlaceDD2Value == [] || this.state.PlaceDD2Value == 0) {
					validate = false
				}
				else {
					if (this.state.PlaceDD2Value.value == "") {
						validate = false
					}
					else {
						Child2Value = this.state.PlaceDD2Value.value
					}
				}
			}
		}

		let streetLines="";
		let postalCode = "";
		let countryCode = "";
		let state = "";
		let city = "";
		let apartmentNo = "";
		let isDefAddress = false;
        //debugger
        if(this.state.userAddress != ""){
			if (this.state.typedAddress == true) {
				streetLines = this.state.typedStreet.trim();
				postalCode = this.state.typedZipCode.trim();
				countryCode = this.state.typedCountry.trim();
				state = this.state.typedState.trim();
				city = this.state.typedCity.trim();
                apartmentNo =  this.state.typedApartmentNo.trim() ;
				isDefAddress = true;
			}
			else if (this.state.isExistingAddress == true) {
				streetLines = this.state.existStreet.trim();
				postalCode = this.state.existPostal.trim();
				countryCode = this.state.existCountry.trim();
				state = this.state.existState.trim();
				city = this.state.existCity.trim();
				apartmentNo = this.state.existApartmentNo != null && this.state.existApartmentNo.trim() != "" ? this.state.existApartmentNo : "" ;
				isDefAddress = true;
			}
			else {
				streetLines = this.state.isValidStreet.trim();
				postalCode = this.state.isValidZipCode.trim();
				countryCode = this.state.isValidCountryCode.trim();
				state = this.state.isValidState.trim();
				city = this.state.isValidCity.trim();
				apartmentNo = this.state.typedApartmentNo.trim();
				isDefAddress = true;
			}

		}
		//if (validate == true) {
		let UpdPrefBO = {
			UserId: this.state.SelectedUserId,
			DescripType: DescripTypeValue,
			Dept: Departmentvalue,
			ShipTo: ShiptoValue,
			BlockPrice: BlockPrice,
			Child1Value: Child1Value,
			Child2Value: Child2Value,
			countryCode: countryCode,
			Street: streetLines,
			State: state,
			City: city,
			ApartmentNo: apartmentNo,
			IsDefaultAddress: isDefAddress,
			postalCode: postalCode,
			Bus_Unit: this.state.Login_UserBU,
			HomeStore: this.state.HomeStore.value
		}
		console.log("UpdPrefBO", UpdPrefBO)
		ProfileAction.UpdatePreferences(UpdPrefBO)
		//}
		//else {
		//    $(`#${SDIConstants.SDILoader}`).addClass('hide');
		//    document.getElementById('StatusMsg').innerText = "Please select the ChargeCode"
		//}
		//[4/27/22, Bug - 2071, Dhevanesam R
		this.setState({
			StatusMsgErrorVal: StatusMsgError
		})
	}
    /*
    * Ref no:UP_PC_84
    * In this method Perference update status is showmn.
    * 
    * */
	ShowPrefMessage = () => {
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let UpdateStatus = JSON.parse(ProfileStore.UpdatePrefBO);
		let SuceessMessageTable = []
		SuceessMessageTable = UpdateStatus.SuceessMessage
		let updateSessionL = []
		let SetDefAddressResponse = ProfileStore.DefaultAddress;

		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		updateSessionL = UpdateStatus.UpdateSession
		if (SuceessMessageTable.length > 0) {
			StatusMsgError = SuceessMessageTable[0].UpdateSuccess
			if (updateSessionL != null && updateSessionL != undefined) {
				if (updateSessionL.length > 0) {
					CookieService.updateLocalStorageValues("Session_ISAShipToID", updateSessionL[0].ISAShipToID);
					let DescripTypeValue = $('#ClientDesp').is(":checked") ? "P" : "C";
					CookieService.updateLocalStorageValues("Session_PROD_DESCPREFERENCE", DescripTypeValue);
					let BlockPrice = $('#PriceDisplay').is(":checked") ? "Y" : "N";
					CookieService.updateLocalStorageValues("Session_ISAPriceBlock", BlockPrice);
					console.log("BlockPrice", BlockPrice)
					if (updateSessionL[0].Session_HOMESTORE != undefined) {
						CookieService.updateLocalStorageValues("Session_HOMESTORE", updateSessionL[0].Session_HOMESTORE);
					}
				}
			}
		}
		else {
			StatusMsgError = "Technical issue"
		}
		this.setState({
			StatusMsgErrorVal: StatusMsgError
		})
	}
    /*
    * Ref no:UP_PC_86
    * This method is called when Submit in Mobility tab is click to update the Mobility in the database.
    * 
    * */
	UpdateCrib = () => {
		//debugger
		const { t } = this.props;
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		//document.getElementById('StatusMsg').innerText = ""
		//document.getElementById('CribValidate').innerText = ""
		let StatusMsgErrCrib = "";
		window.scrollTo(0, 0)
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');

		let validate = true
		let CribValueL = this.state.CribValue.value
		if (CribValueL == "" || CribValueL == null || CribValueL == undefined) {
			StatusMsgErrCrib = "Please select a crib";
			validate = false
			$(`#${SDIConstants.SDILoader}`).addClass('hide');
		}
		if (validate) {
			let UpdCribBO = {
				UserId: this.state.SelectedUserId,
				Session_UserId: this.state.Login_UserId,
				Crib_Value: CribValueL
			}
			ProfileAction.UpdateCrib(UpdCribBO)
		}
		this.setState({
			StatusMsgErrorVal: StatusMsgError, StatusMsgErrValCrib: StatusMsgErrCrib
		})
	}
    /*
    * Ref no:UP_PC_87
    * In this method Crib update status is showmn.
    * 
    * */
	ShowMobMessage = () => {
		//debugger
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let UpdateStatusL = JSON.parse(ProfileStore.UpdateCribBO);
		console.log("UpdateStatusL", UpdateStatusL)
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		//[03/29/22, PC_UP_01, CAS - Mobility - Successfull msg not displayed, Dhevanesam R]
		let UpdateStatus = UpdateStatusL.SuceessMessage;
		if (UpdateStatus[0].UpdateSuccess != null) {
			//debugger
            StatusMsgError = UpdateStatus[0].UpdateSuccess
            //[11/23/23, Zeus 11 - Updating crib value - Vishalini]
            CookieService.updateLocalStorageValues("Session_ISA_CRIB_IDENT", this.state.CribValue.value)
		}
		else {
			//debugger
			StatusMsgError = UpdateStatus[0].UpdateFailed
		}
		this.setState({
			StatusMsgErrorVal: StatusMsgError
		})
	}
    /*
    * Ref no:UP_PC_96
    * This method is called when Submit in Zeus tab is click to update the zeus status in the database.
    * 
    * */
	UpdateZeus = () => {
		//[4/13/22, Bug - 2071, Dhevanesam R]
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		window.scrollTo(0, 0)
		//[4/27/22, Bug - 2071, Dhevanesam R
		//document.getElementById('StatusMsg').innerText = ""
		let StatusMsgError = "";
		let ZeusStatus = document.getElementById("ZeusLabel").innerText
		if (ZeusStatus == "Desactivar Zeus" || ZeusStatus == "Désactiver Zeus") {
			ZeusStatus = "Disable Zeus"
		}
		else if (ZeusStatus == "Activer Zeus" || ZeusStatus == "Habilitar Zeus") {
			ZeusStatus = "Enable Zeus"
		}
		let ZeusCheckBox = $('#Disable-Zeus').is(":checked") ? "True" : "False";
		if (ZeusCheckBox == "True") {
			let UpdZeusBo = {
				UserId: this.state.SelectedUserId,
				Session_UserId: this.state.Login_UserId,
				Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
				ZeusLabelText: ZeusStatus
			}
			ProfileAction.UpdateZeus(UpdZeusBo)
		}
		else {
			$(`#${SDIConstants.SDILoader}`).addClass('hide');
			StatusMsgError = "Please Select the Option"
			this.setState({
				StatusMsgErrorVal: StatusMsgError
			})
		}
	}
    /*
    * Ref no:UP_PC_97
    * In this method Zeus update status is showmn.
    * 
    * */
	ShowZeusMessage = () => {
		const { t } = this.props
		let UpdateStatusL = JSON.parse(ProfileStore.UpdateZeusBO);
		console.log("UpdateStatusL", UpdateStatusL)
		let ZeusStatus = document.getElementById("ZeusLabel").innerHTML
		let UpdateStatus = UpdateStatusL.SuceessMessage
		let ZeusTitleL = ""
		//[4/27/22, Bug - 2071, Dhevanesam R
		let StatusMsgError = "";
		if (UpdateStatus[0].UpdateSuccess != null) {
			StatusMsgError = UpdateStatus[0].UpdateSuccess
		}
		else {
			StatusMsgError = UpdateStatus[0].UpdateFailed
		}
		document.getElementById("Disable-Zeus").checked = false;
		//[4/13/22, Bug - 2071, Dhevanesam R]
		if (ZeusStatus == "Enable Zeus" || ZeusStatus == "Activer Zeus" || ZeusStatus == "Habilitar Zeus") {
			ZeusTitleL = "Disable Zeus"
		}
		else {
			ZeusTitleL = "Enable Zeus"
		}
		this.setState({ ZeusTitle: ZeusTitleL, StatusMsgErrorVal: StatusMsgError })
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
    /*
     * Ref No: UP_PC_75 && UP_PC_77
    * This method is invoked when the Set Budgetary or Set Requestor button is clicked. 
    * Action call is made to get the approvals details from the database using the api.
    * */
	LoadApprover = () => {
		//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		//[4/27/22, Bug - 2071, Dhevanesam R
		//document.getElementById('StatusMsg').innerText = "";
		let StatusMsgError = "";
		//let Buttype = document.getElementById('ReqBtn').innerText
		if (document.getElementById("customCheckk")) {
			document.getElementById("customCheckk").checked = false;
		}
		let SiteSelected = ""
		if (this.state.SiteList.length > 0) {
			SiteSelected = this.state.SelectSite.value
		}
		else {
			SiteSelected = this.state.GroupID
		}
		let SetReqAppBo = {
			Session_ThirdParty_CompanyID: this.state.SelectedThirdParty,
			UserId: this.state.SelectedUserId,
			Session_UserRole: this.state.LoginUserRole,
			SiteValue: SiteSelected
		}
		//[4/13/22, Bug - 2047 & 2048, Dhevanesam R
		//if (Buttype == "Définir l’approbation du demandeur" || Buttype == "Establecer la aprobación del solicitante") {
		//    Buttype = "Set Requestor Approval"
		//}
		//else if (Buttype == "Définir l’approbation budgétaire" || Buttype == "Establecer aprobación presupuestaria") {
		//    Buttype = "Set Budgetary Approval"
		//}
		if (approvalAccordian == 1) {
			ProfileAction.GetBudApprovals(SetReqAppBo)
			//document.getElementById('ReqBtn').innerText = (t("Set Budgetary Approval"))
			//$('#BudLimitDiv').addClass('hide');
			this.setState({
				ApprovBtnTxt: "Set Budgetary Approval", StatusMsgErrorVal: StatusMsgError
			})

		}
		else if (approvalAccordian == 2) {
			ProfileAction.GetReqApprovals(SetReqAppBo)
			//document.getElementById('ReqBtn').innerText = (t("Set Requestor Approval"))
			//$('#BudLimitDiv').removeClass('hide');
			this.setState({
				ApprovBtnTxt: "Set Requestor Approval", StatusMsgErrorVal: StatusMsgError
			})
		} else if (approvalAccordian == 3) {
			ProfileAction.GetAltBudApprovals(SetReqAppBo)
			this.setState({
				ApprovBtnTxt: "Set Requestor Approval", StatusMsgErrorVal: StatusMsgError
			})
		}
	}
    /*
    * Ref no:UP_PC_76
    * In this method the requestor approvals from is binded with the action response.
    * 
    * */
	BuildReqApprovalForm = () => {
		const { t } = this.props;
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let UpdateStatus = JSON.parse(ProfileStore.LoadReqApprovalsBO);
		//document.getElementById("ApprLabel").innerText = (t("Alternate Requestor Approval by"))
		let ReqApprover = []
		ReqApprover = UpdateStatus.ReqApproverDD
		let ExistApprover = []
		ExistApprover = UpdateStatus.ExistReqApprover

		//if (this.state.LoginUserType) {
		//    $('#BudBtn').addClass('hide');
		//}
		let ExistReqApprL = "";
		let ApproveValue = []
		let ExistApp = []
		//[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R
		//[4/13/22, Bug - 2047 & 2048, Dhevanesam R
		//debugger
		ReqApprover.map((val, index) => {
			if (index == 0) {
				let localArray1 = {
					value: 0,
					label: t("--------Select Approver-------")
				}
				ApproveValue.push(localArray1)
				if (ExistApprover.length == 0 || ReqApprover.indexOf(ExistApprover[0].ISA_REQ_APR_ALT) == -1) {
					ExistApp = localArray1
					$('#removeApprover').addClass('hide');
				}
			}
			let localArray = {
				value: val.ISA_EMPLOYEE_ID,
				label: val.EMP_TEXT
			}
			if (ExistApprover.length > 0) {
				////debugger
				if (val.ISA_EMPLOYEE_ID == ExistApprover[0].ISA_REQ_APR_ALT) {
					ExistApp = localArray
					ExistReqApprL = ExistApprover[0].ISA_REQ_APR_ALT;
					//[8/10/22, Bug - 2349, PS_UP_04, Dhevanesam R]
					$('#removeApprover').removeClass('hide');
				}
			}
			else {
				$('#removeApprover').addClass('hide');
			}
			ApproveValue.push(localArray)
		});
		this.setState({
			ApproverList: ApproveValue, ExistApproveValue: ExistApp, ExistReqAppr: ExistReqApprL, ApprovLabelVal: "Alternate Requestor Approval by"
		})
	}
	//[09/05/22, PS_ZE_192_APP_04,Sathis]-Trigger on the click of alternate budgetary accordian
	BuildAltBudApprovalForm = () => {
		const { t } = this.props;
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let UpdateStatus = JSON.parse(ProfileStore.LoadAltBudApprovalsBO);
		let AltBudApprover = [];
		let ExistApprover = [];
		AltBudApprover = UpdateStatus.AltBudApproverDD
		ExistApprover = UpdateStatus.ExistAltBudApprover
		if (ExistApprover.length > 0) {
			ExistApprover = Object.values(ExistApprover[0])
		}
		let ApproveValue = []
		let ExistApp = []
		AltBudApprover.map((val, index) => {
			let localArray = {
				value: val.ISA_EMPLOYEE_ID,
				label: val.EMP_TEXT
			}
			ApproveValue.push(localArray)
			if (ExistApprover.length > 0) {
				if (ExistApprover.includes(val.ISA_EMPLOYEE_ID)) {
					ExistApp.push(localArray)
				}
			}
		});
		this.setState({
			ApproverList: ApproveValue, ExistApproveValue: ExistApp, updateAltBudgetary: ExistApp,
			delAltBudCheck: ExistApp, delAltBudIcon: ExistApp, selectedApprvValue: ExistApp
		})
	}

	ShowAltBudApprovalStatus = () => {
		const { t } = this.props
		let UpdateStatusL = JSON.parse(ProfileStore.UpdateAltBudApprovalsBO);
		let UpdateStatus = UpdateStatusL.Table1
		let StatusMsgError = "";
		if (UpdateStatus[0].DeleteSuccess != null) {
			StatusMsgError = UpdateStatus[0].DeleteSuccess;
			$(`#removeApprover`).addClass('hide');
		}
		else if (UpdateStatus[0].DeleteFailed != null) {
			StatusMsgError = UpdateStatus[0].DeleteFailed
		}
		else if (UpdateStatus[0].UpdateSuccess != null) {
			StatusMsgError = UpdateStatus[0].UpdateSuccess;
			$(`#removeApprover`).removeClass('hide');
			this.setState({ ExistReqAppr: this.state.ExistApproveValue.value });
		}
		else {
			StatusMsgError = UpdateStatus[0].InsertFailed;
			$(`#removeApprover`).removeClass('hide');
			this.setState({ ExistReqAppr: this.state.ExistApproveValue.value });
		}
		let DeleteAppr = $('#customCheckk').is(":checked") ? "TRUE" : "FALSE";
		if (DeleteAppr == "TRUE") {
			let localArray1 = {
				value: 0,
				label: t("--------Select Approver-------"),
			}
			this.setState({ ExistApproveValue: localArray1, ExistAppLimit: "", initialBudAppLimit: "" })
			if (document.getElementById("customCheckk")) {
				document.getElementById("customCheckk").checked = false;
			}
		}
		this.setState({
			StatusMsgErrorVal: StatusMsgError
		})
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
	}
	onAccordianClick = (event, index, currentAccordion) => {
		let n = ['BudReq', 'AltReq', 'AltBud'];
		$(event.currentTarget).children().toggleClass('accordion-arrow-change');
		if (approvalAccordian == currentAccordion) {
			$(`#contentrow-${index}`).toggleClass('d-none');
			return;
		}
		if (approvalAccordian != 0) {
			let newElement = document.getElementById(`arrow-${n[approvalAccordian - 1]}`);
			let classTest = newElement.classList.contains("accordion-arrow-change");
			if (classTest) {
				$(`#arrow-${n[approvalAccordian - 1]}`).toggleClass('accordion-arrow-change');
			}
		}
		if (index == "BudReq") {
			$('#contentrow-BudReq').removeClass('d-none');
			$('#contentrow-AltReq').addClass('d-none');
			$('#contentrow-AltBud').addClass('d-none');
		}
		else if (index == "AltReq") {
			$('#contentrow-BudReq').addClass('d-none');
			$('#contentrow-AltReq').removeClass('d-none');
			$('#contentrow-AltBud').addClass('d-none');
		}
		else if (index == "AltBud") {
			$('#contentrow-BudReq').addClass('d-none');
			$('#contentrow-AltReq').addClass('d-none');
			$('#contentrow-AltBud').removeClass('d-none');
		}
		approvalAccordian = currentAccordion;
		this.LoadApprover();
	};
    /*
    * Ref no:UP_PC_78
    * In this method the budgetary approvals from is binded with the action response.
    * 
    * */
	BuildBudApprovalForm = () => {
		const { t } = this.props;
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let UpdateStatus = JSON.parse(ProfileStore.LoadBudApprovalsBO);
		//document.getElementById("ApprLabel").innerText = (t("Budgetary Approval by"))
		let ApproverList = UpdateStatus.BudApproverDD;
		let ExistApprover = [];
		ExistApprover = UpdateStatus.ExistBudApprover
		let ApproveValue = []
		let ExistApp = []
		let LimitValue = ""
		let ExistBudApprL = "";
		//debugger
		ApproverList.map((val, index) => {
			if (index == 0) {
				let localArray1 = {
					value: 0,
					label: t("--------Select Approver-------")
				}
				ApproveValue.push(localArray1)
				if (ExistApprover.length == 0) {
					ExistApp = localArray1
				}
			}
			let localArray = {
				value: val.ISA_EMPLOYEE_ID,
				label: val.EMP_TEXT
			}
			if (ExistApprover.length > 0) {
				//debugger
				if (val.ISA_EMPLOYEE_ID == ExistApprover[0].ISA_IOL_APR_EMP_ID) {
					ExistApp = localArray
					LimitValue = ExistApprover[0].ISA_IOL_APR_LIMIT
					ExistBudApprL = ExistApprover[0].ISA_IOL_APR_EMP_ID
					$('#BUdLimitData').val(LimitValue);
					//[8/10/22, Bug - 2349, PS_UP_04, Dhevanesam R]
					$('#removeApprover').removeClass('hide');
				}
			}
			else {
				$('#removeApprover').addClass('hide');
			}
			ApproveValue.push(localArray)
		});
		//if (ExistApprover == this.state.initialApprover) {
		//    $('#removeApprover').removeClass('hide');
		//}
		//else {
		//    $('#removeApprover').addClass('hide');
		//}

		this.setState({
			ApproverList: ApproveValue, ExistApproveValue: ExistApp, ExistAppLimit: LimitValue, initialBudAppLimit: LimitValue, ExistBudAppr: ExistBudApprL, ApprovLabelVal: "Budgetary Approval by"
		})
	}
    /*
    * Ref no:UP_PC_90
    * 
    * In this method the user SDI Track is updated to the database.
    * */
	UpdateTST = (Key) => {
		//[4/27/22, Bug - 2071, Dhevanesam R
		//document.getElementById('StatusMsg').innerText = ""
		let StatusMsgError = "";
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		window.scrollTo(0, 0)
		let selecteuserid = ""
		let SDITrackUserId = ""
		let SDItrackPassword = ""
		if (Key == "1") {
			selecteuserid = document.getElementById('TSTCurentUser').innerText
			SDITrackUserId = $('#TSTUSERID1').val()
			SDItrackPassword = $('#TSTPASSWORD1').val()
		}
		else {
			selecteuserid = document.getElementById('TSTUserName').innerText
			SDITrackUserId = $('#TSTOTHERID').val()
			SDItrackPassword = $('#TSTOTHERPASS').val()
		}
		let AddUserSDITrackBO = {
			Session_TrackDBType: CookieService.getLocalStorageValues("Session_TrackDBType"),
			Session_TrackDBGUID: CookieService.getLocalStorageValues("Session_TrackDBGUID"),
			Session_TrackDBUser: CookieService.getLocalStorageValues("Session_TrackDBUser"),
			Session_TrackDBPassword: CookieService.getLocalStorageValues("Session_TrackDBPassword"),
			SelectedUser: selecteuserid,
			SDITrack_UserId: SDITrackUserId,
			SDITrack_PassWord: SDItrackPassword,
		}
		ProfileAction.AddTrackUser(AddUserSDITrackBO)
		this.setState({
			ApprovBtnTxt: "Set Requestor Approval", StatusMsgErrorVal: StatusMsgError
		})
	}
    /*
    * Ref no:UP_PC_91
    * In this the SDI track update response from the action is received and the status message is binded.
    * 
    * */
	ShowTrackMessage = () => {
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let UpdateStatus = JSON.parse(ProfileStore.LoadBudApprovalsBO);
		AddTSTUSERSuccess = 0;
		let AdduserDetail = []
		AdduserDetail = UpdateStatus.AddedUserDetails
		let SuceessMessage = []
		SuceessMessage = UpdateStatus.SuceessMessage
		if (SuceessMessage.length > 0) {
			if (SuceessMessage[0].UpdateSuccess == null) {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t(SuceessMessage[0].UpdateFailed)
				})
				//alert(t(SuceessMessage[0].UpdateFailed))
			}
			else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t(SuceessMessage[0].UpdateSuccess)
				})
				//alert(t(SuceessMessage[0].UpdateSuccess))
				if (AdduserDetail.length > 0) {
					document.getElementById('ADDUserID').innerText = AdduserDetail[0].UserName
					document.getElementById('ADDGUID').innerText = AdduserDetail[0].GUIDVal
					document.getElementById('ADDEDON').innerText = AdduserDetail[0].DateTime
					$('#ADDEDUSERDiv').removeClass('hide')
					$('#SDITrackPassDiv').addClass('hide')

				}
			}
		}
	}
    /*
    * Ref no:UP_PC_89
    * This method is invoked when the store emits
    * The SDI track User from is binded with selected user sdi track details
    * */
	BindSelectUserTrackData = () => {
		//[5/10/22, CAS - 31, Dhevanesam R]
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let TSTValue1 = JSON.parse(ProfileStore.SelecttrackBO);
		let TSTValue = TSTValue1.ADDUserFormTable
		console.log("TSTValue", TSTValue)
		let ErrorMessage = ""
		let TSTError = ""
		if (TSTValue[0].OtherUserBUMessage != null || TSTValue[0].OtherUserNoBU != null) {
			ErrorMessage = TSTValue[0].OtherUserBUMessage != null ? TSTValue[0].OtherUserBUMessage : TSTValue[0].OtherUserNoBU
			//document.getElementById("TSTError").innerText = (t(ErrorMessage));
			TSTError = ErrorMessage
			$('#AddTSTUserDiv').addClass('hide');
			$('#AddUserDiv').addClass('hide');
		}
		document.getElementById("TSTUserName").innerText = TSTValue[0].OtherUserID;
		document.getElementById("TSTuserBu").innerText = TSTValue[0].BusinessUnit;
		$('#ADDEDUSERDiv').addClass('hide')
		this.setState({
			TSTErrorVal: TSTError
		})
	}
    /*
    * Ref no:UP_PC_65
    * 
    * This method is invoked when the cancel button is clicked.
    * Redirected to the homepage.
    * */
	COStatus = () => {
		if (this.state.PageAction != "ADD") {
			this.props.history.push({
				pathname: "/HomePage",
			});
		}
		else {
			window.location.reload(false);
		}
	}
    /*
    * This method is invoked when the check all or expand all or select all 
    * or deselect all button is clicked in the user privileges page.
    * By using the key value find the button clicked and respectively set the value.
    * */
	PrivTreeAction = (Key) => {
		let PrivTreeList = this.state.PrivTree
		let checkvalues = []
		switch (Key) {
			case "E": {
				PrivTreeList.map((val, index) => {
					//Zeus - 206 - Fix the expand all function not working for Catagory - Johnprinto D
					let iscat = PrivTreeList.filter((x) => x.ISA_CATEGORY_ID == val.ISA_CATEGORY_ID);
					let isParent = PrivTreeList.filter((x) => x.ISA_PARENT_IDENT == val.ISA_PARENT_IDENT);
					if (iscat.length > 0) {
						let ispresent = checkvalues.some((x) => x == val.CATSECALIAS);
						if (checkvalues.length == 0 || ispresent == false) {
							checkvalues.push(val.CATSECALIAS)
						}

					}
					if (isParent.length > 0) {
						let ispresent = checkvalues.some((x) => x == val.PARENTSECALIAS);
						if (checkvalues.length == 0 || ispresent == false) {
							checkvalues.push(val.PARENTSECALIAS)
						}
					}
				});
				this.setState({ expanded: checkvalues, isExpandAll: false })
				break
			}
			case "C": {
				this.setState({ expanded: checkvalues, isExpandAll: true })
				break
			}
			case "D": {
				checkvalues.push("HOME")
				this.setState({ checked: checkvalues })
				break
			}
			case "S": {
				debugger
				PrivTreeList.map((val, index) => {
					checkvalues.push(val.SECURITYALIAS)
					//Disrole = true
				});
				this.setState({ checked: checkvalues })
				break
			}
		}

	}
    /*
    * Ref no:UP_PC_99 && UP_PC_101 && UP_PC_103 && UP_PC_105
    * 
    * This method is invoked when the activate site or de active site or activate employee account or de activate employee account is click.
    * Api call is method from the action to update the employee status to the database.
    * */
	ActiveEmployeeAccount = (Key) => {

		let SiteSelected = ""
		let AccountActiveL = 0
		if (this.state.SiteList.length > 0) {
			SiteSelected = this.state.SelectSite.value
		}
		else {
			SiteSelected = this.state.GroupID
		}
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		if (Key == 1) {
			let ButtonValue = document.getElementById('EmpyAcctBtn').innerText

			let ActiveEmpAccountBo = {
				HidenUserId: this.state.HideuserId,
				UserId: this.state.SelectedUserId,
				UserBu: this.state.selectSiteID,
				SessionUserId: this.state.Login_UserId,
				ActiveStatus: this.state.EmployeeAccountActiveStatus,
				Page_Action: "EDIT",
				UserType: this.state.UserType,
				Session_USERTYPE: this.state.LoginUserType,
				Session_UserRole: this.state.LoginUserRole,
				Query_IsMexico: "False",
				Query_IsVendor: "False",
				SiteValue: SiteSelected,
				Session_WEBSITED: CookieService.getLocalStorageValues("Session_WEBSITED"),
			}
			//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
			if (ButtonValue == "In-Activate Employee Account" || ButtonValue == "Compte d’employé in-activate" || ButtonValue == "Cuenta de empleado activada") {
				AccountActiveL = 1
				ProfileAction.DeActivateEmpyAcc(ActiveEmpAccountBo)
			}
			else {
				AccountActiveL = 2
				ProfileAction.ActivateEmpyAcc(ActiveEmpAccountBo)
			}
		}
		else {
			let ButtonValue = document.getElementById('AcctBtn').innerText
			let ActiveAccountBo = {
				HidenUserId: this.state.HideuserId,
				UserId: this.state.SelectedUserId,
				SessionUserId: this.state.Login_UserId,
				ActiveStatus: this.state.UserActiveStatus,
				Page_Action: "EDIT",
				UserType: this.state.UserType,
				Session_USERTYPE: this.state.LoginUserType,
				Session_UserRole: this.state.LoginUserRole,
				Query_IsMexico: "False",
				Query_IsVendor: "False",
				SiteValue: SiteSelected,
				Session_WEBSITED: CookieService.getLocalStorageValues("Session_WEBSITED"),
			}
			//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
			if (ButtonValue == "In-Activate Account" || ButtonValue == "Compte in-activate" || ButtonValue == "Cuenta activada") {
				AccountActiveL = 3
				ProfileAction.DeActivateSite(ActiveAccountBo)
			}
			else {
				AccountActiveL = 4
				ProfileAction.ActivateSite(ActiveAccountBo)
			}
		}
		this.setState({ AccountActive: AccountActiveL })
	}
    /*
    * Ref no:UP_PC_100 && UP_PC_102 && UP_PC_104 && UP_PC_106
    * This is invoked when action response is emit with store listner once the employee account activated or deactivated and
    * similarily the site account is activated or deactivated.
    * 
    * */
	ShowActivateStatus = () => {
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let status = JSON.parse(ProfileStore.ActivateSiteAcctBO);
		console.log(status)
		let UpdateStatus = status.SuceessMessage
		let UpdateActiveStatus = status.updateStatus
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		let EmpLabelErrMsg = "";
		let AcctLabelErrMsg = "";
		let InActEmpLblVal = "";
		let InActAccLblVal = "";
		//$('#EmpLabel').removeClass('hide');
		//$('#AcctLabel').removeClass('hide');
		if (UpdateStatus[0].UpdateSuccess != null && UpdateStatus[0].UpdateSuccess != undefined) {
			if (this.state.AccountActive == 1) {
				InActEmpLblVal = "Activate Employee Account"
				$('#EmpLabel').removeClass('hide');
				EmpLabelErrMsg = UpdateStatus[0].UpdateSuccess
				this.setState({ EmployeeAccountActiveStatus: UpdateActiveStatus[0].ActiveStatus, EmpLabelErr: EmpLabelErrMsg, InActEmpLbl: InActEmpLblVal })
			}
			else if (this.state.AccountActive == 2) {
				InActEmpLblVal = "In-Activate Employee Account"
				$('#EmpLabel').removeClass('hide');
				EmpLabelErrMsg = UpdateStatus[0].UpdateSuccess
				this.setState({ EmployeeAccountActiveStatus: UpdateActiveStatus[0].ActiveStatus, EmpLabelErr: EmpLabelErrMsg, InActEmpLbl: InActEmpLblVal })
			}
			else if (this.state.AccountActive == 3) {
				InActAccLblVal = "Activate Account"
				$('#AcctLabel').removeClass('hide');
				AcctLabelErrMsg = UpdateStatus[0].UpdateSuccess
				this.setState({ UserActiveStatus: UpdateActiveStatus[0].ActiveStatus, AcctLabelErr: AcctLabelErrMsg, InActAccLbl: InActAccLblVal})
			}
			else if (this.state.AccountActive == 4) {
				InActAccLblVal = "In-Activate Account"
				$('#AcctLabel').removeClass('hide');
				AcctLabelErrMsg = UpdateStatus[0].UpdateSuccess
				this.setState({ UserActiveStatus: UpdateActiveStatus[0].ActiveStatus, AcctLabelErr: AcctLabelErrMsg, InActAccLbl: InActAccLblVal})
			}
		}
		else {
			//alert(t("Technical issue"))
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Technical issue")
			})
		}
	};

	handleProfileFormChange = (event) => {
		//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R
		//debugger
		let EnterFirstNameErrMsg = this.state.EnterFirstNameErr;
		let EnterLastNameErrMsg = this.state.EnterLastNameErr;
		let EnterEmailNameErrMsg = this.state.EnterEmailNameErr;
		let EnterPhoneNameErrMsg = this.state.EnterPhoneNameErr;
		let EnterPassNameErrMsg = this.state.EnterPassNameErr;
		let EnterConPassNameErrMsg = this.state.EnterConPassNameErr;
		let ErrorMagSiteErrMsg = this.state.ErrorMagSiteErr;
		let ErrorMegSiteErrMsg = this.state.ErrorMegSiteErr;
		let locuserProfileErr = this.state.userProfileErr == "" ? false : true;
		if (event.target.name == "FirstName") {
			if (event.target.value == "") {
				EnterFirstNameErrMsg = " ";
				locuserProfileErr = true;
			}
			else {
				if(EnterFirstNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("FirstName");
				}
				event.target.value = event.target.value.replace(/[^\x20-\xFF]/g, "");
				EnterFirstNameErrMsg = "";
			}
		}
		else if (event.target.name == "LastName") {
			if (event.target.value == "") {
				EnterLastNameErrMsg = " ";
				locuserProfileErr = true;
			}
			else {
				if(EnterLastNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("LastName");
				}
				event.target.value = event.target.value.replace(/[^\x20-\xFF]/g, "");
				EnterLastNameErrMsg = "";
			}
		}
		else if (event.target.name == "EmailAddres") {
			if (event.target.value == "") {
				EnterEmailNameErrMsg = " ";
				locuserProfileErr = true;
			}
			else {
				if(EnterEmailNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("EmailAddres");
				}
				event.target.value = event.target.value.replace(/[^\x20-\xFF]/g, "");
				EnterEmailNameErrMsg = "";
				var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
				if (!pattern.test(event.target.value)) {
					EnterEmailNameErrMsg = "Please enter valid email address";
				}
			}
		}
		else if (event.target.name == "PhoneNum") {
			if (event.target.value == "") {
				EnterPhoneNameErrMsg = " "
				locuserProfileErr = true
				// document.getElementById("ErrorMagPhone").className = "DropDown-Font-Size errorcolour ";
			}
			else {
				if(EnterPhoneNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("PhoneNum");
				}
				EnterPhoneNameErrMsg = "";
				if (event.target.value.length < 10) {
					EnterPhoneNameErrMsg = "Please enter valid phone Number";
					document.getElementById("ErrorMagPhone").className = "DropDown-Font-Size errorcolour ";
				}
			}
		}
		else if (event.target.name == "PassNTB") {
			if (event.target.value == "") {
				EnterPassNameErrMsg = " ";
				locuserProfileErr = true;
			}
			else {
				if(EnterPassNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("PassNTB");
				}
				EnterPassNameErrMsg = "";
				let firstletter1 = event.target.value.slice(0, 1)
				let Numtest = event.target.value.match(/[0-9]/g)
				if (event.target.value.length < 6) {
					EnterPassNameErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"
				}
				else if ((/[a-zA-Z]/).test(firstletter1) == false || Numtest == null || Numtest == undefined || Numtest == "") {
					EnterPassNameErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!"
				}
				else {
					EnterPassNameErrMsg = "";
				}
			}
		}
		else if (event.target.name == "ConPasNTB") {
			let Password = document.getElementById("PassNTB").innerText
			let Passwordl = $('#PassNTB').val()
			console.log("Password", Passwordl)
			if (event.target.value == "" && Passwordl != null && Passwordl != "") {
				EnterConPassNameErrMsg = " ";
				locuserProfileErr = true;
			}
			else if (Passwordl == null || Passwordl == "") {
				if(EnterConPassNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("ConPasNTB");
				}
				EnterPassNameErrMsg = " ";
			}
			else if (Passwordl != event.target.value) {
				if(EnterConPassNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("ConPasNTB");
				}
				EnterConPassNameErrMsg = "Password doesn't match";
			}
			else {
				if(EnterConPassNameErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("ConPasNTB");
				}
				EnterConPassNameErrMsg = "";
				let firstletter = event.target.value.slice(0, 1)
				let NumtestC = event.target.value.match(/[0-9]/g)
				if (event.target.value.length < 6) {
					EnterConPassNameErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"
				}
				else if ((/[a-zA-Z]/).test(firstletter) == false || NumtestC == null || NumtestC == undefined || NumtestC == "") {
					EnterConPassNameErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!"
				}
				else {
					EnterConPassNameErrMsg = "";
				}
			}
		}
		else if (event.target.name == "SiteName") {
			if (event.target.value == "") {
				ErrorMagSiteErrMsg = " ";
				locuserProfileErr = true;
			}
			else {
				if(ErrorMagSiteErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("SiteName");
				}
				ErrorMagSiteErrMsg = "";
			}
		}
		else if (event.target.name == "TypeName") {
			if (event.target.value == "") {
				ErrorMegSiteErrMsg = " ";
				locuserProfileErr = true;
			}
			else {
				if(ErrorMegSiteErrMsg == " "){
					locuserProfileErr = this.handleProfileValidation("TypeName");
				}
				ErrorMegSiteErrMsg = "";
			}
		}else if (event.target.name == "VendorId") {
			if (event.target.value == "") {
				document.getElementById('VendorGroupValue').innerText = "Please enter the vendor ID"
				$('#VendorID').addClass('border-color-red');
			}
			else {
				document.getElementById("VendorGroupValue").innerHTML = "";
				$('#VendorID').removeClass('border-color-red');
			}
		}
		if (event.target.name == "PhoneNum" || event.target.name == "ExistAppLimit") {
			console.log(isNaN(event.target.value))
			if (!isNaN(event.target.value) && event.target.name == "PhoneNum") {

				this.setState({ [event.target.name]: event.target.value });
			}
			else if ((!isNaN(event.target.value) || event.target.value == "") && event.target.name == "ExistAppLimit") {
				this.setState({ [event.target.name]: event.target.value });
			}
		}
		else {
			this.setState({ [event.target.name]: event.target.value });
		}
		this.setState({
			userProfileErr: locuserProfileErr ? "Please fill all the required fields" : "",EnterFirstNameErr: EnterFirstNameErrMsg, EnterLastNameErr: EnterLastNameErrMsg, EnterEmailNameErr: EnterEmailNameErrMsg, EnterPhoneNameErr: EnterPhoneNameErrMsg, EnterPassNameErr: EnterPassNameErrMsg, EnterConPassNameErr: EnterConPassNameErrMsg, ErrorMagSiteErr: ErrorMagSiteErrMsg, ErrorMegSiteErr: ErrorMegSiteErrMsg
		})
	};
	//[09/05/22, PS_ZE_192_APP_05,Sathis]-Display selected alternate budgetary in grid
	bindAlternateGrid = () => {
		if (this.state.ExistApproveValue.length > 0) {
			return this.state.ExistApproveValue.map((value, index) => {
				return (
					<tr>
						<td className="text-nowrap text-center">
							<input type="checkbox" className="custom-control-del" id={`AltBudgCheckbox${index + 1}`} style={{"opacity":"5","height":'1.25rem',"width":'1rem'}} onChange={(e) => this.handleDelCheckOnchange(e,value, index)} />

						</td>
						<td className="text-nowrap">
							<label id={`AltBudg${index + 1}`}>{value.label}</label>
						</td>
						<td className="text-nowrap text-center">
							<img src="Images/trash.svg" id={`AltBudgDelIcon${index + 1}`} className="del-icon" style={{ "width": '20px', "margin-left": '2px' }} onClick={() => this.handleDelIconAndPopup("Delete", value, index)} ></img>
						</td>
					</tr>
				)
			})
		}
	}
	//[09/05/22, PS_ZE_192_APP_06,Sathis]- To trigger on the click of delete checkbox
	handleDelCheckOnchange = (event, value, index) => {
		let tempArray = JSON.parse(JSON.stringify(this.state.delAltBudCheck));
		if (event.target.checked) {
			tempArray[index] = { value: " ", label: " " };
			$(`#AltBudgDelIcon${index + 1}`).addClass('del-icon-disable');
			checkboxCount++
		}
		else {
			tempArray[index] = value;
			$(`#AltBudgDelIcon${index + 1}`).removeClass('del-icon-disable');
			checkboxCount--
		}
		if (checkboxCount == this.state.ExistApproveValue.length) {
			$('#AltBudgMultiselect').prop('checked', true);
		}
		else {
			$('#AltBudgMultiselect').prop('checked', false);
		}

		this.setState({ delAltBudCheck: tempArray });
	}
	//[09/05/22, PS_ZE_192_APP_06,Sathis]- To trigger on the click of delete icon
	handleDelIconAndPopup = (event, value, index) => {
		let tempArray = this.state.delAltBudIcon;
		if (event == "Delete") {
			tempArray = tempArray.filter((val, ind) => ind !== index);
			this.setState({ delAltBudIcon: tempArray });
			$('#delete-alt-approver').modal('show');
		}
		else if (event == "Yes") {
			$('#delete-alt-approver').modal('hide');
			this.UpdateApprovals(this.state.delAltBudIcon)
		}
		else {
			this.setState({ delAltBudIcon: this.state.ExistApproveValue });
			$('#delete-alt-approver').modal('hide');
		}
	}
	handleMultiCheckOnchange = (event) => {
		let tempArray = JSON.parse(JSON.stringify(this.state.delAltBudCheck));
		if (event.target.checked) {
			checkboxCount = tempArray.length;
			tempArray.forEach((value, index) => {
				tempArray[index] = { value: " ", label: " " };
				$(`#AltBudgCheckbox${index + 1}`).prop('checked', true);
				$(`#AltBudgDelIcon${index + 1}`).addClass('del-icon-disable');
			})
			this.setState({ delAltBudCheck: tempArray, isMultiSelectChecked:true });
		}
		else {
			tempArray.forEach((value, index) => {
				checkboxCount = 0;
				/*tempArray[index] = value;*/
				$(`#AltBudgCheckbox${index + 1}`).prop('checked', false);
				$(`#AltBudgDelIcon${index + 1}`).removeClass('del-icon-disable');
			})
			this.setState({ delAltBudCheck: this.state.ExistApproveValue, isMultiSelectChecked: false });
		}
	}
    /*
    * Ref no:UP_PC_38 &&UP_PC_38
    * Render is called when ever the variable is setstated
    * Render returns the html with data binded of the user profile page
    * */
	render() {
		//[11/26/21, CAS - 31, Dhevanesam R]
		const { t } = this.props
		return (

			<div className="container-fluid content-margin Top-margin custom-mb">
				<div className="row">
					<div className="col-md-12 page-heading mb-3">
						{/*[04/08/22, CAS - 31, Dhevanesam R]*/}
						<h5 className="page-title"><Trans>Profile Update</Trans></h5>
						<div className="fav-item-maintenance-icon mt-1">
							<input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
							<label htmlFor="checkbox-1">
								<svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
									<g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
										<path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
										<circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
										<g className="grp7" opacity={0} transform="translate(7 6)">
											<circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
											<circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
										</g>
										<g className="grp6" opacity={0} transform="translate(0 28)">
											<circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
											<circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
										</g>
										<g className="grp3" opacity={0} transform="translate(52 28)">
											<circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
											<circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
										</g>
										<g className="grp2" opacity={0} transform="translate(44 6)">
											<circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
											<circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
										</g>
										<g className="grp5" opacity={0} transform="translate(14 50)">
											<circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
											<circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
										</g>
										<g className="grp4" opacity={0} transform="translate(35 50)">
											<circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
											<circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
										</g>
										<g className="grp1" opacity={0} transform="translate(24)">
											<circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
											<circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
										</g>
									</g>
								</svg>
							</label>
						</div>
					</div>
					<div className="col-md-12">
						<ul className="nav nav-tabs catalog-tabs  custom-tab-style  catalog-tabs-mobile profile-update-tab" id="myTab" role="tablist">
							<li className="nav-item" id="ProfileTab">
								<a className="nav-link active" id="user-details-tab" data-toggle="tab" href="#user-details" role="tab" aria-controls="user-details" aria-selected="true" onClick={() => this.TabClick("UD")}><Trans>User Details</Trans></a>
							</li>
							<li className="nav-item" id="privilegesTab">
								<a className="nav-link" id="user-privileges-tab" data-toggle="tab" href="#user-privilege" role="tab" aria-controls="user-privilege" aria-selected="false" onClick={() => this.TabClick("UPVL")}><Trans>User Privileges</Trans></a>
							</li>
							<li className="nav-item" id="approvalsTab">
								<a className="nav-link" id="approvals" data-toggle="tab" href="#approvals-link" role="tab" aria-controls="approvals-link" aria-selected="false" onClick={() => this.TabClick("APP")}><Trans>Approvals</Trans></a>
							</li>
							<li className="nav-item" id="orderSETab">
								<a className="nav-link" id="order-status-emails" data-toggle="tab" href="#order-status-link" role="tab" aria-controls="order-status-link" aria-selected="false" onClick={() => this.TabClick("OSE")}><Trans>Order Status Emails</Trans></a>
							</li>
							<li className="nav-item" id="preferencesTab">
								<a className="nav-link" id="preferences" data-toggle="tab" href="#preferences-link" role="tab" aria-controls="preferences-link" aria-selected="false" onClick={() => this.TabClick("PREF")}><Trans>Preferences</Trans></a>
							</li>
							<li className="nav-item" id="mobilityTab">
								<a className="nav-link" id="mobility" data-toggle="tab" href="#mobility-link" role="tab" aria-controls="mobility-link" aria-selected="false" onClick={() => this.TabClick("MOB")}><Trans>Mobility</Trans></a>
							</li>
							<li className="nav-item" id="zeusTab">
								<a className="nav-link" id="zeus" data-toggle="tab" href="#zeus-link" role="tab" aria-controls="zeus-link" aria-selected="false" onClick={() => this.TabClick("ZEUS")}>Zeus</a>
							</li>
							<li className="nav-item" id="sditrackTab">
								<a className="nav-link" id="sdi-track" data-toggle="tab" href="#sdi-track-link" role="tab" aria-controls="sdi-track-link" aria-selected="false" onClick={() => this.TabClick("TST")}><Trans>SDI Track</Trans></a>
							</li>
						</ul>
						{/*[4/27/22, Bug - 2071, Dhevanesam R*/}
						<div className="tab-content catalog-tab-content Catalog-Items" id="myTabContent">
							<div className="errorspan" > <span id="StatusMsg">{t(this.state.StatusMsgErrorVal)}</span></div>
							<div className="tab-pane p-3 fade show active" id="user-details" role="tabpanel" aria-labelledby="user-details-tab">
								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="float-right">
											<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4 ml-2" id="ChangePasBtn" onClick={() => this.ChgPwd()}><Trans>Change Password</Trans></button>
											<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4 ml-2" id="AddUserBtn" onClick={() => this.AddUser()}><Trans>Add User</Trans></button>
											<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" id="EditMode" onClick={() => this.EditUser()}><Trans>Edit Mode</Trans></button>
										</div>
									</div>
									<div className="col-md-12 border-bottom mb-4">
										<div className="row">
											<div className="col-md-6 mb-4 Ipad-UI">
												<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>User ID</Trans></label>
												{/*[3/20/2023, PS_CAS_47_01, Dhevanesam R*/}
												<div className="float-left col-md-8">
													<input type="text" id="TBUserid" maxLength="30" className="form-control w-75" placeholder={t("Enter Value")} value={this.state.SelectedUserId} disabled />
												</div>
											</div>
											<div className="col-md-6 mb-4 Ipad-UI" id="UserTypeDiv">
												<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>User Type</Trans></label>
												<div className="float-left col-md-8">
													<label className="custom-radiobtn mr-2 my-2"><Trans>SDI Employee</Trans>
														<input type="radio" name="radio" checked={this.state.SDIEMp} id="UtypeSDI" onChange={() => this.UserTypeRadio("S")} />
														<span className="checkmark" />
													</label>
													<label className="custom-radiobtn mr-2 my-2"><Trans>Vendor</Trans>
														<input type="radio" name="radio" checked={this.state.vendor} id="UtypeCustomer" onChange={() => this.UserTypeRadio("V")} />
														<span className="checkmark" />
													</label>
													<label className="custom-radiobtn mr-2 my-2"><Trans>Customer</Trans>
														<input type="radio" name="radio" checked={this.state.Customer} id="UtypeVendor" onChange={() => this.UserTypeRadio("C")} />
														<span className="checkmark" />
													</label>
												</div>
											</div>
											<div className="col-md-6 mb-4 Ipad-UI" id="SearchDIv">
												<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Search User</Trans></label>
												<div className="float-left col-md-8">
													<Select
														className="w-75 DropDown-Font-Size"
														value={this.state.selectedOption}
														onChange={this.handleChange}
														options={this.state.SearchUser}
													/>
												</div>
											</div>
											{/*//[6/8/22, PC_UP_01, CAS - User profile translation issue, Dhevanesam R*/}
											<div className="col-md-6 mb-4 Ipad-UI" id="VendorDiv">
												<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Vendor ID</Trans></label>
												<div className="float-left col-md-8">
													<input type="text" id="VendorID" className={`form-control w-75`} placeholder={t("Enter Value")} maxLength="30" name="VendorId" value={this.state.VendorId} onBlur={() => this.VendorIdChaneg()} onChange={this.handleProfileFormChange} />
													<span id="VendorGroupValue" className="DropDown-Font-Size errorcolour"><Trans>{this.state.VendorGroup}</Trans></span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>f_name</Trans></label>
										<div className="float-left col-md-8">
											<input type="text" className={`form-control w-75 changeevent ${this.state.EnterFirstNameErr != "" ? 'border-color-red' : ""}`} placeholder={t("Enter First Name")} maxLength="30" name="FirstName" id="FirstNTB" value={this.state.FirstName} onChange={this.handleProfileFormChange} />
											{/* <span id="ErrorMagFirstName" className="DropDown-Font-Size errorcolour"><Trans>{this.state.EnterFirstNameErr}</Trans></span> */}
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>l_name</Trans></label>
										<div className="float-left col-md-8">
											<input type="text" className={`form-control w-75 changeevent ${this.state.EnterLastNameErr != "" ? 'border-color-red' : ""}`} placeholder={t("Enter Last Name")} name="LastName" id="LastNTB" maxLength="30" value={this.state.LastName} onChange={this.handleProfileFormChange} />
											{/* <span id="ErrorMagLastName" className="DropDown-Font-Size errorcolour"><Trans>{this.state.EnterLastNameErr}</Trans></span> */}
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Email Address</Trans></label>
										<div className="float-left col-md-8">
											{/*[5/26/2022] Increased email address field length to 60 after Ben's request -Poornima S*/}
											<input type="text" className={`form-control w-75 changeevent ${this.state.EnterEmailNameErr != "" ? 'border-color-red' : ""}`} placeholder={t("Enter E-Mail address")} name="EmailAddres" id="EmailNTB" maxLength="60" value={this.state.EmailAddres} onChange={this.handleProfileFormChange} />
											<span id="ErrorMagEMailAdd" className="DropDown-Font-Size errorcolour"><Trans>{this.state.EnterEmailNameErr}</Trans></span>
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Phone</Trans></label>
										<div className="float-left col-md-8">
											<input type="text" className={`form-control w-75 changeevent ${this.state.EnterPhoneNameErr != "" ? 'border-color-red' : ""}`} placeholder={t("Enter Phone number")} name="PhoneNum" id="PhoneNumNTB" maxLength="10" value={this.state.PhoneNum} onChange={this.handleProfileFormChange} />
											<span id="ErrorMagPhone" className="DropDown-Font-Size errorcolour"><Trans>{this.state.EnterPhoneNameErr}</Trans></span>
										</div>
									</div>
									<div className="col-md-6  mb-4 Ipad-UI" id="PassDiv">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Password</Trans></label>
										<div className="float-left col-md-8">
											<InputBase type={this.state.showPassword ? "text" : "password"} className={`form-control w-75 changeevent ${this.state.EnterPassNameErr != "" ? 'border-color-red' : ""}`} id="PassNTB" name="PassNTB" placeholder={t("Enter Password")} maxLength="10" value={this.state.PassNTB} onChange={this.handleProfileFormChange}
												endAdornment={
													<InputAdornment position="end" >
														<IconButton
															style={{ color:  "#3e3e3e" }}
															onClick={(e) => this.handleClickShowPassword(e)}
														>
															{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												} />
											<div className="errorSpanWidth"><span id="ErrorMagPass" className="DropDown-Font-Size errorcolour"><Trans>{this.state.EnterPassNameErr}</Trans></span></div>
										</div>
									</div>
									<div className="col-md-6  mb-4 Ipad-UI" id="ConfPassDiv">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Confirm Password</Trans></label>
										<div className="float-left col-md-8">
											<InputBase type={this.state.showPassword1 ? "text" : "password"} className={`form-control w-75 changeevent ${this.state.EnterConPassNameErr != ""?'border-color-red':""}`} id="ConPasNTB" name="ConPasNTB" placeholder={t("Enter Confirm Password")} maxLength="10" value={this.state.ConPasNTB} onChange={this.handleProfileFormChange}

												endAdornment={
													<InputAdornment position="end" >
														<IconButton
															style={{ color: "#3e3e3e" }}
															onClick={(e) => this.handleClickShowPassword1(e)}
														>
															{this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												} />
											<div className="errorSpanWidth"><span id="ErrorMagConfPass" className="DropDown-Font-Size errorcolour"><Trans>{this.state.EnterConPassNameErr}</Trans></span></div>
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Site</Trans></label>
										<div className="float-left col-md-8">
											{this.state.SiteList.length > 0 ? <div><Select
												className="w-75 DropDown-Font-Size changeevent"
												value={this.state.SelectSite}
												onChange={this.handleChangeSite}
												options={this.state.SiteList}
												name="SiteName"
												styles={{
													control: (provided, state) => ({
														...provided,
														borderColor: this.state.ErrorMagSiteErr != "" ? "#FF0000" : "#ced4da",
														boxShadow: this.state.ErrorMagSiteErr != "" && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
														"&:hover": {
															...provided,
															borderColor: this.state.ErrorMagSiteErr != "" ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
														}
													}),
												}}
											/> </div> : <input type="text" id="SiteGroup" className="form-control w-75" name="SiteName" value={this.state.SiteTitleValue} disabled />}
											<span id="ErrorMagSite" className="DropDown-Font-Size errorcolour"><Trans>{this.state.ErrorMagSiteErr}</Trans></span>
											<div id="MultiDiv">
												<div className="custom-control custom-checkbox my-2 item-custom-control d-inline-block">
													<input type="checkbox" className="custom-control-input" id="MultiSelectUP" name="example4" checked={this.state.MultiSiteChecked == true ? true : false} onChange={() => this.MultiSiteCheckBtn()} />
													<label className="custom-control-label priority-checkbox profile-update-checkbox" htmlFor="MultiSelectUP"><Trans>Enable Multisite Access</Trans></label>
												</div>
												<div id="MultiSelectDD">
													<MultiSelect
														options={this.state.MultiSite}
														value={this.state.MultiSiteSelected}
														onChange={this.handleMultiSiteDD}
														labelledBy={"Select"}
														className="w-75 DropDown-Font-Size"
													/>
												</div>
											</div>

										</div>
									</div>
									<div className="col-md-6  mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>type</Trans></label>
										<div className="float-left col-md-8">
											<Select
												isDisabled={this.state.DisableTypeDD}
												className="w-75 DropDown-Font-Size changeevent"
												name="TypeName"
												value={this.state.SelectUserRole}
												onChange={this.handleChangeUserROle}
												options={this.state.UserRoleDD}
												styles={{
													control: (provided, state) => ({
														...provided,
														borderColor: this.state.ErrorMegSiteErr != "" ? "#FF0000" : "#ced4da",
														boxShadow: this.state.ErrorMegSiteErr != "" && state.isFocused ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
														"&:hover": {
															...provided,
															borderColor: this.state.ErrorMegSiteErr != "" ? '0 0 0 1px rgb(255, 0, 0)' : 'initial',
														}
													}),
												}}
											/>
											{/* <span id="ErrorMegSite" className="DropDown-Font-Size errorcolour"><Trans>{this.state.ErrorMegSiteErr}</Trans></span> */}
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI" id="DepartDiv">
										<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>dept</Trans></label>
										<div className="float-left col-md-8">
											<Select
												placeholder={t("Select department")}
												className="w-75 DropDown-Font-Size"
												value={this.state.DepartMent}
												onChange={this.handleChangeDepart}
												options={this.state.Depart}
											/>
										</div>
									</div>
									<div className="col-md-12 my-3">
										<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" id="EmpyAcctBtn" onClick={() => this.ActiveEmployeeAccount(1)}><Trans>{this.state.InActEmpLbl}</Trans></button>
										<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4 mr-2" id="AcctBtn" onClick={() => this.ActiveEmployeeAccount(2)}><Trans>{this.state.InActAccLbl}</Trans></button>

									</div>
									<div className="col-md-12">
										<span id="EmpLabel" className="float-right px-4 DropDown-Font-Size errorcolour"><Trans>{this.state.EmpLabelErr}</Trans></span>
										<span id="AcctLabel" className="float-right px-4 mr-2 DropDown-Font-Size errorcolour"><Trans>{this.state.AcctLabelErr}</Trans></span>
									</div>

									<div className="col-md-12 my-3">
										<div> <p className="errormsg mr-2 mb-3" id="StatusMsg1"><Trans>{this.state.userProfileErr}</Trans></p></div>
										<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.btnSave_Click()} data-backdrop="static" data-keyboard="false"><Trans>Submit</Trans></button>
										<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
									</div>
								</div>
							</div>
							<div className="tab-pane p-3 fade" id="user-privilege" role="tabpanel" aria-labelledby="user-privileges-tab">
								<div className="row">
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label p-0"><Trans>User ID</Trans></label>
										{/*[3/20/2023, PS_CAS_47_01, Dhevanesam R*/}
										<div className="float-left col-md-8">
											<input type="text" className="form-control w-75" maxLength="30" placeholder={t("Enter Value")} value={this.state.SelectedUserId} disabled />
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI" id="PrivTypeDIv">
										<label className="float-left col-md-4 form-label catalog-label p-0"><Trans>Privilege Type</Trans></label>
										<div className="float-left col-md-8">
											<label className="custom-radiobtn mr-2 my-2"><Trans>A la Carte</Trans>
												<input type="radio" id="Alacart" name="radio2" onClick={() => this.OnchangePrivType("0")} />
												<span className="checkmark" />
											</label>
											<label className="custom-radiobtn mr-2 my-2"><Trans>Role</Trans>
												<input type="radio" id="Role" name="radio2" onClick={() => this.OnchangePrivType("1")} />
												<span className="checkmark" />
											</label>
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label p-0"><Trans>user_group</Trans></label>
										<div className="float-left col-md-8">
											<input type="text" className="form-control w-75" value={this.state.Tab2SiteTitle} disabled onChange={this.handleProfileFormChange} />
										</div>
									</div>
									<div className="col-md-6 mb-4 Ipad-UI">
										<label className="float-left col-md-4 form-label catalog-label p-0"><Trans>Role</Trans></label>
										<div className="float-left col-md-8">
											<Select
												isDisabled={this.state.DisablePrivRole}
												className="w-75 DropDown-Font-Size"
												value={this.state.SelectROleList}
												onChange={this.handleChangeTab2Role}
												options={this.state.Tab2RoleList}
											/>
										</div>
									</div>
									<div className="col-md-12 page-heading mb-3" id="PrivtopButton">
										<h5 className="page-title"><Trans>Program Tree View</Trans></h5>
										<div className="float-right my-2">
											{this.state.isExpandAll ? <button type="button" className="btn btn-primary custom-btn-primary primary-btn float-left px-4 mr-2 mb-2" id="ExpandALL" onClick={() => this.PrivTreeAction("E")}>
												<Trans>Expand</Trans><span> </span><Trans>All</Trans></button> :
												<button type="button" className="btn btn-primary custom-btn-primary primary-btn float-left px-4 mr-2" id="CollapseALL" onClick={() => this.PrivTreeAction("C")}>
													<Trans>Collapse</Trans><span> </span><Trans>All</Trans></button>}
											<button type="button" className="btn btn-primary custom-btn-primary primary-btn float-left px-4 mr-3" id="SelPrivBtn" onClick={() => this.PrivTreeAction("S")}>
												<Trans>Select All</Trans></button>
											<button type="button" className="btn btn-primary custom-btn-primary primary-btn float-left px-4" id="DeSelPrivBtn" onClick={() => this.PrivTreeAction("D")}>
												<Trans>Deselect All</Trans></button>
										</div>
									</div>
									<label className="col-md-1 form-label catalog-label" id="PrivProgramTitle"><Trans>Program</Trans></label>
									<div className="col-md-12" id="PrivProgramTree">
										<CheckboxTree
											nodes={this.state.Tab2BindTree}
											iconsClass="fa4"
											showNodeIcon={true}
											checked={this.state.checked}
											expanded={this.state.expanded}
											onCheck={this.OnCheckTree}
											onExpand={expanded => this.setState({ expanded })}
											disabled={this.state.PrivTreeDisable}
											icons={{
												check: <span className="rct-icon rct-icon-check" />,
												uncheck: <span className="rct-icon rct-icon-uncheck" />,
												halfCheck: <span className="rct-icon rct-icon-half-check" />,
												expandClose: <span className="rct-icon rct-icon-expand-close" />,
												expandOpen: <span className="rct-icon rct-icon-expand-open" />,
												expandAll: <span className="rct-icon rct-icon-expand-all" />,
												collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
												parentClose: <span className="rct-icon rct-icon-parent-close" />,
												parentOpen: <span className="rct-icon rct-icon-parent-open" />,
												leaf: <span className="rct-icon rct-icon-leaf" />,
											}}

										/>

									</div>
									<div className="col-md-12">
										<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.updatePrivilege()}><Trans>Save</Trans></button>
										<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
									</div>
								</div>
							</div>
							<div className="tab-pane p-3 fade" id="approvals-link" role="tabpanel" aria-labelledby="approvals">
								{/*[3/11/22, PC_UP_02, CAS - 31, Dhevanesam R*/}
								<div style={{ "margin-bottom": "1%" }}>
									{this.state.ApproverPopupFlag ? <button className="btn btn-primary custom-btn-primary primary-btn ml-auto float-right" data-toggle="modal" data-target=".my-approver-details">Approver Info</button> : null}
								</div>
								<div id="budApproval" className="hide">
									<div className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, "BudReq", 1)} style={{ "display": "inline-block" }} >
										<span className="accordion-arrow header-arrow" id="arrow-BudReq" />
									</div>
									<span style={{ "margin-left": "1%", "font-size": "15px", "font-weight": "bold" }}><Trans>Budgetary Approval</Trans></span>
									<hr className="accordion-line" />
									<div className="p d-none content-row" id="contentrow-BudReq">
										<div colSpan={8} className="py-0">
											<div className="accordian-body  py-1" id="order-row-1">
												<div className="row pl-4">
													<div className="col-md-12 p-0">
														<div className="col-md-7 mb-4 float-left Approval-mainDiv mt-3">
															<label className="float-left col-md-4 form-label catalog-label pl-0" id="ApprLabel"><Trans>Budgetary Approval by</Trans></label>
															<div className="float-left col-md-8 delete-checkbox p-0">
																<Select
																	className="w-75 DropDown-Font-Size"
																	value={this.state.ExistApproveValue}
																	onChange={this.handleChangeAppro}
																	options={this.state.ApproverList}
																/>
																{/*[8/10/22, Bug - 2349, PS_UP_02, Dhevanesam R]*/}
																{approvalAccordian == 1 ? <div className="custom-control custom-checkbox my-2 item-custom-control hide" id="removeApprover">
																	<input type="checkbox" className="custom-control-input" id="customCheckk" name="example1" />
																	<label className="custom-control-label priority-checkbox profile-update-checkbox" htmlFor="customCheckk"><Trans>Delete</Trans></label>
																</div> : null}

															</div>
														</div>
													</div>
													<div className="col-md-12 p-0" id="BudLimitDiv">
														<div className="col-md-7 mb-4 float-left Ipad-UI">
															<label className="float-left col-md-4 form-label catalog-label p-0"><Trans>Order Total Requiring Approval</Trans> ($)</label>
															<div className="float-left col-md-8 p-0">
																<input type="text" className="form-control w-25" placeholder={t("Enter Value")} id="BUdLimitData" name="ExistAppLimit" maxLength="10" value={this.state.ExistAppLimit} onChange={this.handleProfileFormChange} />
															</div>
														</div>
													</div>
													<div className="col-md-12 my-3">
														<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={(e) => this.UpdateApprovals(e)}><Trans>Submit</Trans></button>
														<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="reqApproval">
									<div className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, "AltReq", 2)} style={{ "display": "inline-block", "margin-top": "1%" }} >
										<span className="accordion-arrow header-arrow" id="arrow-AltReq" />
									</div>
									<span style={{ "margin-left": "1%", "font-size": "15px", "font-weight": "bold" }}><Trans>Alternate Requestor Approval</Trans></span>
									<hr className="accordion-line" />
									<div className="p d-none content-row" id="contentrow-AltReq">
										<div colSpan={8} className={`py-0`}>
											<div className="accordian-body  py-1" id="order-row-1">
												<div className={`row pl-4`}>
													<div className={`col-md-12 p-0 ${this.state.ApproverList.length > 0 ? '' : 'hide'}`}>
														<div className="col-md-7 mb-4 float-left Approval-mainDiv mt-3">
															<label className="float-left col-md-4 form-label catalog-label pl-0" id="ApprLabel"><Trans>Alternate Requestor Approval by</Trans></label>
															<div className="float-left col-md-8 delete-checkbox p-0">
																<Select
																	className="w-75 DropDown-Font-Size"
																	value={this.state.ExistApproveValue}
																	onChange={this.handleChangeAppro}
																	options={this.state.ApproverList}
																/>
																{/*[8/10/22, Bug - 2349, PS_UP_02, Dhevanesam R]*/}
																{approvalAccordian == 2 ? <div className="custom-control custom-checkbox my-2 item-custom-control hide" id="removeApprover">
																	<input type="checkbox" className="custom-control-input" id="customCheckk" name="example1" />
																	<label className="custom-control-label priority-checkbox profile-update-checkbox" htmlFor="customCheckk"><Trans>Delete</Trans></label>
																</div> : null}
															</div>
														</div>
													</div>
													<div className={`col-md-12 my-3 ${this.state.ApproverList.length > 0 ? '' : 'hide'}`}>
														<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={(e) => this.UpdateApprovals(e)}><Trans>Submit</Trans></button>
														<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
													</div>

												</div>
												<div className={`row pl-4 ${this.state.ApproverList.length > 0 ? 'hide' : ''}`}>
													<p className={`search-result-content pt-2 `} style={{ "padding-left": "40%" }}><Trans>No alternate requestor approver found</Trans></p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="altBudApproval">
									<div className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, "AltBud", 3)} style={{ "display": "inline-block", "margin-top": "1%" }} >
										<span className="accordion-arrow header-arrow" id="arrow-AltBud" />
									</div>
									<span style={{ "margin-left": "1%", "font-size": "15px", "font-weight": "bold" }}><Trans>Alternate Budgetary Approval</Trans></span>
									<hr className="accordion-line" />
									<div className="p d-none content-row" id="contentrow-AltBud">
										<div colSpan={8} className="py-0">
											<div className="accordian-body  py-1" id="order-row-1">
												<div className={`row pl-4`}>
													<div className={`col-md-12 p-0 ${this.state.ApproverList.length > 0 ? '' : 'hide'}`}>
														<div className="col-md-7 mb-4 float-left Approval-mainDiv mt-3">
															<label className="float-left col-md-4 form-label catalog-label pl-0" id="ApprLabel"><Trans>Alternate Budgetary Approval by</Trans></label>
															<div className="float-left col-md-8 delete-checkbox p-0">
																<MultiSelect
																	options={this.state.ApproverList}
																	className="DropDown-Font-Size UserCSS-alt-budg "
																	id="REG"
																	value={this.state.selectedApprvValue}
																	onChange={this.handleChangeAppro}
																	labelledBy={"-------Select Approver------"}
																	overrideStrings={{ selectSomeItems: "-------Select Approver------" }}
																	hasSelectAll={false}
																/>
																{/*[8/10/22, Bug - 2349, PS_UP_02, Dhevanesam R]*/}
															</div>
														</div>
													</div>
													{this.state.ExistApproveValue.length > 0 ?
														<div className="container-fluid">
															<div className="row">
																<div className="col-md-6 mt-3">
																	<div class="table-responsive mb-3">
																		<table className="table split-delv-table">
																			<thead >
																				<tr>
																					<th className="text-nowrap text-center"><input type="checkbox" className="custom-control-del" id="AltBudgMultiselect" style={{ "opacity": "5", "height": '1.25rem', "width": '1rem' }} onChange={(e) => this.handleMultiCheckOnchange(e)} /></th>
																					<th className="text-nowrap">Alternate Approver</th>
																					<th className="text-nowrap text-center">Action</th>
																				</tr>
																			</thead>
																			<tbody>
																				{this.bindAlternateGrid()}
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
														</div> : null}

													<div className={`col-md-12 my-3 ${this.state.ApproverList.length > 0 ? '' : 'hide'}`}>
														{((JSON.stringify(this.state.ExistApproveValue) !== JSON.stringify(this.state.updateAltBudgetary)) && (JSON.stringify(this.state.ExistApproveValue) === JSON.stringify(this.state.delAltBudCheck))) ?
															<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.UpdateApprovals(this.state.updateAltBudgetary)}><Trans>Submit</Trans></button> : null}
														{(JSON.stringify(this.state.ExistApproveValue) !== JSON.stringify(this.state.delAltBudCheck)) ?
															<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.UpdateApprovals(this.state.delAltBudCheck)}><Trans>Delete</Trans></button> : null}
														<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
													</div>
												</div>
												<div className={`row pl-4 ${this.state.ApproverList.length > 0 ? 'hide' : ''}`}>
													<p className={`search-result-content pt-2 `} style={{ "padding-left": "40%" }}><Trans>No alternate budgetary approver found</Trans></p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane p-3 fade" id="order-status-link" role="tabpanel" aria-labelledby="order-status-emails">
								<div className="row">

									<div className="col-md-12 mb-3">
										<label className="fav-item-title mt-2"><Trans>Order Status Emails</Trans></label>
									</div>
									{this.state.RoleExist != "" ? <div className="col-md-12 mb-3">
										<label className="mt-2 font-style catalog-details-title">{this.state.RoleExist}</label>
									</div> : null}
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Created</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbCRE" name="example1" />
											<label className="custom-control-label" htmlFor="chbCRE" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Customer Information</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbCST" name="example1" />
											<label className="custom-control-label" htmlFor="chbCST" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Quote Rejected</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbQTR" name="example1" />
											<label className="custom-control-label" htmlFor="chbQTR" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Fully Received</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbRCF" name="example1" />
											<label className="custom-control-label" htmlFor="chbRCF" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Waiting Approval Chain</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbQTW" name="example1" />
											<label className="custom-control-label" htmlFor="chbQTW" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Vendor Information</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbVND" name="example1" />
											<label className="custom-control-label" htmlFor="chbVND" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>RFQ Approved</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbRFA" name="example1" />
											<label className="custom-control-label" htmlFor="chbRFA" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Partial Received</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbRCP" name="example1" />
											<label className="custom-control-label" htmlFor="chbRCP" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Waiting Customer Approval</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbQTC" name="example1" />
											<label className="custom-control-label" htmlFor="chbQTC" />
										</div>
									</div>
									{/*[04/07/22, PC_UP_01, Bug - 2037, Dhevanesam R]*/}
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Approved</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbAPR" name="example1" />
											<label className="custom-control-label" htmlFor="chbAPR" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>RFQ Rejected</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbRFR" name="example1" />
											<label className="custom-control-label" htmlFor="chbRFR" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Cancelled</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbCNC" name="example1" />
											<label className="custom-control-label" htmlFor="chbCNC" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>SDI Exchange Approval</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbQTS" name="example1" />
											<label className="custom-control-label" htmlFor="chbQTS" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Quote Approved</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbQTA" name="example1" />
											<label className="custom-control-label" htmlFor="chbQTA" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>RFQ Completed</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbRFC" name="example1" />
											<label className="custom-control-label" htmlFor="chbRFC" />
										</div>
									</div>
									<div className="col-md-3 Ipad-OSE">
										<label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Delivered</Trans></label>
										<div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
											<input type="checkbox" className="custom-control-input" id="chbDLF" name="example1" />
											<label className="custom-control-label" htmlFor="chbDLF" />
										</div>
                                    </div>
                                    <div className="col-md-3 Ipad-OSE">
                                        <label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Ready for Picking</Trans></label>
                                        <div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
                                            <input type="checkbox" className="custom-control-input" id="chbPKA" name="example1" />
                                            <label className="custom-control-label" htmlFor="chbPKA" />
                                        </div>
                                    </div>
                                    <div className="col-md-3 Ipad-OSE">
                                        <label className="col-md-8 catalog-label float-left mt-1 pl-0 Mobile-OSE"><Trans>Advance Shipping Notice</Trans></label>
                                        <div className="col-md-4 custom-control custom-checkbox mb-3 item-custom-control mt-0 float-left mobile-chk-box">
                                            <input type="checkbox" className="custom-control-input" id="chbASN" name="example1" />
                                            <label className="custom-control-label" htmlFor="chbASN" />
                                        </div>
                                    </div>
									<div className="col-md-12 my-3">
										<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" id="OSESubmitBtn" onClick={() => this.UpdateOSE()}><Trans>Submit</Trans></button>
										<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
									</div>
								</div>
							</div>
							<div className="tab-pane p-3 fade" id="preferences-link" role="tabpanel" aria-labelledby="preferences">
								<div className="row">
									<div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="col-md-4 catalog-label float-left mt-1 pl-0"><Trans>Product Display Type</Trans></label>
											<div className="float-left col-md-8 p-0">
												<label className="custom-radiobtn mr-2 my-2" ><Trans>Catalog</Trans>
													<input type="radio" name="type" id="CataDesp" />
													<span className="checkmark" />
												</label>
												<label className="custom-radiobtn mr-2 my-2" ><Trans>Client</Trans>
													<input type="radio" name="type" id="ClientDesp" />
													<span className="checkmark" />
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="col-md-4 catalog-label float-left mt-1 pl-0"><Trans>Block Price Display</Trans></label>
											<div className="col-md-8 custom-control custom-checkbox float-left">
												<input type="checkbox" className="custom-control-input" id="PriceDisplay" name="example1" />
												<label className="custom-control-label" htmlFor="PriceDisplay" />
											</div>
										</div>
									</div>
									<div className="col-md-12" id="DepartPrefDiv">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>dept</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													className="w-75 DropDown-Font-Size"
													value={this.state.DepartmentPref}
													onChange={this.handleChangeDepartPref}
													options={this.state.DepartPref}
												/>
											</div>
										</div>
									</div>
									{(this.state.ShiptoList.length > 2 && this.state.showAddressField != "Y") ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Ship To</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													className="w-75 DropDown-Font-Size"
													value={this.state.Shipto}
													onChange={this.handleChangeShipto}
													options={this.state.ShiptoList}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.HomeStoreDD.length > 2 ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Home store</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													className="w-75 DropDown-Font-Size"
													value={this.state.HomeStore}
													onChange={this.handleChangeHomeStore}
													options={this.state.HomeStoreDD}
												/>
											</div>
										</div>
									</div> : null}
									{/*//[4/13/22, Bug - 2047 & 2048, Dhevanesam R*/}
									<div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4 d-none" id="Chargelabel">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Charge Code</Trans>:</label>
										</div>
									</div>
									{this.state.PlaceDD1Visible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCPlaceDD1Title}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="PlaceDD1"
													className="w-75 DropDown-Font-Size"
													value={this.state.PlaceDD1Value}
													onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD1Value")}
													options={this.state.PlaceDD1Data}
												/>
											</div>
										</div>
									</div> : null}
									{/*[5/10/22, CAS - 31, Dhevanesam R]*/}
									{this.state.PlaceDD2Visible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCPlaceDD2Title}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="PlaceDD2"
													className="w-75 DropDown-Font-Size"
													value={this.state.PlaceDD2Value}
													onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD2Value")}
													options={this.state.PlaceDD2Data}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.PlaceDD3Visible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCPlaceDD3Title}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="PlaceDD3"
													className="w-75 DropDown-Font-Size"
													value={this.state.PlaceDD3Value}
													onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD3Value")}
													options={this.state.PlaceDD3Data}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.dropCD1SegVisible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCdropCD1SegTitle}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="dropCD1Seg"
													className="w-75 DropDown-Font-Size"
													value={this.state.dropCD1SegValue}
													onChange={(e) => this.handleChangePlaceDD1(e, "dropCD1SegValue")}
													options={this.state.dropCD1SegData}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.dropCD2SegVisible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCdropCD2SegTitle}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="dropCD2Seg"
													className="w-75 DropDown-Font-Size"
													value={this.state.dropCD2SegValue}
													onChange={(e) => this.handleChangePlaceDD1(e, "dropCD2SegValue")}
													options={this.state.dropCD2SegData}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.dropCD3SegVisible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCdropCD3SegTitle}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="dropCD3Seg"
													className="w-75 DropDown-Font-Size"
													value={this.state.dropCD3SegValue}
													onChange={(e) => this.handleChangePlaceDD1(e, "dropCD3SegValue")}
													options={this.state.dropCD3SegData}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.dropEmpSegVisible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCdropEmpSegTitle}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="dropEmpSeg"
													className="w-75 DropDown-Font-Size"
													value={this.state.dropEmpSegValue}
													onChange={(e) => this.handleChangePlaceDD1(e, "dropEmpSegValue")}
													options={this.state.dropEmpSegData}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.dropItmSegVisible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCdropItmSegTitle}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="dropItmSeg"
													className="w-75 DropDown-Font-Size"
													value={this.state.dropItmSegValue}
													onChange={(e) => this.handleChangePlaceDD1(e, "dropItmSegValue")}
													options={this.state.dropItmSegData}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.dropMchSegVisible == true ? <div className="col-md-12">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>{this.state.CCdropMchSegTitle}</Trans></label>
											<div className="float-left col-md-8 pl-1">
												<Select
													id="dropMchSeg"
													className="w-75 DropDown-Font-Size"
													value={this.state.dropMchSegValue}
													onChange={(e) => this.handleChangePlaceDD1(e, "dropMchSegValue")}
													options={this.state.dropMchSegData}
												/>
											</div>
										</div>
									</div> : null}
									{this.state.showAddressField == "Y" ? <div className="col-md-12" id="AddressField">
										<div className="col-lg-6 col-md-12 float-left p-0 mb-4">
											<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Address</Trans></label>
											<div className="float-left col-md-6 pl-1">
												<textarea className="form-control" placeholder="Add Address" id="AddressTB" value={this.state.userAddress} rows={4} defaultValue={""} disabled />
												<span class="edit-favs-icon cursor-pointer" id="EditAddress" style={{ "position": "absolute", "z-index": "12px", "top": "12px", "right": "20px" }} onClick={() => this.handleAddressSelect()} />
												<div>
													<span className="float-left address-msg text-danger" id="isValidAddress"></span>
													{this.state.userAddress != "" ? <a href="#" className="float-right" style={{ "font-size": "13px", "margin-top": "5px" }} onClick={(e) => this.clearAddress(e)}><Trans>Clear</Trans></a> : null}
												</div>
											</div>
										</div>
									</div> : null}
									<div className="col-md-12">
										<div className="float-left col-md-4 p-0" />
										<div className="float-left float-custom col-md-4">
											<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.UpdatePref()}><Trans>Submit</Trans></button>
											<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane p-3 fade" id="mobility-link" role="tabpanel" aria-labelledby="mobility">
								<div className="row">
									<div className="col-md-8 mb-4 Ipad-UI">
										<label className="float-left col-md-4 pl-0"><Trans>Crib Identifier</Trans></label>
										<div className="float-left col-md-6">
											<Select
												id="CribField"
												className="w-75 DropDown-Font-Size changeevent"
												value={this.state.CribValue}
												onChange={this.handleChangeCrib}
												options={this.state.CribList}
											/>
											{/*[4/27/22, Bug - 2071, Dhevanesam R*/}
											<span id="CribValidate" className="DropDown-Font-Size errorcolour">{t(this.state.StatusMsgErrValCrib)}</span>
										</div>

									</div>
									<div className="col-md-12 my-3">
										<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.UpdateCrib()}><Trans>Submit</Trans></button>
										<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
									</div>
								</div>
							</div>
							{/*[8/15/22, Bug - 2298, Madhu - In IPAD Mini the enable zeus checkbox is not aligned properly */}
							<div className="tab-pane p-3 fade" id="zeus-link" role="tabpanel" aria-labelledby="zeus">
								<div className="row">
									<div className="col-md-12">
										<div className="col-md-6 float-left p-0">
											<label className="col-md-4 catalog-label float-left mt-1 pl-0" id="ZeusLabel"><Trans>{this.state.ZeusTitle}</Trans></label>
											<div className="col-md-8 custom-control custom-checkbox mb-3 item-custom-control float-left custom-fav-image">
												<input type="checkbox" className="custom-control-input" id="Disable-Zeus" name="example1" />
												<label className="custom-control-label" htmlFor="Disable-Zeus" />
											</div>
										</div>
									</div>
									<div className="col-md-12 my-3">
										<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4 Zeus-Tab-Btn" onClick={() => this.UpdateZeus()}><Trans>Submit</Trans></button>
										<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
									</div>
								</div>
							</div>
							<div className="tab-pane p-3 fade" id="sdi-track-link" role="tabpanel" aria-labelledby="sdi-track">
								<div className="row">
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12 mb-3">
												<label className="fav-item-title mt-2"><Trans>SDI Track</Trans></label>
											</div>
											<div className="col-md-12 mb-4">
												<label className="col-md-4 float-left catalog-label pl-0"><Trans>Current User</Trans></label>
												<label className="col-md-8 float-left item-details-value" id="TSTCurentUser"></label>
											</div>
											<div className="col-md-12 mb-4 ">
												<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>SDI Track User ID</Trans></label>
												{/*[3/20/2023, PS_CAS_47_01, Dhevanesam R*/}
												<div className="float-left col-md-8">
													<input type="text" className="form-control w-75" maxLength="30" placeholder={t("Enter SDITrack User ID")} id="TSTUSERID1" defaultValue={this.state.TSTUserID} />
												</div>
											</div>
											<div className="col-md-12 mb-4 ">
												<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Password</Trans></label>
												<div className="float-left col-md-8">
													<input type="password" className="form-control w-75" placeholder={t("Enter Password")} id="TSTPASSWORD1" defaultValue={''} />
												</div>
											</div>
											<div className="col-md-12 mb-4 ">
												<label className="col-md-4 float-left catalog-label pl-0"><Trans>SDI Track User ID</Trans></label>
												<label className="col-md-8 float-left item-details-value" id="LoginUserId"></label>
											</div>
											<div className="col-md-12 mb-4  ">
												<label className="col-md-4 float-left catalog-label pl-0"><Trans>Added On</Trans></label>
												<label className="col-md-8 float-left item-details-value" id="LoginUserADDOn"></label>
											</div>
											<div className="col-md-12 mb-4  ">
												<label className="col-md-4 float-left catalog-label pl-0"><Trans>SDI Track GUID</Trans></label>
												<label className="col-md-8 float-left item-details-value" id="LoginUserGuid"></label>
											</div>
											<div className="col-md-12 mb-4">
												<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.UpdateTST("1")}><Trans>Add to SDI Track</Trans></button>
											</div>
										</div>
									</div>
									<div class="vl"></div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12 mb-3">
												<label className="fav-item-title mt-2"><Trans>Add Other User to SDI Track</Trans></label>
											</div>
											<div className="col-md-12 mb-4">
												<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Other User</Trans></label>
												<div className="float-left col-md-8">
													<Select
														className="w-75 DropDown-Font-Size"
														value={this.state.TSTDefaultValue}
														onChange={this.handleChangeEmplyee}
														options={this.state.TSTEmployeeValue}
													/>

												</div>
											</div>
											<div className="col-md-12 mb-4">
												<label className="col-md-4 float-left catalog-label pl-0"><Trans>User ID</Trans></label>
												<label className="col-md-8 float-left item-details-value" id="TSTUserName"></label>
											</div>
											<div className="col-md-12 mb-4">
												<label className="col-md-4 float-left catalog-label pl-0"><Trans>Business Unit</Trans></label>
												<label className="col-md-8 float-left item-details-value" id="TSTuserBu"></label>
											</div>
											{/*[5/10/22, CAS - 31, Dhevanesam R]*/}
											<div className="col-md-12 mb-4">
												<label className="float-left DropDown-Font-Size" id="TSTError"><Trans>{this.state.TSTErrorVal}</Trans></label>
											</div>
											<div className="col-md-12 mb-2">
												<div id="AddTSTUserDiv" className="row">
													<div className="col-md-12 mb-4" id="SDITrackIdDiv">
														<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>SDI Track User ID</Trans></label>
														{/*[3/20/2023, PS_CAS_47_01, Dhevanesam R*/}
														<div className="float-left col-md-8">
															{AddTSTUSERSuccess == 1 ? <input type="text" className="form-control w-75" maxLength="30" id="TSTOTHERID" placeholder={t("Enter SDITrack User ID")} defaultValue={''} />
																: <label className="col-md-8 float-left item-details-value" id="ADDUserID"></label>}
														</div>
													</div>
													<div className="col-md-12 mb-4" id="SDITrackPassDiv">
														<label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Password</Trans></label>
														<div className="float-left col-md-8">
															<input type="password" className="form-control w-75" id="TSTOTHERPASS" placeholder={t("Enter Password")} defaultValue={''} />
														</div>
													</div>
												</div>
											</div>
											{/*<div id="AddTSTUserDiv" className="row">
                                                <div className="col-md-12 mb-4" id="SDITrackIdDiv">
                                                    <label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>SDI Track User ID</Trans></label>
                                                    <div className="float-left col-md-8">
                                                        {AddTSTUSERSuccess == 1 ? <input type="text" className="form-control w-75" id="TSTOTHERID" placeholder={t("Enter SDITrack User ID")} defaultValue={''} />
                                                            : <label className="col-md-8 float-left item-details-value" id="ADDUserID"></label>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-4" id="SDITrackPassDiv">
                                                    <label className="float-left col-md-4 form-label catalog-label pl-0"><Trans>Password</Trans></label>
                                                    <div className="float-left col-md-8">
                                                        <input type="password" className="form-control w-75" id="TSTOTHERPASS" placeholder={t("Enter Password")} defaultValue={''}/>
                                                    </div>
                                                </div>
                                            </div>*/}
											<div id="ADDEDUSERDiv">
												<div className="col-md-12 mb-4">
													<label className="col-md-4 float-left catalog-label pl-0"><Trans>Added On</Trans></label>
													<label className="col-md-8 float-left item-details-value" id="ADDEDON"></label>
												</div>
												<div className="col-md-12 mb-4">
													<label className="col-md-4 float-left catalog-label pl-0"><Trans>SDI Track GUID</Trans></label>
													<label className="col-md-8 float-left item-details-value" id="ADDGUID"></label>
												</div>
											</div>
											<div className="col-md-12 mb-4">
												<button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.UpdateTST("2")}><Trans>Add to SDI Track</Trans></button>
												<button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
											</div>
										</div>
									</div>
									{/*<div className="col-md-12 my-3" id="AddUserDiv">
                                        <button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={() => this.UpdateTST("2")}><Trans>Add to SDI Track</Trans></button>
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-2" onClick={() => this.COStatus()}><Trans>Cancel</Trans></button>
                                    </div>*/}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal userid" id="UserIdModal" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog  custom-popup modal-dialog-centered">
						<div className="modal-content">
							{/* Modal Header */}
							<div className="modal-header">
								<h4 className="modal-title mt-1"><Trans>Add User ID</Trans></h4>
								<button type="button" className="btn close-btn" data-dismiss="modal">
									X
              </button>
							</div>
							{/* Modal body */}
							<div className="modal-body">
								<div className="row justify-content-center">
									<div className="col-md-10 float-left my-5">
										<label className="float-left col-md-3 form-label catalog-label pl-0"><Trans>User ID</Trans></label>
										{/*[3/20/2023, PS_CAS_47_01, Dhevanesam R*/}
										<div className="float-left col-md-9">
											<input type="text" className="form-control " placeholder={t("Enter User Id")} id="ManuallyUserIdTb" maxLength="30" />
											<span id="ErrorMagUserId" className="DropDown-Font-Size errorcolour"></span>
										</div>
									</div>
								</div>
								<div className="col-md-12 text-center mb-2">
									<button className="btn btn-secondary custom-btn-secondary secondary-btn px-4 mr-2" data-dismiss="modal"><Trans>Cancel</Trans></button>
									<button className="btn btn-primary custom-btn-primary primary-btn px-4 mr-2" onClick={() => this.UpdateUserProfile()}><Trans>Save</Trans></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*[5/11/2023]Z-102  AddressFieldsModal modal is shows address fields to be filled by the user to validate it*/}
				<div className="modal" id="AddressFieldsModal" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
						<div className="modal-content">
							{/* Modal Header */}
							<div className="modal-header">
								<h4 className="modal-title mt-1"><Trans>Address</Trans></h4>
								<button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.closeAddressPopup()}>
									X
              </button>
							</div>
							{/* Modal body */}
							<div className="modal-body">

								<div className="row mt-3 w-100">
									<div className="col-12 edit-desc-field mb-4">
										<div className="row mb-3">
											<label className="col-md-5 quick-label">Street</label>
											<div className="col-md-7">
												<input type="text" className="form-control" id="streetTB" placeholder="Enter street" maxLength="55" value={this.state.streetValue} onChange={(e) => this.onStreetChange(e)} />
											</div>
										</div>
										<div className="row mb-3">
											<label className="col-md-5 quick-label">Apartment number</label>
											<div className="col-md-7">
												<input type="text" className="form-control" id="apartmentNoTB" placeholder="Enter apartment #(Optional)" maxLength="20" value={this.state.apartmentNoValue} onChange={(e) => this.onApartmentNoChange(e)} />
											</div>
										</div>
										<div className="row mb-3">
											<label className="col-md-5 quick-label">City</label>
											<div className="col-md-7">
												<input type="text" className="form-control" id="cityTB" placeholder="Enter city" maxLength="30" value={this.state.cityValue} onChange={(e) => this.onCityChange(e)} />
											</div>
										</div>
										<div className="row mb-3">
											<label className="col-md-5 quick-label">State</label>
											<div className="col-md-7">
												<input type="text" className="form-control" id="stateTB" placeholder="Enter state" maxLength="2" value={this.state.stateValue} onChange={(e) => this.onStateChange(e)} />
											</div>
										</div>
										<div className="row mb-3">
											<label className="col-md-5 quick-label">Zip code</label>
											<div className="col-md-7">
												<input type="text" className="form-control" id="zipCodeTB" placeholder="Enter zip code" maxLength="12" value={this.state.zipCodeValue} onChange={(e) => this.OnHandleZipCode(e)} />
											</div>
										</div>
										<div className="row mb-3">
											<label className="col-md-5 quick-label">Country</label>
											<div className="col-md-7">

												<Select
													id="countryDD"
													name="country"
													className="DropDown-Font-Size"
													onChange={(e) => this.onCountryChange(e)}
													value={this.state.selectCountry}
													defaultValue={countryOptions[0]}
													options={countryOptions}
												/>
											</div>
										</div>
										<div className="row mb-3 float-left">
											<span className="float-left err-msg text-danger" id="AddressErrorMsg"></span>
										</div>

									</div>

									<div className="col-md-12 text-center mb-2">
										<button className="btn btn-primary custom-btn-primary primary-btn px-4" id="validateAddressID" onClick={() => this.validateAddressClick()}><Trans>Validate</Trans></button>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
				{/*[5/11/2023]Z-102  AddressModal modal is to show the user typed and google's recommended address with map to user*/}
				<div className="modal" id="AddressModal" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
						<div className="modal-content">
							{/* Modal Header */}
							<div className="modal-header">
								<h4 className="modal-title mt-1"><Trans>Confirm your address</Trans></h4>
								<button type="button" className="btn close-btn" data-dismiss="modal">
									X
              </button>
							</div>
							{/* Modal body */}
							<div classname="modal-body" style={{ "overflow": "inherit" }}>

								<div className="mt-3 w-100">
									<div className="col-12 edit-desc-field mb-4">
										<div className="row d-flex align-items-baseline">
											<input className="ml-2 mr-2" type="radio" name="addressRadio" id="userAddressBtn" checked={this.state.typedAddress} onChange={() => this.selectedAddress("userAddress")} />
											<label className="AddressTitle" id="userAddressLbl">What you entered</label>
										</div>
										<div className="row mb-3">
											<label className="col-md-12 AddressLine" id="userAddressLbl">{this.state.userSelectedAddress}</label>
										</div>
										<div className="row align-items-baseline">
											<input className="ml-2 mr-2" type="radio" name="addressRadio" id="recomndAddressBtn" checked={this.state.recAddress} onChange={() => this.selectedAddress("recommendedAddress")} />
											<label className="AddressTitle" id="userAddressLbl">Recommended</label>
										</div>
										<div className="row mb-3">
											<label className="col-md-12 AddressLine" id="recomndAddressLbl">{this.state.recomendedAddress}</label>
										</div>
										<div className="row mb-3 height-170">
											<GoogleMaps Latitude={this.state.validLatitude} Longitude={this.state.validLongitude} {...this.props} />
										</div>

									</div>

									<div className="col-md-12 text-center mb-2">
										<button className="btn btn-primary custom-btn-primary primary-btn px-4" id="confirmUserAddress" onClick={() => this.confirmAddress()}><Trans>Confirm</Trans></button>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

				{/* My Approver Modal Starts here*/}
				<div
					className="modal fade my-approver-details"
					tabIndex={-1}
					role="dialog"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered custom-success-width"
						role="document"
					>
						<div className="modal-content">
							<div className="modal-header sp-delv-header align-items-center">
								<h5 className="modal-title" id="exampleModalLongTitle">
									Approver Info
                                </h5>
								<button
									type="button"
									className="btn-close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<img src="Images/close-icon.svg" alt="close" />
								</button>
							</div>
							<div className="modal-body pb-5">
								<div className="row">
									<div className="col-md-12">
										<div className="text-center d-flex flex-column justify-content-center align-items-center mt-2">
											{/*Current user start here*/}
											<p className="Font-14 font-bold mb-2 text-black">
												Current User
                                            </p>
											<div className="custom-approver-card-style next-approver-card-after w-55 pr-4 pl-4">
												<div className="d-flex align-items-center justify-content-center float-left ">
													<div className="d-block mr-2">
														<img
															className=""
															src="Images/user-icon.svg"
															alt="user-icon"
														/>
													</div>
													<div className="d-block text-left">
														<p className="font-medium font-12 mb-0 mt-3 text-black order-status-workorder" data-toggle="tooltip" title={this.state.CurrentUserName} data-label="CURRENTUSER">
															{this.state.CurrentUserName.length > 19 ? `${this.state.CurrentUserName.substring(0, 19)}...` : this.state.CurrentUserName}
														</p>
														<p className="mb-3">
															<span className="font-regular font-10 color-grey mr-1">
																Budgetary Limit:
                                                            </span>
															<span className="Font-14 font-bold text-black">
																${this.state.CurrentUserLimit}
															</span>
														</p>
													</div>
												</div>
											</div>
											{/*Current user ends here*/}
											{/*Next Approver starts here*/}
											<p className="Font-14 font-bold mb-2 text-black">
												Next Approver
                                            </p>
											<div className="custom-approver-card-style w-55 pr-4 pl-4">
												<div className="d-flex align-items-center justify-content-center float-left">
													<div className="d-block mr-2">
														<img
															className=""
															src="Images/user-icon.svg"
															alt="user-icon"
														/>
													</div>
													<div className="d-block text-left">
														<p className="font-medium font-12 mb-0 mt-3 text-black order-status-workorder" data-toggle="tooltip" title={this.state.NextApprover} data-label="NEXTAPPROVER">
                                                            {this.state.NextApprover && this.state.NextApprover.length > 19 ? `${this.state.NextApprover.substring(0, 19)}...` : this.state.NextApprover}
														</p>
														<p className="mb-3">
															<span className="font-regular font-10 color-grey mr-1">
																Budgetary Limit:
                                                            </span>
															<span className="Font-14 font-bold text-black">
																{this.state.NextApproverLimit != null ? `$${this.state.NextApproverLimit}` : "-"}
															</span>
														</p>
													</div>
												</div>
											</div>
											{/*Next Approver ends here*/}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*My Approver Modal Ends here*/}
				{/*Delete alternate approver starts here*/}
				<div className="modal fade delete-alternate-approver" id="delete-alt-approver" tabIndex={-1} role="dialog" aria-hidden="false" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-md modal-dialog-centered custom-delete-width custom-popup" role="document">
						<div className="modal-content py-2">
							{/*Delete alternate approver starts here*/}
							<div className="modal-body pb-0">
								<div className="row">
									<div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center">
										<img className="mb-3" src="images/trash.svg" alt="delete" />
										<h5 className="mb-2 font-16 font-bold color-light-black">Confirm</h5>
										<p className="mb-1 font-13 font-regular color-light-black">Are you sure you want to delete the selected alternate approvers?</p>
									</div>
								</div>
							</div>
							{/*Delete alternate approver ends here*/}
							<div className="modal-footer border-0 d-flex justify-content-center">
								<button className="btn btn-custom-secondary-altbudg  font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={(e) => this.handleDelIconAndPopup("No", e, 1)}> No </button>
								<button className="btn btn-custom-primary-altbudg font-15 font-medium mr-2 mb-2 px-4 py-2" onClick={(e) => this.handleDelIconAndPopup("Yes", e, 1)}>Yes</button>
							</div>
						</div>
					</div>
				</div>
				{/*Delete alternate approver ends here*/}

			</div>
		);
	};
};
//[PC_07, 09/13/21, Author - Dhevanesam R]
export default withTranslation()(Profile);
