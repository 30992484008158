//[PC_02, 09/07/21, Author - Priyanka D, Dhevanesam R]
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//[10/18/21, PC_06, Bug ID - 1736, Dhevanesam R]
import * as CookieService from './CookieService';

//[PC_03, 09/07/21, Author - Priyanka D, Dhevanesam R]
const resources = {
    en: {
        translation: {
            "Welcome": "Welcome",
            "Number": "Number",
            "No record": "No record",
            "Non-priority": "Non-priority",
            "Business": "Business",
            "Unit": "Unit",
            "Delivery": "Delivery",
            "Need by Date": "Need by Date",
            "Name": "Name",
            "Price": "Price",
            "Decline": "Decline",
            "Total": "Total",
            "Created": "Created",
            "Waiting": "Waiting",
            "Pending Approver": "Pending Approver",
            "Division": "Division",
            "Region": "Region",
            "Sub Region": "Sub Region",
            "Export to Excel": "Export to Excel",
            "Buy Checked Items": "Buy Checked Items",
            "Status": "Status",
            "Install ": "Install",
            "Filter": "Filter",
            "Apply": "Apply",
            "Preference": "Preference",
            "Select Report": "Select Report",
            "Zip Code": "Zip Code",
            "Model": "Model",
            "Store": "Store",
            "Please enter": "Please enter",
            "Enter valid WorkOrder number": "Enter valid WorkOrder number",
            "Make": "Make",
            "Pre_filter": "Pre-filter",
            "Item has been added to cart": "Item has been added to cart",
            "SearchQuickItem_key": "Search by Quick Item Entry",
            "Required_key": "Required",
            "Done": "Done",
            "more_key": "more",
            "reset_key": "Reset",
            "Attachment": "Attachment",
            "part number": "Part Number",
            "unitofmeasure_key": "Unit of Measure",
            "recommended": "Recommended",
            "Vendor": "Vendor",
            "orderedfor_key": "Ordered For",
            "orderenteredby_key": "Order Entered by",
            "Priority": "Priority",
            "workorder_key": "Work Order",
            "order_key": "Order",
            "Quick Item Entry": "Quick Item Entry",
            "Items have to be from the same supplier for pickup to be enabled": "Items have to be from the same supplier for pickup to be enabled",
            "Pickup": "Pickup",
            "Customer Notes": "Customer Notes",
            "Edit": "Edit",
            "Favorite": "Favorite",
            "Bill-to-Store": "Bill-to-Store",
            "Ship To": "Ship To",
            "Delivery Date": "Delivery Date",
            "Asset Tag ID": "Asset Tag ID",
            "Employee": "Employee",
            "Details": "Details",
            "Item": "Item",
            "Shopping Cart": "Shopping Cart",
            "Download": "Download",
            "Archive": "Archive",
            "Date": "Date",
            "Title": "Title",
            "Notification": "Notification",
            "Number of Records": "Number of Records",
            "Quantity": "Quantity",
            "Action": "Action",
            "Your": "Your",
            "Description": "Description",
            "Category": "Category",
            "ID": "ID",
            "Continue Shopping": "Continue Shopping",
            "Return to": "Return to",
            "New": "New",
            "In the absence of written agreement to the contrary between customer and SDI, all pricing contained in this catalog is subject to change by SDI at any time without notice.": "In the absence of written agreement to the contrary between customer and SDI, all pricing contained in this catalog is subject to change by SDI at any time without notice.",
            "Profile": "Profile",
            "Home": "Home",
            "Update": "Update",
            "Enable": "Enable",
            "Crib Identifier": "Crib Identifier",
            "Block Price Display": "Block Price Display",
            "Client": "Client",
            "Catalog": "Catalog",
            "Cancel": "Cancel",
            "Submit": "Submit",
            "Approval": "Approval",
            "Customer": "Customer",
            "Partial Received": "Partial Received",
            "Approve": "Approve",
            "Information": "Information",
            "Fully Received": "Fully Received",
            "Quote": "Quote",
            "Cancelled": "Cancelled",
            "Rejected": "Rejected",
            "Delivered": "Delivered",
            "Complete": "Complete",
            "Quote Approved": "Quote Approved",
            "set": "set",
            "Delete": "Delete",
            "Order Total Requiring Approval": "Order Total Requiring Approval",
            "budg_approval_by": "Budgetary Approval by",
            "Address": "Address",
            "f_name": "First Name",
            "type": "Type",
            "Phone": "Phone Number",
            "l_name": "Last Name",
            "Deselect": "Deselect",
            "user_group": "User Group",
            "Expand": "Expand",
            "Collapse": "Collapse",
            "Role": "Role",
            "Site": "Site",
            "dept": "Department",
            "User": "User",
            "change_pwd": "Change Password",
            "Add": "Add",
            "Zeus": "Zeus",
            "Mobility": "Mobility",
            "Preferences": "Preferences",
            "emails": "Emails",
            "Product": "Product",
            "My": "My",
            "Logout": "Logout",
            "Term of Purchase": "Term of Purchase",
            "Answer Lab": "Answer Lab",
            "f_p": "Favorite Page",
            "Account": "Account",
            "Selection": "Selection",
            "select": "Collapse",
            "Clear": "Clear",
            "test_site": "TEST site",
            "inv_supplier": "INVENTORY SUPPLIERS",
            "will_call_supplier": "WLL-CALL SUPPLIERS",
            "Parts List": "Parts List",
            "Supplier": "Supplier",
            "Manufacturer": "Manufacturer",
            "Mode": "Mode",
            "Help": "Help",
            "Tech Hub": "Tech Hub",
            "order_status": "Order Status",
            "Non-Catalog Request": "Non-Catalog Request",
            "punch_out": "Punch Out",
            "Maintenance": "Maintenance",
            "Privileges": "Privileges",
            "Entry": "Entry",
            "Reason": "Reason",
            "Choose File": "Choose File",
            "Archived": "Archived",
            "Page": "Page",
            "Total Items": "Total Items",
            "Location": "Location",
            "Summary": "Summary",
            "Confirm": "Confirm",
            "Over Pick": "Over Pick",
            "Under Pick": "Under Pick",
            "Zero Pick": "Zero Pick",
            "Yes": "Yes",
            "No": "No",
            "Order": "Order",
            "Picking Items": "Picking Items",
            "Info": "Info",
            "Select a site": "Select a site",
            "Item ID": "Item ID",
            "Action Code": "Action Code",
            "Utilization Code": "Utilization Code",
            "Forecast Family": "Forecast Family",
            "Zero On Hand": "Zero On Hand",
            "Replenish Type": "Replenish Type",
            "Replenish Class": "Replenish Class",
            "In Business Unit": "In Business Unit",
            "Replenish Status": "Replenish Status",
            "Not On Order": "Not On Order",
            "Cost": "Cost",
            "Extended Cost": "Extended Cost",
            "Process Replen": "Process Replen",
            "Average On Hand": "Average On Hand",
            "Growth": "Growth",
            "Order Forecast Model": "Order Forecast Model",
            "12 Month Forecast": "12 Month Forecast",
            "Month's Usage": "Month's Usage",
            "Next Order Date": "Next Order Date",
            "Next Order Qty": "Next Order Qty",
            "Data Posponed": "Data Posponed",
            "Review": "Review",
            "Next Review Date": "Next Review Date",
            "Days On Hand": "Days On Hand",
            "Excess Units": "Excess Units",
            "Excess Dollars": "Excess Dollars",
            "Receiveing Cost": "Receiveing Cost",
            "Service Level": "Service Level",
            "Max Weeks": "Max Weeks",
            "Carrying Cost": "Carrying Cost",
            "Item Stock Type": "Item Stock Type",
            "Safety stock": "Safety stock",
            "Package Size": "Package Size",
            "Max Capacity": "Max Capacity",
            "Lead-Time": "Lead-Time",
            "Optimal Order QTY": "Optimal Order Quantity",
            "Current On Hand": "Current On Hand",
            "Open PO Qty": "Open PO Qty",
            "Reserved Qty": "Reserved Qty",
            "Min": "Min",
            "Open": "Open",
            "Suggested Min": "Suggested Min",
            "Backorder": "Backorder",
            "Max": "Max",
            "Suggested Max": "Suggested Max",
            "Default Putaway": "Default Putaway",
            "Date Added": "Date Added",
            "Critical Spare": "Critical Spare",
            "Supplier Part Number": "Supplier Part Number",
            "Last Issues Date": "Last Issues Date",
            "Client Stock Type": "Client Stock Type",
            "Inventory Days On Hand": "Inventory Days On Hand",
            "Item Has Been Updated Successfully": "Item Has Been Updated Successfully",
            "Ok": "Ok",
            "Mismatch": "Mismatch",
            "Cycle Count": "Cycle Count",
            "Standard Leadtime": "Standard Leadtime",
            "Machine Number": "Machine Number",
            "Drawing": "Drawing",
            "Purchasing Dept Spec": "Purchasing Dept Spec",
            "Regrind": "Regrind",
            "First": "First",
            "Issue": "Issue",
            "Charge": "Charge",
            "Code": "Code",
            "Replenish": "Replenish",
            "Reorder Paint": "Reorder Paint",
            "Modifiers": "Modifiers",
            "Attributes": "Attributes",
            "Path": "Path",
            "Noun": "Noun",
            "Store Room": "Store Room",
            "No attributes avaliable": "No attributes avaliable",
            "Total Order Qty": "Total Order QTY",
            "SAP Article Number": "SAP Article Number",
            "No. of Pieces": "No. of Pieces",
            "Material": "Material",
            "Owned By": "Owned By",
            "Package": "Package",
            "Features": "Features",
            "Cycle Count Reconciliation": "Cycle Count Reconciliation",
            "Variance": "Variance",
            "Reconciliation Tag": "Reconciliation Tag",
            "Item Ready For Update": "Item Ready For Update",
            "Finalization": "Finalization",
            "Load Recon": "Load Recon",
            "Counting Event": "Counting Event",
            "Entered Item Count": "Entered Item Count",
            "Bin": "Bin",
            "Equipment Tag": "Equipment Tag",
            "Inspection Required": "Inspection Required",
            "Hazard": "Hazard",
            "Tracking": "Tracking",
            "Ship Via": "Ship Via",
            "In": "In",
            "Ship Date": "Ship Date",
            "Years": "Years",
            "Months": "Months",
            "Select Duration": "Select Duration",
            "Receive Items": "Receive Items",
            "Inventory Management System": "Inventory Management System",
            "Search containing value limit to date range entered above": "Search containing value limit to date range entered above",
            "Search containing value but limit to last 6 months": "Search containing value but limit to last 6 months",
            "Search exact value": "Search exact value",
            "Orders": "Orders",
            "Search by": "Search by",
            "Date range is limited to 3 months": "Date range is limited to 3 months",
            "To": "To",
            "From": "From",
            "Report": "Report",
            "Select Report (by Order date)": "Select Report (by Order date)",
            "Items": "Items",
            "Lookup": "Lookup",
            "Check": "Check",
            "WAREHOUSE": "WAREHOUSE",
            "Categories": "Categories",
            "No Search Result found": "No Search Result found",
            "Did you mean": "Did you mean",
            "You searched for": "You searched for",
            "Search Result": "Search Result",
            "Received": "Received",
            "Search": "Search",
            "supplier_callin": "Supplier Call-in",
            "Email Address": "Email Address",
            "Password": "Password",
            "Confirm Password": "Confirm Password",
            "Privilege": "Privilege ",
            "A la Carte": "A la Carte",
            "Program Tree View": "Program Tree View",
            "Program": "Program",
            "Freight Provider :": "Freight Provider :",
            "History": "History",
            "Buy again :": "Buy again :",
            "Expected": "Expected :",
            "Last Updated On": "Last Updated On",
            "Pending": "Pending",
            "Counted": "Counted",
            "Indicates Inventory Order": "Indicates Inventory Order",
            "Stock Value Report": "Stock Value Report",
            "Select": "Select",
            "GO": "GO",
            "Show Bin Loc": "Show Bin Loc",
            "Include Quantity On Hand": "Include Quantity On Hand",
            "ItemKey": "Item",
            "Planner CD": "Planner CD",
            "On Hand": "On Hand",
            "Currency": "Currency",
            "Run": "Run",
            "Count": "Count",
            "Avail": "Avail",
            "Crib": "Crib",
            "Aisle": "Aisle",
            "Row": "Row",
            "Shelf": "Shelf",
            "Reserv": "Reserv",
            "Actual": "Actual",
            "See not updated items": "See not updated items",
            "Change": "Change",
            "Add": "Add",
            "Track": "Track",
            "Current": "Current",
            "Added On": "Added On",
            "Other": "Other",
            "Approved": "Approved",
            "Waiting Approval Chain": "Waiting Approval Chain",
            "No Records found": "No Records found",
            "All": "All",
            "Image": "Image",
            "Waiting Order Approval": "Waiting Order Approval",
            "Advanced": "Advanced",
            "Stock": "Stock",
            "UnDelete": "UnDelete",
            "Line": "Line",
            "Do you want to remove this item?": "Do you want to remove this item?",
            "Favorites": "Favorites",
            "Show": "Show",
            "Start": "Start",
            "End": "End",
            "Search Criteria": "Search Criteria",
            "Contains": "Contains",
            "Any": "Any",
            "Remaining": "Remaining",
            "Returned": "Returned",
            "Reason": "Reason",
            "You don't have Permission to": "You don't have Permission to",
            "Access this page": "Access this page",
            "valid": "valid",
            "call us": "call us",
            "Contact sdi customer care": "Contact sdi customer care",
            "SDI MRO for a more": "SDI MRO for a more",
            "connected": "connected",
            "enterprise": "enterprise",
            "Sorry, we couldn’t find any search result": "Sorry, we couldn’t find any search result",
            "Do you want to remove this order ?": "Do you want to remove this order ?",
            "Reorder": "Reorder",
            "Part": "Part",
            "Reference": "Reference",
            "Door - DH Pace": "Door - DH Pace",
            "Lift Truck": "Lift Truck",
            "Liftparts": "Liftparts",
            "Terms and Conditions": "Terms and Conditions",
            "Zeus emarketplace Registration Terms": "Zeus emarketplace Registration Terms",
            "I Do not Agree": "I Do not Agree",
            "I Agree": "I Agree",
            "Submit a Non Catalog Request": "Submit a Non Catalog Request",
            "Site Under Construction": "Site Under Construction",
            "Will be Back Soon": "Will be Back Soon",
            "Back": "Back",
            "Do you want to archive this notification?": "Do you want to archive this notification?",
            "Merchandise": "Merchandise",
            "Extended": "Extended",
            "Usage": "Usage",
            "Issues": "Issues",
            "Sites": "Sites",
            "Purchase": "Purchase",
            "Transactions": "Transactions",
            "Available": "Available",
            "This price is subject to confirmation by supplier": "This price is subject to confirmation by supplier",
            "Add to Cart": "Add to Cart",
            "Maximum": "Maximum",
            "Item More Details": "Item More Details",
            "Year": "Year",
            "Previous": "Previous",
            "Transaction Date": "Transaction Date",
            "No Records to display": "No Records to display",
            "Owner": "Owner",
            "Lot/Serial Number": "Lot/Serial Number",
            "There are no bin locations to display for this item": "There are no bin locations to display for this item",
            "Related site data not available at this time.": "Related site data not available at this time.",
            "Net Received": "Net Received",
            "Operator Entered By": "Operator Entered By",
            "Receiver": "Receiver",
            "Estimated": "Estimated",
            "Tax Amount": "Tax Amount",
            "Payment": "Payment",
            "Credit Card": "Credit Card",
            "Debit Card": "Debit Card",
            "All Cards are accepted": "All Cards are accepted",
            "Card": "Card",
            "Wallet": "Wallet",
            "Expiry": "Expiry",
            "Pay Now": "Pay Now",
            "Select file to attach": "Select file to attach",
            "Attach": "Attach",
            "Would you like to use line numbers by increments of 10?": "Would you like to use line numbers by increments of 10?",
            "Select any one of your wallet account for your payment process": "Select any one of your wallet account for your payment process",
            "Do you want to make this as your favorite order?": "Do you want to make this as your favorite order?",
            "Success": "Success",
            "Payment has been successfully completed": "Payment has been successfully completed",
            "Are you sure you want to cancel your order?": "Are you sure you want to cancel your order?",
            "All charge code segments must be selected": "All charge code segments must be selected",
            "Notes": "Notes",
            "Is This Priority?": "Is This Priority?",
            "Part": "Part",
            "Browse": "Browse",
            "Tax Exempt": "Tax Exempt",
            "Add Item to Catalog": "Add Item to Catalog",
            "Please agree the terms and conditions": "Please agree the terms and conditions",
            "Market Place": "Market Place",
            "Attribute": "Attribute",
            "Warning! Default UOM list displayed - no cross reference data available for the current Business Unit": "Warning! Default UOM list displayed - no cross reference data available for the current Business Unit",
            "Save/More": "Save/More",
            "Save/Done": "Save/Done",
            "Quick": "Quick",
            "Quantity On Hand": "Quantity On Hand",
            "Due Date": "Due Date",
            "Approved By": "Approved By",
            "Ship": "Ship",
            "RFQ order and line number": "RFQ order and line number",
            "Request by Date": "Request by Date",
            "Recieved": "Received",
            "Received": "Received",
            "Reject": "Reject",
            "Invoice": "Invoice",
            "Packing Slips": "Packing Slips",
            "Stop": "Stop",
            "Load": "Load",
            "Shipping Date": "Shipping Date",
            "Trade": "Trade",
            "Ordered By": "Ordered By",
            "Ship Container": "Ship Container",
            "Comments": "Comments",
            "Fragile Item Signature": "Fragile Item Signature",
            "Truck": "Truck",
            "Driver": "Driver",
            "By": "By",
            "[proof-of-delivery]": "[proof-of-delivery]",
            "Close": "Close",
            "Loaded Container": "Loaded Container",
            "Last": "Last",
            "Days": "Days",
            "This": "This ",
            "Month": "Month",
            "Transaction": "Transaction",
            "Company-Consolidated": "Company-Consolidated",
            "Indicates Alternate Requestor Approver": "Indicates Alternate Requestor Approver",
            "Order Approval History": "Order Approval History",
            "Proof of delivery": "Proof of delivery",
            "Header": "Header",
            "Collapse All": "Collapse All",
            "Expand All": "Expand All",
            "Shipment": "Shipment",
            "Multiple": "Multiple",
            "Main Menu": "Main Menu",
            "Favorite": "Favorite",
            "Pages": "Pages",
            "You don't have any record": "You don't have any record",
            "Go to Cart": "Go to Cart",
            "Pricing": "Pricing",
            "Click on vendor's logo below to start browsing their catalog": "Click on vendor's logo below to start browsing their catalog",
            "ERROR": "ERROR",
            "PunchOut is not currently available for this Vendor": "PunchOut is not currently available for this Vendor",
            "A message was sent notifying site administrator of this problem": "A message was sent notifying site administrator of this problem",
            "We apologize for this inconvenience": "We apologize for this inconvenience",
            "Return": "Return",
            "There are no available vendors": "There are no available vendors",
            "Partner Vendor": "Partner Vendor",
            "Marketplace": "Marketplace",
            "SDIExchange - Request for Quote": "SDIExchange - Request for Quote",
            "Requestor": "Requestor",
            "Received By": "Received By",
            "Thank you for choosing": "Thank you for choosing",
            "Source": "Source",
            "We will notify you once your quote is ready": "We will notify you once your quote is ready",
            "Print": "Print",
            "Continue": "Continue",
            "Order Submitted for Processing": "Order Submitted for Processing",
            "Order Submitted for Quote": "Order Submitted for Quote",
            "User": "User",
            "Approvals": "Approvals",
            "Enable Multisite Access": "Enable Multisite Access",
            "In-Active Employee Account": "In-Active Employee Account",
            "In-Active Account": "In-Active Account",
            "Set Requestor Approval": "Set Requestor Approval",
            "Alternate Requestor Approval by": "Alternate Requestor Approval by",
            "Email": "Email",
            "Approval": "Approval",
            "Display": "Display",
            "Add to SDI Track": "Add to SDI Track",
            "Add Other User to SDI Track": "Add Other User to SDI Track",
            "More": "More",
            "Item Group": "Item Group",
            "Issue Charge Code": "Issue Charge Code",
            "Save": "Save",
            "Archive All": "Archive All",
            "Charge Code": "Charge Code",
            "Order Total": "Order Total",
            "More Details": "More Details",
            "Customer Reference": "Customer Reference",
            "Work Order #": "Work Order #",
            "Item base price": "Item Base Price",
            "Item qty": "Item QTY",
            "Order Summary & Payment Details": "Order Summary & Payment Details",
            "Pickup From Local Supplier": "Pickup From Local Supplier",
            "Check Order Status": "Check Order Status",
            "UserProfile": "UserProfile",
            "Favorite Item Maintenance": "Favorite Item Maintenance",
            "Order Approval": "Order Approval",
            "Waiting Requestor Approval": "Waiting Requestor Approval",
            "Requestor Approval": "Requestor Approval",
            "Order Status": "Order Status",
            "Receiving": "Receiving",
            "User Profile": "User Profile",
            "Approve Orders": "Approve Orders",
            "PunchOut": "PunchOut",
            "Order Status Report": "Order Status Report",
            "Add New User": "Add New User",
            "Edit Vendor Information": "Edit Vendor Information",
            "Edit User Information": "Edit User Information",
            "Edit Mexico Information": "Edit Mexico Information",
            "Activate/Inactivate Users": "Activate/Inactivate Users",
            "Add New Vendor": "Add New Vendor",
            "Add New Mexico": "Add New Mexico",
            "Edit Favorites": "Edit Favorites",
            "  TEST site! Nothing saved to Production!": "TEST site! Nothing saved to Production!",
            "Ordering": "Ordering",
            "Your User Profile": "Your User Profile",
            "User profile": "USER PROFILE",
            "Inventory": "Inventory",
            "SDI Category": "SDI Category",
            "Your Category": "Your Category",
            "Item Description": "Item Description",
            "Your Description": "Your Description",
            "MFG Part Number": "MFG Part Number",
            "Waiting For your Approval": "Waiting For your Approval",
            "This order/quote is still waiting approval from": "This order/quote is still waiting approval from ",
            "Order Number": "Order Number",
            "Order Entered by": "Order Entered by",
            "Business Unit": "Business Unit",
            "Work Order Number": "Work Order Number",
            "Order Entered By": "Order Entered By",
            "Enter ID": "Enter ID",
            "Notification ID": "Notification ID",
            "User Notification": "User Notification",
            "Punchout Catalog Search for ZEUS": "Punchout Catalog Search for ZEUS",
            "Enter Value": "Enter Value",
            "Enter First Name": "Enter First Name",
            "Enter Last Name": "Enter Last Name",
            "Enter E-Mail address": "Enter E-Mail address",
            "Enter Phone number": "Enter Phone number",
            "Enter Password": "Enter Password",
            "Enter Confirm Password": "Enter Confirm Password",
            "Enter User Id": "Enter User Id",
            "Select department": "Select department",
            "-----Select Department-----": "-----Select Department-----",
            "Enter SDITrack User ID": "Enter SDITrack User ID",
            "Corp Admin": "Corp Admin",
            "Super": "Super",
            "Admin": "Admin",
            "Disable Zeus": "Disable Zeus",
            "Enable Zeus": "Enable Zeus",
            "contact sdi customer care": "contact sdi customer care",
            "All fields are mandatory": "All fields are mandatory",
            "First Name": "First Name",
            "Last Name": "Last Name",
            "New PunchIn User Registration": "New PunchIn User Registration",
            "This website utilizes cookies and similar technologies for functionality and other purposes. Your use of this website constitutes your acceptance of cookies.": "This website utilizes cookies and similar technologies for functionality and other purposes. Your use of this website constitutes your acceptance of cookies.",
            "Ok, Got it": "Ok, Got it",
            "Punchin session has been end": "Punchin session has been end",
            "Click on exit button to return": "Click on exit button to return",
            "Exit": "Exit",
            "Catalog Details": "Catalog Details",
            "Non Catalog Details": "Non Catalog Details",
            "ItemDetail": "ItemDetail",
            "Catalog Item Details": "Catalog Item Details",
            "Non Catalog Item Details": "Non Catalog Item Details",
            "PunchOut with our merchant partners": "PunchOut with our merchant partners",
            "Lift Truck - Coats Garage": "Lift Truck - Coats Garage",
            "Lift Truck - Wiese": "Lift Truck - Wiese",
            "Lift Truck - HGM Liftparts": "Lift Truck - HGM Liftparts",
            "Reference Site": "Reference Site",
            "Last 7 Days": "Last 7 Days",
            "Last 15 Days": "Last 15 Days",
            "Last 30 Days": "Last 30 Days",
            "Last Month": "Last Month",
            "This Month": "This Month",
            "Group": "Group",
            "Order #": "Order Number",
            "Work Order": "Workorder Number",
            "PO Id": "PO ID",
            "Ordered For": "Ordered For",
            "Enter Name": "Enter Name",
            "Enter Description": "Enter Description",
            "Enter Estimated Price": "Enter Estimated Price",
            "Ln": "Ln",
            "Sch": "Sch",
            "PO": "PO",
            "qty": "QTY",
            "Line Status": "Line Status",
            "PO": "PO",
            "Addr Seq": "Addr Seq",
            "Session Timeout": "Session Timeout",
            "Go to login": "Go to login",
            "Login": "Login",
            "Now exiting": "Now exiting",
            "and returning to": "and returning to",
            "disabling session connection...": "disabling session connection...",
            "Please click here, if this page does not automatically refresh in 5 seconds": "Please click here, if this page does not automatically refresh in 5 seconds",
            "MFG name": "MFG name",
            "Online Catalog Load": "Online Catalog Load",
            "Loading": "Loading",
            "Please Wait": "Please Wait",
            "Enter Old Password": "Enter Old Password",
            "Enter New Password": "Enter New Password",
            "Price on request": "Price on request",
            "Non Catalog Request": "Non Catalog Request",
            "Order Charge Code": "Order Charge Code",
            "Enter charge code": "Enter charge code",
            "Enter value": "Enter value",
            "Enter Machine Number": "Enter Machine Number",
            "Enter your Card Number": "Enter your Card Number",
            "Enter Part No": "Enter Part No",
            "Enter Location": "Enter Location",
            "Enter CVV/CVC": "Enter CVV/CVC",
            "Submit Order": "Submit Order",
            "Check Out": "Check Out",
            "*Employee ID": "*Employee ID",
            ": (by name)": ": (by name)",
            "Requisitioner Name": "Requisitioner Name",/* removed the colon, since it is not binding after language is changed*/
            "Log in to your account": "Log in to your account",
            "Forgot Password ?": "Forgot Password ?",
            "Speed to Execution": "Speed to Execution",
            "Simplify": "Simplify",
            "Commitment": "Commitment",
            "Perseverance": "Perseverance",
            "Courage": "Courage",
            "Solution-Oriented": "Solution-Oriented",
            "inc. All Rights Reserved": "inc. All Rights Reserved",
            "Zeus ID": "Zeus ID",
            "Request Forgotten Password": "Request Forgotten Password",
            "Enter Login Id": "Enter login Id",
            "Reset Password": "Reset Password",
            "Back To Login": "Back To Login",
            "Password will be reset and sent to the email address that was set up in the Login profile for the Login ID entered above.": "Password will be reset and sent to the email address that was set up in the Login profile for the Login ID entered above.",
            "*Work Order #": "*Work Order #",
            "Invalid item id": "Invalid item id",
            "Invalid or the itemid already exists": "Invalid or the itemid already exists",

            "Technical Issue Try again": "Technical Issue Try again",
            "Deleted Successfully": "Deleted Successfully",
            "Updated Successfully": "Updated Successfully",
            "Please enter Item Id": "Please enter Item Id",
            "You are not allowed to use 'Shopping Cart' here. \nYou must login from SAP.": "You are not allowed to use 'Shopping Cart' here. You must login from SAP.",
            "Links are for reference information only.\nDo not place orders from suppliers site.": "Links are for reference information only. Do not place orders from suppliers site.",
            "True": "True",
            "Please agree terms and conditions to access shopping cart": "Please agree terms and conditions to access shopping cart",
            "Insufficient privileges for adding to cart": "Insufficient privileges for adding to cart",
            "You are not allowed to use this screen here \n you must login from SAP": "You are not allowed to use this screen here you must login from SAP",
            "Item successfully added to the favorite list": "Item successfully added to the favorite list",
            "Due to technical Issue item is not added to the favorite list.Try after sometime.": "Due to technical Issue item is not added to the favorite list.Try after sometime.",
            "Technical issue.Try after sometime.": "Technical issue.Try after sometime.",
            "Cart is empty": "Cart is empty",
            "Please fill the delivery date": "Please fill the delivery date",
            "Please fill all item level employee id": "Please fill all item level employee id",
            "Please fill all Mandatory Fields": "Please fill all Mandatory Fields",
            "Please enter work order number or charge code": "Please enter work order number or charge code",
            "Please fill all item level charge code": "Please fill all item level charge code",
            "Please fill Work Order": "Please fill Work Order",
            "Error while updating WALLET": "Error while updating WALLET",
            "Technical Issue -- Contact SDI": "Technical Issue -- Contact SDI",
            "Card number is required": "Card number is required",
            "Invalid card number": "Invalid card number",
            "Expiration month & Year is required": "Expiration month & Year is required",
            "CVV/CVC Number is required": "CVV/CVC Number is required",
            "Invalid CVV/CVC number": "Invalid CVV/CVC number",
            "Invalid expiry date": "Invalid expiry date",
            "Error In Payment Process": "Error In Payment Process",
            "--Select Charge Code--": "--Select Charge Code--",
            "Card already exist in user wallet": "Card already exist in user wallet",
            "You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names.": "You have exceeded the length of total file names. Please adjust your entry or remove longer file names.",
            "No file is selected": "No file is selected",
            "No Item Number entered for Quick Item Entry": "No Item Number entered for Quick Item Entry",
            "Invalid Item Number entered for Quick Item Entry": "Invalid Item Number entered for Quick Item Entry",
            "Techincal Issue Contact SDI Customer Care": "Techincal Issue Contact SDI Customer Care",
            "Enter valid Work Order Number": "Enter valid Work Order Number",
            "Order Number already exist": "Order Number already exist",
            "No enterprise record found": "No enterprise record found",
            "Order total exceeds budgetary limit, Kindly reach administration team": "Order total exceeds budgetary limit, Kindly reach administration team",
            "All serialized items must be assigned a serial ID.": "All serialized items must be assigned a serial ID.",
            "Error in approval update - please contact Help Desk.": "Error in approval update - please contact Help Desk.",
            "ReqID DB error - Contact Support": "ReqID DB error - Contact Support",
            "Description required for the NSTK item.": "Description required for the NSTK item.",
            "Selected file is already attached, please try again": "Selected file is already attached, please try again",
            "Your Order has been Approved/Declined Successfully": "Your Order has been Approved/Declined Successfully",
            "Problem in approval/decline process, Your Order has not been Approved/Declined": "Problem in approval/decline process, Your Order has not been Approved/Declined",
            "No Orders are selected to approve": "No Orders are selected to approve",
            "No Orders are selected to decline": "No Orders are selected to decline",
            "Remove": "Remove",
            "Enter Confirm Password": "Enter Confirm Password",
            "Item does not exist in the catalog": "Item does not exist in the catalog",
            "Please enter item ID": "Please enter item ID",
            "Req MFG": "Manufacturer name and manufacturer part number are required to check the catalog",
            "Enter a valid Quantity": "Enter a valid Quantity",
            "is not added to cart due to technical issue. Contact SDI customer care": "is not added to cart due to technical issue. Contact SDI customer care",
            "Tec_Issue": "Techincal Issue Contact SDI Customer Care",
            "Favorite item inserted sucessfully": "Favorite item inserted sucessfully",
            "Item has been approved successfully": "Item has been approved successfully",
            "Item has been declined successfully": "Item has been declined successfully",
            "Selected items has been approved successfully": "Selected items has been approved successfully",
            "Selected items has been declined successfully": "Selected items has been declined successfully",
            "Please select line items to Approve/Decline": "Please select line items to Approve/Decline",
            "Please enter first name": "Please enter first name",
            "Please enter last name": "Please enter last name",
            "Please enter email address": "Please enter email address",
            "Please enter valid email address": "Please enter valid email address",
            "Please select shipto": "Please select shipto",
            "Please select the ship to": "Please select the ship to",
            "Enter category": "Enter category",
            "Enter sub category": "Enter sub category",
            "Please enter the address": "Please enter the address",
            "You have exceeded the length of total file names": "You have exceeded the length of total file names",
            "Please adjust your entry or remove longer file names": "Please adjust your entry or remove longer file names",
            "New Password updated successfully": "New Password updated successfully",
            "Old Password is Incorrect": "Old Password is Incorrect",
            "Old Password is required": "Old password is required",
            "New Password is required": "New password is required",
            "Confirm Password is required": "Confirm password is required",
            "Your new password has been sent to your email address.": "Your new password has been sent to your email address.",
            "Please enter required fields": "Please enter required fields",
            "Please enter user id": "Please enter user id",
            "Are you sure you want to reset your password?": "Are you sure you want to reset your password?",
            "Duplicate UserID''s.  Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Duplicate UserID''s.Contact your SDI site contact or call SDI Information Technology at 215-633 - 1900",
            "Email does not match email for this user, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Email does not match email for this user, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900",
            "Not a valid email for your userid, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Not a valid email for your userid, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900",
            "Reset failed! Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Reset failed! Contact your SDI site contact or call SDI Information Technology at 215-633-1900",
            "UserID does not exist, Password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "UserID does not exist, Password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900",
            "Client is inactive": "Client is inactive",
            "Your account has been disabled due to excessive invalid login attempts.Please contact the Help Desk at 215-633-1900, option 7 to reinstate your account.": "Your account has been disabled due to excessive invalid login attempts.Please contact the Help Desk at 215-633-1900, option 7 to reinstate your account.",
            " **Error - your password has expired. Click on the \"Forgot your Password\" link to be assigned a new password": " **Error - your password has expired. Click on the \"Forgot your Password\" link to be assigned a new password",
            "Invalid ID/password": "Invalid ID/password",
            "Provided username and password is incorrect": "Provided username and password is incorrect",
            "View only mode flag set - order not saved": "View only mode flag set - order not saved",
            "Enter Quantity for at least one item": "Enter Quantity for at least one item",
            "notification Archived": "notification Archived",
            "please try again": "please try again",
            "Please select any Notification": "Please select any Notification",
            "BlckShopCart": "You are not allowed to use this screen here  you must login from SAP",
            "Please enter quantity": "Please enter quantity",
            "PO is invalid": "PO is invalid",
            "Problem creating User ID. Please contact Help Desk": "Problem creating User ID. Please contact Help Desk",
            "no data found": "no data found",
            "Item added to cart successfully": "Item added to cart successfully",
            "**Warning - this is a test web site only...\nNo activity will be recorded as production.": "**Warning - this is a test web site only...No activity will be recorded as production.",
            "Date range should be within 3 months": "Date range should be within 3 months",
            "Select date range or search by criteria": "Select date range or search by criteria",
            "select Order status": "Select Order status",
            "select any criteria": "Select any criteria",
            "invalid from date": "Invalid from date",
            "invalid to date": "Invalid to date",
            "Please select From and To Date": "Please select From and To Date",
            "Please enter search criteria": "Please enter search criteria",
            "Please select the employee ID": "Please select the employee ID",
            "Select any Report (by order date)": "Select any Report (by order date)",
            "Employee List is too long": "Employee List is too long",
            "Error updating record from SDIX_RPT_PRESETS": "Error updating record from SDIX_RPT_PRESETS",
            "Please select the Business Unit": "Please select the Business Unit",
            "Your Selection Criteria Preferences were saved successfully!": "Your Selection Criteria Preferences were saved successfully!",
            "Select Report Date Field": "Select Report Date Field",
            "Can not save other Business unit for Report type - Company consolidated": "Can not save other Business unit for Report type - Company consolidated",
            "Select Employee": "Select Employee",
            "Please select any item to buy again": "Please select any item to buy again",
            "SDiTrack Issue": "SDiTrack Issue",
            "Error - User does not exist in ISA_USERS_TBL!": "Error - User does not exist in ISA_USERS_TBL!",
            "Error - User exist more than once in ISA_USERS_TBL table!": "Error - User exist more than once in ISA_USERS_TBL table!",
            "Entered Vendor ID is Invalid": "Entered Vendor ID is Invalid",
            "Please enter Vendor ID": "Please enter Vendor ID",
            "Error - password has already been used": "Error - password has already been used",
            "Error Updating ISA_USERS_TBL Table": "Error Updating ISA_USERS_TBL Table",
            "Error Updating ISA_ISOL_PW Table": "Error Updating ISA_ISOL_PW Table",
            "Error Updating PS_ISA_EMPL_TBL Table": "Error Updating PS_ISA_EMPL_TBL Table",
            "Error Updating ISA_USERS_PRIVS Table": "Error Updating ISA_USERS_PRIVS Table",
            "User ID already exists": "User ID already exists",
            "Invalid User ID": "Invalid User ID",
            "Error - No Business Unit Selected!": "Error - No Business Unit Selected!",
            "Error - Invalid BU - check productview id's!": "Error - Invalid BU - check productview id's!",
            "Please select a user in User Detail before editing privileges!": "Please select a user in User Detail before editing privileges!",
            "Please save User Detail data before editing privileges!": "Please save User Detail data before editing privileges!",
            "Error - Invalid Business Unit - check productview id!": "Error - Invalid Business Unit - check productview id!",
            "Business unit is not set up as an approver site.": "Business unit is not set up as an approver site.",
            "Error - Invalid Business Unit - check productview ids!": "Error - Invalid Business Unit - check productview ids!",
            "Technical issue": "Technical issue",
            "Update Failed": "Update Failed",
            "Budgetory approver details deleted successfully.": "Budgetory approver details deleted successfully.",
            "Budgetory approver details deleted failed.": "Budgetory approver details deleted failed.",
            "Budgetory approver details updated successfully.": "Budgetory approver details updated successfully.",
            "Budgetory approver details inserted successfully.": "Budgetory approver details inserted successfully.",
            "Requestor approver deleted successfully.": "Requestor approver deleted successfully.",
            "Requestor approver details deleted failed.": "Requestor approver details deleted failed.",
            "Requestor approver updated successfully.": "Requestor approver updated successfully.",
            "Requestor approver details inserted successfully.": "Requestor approver details inserted successfully.",
            "User order status email has been modified and saved successfully.": "User order status email has been modified and saved successfully.",
            "User preference has been modified and saved successfully": "User preference has been modified and saved successfully",
            "User information has been modified and saved successfully.": "User information has been modified and saved successfully.",
            "Update failed. Error message was sent to IT group. Please contact help desk.": "Update failed. Error message was sent to IT group. Please contact help desk.",
            "User Added to SDiTrack": "User Added to SDiTrack",
            "UserName already exists in SDiTrack": "UserName already exists in SDiTrack",
            "This SDI User account is now active.": "This SDI User account is now active.",
            "There was an issue trying to activate this account.": "There was an issue trying to activate this account.",
            "This SDI User account is now in active.": "This SDI User account is now in active.",
            "This employee account is now active.": "This employee account is now active.",
            "This employee account is now in active.": "This employee account is now in active.",
            "There was an issue trying to inactivate this employee account.": "There was an issue trying to inactivate this employee account.",
            "Activate Employee Account": "Activate Employee Account",
            "In-Activate Employee Account": "In-Activate Employee Account",
            "Activate Account": "Activate Account",
            "In-Activate Account": "In-Activate Account",
            "The BU does not have an SDiTrack account.": "The BU does not have an SDiTrack account.",
            "SDiTrack User ID": "SDiTrack User ID",
            "The user is not assigned to a valid BU.": "The user is not assigned to a valid BU.",
            "Please select a role.": "Please select a role.",
            "Please select atleast one program.": "Please select atleast one program.",
            "A valid approval name must be selected": "A valid approval name must be selected",
            "Role is assigned so order status email cannot be updated": "Role is assigned so order status email cannot be updated",
            "Please Select the Option": "Please Select the Option",
            "Set Budgetary Approval": "Set Budgetary Approval",
            "Please enter vendor id": "Please enter vendor id",
            "Please enter first Name": "Please enter first Name",
            "Please select user role": "Please select user role",
            "Please enter last name": "Please enter last name",
            "Please enter email address": "Please enter email address",
            "Please enter phone number": "Please enter phone number",
            "Please enter valid phone Number": "Please enter valid phone Number",
            "Please enter password": "Please enter password",
            "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!": "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!",
            "Password must contain at least 1 number and 1 letter - must start with a letter!": "Password must contain at least 1 number and 1 letter - must start with a letter!",
            "Please enter confirm password": "Please enter confirm password",
            "Password doesn't match": "Password doesn't match",
            "Please select site": "Please select site",
            "Please select a crib": "Please select a crib",
            "Budgetary Approval by": "Budgetary Approval by",
            "Enter the vendor ID": "Enter the vendor ID",
            "Please select a PO or enter any PO": "Please select a PO or enter any PO",
            "Favorite Pages": "Favorite Pages",
            "My Account": "My Account",
            "Favorite Orders": "Favorite Orders",
            "Search for Product": "Search for Product",
            "SearchResults": "SearchResults",
            "is not added to cart due to technical issue. Contact SDI customer care.": "is not added to cart due to technical issue. Contact SDI customer care.",
            "Favorite Item Maintanance": "Favorite Item Maintanance",
            "Favorite Item Maintenance": "Favorite Item Maintenance",
            "*Employee ID: (by name)": "*Employee ID: (by name)",
            "*Employee ID (by name)": "*Employee ID (by name)",
            "Employee ID": "Employee ID",
            "*Ship To": "*Ship To",
            "Select Ship To": "--Select Ship To--",
            "Show All": "Show All",
            "Kit Flag": "Kit Flag",
            "Your order placed successfully and added to favorites order": "Your order placed successfully and added to favorites order",
            "Your order placed successfully": "Your order placed successfully",
            "Cart Confirm Report": "Cart Confirm Report",
            "Created Date": "Created Date",
            "Order Type": "Order Type",
            "Report Type": "Report Type",
            "All Orders": "All Orders",
            "My Orders": "My Orders",
            "Open Orders": "Open Orders",
            "Group Orders": "Group Orders",
            "Search Transaction": "Search Transaction",
            "Ship to location": "Ship To Location",
            "Ship To Address": "Ship To Address",
            "Bin Location": "Bin Location",
            "Search User": "Search User",
            "Item Type": "Item Type",
            "Site Name": "Site Name",
            "Exchange": "Exchange",
            "My Favorite Item": "My Favorite Item",
            "Catalog Search": "Catalog Search",
            "Catalog Lookup": "Catalog Lookup",
            "Add Multiple Items": "Add Multiple Items",
            "Item Details": "Item Details",
            "Search Sites": "Search Sites",
            "Purchase History": "Purchase History",
            "Product Description": "Product Description",
            "Quantity Available": "Quantity Available",
            "First Charge Code": "First Charge Code",
            "Hazard Code": "Hazard Code",
            "Drawing Code": "Drawing Code",
            "Current Year": "Current Year",
            "Previous Year": "Previous Year",
            "If the data provided is correct, you will receive an email with password reset instructions": "If the data provided is correct, you will receive an email with password reset instructions",
            "Priceonrequest": " : Price on request",
            "Usually ships": "Usually ships",
            "days": "days",
            "Tech Reference": "Tech Reference",
            "Select charge code": "Select charge code",
            "Unit Price": "Unit Price",
            "Favorite Items": "Favorite Items",
            "Select any Order Type": "Select any Order Type",
            "Select any Report Type": "Select any Report Type",
            "Select any Business Unit": "Select any Business Unit",
            "Select Order Type": "Select Order Type",
            "Select Report Type": "Select Report Type",
            "Select Business Unit": "Select Business Unit",
            "My Favorite Page": "My Favorite Page",
            "My Profile": "My Profile",
            "(OR)": "(OR)",
            "Save Preference": "Save Preference",
            "Priority Orders": "Priority Orders",
            "Apply Filter": "Apply Filter",
            "Make sure report type is site report, when ordered for selected in search by dropdown": "Make sure report type is site report, when ordered for selected in search by dropdown",
            "Return to Shopping Cart": "Return to Shopping Cart",
            "Profile Update": "Profile Update",
            "User ID": "User ID",
            "User Type": "User Type",
            "Add User": "Add User",
            "Change Password": "Change Password",
            "User Privileges": "User Privileges",
            "Privilege Type": "Privilege Type",
            "Select All": "Select All",
            "Deselect All": "Deselect All",
            "SDI Exchange Approval": "SDI Exchange Approval",
            "Order Status Emails": "Order Status Emails",
            "Customer Information": "Customer Information",
            "Vendor Information": "Vendor Information",
            "RFQ Approved": "RFQ Approved",
            "RFQ Rejected": "RFQ Rejected",
            "Quote Rejected": "Quote Rejected",
            "RFQ Completed": "RFQ Completed",
            "Non-Catalog Request Entry": "Non-Catalog Request Entry",
            "Manufacturer Name": "Manufacturer Name",
            "Recommended Supplier": "Recommended Supplier",
            "Estimated Price": "Estimated Price",
            "Manufacturer Part Number": "Manufacturer Part Number",
            "Check Catalog": "Check Catalog",
            "MFG Part Number and MFG Name Required": "MFG Part Number and MFG Name Required",
            "within": "within",
            "after": "after",
            "Add Favorites": "Add Favorites",
            "Catalog Description": "Catalog Description",
            "Client Description": "Client Description",
            "Info Item": "Info Item",
            "Back to search results": "Back to search results",
            "Ext Price": "Ext Price",
            "Item id": "Item id",
            "--------Select Approver-------": "--------Select Approver-------",
            "User preference has been modified and saved successfully.": "User preference has been modified and saved successfully.",
            "Advanced Filter": "Advanced Filter",
            "Order Date": "Order Date",
            "Waiting Customer Approval": "Waiting Customer Approval",
            "Product Display Type": "Product Display Type",
            "This SDI User account has been disabled due to excessive invalid login attempts. Please contact the Help Desk at 215-633-1900, option 7 to reinstate the account.": "This SDI User account has been disabled due to excessive invalid login attempts. Please contact the Help Desk at 215-633-1900, option 7 to reinstate the account.",
            "Edit Mode": "Edit Mode",
            "Cancel Order": "Cancel Order",
            "Priority Order": "Priority Order",
            "Revised Due Date": "Revised Due Date",
            "Ship Document": "Ship Document",
            "Order Details": "Order Details",
            "Archive Notification": "Archive Notification",
            "SDI Track": "SDI Track",
            "SDI Employee": "SDI Employee",
            "Vendor ID": "Vendor ID",
            "Current User": "Current User",
            "SDI Track User ID": "SDI Track User ID",
            "SDI Track GUID": "SDI Track GUID",
            "Other User": "Other User",
            "Add User ID": "Add User ID",
            "SDI Item ID": "SDI Item ID",
            "Mfg Item ID": "Mfg Item ID",
            "Vendor Item ID": "Vendor Item ID",
            "PO QTY": "PO QTY",
            "Open QTY": "Open QTY",
            "Received QTY": "Received QTY",
            "Tracking Number": "Tracking Number",
            "Business Unit In": "Business Unit In",
            "Employee Name": "Employee Name",
            "PO Price": "PO Price",
            "PO Number": "PO Number",
            "6 Months": "6 Months",
            "12 Months": "12 Months",
            "2 Years": "2 Years",
            "PO Date": "PO Date",
            "QTY Received": "Quantity Received",
            "Sch Number": "Sch #",
            "Original Due Date": "Original Due Date",
            "Reject QTY": "Reject QTY",
            "Invoice Amount": "Invoice Amount",
            "Invoice Date": "Invoice Date",
            "Vendor Location": "Vendor Location",
            "SDI PO": "SDI PO",
            "Stop ID": "Stop ID",
            "Load ID": "Load ID",
            "Trucking / Delivery From": "Trucking / Delivery From",
            "ROC": "ROC",
            "District": "District",
            "BLDG#": "BLDG#",
            "Truck Number": "Truck Number",
            "Date Delivered": "Date Delivered",
            "Total Count": "Total Count",
            "Site Report": "Site Report",
            "Company-Consolidated Report": "Company-Consolidated Report",
            "Site Business Unit": "Site Business Unit",
            "--Select Status--": "--Select Status--",
            "Line #": "Line #",
            "Approved By User ID": "Approved By User ID",
            "Approval Status": "Approval Status",
            "Approval Type": "Approval Type",
            "PO Details": "PO Details",
            "PO Header Status": "PO Header Status",
            "Req Line #": "Req Line #",
            "Purchase Price": "Purchase Price",
            "Shipment BOL": "Shipment BOL",
            "has been added to cart": "has been added to cart",
            "Yes, I Agree": "Yes, I Agree",
            "Unit QTY": "Unit Quantity",
            "Submit Date": "Submit Date",
            "There was an error declining the quote. A report has been logged with Support.": "There was an error declining the quote. A report has been logged with Support.",
            "Order total exceeds budgetary limit, Kindly reach administration team": "Order total exceeds budgetary limit, Kindly reach administration team",
            "Error: W/O# is not valid! You can only Decline this order. To Approve please enter Valid W/O number": "Error: W/O# is not valid! You can only Decline this order. To Approve please enter Valid W/O number",
            "Error: W/O# is not valid! To Approve please enter Valid W/O number": "Error: W/O# is not valid! To Approve please enter Valid W/O number",
            "Error: Must Enter a W/O number!!": "Error: Must Enter a W/O number!!",
            "Error Must Enter a W/O#!!": "Error Must Enter a W/O#!!",
            "There was an error approving the quote. A report has been logged with Support.": "There was an error approving the quote. A report has been logged with Support.",
            "Store Room Description": "Store Room Description",
            "Line NBR": "Line NBR",
            "Cancel Status": "Cancel Status",
            "Net Received Quantity": "Net Received Quantity",
            "Receiver ID": "Receiver ID",
            "Receiver Line": "Receiver Line",
            "PO Line": "PO Line",
            "Error --- Problem with e-mail supplied: contact your System Administrator for assistance": "Error --- Problem with e-mail supplied: contact your System Administrator for assistance",
            "Error --- Your account is not Active: contact your System Administrator for assistance": "Error --- Your account is not Active: contact your System Administrator for assistance",
            "Error missing parameters": "Error missing parameters",
            "SDI ZEUS Online Catalog Load": "SDI ZEUS Online Catalog Load",
            "Loading ...Please Wait": "Loading ...Please Wait",
            "Extended Client Description": "Extended Client Description",
            "SDI Marketplace": "SDI Marketplace",
            "SDI Quote Reference Number": "SDI Quote Reference Number",
            "Item Number": "Item Number",
            "Requestor Name": "Requestor Name",
            "Continue(No Print)": "Continue(No Print)",
            "Notification Details": "Notification Details",
            "Price Details": "Price Details",
            "Card Number": "Card Number",
            "Payment Details": "Payment Details",
            "Estimated Tax Amount": "Estimated Tax Amount",
            "SDI Requisition Number": "SDI Requisition Number",
            "Submitted Date": "Submitted Date",
            "Add New Favorites": "Add New Favorites",
            "Catalog Path": "Catalog Path",
            "Show Extended Description": "Show Extended Description",
            "Package Quantity": "Package Quantity",
            "Estimated Ext. Price": "Estimated Ext. Price",
            "Reference Number": "Reference Number",
            "My Wallet": "My Wallet",
            "Add Attachment": "Add Attachment",
            "Edit Description": "Edit Description",
            "Expiry Date": "Expiry Date",
            "Clear Card": "Clear Card",
            "Merchandise Total": "Merchandise Total",
            "Customer Order": "Customer Order",
            "MFG Part #": "MFG Part #",
            "Stock Type": "Stock Type",
            "Approval Total": "Approval Total",
            "My Favorite Order": "My Favorite Order",
            "Catalog Pricing": "Catalog Pricing",
            "Check All": "Check All",
            "Decheck All": "Decheck All",
            "Items In": "Items In",
            "SDI Store Room": "SDI Store Room",
            "SDI Market Place": "SDI Market Place",
            "Quick Confirm": "Quick Confirm",
            "No Vendor Available": "No Vendor Available",
            "Back To Home": "Back To Home",

            "CVV/CVC Number": "CVV/CVC Number",
            "Merchandise Total": "Merchandise Total",
            "Machine Control Num": "Machine Control Num",
            "Trans OperID": "Trans OperID",
            "** Non-Catalog Orders require an additional process and you will be notified via email when your quote is available. At which point you will need to come back to this catalog to approve for final procession.**": "** Non-Catalog Orders require an additional process and you will be notified via email when your quote is available. At which point you will need to come back to this catalog to approve for final procession.**",
            "Your MRO Source": "Your MRO Source",
            "User Details": "User Details",
            "Select Site": "Select Site",
            "Select Type": "Select Type",
            "User Segment 1": "User Segment 1",
            "User Segment 2": "User Segment 2",
            "User Segment 3": "User Segment 3",
            "Employee Segment": "Employee Segment",
            "Item Segment": "Item Segment",
            "Machine Segment": "Machine Segment",
            "Select crib": "Select crib",
            "Door - RollSeal": "Door - RollSeal",
            "Type": "Type",
            "The file for download does not exist": "The file for download does not exist",
            "Technical Issue Contact SDI Customer Care": "Technical Issue Contact SDI Customer Care",
            "Please enter valid quantity": "Please enter valid quantity",
            "Stock Items cannot be added to favorites. Please use the Mobile Issuing program to request stock.": "Stock Items cannot be added to favorites. Please use the Mobile Issuing program to request stock.",
            "Invalid itemid entered": "Invalid itemid entered",
            "Item already in favorite list.": "Item already in favorite list.",
            "Please enter Manufacturer Part Number": "Please enter Manufacturer Part Number",
            "Please enter Manufacturer Name": "Please enter Manufacturer Name",
            "Please enter Description": "Please enter Description",
            "ACCOUNT": "ACCOUNT",
            "DEPARTMENT": "DEPARTMENT",
            "Client Attributes": "Client Attributes",
            "No client attributes available": "No client attributes available",
            "Item PO Price": "Item PO Price",
            "Item PO Ext Price": "Item PO Ext Price",
            "QTY Open PO": "QTY Open PO",
            "Item USD Price": "Item USD Price",
            "Ext. USD Price": "Ext. USD Price",
            "Next Approver is not active, So the order cannot be approved": "Next Approver is not active, So the order cannot be approved",
            "Selected orders are not approved": "Selected orders are not approved",
            "Problem encountered in approval update - please contact Help Desk.": "Problem encountered in approval update - please contact Help Desk.",
            "No database connection.": "No database connection.",
            "There was an error declining the order.A report has been logged with Support.": "There was an error declining the order.A report has been logged with Support.",
            "Encountered an error trying to approve selected order(s). Please review each order's messages and correct or unselect order with error and try to run approval again.": "Encountered an error trying to approve selected order(s). Please review each order's messages and correct or unselect order with error and try to run approval again.",
            "No order(s) were selected. Please select order(s) to approve.": "No order(s) were selected. Please select order(s) to approve.",
            "Missing Business Unit and/or Order Number.  Please call SDI Helpdesk for assistance.": "Missing Business Unit and/or Order Number.  Please call SDI Helpdesk for assistance.",
            "PO Order Total": "PO Order Total",
            "*Order Charge Code": "*Order Charge Code",
            "Archived Notification": "Archived Notification",
            "Your Request Id": "Your Request Id",
            "Error in submitting the PO": "Error in submitting the PO",
            "Charge code for user": "Charge code for user",
            "Address": "Address",
            "*Address": "*Address",
            "Please enter a valid address": "Please enter a valid address",
            "Valid address": "Valid address",
            "Items have to be from the same supplier for pickup to be enabled": "Items have to be from the same supplier for pickup to be enabled",
            "Uncheck pickup to add address": "Uncheck pickup to add address",
            "Unselect ship to to add address": "Unselect ship to to add address",
            "Select Supplier Location for pickup": "Select Supplier Location for pickup",
            "Pickup Store": "Pickup Store",
            "Partially available": "Partially available",
            "Contact branch": "Contact Branch",
            "Alert": "Alert",
            "Lowe's items cannot be mixed with other items": "Lowe's items cannot be mixed with other items",
            "Please select the pickup store": "Please select the pickup store",
            "Please Enter a valid Zip Code": "Please enter a valid zip code",
            "Error in processing zip code": "Error in processing zip code",
            "Please Enter a Zip Code": "Please enter a zip code",
            "Enter Zip Code": "Enter zip code",
            "Expected Delivery Date": "Expected Delivery Date",
            "Pick up is default for Home depot and Lowe's items": "Pick up is default for Home depot and Lowe's items",
            "Home depot items cannot be mixed with other items": "Home depot items cannot be mixed with other items",
            "Home depot and Lowe's items cannot be mixed with other items": "Home depot and Lowe's items cannot be mixed with other items",
            "Supplier Call-In": "Supplier Call-In",
            "Vendor ID": "Vendor ID",
            "Currently Vendors are Not Available": "Currently Vendors are Not Available",
            "Address not available for the selected Vendor": "Address not available for the selected Vendor",
            "*Please allow the location for displaying miles": "*Please allow the location for displaying miles",
            "Phone Number": "Phone Number",
            "Phone Number is not available": "Phone Number is not available",
            "Asset ID": "Asset ID",
            "Enter Asset ID": "Enter Asset ID",
            "The items in the cart will be removed by changing the WO": "The items in the cart will be removed by changing the WO",
            "Issue in clearing the cart": "Issue in clearing the cart",
            "Please enter work order number": "Please enter work order number",
            "Ships By": "Ships By",
            "Unable to add Grainger item using your current work order number": "Unable to add Grainger item using your current work order number",
            "Please enter valid work order number": "Please enter valid work order number",
            "Unable to submit Grainger item using your current work order number": "Unable to submit Grainger item using your current work order number",
            "Contact Us": "Contact Us",
            "Unable to submit Diversified supplier item using your current work order number": "Unable to submit Diversified supplier item using your current work order number",
            "WO Description": "WO Description",
            "Please select an item to share": "Please select an item to share",
            "Please select a user to share": "Please select a user to share",
            "Users are not available to share": "Users are not available to share",
            "Sharing failed. Please try again later": "Sharing failed. Please try again later",
            "Shared successfully": "Shared successfully",
            "Invalid email address": "Invalid email address",
            "Invalid work order": "Invalid work order",
            "Error occured in work order validation": "Error occured in work order validation",
            "Internal error": "Internal error",
            "Active Users - SDI/Customers": "Active Users - SDI/Customers",
            "User Name": "User Name",
            "Role Type": "Role Type",
            "Role Name": "Role Name",
            "Default Putaway Locations": "Default Putaway Locations",
            "Item ID": "Item ID",
            "Crib ID": "Crib ID",
            "Type": "Type",
            "Area": "Area",
            "Aisle": "Aisle",
            "Row": "Row",
            "Shelf": "Shelf",
            "Bin": "Bin",
            "Action": "Action",
            "Default putaway location inserted successfully!": "Default putaway location inserted successfully!",
            "Default putaway location updated successfully!": "Default putaway location updated successfully!",
            "Default putaway location not inserted": "Default putaway location not inserted",
            "Default putaway location not updated": "Default putaway location not updated",
            "Please select all the fields!": "Please select all the fields!",
            "Default Putaway Location does not exist for this Item. Do u want to add new location?": "Default Putaway Location does not exist for this Item.Do u want to add new location ?",
            "Something went wrong please try again": "Something went wrong please try again",
            "The Item ID is invalid": "The Item ID is invalid",
            "No record found": "No record found",
            "Please enter Item ID": "Please enter Item ID",
            "Add New Location": "Add New Location",
            "Default pickup": "Default pickup",
            "Alternate Requestor Approval": "Alternate Requestor Approval",
            "Alternate Budgetary Approval": "Alternate Budgetary Approval",
            "Alternate Budgetary Approval by": "Alternate Budgetary Approval by",
            "No alternate budgetary approver found": "No alternate budgetary approver found",
            "No alternate requestor approver found": "No alternate requestor approver found",
            "Please select line items to approve": "Please select line items to approve",
            "Please select line items to decline": "Please select line items to decline",
            "Required by date": "Required by date",
            "Delivery Point": "Delivery Point",
            "Your order has been approved successfully": "Your order has been approved successfully",
            "Your order has been declined successfully": "Your order has been declined successfully",
            "Selected items have been approved successfully": "Selected items have been approved successfully",
            "Selected items have been declined successfully": "Selected items have been declined successfully",
            "Please fill all the required fields": "Please fill all the required fields",
            "Please enter a valid work order number": "Please enter a valid work order number",
            "Please enter a valid email address": "Please enter a valid email address",
            "Please enter a valid phone number": "Please enter a valid phone number",
            "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!": "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!",
            "Error --- Supplied e-mail address is incorrect": "Error --- Supplied e-mail address is incorrect",
            "Start date": "Start date",
            "To date": "To date",
            "Criteria": "Criteria",
            "Contains any": "Contains any",
            "Contains all": "Contains all",
            "Order no": "Order no",
            "Order ln": "Order ln",
            "Ship date": "Ship date",
            "Product ID": "Product ID",
            "Reason CD": "Reason CD",
            "Qty returned": "Qty returned",
            "Qty remaining": "Qty remaining",
            "UOM": "UOM",
            "Unit price": "Unit price",
            "Charge code": "Charge code",
            "Work order no": "Work order no",
            "Machine no": "Machine no",
            "Return to bu": "Return to bu",
            "Return type": "Return type",
            "Serial out": "Serial out",
            "Serial in": "Serial in",
            "Ship ID": "Ship ID",
            "Enter criteria": "Enter criteria",
            "Business unit": "Business unit",
            "Enter quantity": "Enter quantity",
            "Return To Inventory": "Return To Inventory",
            "Return to Inventory is the page where purchased products are returned": "Return to Inventory is the page where purchased products are returned",
            "Please select the date range": "Please select the date range",
            "Please enter the criteria based on searchby": "Please enter the criteria based on searchby",
            "Please select the search by": "Please select the search by",
            "Please select the employee ID": "Please select the employee ID",
            "Please enter returned qty for at least one order to save": "Please enter returned qty for at least one order to save",
            "Qty returned cannot be greater than remaining qty": "Qty returned cannot be greater than remaining qty",
            "Qty cannot be greater than remaining qty": "Qty cannot be greater than remaining qty",
            "Technical issue please contact SDI help desk": "Technical issue please contact SDI help desk",
            "Role Master": "Role Master",
            "Role ID": "Role ID",
            "Landing page": "Landing page",
            "Add role": "Add role",
            "Copy role": "Copy role",
            "Add New Role": "Add New Role",
            "Apply to all sister sites": "Apply to all sister sites",
            "Programs": "Programs",
            "save": "save",
            "cancel": "cancel",
            "New role has been created successfully!": "New role has been created successfully!",
            "ok": "ok",
            "Role details updated successfully!": "Role details updated successfully!",
            "Confirm": "Confirm",
            "no": "no",
            "yes": "yes",
            "Are you sure you want to delete this role?": "Are you sure you want to delete this role?",
            "This role cannot be deleted as it is applied to users.": "This role cannot be deleted as it is applied to users.",
            "Expand all": "Expand all",
            "Collapse all": "Collapse all",
            "Select all": "Select all",
            "Deselect all": "Deselect all",
            "Role name already exist!": "Role name already exist!",
            "Roles list": "Roles list",
            "Role name": "Role name",
            "User type": "User type",
            "Select atleast one program": "Select atleast one program",
            "Copy Role": "Copy Role",
            "Enter role name": "Enter role name",
            "Role has been deleted successfully!": "Role has been deleted successfully!",
            "Roles list": "Roles list",
            "Advance Shipping Notice": "Advance Shipping Notice",
            "Ready for Picking": "Ready for Picking",
            "Product Replenishment": "Product Replenishment",
            "Item ID": "Item ID",
            "Enter item ID": "Enter item ID",
            "Please enter the item ID": "Please enter the item ID",
            "Please enter valid item ID": "Please enter valid item ID",
            "Cart Items": "Cart Items",
            "Rush order": "Rush order",
            "Cancel order": "Cancel order",
            "Add to cart": "Add to cart",
            "Please enter item quantity": "Please enter item quantity",
            "Business unit": "Business unit",
            "Place order": "Place order",
            "Select business unit": "Select business unit",
            "Item quantity": "Item quantity",
            "Order placed successfully!": "Order placed successfully!",
            "Item ID or QR": "Item ID or QR",
            "Enter item ID or scan QR": "Enter item ID or scan QR",
            "Include zero quantity locations": "Include zero quantity locations",
            "Manual Stock Adjustment": "Manual Stock Adjustment",
            "Please enter the item ID": "Please enter the item ID",
            "Please enter the valid item ID": "Please enter the valid item ID",
            "Item is not available in crib": "Item is not available in crib",
            "Item details": "Item details",
            "Description": "Description",
            "BU": "BU",
            "Qty available": "Qty available",
            "Serial ID": "Serial ID",
            "Edit adjustment": "Edit adjustment",
            "Adjustment type": "Adjustment type",
            "Adjust quantity": "Adjust quantity",
            "Stock adjustment updated successfully!": "Stock adjustment updated successfully!",
            "Stock adjustment updation failed!": "Stock adjustment updation failed!",
            "Please select adjustment type": "Please select adjustment type",
            "Please remove special characters": "Please remove special characters",
            "Please select reason": "Please select reason",
            "Adjustment qty should not be greater than available qty": "Adjustment qty should not be greater than available qty",
            "Unit cost": "Unit cost",
            "OK": "OK",
            "Please allow your browser to access your webcam": "Please allow your browser to access your webcam",
            "QR scan": "QR scan",
            "Item with zero quantity is only available in the crib": "Item with zero quantity is only available in the crib",
            "Express Putaway": "Express Putaway",
            "Enter cost": "Enter cost",
            "Work order#": "Work order#",
            "Enter work order#": "Enter work order#",
            "Location details": "Location details",
            "Item ordered successfully!": "Item ordered successfully!",
            "Item ordered and default location updated successfully!": "Item ordered and default location updated successfully!",
            "Please enter cost": "Please enter cost",
            "Please select area": "Please select area",
            "Please select aisle": "Please select aisle",
            "Please select row": "Please select row",
            "Please select shelf": "Please select shelf",
            "Please select bin": "Please select bin",
            "Please enter valid work order#": "Please enter valid work order#",
            "Make as default location": "Make as default location",
            "Please select business unit": "Please select business unit",
            "Material Storage Locations": "Material Storage Locations",
            "Area": "Area",
            "Please select area": "Please select area",
            "Storage details": "Storage details",
            "Location type": "Location type",
            "Enter value": "Enter value",
            "Please enter aisle": "Please enter aisle",
            "Please enter row": "Please enter row",
            "Please enter shelf": "Please enter shelf",
            "Please enter bin": "Please enter bin",
            "New Location": "New Location",
            "New location added successfully!": "New location added successfully!",
            "Location updated Successfully!": "Location Updated Successfully!",
            "New location cannot be added!": "New location cannot be added!",
            "Location already exists!": "Location already exists!",
            "Location cannot be updated!": "Location cannot be updated!",
            "The category and subcategory cannot be identical": "The category and subcategory cannot be identical",
            "The category alias and subcategory alias cannot be identical": "The category alias and subcategory alias cannot be identical",
            "Order history": "Order history",
            //Zeus 243 Program master (English transaltion)[Vishalini]]
            "Program Master": "Program Master",
            "Identifier": "Identifier",
            "Program name": "Program name",
            "Sub category": "Sub category",
            "Access group": "Access group",
            "New": "New",
            "Program": "Program",
            "Program name": "Program name",
            "Enter program name": "Enter program name",
            "Program name alias": "Program name alias",
            "Enter program name alias": "Enter program name alias",
            "Enter category": "Enter category",
            "Category alias": "Category alias",
            "Enter category alias": "Enter category alias",
            "Subcategory": "Subcategory",
            "Enter subcategory": "Enter subcategory",
            "Subcategory alias": "Subcategory alias",
            "Enter subcategory alias": "Enter subcategory alias",
            "Navigation URL": "Navigation URL",
            "Enter navigation URL": "Enter navigation URL",
            "Apply to site": "Apply to site",
            "Active": "Active",
            "Zeus 2.0": "Zeus 2.0",
            "Walmart": "Walmart",
            "Zeus ordering": "Zeus ordering",
            "IPM": "IPM",
            "Access group": "Access group",
            "SDI": "SDI",
            "Explicit security": "Explicit security",
            "Query string parameters": "Query string parameters",
            "Enter query string": "Enter query string",
            "Enter # if program should not display in menu": "Enter # if program should not display in menu",
            "New program created successfully!": "New program created successfully!",
            "Please enter program name": "Please enter program name",
            "Please enter program name alias": "Please enter program name alias",
            "Please enter category": "Please enter category",
            "Please enter category alias": "Please enter category alias",
            "Please enter subcategory": "Please enter subcategory",
            "Please enter subcategory alias": "Please enter subcategory alias",
            "Program updated successfully": "Program updated successfully",
            "Do you want to remove this program?": "Do you want to remove this program?",
            "New program is not inserted successfully": "New program is not inserted successfully",
            "Program is not updated successfully!": "Program is not updated successfully!",
            "Program is not deleted successfully!": "Program is not deleted successfully!",
            "Program name already exists!": "Program name already exists!",
            "Number of records": "Number of records",
            "Enter query string parameters": "Enter query string parameters",
            "Please enter a unique program name alias": "Please enter a unique program name alias",
            "Please enter a unique category name alias": "Please enter a unique category name alias",
            "Category name alias already exist": "Category name alias already exists",
            "Please enter a unique subcategory name alias": "Please enter a unique subcategory name alias",
            "Subcategory name alias already exist": "Subcategory name alias already exists",
            "Category name already exists in subcategory": "Category name already exists in subcategory",
            "Subcategory already exists": "Subcategory already exists",
            "Alias name already exist": "Alias name already exist",
            "The category and subcategory cannot be identical": "The category and subcategory cannot be identical",
            "The category alias and subcategory alias cannot be identical": "The category alias and subcategory alias cannot be identical",
            //Translation for stock Issue Cart-Niranjana,6/12/24
            "Enter work order": "Enter work order",
            "Enter asset ID": "Enter asset ID",
            "* Required field - work order# or charge code is required": "* Required field - work order# or charge code is required",
            "+ New item": "+ New item",
            "Cart items": "Cart items",
            "MFG": "MFG",
            "MFG part number": "MFG part number",
            "Your cart is empty !": "Your cart is empty !",
            "BackOrder": "BackOrder",
            "Qty": "Qty",
            "Add new item to cart": "Add new item to cart",
            "Enter item ID": "Enter item ID",
            "type": "type",
            "Please enter item ID": "Please enter item ID",
            "Enabling this option indicates it as a backorder": "Enabling this option indicates it as a backorder",
            "Available qty": "Available qty",
            "MFG part#": "MFG part#",
            "No data found": "No data found",
            "Stock Issues Cart": "Stock Issues Cart",
            "Customer notes": "Customer notes",
            "Order charge code": "Order charge code",
            "ID": "ID",
            "The item is already in the cart": "The item is already in the cart",
            "You have successfully placed the order!": "You have successfully placed the order!",
            "  Remove": "  Remove",
            "Please enter qty for the item": "Please enter qty for the item",
            "Enabling this option indicates it as a backorder.": "Enabling this option indicates it as a backorder",
            "Please enter work order number or charge code": "Please enter work order number or charge code",
            "MFG part number": "MFG part number",
            //Zeus -82 Picking assign to,6/20/24
            "Picking Assign To": "Picking Assign To",
            "Export to excel": "Export to excel",
            "Total items": "Total items",
            "Work order": "Work order",
            "Order date": "Order date",
            "Assign to": "Assign to",
            "Reserved qty": "Reserved qty",
            "Customer notes": "Customer notes",
            "Qty requested": "Qty requested",
            "Supplier details": "Supplier details",
            "Address": "Address",
            "Distance": "Distance",
            "Qty available": "Qty available",
            //Zeus-360
            "Store hours": "Store hours",
            "Driving direction": "Driving direction",
            "Add for delivery": "Add for delivery",
            "Real Time Inventory": "Real Time Inventory",
            "Agility": "Agility",
            "One SDI": "One SDI",
            "Operational Excellence": "Operational Excellence",
            //Zeus 349
            "Required By Date": "Required By Date"
        }
    },
    /*[11/24/21,CAS-31,Priyanka D]Modified the french words to canadian french*/
    /*[03/18/22,CAS-31, Dhevanesam R] Modified the French words to Canadian French using Microsoft Bing*/
    fr: {
        translation: {
            "Welcome": "Bienvenue",
            "Number": "Nombre",
            "No record": "Aucune trace",
            "Non-priority": "Non prioritaire",
            "Business": "Entreprise",
            "Unit": "Unité",
            "Delivery": "Livraison",
            "Need by Date": "Besoin par date",
            "Name": "Nom",
            "Price": "Prix",
            "Decline": "Déclin",
            "Total": "Total des dépenses",
            "Created": "Créé",
            "Waiting": "En attente",
            "Pending Approver": "Approbateur en attente",
            "Division": "Division de l’État",
            "Region": "Région",
            "Sub Region": "Sous-région",
            "Export to Excel": "Exporter vers Excel",
            "Buy Checked Items": "Acheter des articles vérifiés",
            "Status": "État d’avancement",
            "Install": "Installer",
            "Filter": "Filtre",
            "Apply": "Présenter une demande",
            "Preference": "Préférence",
            "Select Report": "Sélectionnez Rapport",
            "Zip Code": "Code postal",
            "Model": "Modèle",
            "Store": "Magasin",
            "Please enter": "S’il vous plaît entrer",
            "Enter valid WorkOrder number": "Entrez un numéro de commande de travail valide",
            "Make": "Faire",
            "Pre_filter": "Pré-filtre",
            "Item has been added to cart": "Article ajouté au panier",
            "SearchQuickItem_key": "Élément rapide de recherche",
            "Required_key": "Obligatoire",
            "Done": "Terminé",
            "more_key": "En savoir plus",
            "reset_key": "Réinitialisation",
            "Attachment": "Pièce jointe",
            "part number": "Numéro de pièce",
            "unitofmeasure_key": "Unité de mesure",
            "recommended": "Recommandé",
            "Vendor": "Fournisseur",
            "orderedfor_key": "Commandé pour",
            "orderenteredby_key": "Ordre entré par",
            "Priority": "Priorité",
            "workorder_key": "Bon de travail",
            "order_key": "Ordre",
            "Quick Item Entry": "Entrée d’élément rapide",
            "Items have to be from the same supplier for pickup to be enabled": "Les articles doivent être du même fournisseur pour que le ramassage soit activé",
            "Pickup": "Ramassage",
            "Customer Notes": "Notes à l’intention des clients",
            "Edit": "Modifier",
            "Favorite": "Favori",
            "Bill-to-Store": "Facture-à-magasin",
            "Ship To": "Expédier à",
            "Delivery Date": "Date de livraison",
            "Asset Tag ID": "ID de balise de ressource",
            "Employee": "Employé",
            "Details": "Détails",
            "Item": "Objet",
            "Shopping Cart": "Panier d’achat",
            "Download": "Télécharger",
            "Archive": "Archives",
            "Date": "Date",
            "Title": "Titre",
            "Notification": "Notification",
            "Number of Records": "Nombre d’enregistrements",
            "Quantity": "Quantité",
            "Action": "Mesures à prendre",
            "Your": "Votre",
            "Description": "Désignation des marchandises",
            "Category": "Catégorie",
            "ID": "Pièce d’identité",
            "Continue Shopping": "Continuer à magasiner",
            "Return to": "Retourner à",
            "New": "Nouveau",
            "In the absence of written agreement to the contrary between customer and SDI, all pricing contained in this catalog is subject to change by SDI at any time without notice.": "En l’absence d’accord écrit à l’effet contraire entre le client et SDI, tous les prix contenus dans ce catalogue sont susceptibles d’être modifiés par SDI à tout moment et sans préavis.",
            "Profile": "Profil",
            "Home": "Accueil",
            "Update": "Mise à jour",
            "Enable": "Activer",
            "Crib Identifier": "Identificateur de la salle de stockage",
            "Block Price Display": "Affichage des prix de bloc",
            "Client": "Le client",
            "Catalog": "Catalogue",
            "Cancel": "Annuler",
            "Submit": "Soumettre",
            "Approval": "Approbation",
            "Customer": "Client",
            "Partial Received": "Partiellement reçue",
            "Approve": "Approuver",
            "Information": "L’information",
            "Fully Received": "Entièrement reçu",
            "Quote": "Citation",
            "Cancelled": "Annulé",
            "Rejected": "Rejeté",
            "Delivered": "Livré",
            "Complete": "Terminé",
            "Quote Approved": "Devis approuvé",
            "set": "Ensemble",
            "Delete": "Supprimer",
            "Order Total Requiring Approval": "Total des commandes nécessitant une approbation",
            "budg_approval_by": "L’approbation budgétaire par",
            "Address": "Adresse",
            "f_name": "Prénom",
            "type": "Type",
            "Phone": "Téléphone",
            "l_name": "Nom de famille",
            "Deselect": "Désélectionner",
            "user_group": "Groupe d’utilisateurs",
            "Expand": "Développer",
            "Collapse": "Effondrement",
            "Role": "Rôle",
            "dept": "Département",
            "User": "Utilisateur",
            "change_pwd": "Changer le mot de passe",
            "Add": "Ajouter",
            "Zeus": "Zeus",
            "Mobility": "Mobilité",
            "Preferences": "Préférences",
            "emails": "Courriels",
            "Product": "Produit",
            "My": "Mon",
            "Logout": "Déconnexion",
            "Term of Purchase": "Modalité d’achat",
            "Answer Lab": "Laboratoire de réponses",
            "f_p": "Page préférée",
            "Account": "Compte",
            "Selection": "Sélection",
            "select": "Sélectionner",
            "Clear": "Effacer",
            "test_site": "Site d’essai",
            "inv_supplier": "Fournisseur d’inventaire",
            "will_call_supplier": "Appellera le fournisseur",
            "Parts List": "Liste des pièces",
            "Supplier": "Fournisseur",
            "Manufacturer": "Fabricant",
            "Mode": "Mode",
            "Help": "Aide",
            "Tech Hub": "Centre Technique",
            "order_status": "État De La Commande",
            "Non-Catalog Request": "Demande non cataloguée",
            "punch_out": "Punchout",
            "Maintenance": "Entretien",
            "Privileges": "Privilèges",
            "Entry": "Entrée",
            "Reason": "Raison",
            "Choose File": "Choisissez Fichier",
            "Archived": "Archivé",
            "Page": "La page",
            "Total Items": "Nombre total d’éléments",
            "Location": "Situation géographique",
            "Summary": "Résumé",
            "Confirm": "Confirmer",
            "Over Pick": "Au-dessus du choix",
            "Under Pick": "Sous Choisir",
            "Zero Pick": "Zéro choix",
            "Yes": "Oui",
            "No": "Non",
            "Order": "Ordre",
            "Picking Items": "Sélection d’objets",
            "Info": "L’info",
            "Select a site": "Sélectionner un site",
            "Item ID": "ID d’élément",
            "Action Code": "Code d’action",
            "Utilization Code": "Code d’utilisation",
            "Forecast Family": "Famille de prévisions",
            "Zero On Hand": "Zéro sous la main",
            "Replenish Type": "Type de réapprovisionnement",
            "Replenish Class": "Classe de réapprovisionnement",
            "In Business Unit": "Dans l’unité commerciale",
            "Replenish Status": "État du réapprovisionnement",
            "Not On Order": "Pas sur commande",
            "Cost": "Coût",
            "Extended Cost": "Coût prolongé",
            "Process Replen": "Processus Replen",
            "Average On Hand": "Moyenne à portée de main",
            "Growth": "Croissance",
            "Order Forecast Model": "Modèle de prévision des commandes",
            "12 Month Forecast": "Prévisions sur 12 mois",
            "Month's Usage": "Utilisation du mois",
            "Next Order Date": "Prochaine date de commande",
            "Next Order Qty": "Prochain ordre Qty",
            "Data Posponed": "Données posponed",
            "Review": "Examen",
            "Next Review Date": "Prochaine date d’examen",
            "Days On Hand": "Jours à portée de main",
            "Excess Units": "Unités excédentaires",
            "Excess Dollars": "En dollars excédentaires",
            "Receiveing Cost": "Coût de réception",
            "Service Level": "Niveau de service",
            "Max Weeks": "Nombre maximal de semaines",
            "Carrying Cost": "Frais de portage",
            "Item Stock Type": "Type de stock d’articles",
            "Safety stock": "Stock de sécurité",
            "Package Size": "Taille de l’emballage",
            "Max Capacity": "Capacité maximale",
            "Lead-Time": "Délai d’ad été donné",
            "Optimal Order QTY": "Commande optimale QTY",
            "Current On Hand": "En cours à portée de main",
            "Open PO Qty": "Ouvrez PO Qty",
            "Reserved Qty": "Réservé Qty",
            "Min": "Min",
            "Open": "Ouvrir",
            "Suggested Min": "Min suggéré",
            "Backorder": "Commande en rupture de stock",
            "Max": "Max",
            "Suggested Max": "Max suggéré",
            "Default Putaway": "Putaway par défaut",
            "Date Added": "Date d’ajout",
            "Critical Spare": "Pièce de rechange critique",
            "Supplier Part Number": "Numéro de pièce du fournisseur",
            "Last Issues Date": "Date des derniers numéros",
            "Client Stock Type": "Client Stock Type",
            "Inventory Days On Hand": "Jours d’inventaire à portée de main",
            "Item Has Been Updated Successfully": "L’élément a été mis à jour avec succès",
            "Ok": "Ok",
            "Mismatch": "Non-concordance",
            "Cycle Count": "Nombre de cycles",
            "Standard Leadtime": "Délai d’exécution standard",
            "Machine Number": "Numéro de la machine",
            "Drawing": "Dessin",
            "Machine Control Num": "Num de contrôle de la machine",
            "Purchasing Dept Spec": "Spécification du service d’achat",
            "Regrind": "Regrind",
            "First": "Premièrement",
            "Issue": "Question en litige",
            "Charge": "Accusation",
            "Code": "Le code",
            "Replenish": "Reconstituer",
            "Reorder Paint": "Réorganiser la peinture",
            "Modifiers": "Modificateurs",
            "Attributes": "Attributs",
            "Path": "Chemin d’accès",
            "Noun": "Nom",
            "Store Room": "Salle de magasin",
            "No attributes avaliable": "Aucun attribut avaliable",
            "Total Order Qty": "Total des commandes Qty",
            "SAP Article Number": "Numéro d’article",
            "No. of Pieces": "Non. de pièces",
            "Material": "Matériel",
            "Owned By": "Propriété De",
            "Package": "Forfait",
            "Features": "Caractéristiques",
            "Cycle Count Reconciliation": "Rapprochement du nombre de cycles",
            "Variance": "Écart",
            "Reconciliation Tag": "Étiquette de réconciliation",
            "Item Ready For Update": "Élément prêt pour la mise à jour",
            "Finalization": "Finalisation",
            "Load Recon": "Recon de charge",
            "Counting Event": "Événement de comptage",
            "Entered Item Count": "Nombre d’éléments saisis",
            "Bin": "Bac",
            "Equipment Tag": "Étiquette d’équipement",
            "Inspection Required": "Inspection requise",
            "Hazard": "Danger",
            "Tracking": "Suivi",
            "Ship Via": "Expédier via",
            "In": "Dans",
            "Ship Date": "Date d’expédition",
            "Years": "Années",
            "Months": "Mois",
            "Select Duration": "Sélectionnez Durée",
            "Receive Items": "Reception des articles",
            "Inventory Management System": "Système de gestion des stocks",
            "Search containing value limit to date range entered above": "Recherche contenant la limite de valeur à la plage de dates entrée ci-dessus",
            "Search containing value but limit to last 6 months": "Recherche contenant une valeur mais limite aux 6 derniers mois",
            "Search exact value": "Rechercher la valeur exacte",
            "Orders": "Commandes",
            "Search by": "Recherche par",
            "Date range is limited to 3 months": "La plage de dates est limitée à 3 mois",
            "To": "Pour",
            "From": "À partir de",
            "Report": "Rapport",
            "Select Report (by Order date)": "Sélectionnez Rapport (par date de commande)",
            "Items": "Objets",
            "Lookup": "Recherche",
            "Check": "Vérifier",
            "WAREHOUSE": "ENTREPÔT",
            "Categories": "Catégories",
            "No Search Result found": "Aucun résultat de recherche trouvé",
            "Did you mean": "Vouliez-vous dire",
            "You searched for": "Vous avez recherché",
            "Search Result": "Résultat de la recherche",
            "Received": "Reçu",
            "Search": "Recherche",
            "supplier_callin": "Callin de fournisseur",
            "Email Address": "Adresse e-mail",
            "Password": "Mot de passe",
            "Confirm Password": "Confirmer le mot de passe",
            "Privilege": "Privilège ",
            "A la Carte": "A la Carte",
            "Program Tree View": "Vue de l’arborescence du programme",
            "Program": "Programme",
            "Freight Provider :": "Fournisseur de fret :",
            "History": "Histoire",
            "Buy again :": "Achetez à nouveau :",
            "Expected": "Prévu",
            "Last Updated On": "Dernière mise à jour le",
            "Pending": "En attente",
            "Counted": "Compté",
            "Indicates Inventory Order": "Indique l’ordre d’inventaire",
            "Stock Value Report": "Rapport sur la valeur des actions",
            "Select": "Sélectionner",
            "GO": "ALLEZ-Y",
            "Show Bin Loc": "Afficher Bin Loc",
            "Include Quantity On Hand": "Inclure La Quantité En Main",
            "ItemKey": "Objet",
            "Planner CD": "CD planificateur",
            "On Hand": "À portée de main",
            "Currency": "Devise",
            "Run": "Exécuter",
            "Count": "Compte",
            "Avail": "Disponibilité",
            "Crib": "Lit d’enfant",
            "Aisle": "Allée",
            "Row": "Ligne",
            "Shelf": "Étagère",
            "Reserv": "Réserver",
            "Actual": "Réel",
            "See not updated items": "Voir les éléments non mis à jour",
            "Change": "Changement",
            "Add": "Ajouter",
            "Track": "Piste",
            "Current": "Actuel",
            "Added On": "Ajouté Le",
            "Other": "Autres",
            "Approved": "Approuvé",
            "Waiting Approval Chain": "Chaîne d’approbation en attente",
            "No Records found": "Aucun document trouvé",
            "All": "Tous les",
            "Image": "L’image",
            "Waiting Order Approval": "Approbation de la commande en attente",
            "Advanced": "Avancé",
            "Stock": "Stock",
            "UnDelete": "Annuler la suppression",
            "Line": "Ligne",
            "Do you want to remove this item?": "Voulez-vous supprimer cet élément?",
            "Favorites": "Favoris",
            "Show": "Spectacle",
            "Start": "Début",
            "End": "Fin",
            "Search Criteria": "Critères de recherche",
            "Contains": "Contient",
            "Any": "N’importe quel",
            "Remaining": "Autres",
            "Returned": "Retourné",
            "Reason": "Raison",
            "You don't have Permission to": "Vous n’avez pas l’autorisation de le faire",
            "Access this page": "Accéder à cette page",
            "valid": "valide",
            "call us": "appelez-nous",
            "Contact sdi customer care": "Contactez le service clientèle sdi",
            "SDI MRO for a more": "SDI MRO pour un plus",
            "connected": "connecté",
            "enterprise": "L’entreprise",
            "Sorry, we couldn’t find any search result": "Désolé, nous n’avons trouvé aucun résultat de recherche",
            "Do you want to remove this order ?": "Voulez-vous supprimer cette commande ?",
            "Reorder": "Réorganiser",
            "Part": "Partie",
            "Reference": "Références",
            "Door - DH Pace": "Porte - DH Pace",
            "Lift Truck": "Chariot élévateur",
            "Liftparts": "Pièces d’ascenseur",
            "Terms and Conditions": "Termes et conditions",
            "Zeus emarketplace Registration Terms": "Zeus emarketplace Conditions d’enregistrement",
            "I Do not Agree": "Je ne suis pas d’accord",
            "I Agree": "Je suis d’accord",
            "Submit a Non Catalog Request": "Soumettre une demande non cataloguée",
            "Site Under Construction": "En construction",
            "Site": "Le site",
            "Will be Back Soon": "Sera bientôt de retour",
            "Back": "Retour",
            "Do you want to archive this notification?": "Souhaitez-vous archiver cette notification?",
            "Merchandise": "Marchandises",
            "Extended": "Étendue",
            "Usage": "Utilisation",
            "Issues": "Questions en litige",
            "Sites": "Les sites",
            "Purchase": "Achat",
            "Transactions": "Opérations",
            "Available": "Disponible",
            "This price is subject to confirmation by supplier": "Ce prix est sujet à confirmation par le fournisseur",
            "Add to Cart": "Ajouter au panier",
            "Maximum": "Maximum",
            "Item More Details": "Article Plus de détails",
            "Year": "Année",
            "Previous": "Précédent",
            "Transaction Date": "Transaction Date",
            "No Records to display": "Aucun enregistrement à afficher",
            "Owner": "Propriétaire",
            "Lot/Serial Number": "Numéro de lot/série",
            "There are no bin locations to display for this item": "Il n’y a pas d’emplacements de bac à afficher pour cet élément",
            "Related site data not available at this time.": "Les données du site connexes ne sont pas disponibles pour le moment.",
            "Net Received": "Net reçu",
            "Operator Entered By": "Opérateur Entré Par",
            "Receiver": "Récepteur",
            "Estimated": "Estimation",
            "Tax Amount": "Montant de taxe",
            "Payment": "Paiement",
            "Credit Card": "Carte de crédit",
            "Debit Card": "Carte de débit",
            "All Cards are accepted": "Toutes les cartes sont acceptées",
            "Card": "Carte",
            "Wallet": "Portefeuille",
            "Expiry": "Expiration",
            "Pay Now": "Payez maintenant",
            "Select file to attach": "Sélectionner un fichier à joindre",
            "Attach": "Joindre",
            "Would you like to use line numbers by increments of 10?": "Souhaitez-vous utiliser des numéros de ligne par incréments de 10?",
            "Select any one of your wallet account for your payment process": "Sélectionnez l’un de vos comptes de portefeuille pour votre processus de paiement",
            "Do you want to make this as your favorite order?": "Voulez-vous en faire votre commande préférée?",
            "Success": "Succès",
            "Payment has been successfully completed": "Le paiement a été effectué avec succès",
            "Are you sure you want to cancel your order?": "Êtes-vous sûr de vouloir annuler votre commande?",
            "All charge code segments must be selected": "Tous les segments de code de charge doivent être sélectionnés",
            "Notes": "Remarques",
            "Is This Priority?": "S’agit-il d’une priorité?",
            "Part": "Partie",
            "Browse": "Parcourir",
            "Tax Exempt": "Exonéré d’impôt",
            "Add Item to Catalog": "Ajouter un élément au catalogue",
            "Please agree the terms and conditions": "Veuillez accepter les termes et conditions",
            "Market Place": "Place du marché",
            "Attribute": "Attribut",
            "Warning! Default UOM list displayed - no cross reference data available for the current Business Unit": "Attention! Liste UOM par défaut affichée - aucune donnée de référence croisée disponible pour l’unité commerciale actuelle",
            "Save/More": "Enregistrer/Plus",
            "Save/Done": "Enregistrer/Terminé",
            "Quick": "Rapide",
            "Quantity On Hand": "Quantité en main",
            "Due Date": "Date d’échéance",
            "Approved By": "Approuvé par",
            "Ship": "Navire",
            "RFQ order and line number": "Commande d’appel d’offres et numéro de ligne",
            "Request by Date": "Demande par date",
            "Recieved": "Reçu",
            "Received": "Reçu",
            "Reject": "Rejeter",
            "Invoice": "Facture",
            "Packing Slips": "Bordereaux d’emballage",
            "Stop": "Arrêtez",
            "Load": "Charge",
            "Shipping Date": "Date d’expédition",
            "Trade": "Le commerce",
            "Ordered By": "Commandé par",
            "Ship Container": "Conteneur de navire",
            "Comments": "Commentaires",
            "Fragile Item Signature": "Signature d’élément fragile",
            "Truck": "Camion",
            "Driver": "Pilote",
            "By": "Par",
            "[proof-of-delivery]": "[preuve de livraison]",
            "Close": "Fermer",
            "Loaded Container": "Conteneur chargé",
            "Last": "Dernier",
            "Days": "Jours",
            "This": "Cette ",
            "Month": "Mois",
            "Transaction": "Transaction",
            "Company-Consolidated": "Société-Consolidée",
            "Indicates Alternate Requestor Approver": "Indique l’approbateur de demandeur suppléant",
            "Order Approval History": "Historique d’approbation des commandes",
            "Proof of delivery": "Preuve de livraison",
            "Header": "En-tête",
            "Collapse All": "Tout réduire",
            "Expand All": "Développer tout",
            "Shipment": "Expédition",
            "Multiple": "Multiple",
            "Main Menu": "Menu principal",
            "Favorite": "Favori",
            "Pages": "Les pages",
            "You don't have any record": "Vous n’avez aucun dossier",
            "Go to Cart": "Aller au panier",
            "Pricing": "Prix",
            "Click on vendor's logo below to start browsing their catalog": "Cliquez sur le logo du fournisseur ci-dessous pour commencer à parcourir leur catalogue",
            "ERROR": "ERREUR",
            "PunchOut is not currently available for this Vendor": "PunchOut n’est actuellement pas disponible pour ce fournisseur",
            "A message was sent notifying site administrator of this problem": "Un message a été envoyé pour informer l’administrateur du site de ce problème",
            "We apologize for this inconvenience": "Nous nous excusons pour ce désagrément",
            "Return": "Retour",
            "There are no available vendors": "Il n’y a pas de fournisseurs disponibles",
            "Partner Vendor": "Fournisseur partenaire",
            "Marketplace": "Marché",
            "SDIExchange - Request for Quote": "SDIExchange - Demande de devis",
            "Requestor": "Demandeur",
            "Received By": "Reçu par",
            "Thank you for choosing": "Merci d’avoir choisi",
            "Source": "La source",
            "We will notify you once your quote is ready": "Nous vous informerons une fois que votre devis sera prêt",
            "Print": "Imprimer",
            "Continue": "Continuer",
            "Order Submitted for Processing": "Commande soumise pour traitement",
            "Order Submitted for Quote": "Commande soumise pour soumission",
            "User": "Utilisateur",
            "Approvals": "Approbations",
            "Enable Multisite Access": "Activer l’accès multisite",
            "In-Active Employee Account": "Compte d’employé actif",
            "In-Active Account": "Compte actif",
            "Set Requestor Approval": "Définir l’approbation du demandeur",
            "Alternate Requestor Approval by": "Approbation d’un autre demandeur par",
            "Email": "Courriel",
            "Approval": "Approbation",
            "Display": "Affichage",
            "Add to SDI Track": "Ajouter à la piste SDI",
            "Add Other User to SDI Track": "Ajouter l’autre utilisateur à la piste SDI",
            "More": "En savoir plus",
            "Item Group": "Groupe d'articles",
            "Issue Charge Code": "Code d'emission des frais",
            "Save": "Enregistrer",
            "Archive All": "Sauvegarder ",
            "Charge Code": "Code de facturation",
            "Order Total": "Total des commandes",
            "More Details": "Plus de détails",
            "Customer Reference": "Référence client",
            "Work Order #": "Bon de travail #",
            "Item base price": "Prix de base de l’article",
            "Item qty": "Point qty",
            "Order Summary & Payment Details": "Résumé de la commande et détails du paiement",
            "Pickup From Local Supplier": "Ramassage auprès d’un fournisseur local",
            "Check Order Status": "Vérifier l’état de la commande",
            "UserProfile": "Profile de l'utilisateur ",
            "Favorite Item Maintenance": "Articles de maintenance preferes",
            "Order Approval": "Approbation de la commande",
            "Waiting Requestor Approval": "En attente de l’approbation du demandeur",
            "Requestor Approval": "Approbation du demandeur",
            "Order Status": "État de la commande",
            "Receiving": "Réception",
            "User Profile": "Profil d’utilisateur",
            "Approve Orders": "Approuver les commandes",
            "PunchOut": "PunchOut",
            "Order Status Report": "Rapport sur l’état de la commande",
            "Add New User": "Ajouter un nouvel utilisateur",
            "Edit Vendor Information": "Modifier les informations sur le fournisseur",
            "Edit User Information": "Modifier les informations sur l’utilisateur",
            "Edit Mexico Information": "Modifier les informations sur le compte mexiquain",
            "Activate/Inactivate Users": "Activer/inactiver les utilisateurs",
            "Add New Vendor": "Ajouter un nouveau fournisseur",
            "Add New Mexico": "Ajouter le Nouveau-Mexique",
            "Edit Favorites": "Modifier les favoris",
            "  TEST site! Nothing saved to Production!": "Site de test ! Rien n'a été enregistré sur le site de production!",
            "Ordering": "Commander",
            "Your User Profile": "Votre profil d’utilisateur",
            "User profile": "Profil d’utilisateur",
            "Inventory": "Inventaire",
            "SDI Category": "Catégorie SDI",
            "Your Category": "Votre catégorie",
            "Item Description": "Description de l’élément",
            "Your Description": "Votre description",
            "MFG Part Number": "Numéro de pièce du MFG",
            "Waiting For your Approval": "En attente de votre approbation",
            "This order/quote is still waiting approval from": "Cette commande / devis est toujours en attente d’approbation de ",
            "Order Number": "Numéro de commande",
            "Order Entered by": "Ordre entré par",
            "Business Unit": "Unité d’affaires",
            "Work Order Number": "Numéro de bon de travail",
            "Order Entered By": "Ordre entré par",
            "Enter ID": "Entrez votre ID",
            "Notification ID": "Notification ID",
            "User Notification": "Notification de l’utilisateur",
            "Punchout Catalog Search for ZEUS": "Recherche dans le catalogue Punch-out de ZEUS",
            "Enter Value": "Entrez la valeur",
            "Enter First Name": "Entrez le prénom",
            "Enter Last Name": "Entrez nom de famille",
            "Enter Phone number": "Entrez votre numéro de téléphone",
            "Enter Password": "Entrez votre mot de passe",
            "Enter Confirm Password": "Veuillez confirmer le mot de passe",
            "Enter User Id": "Entrez l’ID de l'utilisateur",
            "Select department": "Sélectionner un département",
            "-----Select Department-----": "-----Sélectionner un département-----",
            "Enter SDITrack User ID": "Entrer l'identifiant utilisateur SDTrack",
            "Corp Admin": "Administration corporative",
            "Super": "Super",
            "Admin": "Administrateur",
            "Disable Zeus": "Désactiver Zeus",
            "Enable Zeus": "Activer Zeus",
            "contact sdi customer care": "Contacter le service clientèle sdi",
            "All fields are mandatory": "Tous les champs sont obligatoires",
            "First Name": "Prénom",
            "Last Name": "Nom de famille",
            "New PunchIn User Registration": "Nouvelle inscription d’utilisateur pour le PunchIn",
            "This website utilizes cookies and similar technologies for functionality and other purposes. Your use of this website constitutes your acceptance of cookies.": "Ce site Web utilise des cookies et des technologies similaires à des fins de fonctionnalité. L'utilisation de ce site Web constitue votre acceptation des cookies.",
            "Ok, Got it": "merci",
            "Punchin session has been end": "La session Punchin a ete terminée",
            "Click on exit button to return": "Cliquez sur le bouton Quitter pour retourner à l'écran précédent",
            "Exit": "sortir",
            "Catalog Details": "Détails du catalogue",
            "Non Catalog Details": "Détails non catalogue",
            "ItemDetail": "Details des articles",
            "Catalog Item Details": "Details des articles du catalogue",
            "Non Catalog Item Details": "Details des articles n'appartenant pas au catalogue",
            "PunchOut with our merchant partners": "PunchOut avec nos partenaires marchands",
            "Lift Truck - Coats Garage": "Chariot élévateur - Garage Coats",
            "Lift Truck - Wiese": "Chariot élévateur - Wiese",
            "Lift Truck - HGM Liftparts": "Chariot élévateur - Pièces élévatrices HGM",
            "Reference Site": "Site de référence",
            "Last 7 Days": "7 derniers jours",
            "Last 15 Days": "15 derniers jours",
            "Last 30 Days": "30 derniers jours",
            "Last Month": "Le mois dernier",
            "This Month": "Ce mois-ci",
            "Group": "Groupe",
            "Order #": "Numéro de commande",
            "Work Order": "Bon de travail",
            "PO Id": "Identifiant du bon de travail",
            "Ordered For": "Commandé pour",
            "Enter Name": "Entrez votre nom",
            "Enter Description": "Entrez la description",
            "Enter Estimated Price": "Entrez le prix estimé",
            "Ln": "Ligne #",
            "Sch": "Sch",
            "PO": "Bon de commande",
            "qty": "Quantitee ",
            "Line Status": "État de la ligne",
            "PO": "Bon de commande",
            "Addr Seq": "Séquence d’adresses",
            "Session Timeout": "Delai de la session de travail",
            "Go to login": "Aller a la page d'acceuil",
            "Now exiting": "Sortir de la session ",
            "and returning to": "retour a la page precedente",
            "disabling session connection...": "Connection de la session desactivee...",
            "Please click here, if this page does not automatically refresh in 5 seconds": "Veuillez cliquer ici si la page ne s’actualise pas automatiquement dans 5 secondes",
            "MFG name": "Nom de Fabriquant",
            "Online Catalog Load": "Chargement du catalogue en ligne",
            "Loading": "Chargement",
            "Please Wait": "Merci d'attendre la mise a jour de la page ",
            "Enter Old Password": "Entrer l’ancien mot de passe",
            "Enter New Password": "Entrer un nouveau mot de passe",
            "Price on request": "Prix sur demande",
            "Non Catalog Request": "Demande d'un article non cataloguée",
            "Order Charge Code": "Code de charge de commande",
            "Enter charge code": "Entrez le code de charge",
            "Enter value": "Entrez la valeur",
            "Enter Machine Number": "Entrer le numéro de l'équipement",
            "Enter your Card Number": "Entrer votre numéro de carte",
            "Enter Part No": "Entrez la partie No",
            "Enter Location": "Entrer l’emplacement",
            "Enter CVV/CVC": "Entrer CVV/CVC ",
            "Submit Order": "Soumettre la commande",
            "Check Out": "Payer la commande",
            "*Employee ID": "*Identifiant de l’employé",
            ": (by name)": ": (par nom)",
            "Requisitioner Name": "Nom du demandeur",
            "Log in to your account": "Connectez-vous à votre compte",
            "Login": "Connexion",
            "Forgot Password ?": "Mot de passe oublié ?",
            "Speed to Execution": "Rapidité d’exécution",
            "Simplify": "Simplifier",
            "Commitment": "L’engagement",
            "Perseverance": "Persévérance",
            "Courage": "Le courage",
            "Solution-Oriented": "Axé sur les solutions",
            "inc. All Rights Reserved": "Tous droits réservés",
            "Zeus ID": "Zeus ID",
            "Request Forgotten Password": "Demander un mot de passe oublié",
            "Enter Login Id": "Entrer votre identifiant",
            "Enter E-Mail address": "Entrer l’adresse Courriel",
            "Reset Password": "Réinitialiser votre mot de passe",
            "Back To Login": "Retour a la page d'acceuil",
            "Password will be reset and sent to the email address that was set up in the Login profile for the Login ID entered above.": "Message de réinitialisation du mot de passe:  Le mot de passe sera réinitialisé et envoyé à l'adresse courriel qui figure dans le profil de connexion de l'identifiant saisi ci-dessus.",
            "*Work Order #": "*Bon de travail #",
            "Invalid item id": "Identification de l'article non valide",
            "Invalid or the itemid already exists": "Identifiant de l’article non valide",
            "Technical Issue Try again": "Problème technique",
            "Deleted Successfully": "Article efface correctement",
            "Updated Successfully": "Articles ajoute correctement",
            "Please enter Item Id": "Entrer l'identifiant de l'article",
            "You are not allowed to use 'Shopping Cart' here. \nYou must login from SAP.": "Erreur - Se connecter depuis SAP pour accéder au panier",
            "Links are for reference information only.\nDo not place orders from suppliers site.": "Lien à titre indicatif seulement",
            "True": "Correct",
            "Please agree terms and conditions to access shopping cart": "Accepter les termes et conditions pour accéder au panier",
            "Insufficient privileges for adding to cart": "Privilège insuffisant pour le panier",
            "You are not allowed to use this screen here \n you must login from SAP": "Se connecter depuis SAP pour accéder à cet écran",
            "Item successfully added to the favorite list": "Élément préféré ajouté",
            "Due to technical Issue item is not added to the favorite list.Try after sometime.": "Un problème technique empêche de créer un Favori",
            "Technical issue.Try after sometime.": "Essayer plus tard",
            "Cart is empty": "Le panier est vide",
            "Please fill the delivery date": "Remplir toutes les dates de livraison au niveau de l'article",
            "Please fill all item level employee id": "Remplir tous les identifiants d'employé au niveau de l'article",
            "Please fill all Mandatory Fields": "Remplir tous les champs obligatoires",
            "Please enter work order number or charge code": "Entrer le numéro de commande ou le code de facturation",
            "Please fill all item level charge code": "Veuillez remplir tout le code de charge au niveau de l’article",
            "Please fill Work Order": "Veuillez remplir le bon de travail",
            "Error while updating WALLET": "Erreur de mise à jour du portefeuille utilisateur",
            "Technical Issue -- Contact SDI": "Problème technique Contacter SDI",
            "Card number is required": "Le numéro de carte est obligatoire",
            "Invalid card number": "Le numero de carte est incorrect",
            "Expiration month & Year is required": "Le mois d’expiration est obligatoire",
            "CVV/CVC Number is required": "CVV/CVCer Reqd",
            "Invalid CVV/CVC number": "CVV/CVC noner CVC",
            "Invalid expiry date": "Le date d'expiration est incorrecte",
            "Error In Payment Process": "Erreur de paiement",
            "--Select Charge Code--": "--Sélectionnez code de charge--",
            "Card already exist in user wallet": "La carte existe déjà dans le portefeuille de l'utilisateur",
            "You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names.": "Longueur du nom de fichier dépassée",
            "No file is selected": "Aucun fichier n’est sélectionné",
            "No Item Number entered for Quick Item Entry": "Aucun article disponible",
            "Invalid Item Number entered for Quick Item Entry": "Article non valide",
            "Techincal Issue Contact SDI Customer Care": "Problème technique Contactez le support clientele de SDI",
            "Enter valid Work Order Number": "Entrez un Bon de Commande valide",
            "Order Number already exist": "Le numéro de commande existe obligatoire",
            "No enterprise record found": "Aucune information sur l'entreprise n'est trouvée",
            "Order total exceeds budgetary limit, Kindly reach administration team": "Le total des commandes dépasse la limite budgétaire, Veuillez contacter l’équipe d’administration",
            "All serialized items must be assigned a serial ID.": "Tous les éléments sérialisés doivent se voir attribuer un ID de série.",
            "Error in approval update - please contact Help Desk.": "Erreur dans la mise à jour de l’approbation - veuillez contacter le service d’assistance.",
            "ReqID DB error - Contact Support": "Erreur ReqID DB - Support de obligatoireo",
            "Description required for the NSTK item.": "Description requise pour l’article obligatoire",
            "Selected file is already attached, please try again": "Le fichier sélectionné est déjà joint, veuillez réessayer",
            "Your Order has been Approved/Declined Successfully": "Votre commande a été approuvée / refusée avec succès",
            "Problem in approval/decline process, Your Order has not been Approved/Declined": "Problème dans le processus d’approbation / refus, votre commande n’a pas été approuvée / refusée",
            "No Orders are selected to approve": "Aucune commande n’est sélectionnée pour être approuvée",
            "No Orders are selected to decline": "Aucune commande n’est sélectionnée pour être refusée",
            "Remove": "Supprimer",
            "Enter Confirm Password": "Veuillez confirmer le mot de passe",
            "Item does not exist in the catalog": "Numero d'article n'existe pas",
            "Please enter item ID": "Entrer l'identifiant de l'article",
            "Req MFG": "Le nom et le numéro de pièce du fabricant sont requis",
            "Enter a valid Quantity": "Entrer une quantité valide",
            "is not added to cart due to technical issue. Contact SDI customer care": "Raison du technicien",
            "Tec_Issue": "Problème technique - Contacter le service à la clientèle de SDI",
            "Favorite item inserted sucessfully": "Favori inséré avec succès",
            "Item has been approved successfully": "Succès de l’application Item",
            "Item has been declined successfully": "Succès de l’item Dec",
            "Selected items has been approved successfully": "Succès de Selc Items Appr",
            "Selected items has been declined successfully": "Succès de Selc Items Dec",
            "Please select line items to Approve/Decline": "Sélectionner les postes (lignes) à approuver/refuser",
            "Please enter first name": "Entrer le prénom",
            "Please enter last name": "Entrer le nom de famille",
            "Please enter email address": "Entrer l’adresse courriel",
            "Passwordvalidation": "Entrer le mot de passe",
            "ValidateConfrmPass": "Confirmer le mot de passe ",
            "ValidPassword": "Mot de passe valide",
            "Valid_Password": "Mot de passe valide",
            "PasswordMatch": "Correspondance de mot de passe",
            "Please select shipto": "Sélectionner : expédier à",
            "Please select the ship to": "S’il vous plaît sélectionner le navire à",
            "Enter category": "Entrez dans la sous-catégorie",
            "Enter sub category": "Enter sub category",
            "Please enter the address": "Veuillez saisir l’adresse",
            "You have exceeded the length of total file names": "Longueur permise du nom de fichier dépassée",
            "Please adjust your entry or remove longer file names": "Ajuster l'info saisie afin de racourcir les nom de fichier",
            "New Password updated successfully": "Nouveau mot de passe mis à jour avec succès",
            "Old Password is Incorrect": "L’ancien mot de passe est incorrect",
            "Old Password is required": "L’ancien mot de passe est requis",
            "New Password is required": "Un nouveau mot de passe est requis",
            "Confirm Password is required": "Confirmer que le mot de passe",
            "Your new password has been sent to your email address.": "Votre nouveau mot de passe a été envoyé à votre adresse courriel.",
            "Please enter required fields": "Entrer les champs obligatoires",
            "Please enter user id": "Entrer l'identifiant utilisateur",
            "Are you sure you want to reset your password?": "Réinitialiser votre mot de passe",
            "Duplicate UserID''s.  Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Pour ces 7 messages il faudrait voir si l'option 7 est effectivement valide car c'est la même qu'en Anglais et je dout que ce soit une option pour les Francophones.",
            "Email does not match email for this user, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Erreur : Le courriel ne correspond pas au courriel pour cet utilisateur, le mot de passe n’est pas ete réinitialisé.  Veuillez contacter SDI par courriel ou appelez au 215-633-1900 option 7 pour parler aux services Technique de l'Information.",
            "Not a valid email for your userid, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Erreur : Courriel invalide pour votre ID. Veuillez contacter SDI par courriel ou appelez au 215-633-1900 option 7 pour parler aux services Technique de l'Information.",
            "Reset failed! Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Erreur: La réinitialisation a échoué! Veuillez contacter SDI par courriel ou appelez au 215-633-1900 option 7 pour parler aux services Technique de l'Information.",
            "UserID does not exist, Password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "Erreur : L’ID Utilisateur n’existe pas. Veuillez contacter SDI par courriel ou appelez au 215-633-1900 option 7 pour parler aux services Technique de l'Information.",
            "Client is inactive": "Le client est inactif",
            "Your account has been disabled due to excessive invalid login attempts.Please contact the Help Desk at 215-633-1900, option 7 to reinstate your account.": "Votre compte a été désactivé en raison de tentatives de connexion excessives non valides. Veuillez communiquer avec le service d’assistance au 215-633-1900, option 7 pour rétablir votre compte.",
            " **Error - your password has expired. Click on the \"Forgot your Password\" link to be assigned a new password": "**Erreur - votre mot de passe a expiré. Cliquer sur le lien Mot de passe oublié afin d'obtenir un nouveau mot de passe.",
            "Invalid ID/password": "ID/mot de passe non obligatoire",
            "Provided username and password is incorrect": "L'identifiant de l’utilisateur et le mot de passe fournis sont incorrects",
            "View only mode flag set - order not saved": "Afficher uniquement l’indicateur de mode défini - ordre non enregistré",
            "Enter Quantity for at least one item": "Entrer la quantité minimale - au moins un article",
            "notification Archived": "Notification sauvegardee",
            "please try again": "Merci d'essayer à nouveau",
            "Please select any Notification": "Veuillez sélectionner n’importe quelle notification",
            "BlckShopCart": "Se connecter depuis SAP pour accéder à cet écran",
            "Please enter quantity": "Entrez une quantitee",
            "PO is invalid": "Bon de commande invalide",
            "Problem creating User ID. Please contact Help Desk": "Problème de création d’un identifiant utilisateur. Veuillez contacter le service d’assistance.",
            "no data found": "Aucune donnée trouvée",
            "Item added to cart successfully": "Article ajouté au panier avec succès",
            "**Warning - this is a test web site only...\nNo activity will be recorded as production.": "**Attention - Ce site Web est utilisé pour fins de tests uniquement. Aucune saisie ne sera enregistrée.",
            "Date range should be within 3 months": "L'intervalle de dates doit être de moins de 3 mois",
            "Select date range or search by criteria": "Sélectionner la plage de dates ou effectuer une recherche par critère",
            "select Order status": "Sélectionner l’état de la commande",
            "select any criteria": "Sélectionner un des critères",
            "invalid from date": "date de début invalide",
            "invalid to date": "date de fin invalide",
            "Please select From and To Date": "Sélectionner Date de début et de fin",
            "Please enter search criteria": "Entrer un critère de recherche",
            "Please select the employee ID": "Sélectionner l'identifiant de l’employé",
            "Select any Report (by order date)": "Sélectionner un des rapports (par date de commande)",
            "Employee List is too long": "La liste des employés est trop longue",
            "Error updating record from SDIX_RPT_PRESETS": "Erreur de mise à jour de l’enregistrement de SDIX_RPT_PRESETS",
            "Please select the Business Unit": "Sélectionner l’unité d’affaires",
            "Your Selection Criteria Preferences were saved successfully!": "Vos préférences en matière de critères de sélection ont été enregistrées avec succès!",
            "Select Report Date Field": "Sélectionner la Date du rapport dans le champs correspondant",
            "Can not save other Business unit for Report type - Company consolidated": "Impossible de sauvegarder une autre unité commerciale pour le type de rapport - Société consolidée",
            "Select Employee": "Sélectionner un employé",
            "Please select any item to buy again": "Sélectionner l'article à acheter de nouveau",
            "SDiTrack Issue": "Problème avec SDiTrack",
            "Error - User does not exist in ISA_USERS_TBL!": "Erreur - L’utilisateur n’existe pas dans la table ISA_USERS_TB!",
            "Error - User exist more than once in ISA_USERS_TBL table!": "Erreur - L’utilisateur existe plus d’une fois dans la table ISA_USERS_TB!",
            "Entered Vendor ID is Invalid": "L'identifiant entrépour le fournisseur n’est pas valide",
            "Please enter Vendor ID": "Entrer l'identifiant du fournisseur",
            "Error - password has already been used": "Erreur - le mot de passe a déjà été utilisé",
            "Error Updating ISA_USERS_TBL Table": "Erreur de mise à jour de la table ISA_USERS_TBL",
            "Error Updating ISA_ISOL_PW Table": "Erreur de mise à jour de la table ISA_ISOL_PW",
            "Error Updating PS_ISA_EMPL_TBL Table": "Erreur de mise à jour du tableau PS_ISA_EMPL_TBL",
            "Error Updating ISA_USERS_PRIVS Table": "Erreur de mise à jour de la table ISA_USERS_PRIVS",
            "User ID already exists": "L'identifiant de l’utilisateur existe déjà",
            "Invalid User ID": "identifiant de l’utilisateur non valide",
            "Error - No Business Unit Selected!": "Erreur - Aucune unité commerciale sélectionnée!",
            "Error - Invalid BU - check productview id's!": "Erreur - BU non valide - vérifiez les id productview!",
            "Please select a user in User Detail before editing privileges!": "Sélectionner un utilisateur dans la liste avant de modifier les privilèges!",
            "Please save User Detail data before editing privileges!": "Enregistrer le détail associé à l’utilisateur avant de modifier ses privilèges!",
            "Error - Invalid Business Unit - check productview id!": "Erreur - Unité commerciale non valide - vérifiez l’unite commerciale!",
            "Business unit is not set up as an approver site.": "L’unité commerciale n’est pas configurée en tant que site approbateur.",
            "Error - Invalid Business Unit - check productview ids!": "Erreur - Unité commerciale non valide - vérifiez l’unite commerciale!",
            "Technical issue": "Problème technique",
            "Update Failed": "Échec de la mise à jour",
            "Budgetory approver details deleted successfully.": "Les détails de l’approbateur budgétaire ont été supprimés avec succès.",
            "Budgetory approver details deleted failed.": "Les détails de l’approbateur budgétaire supprimés ont échoué.",
            "Budgetory approver details updated successfully.": "Les détails de l’approbateur budgétaire ont été mis à jour avec succès.",
            "Budgetory approver details inserted successfully.": "Les détails de l’approbateur budgétaire ont été insérés avec succès.",
            "Requestor approver deleted successfully.": "L’approbateur demandeur a été supprimé avec succès.",
            "Requestor approver details deleted failed.": "Les détails de l’approbateur du demandeur supprimés ont échoué.",
            "Requestor approver updated successfully.": "L’approbateur du demandeur a été mis à jour avec succès.",
            "Requestor approver details inserted successfully.": "Détails de l’approbateur du demandeur insérés avec succès.",
            "User order status email has been modified and saved successfully.": "Le courriel associé au statut de la commande de l'utilisateur a été modifié et enregistré avec succès.",
            "User preference has been modified and saved successfully": "La préférence de l’utilisateur a été modifiée et enregistrée avec succès",
            "User information has been modified and saved successfully.": "Les informations utilisateur ont été modifiées et enregistrées avec succès.",
            "Update failed. Error message was sent to IT group. Please contact help desk.": "Échec de la mise à jour. Un message d’erreur a été envoyé au groupe informatique. Veuillez contacter le service d’assistance.",
            "User Added to SDiTrack": "Utilisateur ajouté à SDiTrack",
            "UserName already exists in SDiTrack": "Le nom d’utilisateur existe déjà dans SDiTrack",
            "This SDI User account is now active.": "Ce compte d’utilisateur SDI est maintenant actif.",
            "There was an issue trying to activate this account.": "Il y avait un problème en essayant d’activer ce compte.",
            "This SDI User account is now in active.": "Ce compte d’utilisateur SDI est maintenant actif.",
            "This employee account is now active.": "Ce compte d’employé est maintenant actif.",
            "This employee account is now in active.": "Ce compte d’employé est maintenant actif.",
            "There was an issue trying to inactivate this employee account.": "Il y a eu un problème pour essayer d’inactiver ce compte d’employé.",
            "Activate Employee Account": "Activer le compte de l’employé",
            "In-Activate Employee Account": "Compte d’employé in-activate",
            "Activate Account": "Activer le compte",
            "In-Activate Account": "Compte in-activate",
            "The BU does not have an SDiTrack account.": "La BU n’a pas de compte SDiTrack.",
            "SDiTrack User ID": "Identifiant avec SDiTrack",
            "The user is not assigned to a valid BU.": "L’utilisateur n’est pas attache à une Unite commerciale valide.",
            "Please select a role.": "Veuillez sélectionner un rôle.",
            "Please select atleast one program.": "Sélectionner au moins un programme.",
            "A valid approval name must be selected": "Selectionner un nom d’approbateur valide",
            "Role is assigned so order status email cannot be updated": "Le rôle est attribué, l’e-mail d’état de la commande ne peux pas être mis à jour",
            "Please Select the Option": "Sélectionner une option",
            "Set Budgetary Approval": "Définir l’approbation budgétaire",
            "Please enter vendor id": "Entrer l'identifiant du fournisseur",
            "Please enter first Name": "Entrer le prénom",
            "Please select user role": "Sélectionner le rôle de l’utilisateur",
            "Please enter last name": "Entrer le nom de famille",
            "Please enter email address": "Entrer l’adresse courriel",
            "Please enter valid email address": "Entrer une adresse courriel valide ",
            "Please enter phone number": "Entrer un numéro de téléphone",
            "Please enter valid phone Number": "Entrer un numéro de téléphone valide",
            "Please enter password": "Entrer votre mot de passe",
            "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!": "Le mot de passe doit contenir au moins six caractères avec 1 chiffre et 1 lettre et doit débuter par une lettre!",
            "Password must contain at least 1 number and 1 letter - must start with a letter!": "Le mot de passe doit contenir au moins 1 chiffre et 1 lettre et doit débuter par une lettre!",
            "Please enter confirm password": "Entrer le mot de passe à nouveau",
            "Password doesn't match": "Le mot de passe ne correspond pas",
            "Please select site": "Sélectionner un site",
            "Please select a crib": "Veuillez sélectionner un magazin",
            "Budgetary Approval by": "Budget approuvé par",
            "Enter the vendor ID": "Entrer l'identifiant du fournisseur",
            "Please select a PO or enter any PO": "Sélectionner un bon de commande ou entrer un bon de commande",
            "Favorite Pages": "Pages de Favoris",
            "My Account": "Mon compte",
            "Favorite Orders": "Commandes - Favoris",
            "Search for Product": "Recherche de produit",
            "SearchResults": "Résultats de la recherche",
            "is not added to cart due to technical issue. Contact SDI customer care.": "Un  problème technique ne permet pas d'ajouter l'article au panier. Contacter le service clientèle de SDI.",
            "Favorite Item Maintanance": "Articles de maintenance preferes",
            "Favorite Item Maintenance": "Articles de maintenance preferes",
            "*Employee ID: (by name)": "*Identifiant de l’employé",
            "*Employee ID (by name)": "*Identifiant de l’employé (par nom)",
            "Employee ID": "Identifiant de l’employé",
            "*Ship To": "*Expédier à ",
            "Select Ship To": "--Sélectionner ledestinataire - Expédier à--",
            "Show All": "Tout afficher",
            "Kit Flag": "Indicateur de kit",
            "Your order placed successfully and added to favorites order": "Votre commande fut traitée avec succès et ajoutée à vos favorits",
            "Your order placed successfully": "Votre commande fut traitée avec succès",
            "Cart Confirm Report": "Rapport de confirmation de panier",
            "Created Date": "Date de création",
            "Order Type": "Type de commande",
            "Report Type": "Type de rapport",
            "All Orders": "Toutes les commandes",
            "My Orders": "Mes commandes",
            "Open Orders": "Commandes ouvertes",
            "Group Orders": "Commandes de groupe",
            "Search Transaction": "Transaction de recherche",
            "Ship to location": "Expédier à l’emplacement",
            "Ship To Address": "Expédier à l’adresse",
            "Bin Location": "Emplacement du bac",
            "Search User": "Utilisateur de recherche",
            "Item Type": "Type d’élément",
            "Site Name": "Nom du site",
            "Exchange": "Échange",
            "My Favorite Item": "Mon article favori",
            "Catalog Search": "Recherche dans le catalogue",
            "Catalog Lookup": "Recherche dans le catalogue",
            "Add Multiple Items": "Ajouter plusieurs éléments",
            "Item Details": "Détails de l’élément",
            "Search Sites": "Sites de recherche",
            "Purchase History": "Historique des achats",
            "Product Description": "Description du produit",
            "Quantity Available": "Quantité disponible",
            "First Charge Code": "Code de première accusation",
            "Hazard Code": "Code de danger",
            "Drawing Code": "Code de dessin",
            "Current Year": "Année en cours",
            "Previous Year": "Année précédente",
            "If the data provided is correct, you will receive an email with password reset instructions": "Si les données fournies sont correctes, vous recevrez un e-mail avec des instructions de réinitialisation de mot de passe",


            "Priceonrequest": " : Prix sur demande",
            "Usually ships": "Habituellement, les navires",
            "days": "jours",
            "Tech Reference": "Référence technique",
            "Select charge code": "Sélectionnez le code de charge",
            "Unit Price": "Prix Unitaire",
            "Favorite Items": "Articles Préférés",
            "Select any Order Type": "Sélectionnez n’importe quel type de commande",
            "Select any Report Type": "Sélectionnez n’importe quel type de rapport",
            "Select any Business Unit": "Sélectionnez n’importe quelle unité commerciale",
            "Select Order Type": "Sélectionner le type de commande",
            "Select Report Type": "Sélectionner le type de rapport",
            "Select Business Unit": "Sélectionnez unité d’affaires",
            "My Favorite Page": "Ma Page Préférée",
            "My Profile": "Mon Profil",
            "(OR)": "(OU)",
            "Save Preference": "Enregistrer La Préférence",
            "Priority Orders": "Ordres De Priorité",
            "Apply Filter": "Appliquer Le Filtre",
            "Make sure report type is site report, when ordered for selected in search by dropdown": "Assurez-vous que le type de rapport est un rapport de site, lorsqu’il est commandé pour être sélectionné dans la recherche par liste déroulante",
            "Return to Shopping Cart": "Retour au panier d’achat",
            "Profile Update": "Mise à jour du profil",
            "User ID": "Nom d’utilisateur",
            "User Type": "Type d’utilisateur",
            "Add User": "Ajouter un utilisateur",
            "Change Password": "Changer le mot de passe",
            "User Privileges": "Privilèges de l’utilisateur",
            "Privilege Type": "Type de privilège",
            "Select All": "Sélectionnez Tout",
            "Deselect All": "Désélectionnez Tout",
            "SDI Exchange Approval": "Approbation de l’échange SDI",
            "Order Status Emails": "E-mails d’état de commande",
            "Customer Information": "Informations sur le client",
            "Vendor Information": "Informations sur le fournisseur",
            "RFQ Approved": "Approbation de l’appel d’offres",
            "RFQ Rejected": "Appel d’offres Rejeté",
            "Quote Rejected": "Citation Rejetée",
            "RFQ Completed": "Appel d’offres terminé",
            "Non-Catalog Request Entry": "Entrée de demande non cataloguée",
            "Manufacturer Name": "Nom du fabricant",
            "Recommended Supplier": "Fournisseur Recommandé",
            "Estimated Price": "Prix Estimé",
            "Manufacturer Part Number": "Numéro de pièce du fabricant",
            "Check Catalog": "Vérifier le Catalogue",
            "MFG Part Number and MFG Name Required": "Numéro de pièce MFG et nom MFG requis",
            "within": "à l’intérieur",
            "after": "après",
            "Add Favorites": "Ajouter des Favoris",
            "Catalog Description": "Catalogue Désignation des marchandises",
            "Client Description": "Description du client",
            "Info Item": "Élément d’Information",
            "Back to search results": "Retour aux résultats de la recherche",
            "Ext Price": "Prix prolongé",
            "Item id": "Id d’élément",
            "--------Select Approver-------": "--------Sélectionner l’approbateur-------",
            "User preference has been modified and saved successfully.": "La préférence de l’utilisateur a été modifiée et enregistrée avec succès.",
            "Advanced Filter": "Filtre avancé",
            "Order Date": "Date de commande",
            "Waiting Customer Approval": "Attente de l’approbation du client",
            "Product Display Type": "Type d’affichage du produit",
            "This SDI User account has been disabled due to excessive invalid login attempts. Please contact the Help Desk at 215-633-1900, option 7 to reinstate the account.": "Ce compte d’utilisateur SDI a été désactivé en raison des tentatives de connexion non valides excessives. Veuillez communiquer avec le service d’assistance au 215-633-1900, option 7 pour rétablir le compte.",
            "Edit Mode": "Mode d’édition",
            "Cancel Order": "Annuler la commande",
            "Priority Order": "Ordre de priorité",
            "Revised Due Date": "Date d’échéance révisée",
            "Ship Document": "Document d’expédition",
            "Order Details": "Détails de la commande",
            "Archive Notification": "Notification d’archivage",
            "SDI Track": "Piste SDI",
            "SDI Employee": "Employé SDI",
            "Vendor ID": "ID du fournisseur",
            "Current User": "Utilisateur actuel",
            "SDI Track User ID": "Id utilisateur de piste SDI",
            "SDI Track GUID": "GUID de piste SDI",
            "Other User": "Autre utilisateur",
            "Add User ID": "Ajouter un nom d’utilisateur",
            "SDI Item ID": "ID d’élément SDI",
            "Mfg Item ID": "ID d’élément Mfg",
            "Vendor Item ID": "ID d’article de fournisseur",
            "PO QTY": "Quantité de PO",
            "Open QTY": "Quantité ouverte",
            "Received QTY": "Quantité reçue",
            "Tracking Number": "Numéro de suivi",
            "Business Unit In": "Unité d’affaires dans",
            "Employee Name": "Nom de l’employé",
            "PO Price": "PO Prix",
            "PO Number": "Numéro de bon de commande",
            "6 Months": "6 mois",
            "12 Months": "12 mois",
            "2 Years": "2 ans",
            "PO Date": "PO Date",
            "QTY Received": "Quantité reçue",
            "Sch Number": "Nombre de Sch",
            "Original Due Date": "Date d’échéance originale",
            "Reject QTY": "Quantité de rejet",
            "Invoice Amount": "Montant de la facture",
            "Invoice Date": "Date de facturation",
            "Vendor Location": "Emplacement du fournisseur",
            "SDI PO": "SDI PO",
            "Stop ID": "ID d’arrêt",
            "Load ID": "ID de chargement",
            "Trucking / Delivery From": "Camionnage / Livraison À Partir De",
            "ROC": "ROC",
            "District": "District",
            "BLDG#": "BLDG#",
            "Truck Number": "Numéro de camion",
            "Date Delivered": "Date de livraison",
            "Total Count": "Nombre total",
            "Site Report": "Rapport de site",
            "Company-Consolidated Report": "Rapport consolidé de l’entreprise",
            "Site Business Unit": "Unité commerciale du site",
            "--Select Status--": "--Sélectionner l’état--",
            "Line #": "Numéro de ligne",
            "Approved By User ID": "Approuvé par le nom d’utilisateur",
            "Approval Status": "Statut d’approbation",
            "Approval Type": "Type d’homologation",
            "PO Details": "Détails du PO",
            "PO Header Status": "État de l’en-tête po",
            "Req Line #": "Numéro de ligne Req",
            "Purchase Price": "Prix d’achat",
            "Shipment BOL": "Expédition BOL",
            "has been added to cart": "a été ajouté au paniert",
            "Yes, I Agree": "Oui, je suis d’accord",
            "Unit QTY": "Quantité unitaire",
            "Submit Date": "Date de soumission",
            "There was an error declining the quote. A report has been logged with Support.": "Il y a eu une erreur dans le refus de la citation. Un rapport a été enregistré avec le support.",
            "Order total exceeds budgetary limit, Kindly reach administration team": "Le total des commandes dépasse la limite budgétaire, Veuillez contacter l’équipe d’administration",
            "Error: W/O# is not valid! You can only Decline this order. To Approve please enter Valid W/O number": "Erreur: W/O # n’est pas valide! Vous ne pouvez refuser cette commande. Pour approuver, veuillez entrer un numéro W/O valide",
            "Error: W/O# is not valid! To Approve please enter Valid W/O number": "Erreur: W/O # n’est pas valide! Pour approuver, veuillez entrer un numéro W/O valide",
            "Error: Must Enter a W/O number!!": "Erreur: Doit entrer un numéro W/O!!",
            "Error Must Enter a W/O#!!": "L’erreur doit entrer un W/O # !!",
            "There was an error approving the quote. A report has been logged with Support.": "Il y a eu une erreur d’approbation du devis. Un rapport a été enregistré avec le support.",
            "Store Room Description": "Description de la salle de magasin",
            "Line NBR": "Ligne NBR",
            "Cancel Status": "Annuler l’état",
            "Net Received Quantity": "Quantité nette reçue",
            "Receiver ID": "ID du récepteur",
            "Receiver Line": "Ligne de récepteur",
            "PO Line": "Ligne PO",
            "Error --- Problem with e-mail supplied: contact your System Administrator for assistance": "Erreur --- Problème avec le courrier électronique fourni : contactez votre administrateur système pour obtenir de l’aide",
            "Error --- Your account is not Active: contact your System Administrator for assistance": "Erreur --- Votre compte n’est pas actif : contactez votre administrateur système pour obtenir de l’aide",
            "Error missing parameters": "Erreur de paramètres manquants",
            "SDI ZEUS Online Catalog Load": "Chargement du catalogue en ligne SDI ZEUS",
            "Loading ...Please Wait": "Chargement ... S’il te plaît attends",
            "Extended Client Description": "Description étendue du client",
            "SDI Marketplace": "Marché SDI",
            "SDI Quote Reference Number": "Numéro de référence du devis SDI",
            "Item Number": "Numéro de l’article",
            "Requestor Name": "Nom du demandeur",
            "Continue(No Print)": "Continuer(Pas d’impression)",
            "Notification Details": "Détails de la notification",
            "Price Details": "Prix Détails",
            "Card Number": "Numéro de carte",
            "Payment Details": "Détails du paiement",
            "Estimated Tax Amount": "Montant estimatif de la taxe",
            "SDI Requisition Number": "Numéro de réquisition SDI",
            "Submitted Date": "Date de soumission",
            "Add New Favorites": "Ajouter de nouveaux favoris",
            "Catalog Path": "Chemin d’accès au catalogue",
            "Show Extended Description": "Afficher la description étendue",
            "Package Quantity": "Quantité du colis",
            "Estimated Ext. Price": "Prix étendu estimé",
            "Reference Number": "Numéro de référence",
            "My Wallet": "Mon portefeuille",
            "Add Attachment": "Ajouter une pièce jointe",
            "Edit Description": "Modifier la description",
            "Expiry Date": "Date d’expiration",
            "Clear Card": "Carte claire",
            "Merchandise Total": "Total des marchandises",
            "Customer Order": "Commande client",
            "MFG Part #": "Partie MFG #",
            "Stock Type": "Stock Type",
            "Approval Total": "Total des approbations",
            "My Favorite Order": "Ma commande préférée",
            "Catalog Pricing": "Prix du catalogue",
            "Check All": "Cochez tout",
            "Decheck All": "Décochez tout",
            "Items In": "Éléments dans",
            "SDI Store Room": "SDI Salle de magasin",
            "SDI Market Place": "SDI Place du marché",
            "Quick Confirm": "Confirmation rapide",
            "No Vendor Available": "Aucun fournisseur disponible",
            "Back To Home": "Retour à la maison",

            "CVV/CVC Number": "Numéro CVV/CVC",
            "Merchandise Total": "Total des marchandises",
            "Trans OperID": "ID d’opération de transaction",
            "** Non-Catalog Orders require an additional process and you will be notified via email when your quote is available. At which point you will need to come back to this catalog to approve for final procession.**": "** Les commandes hors catalogue nécessitent un processus supplémentaire et vous serez informé par e-mail lorsque votre devis sera disponible. À ce moment-là, vous devrez revenir à ce catalogue pour approuver la procession finale.**",
            "Your MRO Source": "Votre source MRO",
            "User Details": "Détails de l’utilisateur",
            "Select Site": "Sélectionner un site",
            "Select Type": "Sélectionner le type",
            "User Segment 1": "Segment d’utilisateurs 1",
            "User Segment 2": "Segment d’utilisateurs 2",
            "User Segment 3": "Segment d’utilisateurs 3",
            "Employee Segment": "Segment des employés",
            "Item Segment": "Segment d’élément",
            "Machine Segment": "Machine Segment",
            "Select crib": "Sélectionner un berceau",
            "Door - RollSeal": "Porte - RollSeal",
            "Type": "Type",
            "The file for download does not exist": "Le fichier à télécharger n’existe pas",
            "Technical Issue Contact SDI Customer Care": "Problème technique Contactez le service clientèle de SDI",
            "Please enter valid quantity": "Veuillez saisir la quantité valide",
            "Stock Items cannot be added to favorites. Please use the Mobile Issuing program to request stock.": "Les articles en stock ne peuvent pas être ajoutés aux favoris. Veuillez utiliser le programme d’émission mobile pour demander des stocks.",
            "Invalid itemid entered": "Itemid non valide entréInvalid itemid entré",
            "Item already in favorite list.": "Élément déjà dans la liste des favoris.",
            "Please enter Manufacturer Part Number": "Veuillez entrer le numéro de pièce du fabricant",
            "Please enter Manufacturer Name": "Veuillez entrer le nom du fabricant",
            "Please enter Description": "S’il vous plaît entrer description",
            "ACCOUNT": "COMPTE",
            "DEPARTMENT": "DÉPARTEMENT",
            "Client Attributes": "Attributs du client",
            "No client attributes available": "Aucun client n’attribue avaliable",
            "Item PO Price": "Prix de l’article PO",
            "Item PO Ext Price": "Prix prolongé de l’article PO",
            "QTY Open PO": "Quantité Po ouvert",
            "Item USD Price": "Article USD Prix",
            "Ext. USD Price": "Prix USD étendu",
            "Next Approver is not active, So the order cannot be approved": "L’approbateur suivant n’est pas actif, donc l’ordre ne peut pas être approuvé",
            "Selected orders are not approved": "Les commandes sélectionnées ne sont pas approuvées",
            "Problem encountered in approval update - please contact Help Desk.": "Problème rencontré dans la mise à jour de l’approbation - veuillez contacter le service d’assistance.",
            "No database connection.": "Aucune connexion à une base de données.",
            "There was an error declining the order.A report has been logged with Support.": "Il y a eu une erreur dans le refus de l’ordre. Un rapport a été enregistré avec le support.",
            "Encountered an error trying to approve selected order(s). Please review each order's messages and correct or unselect order with error and try to run approval again.": "Vous avez rencontré une erreur en essayant d’approuver le ou les ordres sélectionnés. Veuillez consulter les messages de chaque commande et corriger ou désélectionner l’ordre avec erreur et essayer d’exécuter à nouveau l’approbation.",
            "No order(s) were selected. Please select order(s) to approve.": "Aucune commande n’a été sélectionnée. Veuillez sélectionner la ou les commandes à approuver.",
            "Missing Business Unit and/or Order Number.  Please call SDI Helpdesk for assistance.": "Unité commerciale et/ou numéro de commande manquants.  Veuillez appeler le service d’assistance de SDI pour obtenir de l’aide.",
            "PO Order Total": "Total des commandes de PO",
            "*Order Charge Code": "*Code de charge de commande",
            "Archived Notification": "Notification Archivée",
            "Your Request Id": "L’ID de votre demande",
            "Error in submitting the PO": "Erreur dans la présentation du bon de commande",
            "Charge code for user": "Code de facturation pour l’utilisateur",
            "Address": "Adresse",
            "*Address": "*Adresse",
            "Please enter a valid address": "Veuillez saisir une adresse valide",
            "Valid address": "Adresse valide",
            "Items have to be from the same supplier for pickup to be enabled": "Les articles doivent provenir du même fournisseur pour que le ramassage soit activé",
            "Uncheck pickup to add address": "Décochez la case de retrait pour ajouter une adresse",
            "Unselect ship to to add address": "Désélectionnez expédier à pour ajouter une adresse",
            "Select Supplier Location for pickup": "Sélectionnez l'emplacement du fournisseur pour le ramassage",
            "Pickup Store": "Magasin de ramassage",
            "Partially available": "Partiellement disponible",
            "Contact branch": "Contacter la succursale",
            "Alert": "Alerte",
            "Lowe's items cannot be mixed with other items": "Les articles Lowe's ne peuvent pas être mélangés avec d'autres articles",
            "Please select the pickup store": "Veuillez sélectionner le magasin de retrait",
            "Please Enter a valid Zip Code": "Veuillez entrer un code postal valide",
            "Error in processing zip code": "Erreur lors du traitement du code postal",
            "Please Enter a Zip Code": "Veuillez saisir un code postal",
            "Enter Zip Code": "Entrer le code postal",
            "Expected Delivery Date": "Date de livraison prévue",
            "Pick up is default for Home depot and Lowe's items": "Le ramassage est par défaut pour les articles Home Depot et Lowe's",
            "Home depot items cannot be mixed with other items": "Les articles de Home Depot ne peuvent pas être mélangés avec d'autres articles",
            "Home depot and Lowe's items cannot be mixed with other items": "Les articles Home Depot et Lowe's ne peuvent pas être mélangés avec d'autres articles",
            "Supplier Call-In": "Appel fournisseur",
            "Vendor ID": "ID fournisseur",
            "Currently Vendors are Not Available": "Actuellement, les fournisseurs ne sont pas disponibles",
            "Address not available for the selected Vendor": "Adresse non disponible pour le fournisseur sélectionné",
            "*Please allow the location for displaying miles": "*Veuillez autoriser l’emplacement pour afficher les miles",
            "Phone Number": "Numéro de téléphone",
            "Phone Number is not available": "Le numéro de téléphone n’est pas disponible",
            "Asset ID": "ID de ressource",
            "Enter Asset ID": "Entrez l’ID de ressource",
            "The items in the cart will be removed by changing the WO": "Les articles du panier seront supprimés en changeant le WO",
            "Issue in clearing the cart": "Problème pour vider le panier",
            "Please enter work order number": "Veuillez entrer le numéro du bon de travail",
            "Ships By": "Navires par",
            "Unable to add Grainger item using your current work order number": "Impossible d'ajouter un article Grainger en utilisant votre numéro de bon de travail actuel",
            "Please enter valid work order number": "Veuillez entrer un numéro de bon de travail valide",
            "Unable to submit Grainger item using your current work order number": "Impossible de soumettre l'article Grainger en utilisant votre numéro de bon de travail actuel",
            "Contact Us": "Contactez - nous",
            "Unable to submit Diversified supplier item using your current work order number": "Impossible de soumettre un article de fournisseur diversifié à l'aide de votre numéro de bon de travail actuel",
            "WO Description": "Description du bon de travail",
            "Please select an item to share": "Veuillez sélectionner un élément à partager",
            "Please select a user to share": "Veuillez sélectionner un utilisateur à partager",
            "Users are not available to share": "Les utilisateurs ne sont pas disponibles pour partager",
            "Sharing failed. Please try again later": "Le partage a échoué. Veuillez réessayer plus tard",
            "Shared successfully": "Partagé avec succès",
            "Invalid email address": "Adresse e-mail non valide",
            "Invalid work order": "Bon de travail non valide",
            "Error occured in work order validation": "Une erreur s’est produite dans la validation des ordres de travail",
            "Internal error": "Erreur interne",
            "Active Users - SDI/Customers": "Utilisateurs actifs - SDI/Clients",
            "User Name": "Nom d’utilisateur",
            "Role Type": "Type de rôle",
            "Role Name": "Nom du rôle",
            "Default Putaway Locations": "Emplacements de vente par défaut",
            "Item ID": "ID d’élément",
            "Crib ID": "ID de lit d’enfant",
            "Type": "Type",
            "Area": "Superficie",
            "Aisle": "Allée",
            "Row": "Ligne",
            "Shelf": "Étagère",
            "Bin": "Bac",
            "Action": "Mesures à prendre",
            "Default putaway location inserted successfully!": "Emplacement de putaway par défaut inséré avec succès!",
            "Default putaway location updated successfully!": "Emplacement de putaway par défaut mis à jour avec succès!",
            "Default putaway location not inserted": "Emplacement de putaway par défaut non inséré",
            "Default putaway location not updated": "Emplacement du putaway par défaut non mis à jour",
            "Please select all the fields!": "Veuillez sélectionner tous les champs!",
            "Default Putaway Location does not exist for this Item. Do u want to add new location?": "L’emplacement de putaway par défaut n’existe pas pour cet élément.voulez - vous ajouter un nouvel emplacement ?",
            "Something went wrong please try again": "Quelque chose s’est mal passé s’il vous plaît essayer à nouveau",
            "The Item ID is invalid": "L’ID d’élément n’est pas valide",
            "No record found": "Aucun document trouvé",
            "Please enter Item ID": "Veuillez saisir l’ID de l’article",
            "Add New Location": "Ajouter un nouvel emplacement",
            "Default pickup": "Ramassage par défaut",
            "Budgetary Approval": "Approbation budgétaire",
            "Alternate Requestor Approval": "Approbation d’un autre demandeur",
            "Alternate Budgetary Approval": "Autre approbation budgétaire",
            "Alternate Budgetary Approval by": "Autre approbation budgétaire par",
            "No alternate budgetary approver found": "Aucun autre approbateur budgétaire n’a été trouvé",
            "No alternate requestor approver found": "Aucun autre approbateur demandeur n’a été trouvé",
            "Please select line items to approve": "Veuillez sélectionner les lignes à approuver",
            "Please select line items to decline": "Veuillez sélectionner les lignes à refuser",
            "Required by date": "Date requise",
            "Delivery Point": "Point de livraison",
            "Your order has been approved successfully": "Votre commande a été approuvée avec succès",
            "Your order has been declined successfully": "Votre commande a été refusée avec succès",
            "Selected items have been approved successfully": "Les éléments sélectionnés ont été approuvés avec succès",
            "Selected items have been declined successfully": "Les éléments sélectionnés ont été refusés avec succès",
            "Please fill all the required fields": "Veuillez remplir tous les champs obligatoires",
            "Please enter a valid email address": "Veuillez saisir une adresse e-mail valide",
            "Please enter a valid phone number": "Veuillez saisir le numéro de téléphone",
            "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!": "Le mot de passe doit contenir au moins six caractères avec 1 chiffre et 1 lettre - doit commencer par une lettre!",
            "Error --- Supplied e-mail address is incorrect": "Erreur --- L'adresse e-mail fournie est incorrecte",
            "Start date": "Date de début",
            "To date": "À ce jour",
            "Criteria": "Critères",
            "Contains any": "Contient n’importe quel",
            "Contains all": "Contient tous les",
            "Order no": "Ordre non",
            "Order ln": "Ordre ln",
            "Ship date": "Date d’expédition",
            "Product ID": "ID de produit",
            "Reason CD": "Cd de raison",
            "Qty returned": "Qty est revenu",
            "Qty remaining": "Qty restant",
            "UOM": "UOM",
            "Unit price": "Prix unitaire",
            "Charge code": "Code de charge",
            "Work order no": "Bon de travail non",
            "Machine no": "Machine non",
            "Return to bu": "Retour à bu",
            "Return type": "Type de retour",
            "Serial out": "Sortie en série ",
            "Serial in": "Série dans",
            "Ship ID": "ID du navire",
            "Enter criteria": "Entrez les critères",
            "Business unit": "Unité d’affaires",
            "Enter quantity": "Entrez la quantité",
            "Return To Inventory": "Retour À L’Inventaire",
            "Return to Inventory is the page where purchased products are returned": "Le retour à l’inventaire est la page où les produits achetés sont retournés",
            "Please select the date range": "Veuillez sélectionner la plage de dates",
            "Please enter the criteria based on searchby": "S’il vous plaît entrer les critères basés sur searchby",
            "Please select the search by": "S’il vous plaît sélectionner la recherche par",
            "Please select the employee ID": "Veuillez sélectionner l’ID de l’employé",
            "Please enter returned qty for at least one order to save": "Veuillez saisir qty retourné pour au moins une commande à enregistrer",
            "Qty returned cannot be greater than remaining qty": "Qty retourné ne peut pas être supérieur à qty restant",
            "Qty cannot be greater than remaining qty": "Qty ne peut pas être supérieur à qty restant",
            "Technical issue please contact SDI help desk": "Problème technique veuillez contacter le service d’assistance de SDI",
            "Role Master": "Maître de rôle",
            "Role ID": "ID de rôle",
            "Landing page": "Page d’accueil",
            "Add role": "Ajouter un rôle",
            "Copy role": "Copier le rôle",
            "Add New Role": "Ajouter un nouveau rôle",
            "Apply to all sister sites": "S’appliquer à tous les sites frères",
            "Programs": "Programmes",
            "save": "enregistrer",
            "cancel": "Annuler",
            "New role has been created successfully!": "Un nouveau rôle a été créé avec succès !",
            "ok": "ok",
            "Role details updated successfully!": "Les détails du rôle ont été mis à jour avec succès !",
            "Confirm": "Confirmer",
            "no": "Non",
            "yes": "ouais",
            "Are you sure you want to delete this role?": "Êtes-vous sûr de vouloir supprimer ce rôle ?",
            "This role cannot be deleted as it is applied to users.": "Ce rôle ne peut pas être supprimé car il est appliqué aux utilisateurs.",
            "Expand all": "Développez tout",
            "Collapse all": "S’effondrer tout",
            "Select all": "Sélectionnez tout",
            "Deselect all": "Désélectionnez tout",
            "Role name already exist!": "Le nom du rôle existe déjà !",
            "Role list": "Liste des rôles",
            "Role name": "Nom du rôle",
            "User type": "Type d’utilisateur",
            "Select atleast one program": "Sélectionnez au moins un programme",
            "Copy Role": "Copier le rôle",
            "Add role": "Ajouter un rôle",
            "Enter role name": "Entrez le nom du rôle",
            "Role has been deleted successfully!": "Le rôle a été supprimé avec succès !",
            "Roles list": "Liste des rôles",
            "Advance Shipping Notice": "Préavis d’expédition",
            "Ready for Picking": "Prêt pour la cueillette",
            "Product Replenishment": "Réapprovisionnement des produits",
            "Item ID": "ID d’élément",
            "Enter item ID": "Entrez l’id d’élément",
            "Please enter the item ID": "Veuillez saisir l’ID de l’article",
            "Please enter valid item ID": "Veuillez saisir l’ID d’article valide",
            "Cart Items": "Articles de panier",
            "Rush order": "Ordre rush",
            "Cancel order": "Annuler la commande",
            "Add to cart": "Ajouter au panier",
            "Please enter item quantity": "Veuillez saisir la quantité de l’article",
            "Business unit": "Unité d’affaires",
            "Place order": "Passer commande",
            "Select business unit": "Sélectionnez unité d’affaires",
            "Item quantity": "Quantité d'article",
            "Order placed successfully!": "Commande passée avec succès !",
            "Technical issue please contact SDI help desk": "Problème technique veuillez contacter le service d’assistance de SDI",
            "Item ID or QR": "ID d’élément ou QR",
            "Enter item ID or scan QR": "Entrez l’ID d’élément ou numérisez QR ",
            "Include zero quantity locations": "Inclure des emplacements de quantité nulle",
            "Manual Stock Adjustment": "Ajustement manuel du stock",
            "Please enter the item ID": "Veuillez saisir l’ID de l’article",
            "Please enter the valid item ID": "Veuillez saisir l’ID de l’article valide",
            "Item is not available in crib": " L’article n’est pas disponible dans le berceau ",
            "Item details": "Détails de l’élément",
            "Description": "Désignation des marchandises",
            "BU": "Bu",
            "Qty available": "Qty disponible",
            "Serial ID": "ID de série",
            "Edit adjustment": "Modifier l’ajustement",
            "Adjustment type": "Type d’ajustement ",
            "Adjust quantity": "Ajuster la quantité",
            "Stock adjustment updated successfully!": "Ajustement du stock mis à jour avec succès !",
            "Stock adjustment updation failed!": "La mise à jour de l’ajustement des stocks a échoué !",
            "Please select adjustment type": "Veuillez sélectionner le type d’ajustement",
            "Please remove special characters": "S’il vous plaît supprimer le caractère spécial",
            "Please select reason": "Veuillez sélectionner la raison",
            "Adjustment qty should not be greater than available qty": "Le qty d’ajustement ne doit pas être supérieur au qty disponible",
            "Unit cost": "Coût unitaire",
            "OK": "OK",
            "Please allow your browser to access your webcam": "Veuillez autoriser votre navigateur à accéder à votre webcam",
            "QR scan": "Balayage QR",
            "Item with zero quantity is only available in the crib": "L’article avec une quantité nulle n’est disponible que dans le berceau",
            "Express Putaway": "Express Putaway",
            "Enter cost": "Entrez le coût",
            "Work order#": "Bon de travail#",
            "Enter work order#": "Entrez le bon de travail #",
            "Location details": "Détails de l’emplacement",
            "Item ordered successfully!": "Article commandé avec succès!",
            "Item ordered and default location updated successfully!": "Article commandé et emplacement par défaut mis à jour avec succès !",
            "Please enter cost": "Veuillez saisir le coût",
            "Please select area": "Veuillez sélectionner la zone",
            "Please select aisle": "Veuillez sélectionner l’allée",
            "Please select row": "Veuillez sélectionner la ligne",
            "Please select shelf": "Veuillez sélectionner l’étagère",
            "Please select bin": "Veuillez sélectionner le bac",
            "Please enter valid work order#": "Veuillez saisir un bon de travail valide#",
            "Make as default location": "Faire comme emplacement par défaut ",
            "Please select business unit": "Veuillez sélectionner l’unité commerciale",
            "Material Storage Locations": "Lieu d’entreposage du matériel",
            "Area": "Superficie",
            "Please select area": "Veuillez sélectionner la zone",
            "Storage details": "Détails de stockage",
            "Location type": "Type d’emplacement",
            "Enter value": "Entrez la valeur",
            "Please enter aisle": "S’il vous plaît entrer dans l’allée",
            "Please enter row": "S’il vous plaît entrer la ligne",
            "Please enter shelf": "S’il vous plaît entrer dans l’étagère",
            "Please enter bin": "S’il vous plaît entrer bac",
            "New Location": "Nouvel emplacement",
            "New location added successfully!": "Nouvel Emplacement Ajouté Avec Succès!",
            "Location updated Successfully!": "Emplacement mis à jour avec succès!",
            "New location cannot be added!": "Un nouvel emplacement ne peut pas être ajouté!",
            "Location already exists!": "L’emplacement existe déjà!",
            "Location cannot be updated!": "L’emplacement ne peut pas être mis à jour!",
            "The category and subcategory cannot be identical": "La catégorie et la sous-catégorie ne peuvent pas être identiques",
            "The category alias and subcategory alias cannot be identical": "L’alias de catégorie et l’alias de sous-catégorie ne peuvent pas être identiques",
            //Zeus 243 Program master (Francias(Canada) transaltion)[Vishalini]]
            "Program Master": "Maître de programme",
            "Identifier": "Identificateur",
            "Program name": "Nom du programme",
            "Access group": "Groupe d’accès",
            "New": "Nouveau",
            "Program": "Programme",
            "Enter program name": "Entrez le nom du programme",
            "Program name alias": "Alias de nom de programme",
            "Enter program name alias": "Entrez l’alias du nom du programme",
            "Enter category": "Entrez dans la catégorie",
            "Category alias": "Alias de catégorie",
            "Enter category alias": "Entrez l’alias de catégorie",
            "Subcategory": "Sous-catégorie",
            "Enter subcategory": "Entrez sous-catégorie",
            "Subcategory alias": "Alias de sous-catégorie",
            "Enter subcategory alias": "Entrez l’alias de sous-catégorie",
            "Navigation URL": "La navigation URL",
            "Enter Navigation URL": "Entrez dans la navigation URL",
            "Apply to site": "S’appliquer au site",
            "Active": "Actif",
            "Zeus 2.0": "Zeus 2.0",
            "Walmart": "Walmart",
            "Zeus ordering": "Zeus ordering",
            "IPM": "IPM",
            "Access group": "Nom du champ",
            "SDI": "SDI",
            "Explicit security": "Sécurité explicite",
            "Query string parameters": "Paramètres de chaîne de requête",
            "Enter query string": "Entrez la chaîne de requête",
            "Enter # if program should not display in menu": "Entrez # si le programme ne doit pas s’afficher dans le menu",
            "New program created successfully!": "Nouveau programme créé avec succès!",
            "Please enter program name": "Veuillez entrer le nom du programme",
            "Please enter program name alias": "Veuillez entrer l’alias du nom du programme",
            "Please enter category": "S’il vous plaît entrer la catégorie",
            "Please enter category alias": "S’il vous plaît entrer l’alias de catégorie",
            "Please enter subcategory": "S’il vous plaît entrer sous-catégorie",
            "Please enter subcategory alias": "Veuillez entrer l’alias de sous-catégorie",
            "Program updated successfully!": "Programme mis à jour avec succès!",
            "Do you want to remove this program?": "Voulez-vous supprimer ce programme?",
            "New program is not inserted successfully!": "Le nouveau programme n'est pas inséré avec succès!",
            "Program is not updated successfully!": "Le programme n'a pas été mis à jour avec succès!",
            "Program is not deleted successfully!": "Le programme n'a pas été supprimé avec succès!",
            "Program name already exists!": "Le nom du programme existe déjà!",
            "Number of records": "Nombre d’enregistrements",
            "Enter query string parameters": "Entrez les paramètres de chaîne de requête",
            "Please enter a unique program name alias": "Veuillez entrer un alias de nom de programme unique",
            "Please enter a unique category name alias": "Veuillez entrer un alias de nom de catégorie unique",
            "Category name alias already exist": "L'alias de nom de catégorie existe déjà",
            "Please enter a unique subcategory name alias": "Veuillez entrer un alias de nom de sous-catégorie unique",
            "Subcategory name alias already exist": "L'alias de nom de sous-catégorie existe déjà",
            "Category name already exists in subcategory": "Le nom de la catégorie existe déjà dans la sous-catégorie",
            "Subcategory already exists": "La sous-catégorie existe déjà",
            "Alias name already exist": "Le nom d’alias existe déjà",
            "The category and subcategory cannot be identical": "La catégorie et la sous-catégorie ne peuvent pas être identiques",
            "The category alias and subcategory alias cannot be identical": "L’alias de catégorie et l’alias de sous-catégorie ne peuvent pas être identiques",
            "Enter work order": "Entrez l’ordre de travail",
            "Enter asset ID": "Entrez l’ID de ressource",
            "* Required field - work order# or charge code is required": "* Champ obligatoire - le numéro de bon de travail ou le code de charge est requis",
            "+ New item": "+ Nouvel élément",
            "Cart items": "Articles de panier",
            "MFG": "MFG",
            "MFG part number": "Numéro de pièce MFG",
            "Your cart is empty !": "Votre panier est vide !",
            "BackOrder": "Retour en ordre",
            "Qty": "Qty",
            "Add new item to cart": "Ajouter un nouvel article au panier",
            "Enter item ID": "Entrez l’ID d’élément",
            "type": "type",
            "Please enter item ID": "Veuillez saisir l’ID de l’article",
            "Enabling this option indicates it as a backorder": "L’activation de cette option l’indique comme un backorder",
            "Available qty": "Qty disponible",
            "MFG part#": "N° de pièce MFG ",
            "No data found": "Aucune donnée trouvée",
            "Stock Issues Cart": "Panier d’émissions de stock",
            "Customer notes": "Notes à l’intention des clients",
            "Order charge code": "Code de charge de commande",
            "ID": "Pièce d’identité :",
            "The item is already in the cart": "L’article est déjà dans le panier",
            "You have successfully placed the order!": "Vous avez passé la commande avec succès !",
            "  Remove": "  Supprimer",
            "Please enter qty for the item": "S’il vous plaît entrer qty pour l’élément",
            "Enabling this option indicates it as a backorder.": "L’activation de cet ordre l’indique comme un backorder",
            "Please enter work order number or charge code": "Veuillez entrer le numéro de bon de travail ou le code de frais",
            "Please select the employee ID": "Veuillez sélectionner l’ID de l’employé",
            "MFG part number": "Numéro de pièce MFG",
            //Zeus -82 Picking assign to,6/20/24
            "Picking Assign To": "Sélection d’attribuer à",
            "Export to excel": "Exporter vers Excel",
            "Total items": "Total des éléments",
            "Work order": "Bon de travail",
            "Order date": "Date de commande",
            "Assign to": "Affecter à",
            "Reserved qty": "Qty réservé",
            "Customer notes": "Notes à l’intention des clients",
            "Qty requested": "Qty demandé",
            "Supplier details": "Détails du fournisseur",
            "Address": "Adresse",
            "Distance": "Distance",
            "Qty available": "Qty disponible",
            //Zeus-360
            "Store hours": "Heures d’ouverture du magasin",
            "Driving direction": "Sens de conduite",
            "Add for delivery": "Ajouter pour la livraison",
            "Real Time Inventory": "Inventaire en temps réel",
            "Agility": "Agilité",
            "One SDI": "Un SDI",
            "Operational Excellence": "Excellence opérationnelle",
            // Zeus 349
            "Required By Date": "Date Requise"
        }
    },
    es: {
        translation: {
            "Welcome": "Bienvenido",
            "Number": "Número",
            "No record": "Sin registro",
            "Non-priority": "No prioritario",
            "Business": "Negocio",
            "Unit": "Unidad",
            "Delivery": "Entrega",
            "Need by Date": "Necesidad por fecha",
            "Name": "Nombre",
            "Price": "Precio",
            "Decline": "Disminución",
            "Total": "Total",
            "Created": "Creado",
            "Waiting": "Esperando",
            "Pending Approver": "Aprobador pendiente",
            "Division": "División",
            "Region": "Región",
            "Sub Region": "Subregión",
            "Export to Excel": "Exportar a Excel",
            "Buy Checked Items": "Comprar artículos marcados",
            "Status": "Estado",
            "Install": "Instalar en pc",
            "Filter": "Filtrar",
            "Preference": "Preferencia",
            "Select Report": "Seleccionar informe",
            "Zip Code": "Código postal",
            "Model": "Modelo",
            "Store": "Tienda",
            "Please enter": "Por favor seleccione",
            "Enter valid WorkOrder number": "Ingrese un número de orden de trabajo válido",
            "Make": "Hacer",
            "Pre_filter": "Prefiltro",
            "Item has been added to cart": "El artículo ha sido añadido al carrito.",
            "SearchQuickItem_key": "Buscar por entrada de artículo rápido",
            "Required_key": "Requerido",
            "Done": "Hecho",
            "more_key": "Más",
            "Save": "Salvar",
            "reset_key": "Reiniciar",
            "Attachment": "Adjunto archivo",
            "part number": "Número de pieza",
            "unitofmeasure_key": "Unidad de medida",
            "recommended": "Recomendado",
            "Vendor": "Vendedor",
            "orderedfor_key": "Pedido para",
            "orderenteredby_key": "Pedido ingresado por",
            "Priority": "Prioridad",
            "workorder_key": "Orden de trabajo",
            "order_key": "Orden",
            "Quick Item Entry": "Entrada rápida de artículos",
            "Items have to be from the same supplier for pickup to be enabled": "Los artículos deben ser del mismo proveedor para que se habilite la recolección",
            "Pickup": "Recoger",
            "Customer Notes": "Notas de los clientes",
            "Edit": "Editar",
            "Favorite": "Favorito",
            "Bill-to-Store": "Facturación a tienda",
            "Ship To": "Envie a",
            "Delivery Date": "Fecha de entrega",
            "Asset Tag ID": "ID de etiqueta de activo",
            "Employee": "Empleado",
            "Details": "Detalles",
            "Item": "Artículo",
            "Shopping Cart": "Carrito de compras",
            "Download": "Descargar",
            "Archive": "Archivo",
            "Date": "Fecha",
            "Title": "Título",
            "Notification": "Notificación",
            "Number of Records": "Número de registros",
            "Quantity": "Cantidad",
            "Action": "Acción",
            "Your": "Tu",
            "Description": "Descripción",
            "Category": "Categoría",
            "ID": "Identificación",
            "Continue Shopping": "Seguir comprando",
            "Return to": "Volver a",
            "New": "Nuevo",
            "In the absence of written agreement to the contrary between customer and SDI, all pricing contained in this catalog is subject to change by SDI at any time without notice.": "En ausencia de un acuerdo por escrito en contrario entre el cliente y SDI, todos los precios contenidos en este catálogo están sujetos a cambios por parte de SDI en cualquier momento sin previo aviso.",
            "Profile": "Perfil",
            "Home": "Casa",
            "Update": "Actualizar",
            "Enable": "Permitir",
            "Crib Identifier": "Identificador de cuna",
            "Block Price Display": "Visualización de precio de bloque",
            "Client": "Cliente",
            "Catalog": "Catalogar",
            "Cancel": "Cancelar",
            "Submit": "Entregar",
            "Approval": "Aprobación",
            "Customer": "Cliente",
            "Partial Received": "Parcial recibido",
            "Information": "Información",
            "Fully Received": "Totalmente recibido",
            "Quote": "Cita",
            "Cancelled": "Cancelado",
            "Rejected": "Rechazado",
            "Delivered": "Completo",
            "Complete": "Completo",
            "Quote Approved": "Cotización aprobada",
            "set": "Colocar",
            "Delete": "Borrar",
            "Order Total Requiring Approval": "Total del pedido que requiere aprobación",
            "budg_approval_by": "Aprobación presupuestaria por",
            "Address": "Dirección",
            "f_name": "Primer nombre",
            "type": "Escribe",
            "Phone": "Número de teléfono",
            "l_name": "Apellido",
            "Deselect": "Deseleccionar",
            "user_group": "Grupo de usuario",
            "Expand": "Expandir",
            "Collapse": "Colapso",
            "Role": "Papel",
            "Site": "Sitio",
            "dept": "Departamento",
            "User": "Usuario",
            "change_pwd": "Cambiar la contraseña",
            "Add": "Agregar",
            "Zeus": "Zeus",
            "Mobility": "Movilidad",
            "Preferences": "Preferencias",
            "emails": "Correos electrónicos",
            "Product": "Producto",
            "My": "Mi",
            "Logout": "Cerrar sesión",
            "Term of Purchase": "Plazo de compra",
            "Answer Lab": "Laboratorio de respuestas",
            "f_p": "Página favorita",
            "Account": "Cuenta",
            "Selection": "Selección",
            "select": "Seleccione",
            "Clear": "Claro",
            "test_site": "sitio de prueba",
            "inv_supplier": "PROVEEDORES DE INVENTARIO",
            "will_call_supplier": "PROVEEDORES WLL-CALL",
            "Parts List": "Lista de partes",
            "Supplier": "Proveedor",
            "Manufacturer": "Fabricante",
            "Mode": "Modo",
            "Help": "Ayuda",
            "Tech Hub": "Centro Tecnológico",
            "order_status": "Estado Del Pedido",
            "Non-Catalog Request": "Solicitud sin catálogo",
            "punch_out": "Perforar",
            "Maintenance": "Mantenimiento",
            "Privileges": "Privilegios",
            "Entry": "Entrada",
            "Reason": "Razón",
            "Choose File": "Elija el archivo",
            "Archived": "Archivado",
            "Page": "Página",
            "Total Items": "Articulos totales",
            "Location": "Localización",
            "Summary": "Resumen",
            "Confirm": "Confirmar",
            "Over Pick": "Sobre Pick",
            "Under Pick": "Debajo de Pick",
            "Zero Pick": "Selección cero",
            "Yes": "Sí",
            "No": "No",
            "Order": "Orden",
            "Picking Items": "Selección de artículos",
            "Info": "Información",
            "Select a site": "Seleccione un sitio",
            "Item ID": "ID del artículo",
            "Action Code": "Código de acción",
            "Utilization Code": "Código de utilización",
            "Forecast Family": "Familia de previsión",
            "Zero On Hand": "Cero disponible",
            "Replenish Type": "Tipo de reabastecimiento",
            "Replenish Class": "Reabastecer la clase",
            "In Business Unit": "En Unidad de Negocio",
            "Replenish Status": "Estado de reposición",
            "Not On Order": "No en orden",
            "Cost": "Costo",
            "Extended Cost": "Costo extendido",
            "Process Replen": "Reposición de proceso",
            "Average On Hand": "Promedio disponible",
            "Growth": "Crecimiento",
            "Order Forecast Model": "Modelo de previsión de pedidos",
            "12 Month Forecast": "Previsión de 12 meses",
            "Month's Usage": "Uso del mes",
            "Next Order Date": "Próxima fecha de pedido",
            "Next Order Qty": "Próxima cantidad de pedido",
            "Data Posponed": "Datos aplazados",
            "Review": "Revisión",
            "Next Review Date": "Próxima fecha de revisión",
            "Days On Hand": "Días disponibles",
            "Excess Units": "Unidades en exceso",
            "Excess Dollars": "Exceso de dólares",
            "Receiveing Cost": "Recibir el costo",
            "Service Level": "Nivel de servicio",
            "Max Weeks": "Semanas máximas",
            "Carrying Cost": "Costo de transporte",
            "Item Stock Type": "Tipo de stock del artículo",
            "Safety stock": "Stock de seguridad",
            "Package Size": "Tamaño del paquete",
            "Max Capacity": "Máxima capacidad",
            "Lead-Time": "Tiempo de espera",
            "Optimal Order QTY": "Cantidad de orden óptima",
            "Current On Hand": "Actual disponible",
            "Open PO Qty": "Cantidad de orden de compra abierta",
            "Reserved Qty": "Cantidad reservada",
            "Min": "Min",
            "Open": "Abierto",
            "Suggested Min": "Mínimo sugerido",
            "Backorder": "Pedido pendiente",
            "Max": "Max",
            "Suggested Max": "Max sugerido",
            "Default Putaway": "Almacenamiento predeterminado",
            "Date Added": "Fecha Agregada",
            "Critical Spare": "Repuesto crítico",
            "Supplier Part Number": "Número de pieza del proveedor",
            "Last Issues Date": "Fecha de última emisión",
            "Client Stock Type": "Tipo de stock del cliente",
            "Inventory Days On Hand": "Días de inventario disponibles",
            "Item Has Been Updated Successfully": "El artículo se ha actualizado correctamente",
            "Ok": "De acuerdo",
            "Mismatch": "Discordancia",
            "Cycle Count": "Contador de Cíclos",
            "Standard Leadtime": "Plazo de entrega estándar",
            "Machine Number": "Número de máquina",
            "Drawing": "Dibujo",
            "Machine Control Num": "Número de control de la máquina",
            "Purchasing Dept Spec": "Especificaciones del departamento de compras",
            "Regrind": "Triturar",
            "First": "Primero",
            "Issue": "Asunto",
            "Charge": "Cargar",
            "Code": "Código",
            "Replenish": "Reponer",
            "Reorder Paint": "Reordenar pintura",
            "Modifiers": "Modificadores",
            "Attributes": "Atributos",
            "Path": "Sendero",
            "Noun": "Sustantivo",
            "Store Room": "Trastero",
            "No attributes avaliable": "No hay atributos disponibles",
            "Total Order Qty": "Cantidad total de pedidos",
            "SAP Article Number": "Número de artículo",
            "No. of Pieces": "Numero de piezas",
            "Material": "Material",
            "Owned By": "Propiedad de",
            "Package": "Paquete",
            "Features": "Características",
            "Group": "Grupo",
            "Cycle Count Reconciliation": "Conciliación de recuento de ciclos",
            "Variance": "Diferencia",
            "Reconciliation Tag": "Etiqueta de reconciliación",
            "Item Ready For Update": "Artículo listo para actualizar",
            "Finalization": "Finalización",
            "Load Recon": "Carga Recon",
            "Counting Event": "Evento de conteo",
            "Entered Item Count": "Recuento de elementos ingresados",
            "Bin": "Compartimiento",
            "Equipment Tag": "Etiqueta de equipo",
            "Inspection Required": "Se requiere inspección",
            "Hazard": "Peligro",
            "Tracking": "Seguimiento",
            "Ship Via": "Embarcar vía",
            "In": "En",
            "Ship Date": "Fecha de envío",
            "Years": "Años",
            "Months": "Meses",
            "Select Duration": "Seleccionar duración",
            "Receive Items": "Recibir artículos",
            "Inventory Management System": "Sistema de gestión de inventario",
            "Search containing value limit to date range entered above": "Búsqueda que contiene el límite de valor para el rango de fechas ingresado arriba",
            "Search containing value but limit to last 6 months": "La búsqueda contiene valor pero se limita a los últimos 6 meses",
            "Search exact value": "Buscar valor exacto",
            "Orders": "Pedidos",
            "Search by": "Búsqueda por",
            "Date range is limited to 3 months": "El rango de fechas está limitado a 3 meses",
            "To": "A",
            "From": "Desde",
            "Report": "Reporte",
            "Select Report (by Order date)": "Seleccione Informe (por fecha de pedido)",
            "Items": "Elementos",
            "Lookup": "Buscar",
            "Check": "Cheque",
            "WAREHOUSE": "DEPÓSITO",
            "Categories": "Categorías",
            "No Search Result found": "No se encontraron resultados de búsqueda",
            "Did you mean": "Querías decir",
            "You searched for": "Usted buscó",
            "Search Result": "Resultado de búsqueda",
            "Received": "Recibió",
            "Search": "Buscar",
            "supplier_callin": "Llamada de proveedor",
            "Email Address": "Dirección de correo electrónico",
            "Password": "Contraseña",
            "Confirm Password": "Confirmar Contraseña",
            "Privilege": "Privilegio ",
            "A la Carte": "A la carta",
            "Program Tree View": "Vista de árbol del programa",
            "Program": "Programa",
            "Freight Provider :": "Proveedor de flete:",
            "History": "Historia",
            "Buy again :": "Compra otra vez :",
            "Expected": "Esperado",
            "Last Updated On": "Ultima actualización en",
            "Pending": "Pendiente",
            "Counted": "Contados",
            "Indicates Inventory Order": "Indica orden de inventario",
            "Stock Value Report": "Informe de valor de acciones",
            "Select": "Seleccione",
            "GO": "IR",
            "Show Bin Loc": "Mostrar Bin Loc",
            "Include Quantity On Hand": "Incluir cantidad disponible",
            "ItemKey": "Artículo",
            "Planner CD": "CD del planificador",
            "On Hand": "En mano",
            "Currency": "Divisa",
            "Run": "Courir",
            "Count": "Contar",
            "Avail": "Aprovechar",
            "Crib": "Cuna",
            "Aisle": "Pasillo",
            "Row": "Hilera",
            "Shelf": "Estante",
            "Reserv": "Reserv",
            "Actual": "Real",
            "See not updated items": "Ver artículos no actualizados",
            "Change": "Cambio",
            "Add": "Agregar",
            "Track": "Pista",
            "Current": "Actual",
            "Added On": "Añadido",
            "Other": "Otro",
            "Approved": "Aprobado",
            "Waiting Approval Chain": "Esperando la cadena de aprobación",
            "No Records found": "No se encontraron registros",
            "All": "Todos",
            "Image": "Imagen",
            "Waiting Order Approval": "Esperando aprobación de pedido",
            "Advanced": "Avanzado",
            "Stock": "Existencias",
            "UnDelete": "Recuperar",
            "Line": "Línea",
            "Do you want to remove this item?": "Quieres eliminar este artículo?",
            "Favorites": "Favoritos",
            "Show": "Show",
            "Start": "Comienzo",
            "End": "Fin",
            "Search Criteria": "Criterio de búsqueda",
            "Contains": "Contiene",
            "Any": "Alguna",
            "Remaining": "Restante",
            "Returned": "Devuelto",
            "Reason": "Razón",
            "You don't have Permission to": "No tienes permiso para",
            "Access this page": "Accede a esta pagina",
            "valid": "válido",
            "call us": "llámanos",
            "Contact sdi customer care": "Póngase en contacto con el servicio de atención al cliente de sdi",
            "SDI MRO for a more": "SDI MRO para más",
            "connected": "conectado",
            "enterprise": "empresa",
            "Apply": "Solicitar",
            "Sorry, we couldn’t find any search result": "Lo sentimos, no pudimos encontrar ningún resultado de búsqueda",
            "Do you want to remove this order ?": "¿Quieres eliminar este pedido?",
            "Reorder": "Reordenar",
            "Part": "Parte",
            "Reference": "Referencia",
            "Door - DH Pace": "Puerta - DH Pace",
            "Lift Truck": "Carretilla Elevadora",
            "Liftparts": "Liftparts",
            "Terms and Conditions": "Términos y condiciones",
            "Zeus emarketplace Registration Terms": "Condiciones de registro de Zeus emarketplace",
            "I Do not Agree": "No estoy de acuerdo",
            "I Agree": "Estoy de acuerdo",
            "Submit a Non Catalog Request": "Envíe una solicitud que no sea de catálogo",
            "Site Under Construction": "En construcción",
            "Will be Back Soon": "Volveremos pronto",
            "Back": "Atrás",
            "Do you want to archive this notification?": "¿Quieres archivar esta notificación",
            "Merchandise": "Mercancías",
            "Extended": "Extendido",
            "Usage": "Uso",
            "Issues": "Cuestiones",
            "Sites": "Sitios",
            "Purchase": "Compra",
            "Transactions": "Actas",
            "Available": "Disponible",
            "This price is subject to confirmation by supplier": "Este precio está sujeto a confirmación por parte del proveedor.",
            "Add to Cart": "Añadir al carrito",
            "Maximum": "Máximo",
            "Item More Details": "Artículo Más detalles",
            "Year": "Año",
            "Previous": "Anterior",
            "Transaction Date": "Fecha de Transacción",
            "No Records to display": "No hay registros que mostrar",
            "Owner": "Dueño",
            "Lot/Serial Number": "Número de lote/serie",
            "There are no bin locations to display for this item": "No hay ubicaciones para mostrar para este artículo",
            "Related site data not available at this time.": "Los datos del sitio relacionados no están disponibles en este momento.",
            "Net Received": "Neto recibido",
            "Operator Entered By": "Operador ingresado por",
            "Receiver": "Receptor",
            "Estimated": "Estimado",
            "Tax Amount": "Importe del impuesto",
            "Payment": "Pago",
            "Credit Card": "Tarjeta de crédito",
            "Debit Card": "Tarjeta de débito",
            "All Cards are accepted": "Se aceptan todas las tarjetas",
            "Card": "Tarjeta",
            "Wallet": "Billetera",
            "Expiry": "Expiración",
            "Pay Now": "Pagar ahora",
            "Select file to attach": "Seleccionar archivo para adjuntar",
            "Attach": "Adjuntar",
            "Would you like to use line numbers by increments of 10?": "¿Le gustaría usar números de línea en incrementos de 10?",
            "Select any one of your wallet account for your payment process": "Seleccione cualquiera de su cuenta de billetera para su proceso de pago",
            "Do you want to make this as your favorite order?": "¿Quieres que sea tu pedido favorito?",
            "Success": "Éxito",
            "Payment has been successfully completed": "El pago se ha completado con éxito",
            "Are you sure you want to cancel your order?": "¿Estás seguro de que deseas cancelar tu pedido?",
            "All charge code segments must be selected": "Deben seleccionarse todos los segmentos del código de cargo",
            "Notes": "Notas",
            "Is This Priority?": "¿Es esta prioridad?",
            "Part": "Parte",
            "Browse": "Navegar",
            "Tax Exempt": "Exento de impuestos",
            "Add Item to Catalog": "Agregar artículo al catálogo",
            "Please agree the terms and conditions": "Por favor acepte los términos y condiciones",
            "Market Place": "Market Place",
            "Attribute": "Atributo",
            "Warning! Default UOM list displayed - no cross reference data available for the current Business Unit": "¡Advertencia! Se muestra la lista de unidades de medida predeterminada: no hay datos de referencia cruzada disponibles para la unidad de negocio actual",
            "Save/More": "Guardar/mas",
            "Save/Done": "Guardar/Listo",
            "Quick": "Rápido",
            "Quantity On Hand": "Cantidad en mano",
            "Approve": "Aprobar",
            "Due Date": "Fecha de vencimiento",
            "Approved By": "Aprobado por",
            "Ship": "Barco",
            "RFQ order and line number": "Orden de RFQ y número de línea",
            "Request by Date": "Solicitar por fecha",
            "Recieved": "Recibido",
            "Received": "Recibido",
            "Reject": "Rechazar",
            "Invoice": "Factura",
            "Packing Slips": "Albaranes",
            "Stop": "Parada",
            "Load": "Carga",
            "Shipping Date": "Fecha de envío",
            "Trade": "Comercio",
            "Ordered By": "Ordenado por",
            "Ship Container": "Contenedor de barco",
            "Comments": "Comentarios",
            "Fragile Item Signature": "Firma del artículo frágil",
            "Truck": "Camión",
            "Driver": "Conductor",
            "By": "Par",
            "[proof-of-delivery]": "[prueba-de-envío]",
            "Close": "Cerrar",
            "Loaded Container": "Contenedor cargado",
            "Last": "Último",
            "Days": "Dias",
            "This": "Esta ",
            "Month": "Mes",
            "Transaction": "Transacción",
            "Company-Consolidated": "Consolidado por la empresa",
            "Indicates Alternate Requestor Approver": "Indica Aprobador Solicitante Alterno",
            "Order Approval History": "Historial de aprobación de pedidos",
            "Proof of delivery": "Prueba de envío",
            "Header": "Encabezamiento",
            "Collapse All": "Desplegar todo",
            "Expand All": "Expandir todo",
            "Shipment": "Envío",
            "Multiple": "Múltiple",
            "Main Menu": "Menú principal",
            "Favorite": "Favorito",
            "Pages": "Paginas",
            "You don't have any record": "No tienes ningún registro",
            "Go to Cart": "Ir al carrito",
            "Pricing": "Precios",
            "Click on vendor's logo below to start browsing their catalog": "Haga clic en el logotipo del proveedor a continuación para comenzar a navegar por su catálogo.",
            "ERROR": "ERROR",
            "PunchOut is not currently available for this Vendor": "PunchOut no está disponible actualmente para este proveedor",
            "A message was sent notifying site administrator of this problem": "Se envió un mensaje notificando al administrador del sitio sobre este problema",
            "We apologize for this inconvenience": "Pedimos disculpas por este inconveniente",
            "Return": "Regreso",
            "There are no available vendors": "No hay proveedores disponibles",
            "Partner Vendor": "Proveedor asociado",
            "Marketplace": "Mercado",
            "Requestor": "Solicitante",
            "Received By": "Recibido por",
            "Thank you for choosing": "Gracias por escoger",
            "Source": "Fuente",
            "We will notify you once your quote is ready": "Le notificaremos una vez que su cotización esté lista",
            "Print": "Impresión",
            "Continue": "Continuar",
            "Order Submitted for Processing": "Pedido enviado para procesamiento",
            "Order Submitted for Quote": "Pedido enviado para cotización",
            "User": "Usuario",
            "Approvals": "Aprobaciones",
            "Enable Multisite Access": "Habilitar el acceso multisitio",
            "In-Active Employee Account": "Cuenta de empleado activa",
            "In-Active Account": "Cuenta inactiva",
            "Set Requestor Approval": "Establecer la aprobación del solicitante",
            "Alternate Requestor Approval by": "Aprobación del solicitante alternativo por",
            "Email": "Correo electrónico",
            "Approval": "Aprobación",
            "Display": "Monitor",
            "Add to SDI Track": "Agregar a la pista SDI",
            "Add Other User to SDI Track": "Agregar otro usuario a la pista SDI",
            "More": "Más",
            "Item Group": "Grupo de artículos",
            "Issue Charge Code": "Código de cargo de emisión",
            "Archive All": "Archiva todo",
            "Charge Code": "Código de carga",
            "Order Total": "Total del pedido",
            "More Details": "Más detalles",
            "Customer Reference": "Referencia de cliente",
            "Work Order #": "Orden de Trabajo #",
            "Item base price": "Precio base del artículo",
            "Item qty": "Cantidad de objetos",
            "Order Summary & Payment Details": "Resumen de la orden & Detalles del pago",
            "Pickup From Local Supplier": "Recogida del proveedor local",
            "Check Order Status": "Comprobar el estado del pedido",
            "UserProfile": "Perfil de usuario",
            "Favorite Item Maintenance": "Mantenimiento de artículo favorito",
            "Order Approval": "Aprobación de pedido",
            "Waiting Requestor Approval": "Esperando la aprobación del solicitante",
            "Requestor Approval": "Aprobación del solicitante",
            "Order Status": "Estado del pedido",
            "Receiving": "Recepción",
            "User Profile": "Perfil del usuario",
            "Approve Orders": "Aprobar pedidos",
            "PunchOut": "Perforar",
            "Order Status Report": "Informe de estado del pedido",
            "Add New User": "Añadir nuevo usuario",
            "Edit Vendor Information": "Editar la información del proveedor",
            "Edit User Information": "Editar la información del usuario",
            "Edit Mexico Information": "Editar información de México",
            "Activate/Inactivate Users": "Activar / desactivar usuarios",
            "Add New Vendor": "Agregar nuevo proveedor",
            "Add New Mexico": "Agregar Nuevo México",
            "Edit Favorites": "Editar favoritos",
            "  TEST site! Nothing saved to Production!": "¡Sitio de prueba! ¡Nada guardado en Producción!",
            "Ordering": "Pedidos",
            "Your User Profile": "Su perfil de usuario",
            "User profile": "Perfil del usuario",
            "Inventory": "Inventario",
            "SDI Category": "SDI Categoría",
            "Your Category": "Tu Categoría",
            "Item Description": "Descripción del Artículo",
            "Your Description": "Tu descripción",
            "MFG Part Number": "MFG Número de pieza",
            "Waiting For your Approval": "Esperando su aprobación",
            "This order/quote is still waiting approval from": "Este pedido / cotización aún está esperando la aprobación de ",
            "Order Number": "Número de orden",
            "Order Entered by": "Pedido ingresado por",
            "Business Unit": "Unidad de negocio",
            "Work Order Number": "Número de Orden de Trabajo",
            "Order Entered By": "Pedido ingresado por",
            "Enter ID": "Ingrese ID",
            "Notification ID": "ID de notificación",
            "User Notification": "Notificación de usuario",
            "Punchout Catalog Search for ZEUS": "Búsqueda de catálogo Punchout para ZEUS",
            "Enter Value": "Ingrese valor",
            "Enter First Name": "Ingrese el nombre",
            "Enter Last Name": "Introduzca el apellido",
            "Enter Phone number": "Ingresa número telefónico",
            "Enter Password": "Introducir la contraseña",
            "Enter Confirm Password": "Ingrese confirmar contraseña",
            "Enter User Id": "Ingrese la identificación del usuario",
            "Select department": "Seleccionar departamento",
            "-----Select Department-----": "-----Seleccionar Departamento-----",
            "Enter SDITrack User ID": "Ingrese el ID de usuario de SDITrack",
            "Corp Admin": "Administrador Corporativo",
            "Super": "Súper",
            "Admin": "Administración",
            "Disable Zeus": "Desactivar Zeus",
            "Enable Zeus": "Habilitar Zeus",
            "contact sdi customer care": "póngase en contacto con el servicio de atención al cliente de sdi",
            "All fields are mandatory": "Todos los campos son obligatorios",
            "First Name": "Primer nombre",
            "Last Name": "Apellido",
            "New PunchIn User Registration": "Nuevo registro de usuario de PunchIn",
            "This website utilizes cookies and similar technologies for functionality and other purposes. Your use of this website constitutes your acceptance of cookies.": "Este sitio web utiliza cookies y tecnologías similares para funciones y otros fines. Su uso de este sitio web constituye su aceptación de cookies.",
            "Ok, Got it": "Ok, lo tengo",
            "Punchin session has been end": "La sesión de punchin ha finalizado",
            "Click on exit button to return": "Haga clic en el botón de salida para volver",
            "Exit": "Salida",
            "Catalog Details": "Detalles del catálogo",
            "Non Catalog Details": "Detalles que no pertenecen al catálogo",
            "ItemDetail": "Detalle del artículo",
            "Catalog Item Details": "Detalles del artículo del catálogo",
            "Non Catalog Item Details": "Detalles de artículos que no pertenecen al catálogo",
            "PunchOut with our merchant partners": "PunchOut con nuestros socios comerciales",
            "Lift Truck - Coats Garage": "Carretilla Elevadora - Coats Garage",
            "Lift Truck - Wiese": "Carretilla Elevadora - Wiese",
            "Lift Truck - HGM Liftparts": "Carretilla Elevadora - HGM Liftparts",
            "Reference Site": "Sitio de referencia",
            "Last 7 Days": "Los últimos 7 días",
            "Last 15 Days": "Los últimos 15 días",
            "Last 30 Days": "Los últimos 30 días",
            "Last Month": "El mes pasado",
            "This Month": "Este mes",
            "Order #": "Número de orden",
            "Work Order": "Número de Orden de Trabajo",
            "PO Id": "ID de orden de compra",
            "Ordered For": "Pedido para",
            "Enter Name": "Ingrese su nombre",
            "Enter Description": "Ingrese descripción",
            "Enter Estimated Price": "Ingrese el precio estimado",
            "Ln": "línea #",
            "Sch": "Calendario",
            "PO": "Orden de compra",
            "qty": "cantidad",
            "Line Status": "Estado de la línea",
            "PO": "Orden de compra",
            "Addr Seq": "Secuencia de direcciones",
            "Session Timeout": "Hora de término de la sesión",
            "Go to login": "Ir a iniciar sesión",
            "Login": "Acceso",
            "Now exiting": "Ahora saliendo",
            "and returning to": "y volviendo a",
            "disabling session connection...": "deshabilitar la conexión de la sesión...",
            "Please click here, if this page does not automatically refresh in 5 seconds": "Haga clic aquí, si esta página no se actualiza automáticamente en 5 segundos",
            "MFG name": "MFG Nombre",
            "Online Catalog Load": "Carga del catálogo en línea",
            "Loading": "Cargando",
            "Please Wait": "Espere por favor",
            "Enter Old Password": "Ingrese la contraseña anterior",
            "Enter New Password": "Ingrese nueva clave",
            "Price on request": "Precio en demanda",
            "Non Catalog Request": "Solicitud sin catálogo",
            "Order Charge Code": "Código de cargo de pedido",
            "Enter charge code": "Ingrese el código de cargo",
            "Enter value": "Ingrese valor",
            "Enter Machine Number": "Ingrese el número de máquina",
            "Enter your Card Number": "Ingrese su número de tarjeta",
            "Enter Part No": "Ingrese el número de pieza",
            "Enter Location": "Ingrese la ubicación",
            "Enter CVV/CVC": "Ingrese CVV/CVC",
            "Submit Order": "Orden de envio",
            "Check Out": "Verificar",
            "*Employee ID": "*ID de empleado",
            ": (by name)": "por nombre",
            "Requisitioner Name": "Nombre del solicitante",
            "Log in to your account": "Ingrese a su cuenta",
            "Forgot Password ?": "Has olvidado tu contraseña ?",
            "Speed to Execution": "Velocidad de ejecución",
            "Simplify": "Simplificar",
            "Commitment": "Compromiso",
            "Perseverance": "Perseverancia",
            "Courage": "Coraje",
            "Solution-Oriented": "Solucion orientada",
            "inc. All Rights Reserved": "incorporado todos los derechos reservados",
            "Zeus ID": "Identificación de Zeus",
            "Request Forgotten Password": "Solicitar contraseña olvidada",
            "Enter Login Id": "Ingrese ID de inicio de sesión",
            "Enter E-Mail address": "Introduzca la dirección de correo electrónico",
            "Reset Password": "Restablecer la contraseña",
            "Back To Login": "Atrás para iniciar sesión",
            "Password will be reset and sent to the email address that was set up in the Login profile for the Login ID entered above.": "La contraseña se restablecerá y se enviará a la dirección de correo electrónico que se configuró en el perfil de inicio de sesión para el ID de inicio de sesión ingresado anteriormente.",
            "*Work Order #": "*Orden de trabajo #",
            "Invalid item id": "ID de artículo no válido",
            "Favorite item inserted sucessfully": "Elemento favorito insertado con éxito",
            "Invalid or the itemid already exists": "No válido o el itemid ya existe",
            "Technical Issue Try again": "Problema técnico Inténtelo de nuevo",
            "Deleted Successfully": "Borrado exitosamente",
            "Updated Successfully": "Actualizado con éxito",
            "Please enter Item Id": "Ingrese el ID del artículo",
            "Please enter quantity": "Por favor, introduzca la cantidad",
            "You are not allowed to use 'Shopping Cart' here. \nYou must login from SAP.": "No se le permite utilizar 'Carrito de la compra' aquí. Debe iniciar sesión desde SAP.",
            "Links are for reference information only.\nDo not place orders from suppliers site.": "Los enlaces son solo para información de referencia. No realice pedidos desde el sitio de proveedores.",
            "True": "Cierto",
            "Please agree terms and conditions to access shopping cart": "Acepte los términos y condiciones para acceder al carrito de compras",
            "Insufficient privileges for adding to cart": "Privilegios insuficientes para agregar al carrito",
            "You are not allowed to use this screen here \n you must login from SAP": "No se le permite utilizar esta pantalla aquí \ n debe iniciar sesión desde SAP",
            "Item successfully added to the favorite list": "Elemento agregado correctamente a la lista de favoritos",
            "Due to technical Issue item is not added to the favorite list.Try after sometime.": "Debido a un problema técnico, el artículo no se agrega a la lista de favoritos. Inténtelo después de algún tiempo.",
            "Technical issue.Try after sometime.": "Problema técnico. Inténtelo después de algún tiempo.",
            "Cart is empty": "El carrito esta vacío",
            "Please fill the delivery date": "Por favor, rellene la fecha de entrega",
            "Please fill all item level employee id": "Por favor, rellene todos los id de empleado a nivel de artículo",
            "Please fill all Mandatory Fields": "Por favor, complete todos los campos obligatorios",
            "Please enter work order number or charge code": "Introduzca el número de orden de trabajo o el código de cargo",
            "Please fill all item level charge code": "Por favor, rellene todos los códigos de cargo a nivel de artículo",
            "Please fill work order": "Por favor, rellene la orden de trabajo",
            "Error while updating WALLET": "Error al actualizar WALLET",
            "Technical Issue -- Contact SDI": "Problema técnico -- Póngase en contacto con SDI",
            "Card number is required": "Se requiere el número de tarjeta",
            "Invalid card number": "Numero de tarjeta invalido",
            "Expiration month & Year is required": "Se requiere mes y año de vencimiento",
            "CVV/CVC Number is required": "Se requiere el número CVV / CVC",
            "Invalid CVV/CVC number": "Número CVV / CVC no válido",
            "Invalid expiry date": "Fecha de caducidad inválida",
            "Error In Payment Process": "Error en el proceso de pago",
            "--Select Charge Code--": "--Seleccione Código de cargo--",
            "Card already exist in user wallet": "La tarjeta ya existe en la billetera del usuario",
            "You have exceeded the length of total file names.\nPlease adjust your entry or remove longer file names.": "Ha excedido la longitud total de los nombres de archivo. Ajuste su entrada o elimine los nombres de archivo más largos.",
            "No file is selected": "No se ha seleccionado ningún archivo",
            "No Item Number entered for Quick Item Entry": "No se ingresó ningún número de artículo para la entrada rápida de artículos",
            "Invalid Item Number entered for Quick Item Entry": "Número de artículo no válido ingresado para la entrada rápida de artículo",
            "Techincal Issue Contact SDI Customer Care": "Problema técnico Comuníquese con el servicio de atención al cliente de SDI",
            "Enter valid Work Order Number": "Ingrese un número de orden de trabajo válido",
            "Order Number already exist": "El número de pedido ya existe",
            "No enterprise record found": "No se encontró ningún registro empresarial",
            "Order total exceeds budgetary limit, Kindly reach administration team": "El total del pedido supera el límite presupuestario, contacte con el equipo de administración",
            "All serialized items must be assigned a serial ID.": "A todos los artículos serializados se les debe asignar una identificación de serie.",
            "Error in approval update - please contact Help Desk.": "Error en la actualización de la aprobación; comuníquese con el Servicio de asistencia.",
            "ReqID DB error - Contact Support": "Error de ReqID DB - Póngase en contacto con el soporte",
            "Description required for the NSTK item.": "Descripción requerida para el artículo NSTK.",
            "Selected file is already attached, please try again": "El archivo seleccionado ya está adjunto, inténtelo de nuevo",
            "Your Order has been Approved/Declined Successfully": "Su pedido ha sido aprobado / rechazado con éxito",
            "Problem in approval/decline process, Your Order has not been Approved/Declined": "Problema en el proceso de aprobación / rechazo, su pedido no ha sido aprobado / rechazado",
            "No Orders are selected to approve": "No se seleccionan pedidos para aprobar",
            "No Orders are selected to decline": "No se seleccionan pedidos para rechazar",
            "Remove": "Eliminar",
            "Enter Confirm Password": "Ingrese Confirmar Contraseña",
            "Item does not exist in the catalog": "El artículo no existe en el catálogo",
            "Please enter item ID": "Ingrese el ID del artículo",
            "Req MFG": "Se requiere el nombre del fabricante y el número de pieza del fabricante \ n para consultar el catálogo",
            "Enter a valid Quantity": "Ingrese una cantidad válida",
            "is not added to cart due to technical issue. Contact SDI customer care": "no se agrega al carrito debido a un problema técnico.Póngase en contacto con el servicio de atención al cliente de SDI",
            "Tec_Issue": "Problemas técnicos Comuníquese con el servicio de atención al cliente de SBI",
            "Item has been approved successfully": "El artículo ha sido aprobado con éxito",
            "Item has been declined successfully": "El artículo ha sido rechazado correctamente.",
            "Selected items has been approved successfully": "Los elementos seleccionados se aprobaron correctamente",
            "Selected items has been declined successfully": "Los elementos seleccionados se han rechazado correctamente",
            "Please select line items to Approve/Decline": "Veuillez sélectionner les éléments de campagne à approuver/refuser",
            "Please enter first name": "Por favor ingrese su nombre",
            "Please enter last name": "Por favor ingrese su apellido",
            "Please enter email address": "Por favor ingrese la dirección de correo electrónico",
            "Passwordvalidation": "Por favor, ingrese contraseña",
            "ValidateConfrmPass": "Por favor ingrese confirmar contraseña",
            "ValidPassword": "La contraseña debe contener al menos seis caracteres con 1 número y 1 letra, ¡debe comenzar con una letra!",
            "Valid_Password": "La contraseña debe contener al menos 1 número y 1 letra, ¡debe comenzar con una letra!",
            "PasswordMatch": "La contraseña no coincide",
            "Please select shipto": "Por favor seleccione enviar a",
            "Please select the ship to": "Por favor, seleccione el envío a",
            "Enter category": "Introducir categoría",
            "Enter sub category": "Introducir subcategoría",
            "Please enter the address": "Por favor, introduzca la dirección",
            "You have exceeded the length of total file names": "Has excedido la longitud total de los nombres de archivos.",
            "Please adjust your entry or remove longer file names": "Ajuste su entrada o elimine los nombres de archivo largos",
            "New Password updated successfully": "Nueva contraseña actualizada correctamente",
            "Old Password is Incorrect": "Antigua contraseña es incorrecta",
            "Old Password is required": "Se requiere contraseña anterior",
            "New Password is required": "Se requiere nueva contraseña",
            "Confirm Password is required": "Se requiere confirmación de contraseña",
            "Your new password has been sent to your email address.": "Su nueva contraseña ha sido enviada a su dirección de correo electrónico.",
            "Please enter required fields": "Por favor ingrese los campos obligatorios",
            "Please enter user id": "Por favor ingrese el ID de usuario",
            "Are you sure you want to reset your password?": "¿Estás seguro de que quieres restablecer tu contraseña?",
            "Duplicate UserID''s.  Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "ID de usuario duplicados.Comuníquese con el contacto de su sitio de SDI O llame a Tecnología de la Información de SDI al 215-633-1900",
            "Email does not match email for this user, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "El correo electrónico no coincide con el correo electrónico de este usuario, la contraseña no se restableció. Comuníquese con el contacto de su sitio de SDI o llame a Tecnología de la información de SDI al 215-633-1900",
            "Not a valid email for your userid, password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "No es un correo electrónico válido para su ID de usuario, la contraseña no se restableció. Comuníquese con el contacto de su sitio de SDI o llame a Tecnología de la Información de SDI al 215-633-1900",
            "Reset failed! Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "¡El reinicio falló! Comuníquese con el contacto de su sitio de SDI O llame a Tecnología de la información de SDI al 215-633-1900",
            "UserID does not exist, Password not reset. Contact your SDI site contact or call SDI Information Technology at 215-633-1900": "ID de usuario no existe, contraseña no restablecida. Comuníquese con el contacto de su sitio de SDI o llame a Tecnología de la Información de SDI al 215-633-1900",
            "Client is inactive": "El cliente está inactivo",
            "Your account has been disabled due to excessive invalid login attempts.Please contact the Help Desk at 215-633-1900, option 7 to reinstate your account.": "Su cuenta ha sido deshabilitada debido a un número excesivo de intentos de inicio de sesión no válidos. Comuníquese con el Servicio de asistencia al 215-633-1900, opción 7 para restablecer su cuenta.",
            " **Error - your password has expired. Click on the \"Forgot your Password\" link to be assigned a new password": " ** Error: su contraseña ha caducado. Haga clic en el enlace \ Olvidé su contraseña \ para que se le asigne una nueva contraseña",
            "Invalid ID/password": "usuario / contraseña invalida",
            "Provided username and password is incorrect": "El nombre de usuario y la contraseña proporcionados son incorrectos",
            "View only mode flag set - order not saved": "Conjunto de indicadores de modo de solo visualización: pedido no guardado",
            "Enter Quantity for at least one item": "Ingrese la cantidad para al menos un artículo",
            "notification Archived": "Notificación archivada",
            "please try again": "Inténtalo de nuevo",
            "Please select any Notification": "Seleccione cualquier notificación",
            "BlckShopCart": "No está autorizado a utilizar esta pantalla aquí, debe iniciar sesión desde SAP",
            "PO is invalid": "La orden de compra no es válida",
            "Problem creating User ID. Please contact Help Desk": "Problema al crear el ID de usuario. Comuníquese con el servicio de asistencia",
            "no data found": "datos no encontrados",
            "Item added to cart successfully": "Artículo agregado al carrito con éxito",
            "**Warning - this is a test web site only...\nNo activity will be recorded as production.": "** Advertencia: este es solo un sitio web de prueba ... No se registrará ninguna actividad como producción.",
            "Date range should be within 3 months": "El intervalo de fechas debe estar dentro de los 3 meses.",
            "Select date range or search by criteria": "Seleccione el rango de fechas o busque por criterios",
            "select Order status": "Seleccione Estado del pedido",
            "select any criteria": "Seleccione cualquier criterio",
            "invalid from date": "Inválido desde la fecha",
            "invalid to date": "Inválido hasta la fecha",
            "Please select From and To Date": "Seleccionar desde y hasta la fecha",
            "Please enter search criteria": "Ingrese los criterios de búsqueda",
            "Please select the employee ID": "Seleccione la identificación del empleado",
            "Select any Report (by order date)": "Seleccione cualquier informe (por fecha de pedido)",
            "Employee List is too long": "La lista de empleados es demasiado larga",
            "Error updating record from SDIX_RPT_PRESETS": "Error al actualizar el registro de SDIX_RPT_PRESETS",
            "Please select the Business Unit": "Seleccione la unidad de negocio",
            "Your Selection Criteria Preferences were saved successfully!": "¡Sus preferencias de criterios de selección se guardaron correctamente!",
            "Select Report Date Field": "Seleccione el campo de fecha del informe",
            "Can not save other Business unit for Report type - Company consolidated": "No se puede guardar otra unidad de negocio para el tipo de informe: empresa consolidada",
            "Select Employee": "Seleccionar empleado",
            "Please select any item to buy again": "Seleccione cualquier artículo para comprar de nuevo",
            "SDiTrack Issue": "Problema de SDiTrack",
            "Error - User does not exist in ISA_USERS_TBL!": "Error: el usuario no existe en ISA_USERS_TBL.",
            "Error - User exist more than once in ISA_USERS_TBL table!": "Error: el usuario existe más de una vez en la tabla ISA_USERS_TBL!",
            "Entered Vendor ID is Invalid": "El ID de proveedor introducido no es válido",
            "Please enter Vendor ID": "Ingrese el ID del proveedor",
            "Error - password has already been used": "Error: la contraseña ya se ha utilizado",
            "Error Updating ISA_USERS_TBL Table": "Error al actualizar la tabla ISA_USERS_TBL",
            "Error Updating ISA_ISOL_PW Table": "Error al actualizar la tabla ISA_ISOL_PW",
            "Error Updating PS_ISA_EMPL_TBL Table": "Error al actualizar la tabla PS_ISA_EMPL_TBL",
            "Error Updating ISA_USERS_PRIVS Table": "Error al actualizar la tabla ISA_USERS_PRIVS",
            "User ID already exists": "El ID de usuario ya existe",
            "Invalid User ID": "Identificación de usuario inválida",
            "Error - No Business Unit Selected!": "Error: no se ha seleccionado ninguna unidad de negocio !",
            "Error - Invalid BU - check productview id's!": "Error - BU no válido - ¡verifique los ID de la vista del producto!",
            "Please select a user in User Detail before editing privileges!": "Seleccione un usuario en Detalles de usuario antes de editar los privilegios!",
            "Please save User Detail data before editing privileges!": "Guarde los datos de detalles del usuario antes de editar los privilegios !",
            "Error - Invalid Business Unit - check productview id!": "Error - Unidad de negocio no válida - ¡Verifique el id de la vista del producto!",
            "Business unit is not set up as an approver site.": "La unidad de negocio no está configurada como un sitio de aprobación.",
            "Error - Invalid Business Unit - check productview ids!": "Error: unidad de negocio no válida: compruebe los ID de vista de producto!",
            "Technical issue": "Inconveniente técnico",
            "Update Failed": "Actualización fallida",
            "Budgetory approver details deleted successfully.": "Los detalles de aprobación presupuestaria se eliminaron correctamente.",
            "Budgetory approver details deleted failed.": "Los detalles de aprobación presupuestaria se eliminaron correctamente.",
            "Budgetory approver details updated successfully.": "Los detalles de aprobación presupuestaria se actualizaron correctamente.",
            "Budgetory approver details inserted successfully.": "Los detalles de la aprobación presupuestaria se insertaron correctamente.",
            "Requestor approver deleted successfully.": "Aprobador solicitante eliminado correctamente.",
            "Requestor approver details deleted failed.": "Los detalles del aprobador del solicitante eliminados fallaron.",
            "Requestor approver updated successfully.": "Aprobador solicitante actualizado correctamente.",
            "Requestor approver details inserted successfully.": "Los detalles del aprobador del solicitante se insertaron correctamente.",
            "User order status email has been modified and saved successfully.": "El correo electrónico del estado del pedido del usuario se ha modificado y guardado correctamente.",
            "User preference has been modified and saved successfully": "La preferencia del usuario se ha modificado y guardado correctamente",
            "User information has been modified and saved successfully.": "La información del usuario se ha modificado y guardado correctamente.",
            "Update failed. Error message was sent to IT group. Please contact help desk.": "Actualización fallida. Se envió un mensaje de error al grupo de TI. Comuníquese con el servicio de asistencia técnica.",
            "User Added to SDiTrack": "Usuario agregado a SDiTrack",
            "UserName already exists in SDiTrack": "UserName ya existe en SDiTrack",
            "This SDI User account is now active.": "Esta cuenta de usuario de SDI ahora está activa.",
            "There was an issue trying to activate this account.": "Hubo un problema al intentar activar esta cuenta.",
            "This SDI User account is now in active.": "Esta cuenta de usuario de SDI ahora está activa.",
            "This employee account is now active.": "Esta cuenta de empleado ahora está activa.",
            "This employee account is now in active.": "Esta cuenta de empleado ahora está activa.",
            "There was an issue trying to inactivate this employee account.": "Hubo un problema al intentar desactivar esta cuenta de empleado.",
            "Activate Employee Account": "Activar cuenta de empleado",
            "In-Activate Employee Account": "Cuenta de empleado activada",
            "Activate Account": "Activar la cuenta",
            "In-Activate Account": "Cuenta activada",
            "The BU does not have an SDiTrack account.": "La BU no tiene una cuenta SDiTrack.",
            "SDiTrack User ID": "ID de usuario de SDiTrack",
            "The user is not assigned to a valid BU.": "El usuario no está asignado a una BU válida.",
            "Please select a role.": "Seleccione una función.",
            "Please select atleast one program.": "Seleccione al menos un programa.",
            "A valid approval name must be selected": "Se debe seleccionar un nombre de aprobación válido",
            "Role is assigned so order status email cannot be updated": "El rol está asignado, por lo que el correo electrónico de estado del pedido no se puede actualizar",
            "Please Select the Option": "Seleccione la opción",
            "Set Budgetary Approval": "Establecer aprobación presupuestaria",
            "Please enter vendor id": "Ingrese el ID del proveedor",
            "Please enter first Name": "Por favor ingrese el nombre",
            "Please select user role": "Seleccione el rol de usuario",
            "Please enter last name": "Por favor ingrese su apellido",
            "Please enter email address": "Por favor ingrese la dirección de correo electrónico",
            "Please enter phone number": "Por favor ingrese el numero de telefono",
            "Please enter valid phone Number": "Ingrese un número de teléfono válido",
            "Please enter password": "Por favor, ingrese contraseña",
            "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!": "La contraseña debe contener al menos seis caracteres con 1 número y 1 letra, ¡debe comenzar con una letra!",
            "Password must contain at least 1 number and 1 letter - must start with a letter!": "La contraseña debe contener al menos 1 número y 1 letra, ¡debe comenzar con una letra!",
            "Please enter confirm password": "Por favor ingrese confirmar contraseña",
            "Password doesn't match": "La contraseña no coincide",
            "Please select site": "Seleccione el sitio",
            "Please select a crib": "Selecciona una cuna",
            "Budgetary Approval by": "Aprobación presupuestaria por",
            "Enter the vendor ID": "Ingrese la identificación del proveedor",
            "Please select a PO or enter any PO": "Seleccione una orden de compra o ingrese una orden de compra",
            "Favorite Pages": "Páginas favoritas",
            "My Account": "Mi cuenta",
            "Favorite Orders": "Órdenes favoritas",
            "Search for Product": "Buscar producto",
            "SearchResults": "Resultados de la búsqueda",
            "is not added to cart due to technical issue. Contact SDI customer care.": "no se agrega al carrito debido a un problema técnico. Comuníquese con el servicio de atención al cliente de SDI.",
            "Favorite Item Maintanance": "Mantenimiento de artículo favorito",
            "Favorite Item Maintenance": "Mantenimiento de artículo favorito",
            "*Employee ID: (by name)": "*ID de empleado: (por nombre)",
            "*Employee ID (by name)": "*ID de empleado (por nombre)",
            "Employee ID": "ID de empleado",
            "*Ship To": "*Envie a",
            "Select Ship To": "--Seleccione Enviar a--",
            "Show All": "Mostrar todo",
            "Kit Flag": "Kit Bandera",
            "Your order placed successfully and added to favorites order": "Su pedido se realizó con éxito y se agregó a favoritos",
            "Your order placed successfully": "Tu pedido realizado con éxito",
            "Cart Confirm Report": "Carrito Confirmar Informe",
            "Created Date": "Fecha de creación",
            "Order Type": "Tipo de orden",
            "Report Type": "Tipo de informe",
            "All Orders": "Todas las órdenes",
            "My Orders": "Mis ordenes",
            "Open Orders": "Ordenes abiertas",
            "Group Orders": "Órdenes de grupo",
            "Search Transaction": "Transacción de búsqueda",
            "Ship to location": "Enviar a la ubicación",
            "Ship To Address": "Enviar a la dirección",
            "Bin Location": "Ubicación del contenedor",
            "Search User": "Usuario de búsqueda",
            "Item Type": "Tipo de artículo",
            "Site Name": "Nombre del sitio",
            "Exchange": "Intercambiar",
            "Catalog Search": "Búsqueda en catálogos",
            "Item Details": "Detalles del artículo",
            "Search Sites": "Buscar sitios",
            "Purchase History": "Historial de compras",
            "Product Description": "Descripción del producto",
            "Quantity Available": "Cantidad disponible",
            "First Charge Code": "Código de primer cargo",
            "Hazard Code": "Código de peligro",
            "Drawing Code": "Código de dibujo",
            "Current Year": "Año corriente",
            "Previous Year": "Año anterior",
            "If the data provided is correct, you will receive an email with password reset instructions": "Si los datos proporcionados son correctos, recibirá un correo electrónico con instrucciones para restablecer la contraseña",
            "Priceonrequest": " : Precio en demanda",
            "Usually ships": "Por lo general, los barcos",
            "days": "días",
            "--------Select Approver-------": "--------Seleccionar Aprobador-------",
            "Tech Reference": "Referencia tecnológica",
            "Select charge code": "Seleccione el código de cargo",
            "Unit Price": "Precio Por Unidad",
            "Favorite Items": "Artículos Favoritos",
            "Select any Order Type": "Seleccione cualquier tipo de pedido",
            "Select any Report Type": "Seleccione cualquier tipo de informe",
            "Select any Business Unit": "Seleccione cualquier unidad de negocio",
            "Select Order Type": "Seleccione el tipo de pedido",
            "Select Report Type": "Seleccione el tipo de informe",
            "Select Business Unit": "Seleccionar unidad de negocio",
            "My Favorite Page": "Mi Página Favorita",
            "My Profile": "Mi Perfil",
            "(OR)": "(O)",
            "Save Preference": "Guardar Preferencia",
            "Priority Orders": "Órdenes De Prioridad",
            "Apply Filter": "Aplicar Filtro",
            "Make sure report type is site report, when ordered for selected in search by dropdown": "Asegúrese de que el tipo de informe es el informe del sitio, cuando se ordena para seleccionar en la búsqueda mediante el menú desplegable",
            "Return to Shopping Cart": "Volver al carrito de la compra",
            "Profile Update": "Actualización del perfil",
            "User ID": "ID de usuario",
            "User Type": "Tipo de usuario",
            "Add User": "Agregar usuario",
            "Change Password": "Cambiar contraseña",
            "User Privileges": "Privilegios de usuario",
            "Privilege Type": "Tipo de privilegio",
            "Select All": "Seleccionar Todo",
            "Deselect All": "Anule la selección de Todo",
            "SDI Exchange Approval": "Aprobación de SDI Exchange",
            "Order Status Emails": "Correos electrónicos de estado del pedido",
            "Customer Information": "Información del cliente",
            "Vendor Information": "Información del proveedor",
            "RFQ Approved": "RFQ Aprobado",
            "RFQ Rejected": "RFQ Rechazada",
            "Quote Rejected": "Cotización Rechazada",
            "RFQ Completed": "RFQ Completado",
            "Non-Catalog Request Entry": "Entrada de solicitud no catalogada",
            "Manufacturer Name": "Nombre del fabricante",
            "Recommended Supplier": "Proveedor Recomendado",
            "Estimated Price": "Precio Estimado",
            "Manufacturer Part Number": "Número de pieza del fabricante",
            "Check Catalog": "Consultar Catálogo",
            "MFG Part Number and MFG Name Required": "Número de pieza de MFG y nombre de MFG requeridos",
            "within": "dentro",
            "after": "después",
            "Add Favorites": "Añadir Favoritos",
            "Catalog Description": "Catálogo Descripción",
            "Client Description": "Descripción del Cliente",
            "Info Item": "Elemento de Información",
            "Back to search results": "Volver a los resultados de búsqueda",
            "Ext Price": "Precio extendido",
            "Add Multiple Items": "Agregar varios elementos",
            "Item id": "ID del artículo",
            "User preference has been modified and saved successfully.": "La preferencia del usuario se ha modificado y guardado correctamente.",
            "Advanced Filter": "Filtro avanzado",
            "Order Date": "Fecha del pedido",
            "Waiting Customer Approval": "Esperando la aprobación del cliente",
            "Product Display Type": "Tipo de pantalla del producto",
            "This SDI User account has been disabled due to excessive invalid login attempts. Please contact the Help Desk at 215-633-1900, option 7 to reinstate the account.": "Esta cuenta de usuario de SDI se ha deshabilitado debido a intentos de inicio de sesión no válidos excesivos. Comuníquese con el servicio de asistencia al 215-633-1900, opción 7 para restablecer la cuenta.",
            "Edit Mode": "Modo de edición",
            "Cancel Order": "Cancelar pedido",
            "Priority Order": "Orden de prioridad",
            "Revised Due Date": "Fecha de vencimiento revisada",
            "Ship Document": "Documento de envío",
            "Order Details": "Detalles del pedido",
            "Archive Notification": "Notificación de archivo",
            "SDI Track": "Pista SDI",
            "SDI Employee": "Empleado de SDI",
            "Vendor ID": "ID de proveedor",
            "Current User": "Usuario actual",
            "SDI Track User ID": "ID de usuario de SDI Track",
            "SDI Track GUID": "GUID de pista SDI",
            "Other User": "Otro usuario",
            "Add User ID": "Agregar ID de usuario",
            "SDI Item ID": "ID de artículo SDI",
            "Mfg Item ID": "ID de artículo de Mfg",
            "Vendor Item ID": "ID de artículo de proveedor",
            "PO QTY": "Cantidad de PO",
            "Open QTY": "Cantidad abierta",
            "Received QTY": "Cantidad recibida",
            "Tracking Number": "Número de seguimiento",
            "Business Unit In": "Unidad de Negocio En",
            "Employee Name": "Nombre del empleado",
            "PO Price": "Precio PO",
            "PO Number": "Número de orden de compra",
            "6 Months": "6 Meses",
            "12 Months": "12 Meses",
            "2 Years": "2 Años",
            "PO Date": "Fecha de compra",
            "QTY Received": "Cantidad recibida",
            "Sch Number": "Número Sch",
            "Original Due Date": "Fecha de vencimiento original",
            "Reject QTY": "Rechazar cantidad",
            "Invoice Amount": "Importe de la factura",
            "Invoice Date": "Fecha de la factura",
            "Vendor Location": "Ubicación del proveedor",
            "SDI PO": "SDI PO",
            "Stop ID": "ID de detención",
            "Load ID": "ID de carga",
            "Trucking / Delivery From": "Camiones / Entrega desde",
            "ROC": "ROC",
            "District": "Distrito",
            "BLDG#": "BLDG#",
            "Truck Number": "Número de camión",
            "Date Delivered": "Fecha de entrega",
            "Total Count": "Recuento total",
            "Site Report": "Informe del sitio",
            "Company-Consolidated Report": "Informe consolidado de la empresa",
            "Site Business Unit": "Unidad de Negocio del Sitio",
            "--Select Status--": "--Seleccionar estado--",
            "Line #": "Número de línea",
            "Approved By User ID": "Aprobado por ID de usuario",
            "Approval Status": "Estado de aprobación",
            "Approval Type": "Tipo de homologación",
            "PO Details": "Detalles de PO",
            "PO Header Status": "Estado del encabezado PO",
            "Req Line #": "Número de línea Req",
            "Purchase Price": "Precio de compra",
            "Shipment BOL": "Envío BOL",
            "has been added to cart": "se ha añadido al carrito",
            "Yes, I Agree": "Sí, estoy de acuerdo",
            "Catalog Lookup": "Búsqueda de catálogo",
            "Unit QTY": "Cantidad unitaria",
            "Submit Date": "Fecha de envío",
            "There was an error declining the quote. A report has been logged with Support.": "Hubo un error al rechazar la cotización. Se ha registrado un informe con Soporte técnico.",
            "Order total exceeds budgetary limit, Kindly reach administration team": "El total del pedido excede el límite presupuestario, comuníquese amablemente con el equipo de administración",
            "Error: W/O# is not valid! You can only Decline this order. To Approve please enter Valid W/O number": "Error: ¡W/O# no es válido! Solo puede rechazar este pedido. Para aprobar, introduzca el número de W/O válido",
            "Error: W/O# is not valid! To Approve please enter Valid W/O number": "Error: ¡W/O# no es válido! Para aprobar, introduzca el número de W/O válido",
            "Error: Must Enter a W/O number!!": "Error: Debe introducir un número de W/O!!",
            "Error Must Enter a W/O#!!": "Error debe introducir un W/O#!!",
            "There was an error approving the quote. A report has been logged with Support.": "Hubo un error al aprobar la cotización. Se ha registrado un informe con Soporte técnico.",
            "Store Room Description": "Descripción del trastero",
            "Line NBR": "Línea NBR",
            "Cancel Status": "Estado de cancelación",
            "Net Received Quantity": "Cantidad neta recibida",
            "Receiver ID": "ID del receptor",
            "Receiver Line": "Línea de receptores",
            "PO Line": "Línea PO",
            "Error --- Problem with e-mail supplied: contact your System Administrator for assistance": "Error --- Problema con el correo electrónico suministrado: póngase en contacto con el administrador del sistema para obtener ayuda",
            "Error --- Your account is not Active: contact your System Administrator for assistance": "Error --- su cuenta no está activa: póngase en contacto con el administrador del sistema para obtener ayuda",
            "Error missing parameters": "Error al faltar parámetros",
            "SDI ZEUS Online Catalog Load": "Carga del catálogo en línea de SDI ZEUS",
            "Loading ...Please Wait": "Carga... Espera",
            "Extended Client Description": "Descripción ampliada del cliente",
            "SDI Marketplace": "Mercado SDI",
            "SDIExchange - Request for Quote": "SDIExchange - Solicitud de cotización",
            "SDI Quote Reference Number": "Número de referencia de la cotización SDI",
            "Item Number": "Número de artículo",
            "Requestor Name": "Nombre del solicitante",
            "Continue(No Print)": "Continuar(Sin imprimir)",
            "Notification Details": "Detalles de la notificación",
            "Price Details": "Detalles Del Precio",
            "Card Number": "Número de tarjeta",
            "Payment Details": "Detalles de pago",
            "Estimated Tax Amount": "Monto estimado del impuesto",
            "Please enter valid email address": "Introduzca una dirección de correo electrónico válida",
            "SDI Requisition Number": "Número de solicitud de SDI",
            "Submitted Date": "Fecha de envío",
            "Add New Favorites": "Añadir nuevos favoritos",
            "Catalog Path": "Ruta del catálogo",
            "Show Extended Description": "Mostrar descripción ampliada",
            "Package Quantity": "Cantidad del paquete",
            "Estimated Ext. Price": "Precio estimado extendido",
            "Reference Number": "Número de referencia",
            "My Wallet": "Mi monedero",
            "Add Attachment": "Agregar datos adjuntos",
            "Edit Description": "Editar descripción",
            "Expiry Date": "Fecha de caducidad",
            "Clear Card": "Borrar tarjeta",
            "Merchandise Total": "Total de mercancías",
            "Customer Order": "Pedido del cliente",
            "MFG Part #": "Parte MFG #",
            "Stock Type": "Tipo de stock",
            "Approval Total": "Total de aprobación",
            "My Favorite Order": "Mi pedido favorito",
            "Catalog Pricing": "Precios de catálogo",
            "Check All": "Comprobar todo",
            "Decheck All": "Desmarcar todo",
            "Items In": "Artículos en",
            "SDI Store Room": "SDI Trastero",
            "SDI Market Place": "SDI Mercado",
            "Quick Confirm": "Confirmación rápida",
            "No Vendor Available": "No hay proveedor disponible",
            "Back To Home": "Volver a inicio",

            "CVV/CVC Number": "Número CVV/CVC",
            "Merchandise Total": "Total de mercancías",
            "Trans OperID": "ID de operación de transacción",
            "** Non-Catalog Orders require an additional process and you will be notified via email when your quote is available. At which point you will need to come back to this catalog to approve for final procession.**": "** Los pedidos no catalogados requieren un proceso adicional y se le notificará por correo electrónico cuando su cotización esté disponible. En ese momento tendrá que volver a este catálogo para aprobar la procesión final.**",
            "Your MRO Source": "Su fuente MRO",
            "User Details": "Detalles del usuario",
            "Select Site": "Seleccione sitio",
            "Select Type": "Seleccione Tipo",
            "User Segment 1": "Segmento de usuario 1",
            "User Segment 2": "Segmento de usuario 2",
            "User Segment 3": "Segmento de usuario 3",
            "Employee Segment": "Segmento de empleados",
            "Item Segment": "Segmento de artículo",
            "Machine Segment": "Segmento de máquina",
            "Select crib": "Seleccionar cuna",
            "Door - RollSeal": "Puerta - RollSeal",
            "Type": "Escribe",
            "The file for download does not exist": "El archivo para descargar no existe",
            "Technical Issue Contact SDI Customer Care": "Problema técnico Póngase en contacto con el servicio de atención al cliente de SDI",
            "Please enter valid quantity": "Introduzca una cantidad válida",
            "Stock Items cannot be added to favorites. Please use the Mobile Issuing program to request stock.": "Los artículos de stock no se pueden agregar a favoritos. Utilice el programa de emisión móvil para solicitar existencias.",
            "Invalid itemid entered": "Itemid no válido introducidoInvalid itemid introducido",
            "Item already in favorite list.": "Elemento que ya está en la lista de favoritos.",
            "Please enter Manufacturer Part Number": "Introduzca el número de pieza del fabricante",
            "Please enter Manufacturer Name": "Introduzca el nombre del fabricante",
            "Please enter Description": "Introduzca Descripción",
            "ACCOUNT": "CUENTA",
            "DEPARTMENT": "DEPARTAMENTO",
            "Client Attributes": "Atributos de cliente",
            "No client attributes available": "No hay atributos de cliente disponibles",
            "Item PO Price": "Precio del artículo PO",
            "Item PO Ext Price": "Artículo PO Precio extendido",
            "QTY Open PO": "Cantidad Open PO",
            "Item USD Price": "Artículo USD Precio",
            "Ext. USD Price": "Precio extendido en USD",
            "Next Approver is not active, So the order cannot be approved": "Next Approver no está activo, por lo que el pedido no se puede aprobar",
            "My Favorite Item": "Mi artículo favorito",
            "Selected orders are not approved": "Los pedidos seleccionados no son aprobados",
            "Problem encountered in approval update - please contact Help Desk.": "Problema encontrado en la actualización de la aprobación: póngase en contacto con el servicio de asistencia.",
            "No database connection.": "Sin conexión de base de datos.",
            "There was an error declining the order.A report has been logged with Support.": "Hubo un error al rechazar el pedido. Se ha registrado un informe con Soporte técnico.",
            "Encountered an error trying to approve selected order(s). Please review each order's messages and correct or unselect order with error and try to run approval again.": "Se ha producido un error al intentar aprobar los pedidos seleccionados. Revise los mensajes de cada pedido y corrija o anule la selección del pedido con error e intente ejecutar la aprobación nuevamente.",
            "No order(s) were selected. Please select order(s) to approve.": "No se seleccionaron pedidos. Seleccione el pedido o los pedidos que desea aprobar.",
            "Missing Business Unit and/or Order Number.  Please call SDI Helpdesk for assistance.": "Falta unidad de negocio y/o número de pedido.  Llame al servicio de asistencia de SDI para obtener ayuda.",
            "PO Order Total": "Total de pedidos de po",
            "*Order Charge Code": "*Código de cargo de pedido",
            "Archived Notification": "Notificación Archivada",
            "Your Request Id": "Su ID de solicitud",
            "Error in submitting the PO": "Error al enviar la orden de compra",
            "Charge code for user": "Código de carga para el usuario",
            "Address": "Dirección",
            "*Address": "*Dirección",
            "Please enter a valid address": "Introduce una dirección válida",
            "Valid address": "Dirección válida",
            "Items have to be from the same supplier for pickup to be enabled": "Los artículos deben ser del mismo proveedor para que se habilite la recolección",
            "Uncheck pickup to add address": "Desmarque el retiro para agregar la dirección",
            "Unselect ship to to add address": "Anule la selección de enviar a para agregar una dirección",
            "Select Supplier Location for pickup": "Seleccione la ubicación del proveedor para la recogida",
            "Pickup Store": "Tienda de recogida",
            "Partially available": "Parcialmente disponible",
            "Contact branch": "Sucursal de contacto",
            "Alert": "Alerta",
            "Lowe's items cannot be mixed with other items": "Los artículos de Lowe's no se pueden mezclar con otros artículos",
            "Please select the pickup store": "Seleccione la tienda de recogida",
            "Please Enter a valid Zip Code": "Por favor ingrese un código postal válido",
            "Error in processing zip code": "Error al procesar código postal",
            "Please Enter a Zip Code": "Por favor introduzca un codigo postal",
            "Enter Zip Code": "Ingresa tu código postal",
            "Expected Delivery Date": "Fecha de Entrega Estimada",
            "Pick up is default for Home depot and Lowe's items": "La recogida es predeterminada para los artículos de Home Depot y Lowe's",
            "Home depot items cannot be mixed with other items": "Los artículos de Home Depot no se pueden mezclar con otros artículos.",
            "Home depot and Lowe's items cannot be mixed with other items": "Los artículos de Home Depot y Lowe's no se pueden mezclar con otros artículos",
            "Supplier Call-In": "Llamada de proveedores",
            "Vendor ID": "ID de proveedor",
            "Currently Vendors are Not Available": "Actualmente los proveedores no están disponibles",
            "Address not available for the selected Vendor": "Dirección no disponible para el proveedor seleccionado",
            "*Please allow the location for displaying miles": "*Por favor, permita la ubicación para mostrar millas",
            "Phone Number": "Número de teléfono",
            "Phone Number is not available": "El número de teléfono no está disponible",
            "Asset ID": "ID de activo",
            "Enter Asset ID": "Introduzca el ID de activo",
            "The items in the cart will be removed by changing the WO": "Los artículos del carrito se eliminarán cambiando la OT",
            "Issue in clearing the cart": "Problema al vaciar el carrito",
            "Please enter work order number": "Ingrese el número de orden de trabajo",
            "Ships By": "Barcos por",
            "Unable to add Grainger item using your current work order number": "No se puede agregar el artículo de Grainger usando su número de orden de trabajo actual",
            "Please enter valid work order number": "Ingrese un número de orden de trabajo válido",
            "Unable to submit Grainger item using your current work order number": "No se puede enviar el artículo de Grainger usando su número de orden de trabajo actual",
            "Contact Us": "Contáctenos",
            "Unable to submit Diversified supplier item using your current work order number": "No se puede enviar un artículo de proveedor diversificado usando su número de orden de trabajo actual",
            "WO Description": "Descripción de la orden de trabajo",
            "Please select an item to share": "Seleccione un elemento para compartir",
            "Please select a user to share": "Por favor selecciona un usuario para compartir",
            "Users are not available to share": "Los usuarios no están disponibles para compartir",
            "Sharing failed. Please try again later": "Compartir falló. Por favor, inténtelo de nuevo más tarde",
            "Shared successfully": "Compartido con éxito",
            "Invalid email address": "Dirección de correo electrónico no válida",
            "Invalid work order": "Orden de trabajo no válida",
            "Error occured in work order validation": "Error en la validación de la orden de trabajo",
            "Internal error": "Error interno",
            "Active Users - SDI/Customers": "Usuarios activos - SDI/Clientes",
            "User Name": "Nombre de usuario",
            "Role Type": "Tipo de rol",
            "Role Name": "Nombre del rol",
            "Default Putaway Locations": "Ubicaciones predeterminadas de Putaway",
            "Item ID": "ID del artículo",
            "Crib ID": "ID de cuna",
            "Type": "Tipo",
            "Area": "Área",
            "Aisle": "Pasillo",
            "Row": "Fila",
            "Shelf": "Estante",
            "Bin": "Bote",
            "Action": "Acción",
            "Default putaway location inserted successfully!": "¡La ubicación predeterminada de putaway se insertó correctamente!",
            "Default putaway location updated successfully!": "¡La ubicación predeterminada de putaway se actualizó correctamente!",
            "Default putaway location not inserted": "Ubicación de colocación predeterminada no insertada",
            "Default putaway location not updated": "Ubicación predeterminada de putaway no actualizada",
            "Please select all the fields!": "¡Por favor, seleccione todos los campos!",
            "Default Putaway Location does not exist for this Item. Do u want to add new location?": "La ubicación predeterminada de Putaway no existe para este Item.Do desea agregar una nueva ubicación?",
            "Something went wrong please try again": "Algo salió mal, inténtalo de nuevo",
            "The Item ID is invalid": "El identificador del artículo no es válido",
            "No record found": "No se encontró ningún registro",
            "Please enter Item ID": "Introduce el ID del artículo",
            "Add New Location": "Agregar nueva ubicación",
            "Default pickup": "Recogida predeterminada",
            "Budgetary Approval": "Aprobación presupuestaria",
            "Alternate Requestor Approval": "Aprobación alternativa del solicitante",
            "Alternate Budgetary Approval": "Aprobación presupuestaria alternativa",
            "Alternate Budgetary Approval by": "Aprobación presupuestaria alternativa por",
            "No alternate budgetary approver found": "No se encontró ningún aprobador presupuestario alternativo",
            "No alternate requestor approver found": "No se encontró ningún aprobador de solicitante alternativa",
            "Please select line items to approve": "Seleccione las líneas de pedido que desea aprobar",
            "Please select line items to decline": "Seleccione las líneas de pedido que desea rechazar",
            "Required by date": "Requerido por fecha",
            "Delivery Point": "Punto de entrega",
            "Your order has been approved successfully": "Su pedido ha sido aprobado con éxito",
            "Your order has been declined successfully": "Su pedido ha sido rechazado con éxito",
            "Selected items have been approved successfully": "Los artículos seleccionados se han aprobado correctamente",
            "Selected items have been declined successfully": "Los elementos seleccionados se han rechazado correctamente",
            "Please fill all the required fields": "Por favor, rellene todos los campos obligatorios",
            "Please enter a valid email address": "Introduzca una dirección de correo electrónico válida",
            "Please enter a valid phone number": "Por favor, introduzca el número de teléfono",
            "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!": "La contraseña debe contener al menos seis caracteres con 1 número y 1 letra, ¡debe comenzar con una letra!",
            "Error --- Supplied e-mail address is incorrect": "Error --- La dirección de correo electrónico proporcionada es incorrecta",
            "Start date": "Fecha de inicio",
            "To date": "Hasta la fecha",
            "Criteria": "Criterios",
            "Contains any": "Contiene cualquiera",
            "Contains all": "Contiene todo",
            "Order no": "Nº de pedido",
            "Order ln": "Orden ln",
            "Ship date": "Fecha de envío",
            "Product ID": "ID del producto",
            "Reason CD": "Razón CD",
            "Qty returned": "Cantidad devuelta",
            "Qty remaining": "Cantidad restante",
            "UOM": "UOM",
            "Unit price": "Precio unitario",
            "Charge code": "Código de cargo",
            "Work order no": "Orden de trabajo n.º",
            "Machine no": "Máquina no",
            "Return to bu": "Volver a bu",
            "Return type": "Tipo de valor devuelto",
            "Serial out": "Salida de serie",
            "Serial in": "Entrada en serie",
            "Ship ID": "Identificación del buque",
            "Enter criteria": "Introducir criterios",
            "Business unit": "Unidad de negocio",
            "Enter quantity": "Introduzca la cantidad",
            "Return To Inventory": "Volver al inventario",
            "Return to Inventory is the page where purchased products are returned": "Devolver al inventario es la página donde se devuelven los productos comprados",
            "Please select the date range": "Seleccione el intervalo de fechas",
            "Please enter the criteria based on searchby": "Por favor, introduzca los criterios basados en la búsqueda",
            "Please select the search by": "Por favor, seleccione la búsqueda por",
            "Please select the employee ID": "Seleccione el ID de empleado",
            "Please enter returned qty for at least one order to save": "Ingrese la cantidad devuelta para al menos un pedido para guardar",
            "Qty returned cannot be greater than remaining qty": "La cantidad devuelta no puede ser mayor que la cantidad restante",
            "Qty cannot be greater than remaining qty": "La cantidad no puede ser mayor que la cantidad restante",
            "Technical issue please contact SDI help desk": "Problema técnico, póngase en contacto con el servicio de asistencia de SDI",
            "Role Master": "Maestro de roles",
            "Role ID": "ID de rol",
            "Landing page": "Página de destino",
            "Add role": "Agregar rol",
            "Copy role": "Función de copia",
            "Add New Role": "Agregar nuevo rol",
            "Apply to all sister sites": "Aplicar a todos los sitios hermanos",
            "Programs": "Programas",
            "save": "salvar",
            "cancel": "Cancelar",
            "New role has been created successfully!": "¡Se ha creado un nuevo rol con éxito!",
            "ok": "De acuerdo",
            "Role details updated successfully!": "¡Los detalles del rol se actualizaron correctamente!",
            "Confirm": "Confirmar",
            "no": "no",
            "yes": "Sí",
            "Are you sure you want to delete this role?": "¿Está seguro de que desea eliminar este rol?",
            "This role cannot be deleted as it is applied to users.": "Este rol no se puede eliminar, ya que se aplica a los usuarios.",
            "Expand all": "Expandir todo",
            "Collapse all": "Contraer todo",
            "Select all": "Seleccionar todo",
            "Deselect all": "Anular la selección de todo",
            "Role name already exist!": "¡El nombre del rol ya existe!",
            "Role list": "Lista de roles",
            "Role name": "Nombre del rol",
            "User type": "Tipo de usuario",
            "Select atleast one program": "Seleccione al menos un programa",
            "Copy Role": "Rol de copia",
            "Add role": "Agregar rol",
            "Enter role name": "Introduzca el nombre de la función",
            "Role has been deleted successfully!": "¡El rol se ha eliminado correctamente!",
            "Roles list": "Lista de roles",
            "Advance Shipping Notice": "Aviso de envío anticipado",
            "Ready for Picking": "Listo para picking",
            "Product Replenishment": "Reposición de productos",
            "Item ID": "ID del artículo",
            "Enter item ID": "Introduzca el ID del artículo",
            "Please enter the item ID": "Por favor, introduzca el ID del artículo",
            "Please enter valid item ID": "Introduzca un ID de artículo válido",
            "Cart Items": "Artículos de la cesta",
            "Rush order": "Pedido urgente",
            "Cancel order": "Cancelar pedido",
            "Add to cart": "Añadir a la cesta",
            "Please enter item quantity": "Por favor, introduzca la cantidad del artículo",
            "Business unit": "Unidad de negocio",
            "Place order": "Realizar pedido",
            "Select business unit": "Seleccionar unidad de negocio",
            "Item quantity": "Cantidad de objetos",
            "Order placed successfully!": "¡Pedido realizado con éxito!",
            "Technical issue please contact SDI help desk": "Problema técnico, póngase en contacto con el servicio de asistencia de SDI",
            "Item ID or QR": "ID del artículo o QR",
            "Include zero quantity locations": "Incluir ubicaciones de cantidad cero",
            "Enter item ID or scan QR": "Ingrese el ID del artículo o escanee QR",
            "Manual Stock Adjustment": "Ajuste manual de existencias",
            "Please enter the item ID": "Por favor, introduzca el ID del artículo",
            "Please enter the valid item ID": "Por favor, introduzca el ID de artículo válido",
            "Item is not available in crib": "El artículo no está disponible en la cuna",
            "Item details": "Detalles del artículo",
            "Description": "Descripción",
            "BU": "BU",
            "Qty available": "Cantidad disponible",
            "Serial ID": "ID de serie",
            "Edit adjustment": "Ajuste de edición",
            "Adjustment type": "Tipo de ajuste",
            "Adjust quantity": "Ajustar la cantidad",
            "Stock adjustment updated successfully!": "¡Ajuste de stock actualizado con éxito!",
            "Stock adjustment updation failed!": "¡Error en la actualización del ajuste de existencias!",
            "Please select adjustment type": "Seleccione el tipo de ajuste",
            "Please remove special characters": "Por favor, elimine el carácter especial",
            "Please select reason": "Por favor, seleccione el motivo",
            "Adjustment qty should not be greater than available qty": "La cantidad de ajuste no debe ser mayor que la cantidad disponible",
            "Unit cost": "Coste unitario",
            "OK": "De acuerdo",
            "Please allow your browser to access your webcam": "Permita que su navegador acceda a su cámara web",
            "QR scan": "Escaneo QR",
            "Item with zero quantity is only available in the crib": "El artículo con cantidad cero solo está disponible en la cuna",
            "Express Putaway": "Almacenamiento exprés",
            "Enter cost": "Introduzca el costo",
            "Work order#": "Orden de trabajo#",
            "Enter work order#": "Ingrese la orden de trabajo#",
            "Location details": "Detalles de la ubicación",
            "Item ordered successfully!": "Artículo pedido con éxito!",
            "Item ordered and default location updated successfully!": "¡El artículo pedido y la ubicación predeterminada se actualizaron con éxito!",
            "Please enter cost": "Por favor, introduzca el coste",
            "Please select area": "Por favor, seleccione el área",
            "Please select aisle": "Por favor, seleccione el pasillo",
            "Please select row": "Por favor, seleccione la fila",
            "Please select shelf": "Por favor, seleccione la estantería",
            "Please select bin": "Por favor, seleccione la papelera",
            "Please enter valid work order#": "Ingrese una orden de trabajo válida#",
            "Make as default location": "Hacer como ubicación predeterminada",
            "Please select business unit": "Por favor, seleccione la unidad de negocio",
            "Material Storage Locations": "Ubicación de almacenamiento de materiales",
            "Area": "Área",
            "Please select area": "Por favor, seleccione el área",
            "Storage details": "Detalles de almacenamiento",
            "Location type": "Tipo de ubicación",
            "Enter value": "Introduzca el valor",
            "Please enter aisle": "Por favor, entre en el pasillo",
            "Please enter row": "Por favor, introduzca la fila",
            "Please enter shelf": "Por favor, introduzca la estantería",
            "Please enter bin": "Por favor, introduzca bin",
            "New Location": "Nueva ubicación",
            "New location added successfully!": "Nueva ubicación agregada con éxito!",
            "Location updated successfully!": "Ubicación actualizada correctamente!",
            "New location cannot be added!": "Un nouvel emplacement ne peut pas être ajouté!",
            "Location already exists!": "La ubicación ya existe!",
            "Location cannot be updated!": "La ubicación no se puede actualizar!",
            "The category and subcategory cannot be identical": "La categoría y la subcategoría no pueden compartir el mismo nombre",
            "The category alias and subcategory alias cannot be identical": "El alias de categoría y el alias de subcategoría no pueden compartir el mismo nombre",
            //Zeus 243 Program master (Spanish transaltion)[Vishalini]]
            "Program Master": "Programa Máster",
            "Identifier": "Identificador",
            "Program name": "Nombre del programa",
            "Access group": "Grupo de acceso",
            "New": "Nuevo",
            "Program": "Programa",
            "Enter program name": "Introduzca el nombre del programa",
            "Program name alias": "Nombre del programa alias",
            "Enter program name alias": "Introduzca el alias del nombre del programa",
            "Enter category": "Entrar en categoría",
            "Category alias": "Alias de categoría",
            "Enter category alias": "Introduzca el alias de categoría",
            "Subcategory": "Subcategoría",
            "Enter subcategory": "Entrar en la subcategoría",
            "Subcategory alias": "Alias de subcategoría",
            "Enter subcategory alias": "Introduzca el alias de la subcategoría",
            "Navigation URL": "URL de navegación",
            "Enter navigation URL": "Introduzca la URL de navegación",
            "Active": "Activo",
            "Apply to site": "Aplicar al sitio",
            "Zeus 2.0": "Zeus 2.0",
            "Walmart": "Walmart",
            "Zeus ordering": "Zeus ordering",
            "IPM": "IPM",
            "Access group": "Grupo de acceso",
            "SDI": "SDI",
            "Explicit security": "Seguridad explícita",
            "Query string parameters": "Parámetros de cadena de consulta",
            "Enter query string": "Escriba la cadena de consulta",
            "Enter # if program should not display in menu": "Ingrese # si el programa no debe mostrarse en el menú",
            "New program created successfully!": "¡Nuevo programa creado con éxito!",
            "Please enter program name": "Ingrese el nombre del programa",
            "Please enter program name alias": "Introduzca el alias del nombre del programa",
            "Please enter category": "Por favor, introduzca la categoría",
            "Please enter category alias": "Por favor, introduzca el alias de la categoría",
            "Please enter subcategory": "Por favor, introduzca la subcategoría",
            "Please enter subcategory alias": "Por favor, introduzca el alias de la subcategoría",
            "Program updated successfully": "Programa actualizado con éxito",
            "Do you want to remove this program?": "¿Quieres eliminar este programa?",
            "New program is not inserted successfully!": "El nuevo programa no se inserta correctamente!",
            "Program is not updated successfully!": "¡El programa no se ha actualizado correctamente!",
            "Program is not deleted successfully!": "¡El programa no se ha eliminado correctamente!",
            "Program name already exists!": "¡El nombre del programa ya existe!",
            "Number of records": "Número de registros",
            "Enter query string parameters": "Ingrese los parámetros de cadena de consulta",
            "Please enter a unique program name alias": "Ingrese un alias único para el nombre del programa",
            "Please enter a unique category name alias": "Ingrese un alias único para el nombre de la categoría",
            "Category name alias already exist": "El alias del nombre de categoría ya existe",
            "Please enter a unique subcategory name alias": "Ingrese un alias único para el nombre de la subcategoría",
            "Subcategory name alias already exist": "El alias del nombre de subcategoría ya existe",
            "Category name already exists in subcategory": "El nombre de la categoría ya existe en la subcategoría",
            "Subcategory already exists": "La subcategoría ya existe",
            "Alias name already exist": "El nombre del alias ya existe",
            "The category and subcategory cannot be identical": "La categoría y la subcategoría no pueden compartir el mismo nombre",
            "The category alias and subcategory alias cannot be identical": "El alias de categoría y el alias de subcategoría no pueden compartir el mismo nombre",
            //Translation for Stock Issue Cart-Niranjana,6/12/24
            "Enter work order": "Introducir orden de trabajo",
            "Enter asset ID": "Introduzca el ID del recurso",
            "* Required field - work order# or charge code is required": "* Campo obligatorio: se requiere una orden de trabajo# o un código de cargo",
            "+ New item": " Artículo nuevo",
            "Cart items": "Artículos de la cesta",
            "MFG": "MFG",
            "MFG part number": "Número de pieza MFG",
            "Your cart is empty !": "¡Tu carrito está vacío!",
            "BackOrder": "Pedido pendiente",
            "Qty": "Qty",
            "Add new item to cart": "Añadir un nuevo artículo a la cesta",
            "Enter item ID": "Introduzca el ID del artículo",
            "type": "tipo",
            "Please enter item ID": "Introduzca el ID del artículo",
            "Enabling this option indicates it as a backorder": "Al habilitar esta opción, se indica que se trata de un pedido pendiente",
            "Available qty": "Cantidad disponible",
            "MFG part#": "Pieza MFG # ",
            "No data found": "No se han encontrado datos",
            "Stock Issues Cart": "Carrito de Emisiones de Acciones",
            "Customer notes": "Notas de los clientes",
            "Order charge code": "Código de cargo del pedido",
            "ID": "IDENTIFICACIÓN :",
            "The item is already in the cart": "El artículo ya está en el carrito",
            "You have successfully placed the order!": "¡Ha realizado el pedido con éxito!",
            "  Remove": "  Eliminar",
            "Please enter qty for the item": "Por favor, introduzca la cantidad para el artículo",
            "Enabling this option indicates it as a backorder.": "Al habilitar esta opción, se indica que se trata de un pedido pendiente",
            "Please enter work order number or charge code": "Ingrese el número de orden de trabajo o el código de cargo",
            "Please select the employee ID": "Please select the employee ID",
            "Please select the employee ID": "Seleccione el ID de empleado",
            "MFG part number": "Número de pieza MFG",
            //Zeus 82 Picking Assign to,6/20/24
            "Picking Assign To": "Selección Asignar a",
            "Export to excel": "Exportar a Excel",
            "Total items": "Total de artículos",
            "Work order": "Orden de trabajo",
            "Order date": "Fecha del pedido",
            "Assign to": "Asignar a",
            "Reserved qty": "Cantidad reservada",
            "Customer notes": "Notas de los clientes",
            "Qty requested": "Cantidad solicitada",
            "Supplier details": "Detalles del proveedor",
            "Address": "Dirección",
            "Distance": "Distancia",
            "Qty available": "Cantidad disponible",
            //Zeus-360
            "Store hours": "Horario de la tienda",
            "Driving direction": "Dirección de conducción",
            "Add for delivery": "Añadir para la entrega",
            "Real Time Inventory": "Inventario en tiempo real",
            "Agility": "Agilidad",
            "One SDI": "Una IDE",
            "Operational Excellence": "Excelencia operative",
            // Zeus 349
            "Required By Date": "Requerido Por fecha"
        }
    }
};

i18n

    .use(initReactI18next)
    .init({

        resources,
        //[10/18/21, PC_06, Bug ID - 1736, Dhevanesam R]
        fallbackLng: CookieService.getLocalStorageValues("newlanguage") != null && CookieService.getLocalStorageValues("newlanguage") != undefined && CookieService.getLocalStorageValues("newlanguage") != false ? CookieService.getLocalStorageValues("newlanguage").value : "en",

        //interpolation: {
        //    escapeValue: false // react already safes from xss
        //}
    })

export default i18n;