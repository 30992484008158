/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Sushmitha M
 * Crated Date: 08/21/2020
 * 
 * Ref: UP_PC_144
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class ProfileStore extends EventEmitter {
    UserDetailOBO = "";
    SelectUserBO = "";
    RadioUserTypeBO = "";
    VendorIdCheckBO = "";
    MultiSiteBO = "";
    AddUserBO = "";
    TabClickBO = "";
    PrivilegesBO = "";
    PrivilegeTypeBO = "";
    PrivilegeRoleBO = "";
    UpdatePrivilegeBO = "";
    LoadApprovalsBO = "";
    UpdateApprovalsBO = "";
    LoadReqApprovalsBO = "";
    UpdateReqApprovalsBO = "";
    LoadBudApprovalsBO = "";
    LaodOSEBO = "";
    UpdateOSEBO = "";
    LoadPrefBO = "";
    UpdatePrefBO = "";
    LoadTrackBO = "";
    SelecttrackBO = "";
    AddTrackBO = "";
    LoadGribBO = "";
    UpdateCribBO = "";
    LoadZeusBO = "";
    UpdateZeusBO = "";
    ActivateEmpyAcctBO = "";
    DeActivateEmpyAcctBO = "";
    DeActivateSiteAcctBO = "";
    ActivateSiteAcctBO = "";
    LoadThirdParty = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    currentFavPageResponse = "";
    GetchargeCOdeBO = "";
    LoadAltBudApprovalsBO = "";
    UpdateAltBudApprovalsBO = "";
     /**
    * Ref: UP_PC_145
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleProfileStore = (action) => {
        switch (action.action.type) {
            case "UserDetail": {
                this.UserDetailOBO = action.action.response;
                this.emit('UserDetail');
                break;
            }
            case "SelectUser": {
                this.SelectUserBO = action.action.response;
                this.emit('SelectUser');
                break;
            }
            case "RadioUserType": {
                this.RadioUserTypeBO = action.action.response;
                this.emit('RadioUserType');
                break;
            }
            case "ThirdParty": {
                this.LoadThirdParty = action.action.response;
                this.emit('ThirdParty');
                break;
            }
            case "VendorIdCheck": {
                this.VendorIdCheckBO = action.action.response;
                this.emit('VendorIdCheck');
                break;
            }
            case "MultiSite": {
                this.MultiSiteBO = action.action.response;
                this.emit('MultiSite');
                break;
            }
            case "AddUser": {
                this.AddUserBO = action.action.response;
                this.emit('AddUser');
                break;
            }
            case "TabClick": {
                this.TabClickBO = action.action.response;
                this.emit('TabClick');
                break;
            }
            case "Privileges": {
                this.PrivilegesBO = action.action.response;
                this.emit('Privileges');
                break;
            }
            case "PrivilegeType": {
                this.PrivilegeTypeBO = action.action.response;
                this.emit('PrivilegeType');
                break;
            }
            case "PrivilegeRole": {
                this.PrivilegeRoleBO = action.action.response;
                this.emit('PrivilegeRole');
                break;
            }
            case "UpdatePrivilege": {
                this.UpdatePrivilegeBO = action.action.response;
                this.emit('UpdatePrivilege');
                break;
            }
            case "LoadApprovals": {
                this.LoadApprovalsBO = action.action.response;
                this.emit('LoadApprovals');
                break;
            }
            case "UpdateApprovals": {
                this.UpdateApprovalsBO = action.action.response;
                this.emit('UpdateApprovals');
                break;
            }
            case "LoadReqApprovals": {
                this.LoadReqApprovalsBO = action.action.response;
                this.emit('LoadReqApprovals');
                break;
            }
            case "UpdateReqApprovals": {
                this.UpdateReqApprovalsBO = action.action.response;
                this.emit('UpdateReqApprovals');
                break;
            }
            case "LoadBudApprovals": {
                this.LoadBudApprovalsBO = action.action.response;
                this.emit('LoadBudApprovals');
                break;
            }
            case "LoadAltBudApprovals": {
                this.LoadAltBudApprovalsBO = action.action.response;
                this.emit('LoadAltBudApprovals');
                break;
            }
            case "UpdateAltBudApprovals": {
                this.UpdateAltBudApprovalsBO = action.action.response;
                this.emit('UpdateAltBudApprovals');
                break;
            }
            case "LaodOSE": {
                this.LaodOSEBO = action.action.response;
                this.emit('LaodOSE');
                break;
            }
            case "UpdateOSE": {
                this.UpdateOSEBO = action.action.response;
                this.emit('UpdateOSE');
                break;
            }
            case "LoadPref": {
                this.LoadPrefBO = action.action.response;
                this.emit('LoadPref');
                break;
            }
            case "UpdatePref": {
                this.UpdatePrefBO = action.action.response;
                this.emit('UpdatePref');
                break;
            }
            case "LoadTrack": {
                this.LoadTrackBO = action.action.response;
                this.emit('LoadTrack');
                break;
            }
            case "Selecttrack": {
                this.SelecttrackBO = action.action.response;
                this.emit('Selecttrack');
                break;
            }
            case "AddTrack": {
                this.AddTrackBO = action.action.response;
                this.emit('AddTrack');
                break;
            }
            case "LoadGrib": {
                this.LoadGribBO = action.action.response;
                this.emit('LoadGrib');
                break;
            }
            case "UpdateCrib": {
                this.UpdateCribBO = action.action.response;
                this.emit('UpdateCrib');
                break;
            }
            case "LoadZeus": {
                this.LoadZeusBO = action.action.response;
                this.emit('LoadZeus');
                break;
            }
            case "UpdateZeus": {
                this.UpdateZeusBO = action.action.response;
                this.emit('UpdateZeus');
                break;
            }
            case "ActivateEmpyAcct": {
                this.ActivateEmpyAcctBO = action.action.response;
                this.emit('ActivateEmpyAcct');
                break;
            }
            case "DeActivateEmpyAcct": {
                this.DeActivateEmpyAcctBO = action.action.response;
                this.emit('DeActivateEmpyAcct');
                break;
            }
            case "ActivateSiteAcct": {
                this.ActivateSiteAcctBO = action.action.response;
                this.emit('ActivateSiteAcct');
                break;
            }
            case "DeActivateSiteAcct": {
                this.DeActivateSiteAcctBO = action.action.response;
                this.emit('DeActivateSiteAcct');
                break;
            }
            case "ShopCart_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "ShopCart_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "ShopCart_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "BuildChrCD": {
                this.GetchargeCOdeBO = action.action.response;
                this.emit('GetchargeCOdeBO');
                break;
            }
        }
    }

}
const objProfileStore = new ProfileStore;
Dispatcher.register(objProfileStore.handleProfileStore.bind(objProfileStore));
export default objProfileStore;