/**
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Login page component. The component displays the Login page details. 
 * 
 * App Name: SDI-Walmart
 * Author: Chandrasekar
 * Created Date: 07/30/2020 
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
/**
 * Ref: LN_PC_02
 * Importing the action and store file and set to an object.
 */

/*[7/21/22, CAS -2300, Madhu] Importing the necessary packages for Eye icon*/

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as LoginAction from '../actions/LoginAction';
import * as MenuAction from '../actions/MenuAction';
import LoginStore from '../stores/LoginStore';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import { withTranslation, Trans } from 'react-i18next';
//[5/18/2023]Zeus 107  SSO implementation - Poonima S
import { withAuth0 } from "@auth0/auth0-react";
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
import ShoppingCartStore from '../stores/ShoppingCartStore';
//[11/26/21, CAS - 31, Dhevanesam R]

let workOrder;
class LoginComponent extends Component {
    _isMounted = false;
    /**
   * Ref: LN_PC_04
   * Define the constructor that takes props as parameter
   * 
   * @param props
   */
    constructor(props) {
        super(props);
        this.userIDInput = React.createRef();
        this.handleLoginChange = this.handleLoginChange.bind(this);
        this.assignLoginData = this.assignLoginData.bind(this);
        this.loginSuccess = this.loginSuccess.bind(this);
        this.loginFailure = this.loginFailure.bind(this);
        this.loginClick = this.loginClick.bind(this);
        this.loginKeyPress = this.loginKeyPress.bind(this);
        this.SSOKeyPress = this.SSOKeyPress.bind(this)
        this.SSOLoginClick = this.SSOLoginClick.bind(this);
        this.SSOFlagCheckResponse = this.SSOFlagCheckResponse.bind(this);
        this.COStatus = this.COStatus.bind(this);
        /**
        * Ref: LN_PC_05
        * Set the initial values of the state variables
        */
        //[21/05/2023 - PS_ZE_118_APP_1 - Create a state variable, OrderStatusFlag - Johnprinto D ]
        this.state = {
            userName: "", password: "", error_description: "", showPassword: false, loginData: { isBrowserCompatable: true }, workorderNumber: "", isLogout: CookieService.getLocalStorageValues("isLogout"), isSSOClick: CookieService.getLocalStorageValues("isSSOClick"), isNotSSO: false, EmailAddres: "", ssoErrorDesc: "", OrderStatusFlag: ""
        }
        if (CookieService.getLocalStorageValues("isSSOClick") == true) {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
    }



    /**
   * Ref: LN_PC_10
   * Event listener method that gets called to handle the
   * Login failure status codes from store
   * Based on the Status codes the validation message will be displayed
   */
    loginFailure = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let loginClickData = JSON.parse(LoginStore.loginError);
        let loginErrorMsg = "";
        debugger
        if (this.state.isSSOClick == true) {
            if (loginClickData.error == "1") {
                loginErrorMsg = loginClickData.error_description;
            }
            else {
                loginErrorMsg = "Invalid email address";
            }
            CookieService.updateLocalStorageValues("SSOFirstHit", false)
            this.setState({ ssoErrorDesc: loginErrorMsg });
        }
        else{
            if (loginClickData.error == "1") {
                loginErrorMsg = loginClickData.error_description;

            } else if (loginClickData.error == "2") {
                let failureAttemptCount = CookieService.getCookieValue(this.state.userName.toLowerCase());
                if (failureAttemptCount != "") {
                    CookieService.setCookieValues(this.state.userName.toLowerCase(), 0, 1);
                }
                loginErrorMsg = loginClickData.error_description;
            } else if (loginClickData.error == "3" || loginClickData.error == "5") {
                let failureAttemptCount = CookieService.getCookieValue(this.state.userName.toLowerCase());
                if (failureAttemptCount == "") {
                    failureAttemptCount = 1;
                } else {
                    failureAttemptCount = parseInt(failureAttemptCount) + 1;
                }
                CookieService.setCookieValues(this.state.userName.toLowerCase(), failureAttemptCount, 1);
                loginErrorMsg = loginClickData.error_description;
            } else if (loginClickData.error == "4") {
                this.props.history.push({
                    pathname: `/ChangePassword`,
                    search: `?USERID=${this.state.userName}`
                });
            } else if (loginClickData.error == "invalid_grant") {
                loginErrorMsg = loginClickData.error_description;
            }
            CookieService.updateLocalStorageValues("LoginFirstHit", false)
            this.setState({ error_description: loginErrorMsg });
        }

    }
    /**
    * Ref: LN_PC_10
    * Event listener method that gets called to get the
    * Login success response from store.
    * This method will store the user data to localStorage
    */
    loginSuccess = () => {
        // $(`#${SDIConstants.SDILoader}`).addClass('hide');
        this.setState({ error_description: "" });
        let loginSuccessData = JSON.parse(LoginStore.loginSuccess);
        if (loginSuccessData.TokenUpdateStatus.length > 0) {
            let loginSuccess = (loginSuccessData.TokenUpdateStatus[0].isSuccess.toLowerCase() == "true")
            if (loginSuccess) {
                let randomId = Math.floor(Math.random() * 10000000000)
                CookieService.updateLocalStorageValues("Session_ID", randomId)
                console.log("uuid", randomId)
                console.log("Session_ID", CookieService.getLocalStorageValues("Session_ID"))
                //if (CookieService.getLocalStorageValues("Session_BUSUNIT").trim().toUpperCase()== 'I0W01') {
                //    localStorage.clear();
                //    alert('Warning!\nYou do not have permission to access the application');
                //    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                //    return;
                //}
                CookieService.updateLocalStorageValues("Session_IOLServer", this.state.loginData.Session_IOLServer);
                CookieService.updateLocalStorageValues("Session_cplusServer", this.state.loginData.Session_cplusServer);
                CookieService.updateLocalStorageValues("Session_WEBSITEID", this.state.loginData.Session_WEBSITEID);
                var blanketWoList = [];
                var blanketWoList = loginSuccessData.blanketWoList;
                if (blanketWoList != null && blanketWoList != undefined && blanketWoList != "") {
                    let blanketWoAndDescArray = [];
                    let blanketWoArray = [];
                    let workOrder = "";
                    let workOrderAndDesc = "";
                    let Description = "";
                    blanketWoList.forEach((item, index) => {
                        workOrder = item.ISA_WORK_ORDER_NO;
                        workOrderAndDesc = item.ISA_WORK_ORDER_NO;
                        Description = item.NAME1;
                        if (item.NAME1 != " ") {
                            workOrderAndDesc += " ~ " + item.NAME1;
                        }
                        blanketWoAndDescArray.push({ label: workOrderAndDesc, value: workOrder, desc: Description });
                        blanketWoArray.push(workOrder);
                    })
                    CookieService.updateLocalStorageValues("blanketWoAndDesc", blanketWoAndDescArray);
                    CookieService.updateLocalStorageValues("blanketWo", blanketWoArray);
                } else {
                    CookieService.updateLocalStorageValues("blanketWoAndDesc", []);
                    CookieService.updateLocalStorageValues("blanketWo", []);
                }

                //this.props.history.push({
                //    pathname: '/HomePage',
                //});
                CookieService.updateLocalStorageValues("isLogout", false);
                let QryOrderStatus = CookieService.getLocalStorageValues("QryOrderStatus");

                //[21 / 05 / 2023 - PS_ZE_118_APP_1 - Check the Work order is passed in the URL - Johnprinto D]
                if (this.state.OrderStatusFlag != "" && this.state.OrderStatusFlag == "Y") {
                    CookieService.updateLocalStorageValues("QryOrderStatus", this.state.OrderStatusFlag);
                    this.props.history.push({
                        pathname: `\orderstatus2`,
                        state: { origin: 'Login', WorkOrder: this.state.workorderNumber }
                    });
                }
                else if (QryOrderStatus != undefined && QryOrderStatus != "" && QryOrderStatus == "Y") {
                    this.props.history.push({
                        pathname: `\orderstatus2`,
                        state: { origin: 'Login', WorkOrder: CookieService.getLocalStorageValues("QryWONumber") }
                    });
                }
                else {
                    MenuAction.refreshMenuItems(true, this.props);
                }


            } else {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                if (this.state.isSSOClick == true) {
                    CookieService.updateLocalStorageValues("SSOFirstHit", false);
                    this.setState({ ssoErrorDesc: "Client is inactive" });
                }
                else {
                    CookieService.updateLocalStorageValues("LoginFirstHit", false);
                    this.setState({ error_description: "Client is inactive" });
                }
            }
        } else {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }

    }
    /**
     * Ref: LN_PC_11
     * Event listener method that gets called to load the
     * Login page load data from store.
     */
    assignLoginData = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let loginJSONData = JSON.parse(LoginStore.loginData).PageLoadFlags_UpdatetoSession;
        if (loginJSONData.length > 0 && this._isMounted) {
            loginJSONData = loginJSONData[0];
            loginJSONData.isBrowserCompatable = (loginJSONData.isBrowserCompatable.toLowerCase() == "true");
            //[6/13/2023, Removing test site alert for Stanford UAT, Dhevanesam R]
            //if (loginJSONData.isTestSite.toLowerCase() == "true" && this.state.isSSOClick != true) 
            //swal({
            //    allowOutsideClick: false,
            //    closeOnClickOutside: false,
            //    text: t("**Warning - this is a test web site only...\nNo activity will be recorded as production.")
            //})
            //[10/18/21, PC_05, Bug ID - 1736, Dhevanesam R]
            let language = this.props.newlanguage;
            CookieService.updateLocalStorageValues("newlanguage", language);
            console.log("logaclang2", CookieService.getLocalStorageValues("newlanguage"));
            //[05/05/23, PS_WVF_01, Zeus 86, Sathis. N]
            let queryParams = new URLSearchParams(window.location.search)
            //this.state.workorderNumber = queryParams.get('workorder');
            ////[21/05/2023 - PS_ZE_118_APP_1 - Get the orderstatus query params from URL - Johnprinto D ]
            //this.state.OrderStatusFlag = queryParams.get('orderstatus');
            let workorderNumber = queryParams.get('workorder');
            workorderNumber = workorderNumber != undefined && workorderNumber != null & workorderNumber != "" ? workorderNumber.toUpperCase() : workorderNumber;
            this.setState({ loginData: loginJSONData, workorderNumber: workorderNumber, OrderStatusFlag: queryParams.get('orderstatus') });
            console.log("workorderNumber", this.state.workorderNumber);
            console.log("WO#", queryParams.get('workorder'));
        }
    }

    /**
     * componentDidMount method will be invoked once the page is loaded and HTMls are rendered
     * This method will set the title of the page
     */
    componentDidMount() {
        this._isMounted = true;
        document.title = this.props.title;

        let isTokenPresent = CookieService.getCookieValue(SDIConstants.access_token);
        let ssoclick = this.state.isSSOClick;
        if (isTokenPresent != "") {
            workOrder = CookieService.getLocalStorageValues("URLWONumber");
            let orderstatus = CookieService.getLocalStorageValues("OrderStatusURL");

            if (orderstatus != "" && orderstatus != undefined && orderstatus == "Y") {
                if (workOrder != CookieService.getLocalStorageValues("validatedWO")) {
                    this.WoValidation();
                }
                else {
                    this.props.history.push({
                        pathname: `\orderstatus2`,
                        state: { origin: 'Login', WorkOrder: workOrder }
                    });
                }

            }
            else {
                this.props.history.push({
                    pathname: '/HomePage',
                });
            }
            LoginStore.on('ValidateWO', this.woValidateResponse);
        }
        else if (!this.state.isSSOClick) {
            sessionStorage.clear();
            localStorage.clear();

            /**
            * Ref: LN_PC_08
            * Invoke the action to get the page load data from API.
            */
            let loginPageLoadBO = {
                punchinSession: ""
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            LoginAction.pageLoad(loginPageLoadBO);
            /**
            * Ref: LN_PC_07
            * Define the listener methods
            */
            LoginStore.on('load', this.assignLoginData);
            LoginStore.on('success', this.loginSuccess);
            LoginStore.on('error', this.loginFailure);
            LoginStore.on('SSOFlagResp', this.SSOFlagCheckResponse);
            LoginStore.on('ValidateWO', this.woValidateResponse);
        }
        else {
            LoginStore.on('success', this.loginSuccess);
            LoginStore.on('error', this.loginFailure);
            LoginStore.on('SSOFlagResp', this.SSOFlagCheckResponse);
            LoginStore.on('ValidateWO', this.woValidateResponse);
        }

    }

    WoValidation = () => {

        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
            let EMP_EMAIL = CookieService.getLocalStorageValues("Session_EMP_EMAIL");
            if (EMP_EMAIL == undefined || EMP_EMAIL == null || EMP_EMAIL.trim() == "") {
                EMP_EMAIL = CookieService.getLocalStorageValues("Session_EMAIL");
            }
            let sunetID = EMP_EMAIL.split("@");
            let workOrderBO = {
                WorkOrder: workOrder,
                BusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                sunetID: sunetID[0]
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            LoginAction.workOrderValidation(workOrderBO);
        }
        else {
            CookieService.updateLocalStorageValues("validatedWO", workOrder);
            CookieService.updateLocalStorageValues("stanfordAssetId", "");
        }
    }

    //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
    woValidateResponse = () => {
        debugger
        let woValidationData = LoginStore.woValidationData;
        if (woValidationData != undefined && woValidationData != null && woValidationData != "" && woValidationData != "null") {
            if (woValidationData != "error occured in server" && woValidationData != "error occured" && woValidationData != "authorization required"
                && woValidationData != "internal server error" && woValidationData != "invalid" && woValidationData != "valid") {
                let responeData = JSON.parse(LoginStore.woValidationData);
                if (responeData[0].WOVALIDATE != null && responeData[0].WOVALIDATE != "") {
                    if (responeData[0].WOVALIDATE == "valid") {
                        if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                this.CancelOrder();
                            }
                        }
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].WONAME);
                        CookieService.updateLocalStorageValues("workorderLocation", responeData[0].LOCATION);
                        if (CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0631") {
                            CookieService.updateLocalStorageValues("validWO", workOrder);
							CookieService.updateLocalStorageValues("WOName", responeData[0].WONAME);
							let rmvndr = responeData.map(function (obj) {
								return obj.REMOVE_VNDR_ID;
							});
							CookieService.updateLocalStorageValues("RemoveVNDR", rmvndr);
                        }
                        else {
                            CookieService.updateLocalStorageValues("validWO", "");
                            CookieService.updateLocalStorageValues("WOName", "");
                        }
                        this.props.history.push({
                            pathname: `\orderstatus2`,
                            state: { origin: 'Login', WorkOrder: workOrder }
                        });

                    }

                }
                else {
                    if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                        if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                            this.CancelOrder();
                        }
                    }
                    CookieService.updateLocalStorageValues("validatedWO", workOrder);
                    CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                    CookieService.updateLocalStorageValues("stanfordAssetId", responeData[0].assetNumber);
                    CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].workOrderOpDesc);
                    CookieService.updateLocalStorageValues("workOrderDisable", true);
                    this.props.history.push({
                        pathname: `\orderstatus2`,
                        state: { origin: 'Login', WorkOrder: workOrder }
                    });
                }
            }

            else if (woValidationData == "invalid") {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("workorderLocation", "");
                this.props.history.push({
                    pathname: `\orderstatus2`,
                    state: { origin: 'Login', WorkOrder: workOrder }
                });
            }
            else {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("workorderLocation", "");
                this.props.history.push({
                    pathname: `\orderstatus2`,
                    state: { origin: 'Login', WorkOrder: workOrder }
                });
            }

        }
    }

    CancelOrder = () => {
        debugger
        let cancelorderBo = {
            Session_UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        ShoppingCartAction.CancelOrderItem(cancelorderBo);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    COStatus = () => {
        const { t } = this.props;
        if (CookieService.getLocalStorageValues("Session_UserID").toUpperCase() != "I0631") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        let CancelResponse = JSON.parse(ShoppingCartStore.CancelOrderMsge);
        if (CancelResponse == null || CancelResponse == "" || CancelResponse == " ") {
            this.refreshMenu();
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Issue in clearing the cart")
            });
        }
    }
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        MenuAction.getMasterData(masterBO);
    };


    /**
    * handleLoginChange method will be invoked on the change event of username and password text box
    * to set the values to state
    */
    handleLoginChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    //[5/18/2023]Zeus 107  SSO implementation - Poonima S
    handleZeusIDChange = (event) => {
        this.setState({ userName: event.target.value });
    }
    handleEmailIDChange = (event) => {
        this.setState({ EmailAddres: event.target.value });
    }

    /**
    * componentWillUnmount method will be invoked once the component is unloaded from the page
    */
    componentWillUnmount() {
        this._isMounted = false;
        LoginStore.removeListener('load', this.assignLoginData);
        LoginStore.removeListener('success', this.loginSuccess);
        LoginStore.removeListener('error', this.loginFailure);
        LoginStore.removeListener('SSOFlagResp', this.SSOFlagCheckResponse);
        LoginStore.removeListener('ValidateWO', this.woValidateResponse);
    }
    /**
    * Ref: LN_PC_09
    * loginClick will be invoked when login button is clicked
    * Validate the Username and password and invoke the action to validate the login credentials with Database.
    */
    loginClick = (event) => {
        debugger;
        event.preventDefault();
        CookieService.updateLocalStorageValues("isSSOClick", false);
        if (this.state.userName && this.state.password) {
            this.setState({ error_description: "" });
            let failureAttemptCount = CookieService.getCookieValue(this.state.userName.toLowerCase());
            if (failureAttemptCount == "") {
                failureAttemptCount = 1
            } else {
                failureAttemptCount = parseInt(failureAttemptCount) + 1;
            }
            let UserId = this.state.userName
            if (this.state.userName != null && this.state.userName != undefined && this.state.userName != "") {
                if (this.state.userName.length > 0) {
                    UserId = UserId.replaceAll("&", "SDISPECAILCASE#!")
                    UserId = UserId.replaceAll("+", "SDISPECAILCASENEW#!")
                }
            }
            //[05/05/23, PS_WVF_02, Zeus 86, Sathis. N]-work order validation stanford
            let workorderNumber = this.state.workorderNumber
            if (this.state.workorderNumber != null && this.state.workorderNumber != undefined && this.state.workorderNumber != "") {
                workorderNumber = this.state.workorderNumber
            }
            else {
                workorderNumber =""
            }
            let loginClickBO = {
                userName: UserId,
                password: this.state.password,
                failureAttemptCount: failureAttemptCount,
                failureAttemptUname: UserId,
                PARAM_DESTINATION_URL: "",
                Punchin: false,
                workorderNumber: workorderNumber,
                authToken: "",
                isWeb: true
            }
            console.log("loginClickBO", loginClickBO)
            debugger;
            LoginAction.loginClick(loginClickBO, this.state.loginData);
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        } else {
            CookieService.updateLocalStorageValues("LoginFirstHit", false)
            this.setState({ error_description: "Please enter required fields" });
        }
    };
    loginKeyPress(e) {
        if (e.keyCode == 13) {
            this.loginClick(e);
        }
    };
    /*[7/21/22, CAS -2300, Madhu]*/

    handleClickShowPassword = (values) => {
        this.setState({ showPassword: !this.state.showPassword })
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();

    };
    handlePaste = (e) => {
        e.preventDefault();
    };
    handleKeyDown = (e) => {
        debugger
        let val = e.key;
        if (e.key === " " || e.key === "'" || e.key === '"') {
            e.preventDefault();
        }

    };

    //[5/5/2023]Zeus 107 - SSOKeyPress() will call SSOloginClick method if the enter key is pressed after entering email ID
    SSOKeyPress(e) {
        debugger
        if (e.keyCode == 13) {
            this.SSOFlagCheck();
            //this.SSOLoginClick();
        }
    };

    //[5/5/2023]Zeus 107 - SSOLoginClick() will validate the email ID entered and calls loginwithrdirect if email is valid
    SSOLoginClick = () => {
        debugger
        const { loginWithRedirect, user, isAuthenticated, isLoading } = this.props.auth0;
        //[Zeus 251, 12/28/2023] - Skipping auth0 page and redirect to User's Social site page - Kishore
        loginWithRedirect({
            authorizationParams: {
                login_hint: this.state.EmailAddres
            }
        });
    };

    SSOFlagCheck = () => {

        let EmailId = this.state.EmailAddres;
        CookieService.updateLocalStorageValues("isSSOClick", true);
        if (this.state.EmailAddres == "") {
            CookieService.updateLocalStorageValues("SSOFirstHit", false);
            this.setState({ ssoErrorDesc: "Please enter email address" });
            return;
        }
        else {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.EmailAddres)) {
                CookieService.updateLocalStorageValues("SSOFirstHit", false);
                this.setState({ ssoErrorDesc: "Please enter valid email address" });
                return;
            }
            else {
                LoginAction.SSOLoginFlagCheck(EmailId);
            }
        }
    }

    SSOFlagCheckResponse = () => {
        debugger
        CookieService.updateLocalStorageValues("QryWONumber", this.state.workorderNumber);
        CookieService.updateLocalStorageValues("QryOrderStatus", this.state.OrderStatusFlag);
        let isSSOFlagResp = LoginStore.SSOFlagResp;
        if (isSSOFlagResp == "Normal Login") {
            CookieService.updateLocalStorageValues("isSSONormalLogin", true);
            this.setState({ isNotSSO: true, userName: this.state.EmailAddres });
        }
        else if (isSSOFlagResp == "Invalid Email") {
            CookieService.updateLocalStorageValues("SSOFirstHit", false);
            this.setState({ ssoErrorDesc: "Invalid email address" });
        }
        else if (isSSOFlagResp == "Internal Error") {
            CookieService.updateLocalStorageValues("SSOFirstHit", false);
            this.setState({ ssoErrorDesc: "Internal error" });
        }
        else if (isSSOFlagResp == "SSO Login") {
            this.SSOLoginClick();
        }

    }
    //[5/5/2023]Zeus 107 - Auth0Login() will be hit when the Auth0 authentication is success and calls the loginClick action method
    Auth0Login = (IDToken) => {
        debugger
        console.log("Auth0IDtoken", IDToken)
        let failureAttemptCount = CookieService.getCookieValue(this.state.EmailAddres.toLowerCase());
        if (failureAttemptCount == "") {
            failureAttemptCount = 1
        } else {
            failureAttemptCount = parseInt(failureAttemptCount) + 1;
        }

        let qryWO = CookieService.getLocalStorageValues("QryWONumber");
        //[6/16/2023, Zeus 88] - Poornima - Invalid WO Popup display issue fix
        if (qryWO == null || qryWO == undefined) {
            qryWO = ""
        }

        let loginClickBO = {
            userName: "",
            password: "",
            failureAttemptCount: failureAttemptCount,
            failureAttemptUname: "",
            PARAM_DESTINATION_URL: "",
            Punchin: false,
            workorderNumber: qryWO,
            authToken: IDToken,
            isWeb: true
        }
        console.log("renderSSO", IDToken)
        LoginAction.loginClick(loginClickBO, this.state.loginData);
    }

    async getIDToken(AuthProps) {
        debugger
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        console.log("tokenb4auth", CookieService.getLocalStorageValues("IDTokenAuth0"));
        await AuthProps.getIdTokenClaims().then(token => {
            if (token.__raw != undefined) {
                CookieService.updateLocalStorageValues("IDTokenAuth0", token.__raw);
            }
        });

        let IDToken = CookieService.getLocalStorageValues("IDTokenAuth0");
        console.log("IDToken", CookieService.getLocalStorageValues("IDTokenAuth0"));
        let isIDTokenAvailable = false
        if (IDToken != null && IDToken != undefined && IDToken != "") {
            isIDTokenAvailable = true
        }
        if (AuthProps.isAuthenticated == true && isIDTokenAvailable == true && this.state.isLogout != true) {
            this.Auth0Login(IDToken);
        }
        return IDToken;
    }


    render() {
        const { t } = this.props;
        debugger
        if (this.state.isSSOClick) {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }

        //[12/20/2022]Z-88 Declaring Auth0 functionalities to access Auth0 when user  does SSO
        const { isAuthenticated, getIdTokenClaims } = this.props.auth0;
        let ssoErrorMsg = "";
        let nrmErrorMsg = "";

        let normalLogin = CookieService.getLocalStorageValues("ShowNormalLogin");
        if (this.props.showNormalLogin == true) {
            CookieService.updateLocalStorageValues("ShowNormalLogin", false);
        }

        let ShowNormalLogin = false
        if (normalLogin == true || this.state.isNotSSO == true || this.props.logoClickCount == 3 || this.props.showNormalLogin == true) {
            ShowNormalLogin = true
        }
        if (this.state.isNotSSO == true && normalLogin == false) {
            ShowNormalLogin = false
        }

        if (CookieService.getLocalStorageValues("SSOFirstHit") == true) {
            ssoErrorMsg = "";
        }
        else {
            ssoErrorMsg = this.state.ssoErrorDesc;
        }

        if (CookieService.getLocalStorageValues("LoginFirstHit") == true) {
            nrmErrorMsg = "";
        }
        else {
            nrmErrorMsg = this.state.error_description;
        }

        let isLoginSuccess = CookieService.getLocalStorageValues("isLoginSuccess");
        console.log("isLoginSuccess", isLoginSuccess);
        if (isAuthenticated == true && isLoginSuccess != true && this.state.isLogout != true && this.props.showNormalLogin != true && this.state.isSSOClick == true) {
            //[12/20/2022]Z-88 getIdTokenClaim() will get ID token from Auth0 SDK if the user logged in with SSO is valid
            let token = this.getIDToken(this.props.auth0);
        }

        return (this.state.loginData.isBrowserCompatable == true ? ShowNormalLogin != true ? <React.Fragment>
            <p className="text-white mt-4 mb-3 font-24 font-thin text-center"><Trans>Log in to your account</Trans></p>
            <div className="user-id position-relative">
                <input type="text" id="EmailId" name="EmailAddres" value={this.state.EmailAddres} maxLength="60" className="form-control font-12 login-control custom-margin pb-1 txtBox-Border" placeholder={t("Email Address")} onKeyDown={this.SSOKeyPress} onChange={this.handleEmailIDChange} />
            </div>

            <span className="float-left err-msg text-danger mb-5">{t(ssoErrorMsg)}</span>
            <div className="col-12 p-0 float-left">
                <a  id="loginSSO" className="btn btn-primary custom-btn-primary col-12 text-center login-btn" onClick={() => this.SSOFlagCheck()}><Trans>Continue with SDI</Trans></a>
            </div>

        </React.Fragment> : <React.Fragment>
                <p className="text-white mt-4 mb-3 font-24 font-thin">{t("Log in to your account")}</p>
                {/*[3/20/2023, PS_CAS_47_01, Dhevanesam R*/}
                <div className="user-id position-relative"><input type="text" name="userName" value={this.state.userName} maxLength="30" className="form-control font-12 login-control custom-margin" placeholder={t("Zeus ID")} onKeyDown={this.loginKeyPress} onChange={this.handleZeusIDChange} />
                </div>
                <div className="Password position-relative">
                    <Input type={this.state.showPassword ? "text" : "password"} style={{ color: "white" }} name="password" id="password" value={this.state.userName} onPaste={this.handlePaste} className="form-control font-12 login-control mb-5  txtBox-Border" placeholder="Password" maxLength="128" value={this.state.password} onKeyDown={this.loginKeyPress} onChange={this.handleLoginChange}
                        endAdornment={
                            <InputAdornment position="end" >
                                <IconButton
                                    style={{ color: "#707070" }}
                                    onClick={(e) => this.handleClickShowPassword(e)}
                                >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        } />
                </div>
                <span className="float-left err-msg text-danger mb-5">{t(nrmErrorMsg)}</span>
                <div className="col-12 p-0 float-left">
                    <a href="#" className="btn btn-primary custom-btn-primary col-12 text-center login-btn" onClick={this.loginClick}>{t("Login")}</a>
                </div>
                <Link to={`/forgotpassword?userID=${this.state.userName}`} className="mt-4 forgot-pw col-12 float-left p-0" >{t("Forgot Password ?")}</Link>
            </React.Fragment> : null);
        if (this.state.isSSOClick) {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
    };
};
export default withAuth0(LoginComponent);