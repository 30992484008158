import React from 'react';
import {
	Document,
	Page,
	Text,
	Image,
	View,
	Font,
	StyleSheet,
	Svg,
	Polygon
} from '@react-pdf/renderer';

const CoopFormPDFComponent = props => {
	const { clientName, industry,
		primaryName, primaryTitle, primaryEmail, primaryPhoneNo, executiveName, executiveTitle,
		executiveEmail, executivePhoneNo, accPayableName, accPayableTitle, accPayableEmail,
		accPayablePhoneNo,
		// taxExempt,W9,companyRevenue,estMRO,
		optField,
		billToStreet,
		billToApartmentNo,
		billToCity,
		billToState,
		billToPostal,
		billToCountry,
		shipToAddress } = props;
	const styles = StyleSheet.create({
		headertitle: {
			margin: 20,
			fontSize: 20,
			textAlign: 'center',
		},
		title: {
			margin: 10,
			fontSize: 10,
			textAlign: 'center',
		},
		subtitle: {
			textAlign: 'center',
			textDecoration: 'underline',
			text: 'bold',
			marginTop: 40,
			fontSize: 15
		},
		body: {
			margin: 20,
			marginTop: 30,
			flexGrow: 1,
		},
		row: {
			flexDirection: 'row',
			margin: 2,
		},
		text: {
			display: 'inline',
			textDecoration: 'underline',
		},
		content: {
			width: '75%',
			marginTop: 10,
			display: 'inline',
			fontSize: 15,
			textDecoration: 'underline red 3px',
			fontWeight: 'bold'
		},
		label: {
			display: 'inline',
			fontSize: 15,
			marginTop: 10,
			textDecoration: 'underline red 3px',
			fontWeight: 'semibold',
			width: '25%'
		},
		innertext: {
			width: '75%',
			marginTop: 20,
			marginBottom: 10,
			display: 'inline',
			fontSize: 15,
			textDecoration: 'underline',
			fontWeight: 'bold'
		}
	});
	const bindAddress = shipToAddress.map(function (value, index) {
		console.log("JD value", value)
		return (<><View style={styles.row}>
			<Text style={styles.innertext}>Address{index + 1}</Text>
		</View>
			<View style={styles.row}>
				<Text style={styles.label}>Street</Text>
				<Text style={styles.content}>: {value.shipToStreet}</Text>
			</View>
			<View style={styles.row}>
				<Text style={styles.label}>Apartment No</Text>
				<Text style={styles.content}>: {value.shipToApartmentNo}</Text>
			</View>
			<View style={styles.row}>
				<Text style={styles.label}>City</Text>
				<Text style={styles.content}>: {value.shipToCity}</Text>
			</View>
			<View style={styles.row}>
				<Text style={styles.label}>State</Text>
				<Text style={styles.content}>: {value.shipToState.value}</Text>
			</View>
			<View style={styles.row}>
				<Text style={styles.label}>Zip code</Text>
				<Text style={styles.content}>: {value.shipToPostal}</Text>
			</View>
			<View style={styles.row}>
				<Text style={styles.label}>Country</Text>
				<Text style={styles.content}>: {value.shipToCountry.value}</Text>
			</View>
			{(index + 1) % 3 == 0 && (<><Text break></Text>
				<Text style={styles.innertext}></Text></>)}
		</>)
	})

	const bindOpt = optField.map(function (value, index) {
		console.log("JD value", value)
		return (<View style={styles.row}>
			<Text style={styles.label}>{value.label} </Text>
			<Text style={styles.content}>:  {value.value}</Text>
		</View>)
	})

	return (
		<Document>
			<Page size="A4" wrap>
				<Text style={styles.headertitle}>Client Information Sheet</Text>
				<Text style={styles.title}>This purpose of this form is to collect base level information to execute Letters of Participation.</Text>
				<View style={styles.body}>
					<View style={styles.row}>
						<Text style={styles.label}>Client Name </Text>
						<Text style={styles.content}>:  {clientName}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Industry</Text>
						<Text style={styles.content}>:  {industry}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.subtitle}>Primary Contact (Day to Day)</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Name</Text>
						<Text style={styles.content}>:  {primaryName}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Title</Text>
						<Text style={styles.content}>:  {primaryTitle}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Email</Text>
						<Text style={styles.content}>:  {primaryEmail}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Phone No</Text>
						<Text style={styles.content}>:  {primaryPhoneNo}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.subtitle}>Executive Contact</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Name</Text>
						<Text style={styles.content}>:  {executiveName}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Title</Text>
						<Text style={styles.content}>:  {executiveTitle}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Email</Text>
						<Text style={styles.content}>:  {executiveEmail}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Phone No</Text>
						<Text style={styles.content}>:  {executivePhoneNo}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.subtitle}>Accounts Payable Contact</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Name</Text>
						<Text style={styles.content}>:  {accPayableName}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Title</Text>
						<Text style={styles.content}>:  {accPayableTitle}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Email</Text>
						<Text style={styles.content}>:  {accPayableEmail}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Phone No</Text>
						<Text style={styles.content}>:  {accPayablePhoneNo}</Text>
					</View>
					<Text break></Text>
					<View style={styles.row}>
						<Text style={styles.subtitle}>Ship to Addresses</Text>
					</View>
					{bindAddress}
					<View style={styles.row}>
						<Text style={styles.subtitle}>Bill to Addresses</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Street</Text>
						<Text style={styles.content}>:  {billToStreet}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Apartment No</Text>
						<Text style={styles.content}>:  {billToApartmentNo}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>City</Text>
						<Text style={styles.content}>:  {billToCity}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>State</Text>
						<Text style={styles.content}>:  {billToState}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Zip code</Text>
						<Text style={styles.content}>:  {billToPostal}</Text>
					</View>
					<View style={styles.row}>
						<Text style={styles.label}>Country</Text>
						<Text style={styles.content}>:  {billToCountry}</Text>
					</View>
					<Text style={styles.subtitle}></Text>
					{shipToAddress.length % 3 == 2 && (<><Text break></Text>
						<Text style={styles.innertext}></Text></>)}
					{bindOpt}

				</View>
			</Page>
		</Document>
	);
};

export default (CoopFormPDFComponent);