
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class HomepageStore extends EventEmitter {
    pageLoadData = "";
    TechVault = "";
    
   /** updateFavPageResponse = { updateType: 0, responseStr: "" };
    /**
    * Ref: NC_PC_33
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleNonCatalogStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.Homepage_Pageload: {
                this.pageLoadData = action.action.response;
                this.emit('load');
                break;
            }
                //**************NEW*****************
            case SDIConstants.methodNames.TermsnConditionAccept_Click: {
                this.saveResponse = action.action.response;
                this.emit('accepted');
                break;
            }
            case SDIConstants.methodNames.TechVault: {
                this.TechVault = action.action.response;
                this.emit('TechVault');
                break;
            }
                //******************END***********
            //case SDIConstants.methodNames.NonCatalog_SaveNonCatalogEntry: {
            //    this.saveResponse = action.action.response;
            //    this.emit('save');
            //    break;
            //}
            //case "NONCAT_QuickItemCheck": {
            //    this.quickItemcheck = action.action.response;
            //    this.emit('quickItemCheck');
            //    break;
            //}
            //case "NONCAT_CheckCatalog_Click": {
            //    this.checkCatalogResponse = action.action.response;
            //    this.emit('checkCatalogResponse');
            //    break;
            //}
            //case "NONCAT_UploadAttachments": {
            //    this.attachmentResponse = action.action.response;
            //    this.emit('attachmentResponse');
            //    break;
            //}
            //case "NONCAT_RemoveAttachment": {
            //    this.removeAttachmentResponse = action.action.response;
            //    this.emit('rmvAttach');
            //    break;
            //}
            //case "NONCAT_CurrentFavPage": {
            //    this.currentFavPageResponse = action.action.response;
            //    this.emit('favpage');
            //    break;
            //}
            //case "NONCAT_InsertFavPage": {

            //    this.updateFavPageResponse.updateType = 1;
            //    this.updateFavPageResponse.responseStr = action.action.response;
            //    this.emit('updateFavPage');
            //    break;
            //}
            //case "NONCAT_DeleteFavPage": {
            //    this.updateFavPageResponse.updateType = 2;
            //    this.updateFavPageResponse.responseStr = action.action.response;
            //    this.emit('updateFavPage');
            //    break;
            //}

        }
    }
}
const objHomepageStore = new HomepageStore;
// Dispatcher code
Dispatcher.register(objHomepageStore.handleNonCatalogStore.bind(objHomepageStore));
export default objHomepageStore;