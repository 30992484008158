import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,    
} from "react-router-dom";
import route from './routeTable';
import * as CookieService from './CookieService';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/core";
import * as $ from 'jquery';
import { withTranslation, Trans } from 'react-i18next';
const override = css`
    position: relative;
    top: 41%;
    left: 46%;
`;
//[11/26/21, CAS - 31, Dhevanesam R]
class App extends Component {
    displayName = App.name
    constructor(props) {
        super(props);
        this.setCookie = this.setCookie.bind(this);
    };
    componentDidMount() {
        $("#cookie-panel").hide();        
        let isCookiePresent = CookieService.getCookieValue("cookieAggrement");
        if (isCookiePresent == "") {
            $("#cookie-panel").show();
        }
    };
    setCookie = () => {
        CookieService.setCookieValues("cookieAggrement", 1, 30);
        $("#cookie-panel").hide();
    }
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
        let queryParams = new URLSearchParams(window.location.search)
        let workorderNumber = queryParams.get('workorder');
        workorderNumber = workorderNumber != null && workorderNumber != undefined && workorderNumber != "" ? workorderNumber.toUpperCase() : workorderNumber;
        let orderstatus = queryParams.get('orderstatus');
        CookieService.updateLocalStorageValues("URLWONumber", workorderNumber);
        CookieService.updateLocalStorageValues("OrderStatusURL", orderstatus);
        return (
            <React.Fragment>
            <div className="loader-position hide" id="SDILoader">
                <MoonLoader color={"#E99A05"} size={100} css={override} loading={true} />
                </div>
                {/*[6/20/2024, Zeus - 297 - Real time inventory - Johnprinto D]*/}
                <div className="container-fluid content-margin mb-0 content-section-nobreadcrumb hide" id='SDIRTILoader'>
                    <div className="row">
                        <div className="col-md-6 mx-auto d-block mt-5 pt-5">
                            <h4 className="text-center py-5 loading-heading" id='SDIRTILoader-text1'><Trans>Fetching parts information...</Trans></h4>
                            <h4 className="text-center py-5 loading-heading hide" id='SDIRTILoader-text2'><Trans>Calculating supplier distance...</Trans></h4>
                            <div className="progress custom-progress">
                                <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} id="load" style={{ width: '0%' }} />
                            </div>
                            <h4 className="text-center py-5 loading-description"><Trans>Loading... Please wait</Trans></h4>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center w-100 position-absolute " style={{ "top": "90px" }} >
                    <div className="success-toast mt-3 position-fixed bg-white d-none" style={{ "z-index": "1001" }} id='success-toast'>
                        <div className="py-3 px-2">
                            <div className="d-flex align-items-center">
                                <div className="d-block mr-3 ml-2">
                                    <img src="images/toast-tick.svg" alt="success-toast" />
                                </div>
                                <div className="d-block">
                                    <label className="font-14 font-regulars mb-0">Item added to cart successfully</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Z-297 */}
                    <div className="success-toast mt-3 position-fixed bg-white d-none" style={{ "z-index": "1001" }} id='rti-unsuccess-toast'>
                        <div className="py-3 px-2">
                            <div className="d-flex align-items-center">
                                <div className="d-block mr-3 ml-2">
                                    <img src="images/toast-tick.svg" alt="success-toast" />
                                </div>
                                <div className="d-block">
                                    <label className="font-14 font-regulars mb-0">Pickup store currently unavailable and item added to cart for delivery</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Z-297 */}
                    <div className="alert-toast mt-3 position-fixed bg-white d-none" style={{ "z-index": "1001" }} id='rti-maxqty-toast'>
                        <div className="py-3 px-2">
                            <div className="d-flex align-items-center">
                                <div className="d-block mr-3 ml-2">
                                    <img src="images/alert-toast.svg" alt="success-toast" />
                                </div>
                                <div className="d-block">
                                    <label className="font-14 font-regulars mb-0">Item quantities are updated based on the available quantity</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-md-12 alert-panel" id="cookie-panel">
                    <p className="float-left cookie-info mb-0 mt-1"><Trans>This website utilizes cookies and similar technologies for functionality and other purposes.
                         Your use of this website constitutes your acceptance of cookies.</Trans></p>
                    <button className="btn btn-primary custom-save-btn primary-btn float-right mr-3" style={{ "margin-left": "13px", "padding-left": "1rem!important;", "padding-right": "1rem!important;" }} onClick={this.setCookie}><Trans>Ok, Got it</Trans></button>
                </div>
          <Router createElement={true} >
              <HeaderComponent />
              <FooterComponent />
                   
                        <Switch>
                            {route.map(({ path, component: C, title }) =>
                                <Route key={path} path={path} render={(props) => <C {...props} title={title} />} />
                            )}
                        </Switch>
                </Router>
           </React.Fragment>
    );
    };
};
export default withTranslation()(App);
