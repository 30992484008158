import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';


class ProgramMasterStore extends EventEmitter {
    programPageLoadData = "";
    programOperationResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleProgramMasterStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.ProgramMaster_PageLoad: {
                this.programPageLoadData = action.action.response;
                this.emit('programMasterPageloadResponse');
                break;
            }
            case SDIConstants.methodNames.Program_Operation: {
                this.programOperationResponse = action.action.response;
                this.emit('programOperationResponse');
                break;
            }

            case "FAVITEM_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVITEM_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVITEM_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "ValidateToken": {
                this.PageLoadData = action.action.response;
                this.emit('ValidateToken');
                break;
            }

        }
    }
}
const objProgramMasterStore = new ProgramMasterStore;
// Dispatcher code
Dispatcher.register(objProgramMasterStore.handleProgramMasterStore.bind(objProgramMasterStore));
export default objProgramMasterStore;