//[10/21/21, PC_01, CAS - IE 11, Dhevanesam R]
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker'; //[6/30/2022] Unregistering service workers to make the changes reflect after deployment without clearing cache -- Poornima S
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
require('bootstrap/dist/js/bootstrap.min.js');  
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

//[10/18/23, PS_ZE_INC_699_APP_01, Dhevanesam R]
if (isIE) {
	ReactDOM.render(
		<BrowserRouter basename={baseUrl}>
			<App />
		</BrowserRouter>,
		rootElement);
}
else {
	ReactDOM.render(
		<BrowserRouter basename={baseUrl}>
			<Auth0ProviderWithHistory>
				<App />
			</Auth0ProviderWithHistory>
		</BrowserRouter>,
		rootElement);
}

//registerServiceWorker();
unregister();//[6/30/2022] Unregistering service workers to make the changes reflect after deployment without clearing cache -- Poornima S
