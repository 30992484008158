import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class PDFPreviewStore extends EventEmitter {
    OrderPDFMsge = "";
    handlePDFPreviewStore = (action) => {
        switch (action.action.type) {
            case "OrderPDF": {
                this.OrderPDFMsge = action.action.response;
                this.emit('OrderPDF');
                break;
            }
        }
    }
}
const objPDFPreviewStore = new PDFPreviewStore;
Dispatcher.register(objPDFPreviewStore.handlePDFPreviewStore.bind(objPDFPreviewStore));
export default objPDFPreviewStore;