/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 07/30/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: MN_PC_56/MN_PC_57
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import * as CookieService from '../CookieService';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
window.jQuery = $;
require("jquery-toast-plugin/dist/jquery.toast.min.js");
require("jquery-toast-plugin/dist/jquery.toast.min.css");
const availableMenu = SDIConstants.availablePages;
const tokenExpired = async () => {
    try {
        debugger;
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)

    }
};
/**
* MN_PC_58
* This method retrieves the user menu data from API
*/
const getMenuItems = async (menuBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_BindUserMenu,
            JSON.stringify(menuBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Menu_BindUserMenu, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
* MN_PC_59/MN_PC_60/MN_PC_61/MN_PC_62/MN_PC_63
* /MN_PC_64/MN_PC_65/MN_PC_66
* This method retrieves the master data from API
*/
const getMasterData = async (masterBO) => {
    try {

        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetMasterData,
            JSON.stringify(masterBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Menu_GetMasterData, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
* MN_PC_67
* This method retrieves the environment and logo data from API
*/
const getEnvironmentDetails = async (environmentBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetEnvironmentDetails,
            JSON.stringify(environmentBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Menu_GetEnvironmentDetails, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
* MN_PC_69
* This method adds the favorite item to cart
*/
const addFavItemsTocart = async (favCartBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_AddFavItemtoCart,
            JSON.stringify(favCartBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "MENU_AddFavItemsToCart", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
* MN_PC_69
* This method adds the favorite order line item to cart
*/
const addFavOrderItemsTocart = async (favOrderCartBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavOrder,
            SDIConstants.methodNames.FavOrder_ReorderItems,
            JSON.stringify(favOrderCartBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "MENU_AddFavOrderItemsToCart", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
* This method gets the search suggestions from API
*/
const getSearchSuggestions = async (searchBO) => {
    try {
        let responseData = "";
        await SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_SearchSuggestions,
            JSON.stringify(searchBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        responseData = data.response;
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
        return responseData;

    } catch (ex) {
        console.log(ex)
        return "";
    }
};
const refreshMasterData = () => {
    try {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : "",
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : ""
        };
        console.log("masterBO2", masterBO);
        getMasterData(masterBO);

    } catch (ex) {
        console.log(ex)
    }
};
const refreshMasterData1 = () => {
    try {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: true,//isFirstTimeLoaded
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        console.log("masterBO2", masterBO);
        getMasterData(masterBO);

    } catch (ex) {
        console.log(ex)
    }
};
const toastMessage = (type) => {
    try {
            $.toast({
                heading: 'Favorite Page',
                text: type == 1 ? 'Added to favorite pages' : 'Removed from favorite pages',
                position: {
                    top: 175,
                    bottom: 90,
                    left:600
                },
                stack: false,
                loader: false
        });
        refreshMasterData();

    } catch (ex) {
        console.log(ex)
    }
};
const refreshMasterDataWithFullPrivileges = () => {
    try {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: true,
            isOrderApproval: true,
            isFavItem: true,
            isFavOrder: true,//[5/8/2023, PS_FO_05, ZEUS-95 isFavOrder is sent as to display fav order panel - Johnprinto D
            isFirstTime: true,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : "",
            //[04/18/2023, GOA_PSF_3, Zeus 79] add Session_USERTYPE to get order approvals count in homepage for corp admin logged in -Sathis N
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : ""
        };
        console.log("masterBO2", masterBO);
        getMasterData(masterBO);

    } catch (ex) {
        console.log(ex)
    }
};
/**
* MN_PC_58
* This refresh method retrieves the user menu data from API
*/
const refreshMenuItems = async (fromLogin,props) => {
    try {
        let menuBO = {
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_MenuUpdated: "False",
            Session_SDIMenu: "",
            Session_BARCODE: CookieService.getLocalStorageValues("Session_BARCODE") ? CookieService.getLocalStorageValues("Session_BARCODE") : "",
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
            Session_DEFAULTPAGE: CookieService.getLocalStorageValues("Session_DEFAULTPAGE"),
            Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
            Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
            Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERNAME: CookieService.getLocalStorageValues("Session_AGENTUSERNAME") ? CookieService.getLocalStorageValues("Session_AGENTUSERNAME") : "",
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME")
        }
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_BindUserMenu,
            JSON.stringify(menuBO),
            (data) => {
                debugger;
                if (CookieService.getLocalStorageValues("isSSOClick") != true) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                }
                if (data.success) {
                    if (data.response != "" && data.response) {
                       // let contentData = { type: SDIConstants.methodNames.Menu_BindUserMenu, response: data.response };
                        console.log("data.response", data.response);
                        let response = JSON.parse(data.response);
                        localStorage.setItem("SDIMenu", JSON.stringify(response.UserMenu));
                        localStorage.setItem("SDICategories", JSON.stringify(response.Categories));
                        if (fromLogin) {
                            //Added the logic for Default page - Dhinesh R(Zeus-35)//
                            let format = /\.aspx$/;
                            let defaultPage = (CookieService.getLocalStorageValues("Session_DEFAULTPAGE")) ? CookieService.getLocalStorageValues("Session_DEFAULTPAGE").trim() : CookieService.getLocalStorageValues("Session_DEFAULTPAGE");

                            if (defaultPage == undefined || defaultPage == null || defaultPage === "") {
                                props.history.push({
                                    pathname: '/HomePage',
                                });
                            }

                            else {
                                let defaultPageOption = defaultPage.split(".")[0];
                                let landingPage = '/' + defaultPageOption;
                                let formatPage = format.test(defaultPage);
                                if (!formatPage && availableMenu.some((page) => page.pageURL.toLowerCase() === defaultPage.toLowerCase())) {
                                    props.history.push({
                                        pathname: defaultPage,
                                    });
                                }
                                else if (landingPage != undefined && formatPage && availableMenu.some((page) => page.pageURL.toLowerCase() === landingPage.toLowerCase())) {
                                    props.history.push({
                                        pathname: landingPage,
                                    });
                                }
                                else {
                                    props.history.push({
                                        pathname: '/HomePage',
                                    });
                                }
                            }
                        }
                        refreshMasterDataWithFullPrivileges();
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
//***********NEW(12/24/2020)**************
const CrossSiteChange = (OrderStatusPageLoadBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_CrossSiteAccess,
            JSON.stringify(OrderStatusPageLoadBO),

            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.Menu_CrossSiteAccess, response: data.response };
                        debugger;
                        let Validationdata = JSON.parse(data.response);
                        Validationdata = Validationdata.LoginClickFlags_UpdatetoSession;
                        if (Validationdata) {
                            if (Validationdata.length > 0) {
                                Validationdata = Validationdata[0];
                                localStorage.clear();
                                
                            }
                        }
                        let lss = CookieService.insertLocalStorageValues(Validationdata);
                        if (lss) {
                            console.log("success");
                        }
                        console.log("Session_UserID", CookieService.getLocalStorageValues("Session_UserID"));
                        console.log("Session_BUSUNIT", CookieService.getLocalStorageValues("Session_BUSUNIT"));
                        debugger;
                        //let EMP = [];
                        //EMP.push(localArray)
                        //console.log(Validationdata)
                        console.log("Session_CONAME", CookieService.getLocalStorageValues("Session_CONAME"));
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
//[05/09/2023 - PC_OSC_4 - ZEUS-99 - Get the OSC page URL]
const getPassThroughAutheURl = (getPassThroughAutheURlBO) => {
    debugger
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.PassThroughAuth,
            SDIConstants.methodNames.PassThroughAuth_PassAutheInitialize,
            JSON.stringify(getPassThroughAutheURlBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PassThroughAuthe", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const CopyMessage = () => {
    try {
        $.toast({
            text: 'Phone Number Copied To ClipBoard',
            position: {
                top: 175,
                bottom: 90,
                left: 600
            },
            stack: false,
            loader: false
        });

    } catch (ex) {
        console.log(ex)
    }
};

//[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
const workOrderValidation = async (workOrderBO) => {
    try {
        debugger
        SDISVC.callAsync(
            SDIConstants.moduleNames.ServiceChannel,
            SDIConstants.methodNames.ServiceChannel_GetWorkOrderValidation,
            JSON.stringify(workOrderBO),
            (data) => {
                debugger
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "WOValidate", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

//*******************END***********************

export { getMenuItems, getMasterData, getEnvironmentDetails, addFavItemsTocart, addFavOrderItemsTocart, getSearchSuggestions, tokenExpired, toastMessage, refreshMasterData, refreshMenuItems, CrossSiteChange, refreshMasterData1, getPassThroughAutheURl, CopyMessage, workOrderValidation}