import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
import * as MenuAction from './MenuAction';
const GetOrderStatusPageLoad = (OrderStatusPageLoadBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_PageLoad,
            JSON.stringify(OrderStatusPageLoadBO),
            
            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_PageLoad, response: data.response };
                        debugger;
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const SavePreference = (SavePrefereceBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_btnSavePrefs_Click,
            JSON.stringify(SavePrefereceBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_btnSavePrefs_Click, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const RetriveData = (OrderStatusDataBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_RetrieveOrderData,
            JSON.stringify(OrderStatusDataBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_RetrieveOrderData, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const OrderStatusDetail = (OrderStatusBO) => {
    try {
        debugger;
        SDISVC.receiveFileBase64(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_OrderDetail,
            JSON.stringify(OrderStatusBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_OrderDetail, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const ExportData = (OrderStatusDataBO) => {
    try {
        debugger;
        SDISVC.downloadFile(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_ExportToExcel,
            JSON.stringify(OrderStatusDataBO),
            "OrderStatusExport.xlsx",
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        debugger;
                        
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_ExportToExcel, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}



const ApprovalHistory = (ApprovalHistoryBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_ApprovalHistory,
            JSON.stringify(ApprovalHistoryBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_ApprovalHistory, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const PODetail = (PODetailBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_PoDetails,
            JSON.stringify(PODetailBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_PoDetails, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const addFavOrderItemsTocart = async (addFavOrderItemsBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_ReorderItems,
            JSON.stringify(addFavOrderItemsBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_ReorderItems, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } 
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const shipmentBOL = (SavePrefereceBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_ShipTo,
            JSON.stringify(SavePrefereceBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        let contentData = { type: SDIConstants.methodNames.OrderStatus_ShipTo, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};

const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ORDERSTS_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ORDERSTS_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ORDERSTS_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};


const HeaderData = (HeaderBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_HeaderSP,
            JSON.stringify(HeaderBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: "HeaderData", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const LineData = (LineDataBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_LineLevelSP,
            JSON.stringify(LineDataBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: "LineData", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const CancelRequest = (CancelRequestBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_CancelRequest,
            JSON.stringify(CancelRequestBO),
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                    if (data.response != "" && data.response) {
                        let contentData = { type: "CancelRequest", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
//[5/9/2023]Zeus -100 PTA link for incident button API call - Poornima
const getPassThroughAutheURl = (getPassThroughAutheURlBO) => {
    debugger
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.PassThroughAuth,
            SDIConstants.methodNames.PassThroughAuth_PassAutheInitialize,
            JSON.stringify(getPassThroughAutheURlBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PassThroughAuthe", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

//[Zeus 178.-, 08/22/2023] - Fetch Approver list against the particular order - Kishore
const GetApproversPopupList = (ApproverListBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_ApproverList,
            JSON.stringify(ApproverListBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ApproverList", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }

}

const RemainderOrderAction = (RemainderOrderBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_ApprovalRemainder,
            JSON.stringify(RemainderOrderBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ApprovalRemainder", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
}

//[3/27/2024]Z 268 GetOrderNotes() to get notes against an order or line item from the backend
const GetOrderNotes = (GetNotesBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderStatus,
            SDIConstants.methodNames.OrderStatus_GetOrderNotes,
            JSON.stringify(GetNotesBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "OrderNotes", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
}

export {
    GetOrderStatusPageLoad, SavePreference,
    RetriveData, ApprovalHistory, PODetail,
    addFavOrderItemsTocart, ExportData, shipmentBOL,
    OrderStatusDetail, deleteFavPage, insertFavPage, getCurrentFavPage, HeaderData, LineData, CancelRequest, getPassThroughAutheURl, GetApproversPopupList,
    RemainderOrderAction, GetOrderNotes
}