import React, { Component } from 'react';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
import { withTranslation, Trans } from 'react-i18next';
const renderSessionTimeout = (props) => {
    if (!$(`#${SDIConstants.SDILoader}`).hasClass("hide")) {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    } 
    console.log('modal-backdrop', $(`.modal-backdrop`));
    if ($(`.modal-backdrop`)) {
        if ($(`.modal-backdrop`).length > 0) {
            $(`.modal-backdrop`).hide();
        }
    }
    document.title = "Session Timeout";
    //[11/26/21, CAS - 31, Priyanka D]
    return (<div className="container-fluid content-section">
        <div className="row">
            <section className="site-undercontruction container text-center center-panel">
                <p className="primaryinfo"><Trans>Session Timeout</Trans></p>
                <p className="secondaryinfo"><Trans>Go to login</Trans></p>
                <div className="img-section">
                    <img src="/images/session_timeout.jpg" alt="imagefile" />
                </div>
                <div className="text-center my-3">
                    <button className="btn btn-primary custom-btn-primary primary-btn px-4" onClick={() => props.history.push({
                        pathname: '/',
                    })}><Trans>Login</Trans></button>
                </div>
            </section>
        </div>
    </div>)
}
const SessionTimeoutComponent = renderSessionTimeout;
export default withTranslation()(SessionTimeoutComponent);