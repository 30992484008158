/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/22/2020
 * 
 * Ref: PO_PC_19
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class RequestorApprovalStore extends EventEmitter {
    requestorData = "";
    currentFavPageResponse = "";
    singleAppDecRes = "";
    multiAppDecRes = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    DeclineReasonRep = "";
    /**
    * Ref: PO_PC_20
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleRequestorApprovalStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.RequestorApproval_PageLoad: {
                this.requestorData = action.action.response;
                this.emit('requestorData');
                break;
            }
            case SDIConstants.methodNames.RequestorApproval_ApproveSingleLineClickEvent: {
                this.singleAppDecRes = action.action.response;
                this.emit('single');
                break;
            }
            case SDIConstants.methodNames.RequestorApproval_ApproveClickEvent: {
                this.multiAppDecRes = action.action.response;
                this.emit('multi');
                break;
            }

            case "REQAPP_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "REQAPP_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "REQAPP_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case SDIConstants.methodNames.RequestorApproval_DeclineReason: {
                this.DeclineReasonRep = action.action.response;
                this.emit('DecReason');
                break;
            }

        }
    }
}
const objRequestorApprovalStore = new RequestorApprovalStore;
// Dispatcher code
Dispatcher.register(objRequestorApprovalStore.handleRequestorApprovalStore.bind(objRequestorApprovalStore));
export default objRequestorApprovalStore;