import * as $ from 'jquery';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Select from 'react-select';
import swal from 'sweetalert';
import * as ExpressPutAwayAction from '../actions/ExpressPutAwayAction';
import * as MenuAction from '../actions/MenuAction';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import ExpressPutAwayStore from '../stores/ExpressPutAwayStore';
import * as STKIssuesCartAction from '../actions/STKIssuesCartAction';
import STKIssuesCartStore from '../stores/STKIssuesCartStore';
let currentPagePath;

class ExpressPutAwayComponent extends Component {
    constructor(props) {

        super(props);
        this.pageLoadResponse = this.pageLoadResponse.bind(this);
        this.submitBtnClickResponse = this.submitBtnClickResponse.bind(this);
        this.state = {
            itemID: "",
            itemIDError: "",
            selectedBu: "",
            reasonOptions: [],
            reason: "",
            workOrder: "",
            workOrderErr: "",
            selectedArea: "",
            selectedAisle: "",
            selectedShelf: "",
            selectedRow: "",
            selectedBin: "",
            areaOptions: [],
            aisleOptions: [],
            shelfOptions: [],
            rowOptions: [],
            binOptions: [],
            defaultLocationsDt: [],
            locationsDt: [],
            cost: "",
            quantity: "",
            isDefChecked: false,
            isValidToSearch: true,
            isValidToSubmit: true
        }
    }
    /**
    * PS_ZE_265_APP_11/Sathis/03/28/2024
    * Invoke this method on the click of ExpressPutAway from menu bar.
    * */
    componentDidMount() {
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;

        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        }

        this.pageLoad(this.state.selectedBu);
        ExpressPutAwayAction.getCurrentFavPage(getFavPageBO);
        ExpressPutAwayStore.on('expPageLoad', this.pageLoadResponse.bind(this));
        ExpressPutAwayStore.on('expSubmit', this.submitBtnClickResponse.bind(this));
        STKIssuesCartStore.on('STKWOResponse', this.workOrderResponse);
        ExpressPutAwayStore.on('favpage', this.loadFavPageResponse.bind(this));
        ExpressPutAwayStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    /**
    * PS_ZE_265_APP_12/Sathis/03/28/2024
    * Invoke this method while redirect to other panels from express put away.
    * */
    componentWillUnmount() {
        ExpressPutAwayStore.removeListener('expPageLoad', this.pageLoadResponse.bind(this));
        ExpressPutAwayStore.removeListener('expSubmit', this.submitBtnClickResponse.bind(this));
        STKIssuesCartStore.removeListener('STKWOResponse', this.workOrderResponse);
        ExpressPutAwayStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        ExpressPutAwayStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    /****************************************************************Action methods starts here***********************************************************************/


    /**
    * PS_ZE_265_APP_14/Sathis/03/28/2024
    * This method invokes on page load,click of search button and change of business unit drop down.
    * */
    pageLoad = (selectedBu) => {

        let pageLoadBO = {
            businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            itemID: this.state.itemID.toUpperCase().trim(),
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            selectedBu: selectedBu,
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        ExpressPutAwayAction.pageLoadAction(pageLoadBO)
    }

    /**
    * PS_ZE_265_APP_13/Sathis/03/28/2024
    * This method invokes on the click of search button 
    * */
    handleSearchBtnClick = () => {
        let isValidToSearch = true;
        let itemIDError = "";
        if (this.state.selectedBu == "") {
            isValidToSearch = false
        }
        if (this.state.itemID == "") {
            isValidToSearch = false
            itemIDError = "Please enter the item ID"
        }
        this.setState({ itemIDError, isValidToSearch })
        if (isValidToSearch && itemIDError == "") {
            this.pageLoad(this.state.selectedBu.value)
        }
    }
    /**
    * PS_ZE_265_APP_17/Sathis/03/28/2024
    * This method invokes to handle seaarch response
    * */
    pageLoadResponse = () => {
        const { t } = this.props;
        const response = JSON.parse(ExpressPutAwayStore.pageLoadResponse);
        const errorDt = response.errorDt;
        if (errorDt[0].errorMessage.toUpperCase() == "FALSE") {
            if (this.state.itemID === '') {
                const businessUnitDt = response.businessUnitDt;
                if (businessUnitDt) {
                    if (businessUnitDt.length > 0) {
                        const businessUnitOptions = [];
                        businessUnitDt.forEach((value, index) => {
                            const localObj = {
                                value: value.BUSINESS_UNIT,
                                label: value.BUSINESS_UNIT
                            };
                            businessUnitOptions.push(localObj);
                        });
                        this.setState({
                            businessUnitOptions: businessUnitOptions
                        });
                    }
                }
            }
            else {
                const reasonDt = response.reasonsDt;
                const itemDetailsDt = response.itemDetailsDt;
                const locationsDt = response.locationsDt;
                const defaultLocationsDt = response.defaultLocDt;

                if (itemDetailsDt.length < 1) {
                    this.setState({
                        itemIDError: 'Please enter valid item ID',
                        isValidToSearch: false
                    });
                }
                else if (itemDetailsDt.length > 0 && reasonDt.length < 1) {
                    this.setState({
                        itemIDError: 'Item is not available in crib',
                        isValidToSearch: false
                    });
                }
                else {
                    const reasonOptions = [];
                    let cost = '';
                    let reason = '';
                    const locArea = [];
                    if (reasonDt.length > 0) {
                        reasonDt.forEach((value, index) => {
                            const localObj = {
                                value: value.REASON_CD,
                                label: value.REASON_CD
                            };
                            if (this.state.selectedBu.value.substring(0, 1) === 'X' && value.REASON_CD === 'DEFECTIVE') {
                                reason = localObj;
                                cost = '0.1';
                            }
                            reasonOptions.push(localObj);
                        });
                    }

                    if (locationsDt.length > 0) {
                        const bu = this.state.selectedBu.value;
                        locationsDt.forEach((item, index) => {
                            if (item.BUSINESS_UNIT == bu && !locArea.some(obj => obj.value == item.STORAGE_AREA)) {
                                const obj = {
                                    value: item.STORAGE_AREA,
                                    label: item.STORAGE_AREA
                                };
                                locArea.push(obj);
                            }
                        });
                        locArea.sort((a, b) => a.label > b.label ? 1 : -1);
                    }
                    if (defaultLocationsDt.length > 0) {
                        this.buildLocationOptions('', '', false, locationsDt, defaultLocationsDt);
                    }
                    this.setState({
                        reasonOptions: reasonOptions,
                        reason: reason,
                        areaOptions: locArea,
                        itemDetailsDt: itemDetailsDt,
                        cost: cost,
                        defaultLocationsDt: defaultLocationsDt,
                        locationsDt: locationsDt
                    });
                }
            }
        } else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /**
    * PS_ZE_265_APP_20/Sathis/03/28/2024
    * This method invokes on the click of submit button
    * */
    handleSubmitBtnClick = () => {
        let isValidToSubmit = true;
        if (this.state.cost === '' || this.state.quantity === '' || this.state.reason === '' || this.state.selectedArea === '' || this.state.selectedAisle === '' || this.state.selectedShelf === '' || this.state.selectedRow === '' || this.state.selectedBin === '') {
            isValidToSubmit = false;
        }
        this.setState({ isValidToSubmit: isValidToSubmit });
        if (isValidToSubmit && this.state.workOrderErr === '') {
            const submitBO = {
                itemID: this.state.itemID.toUpperCase().trim(),
                businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                userID: CookieService.getLocalStorageValues("Session_UserID"),
                websiteID: CookieService.getLocalStorageValues("Session_websiteID"),
                selectedBu: this.state.selectedBu.value,
                reason: this.state.reason.value,
                cost: this.state.cost,
                quantity: this.state.quantity,
                workOrder: this.state.workOrder != "" ? this.state.workOrder : " ",
                area: this.state.selectedArea.value,
                aisle: this.state.selectedAisle.value,
                row: this.state.selectedRow.value,
                shelf: this.state.selectedShelf.value,
                bin: this.state.selectedBin.value,
                UOM: this.state.itemDetailsDt[0].UNIT_MEASURE_STD,
                isDefChecked: this.state.isDefChecked,
                hasDefault: this.state.defaultLocationsDt.length > 0,
                deptID: this.state.itemDetailsDt[0].dept_ID
            };

            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            ExpressPutAwayAction.submitBtnClickAction(submitBO)
        }
    }
    /**
    * PS_ZE_265_APP_23/Sathis/03/28/2024
    * This method invokes to handle submit button response
    * */
    submitBtnClickResponse = () => {
        const { t } = this.props;
        const rowsAffected = JSON.parse(ExpressPutAwayStore.submitResponse);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (rowsAffected > 0) {
            $(`#exp-success-popup`).modal('show')
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            })
        }
    }

    /**
    * PS_ZE_265_APP_24/Sathis/03/28/2024
    * This method invokes on the outside click of work order button.
    * */

    handleWoValidation = () => {
        let workOrder = this.state.workOrder;
        if (workOrder) {
            let workOrderBO = {
                WorkOrder: workOrder,
                BusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT")
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            STKIssuesCartAction.GetWorkOrderValidate(workOrderBO);
        }
    }

    /**
    * PS_ZE_265_APP_25/Sathis/03/28/2024
    * This method invokes to handle work order response.
    * */

    workOrderResponse = () => {
        const { t } = this.props;
        let response = STKIssuesCartStore.STKWOResponse;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (response && response != "" && response != "null" && response != "error occured in server" && response != "error occured" && response != "authorization required"
            && response != "internal server error") {
            if (response == "invalid") {
                this.setState({ workOrderErr: "Please enter valid work order#" })
            }
            else {
                let responeData = JSON.parse(STKIssuesCartStore.STKWOResponse);
                if (responeData[0].WOVALIDATE && responeData[0].WOVALIDATE != "") {
                    if (responeData[0].WOVALIDATE == "valid") {
                        this.setState({ workOrderErr: "" })
                    }
                }
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Technical issue please contact SDI help desk")
            });
        }
    }


    /***********************************************************Fields onchange handling methods starts here************************************************************/

    /**
    * PS_ZE_265_APP_18/Sathis/03/28/2024
    * This method invokes to handle the default location binding and location details drop downs onchange.
    * */
    buildLocationOptions = (e, name, isFromOnChange, locationDt, defaultLocationsDt) => {
        let locAisle = this.state.aisleOptions;
        let locRow = this.state.rowOptions;
        let locShelf = this.state.shelfOptions;
        let locBin = this.state.binOptions;

        let objDefArea = "";
        let objDefAisle = "";
        let objDefShelf = "";
        let objDefRow = "";
        let objDefBin = "";

        if (isFromOnChange === false) {
            objDefArea = {
                value: defaultLocationsDt[0].STORAGE_AREA,
                label: defaultLocationsDt[0].STORAGE_AREA
            };

            objDefAisle = {
                value: defaultLocationsDt[0].STOR_LEVEL_1,
                label: defaultLocationsDt[0].STOR_LEVEL_1
            };

            objDefRow = {
                value: defaultLocationsDt[0].STOR_LEVEL_2,
                label: defaultLocationsDt[0].STOR_LEVEL_2
            };

            objDefShelf = {
                value: defaultLocationsDt[0].STOR_LEVEL_3,
                label: defaultLocationsDt[0].STOR_LEVEL_3
            };

            objDefBin = {
                value: defaultLocationsDt[0].STOR_LEVEL_4,
                label: defaultLocationsDt[0].STOR_LEVEL_4
            };
        }

        let selectedArea = isFromOnChange ? this.state.selectedArea : objDefArea;
        let selectedAisle = isFromOnChange ? this.state.selectedAisle : objDefAisle;
        let selectedShelf = isFromOnChange ? this.state.selectedShelf : objDefShelf;
        let selectedRow = isFromOnChange ? this.state.selectedRow : objDefRow;
        let selectedBin = isFromOnChange ? this.state.selectedBin : objDefBin;

        if (name === 'area' || isFromOnChange === false) {
            if (selectedArea.value !== e.value) {
                locAisle = [];
                let area = isFromOnChange ? e : objDefArea;
                locationDt.forEach((item, index) => {
                    if (item.BUSINESS_UNIT == this.state.selectedBu.value && item.STORAGE_AREA == area.value && !locAisle.some(obj => obj.value == item.STOR_LEVEL_1)) {
                        let obj = {
                            value: item.STOR_LEVEL_1,
                            label: item.STOR_LEVEL_1
                        };
                        locAisle.push(obj);
                    }
                });
                locAisle.sort((a, b) => a.label > b.label ? 1 : -1);
                if (isFromOnChange) {
                    selectedAisle = "";
                    selectedRow = "";
                    selectedShelf = "";
                    selectedBin = "";
                    locRow = [];
                    locShelf = [];
                    locBin = [];
                    selectedArea = e;
                }
            }
        }

        if (name === 'aisle' || isFromOnChange === false) {
            if (selectedAisle.value !== e.value) {
                locRow = [];
                let aisle = isFromOnChange ? e : objDefAisle;
                locationDt.forEach((item, index) => {
                    if (item.BUSINESS_UNIT == this.state.selectedBu.value && item.STORAGE_AREA == selectedArea.value && item.STOR_LEVEL_1 == aisle.value && !locRow.some(obj => obj.value == item.STOR_LEVEL_2)) {
                        let obj = {
                            value: item.STOR_LEVEL_2,
                            label: item.STOR_LEVEL_2
                        };
                        locRow.push(obj);
                    }
                });
                locRow.sort((a, b) => a.label > b.label ? 1 : -1);
                if (isFromOnChange) {
                    selectedRow = "";
                    selectedShelf = "";
                    selectedBin = "";
                    locShelf = [];
                    locBin = [];
                    selectedAisle = e;
                }
            }
        }

        if (name === 'row' || isFromOnChange === false) {
            if (selectedRow.value !== e.value) {
                locShelf = [];
                let row = isFromOnChange ? e : objDefRow;
                locationDt.forEach((item, index) => {
                    if (item.BUSINESS_UNIT == this.state.selectedBu.value && item.STORAGE_AREA == selectedArea.value && item.STOR_LEVEL_1 == selectedAisle.value && item.STOR_LEVEL_2 == row.value && !locShelf.some(obj => obj.value == item.STOR_LEVEL_3)) {
                        let obj = {
                            value: item.STOR_LEVEL_3,
                            label: item.STOR_LEVEL_3
                        };
                        locShelf.push(obj);
                    }
                });
                locShelf.sort((a, b) => a.label > b.label ? 1 : -1);
                if (isFromOnChange) {
                    selectedShelf = "";
                    selectedBin = "";
                    locBin = [];
                    selectedRow = e;
                }
            }
        }

        if (name === 'shelf' || isFromOnChange === false) {
            if (selectedShelf.value !== e.value) {
                locBin = [];
                let shelf = isFromOnChange ? e : objDefShelf;
                locationDt.forEach((item, index) => {
                    if (item.BUSINESS_UNIT == this.state.selectedBu.value && item.STORAGE_AREA == selectedArea.value && item.STOR_LEVEL_1 == selectedAisle.value && item.STOR_LEVEL_2 == selectedRow.value && item.STOR_LEVEL_3 == shelf.value && !locBin.some(obj => obj.value == item.STOR_LEVEL_4)) {
                        let obj = {
                            value: item.STOR_LEVEL_4,
                            label: item.STOR_LEVEL_4
                        };
                        locBin.push(obj);
                    }
                });
                locBin.sort((a, b) => a.label > b.label ? 1 : -1);
                if (isFromOnChange) {
                    selectedBin = "";
                    selectedShelf = e;
                }
            }
        }

        if (name === 'bin' || isFromOnChange === false) {
            if (isFromOnChange) {
                selectedBin = e;
            }
        }

        this.setState({
            selectedArea: selectedArea,
            selectedAisle: selectedAisle,
            selectedShelf: selectedShelf,
            selectedRow: selectedRow,
            selectedBin: selectedBin,
            aisleOptions: locAisle,
            rowOptions: locRow,
            shelfOptions: locShelf,
            binOptions: locBin
        });
    }

    /**
    * PS_ZE_265_APP_24/Sathis/03/28/2024
    * This method invokes to handle clear button click.
    * */
    handleCancelBtnClick = () => {
        this.setState({
            reasonOptions: [],
            isValidToSubmit: true,
            itemID: '',
            selectedArea: "",
            selectedAisle: "",
            selectedShelf: "",
            selectedRow: "",
            selectedBin: "",
            areaOptions: [],
            aisleOptions: [],
            rowOptions: [],
            shelfOptions: [],
            binOptions: [],
            cost: "",
            quantity: "",
            workOrder: "",
            isDefChecked: false,
            reason: ""
        });
    }



    /**
    * PS_ZE_252_APP_29/Sathis/02/01/2024
    * This method invokes to handle onchange fields.
    * */

    handleOnChange = (name, event) => {
        const cost = SDIConstants.costRegex;
        const quantity = SDIConstants.quantityRegEX;

        switch (name) {
            case 'itemID':
                let itemIDError = "";
                if (this.state.isValidToSearch == false && event.target.value == "") {
                    itemIDError = "Please enter the item ID"
                }
                this.setState({ itemID: event.target.value, itemIDError });
                break;
            case 'workOrder':
                this.setState({ workOrder: event.target.value });
                if (event.target.value == "") {
                    this.setState({ workOrderErr: "" });
                }
                break;
            case 'quantity':
                if (event.target.value === '' || quantity.test(event.target.value)) {
                    if (event.target.value != 0 || event.target.value === '') {
                        this.setState({ quantity: event.target.value });
                    }
                }
                break;
            case 'cost':
                if (event.target.value === '' || cost.test(event.target.value)) {
                    if (event.target.value != 0 || event.target.value === '') {
                        this.setState({ cost: event.target.value });
                    }
                }
                break;
            case 'checkBox':
                this.setState({ isDefChecked: event.target.checked });
                break;
            case 'reason':
                this.setState({ reason: event });
                break;
            default:
                this.setState({ selectedBu: event });
                if (this.state.reasonOptions.length > 0) {
                    this.setState({
                        reasonOptions: [], isValidToSubmit: true, selectedArea: "", selectedAisle: "", selectedShelf: "", selectedRow: "",
                        selectedBin: "", areaOptions: [], aisleOptions: [], rowOptions: [], shelfOptions: [],
                        binOptions: [], cost: "", quantity: "", workOrder: "", isDefChecked: false, reason: ""
                    });
                    this.pageLoad(event.value);
                }
                break;
        }
    }

    /**
    * PS_ZE_265_APP_26/Sathis/03/28/2024
    * This method invokes to handle enter key press from item ID field.
    * */
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.handleSearchBtnClick();
        };
    };


    /****************************************************************Favorites handle starts from here***********************************************************************/
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Express Putaway",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            ExpressPutAwayAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            ExpressPutAwayAction.deleteFavPage(deleteFavPageBO)
        }
    };
    loadFavPageResponse = () => {
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let favPageResponse = JSON.parse(ExpressPutAwayStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ExpressPutAwayStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(ExpressPutAwayStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(ExpressPutAwayStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };


    /****************************************************************HTML parts starts from here***********************************************************************/

    /**
    * PS_ZE_252_APP_26/Sathis/02/01/2024
    * This method invokes to bind HTML.
    * */
    render() {
        const { t } = this.props;
        return (
            <>
                <div className="container-fluid content-section" >
                    <div className="row justify-content-center">
                        {/*Page Heading starts here*/}
                        <div className="col-md-12 mt-3 mb-4 page-heading">
                            <h5 className="page-title"><Trans>Express Putaway</Trans></h5>
                            <div className="fav-item-maintenance-icon mt-1">
                                <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                <label htmlFor="checkbox-1">
                                    <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </div>
                        </div>
                        {/*Page Heading ends here*/}
                        {/*forms starts here*/}
                        <div className="col-md-11 mt-4">
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <label className="form-label catalog-label w-100" htmlFor="business-unit"><Trans>Business unit</Trans><span className="color-manditory">*</span></label>
                                    <Select
                                        options={this.state.businessUnitOptions}
                                        value={this.state.selectedBu}
                                        onChange={(e) => this.handleOnChange("businessUnit", e)}
                                        name='exp-businessUnit'
                                        id='exp-businessUnit'
                                        className={`${this.state.selectedBu == "" && this.state.isValidToSearch == false ? 'mstk-dropdown' : ''}`}
                                    />
                                    {this.state.selectedBu == "" && this.state.isValidToSearch == false ?
                                        <span className="font-12 font-regular color-validation"><Trans>Please select business unit</Trans></span> : null}
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label className="form-label catalog-label" htmlFor="item-id"><Trans>Item ID</Trans><span className="color-manditory">*</span></label>
                                    <input type="text" className={`form-control rounded ${this.state.itemIDError != "" && this.state.isValidToSearch == false ? 'validation' : ''}`} placeholder={t("Enter item ID")}
                                        id="item-id" name="itemID" maxLength="18" value={this.state.itemID} onChange={(e) => this.handleOnChange("itemID", e)} onKeyDown={(e) => this.searchKeyPress(e)} disabled={this.state.reasonOptions.length == 0 ? false : true} />
                                    {this.state.itemIDError != "" && this.state.isValidToSearch == false ?
                                        <span className="font-12 font-regular color-validation"><Trans>{this.state.itemIDError}</Trans></span> : null}
                                </div>
                                {this.state.reasonOptions.length == 0 ?
                                    <div className="col-md-4 mb-4 d-flex align-items-end justify-content-md-start justify-content-end">
                                        <button className={`btn btn-primary custom-resp-btn-width custom-btn-primary primary-btn px-3 rounded  ${(this.state.itemIDError != "" && this.state.isValidToSearch == false) || (this.state.selectedBu == "" && this.state.isValidToSearch == false) ? 'validation-margin-bottom' : 'mb-1'}`} type="button" onClick={() => this.handleSearchBtnClick()} ><Trans>Search</Trans></button>
                                    </div> : null}
                            </div>
                            {this.state.reasonOptions.length > 0 ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="font-16 font-bold mb-4"><Trans>Item details</Trans></h5>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <div className="item-box px-3 py-3">
                                                <div className="w-100 mb-2">
                                                    <span className="font-medium font-12 mr-2 label-color"><Trans>Description</Trans> :</span><span className="font-12 font-regular color-light-black">{this.state.itemDetailsDt[0].DESCR60}</span>
                                                </div>
                                                <div className="w-100">
                                                    <span className="font-medium font-12  mr-2 label-color"><Trans>UOM</Trans> :</span><span className="font-12 font-regular color-light-black">{this.state.itemDetailsDt[0].UNIT_MEASURE_STD}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label" htmlFor="cost"><Trans>Cost</Trans><span className="color-manditory">*</span></label>
                                            <input type="text" className={`form-control  ${this.state.cost == "" && this.state.isValidToSubmit == false ? 'validation' : ''}`} id="cost"
                                                placeholder={t("Enter cost")} maxLength="10" value={this.state.cost} onChange={(e) => this.handleOnChange("cost", e)} disabled={this.state.selectedBu.value.substring(0, 1) == 'X' ? true : false} />
                                            {this.state.cost == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please enter cost</Trans></span> : null}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label" htmlFor="quantity"><Trans>Quantity</Trans><span className="color-manditory">*</span></label>
                                            <input type="text" className={`form-control  ${this.state.quantity == "" && this.state.isValidToSubmit == false ? 'validation' : ''}`} id="quantity" placeholder={t("Enter quantity")} maxLength="8" value={this.state.quantity} onChange={(e) => this.handleOnChange("quantity", e)} />
                                            {this.state.quantity == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please enter quantity</Trans></span> : null}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label w-100" htmlFor="reason"><Trans>Reason</Trans><span className="color-manditory">*</span></label>
                                            <Select
                                                options={this.state.reasonOptions}
                                                value={this.state.reason}
                                                onChange={(e) => this.handleOnChange("reason", e)}
                                                name='exp-reason'
                                                id='exp-reason'
                                                className={`${this.state.reason == "" && this.state.isValidToSubmit == false ? 'mstk-dropdown' : ''}`}
                                                isDisabled={this.state.selectedBu.value.substring(0, 1) == 'X' ? true : false}
                                            />
                                            {this.state.reason == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please select reason</Trans> </span> : null}
                                        </div>
                                        {/*<div className="col-md-4 mb-4">
                                                <label className="form-label catalog-label" htmlFor="wo"><Trans>Work order#</Trans></label>
                                            <input type="text" className={`form-control  ${this.state.workOrderErr != "" ? 'validation' : ''}`} id="workOrder" name="workOrder" placeholder={t("Enter work order#")} maxLength="10" onBlur={() => this.handleWoValidation()} onChange={(e) => this.handleOnChange("workOrder", e)}  />
                                            {this.state.workOrderErr != "" ?
                                                    <span className="font-12 font-regular color-validation"><Trans>{this.state.workOrderErr}</Trans></span> : null}
                                        </div>*/}
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <h5 className="font-16 font-bold mb-3"><Trans>Location details</Trans></h5>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label w-100" htmlFor="area"><Trans>Area</Trans><span className="color-manditory">*</span></label>
                                            <Select
                                                options={this.state.areaOptions}
                                                value={this.state.selectedArea}
                                                onChange={(e) => this.buildLocationOptions(e, "area", true, this.state.locationsDt, "")}
                                                name='exp-area'
                                                id='exp-area'
                                                className={`${this.state.selectedArea == "" && this.state.isValidToSubmit == false ? 'mstk-dropdown' : ''}`}
                                            />
                                            {this.state.selectedArea == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please select area</Trans></span> : null}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label w-100" htmlFor="aisle"><Trans>Aisle</Trans><span className="color-manditory">*</span></label>
                                            <Select
                                                options={this.state.aisleOptions}
                                                value={this.state.selectedAisle}
                                                onChange={(e) => this.buildLocationOptions(e, "aisle", true, this.state.locationsDt, "")}
                                                name='exp-aisle'
                                                id='exp-aisle'
                                                className={`${this.state.selectedAisle == "" && this.state.isValidToSubmit == false ? 'mstk-dropdown' : ''}`}
                                            />
                                            {this.state.selectedAisle == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please select aisle</Trans></span> : null}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label w-100" htmlFor="row"><Trans>Row</Trans><span className="color-manditory">*</span></label>
                                            <Select
                                                options={this.state.rowOptions}
                                                value={this.state.selectedRow}
                                                onChange={(e) => this.buildLocationOptions(e, "row", true, this.state.locationsDt, "")}
                                                name='exp-row'
                                                id='exp-row'
                                                className={`${this.state.selectedRow == "" && this.state.isValidToSubmit == false ? 'mstk-dropdown' : ''}`}

                                            />
                                            {this.state.selectedRow == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please select row</Trans></span> : null}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label w-100" htmlFor="shelf"><Trans>Shelf</Trans><span className="color-manditory">*</span></label>
                                            <Select
                                                options={this.state.shelfOptions}
                                                value={this.state.selectedShelf}
                                                onChange={(e) => this.buildLocationOptions(e, "shelf", true, this.state.locationsDt, "")}
                                                name='exp-shelf'
                                                id='exp-shelf'
                                                className={`${this.state.selectedShelf == "" && this.state.isValidToSubmit == false ? 'mstk-dropdown' : ''}`}
                                            />
                                            {this.state.selectedShelf == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please select shelf</Trans></span> : null}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label catalog-label w-100" htmlFor="bin"><Trans>Bin</Trans><span className="color-manditory">*</span></label>
                                            <Select
                                                options={this.state.binOptions}
                                                value={this.state.selectedBin}
                                                onChange={(e) => this.buildLocationOptions(e, "bin", true, this.state.locationsDt, "")}
                                                name='exp-bin'
                                                id='exp-bin'
                                                className={`${this.state.selectedBin == "" && this.state.isValidToSubmit == false ? 'mstk-dropdown' : ''}`}
                                            />
                                            {this.state.selectedBin == "" && this.state.isValidToSubmit == false ?
                                                <span className="font-12 font-regular color-validation"><Trans>Please select bin</Trans></span> : null}
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="location" onChange={(e) => this.handleOnChange("checkBox", e)} checked={this.state.isDefChecked} />
                                                <label className="form-check-label font-14 font-regular" htmlFor="location"><Trans>Make as default location</Trans></label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-5">
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-4 py-2" onClick={() => this.handleCancelBtnClick()}><Trans>Cancel</Trans></button>
                                                <button className="btn btn-custom-primary font-15 font-medium px-4 py-2" onClick={() => this.handleSubmitBtnClick()}><Trans>Submit</Trans></button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                        </div>
                        {/*forms ends here*/}

                    </div>
                </div>



                {/*Modal Starts here*/}
                <div className="modal fade success-customer" id="exp-success-popup" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                        <img className="mb-3" src="images/tick.svg" alt="tick" />
                                        <p className="mb-1 font-14 font-regular color-light-black"><Trans>{this.state.isDefChecked ? "Item ordered and default location updated successfully!" : "Item ordered successfully!"}</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            {/*button*/}
                            <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={() => this.handleCancelBtnClick()} ><Trans>OK</Trans></button>
                            </div>
                            {/*button*/}
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

            </>
        );
    };
}
export default withTranslation()(ExpressPutAwayComponent);