




import * as $ from 'jquery';
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';



class RoleMasterStore extends EventEmitter {
    pageLoadResponse = "";
    addNewRoleResponse = "";
    editRoleResponse = "";
    oncheckSistersiteBUResponse = "";
    saveRoleResponse = "";
    deleteRoleResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };


    handleRoleMasterStore = (action) => {
        switch (action.action.type) {

            case SDIConstants.methodNames.RoleMaster_PageLoad: {
                this.pageLoadResponse = action.action.response;
                this.emit('pageLoad');
                break;
            }

            case SDIConstants.methodNames.RoleMaster_AddNewRole: {
                this.addNewRoleResponse = action.action.response;
                this.emit('addNewRole');
                break;
            }

            case SDIConstants.methodNames.RoleMaster_EditRole: {
                this.editRoleResponse = action.action.response;
                this.emit('editRole');
                break;
            }

            case SDIConstants.methodNames.RoleMaster_SistersiteBU: {
                this.oncheckSistersiteBUResponse = action.action.response;
                this.emit('sistersiteBU');
                break;
            }

            case SDIConstants.methodNames.RoleMaster_SaveRole: {
                this.saveRoleResponse = action.action.response;
                this.emit('saveRole');
                break;
            }

            case SDIConstants.methodNames.RoleMaster_DeleteRole: {
                this.deleteRoleResponse = action.action.response;
                this.emit('deleteRole');
                break;
            }

            case "NOTIFY_InsertFavPage": {
                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }

}



const objStore = new RoleMasterStore;
// Dispatcher code
Dispatcher.register(objStore.handleRoleMasterStore.bind(objStore));
export default objStore;