/**
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Order Approval page component. The component displays the Order Approval page details.
 * 
 * App Name: SDI-Walmart
 * Author: Chandrasekar
 * Created Date: 08/18/2020 
 */
import React, { Component } from 'react';
import * as CookieService from '../CookieService';
import * as OrderApprovalAction from '../actions/OrderApprovalAction';
import * as MenuAction from '../actions/MenuAction';
import OrderApprovalStore from '../stores/OrderApprovalStore';
import SDIConstants from '../SDIConstants';
import ReactPaginate from 'react-paginate';
import * as $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "react-multi-select-component";
//[PC_07, 09/09/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R 
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
import ShoppingCartStore from '../stores/ShoppingCartStore';
import Select from 'react-select';
import swal from 'sweetalert';
let searchTxt = "";
//[PC_3818_APP_01 - Johnprinto D]
let searchColumnGlobVar = "";
let searchTextGlobVar = "";
let startDateGlobVar = "";
let endDateGlobVar = "";
let currentPagePath;
let isPostBack = true;
let isFromEmail = false;
let flagValues;
const urlParams = new URLSearchParams(window.location.search);
/* // [06/28/2024] - Zeus 327 - User Name Display - create a variable for send username query params  - Ayyappan.S*/
let QS_username;

let QS_fer;
let QS_op;
let QS_xyz;
let QS_HOME;
let QS_TYPE;
let QS_FROM;
let approvalType = null;
//if (QS_fer || QS_op || QS_xyz) {
//    isFromEmail = true;
//}
class OrderApprovalComponent extends Component {
	constructor(props) {
		//const urlParams = new URLSearchParams(window.location.search);
		//const QS_fer = urlParams.get('fer');
		//const QS_op = urlParams.get('op');
		//const QS_xyz = urlParams.get('xyz');
		//const QS_HOME = urlParams.get('HOME');
		//const QS_TYPE = urlParams.get('TYPE');
		//const QS_FROM = urlParams.get('FROM');
		//if (QS_fer || QS_op || QS_xyz) {
		//    isFromEmail = true;
		//}
		super(props);
		this.state = {
			pageNo: 1,
			itemCount: 10,
			pageCount: 0,
			searchText: "",
			searchColumn: "ALL",
			sortColumn: "ORDER_NO",
			sortValue: "ASC",
			startDate: "",
			endDate: "",
			isSearch: false,
			selectedMultiSites: [],
			OrderApprovalData: [],
			LineItemData: [],
			MultisiteDD: [],
			FlagData: {},
			totalCount: 0,
			isMultiSiteDropdownChange: false,
			filterDisplayName: "All",
			isMultipleApproval: false,
			strAppType: "",
			startDateObj: null,
			endDateObj: null,
			isFilterOpen: false,
			isExpanded: false,
			pnlBUVisible: false,
			isPageLoadedOnce: false,
			isFavorited: false,
            SelectedMultiSiteValue: [], Email: false,
            limitExeData: [],
			//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R 
			ChargeCodeMultiple: false,
			Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
			Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
			PlaceDD1Value: [], PlaceDD1Data: [], PlaceDD2Value: [], PlaceDD2Data: [], PlaceDD3Value: [], PlaceDD3Data: [],
			dropCD1SegValue: [], dropCD1SegData: [], dropCD2SegValue: [], dropCD2SegData: [], dropCD3SegValue: [], dropCD3SegData: [], dropEmpSegValue: [], dropEmpSegData: [],
			dropItmSegValue: [], dropItmSegData: [], dropMchSegValue: [], dropMchSegData: [], dropCD1SegVisible: false, dropCD2SegVisible: false, dropCD3SegVisible: false,
			PlaceDD1Visible: false, PlaceDD2Visible: false, PlaceDD3Visible: false, dropMchSegVisible: false, dropEmpSegVisible: false, dropItmSegVisible: false, ChargeSelected: "",
			ChargeCodeLevel: 0, PlaceDD1_disable: false, PlaceDD2_disable: false, PlaceDD3_disable: false, dropCD1Seg_disable: false, dropCD2Seg_disable: false, dropCD3Seg_disable: false,
			dropEmpSeg_disable: false, dropItmSeg_disable: false, dropMchSeg_disable: false, lineORDER_NO: "", lineISA_INTFC_LN: "", CCPlaceholder: "Select charge code", isBelowMyChain: false, //8/23/22, PS_ZE_176_APP_01, Zeus 176 - Order approval pre filter 
            selectedLineItems: [], Comments: "", disabled_button: true,
            User_Name: "" /* // [06/28/2024] - Zeus 327 - User Name Display - create a state to get assign the username  - Ayyappan.S*/

        };
		this.assignApprovals = this.assignApprovals.bind(this);
		this.onAccordianClick = this.onAccordianClick.bind(this);
		this.handleQuantityChange = this.handleQuantityChange.bind(this);
		this.filterClick = this.filterClick.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleSearchTxt = this.handleSearchTxt.bind(this);
		this.keyPress = this.keyPress.bind(this);
		this.approveDeclineResponse = this.approveDeclineResponse.bind(this);
		this.approveDeclineSingleOrder = this.approveDeclineSingleOrder.bind(this);
		this.approveDeclineMultipleOrder = this.approveDeclineMultipleOrder.bind(this);
		this.handleDatePicker = this.handleDatePicker.bind(this);
		this.applyFilterclick = this.applyFilterclick.bind(this);
		this.cancelFilter = this.cancelFilter.bind(this);
		this.expandAll = this.expandAll.bind(this);
		this.exportToExcel = this.exportToExcel.bind(this);
		this.deleteItems = this.deleteItems.bind(this);
		this.handleMultiSiteDD = this.handleMultiSiteDD.bind(this);
		this.sortingClick = this.sortingClick.bind(this);
		this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
		this.handleFavorite = this.handleFavorite.bind(this);
		this.filtertoggle = this.filtertoggle.bind(this);
	};
	componentDidMount() {
		//const queryParams = new URLSearchParams(window.location.search)
		//const fer = queryParams.get('fer');
		//const op = queryParams.get('op');
		//const pyt = queryParams.get('pyt');
		debugger
		//window.location.reload()
		let urlParam = new URLSearchParams(window.location.search);
		QS_fer = urlParam.get('fer');
		QS_op = urlParam.get('op');
		QS_xyz = urlParam.get('xyz');
        QS_HOME = urlParam.get('HOME');
        QS_username = urlParam.get('un'); // [06/28/2024] - Zeus 327 - User Name Display - Pass the query as 'un'  - Ayyappan.S
		QS_TYPE = urlParam.get('TYPE');
		QS_FROM = urlParam.get('FROM');
		//QS_FROM = from;
		//***NEW****
		isFromEmail = false;
		if (QS_fer || QS_op || QS_xyz) {
			isFromEmail = true;

		}
		console.log("urlParams", urlParam);
		console.log("from", QS_FROM);
		console.log("CookieService.getLocalStorageValue", JSON.parse(atob(localStorage.getItem("SDISession"))));
		this.setState({ Email: isFromEmail})
		//*END*
		if (isFromEmail == true) {
			$('#HeadDIv').removeClass('content-section')
			$('#HeadDIv').addClass('MArgin-65')

		}
		currentPagePath = this.props.location.pathname;
		currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
		let getFavPageBO = {
			Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
			pageURL: currentPagePath.toLowerCase(),
			Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
		};
		OrderApprovalAction.getCurrentFavPage(getFavPageBO);
		//[PC_02, Priyanka D, 09/27/21]Changed isPostBack value to true"
		isPostBack = true;
		this.pageload(this.state.pageNo, this.state.itemCount,
			this.state.searchColumn, this.state.searchText, this.state.sortColumn,
			this.state.sortValue, this.state.startDate, this.state.endDate, this.state.SelectedMultiSiteValue, this.state.isMultiSiteDropdownChange, this.state.isBelowMyChain);
		OrderApprovalStore.on("approvalData", this.assignApprovals);
		OrderApprovalStore.on("approveDeclineResponse", this.approveDeclineResponse);
		OrderApprovalStore.on('favpage', this.loadFavPageResponse);
		OrderApprovalStore.on('updateFavPage', this.updateFavPageResponse);
		//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R 
		ShoppingCartStore.on('GetchargeCOdeBO', this.buildChargeCode)
	};
    componentWillUnmount() {
        OrderApprovalStore.removeListener("approvalData", this.assignApprovals);
        OrderApprovalStore.removeListener("approveDeclineResponse", this.approveDeclineResponse);
        OrderApprovalStore.removeListener('favpage', this.loadFavPageResponse);
        OrderApprovalStore.removeListener('updateFavPage', this.updateFavPageResponse);
        //7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R 
        ShoppingCartStore.removeListener('GetchargeCOdeBO', this.buildChargeCode)
        searchColumnGlobVar = "";
        searchTextGlobVar = "";
        startDateGlobVar = "";
        endDateGlobVar = "";

    }
	pageload = (pageNo, itemCount, searchColumn, searchText, sortColumn, sortValue, startDate, endDate, Multise, isMultiSiteDropdownChange, isBelowMyChain) => {
		console.log("Session_MULTISITEUSER", CookieService.getLocalStorageValues("Session_MULTISITEUSER"));
		//[9/1/2023]Zeus 182 Approvals redirection from notification panel - Poornima S
		let queryParams = new URLSearchParams(window.location.search)
		let orderNo = "";
		//[9/8/2023]Zeus 182 Approvals redirection from notification panel - SIT - 1 bug fix - Johnprint D
		if (queryParams != null && queryParams != undefined && queryParams != "" && queryParams.get('ordNo')) {
			orderNo = queryParams.get('ordNo');
			searchTextGlobVar = orderNo ? orderNo : "";
			searchColumnGlobVar = "ALL";
			let newURL = window.location.href.split("?")[0];
			window.history.pushState('object', document.title, newURL);
		}
		let pageLoadBO = {
			QS_fer: QS_fer ? QS_fer : "",
			QS_op: QS_op ? QS_op : "",
			QS_xyz: QS_xyz ? QS_xyz : "",
            QS_HOME: QS_HOME ? QS_HOME : "",
            QS_username: QS_username ? QS_username : "", // [06/28/2024] - Zeus 327 - Send the query params to backend  - Ayyappan.S
			Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT") ? CookieService.getLocalStorageValues("Session_BUSUNIT") : "",
			Session_USERID: CookieService.getLocalStorageValues("Session_UserID") ? CookieService.getLocalStorageValues("Session_UserID") : "",
			Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU") ? CookieService.getLocalStorageValues("Session_AGENTUSERBU") : "",
			Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID") ? CookieService.getLocalStorageValues("Session_AGENTUSERID") : "",
			isMultiSiteDropdownChange: isMultiSiteDropdownChange,
			Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "",
			Session_PunchinEnd: "",
			Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP") ? CookieService.getLocalStorageValues("Session_SDIEMP") : "",
			Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU") ? CookieService.getLocalStorageValues("Session_SITEBU") : "",
			Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME") ? CookieService.getLocalStorageValues("Session_CONAME") : "",
			QS_TYPE: QS_TYPE ? QS_TYPE : "",
			QS_FROM: QS_FROM ? QS_FROM : "",
			Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : "",
			Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE") ? CookieService.getLocalStorageValues("Session_ITEMMODE") : "",
			Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX") ? CookieService.getLocalStorageValues("Session_SITEPREFIX") : "",
			Session_NSTKONLY: CookieService.getLocalStorageValues("Session_NSTKONLY") ? CookieService.getLocalStorageValues("Session_NSTKONLY") : "",
			Multise: Multise,
			pageNo: pageNo,
			itemCount: itemCount,
			searchColumn: searchColumn,
			searchText: searchText,
			sortColumn: sortColumn,
			sortValue: sortValue,
			startDate: startDate,
			endDate: endDate,
			//[04/18/2023, GOA_PSF_4, Zeus 79] add Session_USERTYPE to order details in order approvals panel for corp admin -Sathis N
			Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : "",
			isBelowMyChain: isBelowMyChain,    //8/23/22, PS_ZE_176_APP_04, Zeus 176 - Order approval pre filter - page load
			//8/30/22, PS_ZE_OAP_APP_01, Zeus 2.0 - Order Approval pageload optimization, Dhevanesam R]
			Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID") ? CookieService.getLocalStorageValues("Session_WEBSITEID") : "",
			OrderNo: orderNo
		};
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		if (isFromEmail) {
			window.document.title = "Need Approve";
			OrderApprovalAction.getApprovalsUnauth(pageLoadBO);
		} else {
			OrderApprovalAction.getApprovals(pageLoadBO);
		}
		//[9/8/2023]Zeus 182 Approvals redirection from notification panel - SIT - 1 bug fix - Johnprint D
		this.setState({ searchText: orderNo != "" ? orderNo : searchTextGlobVar })
	};

	exportToExcel = () => {
		let exportBO = {
			QS_fer: QS_fer ? QS_fer : "",
			Session_NeedApprBUSUNIT: CookieService.getLocalStorageValues("Session_NeedApprBUSUNIT"),
			pnlBUVisible: this.state.pnlBUVisible,
			Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
			Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
			Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
			Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
			//[04/18/2023, GOA_PSF_5, Zeus 79] add Session_USERTYPE to export order details for corp admin -Sathis N
			Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : "",
			//7/21/23, PC_3818_APP_01, Johnprinto D]
			searchText: searchTextGlobVar,
			searchColumn: searchColumnGlobVar,
			startDate: startDateGlobVar,
			endDate: endDateGlobVar,
			isMultiSiteDropdownChange: this.state.isMultiSiteDropdownChange,
			SelectedMultiSiteValue: this.state.SelectedMultiSiteValue,
			isBelowMyChain: this.state.isBelowMyChain, //8/23/22, PS_ZE_176_APP_05, Zeus 176 - Order approval pre filter - export to excel
			Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID") ? CookieService.getLocalStorageValues("Session_WEBSITEID") : ""
		};
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		OrderApprovalAction.exportApprovals(exportBO);
    }

    //[3/12/2024] Z 268 HandleComments() onchange method of comments text to set the target value to Comments state variable - Poornima S
    HandleComments = (e) => {
        let cmntTxt = e.target.value;
        let btnDisable = this.state.disabled_button;
        if (cmntTxt.trim() != "") {
            btnDisable = false;
        }
        else {
            btnDisable = true;
        }

        this.setState({ Comments: e.target.value, disabled_button: btnDisable })
    }

    //[3/12/2024]Z 256 closeDeclineComment() method is triggered when close icon or cancel button of decline comments pop up is clicked to clear the elements and state variable - Poornima S
    closeDeclineComment = () => {
        debugger
        $('#DeclineComments').modal('hide');
        this.setState({ Comments: "", selectedLineItems: [], disabled_button: true })
    }

    //[4/15/2024] Z- 268 CR to close approval comments while clicking cancel button and close icon 
    closeApprovalComments = () => {
        $('#ApprovalComments').modal('hide');
        this.setState({ Comments: "" })
    }

	approveDeclineResponse = () => {
		//[11/30/21, PC_OAT_01, CAS - 31], Poornima S//Added translation keywords for alerts
		const { t } = this.props;
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let approveDeclineResponse = JSON.parse(OrderApprovalStore.approveDeclineResponse);
		console.log("approveDeclineResponse", approveDeclineResponse);
		if (approveDeclineResponse.isSuccess == true) {
			//[8/1/22, PC_OA_02, Bug - 2150 - Alerting the user if there is no next approver, Dhevanesam R
            if (approveDeclineResponse.lblAlert != null && approveDeclineResponse.lblAlert != "" && approveDeclineResponse.lblAlert != " " && approveDeclineResponse.lblAlert != undefined) {
                //[Zeus 255, 01/22/2024] - Modified the next approver message format - Kishore
                let orderLimitDetails = approveDeclineResponse.lblAlert.split("||");
                if (orderLimitDetails.length > 3) {
                    this.setState({ limitExeData: orderLimitDetails });
                    if (approvalType == 2) {
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: t("Your order has been declined successfully")
                        }).then(() => {
                            $('#limit-exceeded').modal('show');
                        });
                    }
                    else {
                        $('#limit-exceeded').modal('show');
                    }
                }
                else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: `${approveDeclineResponse.lblAlert}`
                    })
                }
                //alert(t(approveDeclineResponse.lblAlert));
            }
			else {
				if (approvalType == 1) {
					swal({
						allowOutsideClick: false,
						closeOnClickOutside: false,
						text: t("Your order has been approved successfully")
					}).then(function () {
						//[09 /08/2023] PS_SR_Bug_4183_API_03 - 4183 - Bug fix - Johnprinto D
                    //window.location.reload();
					});
				}
				else {
					swal({
						allowOutsideClick: false,
						closeOnClickOutside: false,
						text: t("Your order has been declined successfully")
					}).then(function () {
						//[09 /08/2023] PS_SR_Bug_4183_API_03 - 4183 - Bug fix - Johnprinto D
                        //window.location.reload();
					});
				}
				//alert(t("Your Order has been Approved/Declined Successfully"));
			}
			//window.location.reload();
		} else {
			if (approveDeclineResponse.lblAlert != null && approveDeclineResponse.lblAlert != "" && approveDeclineResponse.lblAlert != " " && approveDeclineResponse.lblAlert != undefined) {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t(approveDeclineResponse.lblAlert)
				}).then(function () {
					//[09 /08/2023] PS_SR_Bug_4183_API_03 - 4183 - Bug fix - Johnprinto D
                        //window.location.reload();
				});
				// alert(t(approveDeclineResponse.lblAlert));
				// window.location.reload();
			}
			else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Problem in approval/decline process, Your Order has not been Approved/Declined")
				})
				//alert(t("Problem in approval/decline process, Your Order has not been Approved/Declined"));
			}
        }
        //[09 /08/2023] PS_SR_Bug_4173_APP_01 -Hide the date filter - Johnprinto D
        this.setState({ isFilterOpen: false, pageNo: 1, Comments: "", disabled_button: true });
        //[09 /08/2023] PS_SR_Bug_4183_API_03 - 4183 - Bug fix - Johnprinto D
        this.pageload(1, this.state.itemCount,
            searchColumnGlobVar, searchTextGlobVar, this.state.sortColumn,
            this.state.sortValue, startDateGlobVar, endDateGlobVar,
            this.state.SelectedMultiSiteValue, this.state.isMultiSiteDropdownChange, this.state.isBelowMyChain);
    }
	assignApprovals = () => {
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let approvalData = JSON.parse(OrderApprovalStore.approvalData);
        console.log("approvalData", approvalData);
        let userName; // [06/28/2024] - Zeus 327 -Create a variable to assign the userName value from backend  - Ayyappan.S

		//isMultipleApproval
		if (approvalData) {
			let isMultipleApproval = approvalData.FlagData.length > 0 ? approvalData.FlagData[0].isMultipleApproval.toLowerCase() == "true" : false;
			if (approvalData.LineItemData.length > 0) {
				approvalData.LineItemData.forEach((lineItem) => {
					lineItem.extPrice = lineItem.lblConvertedExtPrice;
					lineItem.chkDeleted = false;
				});
			};
			if (approvalData.OrderApprovalData.length > 0) {
				approvalData.OrderApprovalData.forEach((order, dupindex, object) => {
					order.checked = false;
				});

			};
			console.log("MultisiteDD",approvalData.MultisiteDD);
			//if (this.state.isPageLoadedOnce==false) {
			let tempMultiSites = [];

			if (approvalData.MultisiteDD) {
				if (approvalData.MultisiteDD.length > 0) {
					approvalData.MultisiteDD.forEach((multiSite, multiSiteindex) => {
						tempMultiSites.push({ label: multiSite.DESCR, value: multiSite.BUSINESS_UNIT });
					});
				}
			}
			//$("#multisitedrop").hide();
			////***NEW****

			//if (isFromEmail==false) {

			//    if (approvalData.MultisiteDD) {
			//        if (approvalData.MultisiteDD.length > 0) {
			//            $("#multisitedrop").show();
			//        }

			//    }

			//}

			if (approvalData.FlagData) {
				if (approvalData.FlagData.length > 0) {
                    flagValues = approvalData.FlagData[0];
                    userName = flagValues.QS_username; // [06/28/2024] - Zeus 327 - Assign the userName value from backend  - Ayyappan.S
					flagValues["Session_UserID"] = flagValues.Session_USERID;
					if (isFromEmail) {
						let isTokenPresent = CookieService.getCookieValue(SDIConstants.access_token);
						if (isTokenPresent == "") {
							localStorage.clear();
							CookieService.insertLocalStorageValues(flagValues);
							console.log("InsertCookies");
						}
					}
				}
			}
			debugger;
			if (this.state.isPageLoadedOnce && this.state.totalCount == 0 && isPostBack == true && isFromEmail == false) {
				console.log("totalCount", this.state.totalCount);

			}
			//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
			let Update_Session = approvalData.Update_Session;
			let MultiCHargeCOde = false

			if (Update_Session != null && Update_Session != "") {
				if (Update_Session.length > 0) {
					if (Update_Session[0].ChargeCode_Enable != null && Update_Session[0].ChargeCode_Enable != "") {
						if (Update_Session[0].ChargeCode_Enable.toUpperCase() != "FALSE") {
							debugger
							MultiCHargeCOde = true
						}
					}
				}

			}

			CookieService.updateLocalStorageValues("Session_NeedApprBUSUNIT", approvalData.FlagData.length > 0 ? approvalData.FlagData[0].Session_NeedApprBUSUNIT : "");
			this.setState({
				OrderApprovalData: approvalData.OrderApprovalData,
				LineItemData: approvalData.LineItemData,
				isPageLoadedOnce: true,
				totalCount: approvalData.OrderTotlaCount.length > 0 ? approvalData.OrderTotlaCount[0].TOTAL_COUNT : 0,
				pageCount: Math.ceil((approvalData.OrderTotlaCount.length > 0 ? approvalData.OrderTotlaCount[0].TOTAL_COUNT : 0) / this.state.itemCount),
				isMultipleApproval: isMultipleApproval,//isMultipleApproval,
				strAppType: approvalData.FlagData.length > 0 ? approvalData.FlagData[0].strAppType : "",
				pnlBUVisible: approvalData.FlagData.length > 0 ? approvalData.FlagData[0].pnlBUVisible : false,
				MultisiteDD: tempMultiSites,
                ChargeCodeMultiple: MultiCHargeCOde,
                User_Name: userName // [06/28/2024] - Zeus 327 - Setstate the state by userName varibale - Ayyappan.S
			});
		}
		//[7/12/2023, PS_ZE_142_APP_20, Dhevanesam R]
		MenuAction.refreshMasterData()
	};


	handleSearchTxt = (e) => {
		this.setState({ searchText: e.target.value });
	}
	keyPress(e) {
		if (e.keyCode == 13) {
			this.handleSearch();
		}
    };
	handleSearch = () => {
		let pageNo = 1;
		//[PC_3818_APP_04 - Johnprinto D]
		//searchTxt = $(`#searchTxt`).val().trim();
		searchColumnGlobVar = this.state.searchColumn;
		searchTextGlobVar = this.state.searchText;
		startDateGlobVar = this.state.startDate;
		endDateGlobVar = this.state.endDate;
		isPostBack = false;
		this.pageload(pageNo, this.state.itemCount,
			this.state.searchColumn, this.state.searchText, this.state.sortColumn,
			this.state.sortValue, this.state.startDate, this.state.endDate,
			this.state.SelectedMultiSiteValue, this.state.isMultiSiteDropdownChange, this.state.isBelowMyChain);

		this.setState({ pageNo: pageNo});
    };
    ClearSearch = () => {
        this.setState({ searchText: "" });
    }
	sortingClick = (sortColumn, sortValue) => {
		console.log(sortColumn, sortValue);
		//[PC_3818_APP_06 - Johnprinto D]
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
		let pageNo = 1;
		isPostBack = false;
		/*[PC_01, Bug-1779, Priyanka D]*/
		this.pageload(pageNo, this.state.itemCount,
			searchColumnGlobVar, searchTextGlobVar, sortColumn,
			sortValue, startDateGlobVar, endDateGlobVar,
			this.state.SelectedMultiSiteValue,this.state.isMultiSiteDropdownChange, this.state.isBelowMyChain);
		this.setState({ pageNo: pageNo, sortColumn: sortColumn, sortValue: sortValue });
	}
	//7/4/22, PC_OA_17, CAS - Waiting order approval panel query change, Dhevanesam R
	handlePageClick = (data) => {
		let pageNo = data.selected + 1;

		this.setState({ pageNo: pageNo, isExpanded: false });
		//[PC_3818_APP_05 - Johnprinto D]
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
		isPostBack = false;
		this.pageload(pageNo, this.state.itemCount,
			searchColumnGlobVar, searchTextGlobVar, this.state.sortColumn,
			this.state.sortValue, startDateGlobVar, endDateGlobVar, this.state.SelectedMultiSiteValue, this.state.isMultiSiteDropdownChange, this.state.isBelowMyChain);
	};
	handleMultiSiteDD = (values) => {
		this.setState({ selectedMultiSites: values });
		let selectedMultiSites = [];
		values.forEach((selected) => {
			selectedMultiSites.push({ BUSINESS_UNIT: selected.value, SIteText: selected.label});
		});
		debugger;
		/***NEW30/12/2020***************/
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
		/*****END***************************/
		isPostBack = false;
		this.pageload(1,
			this.state.itemCount,
			searchColumnGlobVar,
			searchTextGlobVar,
			"ORDER_NO",
			"ASC",
			"",
			"",
			selectedMultiSites,
			values.length > 0 ? true : false,
			this.state.isBelowMyChain);
		$(`#searchTxt`).val("");
		searchColumnGlobVar = "ALL";
		searchTextGlobVar = "";
		startDateGlobVar = "";
		endDateGlobVar = "";
		this.setState({
			pageNo: 1,
			searchColumn: "ALL",
			sortColumn: "ORDER_NO",
			sortValue: "ASC",
			startDate: null,
			endDate: null,
			selectedMultiSites: values,
			SelectedMultiSiteValue:selectedMultiSites,
			isMultiSiteDropdownChange: values.length > 0 ? true : false
		});
	}
	filterClick = (event, displayName, columnName) => {
		event.preventDefault();
		this.setState({ filterDisplayName: displayName, searchColumn: columnName });

	};
	getDateString = (date) => {
		return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear())
	};
	handleDatePicker = (date, name) => {
		this.setState({
			[name]: date
		});
		console.log("Date", date);
	};
	applyFilterclick = () => {
		const { t } = this.props;
		let startDate = this.state.startDateObj ? this.getDateString(this.state.startDateObj) : "";
		let endDate = this.state.endDateObj ? this.getDateString(this.state.endDateObj) : "";
		console.log(startDate, endDate);


		//[PC_3818_APP_07 - Johnprinto D]
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
		isPostBack = false;
		//[4/13/22, Bug - 2060, Dhevanesam R]
		if (startDate != "" && endDate != "") {
			startDateGlobVar = startDate;
			endDateGlobVar = endDate;
			this.pageload(1,
				this.state.itemCount,
				searchColumnGlobVar,
				searchTextGlobVar,
				this.state.sortColumn,
				this.state.sortValue,
				startDate,
				endDate,
				this.state.SelectedMultiSiteValue,
				this.state.isMultiSiteDropdownChange,
				this.state.isBelowMyChain);
			this.setState({ pageNo: 1, startDate: startDate, endDate: endDate });
			this.filtertoggle();
		} else {
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Please select From and To Date")
			})
			//alert(t("Please select From and To Date"))
		}
	};
	cancelFilter = () => {
		this.setState({
			startDateObj: null,
			endDateObj: null
		})
		//[PC_3818_APP_08 - Johnprinto D]
		startDateGlobVar = "";
		endDateGlobVar = "";
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
		isPostBack = false;
		this.pageload(1,
			this.state.itemCount,
			searchColumnGlobVar,
			searchTextGlobVar,
			this.state.sortColumn,
			this.state.sortValue,
			startDateGlobVar,
			endDateGlobVar,
			this.state.SelectedMultiSiteValue,
			this.state.isMultiSiteDropdownChange,
			this.state.isBelowMyChain);
		this.setState({ pageNo: 1, startDate: startDateGlobVar, endDate: endDateGlobVar });
		this.filtertoggle();
	}
	filtertoggle = (e) => {
		if (e)
			e.preventDefault();
		this.setState({ isFilterOpen: this.state.isFilterOpen == true ? false : true });
	};
	expandAll = () => {
		if (this.state.isExpanded == false) {
			this.setState({ isExpanded: true });
			$('.header-arrow').removeClass('accordion-arrow-change');
			$('.content-row').addClass('d-none');
			$('.header-arrow').addClass('accordion-arrow-change');
			$('.content-row').removeClass('d-none');
		} else {
			this.setState({ isExpanded: false });
			$('.header-arrow').addClass('accordion-arrow-change');
			$('.content-row').removeClass('d-none');
			$('.header-arrow').removeClass('accordion-arrow-change');
			$('.content-row').addClass('d-none');

		}
	}
	onAccordianClick = (event, index) => {
		$(event.currentTarget).children().toggleClass('accordion-arrow-change');
		$(`#contentrow-${index}`).toggleClass('d-none');
		$('tr').removeClass('tr-border');
		$(event.currentTarget).parent('tr').addClass('tr-border');
	};

    approveDeclineOrder = (orderDetails, type, NotesSubmitStatus, Comments) => {
		debugger;
		let cookieValuesLocal = {};
		if (isFromEmail) {
			cookieValuesLocal = {
				Session_USERID: flagValues.Session_UserID ? flagValues.Session_UserID : "",
				Session_BUSUNIT: flagValues.Session_BUSUNIT ? flagValues.Session_BUSUNIT : "",
				Session_AGENTUSERID: flagValues.Session_AGENTUSERID ? flagValues.Session_AGENTUSERID : "",
				Session_AGENTUSERBU: flagValues.Session_AGENTUSERBU ? flagValues.Session_AGENTUSERBU : "",
				Session_ITEMMODE: flagValues.Session_ITEMMODE ? flagValues.Session_ITEMMODE : "",
				Session_SITEPREFIX: flagValues.Session_SITEPREFIX ? flagValues.Session_SITEPREFIX : "",
				Session_IOLServer: flagValues.Session_IOLServer ? flagValues.Session_IOLServer : "",
				Session_cplusServer: flagValues.Session_cplusServer ? flagValues.Session_cplusServer : "",
			}
		} else {
			cookieValuesLocal = {
				Session_USERID: CookieService.getLocalStorageValues("Session_UserID") ? CookieService.getLocalStorageValues("Session_UserID") : "",
				Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT") ? CookieService.getLocalStorageValues("Session_BUSUNIT") : "",
				Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID") ? CookieService.getLocalStorageValues("Session_AGENTUSERID") : "",
				Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU") ? CookieService.getLocalStorageValues("Session_AGENTUSERBU") : "",
				Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE") ? CookieService.getLocalStorageValues("Session_ITEMMODE") : "",
				Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX") ? CookieService.getLocalStorageValues("Session_SITEPREFIX") : "",
				Session_IOLServer: CookieService.getLocalStorageValues("Session_IOLServer") ? CookieService.getLocalStorageValues("Session_IOLServer") : "",
				Session_cplusServer: CookieService.getLocalStorageValues("Session_cplusServer") ? CookieService.getLocalStorageValues("Session_cplusServer") : "",
			}
		}


		let approveDeclineBO = {
			hdnLineItem: "",
			hdnHdrItem: "AppHeaderItem",
			Session_USERID: cookieValuesLocal.Session_USERID,
			Session_BUSUNIT: cookieValuesLocal.Session_BUSUNIT,
			SESSION_SITE_CURRENCY: "",
			Session_AGENTUSERID: cookieValuesLocal.Session_AGENTUSERID,
			Session_AGENTUSERBU: cookieValuesLocal.Session_AGENTUSERBU,
			Session_ITEMMODE: cookieValuesLocal.Session_AGENTUSERBU,
			Session_SITEPREFIX: cookieValuesLocal.Session_SITEPREFIX,
			Session_IOLServer: cookieValuesLocal.Session_IOLServer,
			Session_cplusServer: cookieValuesLocal.Session_cplusServer,
			Session_OrdPlacedBy: "",
			txtTypeAppr: "W",
			OrderDetailsLst: orderDetails,
			//[04/18/2023, GOA_PSF_8, Zeus 79] add Session_USERTYPE to trigger mail to pending approver case of corp admin approval  -Sathis N
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : "",
            Comments: Comments.trim(),
            NotesSubmitStatus: NotesSubmitStatus

		};
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (type == 1) {
            $('#ApprovalComments').modal('hide');
			if (isFromEmail) {
				OrderApprovalAction.approveHeaderOrdersUnAuth(approveDeclineBO);
			} else {
				OrderApprovalAction.approveHeaderOrders(approveDeclineBO);
			}
        } else {
            $('#DeclineComments').modal('hide');
			if (isFromEmail) {
				OrderApprovalAction.declineHeaderOrdersUnAuth(approveDeclineBO);
			} else {
				OrderApprovalAction.declineHeaderOrders(approveDeclineBO);
			}
		}
	};
	approveDeclineMultipleOrder = (type) => {
		const { t } = this.props;
		let orderData = [];
		let currencySymbol = "";
		let totalAmount = "";
		let orderDetails = this.state.OrderApprovalData.filter((order) => order.checked == true);
		if (orderDetails.length > 0) {
			orderDetails.forEach((orderDetail) => {
				let lineItms = this.state.LineItemData.filter((x) => x.ORDER_NO == orderDetail.ORDER_NO);

				if (orderDetail.Ordertotal) {
					currencySymbol = orderDetail.Ordertotal.split(" ").length > 0 ? orderDetail.Ordertotal.split(" ")[0] : "";
					totalAmount = orderDetail.Ordertotal.split(" ").length > 0 ? orderDetail.Ordertotal.split(" ")[1] : ""
				};
				//[8/1/22, PC_OA_03, Bug - 2150 - To send the currency and price values for the mail bindings, Dhevanesam R
				let selectedLineItms = [];
				if (lineItms.length > 0) {
					lineItms.forEach((lineItem, index) => {
						selectedLineItms.push({
							hdnLineItemValue: "",
							hdnLineNum: lineItem.ISA_INTFC_LN,
							txtItemChgCode: lineItem.ISA_CUST_CHARGE_CD,
							ltrlItemNumber: lineItem.ltrlItemNumber,
							txtQTY: lineItem.QTY_REQUESTED ? lineItem.QTY_REQUESTED : 0,
							lblItemBasePrice: lineItem.lblBasePrice,
							lblStockTyp: lineItem.CCINV_STOCK_TYPE ? lineItem.CCINV_STOCK_TYPE : "",
							chkDeleted: lineItem.chkDeleted,
							itemDescription: lineItem.DESCR254,
							CurrencyCD_BO: lineItem.CURRENCY_CD,
							ItemBasePriceUSDBO: parseFloat(lineItem.lblConvertedPrice).toFixed(4),
							ItemExtPriceUSDBO: lineItem.lblExtPriceUSD
						});
					});
				};
				orderData.push({
					chkSelectedOrder: false,
					lblOrderNo: orderDetail.ORDER_NO,
					lblBuID: orderDetail.BUSINESS_UNIT,
					lblWorkOrdNo: orderDetail.ISA_WORK_ORDER_NO,
					lblOrderApprovalFlag11: "",
					lblApprovalProcessFlag11: "",
					hdnHdrAppDec: "ApproveHeaderItem",
					lblCurrencySymbol: currencySymbol,
					lblAmount: totalAmount,
					OPRID_ENTERED_BY: orderDetail.OPRID_ENTERED_BY,
					totalLineItems: selectedLineItms.length,
					ChildItem: selectedLineItms,
					CURRENT_APPR_ID: orderDetail.CURRENT_APPR_ID //[04/18/2023, GOA_PSF_7, Zeus 79] add CURRENT_APPR_ID to trigger mail to pending approver case of corp admin approval  -Sathis N
				});
			});
            if (type == 1) {
                $('#ApprovalComments').modal('show');
            }
            else {
                $('#DeclineComments').modal('show');
            }

            this.setState({ selectedLineItems: orderData });

		} else {
			if (type == 1) {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("No Orders are selected to approve")
				})
				//alert(t("No Orders are selected to approve"));
			} else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("No Orders are selected to decline")
				})
				//alert(t("No Orders are selected to decline"));
			}
		};
	};

	approveDeclineSingleOrder = (orderDetails, type, event) => {
		approvalType = type;
		if (event) {
			event.preventDefault();
		}
		let orderData = [];
		let currencySymbol = "";
		let totalAmount = "";
		let lineItms = this.state.LineItemData.filter((x) => x.ORDER_NO == orderDetails.ORDER_NO);

		if (orderDetails.Ordertotal) {
			currencySymbol = orderDetails.Ordertotal.split(" ").length > 0 ? orderDetails.Ordertotal.split(" ")[0] : "";
			totalAmount = orderDetails.Ordertotal.split(" ").length > 0 ? orderDetails.Ordertotal.split(" ")[1] : ""
		};
		//[8/1/22, PC_OA_03, Bug - 2150 - To send the currency and price values for the mail bindings, Dhevanesam R
		let selectedLineItms = [];
		if (lineItms.length > 0) {
			lineItms.forEach((lineItem, index) => {
				selectedLineItms.push({
					hdnLineItemValue: "",
					hdnLineNum: lineItem.ISA_INTFC_LN,
					txtItemChgCode: lineItem.ISA_CUST_CHARGE_CD,
					ltrlItemNumber: lineItem.ltrlItemNumber,
					txtQTY: lineItem.QTY_REQUESTED ? lineItem.QTY_REQUESTED : 0,
					lblItemBasePrice: lineItem.lblBasePrice,
					lblStockTyp: lineItem.CCINV_STOCK_TYPE ? lineItem.CCINV_STOCK_TYPE : "",
					chkDeleted: lineItem.chkDeleted,
					itemDescription: lineItem.DESCR254,
					CurrencyCD_BO: lineItem.CURRENCY_CD,
					ItemBasePriceUSDBO: parseFloat(lineItem.lblConvertedPrice).toFixed(4),
					ItemExtPriceUSDBO: lineItem.lblExtPriceUSD
				});
			});
		};
		orderData.push({
			chkSelectedOrder: false,
			lblOrderNo: orderDetails.ORDER_NO,
			lblBuID: orderDetails.BUSINESS_UNIT,
			lblWorkOrdNo: orderDetails.ISA_WORK_ORDER_NO,
			lblOrderApprovalFlag11: "",
			lblApprovalProcessFlag11: "",
			hdnHdrAppDec: "ApproveHeaderItem",
			lblCurrencySymbol: currencySymbol,
			lblAmount: totalAmount,
			OPRID_ENTERED_BY: orderDetails.OPRID_ENTERED_BY,
			totalLineItems: selectedLineItms.length,
			ChildItem: selectedLineItms,
			CURRENT_APPR_ID: orderDetails.CURRENT_APPR_ID //[04/18/2023, GOA_PSF_6, Zeus 79] add CURRENT_APPR_ID to trigger mail to pending approver case of corp admin approval  -Sathis N

		});
        if (type == 1) {
            $('#ApprovalComments').modal('show');
        }
        else {
            $('#DeclineComments').modal('show');
        }

        this.setState({ selectedLineItems: orderData });
	}
	handleQuantityChange = (e, lineID, OrderNO) => {
		const re = SDIConstants.quantityRegEX;
		if (e.target.value === '' || re.test(e.target.value)) {
			if (e.target.value != "0") {
				let { LineItemData } = this.state;
				let index = LineItemData.findIndex(x => x.ORDER_NO == OrderNO && x.ISA_INTFC_LN == lineID);
				LineItemData[index].QTY_REQUESTED = e.target.value;
				if (LineItemData[index].extPrice && LineItemData[index].lblConvertedPrice && LineItemData[index].QTY_REQUESTED) {
					LineItemData[index].extPrice = parseFloat(LineItemData[index].QTY_REQUESTED) * parseFloat(LineItemData[index].lblConvertedPrice);
					LineItemData[index].extPrice = LineItemData[index].extPrice.toFixed(2);
				};
				this.setState(LineItemData);
			};
		};
	};
	selectOrders = (Orderindex) => {
		let { OrderApprovalData } = this.state;

		OrderApprovalData[Orderindex].checked = OrderApprovalData[Orderindex].checked == true ? false : OrderApprovalData[Orderindex].checked == false ? true : true;
		this.setState(OrderApprovalData);
	};
	deleteItems = (value, lineID, OrderNO) => {
		let { LineItemData } = this.state;
		let indexVal = LineItemData.findIndex(x => x.ORDER_NO == OrderNO && x.ISA_INTFC_LN == lineID);
		LineItemData[indexVal].chkDeleted = value;
		this.setState(LineItemData);
	};
	//******NEW(12/23/2020)*******************
	ItemTotalCal = (ItemPrice, Quantity) => {
		if (ItemPrice != "") {
			let ItemTotal = 0;
			//  let SiteCurrency = this.state.SiteCurrencyTable;
			let uniteprice = parseFloat(ItemPrice) ? parseFloat(ItemPrice) : 0
			let QuantityAvail = parseFloat(Quantity) ? parseFloat(Quantity) : 0
			if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
				ItemTotal = uniteprice * QuantityAvail;
				ItemTotal = ItemTotal.toFixed(2)
				//ItemTotal = uniteprice;
			}
			return ( ItemTotal)
		}
		else {
			return (ItemPrice)
		}
	}
	//*******************************
	//[10/18/21, PC_02, Bug ID - 1732, Dhevanesam R]
	calculateTotal = (ORDER_NO) => {
		debugger
		let TotalPrice = "0";
		let lineItemsArray = this.state.LineItemData.filter((x) => x.ORDER_NO == ORDER_NO);
		lineItemsArray.map((line, index) => {
			//6/10/22, PC_OA_03, CAS - Waiting order approval panel query change, Dhevanesam R]
			let PriceFloatvalue = line.lblConvertedPrice;
			let Pricevalue = parseFloat(PriceFloatvalue) ? parseFloat(PriceFloatvalue) : 0
			let QTY = parseFloat(line.QTY_REQUESTED) ? parseFloat(line.QTY_REQUESTED) : 0;
			if (Pricevalue != NaN && QTY != NaN && Pricevalue != 0 && QTY != 0) {
				let extensionPrice = Pricevalue * QTY;
				TotalPrice = parseFloat(TotalPrice) + parseFloat(extensionPrice);
				TotalPrice = TotalPrice.toFixed(2)
			}
			let CurrencyCD = "USD";
			//console.log("CURRENCY_CD", CurrencyCD);            
			//TotalPrice = "$" + TotalPrice + " " + "USD";
			TotalPrice = TotalPrice + " " + CurrencyCD
		})

		//return TotalPrice;
		return (TotalPrice);
	};
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	GetChargeCode = (ORDER_NO, ISA_INTFC_LN, ID) => {
		debugger
		let CCArray = [];
		var DD1 = "";
		var DD2 = "";
		var DD3 = "";
		if (ID != null && ID != "" && ID != " " && ID != undefined) {
			ID.includes("--") ? CCArray = ID.split("--") : CCArray = ID.split("-");
			if (CCArray.length >= 4) {
				debugger
				DD1 = CCArray[0] + "-" + CCArray[1];
				DD2 = CCArray[2];
				DD3 = CCArray[3];
			}
			else {
				DD1 = CCArray[0];
				DD2 = CCArray[1];
				DD3 = CCArray[2];
			}


		}
		else {
			DD1 = "";
			DD2 = "";
			DD3 = "";
		}
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		if (ID != null && ID != "" && ID != " " && ID != undefined) {
			this.setState({ PlaceDD1Value: DD1, PlaceDD2Value: DD2, PlaceDD3Value: DD3, ChargeSelected: ID });
		}
		else{
			this.setState({ PlaceDD1Value: [], PlaceDD2Value: [], PlaceDD3Value: [], ChargeSelected: [] });
		}
		this.setState({
            lineORDER_NO: ORDER_NO, lineISA_INTFC_LN: ISA_INTFC_LN
        });
		let BuildChrCDBO = {
			UserId: this.state.Login_UserId,
			UserBU: this.state.Login_UserBU,
			dropCD1SegValue: "",
			dropCD2SegValue: "",
			dropCD3SegValue: "",
			dropCD1SegFilled: false,
			dropCD2SegFilled: false,
			dropCD1Seg_Visible: this.state.dropCD1SegVisible,
			dropCD2Seg_Visible: this.state.dropCD2SegVisible,
			dropCD3Seg_Visible: this.state.dropCD3SegVisible,
		}
		ShoppingCartAction.GetchargeCOde(BuildChrCDBO)
	}
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	buildChargeCode = () => {
		debugger
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let GetchargeCOdeBO = JSON.parse(ShoppingCartStore.GetchargeCOdeBO);
		let ChargeCOdeTable = GetchargeCOdeBO.ChargeCOdeTable
		let ChargeCOdeType = GetchargeCOdeBO.ChargeCOdeType
		let CCArray = [];
		let ChargeSelected = "";
		var DD1 = [];
		var DD2 = [];
		var DD3 = [];
		let PlaceDD1Val = "";
		let PlaceDD2Val = "";
		let PlaceDD3Val = "";
		let PlaceDDdefault = ""
		if (this.state.ChargeSelected != null && this.state.ChargeSelected != "" && this.state.ChargeSelected != " " && ChargeSelected != undefined) {
			this.state.ChargeSelected.includes("--") ? CCArray = this.state.ChargeSelected.split("--") : CCArray = this.state.ChargeSelected.split("-");
			//var DD1 = CCArray[0];
			//var DD2 = CCArray[1];
			//var DD3 = CCArray[2];
			if (CCArray.length >= 4) {
				debugger
				DD1 = CCArray[0] + "-" + CCArray[1];
				DD2 = CCArray[2];
				DD3 = CCArray[3];
			}
			else {
				DD1 = CCArray[0];
				DD2 = CCArray[1];
				DD3 = CCArray[2];
			}
		}
		if (ChargeCOdeTable[0].PlaceDD1Value != null && ChargeCOdeTable[0].PlaceDD1Value != " " && ChargeCOdeTable[0].PlaceDD1Value != "" && ChargeCOdeTable[0].PlaceDD1Value != undefined) {
			PlaceDD1Val = ChargeCOdeTable[0].PlaceDD1Value
		} else if ((DD1 != null && DD1 != "" && DD1 != " " && DD1 != undefined)) {
			PlaceDD1Val = DD1;
		}
		if (ChargeCOdeTable[0].PlaceDD2Value != null && ChargeCOdeTable[0].PlaceDD2Value != " " && ChargeCOdeTable[0].PlaceDD2Value != "" && ChargeCOdeTable[0].PlaceDD2Value != undefined) {
			PlaceDD2Val = ChargeCOdeTable[0].PlaceDD2Value
		} else if (DD2 != null && DD2 != "" && DD2 != " " && DD2 != undefined) {
			PlaceDD2Val = DD2;
		}
		if (ChargeCOdeTable[0].PlaceDD3Value != null && ChargeCOdeTable[0].PlaceDD3Value != " " && ChargeCOdeTable[0].PlaceDD3Value != "" && ChargeCOdeTable[0].PlaceDD3Value != undefined) {
			PlaceDD3Val = ChargeCOdeTable[0].PlaceDD3Value
		} else if (DD3 != null && DD3 != "" && DD3 != " " && DD3 != undefined) {
			PlaceDD3Val = DD3;
		}
		let PlaceDD1 = this.buildDropDownValue(GetchargeCOdeBO.PlaceDD1, PlaceDDdefault)
		let PlaceDD1Data = PlaceDD1.ReturnTable;
		PlaceDD1Data = PlaceDD1Data.filter((item, index) => item.value == PlaceDD1Val);
		let PlaceDD2 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.PlaceDD2, ChargeCOdeTable[0].PlaceDD2Value) : ""
		let PlaceDD3 = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.PlaceDD3, ChargeCOdeTable[0].PlaceDD3Value) : ""
		let dropCD1Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD1Seg, ChargeCOdeTable[0].dropCD1SegValue) : ""
		let dropCD2Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD2Seg, ChargeCOdeTable[0].dropCD2SegValue) : ""
		let dropCD3Seg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropCD3Seg, ChargeCOdeTable[0].dropCD3SegValue) : ""
		let dropEmpSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropEmpSeg, ChargeCOdeTable[0].dropEmpSegValue) : ""
		let dropItmSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropItmSeg, ChargeCOdeTable[0].dropItmSegValue) : ""
		let dropMchSeg = GetchargeCOdeBO != undefined && ChargeCOdeTable != undefined && GetchargeCOdeBO != null && ChargeCOdeTable != null && GetchargeCOdeBO != [] && ChargeCOdeTable != [] ? this.buildDropDownValue(GetchargeCOdeBO.dropMchSeg, ChargeCOdeTable[0].dropMchSegValue) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD1Val, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD2Val, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(PlaceDD3Val, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD1SegValue, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD2SegValue, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropCD3SegValue, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropEmpSegValue, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropItmSegValue, ChargeSelected) : ""
		ChargeSelected = ChargeCOdeTable != undefined && ChargeCOdeTable != null && ChargeCOdeTable != [] ? this.buildChargecodevalue(ChargeCOdeTable[0].dropMchSegValue, ChargeSelected) : ""
		let PlaceDD2Data = PlaceDD2.ReturnTable;
		PlaceDD2Data = PlaceDD2Data.filter((item, index) => item.value == PlaceDD2Val);
		let PlaceDD3Data = PlaceDD3.ReturnTable;
		PlaceDD3Data = PlaceDD3Data.filter((item, index) => item.value == PlaceDD3Val);

		if (ChargeCOdeTable[0].ShowAllFieldFillLabel == "True") {
			$('#ChargeCodeLabelDiv').removeClass('d-none')
			$('#ChargeCodeLabel').removeClass('errorcolour')
		}
		else {
			$('#ChargeCodeLabelDiv').addClass('d-none')
		}

		this.setState({
			PlaceDD1Value: PlaceDD1Data, PlaceDD1Data: PlaceDD1.ReturnTable, ChargeSelected: ChargeSelected,
			PlaceDD2Value: PlaceDD2Data, PlaceDD2Data: PlaceDD2.ReturnTable,
			PlaceDD3Value: PlaceDD3Data, PlaceDD3Data: PlaceDD3.ReturnTable,
			dropCD1SegValue: dropCD1Seg.SelectedValue, dropCD1SegData: dropCD1Seg.ReturnTable,
			dropCD2SegValue: dropCD2Seg.SelectedValue, dropCD2SegData: dropCD2Seg.ReturnTable,
			dropCD3SegValue: dropCD3Seg.SelectedValue, dropCD3SegData: dropCD3Seg.ReturnTable,
			dropEmpSegValue: dropEmpSeg.SelectedValue, dropEmpSegData: dropEmpSeg.ReturnTable,
			dropItmSegValue: dropItmSeg.SelectedValue, dropItmSegData: dropItmSeg.ReturnTable,
			dropMchSegValue: dropMchSeg.SelectedValue, dropMchSegData: dropMchSeg.ReturnTable,
			CCPlaceDD1Title: ChargeCOdeTable[0].PlaceDD1, CCPlaceDD2Title: ChargeCOdeTable[0].PlaceDD2, CCPlaceDD3Title: ChargeCOdeTable[0].PlaceDD3,
			CCdropCD1SegTitle: ChargeCOdeTable[0].dropCD1Seg, CCdropCD2SegTitle: ChargeCOdeTable[0].dropCD2Seg, CCdropCD3SegTitle: ChargeCOdeTable[0].dropCD3Seg,
			CCdropEmpSegTitle: ChargeCOdeTable[0].dropEmpSeg, CCdropItmSegTitle: ChargeCOdeTable[0].dropItmSeg, CCdropMchSegTitle: ChargeCOdeTable[0].dropMchSeg,
			dropCD1SegVisible: ChargeCOdeTable[0].dropCD1Seg_Visible == "True" ? true : false,
			dropCD2SegVisible: ChargeCOdeTable[0].dropCD2Seg_Visible == "True" ? true : false,
			dropCD3SegVisible: ChargeCOdeTable[0].dropCD3Seg_Visible == "True" ? true : false,
			PlaceDD1Visible: ChargeCOdeTable[0].PlaceDD1_Visible == "True" ? true : false,
			PlaceDD2Visible: ChargeCOdeTable[0].PlaceDD2_Visible == "True" ? true : false,
			PlaceDD3Visible: ChargeCOdeTable[0].PlaceDD3_Visible == "True" ? true : false,
			dropEmpSegVisible: ChargeCOdeTable[0].dropEmpSeg_Visible == "True" ? true : false,
			dropItmSegVisible: ChargeCOdeTable[0].dropItmSeg_Visible == "True" ? true : false,
			dropMchSegVisible: ChargeCOdeTable[0].dropMchSeg_Visible == "True" ? true : false, dropCD1Seg_disable: ChargeCOdeTable[0].dropCD1Seg_Enable == "False" ? true : false,
			dropCD2Seg_disable: ChargeCOdeTable[0].dropCD2Seg_Enable == "False" ? true : false, dropCD3Seg_disable: ChargeCOdeTable[0].dropCD3Seg_Enable == "False" ? true : false,
			PlaceDD1_disable: ChargeCOdeTable[0].PlaceDD1_Enable == "False" ? true : false, PlaceDD2_disable: ChargeCOdeTable[0].PlaceDD2_Enable == "False" ? true : false,
			PlaceDD3_disable: ChargeCOdeTable[0].PlaceDD3_Enable == "False" ? true : false, dropEmpSeg_disable: ChargeCOdeTable[0].dropEmpSeg_Enable == "False" ? true : false,
			dropItmSeg_disable: ChargeCOdeTable[0].dropItmSeg_Enable == "False" ? true : false, dropMchSeg_disable: ChargeCOdeTable[0].dropMchSeg_Enable == "False" ? true : false,
		})
		$('#Charge-Code-popup').modal('show');

	}
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	buildChargecodevalue = (CCValue, ChargeSelect) => {
		let ChargeSelected = ChargeSelect
		if (CCValue != null && CCValue != "" && CCValue != undefined) {
			if (ChargeSelected != "" && ChargeSelected != null && ChargeSelected != undefined) {
				ChargeSelected = ChargeSelected + "-" + CCValue
			}
			else {
				ChargeSelected = CCValue
			}
		}
		return (ChargeSelected)
	}
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	buildDropDownValue = (DropDownValue, DefaultValue) => {
		debugger
		const { t } = this.props;
		let ReturnTable = []
		let SelectedValue = []
		if (DropDownValue != null && DropDownValue != undefined) {
			if (DropDownValue.length > 0) {
				let defaulttype = "ISA_CHGCD_USR1_SEG"
				DropDownValue.map((val, index) => {

					if (index == 0) {
						if (val.ISA_CHGCD_USR2_SEG != undefined) {
							defaulttype = "ISA_CHGCD_USR2_SEG"
						}
						else if (val.ISA_CHGCD_USR3_SEG != undefined) {
							defaulttype = "ISA_CHGCD_USR3_SEG"
						}
						else if (val.ISA_CUST_CHARGE_CD != undefined) {
							defaulttype = "ISA_CUST_CHARGE_CD"
						}
						else if (val.ISA_CHGCD_ITEM_SEG != undefined) {
							defaulttype = "ISA_CHGCD_ITEM_SEG"
						}
						let defaultvalue = {
							value: "",
							label: t("--Select Charge Code--")
						}
						ReturnTable.push(defaultvalue)
					}
					let localArray = {
						value: val.SEQ,
						label: val[defaulttype]
					}
					if (val.SEQ == DefaultValue) {
						SelectedValue = localArray
					}
					ReturnTable.push(localArray)
				});
			}
		}
		let ReturnTableValue = {
			ReturnTable: ReturnTable,
			SelectedValue: SelectedValue
		}
		return (ReturnTableValue)
	}
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	handleChangePlaceDD1 = (selectedOption, Title) => {
		debugger;
		let ChargeSelected = this.state.ChargeSelected;
		let CCArray = [];
		if (ChargeSelected != "" && ChargeSelected != " " && ChargeSelected != null && ChargeSelected != undefined) {
			ChargeSelected.includes("--") ? CCArray = ChargeSelected.split("--") : CCArray = ChargeSelected.split("-");
			let DD1 = [];
			let DD2 = [];
			let DD3 = [];
			if (CCArray.length >= 4) {
				DD1 = CCArray[0] + "-" + CCArray[1];
				DD2 = CCArray[2];
				DD3 = CCArray[3];
			}
			else {
				DD1 = CCArray[0];
				DD2 = CCArray[1];
				DD3 = CCArray[2];
			}
			if (selectedOption.value == "" || selectedOption.value == null) {
				selectedOption.value = " ";
			}

			if (Title == "PlaceDD1Value") {
				if (DD1 == " " && DD2 == " " && DD3 == " ") {
					if (DD1 != null && DD1 != undefined && DD1 != "" && DD1 != " ") {
						if (DD3 != undefined) {
							ChargeSelected = selectedOption.value + "-" + DD2 + "-" + DD3;
						}
						else {
							ChargeSelected = selectedOption.value + "-" + DD2;
						}

					} else if (DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
						ChargeSelected = selectedOption.value + "-" + ChargeSelected;
					} else {
						ChargeSelected = selectedOption.value
					}
				}
				else{
					if (DD1 != null && DD1 != undefined && DD1 != "") {
						if (DD3 != undefined) {
							ChargeSelected = selectedOption.value + "-" + DD2 + "-" + DD3;
						}
						else {
							ChargeSelected = selectedOption.value + "-" + DD2;
						}
					} else if (DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
						ChargeSelected = selectedOption.value + "-" + ChargeSelected;
					} else {
						ChargeSelected = selectedOption.value
					}
				}

			}
			else if (Title == "PlaceDD2Value") {
				debugger
				if (DD1 == " " && DD2 == " " && DD3 == " ") {
					if (DD2 != null && DD2 != undefined && DD2 != "" && DD2 != " ") {
						if (DD3 != undefined) {
							ChargeSelected = DD1 + "-" + selectedOption.value + "-" + DD3;
						}
						else {
							ChargeSelected = DD1 + "-" + selectedOption.value;
						}

					} else if (DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
						ChargeSelected = selectedOption.value + "-" + ChargeSelected;
					} else {
						ChargeSelected = ChargeSelected + "-" + selectedOption.value
					}
				}
				else {
					if (DD2 != null && DD2 != undefined && DD2 != "") {
						if (DD3 != undefined) {
							ChargeSelected = DD1 + "-" + selectedOption.value + "-" + DD3;
						}
						else {
							ChargeSelected = DD1 + "-" + selectedOption.value;
						}
					} else if (DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
						ChargeSelected = selectedOption.value + "-" + ChargeSelected;
					} else {
						ChargeSelected = ChargeSelected + "-" + selectedOption.value
					}
				}

			}
			else if (Title == "PlaceDD3Value") {
				if (DD1 == " " && DD2 == " " && DD3 == " ") {
					if (DD3 != null && DD3 != undefined && DD3 != "" && DD3 != " ") {
						ChargeSelected = DD1 + "-" + DD2 + "-" + selectedOption.value;
					} else {
						ChargeSelected = ChargeSelected + "-" + selectedOption.value
					}
				}
				else {
					if (DD3 != null && DD3 != undefined && DD3 != "") {
						ChargeSelected = DD1 + "-" + DD2 + "-" + selectedOption.value;
					} else {
						ChargeSelected = ChargeSelected + "-" + selectedOption.value
					}
				}

			}
		} else {
			ChargeSelected = selectedOption.value
		}

		this.setState({ [Title]: selectedOption, ChargeSelected: ChargeSelected });
		let dropCD1SegValue = this.state.dropCD1SegValue.value == undefined ? "" : this.state.dropCD1SegValue.value
		let dropCD2SegValue = this.state.dropCD2SegValue.value == undefined ? "" : this.state.dropCD2SegValue.value
		let dropCD3SegValue = this.state.dropCD3SegValue.value == undefined ? "" : this.state.dropCD3SegValue.value
		let dropCD1SegFilled = this.state.dropCD1SegValue.value == undefined ? false : true
		let dropCD2SegFilled = this.state.dropCD2SegValue.value == undefined ? false : true
		if (Title == "dropCD1SegValue") {
			if (Title == "dropCD1SegValue") {
				dropCD1SegValue = selectedOption.value
			}
			else if (this.state.dropCD1SegValue != []) {
				dropCD1SegValue = this.state.dropCD1SegValue.value
			}
			dropCD1SegFilled = true
		}
		else if (Title == "dropCD2SegValue") {
			if (Title == "dropCD2SegValue") {
				dropCD2SegValue = selectedOption.value
			}
			else if (this.state.dropCD1SegValue != []) {
				dropCD2SegValue = this.state.dropCD2SegValue.value
			}
			dropCD2SegFilled = true
		}
		else if (Title == "dropCD3SegValue") {
			if (Title == "dropCD3SegValue") {
				dropCD3SegValue = selectedOption.value
			}
			else if (this.state.dropCD1SegValue != []) {
				dropCD3SegValue = this.state.dropCD3SegValue.value
			}
		}
		if (Title == "dropCD1SegValue" || Title == "dropCD2SegValue") {
			$(`#${SDIConstants.SDILoader}`).removeClass('hide');
			let BuildChrCDBO = {
				UserId: this.state.Login_UserId,
				UserBU: this.state.Login_UserBU,
				dropCD1SegValue: dropCD1SegValue,
				dropCD2SegValue: dropCD2SegValue,
				dropCD3SegValue: dropCD3SegValue,
				dropCD1SegFilled: dropCD1SegFilled,
				dropCD2SegFilled: dropCD2SegFilled,
				dropCD1Seg_Visible: this.state.dropCD1SegVisible,
				dropCD2Seg_Visible: this.state.dropCD2SegVisible,
				dropCD3Seg_Visible: this.state.dropCD3SegVisible,
			}
			ShoppingCartAction.GetchargeCOde(BuildChrCDBO)
		}
	};
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	handleChargecodechange = (event, ORDER_NO, ISA_INTFC_LN) => {
		let lineItemsResponse = this.state.LineItemData;
		lineItemsResponse.forEach((value, index) => {
			if (value.ORDER_NO == ORDER_NO && value.ISA_INTFC_LN == ISA_INTFC_LN) {
				value.ISA_CUST_CHARGE_CD = event.target.value;
			}

		});
		this.setState({ LineItemData: lineItemsResponse });
	}
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	UpdateChargeCode = () => {
		debugger
		let Validate = true
		Validate = this.validateChargeCode(this.state.PlaceDD1Visible, this.state.PlaceDD1Value)
		Validate = Validate == true ? this.validateChargeCode(this.state.PlaceDD2Visible, this.state.PlaceDD2Value) : Validate
		Validate = Validate == true ? this.validateChargeCode(this.state.PlaceDD3Visible, this.state.PlaceDD3Value) : Validate
		Validate = Validate == true ? this.validateChargeCode(this.state.dropCD1SegVisible, this.state.dropCD1SegValue) : Validate
		Validate = Validate == true ? this.validateChargeCode(this.state.dropCD2SegVisible, this.state.dropCD2SegValue) : Validate
		Validate = Validate == true ? this.validateChargeCode(this.state.dropCD3SegVisible, this.state.dropCD3SegValue) : Validate
		Validate = Validate == true ? this.validateChargeCode(this.state.dropItmSegVisible, this.state.dropItmSegValue) : Validate
		Validate = Validate == true ? this.validateChargeCode(this.state.dropMchSegVisible, this.state.dropMchSegValue) : Validate
		Validate = Validate == true ? this.validateChargeCode(this.state.dropEmpSegVisible, this.state.dropEmpSegValue) : Validate
		console.log("samp10", this.state.ChargeSelected)
		if (Validate == true && this.state.ChargeSelected != null && this.state.ChargeSelected != "" && this.state.ChargeSelected != undefined) {

			let lineItemsResponse = this.state.LineItemData;
			lineItemsResponse.forEach((value, index) => {
				if(value.ORDER_NO == this.state.lineORDER_NO && value.ISA_INTFC_LN == this.state.lineISA_INTFC_LN) {
					value.ISA_CUST_CHARGE_CD = this.state.ChargeSelected
				}

			});
			this.setState({ LineItemData: lineItemsResponse });

			$('#Charge-Code-popup').modal('hide');
		}
		else {
			$('#ChargeCodeLabel').addClass('errorcolour')
		}
	}
	//7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R
	validateChargeCode = (ChargeCodeDisplayed, ChargeCode) => {
		debugger
		let Validate = true
		if (ChargeCodeDisplayed) {
			if (ChargeCode == [] || ChargeCode.length == 0) {
				Validate = false
			}
			else if (ChargeCode.value == "" || ChargeCode.value == " ") {
				Validate = false
			}
			else if (ChargeCode.length == 1) {
				if (ChargeCode[0].value == "") {
					Validate = false
				}
			}
		}
		return (Validate)
	}
	bindApprovalLineItems = (ORDER_NO) => {
		debugger
		const { t } = this.props;
		let lineItemsArray = this.state.LineItemData.filter((x) => x.ORDER_NO == ORDER_NO);
		return lineItemsArray.map((line, index) => {
			//[10/18/21, PC_02, Bug ID - 1732, Dhevanesam R]
			//7/12/22, PC_OA_01, CAS - Waiting order approval panel query change, Dhevanesam R]
			let PriceFloatvalue = line.lblConvertedPrice;
			let Pricevalue = parseFloat(PriceFloatvalue) ? parseFloat(PriceFloatvalue) : 0
			//6/10/22, PC_OA_03, CAS - Waiting order approval panel query change, Dhevanesam R]
			let roundOffvalue = Pricevalue.toFixed(4);
			let PriceFloatvalueMXN = line.PRICE_REQ;
			let PricevalueMXN = parseFloat(PriceFloatvalueMXN) ? parseFloat(PriceFloatvalueMXN) : 0
			let roundOffvalueMXN = PricevalueMXN.toFixed(4);
			console.log("roundOffvalue", roundOffvalue);
			//[10/18/21, PC_03, Bug ID - 1732, Dhevanesam R]       
			let PricevalueEst = parseFloat(line.extPrice) ? parseFloat(line.extPrice) : 0
			let roundOffvalueEST = PricevalueEst.toFixed(2)
			return (<div className="row mx-0 w-100 py-3 item-box" key={line.DESCR254}>
				<div className="col-lg-9 col-md-12 pl-5">
					<div className="row">
						<div className="col-md-12 fav-maintence">
							<label className="catalog-label "><Trans>Description</Trans> :</label>
							<label className="item-details-value ml-2 ">{line.DESCR254}</label>
						</div>
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-6 fav-maintence">
                                    <label className="catalog-label"><Trans>ID</Trans> :</label>

                                    {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                    <label className="item-details-value ml-2">{line.MFG_ITM_ID}</label>
								</div>
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Manufacturer</Trans> :</label>
									<label className="item-details-value ml-2">{line.ISA_MFG_FREEFORM}</label>
								</div>
								{/*[4/13/22, Bug - 2060, Dhevanesam R]*/}

                                {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                <div className="col-md-6 fav-maintence">
                                    <label className="catalog-label"><span>Supplier</span> <Trans>part#</Trans><span>:</span></label>
                                    <label className="item-details-value ml-2"> {line.INV_ITEM_ID != null && line.INV_ITEM_ID != undefined && line.INV_ITEM_ID != "" && line.INV_ITEM_ID != " " ? line.INV_ITEM_ID : line.ITM_ID_VNDR}</label>
                                </div>
                                <div className="col-md-4 fav-maintence">
                                    <label className="catalog-label"><span><Trans>Supplier</Trans> <Trans>Name</Trans></span><span>:</span></label>
                                    <label className="item-details-value ml-2">{line.SUPPLIERNAME}</label>
								</div>
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label">UOM :</label>
									<label className="item-details-value ml-2">{line.UNIT_OF_MEASURE}</label>
								</div>
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Stock Type</Trans> :</label>
									<label className="item-details-value ml-2">{line.CCINV_STOCK_TYPE}</label>
								</div>
								{/*****************NEW******************/}
								{/*[11/18/21, PC_01, Bug ID - 1809, Poornima S] //Changed Work Order#, Charge Code, Item Base Price, Item Qty keywords & Added Trans for Expand, Collapse All */}
								{/*<div className="col-md-6 fav-maintence">
                                    <label className="catalog-label"><Trans>Charge Code</Trans> :</label>
                                    <label className="item-details-value ml-2">{line.ISA_CUST_CHARGE_CD}</label>
                                </div>*/}
								{/*7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R*/}
								<div className="col-md-6 col-lg-4 mb-3">
									<div className="row">
										<label className="col-lg-4 col-md-4 catalog-label form-label pr-0"><Trans>Charge Code</Trans></label>
										{this.state.ChargeCodeMultiple == true ? <div className="col-lg-7 col-md-7 float-left pr-0 col-12">
											<div className="col-lg-10 col-md-10 float-left px-0 col-12 CardDiv">
												<input type="text" className="form-control" placeholder={t("Select charge code")} id={'ItemChgCode' + line.ISA_INTFC_LN} value={line.ISA_CUST_CHARGE_CD == " " ? "Select charge code" : line.ISA_CUST_CHARGE_CD} maxLength="40" disabled={this.state.ChargeCodeMultiple} onChange={(e) => this.handleChargecodechange(e, line.ORDER_NO, line.ISA_INTFC_LN)}/>
											</div>
											<div className="col-lg-2 col-md-2 float-left px-0 CardImageDiv">
												<button className="btn btn-primary custom-btn-primary primary-btn float-right" data-backdrop="static" data-keyboard="false" onClick={() => this.GetChargeCode(line.ORDER_NO, line.ISA_INTFC_LN, line.ISA_CUST_CHARGE_CD)}>CC</button>
											</div>
										</div> : <div className="col-lg-6 col-md-6 float-left px-0 col-12 mr-1">
												{/*7/11/22, PC_OA_16, Bug - 2364 - In Waiting Order Approval_ CC button is not displayed and Bug - 2363 In Waiting Order Approval_ At the line level, Charge Code is displayed as textbox but in mail its mentioned as dropdown, Dhevanesam R*/}
												<input type="text" className="form-control" placeholder={t("Enter charge code")} id={'ItemChgCode' + line.ISA_INTFC_LN} value={line.ISA_CUST_CHARGE_CD == " " ? "" : line.ISA_CUST_CHARGE_CD} maxLength="40" onChange={(e) => this.handleChargecodechange(e, line.ORDER_NO, line.ISA_INTFC_LN)} />

											</div>}
									</div>
								</div>
								{/*6/30/22, PC_OA_08, CAS - Waiting order approval panel query change, Dhevanesam*/}
								{/*7/12/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R*/}
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Item PO Price</Trans> :</label>
									<label className="item-details-value ml-2">{line.lblBasePriceMXN} </label>
								</div>
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Item PO Ext Price</Trans> :</label>
									<label className="item-details-value ml-2">{line.lblExtPriceMXN} </label>
								</div>
								{/***************END********************/}
							</div>
						</div>
					</div>
				</div>
				{/*[10/18/21, PC_03, Bug ID - 1732, Dhevanesam R]*/}
				<div className="col-lg-3 col-md-12 prices pl-0">
					<div className="float-right">
						<div className="w-100 float-left">
							<div className="float-right">
								<label className="float-left mt-2"><Trans>Item USD Price</Trans> :</label>
								<div className="float-left mt-2">
									<span className="price-rate">{`${line.lblConvertedCurrSym} ${roundOffvalue}`}</span>
								</div>
							</div>
						</div>
						<div className="w-100 float-left">
							<div className="float-right">
								<div className="float-left">
									{/*[11/9/21, PC_OA_01, CAS - QTY Onchange Remove, Dhevanesam R]*/}
									<label className="float-left m-2"><Trans>Item qty</Trans> :</label>
									{line.lblqty.toLowerCase() == `false` ? <span className="float-left m-2 price-rate">{line.QTY_REQUESTED}</span> : line.QTY_REQUESTED}
									<div className="float-left mt-2">
										<span className="price-calc">&nbsp;X</span>
										{/*<span className="price-rate">{`${line.lblConvertedCurrSym} ${line.lblConvertedPrice} ${line.lblConvertedCurrCdVisible ? line.lblConvertedCurrCdVisible.toLowerCase() == `true` ? line.lblConvertedCurrCd : `` : ``}`}</span>*/}
										<span className="price-rate">{`${line.lblConvertedCurrSym} ${roundOffvalue}`}</span>
									</div>
								</div>
							</div>
						</div>
						{/*[4/20/22, CAS -31, Dhevanesam R]*/}
						<div className="w-100 float-left">
							<div className="float-right">
								<label className="float-left mt-2"><Trans>Ext. USD Price</Trans> :</label>
								<div className="float-left mt-2">
									{/********12/23/2020*****
                                     * <span className="price-rate">{`${line.lblConvertedExtCurrSym} ${roundOffvalueEST}`}</span>*
                                     *END ***********/}
									<span className="price-rate">{`${line.lblConvertedExtCurrSym} ${line.lblExtPriceUSD}`}</span>
								</div>
							</div>
						</div>
						{this.state.isBelowMyChain == false ?
							<div className="float-right">
								{line.chkDeleted == false ? <button className="btn p-0 action-btns reject-btn" onClick={() => this.deleteItems(true, line.ISA_INTFC_LN, line.ORDER_NO)}><Trans>Delete</Trans></button>
									: <button className="btn p-0 action-btns undelete-btn" onClick={() => this.deleteItems(false, line.ISA_INTFC_LN, line.ORDER_NO)}><Trans>UnDelete</Trans></button>}
							</div> : null}
					</div>
				</div>
			</div>);

		});

	};

	bindOrderApprovals = () => {
		return this.state.OrderApprovalData.map((order, index) => {
			let islineItemPresent = this.state.LineItemData.filter((x) => x.ORDER_NO == order.ORDER_NO).length > 0 ? true : false;
			return (
				<React.Fragment key={order.ORDER_NO + index}>
					<tr id={`orderApp-tr-${index}`}>
						<td className="text-center">
							{this.state.isMultipleApproval ? <div className="custom-control custom-checkbox item-custom-control">
								<input type="checkbox" className="custom-control-input" id={`customCheck-approval-${index}`} name={`orderChck${index}`} onClick={(e) => this.selectOrders(index)} />
								<label className="custom-control-label" htmlFor={`customCheck-approval-${index}`} />
							</div> : null}
						</td>
						<td className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, index)} >
							<span className="accordion-arrow header-arrow" />
						</td>
						<td className="d-none d-sm-none d-md-table-cell">{order.DESCR}</td>
						<td>{order.ORDER_NO}</td>
						<td className="d-none d-sm-none d-md-table-cell order-status-workorder"><div style={{ width: '75px' }} >{order.ISA_WORK_ORDER_NO}</div></td>
						{/*[06/15/2023 - PC_ZE_139_APP_7 - Johnprinto D]*/}
						{/*[07/12/2023 - PC_ZE_139_SIT_1_APP_02 - Johnprinto D]*/}
						<td data-toggle="tooltip" title={order.WO_DESC} data-label="Item Description">{order.WO_DESC != " " && order.WO_DESC != null && order.WO_DESC != undefined ? order.WO_DESC.length > 14 ? `${order.WO_DESC.substring(0, 14)}...` : order.WO_DESC : `-`} </td>
						<td className="d-none d-sm-none d-md-table-cell">{order.EMPNAME}</td>
						<td className="d-none d-sm-none d-md-table-cell">{order.ADD_DT}</td>
						<td className="d-none d-sm-none d-md-table-cell">{order.CURRENT_APPR_NAME}</td>
						{/*[10/18/21, PC_02, Bug ID - 1732, Dhevanesam R]*/}
						{/*<td className="d-none d-sm-none d-md-table-cell text-right bold-font">$ {this.calculateTotal(order.ORDER_NO)} </td>
                        <td className="d-none d-sm-none d-md-table-cell text-right bold-font">$ {this.calculateTotal(order.ORDER_NO)} </td>*/}
						{/*//6/10/22, PC_OA_03, CAS - Waiting order approval panel query change, Dhevanesam R]*/}
						<td className="d-none d-sm-none d-md-table-cell text-right bold-font">{order.Ordertotal} </td>
						<td className="d-none d-sm-none d-md-table-cell text-right bold-font">{order.Approvaltotal} </td>
						{this.state.isBelowMyChain == false ?
							<><td >{order.Comment}</td>
								<td className="btn-column">
									{/*[6/17/22, PC_OA_05, Bug ID - 2246, Dhevanesam R]*/}
									<button className="btn grid-btn-approve mr-2 desktop-action" onClick={() => this.approveDeclineSingleOrder(order, 1)}><Trans>Approve</Trans></button>
									<button className="btn grid-btn-decline desktop-action" onClick={() => this.approveDeclineSingleOrder(order, 2)}><Trans>Decline</Trans></button>
									<a href="#" className="action-icon" onClick={(e) => this.approveDeclineSingleOrder(order, 1, e)}><img className="approve-icon" src="images/approve-icon.svg" alt="approve-icon" /></a>
									<a href="#" className="action-icon" onClick={(e) => this.approveDeclineSingleOrder(order, 2, e)}><img className="reject-icon" src="images/reject-icon.svg" alt="reject-icon" /></a>
								</td></> : null}
					</tr>
					<tr className="p d-none content-row" id={`contentrow-${index}`}>
						<td colSpan={14} className="hiddenRow">
							<div className="py-1">
								<div className="col-md-12 float-left mx-0 mobile-fieldscontainer item-box py-3" id="Header">
									<div className="col-12 pl-5">
										<div className="col-12 mobile-fields p-0">
											<label className="catalog-label"><Trans>Business Unit</Trans> :</label>
											<label className="item-details-value">{order.DESCR}</label>
										</div>
										<div className="col-12 mobile-fields p-0">
											<label className="catalog-label"><Trans>Work Order #</Trans> :</label>
											<label className="item-details-value">{order.ISA_WORK_ORDER_NO}</label>
										</div>
										<div className="col-12 mobile-fields p-0">
											<label className="catalog-label"><Trans>Order Entered By</Trans> :</label>
											<label className="item-details-value">{order.EMPNAME}</label>
										</div>
										<div className="col-12 mobile-fields p-0">
											<label className="catalog-label"><Trans>Created Date</Trans> :</label>
											<label className="item-details-value ">{order.ADD_DT}</label>
										</div>
										<div className="col-12 mobile-fields p-0">
											<label className="catalog-label"><Trans>Pending Approver</Trans> :</label>
											<label className="item-details-value">{order.CURRENT_APPR_NAME}</label>
										</div>
										<div className="col-12 mobile-fields p-0">
											<label className="catalog-label"><Trans>Order Total</Trans> :</label>
											<label className="item-details-value">{order.Ordertotal}</label>
										</div>
										{/*6/30/22, PC_OA_08, CAS - Waiting order approval panel query change, Dhevanesam*/}
										<div className="col-12 mobile-fields p-0">
											<label className="catalog-label"><Trans>PO Order Total</Trans>:</label>
											<label className="item-details-value">{order.Approvaltotal}</label>
										</div>
									</div>
								</div>
								{islineItemPresent ? this.bindApprovalLineItems(order.ORDER_NO)
									: `Line items has been approved,declined or cancelled`}
							</div>

						</td>
					</tr>
				</React.Fragment>);
		});
	};
    /**
   * Listener method for the response of Save/Delete favorite page
   */
	updateFavPageResponse = () => {
		if (OrderApprovalStore.updateFavPageResponse.updateType == 1) {
			let response = JSON.parse(OrderApprovalStore.updateFavPageResponse.responseStr)[0];
			if (response.is_Success == "True") {
				$(`#${SDIConstants.SDILoader}`).addClass('hide');
				this.setState({ isFavorited: true, Page_ID: response.Page_ID });
				MenuAction.toastMessage(1);
			}
		} else {
			let response = JSON.parse(OrderApprovalStore.updateFavPageResponse.responseStr)[0];
			if (response.success == "true") {
				$(`#${SDIConstants.SDILoader}`).addClass('hide');
				this.setState({ isFavorited: false });
				MenuAction.toastMessage(2);
			}
		}
	};
    /**
    * Handle change method for favorite page checkbox
    * @param event
    */
	handleFavorite = (event) => {
		this.setState({ isFavorited: event.target.checked });
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		if (event.target.checked == true) {
			let insertFavPageBO = {
				Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
				pageURL: currentPagePath.toLowerCase(),
				pageTitle: "Waiting Order Approval",
				Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
			}
			OrderApprovalAction.insertFavPage(insertFavPageBO)
		} else {
			let deleteFavPageBO = {
				Page_ID: this.state.Page_ID
			}
			OrderApprovalAction.deleteFavPage(deleteFavPageBO)
		}
	};
    /**
    * Listener method for loading favorite page icon
    */
	loadFavPageResponse = () => {
		let favPageResponse = JSON.parse(OrderApprovalStore.currentFavPageResponse);
		if (favPageResponse.length > 0) {
			favPageResponse = favPageResponse[0];
			this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
		};
	};
	//8/23/22, PS_ZE_176_APP_03, Zeus 176 - Order approval pre filter - Tab click method
	handleOnChangePrefilter = (event) => {
		searchColumnGlobVar = "";
		searchTextGlobVar = "";
		startDateGlobVar = "";
		endDateGlobVar = "";
		$(`#searchTxt`).val('');
		if (event == "MyOrders" && this.state.isBelowMyChain != false) {
			this.setState({
				isBelowMyChain: false, selectedPrefilter: "MyOrders", pageNo: 1, itemCount: 10, searchColumn: "ALL", searchText: "", isExpanded: false, filterDisplayName: "All", isFilterOpen: false,
				sortColumn: "ORDER_NO", sortValue: "ASC", startDate: "", endDate: "", SelectedMultiSiteValue: [], isMultiSiteDropdownChange: false, startDateObj: null, endDateObj: null
			});
			this.pageload(1, 10, "ALL", "", "ORDER_NO", "ASC", "", "", [], false, false);
		}
		else if (event == "BelowMyChain" && this.state.isBelowMyChain != true) {
			this.setState({
				isBelowMyChain: true, selectedPrefilter: "BelowMyChain", pageNo: 1, itemCount: 10, searchColumn: "ALL", searchText: "", isExpanded: false, filterDisplayName: "All", isFilterOpen: false,
				sortColumn: "ORDER_NO", sortValue: "ASC", startDate: "", endDate: "", SelectedMultiSiteValue: [], isMultiSiteDropdownChange: false, startDateObj: null, endDateObj: null
			});
			this.pageload(1, 10, "ALL", "", "ORDER_NO", "ASC", "", "", [], false, true);
		}
	}

    //[11/26/21, CAS - 31, Dhevanesam R]
	render() {
		const { t } = this.props;
		let mail = isFromEmail;
		console.log("isFromEmail", isFromEmail);
		console.log("isFromEmail", mail);
		console.log("this.state.MultisiteDD.length ", this.state.MultisiteDD.length);
		return (<div className="container-fluid content-section" id="HeadDIv">
			<div className="row">
				{/* Waiting Order Approval starts */}
				<div className="container-fluid content-margin">
					<div className="row">
						<div className="col-md-12 page-heading mb-3">
							<div >
                                {/*2551 - UI issue in Waiting order approval page  - Dhamotharan P*/}
                                {/*                   // [06/28/2024] - Zeus 327 - User Name Display -Check if user comes from email display the content else show null  - Ayyappan.S*/}
                                {isFromEmail == true && this.state.User_Name ? <h5 className="page-title-orderapproval pb-3 margin-order-app mb-2">Hi, {this.state.User_Name}</h5> : null}
								<h5 className="page-title-orderapproval"><Trans>Waiting Order Approval</Trans>{isFromEmail == false ? <span>
									<input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
									<label htmlFor="checkbox-1">
										<svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
											<g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
												<path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
												<circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
												<g className="grp7" opacity={0} transform="translate(7 6)">
													<circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
													<circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
												</g>
												<g className="grp6" opacity={0} transform="translate(0 28)">
													<circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
													<circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
												</g>
												<g className="grp3" opacity={0} transform="translate(52 28)">
													<circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
													<circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
												</g>
												<g className="grp2" opacity={0} transform="translate(44 6)">
													<circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
													<circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
												</g>
												<g className="grp5" opacity={0} transform="translate(14 50)">
													<circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
													<circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
												</g>
												<g className="grp4" opacity={0} transform="translate(35 50)">
													<circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
													<circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
												</g>
												<g className="grp1" opacity={0} transform="translate(24)">
													<circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
													<circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
												</g>
											</g>
										</svg>
									</label>
								</span> : null}</h5>

							</div>
							{/* //8/23/22, PS_ZE_176_APP_02, Zeus 176 - Order approval pre filter tab index*/}
							<div class="row">
								<div class="col-md-12">

									<ul class="nav nav-pills catalog-tabs-mobile catalog-tabs border-top-0 border-right-0 border-left-0 mt-4 mb-4" id="myTab" role="tablist">
										<li class="nav-item mx-0">
											<a class="nav-link active rounded-0 bg-white ml-0 my-orders pl-4 " style={{ "cursor": "pointer" }} id="myorders-details-tab" data-toggle="tab" role="tab" aria-selected="true" onClick={() => this.handleOnChangePrefilter("MyOrders")}>My Orders</a>
										</li>
										{isFromEmail == false && CookieService.getLocalStorageValues("Session_USERTYPE") != "CORPADMIN" ?
											<li class="nav-item">
												<a class="nav-link rounded-0 bg-white below-chain pl-4 " style={{ "cursor": "pointer" }} id="belowmychain-details-tab" data-toggle="tab" role="tab" aria-selected="false" onClick={() => this.handleOnChangePrefilter("BelowMyChain")}>Below My Chain</a>
											</li> : null}
									</ul>

									<div class="tab-content Catalog-Items w-100" id="myTabContent">
										<div class="row">
											<div class="col-md-12">
												<div class="col-md-8 col-lg-8">
													{/*NEW(1/7/2021)*********Removed*/}
													{/*****this.state.isPageLoadedOnce &&isPostBack == true && isFromEmail == false******/}
													{/* { this.state.totalCount == 0 ||  isFromEmail == true ? null : this.state.MultisiteDD.length > 0 ? <React.Fragment>
                                    <div class="col-md-4 col-lg-12 pl-0">
                                        <label className="waiting-order-label float-left my-3">Business Unit</label>
                                    </div>
                                    <div class="col-md-12 col-lg-12 pl-0">
                                        <MultiSelect
                                            options={this.state.MultisiteDD}
                                            value={this.state.selectedMultiSites}
                                            onChange={this.handleMultiSiteDD}
                                            labelledBy={"Select"}
                                            className="form-control waiting-order-dropdown p-1 OrderApproverMultiSite"
                                        />
                                    </div>
                                </React.Fragment> : null}*/}
													{/*ADDED*/}
													{/*[4/13/22, Bug - 2060, Dhevanesam R]*/}

                                                    {this.state.Email == false && this.state.MultisiteDD.length > 0 ? <div id="multisitedrop">
														<div class="col-md-4 col-lg-12 pl-0">
															<label className="waiting-order-label float-left my-3"><Trans>Business Unit</Trans></label>
														</div>
														<div class="col-md-12 col-lg-12 pl-0">
															<MultiSelect
																options={this.state.MultisiteDD}
																value={this.state.selectedMultiSites}
																onChange={this.handleMultiSiteDD}
																labelledBy={t("Select")}
																className="form-control waiting-order-dropdown p-1 OrderApproverMultiSite"
															/>
														</div>
													</div> : null}
													{/****END********/}
												</div>

											</div>
											<div className="col-md-12">
												<div className="float-left page-title-orderapproval "> {this.state.isBelowMyChain == false ? <span>My Orders</span> : <span>Below My Chain</span>}</div>
												{this.state.isMultipleApproval && this.state.totalCount > 0 && isFromEmail == false ? <div className="float-left mobile-btns">
													{/*[6/17/22, PC_OA_05, Bug ID - 2246, Dhevanesam R]*/}
													{/*2552 - Font style needs to be change in ipad - Dhamotharan P (added px-1 and px-2)*/}
													<button className="btn custom-btn-approve float-left px-2 mr-2 mb-2" onClick={() => this.approveDeclineMultipleOrder(1)}><Trans>Approve</Trans></button>
													{/*Bug 2314 - UI issue in the approve/decline button in french- Request approval [7/1/2022] - Dhamotharan P*/}
													<button className="btn custom-btn-approve  float-left px-2 mb-2" style={{ "backgroundColor": "red","border-color":"red"}} onClick={() => this.approveDeclineMultipleOrder(2)}><Trans>Decline</Trans></button>
												</div> : null}
												{(this.state.isPageLoadedOnce && this.state.totalCount == 0 && isPostBack == true && searchTextGlobVar == "") || isFromEmail == true ? null : <div className="float-right">
													{this.state.totalCount > 0 && isFromEmail == false ? <button className="btn btn-primary custom-btn-primary primary-btn float-left px-1 mr-3 mb-2" onClick={this.expandAll}>{!this.state.isExpanded ? <Trans>Expand All</Trans> : <Trans>Collapse All</Trans>}</button> : null}
													{/*****New change********(this.state.totalCount > 0 && )-->added*************/}
													{this.state.totalCount > 0 && isFromEmail == false ? <button className="btn btn-primary custom-btn-primary primary-btn float-left px-1 mr-3 mb-2" onClick={this.exportToExcel}><Trans>Export to Excel</Trans></button> : null}
													{/*****end*********/}
													<div className="input-group dropdown-search float-left mb-2">
														<div className="input-group-prepend">
															<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.filterDisplayName}</Trans></button>
															<div className="dropdown-menu" style={{}}>
																<a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "All", "ALL")}><Trans>All</Trans></a>
																<a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Business Unit", "DESCR")}><Trans>Business Unit</Trans></a>
																<a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order Number", "ORDER_NO")} ><Trans>Order Number</Trans></a>
																<a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Work Order Number", "ISA_WORK_ORDER_NO")}><Trans>Work Order Number</Trans></a>
																<a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order Entered By", "EMPNAME")}><Trans>Order Entered By</Trans></a>
																<a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Pending Approver", "CURRENT_APPR_NAME")}><Trans>Pending Approver</Trans></a>
															</div>
														</div>
                                                        <input type="text" className="form-control" id="searchTxt" placeholder={t("Search")} value={this.state.searchText} style={{ 'padding-right': '50px' }} aria-label="Text input with dropdown button" onKeyDown={this.keyPress} onChange={(e) => this.handleSearchTxt(e)} />
                                                        {this.state.searchText.length > 0 ? <span class="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} > </span> : null}
                                                        <span className="dropdown-search-icon cursor-pointer" onClick={this.handleSearch} />
													</div>
													<div className="dropdown float-left">
														<a href="#" className="float-right filter-icon mt-1 mx-2" title="Advance Filter" id="advanceFilterIcon" onClick={(e) => this.filtertoggle(e)}><img src="images/filter-icon.png" alt="Filter Icon" />
														</a>
														<div className={`dropdown-menu order-maintenance-filter m-0 ${this.state.isFilterOpen ? 'd-block' : ''}`} id="advanced-filter" >
															<div className="container-fluid">
																<div className="row">
																	{/*[4/20/22, CAS -31, Dhevanesam R]*/}
																	<div className="col-md-12 p-0 pb-2 border-bottom mb-3">
																		<span className="filter-title pl-3"><Trans>Advanced Filter</Trans></span>
																		<button type="button" className="filter-close close pr-2 filter-btn" onClick={(e) => this.filtertoggle(e)}><span className="filter-close-icon" /></button>
																	</div>
																	<div className="col-md-12">
																		<label className="catalog-label mb-0"><Trans>Created Date</Trans></label> </div>
																	<div className="col-md-6 mb-4">
																		<label className="catalog-label mb-0"><Trans>From</Trans></label>
																		{/*[04/05/22], PC_OA_01, Bug - 2004, Dhevanesam R*/}
																		<DatePicker className="form-control datepicker"
																			id="Fromdateid"
																			fixedHeight
																			placeholderText="MM/DD/YYYY"
																			dateFormat="MM/dd/yyyy"
																			showMonthDropdown
																			showYearDropdown
																			selected={this.state.startDateObj}
																			maxDate={this.state.endDateObj}
																			onChange={(e) => this.handleDatePicker(e, "startDateObj")}

																		/>
																		{/*<span className="calendar-icon filter-calendar-icon" />*/}
																		<span className="calendar-icon filter-calendar-icon" onClick={() => $(`#Fromdateid`).focus()} />
																	</div>
																	<div className="col-md-6 mb-4">
																		<label className="catalog-label mb-0"><Trans>To</Trans></label>
																		<DatePicker className="form-control datepicker"
																			id="Todateid"
																			fixedHeight
																			placeholderText="MM/DD/YYYY"
																			dateFormat="MM/dd/yyyy"
																			showMonthDropdown
																			showYearDropdown
																			selected={this.state.endDateObj}
																			minDate={this.state.startDateObj}
																			onChange={(e) => this.handleDatePicker(e, "endDateObj")}

																		/>
																		{/*<span className="calendar-icon filter-calendar-icon" />*/}
																		<span className="calendar-icon filter-calendar-icon" onClick={() => $(`#Todateid`).focus()} />
																	</div>
																	<div className="col-md-12 mb-2">
																		<div className="float-right">

																			<button className="btn btn-primary filter-clear-btn px-4 mr-2 mt-2 filter-btn" onClick={this.cancelFilter} ><Trans>Clear</Trans></button>
																			{/*Bug 2314 - UI issue in the approve/decline button in french- Request approval [7/1/2022] Dhamotharan P*/}
																			<button className="btn btn-primary custom-btn-primary primary-btn filter-btn wallet-btn mt-2 px-4" onClick={this.applyFilterclick}><Trans>Apply</Trans></button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>}
											</div>
											{/*[4/13/22, Bug - 2060, Dhevanesam R]*/}
											<div className="col-lg-12">
												<div className="table-responsive">
													{this.state.isPageLoadedOnce && this.state.totalCount == 0 && isPostBack == true && searchTextGlobVar == "" ? (<section className="site-undercontruction container text-center center-panel pt-4">
														<div className="img-section">
															<img src="images/nosearch.jpg" alt="imagefile" />
														</div>
														<p className="search-result-content pt-4"><Trans>No Records found</Trans>
														</p>
													</section>) : (<table className="table ZEUS_Grid FOM-grid WOA-grid">
														<thead>
															<tr>
																<th />
																<th />
																<th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Business Unit</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`DESCR`, `ASC`)} ><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.sortingClick(`DESCR`, `DESC`)} ><img src="Images/sort1.svg" /></span></th>
																<th className="position-sort-icon"><Trans>Order</Trans> #<span className="sort-up ml-1" onClick={(e) => this.sortingClick(`ORDER_NO`, `ASC`)} ><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.sortingClick(`ORDER_NO`, `DESC`)} ><img src="Images/sort1.svg"/></span></th>
																<th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Work Order #</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`ISA_WORK_ORDER_NO`, `ASC`)} ><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`ISA_WORK_ORDER_NO`, `DESC`)} /></span></th>
																{/*[06/15/2023 - PC_ZE_139_APP_8 - Johnprinto D]*/}
																{/*[07/12/2023 - PC_ZE_139_SIT_1_APP_01 - Johnprinto D]*/}
																<th className="position-sort-icon"><Trans>WO Description</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`WO_DESC`, `ASC`)} ><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.sortingClick(`WO_DESC`, `DESC`)} ><img src="Images/sort1.svg" /></span></th>
																<th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Order Entered By</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`EMPNAME`, `ASC`)}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`EMPNAME`, `DESC`)} /></span></th>
																<th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Created Date</Trans></th>
																<th className="d-none d-sm-none d-md-table-cell position-sort-icon"><Trans>Pending Approver</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`CURRENT_APPR_NAME`, `ASC`)}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`CURRENT_APPR_NAME`, `DESC`)} /></span></th>
																{/*[10/18/21, PC_01, Bug ID - 1732, Dhevanesam R]*/}
																{/*6/30/22, PC_OA_08, CAS - Waiting order approval panel query change, Dhevanesam*/}
																<th className="d-none d-sm-none d-md-table-cell text-right position-sort-icon"><Trans>Order Total</Trans> (USD)</th>
																<th className="d-none d-sm-none d-md-table-cell text-right position-sort-icon"><Trans>PO Order Total</Trans></th>
																{this.state.isBelowMyChain == false ?
																	<> <th className="d-sm-none d-md-table-cell position-sort-icon statusapproval"><Trans>Status</Trans></th>
																		<th className="d-sm-none d-md-table-cell position-sort-icon"><Trans>Action</Trans></th></> : null}

															</tr>
														</thead>
														<tbody>
															{this.state.isPageLoadedOnce && this.state.totalCount > 0 ? this.bindOrderApprovals() :
																(<tr>
																	<td colSpan="15">
																		<section className="site-undercontruction container text-center center-panel pt-4">
																			<div className="img-section">
																				<img src="images/nosearch.jpg" alt="imagefile" />
																			</div>
																			<p className="search-result-content pt-4"><Trans>Sorry, we couldn’t find any search result</Trans>
																			</p>
																		</section>
																	</td>
																</tr>)}

														</tbody>
													</table>)}
												</div>
											</div>
											{this.state.totalCount > 0 ? <div className="col-md-12">
												<span className="total-records mt-2 d-inline-block">
													<Trans>Number of Records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
												</span>
												{this.state.totalCount > this.state.itemCount ? <ReactPaginate
													previousLabel={<span className="right-pagination-arrow" />}
													nextLabel={<span className="left-pagination-arrow" />}
													previousClassName={'page-item'}
													nextClassName={'page-item'}
													previousLinkClassName={'page-link'}
													nextLinkClassName={'page-link'}
													disabledClassName={'disabled'}
													breakLabel={'...'}
													breakClassName={'page-item'}
													breakLinkClassName={'page-link'}
													pageCount={this.state.pageCount}
													marginPagesDisplayed={1}
													pageRangeDisplayed={2}
													onPageChange={this.handlePageClick}
													containerClassName={'custom-pagination pagination'}
													pageClassName={'page-item'}
													pageLinkClassName={'page-link'}
													activeClassName={'active'}
													hrefBuilder={() => '#'}
													forcePage={this.state.pageNo - 1}
												/> : null}

											</div> : null}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			{/*7/4/22, PC_OA_10, CAS - Waiting order approval panel query change, Dhevanesam R*/}
			<div className="modal" id="Charge-Code-popup" data-backdrop="static" data-keyboard="false">
				<div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
					<div className="modal-content">
						{/* Modal Header */}
						<div className="modal-header">
							<h4 className="modal-title mt-1"><Trans>Charge Code</Trans></h4>
							<button type="button" className="btn close-btn" data-dismiss="modal">
								X
              </button>
						</div>
						<div className="modal-body">
							{/*[9/1/22, Bug - 2549, PC_OA_01 Dhevanesam R]*/}
							<div className="float-left mb-2" ><Trans>Charge code for user</Trans>{" " + CookieService.getLocalStorageValues("Session_CONAME")}</div>
							{/*<div className="float-right TextAlignLast mb-2" ></div>*/}
							<div className="row mt-3 w-100">
								<div className="col-12 edit-desc-field mb-4">
									{this.state.PlaceDD1Data.length > 1 || this.state.PlaceDD1Visible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD1Title}</Trans></label>
										<div className="col-md-7">
											<Select
												id="PlaceDD1"
												isDisabled={this.state.PlaceDD1_disable}
												className="DropDown-Font-Size"
												value={this.state.PlaceDD1Value}
												onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD1Value")}
												options={this.state.PlaceDD1Data}
											/>
										</div>
									</div> : null}
									{this.state.PlaceDD2Data.length > 1 || this.state.PlaceDD2Visible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD2Title}</Trans></label>
										<div className="col-md-7">
											<Select
												id="PlaceDD2"
												isDisabled={this.state.PlaceDD2_disable}
												className="DropDown-Font-Size"
												value={this.state.PlaceDD2Value}
												onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD2Value")}
												options={this.state.PlaceDD2Data}
											/>
										</div>
									</div> : null}
									{this.state.PlaceDD3Data.length > 1 || this.state.PlaceDD3Visible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCPlaceDD3Title}</Trans></label>
										<div className="col-md-7">
											<Select
												id="PlaceDD3"
												isDisabled={this.state.PlaceDD3_disable}
												className="DropDown-Font-Size"
												value={this.state.PlaceDD3Value}
												onChange={(e) => this.handleChangePlaceDD1(e, "PlaceDD3Value")}
												options={this.state.PlaceDD3Data}
											/>
										</div>
									</div> : null}
									{this.state.dropCD1SegData.length > 1 || this.state.dropCD1SegVisible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD1SegTitle}</Trans></label>
										<div className="col-md-7">
											<Select
												id="dropCD1Seg"
												className="DropDown-Font-Size"
												isDisabled={this.state.dropCD1Seg_disable}
												value={this.state.dropCD1SegValue}
												onChange={(e) => this.handleChangePlaceDD1(e, "dropCD1SegValue")}
												options={this.state.dropCD1SegData}
											/>
										</div>
									</div> : null}
									{this.state.dropCD2SegData.length > 1 || this.state.dropCD2SegVisible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD2SegTitle}</Trans></label>
										<div className="col-md-7">
											<Select
												id="dropCD2Seg"
												className="DropDown-Font-Size"
												isDisabled={this.state.dropCD2Seg_disable}
												value={this.state.dropCD2SegValue}
												onChange={(e) => this.handleChangePlaceDD1(e, "dropCD2SegValue")}
												options={this.state.dropCD2SegData}
											/>
										</div>
									</div> : null}
									{this.state.dropCD3SegData.length > 1 || this.state.dropCD3SegVisible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCdropCD3SegTitle}</Trans></label>
										<div className="col-md-7">
											<Select
												id="dropCD3Seg"
												isDisabled={this.state.dropCD3Seg_disable}
												className="DropDown-Font-Size"
												value={this.state.dropCD3SegValue}
												onChange={(e) => this.handleChangePlaceDD1(e, "dropCD3SegValue")}
												options={this.state.dropCD3SegData}
											/>
										</div>
									</div> : null}
									{this.state.dropEmpSegData.length > 1 || this.state.dropEmpSegVisible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCdropEmpSegTitle}</Trans></label>
										<div className="col-md-7">
											<Select
												id="dropEmpSeg"
												isDisabled={this.state.dropEmpSeg_disable}
												className="DropDown-Font-Size"
												value={this.state.dropEmpSegValue}
												onChange={(e) => this.handleChangePlaceDD1(e, "dropEmpSegValue")}
												options={this.state.dropEmpSegData}
											/>
										</div>
									</div> : null}
									{this.state.dropItmSegData.length > 1 || this.state.dropItmSegVisible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCdropItmSegTitle}</Trans></label>
										<div className="col-md-7">
											<Select
												id="dropItmSeg"
												className="DropDown-Font-Size"
												isDisabled={this.state.dropItmSeg_disable}
												value={this.state.dropItmSegValue}
												onChange={(e) => this.handleChangePlaceDD1(e, "dropItmSegValue")}
												options={this.state.dropItmSegData}
											/>
										</div>
									</div> : null}
									{this.state.dropMchSegData.length > 1 || this.state.dropMchSegVisible == true ? <div className="row mb-3">
										<label className="col-md-5 quick-label"><Trans>{this.state.CCdropMchSegTitle}</Trans></label>
										<div className="col-md-7">
											<Select
												id="dropMchSeg"
												isDisabled={this.state.dropMchSeg_disable}
												className="DropDown-Font-Size"
												value={this.state.dropMchSegValue}
												onChange={(e) => this.handleChangePlaceDD1(e, "dropMchSegValue")}
												options={this.state.dropMchSegData}
											/>
										</div>
									</div> : null}

								</div>
								<div className="col-md-12 text-center mb-2 d-none" id="ChargeCodeLabelDiv">
									<label id="ChargeCodeLabel">***<Trans>All charge code segments must be selected</Trans>***</label>
								</div>

								<div className="col-md-12 text-center mb-2">
									<button className="btn btn-primary custom-btn-primary primary-btn px-4" id="ChargeCodeBtn" onClick={() => this.UpdateChargeCode()}><Trans>Submit</Trans></button>
								</div>

							</div>
						</div>
					</div>
				</div>
            </div>
            {/*Zeus 255 - Limit Exceeded Modal Starts here*/}
            <div className="modal fade order-limit pl-0" id="limit-exceeded" tabIndex={-1} role="dialog" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered order-limit-custom-width" role="document" >
                    <div className="modal-content">
                        <div className="modal-header sp-delv-header align-items-center">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.state.limitExeData[1]}</h5>
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close-icon.svg" alt="close" />
                            </button>
                        </div>
                        {/*Form Starts here*/}
                        <div className="modal-body px-4">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <span className="font-14 font-regular custom-light-black">{this.state.limitExeData[0]}</span>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <span className="font-14 font-medium custom-black"> Approver Name: </span>
                                    <span className="font-14 font-regular ml-1 custom-light-black">{this.state.limitExeData[2]}</span>
                                </div>
                                <div className="col-md-12">
                                    <span className="font-14 font-medium custom-black">Email ID:</span>
                                    <span className="font-14 font-regular ml-1 custom-light-black">{this.state.limitExeData[3]}</span>
                                </div>
                            </div>
                        </div>
                        {/*Form ends here*/}
                        {/*button*/}
                        <div className="modal-footer border-0 pt-1 px-4">
                            <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" > OK </button>
                        </div>
                        {/*button*/}
                    </div>
                </div>
            </div>
            {/*Limit Exceeded Modal End here*/}
            {/*Approval comments modal starts here*/}
            <div class="modal fade approval-popup pl-0" id="ApprovalComments" tabindex="-1" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content border-0">
                        <div class="modal-header sp-delv-header align-items-center">
                            <h5 class="modal-title" >Approval comments</h5>
                            <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.closeApprovalComments()}>
                                X
                            </button>
                        </div>

                        <div class="modal-body px-4">
                            <div class="row">

                                <div class="col-md-12 mb-3">
                                    <label class="form-label catalog-label" for="comments">Comments</label>
                                    <textarea class="form-control" id="ApprvComment" placeholder="Enter comments" maxLength="254" value={this.state.Comments} onChange={(e) => this.HandleComments(e)} rows="3"></textarea>
                                </div>

                            </div>
                        </div>

                        <div class="modal-footer border-0 pt-1 mb-2 px-4">
                            <button id="ApprvCommentCancel" class="btn btn-secondary custom-btn-secondary secondary-btn mr-2 px-4 py-2" data-dismiss="modal" onClick={() => this.closeApprovalComments()}>Cancel</button>
                            <button id="ApprvCommentSave" class="btn btn-custom-primary font-15 font-medium px-4 py-2" onClick={() => this.approveDeclineOrder(this.state.selectedLineItems, 1, "QWA", this.state.Comments)}>Save</button>
                        </div>

                    </div>
                </div>
            </div>
            {/*Approval comments modal ends here*/}

            {/*Decline comments modal starts here*/}
            <div class="modal fade approval-popup pl-0" tabindex="-1" id="DeclineComments" role="dialog" aria-hidden="true" data-keyboard="false">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content border-0">
                        <div class="modal-header sp-delv-header align-items-center">
                            <h5 class="modal-title" >Decline comments</h5>
                            <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.closeDeclineComment()}>
                                X
                            </button>
                        </div>

                        <div class="modal-body px-4">
                            <div class="row">

                                <div class="col-md-12 mb-3">
                                    <label class="catalog-label" for="comments">Comments</label><span className="color-manditory" id="CmntsMandatory">*</span>
                                    <textarea class="form-control" id="DeclineComment" placeholder="Enter comments" maxLength="254" value={this.state.Comments} onChange={(e) => this.HandleComments(e)} rows="3"></textarea>
                                </div>

                            </div>
                        </div>

                        <div class="modal-footer border-0 pt-1 mb-2 px-4">
                            <button id="DeclnCommentCancel" class="btn btn-secondary custom-btn-secondary secondary-btn mr-2 px-4 py-2" data-dismiss="modal" onClick={() => this.closeDeclineComment()}>Cancel</button>
                            <button id="DeclnCommentSave" class="btn btn-custom-primary font-15 font-medium px-4 py-2" disabled={this.state.disabled_button} onClick={() => this.approveDeclineOrder(this.state.selectedLineItems, 2, "QWR", this.state.Comments)}>Decline</button>
                        </div>

                    </div>
                </div>
            </div>
            {/*Decline comments modal ends here*/}
		</div>);

	}
};
//[PC_07, 09/09/21, Author - Dhevanesam R]
export default withTranslation()(OrderApprovalComponent);