//10/18/23  PC_EC_04 Mythili -- Zeus-40 SDI Enterprise customer master

import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';
class EnterpriseCustStore extends EventEmitter {
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    //10/18/23  PC_EC_05 Mythili -- Zeus-40 SDI Enterprise customer master
    handleEnterpriseCust = (action) => {
        let PageLoadResponse = "";
        let InsertOrUpdateResponse = "";
        switch (action.action.type) {
            case "PageLoadResponse": {
                this.PageLoadResponse = action.action.response;
                this.emit('PageLoadResponse');
                break;
            }
            case "InsertOrUpdateResponse": {
                this.InsertOrUpdateResponse = action.action.response;
                this.emit('InsertOrUpdateResponse');
                break;
            }
            case "NOTIFY_InsertFavPage": {
                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }

}
const objEnterpriseCust = new EnterpriseCustStore;
// Dispatcher code
Dispatcher.register(objEnterpriseCust.handleEnterpriseCust.bind(objEnterpriseCust));
export default objEnterpriseCust;