/**
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Fav Order maintanance page component. The component displays the Fav Order maintanance page details.
 * 
 * App Name: SDI-Walmart
 * Author: Chandrasekar
 * Created Date: 08/13/2020 
 */
import React, { Component } from 'react';
import * as CookieService from '../CookieService';
/**
 * Ref: FOM_PC_01 * 
 * 
 * Importing the action and store file and set to an object.
 */
import * as FavOrderMainAction from '../actions/FavOrderMainAction';
import FavOrderMainStore from '../stores/FavOrderMainStore';
import ReactPaginate from 'react-paginate';
import SDIConstants, { redirectToItemDetails } from '../SDIConstants';
import * as MenuAction from '../actions/MenuAction';
import * as $ from 'jquery';
//[PC_07, 09/07/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
//[02/13/2024 - Zeus 260 - FavOrder revamp - Johnprinto]
import Image from 'react-image-resizer';
import Autocomplete from 'react-autocomplete';
import MultiSelect from 'react-multi-select-component';
import Select from 'react-select';

let searchTxt = "";
let currentPagePath;
let isPostBack = true;
let isPrioritySearch = false;
//[02/13/2024 - Zeus 260 - Category suggestion menu style - Johnprinto]
let menuStyle = {
    borderRadius: '3px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 12px',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0px 2px 12px',
    fontSize: '90%',
    position: 'absolute',
    maxHeight: '160px',
    left: '24px',
    top: '89px',
    minWidth: '86%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
};
//[02/13/2024 - Zeus 260 - Image style - Johnprinto]
const imgStyle = {
    image: {
        position: 'sticky !important',
        display: 'block',
        left: '0px !important',
        top: '0px !important',
        marginLeft: '7px'
    },
}
class FavOrderMainComponent extends Component {
    /**
     * Ref: FOM_PC_03
     * Define the constructor that takes props as parameter
     * 
     * @param props
     */
    constructor(props) {
        super(props);
        /**
         * Ref: FOM_PC_05
         * Set the initial values of the state variables
         */
        this.state = {
            favOrderArray: [],
            favOrderLineItemData: [],
            totalCount: 0,
            pageNo: 1,
            itemCount: 10,
            pageCount: 0,
            isSearch: false,
            searchColumn: "ALL",
            sortColumn: "LAST_UPDATE_DTTM",
            sortValue: "DESC",
            filterDisplayName: "All",
            isFavorited: false,
            deleteID: "",
            isPageLoadedOnce: false,
            priorityFlag: 0,
            //[02/13/2024 - Zeus 260 - FavOrder revamp - Johnprinto]
            selectedCategory: [],
            selectAll: true, subCategoryArr: [], selectedCatIndex: '',
            selectedRegion: '', selectedUser: [],
            categoryArr: [], regionDD: [], userDD: [],
            favOrdCat: "", favOrderSugg: [], favOrderCatSuggArr: [], currentOrderNo: "", searchTxt: ""
        };
        this.assignFavOrderData = this.assignFavOrderData.bind(this);
        this.onAccordianClick = this.onAccordianClick.bind(this);
        this.filterClick = this.filterClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchTxt = this.handleSearchTxt.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.sortingClick = this.sortingClick.bind(this);
        this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
        this.handleFavorite = this.handleFavorite.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleItemCheckbox = this.handleItemCheckbox.bind(this);
        this.addFavOrderItemsToCart = this.addFavOrderItemsToCart.bind(this);
        this.cartOrderSuccess = this.cartOrderSuccess.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.deleteFavOrder = this.deleteFavOrder.bind(this);
        this.deleteResponse = this.deleteResponse.bind(this);
        this.handlePriority = this.handlePriority.bind(this);
    };

    /**
    * Ref: FOM_PC_06
    * componentWillMount method will be first method which will be invoked
    * when Favorite order maintanance page is loaded
    */
    componentDidMount() {
        /**
        * Ref: FOM_PC_07
        * Invoke the action to get the page load data from API.
        */
        isPostBack = true;
        $("#priorityDiv").hide();
        this.pageLoad(this.state.pageNo,
            this.state.itemCount,
            "",
            "",
            this.state.sortColumn,
            this.state.sortValue
        );
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        FavOrderMainAction.getCurrentFavPage(getFavPageBO);
        /**
        * Ref: FOM_PC_09
        * Define the listener methods
        */
        FavOrderMainStore.on('favOrderload', this.assignFavOrderData);
        FavOrderMainStore.on('favpage', this.loadFavPageResponse);
        FavOrderMainStore.on('updateFavPage', this.updateFavPageResponse);
        FavOrderMainStore.on('addFavOrder', this.cartOrderSuccess);
        FavOrderMainStore.on('dltFavOrder', this.deleteResponse);
        FavOrderMainStore.on('shareResponse', this.shareButtonClickResponse);
        FavOrderMainStore.on('favOrderImageUpload', this.favOrderImageUpload);

    };
    componentWillUnmount() {
        FavOrderMainStore.removeListener('favOrderload', this.assignFavOrderData);
        FavOrderMainStore.removeListener('favpage', this.loadFavPageResponse);
        FavOrderMainStore.removeListener('updateFavPage', this.updateFavPageResponse);
        FavOrderMainStore.removeListener('addFavOrder', this.cartOrderSuccess);
        FavOrderMainStore.removeListener('dltFavOrder', this.deleteResponse);
        FavOrderMainStore.removeListener('shareResponse', this.shareButtonClickResponse);
        FavOrderMainStore.removeListener('favOrderImageUpload', this.favOrderImageUpload);
    }
    /**
    * Ref: FOM_PC_26
    * Listener method for delete response
    */
    deleteResponse = () => {
        const { t } = this.props;
        let deleteResponse = JSON.parse(FavOrderMainStore.deleteResponse)[0];
        if (deleteResponse.Success.toLowerCase() == "true") {
            this.refreshMenu();

            //[09/08/2023, PS_ZE_142_APP_01 - 4183 - Bug fix , Johnprinto D]
            if (this.state.isSearch) {
                searchTxt = $(`#searchTxt`).val().trim();
            }
            let pageNo = 1;
            isPostBack = false;
            this.pageLoad(pageNo,
                this.state.itemCount,
                this.state.searchColumn,
                searchTxt,
                this.state.sortColumn,
                this.state.sortValue
            );
            //alert('Favorite order deleted successfully');
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Favorite order deleted successfully")
            })
            this.setState({ pageNo: pageNo, sortColumn: "LAST_UPDATE_DTTM", sortValue: "DESC", filterDisplayName: "All", favOrdCat: "" });
        } else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Error in removing favorite order")
            })
            //alert("Error in removing favorite order");
        }
    };
    /**
    * Ref: FOM_PC_26
    * Listener method for adding cart items response
    */
    cartOrderSuccess = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let addFavOrderdataRes = JSON.parse(FavOrderMainStore.cartOrderSuccess);
        if (addFavOrderdataRes) {
            if (addFavOrderdataRes.Table1.length > 0) {
                addFavOrderdataRes = addFavOrderdataRes.Table1[0];
                if (addFavOrderdataRes.is_Success == "True") {
                    this.props.history.push({
                        pathname: '/shoppingcart',
                    });
                } else {
                    CookieService.updateLocalStorageValues("Session_CARTFAIL", addFavOrderdataRes.Session_CARTFAIL);
                }
            }
        }
    };
    /**
    * Listener method for the response of Save/Delete favorite page
    */
    updateFavPageResponse = () => {
        if (FavOrderMainStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(FavOrderMainStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(FavOrderMainStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    /**
    * Handle change method for favorite page checkbox
    * @param event
    */
    handleFavorite = (event) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        this.setState({ isFavorited: event.target.checked });
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Favorite Order Maintanance",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            FavOrderMainAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            FavOrderMainAction.deleteFavPage(deleteFavPageBO)
        }
    };
    /**
    * Listener method for loading favorite page icon
    */
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(FavOrderMainStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    /**
    * Reusable method for getting fav order details 
    */
    pageLoad = (pageNo, itemCount, searchColumn, searchText, sortColumn, sortValue) => {
        let pageLoadBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            pageNo: pageNo,
            itemCount: itemCount,
            searchColumn: searchColumn,
            searchText: searchText,
            sortColumn: sortColumn,
            sortValue: sortValue,
            Session_HOMESTORE: CookieService.getLocalStorageValues("Session_HOMESTORE"),
            //[02/13/2024 - Zeus 260 - FavOrder revmap - Johnprinto]
            ThirdParty_CompanyID: CookieService.getLocalStorageValues("ThirdParty_CompanyID"),
            Session_ACTIVEUSERS: CookieService.getLocalStorageValues("Session_ACTIVEUSERS"),
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        FavOrderMainAction.getFavOrderDetails(pageLoadBO);
    };
    /**
     * Ref: FOM_PC_14
     * Listener method for loading favorite order data
     */
    assignFavOrderData = () => {
        let pageLoadResponse = JSON.parse(FavOrderMainStore.pageLoadData);
        if (pageLoadResponse.LineItemData) {
            pageLoadResponse.LineItemData.forEach((item, index) => {
                item.checked = false;
                let filteredItem = [];
                if (pageLoadResponse.LineItemImgData) {
                    filteredItem = pageLoadResponse.LineItemImgData.filter((x) => x.ORDER_NO == item.ORDER_NO && x.BUSINESS_UNIT_OM == item.BUSINESS_UNIT_OM && x.ISA_INTFC_LN == item.ISA_INTFC_LN);
                };
                if (filteredItem.length > 0) {
                    item.INV_ITEM_ID = filteredItem[0].ISA_ITEM;
                };
                //[02/13/2024 - Zeus 260 - Favorder revamp - Johnprinto]
                if (item.ORDER_CATEGORY == " " || item.ORDER_CATEGORY == "" || item.ORDER_CATEGORY == null || item.ORDER_CATEGORY == undefined) {
                    item.ORDER_CATEGORY = "UNCLASSIFIED"
                }
                if (item.ORDER_SUBCATEGORY == " " || item.ORDER_SUBCATEGORY == "" || item.ORDER_SUBCATEGORY == null || item.ORDER_SUBCATEGORY == undefined) {
                    item.ORDER_SUBCATEGORY = "UNCLASSIFIED"
                }
                item.ORDER_SUBCATEGORY = item.ORDER_SUBCATEGORY
            });
        };
        //[02/13/2024 - Zeus 260 - Favorder revamp - Johnprinto]
        let category = []
        let HeaderData = pageLoadResponse.HeaderData;
        HeaderData.forEach((value, index) => {
            if (value.ORDER_CATEGORY == " " || value.ORDER_CATEGORY == "" || value.ORDER_CATEGORY == null) {
                value.ORDER_CATEGORY = "UNCLASSIFIED"
            }
            if (value.ORDER_SUBCATEGORY == " " || value.ORDER_SUBCATEGORY == "" || value.ORDER_SUBCATEGORY == null) {
                value.ORDER_SUBCATEGORY = "UNCLASSIFIED"
            }
            value.checked = false;
            value.ReorderCheck = false;
            value.SubCatcheck = false;
        });

        if (pageLoadResponse.HeaderData) {
            category = pageLoadResponse.HeaderData.filter((v, i, a) => a.findIndex(t => (t.ORDER_CATEGORY.trim() === v.ORDER_CATEGORY.trim())) === i)
        }
        let count = parseFloat(category.length) / 10
        let catpush = []

        if (category != undefined) {
            category.forEach((value, index) => {
                catpush.push({
                    BUSINESS_UNIT: value.BUSINESS_UNIT,
                    IMG_URL: value.IMG_URL,
                    FAV_ORDER_ID: value.FAV_ORDER_ID,
                    ISA_PRIORITY_FLAG: value.ISA_PRIORITY_FLAG,
                    ISA_WORK_ORDER_NO: value.ISA_WORK_ORDER_NO,
                    OPRID_ENTERED_BY: value.OPRID_ENTERED_BY,
                    ORDERED_FOR: value.ORDERED_FOR,
                    ORDER_CATEGORY: value.ORDER_CATEGORY,
                    ORDER_SUBCATEGORY: value.ORDER_SUBCATEGORY,
                    ORDER_DESC: value.ORDER_DESC,
                    ORDER_NO: value.ORDER_NO,
                    check: false,
                    SubCatcheck: false
                });
            });
        }

        let locEMP = []
        let locREGDD = []
        let locReg = []
        if (pageLoadResponse.EmployeeCC != undefined && pageLoadResponse.EmployeeCC != null) {
            if (pageLoadResponse.EmployeeCC.length > 0) {
                pageLoadResponse.EmployeeCC.map((val, index) => {
                    if (val.EMP_ID != CookieService.getLocalStorageValues("Session_UserID")) {
                        let localArray = {
                            value: val.EMP_ID,
                            label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
                        }

                        locEMP.push(localArray)
                    }
                });
                locReg = pageLoadResponse.EmployeeCC.filter((v, i, a) => a.findIndex(t => (t.REGION === v.REGION && t.REGION != null && t.REGION != " ")) === i)
                locReg.map((val, index) => {
                    let localArray = {
                        value: val.REGION,
                        label: val.REGION
                    }

                    locREGDD.push(localArray)
                });
            }
        }

        let locordcat = []
        if (catpush != null && catpush != "" && catpush != undefined) {
            catpush.forEach((sugg, index) => {
                if (sugg.ORDER_CATEGORY != null) {
                    locordcat.push({ label: sugg.ORDER_CATEGORY, value: sugg.ORDER_CATEGORY });
                }
            });
        }
        if (locordcat.length > 0) {
            locordcat = locordcat.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.label != null && t.label != " ")) === i)
            locordcat = locordcat.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }
        this.setState({
            favOrderArray: HeaderData,
            favOrderLineItemData: pageLoadResponse.LineItemData,
            totalCount: pageLoadResponse.TotalCount.length > 0 ? pageLoadResponse.TotalCount[0].TOTAL_COUNT : 0,
            pageCount: count,
            isPageLoadedOnce: true,
            userDD: locEMP, regionDD: locREGDD, EMPDD: pageLoadResponse.EmployeeCC,
            categoryArr: catpush,
            favOrderSugg: locordcat.length == 1 ? [] : locordcat,
            favOrderCatSuggArr: locordcat.length == 1 ? [] : locordcat,
            selectAll: true
        });
        if (pageLoadResponse.HeaderData && pageLoadResponse.HeaderData.length > 0) {
            this.SelectSubCategory("pageLoad", category[0].ORDER_CATEGORY, 0, category[0]);
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        MenuAction.refreshMasterData()

    };
    /**
     * Ref: FOM_PC_22
     * This method will be invoked on click of delete icon
     * 
     */
    handleDelete = () => {
        // 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4762 - Johnprinto
        let delOrder = this.state.favOrderArray.filter((x) => x.checked == true)
        if (delOrder.length > 0) {
            $('#Cancel-confirmation').modal('show');
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Select any Order/Category"
            });
        }
    };
    /**
     * Ref: FOM_PC_20
     * This method will be invoked on click of sort icon
     * @param sortColumn,@param sortValue
     */
    sortingClick = (sortColumn, sortValue) => {
        console.log(sortColumn, sortValue);
        if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }
        isPostBack = false;
        let pageNo = 1;
        this.pageLoad(pageNo,
            this.state.itemCount,
            this.state.searchColumn,
            searchTxt,
            sortColumn,
            sortValue
        );
        this.setState({ pageNo: pageNo, sortColumn: sortColumn, sortValue: sortValue });
    }
    /**
    * Ref: FOM_PC_24
    * This method will be invoked on click of page number
    */
    handlePageClick = (data) => {
        let pageNo = data.selected + 1;
        this.setState({ pageNo: pageNo });
    };

    /**
    * Ref :FOM_PC_19
    * This method will be invoked on change of Priority radio button
    * @param event
    */
    handlePriority = (value) => {
        this.setState({ priorityFlag: value });
        let priorityFlag = ``;
        if (value == 1) {
            priorityFlag = `R`;
        } else {
            priorityFlag = ` `;
        }
        this.handleSearch(true, priorityFlag);
    }
    /**
     * Ref :FOM_PC_19
     * This method will be invoked on change of search textbox
     * @param event
     */
    handleSearchTxt = (event) => {
        this.setState({ isSearch: false, searchTxt: event.target.value });
    }
    /**
     *  Ref :FOM_PC_19
     * This method will be invoked on enter button click
     */
    keyPress(e, filter) {
        if (e.keyCode == 13) {
            if (filter == "SEARCH") {
                this.handleSearch(false, "");
            }
            else {
                this.handleCatSearch(this.state.favOrdCat);
            }

        }
    };
    /*
     * Common method to refresh the master data in menu
     */
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        console.log("masterBO", masterBO);
        MenuAction.getMasterData(masterBO);
    };
    /**
     * Ref:FOM_PC_23
     * This method will be invoked on yes button on delete confirmation popup
     */
    deleteFavOrder = () => {
        //[02/13/2024 - Zeus 260 - Favorder revamp - Johnprinto]
        let deleteOrderID = [];
        this.state.favOrderArray.forEach((value) => { if (value.checked) { deleteOrderID.push({ FAV_ORDER_ID: value.FAV_ORDER_ID }) } });
        let deleteBO = {
            FavOrderIDList: deleteOrderID,
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID")
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        FavOrderMainAction.deleteFavOrder(deleteBO);
    };
    /**
    * Ref :FOM_PC_19
    * This method will be invoked on Search icon click
    */
    handleSearch = (isPriority, priorityFlag) => {
        let pageNo = 1;
        if (isPriority == true) {
            isPrioritySearch = true;
            searchTxt = priorityFlag;
        } else {
            isPrioritySearch = false;
            searchTxt = $(`#searchTxt`).val().trim();
        }
        isPostBack = false;
        this.pageLoad(pageNo,
            this.state.itemCount,
            this.state.searchColumn,
            searchTxt,
            this.state.sortColumn,
            this.state.sortValue
        );
        this.setState({ pageNo: pageNo, isSearch: true, favOrdCat: '' });
    };
    ClearSearch = (filter) => {
        if (filter == "SEARCH") {
            this.setState({ searchTxt: "", isSearch: false })
        }
        else {
            this.setState({ favOrdCat: "", favOrderSugg: this.state.favOrderCatSuggArr })
        }
    }
    /**
    * Ref :FOM_PC_21
    * This method is invoked when the Expand Order Button is click in the Order Level.
    * @param event,@param index
    */
    onAccordianClick = (event, index) => {
        //[02/13/2024 - Zeus 260 - Favorder revamp - Johnprinto]
        if (event.target.id.includes('Span-accordian-change')) {
            $('.accordian-body').removeClass('show');
            $('.accordion-arrow').removeClass('accordion-arrow-change');
        }
        $(event.currentTarget).children().toggleClass('accordion-arrow-change');
    };
    /**
    * Ref :FOM_PC_17
    * This method is invoked when the checkbox of a item is selected
    * @param lineID,@param OrderNo
    */
    handleItemCheckbox = (lineID, OrderNO) => {
        let { favOrderLineItemData } = this.state;
        let { favOrderArray } = this.state;
        let index = favOrderLineItemData.findIndex(x => x.ORDER_NO == OrderNO && x.ISA_INTFC_LN == lineID);
        favOrderLineItemData[index].checked = favOrderLineItemData[index].checked == true ? false : favOrderLineItemData[index].checked == false ? true : true;

        let favOrderLineItemDatacount = favOrderLineItemData.filter((x) => x.ORDER_NO == OrderNO).length
        let favOrderLineItemDatacountchecked = favOrderLineItemData.filter((x) => x.ORDER_NO == OrderNO && x.checked == true).length
        if (favOrderLineItemDatacount != favOrderLineItemDatacountchecked) {
            this.state.favOrderArray.forEach((y) => {
                if (y.ORDER_NO == OrderNO) {
                    y.ReorderCheck = false;
                }
            })
        }
        else {
            this.state.favOrderArray.forEach((y) => {
                if (y.ORDER_NO == OrderNO) {
                    y.ReorderCheck = true;
                }
            })
        }
        this.setState({ favOrderArray, favOrderLineItemData })
    };
    handleOrderCheckbox = (OrderNO) => {
        let { favOrderLineItemData } = this.state;
        let favorderboolean = false
        this.state.favOrderArray.forEach((y) => {
            if (y.ORDER_NO == OrderNO) {
                y.ReorderCheck = y.ReorderCheck == true ? false : true;
                favorderboolean = y.ReorderCheck
            }
        })
        favOrderLineItemData.forEach((x, index) => {
            if (x.ORDER_NO == OrderNO) {
                favOrderLineItemData[index].checked = favorderboolean;
            }

        })
        this.setState(favOrderLineItemData);
    };
    /**
    * Ref :FOM_PC_16
    * This method is invoked on change of quantity textbox
    * @param e,@param lineID,@param OrderNo
    */
    handleQuantityChange = (e, lineID, OrderNO) => {
        const re = SDIConstants.quantityRegEX;
        if (e.target.value === '' || re.test(e.target.value)) {
            if (Math.round(e.target.value) != "0" || !e.target.value) {
                let { favOrderLineItemData } = this.state;
                let index = favOrderLineItemData.findIndex(x => x.ORDER_NO == OrderNO && x.ISA_INTFC_LN == lineID);
                favOrderLineItemData[index].QTY_REQUESTED = e.target.value;
                this.setState(favOrderLineItemData);
            }
        }
    };
    /**
     * Ref :FOM_PC_14
     * This method is to bind the fav order line items
     * @param OrderNo
     */
    bindFavOrderLineItems = (OrderNo) => {
        const { t } = this.props;
        let childItems = this.state.favOrderLineItemData.filter((item, index) => item.ORDER_NO == OrderNo);
        return childItems.map((childItem, childindex) => {
            return (<div className="col-md-12 float-left mx-0 mb-3 pt-3 pb-2 item-box" key={childindex}>
                <div className="col-md-1 float-left text-center px-0">

                    <div className="custom-control custom-checkbox item-custom-control d-inline">
                        <input type="checkbox" className="custom-control-input" id={`FavOrder-${OrderNo}${childindex}`} checked={childItem.checked} name={`example1`} onClick={() => this.handleItemCheckbox(childItem.ISA_INTFC_LN, OrderNo)} />
                        <label className="custom-control-label" htmlFor={`FavOrder-${OrderNo}${childindex}`} />
                    </div>
                </div>
                <div className="col-lg-7 float-left col-md-7 px-0">
                    <div className="row">
                        <div className="col-md-12 fav-maintence">
                            <label className="catalog-label"><Trans>Description</Trans> :</label>
                            <label className="item-details-value">{childItem.DESCR254}</label>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 Ipad-UI fav-maintence">
                                    <label className="catalog-label"><Trans>ID</Trans>:</label>
                                    {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                    {!childItem.LINE_FIELD_C6 || childItem.LINE_FIELD_C6.trim() == "" || childItem.LINE_FIELD_C6 == 'NSTK' || childItem.LINE_FIELD_C6 == 'PCH' ?
                                        <label className="item-details-value" id={`ItemKey-${childItem.MFG_ITM_ID}-${childindex}`}>{childItem.MFG_ITM_ID}</label>
                                        :
                                        <label className="item-details-value hyper-link back-icon" id={`ItemKey-${childItem.MFG_ITM_ID}-${childindex}`} onClick={() => redirectToItemDetails(childItem.INV_ITEM_ID, childItem.MFG_ITM_ID, childItem.VENDOR_ID, "FavOrder",this.props)}>{childItem.MFG_ITM_ID}</label>
                                    }
                                    {/*<label className="item-details-value pl-1">{childItem.MFG_ITM_ID}</label>*/}
                                    {/* 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4765 - Johnprinto */}
                                    {childItem.ItemType == "ORO" ?
                                        <span className="oro-item">O</span> : null}
                                    {childItem.ItemType == "VMI" ?
                                        <span className="oro-item vmi-item">V</span> : null}

                                </div>
                                <div className="col-lg-4 col-md-4 Ipad-UI fav-maintence">
                                    <label className="catalog-label"><Trans>Delivery Date</Trans>:</label>
                                    <label className="item-details-value pl-1">{childItem.ISA_REQUIRED_BY_DT}</label>
                                </div>
                                <div className="col-lg-3 col-md-3 Ipad-UI fav-maintence">
                                    <label className="catalog-label">UOM:</label>
                                    <label className="item-details-value pl-1">{childItem.UNIT_OF_MEASURE}</label>
                                </div>
                                <div className="col-lg-5 col-md-5 Ipad-UI fav-maintence">
                                    <label className="catalog-label"><Trans>Manufacturer</Trans>:</label>
                                    <label className="item-details-value pl-1">{childItem.ISA_MFG_FREEFORM}</label>
                                </div>
                                <div className="col-lg-7 col-md-7 Ipad-UI fav-maintence">
                                    {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                                    <label className="catalog-label">Supplier <Trans>Part</Trans>#:</label>
                                    <label className="item-details-value pl-1">{childItem.INV_ITEM_ID != " " && childItem.INV_ITEM_ID ? childItem.INV_ITEM_ID : `NONCAT-${childItem.ISA_INTFC_LN}`}</label>

                                </div>
                                <div className="col-lg-7 col-md-7 Ipad-UI fav-maintence">
                                    <label className="catalog-label"><Trans>Charge</Trans> <Trans>Code</Trans>:</label>
                                    <label className="item-details-value pl-1">{childItem.ISA_CUST_CHARGE_CD}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 float-left col-md-4">
                    <div className="float-right">
                        <div className="w-100 float-right">
                            <div className="float-right">
                                <div className="float-left">
                                    <div className="float-left">
                                        <label className="float-left m-2"><Trans>Unit</Trans> QTY :</label>
                                        <input type="text" className="form-control float-left catalog-inline-qty" maxLength="8" value={childItem.QTY_REQUESTED} onChange={(e) => this.handleQuantityChange(e, childItem.ISA_INTFC_LN, OrderNo)} />

                                    </div>
                                </div>
                                {/*<div className="float-right mt-2">
                                    <span className="price-calc ml-2">X</span>
                                    <span className="price-rate">$  {childItem.ISA_SELL_PRICE > 0 ? childItem.ISA_SELL_PRICE.toFixed(2) : "Price on request"}</span>
                                </div>*/}

                            </div>
                        </div>
                        <div className="w-100 float-right">
                            <div className="float-right">
                                <label className="float-left mt-2"><Trans>Unit Price</Trans> :</label>
                                <div className="float-left mt-2">
                                    <span className="price-rate">  {childItem.ISA_SELL_PRICE > 0 ? "$" + childItem.ISA_SELL_PRICE.toFixed(2) : t("Price on request")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 float-right">
                            {childItem.ISA_SELL_PRICE > 0 ? <div className="float-right">
                                <label className="float-left mt-2">Ext <Trans>Price</Trans> :</label>
                                <div className="float-left mt-2">
                                    <span className="price-rate">{this.OrderTotalCal(childItem.ISA_SELL_PRICE, childItem.QTY_REQUESTED)}</span>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>)

        })
    }
    OrderTotalCal = (Price, Quantity) => {
        //let SiteCurrency = this.state.SiteCurrencyTable;
        let ItemTotal = 0;
        let OrderTotal = 0;

        let uniteprice = parseFloat(Price) ? parseFloat(Price) : 0
        let QuantityAvail = parseFloat(Quantity) ? parseFloat(Quantity) : 0
        if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
            ItemTotal = uniteprice * QuantityAvail;
            //ItemTotal = uniteprice;
        }
        OrderTotal = OrderTotal + ItemTotal;
        let roundOffvalue = OrderTotal.toFixed(2)
        return ("$ " + roundOffvalue);



    }
    /**
     * Ref :FOM_PC_14
     * This method is to bind the fav orders
     */
    bindFavOrderGrid = (index, ORDER) => {
        return this.state.favOrderArray.map((order, index) => {
            if (order.ORDER_CATEGORY == ORDER.ORDER_CATEGORY && ORDER.ORDER_SUBCATEGORY == order.ORDER_SUBCATEGORY) {
                return (

                    <div className="w-100">
                        <div className="row align-items-center py-4">
                            <div className="col-12 col-lg-1 col-md-1 p-0 mb-3">
                                <span data-toggle="collapse" id={`#Order-accordian${order.ORDER_NO}${index}`} data-target={`#Item-accordian${order.ORDER_NO}${index}`} aria-controls={`#Item-accordian${order.ORDER_NO}${index}`} className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, index)}><span className="accordion-arrow" /></span>
                            </div>
                            <div className="col-12 col-lg-1 col-md-1 p-0 mb-3 ">
                                <div className="d-flex justify-content-center">
                                    <div className="custom-control custom-checkbox item-custom-control">
                                        <input type="checkbox" className="custom-control-input" id={`ReOrder-${index}`} name="" checked={order.ReorderCheck} onChange={e => this.handleOrderCheckbox(order.ORDER_NO)} />
                                        <label className="custom-control-label" htmlFor={`ReOrder-${index}`} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-10 col-md-6">
                                <div className="row align-items-center">
                                    <div className="col-md-7 col-lg-2 mb-3">
                                        <Image
                                            id={`Img${order.ORDER_NO}${index}id`}
                                            src={order.IMG_URL}
                                            width={100}
                                            height={100}
                                            overflow="hidden"
                                            alignItems="center"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            style={imgStyle.image}
                                            noImageSrc="/Images/noimage_new.png"
                                        />
                                        <div className="d-flex">
                                            <input type="file" name="input" id={`file-${order.ORDER_NO}${index}`} style={{ display: 'none' }} onChange={(e) => this.handleAttachmentImg(e, order.ORDER_NO)} />
                                            <label htmlFor={`file-${order.ORDER_NO}${index}`} className="sdi-edit-label mb-0 sdi-edit-bg">
                                                <span>
                                                </span>Edit</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-10">
                                        <div class="d-flex justify-content-between align-items-center my-3">
                                            <div>
                                                <h5 className="font-bold">{order.ORDER_NO}</h5>
                                            </div>
                                            <div>
                                                <div className="d-flex font-bold mb-1">Share/Delete <input type="checkbox" className="sdi-checkbox ml-2" id={`Order-${index}`} name="" checked={order.checked} onChange={e => this.CategoryOnCheck("Item", order, order.checked)} /></div>
                                            </div>
                                        </div>
                                        <div className="d-md-none d-lg-block">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <label className="catalog-label w-100">Priority :</label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            {/* 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4743 - Johnprinto */}
                                                            <label>{order.ISA_PRIORITY_FLAG == `R` ? `Priority` : `-`}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="catalog-label w-100">Work Order :</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>{order.ISA_WORK_ORDER_NO}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-7 word-break-all">
                                                            <label className="catalog-label w-100">Order Entered by :</label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <label>{order.OPRID_ENTERED_BY}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="catalog-label w-100">Business Unit :</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>{order.BUSINESS_UNIT}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <label className="catalog-label w-100">Ordered For :</label>
                                                        </div>
                                                        <div className="col-md-5 word-break-all">
                                                            <label>{order.ORDERED_FOR}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" >
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="catalog-label w-100">Asset ID:</label>
                                                        </div>
                                                        <div className="col-md-6 word-break-all">
                                                            <label>{order.ISA_MACHINE_NO}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-1">
                                                            <label className="catalog-label mr-3">Notes:</label>
                                                        </div>
                                                        <div className="col-md-11 word-break-al">
                                                            {/* 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4743 - Johnprinto */}
                                                            <label>{order.ORDER_DESC && order.ORDER_DESC.trim() ? order.ORDER_DESC : "-"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <td class="sdi-category-image"></td> */}
                        </div>
                        <div className="row accordian-body py-5 collapse sdi-category-grid" id={`Item-accordian${order.ORDER_NO}${index}`}>
                            <div className="col-md-12">
                                <h4 className="item-details-title">item details</h4>
                                {this.bindFavOrderLineItems(order.ORDER_NO)}
                                <div className="text-right">
                                    <button className="btn btn-primary custom-btn-primary primary-btn mb-2" onClick={() => this.addFavOrderItemsToCart(order.ORDER_NO, false, false)}>Add to cart</button>
                                </div>
                            </div>
                        </div>
                    </div>

                );

            }

        })
    };

    handleAttachmentImg = (event, OrderNo) => {
        let files = event.target.files;
        let acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (acceptedImageTypes.includes(files[0].type)) {
            if (files.length > 0) {
                let { favOrderArray } = this.state;
                let index = this.state.favOrderArray.findIndex(elem => elem.ORDER_NO === OrderNo);
                this.setState({ attachmentErrormsg: "", favOrderArray: favOrderArray, currentOrderNo: OrderNo });
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                let fileBO = {
                    fileUpload: "FavOrder",
                    Order_No: OrderNo,
                    UserId: CookieService.getLocalStorageValues("Session_UserID"),
                    Business_Unit: CookieService.getLocalStorageValues("Session_BUSUNIT")
                }
                FavOrderMainAction.addFavOrderImg(files, fileBO);
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please choose only Image file "
            });
        }

    };
    favOrderImageUpload = () => {
        let favOrderArray = this.state.favOrderArray;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        $(`#nAttachments`).val('');
        let isValid = FavOrderMainStore.favOrderImageUpload.indexOf("You have exceeded the length of total file names") >= 0 ? false : true;
        if (isValid) {
            let attachmentResponse = JSON.parse(FavOrderMainStore.favOrderImageUpload);
            if (attachmentResponse[0].FilePath.includes("Success")) {
                this.refreshMenu();//3/13/2024 - Zeus 264 - SIT -1 Bug fixing - Johnprinto D
                let index = this.state.favOrderArray.findIndex(elem => elem.ORDER_NO === this.state.currentOrderNo);
                favOrderArray[index].IMG_URL = attachmentResponse[0].FilePath.split("||")[1];

                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Image Updated Successfully"
                });
                this.setState({ favOrderArray });
            } else {

                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Updation Failed"
                })
            }
        } else {
            this.setState({
                attachmentErrormsg: "You have exceeded the length of total file names. \nPlease adjust your entry or remove longer file names."
            });
        }
    };

    bindCategory = () => {
        return this.state.categoryArr.map((order, index) => {
            return (
                <React.Fragment key={order.ORDER_CATEGORY}>
                    <li className="card-header sdi-category-header mb-2" id={`Category-${index}`} onClick={e => this.SelectSubCategory("", order.ORDER_CATEGORY, index, order)}><div className="d-flex justify-content-between" >{order.ORDER_CATEGORY == null || order.ORDER_CATEGORY == undefined ? "UNCLASSIFIED" : order.ORDER_CATEGORY.trim() == "" ? "UNCLASSIFIED" : order.ORDER_CATEGORY}
                        <input type="checkbox" className="sdi-checkbox" id={`CategoryCheckBox-${index}`} name="" checked={order.check} onChange={e => this.CategoryOnCheck("cat", order, order.check)} /></div></li>
                </React.Fragment>
            )
        });
    }

    SelectSubCategory = (event, cat, index, catItem) => {
        let locsubcat = []
        let filteredSubCat = []
        let count = 0
        if (event == "pageLoad") {
            $(`.sdi-category-header-active`).removeClass('sdi-category-header-active')
            $(`#Category-${index}`).addClass('sdi-category-header-active')
            $('.accordian-body').removeClass('show');
            $('.accordion-arrow').removeClass('accordion-arrow-change');
        }
        else {
            $(`.sdi-category-header-active`).removeClass('sdi-category-header-active')
            $(`#Category-${index}`).addClass('sdi-category-header-active')
            if (this.state.selectedCatIndex != index) {
                $('.accordian-body').removeClass('show');
                $('.accordion-arrow').removeClass('accordion-arrow-change');
            }
        }
        locsubcat = this.state.favOrderLineItemData.filter((val, ind) => val.ORDER_CATEGORY == cat)
        filteredSubCat = locsubcat.filter((v, i, a) => a.findIndex(t => (t.ORDER_SUBCATEGORY === v.ORDER_SUBCATEGORY)) === i)
        if (filteredSubCat.length > 0) {
            count = filteredSubCat.length;
        }
        let countT = parseFloat(count) / 10
        if (catItem.check) {
            if (filteredSubCat != [] && filteredSubCat != undefined && filteredSubCat != null) {
                filteredSubCat.forEach((x, index) => {
                    if (x.ORDER_CATEGORY == catItem.ORDER_CATEGORY) {
                        filteredSubCat[index].SubCatcheck = true
                    }
                })
                this.setState({ subCategoryArr: filteredSubCat });
            }
        }
        else {
            if (filteredSubCat != [] && filteredSubCat != undefined && filteredSubCat != null) {
                filteredSubCat.forEach((x, index) => {
                    if (x.ORDER_CATEGORY == catItem.ORDER_CATEGORY) {
                        filteredSubCat[index].SubCatcheck = false
                    }
                })
                this.setState({ subCategoryArr: filteredSubCat });
            }
        }

        this.setState({ selectedCategory: cat, selectedCatIndex: index, subCategoryArr: filteredSubCat, totalCount: count, pageCount: countT, pageNo: 1 });
    }



    /**
     * This method will be invoked on selecting filter column names
     * @param event,@param displayName,@param columnName
     */
    filterClick = (event, displayName, columnName) => {
        event.preventDefault();
        this.setState({ filterDisplayName: displayName, searchColumn: columnName });
        if (displayName == `Priority`) {
            $("#searchTxt").hide();
            $("#searchIcon").hide();
            $("#priorityDiv").show();
            //this.setState({ priorityFlag: 0 });
        } else {
            $("#searchTxt").show();
            $("#searchIcon").show();
            $("#priorityDiv").hide();
        }

    };
    /**
     * Ref: FOM_PC_18
     * This method will be invoked on clicking Reorder button
     * @param ORDER_NO
     */
    addFavOrderItemsToCart = (ORDER_NO) => {
        const { t } = this.props;
        let itemsSelected = this.state.favOrderLineItemData.filter((favOrder) => favOrder.checked == true && favOrder.ORDER_NO == ORDER_NO);
        let isQuantityPresent = itemsSelected.filter((x) => x.QTY_REQUESTED == "").length == 0 ? true : false;
        if (!isQuantityPresent) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please enter quantity for the selected line items")
            })
            //alert("Please enter quantity for the selected line items");
            return false;
        }
        let itemsToAdd = [];
        if (itemsSelected.length > 0 && isQuantityPresent) {
            itemsSelected.forEach((value) => {
                //let prc = value.ISA_SELL_PRICE == "" || value.ISA_SELL_PRICE == " " || value.ISA_SELL_PRICE == null || value.ISA_SELL_PRICE == undefined ? "0" : value.ISA_SELL_PRICE;
                itemsToAdd.push({
                    Order_Number: value.ORDER_NO,
                    Business_unit_om: value.BUSINESS_UNIT_OM,
                    INV_ITEM_ID: value.INV_ITEM_ID,
                    Quantity: value.QTY_REQUESTED,
                    ISA_INTFC_LN: value.ISA_INTFC_LN,
                    MFG_ITM_ID: value.MFG_ITM_ID,
                    ISA_MFG_FREEFORM: value.ISA_MFG_FREEFORM,
                    Descr: value.DESCR254,
                    price: value.ISA_SELL_PRICE
                });

            });

            let addFavOrderItemsBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                LineItems: itemsToAdd,
                Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "

            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            FavOrderMainAction.addFavOrderItemsTocart(addFavOrderItemsBO);
        } else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please select line items to reorder")
            })
            //alert("Please select line items to reorder");
        }

    };
    //Zeus 260 -  Trigger when user click the select all button - Johnprinto
    selectAllButtonClick = (e, selectAll) => {
        let { favOrderArray } = this.state;
        let { categoryArr } = this.state;
        let { subCategoryArr } = this.state;
        if (selectAll) {
            favOrderArray.forEach((x, i) => {
                this.state.favOrderArray[i].checked = true
            })
            categoryArr.forEach((x, i) => {
                this.state.categoryArr[i].check = true
            })
            subCategoryArr.forEach((x, i) => {
                this.state.subCategoryArr[i].SubCatcheck = true
            })
        }
        else {
            favOrderArray.forEach((x, i) => {
                this.state.favOrderArray[i].checked = false
            })
            categoryArr.forEach((x, i) => {
                this.state.categoryArr[i].check = false
            })
            subCategoryArr.forEach((x, i) => {
                this.state.subCategoryArr[i].SubCatcheck = false
            })
        }
        this.setState({ favOrderArray, categoryArr, subCategoryArr, selectAll: !this.state.selectAll });
    }

    //Zeus 260 - Trigger when user click the Share button - Johnprinto
    shareMultipleItems = () => {
        let filteritem = this.state.favOrderArray.filter((x) => x.checked == true)
        if (filteritem.length > 0) {
            $('#FavOrderSharing').modal('show');
            document.getElementById('usererror').innerHTML = ""
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Select any category / order"
            });
        }

    }
    favOrderShareClose = () => {
        let locEMP = [];
        this.state.EMPDD.forEach((val, index) => {
            let localArray = {
                value: val.EMP_ID,
                label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
            }
            locEMP.push(localArray)
        });
        document.getElementById('usererror').innerHTML = ""
        this.setState({ selectedRegion: [], selectedUser: [], userDD: locEMP });
    }
    handleOnChange = (event, name, index) => {
        switch (name) {
            case "selectedRegion": {
                let emp = []
                if (event != null) {
                    this.state.EMPDD.map((val, index) => {
                        if (event.value == val.REGION) {
                            let localArray = {
                                value: val.EMP_ID,
                                label: val.ISA_EMPLOYEE_NAME + "  " + val.ISA_EMPLOYEE_EMAIL
                            }
                            emp.push(localArray)
                        }
                    });
                }
                else {
                    this.state.EMPDD.map((val, index) => {
                        let localArray = {
                            value: val.EMP_ID,
                            label: val.ISA_EMPLOYEE_NAME + "  " + val.ISA_EMPLOYEE_EMAIL
                        }
                        emp.push(localArray)
                    });
                }
                this.setState({ [name]: event, userDD: emp, selectedUser: [] });
                break;
            }
            case "selectedUser": {
                let selectedPC = [];
                event.forEach((val, ind) => {
                    selectedPC.push(val);
                })
                // 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4763 - Johnprinto
                document.getElementById('usererror').innerHTML = ""
                this.setState({ selectedUser: selectedPC });
                break;
            }


        }

    }

    shareButtonClick = () => {
        let empids = []
        let shareorderdetail = []
        this.state.selectedUser.forEach((x) => {
            empids.push(x.value)
        })
        if (empids.length > 0) {
            let shareorder = this.state.favOrderArray.filter((x) => x.checked == true)
            let uniqueOrders = [];
            shareorder.forEach((x) => {
                this.state.favOrderLineItemData.forEach((y) => {
                    if (x.ORDER_NO == y.ORDER_NO && !uniqueOrders.includes(x.ORDER_NO)) {
                        uniqueOrders.push(x.ORDER_NO);
                        shareorderdetail.push(y)
                    }
                })

            })
            let OrderDetails = [];
            shareorderdetail.forEach((x, i) => {
                OrderDetails.push({
                    OrderNo: shareorderdetail[i].ORDER_NO,
                    EmployeeID: shareorderdetail[i].ISA_EMPLOYEE_ID,
                    BusinessUnit: shareorderdetail[i].BUSINESS_UNIT_OM,
                    OrderEnteredFor: shareorderdetail[i].ORDERED_FOR,
                    OrderEnteredBy: shareorderdetail[i].OPRID_ENTERED_BY,
                    WorkOrder: shareorderdetail[i].ISA_WORK_ORDER_NO,
                    Priority: shareorderdetail[i].ISA_PRIORITY_FLAG,
                    OrderCategory: shareorderdetail[i].ORDER_CATEGORY != undefined && shareorderdetail[i].ORDER_CATEGORY != null ? shareorderdetail[i].ORDER_CATEGORY.replaceAll("'", "''") : "UNCLASSIFED",
                    OrderSubCategory: shareorderdetail[i].ORDER_SUBCATEGORY != undefined && shareorderdetail[i].ORDER_SUBCATEGORY != null ? shareorderdetail[i].ORDER_SUBCATEGORY.replaceAll("'", "''") : "UNCLASSIFED",
                    OrderDescription: shareorderdetail[i].ORDER_DESC != undefined && shareorderdetail[i].ORDER_DESC != null ? shareorderdetail[i].ORDER_DESC.replaceAll("'", "''") : ""
                })
            })

            let BO = {
                FavOrders: OrderDetails,
                EmpIds: empids
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            FavOrderMainAction.shareButtonClickAction(BO);
        }
        else {
            document.getElementById('usererror').innerHTML = "Please select user"
        }

    }

    shareButtonClickResponse = () => {
        let Response = FavOrderMainStore.shareResponse;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (Response == "Success") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Shared successfully"
            });

        } else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Unable to share the selected order"
            });

        }
        $('#FavOrderSharing').modal('hide');
        let locEMP = [];
        this.state.EMPDD.forEach((val, index) => {
            let localArray = {
                value: val.EMP_ID,
                label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
            }
            locEMP.push(localArray)
        });
        this.pageLoad(this.state.pageNo,
            this.state.itemCount,
            "",
            "",
            this.state.sortColumn,
            this.state.sortValue
        );
        document.getElementById('usererror').innerHTML = ""
        this.setState({ selectedUser: [], selectedRegion: [], userDD: locEMP, favOrdCat: "" })
    }
    bindSubCategory = () => {
        return this.state.subCategoryArr.map((order, index) => {
            if (order.ORDER_CATEGORY == this.state.selectedCategory) {
                if (index < parseInt(this.state.pageNo) * 10 && index >= parseInt(this.state.pageNo - 1) * 10) {
                    return (
                        <React.Fragment>
                            <tr>
                                <td data-toggle="collapse" data-target={`#Order-accordian${order.ORDER_NO}${index}`} aria-controls={`#Order-accordian${order.ORDER_NO}${index}`} id={`SubCat-accordian${order.ORDER_NO}${index}`} className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, index)}>
                                    <span id={`Span-accordian-change${order.ORDER_NO}${index}`} name={`Span-accordian-change`} className="accordion-arrow" />
                                </td>
                                <td><h5 className="font-20 mb-0 font-bold">{order.ORDER_SUBCATEGORY}</h5></td>
                                <td className="d-flex justify-content-end  pr-5">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex font-bold mb-1">Share/Delete
                                            <input type="checkbox" className="sdi-checkbox ml-2" id={`SubCategroeyCheckbox${index}`} name="" checked={order.SubCatcheck} onChange={e => this.CategoryOnCheck("SubCat", order, order.SubCatcheck)} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="accordian-body collapse py-1" id={`Order-accordian${order.ORDER_NO}${index}`}   >
                                <td colSpan={12} className="pl-5 pr-3 py-0">
                                    {this.bindFavOrderGrid(index, order)}
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                }
            }

        });
    }

    CategoryOnCheck = (type, item, boolean) => {
        let { categoryArr } = this.state;
        let { subCategoryArr } = this.state;
        let { favOrderArray } = this.state;
        let { selectAll } = this.state;
        if (type == "cat") {
            let I = categoryArr.findIndex(t => (t.ORDER_CATEGORY === item.ORDER_CATEGORY))
            this.state.categoryArr[I].check = categoryArr[I].check == true ? false : categoryArr[I].check == false ? true : true;
            this.state.favOrderArray.forEach((x, index) => {
                if (x.ORDER_CATEGORY == item.ORDER_CATEGORY) {
                    this.state.favOrderArray[index].checked = categoryArr[I].check
                }
            })
            let locsubcat = this.state.favOrderLineItemData.filter((val, ind) => val.ORDER_CATEGORY == item.ORDER_CATEGORY)
            subCategoryArr = locsubcat.filter((v, i, a) => a.findIndex(t => (t.ORDER_SUBCATEGORY === v.ORDER_SUBCATEGORY)) === i)

            if (subCategoryArr != [] && subCategoryArr != undefined && subCategoryArr != null) {
                subCategoryArr.forEach((x, index) => {
                    if (x.ORDER_CATEGORY == item.ORDER_CATEGORY) {
                        subCategoryArr[index].SubCatcheck = categoryArr[I].check
                    }
                })
                this.setState(subCategoryArr);
            }
            let allTrue = true;
            categoryArr.forEach(obj => {
                if (!obj.check) {
                    allTrue = false;
                    return;
                }
            }
            );
            if (allTrue) {
                selectAll = false;
            } else {
                selectAll = true;
            }
            this.setState({ selectAll, categoryArr });
        }
        else if (type == "SubCat") {
            let I = subCategoryArr.findIndex(t => (t.ORDER_CATEGORY === item.ORDER_CATEGORY && t.ORDER_SUBCATEGORY === item.ORDER_SUBCATEGORY))
            subCategoryArr[I].SubCatcheck = subCategoryArr[I].SubCatcheck == true ? false : subCategoryArr[I].SubCatcheck == false ? true : true;
            this.state.favOrderArray.forEach((x, index) => {
                if (x.ORDER_CATEGORY == item.ORDER_CATEGORY && x.ORDER_SUBCATEGORY === item.ORDER_SUBCATEGORY) {
                    this.state.favOrderArray[index].checked = subCategoryArr[I].SubCatcheck
                }
            })

            let checkedsubcat = subCategoryArr.filter((t) => (t.ORDER_CATEGORY == item.ORDER_CATEGORY && t.SubCatcheck == true))
            let In = categoryArr.findIndex(t => (t.ORDER_CATEGORY == item.ORDER_CATEGORY))
            if (subCategoryArr.length == checkedsubcat.length) {
                categoryArr[In].check = true;
            }
            else {
                categoryArr[In].check = false;
            }
            let allTrue = true;
            categoryArr.forEach(obj => {
                if (!obj.check) {
                    allTrue = false;
                    return;
                }
            }
            );
            if (allTrue) {
                selectAll = false;
            } else {
                selectAll = true;
            }
            this.setState({ selectAll, subCategoryArr, categoryArr });
        }
        else {
            let I = favOrderArray.findIndex(t => (t.ORDER_CATEGORY == item.ORDER_CATEGORY && t.ORDER_NO == item.ORDER_NO))
            favOrderArray[I].checked = favOrderArray[I].checked == true ? false : favOrderArray[I].checked == false ? true : true;
            let grid = favOrderArray.filter((x) => (x.ORDER_CATEGORY === item.ORDER_CATEGORY))
            let checkedgrid = favOrderArray.filter((t) => (t.ORDER_CATEGORY == item.ORDER_CATEGORY && t.checked == true))
            let In = categoryArr.findIndex(t => (t.ORDER_CATEGORY == item.ORDER_CATEGORY))


            if (grid.length == checkedgrid.length) {
                categoryArr[In].check = true;
            }
            else {
                categoryArr[In].check = false;
            }

            let ordercheckedlen = favOrderArray.filter((t) => (t.ORDER_CATEGORY == item.ORDER_CATEGORY && t.ORDER_SUBCATEGORY == item.ORDER_SUBCATEGORY && t.checked == true))
            let orderlen = favOrderArray.filter((t) => (t.ORDER_CATEGORY == item.ORDER_CATEGORY && t.ORDER_SUBCATEGORY == item.ORDER_SUBCATEGORY))
            let SubIn = subCategoryArr.findIndex(t => (t.ORDER_CATEGORY == item.ORDER_CATEGORY && t.ORDER_SUBCATEGORY == item.ORDER_SUBCATEGORY))
            if (ordercheckedlen.length == orderlen.length) {
                subCategoryArr[SubIn].SubCatcheck = true;
            }
            else {
                subCategoryArr[SubIn].SubCatcheck = false;
            }
            let allTrue = true;
            categoryArr.forEach(obj => {
                if (!obj.check) {
                    allTrue = false;
                    return;
                }
            }
            );
            if (allTrue) {
                selectAll = false;
            } else {
                selectAll = true;
            }
            this.setState({ selectAll, favOrderArray, subCategoryArr, categoryArr });

        }



    }
    getItemValue(item) {
        return `${item.value}`;
    }
    renderItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white', zIndex: 1 }}>
                {item.label}
            </div>
        );

    }
    onSelect(val, name) {
        if (name == "FAVORDERCAT") {
            this.setState({
                favOrdCat: val
            });

            this.handleCatSearch(val);
        }
    }
    handleCatSearch = (value) => {
        let result = this.state.favOrderCatSuggArr.filter((name) => name.label.toUpperCase().includes(this.state.favOrdCat.toLocaleUpperCase()))
        if (result.length > 0 || value == "") {
            let pageNo = 1;
            isPostBack = false;
            this.pageLoad(pageNo,
                this.state.itemCount,
                "ORDER_CATEGORY",
                value,
                this.state.sortColumn,
                this.state.sortValue
            );
            this.setState({ pageNo: pageNo, isSearch: true });
        }
    };
    onChange = (e, name) => {
        let val = e.target.value.toUpperCase()
        let re = /^[a-zA-Z0-9_-\s\x20 &, :;=?@#|'<>.{}"*()%!`%^$+~[/\]\\]+$/;
        if (val === '' || re.test(val)) {
            if (name == "FAVORDERCAT") {
                if (e.target.value == "") {
                    this.setState({
                        favOrdCat: e.target.value, favOrderSugg: this.state.favOrderCatSuggArr
                    })
                }
                else if (val.length <= 50) {
                    let result = []
                    if (this.state.favOrderCatSuggArr) {
                        if (this.state.favOrderCatSuggArr.length > 0) {
                            result = this.state.favOrderCatSuggArr.filter(name => name.label.toUpperCase().includes(val))
                        }

                    }
                    this.setState({
                        favOrdCat: e.target.value, favOrderSugg: result
                    });
                }
            }
        }
    }



    render() {
        return (
            <React.Fragment>
                <div className="container-fluid content-section">
                    <div className="row">
                        {/* Favorite Order Maintenance starts */}
                        <div className="container-fluid content-margin">
                            <div className="row">
                                <div className="col-md-12 page-heading mb-3">
                                    <h5 className="page-title"><Trans>Favorite</Trans> <Trans>order_key</Trans> <Trans>Maintenance</Trans> </h5>
                                    <div className="fav-item-maintenance-icon mt-1">
                                        <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                        <label htmlFor="checkbox-1">
                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                                {this.state.isPageLoadedOnce && this.state.totalCount == 0 && isPostBack == true ? null : (<div className="col-md-12">
                                    <div className="float-right">
                                        <div className="input-group dropdown-search float-left mb-2 mr-2">
                                            <div className="input-group-prepend">
                                                <button className="btn btn-outline-secondary dropdown-toggle" id="filterButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.filterDisplayName}</button>
                                                <div className="dropdown-menu" >
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "All", "ALL")}><Trans>All</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order #", "FO.ORDER_NO")}><Trans>order_key</Trans> #</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Work Order", "FO.ISA_WORK_ORDER_NO")}><Trans>workorder_key</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Asset ID", "LN.ISA_MACHINE_NO")}><Trans>Asset ID</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Business Unit", "FO.BUSINESS_UNIT")}><Trans>Business</Trans> <Trans>Unit</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Priority", "FO.ISA_PRIORITY_FLAG")}><Trans>Priority</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order Entered by", "FO.OPRID_ENTERED_BY")}><Trans>orderenteredby_key</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Ordered For", "FO.ORDERED_FOR")}><Trans>orderedfor_key</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Category", "FO.ORDER_CATEGORY")}><Trans>Category</Trans></a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Sub Category", "FO.ORDER_SUBCATEGORY")}><Trans>Sub Category</Trans></a>
                                                </div>
                                            </div>
                                            {/* 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4746 - Johnprinto */}
                                            <input type="text" className="form-control pr-5" placeholder="Search" id="searchTxt" value={this.state.searchTxt} aria-label="Text input with dropdown button" maxLength={"254"} onKeyDown={(e) => this.keyPress(e, "SEARCH")} onChange={this.handleSearchTxt} />
                                            {this.state.searchTxt.length > 0 ? <span class="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch("SEARCH")} > </span> : null}
                                            <span className="dropdown-search-icon cursor-pointer" onClick={() => this.handleSearch(false, "")} id="searchIcon" />
                                            <div className="float-left pl-3 priority-dropdown" id="priorityDiv">
                                                <label className="custom-radiobtn"><Trans>Priority</Trans>
                                                    <input type="radio" checked={this.state.priorityFlag == 1 ? true : false} value="1" name="priorityOption" onChange={() => this.handlePriority(1)} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                                <label className="custom-radiobtn"><Trans>Non-Priority</Trans>
                                                    <input type="radio" checked={this.state.priorityFlag == 2 ? true : false} value="2" name="priorityOption" onChange={() => this.handlePriority(2)} />
                                                    <span className="checkmark checkmark-org" />
                                                </label>
                                            </div>
                                        </div>
                                        {this.state.totalCount == 0 ? <></> : <><button className="btn btn-primary custom-btn-primary primary-btn float-right mr-2 mb-2" onClick={(event) => this.selectAllButtonClick(event, this.state.selectAll)}>
                                            {this.state.selectAll ? <Trans>Select All Category</Trans> : <Trans>UnSelectAll Category</Trans>}
                                        </button>
                                            {/* 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4762 - Johnprinto */}
                                            <button className="btn btn-primary custom-btn-primary primary-btn float-right mr-2 mb-2" onClick={(event) => this.handleDelete()}>Delete</button>
                                            <button className="btn btn-primary custom-btn-primary primary-btn float-right mr-2 mb-2" onClick={(event) => this.shareMultipleItems()}>Share</button>
                                        </>}
                                    </div>
                                </div>)}

                                {this.state.totalCount == 0 ? <></> : <div className="col-md-12">
                                    <div className=" text-center ">
                                        <span style={{ fontSize: "15px" }}>Cart items quantity will be updated when it is already available in the cart</span>
                                    </div>
                                </div>}
                                {this.state.totalCount != 0 ?
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-lg-3 my-3">
                                                <div id="accordion" className="accordion-container filters-search">
                                                    <div className="sdi-fav-category-container filters-search">
                                                        <h6 className="accordion-title mb-0">Category</h6>
                                                        <div className="p-2" style={{ zIndex: "9999" }}>
                                                            <Autocomplete
                                                                getItemValue={this.getItemValue}
                                                                items={this.state.favOrderSugg}
                                                                renderItem={this.renderItem}
                                                                value={this.state.favOrdCat}
                                                                wrapperProps={{ className: "w-100 cat-scroll" }}
                                                                onChange={e => this.onChange(e, "FAVORDERCAT")}
                                                                onSelect={e => this.onSelect(e, "FAVORDERCAT")}
                                                                menuStyle={menuStyle}
                                                                inputProps={{ placeholder: "Search", className: "form-control pr-5 float-left cataccord ", onKeyDown: e => { this.keyPress(e, "CATSEARCH") } }}
                                                            />
                                                            {this.state.favOrdCat.length > 0 ? <span class="search-cancel-header cursor-pointer" style={{ 'margin-right': '25px', 'margin-top': '52px' }} onClick={() => this.ClearSearch("CATSEARCH")} > </span> : null}
                                                            <span className="dropdown-search-icon cursor-pointer" id="searchCatIcon" onClick={() => this.handleCatSearch(this.state.favOrdCat)} style={{ top: '63px', right: '40px' }} ></span>
                                                        </div>
                                                        <ul className="card border-0 list-unstyled sdi-container-padding AG-category-scroll" >
                                                            {this.state.isPageLoadedOnce && this.state.totalCount > 0 ? this.bindCategory() : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8 col-md-8 col-lg-9 mt-3">
                                                <div className="row px-3">
                                                    <div className="w-100 sdi-outerframe-style">
                                                        <div className="col-md-12">
                                                            <div className="row align-items-center">
                                                                <div className="col-lg-6 mb-2">
                                                                    {/* Filter popup */}
                                                                    <span className="float-right mb-3 d-none">
                                                                        <a href="#" className="mt-2 mx-2 category-icon-toggle">
                                                                            <img src="images/category-icon.svg" alt="category Icon" className="sdi-category-icon-width" />
                                                                        </a>
                                                                    </span>
                                                                    {/* Filter popup */}
                                                                    <label className="search-category title-font m-0">Selected Category :</label>
                                                                    <span className="list-count ml-2">{this.state.selectedCategory}</span>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="d-flex justify-content-end">
                                                                        {this.state.totalCount == 0 ? null : (<div className="col-md-12">

                                                                            {this.state.totalCount > this.state.itemCount ? <ReactPaginate
                                                                                previousLabel={<span className="right-pagination-arrow" />}
                                                                                nextLabel={<span className="left-pagination-arrow" />}
                                                                                previousClassName={'page-item'}
                                                                                nextClassName={'page-item'}
                                                                                previousLinkClassName={'page-link'}
                                                                                nextLinkClassName={'page-link'}
                                                                                disabledClassName={'disabled'}
                                                                                breakLabel={'...'}
                                                                                breakClassName={'page-item'}
                                                                                breakLinkClassName={'page-link'}
                                                                                pageCount={this.state.pageCount}
                                                                                marginPagesDisplayed={1}
                                                                                pageRangeDisplayed={2}
                                                                                onPageChange={this.handlePageClick}
                                                                                containerClassName={'custom-pagination pagination float-right'}
                                                                                pageClassName={'page-item'}
                                                                                pageLinkClassName={'page-link'}
                                                                                activeClassName={'active'}
                                                                                hrefBuilder={() => '#'}
                                                                                forcePage={this.state.pageNo - 1}
                                                                            /> : null}

                                                                        </div>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="table-responsive">
                                                                <table className="table ZEUS_Grid sdi-FOM-grid mobileresponsive-grid">
                                                                    <tbody>
                                                                        {this.state.selectedCategory != "" ? this.bindSubCategory() : null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <section className="site-undercontruction container text-center center-panel-1">
                                        <div className="img-section">
                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                        </div>
                                        <p className="search-result-content pt-4">No Records found
                                            </p>
                                    </section>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="Cancel-confirmation" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
                                  </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-4">
                                        <span className="question-mark-icon delete-confirm-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block"><Trans>Do you want to remove this order ?</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal"><Trans>No</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={this.deleteFavOrder}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal userid" id="FavOrderSharing" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog  custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Share</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.favOrderShareClose()}>
                                    X
                                </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body overflow-hidden">
                                <div className="row justify-content-center">
                                    <div className="col-md-10 float-left mt-2">
                                        <label className="float-left col-md-3 form-label catalog-label pl-0"><Trans>Region</Trans></label>
                                        <div id=" RegionDD">
                                            <Select
                                                // arrowRenderer={this.customArrowRenderer}
                                                classNamePrefix="custom-select"
                                                className="DropDown-Font-Size"
                                                options={this.state.regionDD}
                                                value={this.state.selectedRegion}
                                                onChange={e => this.handleOnChange(e, "selectedRegion")}
                                                isClearable={true}
                                                name="REG"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-10 float-left mb-4 mt-4 ">
                                        <label htmlFor="UserDDHTML" className="float-left form-label catalog-label pl-0">User</label>
                                        <div id="userDD">
                                            <MultiSelect
                                                options={this.state.userDD}
                                                className="DropDown-Font-Size UserCSS w-100"
                                                id="userdd"
                                                value={this.state.selectedUser}
                                                onChange={e => this.handleOnChange(e, "selectedUser")}
                                                labelledBy={"Select"}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-6 float-left mb-2">
                                        {/* 02/21/2024 - Zeus 260 - SIT -1 Bug fix - 4746 - Johnprinto */}
                                        <span className="errorspan1" style={{ fontSize: '14px', marginRight: '85%' }} id="usererror" />
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4 mr-2 mt-5" onClick={() => this.shareButtonClick()}><Trans>OK</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

};
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(FavOrderMainComponent);