import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class FavItemMainStore extends EventEmitter {
    PageLoadData = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleFavItemMainStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.FavItemMaintenance_PageLoad: {
                this.PageLoadData = action.action.response;
                this.emit('favItemload');
                break;
            } 

            case SDIConstants.methodNames.FavItemMaintenance_txtid_TextChanged: {
                this.PageLoadData = action.action.response;
                this.emit('NewfavItem');
                break;
            }

            case SDIConstants.methodNames.FavItemMaintenance_InsertFavItem: {
                this.PageLoadData = action.action.response;
                this.emit('InsertfavItem');
                break;
            }

            case SDIConstants.methodNames.FavItemMaintenance_DeleteFavItem: {
                this.PageLoadData = action.action.response;
                this.emit('DeleteItem');
                break;
            }


            case SDIConstants.methodNames.FavItemMaintenance_UpdateFavItem: {
                this.PageLoadData = action.action.response;
                this.emit('UpdateFavItem');
                break;
            }


            case SDIConstants.methodNames.Menu_AddFavItemtoCart: {
                this.PageLoadData = action.action.response;
                this.emit('addedtoCart');
                break;
            }
            case "FAVITEM_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVITEM_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "FAVITEM_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            //[7/10/2023, PS_ZE_142_APP_14, Dhevanesam R]
            case SDIConstants.methodNames.ShareFavItem: {
                this.pageLoadData = action.action.response;
                this.emit('ShareItem');
                break;
            }
        }
    }
}
const objFavItemStore = new FavItemMainStore;
// Dispatcher code
Dispatcher.register(objFavItemStore.handleFavItemMainStore.bind(objFavItemStore));
export default objFavItemStore;