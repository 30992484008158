/*
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Forgot Password page component. The component displays the Forgot Password page details.
 * 
 * App Name: SDI-Walmart
 * Author: M.Sushmitha
 * Created Date: 09/01/2020 
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
/*
 * FP_PC_01
 * */
import * as ForgotPasswordAction from '../actions/ForgotPasswordAction';
import ForgotPasswordStore from '../stores/ForgotPasswordStore';
import SDIConstants from '../SDIConstants';
import * as CookieService from '../CookieService';
import * as $ from 'jquery';
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
class ForgotPassword extends Component {
    /**
     * FP_PC_03
     * Declare a “constructor” method with “props” as its parameters.
     */
    constructor(props) {
        super(props);
        this.validation_Check = this.validation_Check.bind(this)        
        /*
         * FP_PC_04
         * */
        //[3/11/22, PC_FP_03, CAS - 31, Dhevanesam R
        this.state = {
            ForgotPassword: true,
            LoginUserId: "",
            ErrorMsg1: "",
            ErrorMsg2: "",
            newlang: CookieService.getLocalStorageValues("newlanguage") != null && CookieService.getLocalStorageValues("newlanguage") != undefined && CookieService.getLocalStorageValues("newlanguage") != false ? CookieService.getLocalStorageValues("newlanguage") : "en"
        }
    }
    /*
         * FP_PC_05
         * */
    componentDidMount() {

        const params = new URLSearchParams(window.location.search)                 
        console.log(params)
        let UserId = []
        for (const param of params) {
            UserId = param
            console.log(param)
        }   
        //[7/7/2023] Forgot pasword fields will be auto populated with appropriate values when login ID or email ID is entered from login - Poornima S
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(UserId[1])) {
            this.setState({ LoginUserId: UserId[1] })
        } else {
            $('#EmailID').val(UserId[1])
        }        
        ForgotPasswordStore.on("Forgot_Password", this.validation_Check);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

    }
    componentWillUnmount() {
        ForgotPasswordStore.removeListener("Forgot_Password", this.validation_Check);
    }
    //[5/18/2023]Zeus 107  SSO implementation - Poonima S
    backToLoginClick = () => {
        debugger
        CookieService.updateLocalStorageValues("ShowNormalLogin", true);
        this.props.history.push({
            pathname: '/',
        });
    }

    /*
         * FP_PC_07
         * */
    resetPwdBtn_Clk = () => {
        //[3/11/22, PC_FP_03, CAS - 31, Dhevanesam R
        const { t } = this.props;
        let UserId = $('#UserId').val()
        let EmailId = $('#EmailID').val()

        let isValid = true
        if (UserId == "" || UserId == null || EmailId == "" || EmailId == null) {
            isValid = false;
            //document.getElementById("ErrorMsg2").innerText = (t("Please enter required fields"));
            this.setState({
                ErrorMsg2: "Please enter required fields"
            })
        }
        else if (UserId == "" || UserId == null) {
            isValid = false;
            //document.getElementById("ErrorMsg2").innerText = (t("Please enter user id"));
            this.setState({
                ErrorMsg2: "Please enter user id"
            })
        }
        else if (EmailId == "" || EmailId == null) {
            isValid = false;
            //document.getElementById("ErrorMsg2").innerText = (t("Please enter valid email address"));
            this.setState({
                ErrorMsg2: "Please enter valid email address"
            })
        }
        else if (EmailId != "" || EmailId != null) {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(EmailId)) {

                isValid = false;

                //document.getElementById("ErrorMsg2").innerHTML = (t("Please enter valid email address"));
                this.setState({
                    ErrorMsg2: "Please enter valid email address"
                })

            }
        }
        else {
            //document.getElementById("ErrorMsg2").innerHTML = "";
            //document.getElementById("ErrorMsg1").innerHTML = "";
            this.setState({
                ErrorMsg2: "",
                ErrorMsg1: ""
            })
        }
        if (isValid == true) {
            $('#forgetpassword').modal('show');
            //var person = prompt("Are you sure you want to reset your password?");
            
           /* if (window.confirm(t("Are you sure you want to reset your password?"))) {
                let ForgotPasswordBo = {
                    USERID: UserId,
                    EmailID: EmailId,
                    Session_PW: "N",
                }
                ForgotPasswordAction.RSTPwdBtn_Clk(ForgotPasswordBo)
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            } else {
                
            }*/
            
        }
    }
    forgetPasswordOk_Clk = () => {
       
            debugger
            let UserId = $('#UserId').val()
            let EmailId = $('#EmailID').val()
            let ForgotPasswordBo = {
                USERID: UserId,
                EmailID: EmailId,
                Session_PW: "N",
            }
            ForgotPasswordAction.RSTPwdBtn_Clk(ForgotPasswordBo)
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        
    }
    /*
         * FP_PC_08
         * */
    //[3/11/22, PC_FP_03, CAS - 31, Dhevanesam R
    validation_Check = () => {
        debugger
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let Validate = JSON.parse(ForgotPasswordStore.ForgotPasswordList);
        console.log("Validate", Validate)
        let MesageTable = Validate.MessageTable
        if (MesageTable[0].Duplicate_Password_Exist != null) {
            //document.getElementById("ErrorMsg2").innerHTML = (t(MesageTable[0].Duplicate_Password_Exist));
            this.setState({
                ErrorMsg2: MesageTable[0].Duplicate_Password_Exist
            })
        }
        else if (MesageTable[0].EmailID_Not_Matched != null) {
            //document.getElementById("ErrorMsg2").innerHTML = (t(MesageTable[0].EmailID_Not_Matched));
            this.setState({
                ErrorMsg2: MesageTable[0].EmailID_Not_Matched
            })
        }
        else if (MesageTable[0].Invalid_EmailID != null) {
            //document.getElementById("ErrorMsg2").innerHTML = (t(MesageTable[0].Invalid_EmailID));
            this.setState({
                ErrorMsg2: MesageTable[0].Invalid_EmailID
            })
        }
        else if (MesageTable[0].NOTFOUND != null) {
            //document.getElementById("ErrorMsg2").innerHTML = (t(MesageTable[0].NOTFOUND));
            this.setState({
                ErrorMsg2: MesageTable[0].NOTFOUND
            })
        }
        else if (MesageTable[0].Password_Not_Exist != null) {
            //document.getElementById("ErrorMsg2").innerHTML = (t(MesageTable[0].Password_Not_Exist));
            this.setState({
                ErrorMsg2: MesageTable[0].Password_Not_Exist
            })
        }
        else {
            /*alert(t(MesageTable[0].Success_Message));
            this.props.history.push({
                pathname: '/',
            });*/
            this.setState({ErrorMsg2: ""})
            CookieService.updateLocalStorageValues("ShowNormalLogin", true);
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t(MesageTable[0].Success_Message)
            }).then(() => {
                this.props.history.push({
                    pathname: '/',
                });
            });
        }
    }
    render() {
        //[11/26/21, PC_FPT_01, CAS - 31], Poornima S//Added translation keywords
        const { t } = this.props;        
        return (this.state.ForgotPassword == true ? < React.Fragment >
            {/*//[5/4/22, Bug 2046, 2073], Poornima S - Adjusted UI for back to login link when language is French*/}
            <p className={`text-white mt-4 font-24 ${this.state.newlang.value == "fr" ? 'mb-0' : 'mb-4'} font-thin`}>{t("Request Forgotten Password")}</p>
            {/*[3/20/2023, PS_CAS_47_01, Dhevanesam R*/}
            <div className="user-id position-relative">
                <input type="text" className="form-control font-12 login-control custom-margin changeevent" maxLength="30" id="UserId" placeholder={t("Enter Login Id")} autoFocus defaultValue={this.state.LoginUserId} />
                
            </div>

            <div className="Email position-relative"><input type="text" className="form-control font-12 login-control mb-1 changeevent" id="EmailID" placeholder={t("Enter E-Mail address")} />
                
            </div>
            {/*[3/11/22, PC_FP_03, CAS - 31, Dhevanesam R*/}
            <div><span className="float-left err-msg text-danger mb-1" id="ErrorMsg2"><Trans>{this.state.ErrorMsg2}</Trans></span></div>
            {/*4/15/2022 Bug 2073 & 2046 UI correction in Forgot Password page -Poornima S*/}
            <button className={`btn btn-primary custom-btn-primary col-12 text-center login-btn  ${this.state.ErrorMsg2 != "" ? 'mt-2' :'mt-4 mb-2'}`} onClick={this.resetPwdBtn_Clk}>{t("Reset Password")}</button>
            <p className="col-12 p-0 float-left text-left mb-1 mt-1 password-info">{t("Password will be reset and sent to the email address that was set up in the Login profile for the Login ID entered above.")}</p>
            <Link className={`float-left col-12 ${this.state.newlang.value == "fr" ? 'mt-0' : 'mt-1'} back-to-login-btn`} onClick={this.backToLoginClick} >{t("Back To Login")}</Link>
            < div className="modal" id="forgetpassword" >
                <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                    <div className="modal-content">


                        {/* Modal body */}
                        <div className="modal-body">
                            <div className="row text-center">

                                <div className="col-12 mb-4">

                                    <label className="confirm-content d-block"><Trans>Are you sure you want to reset your password?</Trans></label>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-4" data-dismiss="modal"><Trans>No</Trans></button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4" data-dismiss="modal" onClick={() => this.forgetPasswordOk_Clk()}><Trans>Yes</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div > 

        </React.Fragment > : null)
    }
}
export default withTranslation()(ForgotPassword);