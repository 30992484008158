/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Varun
 * Created Date: 08/21/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: LN_PC_15
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
import * as CookieService from '../CookieService';
/**
 * Ref: LN_PC_16
 * 
 * pageLoad method gets called from the componentWillMount() of the component.
 * It takes a parameters loginPageLoadBO which is passed from the component file.
 * @param HomepageBO
 */
const pageLoad = (HomepageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Homepage,
            SDIConstants.methodNames.Homepage_Pageload,
            JSON.stringify(HomepageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        //****************New******************************
                        //let Data = JSON.parse(data.response);
                        //let FrontEndFlag = Data.FrontEndFlags
                        //if (FrontEndFlag.length > 0) {
                        //    FrontEndFlag = FrontEndFlag[0];
                        //}
                        //let localStorageSuccess = CookieService.insertLocalStorageValues(FrontEndFlag);
                        
                        //*******************END*************************************
                        let contentData = { type: SDIConstants.methodNames.Homepage_Pageload, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                    else if (data.success == false) {
                        if (data.response) {
                            debugger;
                            if (data.response == SDIConstants.tokenExpired) {
                                MenuAction.tokenExpired();
                            }
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}; 
const TechVault = (BU) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Homepage,
            SDIConstants.methodNames.TechVault,
            BU,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.TechVault, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                    else if (data.success == false) {
                        if (data.response) {
                            if (data.response == SDIConstants.tokenExpired) {
                                MenuAction.tokenExpired();
                            }
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
//*************NEW******************************
const TermsnConditionAccept_Click = (HomepageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Homepage,
            SDIConstants.methodNames.TermsnConditionAccept_Click,
            JSON.stringify(HomepageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        debugger;
                        //****************New******************************
                        //let Data = JSON.parse(data.response);
                        //let FrontEndFlag = Data.FrontEndFlags
                        //if (FrontEndFlag.length > 0) {
                        //    FrontEndFlag = FrontEndFlag[0];
                        //}
                        //let localStorageSuccess = CookieService.insertLocalStorageValues(FrontEndFlag);
                        //let responses = "";
                        //if (data.response == "True") {
                        //    responses = "True";
                        //}
                        //*******************END*************************************
                        let contentData = { type: SDIConstants.methodNames.TermsnConditionAccept_Click, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                    else if (data.success == false) {
                        if (data.response) {
                            if (data.response == SDIConstants.tokenExpired) {
                                MenuAction.tokenExpired();
                            }
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
//***************END****************************************

const getSearchSuggestions = async (searchBO) => {
    try {
        let responseData = "";
        await SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_SearchSuggestions,
            JSON.stringify(searchBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        responseData = data.response;
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
        return responseData;

    } catch (ex) {
        console.log(ex)
        return "";
    }
};

const tokenExpired = async () => {
    try {
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)

    }
};
export {
    pageLoad, getSearchSuggestions, tokenExpired, TermsnConditionAccept_Click, TechVault
}