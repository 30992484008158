import * as $ from 'jquery';
import * as CookieService from '../src/CookieService';
const SDIConstants = {
	access_token: `access_token`,
	localService: `api/Configuration/GetServerURL`,
	localWebAPP: `api/Configuration/GetWebAPPURL`,
	localTokenHMAC: `api/Configuration/GetTokenHMAC`,
	sessionTimeout: 15, //Minutes,
	quantityRegEX: /^[0-9\b]+$/,
    SDILoader: `SDILoader`,
    tokenExpired: `Token Expired`,
    SDIRTILoader: `SDIRTILoader`,
	TokenEXID: "7422323732826902",
	APIKey: "VzpjalpPQJ6BnRGfZmIuWvvHVneS43ogMydpGyAr",
	ClientSecretKey: "VzpjalpPQJ6BnRGfZmIuWvvHVneS43ogMydpGyAr",
	TokenSchema: "sixANTOKENfour",
    AlphabetsReg: /^[a-zA-Z]+$/,
    zipcodeRegEx: /^(?!.*  )([0-9a-zA-Z\s\-]+)$/,
    costRegex: /^\d*\.?\d{0,2}$/,
    jsonRegex: /[{}:\[\],&'"]/,
	availablePages: [
		{
			pageName: "Home", pageTitle: "Home", pageURL: "/homepage"
		},
		{
			pageName: "ShoppingCart", pageTitle: "Shopping Cart", pageURL: "/shoppingcart"
		},
		{
			pageName: "Favorites", pageTitle: "Favorites", pageURL: "/favorites"
		},
		{
			pageName: "FavoriteOrder", pageTitle: "Favorite Orders", pageURL: "/favorder"
		},
		{
			pageName: "Notifications", pageTitle: "UserNotifications", pageURL: "/usernotification"
		},
		{
			pageName: "NonCatalog", pageTitle: "RFQ Entry", pageURL: "/rfqentry"
		},
		{
			pageName: "Punchout", pageTitle: "Pickup From Local Supplier", pageURL: "/punchout"
		},
		{
			pageName: "ShopRedirect", pageTitle: "Shop Redirect", pageURL: "/shopredirect"
		},
		{
			pageName: "OrderApproval", pageTitle: "Need Approve", pageURL: "/needapprove"
		},
		{
			pageName: "WaitingRequestorApproval", pageTitle: "Need Quote", pageURL: "/needquote"
		},
		{
			pageName: "RequestorApproval", pageTitle: "Approve Quote", pageURL: "/approvequote"
		},
		{
			pageName: "UserProfile", pageTitle: "User Profile", pageURL: "/profile"
		},
		{
			pageName: "ItemDetail", pageTitle: "Item Detail", pageURL: "/itemdetailnew"
		},
		{
			pageName: "SearchResults", pageTitle: "Search Results", pageURL: "/searchresults"
		},
		{
			pageName: "OrderStatus", pageTitle: "Order Status", pageURL: "/orderstatus2"
		},
		{
			pageName: "UnderConstruction", pageTitle: "Under Construction", pageURL: "/underconstruction"
		},
		{
			pageName: "AccessDenied", pageTitle: "Access Denied", pageURL: "/accessdenied"
		},
		{
			pageName: "TestPage", pageTitle: "Test Page", pageURL: "/testpage"
		},
		{
			pageName: "SessionTimeOut", pageTitle: "Session Timeout", pageURL: "/sessiontimeout"
		},
		{
			pageName: "PunchInSAP", pageTitle: "Punchin", pageURL: "/punchinsap"
		},
		{
			pageName: "AddNewPunchinUser", pageTitle: "Punchin", pageURL: "/addnewpunchinuser"
		},
		{
			pageName: "PIPost", pageTitle: "PIPost", pageURL: "/PIPost"
		},
		{
			pageName: "Punchinend", pageTitle: "Punchin End", pageURL: "/Punchinend"
		},
		{
			pageName: "PunchInRFQConfirm", pageTitle: "PunchIn RFQ Confirm", pageURL: "/PunchInRFQConfirm"
		},
		//[09/14/21, PC_04, Dhevanesam R, Priyanks D], Add the pageName, pageTitle and pageURL for the "Receive Items" panel
		{
            pageName: "Receive", pageTitle: "Receive Items", pageURL: "/receiving"
		},
		{
			pageName: "Default PutAway Locations", pageTitle: "Default PutAway Locations", pageURL: "/PutawayPickingLocations"
		},
		{
			pageName: "Active Users - SDI/Customers", pageTitle: "Active Users - SDI/Customers", pageURL: "/ActiveUsersbySite"
		},
		//[PS_PL_02, Kishore], Add the pageName , pageTitle and pageURL for the supplierCallin
		{
			pageName: "Supplier Call-In", pageTitle: "Supplier Call-In", pageURL: "/SupplierOrderEntry"
        },
        {
            pageName: "BinToBinTransfer", pageTitle: "Bin To Bin Transfer", pageURL: "/BinToBinTransfer"
        },
        //[1/18/2023] Zeus-11 Stock Issues Panel(Add the pageName , pageTitle and pageURL for stock issue) - Vishalini       
        {
            pageName: "Stock Issues Cart", pageTitle: "Stock Issues Cart", pageURL: "/STKIssuesCart"
        },
        {
            pageName: "InsiteonlineRMA", pageTitle: "RMA", pageURL: "/insiteonlinerma"
        },
        {
            pageName: "Role Master", pageTitle: "Role Master", pageURL: "/RoleMaster"
        },
        {
            pageName: "Product Replenishment", pageTitle: "Product Replenishment", pageURL: "/OrderItems"
        },
        {
            pageName: "ManualStockAdjustment", pageTitle: "Manual Stock Adjustment", pageURL: "/ManualStockAdj"
        },
        {
            pageName: "ExpressPutAway", pageTitle: "Express PutAway", pageURL: "/ExpressPutawayLocation"
        },
        // Mythili -- Zeus-40 SDI Enterprise customer master Add the pageName , pageTitle and pageURL for the EnterpriseCust
        {
            pageName: "Enterprise Customer Master", pageTitle: "Enterprise Customer Master", pageURL: "/EnterpriseCustomerMaster"
        },
        {
            pageName: "Material Storage Locations", pageTitle: "Material Storage Locations", pageURL: "/MaterialStorageLoc"
        },
        {
            pageName: "Program Master", pageTitle: "Program Master", pageURL: "/ProgramMaster"
        },
        {
            pageName: "Picking Assign To", pageTitle: "Picking Assign To", pageURL: "/pickingassignto"
        },
        {
            pageName: "Real time inventory", pageTitle: "Real time inventory", pageURL: "/realtimeinv"
        }
	],
	moduleNames: {
		Login: 1,
		ShoppingCart: 2,
		Menu: 3,
		Homepage: 4,
		OrderStatus: 5,
		FavOrder: 6,
		NonCatalog: 7,
		Searchpage: 8,
		Punchout: 9,
		Shopredirect: 10,
		Mobile: 11,
		OrderApproval: 12,
		UserProfile: 13,
		UserNotification: 14,
		ItemDetailNew: 15,
		ForgotPassword: 16,
		ChangePassword: 17,
		WaitingRequestorApproval: 18,
		FavItemMaintenance: 19,
		RequestorApproval: 20,
		punchin: 21,
		ShoppingCartPayment: 22,
		AddNewPunchinUser: 23,
		BuildChrCD: 24,
		PunchInRFQConfirm: 25,
		//[09/14/21, PC_04, Dhevanesam R, Priyanks D], Add moduleNames "Receiving" and assign a number to it
		Receiving: 26,
		DefaultPutAwayLocations: 27,
		ActiveUsersReport: 28,
		//[5/7/23, PC_ZE_85_APP_13, Dhevanesam R]
		GetGraingerAvailability: 29,
		//[5/9/2023]Zeus -100 PTA link for incident button API call - Poornima
		PassThroughAuth: 30,
		SupplierCallIn: 31,
        ServiceChannel: 32,
        //[1/18/2023] Zeus-11 Stock Issues Panel[Added moduleName "STKIssuesCart" and assigned a number to it] - Vishalini
        STKIssuesCart: 33,
        COOP_Form: 34,
        BinToBinTransfer: 35,
        RMA: 36,
        //Mythili Zeus-241 Product Replenishment panel
        ProductReplen: 37,
        ManualStkAdj: 38,
        EnterpriseCust: 39,
        RoleMaster: 40,
        ExpressPutAway: 41,
        // PS_ZE_243_API_03  Zeus 243 - Program master module name [Vishalini]
        ProgramMaster: 43,
        MaterialStorageLocation: 44,
        CoopLandingPage: 45,
        PickingAssignTo: 47
	},
	methodNames: {
		Login_Pageload: 1,
		Login_TokenUpdate: 2,
		ShoppingCart_Pageload: 3,
		Menu_BindUserMenu: 4,
		Homepage_Pageload: 5,
		OrderStatus_PageLoad: 6,
		FavOrder_Pageload: 7,
		OrderStatus_RetrieveOrderData: 8,
		FavOrder_RemoveFavOrder: 9,
		FavOrder_ReorderItems: 10,
		ShoppingCart_UpdateUserDesp: 11,
		ShoppingCart_btnSubmitNot_Click: 12,
		ShoppingCart_UpdateDueDate: 13,
		ShoppingCart_WriteToCartTable: 14,
		ShoppingCart_UpdateCatalog: 15,
		ShoppingCart_UpdateQtyValue: 16,
		ShoppingCart_UpdateUserEmp: 17,
		ShoppingCart_UpdateMexicanFields: 18,
		ShoppingCart_DeleteFromUserCart: 19,
		OrderStatus_ExportToExcel: 20,
		NonCatalog_PageLoad: 21,
		NonCatalog_CheckCatalog_Click: 22,
		NonCatalog_AttachFiles: 23,
		NonCatalog_DownloadFile: 24,
		NonCatalog_RemoveFile: 25,
		ShoppingCart_UpdateLineItemBYOrderDetails: 26,
		OrderStatus_ApprovalHistory: 27,
		ShoppingCart_AttachFiles: 28,
		ShoppingCart_DownloadFile: 29,
		ShoppingCart_RemoveFile: 30,
		NonCatalog_SaveNonCatalogEntry: 31,
		NonCatalog_QuickItemCheck: 32,
		NonCatalog_getcpdata: 33,
		TermsnConditionAccept: 34,
		Searchpage_Page_Load: 35,
		NonCatalog_SubmitQuickEntry: 36,
		Menu_GetMasterData: 37,
		ShoppingCart_GetAddToFavItemDetails: 38,
		ShoppingCart_updateFavs: 39,
		ShoppingCart_BtnSubmit_Click: 40,
		ShoppingCart_CartConfirmFile: 41,
		ShoppingCart_SubmitQuickEntry: 42,
		Menu_AddFavItemtoCart: 43,
		Menu_GetEnvironmentDetails: 44,
		Menu_GetCurrentFavPage: 45,
		Menu_InsertFavPage: 46,
		Menu_DeleteFavPage: 47,
		Menu_SearchSuggestions: 48,
		Punchout_PageLoad: 49,
		Punchout_LogoClick: 50,
		OrderStatus_ShipTo: 51,
		Shopredirect_AddPunchoutDatatoCart: 52,
		Mobile_GetConfigurations: 53,
		OrderApproval_PageLoad: 54,
		UserProfile_Page_Load: 55,
		UserProfile_radioUserType_SelectedIndexChanged: 56,
		UserProfile_rcbdropSelectUser_SelectedIndexChanged: 57,
		UserProfile_MainvndrID_TextChanged: 58,
		UserProfile_MultiSiteChk_CheckedChanged: 59,
		UserProfile_btnSave_Click: 60,
		UserProfile_tbStripUserDetails_TabClick: 61,
		UserProfile_ddlSDiUsers_SelectedIndexChanged: 62,
		UserProfile_btnSetBudAppr_Click: 63,
		UserProfile_btnSetReqAppr_Click: 64,
		UserProfile_btnSubmit_Click: 65,
		UserProfile_btnSubmitReqAppr_Click: 66,
		UserProfile_btnOrdStatEmailSubmit_Click: 67,
		UserProfile_btnSubmitPrefs_Click: 68,
		UserProfile_btnGribSubmit_Click: 69,
		UserProfile_btnZuseSubmit_Click: 70,
		UserProfile_btnTangoAddOtherUser_Click: 71,
		UserProfile_rblType_SelectedIndexChanged: 72,
		UserProfile_ddlUserRole_SelectedIndexChanged: 73,
		UserProfile_btnUserAccessSave_Click: 74,
		UserProfile_btnActivateAccount_Click: 75,
		UserProfile_btnInactivateAccount_Click: 76,
		UserProfile_btnEmplActivateAccount_Click: 77,
		UserProfile_btnEmplInactivateAccount_Click: 78,
		UserNotification_PagLoad: 79,
		UserNotification_imgArchive_Command: 80,
		UserNotification_FileDownload: 81,
		OrderApproval_ExportToExcel: 82,
		UserNotification_NotifyDetail: 83,
		OrderApproval_ApproveHeaderOrder: 84,
		OrderApproval_DeclineHeaderOrder: 85,
		ItemDetailNew_Page_Load: 86,
		ItemDetailNew_sdiTabStrip_TabClick: 87,
		ItemDetailNew_imgBtnAddToCart_Click: 88,
		ForgotPassword_CustomValidator2_ServerValidate: 89,
		ChangePassword_btn_Update_Pwd_Click: 90,
		WaitingRequestorApproval_Pageload: 91,
		WaitingRequestorApproval_ExportToExcel: 92,
		FavItemMaintenance_PageLoad: 93,
		FavItemMaintenance_UpdateFavItem: 94,
		FavItemMaintenance_DeleteFavItem: 95,
		OrderStatus_OrderDetail: 96,
		FavItemMaintenance_txtid_TextChanged: 97,
		FavItemMaintenance_InsertFavItem: 98,
		RequestorApproval_PageLoad: 99,
		RequestorApproval_ApproveClickEvent: 100,
		OrderStatus_btnSavePrefs_Click: 101,
		OrderStatus_ReorderItems: 102,
		RequestorApproval_ApproveSingleLineClickEvent: 103,
		RequestorApproval_DeclineClickEvent: 104,
		RequestorApproval_DeclineSingleLineClickEvent: 105,
		OrderStatus_PoDetails: 106,
		Searchpage_AddToCart_Click: 107,
        /*[3/17/2023, PS_SR_URL_APP_14 - To remove catalog look up tree, Dhevanesam R]
         *Searchpage_ProductCatalog: 108,*/
		ShoppingCart_UpdateLineLevelItem: 109,
		ShoppingCart_UpdateFieldLevelItem: 110,
		FavItemMaintenance_AddNonCatalogFavItem: 111,
		Mobile_SubmitOrder: 112,
		TermsnConditionAccept_Click: 113,
		ShoppingCart_btnCancel_Click: 116,
		UserProfile_SiteBased_ThirdParty: 117,
		Shopredirect_ReturnPunchoutXMLContents: 118,
		Shopredirect_AddPunchoutDatatoCartWithXML: 119,
		ServiceChannel_GetStoreData: 120,
		SupplierOrderEntry_PageLoad: 121,
		InsertSupplier_Entry: 122,
		Pickup_Vendor: 123,
		punchinload: 124,
		ShoppingCartPayment_getSiteTaxAmount: 125,
		AddNewPunchinUser_Adduser: 126,
		AddNewPunchinUser_submit: 127,
		ShoppingCartPayment_BindWalletData: 128,
		ShoppingCartPayment_DeleteUserCart: 129,
		ShoppingCartPayment_ProcessPayment: 130,
		ShoppingCartPayment_UpdateCardDetails: 131,
		BuildChrCD_Page_Load: 132,
		PunchInRFQConfirm_Page_Load:133,
		Menu_CrossSiteAccess: 134,
		//[09/14/21, PC_04, Dhevanesam R, Priyanks D], Add methods for the "Receiving" module and assign the numbers to it
		ReceivingPageLoad: 135,
		getPODetails: 136,
		submitReceiving: 137,
		//[09/21/21, PC_04, Dhevanesam R, Priyanka D] 
		GetData: 138,
		//[7/14/2022] ItemDetailNew_ItemDetailsLoad - Page load method of item details page when hit through props from search result - Poornima S
		ItemDetailNew_ItemDetailsLoad: 139,
		//[3/15/2023] CAS-44 Adding method num for address validate method in shopping cart BL
		ShoppingCart_AddressValidate: 140,
		DefaultPutAway_Pageload : 141,
		DefaultPutAway_Search : 142,
		DefaultPutAway_Save: 143,
		GetActiveUsers: 144,
		ActiveUserExportToExcel: 145,
		//[4/18/2023]Order status header, line level SP integration and Cancel order changes - Poornima S
		OrderStatus_HeaderSP : 146,
		OrderStatus_LineLevelSP: 147,
		OrderStatus_CancelRequest: 148,
		//[5/5/2023]Zeus 107 - SSO implemnetation - Poornima S
		CheckSSOLoginFlag: 149,
		//[5/7/23, PC_ZE_85_APP_13, Dhevanesam R]
		GetGraingerAvailability: 150,
		//[5/9/2023]Zeus -100 PTA link for incident button API call - Poornima
		PassThroughAuth_PassAutheInitialize: 151,
		SupplierCallIn_PageLoad: 152,
		//Z-102 Default address enable changes
		ShoppingCart_SetDefaultAddress: 153,

		ServiceChannel_GetWorkOrderValidation: 154,
		//[7/10/2023, PS_ZE_142_APP_12, Dhevanesam R]
		ShareFavItem: 155,
		UserProfile_btnSetAltBudAppr_Click: 156,
		UserProfile_btnSubmitAltBudAppr_Click: 157,
        OrderStatus_ApproverList: 158,
        TechVault: 159,
        OrderStatus_ApprovalRemainder: 160,
        //[9/28/2023] Zeus-11 Stock Issues Panel[Added methodNames for pageload, itemSearch, submit] - Vishalini
        STKIssuesCart_PageLoad: 161,
        STKIssuesCart_ItemSearch: 162,
        STKIssuesCart_Submit: 163,
		COOP_Submit: 164,
		COOP_RemoveFile: 165,
        COOP_PageLoad: 166,
        BinToBinTransfer_SearchItem: 167,
        BinToBinTransfer_TransSub: 168,
        RMA_PageLoad: 169,
        RMA_SearchButtonClick: 170,
        RMA_ReasonSelectClick: 171,
        RMA_SaveButtonClick: 172,
        //[1/22/2024]Z 256 RequestorApproval_DeclineReason to update decline reason in quote reject table - Poornima S
        RequestorApproval_DeclineReason: 173,
        // Mythili Zeus-241 Product Replenishment panel
        ProductReplen_ItemLoad: 174,
        ProductReplen_CartLoad: 175,
        ProductReplen_PlaceCart: 176,
        ManualStkAdj_SearchBtnClick: 177,
        ManualStkAdj_SubmitBtnClick: 178,
        EnterpriseCust_PageLoad: 179,
        EnterpriseCust_InsertOrUpdate: 180,
        RoleMaster_PageLoad: 181,
        RoleMaster_AddNewRole: 182,
        RoleMaster_SistersiteBU: 183,
        RoleMaster_EditRole: 184,
        RoleMaster_SaveRole: 185,
        RoleMaster_DeleteRole: 186,
        FavOrder_Share: 187,
        //[3/27/2024]Z 268 GetOrderNotes() is to get notes against an order or line item from PS_ISA_NOTE_HIST table
        OrderStatus_GetOrderNotes: 188,
        ExpressPutAway_PageLoad: 189,
        ExpressPutAway_SubmitBtnClick: 190,
        // PS_ZE_243_API_03  Zeus 243 - Program master method name [Vishalini]
        ProgramMaster_PageLoad: 192,
        Program_Operation: 193,
        MaterialStorageLoc_PageLoad: 194,
        MaterialStorageLoc_SubmitButtonClick: 195,
        CoopLanding_PageLoad: 196,
        PickingAssignTo_PageLoad: 198,
        PickingAssignTo_LineDetail: 199,
        PickingAssignTo_ExportToExcel: 200,
        PickingAssignTo_UpdateAssignTo: 201
	}
}
let getAPIURL = async () => {
	let response = "";
	let cachedURL = sessionStorage.getItem("serviceURL");
	if (!cachedURL || cachedURL == "") {
		response = await $.ajax({
			type: "GET",
			url: SDIConstants.localService
		});
		sessionStorage.setItem("serviceURL", response);
	} else {
		response = sessionStorage.getItem("serviceURL");
	}
	return response;

}
//*[01/26/2024, Zeus 261] - redirectToItemDetails() to navigate to Item details page - Kishore*//
let redirectToItemDetails = (itemID, manuPartNo, supID, origin, props) => {
    if (!itemID.includes('NONCAT')) {
        itemID = encodeURIComponent(itemID);
        manuPartNo = encodeURIComponent(manuPartNo);
        supID = encodeURIComponent(supID);
        if (window.location.pathname.toLowerCase() == "/itemdetailnew") {
            CookieService.updateLocalStorageValues("Item_Origin", origin);
            CookieService.updateLocalStorageValues("Item_ManuPartNo", manuPartNo);
            props.history.push({
                pathname: `\itemdetailnew`,
                search: `?ItemId=${itemID}&ManufacturePartNumber=${manuPartNo}&SupplierID=${supID}`,
                state: { origin: origin }
            });
            window.location.reload();
        }
        else {
            CookieService.updateLocalStorageValues("Item_Origin", origin);
            CookieService.updateLocalStorageValues("Item_ManuPartNo", manuPartNo);
            props.history.push({
                pathname: `\itemdetailnew`,
                search: `?ItemId=${itemID}&ManufacturePartNumber=${manuPartNo}&SupplierID=${supID}`,
                state: { origin: origin }
            });
        }
    }
}
export default SDIConstants;
export { getAPIURL, redirectToItemDetails };

//Session Parameter names
 //"Session_UserID"
 //"Session_WEBSITEID"
 //"Session_SITE_CONTACTS" 
 //"Session_AMAZONSEARCH" 
 //"Session_USERTYPE" 
 //"Session_BUSUNIT" 
 //"Session_SITEID" 
 //"Session_USERNAME" 
 //"Session_EMAIL" 
 //"Session_PHONE" 
 //"Session_CUSTID" 
 //"Session_PRODVIEW" 
 //"Session_ACTIVEUSERS" 
 //"Session_CONAME" 
 //"Session_SDICAT" 
 //"Session_PARTLIST" 
 //"Session_SHIPTO_DEFAULT" 
 //"Session_ISAShipToID" 
 //"Session_CARTCHGCDREQ" 
 //"Session_CARTWOREQ" 
 //"Session_CARTWO_CHGCDREQ" 
 //"Session_CC_VALIDATE_WO" 
 //"Session_ISAProdPriceREQ" 
 //"Session_ISAPriceBlock" 
 //"Session_ShowPrice" 
 //"Session_IsSigPadOrdDispatch" 
 //"Session_IsSigPadStkIssue" 
 //"Session_ISACstVendor" 
 //"Session_ISACstPrice" 
 //"Session_ISAProFormaFlag" 
 //"Session_ISAQlikID" 
 //"Session_ISACrossSiteAcc" 
 //"Session_ISABudgetaryLimit" 
 //"Session_USESOLRSEARCH" 
 //"Session_ORGINSITE" 
 //"Session_MULTISITEUSER" 
 //"Session_ClientLogoImagePath" 
 //"Session_DEFAULTPAGE" 
 //"Session_ROLENUM" 
 //"Session_PROD_DESCPREFERENCE" 
 //"Session_TESTMODE" 
 //"Session_SITEEMAIL" 
 //"Session_CplusDbSQL" 
 //"Session_CplusDB" 
 //"Session_SITEPREFIX" 
 //"Session_ORDERPREFIX" 
 //"Session_SITELANG" 
 //"Session_UserTicketAssign" 
 //"Session_SITEBU" 
 //"Session_LBLPRT" 
 //"Session_OROPUNCHOUTFLAG" 
 //"Session_ISAShpdocRcv" 
 //"Session_SEARCH3M" 
 //"Session_SEARCHORONASCO" 
 //"Session_SEARCHCAPP" 
 //"Session_SEARCHMSC" 
 //"Session_SEARCHIMP" 
 //"Session_SEARCHSTAUFFER" 
 //"Session_CATLTREEHLDFLAG" 
 //"Session_CARTTAXEXMPTFLAG" 
 //"Session_APPRTYPE" 
 //"Session_BUDGETFLG" 
 //"Session_NSTKONLY" 
 //"Session_EmpChgCd" 
 //"Session_RecvDate" 
 //"Session_COMPANYID" 
 //"Session_ITEMMODE" 
 //"Session_SHOPPAGE" 
 //"Session_LPPFLAG" 
 //"Session_PUNCHOUT" 
 //"Session_PWDays21" 
 //"Session_MoblPick1" 
 //"Session_TrackDBType" 
 //"Session_TrackDBGUID" 
 //"Session_TrackDBUser" 
 //"Session_TrackDBPassword" 
 //"Session_TrackDBCust" 
 //"Session_RecvBarCodePrint" 
 //"Session_ValidateWorkOrder" 
 //"Session_IsaKitPrinting" 
 //"Session_IsaCustintApprvl" 
 //"Session_ApprovalTypeHL" 
 //"Session_RFQONLYSITE" 
 //"Session_BypassReqstrAppr" 
 //"Session_SmallSite" 
 //"Session_MobIssuing" 
 //"Session_DeptIdN1" 
 //"Session_ShowPriceInRecv" 
 //"Session_IsaSdixchApprvl" 
 //"Session_RFApproveLikeAscend" 
 //"Session_ZEUSNOCATALOGSITE" 
 //"Session_ZEUS_SITE" 
 //"Session_CREDITCARDPROCSite" 
 //"Session_MANORDNO" 
 //"Session_ORDLNRENUM" 
 //"Session_RFQSite" 
 //"Session_SDIEMP" 
 //"Session_STOCKREQPRIV" 
 //"Session_NONSTCKREQPRIV" 
 //"Session_NEWITEMPRIV" 
 //"Session_MODITEMPRIV" 
 //"Session_AGENTUSERBU" 
 //"Session_AGENTUSERID" 
 //"Session_ISOL" 
 //"Session_CatalogdtTop" 
 //"Session_Trackvalue" 
 //"Session_SDITRACKUSRNAME" 
 //"Session_SDITRACKPWD" 
 //"Session_TCKTDEPT" 
 //"Session_ISA_USER_ID" 
//Session_THIRDPARTY_COMP_ID
//Session_SPLITPRICEDORDERS
//Session_ISAAscendProcess
    //Session_BuyerCookie
    //Session_HOOKURL