import React, { Component } from 'react';
import { Link } from 'react-router-dom';
/**
 * Ref: LN_PC_02
 * Importing the action and store file and set to an object.
 */
//[09/08/2023 - PS_ZE_134_APP_01 - Johnprinto D]
import { RotatingLines } from 'react-loader-spinner';
import * as HomepageAction from '../actions/HomepageAction'
import * as MenuAction from '../actions/MenuAction'
import HomepageStore from '../stores/HomepageStore'
import * as CookieService from '../CookieService';
import Autocomplete from 'react-autocomplete';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
//[PC_07, 09/07/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
let currentPath = "";
//********NEw**********
let ispostback = false;
//***********END********
class HomePageComponent extends Component {
    _isMounted = false;

    /**
   * Ref: LN_PC_04
   * Define the constructor that takes props as parameter
   * 
   * @param props
   */
    constructor(props) {
        super(props);
        this.userIDInput = React.createRef();
        //  this.handleLoginChange = this.handleLoginChange.bind(this);
        this.assignPageLoad = this.assignPageLoad.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.searchClick = this.searchClick.bind(this);
        this.getItemValue = this.getItemValue.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.searchKeyPress = this.searchKeyPress.bind(this);
        this.referenceSiteChange = this.referenceSiteChange.bind(this);
        this.referenceSiteNavigation = this.referenceSiteNavigation.bind(this);
        this.acceptedsuccess = this.acceptedsuccess.bind(this);
        /**
        * Ref: LN_PC_05
        * Set the initial values of the state variables
        */
        this.state = {
            NonCatalogVisible: false,
            PunchoutVisible: false,
            strCplusitemid: "0",
            ItemDate: "",
            txtItemID: "",
            Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
            FrontEndFlags: [],
            ExternalSiteList: [],            
            autocompleteData: [],
            autoCompleteValue: "",
            referenceSite: "",
            PageTitle: CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0W01" ? "Pickup From Local Supplier" : "PunchOut with our merchant partners",
            referenceSiteVal: "",
            //[3/17/2023, PS_SR_URL_APP_01 - To remove catalog look up tree, Dhevanesam R]
            //TradeCatalogList: [], CatalogLookup: [], CatalogRoot1: "", CatalogRoot2: "", CatalogRoot3: "", CatalogRoot4: "", CatalogRoot1Value: "", CatalogRoot2Value: "", CatalogRoot3Value: "", CatalogRoot4Value: "", ParentLevel: "", CatalogTreeRoot1: "",
            //CatalogTreeRoot2: "", CatalogTreeRoot3: "", CatalogTreeRoot4: "", expandRoot1: false, expandRoot2: false, expandRoot3: false, UNSPSCCodeDT: []

        }
    }



    componentDidMount() {
        debugger
        console.log("sample",CookieService.getLocalStorageValues("sample_termsaccepted"));
        const { t } = this.props;
        //*********NEW**********
        ispostback = true;
        //****END*******************
        console.log("hasReqApprovalCheck", CookieService.getLocalStorageValues("hasReqApprovalCheck"));
        console.log("access_token", SDIConstants.access_token)
        console.log("Session_PUNCHIN", CookieService.getLocalStorageValues("Session_PUNCHIN"))
        const queryParams = new URLSearchParams(window.location.search)
        const QS_src = queryParams.get('GetAccess21');
        if (QS_src != null && QS_src != undefined && CookieService.getLocalStorageValues("Session_PUNCHIN") != "YES") {
            if (QS_src == "NoShopCartAccessForCust") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("You are not allowed to use 'Shopping Cart' here. \nYou must login from SAP.")
                })
                //alert(t("You are not allowed to use 'Shopping Cart' here. \nYou must login from SAP."));
            }
        }
        let HomepageBO = {

            //Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            //Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            //Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            //Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            //Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME"),
            //Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),

            Session_Cart: '',
            Session_AttrbuteName: '',
            Session_CategoryID: '',
            Session_ClassID: '',
            Session_FAMILYID: '',
            Session_LastRecord: '',
            Session_Minimize: '',
            Session_PageSize: '',
            Session_ParentID: '',
            Session_SCREENNAME: '',
            Session_SEGMENTID: '',
            Session_SkipBread: '',
            Session_TermsnConditionsAccept: 'Y',
            Session_UnClass: '',
            UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_xmlData: '',
            Session_xmlToUrl: '',
            Session_SITE_CONTACTS: '',
            Session_AMAZONSEARCH: CookieService.getLocalStorageValues("Session_AMAZONSEARCH"),
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
            BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_PRODVIEW: CookieService.getLocalStorageValues("Session_PRODVIEW"),
            Session_PROD_DESCPREFERENCE: CookieService.getLocalStorageValues("Session_PROD_DESCPREFERENCE"),
            cplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CATLTREEHLDFLAG: CookieService.getLocalStorageValues("Session_CATLTREEHLDFLAG"),
            SHOPPAGE: CookieService.getLocalStorageValues("Session_SHOPPAGE"),
            Session_ZEUSNOCATALOGSITE: CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE"),
            SDIEmp: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            m_siteCurrency: '',
            Session_SiteLang: '',
            Session_TrackingNumber: '',
            Session_ShipToID: '',
            Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")

        }
        //
        //S ePath = this.props.location.pathname;
        //currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        //let getFavPageBO = {
        //    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
        //    pageURL: currentPagePath.toLowerCase()
        //}

        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        debugger
        let currentPagePath = this.props.location.pathname;
        console.log("currentPagePath", currentPagePath)
        if (currentPagePath != "/shoppingcart") {
            HomepageAction.pageLoad(HomepageBO);
        }
        MenuAction.refreshMasterData()
        /**
        * Ref: LN_PC_07
        * Define the listener methods
        */
        HomepageStore.on('load', this.assignPageLoad);
        //*******************NEW*****************************
        HomepageStore.on('accepted', this.acceptedsuccess);
        //****************END*****************************
        HomepageStore.on('TechVault', this.TechVaultResponse);
        //LoginStore.on('success', this.loginSuccess);
        //LoginStore.on('error', this.loginFailure);
    }
    //********NEW***************
    componentWillUnmount() {

        ispostback = false;
        this._isMounted = false;
        HomepageStore.removeListener('TechVault', this.TechVaultResponse);

    }
    acceptedsuccess = () => {
        debugger;
        let pageLoadData = JSON.parse(HomepageStore.pageLoadData);
        console.log("accepted", pageLoadData);
    }
    //*************END*****************

    assignPageLoad = () => {
        debugger
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let currentPagePath = this.props.location.pathname;
        console.log("currentPagePath", currentPagePath)
        if (currentPagePath == "/shoppingcart") {
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        }
        let pageLoadData = JSON.parse(HomepageStore.pageLoadData);
        console.log("Home_pageLoadData", pageLoadData)
        //[3/17/2023, PS_SR_URL_APP_02 - To remove catalog look up tree, Dhevanesam R]
        //let CatalogLookup = []
        //let UNSPSCCodeDT = []
        //if (CookieService.getLocalStorageValues("Session_IPMSite") != "Y") {
        //    //CatalogLookup = pageLoadData.CatalogdtTop
        //    //[7 / 14 / 2022] Bug - 2355 Assigning the available UNSPSC codes fetched from backend to UNSPSCCodeDT - Poornima S
        //    UNSPSCCodeDT = pageLoadData.UNSPSCCodeDT
        //}
        //*******NEW*********************
        if (CookieService.getLocalStorageValues("extra") == "1") {
            let sitecontract = pageLoadData.FrontEndFlags[0].SESSION_SiteContractFlag;
            let accepted = pageLoadData.FrontEndFlags[0].SESSION_UserTAndCFlag;
            console.log("sitecontract", sitecontract);
            debugger;
            if (sitecontract != "Y") {
                if (accepted == null || accepted == "") {

                    $('#terms-condition').modal('show');
                    $('#termsandconditions').html(pageLoadData.termsNcondition[0].terms);
                }
                else {
                    CookieService.updateLocalStorageValues("sample_termsaccepted", true);
                }
            }

            //$('#terms-condition').modal('show');
            //$('#termsandconditions').html(pageLoadData.termsNcondition[0].terms);
        }
        //******END*********************

        console.log("pageLoadData", pageLoadData)

        //  $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        let FrontEndFlags = pageLoadData.FrontEndFlags;
        let ExternalSiteList = pageLoadData.ExternalSiteList;
        //let TradeCatalogList = pageLoadData.TradeCatalogList;
        //FrontEndValues = this.state.FrontEndFlags
        //ExtendedSiteValues = this.state.ExternalSiteList
        //TradeCatalogValues = this.state.TradeCatalogList
        //if (CatalogLookup != null && CatalogLookup != undefined && CatalogLookup != []) {
        //    if (CatalogLookup.length == 0) {
        //        $('#Catalogtree').addClass('d-none')
        //    }
        //}
        //else {
        //    $('#Catalogtree').addClass('d-none')
        //}
        //[PC_02, 02/11/22, CAS - Reference site dynmaic binding, Dhevanesam R]
        CookieService.updateLocalStorageValues("Session_ExternalSiteList", ExternalSiteList);
        CookieService.updateLocalStorageValues("FavOrder_Category", pageLoadData.FavOrder_Category);
        this.setState({
            FrontEndFlags: pageLoadData.FrontEndFlags[0],
            ExternalSiteList: pageLoadData.ExternalSiteList[0],            
            NonCatalogVisible: pageLoadData.FrontEndFlags[0].NonCatalogRequestAccess,
            PunchoutVisible: pageLoadData.FrontEndFlags[0].PunchoutAccess
            //CatalogLookup: CatalogLookup,
            //UNSPSCCodeDT: UNSPSCCodeDT,
            //TradeCatalogList: pageLoadData.TradeCatalogList[0],
        })


        //let Favmenu = pageLoadData.FavMenu;
        //let Favsub = PageLaod_Data.FavSubMenu;
        //let TradeTree = PageLaod_Data.TradeCatalogList;
        //let TradeValue = []
        //let Searchdata = PageLaod_Data.SearchItemTable;
        //let AttributeValue = []
        //[06/22/23, PS_ZE_126_API_07, Zeus 126, Sathis. N]- Stanford tools and order expandables-for blanket wo 
        
    }
    onChange(e) {
        this.setState({
            autoCompleteValue: e.target.value
        });

        /**
         * Handle the remote request with the current text !
         */
        this.retrieveDataAsynchronously(e.target.value);

    }
    /**
  * Callback triggered when the autocomplete input changes.
  * 
  * @param {Object} val Value returned by the getItemValue function.
  * @return {Nothing} No value is returned
  */
    onSelect(val) {
        this.setState({
            autoCompleteValue: val
        });

    }

    /** Define which property of the autocomplete source will be show to the user.
   * 
   * @param { Object } item Single object from the data that can be shown inside the autocomplete
    * @return { String } val
        */
    getItemValue(item) {
        // You can obviously only return the Label or the component you need to show
        // In this case we are going to show the value and the label that shows in the input
        // something like "1 - Microsoft"
        return `${item.value}`;
    }

    /**
 * Reusable method for click of header link navigation
 */
    headerRedirect = (event, pageName) => {
        let queryParamindex = pageName.indexOf("?");
        let pagePath = pageName;
        let queryParams = "";
        if (queryParamindex > 0) {
            pagePath = pageName.substring(0, queryParamindex);
            queryParams = pageName.substring(queryParamindex, pageName.length);
        };

        event.preventDefault();
        this.props.history.push({
            pathname: pagePath,
            search: queryParams
        });
    };

    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.searchClick();
        };
    };
    searchClick = () => {
        debugger
        console.log(this.state.autoCompleteValue);
        if (this.state.autoCompleteValue.trim()) {
            //Zeus 279 - Unexpected suggested options for "SPACER SHI" request - Dhinesh R//
            let autoCompleteValue = encodeURIComponent(this.state.autoCompleteValue.trim());
            this.props.history.push({
                pathname: `\searchresults`,
                search: `?key=${autoCompleteValue}`
            });
        }


    };
    TechVault = (e) => {
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');

        HomepageAction.TechVault(CookieService.getLocalStorageValues("Session_BUSUNIT"));
    }
    TechVaultResponse = () => {
        let TechVaultResponse = JSON.parse(HomepageStore.TechVault);
        if (TechVaultResponse == undefined || TechVaultResponse == null ||
            TechVaultResponse == "" || TechVaultResponse.hasOwnProperty('ErrorMessage')) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical Issue. Please contact SDI Customer Care"
            });
        } else {
            let TechToken = TechVaultResponse.Token[0].token;
            window.open(TechToken, '_blank');
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    renderItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );

    }

    retrieveDataAsynchronously = async (searchText) => {
        let searchBO = {
            keywordStartsWith: searchText,
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            Session_ZEUSNOCATALOGSITE: CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE"),
            Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
            Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")
        }
        debugger;
        let responseData = await MenuAction.getSearchSuggestions(searchBO);
        let autoCompleteDataRes = [];
        let suggestionsStr = []
        if (responseData != "") {
            responseData = JSON.parse(responseData);
            suggestionsStr = responseData.filter((item) => item != null);

            suggestionsStr.forEach((sugg, index) => {
                autoCompleteDataRes.push({ label: sugg, value: sugg });
            });
            this.setState({ autocompleteData: [] });
        }
    }
    clearSearch = () => {
        this.setState({ autoCompleteValue: "", autocompleteData: [] });
    }
    //referenceSiteChange = (e) => {
    //    this.setState({ referenceSite: e.target.value });
    //}
    //[PC_03, 02/11/22, CAS - Reference site dynmaic binding, Dhevanesam R]
    referenceSiteChange = (e) => {
        debugger
        let strExtSiteArr = []
        let Session_ExternalSiteList = CookieService.getLocalStorageValues("Session_ExternalSiteList");
        if (Session_ExternalSiteList != null && Session_ExternalSiteList != undefined) {
            if (Session_ExternalSiteList.length > 0) {
                Session_ExternalSiteList.forEach((value, index) => {
                    debugger
                    if (e.target.value == Session_ExternalSiteList[index].SITE_DESC) {
                        strExtSiteArr.push(Session_ExternalSiteList[index].SITE_URL)
                    }
                });
            }
        }
        console.log("strExtSiteArr", strExtSiteArr)
        if (strExtSiteArr.length > 0) {
            debugger
            this.setState({ referenceSite: strExtSiteArr, referenceSiteVal: e.target.value });
        }
        else {
            this.setState({ referenceSite: "", referenceSiteVal: "" });
        }

    }
    //[PC_04, 02/11/22, CAS - Reference site dynmaic binding, Dhevanesam R]
    referenceSiteNavigation = (e) => {
        debugger;
        const { t } = this.props;
        //e.preventDefault();
        //alert(t("LinkforReferenceAlone"));
        //if (this.state.referenceSite)
        //    window.location.href = this.state.referenceSite;
        e.preventDefault();
        //[2/18/22, CAS - 31, Dhevanesam R]
        //alert(t("Links are for reference information only.\nDo not place orders from suppliers site."));
        swal({
            allowOutsideClick: false,
            closeOnClickOutside: false,
            text: t("Links are for reference information only.\nDo not place orders from suppliers site.")
        }).then(() => {
            debugger;
            if (this.state.referenceSite) {
                /***NEW******wal-158***/
                debugger;
                window.open(this.state.referenceSite);
            }
        });
    }
    //*****************************NEW*************************************************
    Agree = () => {
        ispostback = false;
        HomepageAction.TermsnConditionAccept_Click(CookieService.getLocalStorageValues("Session_UserID"));
        CookieService.updateLocalStorageValues("extra", "2");
    }

    donotAgree = () => {
        debugger;
        ispostback = false;
        CookieService.updateLocalStorageValues("extra", "2");
        CookieService.updateLocalStorageValues("Session_SEARCH3M", "N");
        CookieService.updateLocalStorageValues("Session_SEARCHORONASCO", "N");
        CookieService.updateLocalStorageValues("Session_SEARCHCAPP", "N");
        CookieService.updateLocalStorageValues("Session_SEARCHMSC", "N");
        CookieService.updateLocalStorageValues("Session_SEARCHIMP", "N");
        CookieService.updateLocalStorageValues("Session_SEARCHSTAUFFER", "N");
        CookieService.updateLocalStorageValues("Session_SEARCHGRAINGER", "N");//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
        CookieService.updateLocalStorageValues("Session_USESOLRSEARCH", "N");
        CookieService.updateLocalStorageValues("Session_CATLTREEHLDFLAG", "Y");
        CookieService.updateLocalStorageValues("Session_AMAZONSEARCH", "N");
        CookieService.updateLocalStorageValues("Session_CplusDbSQL", "0001");
        CookieService.updateLocalStorageValues("Session_CplusDb", "0001");
        CookieService.updateLocalStorageValues("Session_ZEUSNOCATALOGSITE", "Y");
        CookieService.updateLocalStorageValues("sample_termsaccepted", false);
        //window.location.reload();
        console.log("sample_termsaccepted", CookieService.getLocalStorageValues("sample_termsaccepted"));
        $('#terms-condition').modal('hide');
        window.location.reload();

    }
    //[3/17/2023, PS_SR_URL_APP_03 - To remove catalog look up tree, Dhevanesam R]
    /***************************-----Catalog look up tree method call starts from here------******************/
    //buildCataloglookup = () => {
    //    if (this.state.CatalogLookup != null && this.state.CatalogLookup != undefined && this.state.CatalogLookup != []) {
    //        let RemoveDuplicate = this.state.CatalogLookup.filter((v, i, a) => a.findIndex(t => (t.SEGMENTID === v.SEGMENTID && t.SLEVELDESC === v.SLEVELDESC)) === i)
    //        RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //            if (a.SLEVELDESC > b.SLEVELDESC) {
    //                return 1;
    //            }
    //            if (b.SLEVELDESC > a.SLEVELDESC) {
    //                return -1;
    //            }
    //            return 0;
    //        });
    //        return RemoveDuplicate.map((Lookup, index) => {
    //            let SecondLevel = this.state.CatalogLookup.filter((item, index) => item.SEGMENTID == Lookup.SEGMENTID && item.SLEVELDESC == Lookup.SLEVELDESC);
    //            //[7/14/2022] Bug -2355 Filtering the lookup items with matching segment ID in UNSPSCCodeDT to find the count of items in first layer - Poornima S
    //            let SegmentID = this.state.UNSPSCCodeDT.filter((item, index) => item.SegmentID == Lookup.SEGMENTID); 
    //             //[08/01/2022] Bug - 2343 To hide the catalog root when closing - Vishalini M
    //            if (this.state.CatalogRoot1 == Lookup.SEGMENTID) {
    //                this.state.expandRoot1 = true
    //            }
    //            else {
    //                this.state.expandRoot1 = false
    //            }
    //            return (
    //                <div className="card border-0 " key={"Root" + index}>
    //                    <div className="card-header p-0 CatalohLookupDiv" id={"headingOne" + index}>
    //                        {/*[08/29/2022, Bug - 2553, PS_HP_01, Dhevanesam R*/}
    //                        {this.state.expandRoot1 == false ? <h5 className="mb-0 accordion-header CatalohLookupHTag cataccord" onClick={() => this.BuildCatalogPath(1, Lookup.SEGMENTID, Lookup.SLEVELDESC, "", "", "", "", "", "")}>
    //                            {Lookup.SLEVELDESC + "(" + (SegmentID.length) + ")"}
    //                        </h5> :
    //                            <h5 className="mb-0 accordion-header CatalohLookupHTag cataccord" onClick={() => this.BuildCatalogPath("", "", "", "", "", "", "", "", "")}>
    //                                {Lookup.SLEVELDESC + "(" + (SegmentID.length) + ")"}
    //                            </h5>}
    //                        {/*[08/29/2022, Bug - 2553, PS_HP_02, Dhevanesam R*/}
    //                        {this.state.expandRoot1 == true ? <span className="accordion-heading accordion-icon-position" onClick={() => this.BuildCatalogPath("", "", "", "", "", "", "", "", "")} data-toggle="collapse" data-target={"#collapseOne" + index} aria-expanded="true" aria-controls="collapseOne" /> :
    //                            <span className="accordion-heading accordion-icon-position collapsed" onClick={() => this.BuildCatalogPath(1, Lookup.SEGMENTID, Lookup.SLEVELDESC, "", "", "", "", "", "")} data-toggle="collapse" data-target={"#collapseOne" + index} aria-expanded="false" aria-controls="collapseOne" />}
    //                    </div>
    //                    {this.state.expandRoot1 == true ? <div id={"collapseOne" + index} className="collapse show" aria-labelledby={"headingOne" + index} data-parent="#accordion" style={{}}>
    //                        <div className="card-body m-0 p-0" id={"child1" + index}>
    //                            {this.BuildSecondLevel(SecondLevel, index)}
    //                        </div>
    //                    </div> : <div id={"collapseOne" + index} className="collapse d-none" aria-labelledby={"headingOne" + index} data-parent="#accordion" style={{}}>
    //                            <div className="card-body m-0 p-0" id={"child1" + index}>
    //                                {this.BuildSecondLevel(SecondLevel, index)}
    //                            </div>
    //                        </div> }
    //                </div>
    //            )
    //        });
    //    }
    //}
    //BuildSecondLevel = (SecendlevelArray, indexvalue) => {
    //    let RemoveDuplicate = SecendlevelArray.filter((v, i, a) => a.findIndex(t => (t.FAMILYID === v.FAMILYID && t.FLEVELDESC === v.FLEVELDESC)) === i)
    //    RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //        if (a.FLEVELDESC > b.FLEVELDESC) {
    //            return 1;
    //        }
    //        if (b.FLEVELDESC > a.FLEVELDESC) {
    //            return -1;
    //        }
    //        return 0;
    //    });
    //    return RemoveDuplicate.map((Lookup, index) => {
    //        let ThirdLevel = SecendlevelArray.filter((item, index) => item.FAMILYID == Lookup.FAMILYID && item.FLEVELDESC == Lookup.FLEVELDESC);
    //        //[7/14/2022] Bug -2355 Filtering the lookup items with matching family ID in UNSPSCCodeDT to find the count of items in second layer - Poornima S
    //        let FamilyID = this.state.UNSPSCCodeDT.filter((item, index) => item.FamilyID == Lookup.FAMILYID); 
    //         //[08/01/2022] Bug - 2343 To hide the catalog root when closing - Vishalini M
    //        if (this.state.CatalogRoot2 == Lookup.FAMILYID) {
    //            this.state.expandRoot2 = true
    //        }
    //        else {
    //            this.state.expandRoot2 = false
    //        }
    //        //console.log("ThirdLevel", ThirdLevel)
    //        return (<div className="card border-0" key={"Root1" + index}>
    //            <div className="card-header p-0">
    //                {/*[08/29/2022, Bug - 2553, PS_HP_01, Dhevanesam R*/}
    //                {this.state.expandRoot2 == false ? <h5 className="mb-0 accordion-header accordion-headerl1 ml-2 CatalohLookupHTag2 cataccord" onClick={() => this.BuildCatalogPath(2, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, "", "", "", "")}>{Lookup.FLEVELDESC + "(" + (FamilyID.length) + ")"}</h5> :
    //                    <h5 className="mb-0 accordion-header accordion-headerl1 ml-2 CatalohLookupHTag2 cataccord" onClick={() => this.BuildCatalogPath(2, Lookup.SEGMENTID, Lookup.SLEVELDESC, "", "", "", "", "", "")}>{Lookup.FLEVELDESC + "(" + (FamilyID.length) + ")"}</h5>
    //                }
    //                {/*[08/29/2022, Bug - 2553, PS_HP_02, Dhevanesam R*/}
    //                {this.state.expandRoot2 == true ? <span className="accordion-heading accordion-headingl1" data-toggle="collapse" data-target={"#collapseOneAB" + index} aria-expanded="true" onClick={() => this.BuildCatalogPath(2, Lookup.SEGMENTID, Lookup.SLEVELDESC, "", "", "", "", "", "")} /> :
    //                    <span className="accordion-heading accordion-headingl1 collapsed" data-toggle="collapse" data-target={"#collapseOneAB" + index} aria-expanded="false" onClick={() => this.BuildCatalogPath(2, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, "", "", "", "")} />}
    //            </div>
    //            {this.state.expandRoot2 == true ? <div className="card-body collapse show p-0" data-parent={"#child1" + indexvalue} id={"collapseOneAB" + index}>
    //                {this.BuildThirdLevel(ThirdLevel, indexvalue, index)}
    //            </div> : <div className="card-body collapse p-0 d-none" data-parent={"#child1" + indexvalue} id={"collapseOneAB" + index}>
    //                    {this.BuildThirdLevel(ThirdLevel, indexvalue, index)}
    //                </div>}
    //        </div>)
    //    });
    //}
    //BuildThirdLevel = (SecendlevelArray, indexvalue, index1) => {
    //    debugger
    //    let RemoveDuplicate = SecendlevelArray.filter((v, i, a) => a.findIndex(t => (t.CLASSID === v.CLASSID && t.CLEVELDESC === v.CLEVELDESC)) === i)
    //    RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //        if (a.CLEVELDESC > b.CLEVELDESC) {
    //            return 1;
    //        }
    //        if (b.CLEVELDESC > a.CLEVELDESC) {
    //            return -1;
    //        }
    //        return 0;
    //    });
    //    return RemoveDuplicate.map((Lookup, index) => {
    //        let ThirdLevel = SecendlevelArray.filter((item, index) => item.CLASSID == Lookup.CLASSID && item.CLEVELDESC == Lookup.CLEVELDESC);            
    //         //[7/14/2022] Bug -2355 Filtering the lookup items with matching Class ID in UNSPSCCodeDT to find the count of items in third layer - Poornima S
    //        let ClassID = this.state.UNSPSCCodeDT.filter((item, index) => item.ClassID == Lookup.CLASSID); 
    //         //[08/01/2022] Bug - 2343 To hide the catalog root when closing - Vishalini M
    //        if (this.state.CatalogRoot3 == Lookup.CLASSID) {
    //            this.state.expandRoot3 = true
    //        }
    //        else {
    //            this.state.expandRoot3 = false
    //        }
    //        return (<div className="card border-0" key={"Root2" + index}>
    //            <div className="card-header p-0 ">
    //                {/*[08/29/2022, Bug - 2553, PS_HP_01, Dhevanesam R*/}
    //                {this.state.expandRoot3 == false ? <h5 className="mb-0 accordion-header accordion-headerl2  ml-3 CatalohLookupHTag3 cataccord" onClick={() => this.BuildCatalogPath(3, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, Lookup.CLASSID, Lookup.CLEVELDESC, "", "")}>{Lookup.CLEVELDESC + "(" + (ClassID.length) + ")"}</h5> :
    //                    <h5 className="mb-0 accordion-header accordion-headerl2  ml-3 CatalohLookupHTag3 cataccord" onClick={() => this.BuildCatalogPath(3, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, "", "", "", "")}>{Lookup.CLEVELDESC + "(" + (ClassID.length) + ")"}</h5>
    //                }
    //                {/*[08/29/2022, Bug - 2553, PS_HP_02, Dhevanesam R*/}
    //                {this.state.expandRoot3 == true ? <span className="accordion-heading accordion-headingl2" data-toggle="collapse" aria-expanded="true" data-target={"#collapseOneA" + index} onClick={() => this.BuildCatalogPath(3, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, "", "", "", "")} /> :
    //                    <span className="accordion-heading accordion-headingl2 collapsed" data-toggle="collapse" aria-expanded="false" data-target={"#collapseOneA" + index} onClick={() => this.BuildCatalogPath(3, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, Lookup.CLASSID, Lookup.CLEVELDESC, "", "")} />}
    //            </div>{/*******catborder* classname added*******/}
    //            {this.state.expandRoot3 == true ? <div className="card-body collapse show p-0 catborder" data-parent={"#collapseOneAB" + index1} id={"collapseOneA" + index}>
    //                <ul className="p-0">
    //                    {this.BuildFourLevel(ThirdLevel)}
    //                </ul>
    //            </div> : <div className="card-body collapse p-0 d-none" data-parent={"#collapseOneAB" + index1} id={"collapseOneA" + index}>
    //                    <ul className="p-0">
    //                        {this.BuildFourLevel(ThirdLevel)}
    //                    </ul>
    //                </div>}
    //        </div>)
    //    });
    //}
    //BuildFourLevel = (ThirdLevelArray) => {
    //    let RemoveDuplicate = ThirdLevelArray.filter((v, i, a) => a.findIndex(t => (t.COMMODITYID === v.COMMODITYID && t.MLEVELDESC === v.MLEVELDESC)) === i)
    //    RemoveDuplicate = RemoveDuplicate.sort(function (a, b) {
    //        if (a.MLEVELDESC > b.MLEVELDESC) {
    //            return 1;
    //        }
    //        if (b.MLEVELDESC > a.MLEVELDESC) {
    //            return -1;
    //        }
    //        return 0;
    //    });
    //    return RemoveDuplicate.map((Lookup, index) => {           
    //        let ThirdLevel = ThirdLevelArray.filter((item, index) => item.COMMODITYID == Lookup.COMMODITYID && item.MLEVELDESC == Lookup.MLEVELDESC);
    //        //[7/14/2022] Bug -2355 Filtering the lookup items with matching Commodity ID in UNSPSCCodeDT to find the count of items in fourth layer - Poornima S
    //        let CommodityID = this.state.UNSPSCCodeDT.filter((item, index) => item.CommodityID == Lookup.COMMODITYID);
    //        return (
    //            <li className="list-group-item inner-list-item ml-5" key={"Root3" + index} onClick={() => this.BuildCatalogPath(4, Lookup.SEGMENTID, Lookup.SLEVELDESC, Lookup.FAMILYID, Lookup.FLEVELDESC, Lookup.CLASSID, Lookup.CLEVELDESC, Lookup.COMMODITYID, Lookup.MLEVELDESC)}>{Lookup.MLEVELDESC + "(" + (CommodityID.length) + ")"}</li>
    //        )
    //    });
    //}    //**********************END***************************************************
    //BuildCatalogPath = (ParentLevel, Node1, Node1Value, Node2, Node2Value, Node3, Node3Value, Node4, Node4Value) => {          
    //    //[08/01/2022] Bug - 2343 To hide the catalog root when closing - Vishalini M
    //    if (Node1Value == "") {
    //        this.setState({
    //            CatalogTreeRoot1: "",
    //            expandRoot1: false
    //        })
    //    }
    //    else {
    //        this.setState({
    //            CatalogTreeRoot1: "  >  ",
    //            expandRoot1: true
    //        })
    //    }
    //    if (Node2Value == "") {
    //        this.setState({
    //            CatalogTreeRoot2: "",
    //            expandRoot2: false
    //        })
    //    }
    //    else {
    //        this.setState({
    //            CatalogTreeRoot2: "  >  ",
    //            expandRoot2: true
    //        })
    //    }
    //    if (Node3Value == "") {
    //        this.setState({
    //            CatalogTreeRoot3: "",
    //            expandRoot3: false
    //        })
    //    }
    //    else {
    //        this.setState({
    //            CatalogTreeRoot3: "  >  ",
    //            expandRoot3: true
    //        })
    //    }
    //    if (Node4Value == "") {
    //        this.setState({
    //            CatalogTreeRoot4: ""
    //        })
    //    }
    //    else {
    //        this.setState({
    //            CatalogTreeRoot4: "  >  "
    //        })
    //    }
    //    this.setState({
    //        CatalogRoot1: Node1, CatalogRoot2: Node2, CatalogRoot3: Node3, CatalogRoot4: Node4,
    //        CatalogRoot1Value: Node1Value, CatalogRoot2Value: Node2Value, CatalogRoot3Value: Node3Value, CatalogRoot4Value: Node4Value, ParentLevel: ParentLevel
    //    })
    //    if (ParentLevel == 4) {
    //        CookieService.updateLocalStorageValues("Catalog_Node1", Node1Value);
    //        CookieService.updateLocalStorageValues("Catalog_Node2", Node2Value);
    //        CookieService.updateLocalStorageValues("Catalog_Node3", Node3Value);
    //        CookieService.updateLocalStorageValues("Catalog_Node4", Node4Value);
    //        CookieService.updateLocalStorageValues("CatalogNODE1", Node1);
    //        CookieService.updateLocalStorageValues("CatalogNODE2", Node2);
    //        CookieService.updateLocalStorageValues("CatalogNODE3", Node3);
    //        CookieService.updateLocalStorageValues("CatalogNODE4", Node4);
    //        this.props.history.push({
    //            pathname: `\searchresults`,
    //            search: `?key=${Node4Value}&unspsc=${Node4}&Home=yes&CatTree=yes`
    //        });
    //    }
    //}
    //CatalogHeaderLink = (event,Node) => {
    //    if (event) {
    //        event.preventDefault();
    //    }
    //    let Node1 = this.state.CatalogRoot1
    //    let Node2 = this.state.CatalogRoot2
    //    let Node3 = this.state.CatalogRoot3
    //    let Node4 = this.state.CatalogRoot4
    //    let Node1Value = this.state.CatalogRoot1Value
    //    let Node2Value = this.state.CatalogRoot2Value
    //    let Node3Value = this.state.CatalogRoot3Value
    //    let Node4Value = this.state.CatalogRoot4Value
    //    let ParentLevel = Node
    //    if (Node == 0) {
    //        Node1 = ""
    //        Node2 = ""
    //        Node3 = ""
    //        Node4 = ""
    //        Node1Value = ""
    //        Node2Value = ""
    //        Node3Value = ""
    //        Node4Value = ""
    //    }
    //    else if (Node == 1) {
    //        Node2 = ""
    //        Node3 = ""
    //        Node4 = ""
    //        Node2Value = ""
    //        Node3Value = ""
    //        Node4Value = ""
    //    }
    //    else if (Node == 2) {
    //        Node3 = ""
    //        Node4 = ""
    //        Node3Value = ""
    //        Node4Value = ""
    //    }
    //    else if (Node == 3) {
    //        Node4 = ""
    //        Node4Value = ""
    //    }         
    //    this.setState({
    //        CatalogRoot1: Node1, CatalogRoot2: Node2, CatalogRoot3: Node3, CatalogRoot4: Node4, CatalogTreeRoot1: "  >  ", CatalogTreeRoot2: "  >  ", CatalogTreeRoot3: "  >  ", CatalogTreeRoot4: "  >  ",
    //        CatalogRoot1Value: Node1Value, CatalogRoot2Value: Node2Value, CatalogRoot3Value: Node3Value, CatalogRoot4Value: Node4Value, ParentLevel: Node
    //    })
    //}
    /***************************-----Catalog look up tree method calls ends here------******************/
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        const { t } = this.props;
        return (<React.Fragment>
            <div className="content-section-homepage mobile-header-label container-fluid home-page-margin">
                <div className="row">
                    {/**********New*************** */}

                    {/*[3/17/2023, PS_SR_URL_APP_04 - To remove catalog look up tree, Dhevanesam R]
                     /***************************-----Catalog look up tree HTML starts from here------*****************
                    {((CookieService.getLocalStorageValues("sample_termsaccepted") == true) && (CookieService.getLocalStorageValues("Session_IPMSite") == "Y")) ? <div className="col-md-12">
                        <h5 className="py-3 title-txt"><Trans>Catalog Search</Trans></h5>
                    </div> : null}
                    {/*[4/14/22, Bug - 2066, Dhevanesam R]*/}                    
                    {/*{CookieService.getLocalStorageValues("Session_IPMSite") != "Y" && this.state.CatalogLookup != null && this.state.CatalogLookup != undefined && this.state.CatalogLookup != [] ? <div className="col-md-12  CatPath-margin-top">{/*4/15/2022 Bug 2055 Catalog Path UI is overlapping in iPad view - Poornima S*/}
                    {/*{(this.state.CatalogLookup.length > 0) && (this.state.ParentLevel == "" || this.state.ParentLevel == null || this.state.ParentLevel == undefined) ? <span className="float-left"><Trans>Catalog</Trans></span> : this.state.CatalogLookup.length > 0 ? <a href="#" className="float-left" onClick={(e) => this.CatalogHeaderLink(e, 0)}><Trans>Catalog</Trans></a>:null}
                        {this.state.ParentLevel == "1" || this.state.ParentLevel == 1 ? <span className="float-left pl-1">{this.state.CatalogTreeRoot1 + this.state.CatalogRoot1Value}</span> : <div className="float-left">{this.state.ParentLevel == "2" || this.state.ParentLevel > 1 ? <span className="pl-1">{this.state.CatalogTreeRoot1}</span> : null}<a href="#" onClick={(e) => this.CatalogHeaderLink(e, 1)}>{this.state.CatalogRoot1Value}</a></div>}
                        {this.state.ParentLevel == "2" || this.state.ParentLevel == 2 ? <span className="float-left pl-1">{this.state.CatalogTreeRoot2 + this.state.CatalogRoot2Value}</span> : <div className="float-left">{this.state.ParentLevel == "3" || this.state.ParentLevel > 2 ? <span className="pl-1">{this.state.CatalogTreeRoot2}</span> : null}<a href="#" onClick={(e) => this.CatalogHeaderLink(e, 2)}>{this.state.CatalogRoot2Value}</a></div>}
                        {this.state.ParentLevel == "3" || this.state.ParentLevel == 3 ? <span className="float-left pl-1">{this.state.CatalogTreeRoot3 + this.state.CatalogRoot3Value}</span> : <div className="float-left">{this.state.ParentLevel == "4" || this.state.ParentLevel > 3 ? <span className="pl-1">{this.state.CatalogTreeRoot3}</span> : null}<a href="#" onClick={(e) => this.CatalogHeaderLink(e, 3)}>{this.state.CatalogRoot3Value}</a></div>}
                        {this.state.ParentLevel == "4" || this.state.ParentLevel == 4 ? <span className="float-left pl-1">{this.state.CatalogTreeRoot4 + this.state.CatalogRoot4Value}</span> : null}
                    </div> : null}*/}
                    {/*{this.state.CatalogLookup != null && this.state.CatalogLookup != undefined && this.state.CatalogLookup != [] ? <div className="col-sm-4 col-lg-3 mt-3" id="Catalogtree">
                        {this.state.CatalogLookup.length > 0 ? <div id="accordion" className="CatalogLookUp">
                            <h6 className="accordion-title mb-0"><Trans>Catalog Lookup</Trans></h6>
                            {this.buildCataloglookup()}
                        </div> : null}
                    </div> : null}
                    /***************************-----Catalog look up tree HTML ends here------*******************/}

                    <div className="col-sm-8 col-lg-5 center-panel">
                        <div className="col-md-11 mx-auto p-0 search-autocomplete home-search">
                           <Autocomplete
                                getItemValue={this.getItemValue}
                                items={this.state.autocompleteData}
                                renderItem={this.renderItem}
                                value={this.state.autoCompleteValue}
                                onChange={this.onChange}
                                onSelect={this.onSelect}
                                inputProps={{ placeholder: "Search", className: "search-lg float-left", onKeyDown: this.searchKeyPress }}
                            />
                            <span className={this.state.autoCompleteValue.trim().length > 0 ? "search-cancel cursor-pointer" : "d-none"} onClick={() => this.clearSearch()}></span>
                            {/*[PC_HP_01, 08/22/22, Bug - 2523, Dhevanesam R]*/}
                            <span className="search-icon-bg" onClick={this.searchClick}><img className="search-icon" src="Images/search-black.png" alt="search-icon"/> 
                            </span>
                        </div>
                        {/*[PC_01, 02/11/22, CAS - Reference site dynmaic binding, Dhevanesam R]*/}
                        {CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0W01" ?
                            //<div className="row justify-content-center">
                            //    <div className="col-md-10 mt-5">
                            //        <select className="form-control" onChange={this.referenceSiteChange} value={this.state.referenceSite}>
                            //            <option value="">{t("Reference Site")}</option>
                            //            {/*[11/29/21, PC_HP_01, CAS-40], Priyanka D*/}
                            //            <option value="https://one.walmart.com/content/facilities-management/outsource/TI/MTI.html">{t("Tech Hub")}</option>
                            //            <option value="http://walmartnavigator.dhpace.com/">{t("Door - DH Pace")}</option>
                            //            <option value="https://www.coatsgarage.com/parts-lists">{t("Lift Truck - Coats Garage")}</option>
                            //            <option value="https://login.lifttrucksolutions.com/">{t("Lift Truck - Wiese")}</option>
                            //            <option value="https://store.hgmforkliftparts.com/">{t("Lift Truck - HGM Liftparts")}</option>
                            //        </select>
                            //        <a href="#" className="dropdown-link-home" onClick={this.referenceSiteNavigation}>{this.state.referenceSite}</a>
                            //    </div>
                            //</div>
                            //[5/10/22, CAS - 31, Dhevanesam R]
                            <div className="row justify-content-center">
                                <div className="col-md-10 mt-5">
                                    <select className="form-control" onChange={this.referenceSiteChange} value={this.state.referenceSiteVal}>
                                        <option value="">{t("Reference Site")}</option>
                                        <option value="Tech Hub">{t("Tech Hub")}</option>
                                        <option value="Tech Reference">{t("Tech Reference")}</option>
                                        <option value="Door - DH Pace" > {t("Door - DH Pace")}</option>
                                        <option value="Lift Truck - Coats Garage" > {t("Lift Truck - Coats Garage")}</option>
                                        <option value="Lift Truck - Wiese" > {t("Lift Truck - Wiese")}</option>
                                        <option value="Lift Truck - HGM Liftparts" > {t("Lift Truck - HGM Liftparts")}</option>
                                        <option value="Door - RollSeal" > {t("Door - RollSeal")}</option>
                                    </select>
                                    <a href="#" className="dropdown-link-home" target="_blank" onClick={this.referenceSiteNavigation}>{this.state.referenceSite}</a>
                                </div>
                            </div> : null}

                        <div className="col-md-12 float-left">
                            {CookieService.getLocalStorageValues("hasNonCatalogCheck") == true ? (
                                <a href="#" onClick={(event) => this.headerRedirect(event, "/RFQEntry")} className="col-md-4 float-left cust-homelink pt-3">
                                    <span className="navicon-container">
                                        <img src="Images/Non-Caatalog.svg" alt="submit-icon" />
                                        <span className="nav-txt PunchoutBtn"><Trans>Non-Catalog Request</Trans></span>
                                    </span>
                                </a>) : null}
                            {CookieService.getLocalStorageValues("hasPunchOutCheck") == true ? (
                                <a href="#" onClick={(event) => this.headerRedirect(event, "/Punchout")} className="col-md-4 float-left cust-homelink pt-3">
                                    <span className="navicon-container">
                                        <img src="Images/punchout.svg" alt="punchout-icon" />
                                        <span className="nav-txt PunchoutBtn">Punch Out</span>
                                    </span>
                                </a>) : null}
                            {/*[09/08/2023 - PS_ZE_134_APP_08 - Johnprinto D]*/}
                            {CookieService.getLocalStorageValues("hasReqApprovalCheck") == true ? (
                                <a href="#" onClick={(event) => this.headerRedirect(event, "/needquote")} className="col-md-4 float-left cust-homelink pt-3">
                                    <span className="navicon-container">
                                        <span className="position-badge">
                                            <img src="Images/RequestApproval.svg" alt="RequestApproval-icon" className="cust-navicon align-icon" />
                                            <span id="request-loader" className="cart-count approval request-count threedigit-count">
                                                <RotatingLines
                                                    strokeColor="#FFFFFF"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="15"
                                                    visible={true} />
                                            </span>
                                            <span id="request-count" className={`cart-count approval request-count 
                                                ${localStorage.getItem("requestorApprovalCount") != undefined && localStorage.getItem("requestorApprovalCount") != null && localStorage.getItem("requestorApprovalCount") != "" ?
                                                    localStorage.getItem("requestorApprovalCount").toString().split('').length == 1 ? 'null-count' :
                                                        localStorage.getItem("requestorApprovalCount").toString().split('').length == 3 ? 'threedigit-count' : '' : ''}`}>{localStorage.getItem("requestorApprovalCount")}</span>
                                        </span>
                                        <span className="nav-txt "><Trans>Requestor Approval</Trans></span>
                                    </span>
                                </a>
                            ) : null}
                            {/*[09/08/2023 - PS_ZE_134_APP_09 - Johnprinto D]*/}
                            {CookieService.getLocalStorageValues("hasOrderApprovalCheck") == true ? (
                                <a href="#" onClick={(event) => this.headerRedirect(event, "/needapprove?TYPE=W&FROM=MENU")} className="col-md-4 float-left cust-homelink pt-3">
                                    <span className="navicon-container">
                                        <span className="position-badge">
                                            <img src="Images/NewOrderApproval(3).svg" alt="RequestApproval-icon"
                                                className="cust-navicon align-icon" />
                                            <span id="order-loader" className="cart-count approval order-count threedigit-count d-none">
                                                <RotatingLines
                                                    strokeColor="#FFFFFF"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="15"
                                                    visible={true} />
                                            </span>
                                            <span id="order-count" className={`cart-count approval order-count 
                                                ${localStorage.getItem("orderApprovalCount") != undefined && localStorage.getItem("orderApprovalCount") != null && localStorage.getItem("orderApprovalCount") != "" ?
                                                    localStorage.getItem("orderApprovalCount").toString().split('').length == 1 ? 'null-count' :
                                                        localStorage.getItem("orderApprovalCount").toString().split('').length == 3 ? 'threedigit-count' : '' : ''}`}>{localStorage.getItem("orderApprovalCount")}</span>
                                        </span>
                                        <span className="nav-txt"><Trans>Order Approval</Trans></span>
                                    </span>
                                </a>
                            ) : null}
                            {CookieService.getLocalStorageValues("hasTechVault") == true ?
                                <a onClick={(event) => this.TechVault(event)} className="col-md-4 float-left cust-homelink tech-vault pt-4 cursor-pointer" target='_blank'>
                                    <span className="navicon-navcontainer" >
                                        <img src="Images/techvault.svg" alt="techhub-icon" style={{ "margin-left": "5%" }} />
                                        <span className="nav-txt PunchoutBtn"><Trans>Tech Vault</Trans></span>
                                    </span>
                                </a> : null}
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="container-fluid content-margin Top-margin">
                <div>
                    <Autocomplete
                        getItemValue={this.getItemValue}
                        items={this.state.autocompleteData}
                        renderItem={this.renderItem}
                        value={this.state.autoCompleteValue}
                        onChange={this.onChange}
                        onSelect={this.onSelect}
                        inputProps={{ placeholder: "Search for Product", className: "form-control charge-code-control", onKeyDown: this.searchKeyPress }}

                    />
                    <div className="input-group-append menu-search-group">
                        <span className="input-group-text">
                            <span className="shopping-search-icon" onClick={this.searchClick} />
                        </span>
                    </div>
                </div>

                <div className="col-md-10 card-section">

                    {this.state.NonCatalogVisible == 'True' ? (
                        <a onClick={(event) => this.headerRedirect(event, "/RFQEntry" )} className="col-md-6 pl-0 card-item float-left">

                            <span className="navicon-container" name="NonCatalogVisible">
                                <img src="Images/non_catalog.svg" alt="submit-icon" />

                                <span className="nav-txt">Submit a Non Catalog..</span>
                                <img src="images/arrow-right.svg" alt="arrow-icon" className="home-arrow-right" />
                            </span>
                        </a>) : null}


                    {this.state.PunchoutVisible == 'True' ? (
                        <a onClick={(event) => this.headerRedirect(event, "/Punchout" )} className="col-md-6 pr-0 card-item float-left">

                            <span className="navicon-container" visible="{this.state.NonPunchoutVisible}">
                                <img src="Images/punchout.svg" alt="punchout-icon" />

                                <span className="nav-txt">Punchout with our mer..</span>
                                <img src="images/arrow-right.svg" alt="arrow-icon" className="home-arrow-right" />
                            </span>
                        </a>) : null}
                </div>
            </div>*/}


            <div className="modal" id="terms-condition" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title mt-1"><Trans>Terms and Conditions</Trans></h4>

                        </div>
                        {/* Modal body */}
                        <div className="modal-body mb-4 terms-scroll">
                            <div className="row">
                                <div className="col-12">
                                    <label className="extended-label d-block"><Trans>Zeus emarketplace Registration Terms</Trans></label>
                                </div>
                                <div className="col-12 mb-4" id="termsandconditions">

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="col-md-12 agree-terms py-3 m-0">
                                <div className="float-right">
                                    <button className="btn not-agree float-left mr-2" data-dismiss="modal" onClick={this.donotAgree}><Trans>I Do not Agree</Trans></button>
                                    <button className="btn btn-primary custom-save-btn primary-btn float-left px-4 mr-3" data-dismiss="modal" onClick={this.Agree}><Trans>Yes, I Agree</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

        );
    };

};
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(HomePageComponent);



