import React, { Component } from 'react';
import * as BinToBinAction from '../actions/BinToBinAction';
import BinToBinStore from '../stores/BinToBinStore';
import * as $ from 'jquery';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import Select from 'react-select';
import { withTranslation, Trans } from 'react-i18next';
import * as MenuAction from '../actions/MenuAction';
import swal from 'sweetalert';
let currentPagePath;

class BinToBinComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemID: "",
            makeDefchk: false,
            transQuantity: "",
            isFavorited: false,
            itemDescription: "",
            SerialID: "",
            transferMessage: "",
            phyInvMsg: "",
            selectedOrigArea: "",
            selectedOrigAisle: "",
            selectedOrigRow: "",
            selectedOrigShelf: "",
            selectedOrigBin: "",
            selectedDestArea: "",
            selectedDestAisle: "",
            selectedDestRow: "",
            selectedDestShelf: "",
            selectedDestBin: "",
            origLocGrid: [],
            destLocGrid: [],
            area: [],
            selectedOrigBU: "",
            aisle: [],
            row: [],
            shelf: [],
            bin: [],
            selectedCurQty: "",
            selectedBuName: "",
            itemDefBin: "",
            itemDefShelf: "",
            itemDefRow: "",
            itemDefAisle: "",
            itemDefArea: "",
            disableItemID: false
        }
    }
    /***
     * [BTB_SQ_1.1- To invoke while click bintobin from Menu bar- Sathis N-10/09/2023]
     * */
    componentDidMount() {
        debugger
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let cribID = CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT");
        if (cribID == undefined || cribID == null || cribID.trim() == "") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please select crib from user profile"
            });
            this.props.history.push({
                pathname: `/profile`,
                state: { origin: 'Inventory' }
            });
            return;
        }
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        BinToBinAction.getCurrentFavPage(getFavPageBO);
        BinToBinStore.on('SearchItem', this.searchItemClickResponse);
        BinToBinStore.on('TransferConfSubmit', this.transferConfClickResponse);
        BinToBinStore.on('favpage', this.loadFavPageResponse.bind(this));
        BinToBinStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    /***
     * [BTB_SQ_1.10- To invoke while redirect to other panels from bintobin- Sathis N-10/09/2023]
     * */
    componentWillUnmount() {
        BinToBinStore.removeListener('SearchItem', this.searchItemClickResponse);
        BinToBinStore.removeListener('TransferConfSubmit', this.transferConfClickResponse);
        BinToBinStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        BinToBinStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    /***
     * [BTB_SQ_1.10- To invoke when enter values in item id,transfer quantity fields and check default check box- Sathis N-10/09/2023]
     * */
    onHandleChange = (e, type) => {
        const quantReg = SDIConstants.quantityRegEX;
        if (type == "ItemId") {
            this.setState({ [e.target.name]: e.target.value.trim() });
        }
        else if (type == "transQuantity") {
            if (e.target.value != "") {
                if (quantReg.test(e.target.value)) {
                    let quantity = Math.round(e.target.value);
                    if (quantity != "0") {
                        this.setState({ [e.target.name]: e.target.value });
                    }
                }
            }
            else {
                this.setState({ [e.target.name]: e.target.value });
            }
        }
        else if (type == "checkbox") {
            this.setState({ makeDefchk: e.target.checked });
        }

    };
    /***
     * [BTB_SQ_4.1- To invoke when click radio buttons in current location grid- Sathis N-10/09/2023]
     * */
    onHandleGridRadChange = (e, Gindex) => {
        let locGrid = this.state.origLocGrid;
        if (locGrid.length == 1) {
            return;
        }
        else if (locGrid.length > 1) {
            locGrid.forEach((value, index) => {
                if (index == Gindex) {
                    value.IsLocSelected = e.target.checked;
                    let destArea = {
                        value: value.STORAGE_AREA,
                        label: value.STORAGE_AREA
                    }
                    let destAisle = {
                        value: value.STOR_LEVEL_1,
                        label: value.STOR_LEVEL_1
                    }
                    let destrow = {
                        value: value.STOR_LEVEL_2,
                        label: value.STOR_LEVEL_2
                    }
                    let destshelf = {
                        value: value.STOR_LEVEL_3,
                        label: value.STOR_LEVEL_3
                    }
                    let destbin = {
                        value: value.STOR_LEVEL_4,
                        label: value.STOR_LEVEL_4
                    }
                    let Areadd = [];
                    this.state.destLocGrid.forEach((item, index) => {
                        if (item.BUSINESS_UNIT == value.BUSINESS_UNIT && Areadd.indexOf(item.STORAGE_AREA) == -1) {
                            let obj = {
                                value: item.STORAGE_AREA,
                                label: item.STORAGE_AREA
                            }
                            Areadd.push(obj);
                        }
                    })
                    let locArea = Areadd.filter((ele, ind) => ind === Areadd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
                    locArea.sort((a, b) => a.label > b.label ? 1 : -1);
                    let Aisledd = [];
                    this.state.destLocGrid.forEach((item, index) => {
                        if (item.STORAGE_AREA == value.STORAGE_AREA && Aisledd.indexOf(value.STOR_LEVEL_1) == -1) {
                            let obj = {
                                value: item.STOR_LEVEL_1,
                                label: item.STOR_LEVEL_1
                            }
                            Aisledd.push(obj);
                        }
                    })
                    let ppaisle = Aisledd.filter((ele, ind) => ind === Aisledd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
                    ppaisle.sort((a, b) => a.label > b.label ? 1 : -1);
                    let Rowdd = [];
                    this.state.destLocGrid.forEach((item, index) => {
                        if (item.STORAGE_AREA == value.STORAGE_AREA && item.STOR_LEVEL_1 == value.STOR_LEVEL_1
                            && Rowdd.indexOf(item.STOR_LEVEL_2) == -1
                        ) {
                            let obj = {
                                value: item.STOR_LEVEL_2,
                                label: item.STOR_LEVEL_2
                            }
                            Rowdd.push(obj);
                        }
                    })
                    let pprow = Rowdd.filter((ele, ind) => ind === Rowdd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
                    pprow.sort((a, b) => a.label > b.label ? 1 : -1);
                    let Shelfdd = [];
                    this.state.destLocGrid.forEach((item, index) => {
                        if (item.STORAGE_AREA == value.STORAGE_AREA && item.STOR_LEVEL_1 == value.STOR_LEVEL_1
                            && item.STOR_LEVEL_2 == value.STOR_LEVEL_2 && Shelfdd.indexOf(item.STOR_LEVEL_3) == -1
                        ) {
                            let obj = {
                                value: item.STOR_LEVEL_3,
                                label: item.STOR_LEVEL_3
                            }
                            Shelfdd.push(obj);
                        }
                    })
                    let ppshelf = Shelfdd.filter((ele, ind) => ind === Shelfdd.findIndex(elem => elem.value === ele.value && elem.label === ele.label));
                    ppshelf.sort((a, b) => a.label > b.label ? 1 : -1);
                    let Bindd = [];
                    this.state.destLocGrid.forEach((item, index) => {
                        if (item.STORAGE_AREA == value.STORAGE_AREA && item.STOR_LEVEL_1 == value.STOR_LEVEL_1
                            && item.STOR_LEVEL_3 == value.STOR_LEVEL_3 && item.STOR_LEVEL_2 == value.STOR_LEVEL_2 && Bindd.indexOf(item.STOR_LEVEL_4) == -1
                        ) {
                            let obj = {
                                value: item.STOR_LEVEL_4,
                                label: item.STOR_LEVEL_4
                            }
                            Bindd.push(obj);
                        }
                    })
                    let ppbin = Bindd.filter((ele, ind) => ind === Bindd.findIndex(elem => elem.value === ele.value && elem.label === ele.label));
                    ppbin.sort((a, b) => a.label > b.label ? 1 : -1);
                    this.setState({
                        origLocGrid: locGrid, selectedOrigArea: value.STORAGE_AREA, selectedDestArea: destArea, selectedDestAisle: destAisle, selectedDestRow: destrow,
                        selectedDestShelf: destshelf, selectedDestBin: destbin, area: locArea, aisle: ppaisle, row: pprow, shelf: ppshelf, bin: ppbin,
                        selectedOrigAisle: value.STOR_LEVEL_1, selectedOrigRow: value.STOR_LEVEL_2, selectedOrigShelf: value.STOR_LEVEL_3, selectedOrigBin: value.STOR_LEVEL_4, selectedBuName: value.DESCR, selectedOrigBU: value.BUSINESS_UNIT, selectedCurQty: value.QuantityAvail,
                        transQuantity: value.QuantityAvail
                    });
                }
                else {
                    value.IsLocSelected = !e.target.checked;
                }
            });
        }
    }
    /***
     * [BTB_SQ_2.1- To invoke on the click of search button  - Sathis N-10/09/2023]
     * */
    searchItemClick = () => {
        if (this.state.itemID != "") {
            let SearchItemBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                ItemId: this.state.itemID.toUpperCase(),
                UserId: CookieService.getLocalStorageValues("Session_UserID"),
                cribId: CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT")
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            BinToBinAction.SearchItem(SearchItemBO)
            this.setState({
                selectedOrigArea: [], selectedOrigAisle: [], selectedOrigRow: [], itemDescription: [], ItemDefBUName: [], itemDefArea: [], itemDefAisle: [], itemDefRow: [], itemDefShelf: [], itemDefBin: [],
                selectedOrigShelf: [], selectedOrigBin: [], origLocGrid: [], destLocGrid: [], selectedOrigBU: [], selectedBuName: [], selectedCurQty: [], transQuantity: []
            });
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please enter item ID'
            });
        }

    }
    /***
     * [BTB_SQ_2.10- To invoke on seacrh click response  - Sathis N-10/09/2023]
     * */
    searchItemClickResponse = () => {
        let SearchItemData = JSON.parse(BinToBinStore.SearchItemData);
        let PhyInv = SearchItemData.PhysInvDesc;
        let errorDt = SearchItemData.errorDt;
        if (errorDt[0].isErrorOccured.toUpperCase() == "FALSE") {
            if (PhyInv == undefined) {
                let ItemDescArray = SearchItemData.ItemDescDt;
                let ItemOrigArray = SearchItemData.ItemOrigDt;
                let ItemDestArray = SearchItemData.ItemDestDt;

                let ItemDesc = ItemDescArray[0].ITEMDESCRIPTION;
                if (ItemDesc == null || ItemDesc == undefined || ItemDesc == "") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "Please enter a valid item ID"
                    });

                }
                else {
                    if (ItemOrigArray.length > 0 && ItemDestArray.length > 0) {
                        let DefLoc = ItemOrigArray[0].DEFLOC;
                        let SplitedDefLoc = []

                        if (DefLoc != null && DefLoc != undefined) {
                            SplitedDefLoc = DefLoc.split('|');
                        }
                        ItemOrigArray.forEach((value, index) => {
                            value.IsLocSelected = false;
                        });
                        ItemOrigArray[0].IsLocSelected = true;
                        this.buildDefLocDD(ItemOrigArray, ItemDestArray);
                        this.setState({
                            selectedOrigArea: ItemOrigArray[0].STORAGE_AREA, selectedOrigAisle: ItemOrigArray[0].STOR_LEVEL_1, selectedOrigRow: ItemOrigArray[0].STOR_LEVEL_2, itemDescription: ItemDesc,
                            ItemDefBUName: SplitedDefLoc[0] != null && SplitedDefLoc[0] != undefined ? SplitedDefLoc[0] : "", itemDefArea: SplitedDefLoc[1] != null && SplitedDefLoc[1] != undefined ? SplitedDefLoc[1] : "",
                            itemDefAisle: SplitedDefLoc[2] != null && SplitedDefLoc[2] != undefined ? SplitedDefLoc[2] : "", itemDefRow: SplitedDefLoc[3] != null && SplitedDefLoc[3] != undefined ? SplitedDefLoc[3] : "",
                            itemDefShelf: SplitedDefLoc[4] != null && SplitedDefLoc[4] != undefined ? SplitedDefLoc[4] : "", itemDefBin: SplitedDefLoc[5] != null && SplitedDefLoc[5] != undefined ? SplitedDefLoc[5] : "",
                            selectedOrigShelf: ItemOrigArray[0].STOR_LEVEL_3, selectedOrigBin: ItemOrigArray[0].STOR_LEVEL_4, origLocGrid: ItemOrigArray, destLocGrid: ItemDestArray, selectedOrigBU: ItemOrigArray[0].BUSINESS_UNIT,
                            selectedBuName: ItemOrigArray[0].DESCR, selectedCurQty: ItemOrigArray[0].QuantityAvail, transQuantity: ItemOrigArray[0].QuantityAvail, disableItemID: true
                        });
                    }
                    else {
                        this.setState({
                            itemDescription: ItemDesc, phyInvMsg: "Physical inventory not found for item.", origLocGrid: [], destLocGrid: []
                        });
                    }
                }

            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: (PhyInv[0].AlertMsg)
                });

                this.setState({
                    itemDescription: PhyInv[0].itemDescription
                });
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Technical issue please contact SDI help desk"
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /***
    * [BTB_SQ_2.05- To invoke in order to bind destination location drop down's- Sathis N-10/09/2023]
    * */
    buildDefLocDD(ItemOrigArray, ItemDestArray) {
        let destArea = {
            value: ItemOrigArray[0].STORAGE_AREA,
            label: ItemOrigArray[0].STORAGE_AREA
        }
        let destAisle = {
            value: ItemOrigArray[0].STOR_LEVEL_1,
            label: ItemOrigArray[0].STOR_LEVEL_1
        }
        let destRow = {
            value: ItemOrigArray[0].STOR_LEVEL_2,
            label: ItemOrigArray[0].STOR_LEVEL_2
        }
        let destShelf = {
            value: ItemOrigArray[0].STOR_LEVEL_3,
            label: ItemOrigArray[0].STOR_LEVEL_3
        }
        let destBin = {
            value: ItemOrigArray[0].STOR_LEVEL_4,
            label: ItemOrigArray[0].STOR_LEVEL_4
        }
        let Areadd = [];
        ItemDestArray.forEach((item, index) => {
            if (item.BUSINESS_UNIT == ItemOrigArray[0].BUSINESS_UNIT && Areadd.indexOf(item.STORAGE_AREA) == -1) {
                let obj = {
                    value: item.STORAGE_AREA,
                    label: item.STORAGE_AREA
                }
                Areadd.push(obj);
            }
        })
        let locArea = Areadd.filter((ele, ind) => ind === Areadd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
        locArea.sort((a, b) => a.label > b.label ? 1 : -1);

        let Aisledd = [];
        ItemDestArray.forEach((item, index) => {
            if (item.STORAGE_AREA == ItemOrigArray[0].STORAGE_AREA && Aisledd.indexOf(item.STOR_LEVEL_1) == -1) {
                let obj = {
                    value: item.STOR_LEVEL_1,
                    label: item.STOR_LEVEL_1
                }
                Aisledd.push(obj);
            }
        })
        let ppaisle = Aisledd.filter((ele, ind) => ind === Aisledd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
        ppaisle.sort((a, b) => a.label > b.label ? 1 : -1);
        let Rowdd = [];
        ItemDestArray.forEach((item, index) => {
            if (item.STORAGE_AREA == ItemOrigArray[0].STORAGE_AREA && item.STOR_LEVEL_1 == ItemOrigArray[0].STOR_LEVEL_1
                && Rowdd.indexOf(item.STOR_LEVEL_2) == -1
            ) {
                let obj = {
                    value: item.STOR_LEVEL_2,
                    label: item.STOR_LEVEL_2
                }
                Rowdd.push(obj);
            }
        })
        let pprow = Rowdd.filter((ele, ind) => ind === Rowdd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
        pprow.sort((a, b) => a.label > b.label ? 1 : -1);
        let Shelfdd = [];
        ItemDestArray.forEach((item, index) => {
            if (item.STORAGE_AREA == ItemOrigArray[0].STORAGE_AREA && item.STOR_LEVEL_1 == ItemOrigArray[0].STOR_LEVEL_1
                && item.STOR_LEVEL_2 == ItemOrigArray[0].STOR_LEVEL_2 && Shelfdd.indexOf(item.STOR_LEVEL_3) == -1
            ) {
                let obj = {
                    value: item.STOR_LEVEL_3,
                    label: item.STOR_LEVEL_3
                }
                Shelfdd.push(obj);
            }
        })
        let ppshelf = Shelfdd.filter((ele, ind) => ind === Shelfdd.findIndex(elem => elem.value === ele.value && elem.label === ele.label));
        ppshelf.sort((a, b) => a.label > b.label ? 1 : -1);
        let Bindd = [];
        ItemDestArray.forEach((item, index) => {
            if (item.STORAGE_AREA == ItemOrigArray[0].STORAGE_AREA && item.STOR_LEVEL_1 == ItemOrigArray[0].STOR_LEVEL_1
                && item.STOR_LEVEL_3 == ItemOrigArray[0].STOR_LEVEL_3 && item.STOR_LEVEL_2 == ItemOrigArray[0].STOR_LEVEL_2 && Bindd.indexOf(item.STOR_LEVEL_4) == -1
            ) {
                let obj = {
                    value: item.STOR_LEVEL_4,
                    label: item.STOR_LEVEL_4
                }
                Bindd.push(obj);
            }
        })
        let ppbin = Bindd.filter((ele, ind) => ind === Bindd.findIndex(elem => elem.value === ele.value && elem.label === ele.label));
        ppbin.sort((a, b) => a.label > b.label ? 1 : -1);
        /* let AreaArray = [];
         let AisleArray = [];
         let RowArray = [];
         let ShelfArray = [];
         let BinArray = [];
         AreaArray.push(destArea);
         AisleArray.push(destAisle);
         RowArray.push(destRow);
         ShelfArray.push(destShelf);
         BinArray.push(destBin);*/
        this.setState({
            selectedDestArea: destArea, selectedDestAisle: destAisle, selectedDestRow: destRow,
            selectedDestShelf: destShelf, selectedDestBin: destBin, area: locArea, aisle: ppaisle, row: pprow, shelf: ppshelf, bin: ppbin
        });
    }
    /***
    * [ To invoke while change the value in area dropdown - Sathis N-10/09/2023]
    * */
    selectAreaChange = (e) => {
        if (this.state.selectedDestArea.value != e.value) {
            let Aisledd = [];
            this.state.destLocGrid.forEach((item, index) => {
                if (item.STORAGE_AREA == e.value && Aisledd.indexOf(item.STOR_LEVEL_1) == -1) {
                    let obj = {
                        value: item.STOR_LEVEL_1,
                        label: item.STOR_LEVEL_1
                    }
                    Aisledd.push(obj);
                }
            })
            let pp = Aisledd.filter((ele, ind) => ind === Aisledd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
            pp.sort((a, b) => a.label > b.label ? 1 : -1);
            this.setState({ aisle: pp, selectedDestArea: e, selectedDestAisle: {}, selectedDestRow: {}, selectedDestShelf: {}, selectedDestBin: {}, row: [], bin: [], shelf: [] });
        }
    }
    /***
    * [ To invoke while change the value in aisle dropdown - Sathis N-10/09/2023]
    * */
    selectAisleChange = (e) => {
        if (this.state.selectedDestAisle.value != e.value) {
            let Rowdd = [];
            this.state.destLocGrid.forEach((item, index) => {
                if (item.STORAGE_AREA == this.state.selectedDestArea.value && item.STOR_LEVEL_1 == e.value
                    && Rowdd.indexOf(item.STOR_LEVEL_2) == -1
                ) {
                    let obj = {
                        value: item.STOR_LEVEL_2,
                        label: item.STOR_LEVEL_2
                    }
                    Rowdd.push(obj);
                }
            })
            let pp = Rowdd.filter((ele, ind) => ind === Rowdd.findIndex(elem => elem.value === ele.value && elem.label === ele.label))
            pp.sort((a, b) => a.label > b.label ? 1 : -1);
            this.setState({ row: pp, selectedDestAisle: e, selectedDestRow: {}, selectedDestShelf: {}, selectedDestBin: {}, shelf: [], bin: [] })
        }
    }
    /***
    * [ To invoke while change the value in row dropdown - Sathis N-10/09/2023]
    * */
    selectRowChange = (e) => {
        if (this.state.selectedDestRow.value != e.value) {
            let Shelfdd = [];
            this.state.destLocGrid.forEach((item, index) => {
                if (item.STORAGE_AREA == this.state.selectedDestArea.value && item.STOR_LEVEL_1 == this.state.selectedDestAisle.value
                    && item.STOR_LEVEL_2 == e.value && Shelfdd.indexOf(item.STOR_LEVEL_3) == -1
                ) {
                    let obj = {
                        value: item.STOR_LEVEL_3,
                        label: item.STOR_LEVEL_3
                    }
                    Shelfdd.push(obj);
                }
            })
            let pp = Shelfdd.filter((ele, ind) => ind === Shelfdd.findIndex(elem => elem.value === ele.value && elem.label === ele.label));
            pp.sort((a, b) => a.label > b.label ? 1 : -1);
            this.setState({ shelf: pp, selectedDestRow: e, selectedDestShelf: {}, selectedDestBin: {}, bin: [] })
        }
    }
    /***
    * [ To invoke while change the value in shelf dropdown - Sathis N-10/09/2023]
    * */
    selectShelfChange = (e) => {
        if (this.state.selectedDestShelf.value != e.value) {
            let Bindd = [];
            this.state.destLocGrid.forEach((item, index) => {
                if (item.STORAGE_AREA == this.state.selectedDestArea.value && item.STOR_LEVEL_1 == this.state.selectedDestAisle.value
                    && item.STOR_LEVEL_3 == e.value && item.STOR_LEVEL_2 == this.state.selectedDestRow.value && Bindd.indexOf(item.STOR_LEVEL_4) == -1
                ) {
                    let obj = {
                        value: item.STOR_LEVEL_4,
                        label: item.STOR_LEVEL_4
                    }
                    Bindd.push(obj);
                }
            })
            let pp = Bindd.filter((ele, ind) => ind === Bindd.findIndex(elem => elem.value === ele.value && elem.label === ele.label));
            pp.sort((a, b) => a.label > b.label ? 1 : -1);
            this.setState({ bin: pp, selectedDestShelf: e, selectedDestBin: {} })
        }
    }
    /***
    * [ To invoke while change the value in bin dropdown - Sathis N-10/09/2023]
    * */
    selectBinChange = (e) => {
        if (this.state.selectedDestBin.value != e.value) {
            this.setState({ selectedDestBin: e })
        }
    }
    /***
    * [ To invoke on the click of submit button - Sathis N-10/09/2023]
    * */
    submitClick = () => {
        if (this.state.itemID == "") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please enter item ID'
            });
            return;
        }
        else if (this.state.origLocGrid.length < 1 || this.state.destLocGrid.length < 1) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please enter valid item ID'
            });
            return;
        }

        else if (this.isDifferentToFromLocation() == false) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Original and destination locations must be different.'
            });
            return;
        }
        else if (this.state.selectedDestAisle.value == "" || this.state.selectedDestAisle.value == null) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please select destination aisle'
            });
            return;
        }
        else if (this.state.selectedDestRow.value == "" || this.state.selectedDestRow.value == null) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please select destination row'
            });
            return;
        }
        else if (this.state.selectedDestShelf.value == "" || this.state.selectedDestShelf.value == null) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please select destination shelf'
            });
            return;
        }
        else if (this.state.selectedDestBin.value == "" || this.state.selectedDestBin.value == null) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please select destination bin'
            });
            return;
        }
        else if (this.state.transQuantity == "") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Please enter quantity '
            });
            return;
        }
        else if ((parseInt(this.state.transQuantity) > parseInt(this.state.selectedCurQty))) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: 'Transfer quantity should not be greater than available quantity.'
            });
            return;
        }
        else {
            $('#TransferConfPopUp').modal('show');
        }
    }
    /***
    * [ To invoke inorder to check current location and destination location should be same or not - Sathis N-10/09/2023]
    * */
    isDifferentToFromLocation = () => {
        if (this.state.selectedOrigArea == this.state.selectedDestArea.label && this.state.selectedOrigAisle == this.state.selectedDestAisle.label
            && this.state.selectedOrigRow == this.state.selectedDestRow.label && this.state.selectedOrigShelf == this.state.selectedDestShelf.label && this.state.selectedOrigBin == this.state.selectedDestBin.label) {
            return false;
        }
        else {
            return true;
        }
    }
    /***
    * [ To invoke inorder to bind current location grid - Sathis N-10/09/2023]
    * */
    bindGridData = () => {
        debugger
        if (this.state.origLocGrid.length > 0) {
            return this.state.origLocGrid.map((value, index) => {
                return (<React.Fragment key={index}>{value.INV_ITEM_ID != "" ? <>
                    <tr>
                        <td>
                            <label className="custom-radiobtn">
                                <input type="radio" name="BURad" id="BURad" checked={value.IsLocSelected} onClick={e => this.onHandleGridRadChange(e, index)} />
                                <span className="checkmark checkmark-org" />
                            </label>
                        </td>
                        <td>{`${value.BUSINESS_UNIT}-${value.DESCR}`}</td>
                        <td>{value.QuantityAvail}</td>
                        <td>{value.SERIAL_ID}</td>
                        <td>{value.STORAGE_AREA}</td>
                        <td>{value.STOR_LEVEL_1}</td>
                        <td>{value.STOR_LEVEL_2}</td>
                        <td>{value.STOR_LEVEL_3}</td>
                        <td>{value.STOR_LEVEL_4}</td>
                    </tr>
                </> : null}
                </React.Fragment>

                );


            })

        }
    }
    /***
    * [ To invoke on the click of submit button from confirmation popup - Sathis N-10/09/2023]
    * */
    transferConfClick = () => {
        let TransConfSubmitBO = {
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_UserID: CookieService.getLocalStorageValues("Session_UserID"),
            SelectedBU: this.state.selectedOrigBU,
            itemID: this.state.itemID.toUpperCase(),
            IsMakeDef: this.state.makeDefchk,
            SerialID: this.state.SerialID,
            SelOrigArea: this.state.selectedOrigArea,
            SelOrigAisle: this.state.selectedOrigAisle,
            SelOrigRow: this.state.selectedOrigRow,
            SelOrigShelf: this.state.selectedOrigShelf,
            SelOrigBin: this.state.selectedOrigBin,
            SelDestArea: this.state.selectedDestArea.value,
            SelDestAisle: this.state.selectedDestAisle.value,
            SelDestRow: this.state.selectedDestRow.value,
            SelDestShelf: this.state.selectedDestShelf.value,
            SelDestBin: this.state.selectedDestBin.value,
            TransferQty: this.state.transQuantity,
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID")

        }
        $('#TransferConfPopUp').modal('hide');
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        BinToBinAction.TransfConfSubmit(TransConfSubmitBO)
    }
    /***
    * [ To invoke confirm transfer response - Sathis N-10/09/2023]
    * */
    transferConfClickResponse = () => {
        let TransferConfSubmitRes = JSON.parse(BinToBinStore.TransferResData);
        /*        if (TransferConfSubmitRes.includes("SUCCESS")) {
                    $('#itemID').val("");
                    this.setState({ origLocGrid: [], destLocGrid: [], itemID: "", IsMakeDef: false, itemDescription: "", transQuantity: "", transferMessage: TransferConfSubmitRes });
                }*/
        // else {
        // }
        this.setState({ transferMessage: TransferConfSubmitRes, makeDefchk: false });
        $('#successpopup').modal('show');
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }
    /***
    * [ To invoke on the click of ok button inorder to retain quantity changed item's details - Sathis N-10/09/2023]
    * */
    okBtnClick = () => {
        if (this.state.transferMessage.includes("SUCCESS")) {
            this.searchItemClick();
        }
    }
    /***
    * [ To invoke on the click of cancel and clear button inorder to clear current item's details - Sathis N-10/09/2023]
    * */
    cancelBtnClick = () => {
        this.setState({ origLocGrid: [], destLocGrid: [], itemID: "", makeDefchk: false, itemDescription: "", transQuantity: "", disableItemID: false });
    }
    /***
    * [ To invoke on the click of clear icon inorder to clear item id - Sathis N-10/09/2023]
    * */
    /*clearItemId = () => {
        this.setState({ itemID: "" });
    }*/
    /***
    * [ To invoke on the click of enter button from item id field - Sathis N-10/09/2023]
    * */
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.searchItemClick();
        };
    };
    //To handle the favorite page
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Bin To Bin Transfer",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            BinToBinAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            BinToBinAction.deleteFavPage(deleteFavPageBO)
        }
    };
    loadFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let favPageResponse = JSON.parse(BinToBinStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (BinToBinStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(BinToBinStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(BinToBinStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    /***
     * To display HTML fields
     * */
    render() {
        return (
            //<div className="container-fluid content-section">
            <div className="container-fluid content-section">
                <div className="row">
                    <div className="container-fluid content-margin">
                        <div className="col-md-12 page-heading mb-3 float-left p-0">
                            <h5 className="page-title">Bin To Bin Transfer</h5>
                            <div className="fav-item-maintenance-icon mt-1">
                                <input type="checkbox" id="checkbox-OrdStatus" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                <label htmlFor="checkbox-OrdStatus">
                                    <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12 float-left  mb-3">
                            <div className="row ">
                                <div className="col-md-12 p-0 float-left mb-4">
                                    <div className="row">
                                        <div className="col-md-3 my-2">
                                            <div className="row">
                                                <label className="catalog-label col-md-4 float-left mt-2">Item ID</label>
                                                <div className="col-md-8">
                                                    <input type="text" id="itemID" name="itemID" className="form-control float-left" value={this.state.itemID} disabled={this.state.disableItemID} onKeyDown={(e) => this.searchKeyPress(e)} onChange={(e) => this.onHandleChange(e, "ItemId")} style={{ padding: '0px 57px 0px 10px' }} />
                                                    {this.state.itemID != "" ?
                                                        <span class="search-cancel-header cursor-pointer" onClick={() => this.cancelBtnClick()} > </span> : null}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-4 col-lg-4 my-2 pl-3 ml-1">
                                            <button className="btn btn-primary custom-btn-primary primary-btn float-left" onClick={e => this.searchItemClick(e)}>Search</button>
                                        </div>
                                    </div>
                                    {this.state.itemDescription != "" ?
                                        <div class="row">
                                            <div class="col-md-12 my-2">
                                                <div class="row align-items-center">
                                                    <label class="catalog-label col-md-1 float-left mt-2 text-nowrap">Item description</label>
                                                    <div class="col-md-11 item-details-value">
                                                        <label class="mt-2">{this.state.itemDescription}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                    {this.state.phyInvMsg != "" ?
                                        <div class="row">
                                            <div className="col-md-12 item-details-value my-2" style={{ "color": "red" }}>
                                                <label>{this.state.phyInvMsg} </label>
                                            </div>
                                        </div> : null}

                                </div>
                                {/*Item Info starts here*/}
                                {this.state.origLocGrid.length > 0 ?
                                    <div className="col-md-12 float-left mb-4 p-0">
                                        <h5 className="fav-item-title mb-2">Default location</h5>

                                        <div className="row">
                                            <div className="col-auto mb-3">
                                                <label className="catalog-label w-100">Business unit</label>
                                                <label className="item-details-value  w-100">{this.state.ItemDefBUName}</label>
                                            </div>
                                            <div className="col-auto mb-3">
                                                <label className="catalog-label w-100">Area </label>
                                                <label className="item-details-value  w-100">{this.state.itemDefArea}</label>
                                            </div>
                                            <div className="col-auto mb-3">
                                                <label className="catalog-label w-100">Aisle </label>
                                                <label className="item-details-value  w-100">{this.state.itemDefAisle}</label>
                                            </div>
                                            <div className="col-auto mb-3">
                                                <label className="catalog-label w-100">Row </label>
                                                <label className="item-details-value  w-100">{this.state.itemDefRow}</label>
                                            </div>
                                            <div className="col-auto mb-3">
                                                <label className="catalog-label w-100">Shelf </label>
                                                <label className="item-details-value  w-100">{this.state.itemDefShelf}</label>
                                            </div>
                                            <div className="col-auto mb-3">
                                                <label className="catalog-label w-100">Bin </label>
                                                <label className="item-details-value  w-100">{this.state.itemDefBin}</label>
                                            </div>
                                        </div>
                                        <h5 className="fav-item-title mb-2">Current location</h5>
                                        <div className="table-responsive">
                                            <table className="table ZEUS_Grid FOM-grid mobileflex-grid">
                                                <thead>
                                                    <tr>
                                                        <th />
                                                        <th>Business unit</th>
                                                        <th>Quantity</th>
                                                        <th>Serial ID</th>
                                                        <th>Area</th>
                                                        <th>Aisle</th>
                                                        <th>Row</th>
                                                        <th>Shelf</th>
                                                        <th>Bin</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.bindGridData()}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : null}
                                {this.state.destLocGrid.length > 0 ? < div class="col-md-12 float-left mb-4 p-0">
                                    <div class="table-responsive table-customstyle-whitespace mt-3" style={{ "marginBottom": "10%", "height": "150%", "overflow": "hidden" }}>
                                        <h5 className="fav-item-title mb-2">Destination location</h5>
                                        <table class="table ZEUS_Grid FOM-grid mobileflex-grid" id="table">
                                            <thead>
                                                <tr>
                                                    <th>Area</th>
                                                    <th>Aisle</th>
                                                    <th>Row</th>
                                                    <th>Shelf</th>
                                                    <th>Bin</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td data-label="Area" style={{ "max-width": "90px" }}>
                                                        <Select class="form-control"
                                                            id="AreaDD"
                                                            name="Area"
                                                            options={this.state.area}
                                                            value={this.state.selectedDestArea}
                                                            onChange={(e) => this.selectAreaChange(e)}
                                                        />
                                                    </td>
                                                    <td data-label="AIS" style={{ "max-width": "90px" }}>
                                                        <Select class="form-control"
                                                            id="AisleDD"
                                                            name="Aisle"
                                                            options={this.state.aisle}
                                                            value={this.state.selectedDestAisle}
                                                            onChange={(e) => this.selectAisleChange(e)}
                                                        />
                                                    </td>
                                                    <td data-label="Row" style={{ "max-width": "90px" }}>
                                                        <Select class="form-control"
                                                            id="RowDD"
                                                            name="Row"
                                                            options={this.state.row}
                                                            value={this.state.selectedDestRow}
                                                            onChange={(e) => this.selectRowChange(e)}
                                                        />
                                                    </td>
                                                    <td data-label="SHF" style={{ "max-width": "90px" }}>
                                                        <Select class="form-control"
                                                            id="ShelfDD"
                                                            name="Shelf"
                                                            options={this.state.shelf}
                                                            value={this.state.selectedDestShelf}
                                                            onChange={(e) => this.selectShelfChange(e)}
                                                        />
                                                    </td>
                                                    <td data-label="BIN" style={{ "max-width": "90px" }}> <Select class="form-control"
                                                        id="BinDD"
                                                        name="Bin"
                                                        options={this.state.bin}
                                                        value={this.state.selectedDestBin}
                                                        onChange={(e) => this.selectBinChange(e)}
                                                    />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-md-9 float-left mb-2">
                                            <div className="row">

                                                <label className="catalog-label col-md-2 px-1" htmlFor="AdjustQuantity">Transfer quantity</label>
                                                <div className="col-md-4">
                                                    <div className="row align-items-center">
                                                        <input type="text" className="form-control float-left" id="transQuantity" name="transQuantity" value={this.state.transQuantity} onChange={(e) => this.onHandleChange(e, "transQuantity")} />
                                                        <div className="custom-control custom-checkbox item-custom-control mt-2">
                                                            <input type="checkbox" className="custom-control-input" id="makeDefchk" name="makeDefchk" checked={this.state.makeDefchk} onChange={(e) => this.onHandleChange(e, "checkbox")} />
                                                            <label className="custom-control-label" htmlFor="makeDefchk">Make default </label>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-md-12 float-left pl-2">
                                            <div className="float-right">
                                                <button className="btn btn-secondary custom-btn-custom-btn-secondary secondary-btn px-4 mr-2" onClick={e => this.cancelBtnClick()}>Cancel</button>
                                                <button className="btn btn-primary custom-btn-primary primary-btn px-4" onClick={e => this.submitClick(e)}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                                {/*Item Info ends here*/}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="TransferConfPopUp" >
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header bg-white border-0">
                                <h2 className="mt-1 fav-item-title">Confirm transfer</h2>
                                <button type="button" className="btn close-btn bg-white" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body pt-0">
                                <div className="row">
                                    <div className="col-auto mb-3">
                                        <label className="catalog-label w-100">Item ID</label>
                                        <label className="item-details-value  w-100">{this.state.itemID}</label>
                                    </div>
                                    <div className="col-auto mb-3">
                                        <label className="catalog-label w-100">Business unit </label>
                                        <label className="item-details-value  w-100">{this.state.selectedOrigBU}</label>
                                    </div>
                                    <div className="col-auto mb-3">
                                        <label className="catalog-label w-100">QTY </label>
                                        <label className="item-details-value  w-100">{this.state.transQuantity}</label>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table ZEUS_Grid FOM-grid mobileflex-grid">
                                        <thead>
                                            <tr>
                                                <th>Level</th>
                                                <th>From location</th>
                                                <th>To location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Area</td>
                                                <td>{this.state.selectedOrigArea}</td>
                                                <td>{this.state.selectedDestArea.value}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Aisle</td>
                                                <td>{this.state.selectedOrigAisle}</td>
                                                <td>{this.state.selectedDestAisle.value}</td>
                                            </tr>
                                            <tr>
                                                <td>Row</td>
                                                <td>{this.state.selectedOrigRow}</td>
                                                <td>{this.state.selectedDestRow.value}</td>
                                            </tr>
                                            <tr>
                                                <td>Shelf</td>
                                                <td>{this.state.selectedOrigShelf}</td>
                                                <td>{this.state.selectedDestShelf.value}</td>
                                            </tr>
                                            <tr>
                                                <td>Bin</td>
                                                <td>{this.state.selectedOrigBin}</td>
                                                <td>{this.state.selectedDestBin.value}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-12 text-right mb-4">
                                <button className="btn btn-secondary custom-btn-custom-btn-secondary secondary-btn px-4 mr-2" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary custom-btn-primary primary-btn px-4" onClick={e => this.transferConfClick(e)}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="successpopup" data-backdrop="static" data-keyboard="false" >
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="text-center mt-2">
                                            {this.state.transferMessage.includes("BintoBin Complete") ? <img src="Images/success-alert.png" alt="Transfer Success" /> : <img src="Images/close.png" alt="Transfer Failed" />}
                                        </div>
                                        <div className="text-center my-4">
                                            <span className="info-label">{this.state.transferMessage.includes("BintoBin Complete") ? "Bin to bin transfer completed" : this.state.transferMessage}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={e => this.okBtnClick(e)} >OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
export default withTranslation()(BinToBinComponent);