/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 08/13/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: FOM_PC_27
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
/**
 * Ref: FOM_PC_28
 * 
 * getFavOrderDetails method gets called from the componentWillMount() of the component.
 * It takes a parameters pageLoadBO which is passed from the component file.
 * @param pageLoadBO
 */
const getFavOrderDetails = async (pageLoadBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavOrder,
            SDIConstants.methodNames.FavOrder_Pageload,
            JSON.stringify(pageLoadBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavOrder_Pageload, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * getCurrentFavPage method gets called on page load
 * @param getFavPageBO
 */
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVORDER_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVORDER_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVORDER_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: FOM_PC_29
 * addFavOrderItemsTocart method gets called on Reorder button click
 * @param favOrderCartBO
 */
const addFavOrderItemsTocart = async (favOrderCartBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavOrder,
            SDIConstants.methodNames.FavOrder_ReorderItems,
            JSON.stringify(favOrderCartBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "FAVORDER_AddFavOrderItemsToCart", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: FOM_PC_30
 * deleteFavOrder method gets called on yes button click of delete confirmation popup
 * @param deleteBO
 */
const deleteFavOrder = async (deleteBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavOrder,
            SDIConstants.methodNames.FavOrder_RemoveFavOrder,
            JSON.stringify(deleteBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavOrder_RemoveFavOrder, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const shareButtonClickAction = async (shareBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.FavOrder,
            SDIConstants.methodNames.FavOrder_Share,
            JSON.stringify(shareBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.FavOrder_Share, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
}
const addFavOrderImg = async (files, data) => {
    try {
        SDISVC.uploadFile(
            SDIConstants.moduleNames.NonCatalog,
            SDIConstants.methodNames.NonCatalog_AttachFiles,
            JSON.stringify(data),
            files,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "favOrderImageUpload", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export {
    getFavOrderDetails,
    getCurrentFavPage,
    insertFavPage,
    deleteFavPage,
    addFavOrderItemsTocart,
    deleteFavOrder,
    shareButtonClickAction,
    addFavOrderImg
}