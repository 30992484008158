import React, { Component } from 'react';
import {
	Link, withRouter, useLocation
} from "react-router-dom";
import MenuComponent from './MenuComponent';
import UnAuthMenuComponent from './UnAuthMenuComponent';
import * as CookieService from '../CookieService';
let counter = 0;
const isReqApprovalEmail = (props) => {
	let isEmailLink = false;
	const routePath = props.location.pathname.toLowerCase();
	if (routePath.match(/approvequote/)) {
		isEmailLink = true;
		const reqqueryParams = new URLSearchParams(window.location.search);
		const reqQS_src = reqqueryParams.get('src');
		let reqSession_USERNAME = CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "";
		if (reqQS_src) {
			if (reqQS_src.toLowerCase() == "needquote") {
				if (reqSession_USERNAME != "App Order Email Link" && reqSession_USERNAME != "App Quote Email Link") {
					isEmailLink = false;
				}
			}
		}
	}
	return isEmailLink;
}
const isOrderApprovalLink = (props) => {
	let isEmailLink = false;
	const routePath = props.location.pathname.toLowerCase();
	if (routePath.match(/needapprove/)) {
		const orderQueryParams = new URLSearchParams(window.location.search);
		const orderQS_fer = orderQueryParams.get('fer');
		const orderQS_op = orderQueryParams.get('op');
		const orderQS_xyz = orderQueryParams.get('xyz');
		if (orderQS_fer || orderQS_op || orderQS_xyz) {
			isEmailLink = true;
		}

	}
	return isEmailLink;
}
const ComponentToShow = (props) => {
	let componentToBind = null;
	counter = counter + 1;
	console.log(counter);
	const locationVal = props.location;

    if (locationVal.pathname.toLowerCase().match(/forgotpassword/) || locationVal.pathname == "/" || locationVal.pathname.toLowerCase().match(/changepassword/) || locationVal.pathname.toLowerCase().match(/pipost/) || locationVal.pathname.toLowerCase().match(/coopform/) || locationVal.pathname.toLowerCase().match(/sdicoop/)) {
		return componentToBind;
	}
	if (locationVal.pathname.toLowerCase().match(/sessiontimeout/) || locationVal.pathname.toLowerCase().match(/shopredirect/) || locationVal.pathname.toLowerCase().match(/punchinsap/) || locationVal.pathname.toLowerCase().match(/addnewpunchinuser/) || locationVal.pathname.toLowerCase().match(/punchinend/) ) {
		componentToBind = (<UnAuthMenuComponent key={counter} {...props} />)
		return componentToBind;
	}
	let isOrderApprovalFromEmail = isOrderApprovalLink(props);
	let isReqApprovalFromEmail = isReqApprovalEmail(props);
	if (isOrderApprovalFromEmail || isReqApprovalFromEmail) {
		componentToBind = <UnAuthMenuComponent key={counter} {...props} />
		return componentToBind;
	}

	componentToBind = (<MenuComponent key={counter} {...props} />)
	return componentToBind;
}

const HeaderComponent = withRouter(ComponentToShow);

export default HeaderComponent;