import React, { Component } from 'react';
import * as CookieService from '../CookieService';
import * as WaitingRequestorApprovalAction from '../actions/WaitingRequestorApprovalAction';
import WaitingRequestorApprovalStore from '../stores/WaitingRequestorApprovalStore';
import SDIConstants from '../SDIConstants';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as MenuAction from '../actions/MenuAction';
import * as $ from 'jquery';
//[PC_07, 09/07/21, Author - Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';

let currentPagePath;
let searchTxt = "";
let isPostBack = true;
//[PC_3818_APP_01 - Johnprinto D]
let searchColumnGlobVar = "";
let searchTextGlobVar = "";
let startDateGlobVar = "";
let endDateGlobVar = "";

class WaitingRequestorApprovalComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            itemCount: 10,
            searchText: "",
            searchColumn: "ALL",
            sortColumn: "ADD_DTTM",
            sortValue: "DESC",
            startDate: "",
            endDate: "",
            isSearch: false,
            reqApprovalsData: [],
            totalCount: 0,
            pageCount: 0,
            filterDisplayName: "All",
            isFilterOpen: false,
            startDateObj: null,
            endDateObj: null,
            isFavorited: false,
            isPageLoadedOnce: false,
            Session_UserID: CookieService.getLocalStorageValues("Session_UserID") ? CookieService.getLocalStorageValues("Session_UserID") : "",
            ReqApprovalFromNotification: ""

        };
        this.assignWReqApproval = this.assignWReqApproval.bind(this);
        this.filterClick = this.filterClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchTxt = this.handleSearchTxt.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.filtertoggle = this.filtertoggle.bind(this);
        this.sortingClick = this.sortingClick.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);
        this.applyFilterclick = this.applyFilterclick.bind(this);
        this.cancelFilter = this.cancelFilter.bind(this);
        this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
        this.handleFavorite = this.handleFavorite.bind(this);
        this.filtertoggle = this.filtertoggle.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.viewReqApproval = this.viewReqApproval.bind(this);
    };

    componentDidMount() {
        window.scrollTo(0, 0)
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        WaitingRequestorApprovalAction.getCurrentFavPage(getFavPageBO);
        isPostBack = true;
        //[9/25/2023]Zeus 182 Approvals redirection from notification - receiving the order no sent as props from receiving component to bind it in search field and bind empty grid
        let OrderNoFromReqApproval = "";

        if (this.props.location.state && CookieService.getLocalStorageValues("IsOrderNAInReqApproval")) {
            if (this.props.location.state.origin && this.props.location.state.origin == 'FromNotification') {
                OrderNoFromReqApproval = this.props.location.state.OrderNo;
                CookieService.updateLocalStorageValues("IsOrderNAInReqApproval", false);
            }
        }

        let srchText = OrderNoFromReqApproval ? OrderNoFromReqApproval : this.state.searchText;
        this.pageLoad(this.state.pageNo,
            this.state.itemCount,
            this.state.searchColumn,
            srchText,
            this.state.sortColumn,
            this.state.sortValue,
            this.state.startDate,
            this.state.endDate);
        WaitingRequestorApprovalStore.on("wrequestorData", this.assignWReqApproval);
        WaitingRequestorApprovalStore.on('favpage', this.loadFavPageResponse);
        WaitingRequestorApprovalStore.on('updateFavPage', this.updateFavPageResponse);
        this.setState({ searchText: srchText, ReqApprovalFromNotification: OrderNoFromReqApproval })
    };
    componentWillUnmount() {
        WaitingRequestorApprovalStore.removeListener("wrequestorData", this.assignWReqApproval);
        WaitingRequestorApprovalStore.removeListener('favpage', this.loadFavPageResponse);
        WaitingRequestorApprovalStore.removeListener('updateFavPage', this.updateFavPageResponse);
    }

    pageLoad = (pageNo, itemCount, searchColumn, searchText, sortColumn, sortValue, startDate, endDate) => {
        debugger
        let pageLoadBO = {
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT") ? CookieService.getLocalStorageValues("Session_BUSUNIT") : "",
            Session_USERID: this.state.Session_UserID,
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU") ? CookieService.getLocalStorageValues("Session_AGENTUSERBU") : "",
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID") ? CookieService.getLocalStorageValues("Session_AGENTUSERID") : "",
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "",
            Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN") ? CookieService.getLocalStorageValues("Session_PUNCHIN") : "",
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP") ? CookieService.getLocalStorageValues("Session_SDIEMP") : "",
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "",
            Session_SHOPPAGE: CookieService.getLocalStorageValues("Session_SHOPPAGE") ? CookieService.getLocalStorageValues("Session_SHOPPAGE") : "SHOPPINGCART.ASPX",
            WorkOrderPnchInA: CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") ? CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") : "",
            pageNo: pageNo,
            itemCount: itemCount,
            searchText: searchText,
            searchColumn: searchColumn,
            sortColumn: sortColumn,
            sortValue: sortValue,
            startDate: startDate,
            endDate: endDate,

        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        WaitingRequestorApprovalAction.getWaitingReqData(pageLoadBO);
    }
    assignWReqApproval = () => {
        debugger
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let wRequestorData = JSON.parse(WaitingRequestorApprovalStore.wRequestorData);
        console.log("wRequestorData", wRequestorData);
        if (wRequestorData) {
            if (wRequestorData.flagdt.length > 0) {
                debugger
                //****************(12/15/2020)after(||)******************
                if (wRequestorData.flagdt[0].blockApproval != "Y" || CookieService.getLocalStorageValues("Session_PUNCHIN") == "YES") {
                    this.setState({
                        reqApprovalsData: wRequestorData.WaitReqApprovalDt,
                        totalCount: wRequestorData.totalCount.length > 0 ? wRequestorData.totalCount[0].TOTAL_COUNT : 0,
                        pageCount: Math.ceil((wRequestorData.totalCount.length > 0 ? wRequestorData.totalCount[0].TOTAL_COUNT : 0) / this.state.itemCount),
                        isPageLoadedOnce: true
                    });
                }
                //************NEW********************
                else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("BlckShopCart")
                    }).then(() => {
                        window.location.href = '/homepage';
                    });
                    //alert(t("BlckShopCart"))
                    /* this.props.history.push({
                 pathname: '/HomePage', });*/

                }
                //*************END********************
            }
        }
        //[7/12/2023, PS_ZE_142_APP_20, Dhevanesam R]
        MenuAction.refreshMasterData()
    };
    exportToExcel = () => {
        //[PC_3818_APP_09 - Johnprinto D]
        let exportBO = {
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT") ? CookieService.getLocalStorageValues("Session_BUSUNIT") : "",
            Session_USERID: this.state.Session_UserID,
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU") ? CookieService.getLocalStorageValues("Session_AGENTUSERBU") : "",
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID") ? CookieService.getLocalStorageValues("Session_AGENTUSERID") : "",
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "",
            Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN") ? CookieService.getLocalStorageValues("Session_PUNCHIN") : "",
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP") ? CookieService.getLocalStorageValues("Session_SDIEMP") : "",
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "",
            Session_SHOPPAGE: CookieService.getLocalStorageValues("Session_SHOPPAGE") ? CookieService.getLocalStorageValues("Session_SHOPPAGE") : "SHOPPINGCART.ASPX",
            WorkOrderPnchInA: CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") ? CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") : "",
            searchText: searchTextGlobVar,
            searchColumn: searchColumnGlobVar,
            startDate: startDateGlobVar,
            endDate: endDateGlobVar,
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        WaitingRequestorApprovalAction.exportData(exportBO);
    };
    handlePageClick = (data) => {
        debugger
        let pageNo = data.selected + 1;

        this.setState({ pageNo: pageNo });
        //[PC_3818_APP_05 - Johnprinto D]
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
        isPostBack = false;
        this.pageLoad(pageNo, this.state.itemCount,
            searchColumnGlobVar, searchTextGlobVar, this.state.sortColumn,
            this.state.sortValue, startDateGlobVar, endDateGlobVar);
    };
    filterClick = (event, displayName, columnName) => {
        event.preventDefault();
        this.setState({ filterDisplayName: displayName, searchColumn: columnName });

    };
    //[PC_3818_APP_03 - Johnprinto D]
    handleSearchTxt = (e) => {
        this.setState({ searchText: e.target.value });
    }
    keyPress(e) {
        if (e.keyCode == 13) {
            this.handleSearch();
        }
    };
    ClearSearch = () => {
        this.setState({ searchText: "" });
    }
    handleSearch = () => {
        let pageNo = 1;
        //[PC_3818_APP_04 - Johnprinto D]
        //searchTxt = $(`#searchTxt`).val().trim();
        searchColumnGlobVar = this.state.searchColumn;
        searchTextGlobVar = this.state.searchText;
        startDateGlobVar = this.state.startDate;
        endDateGlobVar = this.state.endDate;
        isPostBack = false;
        this.pageLoad(pageNo, this.state.itemCount,
            this.state.searchColumn, this.state.searchText, this.state.sortColumn,
            this.state.sortValue, this.state.startDate, this.state.endDate,
        );

        this.setState({ pageNo: pageNo });
    };
    filtertoggle = (e) => {
        if (e)
            e.preventDefault();
        this.setState({ isFilterOpen: this.state.isFilterOpen == true ? false : true });
    };
    getDateString = (date) => {
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear())
    };
    handleDatePicker = (date, name) => {
        this.setState({
            [name]: date
        });
        console.log("Date", date);
    };
    applyFilterclick = () => {
        const { t } = this.props;
        let startDate = this.state.startDateObj ? this.getDateString(this.state.startDateObj) : "";
        let endDate = this.state.endDateObj ? this.getDateString(this.state.endDateObj) : "";
        console.log(startDate, endDate);
        //[PC_3818_APP_07 - Johnprinto D]
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
        isPostBack = false;
        if (startDate != "" && endDate != "") {
            startDateGlobVar = startDate;
            endDateGlobVar = endDate;
            this.pageLoad(1,
                this.state.itemCount,
                searchColumnGlobVar,
                searchTextGlobVar,
                this.state.sortColumn,
                this.state.sortValue,
                startDate,
                endDate);
            this.setState({ pageNo: 1, startDate: startDate, endDate: endDate });
            this.filtertoggle();
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please select From and To Date")
            })
        }
    };
    cancelFilter = () => {
        this.setState({
            startDateObj: null,
            endDateObj: null
        })

        //[PC_3818_APP_08 - Johnprinto D]
        startDateGlobVar = "";
        endDateGlobVar = "";
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
        isPostBack = false;
        this.pageLoad(1,
            this.state.itemCount,
            searchColumnGlobVar,
            searchTextGlobVar,
            this.state.sortColumn,
            this.state.sortValue,
            startDateGlobVar,
            endDateGlobVar);
        this.setState({ pageNo: 1, startDate: startDateGlobVar, endDate: endDateGlobVar });
        this.filtertoggle();
    };
    sortingClick = (sortColumn, sortValue) => {

        console.log(sortColumn, sortValue);
        //[PC_3818_APP_06 - Johnprinto D]
        /*if (this.state.isSearch) {
            searchTxt = $(`#searchTxt`).val().trim();
        }*/
        let pageNo = 1;
        isPostBack = false;
        /*[PC_01, Bug-1779, Priyanka D]*/
        this.pageLoad(pageNo, this.state.itemCount,
            searchColumnGlobVar, searchTextGlobVar, sortColumn,
            sortValue, startDateGlobVar, endDateGlobVar);
        this.setState({ pageNo: pageNo, sortColumn: sortColumn, sortValue: sortValue });
    };
    viewReqApproval = (event, wreq) => {
        event.preventDefault();
        debugger;
        let pagePath = `\approvequote`;
        let encodedOrderNo = encodeURIComponent(wreq.ORDER_NO);
        let encodedEMPID = encodeURIComponent(wreq.EMPID);
        let strBU = CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU");
        let encodedStrBU = encodeURIComponent(strBU);
        let queryParamsVal = `?fer=${encodedOrderNo}&op=${encodedEMPID}&xyz=${encodedStrBU}&alt=&src=needquote`;
        this.props.history.push({
            pathname: pagePath,
            search: queryParamsVal
        });
    }
    //[4/18/22, Bug - 2085, Dhevanesam R]
    bindWaitingReqGrid = () => {
        debugger
        const { t } = this.props;
        return this.state.reqApprovalsData.map((wreq, windex) => {
            return (<tr key={wreq.ORDER_NO + "-" + windex}>
                <td data-label={t("Order Number")}><a href="#" className="order-id" onClick={(e) => this.viewReqApproval(e, wreq)}>{wreq.ORDER_NO}</a></td>
                <td data-label={t("Work order #")}>{wreq.WRK_ORDR_NUM}</td>
                {/*[06/15/2023 - PC_ZE_139_APP_2 - Johnprinto D]*/}
                <td data-label={t("Work order description")}>{wreq.WO_DESC}</td>
                <td data-label={t("Order Entered by")}>{wreq.EMPNAME}</td>
                <td data-label={t("Created Date")}>{wreq.ADD_DT}</td>
                <td data-label={t("Pending Approver")}>{wreq.CURRENT_APPR_NAME}</td>
                <td data-label={t("Status")}><span className="warning-icon mr-2" /><Trans>{wreq.CURRENT_APPR_ID == this.state.Session_UserID ? `Waiting For your Approval` : t(`This order/quote is still waiting approval from`) + `${wreq.CURRENT_APPR_NAME}.`}</Trans></td>
            </tr>);

        });

    };
    /**
  * Listener method for the response of Save/Delete favorite page
  */
    updateFavPageResponse = () => {
        if (WaitingRequestorApprovalStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(WaitingRequestorApprovalStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(WaitingRequestorApprovalStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    /**
    * Handle change method for favorite page checkbox
    * @param event
    */
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Waiting Requestor Approval",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            WaitingRequestorApprovalAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID,
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            WaitingRequestorApprovalAction.deleteFavPage(deleteFavPageBO)
        }
    };
    /**
    * Listener method for loading favorite page icon
    */
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(WaitingRequestorApprovalStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        const { t } = this.props;
        return (<div className="container-fluid content-section">
            <div className="row">
                {/* Waiting Request Approval starts */}
                <div className="container-fluid content-margin">
                    <div className="row">
                        <div className="col-md-12 page-heading mb-3">
                            {/*2551 - UI issue in Waiting requester approval page - Dhamotharan P*/}
                            <h5 className="page-title w-75"><Trans>Waiting Requestor Approval</Trans><span>
                                <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                <label htmlFor="checkbox-1">
                                    <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </span></h5>

                        </div>
                        {this.state.isPageLoadedOnce && this.state.totalCount == 0 && isPostBack == true && this.state.ReqApprovalFromNotification == "" ? null : <div className="col-md-12">
                            <div className="float-right">
                                {/********************New************************/}
                                {this.state.totalCount == 0 ? null :
                                    <button className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" onClick={this.exportToExcel}><Trans>Export to Excel</Trans></button>}
                                {/************************end*************************/}
                                <div className="input-group dropdown-search float-left mb-2 req-search-width">
                                    <div className="input-group-prepend">
                                        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.filterDisplayName}</Trans></button>
                                        <div className="dropdown-menu" style={{}}>
                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "All", "ALL")}><Trans>All</Trans></a>
                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order Number", "ORDER_NO")}><Trans>Order Number</Trans></a>
                                            {/*[06/15/2023 - PC_ZE_139_APP_4 - Johnprinto D]*/}
                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Work Order #", "WRK_ORDR_NUM")}><Trans>Work Order #</Trans></a>
                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Order Entered by", "EMPNAME")}><Trans>Order Entered By</Trans></a>
                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Pending Approver", "CURRENT_APPR_NAME")}><Trans>Pending Approver</Trans></a>
                                        </div>
                                    </div>
                                    {/*//[PC_3818_APP_02 - Johnprinto D]*/}
                                    <input type="text" className="form-control" id="searchTxt" placeholder={t("Search")} value={this.state.searchText} aria-label="Text input with dropdown button" style={{ 'padding-right': '50px' }} onKeyDown={this.keyPress} onChange={(e) => this.handleSearchTxt(e)} />
                                    {this.state.searchText.length > 0 ? <span class="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} > </span> : null}
                                    <span className="dropdown-search-icon" onClick={this.handleSearch} />
                                </div>
                                <div className="dropdown float-right">
                                    <a href="#" className="float-right filter-icon mt-1 mx-2" title="Advance Filter" onClick={(e) => this.filtertoggle(e)}>
                                        <img src="images/filter-icon.png" alt="Filter Icon" />
                                    </a>
                                    <div className={`dropdown-menu order-maintenance-filter m-0 ${this.state.isFilterOpen ? 'd-block' : ''}`} id="advanced-filter">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-12 p-0 pb-2 border-bottom mb-3">
                                                    <span className="filter-title pl-3"><Trans>Advanced Filter</Trans></span>
                                                    <button type="button" className="filter-close close pr-2 filter-btn" onClick={(e) => this.filtertoggle(e)}><span className="filter-close-icon" /></button>
                                                </div>
                                                {/*[4/22/22, CAS - 31, Dhevanesam R]*/}
                                                <div className="col-md-12">
                                                    <label className="catalog-label mb-0"><Trans>Created Date</Trans></label> </div>
                                                <div className="col-md-6 mb-4">
                                                    <label className="catalog-label mb-0 w-100"><Trans>From</Trans></label>
                                                    <DatePicker className="form-control datepicker"
                                                        id="startDateObj"
                                                        popperPlacement="bottom-end"
                                                        placeholderText="MM/DD/YYYY"
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        selected={this.state.startDateObj}
                                                        maxDate={this.state.endDateObj}
                                                        onChange={(e) => this.handleDatePicker(e, "startDateObj")}
                                                    />
                                                    <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#startDateObj`).focus()} />
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <label className="catalog-label mb-0 w-100"><Trans>To</Trans></label>
                                                    <DatePicker className="form-control datepicker"
                                                        popperPlacement="bottom-end"
                                                        id="endDateObj"
                                                        placeholderText="MM/DD/YYYY"
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        selected={this.state.endDateObj}
                                                        minDate={this.state.startDateObj}
                                                        onChange={(e) => this.handleDatePicker(e, "endDateObj")}

                                                    />
                                                    <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#endDateObj`).focus()} />
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="float-right">
                                                        <button className="btn btn-primary filter-clear-btn px-4 mr-2 mt-2 filter-btn" onClick={this.cancelFilter} ><Trans>Clear</Trans></button>
                                                        <button className="btn btn-primary custom-btn-primary primary-btn filter-btn wallet-btn mt-2 px-4" onClick={this.applyFilterclick}><Trans>Apply</Trans></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/*[7/21/22, CAS -2359, Madhu]*/}

                        <div className="col-lg-12">
                            <div className="table-responsive">
                                {this.state.isPageLoadedOnce && this.state.totalCount == 0 && isPostBack == true && this.state.ReqApprovalFromNotification == "" ? (<section className="site-undercontruction container text-center center-panel pt-4">
                                    <div className="img-section">
                                        <img src="images/nosearch.jpg" alt="imagefile" />
                                    </div>
                                    <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                    </p>
                                </section>) : (<table className="table ZEUS_Grid FOM-grid mobileflex-grid">
                                    <thead>
                                        <tr>
                                            <th className="position-sort-icon"><Trans>Order Number</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`ORDER_NO`, `ASC`)} ><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`ORDER_NO`, `DESC`)} /></span></th>
                                            {/*[06/15/2023 - PC_ZE_139_APP_5 - Johnprinto D]*/}
                                            <th className="position-sort-icon"><Trans>Work Order #</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`WRK_ORDR_NUM`, `ASC`)}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`WRK_ORDR_NUM`, `DESC`)} /></span></th>
                                            {/*[06/15/2023 - PC_ZE_139_APP_6 - Johnprinto D]*/}
                                            <th className="position-sort-icon"><Trans>Work Order Description</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`WO_DESC`, `ASC`)}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`WO_DESC`, `DESC`)} /></span></th>
                                            <th className="position-sort-icon"><Trans>Order Entered By</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`EMPNAME`, `ASC`)}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`EMPNAME`, `DESC`)} /></span></th>
                                            <th className="position-sort-icon"><Trans>Created Date</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`ADD_DTTM`, `ASC`)}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`ADD_DTTM`, `DESC`)} /></span></th>
                                            <th className="position-sort-icon"><Trans>Pending Approver</Trans><span className="sort-up ml-1" onClick={(e) => this.sortingClick(`CURRENT_APPR_NAME`, `ASC`)}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1"><img src="Images/sort1.svg" onClick={(e) => this.sortingClick(`CURRENT_APPR_NAME`, `DESC`)} /></span></th>
                                            <th className="position-sort-icon"><Trans>Status</Trans></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.isPageLoadedOnce && this.state.totalCount > 0 ? this.bindWaitingReqGrid() :
                                            (<tr className="border-0">
                                                <td colSpan="10" className="search-padding-none">
                                                    <section className="site-undercontruction container text-center center-panel pt-4">
                                                        <div className="img-section">
                                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                                        </div>
                                                        <p className="search-result-content pt-4"><Trans>Sorry, we couldn’t find any search result</Trans>
                                                        </p>
                                                    </section>
                                                </td>
                                            </tr>)}
                                    </tbody>
                                </table>)}
                            </div>
                        </div>
                        {this.state.totalCount > 0 ? <div className="col-md-12">
                            <span className="total-records mt-2 d-inline-block">
                                <Trans>Number of Records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
                            </span>
                            {this.state.totalCount > this.state.itemCount ? <ReactPaginate
                                previousLabel={<span className="right-pagination-arrow" />}
                                nextLabel={<span className="left-pagination-arrow" />}
                                previousClassName={'page-item'}
                                nextClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextLinkClassName={'page-link'}
                                disabledClassName={'disabled'}
                                breakLabel={'...'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                containerClassName={'custom-pagination pagination'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                activeClassName={'active'}
                                hrefBuilder={() => '#'}
                                forcePage={this.state.pageNo - 1}
                            /> : null}
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>);
    }

}
//[PC_07, 09/07/21, Author - Priyanka D]
export default withTranslation()(WaitingRequestorApprovalComponent);