import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import * as CookieService from '../CookieService';
import * as FavItemMainAction from '../actions/FavItemMainAction';
import FavItemMainStore from '../stores/FavItemMainStore';
import * as MenuAction from '../actions/MenuAction';
import * as $ from 'jquery';
import SDIConstants, { redirectToItemDetails } from '../SDIConstants';
import ReactPaginate from 'react-paginate';
import Autocomplete from 'react-autocomplete';
import swal from 'sweetalert';
import MultiSelect from "react-multi-select-component";
import Select from 'react-select';
//[PC_07, 09/07/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
let searchTxt = "";
let searchCol = "ALL";
let isPostBack = true;
let currentPagePath;
class FavItemMainComponent extends Component {
    constructor(props) {
        super(props);
        this.addedtoCart = this.addedtoCart.bind(this);
        this.AnykeyPress = this.AnykeyPress.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.InsertNewFavItem = this.InsertNewFavItem.bind(this);
        this.UpdateFavItembind = this.UpdateFavItembind.bind(this);
        this.DelFavItem = this.DelFavItem.bind(this);
        this.AssignNewFavItem = this.AssignNewFavItem.bind(this);
        this.ShareItemButtonClickResponse = this.ShareItemButtonClickResponse.bind(this);
        this.state = {
            GridArr: [], NewItem: [], yourCat: "", yourDes: "", Response: [], RemainItemId: "", filterDisplayName: "All", isFavorited: false,
            PageNo: 1,isPageLoad:false,
			RecCount: 10, totalCount: 0, pageCount: 0, itemQuantity: [], id: [], Supplier_ID: "", MFG: "", MFGPartNo: "",
            SortingType: "DESC",
            SortingColumn: "ADD_DTTM", searchDisplayName: "All", FromDate: "", ToDate: "", Site_Currency: "", category: [], bindCategory: [], subCategory: [], bindSubCategory: [], yourSubCat: "", categoryError: false, subCategoryError: false,
            UserDD: [], SelectedUser: [], RegionDD: [], SelectedRegion: [], EMPDD: [], GridCheckArr: [], searchTxt: ""
        }
    }
    componentDidMount() {
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        FavItemMainAction.getCurrentFavPage(getFavPageBO);
        isPostBack = true;
        searchTxt = "";
        searchCol = "ALL";
        this.favPageLoad("1", this.state.RecCount, searchTxt, searchCol, "ADD_DTTM", "DESC");
        FavItemMainStore.on('favItemload', this.AssignFavItemData.bind(this));
        FavItemMainStore.on('NewfavItem', this.AssignNewFavItem);
        FavItemMainStore.on('InsertfavItem', this.InsertNewFavItem);
        FavItemMainStore.on('DeleteItem', this.DelFavItem);
        FavItemMainStore.on('UpdateFavItem', this.UpdateFavItembind);
        FavItemMainStore.on('addedtoCart', this.addedtoCart);
        FavItemMainStore.on('favpage', this.loadFavPageResponse.bind(this));
        FavItemMainStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
        FavItemMainStore.on('ShareItem', this.ShareItemButtonClickResponse);
    }
    addedtoCart = () => {
        const { t } = this.props;
        //[6/1/2022]PS_FI_07 - Redirects to shop cart if fav item is added to cart else alert will be shown - Poornima S
        let addToCartResponse = JSON.parse(FavItemMainStore.PageLoadData);       
            let itemAddStatus = addToCartResponse[0].isAddedToCart;
            if (itemAddStatus == "True") {
                this.props.history.push({
                    pathname: "/shoppingcart"
                });
            }
            else {
                
               // alert(t("Invalid or the itemid already exists"));
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Invalid item ID")
                }).then(function () {
                    window.location.reload();
                });
                //window.location.reload();
            }           
    }
    componentWillUnmount() {
        FavItemMainStore.removeListener('favItemload', this.AssignFavItemData.bind(this));
        FavItemMainStore.removeListener('NewfavItem', this.AssignNewFavItem);
        FavItemMainStore.removeListener('InsertfavItem', this.InsertNewFavItem);
        FavItemMainStore.removeListener('DeleteItem', this.DelFavItem);
        FavItemMainStore.removeListener('UpdateFavItem', this.UpdateFavItembind);
        FavItemMainStore.removeListener('addedtoCart', this.addedtoCart);
        FavItemMainStore.removeListener('ShareItem', this.ShareItemButtonClickResponse);
    }
    //[7/12/2023, PS_ZE_142_APP_19, Dhevanesam R]
    favPageLoad = (pg, RecCount, searchText, searchColumn, sortColumn, sortValue) => {
        let favItemBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
            pageNo: pg,                
            itemCount: RecCount, searchText: searchText, searchColumn: searchColumn, sortColumn: sortColumn, sortValue: sortValue,
            Session_ACTIVEUSERS: CookieService.getLocalStorageValues("Session_ACTIVEUSERS") != "" && CookieService.getLocalStorageValues("Session_ACTIVEUSERS") != null && CookieService.getLocalStorageValues("Session_ACTIVEUSERS") != undefined ? CookieService.getLocalStorageValues("Session_ACTIVEUSERS") : "",        
            ThirdParty_CompanyID: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") != "" && CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") != null && CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") != undefined ? CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") : 0,  
            Session_HOMESTORE: CookieService.getLocalStorageValues("Session_HOMESTORE")


        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        FavItemMainAction.FavItemPageLoad(favItemBO);
    }

    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(FavItemMainStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        if (FavItemMainStore.updateFavPageResponse.updateType == 1) {

            let response = JSON.parse(FavItemMainStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide'); 
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }

        } else {
            let response = JSON.parse(FavItemMainStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide'); 
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
        isPostBack = false;
        //this.refreshMenu();
        MenuAction.refreshMasterData()
    };

    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        console.log("masterBO", masterBO);
        MenuAction.getMasterData(masterBO);
    };

    AssignFavItemData = () => {
        debugger;
        let PageLoadResponse = JSON.parse(FavItemMainStore.PageLoadData);

        PageLoadResponse.FavItemData.forEach((value, index) => {
            value.QTY_REQUESTED = "";
            value.Gridchecked = false;
        });
        let SitecurrencyTable = PageLoadResponse.siteCurrencyTable;
        let Site_CurrencyValue = ""
        if (SitecurrencyTable != null && SitecurrencyTable != undefined) {
            if (SitecurrencyTable.length > 0) {
                Site_CurrencyValue = SitecurrencyTable[0].Symbol
            }
        }

        
        let count = PageLoadResponse.TotalCount[0].TOTAL_COUNT;
        let pg = Math.ceil((count > 0 ? count : 0) / this.state.RecCount);
        let allCategory = []
        let categories = CookieService.getLocalStorageValues("FavItem_Cat")
        if (categories != null && categories != "" && categories != undefined) {
            categories.map((val, index) => {
                allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
            });
            allCategory = allCategory.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }
        debugger;
        let subCategory = []
        let allSubCategory = []
        let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
        if (subCategories != null && subCategories != "" && subCategories != undefined) {
            subCategories.map((val, index) => {
                if (val.ISA_FAVS_SUBCATEGORY != null) {
                    subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                }
            });
        }
        if (subCategory.length > 0) {
            allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
            allSubCategory = allSubCategory.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }
        let locEMP = []
        let locREGDD = []
        let locReg = []
        if (PageLoadResponse.EmployeeCC != undefined && PageLoadResponse.EmployeeCC != null) {

            if (PageLoadResponse.EmployeeCC.length > 0) {
                PageLoadResponse.EmployeeCC.map((val, index) => {
                    if (val.EMP_ID != CookieService.getLocalStorageValues("Session_UserID")) {
                        let localArray = {
                            value: val.EMP_ID,
                            label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
                        }

                        locEMP.push(localArray)
                    }

                });
                locReg = PageLoadResponse.EmployeeCC.filter((v, i, a) => a.findIndex(t => (t.REGION === v.REGION && t.REGION != null && t.REGION != " ")) === i)
                locReg.map((val, index) => {
                    let localArray = {
                        value: val.REGION,
                        label: val.REGION
                    }

                    locREGDD.push(localArray)
                });

            }

        }
        debugger
        let GridcheckedData = this.state.GridCheckArr
        if (GridcheckedData.length > 0) {

            GridcheckedData.forEach((Checkvalue, index) => {

                PageLoadResponse.FavItemData.forEach((value, index) => {

                    if (value.ISA_CP_ITEM_ID === Checkvalue.ISA_CP_ITEM_ID && value.MANUFACTURER == Checkvalue.MANUFACTURER && value.CLIENT_MFG_PART == Checkvalue.CLIENT_MFG_PART) {
                        value.Gridchecked = true;
                    }
                });
            });
        }
        //[7/12/2023, PS_ZE_142_APP_20, Dhevanesam R]
        MenuAction.refreshMasterData()
        console.log("sub categories", allSubCategory);
        this.setState({ GridArr: PageLoadResponse.FavItemData, totalCount: PageLoadResponse.TotalCount[0].TOTAL_COUNT, isPageLoad: true, Site_Currency: Site_CurrencyValue, pageCount: pg, totalCount: count, category: allCategory, bindCategory: allCategory, categoryError: false, subCategoryError: false, subCategory: allSubCategory, bindSubCategory: [], UserDD: locEMP, RegionDD: locREGDD, EMPDD: PageLoadResponse.EmployeeCC });

        
    }

    AssignNewFavItem = () => {
        const { t } = this.props;
        let PageLoadResponse = JSON.parse(FavItemMainStore.PageLoadData);
        let New = [];
        debugger;
        New = PageLoadResponse.ResultFavDetail;
        if (PageLoadResponse.errorT) {
            $('#savebuttonid').hide();
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Invalid item id")
            });
            //alert(t("Invalid item id"));
            return;
        }
        if (New) {
            let allCategory = [];
            let categories = CookieService.getLocalStorageValues("FavItem_Cat")
            if (categories != null && categories != "" && categories != undefined) {
                categories.map((val, index) => {
                    allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
                });
                allCategory = allCategory.sort((a, b) =>
                    a.value > b.value ? 1 : -1,
                );
            }
            debugger;
            let subCategory = []
            let allSubCategory = []
            let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
            if (subCategories != null && subCategories != "" && subCategories != undefined) {
                subCategories.map((val, index) => {
                    if (val.ISA_FAVS_SUBCATEGORY != null) {
                        subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                    }
                });
            }
            if (subCategory.length > 0) {
                allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
                allSubCategory = allSubCategory.sort((a, b) =>
                    a.value > b.value ? 1 : -1,
                );
            }
            console.log("sub categories", allSubCategory);

            this.setState({ NewItem: PageLoadResponse.ResultFavDetail, yourCat: "", yourSubCat: "", categoryError: false, subCategoryError: false, category: allCategory, bindCategory: allCategory,subCategory: allSubCategory, bindSubCategory: []});
            console.log("NewItemArr", PageLoadResponse.ResultFavDetail);
            
        }
        
        
    }

    InsertNewFavItem = () => {
        //[11/30/21, PC_FIMT_01, CAS - 31], Poornima S//Added translation keywords for alerts, error messages
        const { t } = this.props;
        let PageLoadResponse = JSON.parse(FavItemMainStore.PageLoadData);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        console.log("PageLoadResponse", PageLoadResponse)
        let Favstatus = PageLoadResponse.ErrorMsg
        if (Favstatus.length > 0) {
            if (Favstatus[0].Error_Msg == "Favorite item inserted sucessfully") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Favorite item inserted successfully")
                });
                //alert(t("Favorite item inserted sucessfully"));
                //window.location.reload();
                MenuAction.refreshMasterData();
                searchTxt = "";
                searchCol = "ALL";
                this.favPageLoad("1", this.state.RecCount, searchTxt, searchCol, "ADD_DTTM", "DESC");
            }
            else if (Favstatus[0].Error_Msg == "Invalid or the itemid already exists")
            {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Invalid or the itemid already exists")
                });
                //alert(t("Invalid or the itemid already exists"))
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Technical Issue Try again")
                });
                //alert(t("Technical Issue Try again"))
            }
        }
        isPostBack = false;
        this.setState({ Response: PageLoadResponse.ErrorMsg });
        
    }

	DelFavItem = () => {
		const { t } = this.props;
		let PageLoadResponse = JSON.parse(FavItemMainStore.PageLoadData);
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		//alert(t("Deleted Successfully"));
		if (PageLoadResponse.ErrorTable[0].error != "True") {
			if (PageLoadResponse.ResultFlag[0].DeleteResult == "True") {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Deleted Successfully")
				})
				// window.location.reload();
				this.setState({ Response: PageLoadResponse.ResultFlag });

				this.setState({ RemainItemId: "" });
				let srtxt = ""
				if (searchTxt != "") {
                    //[09/08/2023] PS_SR_Bug_4183_APP_02 - 4183 - Bug fix - Johnprinto 
                    srtxt = $(`#searchTxt`).val().trim().toLowerCase();
				}

				this.favPageLoad(this.state.PageNo, this.state.RecCount, srtxt, searchCol, this.state.SortingColumn, this.state.SortingType);
				MenuAction.refreshMasterData();
				isPostBack = false;
			}
			else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Technical Issue Try again")
				});
			}
		}
		else {
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Technical Issue Try again")
			});
		}

	}

    UpdateFavItembind = () => {
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let PageLoadResponse = JSON.parse(FavItemMainStore.PageLoadData);
       // this.setState({ Response: PageLoadResponse.ResultFlag });
        debugger;
        console.log("PageLoadResponse", PageLoadResponse[0].updateResult);
        MenuAction.refreshMasterData();
        if (PageLoadResponse) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Updated Successfully")
            });
            //alert(t("Updated Successfully"));
        }
        this.setState({ RemainItemId: "" });
        
        this.favPageLoad(this.state.PageNo, this.state.RecCount, searchTxt, searchCol, this.state.SortingColumn, this.state.SortingType);
       
        isPostBack = false;
    }


    handlePageClick = (data) => {
        let pageNo = data.selected + 1;
        
       
        //$('.accordion-arrow').removeClass('accordion-arrow-change');
        //$('.hiddenRow').hide();
        //$('tr').removeClass('tr-border');
        this.setState({ PageNo: pageNo });

        //searchTxt = $(`#searchTxt`).val().trim();


       
        this.favPageLoad(
            pageNo, this.state.RecCount,
            searchTxt, searchCol,
            this.state.SortingColumn, this.state.SortingType);

        
        debugger;
        isPostBack = false;
    };

    AddNewFav = (value)=>{
        $('#add-fav-popup').modal('show');
        $('#NewItemId').val('');
        $('#savebuttonid').hide();
        this.setState({ yourCat: "", yourDes: "", yourSubCat: "", categoryError: false, subCategoryError:false, NewItem: [] });
    }

    NewItemClick = () => {
        const { t } = this.props;
        let newItemId = $('#NewItemId').val().toUpperCase();
        console.log("newItemId", newItemId);
        if ($('#NewItemId').val()!="") {
            this.setState({ NewItem: [] });
            let newItemIdBO = {
                ItemId: newItemId,
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")

            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            FavItemMainAction.NewFavItem(newItemIdBO);
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please Enter Item Id")
            })
           // alert(t("Please enter Item Id"));
        }

    }

    InsertFavItem = () => {
        const { t } = this.props;
        let FavCatg = this.state.yourCat;
        let FavSubCatg = this.state.yourSubCat;
        debugger;
        if ($('#NewItemId').val() != "") {
            if (FavCatg != null && FavCatg != undefined && FavSubCatg != null && FavSubCatg != undefined) {
                if (FavCatg.trim() != "" && FavSubCatg.trim() != "") {
                    let newItemId = $('#NewItemId').val().trim();
                    let InsertFavItemBO = {
                        ItemId: newItemId,
                        YourCat: FavCatg.toUpperCase(),
                        YourSubCat: FavSubCatg.toUpperCase(),
                        YourDesc: this.state.yourDes.toUpperCase(),
                        Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                        Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                        Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                        Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                        Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                        Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                        Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                        Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                        Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                        Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                        Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                        Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                        Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                        Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
                        Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                        Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                        SDI_Catagory: $('#sdiCategory').text(),
                        txtMfgItemidHide: this.state.NewItem[0].manfPartNumber,
                        Manufacturer: this.state.NewItem[0].manufacturer != undefined && this.state.NewItem[0].manufacturer != null ? this.state.NewItem[0].manufacturer:"",//[06/29/2023, Zeus-94]- Kishore S - Checking supplier ID while adding to fav
                        Supplier_ID: this.state.NewItem[0].vendor_id != undefined && this.state.NewItem[0].vendor_id != null ? this.state.NewItem[0].vendor_id :""  ,
                        SAP: this.state.NewItem[0].customer_part_number_woprefix != "\N" && this.state.NewItem[0].customer_part_number_woprefix.trim() != "" && this.state.NewItem[0].customer_part_number_woprefix.trim() != null && this.state.NewItem[0].customer_part_number_woprefix.trim() != undefined ? this.state.NewItem[0].customer_part_number_woprefix : " ",
                        Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                        Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")
                    }
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    $('#add-fav-popup').modal('hide');
                    FavItemMainAction.InsertFav(InsertFavItemBO);
                } else {
                    this.setState({ categoryError: FavCatg.trim() == "" ? true : false, subCategoryError: FavSubCatg.trim() == "" ? true : false });
                }
            }
            else {
                this.setState({ categoryError: FavCatg == null || FavCatg == undefined ? true : false, subCategoryError: FavSubCatg == null || FavSubCatg == undefined ? true : false });
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please enter Item Id")
            })
                //alert(t("Please enter Item Id"));
        }
    }

    DeleteFav = (e, value) => {
        e.preventDefault();
        $('#Cancel-confirmation').modal('show');
		this.setState({ RemainItemId: value.ISA_CP_ITEM_ID, Supplier_ID: value.SUPPLIER_ID, MFG: value.MANUFACTURER, MFGPartNo: value.CLIENT_MFG_PART });
        console.log("value.ISA_CP_ITEM_ID ", value.ISA_CP_ITEM_ID );
    }

    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Favorite Item Maintanance",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            FavItemMainAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            FavItemMainAction.deleteFavPage(deleteFavPageBO)
        }
    };


    ConfirmationNO = () => {
        this.setState({ RemainItemId: "" });
        console.log("RemainItemId", this.state.RemainItemId);
        
    }

    ConfirmationYES = () => {

        let deleteFavBO = {
            ItemId: this.state.RemainItemId,
			Supplier_ID: this.state.Supplier_ID,
			MFG: this.state.MFG,
			MFGPartNo: this.state.MFGPartNo,
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        FavItemMainAction.DeletFavItem(deleteFavBO);
    }

    EditFav = (e, value) => {
        debugger;
        let allCategory = []
        let categories = CookieService.getLocalStorageValues("FavItem_Cat")
        if (categories != null && categories != "" && categories != undefined) {
            categories.map((val, index) => {
                allCategory.push({ label: val.ISA_FAVS_CATEGORY, value: val.ISA_FAVS_CATEGORY });
            });
            allCategory = allCategory.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }
        debugger;
        let subCategory = []
        let allSubCategory = []
        let subCategories = CookieService.getLocalStorageValues("FavItem_SubCat")
        if (subCategories != null && subCategories != "" && subCategories != undefined) {
            subCategories.map((val, index) => {
                if (val.ISA_FAVS_SUBCATEGORY != null) {
                    subCategory.push({ label: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), value: val.ISA_FAVS_SUBCATEGORY.toUpperCase(), category: val.ISA_FAVS_CATEGORY.toUpperCase() });
                }
            });
        }
        if (subCategory.length > 0) {
            allSubCategory = subCategory.filter((v, i, a) => a.findIndex(t => (t.label === v.label && t.category === v.category && t.label != null && t.label != " ")) === i)
            allSubCategory = allSubCategory.sort((a, b) =>
                a.value > b.value ? 1 : -1,
            );
        }
        console.log("bind sub category", this.state.bindSubCategory);
        e.preventDefault();
        $('#edit-fav-popup').modal('show');
		this.setState({ RemainItemId: value.ISA_CP_ITEM_ID, yourCat: value.ISA_FAVS_CATEGORY, yourSubCat: value.ISA_FAVS_SUBCATEGORY != " " ? value.ISA_FAVS_SUBCATEGORY : "UNCALSSIFIED", yourDes: value.ISA_CP_FAV_ALT_DSC, category: allCategory, bindCategory: allCategory, categoryError: false, subCategoryError: false, subCategory: allSubCategory, bindSubCategory: [], Supplier_ID: value.SUPPLIER_ID, MFG: value.MANUFACTURER, MFGPartNo: value.CLIENT_MFG_PART });
        //console.log("value.ISA_CP_ITEM_ID ", value.ISA_CP_ITEM_ID);
    }

    updatFavItem = (e, value) => {
        e.preventDefault();
        let Des = " ";
        let cat = this.state.yourCat;
        let subCat = this.state.yourSubCat;
            
        if (this.state.yourDes != "") {
            Des = this.state.yourDes;
        }
        if (cat != null && cat != undefined && subCat != null && subCat != undefined) {
            if (cat.trim() != "" && subCat.trim() != "") {
                let UpdateFavBO = {
                    ItemId: this.state.RemainItemId,
                    yourCat: cat.toUpperCase(),
                    yourSubCat: subCat.toUpperCase(),
                    yourDec: Des,
					Supplier_ID: this.state.Supplier_ID,
					MFG: this.state.MFG,
					MFGPartNo: this.state.MFGPartNo,
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                }
                $('#edit-fav-popup').modal('hide');
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                FavItemMainAction.UpdateFavItem(UpdateFavBO);
            }
            else {
                this.setState({ categoryError: cat.trim() == "" ? true : false, subCategoryError: subCat.trim() == "" ? true : false });
            }
        }
        else {
            this.setState({ categoryError: cat == null || cat == undefined ? true : false, subCategoryError: subCat == null || subCat == undefined ? true : false });
        }

    }

    Addtocart = (e, value) => {
        const { t } = this.props;
        debugger;
        e.preventDefault();
        let itemid = value.ISA_CP_ITEM_ID;
        //let qty = this.state.qty;
        //let itemsSelected = this.state.GridArr.filter((item) => item.ISA_CP_ITEM_ID == value.ISA_CP_ITEM_ID);
        //console.log("itemsee", itemsSelected);
        //let itemsToAdd = [];
        //let quty = "";
        //if (this.state.id == value.ISA_CP_ITEM_ID) {
        //    quty = this.state.itemQuantity;
        //};
        //if (!quty) {
        //    alert("Please enter quanity for the selected line items");
        //    return false;
        //};
        
        //itemsSelected.forEach((value) => {
        //    itemsToAdd.push({
        //        ISA_CP_ITEM_ID: value.ISA_CP_ITEM_ID,
        //        Quantity: quty,

        //    });

        //});
        //[04/11/22, CAS - 31, Dhevanesam R]
        let filteredArray = this.state.GridArr.filter((f) => f.QTY_REQUESTED != "");
        if (filteredArray.length == 0) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please enter quantity")
            })
            //alert(t("Please enter quantity"));
            return;
        }
        let lineItemsID = [];
            filteredArray.forEach((eachfilter) => {
                lineItemsID.push({
                    ISA_CP_ITEM_ID: eachfilter.ISA_CP_ITEM_ID,
                    Quantity: eachfilter.QTY_REQUESTED,
                    //[6/1/2022]PS_FI_08 - Added MFG & MFG Part num objects to compare fav item with SOLR search results - Poornima S
                    MFG: eachfilter.MANUFACTURER,
                    MFGPartnum: eachfilter.CLIENT_MFG_PART,
                    Supplier_ID: eachfilter.SUPPLIER_ID //[06/29/2023, Zeus-94]- Kishore S - Checking supplier ID while adding to cart in favmain
                });
        });
        console.log("id", lineItemsID);
        let AddtoCartBO = {
            //ItemId: itemid,
            LineItems: lineItemsID,
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            Session_ZEUSNOCATALOGSITE: CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE"),
            Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"), //[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search           
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
            Session_ID: CookieService.getLocalStorageValues("Session_ID"),
            Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "

        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        FavItemMainAction.AddToCartFavItem(AddtoCartBO);


    };

    handleChange = (event) => {
        debugger;
        this.setState({ [event.target.name]: event.target.value });
        console.log("name", event.target.value);
    };


    filterClick = (event, displayName, columnName) => {
        event.preventDefault();
        this.setState({ filterDisplayName: displayName, searchCol: columnName });
        console.log("searchColumn", columnName);
        searchCol= columnName;
    }

    handleSearch = () => {
        let pageNo = 1;
      
        let sortColumn = "ISA_CP_ITEM_ID";
        let sortType = "ASC";
        //searchTxt = $(`#searchTxt`).val().trim();
        searchTxt = $(`#searchTxt`).val().trim().toLowerCase();
        console.log("SEARCHTXT", searchTxt);
        isPostBack = false;
        this.favPageLoad(pageNo,
            this.state.RecCount,
            
            searchTxt, searchCol,
            sortColumn,
            sortType
        );
        debugger;
        console.log("search", this.state.searchCol);
        this.setState({ PageNo: pageNo});
        
    }

    ClearSearch = () => {
        this.setState({ searchTxt: "" })
    }
    SearchTxtChange = (e) => {
        this.setState({ searchTxt: e.target.value })
    }

   RedirectTo = (event, pageName) => {
        let queryParamindex = pageName.indexOf("?");
        let pagePath = pageName;
        let queryParams = "";
        if (queryParamindex > 0) {
            pagePath = pageName.substring(0, queryParamindex);
            queryParams = pageName.substring(queryParamindex, pageName.length);
        };

        event.preventDefault();
        this.props.history.push({
            pathname: pagePath,
            search: queryParams
        });
    };


    Sorting = (sortType, sortColumn) => {
        let sc
        //if (this.state.isSearch) {

        //    sc = $(`#searchTxt`).val().trim()

        //}
        //if (this.state.searchTrTxt !== "") {

        //}
        //let isArchi = "N";
        //if (isArchieved == true) {
        //    isArchi = "Y"
        //}
        //this.setState({ sSearchCriteria: sc });
        let pageNo = 1;
        let searchcol = "";
        isPostBack = false;
        let searchtxt = $(`#searchTxt`).val().trim();
        if (searchtxt != "") {
            searchcol = searchCol;
        }
        
        this.favPageLoad(
            pageNo, this.state.RecCount,
            searchtxt, searchcol,
            sortColumn, sortType);

        this.setState({ pageNo: pageNo, SortingColumn: sortColumn, SortingType: sortType });
    }
    onquantityChange = (e,val,index) => {
        e.preventDefault();
        let value = val.ISA_CP_ITEM_ID;
        let itemsToAdd = [];
        let i = $(`#itemQuantity${index + 1}`).val().trim();
        const re = SDIConstants.quantityRegEX;
        let { itemQuantity } = this.state;
        debugger;
        if (e.target.value === '' || re.test(e.target.value)) {
            //[6/12/2023, Bug - 3707 & 3683, Dhevanesam R]
            if (Math.round(e.target.value) != "0" || !e.target.value) {
                let { GridArr } = this.state;
                GridArr[index].QTY_REQUESTED = e.target.value;
                
                let { id } = this.state;
                id.push({
                    ISA_CP_ITEM_ID: value,
                    Quantity: i,

                });
               // this.setState({ id: id });
                  //this.setState({ itemQuantity: i, id: value });
                //this.state.id.push({
                //    ISA_CP_ITEM_ID: value,
                //    Quantity: i,

                //});
                this.setState({ itemQuantity: i, id: id, GridArr: GridArr });
                console.log("itemsToAdd", itemsToAdd);
                console.log("itemQuantity", (e.target.value));
                console.log("id", this.state.id);
            }
            //this.setState({ itemQuantity});
        }
        //else {
        //    //alert("not valid quantity");
        //    //document.getElementById(`#itemQuantity${index + 1}`).innerText = "2";
        //    //$(`#itemQuantity${index + 1}`).val('');
        //    //this.setState({ itemQuantity: 2 });
        //}
    }
    keyPress(e) {
        const { t } = this.props;
        if (e.keyCode == 13) {
            debugger;
            if ($("#NewItemId").val() != "") {
                this.NewItemClick();
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Please enter Item Id")
                })
                //alert(t("Please enter Item Id"));
            }

        }
    }


    AnykeyPress(e) {
        if (e.keyCode == 13) {
            debugger;         
            
            if ($("#searchTxt").val() != "") {
                this.handleSearch();
            }
            else {
                this.handleSearch();
            }
           
        }
    }
    //handleSTrTxt = (event) => {
    //    //this.setState({ searchC: [event.traget.value] });
    //    let item = $('#NewItemID').val();
    //    searchTrCriteria = $('#SearchTrTxt').val();
    //    //debugger;
    //}


    bindNewFavItemDetail = () => {
        debugger;
        //{ value.CategoyID }
        console.log("newitem", this.state.NewItem[0]);
        if (this.state.NewItem.length > 0) {
            $('#savebuttonid').show();
            //return this.state.NewItem.map((value, index) => {
                //console.log("newitem", value.customerPartnumber_CatalogZero);
                console.log("newitem", $('#NewItemId').val());
            //if (this.state.NewItem[0].customerPartnumber_CatalogZero == $('#NewItemId').val()) {
            //[04/08/22, CAS - 31, Dhevanesam R]
                    return (
                        <>
                            {/*<div className="row mb-3">
                                <label className="col-md-5 quick-label"><Trans>SDI Category</Trans></label>
                                <label className="col-md-7 quick-value" id="sdiCategory">{!this.state.NewItem[0].UNSPSCCategory.trim() ? "Unclassified" : this.state.NewItem[0].UNSPSCCategory}</label>
                            </div>*/}
                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Category</Trans></label>
                                {/*<div className="col-md-7">
                                                <textarea className="form-control custom-textarea" id="FavYourCatg" maxLength="40" rows={3} defaultValue={''} />
                                            </div>*/}
                                <div className="float-left search-autocomplete col-md-7 home-search" style={{ zIndex: "9999" }}>
                                    <Autocomplete
                                        getItemValue={this.getItemValue}
                                        items={this.state.bindCategory}
                                        renderItem={this.renderItem}
                                        value={this.state.yourCat}
                                        onChange={e => this.onChange(e, "CAT")}
                                        onSelect={e => this.onSelect(e, "CAT")}
                                        inputProps={{ placeholder: "Search", className: "form-control float-left" }}
                                        maxLength="40"
                                    />
                                    <span className="errorspan1" style={{ fontSize: '10px' }} id="favordcatid" />
									{this.state.categoryError ? <span className=" W-100 errorspan mr-3" style={{ fontSize: '15px' }}>Enter category</span> : null}
                                </div>
                            </div>
                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Sub category</Trans></label>

                                <div className="float-left search-autocomplete col-md-7 home-search">
                                    <Autocomplete
                                        getItemValue={this.getItemValue}
                                        items={this.state.bindSubCategory}
                                        renderItem={this.renderItem}
                                        value={this.state.yourSubCat}
                                        onChange={e => this.onChange(e, "SUBCAT")}
                                        onSelect={e => this.onSelect(e, "SUBCAT")}
										inputProps={{ placeholder: "Search", className: "form-control float-left", disabled: this.state.yourCat.length > 0 ? false : true }}
                                        maxLength="40"
                                    />
                                    <span className="errorspan1" style={{ fontSize: '10px' }} id="favordsubcatid" />
									{this.state.subCategoryError ? <span className=" W-100 errorspan1 text-left " style={{ fontSize: '15px' }}>Enter sub category</span> : null}
                                </div>
                            </div>

                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><Trans>Item description</Trans></label>
                                <label className="col-md-7 quick-value">{this.state.NewItem[0].Description == "" ? "-" : this.state.NewItem[0].Description}</label>
                            </div>
                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><Trans>Your description</Trans></label>
                                <div className="col-md-7">
                                    {/* <input type className="form-control custom-textarea " rows={3} value={this.state.yourDes} />*/}
                                    <textarea className="form-control custom-textarea" rows={3} name="yourDes" maxLength="100" value={this.state.yourDes} onChange={this.handleChange}></textarea>

                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-md-5 quick-label">MFG</label>
                                <label className="col-md-7 quick-value">{this.state.NewItem[0].manufacturer}</label>
                            </div>
                            <div className="row mb-3">
                                {/*//[2/18/22, CAS - 31, Dhevanesam R]*/}
                                <label className="col-md-5 quick-label"><Trans>Supplier Part Number</Trans> : </label>
                                <label className="col-md-7 quick-value">{this.state.NewItem[0].clientPartNumber}</label>
                            </div>
                            
                            
                        </>);
                //}
            //})
        }
    }
    getItemValue(item) {
        return `${item.value}`;
    }
    renderItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );

    }
    onSelect(val, name) {
        debugger;
        let result = [];
        if (name == "CAT") {
            if (val != "") {
                this.state.subCategory.map((x, i) => {
                    if (val.toUpperCase() == x.category) {
                        result.push({ label: x.value, value: x.value })
                    }
                }
                )
            }
            this.setState({
				yourCat: val, bindSubCategory: result, categoryError: false, yourSubCat: val != this.state.yourCat ? "" : this.state.yourSubCat
            });
        }
        else {
            this.setState({
                yourSubCat: val, subCategoryError: false
            });
        }
    }
    onChange = (e, name) => {
        debugger;
        let val = e.target.value.toUpperCase()
        let len = e.target.value
        let category = [];
        let subCategory = [];
        let re = /^[a-zA-Z0-9_-\s\x20 &, :;=?@#|'<>.{}"*()%!`%^$+~[/\]\\]+$/;
        if (val === '' || re.test(val)) {
            if (name == "CAT") {
                if (e.target.value == "") {
                    this.setState({
						yourCat: e.target.value, bindCategory: this.state.category, bindSubCategory: [], yourSubCat: ""
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.category != undefined && this.state.category != null) {
                        if (this.state.category.length != 0) {
                            category = this.state.category.filter(name => name.label.toUpperCase().includes(val))

                        }
                    }
                    if (e.target.value != "") {
                        this.state.subCategory.map((x, i) => {
                            if (e.target.value.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
						yourCat: e.target.value, bindCategory: category, bindSubCategory: subCategory, categoryError: false, yourSubCat: ""
                    });
                }
            }
            else {
                if (e.target.value == "") {
                    if (this.state.yourCat != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.yourCat.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        })
                    }
                    this.setState({
                        yourSubCat: e.target.value, bindSubCategory: subCategory
                    });
                }
                else if (len.length <= 40) {
                    if (this.state.yourCat != "") {
                        this.state.subCategory.map((x, i) => {
                            if (this.state.yourCat.toUpperCase() == x.category) {
                                subCategory.push({ label: x.value, value: x.value })
                            }
                        }
                        )
                        subCategory = subCategory.filter(name => name.label.toUpperCase().includes(val))
                    }
                    this.setState({
                        yourSubCat: e.target.value, bindSubCategory: subCategory, subCategoryError: false
                    });
                }
                else if (this.state.yourCat == "" && e.target.value == "") {
                    this.setState({
                        yourSubCat: e.target.value, bindSubCategory: this.state.subCategory
                    });
                }
            }
        }
    }

    EditFavItemBind = () => {
        debugger;
        let ShiptoValue = this.state.GridArr.filter((item, index) => item.ISA_CP_ITEM_ID == this.state.RemainItemId && item.SUPPLIER_ID == this.state.Supplier_ID);
        //defaultValue={Item.ISA_FAVS_CATEGORY} 
        //defaultValue={Item.ISA_CP_FAV_ALT_DSC}
        if (ShiptoValue) 
        return ShiptoValue.map((Item, index) => {
            console.log("desc", Item.ISA_CP_FAV_ALT_DSC);
            return (
                <div className="modal-body" key={Item.ISA_CP_ITEM_ID}>
                    <div className="row mt-3">
                        <div className="col-12 edit-desc-field mb-4">
                            <div className="row mb-3">
                                <label className="col-md-5 quick-label"><Trans>Item ID</Trans></label>
                                <div className="col-md-7 quick-value">
                                    {Item.CLIENT_MFG_PART}
                    </div>
                            </div>
                            {/*[11/18/21, PC_FI_02, Bug - 1799, Dhevanesam R]*/}
                            {/*<div className="row mb-3">
                                <label className="col-md-5 quick-label"><Trans>SDI Category</Trans></label>
                                <label className="col-md-7 quick-value"> {Item.ITEM_CATEGORY == "" || Item.ITEM_CATEGORY == " " || Item.ITEM_CATEGORY == null || Item.ITEM_CATEGORY == undefined ? "Unclassified" : Item.ITEM_CATEGORY}</label>
                            </div>*/}
                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Category</Trans></label>
                                {/*<div className="col-md-7">
                                                <textarea className="form-control custom-textarea" id="FavYourCatg" maxLength="40" rows={3} defaultValue={''} />
                                            </div>*/}
                                <div className="float-left search-autocomplete col-md-7 home-search" style={{ zIndex: "9999" }}>
                                    <Autocomplete
                                        getItemValue={this.getItemValue}
                                        items={this.state.bindCategory}
                                        renderItem={this.renderItem}
                                        value={this.state.yourCat}
                                        onChange={e => this.onChange(e, "CAT")}
                                        onSelect={e => this.onSelect(e, "CAT")}
                                        inputProps={{ placeholder: "Search", className: "form-control float-left" }}
                                        maxLength="40"
                                    />
                                    <span className="errorspan1" style={{ fontSize: '10px' }} id="favordcatid" />
									{this.state.categoryError ? <span className=" W-100 errorspan mr-3" style={{ fontSize: '15px' }}>Enter category</span> : null}
                                </div>
                            </div>
                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><span style={{ "color": "red" }}>*</span><Trans>Sub category</Trans></label>

                                <div className="float-left search-autocomplete col-md-7 home-search">
                                    <Autocomplete
                                        getItemValue={this.getItemValue}
                                        items={this.state.bindSubCategory}
                                        renderItem={this.renderItem}
                                        value={this.state.yourSubCat}
                                        onChange={e => this.onChange(e, "SUBCAT")}
                                        onSelect={e => this.onSelect(e, "SUBCAT")}
										inputProps={{ placeholder: "Search", className: "form-control float-left", disabled: this.state.yourCat.length > 0 ? false : true }}
                                        maxLength="40"
                                    />
                                    <span className="errorspan1" style={{ fontSize: '10px' }} id="favordsubcatid" />
									{this.state.subCategoryError ? <span className=" W-100 errorspan1 text-left " style={{ fontSize: '15px' }}>Enter sub category</span> : null}
                                </div>
                            </div>

                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><Trans>Item description</Trans></label>
                                <label className="col-md-7 quick-value">{Item.ITEM_DESC}</label>
                            </div>
                            <div className="row mb-3">
								<label className="col-md-5 quick-label"><Trans>Your description</Trans></label>
                                <div className="col-md-7 quick-value">
                                    <textarea className="form-control custom-textarea" rows={3} name="yourDes" maxLength="100" value={this.state.yourDes} onChange={this.handleChange}></textarea>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-md-5 quick-label">MFG</label>
                                <label className="col-md-7 quick-value">{Item.MANUFACTURER}</label>
                            </div>
                            <div className="row mb-3">
                                {/*//[2/18/22, CAS - 31, Dhevanesam R]*/}
                                <label className="col-md-5 quick-label"><Trans>Supplier Part Number</Trans></label>
                                <label className="col-md-7 quick-value">{Item.ISA_CP_ITEM_ID}</label>
                            </div>
                        </div>
                        <div className="col-md-5" />
                        <div className="col-md-7 mb-2">
                            <button className="btn btn-primary custom-btn-primary primary-btn px-4 "  onClick={(e)=>this.updatFavItem(e,Item)}><Trans>Save</Trans></button>
                        </div>
                    </div>
                </div>
            );
        })
    }
    //[7/10/2023, PS_ZE_142_APP_01 - 17, Dhevanesam R]
    selectFavItemShareOnChange = (val) => {
        debugger
        let { GridArr } = this.state;
        let locGridCheck = this.state.GridCheckArr;
        let filtarray;
        if (locGridCheck.length > 0) {
			filtarray = locGridCheck.findIndex(t => (t.ISA_CP_ITEM_ID === val.ISA_CP_ITEM_ID && t.MANUFACTURER == val.MANUFACTURER && t.CLIENT_MFG_PART == val.CLIENT_MFG_PART && t.SUPPLIER_ID == val.SUPPLIER_ID));
        }
		let I = GridArr.findIndex(t => (t.ISA_CP_ITEM_ID === val.ISA_CP_ITEM_ID && t.MANUFACTURER == val.MANUFACTURER && t.CLIENT_MFG_PART == val.CLIENT_MFG_PART && t.SUPPLIER_ID == val.SUPPLIER_ID))
        GridArr[I].Gridchecked = GridArr[I].Gridchecked == true ? false : GridArr[I].Gridchecked == false ? true : true;
        if (GridArr[I].Gridchecked == true) {
            if (filtarray == -1 || filtarray == undefined) {
                locGridCheck.push(GridArr[I]);
            }
        }
        else {
            locGridCheck.splice(filtarray, 1)
        }
        if (locGridCheck.length > 0) {

            locGridCheck = locGridCheck.filter((selected) => selected.Gridchecked == true);
        }
        this.setState({ GridCheckArr: locGridCheck });
        this.setState(GridArr);
    }
    Fav_Item_Share_Close = () => {
        debugger
        let locEMP = [];
        if(this.state.EMPDD.length > 0) {
            this.state.EMPDD.forEach((val, index) => {
                if (val.EMP_ID != CookieService.getLocalStorageValues("Session_UserID")) {
                    let localArray = {
                        value: val.EMP_ID,
                        label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
                    }

                    locEMP.push(localArray)
                }
            });
        }
        document.getElementById('usererror').innerHTML = ""
        this.setState({ SelectedRegion: [], SelectedUser: [], UserDD: locEMP });
    }

    ShareMultipleItems = () => {
        debugger
        const { t } = this.props;
        let filteritem = this.state.GridCheckArr.filter((x) => x.Gridchecked == true)
        if (filteritem.length > 0) {
            if (this.state.UserDD.length > 0) {
                $('#Fav_Item_Sharing').modal('show');
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Users are not available to share")
                });
            }
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please select an item to share")
            });
        }

    }

    HandleOnChange = (event, name) => {
        debugger
        switch (name) {
            case "SelectedRegion": {
                let emp = []
                if (event != null) {
                    if (this.state.EMPDD.length > 0) {
                        this.state.EMPDD.map((val, index) => {
                            if (event.value == val.REGION) {
                                if (val.EMP_ID != CookieService.getLocalStorageValues("Session_UserID")) {
                                    let localArray = {
                                        value: val.EMP_ID,
                                        label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
                                    }

                                    emp.push(localArray)
                                }
                            }

                        });
                    }
                }
                else {
                    if (this.state.EMPDD.length > 0) {
                        this.state.EMPDD.map((val, index) => {
                            if (val.EMP_ID != CookieService.getLocalStorageValues("Session_UserID")) {
                                let localArray = {
                                    value: val.EMP_ID,
                                    label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
                                }

                                emp.push(localArray)
                            }
                        });
                    }
                }
                this.setState({ [name]: event, UserDD: emp, SelectedUser: [] });
                break;
            }
            case "SelectedUser": {
                let emplocArr = [];

                event.forEach((val, ind) => {
                    if (event[ind].value == "*") {

                        emplocArr = []
                        this.state.UserDD.forEach((x, i) => {
                            emplocArr.push(x);

                        })
                        return true;
                    }
                    else {
                        emplocArr.push(val);
                    }


                })
                document.getElementById('usererror').innerHTML = ""
                this.setState({ SelectedUser: emplocArr });
                break;
            }


        }
    }

    ShareButtonClick = () => {
        debugger
        const { t } = this.props;
        let empids = []
        let filteritem = []
        if (this.state.SelectedUser.length > 0) {
            this.state.SelectedUser.forEach((x) => {
                empids.push(x.value)
            })
        }
        if (empids.length > 0) {
            filteritem = this.state.GridCheckArr.filter((x) => x.Gridchecked == true)
            let NewFavItem = [];
            if (filteritem.length > 0) {
                filteritem.forEach((x, i) => {
                    NewFavItem.push({
                        ItemId: filteritem[i].ISA_CP_ITEM_ID,
                        YourCat: filteritem[i].ISA_FAVS_CATEGORY,
                        YourSubCat: filteritem[i].ISA_FAVS_SUBCATEGORY,
                        YourDesc: filteritem[i].ITEM_DESC,
                        Notes: filteritem[i].ISA_CP_FAV_ALT_DSC,
                        //Isa_Catalog_Flag: filteritem[i].ISA_CATALOG_FLAG,
                        Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                        Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                        //Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                        //Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                        //Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                        //Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                        //Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                        //Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                        //Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                        //Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                        //Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                        Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                        Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                        //Session_MobIssuing: CookieService.getLocalStorageValues("Session_MobIssuing"),
                        //Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                        //Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                        SDI_Catagory: filteritem[i].ITEM_CATEGORY,
                        txtMfgItemidHide: filteritem[i].CLIENT_MFG_PART,
                        Supplier_ID: filteritem[i].SUPPLIER_ID,
                        Manufacturer: filteritem[i].MANUFACTURER,
                        Price: filteritem[i].PRICE,
                        SDI_Description: "",
                        UOM: filteritem[i].UNIT_OF_MEASURE,
                        ImgByteCode: filteritem[i].IMAGE_NAME,

                    })
                })
                let BO = {
                    FavItems: NewFavItem,
                    EmplIDs: empids
                }
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                FavItemMainAction.ShareItemButtonClickAction(BO);
            } else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Please select an item to share")
                });
            }
        } else {
            document.getElementById('usererror').innerHTML = t("Please select a user to share")
        }
    }

    ShareItemButtonClickResponse = () => {
        debugger
        const { t } = this.props;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

        let response = FavItemMainStore.pageLoadData

        if (response == "Success") {

            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Shared successfully")
            });
            this.setState({ GridCheckArr: [] })
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Sharing failed. Please try again later")
            });
        }

        $('#Fav_Item_Sharing').modal('hide');
        let locEMP = [];
        this.state.EMPDD.forEach((val, index) => {
            if (val.EMP_ID != CookieService.getLocalStorageValues("Session_UserID")) {
                let localArray = {
                    value: val.EMP_ID,
                    label: val.ISA_EMPLOYEE_NAME + " " + val.ISA_EMPLOYEE_EMAIL
                }

                locEMP.push(localArray)
            }
        });
        document.getElementById('usererror').innerHTML = ""

        searchTxt = "";
        searchCol = "ALL";
        this.favPageLoad("1", this.state.RecCount, searchTxt, searchCol, "ADD_DTTM", "DESC");
        this.setState({ SelectedUser: [], SelectedRegion: [], UserDD: locEMP, SharedItem: [], PageNo: 1 })

    }

    bindDataGrid = () => {
        const { t } = this.props;
        debugger;
        let UnitPrice = 0.00
        if (this.state.GridArr)
            return this.state.GridArr.map((value, index) => {
                UnitPrice = parseFloat(value.PRICE) ? parseFloat(value.PRICE) : 0
                if (UnitPrice > 0) {
                    UnitPrice = UnitPrice.toFixed(2)
                    console.log("unitprice", UnitPrice);
                }
                else {
                    UnitPrice = 0.00
                }
                console.log("ITEM_CATEGORY", value.ITEM_CATEGORY);
            return (
                <tr key={value.ISA_CP_ITEM_ID}>
                    <td data-label="Share">
                        <div className="col-md-1 float-left text-center mb-1 px-2">
                            <div className="custom-control custom-checkbox item-custom-control">
                                
                                <input type="checkbox" className="custom-control-input" checked={value.Gridchecked} id={`FavShareCheckbox${index + 1}`} name={`example1`} onChange={() => this.selectFavItemShareOnChange(value)} />
                                <label className="custom-control-label" htmlFor={`FavShareCheckbox${index + 1}`} />
                            </div>
                        </div>
                    </td>
                    {/* 19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                    {/*[01/26/2024, Zeus 261] - Invoke redirectToItemDetails() to navigate to Item details page - Kishore*/}
                    <td data-label="Item ID" className="back-icon hyper-link" onClick={() => redirectToItemDetails(value.ISA_CP_ITEM_ID, value.CLIENT_MFG_PART, value.SUPPLIER_ID, "FavItemMain", this.props)}>{value.CLIENT_MFG_PART}
                        {/*<div className="col-md-4 float-left text-center px-2">
                            <div className="custom-control custom-checkbox item-custom-control">
                                <input type="checkbox" className="custom-control-input" checked={value.Gridchecked} id={`FavShareCheckbox${index + 1}`} name={`example1`} onChange={() => this.selectFavItemShareOnChange(value)} />
                                <label className="custom-control-label" htmlFor={`FavShareCheckbox${index + 1}`} />
                            </div>
                        </div>*/}
                    </td>
                    {/* <td data-label="SDI Category">{value.ITEM_CATEGORY == "" ||  value.ITEM_CATEGORY == " " ?"Unclassified" : value.ITEM_CATEGORY}</td>*/}
                    {/* <td data-toggle="tooltip" title={value.ITEM_CATEGORY} data-label="SDI Category">{value.ITEM_CATEGORY ? value.ITEM_CATEGORY.length > 20 ? `${value.ITEM_CATEGORY.substring(0, 20)}...` : value.ITEM_CATEGORY : `Unclassified`}</td>*/}
                    {/*<td data-toggle="tooltip" title={value.ITEM_CATEGORY} data-label="SDI Category">{value.ITEM_CATEGORY == "" || value.ITEM_CATEGORY == " " || value.ITEM_CATEGORY == null ? `Unclassified` : value.ITEM_CATEGORY.length > 15 ? `${value.ITEM_CATEGORY.substring(0, 15)}...` : value.ITEM_CATEGORY  }</td>*/}
                    {/* <td data-label="Your Category">{value.ISA_FAVS_CATEGORY == " " ? "-" : value.ISA_FAVS_CATEGORY }</td>*/}
                    <td data-toggle="tooltip" title={value.ISA_FAVS_CATEGORY} data-label="Category">{value.ISA_FAVS_CATEGORY != " " && value.ISA_FAVS_CATEGORY != null && value.ISA_FAVS_CATEGORY != undefined ? value.ISA_FAVS_CATEGORY.length > 15 ? `${value.ISA_FAVS_CATEGORY.substring(0, 15)}...` : value.ISA_FAVS_CATEGORY : `-`}</td>
                    <td data-toggle="tooltip" title={value.ISA_FAVS_SUBCATEGORY} data-label="Sub Category">{value.ISA_FAVS_SUBCATEGORY != " " && value.ISA_FAVS_SUBCATEGORY != null && value.ISA_FAVS_SUBCATEGORY != undefined ? value.ISA_FAVS_SUBCATEGORY.length > 15 ? `${value.ISA_FAVS_SUBCATEGORY.substring(0, 15)}...` : value.ISA_FAVS_SUBCATEGORY : `-`}</td>
                    <td data-toggle="tooltip" title={value.ITEM_DESC} data-label="Item Description">{value.ITEM_DESC != " " && value.ITEM_DESC != null && value.ITEM_DESC != undefined ? value.ITEM_DESC.length > 15 ? `${value.ITEM_DESC.substring(0, 15)}...` : value.ITEM_DESC : `-`} </td>
                    <td >
                        {value.ItemType == "ORO" ?
                            <span className="oro-item" style={{ "top": "7px", "margin-left": "7px" }}>O</span> : null}
                        {value.ItemType == "VMI" ?
                            <span className="oro-item vmi-item" style={{ "top": "7px", "margin-left": "7px" }}>V</span> : null}
                    </td>
                    {/*<td data-label="Your Description">{value.ISA_CP_FAV_ALT_DSC == " " ? "-" : value.ISA_CP_FAV_ALT_DSC}</td>*/}
                    <td data-toggle="tooltip" title={value.ISA_CP_FAV_ALT_DSC} data-label="Your Description">{value.ISA_CP_FAV_ALT_DSC !=undefined && value.ISA_CP_FAV_ALT_DSC!=null && value.ISA_CP_FAV_ALT_DSC != " "? value.ISA_CP_FAV_ALT_DSC.length > 15 ? `${value.ISA_CP_FAV_ALT_DSC.substring(0, 15)}...` : value.ISA_CP_FAV_ALT_DSC : `-`}</td>
                    <td data-toggle="tooltip" title={value.MANUFACTURER} data-label="MFG">{value.MANUFACTURER != " " && value.MANUFACTURER != undefined && value.MANUFACTURER!=null ? value.MANUFACTURER.length > 15 ? `${value.MANUFACTURER.substring(0, 15)}...` : value.MANUFACTURER : `-`}</td>
                    {/* <td data-label="MFG Part Number">{value.CLIENT_MFG_PART}</td>*/}{/*NEW(1/6/2021)******/}
                    {/* 19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                    {/* [12/21/23] Zeus 242 - SIT - Bug fix - 4557 - Fix page top spaccing issue - Johnprinto D */}
                    <td data-toggle="tooltip" title={value.ISA_CP_ITEM_ID} data-label="Supplier Part #">{value.ISA_CP_ITEM_ID && value.ISA_CP_ITEM_ID.trim() ? value.ISA_CP_ITEM_ID.length >10 ? `${value.ISA_CP_ITEM_ID.substring(0, 10)}...` : value.ISA_CP_ITEM_ID : `-`}</td>
                    {/* 19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                    <td data-toggle="tooltip" title={value.SUPPLIERNAME} data-label="Supplier Name">{value.SUPPLIERNAME && value.SUPPLIERNAME.trim() ? value.SUPPLIERNAME.length >10 ? `${value.SUPPLIERNAME.substring(0, 10)}...` : value.SUPPLIERNAME : `-`}</td>
                    {value.PRICE > 0 ?
                        
                        <td data-label="Price">{this.state.Site_Currency + " " + UnitPrice}</td> :
                        <td data-label="Price">{value.PRICE > 0 ? `$ ${value.PRICE}` : t("Price on request")}</td> 
                        }
                    
                    
                    <td data-label="Quantity" className="custom-width-table">
                        <input type="text" className="form-control d-inline catalog-inline-qty text-center" maxLength="8" name="itemQuantity" id={`itemQuantity${index + 1}`} value={value.QTY_REQUESTED} onChange={(e) => this.onquantityChange(e, value, index)} />
                        <a href="#" className="search-results-cart-button d-inline ml-2" onClick={(e)=>this.Addtocart(e,value)}><span className="cart-icon" /></a>
                    </td>
                    <td data-label="Action">
                        <a href="#" className="edit-icon float-left"   data-backdrop="static" data-keyboard="false" onClick={(e) => this.EditFav(e, value)}/>
                        <a href="#" className="delete-icon delete-icon-position float-none d-inline-block" data-toggle="modal" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#Cancel-confirmation" onClick={(e)=>this.DeleteFav(e,value)} />
                    </td>
                   
                </tr>
            );
        })
    }
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {  
        const { t } = this.props;
        return (
            
            <div>
            <div className="container-fluid content-section">
                <div className="row">
                    {/* Favorite Item Maintenance starts */}
                    <div className="container-fluid content-margin">
                        <div className="row">
                                <div className="col-md-12 page-heading mb-3">
                                    {/*//[2/18/22, CAS - 31, Dhevanesam R]*/}
                                    <h5 className="page-title"><Trans>Favorite Item Maintenance</Trans></h5>
                                <div className="fav-item-maintenance-icon mt-1">
                                        <input type="checkbox" id="checkbox-Fav" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                        <label htmlFor="checkbox-Fav">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                    </div>
                                    
                                    <div className="float-right my-2">
                                        {this.state.totalCount == 0 && isPostBack == true ?null:
                                            (<div className="float-right ml-2 row mr-1">
                                                <div className="input-group dropdown-search float-left mb-2">
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.filterDisplayName}</Trans></button>
                                                        <div className="dropdown-menu" style={{}}>
                                                            {/*//[2/18/22, CAS - 31, Dhevanesam R]*/}
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "All", "ALL")}><Trans>All</Trans></a>
                                                            {/* 19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Item ID", "CLIENT_MFG_PART")}><Trans>Item ID</Trans></a>
                                                            {/*<a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "SDI Category", "ITEM_CATEGORY")}><Trans>SDI Category</Trans></a>*/}
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Category", "ISA_FAVS_CATEGORY")}><Trans>Category</Trans></a>
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Sub Category", "ISA_FAVS_SUBCATEGORY")}><Trans>Sub Category</Trans></a>
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Item Description", "ITEM_DESC")}><Trans>Item Description</Trans></a>
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Your Description", "ISA_CP_FAV_ALT_DSC")}><Trans>Your Description</Trans></a>
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "MFG", "MANUFACTURER")}>MFG</a>
                                                            <a className="dropdown-item" href="#" onClick={(e) => this.filterClick(e, "Supplier Part #", "ISA_CP_ITEM_ID")}><Trans>Supplier</Trans> <Trans>Part</Trans># </a>
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control" placeholder={t("Search")} aria-label="Text input with dropdown button" style={{ 'padding-right': '50px' }} value={this.state.searchTxt} id="searchTxt" onKeyDown={this.AnykeyPress} onChange={this.SearchTxtChange} />
                                                    {this.state.searchTxt.length > 0 ? <span class="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} > </span> : null}
                                                    <span className="dropdown-search-icon" onClick={this.handleSearch} />
                                                </div>
                                            </div>)}
                                        {/*2552 - Font style needs to be change in ipad - Dhamotharan P (added px-1)*/}
                                        <button type="button" className="btn btn-labeled btn-primary custom-btn-primary primary-btn float-left px-1 py-0 mr-2" data-toggle="modal"  data-backdrop="static" data-keyboard="false" data-target="#add-fav-popup" onClick={this.AddNewFav}>
                                            <span className="btn-label"><span className="add-new-icon" /></span><Trans>New</Trans></button>
                                        <button type="button" className="btn btn-primary custom-btn-primary primary-btn float-left mr-2 mb-2" onClick={(event) => this.ShareMultipleItems()}>
                                            <Trans>Share</Trans></button>
                                        <button type="button" className="btn btn-labeled btn-primary custom-btn-primary primary-btn float-left px-1 py-0 mr-2" onClick={(event) => this.RedirectTo(event, "/shoppingcart")}>
                                        <span className="btn-label"><span className="item-cart" /></span><Trans>Return to Shopping Cart</Trans></button>
                                        <button type="button" className="btn btn-labeled btn-primary custom-btn-primary primary-btn float-left px-1 py-0 mr-2" onClick={(event) => this.RedirectTo(event, "/homepage")}>
                                        <span className="btn-label"><span className="shopping-bag" /></span><Trans>Continue Shopping</Trans></button>
                                        </div>
                                       
                                </div>
                                {this.state.totalCount > 0 ?

                                    (<div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table ZEUS_Grid FOM-grid responsive-scroll mobileflex-grid">
                                                <thead>
                                                    <tr>
                                                        {/*//[2/18/22, CAS - 31, Dhevanesam R]*/}
                                                        <th scope="col"></th>
                                                        <th scope="col" className="position-sort-icon itemIdfav"><Trans>Item ID</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "CLIENT_MFG_PART")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "CLIENT_MFG_PART")}><img src="Images/sort1.svg" /></span></th>
                                                        {/*<th scope="col" className="position-sort-icon"><Trans>SDI Category</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ITEM_CATEGORY")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ITEM_CATEGORY")}><img src="Images/sort1.svg" /></span></th>*/}
                                                        <th scope="col" className="position-sort-icon"><Trans>Category</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ISA_FAVS_CATEGORY")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ISA_FAVS_CATEGORY")}><img src="Images/sort1.svg" /></span></th>
                                                        <th scope="col" className="position-sort-icon"><Trans>Sub Category</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ISA_FAVS_SUBCATEGORY")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ISA_FAVS_SUBCATEGORY")}><img src="Images/sort1.svg" /></span></th>
                                                        <th scope="col" className="position-sort-icon"><Trans>Item Description</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ITEM_DESC")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ITEM_DESC")}><img src="Images/sort1.svg" /></span></th>
                                                        <th scope="col" className="position-sort-icon"></th>
                                                        <th scope="col" className="position-sort-icon"><Trans>Your Description</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ISA_CP_FAV_ALT_DSC")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ISA_CP_FAV_ALT_DSC")}><img src="Images/sort1.svg" /></span></th>
                                                        <th scope="col" className="position-sort-icon itemIdfav">MFG<span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "MANUFACTURER")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "MANUFACTURER")}><img src="Images/sort1.svg" /></span></th>

                                                        {/* 19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                                                        <th scope="col" className="position-sort-icon itemIdfav"><Trans>Supplier</Trans> <Trans>Part</Trans>#<span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "ISA_CP_ITEM_ID")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "ISA_CP_ITEM_ID")}><img src="Images/sort1.svg" /></span></th>

                                                        {/* 19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                                                        <th scope="col" className="position-sort-icon itemIdfav"><Trans>Supplier</Trans> <Trans>Name</Trans> <span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "SUPPLIERNAME")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "SUPPLIERNAME")}><img src="Images/sort1.svg" /></span></th>
                                                        <th scope="col" className="position-sort-icon"><Trans>Price</Trans></th>
                                                        <th scope="col"><Trans>Quantity</Trans></th>
                                                        <th scope="col" className="action-custom-width custom-width-action"><Trans>Action</Trans></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.GridArr.length > 0 ? this.bindDataGrid() :
                                                        (

                                                            <tr>
                                                                <td colSpan="11">
                                                                    <section className="site-undercontruction container text-center center-panel pt-4">
                                                                        <div className="img-section">
                                                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                                                        </div>
                                                                        <p className="search-result-content pt-4"><Trans>Sorry, we couldn’t find any search result</Trans>
                                                </p>
                                                                    </section>
                                                                </td>
                                                            </tr>)}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>) :
                                    (<section className="site-undercontruction container text-center center-panel pt-4">
                                        <div className="img-section">
                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                        </div>
                                        <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                            </p>
                                    </section>) }
                            </div>
                            {this.state.totalCount > 0 ? <>

                                <div className="col-md-12">
                                    <span className="total-records mt-2 d-inline-block">
                                        <Trans>Number of Records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
                                    </span>
                                    {this.state.totalCount > this.state.RecCount ? <ReactPaginate
                                        previousLabel={<span className="right-pagination-arrow" />}
                                        nextLabel={<span className="left-pagination-arrow" />}
                                        previousClassName={'page-item'}
                                        nextClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        breakLabel={'...'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'custom-pagination pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        hrefBuilder={() => '#'}
                                        forcePage={this.state.PageNo - 1}
                                    /> : null}
                                </div></> : null}

                    </div>
                </div>
                </div>

                <div className="modal" id="add-fav-popup">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Add New Favorites</Trans> </h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row mt-3">
                                    <div className="col-12 edit-desc-field mb-4">
                                        <div className="row mb-3">
                                            <label className="col-md-5 quick-label"><Trans>Item ID</Trans></label>
                                            <div className="col-md-7 input-group">
                                                <input type="text" className="form-control" placeholder={t("Enter ID")} id="NewItemId" value={this.state.NewItemid} onKeyDown={this.keyPress} onBlur={this.handleSTrTxt} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={this.NewItemClick}>
                                                        <span className="shopping-search-icon" />
                                                    </span>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        {this.bindNewFavItemDetail()}

                                    </div>

                                   <div className="col-md-5" />
                                    <div className="col-md-7 mb-2" id="savebuttonid">
                                        {/*<button className="btn btn-secondary custom-btn-secondary secondary-btn px-4">Close</button>*/}
                                        {/*  {$("#NewItemId").val() == "" ? null :*/}
                                        {this.state.NewItem ?
                                            <button className="btn btn-primary custom-btn-primary primary-btn px-4 custombtn" onClick={this.InsertFavItem}><Trans>Save</Trans></button>:null}
                                    </div>


                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="Cancel-confirmation">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-4">
                                        <span className="question-mark-icon delete-confirm-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block"><Trans>Do you want to remove this item?</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal" onClick={this.ConfirmationNO}><Trans>No</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={this.ConfirmationYES}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="Fav_Item_Sharing" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Share</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.Fav_Item_Share_Close()}>
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body" >
                                <div className="row">
                                    <div className="col-md-12 float-left">
                                        <div className="col-md-12 col-lg-12 mb-3 float-left p-0">
                                            <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label"><Trans>Region</Trans></label>
                                            <div className="col-lg-12 col-md-12 float-left p-0 col-12">

                                                <Select
                                                    className="DropDown-Font-Size"
                                                    options={this.state.RegionDD}
                                                    value={this.state.SelectedRegion}
                                                    onChange={e => this.HandleOnChange(e, "SelectedRegion")}
                                                    name="REG"
                                                    isClearable={true}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 float-left">
                                        <div className="col-md-12 col-lg-12 mb-3 float-left p-0">
                                            <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label mandatory-after"><Trans>User</Trans></label>
                                            <div className="col-lg-12 col-md-12 float-left p-0 col-12">

                                                <MultiSelect
                                                    options={this.state.UserDD}
                                                    className="DropDown-Font-Size UserCSS w-100"
                                                    id="REG"
                                                    value={this.state.SelectedUser}
                                                    onChange={e => this.HandleOnChange(e, "SelectedUser")}
                                                    labelledBy={"Select"}
                                                />


                                                <span className="errorspan1 text-left" style={{ fontSize: '14px' }} id="usererror" />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-2 mt-2">
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4 mt-3" onClick={() => this.ShareButtonClick()}><Trans>OK</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal" id="edit-fav-popup">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Edit Favorites</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            {this.EditFavItemBind()}
                        </div>
                    </div>
                </div>






            </div>
            );

    }
};
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(FavItemMainComponent);