import EventEmitter from 'events';
import { Dispatcher } from 'simplr-flux';

class RealTimeInvStore extends EventEmitter {
    addToCartRTI = "";
    handleRealTimeInvStore = (action) => {
        switch (action.action.type) {
            case "addToCartRTI": {
                this.addToCartList = action.action.response;
                this.emit('addToCartRTI');
                break;
            }
        }
    }
}
const objRealTimeInvStore = new RealTimeInvStore;
Dispatcher.register(objRealTimeInvStore.handleRealTimeInvStore.bind(objRealTimeInvStore));
export default objRealTimeInvStore;
