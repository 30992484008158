/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Sushmitha
 * Created Date: 08/21/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: UP_PC_107 && UP_PC_108
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
import * as CookieService from '../CookieService';

const tokenExpired = async () => {
    try {
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)

    }
};
/**
 * Ref: UP_PC_109
 * 
 * GetEditUser method gets called the BuildUserProfile()
 * @param GetEditUserBO
 */
const GetEditUser = (GetEditUserBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_Page_Load,
            JSON.stringify(GetEditUserBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UserDetail", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_113
 * 
 * GetSearchUsers method gets called the LoadCartItem()
 * @param GetSearchUsersBO
 */
const GetSearchUsers = (GetSearchUsersBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_rcbdropSelectUser_SelectedIndexChanged,
            JSON.stringify(GetSearchUsersBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "SelectUser", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_112
 * 
 * GetSearchUserOnUTypeChange method gets called the UserTypeRadio()
 * @param GetSearchUserRBChgBO
 */
const GetSearchUserOnUTypeChange = (GetSearchUserRBChgBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_radioUserType_SelectedIndexChanged,
            JSON.stringify(GetSearchUserRBChgBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "RadioUserType", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_114
 * 
 * GetVendorGroupBO method gets called the VendorIdChaneg()
 * @param GetVendorGroup
 */
const GetVendorGroup = (GetVendorGroupBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_MainvndrID_TextChanged,
            JSON.stringify(GetVendorGroupBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "VendorIdCheck", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_115
 * 
 * GetMultiSite method gets called the MultiSiteCheckBtn()
 * @param GetMultiSiteBO
 */
const GetMultiSite = (GetMultiSiteBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_MultiSiteChk_CheckedChanged,
            JSON.stringify(GetMultiSiteBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "MultiSite", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_211
 * 
 * GetThirdParty method gets called the handleChangeSite()
 * @param GetThirdPartyBO
 */
const GetThirdParty = (GetThirdPartyBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_SiteBased_ThirdParty,
            JSON.stringify(GetThirdPartyBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ThirdParty", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_117
 * 
 * UpdateUserDetails method gets called the UpdateUserProfile()
 * @param EditUserDetailsIBO
 */
const UpdateUserDetails = (EditUserDetailsIBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSave_Click,
            JSON.stringify(EditUserDetailsIBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "AddUser", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_118 && UP_PC_137 && UP_PC_133 && UP_PC_131 && UP_PC_129 && UP_PC_127 && UP_PC_122 
 * 
 * GetTabBasedDetails method gets called the TabClick()
 * @param GetTabBasedDetailsBO
 */
const GetTabBasedDetails = (GetTabBasedDetailsBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_tbStripUserDetails_TabClick,
            JSON.stringify(GetTabBasedDetailsBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "TabClick", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_120
 * 
 * GetPrivilegesType method gets called the OnchangePrivType()
 * @param GetPrivilegesTypeIBO
 */
const GetPrivilegesType = (GetPrivilegesTypeIBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_rblType_SelectedIndexChanged,
            JSON.stringify(GetPrivilegesTypeIBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PrivilegeType", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_121
 * 
 * GetPrivilegeRole method gets called the handleChangeTab2Role()
 * @param GetPrivilegeRoleBO
 */
const GetPrivilegeRole = (GetPrivilegeRoleBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_ddlUserRole_SelectedIndexChanged,
            JSON.stringify(GetPrivilegeRoleBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "PrivilegeRole", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_119
 * 
 * UpdatePrivilege method gets called the updatePrivilege()
 * @param UpdatePrivilegeBO
 */
const UpdatePrivilege = (UpdatePrivilegeBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnUserAccessSave_Click,
            JSON.stringify(UpdatePrivilegeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdatePrivilege", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_123
 * 
 * UpdateBudgetApp method gets called the UpdateApprovals()
 * @param UpdateBudgetAppBO
 */
const UpdateBudgetApp = (UpdateBudgetAppBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSubmit_Click,
            JSON.stringify(UpdateBudgetAppBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateApprovals", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_124
 * 
 * UpdateReqApp method gets called the UpdateApprovals()
 * @param UpdateReqAppBO
 */
const UpdateReqApp = (UpdateReqAppBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSubmitReqAppr_Click,
            JSON.stringify(UpdateReqAppBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateReqApprovals", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_125
 * 
 * GetReqApprovals method gets called the LoadApprover()
 * @param GetReqApprovalsBO
 */
const GetReqApprovals = (GetReqApprovalsBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSetReqAppr_Click,
            JSON.stringify(GetReqApprovalsBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "LoadReqApprovals", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_126
 * 
 * GetBudApprovals method gets called the LoadApprover()
 * @param GetBudApprovalsBO
 */
const GetBudApprovals = (GetBudApprovalsBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSetBudAppr_Click,
            JSON.stringify(GetBudApprovalsBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "LoadBudApprovals", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const GetAltBudApprovals = (GetAltBudApprovalsBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSetAltBudAppr_Click,
            JSON.stringify(GetAltBudApprovalsBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "LoadAltBudApprovals", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
const UpdateAltBudApp = (UpdateAltBudAppBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSubmitAltBudAppr_Click,
            JSON.stringify(UpdateAltBudAppBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateReqApprovals", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_128
 * 
 * UpdateOSE method gets called the UpdateOSE()
 * @param UpdateOSEBO
 */
const UpdateOSE = (UpdateOSEBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnOrdStatEmailSubmit_Click,
            JSON.stringify(UpdateOSEBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateOSE", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_130
 * 
 * UpdatePreferences method gets called the UpdatePref()
 * @param UpdatePreferencesBO
 */
const UpdatePreferences = (UpdatePreferencesBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnSubmitPrefs_Click,
            JSON.stringify(UpdatePreferencesBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdatePref", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_132
 * 
 * UpdateCrib method gets called the UpdateCrib()
 * @param UpdateCribBO
 */
const UpdateCrib = (UpdateCribBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnGribSubmit_Click,
            JSON.stringify(UpdateCribBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateCrib", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_134
 * 
 * GetSearchUserTrack method gets called the handleChangeEmplyee()
 * @param GetSearchUserTrackBO
 */
const GetSearchUserTrack = (GetSearchUserTrackBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_ddlSDiUsers_SelectedIndexChanged,
            JSON.stringify(GetSearchUserTrackBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "Selecttrack", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_135
 * 
 * AddTrackUser method gets called the UpdateTST()
 * @param AddTrackUserBO
 */
const AddTrackUser = (AddTrackUserBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnTangoAddOtherUser_Click,
            JSON.stringify(AddTrackUserBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "AddTrack", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_138
 * 
 * UpdateZeus method gets called the UpdateZeus()
 * @param UpdateZeusBO
 */
const UpdateZeus = (UpdateZeusBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnZuseSubmit_Click,
            JSON.stringify(UpdateZeusBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "UpdateZeus", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_139
 * 
 * ActivateSite method gets called the ActiveEmployeeAccount()
 * @param ActivateSiteBO
 */
const ActivateSite = (ActivateSiteBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnActivateAccount_Click,
            JSON.stringify(ActivateSiteBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ActivateSiteAcct", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_140
 * 
 * DeActivateSite method gets called the ActiveEmployeeAccount()
 * @param DeActivateSiteBO
 */
const DeActivateSite = (DeActivateSiteBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnInactivateAccount_Click,
            JSON.stringify(DeActivateSiteBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ActivateSiteAcct", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_141
 * 
 * ActivateEmpyAcc method gets called the ActiveEmployeeAccount()
 * @param ActivateEmpyAccBO
 */
const ActivateEmpyAcc = (ActivateEmpyAccBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnEmplActivateAccount_Click,
            JSON.stringify(ActivateEmpyAccBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ActivateSiteAcct", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
/**
 * Ref: UP_PC_142
 * 
 * DeActivateEmpyAcc method gets called the ActiveEmployeeAccount()
 * @param DeActivateEmpyAccBO
 */
const DeActivateEmpyAcc = (DeActivateEmpyAccBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.UserProfile,
            SDIConstants.methodNames.UserProfile_btnEmplInactivateAccount_Click,
            JSON.stringify(DeActivateEmpyAccBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ActivateSiteAcct", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};

const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ShopCart_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const GetchargeCOde = (GetchargeCOdeBO) => {
    try {
        debugger;
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.BuildChrCD,
            SDIConstants.methodNames.BuildChrCD_Page_Load,
            JSON.stringify(GetchargeCOdeBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "BuildChrCD", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
export {
    GetEditUser, GetSearchUsers, GetSearchUserOnUTypeChange, GetVendorGroup, GetMultiSite, UpdateUserDetails, GetchargeCOde,
    GetTabBasedDetails, GetPrivilegesType, GetPrivilegeRole, UpdatePrivilege, UpdateBudgetApp, UpdateReqApp,
    GetReqApprovals, GetBudApprovals, UpdateOSE, UpdatePreferences, UpdateCrib, GetSearchUserTrack, AddTrackUser, GetThirdParty,
    UpdateZeus, ActivateSite, DeActivateSite, ActivateEmpyAcc, DeActivateEmpyAcc, getCurrentFavPage, insertFavPage, deleteFavPage,
    UpdateAltBudApp, GetAltBudApprovals

}
