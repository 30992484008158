import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import * as CookieService from '../CookieService';
import * as UserNotificationAction from '../actions/UserNotificationAction';
import UserNotificationStore from '../stores/UserNotificationStore';
import * as MenuAction from '../actions/MenuAction';
import Autocomplete from 'react-autocomplete';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate';
//[PC_07, 09/07/21, Author - Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
let searchTxt = "";
let searchColumn = "All";
let isArchieved = false;
let currentPagePath;
let isClear = false;
let isPostback = true;
class UserNotificationComponent extends Component {

    constructor(props) {
        super(props);
        this.pageloadcall = this.pageloadcall.bind(this);
        this.ArchiveButtonClick = this.ArchiveButtonClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.AnykeyPress = this.AnykeyPress.bind(this);
        this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
        this.NotifydDetail=this.NotifydDetail.bind(this);
        this.download = this.download.bind(this);
        this.AfterArchiveClick = this.AfterArchiveClick.bind(this);
        this.updateFavPageResponse=this.updateFavPageResponse.bind(this);
        this.state = {
            GridArr: [], NotifyId: [],
            PageNo: 1, isFilterOpen: false,
            RecCount: 10, totalCount: 0, pageCount: 0, isFavorited: false,
            SortingType: "DESC", NotifcationConfirm:false,
            SortingColumn: "DTTMADDED", searchDisplayName: "All", FromDate: null, ToDate: null, searchTxt: "", isSearch: false
        }
    }
    /*
     * N_PC_08
     * For thepage load 
     * */
    //componentWillMount() {

    //    //this.pageloadcall("N", "", this.state.PageNo, this.state.RecCount, searchTxt, searchColumn, this.state.SortingColumn, this.state.SortingType);
    //    debugger;
    //    UserNotificationStore.on('Notificationload', this.PageLoadData.bind(this));
    //    UserNotificationStore.on('ArchiveUpdate', this.AfterArchiveClick.bind(this));
    //    UserNotificationStore.on('NotifyDetail', this.NotifydDetail.bind(this));
    //    UserNotificationStore.on('FileDownload', this.download.bind(this));

    //}

    componentDidMount() {
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        UserNotificationAction.getCurrentFavPage(getFavPageBO);
        isPostback = true;
        isArchieved = false;
        //$('#archid').hide();
        searchTxt = "";
        searchColumn = "All";
        this.pageloadcall("N", "", this.state.PageNo, this.state.RecCount, searchTxt, searchColumn, this.state.SortingColumn, this.state.SortingType);
        debugger;
        UserNotificationStore.on('Notificationload', this.PageLoadData.bind(this));
        UserNotificationStore.on('ArchiveUpdate', this.AfterArchiveClick);
        UserNotificationStore.on('NotifyDetail', this.NotifydDetail.bind(this));
        UserNotificationStore.on('FileDownload', this.download.bind(this));
        UserNotificationStore.on('favpage', this.loadFavPageResponse.bind(this));
        UserNotificationStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    componentWillUnmount() {
        UserNotificationStore.removeListener('Notificationload', this.PageLoadData);
        UserNotificationStore.removeListener('ArchiveUpdate', this.AfterArchiveClick);
        UserNotificationStore.removeListener('NotifyDetail', this.NotifydDetail);
        UserNotificationStore.removeListener('FileDownload', this.download);
        UserNotificationStore.removeListener('favpage', this.loadFavPageResponse);
        UserNotificationStore.removeListener('updateFavPage', this.updateFavPageResponse);

    }

    download = () => {
        //[5/10/22, CAS - 31, Dhevanesam R]
        let downloadResponse = JSON.parse(UserNotificationStore.PageLoadData);
        const { t } = this.props;
        if (downloadResponse == false) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("The file for download does not exist")
            });
            // alert(t("The file for download does not exist"))
        }
    }


    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(UserNotificationStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };

    updateFavPageResponse = () => {
        if (UserNotificationStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(UserNotificationStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(UserNotificationStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
        this.refreshMenu();
    };
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        console.log("masterBO", masterBO);
        MenuAction.getMasterData(masterBO);
    };


    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Notifications",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            UserNotificationAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            UserNotificationAction.deleteFavPage(deleteFavPageBO)
        }
    };
    /*
    * N_PC_11
    * this method to call the action method for the pageload
    * */
    pageloadcall = (NotificationStatus, NotifyId, pageNo, RecCount, searchText, searchColumn, sortColumn, sortValue) => {
        debugger;
        let FD = "";
        let TD = "";

        FD = this.state.FromDate ? this.handledate(this.state.FromDate) : "";
        TD = this.state.ToDate ? this.handledate(this.state.ToDate) : "";
        if (isClear) {
            FD = "";
            TD = "";
        }
        //if (isArchieved == false) {
        //    //if (this.state.FromDate != "")
        //    if (this.state.FromDate != null) {
        //        FD = this.state.FromDate;
        //    }
        //    //if (this.state.ToDate != "")
        //    if (this.state.ToDate != null){
        //        TD = this.state.ToDate;
        //    }
        //}
        debugger;
        let UserNOtificationBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            NotificationStatus: NotificationStatus ,
            NotifyId: NotifyId,
            pageNo: pageNo ,
            itemCount: RecCount, searchText: searchText, searchColumn: searchColumn, sortColumn: sortColumn, sortValue: sortValue, FromDate: FD, ToDate: TD

        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        UserNotificationAction.NotificationPageLoad(UserNOtificationBO);
    }
    /*
    * N_PC_14
    * 
    * */
    PageLoadData = () => {
        debugger;
        //if (isArchieved == true) {
        //    $('#Archived').modal('show');
        //    $('Notification').modal('hide');
        //}
        //else {
        //    $('#Notificaiton').modal('show');
        //    $('#Archieved').modal('hide');
        //}
        let PageLoadResponse = JSON.parse(UserNotificationStore.PageLoadData);
        window.scrollTo(0, 0)
        console.log("totArr", PageLoadResponse.TotalCount[0].TOTALCOUNT);
        let total = PageLoadResponse.TotalCount[0].TOTALCOUNT != 0 ? PageLoadResponse.TotalCount[0].TOTALCOUNT : 0
        //let total = PageLoadResponse.TotalCount.length > 0 ? PageLoadResponse.TotalCount[0].TOTALCOUNT : 0
        let count = PageLoadResponse.TotalCount[0].TOTAL_COUNT;
        let pg = Math.ceil((count > 0 ? count : 0) / this.state.RecCount);
        this.setState({ pageCount: pg, totalCount: total });
        debugger;
        let Grid = [];
        Grid=PageLoadResponse.NotifyData;
        Grid.forEach((value, index) => {
            //value.checked = false;
            Grid[index].checked = false;
            //id.checked = false;
        })
        debugger;
        //[7/12/2023, PS_ZE_142_APP_20, Dhevanesam R]
        MenuAction.refreshMasterData()
        if (total == 0 && isArchieved == false && $("#searchTxt").val() == "" &&
            this.state.FromDate == null && this.state.ToDate == null) {
            $('#archid').show();
        }
        //this.setState({ GridArr: PageLoadResponse.NotifyData, totalCount: PageLoadResponse.TotalCount[0].TOTAL_COUNT });
        this.setState({ GridArr: Grid, totalCount: PageLoadResponse.TotalCount[0].TOTAL_COUNT });
        console.log("grid", Grid);
    }

    /*
    * this.function will call the pageload method
    * 
    * */
    AfterArchiveClick = () => {
        //$(`#searchTxt`).val().trim() = "";
        const { t } = this.props;
        let PageLoadResponse = JSON.parse(UserNotificationStore.PageLoadData);
        debugger;
        MenuAction.refreshMasterData();
        console.log("archive",PageLoadResponse.ResultFlag[0].updateResult);
        if (PageLoadResponse.ResultFlag[0].updateResult == "True") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("notification Archived")
            });
            //alert(t("notification Archived"));
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("please try again")
            });
            //alert(t("please try again"));
        }

        searchColumn = "All";
        isPostback = false;
        isClear = false;
        this.pageloadcall("N", "", this.state.PageNo,
            this.state.RecCount, searchTxt,
            searchColumn, this.state.SortingColumn,
            this.state.SortingType);
        //window.location.reload();
    }

    NotifydDetail = () => {
        let PageLoadResponse = UserNotificationStore.PageLoadData;
        let PageLoad = JSON.parse(UserNotificationStore.PageLoadData);
        debugger;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        isPostback = false;
        isClear = false;
        $('#notification-details').modal('show');
        $('#d').html(PageLoad);
        debugger;
        //let win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=" + (window.screen.height - 400) + ",left=" + (window.screen.width - 840));
        //win.document.body.innerHTML = '<iframe width = "100%" height = "100%" src = "' + PageLoadResponse + '"></iframe>';

        //let myWindow = window.open("", "MsgWindow", "width=200,height=100");
        //myWindow.document.write(PageLoadResponse);

    }
    selectNotification = (index) => {
        let { GridArr } = this.state;
        GridArr[index].checked = GridArr[index].checked == true ? false : GridArr[index].checked == false ? true : true;
        this.setState(GridArr);
        isPostback = false;
        isClear = false;
    }
    /*
    * this.function will call the pageload method
    * 
    * */
    ConfirmationYES = () => {
        let itemsSelected = this.state.GridArr.filter((selected) => selected.checked == true);
        let ArchiveAdd = [];
        isPostback = false;
        isClear = false;
        itemsSelected.forEach((value) => {
            ArchiveAdd.push({
                NotifyIds: value.NOTIFY_ID
            });

        });
        let ArchiveBO = {

            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            NotifyId: ArchiveAdd

        }; debugger;
        this.setState({NotifcationConfirm:true})
        UserNotificationAction.ArchiveUpdate(ArchiveBO);
    }

    ConfirmationNO = () => {
        //this.setState({ RemainItemId: "" });
        //console.log("RemainItemId", this.state.RemainItemId);
        $('#Cancel-confirmation').modal('hide');
    }
    ArchiveButtonClick = (e, value) => {
        const { t } = this.props;
        e.preventDefault();
        isPostback = false;
        isClear = false;
        let itemsSelected = this.state.GridArr.filter((selected) => selected.checked == true);
        let ArchiveAdd = [];
        itemsSelected.forEach((value) => {
            ArchiveAdd.push({
                NotifyIds: value.NOTIFY_ID
            });

        });
        if (ArchiveAdd.length > 0) {
            $('#Cancel-confirmation').modal('show');
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Please select any Notification")
            })
            //alert(t("Please select any Notification"));
        }
        //this.setState({ RemainItemId: value.ISA_CP_ITEM_ID });
        //console.log("value.ISA_CP_ITEM_ID ", value.ISA_CP_ITEM_ID);
    }
    ArchiveImgClick = (e, value) => {
        e.preventDefault();
        isPostback = false;
        isClear = false;
        let ArchiveAdd = [];
        debugger;
        ArchiveAdd.push({
            NotifyIds: value.NOTIFY_ID
        });
        let ArchiveBO = {

            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            NotifyId: ArchiveAdd

        }; debugger;
        UserNotificationAction.ArchiveUpdate(ArchiveBO);
    }
    NotificationDetail = (event, value) => {
        event.preventDefault();
        isClear = false;
        let DetatilBO = {
            LinkPath: value.LINK
        }
        debugger;
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        UserNotificationAction.NotificationDetail(DetatilBO);
    }
    filtertoggle = (e) => {
        if (e)
            e.preventDefault();
        this.setState({ isFilterOpen: this.state.isFilterOpen == true ? false : true });
        debugger;
    };

    ExportToExcel = (event, value) => {
        event.preventDefault();
        //let filename = [];
        isPostback = false;
        isClear = false;
        let path = value.ATTACHMENTS
        //filename = path.split("\\sdicwsvsp\UpdEmailOut\Notifications" );
        //let filename = path.substring(path.lastIndexOf("\") + 1);
        let filename = path.replace(/^.*[\\\/]/, '');
        //alert(filename);
        console.log("path", filename);
        let DetatilBO = {
            LinkPath: path,
            filenameBO: filename
        }
        UserNotificationAction.ExportData(DetatilBO);
        debugger;
    }
    //[9/1/2023]Zeus 182 OrderApprovalRedirect() will navigate to order approval page when notification contains needs approval and its type is ORD or AQO - Poornima S
    OrderApprovalRedirect = (event, title) => {
        if (title != "") {
            let OrderNo = "";
            let str = title.indexOf("Order # ");
            let strOld = title.indexOf("Order Number ");
            if (str != -1) {
                OrderNo = title.substring(str + 18, str + 8);
            }
            else if (strOld != -1) {
                OrderNo = title.substring(strOld + 23, strOld + 13);
            }

            event.preventDefault();
            this.props.history.push({
                pathname: `/needapprove`,
                search: `?ordNo=${OrderNo}`
            });
        }
    }

    //[9/1/2023]Zeus 182 ReqApprovalRedirect() will navigate to requestor approval page when notification contains quote process and its type is AQO - Poornima S
    ReqApprovalRedirect = (event, title) => {
        debugger
        if (title != "") {
            let OrderNo = "";
            let str = title.indexOf("Quoted Non-Catalog - ");
            let strOld = title.indexOf("Quote Process - ");
            if (str != -1) {
                OrderNo = title.substring(str + 31, str + 21);
            }
            else if (strOld != -1) {
                OrderNo = title.substring(strOld + 26, strOld + 16);
            }

            event.preventDefault();
            let pagePath = `\approvequote`;
            let encodedOrderNo = encodeURIComponent(OrderNo);
            let encodedEMPID = encodeURIComponent(CookieService.getLocalStorageValues("Session_UserID"));
            let strBU = CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU");
            let encodedStrBU = encodeURIComponent(strBU);
            let queryParamsVal = `?fer=${encodedOrderNo}&op=${encodedEMPID}&xyz=${encodedStrBU}&alt=&src=needquote`;
            this.props.history.push({
                pathname: pagePath,
                search: queryParamsVal
            });
        }
    }

    /*
     * N_PC_08
     * Method to display griddatat in pageload and also in archive button click
     * */
    bindNotifictionGrid = () => {
        //if (this.state.GridArr.length > 0) {         
        return this.state.GridArr.map((value, index) => {
            return (
                <><React.Fragment key={value.NOTIFY_ID + index}>
                    <tr>
                        <td className="mobile-check-box text-center mobile-chkbox-align">
                            {isArchieved == false ?
                                <div className="custom-control custom-checkbox item-custom-control">

                                    <input type="checkbox" className="custom-control-input" id={`Notification${index + 1}`} name="example1" onChange={() => this.selectNotification(index)} />
                                    <label className="custom-control-label" htmlFor={`Notification${index + 1}`} />
                                </div> : null}
                        </td>
                        {value.LINK.trim() == "" ? <td data-label="Notification ID">{value.NOTIFY_ID}</td> :
                            <td data-label="Notification ID" ><a href="#" className="order-id" data-toggle="modal" data-keyboard="false" data-backdrop="static" data-target="#notification-details" onClick={(e) => this.NotificationDetail(e, value)}>{value.NOTIFY_ID}</a></td>}
                        <td data-label="Date">{value.RECEIVEDDATE}</td>
                        <td data-label="Type">{value.NOTIFY_DESC}</td>
                        {((value.TITLE.toUpperCase().includes("NEEDS APPROVAL") && value.NOTIFY_TYPE == "ORD") || (value.TITLE.toUpperCase().includes("NEEDS APPROVAL") && value.NOTIFY_TYPE == "AQO")) ? <td data-label="Title"><a href="#" className="order-id" onClick={(event) => this.OrderApprovalRedirect(event, value.TITLE)}  >{value.TITLE}</a></td> :
                            ((value.TITLE.toUpperCase().includes("QUOTE PROCESS") || value.TITLE.toUpperCase().includes("QUOTED NON-CATALOG")) && value.NOTIFY_TYPE == "AQO") ? <td data-label="Title"><a href="#" className="order-id" onClick={(event) => this.ReqApprovalRedirect(event, value.TITLE)}  >{value.TITLE}</a></td> :
                                <td data-label="Title">{value.TITLE}</td>}
                        {isArchieved == true ? null :
                            <td data-label="Archive" className="text-center"><a href="#" className="archive-icon" onClick={(e) => this.ArchiveImgClick(e, value)} /></td>}
                        {value.ATTACHMENTS == " " ? <td data-label="Download" className="text-center"> - </td> :
                            <td data-label="Download" className="text-center"><a href="#" className="download-icon" onClick={(e) => this.ExportToExcel(e, value)} /></td>}
                    </tr>
                </React.Fragment>
                </>

            );
        })

        //else {
        //    return (

        //        );
        //}
    }
    /*
     * n_PC_17
     * pagination function when page gets cheanged
     * */
    handlePageClick = (data) => {
        isPostback = false;
        isClear = false;
        let pageNo = data.selected + 1;
        let isArchi = "N";
        let SC = "NOTIFY_ID";
        let ST = "DESC";
        console.log("pageNo", pageNo);
        //$('.accordion-arrow').removeClass('accordion-arrow-change');
        //$('.hiddenRow').hide();
        //$('tr').removeClass('tr-border');
        this.setState({ PageNo: pageNo });

        searchTxt = $(`#searchTxt`).val().trim();

        debugger;
        if (isArchieved == true) {
            isArchi="Y"
        }
        this.pageloadcall(isArchi, "",
            pageNo, this.state.RecCount,
            searchTxt, searchColumn,
            this.state.SortingColumn, this.state.SortingType);

        console.log("isArchi", isArchi);
        debugger;

    };

    searchClick = (event, displayName, columnName) => {
        event.preventDefault();
        isPostback = false;
        isClear = false;
        this.setState({ searchDisplayName: displayName });
        searchColumn= columnName;
        console.log("searchColumn", columnName);
        debugger;
    }
    ClearSearch = () => {
        this.setState({ searchTxt: "" })
    }
    SearchTxtChange = (e) => {
        this.setState({ searchTxt: e.target.value })
    }

    handleSearch = () => {
        isPostback = false;
        isClear = false;
        let pageNo = 1;
        let sortColumn = "NOTIFY_ID";
        let sortType = "DESC";
        let isArchi = "N";

        searchTxt = $(`#searchTxt`).val().toLowerCase().trim();
        //if (this.state.searchTxt != "") {
        //    searchTxt = $(`#searchTxt`).val().trim();
        //}
        debugger;
        if (isArchieved == true) {
            isArchi = "Y";
        }

        this.pageloadcall(isArchi, "",
            pageNo, this.state.RecCount,
            searchTxt, searchColumn,
            sortColumn, sortType);
        debugger;
        console.log("search", this.state.searchColumn);
        this.setState({ PageNo: pageNo, isSearch: true });

    }

    AnykeyPress(e) {
        if (e.keyCode == 13) {
            debugger;
            this.handleSearch();
        }
    }

    ArchivedNotification = (event) => {
        event.preventDefault();
        debugger;
        isPostback = false;
        isClear = true;
        searchColumn = "All"
        //document.getElementById("searchTxt").innerText = "";
        $('#searchTxt').val('');
        //console.log("searchTxt",)
        let searchtxt = "";
        searchTxt = "";
        let pageNo = 1;
        let sortColumn = "NOTIFY_ID";
        let sortType = "DESC";
        isArchieved = true;
        this.setState({ FromDate: "", ToDate: "", searchDisplayName: "All", PageNo: pageNo, searchTxt: "", isSearch: false }); 
        this.pageloadcall("Y", "", pageNo,
            this.state.RecCount, searchtxt,
            searchColumn, sortColumn,
            sortType);
        debugger;
        console.log("isArchieved", isArchieved);
    }
    handledate = (date) => {
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear())
    }

    FromDateChange = (e) => {
        //e.preventDefault();
        isPostback = false;
        let dateOL = this.handledate(e);
        if (e != "") {
            this.setState({ FromDate: dateOL });
        }
        console.log("FromDate", dateOL);
        // this.setState({ FromDate: dateOL });
    };

    ToDateChange = (e) => {
        //e.preventDefault();
        isPostback = false;
        let dateOL = this.handledate(e);
        if (e != "") {
            this.setState({ ToDate: dateOL });
        }
        console.log("dateOL", dateOL);
        //this.setState({ FromDate: dateOL });
    };
    ApplyButtonClick = () => {
        isPostback = false;
        isClear = false;
        let isArchi = "N";
        if (isArchieved == true) {
            isArchi = "Y"
        }
        let pageNo = 1;
        let sortColumn = "NOTIFY_ID";
        let sortType = "DESC";
        this.setState({ PageNo: pageNo });
        this.pageloadcall(isArchi, "",
            pageNo, this.state.RecCount,
            searchTxt, searchColumn,
            sortColumn, sortType);
        debugger;
        console.log("search", this.state.searchColumn);
        console.log("search", this.state.FromDate);
        this.filtertoggle();
        //this.clearButtonClick();
    }
    clearButtonClick = () => {
        isPostback = false;
        isClear = true;
        this.setState({ FromDate: "", ToDate: "", PageNo: 1  });
        if (isArchieved) {
            this.pageloadcall("Y", "", 1, this.state.RecCount, searchTxt, searchColumn, this.state.SortingColumn, this.state.SortingType);
        }
        else {
            this.pageloadcall("N", "", 1, this.state.RecCount, searchTxt, searchColumn, this.state.SortingColumn, this.state.SortingType);

        }
        this.filtertoggle();
    }
    BackToNotification = (e) => {
        e.preventDefault();
        isClear = true;
        isArchieved = false;

        let pageNo = 1;
        let sortColumn = "NOTIFY_ID";
        let sortType = "DESC";
        searchTxt = "";
        $(`#searchTxt`).val('');
        searchColumn = "All";
        this.setState({ FromDate: "", ToDate: "", GridArr: [], PageNo: pageNo, searchTxt: "", isSearch: false });
        this.pageloadcall("N", "",
            pageNo, this.state.RecCount,
            searchTxt, searchColumn, sortColumn,
            sortType);

    }

    Sorting = (sortType, sortColumn) => {
        let sc;
        debugger;
        isPostback = false;
        isClear = false;
        if (this.state.isSearch) {

            sc = $(`#searchTxt`).val().trim()

        }
        if (this.state.searchTrTxt !== "") {

        }
        let isArchi = "N";
        if (isArchieved == true) {
            isArchi = "Y"
        }
        debugger;
        //this.setState({ sSearchCriteria: sc });
        let pageNo = 1;
        this.pageloadcall(isArchi, "",
            this.state.PageNo, this.state.RecCount,
            searchTxt, searchColumn, sortColumn,
            sortType);
        this.setState({ pageNo: pageNo, SortingColumn: sortColumn, SortingType: sortType });
    }
    handleDatePicker = (date, name) => {
        //date.preventDefault();
        debugger;
        let dateOL = this.handledate(date);
        this.setState({
            [name]: date
        });
        console.log("Date", date);
        console.log("Date", dateOL);
    };

    blurtext = () => {
        searchTxt = $(`#searchTxt`).val().trim();
        console.log("searchTxt", searchTxt);
    }
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        const { t } = this.props;
        return (
            <div>
                <div id="Notification">
                    <div class="container-fluid content-section">
                        <div class="row">

                            <div className="container-fluid content-margin">
                                <div className="row">
                                    {/*[8/12/22, CAS-2301, Madhu] */}
                                    {/*[4/20/22, CAS -31, Dhevanesam R]*/}
                                    <div className="col-md-12 page-heading mt-3 mb-3">
                                        {isArchieved == true ? <>
                                            <h5 className="page-title"><a href="Notifications.html"><img className="mr-2 back-icon" title="Back" src="images/back-arrow.svg" onClick={(e) => this.BackToNotification(e)} /></a><Trans>Archived Notification</Trans></h5>
                                        </>
                                            :<>
                                                <h5 className="page-title"><Trans>Notification</Trans></h5>
                                                <div className="fav-item-maintenance-icon mt-1">
                                                    <input type="checkbox" id="checkbox-Notify" className="checknow" data-toggle="modal" data-target="#Note-confirmation" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite}/>
                                                    <label htmlFor="checkbox-Notify">
                                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                                </g>
                                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                                </g>
                                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                                </g>
                                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                                </g>
                                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                                </g>
                                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                                </g>
                                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </label>
                                                    {/*[4/22/22, CAS - 31, Dhevanesam R]*/}
                                                </div>
                                                {/*[8/12/22, CAS-2301, Madhu] */}
                                                {this.state.totalCount == 0 && isArchieved == false && !this.state.isSearch && $("#searchTxt").val() == "" && !this.state.FromDate && !this.state.ToDate ?
                                                    <>
                                                        <a href="Notification - Archive.html" id="archid" className="btn btn-primary custom-btn-primary primary-btn float-right my-2" onClick={this.ArchivedNotification}><Trans>Archived Notification</Trans></a>
                                                    </> : null}

                                            </>}
                                    </div>
                                    {this.state.totalCount == 0 && !this.state.isSearch && $("#searchTxt").val() == "" && !this.state.FromDate && !this.state.ToDate ?

										 (<section className="site-undercontruction container text-center center-panel pt-4">
                                            <div className="img-section">
                                                <img src="images/nosearch.jpg" alt="imagefile" />
                                            </div>
                                            <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                            </p>
                                        </section>):
                                        <>
                                            <div className="col-md-12" >
                                                <div className="float-right">
                                                    {isArchieved == true ? null :
                                                        <>
                                                            <button className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" data-toggle="modal" data-backdrop="static" data-keyboard="false" name="archivevisible" onClick={this.ArchiveButtonClick.bind(this)}><Trans>Archive All</Trans></button>
                                                            <a href="Notification - Archive.html" className="btn btn-primary custom-btn-primary primary-btn float-left mr-3" onClick={this.ArchivedNotification}><Trans>Archived Notification</Trans> </a>
                                                        </>
                                                    }


                                                    <div className="input-group dropdown-search float-left mb-2">
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.searchDisplayName}</Trans></button>
                                                            <div className="dropdown-menu" style={{}}>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.searchClick(e, "All", "All")}><Trans>All</Trans></a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.searchClick(e, "Notification ID", "NOTIFY_ID")}><Trans>Notification ID</Trans></a>
                                                                {/*<a className="dropdown-item" href="#" onClick={(e) => this.searchClick(e, "Date", "RECEIVEDDATE")}>Date</a>*/}
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.searchClick(e, "Type", "NOTIFY_DESC")}><Trans>type</Trans></a>
                                                                <a className="dropdown-item" href="#" onClick={(e) => this.searchClick(e, "Title", "TITLE")}><Trans>Title</Trans></a>
                                                            </div>
                                                        </div>
                                                        <input type="text" className="form-control" placeholder={t("Search")} aria-label="Text input with dropdown button" id="searchTxt" style={{ 'padding-right': '50px' }} value={this.state.searchTxt} onKeyDown={this.AnykeyPress} onChange={this.SearchTxtChange} />
                                                        {this.state.searchTxt.length > 0 ? <span class="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} > </span> : null}
                                                        <span className="dropdown-search-icon" onClick={this.handleSearch} />
                                                    </div>
                                                    <div className="dropdown float-left">
                                                        <a href="#" className="float-right filter-icon mt-1 mx-2" title="Advanced Filter" onClick={(e) => this.filtertoggle(e)} data-backdrop="static" data-keyboard="false" ><img src="Images/filter-icon.png" alt="Filter Icon" />
                                                        </a>
                                                        <div className={`dropdown-menu order-maintenance-filter m-0 ${this.state.isFilterOpen ? 'd-block' : ''}`} id="advanced-filter" >
                                                            <div className="container-fluid">
                                                                {/*[4/20/22, CAS -31, Dhevanesam R]*/}
                                                                <div className="row">
                                                                    <div className="col-md-12 p-0 pb-2 border-bottom mb-3">
                                                                        <span className="filter-title pl-3"><Trans>Advanced Filter</Trans></span>
                                                                        <button type="button" className="filter-close close pr-2 filter-btn" onClick={(e) => this.filtertoggle(e)}><span className="filter-close-icon" /></button>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {/*[8/30/22, Bug 2550, Poornima S]*/}
                                                                        <label className="filter-date mb-0"><Trans>Created Date</Trans></label></div>
                                                                    <div className="col-md-6 mb-4">
                                                                        <label className="catalog-label mb-0"><Trans>From</Trans></label>
                                                                        {/*<DatePicker className="form-control datepicker"
                                                                    id="FromOrderDate"
                                                                    fixedHeight
                                                                    placeholderText="MM/DD/YYYY"
                                                                    dateFormat="MM/dd/yyyy"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    //dropdownMode="select"
                                                                        //selected={this.state.FromDate}
                                                                        maxDate={this.state.ToDate}
                                                                    onChange={(e)=>this.FromDateChange(e)}
                                                                    value={this.state.FromDate}
                                                                />*/}
                                                                        <DatePicker className="form-control datepicker"
                                                                            id="Fromdateid"
                                                                            fixedHeight
                                                                            placeholderText="MM/DD/YYYY"
                                                                            dateFormat="MM/dd/yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            selected={this.state.FromDate}
                                                                            maxDate={this.state.ToDate}
                                                                            onChange={(e) => this.handleDatePicker(e, "FromDate")}
                                                                        //value={this.state.FromDate}
                                                                        />
                                                                        <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#Fromdateid`).focus()} />
                                                                    </div>
                                                                    <div className="col-md-6 mb-4">
                                                                        <label className="catalog-label mb-0 w-100"><Trans>To</Trans></label>
                                                                        {/*<DatePicker className="form-control datepicker"
                                                                    id="FromOrderDate"
                                                                    fixedHeight
                                                                    placeholderText="MM/DD/YYYY"
                                                                    dateFormat="MM/dd/yyyy"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    //dropdownMode="select"
                                                                        //selected={this.state.ToDate}
                                                                        minDate={this.state.FromDate}
                                                                        onChange={(e) => this.ToDateChange(e)}
                                                                    value={this.state.ToDate}
                                                                />*/}
                                                                        <DatePicker className="form-control datepicker"
                                                                            id="Todateid"
                                                                            fixedHeight
                                                                            placeholderText="MM/DD/YYYY"
                                                                            dateFormat="MM/dd/yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            selected={this.state.ToDate}
                                                                            minDate={this.state.FromDate}
                                                                            onChange={(e) => this.handleDatePicker(e, "ToDate")}
                                                                        //value={this.state.ToDate}
                                                                        />

                                                                        <span className="calendar-icon filter-calendar-icon" onClick={() => $(`#Todateid`).focus()} />
                                                                    </div>
                                                                    <div className="col-md-12 mb-2">
                                                                        <div className="float-right">
                                                                            <button className="btn btn-primary filter-clear-btn filter-btn px-4 mt-2 mr-2" onClick={this.clearButtonClick}><Trans>Clear</Trans></button>
                                                                            <button className="btn btn-primary custom-btn-primary  filter-btn  primary-btn wallet-btn mt-2 px-4" onClick={this.ApplyButtonClick}><Trans>Apply</Trans></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*[8/12/22, CAS-2301, Madhu] */}
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table ZEUS_Grid FOM-grid mobileflex-grid">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="text-center" />
                                                                <th scope="col" className="position-sort-icon"><Trans>Notification ID</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "NOTIFY_ID")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "NOTIFY_ID")}><img src="Images/sort1.svg" /></span></th>
                                                                <th scope="col" className="position-sort-icon"><Trans>Date</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "DTTMADDED")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "DTTMADDED")}><img src="Images/sort1.svg" /></span></th>
                                                                <th scope="col" className="position-sort-icon"><Trans>type</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "NOTIFY_DESC")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "NOTIFY_DESC")}><img src="Images/sort1.svg" /></span></th>
                                                                <th scope="col" className="position-sort-icon"><Trans>Title</Trans><span className="sort-up ml-1" onClick={(e) => this.Sorting("ASC", "TITLE")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.Sorting("DESC", "TITLE")}><img src="Images/sort1.svg" /></span></th>
                                                                {isArchieved == true ? null :
                                                                    <th scope="col" className="text-center"><Trans>Archive</Trans></th>}
                                                                <th scope="col" className="text-center"><Trans>Download</Trans></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.GridArr.length > 0 ?
                                                                this.bindNotifictionGrid() :
                                                                (

                                                                    <tr className="border-0">
                                                                        <td colSpan="11" className="search-padding-none">
                                                                            <section className="site-undercontruction container text-center center-panel pt-4">
                                                                                <div className="img-section">
                                                                                    <img src="images/nosearch.jpg" alt="imagefile" />
                                                                                </div>
                                                                                <p className="search-result-content pt-4"><Trans>Sorry, we couldn’t find any search result</Trans>
                                                                                </p>
                                                                            </section>
                                                                        </td>
                                                                    </tr>)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </> }
                                </div>

                                <div className="col-md-12">{this.state.totalCount>0?
                                    <span className="total-records mt-2 d-inline-block">
                                        <Trans>Number of Records</Trans>:<span className="ml-2">{this.state.totalCount}</span>
                                    </span> : null}
                                    {this.state.totalCount > this.state.RecCount ? <ReactPaginate
                                        previousLabel={<span className="right-pagination-arrow" />}
                                        nextLabel={<span className="left-pagination-arrow" />}
                                        previousClassName={'page-item'}
                                        nextClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        breakLabel={'...'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'custom-pagination pagination float-right'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        hrefBuilder={() => '#'}
                                        forcePage={this.state.PageNo - 1}
                                    /> : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div >


                <div className="modal" id="notification-details">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Notification Details</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body pr-0 ml-2 marginleft-0" id="d">

                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal" id="Cancel-confirmation">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" />
                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row text-center">
                                    <div className="col-md-12 my-4">
                                        <span className="question-mark-icon" />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label className="confirm-label d-block"><Trans>Confirm</Trans></label>
                                        <label className="confirm-content d-block"><Trans>Do you want to archive this notification?</Trans></label>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-5" data-dismiss="modal" onClick={this.ConfirmationNO}><Trans>No</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn px-5" data-dismiss="modal" onClick={this.ConfirmationYES}><Trans>Yes</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    };

}
//[PC_07, 09/07/21, Author - Priyanka D]
export default withTranslation()(UserNotificationComponent);