import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as CookieService from '../CookieService';
const RSTPwdBtn_Clk = (RSTPwdBtn_ClkBO) => {
    try {
        debugger;
        SDISVC.callGateway(
            SDIConstants.moduleNames.ForgotPassword,
            SDIConstants.methodNames.ForgotPassword_CustomValidator2_ServerValidate,
            JSON.stringify(RSTPwdBtn_ClkBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "Forgot_Password", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                //else if (data.success == false) {
                //    if (data.response) {
                //        if (data.response == SDIConstants.tokenExpired) {
                //            tokenExpired();
                //        }
                //    }
                //}
            });
    } catch (ex) {
        console.log(ex)
    }
};
export { RSTPwdBtn_Clk}
