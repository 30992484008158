import * as $ from 'jquery';
import SDIConstants,{ getAPIURL} from './SDIConstants';
import * as CookieService from './CookieService';
import { saveAs } from 'file-saver';
let callSecureGateway = async (module, method, data, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        let payload = { moduleName: module, methodName: method, data: data, accessMode: 2, userID: CookieService.getLocalStorageValues("Session_UserID") };
        
        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/api/SDISecure/messageInterface`,
            data: payload ,
            headers: {
                "Authorization": "Bearer " + CookieService.getCookieValue(SDIConstants.access_token)
            }
        });
        let isTokenExpired = false;
        let result = {};
        if (response)
            isTokenExpired = response.indexOf("Token Expired")>= 0 ? true : false;
        if (isTokenExpired == false) {
            CookieService.updateLocalStorageValues("isLoginSuccess", true);
             result = {
                "success": true,
                "response": response
            };
        } else {
            CookieService.updateLocalStorageValues("isLoginSuccess", true);
            result = {
                "success": false,
                "response": SDIConstants.tokenExpired
            };
        }
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": ex
        };
        callback(result);
    }
  
}
let callRecurringGateway = async (module, method, data, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        let payload = { moduleName: module, methodName: method, data: data, accessMode: 2, userID: CookieService.getLocalStorageValues("Session_UserID") };
        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/api/SDITimeOut/messageInterface`,
            data: payload,
            headers: {
                "Authorization": "Bearer " + CookieService.getCookieValue(SDIConstants.access_token)
            }
        });
        let isTokenExpired = false;
        let result = {};
        if (response)
            isTokenExpired = response.indexOf("Token Expired") >= 0 ? true : false;
        if (isTokenExpired == false) {
            result = {
                "success": true,
                "response": response
            };
        } else {
            result = {
                "success": false,
                "response": SDIConstants.tokenExpired
            };
        }
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": ex
        };
        callback(result);
    }

}
let callGateway = async (module, method, data, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        let payload = { moduleName: module, methodName: method, data: data,accessMode: 2 };
        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/api/SDIAPI/messageInterface`,
            data: payload,
        });
        let result = {
            "success": true,
            "response": response
        };
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": ex
        };
        callback(result);
    }

}
let uploadFile = async (module, method, data, files, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        // Create FormData object
        let fileData = new FormData();
        // Looping over all files and add it to FormData object
        for (let i = 0; i < files.length; i++) {
            fileData.append(files[i].name, files[i]);
        }
        let payload = { moduleName: module, methodName: method, data: data, accessMode: 2 };
        fileData.append('supportData', JSON.stringify(payload));
        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/api/SDIFile/upload`,
            data: fileData,
            processData: false,
            contentType: false
        });
        let result = {
            "success": true,
            "response": response
        };
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": ex
        };
        callback(result);
    }

}
let downloadFile = async (module, method, data, fileName, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        let payload = { moduleName: module, methodName: method, data: data, accessMode: 2 };
        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/api/SDIFile/download`,
            data: payload,
            xhrFields: {
                // make sure the response knows we're expecting a binary type in return.
                // this is important, without it the excel file is marked corrupted.
                responseType: 'arraybuffer'
            }
        });
        let file = new Blob([response], { type: "application/octet-stream" });
        saveAs(file, fileName);
        let result = {
            "success": true,
            "response": ""
        };
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": ex
        };
        callback(result);
    }

}
let receiveFileBase64 = async (module, method, data, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        let payload = { moduleName: module, methodName: method, data: data, accessMode: 2 };
        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/api/SDIFile/download`,
            data: payload,
            xhrFields: {
                // make sure the response knows we're expecting a binary type in return.
                // this is important, without it the excel file is marked corrupted.
                responseType: 'arraybuffer'
            }
        });
        let file = new Blob([response], { type: "application/pdf" });
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            var base64data = reader.result;
            let result = {
                "success": true,
                "response": base64data
            };
            callback(result);
            //console.log(base64data);
        }
      
        
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": ex
        };
        callback(result);
    }

}
let login = async (loginData, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        var data = "grant_type=password&username=" + loginData.userName + "&password=" + loginData.password + "&accessMode=2&failureAttemptCount=" + loginData.failureAttemptCount + "&failureAttemptUname=" + loginData.failureAttemptUname + "&PARAM_DESTINATION_URL=" + loginData.PARAM_DESTINATION_URL + "&PunchinEnable=" + loginData.PunchinEnable + "&AddPunchinUserEnable=" + loginData.AddPunchinUserEnable + "&UserEmailId=" + loginData.UserEmailId + "&workorderNumber=" + loginData.workorderNumber + "&authToken=" + loginData.authToken + "&isSrcWeb=" + loginData.isWeb; //[05/05/23, PS_WVF_03, Zeus 86, Sathis. N]-work order validation stanford

        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/token`,
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        let result = {
            "success": true,
            "response": response
        };
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": "",
            "error":ex
        };
        callback(result);
    }

}
//********Punchin***********
let punchin = async (punchinData, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        var data = "grant_type=password&username=" + punchinData.userName + "&password=" + punchinData.password + "&accessMode=2&failureAttemptCount=1&failureAttemptUname= &PARAM_DESTINATION_URL=" ;
        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}/token`,
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        let result = {
            "success": true,
            "response": response
        };
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": "",
            "error": ex
        };
        callback(result);
    }

}
      //[15/05/23, PS_EWVB_04, Zeus 112, Sathis. N]- EMCOR WO validation
let callAsync = async (module, method, data, callback) => {
    let serviceURL = await getAPIURL();
    console.log("serviceURL", serviceURL);
    try {
        let payload = { moduleName: module, methodName: method, data: data, accessMode: 2, userID: CookieService.getLocalStorageValues("Session_UserID") };

        let response = await $.ajax({
            type: "POST",
            url: `${serviceURL}api/SDISecureAsync/messageInterface`,
            data: payload,
            headers: {
                //[10/3/22], PS_AT_03, WAL - Login authorization token (load balancer fix) - To pass user ID for basic authentication, Dhevanesam R
                "UserName": CookieService.getLocalStorageValues("Session_UserID"),
                "Authorization": "Bearer " + CookieService.getCookieValue(SDIConstants.access_token)
            }
        });
        let isTokenExpired = false;
        let result = {};
        if (response)
            isTokenExpired = response.indexOf("Token Expired") >= 0 ? true : false;
        if (isTokenExpired == false) {
            result = {
                "success": true,
                "response": response
            };
        } else {
            result = {
                "success": false,
                "response": SDIConstants.tokenExpired
            };
        }        
        callback(result);
    }
    catch (ex) {
        let result = {
            "success": false,
            "response": ex
        };
        callback(result);
    }

}

//********************
//*****don't forget to remove punchin in exporting part*****************
export { callSecureGateway, callRecurringGateway, callGateway, uploadFile, downloadFile, receiveFileBase64, login, punchin, callAsync }