/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/11/2020
 * 
 * Ref: NC_PC_32
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class NonCatalogStore extends EventEmitter {
    pageLoadData = "";
    quickItemcheck = "";
    saveResponse = "";
    checkCatalogResponse = "";
    attachmentResponse = "";
    removeAttachmentResponse = "";
    currentFavPageResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    /**
    * Ref: NC_PC_33
    * 
    * Declare the store call that extends "EventsEmitter"
    * Handle the response from action to component
    */
    handleNonCatalogStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.NonCatalog_PageLoad: {
                this.pageLoadData = action.action.response;
                this.emit('load');
                break;
            }
            case SDIConstants.methodNames.NonCatalog_SaveNonCatalogEntry: {
                this.saveResponse = action.action.response;
                this.emit('save');
                break;
            }
            case "NONCAT_QuickItemCheck": {
                this.quickItemcheck = action.action.response;
                this.emit('quickItemCheck');
                break;
            }
            //Start of commenting[10/16/23, Zeus-218] - Code is commented because Check catalog functionality is been removed from the Panel
            /*case "NONCAT_CheckCatalog_Click": {
                this.checkCatalogResponse = action.action.response;
                this.emit('checkCatalogResponse');
                break;
            }*/
            //End of commenting
            case "NONCAT_UploadAttachments": {
                this.attachmentResponse = action.action.response;
                this.emit('attachmentResponse');
                break;
            }
            case "NONCAT_RemoveAttachment": {
                this.removeAttachmentResponse = action.action.response;
                this.emit('rmvAttach');
                break;
            }
            case "NONCAT_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "NONCAT_InsertFavPage": {
                
                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NONCAT_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }

        }
    }
}
const objNonCatalogStore = new NonCatalogStore;
// Dispatcher code
Dispatcher.register(objNonCatalogStore.handleNonCatalogStore.bind(objNonCatalogStore));
export default objNonCatalogStore;