import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class UserNotificationStore extends EventEmitter {
    PageLoadData = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleNotificationStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.UserNotification_PagLoad: {
                this.PageLoadData = action.action.response;
                this.emit('Notificationload');
                break;
            }

            case SDIConstants.methodNames.UserNotification_imgArchive_Command: {
                this.PageLoadData = action.action.response;
                this.emit('ArchiveUpdate');
                break;
            }

            case SDIConstants.methodNames.UserNotification_NotifyDetail: {
                this.PageLoadData = action.action.response;
                this.emit('NotifyDetail');
                break;
            }

            case SDIConstants.methodNames.UserNotification_FileDownload: {
                this.PageLoadData = action.action.response;
                this.emit('FileDownload');
                break;
            }
           

            case "NOTIFY_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }
}
const objNotificationStore = new UserNotificationStore;
// Dispatcher code
Dispatcher.register(objNotificationStore.handleNotificationStore.bind(objNotificationStore));
export default objNotificationStore;