import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';

class SupplierCallInStore extends EventEmitter {
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    pageLoadData = "";
    //[PS_PL_20,Kishore], This method is used to store the response data from the action file and emit it to the component
    handleSupplierCallInStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.SupplierCallIn_PageLoad: {
                this.pageLoadData = action.action.response;
                this.emit('SupplierCallIn_PageLoad');
                break;
            }
            case "NOTIFY_InsertFavPage": {
                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }

}
const objSupplierCallIn = new SupplierCallInStore;
// Dispatcher code
Dispatcher.register(objSupplierCallIn.handleSupplierCallInStore.bind(objSupplierCallIn));
export default objSupplierCallIn;