import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class PunchInRFQConfirmStore extends EventEmitter {
    ResponseValue = "";
    handlePunchInRFQConfirmStore = (action) => {
        switch (action.action.type) {
            case "PageLaod": {
                this.ResponseValue = action.action.response;
                this.emit('PageLaod');
                break;
            }
            
        }
    }
}
const objPunchInRFQConfirmStore = new PunchInRFQConfirmStore;
Dispatcher.register(objPunchInRFQConfirmStore.handlePunchInRFQConfirmStore.bind(objPunchInRFQConfirmStore));
export default objPunchInRFQConfirmStore;