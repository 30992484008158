
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

const tokenExpired = async () => {
    try {
        let tokenExpiredData = { type: "Token_Expired", response: true };
        Dispatcher.dispatch(tokenExpiredData);

    } catch (ex) {
        console.log(ex)
    }
};

const addToCartItemsRTI = (addToCartItemsBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.ItemDetailNew,
            SDIConstants.methodNames.ItemDetailNew_imgBtnAddToCart_Click,
            JSON.stringify(addToCartItemsBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "addToCartRTI", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
};
export {
    addToCartItemsRTI
}