/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 07/30/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: LN_PC_15
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as CookieService from '../CookieService';
/**
 * Ref: LN_PC_16
 * 
 * pageLoad method gets called from the componentWillMount() of the component.
 * It takes a parameters loginPageLoadBO which is passed from the component file.
 * @param loginPageLoadBO
 */
const pageLoad = (loginPageLoadBO) => {
    try {
    SDISVC.callGateway(
    SDIConstants.moduleNames.Login, 
    SDIConstants.methodNames.Login_Pageload, 
    JSON.stringify(loginPageLoadBO),
    (data) => {
        if (data.success) {
            if (data.response != "" && data.response) {
                let contentData = { type: SDIConstants.methodNames.Login_Pageload, response: data.response };
                Dispatcher.dispatch(contentData);
            }
        }
            });
    } catch (ex) {
        console.log(ex)
    }
}
/**
 * Ref: LN_PC_17
 * 
 * loginClick method gets called from the loginClick() of the component.
 * It takes a parameters loginClickBO which is passed from the component file.
 * @param loginClickBO
 */
const loginClick = (loginClickBO) => {
    try {
        debugger;
        SDISVC.login(
            loginClickBO,
            (data) => {
                if (data.success) {
                    debugger;
                    if (data.response != "" && data.response) {
                        CookieService.setCookieValues(SDIConstants.access_token, data.response.access_token, 30);
                        let userData = JSON.parse(data.response.userData);
                        userData = userData.LoginClickFlags_UpdatetoSession
                        if (userData.length > 0) {
                            userData = userData[0];
                        }
                        console.log(userData)
                        //[10/18/21, PC_04, Bug ID - 1736, Dhevanesam R]
                        let newLang = CookieService.getLocalStorageValues("newlanguage"); 
                        let qryOrderStatus = CookieService.getLocalStorageValues("currentPath");
                        let isOrderStatus = CookieService.getLocalStorageValues("QryOrderStatus");
                        let qryWO = CookieService.getLocalStorageValues("QryWONumber"); 
                        console.log("logaclang1", newLang);
                        //[05/20/22, I0629 BU login issue due to presence of "-" so replacing it with "–", Poornima S]
                        if (userData.Session_CONAME != null && userData.Session_CONAME != undefined) {
                            userData.Session_CONAME = userData.Session_CONAME.replace("–", "-");
                        }
                        let localStorageSuccess = CookieService.insertLocalStorageValues(userData);
                        let rmvndr = userData.REMOVE_VNDR_ID ? userData.REMOVE_VNDR_ID.split("||") : userData.REMOVE_VNDR_ID;
                        CookieService.updateLocalStorageValues("RemoveVNDR", rmvndr);
                        CookieService.updateLocalStorageValues("newlanguage", newLang);
                        CookieService.updateLocalStorageValues("currentPath", qryOrderStatus);
                        CookieService.updateLocalStorageValues("QryWONumber", qryWO);
                        CookieService.updateLocalStorageValues("QryOrderStatus", isOrderStatus);
                        CookieService.updateLocalStorageValues("pickupVendor", JSON.parse(data.response.userData).pickupVendor);
                        console.log("logaclang", CookieService.getLocalStorageValues("newlanguage"));


                        var WoList = [];
                        WoList= JSON.parse(data.response.userData);
                        WoList = WoList.blanketWoList
                        if (WoList != null && WoList != undefined && WoList != "") {
                            CookieService.updateLocalStorageValues("blanketWoAndDesc", WoList);
                            CookieService.updateLocalStorageValues("blanketWo", WoList[0].VALUE);

                        } else {
                            CookieService.updateLocalStorageValues("blanketWoAndDesc", []);
                            CookieService.updateLocalStorageValues("blanketWo", []);
                        }
                        let a = CookieService.getLocalStorageValues("blanketWoAndDesc");
                        let b = CookieService.getLocalStorageValues("blanketWo");
                        

                        if (localStorageSuccess) {
                            debugger;
                           let tokenUpdateBO = {
                                userID: CookieService.getLocalStorageValues("Session_UserID"),
                                busUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                                accessMode: 2,
                               deviceInfo: "WebAPP",
                               expirySeconds: data.response.expires_in
                            }
                            SDISVC.callSecureGateway(
                                SDIConstants.moduleNames.Login,
                                SDIConstants.methodNames.Login_TokenUpdate,
                                JSON.stringify(tokenUpdateBO),
                                (data) => {
                                    if (data.success) {
                                        debugger;
                                        if (data.response != "" && data.response) {
                                            console.log(data.response);
                                            let contentData = { type: SDIConstants.methodNames.Login_TokenUpdate, response: data.response };
                                            Dispatcher.dispatch(contentData);
                                        }
                                    }
                                });
                        }
                    }
                } else if (data.success == false) {
                    debugger;
                    let contentData = { type: "Login_Error", response: data.error.responseText };
                    Dispatcher.dispatch(contentData);
                }
            });
    }catch (ex) {
        console.log(ex)
    }
}
//[5/18/2023]Zeus 107  SSO implementation - Poonima S
const SSOLoginFlagCheck = (email) => {
    try {
        SDISVC.callGateway(
            SDIConstants.moduleNames.Login,
            SDIConstants.methodNames.CheckSSOLoginFlag,
            email,
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "SSOLoginCheck", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const workOrderValidation = async (workOrderBO) => {
    try {
        debugger
        SDISVC.callAsync(
            SDIConstants.moduleNames.ServiceChannel,
            SDIConstants.methodNames.ServiceChannel_GetWorkOrderValidation,
            JSON.stringify(workOrderBO),
            (data) => {
                debugger
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ValidateWO", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
               
            });

    } catch (ex) {
        console.log(ex)
    }
};



export { pageLoad, loginClick, SSOLoginFlagCheck, workOrderValidation}