// 1/9/2024 Mythili Zeus-241 Product Replen panel

//import nessecessary packages
import React, { Component } from 'react';
import * as ProductReplenAction from '../actions/ProductReplenAction';
import ProductReplenStore from '../stores/ProductReplenStore';
import * as $ from 'jquery';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation, Trans } from 'react-i18next'
import * as MenuAction from '../actions/MenuAction';
import swal from 'sweetalert';
import Switch from '@material-ui/core/Switch';
let currentPagePath;
class ProductReplenComponent extends Component {
    //PC_PR_APP_18 to PC_PR_APP_38 Intialize state variables with their default value
    constructor(props) {
        super(props);
        this.state = {
            cartItemsList: [],
            itemId: "",
            description: "",
            vendor: "",
            cost: "",
            quantity: 0,
            rushorder: false,
            lineNo: "",
            itemSearchDetails: [],
            cribDD: [],
            cribValue: [],
            imageLink: "",
            userId: CookieService.getLocalStorageValues("Session_UserID"),
            bu: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            deleteindex: "",
            action: "",
            isFavorited: false,
            errorNoItem: false,
            errorInvItem: false,
            disableItemFiled: false,
            errorQuantity: false,
            errorBU:false
        };

    }
    //PC_PR_APP_40 to PC_PR_APP_40 45 invoke ItemLoad methods and add listerners for ItemLoadResponse, CartLoadResponse and placeCartResponse
    componentDidMount() {
        debugger;
        const { t } = this.props;
        ProductReplenStore.on('itemLoadResponse', this.itemLoadResponse);
        ProductReplenStore.on('cartLoadResponse', this.cartLoadResponse);
        ProductReplenStore.on('placeCartResponse', this.placeCartResponse);

        ProductReplenStore.on('favpage', this.loadFavPageResponse.bind(this));
        ProductReplenStore.on('updateFavPage', this.updateFavPageResponse.bind(this));

        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        ProductReplenAction.getCurrentFavPage(getFavPageBO);

        this.itemLoad();

    }
    //remove the listeners ItemLoadResponse CartLoadResponse and placeCartResponse while un mounting
    componentWillUnmount() {
        ProductReplenStore.removeListener('itemLoadResponse', this.itemLoadResponse);
        ProductReplenStore.removeListener('cartLoadResponse', this.cartLoadResponse);
        ProductReplenStore.removeListener('placeCartResponse', this.placeCartResponse);

        ProductReplenStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        ProductReplenStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    //to handle removing page from favorites
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Product Replenishment",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            ProductReplenAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            ProductReplenAction.deleteFavPage(deleteFavPageBO)
        }
    };
    //to get if the page is favorite or not
    loadFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let favPageResponse = JSON.parse(ProductReplenStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    // to addthe page to favorite list
    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ProductReplenStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(ProductReplenStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(ProductReplenStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    //PC_PR_APP_50 to PC_PR_APP_79 method to load the search, cart, available Bu and default bu
    itemLoad = () => {
        debugger;
        let ItemDetailBO = {
            itemId: this.state.itemId.toUpperCase().trim(),
            cribId: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_userid: CookieService.getLocalStorageValues("Session_UserID"),
            Session_busUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            AgentUserid: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            AgentUserBu: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_SdiEmp: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_UserName: CookieService.getLocalStorageValues("Session_USERNAME"),
            Session_SdiCat: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_UseSOLRSearch: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_Search3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SearchORONasco: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_searchapp: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SearchMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SearchIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SearchSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_AmazonSearch: CookieService.getLocalStorageValues("Session_AMAZONSEARCH"),
            Source: "WEB",
            Session_SitePrefix: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_CustId: CookieService.getLocalStorageValues("Session_CUSTID"),
            Session_ItemMode: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            cosigned: true,
            location: CookieService.getLocalStorageValues("Session_STATE") != null ? CookieService.getLocalStorageValues("Session_STATE") : "",
            shipToID: CookieService.getLocalStorageValues("HOME_STORE") != null && CookieService.getLocalStorageValues("HOME_STORE") != undefined && CookieService.getLocalStorageValues("HOME_STORE") != "" ? CookieService.getLocalStorageValues("HOME_STORE") : " ",
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        ProductReplenAction.itemLoad(ItemDetailBO);
    };
    //PC_PR_APP_134 set the value of the item id field in a state variabl on change
    handleItemChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorNoItem: false,
            errorInvItem:false
        });
        $("#itemId").removeClass("validation");
    };
    //PC_PR_APP_47,PC_PR_APP_48,PC_PR_APP_49 to check if the item id filed is empty or not and call the itemload method
    handleSearchItem = () => {
        debugger;
        if (this.state.itemId.trim() == "") {
            $("#itemId").addClass("validation");
            this.setState({
                errorNoItem: true,
                action:""
            });
        }
        else {
            this.setState({
                action: ""
            });
            this.itemLoad();
        }
    };
    //PC_PR_APP_197,PC_PR_APP_198 to call itemLoad() onpressing the enter button
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            if (this.state.itemId.trim() == "") {
                $("#itemId").addClass("validation");
                this.setState({
                    errorNoItem: true,
                    action: ""
                });
            }
            else {
                this.setState({
                    action: ""
                });
                this.itemLoad();
            }
        };
    };
    //PC_PR_APP_84 to PC_PR_APP_107 to get the response of item load method and bind data
    itemLoadResponse = () => {
        debugger;
        let ItemList = JSON.parse(ProductReplenStore.itemLoadResponse);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ItemList != undefined && ItemList != null && ItemList != "" )
        {
            if (ItemList.hasOwnProperty('isException')) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Technical issue please contact SDI help desk"
                }).then(() => {
                    window.location.href = '/';
                });
            }
            else {
                debugger;
                let itemSearchDetails = ItemList.v_item_details
                if (itemSearchDetails != undefined && itemSearchDetails != null && itemSearchDetails != "") {

                    if (itemSearchDetails.length === 1 && this.state.action != "ADD") {
                        let itemLoc = itemSearchDetails[0];
                        this.setState({
                            itemId: itemLoc.item_no,
                            description: itemLoc.description,
                            vendor: itemLoc.vendor,
                            cost: itemLoc.std_cost,
                            quantity: "",
                            rushorder: false,
                            imageLink: itemLoc.ImageLink,
                            itemSearchDetails: itemSearchDetails,
                            errorInvItem: false,
                            disableItemFiled:true
                        });
                        
                    }
                    
                        $("#itemId").removeClass("validation");
                    this.setState({
                        errorInvItem: false
                    });
                }
                else if (this.state.itemId != "") {
                    $("#itemId").addClass("validation");
                    this.setState({
                        errorInvItem: true
                    });
                }
                $("#quantity").removeClass("validation");
                this.setState({
                    errorQuantity: false
                });
                let locArr1 = [];
                let cribListLoc = ItemList.v_crib_list;
                if (cribListLoc != undefined && cribListLoc != null && cribListLoc != "") {
                    if (cribListLoc.length > 0) {
                        cribListLoc.map((value) => {
                            let locArr = { label: value.BUUNIT, value: value.BUUNIT };
                            locArr1.push(locArr);
                        })

                    }
                }
                let locArr2 = []
                let cartListLoc = ItemList.v_cart_list;                
                if (cartListLoc != undefined && cartListLoc != null && cartListLoc != "") {
                    if (cartListLoc.length > 0) {
                        cartListLoc.map((value) => {
                            let locArr = {
                                bu: value.Unit,
                                item_no: value.Item,
                                description: value.Description,
                                orderQty: value.Quantity,
                                unitPrice:value.Cost,
                                rushOrder: value.rush,
                                lineNo: value.line_nbr,
                                imageLink: value.ImageLink,
                                vendor: value.Vendor,
                                userId: value.operator_id
                            }; 
                            locArr2.push(locArr);
                        })
                        $("#quantity").removeClass("validation");
                        this.setState({
                            errorQuantity: false
                        });
                        
                        
                    }
                   
                }
                let locArr3 = [];
                let cribLoc = ItemList.v_crib;
                if (cribLoc != undefined && cribLoc != null && cribLoc != "") {
                    let locArr = [];
                    if (cribLoc.length !== 0) {
                        cribLoc.map((value) => {
                            locArr = { label: value.BU, value: value.BU };
                            locArr3 = locArr;
                        })
                    }
                    else {
                        locArr3 = [];
                    }
                }
                this.setState({
                    cribDD: locArr1,
                    cartItemsList: locArr2,
                    cribValue: locArr3
                });

                window.scrollTo(0, 0);
            }
        }
        
    };
    
    //PC_PR_APP_203, PC_PR_APP_204 to  clear the entered item id
    clearItemID = () => {
        this.setState({
            itemId: "",
            action: ""
        });
        $("#itemId").removeClass("validation");
        this.setState({
            errorNoItem: false,
            errorInvItem: false,
        });
    }; 
    //PC_PR_APP_159 to store the selected bu value in a state variable
    handleCribChange = (e) => {
        debugger;
        this.setState({
            cribValue: e,
            errorBU:false
        });
    };
    //PC_PR_APP_151 to PC_PR_APP_155 to store the correct qyt enterd by the user in a state variable
    handleQTYChange = (event) => {
        debugger;
        const re = SDIConstants.quantityRegEX;
        console.log("Quantity", event.target.value)
        if (re.test(event.target.value)) {
            let txtQTY = Math.round(event.target.value);
            if (txtQTY !== 0 ) {
                this.setState({ [event.target.name]: event.target.value });
            }
        }
        if (event.target.value == "") {
            this.setState({ [event.target.name]: event.target.value });
        }
        $("#quantity").removeClass("validation");
        this.setState({
            errorQuantity: false
        });
    };
    //PC_PR_APP_160,PC_PR_APP_161 to set the value of the toggle switch in a state variable
    handleToggleChangeItem = (checked) => {
        debugger;
        this.setState({ rushOrder: checked });
    };
    //PC_PR_APP_199 to PC_PR_APP_202 to validate the item qty before adding to the cart
    validateQty = () => {
        debugger;
        let quantity = this.state.quantity;
        if (quantity == "" || parseInt(quantity) == 0) {
            $("#quantity").addClass("validation");
            this.setState({
                errorQuantity: true
            });
            return false;
        }
        else {
            $("#quantity").removeClass("validation");
            this.setState({
                errorQuantity: false
            });
            return true;
        }
    };
    //PC_PR_APP_108 to PC_PR_APP_121 invoked on clicking the add an item 
    addItemToCart = () => {
        debugger;
        let cribloc = this.state.cribValue;
        if (cribloc.length == 0) { 
            this.setState({
                errorBU: true
            });           
        }
        else {
            this.setState({
                errorBU: false
            });
            
            let itemDetailLoc = this.state.itemSearchDetails;
            if (this.validateQty()) {               
                let CartItemBO = {
                    userId: this.state.userId,
                    bu: cribloc.value,
                    itemId: this.state.itemId,
                    orderqty: this.state.quantity,
                    rushOrder: this.state.rushOrder == true ? "Y" : "N",
                    action: "ADD",
                    lineNo: "0"
                };
                //{ "userId": "MYTHILIS1", "bu": "I0260", "itemId": "N170000000016", "orderqty": "5", "action": "ADD", "rushOrder": "N", "lineNo": "0" }
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                ProductReplenAction.cartLoad(CartItemBO);
                //this.setState({
                //   
                //});
            }
        }
        
        
    };
    //PC_PR_APP_126 to PC_PR_APP_133 to bind the updated cart items
    cartLoadResponse = () => {
        debugger;
        let ResultResponse = JSON.parse(ProductReplenStore.cartLoadResponse);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        $('#DeleteItem').modal('hide');
        if (ResultResponse != undefined || ResultResponse != null || ResultResponse != "") {
            if (ResultResponse.hasOwnProperty('isException')) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Technical issue please contact SDI help desk"
                });
            }
            else {
                debugger;
                if (ResultResponse.RowAffected === 0) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "Technical issue please contact SDI help desk"
                    });
                }
                else {
                    if (ResultResponse.Action == "ADD") {
                        this.setState({
                            itemId: "",
                            disableItemFiled: false,
                            action: "ADD",
                            description: "",
                            vendor: "",
                            cost: "",
                            quantity: "",
                            rushOrder: false,
                            imageLink: "",
                            itemSearchDetails: []
                        });
                    }
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    this.itemLoad(); 
                }
            }
        }
    };
    //PC_PR_APP_135 to PC_PR_APP_147 Invoked on clciking the Yes button in the delete confirmation popup
    removeItemPopUp = () => {
        let index = this.state.deleteItemIndex;
        let itemToDelete = this.state.cartItemsList;
        let CartItemBO = {
            userId: itemToDelete[index].userId,
            bu: itemToDelete[index].bu,
            itemId: itemToDelete[index].item_no,
            orderqty: itemToDelete[index].orderQty,
            rushOrder: itemToDelete[index].rushOrder == true ? "Y" : "N",
            lineNo: itemToDelete[index].lineNo,
            action: "DELETE"
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        ProductReplenAction.cartLoad(CartItemBO);
        //this.setState({
        //    action: "DELETE",
        //});
    };
    //PC_PR_APP_148 to PC_PR_APP_150 to set the index of item to be delete in a state variable
    onClickRemoveItem = (e, index) => {
        debugger;
        this.setState({
            deleteItemIndex: index
        });
        $('#DeleteItem').modal('show');
    };
    //PC_PR_APP_155 to PC_PR_APP_158 to set the correct quantity in the cart
    handleQTYChangeCart = (index, e) => {
        debugger;
        const re = SDIConstants.quantityRegEX;
        let newQty = e.target.value;
        let { cartItemsList } = this.state;
        if (re.test(newQty)) {
            let txtQTY = Math.round(e.target.value);
            if (txtQTY !== 0) {
                cartItemsList[index].orderQty = parseInt(newQty);
                let quantityId = "#quantity" + index;
                let errorQuantityId = "#errorQuantity" + index;
                $(quantityId).removeClass("validation");
                $(errorQuantityId).hide();
                this.setState({ cartItemsList });
            }
        }
        if (newQty.trim() == "") {
            cartItemsList[index].orderQty = parseInt(0);
            this.setState({ cartItemsList });
        }
        
    };
    //PC_PR_APP_162, PC_PR_APP_163 to store the toggle swict value in the cart
    handleToggleChangeCart = (index, newValue) => {
        debugger;
        let { cartItemsList } = this.state;
        cartItemsList[index].rushOrder = newValue == true ? "Y" : "N";
        this.setState({ cartItemsList });
    };
    //PC_PR_APP_164 to PC_PR_APP_175 to cleare the searched item.
    cancleItem = () => {
        debugger;
        this.setState({
            itemId: "",
            description: "",
            vendor: "",
            cost: "",
            quantity: 0,
            rushorder: false,
            imageLink: "",
            itemSearchDetails: [],
            disableItemFiled: false,
            errorNoItem: false,
            errorInvItem: false
        });
        $("#itemId").removeClass("validation");
    };
    //PC_PR_APP_176 to PC_PR_APP_179 invoked on clicking the place cart button
    placeCart = () => {
        debugger;        
        
        let isValidQty = this.ValidateQtyField();
        debugger;
        if (isValidQty) {
            let CartItemBO = [];
            if (this.state.cartItemsList.length > 0) {
                this.state.cartItemsList.forEach((item, index) => {
                    console.log(item.orderQty)
                    let CartItem = {
                        userId: item.userId,
                        bu: item.bu,
                        itemId: item.item_no,
                        orderqty: item.orderQty,
                        rushOrder: item.rushOrder,
                        action: "PLACE"
                    };
                    CartItemBO.push(CartItem)
                })
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            ProductReplenAction.placeCart(CartItemBO);
            //this.setState({
            //    action: "PLACE",
            //});
            
        }
        
    };
    //PC_PR_APP_192 to PC_PR_APP_196 to validate the qtys of the cart items
    ValidateQtyField = () => {
        debugger;
        let item = this.state.cartItemsList;
        let isValidQty = true;
        if (item && item.length > 0) {
            item.forEach((item, index) => {
                if (item.orderQty == null || item.orderQty == "") {
                    let quantityId = "#quantity" + index;
                    let errorQuantityId = "#errorQuantity" + index;
                    $(quantityId).addClass("validation");
                    $(errorQuantityId).show();
                    isValidQty = false;
                }
            })
        }  
        return isValidQty;
    };
    //PC_PR_APP_184 to PC_PR_APP_191 to check the response of Place cart method and show sucess popup
    placeCartResponse = () => {
        debugger;
        let ResultResponse = JSON.parse(ProductReplenStore.placeCartResponse);
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ResultResponse != undefined || ResultResponse != null || ResultResponse != "") {
            if (ResultResponse.hasOwnProperty('isException')) {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: "Technical issue please contact SDI help desk"
                });
            }
            else {
                debugger;
                if (ResultResponse.RowAffected > 0) {
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    let CartItemBO = [];
                    if (this.state.cartItemsList.length > 0) {                        
                        this.state.cartItemsList.forEach((item, index) => {
                            console.log(item.orderQty)
                            let CartItem = {
                                userId: item.userId,
                                bu: item.bu,
                                itemId: item.item_no,
                                orderqty: item.orderQty,
                                rushOrder: item.rushOrder,
                                action: "PLACE"
                            };
                            CartItemBO.push(CartItem)
                        })
                    }
                    let CartItemBODel = CartItemBO[0];
                    ProductReplenAction.cartLoad(CartItemBODel);
                    $('#SuccessPopUp').modal('show');
                }
                else {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: "Technical issue please contact SDI help desk"
                    });
                }
            }
        }
    };
    bindCart = () => {
        debugger;
        return this.state.cartItemsList.map((CartArr, index) => (
            <React.Fragment key={index}>
                <div className="row mx-0 mb-4 py-3 item-box">
                    <div className="col-md-12" id={`cart-row-${index}`}>
                        <div className="d-flex flex-wrap flex-lg-nowrap" >
                            <div className="image-resp-width mb-2">
                                <img className="cart-product-img-style" src={CartArr.imageLink} alt="fav_item" />
                            </div>
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-md-12 responsive-spacing-style">
                                        <div className="d-grid d-lg-flex justify-content-between mb-3">
                                            <div className="d-flex">
                                                <div>
                                                    <h5 className="font-16 font-bold mb-3 item-id-color">{CartArr.item_no}</h5>
                                                </div>
                                                <div className="ml-5">                                                    
                                                    <label className="catalog-label font-12 label-color text-nowrap"><Trans>Rush order</Trans></label>                                                    
                                                </div>
                                                <div>
                                                    <Switch
                                                        onChange={() => this.handleToggleChangeCart(index, CartArr.rushOrder === "Y" ? false : true)}
                                                        checked={CartArr.rushOrder == "Y" ? true : false} color="primary"
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div>
                                                <div className="d-block">
                                                    <div className="d-flex justify-content-start justify-content-lg-end">
                                                        <label className=" mt-1 catalog-label font-12 label-color mb-0"><Trans>Item quantity</Trans> : </label>
                                                        <input type="text" data-toggle="tooltip" data-placement="top" title={CartArr.orderQty} maxLength="8" className="form-control  item-qty-box ml-2" id={`quantity${index}`} name={`quantity${index}`} value={CartArr.orderQty == 0 ? "" : CartArr.orderQty} onChange={(e) => this.handleQTYChangeCart(index, e)}/>
                                                    </div>
                                                    <label className="color-validation font-12 hide" id={`errorQuantity${index}`} name={`errorQuantity${index}`}><Trans>Please enter item quantity</Trans></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-12  mb-3">
                                                <label className="catalog-label font-12 label-color"><Trans>Description</Trans> : </label>
                                                <label className="item-details-value ml-2 font-12 font-regular label-value-color item-id-color desc-margin-left">{CartArr.description}</label>
                                            </div>
                                            <div className="col-lg-3 col-md-12  mb-3">
                                                <label className="catalog-label font-12 label-color"><Trans>Business unit</Trans> : </label>
                                                <label className="item-details-value ml-2 font-12 font-regular item-id-color">{CartArr.bu}</label>
                                            </div>
                                            <div className="col-lg-3 col-md-12  mb-3">
                                                <label className="catalog-label font-12 label-color"><Trans>Vendor</Trans> : </label>
                                                <label className="item-details-value ml-2 font-12 font-regular item-id-color">{CartArr.vendor}</label>
                                            </div>
                                            <div className="col-lg-3 col-md-12  mb-3">
                                                <div className="d-flex justify-content-start justify-content-lg-end">
                                                    <label className="catalog-label font-12 label-color"><Trans>Unit price</Trans> : </label>
                                                    <label className="item-details-value ml-2 font-12 font-bold text-dark">${CartArr.unitPrice}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12  mb-3">
                                                <div className="d-flex justify-content-start justify-content-lg-end">
                                                    <label className="catalog-label font-12 label-color"><Trans>Total</Trans> : </label>
                                                    <label className="item-details-value ml-2 font-12 font-bold text-dark">${Number((parseFloat(CartArr.unitPrice) * parseFloat(CartArr.orderQty)).toFixed(2))}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <a className="remove-order font-medium font-12" style={{"color": "#ED4040","cursor": "pointer"}} onClick={(e) => this.onClickRemoveItem(e, index)}><img src="Images/remove-order.svg" className="removeorder-icon mr-2 mb-1" alt="Remove Order" onClick={(e) => this.onClickRemoveItem(e, index)} /><Trans>Remove</Trans></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Cart Item 1 end here*/}
                </div>    
            </React.Fragment>
                   
        ));
    };

    render() {
        const { t } = this.props;
        return (
            <>               
                <div className="container-fluid content-section replen content-margin">
                    <div className="row">
                        {/*Page Heading starts here*/}
                        <div className="col-md-12 mt-3 page-heading">
                            <h5 className="page-title"><Trans>Product Replenishment</Trans></h5>
                            <div className="fav-item-maintenance-icon mt-1">
                                <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-1">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff"></path>
                                                <circle className="main-circ" fill="#E2264D" opacity="0" cx="29.5" cy="29.5" r="1.5"></circle>

                                                <g className="grp7" opacity="0" transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx="2" cy="6" r="2"></circle>
                                                    <circle className="oval2" fill="#8CE8C3" cx="5" cy="2" r="2"></circle>
                                                </g>

                                                <g className="grp6" opacity="0" transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx="2" cy="7" r="2"></circle>
                                                    <circle className="oval2" fill="#91D2FA" cx="3" cy="2" r="2"></circle>
                                                </g>

                                                <g className="grp3" opacity="0" transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx="2" cy="7" r="2"></circle>
                                                    <circle className="oval1" fill="#8CE8C3" cx="4" cy="2" r="2"></circle>
                                                </g>

                                                <g className="grp2" opacity="0" transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx="5" cy="6" r="2"></circle>
                                                    <circle className="oval1" fill="#CC8EF5" cx="2" cy="2" r="2"></circle>
                                                </g>

                                                <g className="grp5" opacity="0" transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx="6" cy="5" r="2"></circle>
                                                    <circle className="oval2" fill="#91D2FA" cx="2" cy="2" r="2"></circle>
                                                </g>

                                                <g className="grp4" opacity="0" transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx="6" cy="5" r="2"></circle>
                                                    <circle className="oval2" fill="#F48EA7" cx="2" cy="2" r="2"></circle>
                                                </g>

                                                <g className="grp1" opacity="0" transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2"></circle>
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2"></circle>
                                                </g>
                                            </g>
                                        </svg>
                                </label>
                            </div>
                        </div>
                        {/*Page Heading ends here*/}
                        {/*form starts here*/}
                        <div className="col-md-3 col-lg-3 mb-3 mt-4">
                            <label className="form-label catalog-label w-100 float-none"><Trans>Item ID</Trans></label>
                            <div className="position-relative">
                            <input type="text" className="form-control replen-item" maxLength="18" id="itemId" name="itemId" placeholder={t("Enter item ID")} value={this.state.itemId}
                                    disabled={this.state.disableItemFiled} onChange={(e) => this.handleItemChange(e)} onKeyDown={(e) => this.searchKeyPress(e)} />
                            </div>
                            <img className={this.state.itemId.trim() != "" ? "search-cancel-header1" : "d-none"} src="../images/search-clear.svg" onClick={() => this.cancleItem()} />
                            <img className="dropdown-search-icon1" src="../images/search-icon.png" onClick={this.state.disableItemFiled === false ? () => this.handleSearchItem() : ""} />

                                {/*<span className={this.state.itemId.trim() != "" ? "search-cancel-header1" : "d-none"} onClick={() => this.cancleItem()}></span>
                                <span className="dropdown-search-icon1" onClick={this.state.disableItemFiled === false ? () => this.handleSearchItem() : ""} />*/}
                               
                            <span className={this.state.errorInvItem == true ? "font-12 font-regular color-validation" : "d-none"} id="errorItemId"><Trans>Please enter valid item ID</Trans></span>
                            <span className={this.state.errorNoItem == true ? "font-12 font-regular color-validation" : "d-none"} id="errorNoItemId"><Trans>Please enter the item ID</Trans></span>
                        </div>
                        {this.state.itemSearchDetails.length > 0 && this.state.action != "ADD" ?
                            <div className="col-md-3 mb-3 mt-3">
                                <label className="form-label catalog-label w-100 float-none" htmlFor="crib" ><Trans>Business unit</Trans></label>
                                <Select className={this.state.errorBU == true ? "form-control font-14 font-regular custom-form-style crib-border spCrib" : "form-control font-13 font-regular custom-form-style crib-border"}
                                    name="crib" id="crib" placeholder={t("Select business unit")} value={this.state.cribValue} options={this.state.cribDD} onChange={(e) => this.handleCribChange(e)}
                                />
                                <label className={this.state.errorBU == true ? "float-none font-12 font-regular color-validation" : "d-none"} style={{ "marginTop": "20px" }} htmlFor="crib" ><Trans>Please select business unit</Trans></label>
                            </div>
                                 : null}
                                {this.state.itemSearchDetails.length > 0 && this.state.action != "ADD" ?
                            <div className="col-md-12 border-top">
                                <div className="row">
                                    <div className="col-lg-12 mt-3 mb-4">
                                        <div className="row mx-0 mb-3 py-3 item-box">
                                            <div className="col-md-12">
                                                <div className="d-flex flex-wrap flex-lg-nowrap">
                                                    <div className="image-resp-width mb-2">
                                                        <img className="cart-product-img-style" src={this.state.imageLink} alt="no image" />
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="row">
                                                            <div className="col-md-12 mb-4 resp-md-none">
                                                                <label className="catalog-label font-12 label-color"><Trans>Description</Trans> : </label>
                                                                <label className="item-details-value ml-2 font-12 font-regular item-id-color">{this.state.description}</label>
                                                            </div>
                                                            <div className="col-lg-3 col-md-12 mb-3">
                                                                <label className="catalog-label font-12 label-color"><Trans>Vendor</Trans> : </label>
                                                                <label className="item-details-value ml-2 font-12 font-regular item-id-color">{ this.state.vendor}</label>
                                                            </div>
                                                            <div className="col-lg-3 col-md-12 mb-3">
                                                                <label className="catalog-label font-12 label-color"><Trans>Price</Trans> : </label>
                                                                <label className="item-details-value ml-2 font-12 font-regular item-id-color">${this.state.cost}</label>
                                                            </div>
                                                            <div className="col-lg-3 col-md-12 mb-3">
                                                                <div className="w-100">
                                                                    <label className=" mt-1 catalog-label font-12 label-color mr-2 mb-0"><Trans>Item quantity</Trans> : </label>
                                                                    <input type="text" data-toggle="tooltip" data-placement="top" title={this.state.quantity} maxLength="8" className="form-control  item-qty-box" id="quantity" name="quantity" value={this.state.quantity} onChange={(e) => this.handleQTYChange(e)} />
                                                                    <label className={this.state.errorQuantity == true ? "color-validation w-100 font-12" : "d-none"} id="errorQuantity"><Trans>Please enter item quantity</Trans></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-12 mb-3">
                                                                <label className="catalog-label font-12 label-color"><Trans>Rush order</Trans> </label>
                                                                <Switch
                                                                    onChange={() => this.handleToggleChangeItem(!this.state.rushOrder)}
                                                                    checked={Boolean(this.state.rushOrder)}
                                                                    color="primary"
                                                                />
                                                            </div>
                                                        </div> 
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mb-2">
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn mb-2 mr-2" onClick={() => this.cancleItem()}><Trans>Cancel</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary primary-btn" onClick={() => this.addItemToCart()}><Trans>Add to cart</Trans></button>
                                    </div>
                                </div>
                            </div>
                            : null}
                      
                        
                    </div>
                    {/*Cart Item*/}
                    {this.state.cartItemsList.length > 0 ? 
                        
                        <div className="col-md-12 mt-4 border-top">
                                <h5 className="font-16 font-bold mb-3 mt-3"><Trans>Cart Items</Trans></h5>
                                <div className="row">
                                    <div className="col-lg-12 mb-4">
                                    {/*Cart Item 1 start here*/}
                                    {this.bindCart()}
                                    
                                    </div>
                                    {/*Cart Item*/}
                                    {/*button starts here*/}
                                    <div className="col-md-12 mb-2">
                                        <div className="d-flex justify-content-end">
                                            <button className="btn btn-primary custom-btn-primary primary-btn" onClick={() => this.placeCart()}><Trans>Place order</Trans></button>
                                        </div>
                                    </div>
                                    {/*button Ends here*/}
                                </div>
                            </div>
                        : null}                    
                </div>

                {/*Modal Starts here*/}
                <div className="modal fade delete-item pr-0" id="DeleteItem" tabIndex={-1} role="dialog" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered custom-popup-width" role="document">
                        <div className="modal-content py-2">
                            {/*Delete Starts here*/}
                            <div className="modal-body pb-0">
                                <div className="row">
                                    <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center mt-2">
                                        <img className="mb-3" src="images/trash.svg" alt="delete" />
                                        <h5 className="mb-2 font-16 font-bold color-light-black"><Trans>Confirm</Trans></h5>
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>Do you want to remove this item?</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*Delete ends here*/}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button className="btn btn-custom-secondary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>No</Trans></button>
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" onClick={() => this.removeItemPopUp()}><Trans>Yes</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

                {/*Modal Starts here*/}
                <div className="modal fade order-success pr-0" id="SuccessPopUp" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered custom-popup-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center mt-2">
                                        <img className="mb-2" src="images/tick.svg" alt="tick" />
                                        {/*<h5 className="mb-2 font-16 font-bold color-light-black">Success</h5>*/}
                                        <p className="mb-1 font-13 font-regular color-light-black"><Trans>Order placed successfully!</Trans></p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>OK</Trans></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}                
            </>
        );
    };
};
export default withTranslation()(ProductReplenComponent);