import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
import * as $ from 'jquery';
import swal from 'sweetalert';


const pageLoadAction = (pageLoadBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.PickingAssignTo,
            SDIConstants.methodNames.PickingAssignTo_PageLoad,
            JSON.stringify(pageLoadBO).replaceAll(/'/g, "''"),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "funcPageLoad", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired()
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}


const lineDetailAction = (lineDetailBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.PickingAssignTo,
            SDIConstants.methodNames.PickingAssignTo_LineDetail,
            JSON.stringify(lineDetailBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "funcLineDetail", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired()
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const pickingAssignAction = (UpdatePickingAssignToBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.PickingAssignTo,
            SDIConstants.methodNames.PickingAssignTo_UpdateAssignTo,
            JSON.stringify(UpdatePickingAssignToBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "funcPickingAssign", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired()
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const exportToExcelAction = (exportToExcelBO) => {
    try {

        SDISVC.downloadFile(
            SDIConstants.moduleNames.PickingAssignTo,
            SDIConstants.methodNames.PickingAssignTo_ExportToExcel,
            JSON.stringify(exportToExcelBO).replaceAll(/'/g, "''"),
            "PickingAssignTOReport.xlsx",
            (data) => {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "funcExportToExcel", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else {
                    swal({
                        allowOutsideClick: true,
                        closeOnClickOutside: true,
                        text: 'Technical issue please contact SDI help desk'
                    });
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

export { pageLoadAction, exportToExcelAction, pickingAssignAction, lineDetailAction, getCurrentFavPage, deleteFavPage, insertFavPage }
