/**
* RMAS_PC_01
* */
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
/**
* RMAS_PC_02
* */
class RMAStore extends EventEmitter {
    pageLoadResponse = "";
    searchResponse = "";
    reasonSelectResponse = "";
    submitResponse = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleRMAStore = (action) => {
        switch (action.action.type) {

            case SDIConstants.methodNames.RMA_PageLoad: {
                this.pageLoadResponse = action.action.response;
                this.emit('pageLoad');
                break;
            }
            case SDIConstants.methodNames.RMA_SearchButtonClick: {
                this.searchResponse = action.action.response;
                this.emit('search');
                break;
            }
            case SDIConstants.methodNames.RMA_ReasonSelectClick: {
                this.reasonSelectResponse = action.action.response;
                this.emit('reasonSelect');
                break;
            }
            case SDIConstants.methodNames.RMA_SaveButtonClick: {
                this.submitResponse = action.action.response;
                this.emit('submit');
                break;
            }
            case "NOTIFY_InsertFavPage": {

                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }

        }
    }
}

const objStore = new RMAStore;
// Dispatcher code
Dispatcher.register(objStore.handleRMAStore.bind(objStore));
export default objStore;