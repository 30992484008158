import React, { Component } from 'react';
const unAuthMenu = (props) => {
   
    let spanTest = null;
    let spanVersion = null;
    if (localStorage.sessionTimeOutData) {
        let environmentDetails = JSON.parse(localStorage.sessionTimeOutData);
        if (environmentDetails) {
            if (environmentDetails.length > 0) {
                environmentDetails = environmentDetails[0];
                environmentDetails.lblTestVisible=environmentDetails.lblTestVisible.toLowerCase() == "true"
                spanTest = (environmentDetails.lblTestVisible ? <span className="alert-text-title">{environmentDetails.lblTest}</span> : null);
                spanVersion = (environmentDetails.lblTestVisible ? <span className="px-2">{environmentDetails.lblVersion}</span> : null);
            }
        }
    }

    return (<div className="fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light bg-dark-sdi-nav border-0 main-menu">
            <a className="navbar-brand ml-2" > <img src="/Images/sdi-logo.png" className="sdi-logo" alt="SDI Logo" /> </a>
        </nav>
        {spanTest || spanTest?<section className="breadcrumb-section">
            <div className="alert-text float-right">
                {spanTest}
                {spanVersion}
            </div>
        </section>:null}
    </div>)
}
const UnAuthMenuComponent = unAuthMenu;
export default UnAuthMenuComponent;