// PC_AU_8
// Import the files and packages
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
// PC_AU_15, PC_AU_35
class ActiveUsersReportStore extends EventEmitter {
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleActiveUsersReport = (action) => {
        let FetchActiveUsers = "";
        let ExportToExcelResponse = "";
        debugger;
        switch (action.action.type) {
            case "FetchActiveUsers": {
                this.FetchActiveUsers = action.action.response;
                this.emit('FetchActiveUsers');
                break;
            }
            case "exportToExcelResponse": {
                this.ExportToExcelResponse = action.action.response;
                this.emit('exportToExcelResponse');
                break;
            }
            case "NOTIFY_InsertFavPage": {
                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "NOTIFY_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
        }
    }


}
const objActiveUsersReport = new ActiveUsersReportStore;
// Dispatcher code
Dispatcher.register(objActiveUsersReport.handleActiveUsersReport.bind(objActiveUsersReport));
export default objActiveUsersReport;