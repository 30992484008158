import React, { Component } from 'react';
import * as CookieService from '../CookieService';
import * as RequestorApprovalAction from '../actions/RequestorApprovalAction';
import RequestorApprovalStore from '../stores/RequestorApprovalStore';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
//[PC_07, 09/13/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
import swal from 'sweetalert';
//let currentPagePath;
let selectedMultiItems;
let bFromEmailLink = true;
let sessionValues;
let approvalType = null;
let orderNoQryParam;
class RequestorApprovalComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
            Work_Order: "",
            limitExeData: [],
			headerdetails: {
				Chg_Emp_ID: "",
				Currency_Symbol: "",
				Employee_Name: "",
				Footer_Total: "",
				Machine_Number: "",
				Need_by_Date: "",
				Notes: "",
				SDI_Requisition_Number: "",
				Submit_Date: "",
				btnApprove: "",
				isWorkOrderEnable: "",
				Work_Order: "",
				lblChgCD: "",
				lblDBErr1: "",
				txtOrdByID: "",
				DeliveryPoint: "",
				isFavorited: false,
                showprice: (CookieService.getLocalStorageValues("Session_ShowPrice") == "1" && CookieService.getLocalStorageValues("Session_ISAPriceBlock") != "Y" ? "0" : "")                
			},
			Linedetails: [],
			errorMessage: "",
            appURL: "",
            disabled_button: true,
            Comments: "",
            DecReasonBtnName: "Decline",
            singlelinedetails: [],
            isLineClicked: true,
            checkboxline: "",
            isDeclineReasonSuccess: false,
            totalcount: 0,
            selectedLineNums: [],
            selectedLineItems: [],
            SingleOrMultiSubmit: "",
            ApproveOrDecline: "",
            CommentTextCount: 30,
            decReasonValue: "Select", //[4/16/2024]Z 268 CR - To handle decline reason DD value with state variable 
            User_Name: "" /* [06/28/2024] - Zeus 327 - User Name Display - create a state to get assign the username  - Ayyappan.S*/

        };
		this.assignReqApproval = this.assignReqApproval.bind(this);
		this.handleWorkOrder = this.handleWorkOrder.bind(this);
		this.handleQty = this.handleQty.bind(this);
		this.approveDeclineSingle = this.approveDeclineSingle.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.approveDeclineMultiple = this.approveDeclineMultiple.bind(this);
		this.singleLineResponse = this.singleLineResponse.bind(this);
		this.multiLineResponse = this.multiLineResponse.bind(this);
		//this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
		//this.handleFavorite = this.handleFavorite.bind(this);
	}
	IsFromEmailLink = () => {
		let isEmail = true;
		const queryParams = new URLSearchParams(window.location.search);
		const QS_src = queryParams.get('src');
		let Session_USERNAME = CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "";
		if (QS_src) {
			if (QS_src.toLowerCase() == "needquote") {
				if (Session_USERNAME != "App Order Email Link" && Session_USERNAME != "App Quote Email Link") {
					isEmail = false;
				}
			}
		}
		return isEmail;
	};
	setWebAppURL = () => {
		let cachedURL = sessionStorage.getItem("webAppURL");
		if (!cachedURL || cachedURL == "") {
			$.ajax({
				type: "GET",
				url: SDIConstants.localWebAPP
			}).then((data) => {
				this.setState({ appURL: data });
				console.log("ThroughCallWeb", data);
				sessionStorage.setItem("webAppURL", data);
			});
		} else {
			console.log("ThroughCacheWeb", sessionStorage.getItem("webAppURL"));
			this.setState({ appURL: sessionStorage.getItem("webAppURL") });
		}
	};
	componentDidMount() {
		console.log("showprice", (CookieService.getLocalStorageValues("Session_ShowPrice") == "1" && CookieService.getLocalStorageValues("Session_ISAPriceBlock") != "Y" ? "0" : ""));
		console.log("Session_ShowPrice", CookieService.getLocalStorageValues("Session_ShowPrice"))
		console.log("Session_ISAPriceBlock", CookieService.getLocalStorageValues("Session_ISAPriceBlock"))
		console.log("Show", this.state.showprice)
		bFromEmailLink = this.IsFromEmailLink();
		this.setWebAppURL();
		//if (bFromEmailLink == false) {
		//    currentPagePath = this.props.location.pathname;
		//    currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
		//    let getFavPageBO = {
		//        Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
		//        pageURL: currentPagePath.toLowerCase()
		//    };
		//    RequestorApprovalAction.getCurrentFavPage(getFavPageBO);
		//}
		this.pageLoad();
		RequestorApprovalStore.on("requestorData", this.assignReqApproval);
		RequestorApprovalStore.on("single", this.singleLineResponse);
        RequestorApprovalStore.on("multi", this.multiLineResponse);
        RequestorApprovalStore.on("DecReason", this.DeclineReasonResp);
		//RequestorApprovalStore.on('favpage', this.loadFavPageResponse);
		//RequestorApprovalStore.on('updateFavPage', this.updateFavPageResponse);
	};
	componentWillUnmount() {
		RequestorApprovalStore.removeListener("requestorData", this.assignReqApproval);
		RequestorApprovalStore.removeListener("single", this.singleLineResponse);
        RequestorApprovalStore.removeListener("multi", this.multiLineResponse);
        RequestorApprovalStore.removeListener("DecReason", this.DeclineReasonResp);
	};



	pageLoad = () => {
		console.log("showprice", this.state.showprice);
		const urlParams = new URLSearchParams(window.location.search);
		let QS_fer = urlParams.get('fer');
		let QS_op = urlParams.get('op');
        let QS_xyz = urlParams.get('xyz');
        let QS_username = urlParams.get('un'); // [06/28/2024] - Zeus 327 - User Name Display - Create a variable and Pass the query as 'un'  - Ayyappan.S
		let QS_alt = urlParams.get('QS_alt');
		const QS_ExchHome23 = urlParams.get('ExchHome23');
		if (bFromEmailLink == false) {
			//[3/24/2023, PS_CAS_47_APP_04, Dhevanesam R]                                                                                                           
			let urlParamsdec = decodeURIComponent(urlParams);
			const urlParamsdecode = new URLSearchParams(urlParamsdec)
			QS_fer = urlParamsdecode.get('fer');
            /*let indopParams = urlParamsdec.indexOf("op=");
            let indxyzParams = urlParamsdec.indexOf("&xyz");
            QS_op = urlParamsdec.substring(indopParams + 3, indxyzParams);*/
			if (QS_op) {
				QS_op = decodeURIComponent(QS_op);
			}
			QS_xyz = urlParamsdecode.get('xyz');
			QS_alt = urlParamsdecode.get('QS_alt');
		} else {
			window.document.title = "Approve Quote";
		}
		orderNoQryParam = QS_fer;
		let pageLoadBO = {
			QS_fer: QS_fer ? QS_fer : "",
			QS_op: QS_op ? QS_op : "",
            QS_xyz: QS_xyz ? QS_xyz : "",
            QS_username: QS_username ? QS_username : "", // [06/28/2024] - Zeus 327 - Send the query params to backend  - Ayyappan.S
			QS_alt: QS_alt ? QS_alt : "",
			QS_ExchHome23: QS_ExchHome23 ? QS_ExchHome23 : "",
			bFromEmailLink: bFromEmailLink,//bFromEmailLink,
			Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT") ? CookieService.getLocalStorageValues("Session_BUSUNIT") : "",
			Session_USERID: CookieService.getLocalStorageValues("Session_UserID") ? CookieService.getLocalStorageValues("Session_UserID") : "",
			Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME") ? CookieService.getLocalStorageValues("Session_USERNAME") : "",
			Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN") ? CookieService.getLocalStorageValues("Session_PUNCHIN") : "",
			Session_WorkOrderPnchInA: CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") ? CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") : "",
			Session_ValidateWorkOrder: CookieService.getLocalStorageValues("Session_ValidateWorkOrder") ? CookieService.getLocalStorageValues("Session_ValidateWorkOrder") : "",
			Session_CARTWOREQ: CookieService.getLocalStorageValues("Session_CARTWOREQ") ? CookieService.getLocalStorageValues("Session_CARTWOREQ") : "",
		};
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		if (bFromEmailLink == false) {
			RequestorApprovalAction.getReqApprovals(pageLoadBO);
		} else {
			RequestorApprovalAction.getReqApprovalsUnauth(pageLoadBO);
		}
	};
	assignReqApproval = () => {
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let requestorData = JSON.parse(RequestorApprovalStore.requestorData);
        let userName; // [06/28/2024] - Zeus 327 -Create a variable to assign the userName value from backend  - Ayyappan.S
		window.scrollTo(0, 0)
		console.log("requestorData", requestorData);
		let flagData = requestorData.FlagDetails;
		if (flagData.length > 0) {
            flagData = flagData[0];
            userName = flagData.QS_username // [06/28/2024] - Zeus 327 - Assign the userName value from backend  - Ayyappan.S
			if (bFromEmailLink) {
				sessionValues = {
					Session_ApprovalUserName: flagData.Session_ApprovalUserName,
					Session_ExchHome2: flagData.Session_ExchHome2,
					Session_RFApproveLikeAscend: flagData.Session_RFApproveLikeAscend,
					Session_ApprovalDetails: flagData.Session_ApprovalDetails,
					Session_USERNAME: "App Quote Email Link",
					__siteCurrency: flagData.__siteCurrency
				};
				// CookieService.insertLocalStorageValues(sessionValues);

			} else {
				CookieService.updateLocalStorageValues("Session_ApprovalUserName", flagData.Session_ApprovalUserName);
				CookieService.updateLocalStorageValues("Session_ExchHome2", flagData.Session_ExchHome2);
				CookieService.updateLocalStorageValues("Session_RFApproveLikeAscend", flagData.Session_RFApproveLikeAscend);
				CookieService.updateLocalStorageValues("Session_ApprovalDetails", flagData.Session_ApprovalDetails);
				CookieService.updateLocalStorageValues("__siteCurrency", flagData.__siteCurrency);
			}
		};
		let headerdetails = requestorData.Headerdetails;
        let totalCount = 0;
        if (requestorData.Linedetails.length > 0) {
            totalCount = requestorData.Linedetails.length;
            requestorData.Linedetails.forEach((row) => {
                row.chkLineItem = false;
            });
        }
        else if (bFromEmailLink) {
            totalCount = requestorData.Linedetails.length;
        }
        else if (this.state.isDeclineReasonSuccess) {
            this.props.history.push({
                pathname: '/needquote'
            });
            return false
        }
        else {
			//[9/25/2023]Zeus 182 Approvals redirection from notifciation - after approving it will redirect to waiting req approval page if the linedetails is 0
			CookieService.updateLocalStorageValues("IsOrderNAInReqApproval", true);
			this.props.history.push({
				pathname: '/needquote',
				state: { origin: 'FromNotification', OrderNo: orderNoQryParam }
			});
		};
        this.setState({ Linedetails: requestorData.Linedetails, totalCount: totalCount, User_Name: userName }); // [06/28/2024] - Zeus 327 - Setstate the state by userName varibale - Ayyappan.S
		if (headerdetails.length > 0) {
			headerdetails = headerdetails[0];
			this.setState({
				headerdetails: headerdetails,
				Work_Order: headerdetails.Work_Order,
			});
		};
	};

	handleWorkOrder = (event) => {
		this.setState({ Work_Order: event.target.value });
	};
	handleQty = (event, index) => {
		//[6/12/2023, Bug - 3707 & 3683, Dhevanesam R]
		if (Math.round(event.target.value) != "0" || !event.target.value) {
			let { Linedetails } = this.state;
			Linedetails[index].QTY_REQ = event.target.value;
			this.setState(Linedetails);
		}
	};
    approveDeclineReqOrder = (lineItems, selectedLines, appDecType, singleMultiType, NotesSubmitStatus, Comments) => {
		approvalType = appDecType;
		const urlParams = new URLSearchParams(window.location.search);
		let QS_fer = urlParams.get('fer');
		let QS_op = urlParams.get('op');
		let QS_xyz = urlParams.get('xyz');
		let QS_alt = urlParams.get('QS_alt');
		if (bFromEmailLink == false) {
			//[3/24/2023, PS_CAS_47_APP_04, Dhevanesam R]
			let urlParamsdec = decodeURIComponent(urlParams);
			const urlParamsdecode = new URLSearchParams(urlParamsdec)
			QS_fer = urlParamsdecode.get('fer');
            /*let indopParams = urlParamsdec.indexOf("op=");
            let indxyzParams = urlParamsdec.indexOf("&xyz");
            QS_op = urlParamsdec.substring(indopParams + 3, indxyzParams);*/
			if (QS_op) {
				QS_op = decodeURIComponent(QS_op);
			}
			QS_xyz = urlParamsdecode.get('xyz');
			QS_alt = urlParamsdecode.get('QS_alt');
		}
		let cookieValues;
		if (bFromEmailLink) {
			cookieValues = {
				Session_ApprovalUserName: sessionValues.Session_ApprovalUserName ? sessionValues.Session_ApprovalUserName : "",
				Session_ApprovalDetails: sessionValues.Session_ApprovalDetails ? sessionValues.Session_ApprovalDetails : "",
				Session_USERID: "",
				Session_BUSUNIT: "",
				Session_ITEMMODE: "",
				Session_IOLServer: "",
				Session_cplusServer: "",
				Session_SITEPREFIX: "",
				Session_ISABudgetaryLimit: "",
				Session_PUNCHIN: "",

			};
		} else {
			cookieValues = {
				Session_ApprovalUserName: CookieService.getLocalStorageValues("Session_ApprovalUserName") ? CookieService.getLocalStorageValues("Session_ApprovalUserName") : "",
				Session_ApprovalDetails: CookieService.getLocalStorageValues("Session_ApprovalDetails") ? CookieService.getLocalStorageValues("Session_ApprovalDetails") : "",
				Session_USERID: CookieService.getLocalStorageValues("Session_UserID") ? CookieService.getLocalStorageValues("Session_UserID") : "",
				Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT") ? CookieService.getLocalStorageValues("Session_BUSUNIT") : "",
				Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE") ? CookieService.getLocalStorageValues("Session_ITEMMODE") : "",
				Session_IOLServer: CookieService.getLocalStorageValues("Session_IOLServer") ? CookieService.getLocalStorageValues("Session_IOLServer") : "",
				Session_cplusServer: CookieService.getLocalStorageValues("Session_cplusServer") ? CookieService.getLocalStorageValues("Session_cplusServer") : "",
				Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX") ? CookieService.getLocalStorageValues("Session_SITEPREFIX") : "",
				Session_ISABudgetaryLimit: CookieService.getLocalStorageValues("Session_ISABudgetaryLimit") ? CookieService.getLocalStorageValues("Session_ISABudgetaryLimit") : "",
				Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN") ? CookieService.getLocalStorageValues("Session_PUNCHIN") : "",
			}
		}
		let approveDeclineBO = {
			Session_USERID: cookieValues.Session_USERID,
			Session_BUSUNIT: cookieValues.Session_BUSUNIT,
			Session_ApprovalDetails: cookieValues.Session_ApprovalDetails,
			QS_fer: QS_fer ? QS_fer : "",
			QS_op: QS_op ? QS_op : "",
			QS_xyz: QS_xyz ? QS_xyz : "",
			QS_alt: QS_alt ? QS_alt : "",
			bFromEmailLink: bFromEmailLink,//bFromEmailLink,
			Session_ITEMMODE: cookieValues.Session_ITEMMODE,
			Session_IOLServer: cookieValues.Session_IOLServer,
			Session_cplusServer: cookieValues.Session_cplusServer,
			Session_SITEPREFIX: cookieValues.Session_SITEPREFIX,
			Session_ISABudgetaryLimit: cookieValues.Session_ISABudgetaryLimit,
			Session_PUNCHIN: cookieValues.Session_PUNCHIN,
			selectedLines: selectedLines,
			ReqLineItems: lineItems,
			txtWO_visible: this.state.headerdetails.isWorkOrderEnable,
			txtWO: this.state.Work_Order,
			lblWO: this.state.headerdetails.Work_Order,
			totalLineItems: this.state.Linedetails.length,
			Session_ApprovalUserName: cookieValues.Session_ApprovalUserName,
            txtBU: QS_xyz ? QS_xyz : "",
            Session_APPROVALTYPE: "APPROVEQUOTE",
			//[04/24/2023, GOA_PSF_9, Zeus 79] add Session_USERTYPE to corp admin global approval  -Sathis N
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : "",
             Comments: Comments.trim(),
            NotesSubmitStatus: NotesSubmitStatus,
            Reason: this.state.decReasonValue == "Select" ? "" : this.state.decReasonValue//[4/16/2024]Z 268 CR to save comments along with reason
        };
		debugger;
		$(`#${SDIConstants.SDILoader}`).removeClass('hide');
		if (singleMultiType == 1) {
            if (appDecType == 1) {
                $('#ApprovalComments').modal('hide');
				if (bFromEmailLink == false) {
					RequestorApprovalAction.approveSingleOrder(approveDeclineBO);
				} else {
					RequestorApprovalAction.approveSingleOrderUnAuth(approveDeclineBO);
				}
			} else {
				if (bFromEmailLink == false) {
					RequestorApprovalAction.declineSingleOrder(approveDeclineBO);
				} else {
					RequestorApprovalAction.declineSingleOrderUnAuth(approveDeclineBO);
				}
			}
		} else {
            if (appDecType == 1) {
                $('#ApprovalComments').modal('hide');
				if (bFromEmailLink == false) {
					RequestorApprovalAction.approveMultiOrder(approveDeclineBO);
				} else {
					RequestorApprovalAction.approveMultiOrderUnAuth(approveDeclineBO);
				}
			} else {
				if (bFromEmailLink == false) {
					RequestorApprovalAction.declineMultiOrder(approveDeclineBO);
				} else {
					RequestorApprovalAction.declineMultiOrderUnAuth(approveDeclineBO);
				}
			}
		}
	};
	singleLineResponse = () => {
		debugger
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let singleAppDecRes = JSON.parse(RequestorApprovalStore.singleAppDecRes)[0];
		console.log("singleAppDecRes", singleAppDecRes);
		if (singleAppDecRes.strApproveString) {
			//let str = singleAppDecRes.strApproveString;
			//if (str.contains = "Budget") {
			//    let FirstText = str.substring(0, 27);
			//    let NameFirInd1 = str.indexOf("Order");
			//    let NameFirInd2 = str.indexOf("to") + 3;
			//    let BefNameText = str.substring(NameFirInd1, NameFirInd2);
			//    let NamelaInd1 = str.indexOf("email");
			//    let NamelaInd2 = NamelaInd1.indexOf("email") + 4;
			//    let AftNameText = str.substring(NamelaInd1, NamelaInd2);
			//    if (str.contains = "and") {
			//        let EmailAftInd1 = str.indexOf("and");
			//        let EmailAftInd2 = str.indexOf("and") + 3;
			//        let EmailAftTxt = str.substring(EmailAftInd1, EmailAftInd2);
			//        let LastInd1 = str.indexOf("for");
			//        let LastInd2 = str.indexOf("for") + 12;
			//        let LasttTxt = str.substring(LastInd1, LastInd2);
			//    }
			//    else {
			//        let LastInd1 = str.indexOf("for");
			//        let LastInd2 = str.indexOf("for") + 12;
			//        let LasttTxt = str.substring(LastInd1, LastInd2);
			//    }

			//}
			//else {
			//    let FirstText = str.substring(0, 21);
			//    let NameFirInd1 = str.indexOf("Order");
			//    let NameFirInd2 = str.indexOf("to") + 3;
			//    let BefNameText = str.substring(NameFirInd1, NameFirInd2);
			//    let NamelaInd1 = str.indexOf("email");
			//    let NamelaInd2 = NamelaInd1.indexOf("email") + 4;
			//    let AftNameText = str.substring(NamelaInd1, NamelaInd2);
			//    if (str.contains = "and") {
			//        let LastInd1 = str.indexOf("for");
			//        let LastInd2 = str.indexOf("for") + 12;
			//        let LasttTxt = str.substring(LastInd1, LastInd2);
			//    }
			//    else {
			//        let LastInd1 = str.indexOf("for");
			//        let LastInd2 = str.indexOf("for") + 12;
			//        let LasttTxt = str.substring(LastInd1, LastInd2);
			//    }
			//}


            //[Zeus 255, 01/22/2024] - Modified the next approver message format - Kishore
            let orderLimitDetails = singleAppDecRes.strApproveString.split("||");
            if (orderLimitDetails.length > 3) {
                this.setState({ limitExeData: orderLimitDetails })
                if (approvalType == 2) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Item has been declined successfully")
                    }).then(() => {
                        $('#limit-exceeded').modal('show');
                    });
                }
                else {
                    $('#limit-exceeded').modal('show');
                }
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: `${singleAppDecRes.strApproveString}`
                })
            }
			//alert(`${singleAppDecRes.strApproveString}`);
		}
		if (singleAppDecRes.bSuccess.toLowerCase() == "true") {
			if (!singleAppDecRes.strApproveString) {
				if (approvalType == 1) {
					//alert(t("Item has been approved successfully"));
					swal({
						allowOutsideClick: false,
						closeOnClickOutside: false,
						text: t("Item has been approved successfully")
					})
				} else {
					swal({
						allowOutsideClick: false,
						closeOnClickOutside: false,
						text: t("Item has been declined successfully")
					})
					//alert(t("Item has been declined successfully"));
				}
            }
            if (!bFromEmailLink) {
                if (this.state.Linedetails.length == 1) {
                    //*********************NEW(12/23/2020)***************
                    if (CookieService.getLocalStorageValues("Session_PUNCHIN") == "YES") {
                        if (CookieService.getLocalStorageValues("Session_ISAAscendProcess") == "Y") {
                            CookieService.setCookieValues(SDIConstants.access_token, "", -1);
                            localStorage.clear();
                            const urlParams = new URLSearchParams(window.location.search);
                            let QS_fer = urlParams.get('fer');
                            this.props.history.push({
                                pathname: `\PIPost`,
                                search: `?HOME=N&ITEMS=1&ASCEND=YES&REQID=${QS_fer}`
                            });
                            return false;
                        }
                        else if (!singleAppDecRes.strApproveString) {
                            this.props.history.push({
                                pathname: '/needquote',
                            });
                            return false;
                        }


                    } else if (!singleAppDecRes.strApproveString) {
                        this.props.history.push({
                            pathname: '/needquote',
                        });
                        return false;
                    }

                    //this.props.history.push({
                    //    pathname: '/needquote',
                    //});
                    //return false;
                    //**************************************************
                }
            }
			//*********************NEW(1/3/2021)***************
			if (CookieService.getLocalStorageValues("Session_PUNCHIN") == "YES") {
				if (CookieService.getLocalStorageValues("Session_ISAAscendProcess") == "Y") {
					CookieService.setCookieValues(SDIConstants.access_token, "", -1);
					localStorage.clear();
					const urlParams = new URLSearchParams(window.location.search);
					let QS_fer = urlParams.get('fer');
					this.props.history.push({
						pathname: `\PIPost`,
						search: `?HOME=N&ITEMS=1&ASCEND=YES&REQID=${QS_fer}`
					});
					return false;
				}
				else {
					this.pageLoad();
				}
			}
			//**********************END****************************
            if (!singleAppDecRes.strApproveString || bFromEmailLink) {
                this.pageLoad();
            }
			
		} else {
			if (singleAppDecRes.lblMsgrslt) {
				this.setState({ errorMessage: singleAppDecRes.lblMsgrslt });
			} else if (singleAppDecRes.lblDBErr1_Visible.toLowerCase() == "true") {
				this.setState({ errorMessage: singleAppDecRes.lblDBErr1 });
			} else if (singleAppDecRes.lblDBErr_Visible.toLowerCase() == "true") {
				this.setState({ errorMessage: singleAppDecRes.lblDBErr });
			}
        }
        this.DecReasonCancel();
    };

    //[Zeus 255, 01/22/2024] - Limit exceeded popup close - Kishore
    closeLimitExceedPoppup = () => {
        if (!bFromEmailLink) {
            this.props.history.push({
                pathname: '/needquote',
            });
        }
    }

	multiLineResponse = () => {
		const { t } = this.props
		$(`#${SDIConstants.SDILoader}`).addClass('hide');
		let multiAppDecRes = JSON.parse(RequestorApprovalStore.multiAppDecRes)[0];

		console.log("multiAppDecRes", multiAppDecRes);
		if (multiAppDecRes.strApproveString) {
            //[Zeus 255, 01/22/2024] - Modified the next approver message format - Kishore
            let orderLimitDetails = multiAppDecRes.strApproveString.split("||");
            if (orderLimitDetails.length > 3) {
                this.setState({ limitExeData: orderLimitDetails });
                if (approvalType == 2) {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Selected items have been declined successfully")
                    }).then(() => {
                        $('#limit-exceeded').modal('show');
                    });
                }
                else {
                    $('#limit-exceeded').modal('show');
                }
            }
            else {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: `${multiAppDecRes.strApproveString}`
                })
            }
			// alert(`${multiAppDecRes.strApproveString}`);
		}
		if (multiAppDecRes.bSuccess.toLowerCase() == "true") {
			if (bFromEmailLink == true) {
				
					if (!multiAppDecRes.strApproveString) {
						if (approvalType == 1) {
							swal({
								allowOutsideClick: false,
								closeOnClickOutside: false,
								text: t("Selected items have been approved successfully")
							})
							//alert(t("Selected items has been approved successfully"));
						} else {
							swal({
								allowOutsideClick: false,
								closeOnClickOutside: false,
								text: t("Selected items have been declined successfully")
							})
							//alert(t("Selected items has been declined successfully"));
						}
					}

					//window.location.href = this.state.appURL;
                this.pageLoad();
			} else {
				if (!multiAppDecRes.strApproveString) {
					if (approvalType == 1) {
						swal({
							allowOutsideClick: false,
							closeOnClickOutside: false,
							text: t("Selected items has been approved successfully")
						})
						//alert(t("Selected items has been approved successfully"));
					} else {
						swal({
							allowOutsideClick: false,
							closeOnClickOutside: false,
							text: t("Selected items has been declined successfully")
						})
						//alert(t("Selected items has been declined successfully"));
					}
				}
				if (selectedMultiItems.length == this.state.Linedetails.length) {
					//*********************NEW(12/23/2020)***************
					if (CookieService.getLocalStorageValues("Session_PUNCHIN") == "YES") {
						if (CookieService.getLocalStorageValues("Session_ISAAscendProcess") == "Y") {
							CookieService.setCookieValues(SDIConstants.access_token, "", -1);
							localStorage.clear();
							const urlParams = new URLSearchParams(window.location.search);
							let QS_fer = urlParams.get('fer');
							this.props.history.push({
								pathname: `\PIPost`,
								search: `?HOME=N&ITEMS=1&ASCEND=YES&REQID=${QS_fer}`
							});
							return false;
						}
                        else if (!multiAppDecRes.strApproveString) {
							this.props.history.push({
								pathname: '/needquote',
							});
							return false;
						}


                    } else if (!multiAppDecRes.strApproveString) {
						this.props.history.push({
							pathname: '/needquote',
						});
						return false;
					}

					//this.props.history.push({
					//    pathname: '/needquote',
					//});
					//return false;
					//**************************************************

					//this.props.history.push({
					//    pathname: '/needquote',
					//});
				};
				//*********************NEW(12/23/2020)***************
				if (CookieService.getLocalStorageValues("Session_PUNCHIN") == "YES") {
					if (CookieService.getLocalStorageValues("Session_ISAAscendProcess") == "Y") {
						CookieService.setCookieValues(SDIConstants.access_token, "", -1);
						localStorage.clear();
						const urlParams = new URLSearchParams(window.location.search);
						let QS_fer = urlParams.get('fer');
						this.props.history.push({
							pathname: `\PIPost`,
							search: `?HOME=N&ITEMS=1&ASCEND=YES&REQID=${QS_fer}`
						});
						return false;
					}
					else {
						this.pageLoad();
					}
				}
				//**********************END****************************
                if (!multiAppDecRes.strApproveString) {
                    this.pageLoad();
                }
			}
		} else {
			if (multiAppDecRes.lblMsgrslt) {
				this.setState({ errorMessage: multiAppDecRes.lblMsgrslt });
			} else if (multiAppDecRes.lblDBErr1_Visible.toLowerCase() == "true") {
				this.setState({ errorMessage: multiAppDecRes.lblDBErr1 });
			} else if (multiAppDecRes.lblDBErr_Visible.toLowerCase() == "true") {
				this.setState({ errorMessage: multiAppDecRes.lblDBErr });
			}
        }
        this.DecReasonCancel();
	};
	approveDeclineSingle = (lineItemDetail, type) => {
		const { t } = this.props
		let lineItems = [];
		let selectedLines = [];
		//[5/20/22, RA_TFS_01, Bug 2156], Poornima S//Added alert to enter valid qty while declining or approving a line item
		let valid = true
		if (lineItemDetail.QTY_REQ == "0" || lineItemDetail.QTY_REQ == "" || lineItemDetail.QTY_REQ == undefined) {
			swal({
				allowOutsideClick: false,
				closeOnClickOutside: false,
				text: t("Please enter valid quantity")
			})
			//alert(t("Please enter valid quantity"));
			valid = false;
			return;
		}
		if (valid) {
			lineItems.push({
				chkLineItem: true,
				LINE_NBR: lineItemDetail.LINE_NBR,
				lblPrice: lineItemDetail.lblPrice,
				txtQTY: lineItemDetail.QTY_REQ,
				lblDueDt: lineItemDetail.lblDueDt,
				DUE_DT: lineItemDetail.DUE_DT,
				ISA_ORDER_STATUS: lineItemDetail.ISA_ORDER_STATUS,
				ltrItemNo: lineItemDetail.ltrItemNo,
				INV_STOCK_TYPE: lineItemDetail.INV_STOCK_TYPE,
				CURRENCY_CD: lineItemDetail.CURRENCY_CD,
				Description: lineItemDetail.DESCR254,
				UOM: lineItemDetail.UNIT_OF_MEASURE,
				ISAMFG_FREEFORM: lineItemDetail.ISA_MFG_FREEFORM,
				MFGITM_ID: lineItemDetail.MFG_ITM_ID,
				ISA_CUST_CHARGE_CD: lineItemDetail.ISA_CUST_CHARGE_CD,
				QTY_REQ_ORIG: lineItemDetail.QTY_REQ_ORIG

			});
			selectedLines.push(lineItemDetail.LINE_NBR);
            if (type == 2) {
                this.approveDeclineReqOrder(lineItems, selectedLines, type, 1, "QSR", this.state.Comments);
            }
            else {
                $('#ApprovalComments').modal('show');
                this.setState({ selectedLineNums: selectedLines, selectedLineItems: lineItems, SingleOrMultiSubmit: 1, ApproveOrDecline: type });
            }
		}

	};
	approveDeclineMultiple = (type) => {
		const { t } = this.props
		selectedMultiItems = [];
		let qtyBool = true;
		selectedMultiItems = this.state.Linedetails.filter((x) => x.chkLineItem == true);
		if (selectedMultiItems.length > 0) {
			let lineItems = [];
			let selectedLines = [];
			selectedMultiItems.forEach((lineItemDetail) => {
				//[5/20/22, RA_TFS_02, Bug 2156], Poornima S//Added alert to enter valid qty while declining or approving multiple line items
				if (lineItemDetail.QTY_REQ == "0" || lineItemDetail.QTY_REQ == "" || lineItemDetail.QTY_REQ==undefined) {
					qtyBool = false;
				}
				lineItems.push({
					chkLineItem: true,
					LINE_NBR: lineItemDetail.LINE_NBR,
					lblPrice: lineItemDetail.lblPrice,
					txtQTY: lineItemDetail.QTY_REQ,
					lblDueDt: lineItemDetail.lblDueDt,
					DUE_DT: lineItemDetail.DUE_DT,
					ISA_ORDER_STATUS: lineItemDetail.ISA_ORDER_STATUS,
					ltrItemNo: lineItemDetail.ltrItemNo,
					INV_STOCK_TYPE: lineItemDetail.INV_STOCK_TYPE,
					CURRENCY_CD: lineItemDetail.CURRENCY_CD,
					Description: lineItemDetail.DESCR254,
					UOM: lineItemDetail.UNIT_OF_MEASURE,
					ISAMFG_FREEFORM: lineItemDetail.ISA_MFG_FREEFORM,
					MFGITM_ID: lineItemDetail.MFG_ITM_ID,
					ISA_CUST_CHARGE_CD: lineItemDetail.ISA_CUST_CHARGE_CD,
					QTY_REQ_ORIG: lineItemDetail.QTY_REQ_ORIG

				});
				selectedLines.push(lineItemDetail.LINE_NBR);

			});
			//[5/20/22, RA_TFS_03, Bug 2156], Poornima S//Added alert to enter valid qty while declining or approving a line item
			if (qtyBool == false) {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Please enter valid quantity")
				})
				//alert(t("Please enter valid quantity"));
				return;
			}

            if (type == 2) {
                this.approveDeclineReqOrder(lineItems, selectedLines, type, 2, "QSR", this.state.Comments);
            }
            else {
                $('#ApprovalComments').modal('show');
                this.setState({ selectedLineNums: selectedLines, selectedLineItems: lineItems, SingleOrMultiSubmit: 2, ApproveOrDecline: type });
            }
		} else {
			if (type == 1) {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Please select line items to approve")
				})
			}
			else {
				swal({
					allowOutsideClick: false,
					closeOnClickOutside: false,
					text: t("Please select line items to decline")
				})
			}
			//alert(t("Please select line items to Approve/Decline"));
		}

	};
	handleCheckbox = (index) => {
		let { Linedetails } = this.state;
		Linedetails[index].chkLineItem = Linedetails[index].chkLineItem == true ? false : true;
		this.setState(Linedetails);

	};
	//***********************New******************************
	OrderTotalCal = (Price, Quantity) => {
		//let SiteCurrency = this.state.SiteCurrencyTable;
		let ItemTotal = 0;
		let OrderTotal = 0;

		let uniteprice = parseFloat(Price) ? parseFloat(Price) : 0
		let QuantityAvail = parseFloat(Quantity) ? parseFloat(Quantity) : 0
		if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
			ItemTotal = uniteprice * QuantityAvail;
			//ItemTotal = uniteprice;
		}
		OrderTotal = OrderTotal + ItemTotal;
		let roundOffvalue = OrderTotal.toFixed(2)
		return ("$ " + roundOffvalue);



	}
	//*********************end***********************************



	//  /**
	//* Listener method for the response of Save/Delete favorite page
	//*/
	//  updateFavPageResponse = () => {
	//      if (RequestorApprovalStore.updateFavPageResponse.updateType == 1) {
	//          let response = JSON.parse(RequestorApprovalStore.updateFavPageResponse.responseStr)[0];
	//          if (response.is_Success == "True") {
	//              this.setState({ isFavorited: true })
	//          }
	//      } else {
	//          let response = JSON.parse(RequestorApprovalStore.updateFavPageResponse.responseStr)[0];
	//          if (response.success == "true") {
	//              this.setState({ isFavorited: false })
	//          }
	//      }
	//  };
	//  /**
	//  * Handle change method for favorite page checkbox
	//  * @param event
	//  */
	//  handleFavorite = (event) => {
	//      this.setState({ isFavorited: event.target.checked });

	//      if (event.target.checked == true) {
	//          let insertFavPageBO = {
	//              Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
	//              pageURL: currentPagePath.toLowerCase(),
	//              pageTitle: "Request Approvals"
	//          }
	//          RequestorApprovalAction.insertFavPage(insertFavPageBO)
	//      } else {
	//          let deleteFavPageBO = {
	//              Page_ID: this.state.Page_ID
	//          }
	//          RequestorApprovalAction.deleteFavPage(deleteFavPageBO)
	//      }
	//  };
	//  /**
	//  * Listener method for loading favorite page icon
	//  */
	//  loadFavPageResponse = () => {
	//      let favPageResponse = JSON.parse(RequestorApprovalStore.currentFavPageResponse);
	//      if (favPageResponse.length > 0) {
	//          favPageResponse = favPageResponse[0];
	//          this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
	//      };
	//  };

    //[1/22/2024] Z 256 DecBtnClick() is line level decline btn onclick method, it will show decline reason pop up to user - Poornima S
    DecBtnClick = (lineItem, index) => {
        $('#DeclineReason').modal('show');
        this.setState({ disabled_button: true, singlelinedetails: lineItem, isLineClicked: true, checkboxline: index })
    }

    //[1/22/2024] Z 256 HandleBtnTextchange() method changes the name of button in decline reason pop up based on the reason selected - Poornima S
    HandleBtnTextchange = (e) => {
        debugger
        if (e.target.value == "Select") {
            this.setState({ disabled_button: true, DecReasonBtnName: "Decline", decReasonValue: e.target.value })
            $("#CmntsMandatory").addClass('d-none');
        }
        else if (e.target.value == "No Longer Needed") {
            let btnDisable = true;
            $("#CmntsMandatory").removeClass('d-none');
            if (this.state.Comments) {
                btnDisable = false;
                this.setState({ disabled_button: btnDisable, DecReasonBtnName: "Decline", CommentTextCount: 254, decReasonValue: e.target.value });
            }
            else {
                this.setState({ disabled_button: btnDisable, DecReasonBtnName: "Decline", CommentTextCount: 254, decReasonValue: e.target.value })
            }
        }
        else {
            $("#CmntsMandatory").addClass('d-none');
            this.setState({ disabled_button: false, DecReasonBtnName: "Submit", CommentTextCount: 30, decReasonValue: e.target.value })
        }

    }

    //[1/22/2024] Z 256 HandleComments() onchange method of comments text to set the target value to Comments state variable - Poornima S
    HandleComments = (e) => {
        debugger
        let btnDisable = this.state.disabled_button;
        let cmntTxt = e.target.value;
        if ((this.state.decReasonValue == "No Longer Needed")) {
            if (cmntTxt.trim() != "") {
                btnDisable = false;
            }
            else {
                btnDisable = true;
            }
        }
        this.setState({ Comments: e.target.value, disabled_button: btnDisable })
    }

    //[3/12/2024] Z 268 HandleApprovalComments() onchange method of comments text to set the target value to Comments state variable - Poornima S
    HandleApprovalComments = (e) => {
        this.setState({ Comments: e.target.value })
    }

    //[1/22/2024]Z 256 DecReasonCancel() method is triggered when close icon or cancel button of decline reason pop up is clicked to clear the elements and state variable - Poornima S
    DecReasonCancel = () => {
        debugger
        $('#DeclineReason').modal('hide');
        $("#CmntsMandatory").addClass('d-none');
        document.getElementById("decreasoncmt").value = ""

        this.setState({ DecReasonBtnName: "Decline", Comments: "", decReasonValue: "Select" })
    }

    //[1/22/2024] Z 256 DeclineMultipleLines() is triggered when order level decline button is clicked - Poornima S
    DeclineMultipleLines = () => {
        let checkedLines = [];
        checkedLines = this.state.Linedetails.filter((x) => x.chkLineItem == true);
        if (checkedLines == 0 || checkedLines == undefined) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please select any line items to decline"
            });
        }
        else {
            $('#DeclineReason').modal('show');
        }
        this.setState({ disabled_button: true, isLineClicked: false })
    }

    //[1/22/2024]Z 256 HandleDecSubClick() is triggered when decline/submit button in decline reason pop up is clicked - Poornima S
    HandleDecSubClick = () => {
        debugger
        if (this.state.DecReasonBtnName == "Decline") {
            if (this.state.isLineClicked == true) {
                this.approveDeclineSingle(this.state.singlelinedetails, 2);
                $('#DeclineReason').modal('hide');
            }
            else {
                this.approveDeclineMultiple(2);
                $('#DeclineReason').modal('hide');
            }
        }
        else {
            if (this.state.isLineClicked == true) {
                let reason = this.state.decReasonValue;
                let comments = this.state.Comments;
                this.UpdateDeclineReason(this.state.singlelinedetails, reason, comments);
                let checkid = `req-${this.state.checkboxline}`;
                $("#" + checkid).prop("checked", false);
                $('#DeclineReason').modal('hide');
            }
            else {
                let selectedMultiItems = this.state.Linedetails.filter((x) => x.chkLineItem == true);
                let reason = this.state.decReasonValue;
                let comments = this.state.Comments;
                this.UpdatemultipleDeclineReason(selectedMultiItems, reason, comments);
                $('#declinereason').modal('hide');
            }
        }
    }


    //[4/15/2024] Z- 268 CR to close approval comments while clicking cancel button and close icon 
    closeApprovalComments = () => {
        $('#ApprovalComments').modal('hide');
        this.setState({ Comments: "" })
    }

    //[1/22/2024] Z-256 UpdateDeclineReason() is to call DeclineReason() action method to updated decline reason of single line item - Poornima S
    UpdateDeclineReason = (lineItem, decreason, comments) => {
        let selectedLines = []
        let Obj = {
            ORDER_NO: lineItem.ORDER_NO,
            LINE_NBR: lineItem.LINE_NBR
        }
        selectedLines.push(Obj);

        let DeclineReasonBO = {
            DeclineReason: decreason,
            ItemDetails: selectedLines,
            Comments: comments,
            UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Bus_Unit: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (bFromEmailLink == false) {
            RequestorApprovalAction.DeclineReason(DeclineReasonBO);
        } else {
            RequestorApprovalAction.DeclineReasonUnAuth(DeclineReasonBO);
        }


    }

    //[1/22/2024] Z-256 UpdatemultipleDeclineReason() is to call DeclineReason() action method to updated decline reason of an order - Poornima S
    UpdatemultipleDeclineReason = (selectedMultiItems, decreason, comments) => {
        let selectedLines = []
        selectedMultiItems.forEach((val, index) => {
            let Obj = {
                ORDER_NO: val.ORDER_NO,
                LINE_NBR: val.LINE_NBR
            }
            selectedLines.push(Obj);
        })

        let DeclineReasonBO = {
            DeclineReason: decreason,
            ItemDetails: selectedLines,
            Comments: comments,
            UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Bus_Unit: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (bFromEmailLink == false) {
            RequestorApprovalAction.DeclineReason(DeclineReasonBO);
        } else {
            RequestorApprovalAction.DeclineReasonUnAuth(DeclineReasonBO);
        }
    }

    //[1/22/2024] Z 256 DeclineReasonResp is the response method after updating the decline reason in table - Poornima S
    DeclineReasonResp = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let { Linedetails } = this.state;
        let isDecReasonSuccess = false;
        Linedetails.forEach((val, index) => {
            Linedetails[index].chkLineItem = Linedetails[index].chkLineItem == false;
        })

        let data = JSON.parse(RequestorApprovalStore.DeclineReasonRep);
        let result = data.Table1[0].lblDBErr1;
        if (result == "Successfully Inserted") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Decline reason updated successfully"
            });
            isDecReasonSuccess = true;
            this.pageLoad();

        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: result
            });
        }
        $('#DeclineReason').modal('hide');
        document.getElementById("decreasoncmt").value = ""
        $("#CmntsMandatory").addClass('d-none');
        this.setState({ declinereason: "Decline", Comments: "", Linedetails, isDeclineReasonSuccess: isDecReasonSuccess, decReasonValue: "Select" })
    }


	bindReqApprovals = () => {
		const { t } = this.props;
		let { showprice } = this.state;
		showprice = (CookieService.getLocalStorageValues("Session_ShowPrice") == "1" && CookieService.getLocalStorageValues("Session_ISAPriceBlock") != "Y" ? "0" : "")

		console.log("showprice", showprice);
		console.log("Session_ShowPrice", CookieService.getLocalStorageValues("Session_ShowPrice"))
		console.log("Session_ISAPriceBlock", CookieService.getLocalStorageValues("Session_ISAPriceBlock"))
        
        let itemid = "";
        return this.state.Linedetails.map((lineItem, indexVal) => {
            
            itemid = "NONCAT-" + lineItem.LINE_NBR
			return (<div className="row mx-0 mb-3 pt-3 pb-2 item-box requestor-panel" key={lineItem.ORDER_NO + "-" + lineItem.LINE_NBR}>
				<div className="custom-control custom-checkbox item-custom-control">
                    <input type="checkbox" className="custom-control-input" id={`req-${indexVal}`} checked={lineItem.chkLineItem} name={`reqName-${indexVal}`} onClick={() => this.handleCheckbox(indexVal)} />
					<label className="custom-control-label" htmlFor={`req-${indexVal}`} />
				</div>
				<div className="col-lg-8 col-md-12 col-11">
					<div className="row">
						<div className="col-md-12 fav-maintence">
							<label className="catalog-label"><Trans>Description</Trans> : </label>
							<label className="item-details-value">{lineItem.DESCR254}</label>
						</div>
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-6 fav-maintence">
                                    <label className="catalog-label"><Trans>ID</Trans> : </label>
                                    {/* 12/19/23 -Zeus - 232 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                                    <label className="item-details-value">{itemid}</label>
								</div>
								{/*[4/22/22, CAS - 31, Dhevanesam R]*/}
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Manufacturer</Trans> : </label>
									<label className="item-details-value">{lineItem.ISA_MFG_FREEFORM}</label>
								</div>
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Manufacturer Part Number</Trans> : </label>
									<label className="item-details-value">{lineItem.MFG_ITM_ID}</label>
								</div>
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>unitofmeasure_key</Trans> : </label>
									<label className="item-details-value">{lineItem.UNIT_OF_MEASURE}</label>
								</div>
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Delivery Date</Trans> : </label>
									<label className="item-details-value">{lineItem.lblDueDt}</label>
                                </div>
                                {/* 12/19/23 -Zeus - 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                                <div className="col-md-6 fav-maintence">
                                    <label className="catalog-label"><Trans>Supplier</Trans> <Trans>Name</Trans> : </label>
                                    <label className="item-details-value">{lineItem.SUPPLIERNAME}</label>
                                </div>
                                {/* 12/19/23 -Zeus - 242 - INC0028868 - Item ID change as MFG part number - Johnprinto */}
                                <div className="col-md-6 fav-maintence">
                                    <label className="catalog-label"><Trans>Supplier</Trans> <Trans>Part</Trans># : </label>
                                    <label className="item-details-value">{lineItem.ltrItemNo}</label>
                                </div>
								{/************NEW**************/}
								{/*[11/18/21, PC_RA_01, Bug - 1804, Dhevanesam R]*/}
								<div className="col-md-6 fav-maintence">
									<label className="catalog-label"><Trans>Charge Code</Trans> : </label>
									<label className="item-details-value">{lineItem.ISA_CUST_CHARGE_CD}</label>
								</div>
								{/************END**************/}
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-md-12 pricesec p-0 prices">
					<div className="float-right">
						<div className="w-100 float-right">
							<div className="float-right">
								<div className="float-left">
									<div className="float-left ">
										{/*[10/08/21, PC_RA_01 BUG-1771], Priyanka D*/}
										<label className="float-left" style={{ marginTop: "11px"}}><Trans>Unit QTY</Trans> :</label>
										{/*[4/28/2022], BUG 2072 - QTY is not editable in requestor approval - Poornima S*/}
										<input type="number" min="0" value={lineItem.QTY_REQ == null || lineItem.QTY_REQ.trim == "" ? "" : lineItem.QTY_REQ} name="itemQuantity" id={`itemQuantity${indexVal + 1}`} className="form-control float-left catalog-reqApprval-qty" maxLength="8" onChange={(e) => this.handleQty(e, indexVal)} />
									</div>
								</div>
								{showprice == "0" ?
									<div className="float-right mt-2 ">
										<span className="price-calc ml-1 mr-1">X</span>
										<span className="price-rate ml-0 mr-0">$  {lineItem.NET_UNIT_PRICE > 0 ? lineItem.NET_UNIT_PRICE.toFixed(2) : t("Price on request")}</span>
									</div>:null}

							</div>
						</div>

						{showprice=="0" && lineItem.NET_UNIT_PRICE > 0 ? <div className="w-100 float-right ">
							<div className="float-right ">
								<label className="float-left mt-2"><Trans>Ext Price</Trans> :</label>
								<div className="float-left mt-2 Font13">
									<span className="price-rate">{this.OrderTotalCal(lineItem.NET_UNIT_PRICE, lineItem.QTY_REQ)}</span>
								</div>
							</div>
						</div> : null}
					</div>
					{/*<div className="prices fav-maintence">
                        <div className="float-right">
                            <div className="w-100 float-left mb-2 ">
                                <div className="float-right">
                                    <label className="float-left m-2">Unit QTY : </label>
                                    {/******************************************
                                    <input type="text" className="form-control d-inline catalog-inline-qty text-center" maxLength="8" name="itemQuantity" id={`itemQuantity${indexVal + 1}`} value={lineItem.QTY_REQ} onChange={(e) => this.handleQty(e, indexVal)} />

                                    {/* <label className="item-details-value float-left m-2">{lineItem.QTY_REQ}</label>
                                    {/******************************************
                                </div>
                            </div>
                            <div className="w-100 float-left mb-2 ">
                                <div className="float-right">
                                    <span className="price-calc ml-2">Price</span>
                                    <span className="price-rate">${lineItem.NET_UNIT_PRICE}</span>
                                </div>
                            </div>
                            <div className="w-100 float-left mb-2 ">
                                <div className="float-right">

                                    <label className="float-left mt-2">Ext Price :</label>
                                    <span className="price-rate">{this.OrderTotalCal(lineItem.NET_UNIT_PRICE, lineItem.QTY_REQ)}</span>

                                </div>
                            </div>

                        </div>
                    </div>*/}
					<div className="float-right mt-2 w-100">
                        <button className="btn p-0 action-btns reject-btn" onClick={() => this.DecBtnClick(lineItem, indexVal)}><Trans>Decline</Trans></button>
						<button className="btn p-0 action-btns approve-btn" onClick={() => this.approveDeclineSingle(lineItem, 1)}><Trans>Approve</Trans></button>
					</div>
				</div>
			</div>);
		});

	}
	render() {
		const { t } = this.props;
		return (
            <div className={`container-fluid content-section req-margin ${bFromEmailLink == true ? "margin-top-request" : ""}`} > {/* // [06/28/2024] - Zeus 327 - User Name Display -Check if user comes from email show the css else don't show the css  - Ayyappan.S*/}
				<div className="row">
					{/* Requestor Approval starts */}
					<div className="container-fluid content-margin">
						<div className="row">
                            <div className="col-md-12 page-heading mb-3">
                                {/* // [06/28/2024] - Zeus 327 - User Name Display -Check if user comes from email display the content else show null  - Ayyappan.S*/}
                                {bFromEmailLink == true && this.state.User_Name ? <div className="w-100"><h5 className="page-title w-100 mb-2">Hi, {this.state.User_Name}</h5></div> : null}
								<h5 className="page-title"><Trans>Waiting Requestor Approval</Trans></h5>
								{/*<div className="fav-item-maintenance-icon mt-1">
                                    <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-1">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                </div>*/}
                            </div>
                            {this.state.totalCount == 0 ?
                                (<div className="col-lg-12">
                                    <section className="site-undercontruction container text-center center-panel pt-4">
                                        <div className="img-section">
                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                        </div>
                                        <p className="search-result-content pt-4"><Trans>No Records Found</Trans>
                                        </p>
                                    </section>
                                </div>) :
                                (<div className="col-lg-12">
                                        <div className="row border-bottom mb-4">
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>SDI Requisition Number</Trans></label>
                                                    <label className="col-md-7 item-details-value">{this.state.headerdetails.SDI_Requisition_Number}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>Employee ID</Trans></label>
                                                    <label className="col-md-7 item-details-value">{this.state.headerdetails.txtOrdByID}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>Employee Name</Trans></label>
                                                    <label className="col-md-7 item-details-value">{this.state.headerdetails.Employee_Name}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>Work Order Number</Trans></label>
                                                    <label className="col-md-7">
                                                        {this.state.headerdetails.isWorkOrderEnable == "True" ? <input type="text" className="form-control w-75" placeholder={t("Enter Value")} value={this.state.Work_Order} onChange={this.handleWorkOrder} /> : this.state.headerdetails.Work_Order}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>Need by Date</Trans></label>
                                                    <label className="col-md-7 item-details-value">{this.state.headerdetails.Need_by_Date}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>Asset ID</Trans></label>{/*PS_AID_FE_01-Zeus 122- Machine Number should changed to Asset ID-Sathis*/}
                                                    <label className="col-md-7 item-details-value">{this.state.headerdetails.Machine_Number}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>Submit Date</Trans></label>
                                                    <label className="col-md-7 item-details-value">{this.state.headerdetails.Submit_Date}</label>
                                                </div>
                                            </div>
                                            {/*6/9/2023 - Zeus 130 - Kishore*/}
                                            <div className="col-lg-4 col-md-6 mb-3">
                                                <div className="row">
                                                    <label className="col-md-5 catalog-label"><Trans>Delivery Point</Trans></label>
                                                    <label className="col-md-7 item-details-value">{this.state.headerdetails.DeliveryPoint}</label>
                                                </div>
                                            </div>
                                            {/*/Commented Notes textbox since it is returning any value from backend --Poornima*/}
                                            {/*  <div className="col-lg-8 col-md-12 mb-3">
                                        <div className="row">
                                            <label className="col-md-5 notes-label catalog-label"><Trans>Notes</Trans></label>
                                            <label className="col-md-7">
                                                <textarea className="form-control custom-textarea" readOnly defaultValue={this.state.headerdetails.Notes} />
                                            </label>
                                        </div>
                                    </div>*/}
                                        </div>
                                        {this.bindReqApprovals()}

                                        <div className="row mx-0">
                                            <div className="col-md-12  d-none">
                                                <div className="float-right total-order">
                                                    <Trans>Order Total</Trans> <span className="price-rate">$8.4352</span>
                                                </div>
                                            </div>
                                            {/*<div className="col-md-12 my-3">
                                        <button className="btn btn-primary custom-btn-primary primary-btn float-right px-5">Recalculate</button>
                                    </div>*/}
                                            <div className="col-md-12 my-3">
                                                <button className="btn custom-btn-approve float-right px-3" style={{ "box-shadow": "none !important" }} onClick={() => this.approveDeclineMultiple(1)}><Trans>Approve</Trans></button>
                                                <button className="btn custom-btn-decline primary-btn float-right px-3 mr-2 Zeus-Tab-Btn" style={{ "box-shadow": "none !important" }} onClick={() => this.DeclineMultipleLines()}><Trans>Decline</Trans></button>
                                                <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-3 mr-2" onClick={() =>
                                                    this.props.history.push({
                                                        pathname: '/needquote',
                                                    })}><Trans>Cancel</Trans></button>
                                            </div>
                                            <div className="err-msg text-danger w-100 ">{t(this.state.errorMessage)}</div>

                                        </div>
                                </div>)
                            }
						</div>
					</div>
                </div>

                {/*Decline reason modal starts*/}
                <div className="modal" id="DeclineReason" aria-modal="true">
                    <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/*Modal Header*/}

                            <div className="modal-header">
                                <h4 className="modal-title mt-1"><Trans>Reason for Decline</Trans></h4>
                                <button type="button" className="btn close-btn" onClick={() => this.DecReasonCancel()} data-dismiss="modal">
                                    X
                                          </button>
                            </div>
                            {/*Modal body*/}

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 float-left">
                                        <div className="col-md-12 col-lg-12 mb-3 float-left p-0">
                                            <label className="col-lg-12 col-md-12 float-left p-0 catalog-label form-label"><Trans>Reason</Trans></label>
                                            <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                                <select className="form-control" id="decReasonValue" value={this.state.decReasonValue} onChange={this.HandleBtnTextchange}>
                                                    <option value="Select"> {t("Select")}</option>
                                                    <option value="Too Expensive (Need Cheaper Quote)"> {t("Too Expensive (Need Cheaper Quote)")}</option>
                                                    <option value="Wrong Item(s)"> {t("Wrong Item(s)")}</option>
                                                    <option value="Wrong Ship To Store"> {t("Wrong Ship To Store")}</option>
                                                    <option value="No Longer Needed"> {t("No Longer Needed")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12 moredetail-content">
                                        <label className="catalog-label d-block"><Trans>Comments</Trans></label><span className="color-manditory d-none" id="CmntsMandatory">*</span>
                                        <textarea className="form-control custom-textarea " maxLength={this.state.CommentTextCount} id="decreasoncmt" placeholder="" onChange={(e) => this.HandleComments(e)} value={this.state.Comments}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2 text-right p-0">
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-3 px-4 mt-3" id="cancelBtn" onClick={() => this.DecReasonCancel()} data-dismiss="modal"><Trans>Cancel</Trans></button>
                                    <button className="btn btn-primary custom-btn-primary primary-btn px-4 mt-3" id="SubmitDecBtn" disabled={this.state.disabled_button} onClick={() => this.HandleDecSubClick()}><Trans>{this.state.DecReasonBtnName}</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Decline reason modal ends*/}
                {/*Zeus 255 - Limit Exceeded Modal Starts here*/}
                <div className="modal fade order-limit pl-0" data-backdrop="static" data-keyboard="false"  id="limit-exceeded" tabIndex={-1} role="dialog" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered order-limit-custom-width" role="document" >
                        <div className="modal-content">
                            <div className="modal-header sp-delv-header align-items-center">
                                <h5 className="modal-title" id="exampleModalLongTitle"> {this.state.limitExeData[1]}</h5>
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={this.closeLimitExceedPoppup}>
                                    <img src="images/close-icon.svg" alt="close" />
                                </button>
                            </div>
                            {/*Form Starts here*/}
                            <div className="modal-body px-4">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <span className="font-14 font-regular custom-light-black">{this.state.limitExeData[0]}</span>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <span className="font-14 font-medium custom-black"> Approver Name: </span>
                                        <span className="font-14 font-regular ml-1 custom-light-black">{this.state.limitExeData[2]}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <span className="font-14 font-medium custom-black">Email ID:</span>
                                        <span className="font-14 font-regular ml-1 custom-light-black">{this.state.limitExeData[3]}</span>
                                    </div>
                                </div>
                            </div>
                            {/*Form ends here*/}
                            {/*button*/}
                            <div className="modal-footer border-0 pt-1 px-4">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={this.closeLimitExceedPoppup} > OK </button>
                            </div>
                            {/*button*/}
                        </div>
                    </div>
                </div>
                {/*Limit Exceeded Modal End here*/}
                {/*Approval comments modal starts here*/}
                <div class="modal fade approval-popup pl-0" id="ApprovalComments" tabindex="-1" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content border-0">
                            <div class="modal-header sp-delv-header align-items-center">
                                <h5 class="modal-title" >Approval comments</h5>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.closeApprovalComments()}>
                                    X
                                </button>
                            </div>

                            <div class="modal-body px-4">
                                <div class="row">

                                    <div class="col-md-12 mb-3">
                                        <label class="form-label catalog-label" for="comments">Comments</label>
                                        <textarea class="form-control" maxLength="254" id="ApprvComment" placeholder="Enter comments" value={this.state.Comments} onChange={(e) => this.HandleApprovalComments(e)} rows="3"></textarea>
                                    </div>

                                </div>
                            </div>

                            <div class="modal-footer border-0 pt-1 mb-2 px-4">
                                <button id="ApprvCommentCancel" class="btn btn-secondary custom-btn-secondary secondary-btn mr-2 px-4 py-2" data-dismiss="modal" onClick={() => this.closeApprovalComments()}>Cancel</button>
                                <button id="ApprvCommentSave" class="btn btn-custom-primary font-15 font-medium px-4 py-2" onClick={() => this.approveDeclineReqOrder(this.state.selectedLineItems, this.state.selectedLineNums, 1, this.state.SingleOrMultiSubmit, "QSA", this.state.Comments)}>Save</button>
                            </div>

                        </div>
                    </div>
                </div>
                {/*Approval comments modal ends here*/}


			</div>
		);
	};
};

//[PC_07, 09/13/21, Author - Dhevanesam R]
export default withTranslation()(RequestorApprovalComponent);
