
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class AddNewPunchinUserStore extends EventEmitter {
    PunchinData = "";
    loginSuccess = "";
    loginError = "";
    
    handlePunchinStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.AddNewPunchinUser_Adduser: {
                this.PunchinData = action.action.response;
                this.emit('load');
                this.emit('contactChange'); //[Zeus 208, 11/24/2023] - Footer component not rendered bug fix - Kishore
                break;
            }
                case SDIConstants.methodNames.AddNewPunchinUser_submit: {
                this.PunchinData = action.action.response;
                this.emit('submit');
                break;
            }

        }
    }
}

const objAddNewPunchinUserStore = new AddNewPunchinUserStore;
// Dispatcher code
Dispatcher.register(objAddNewPunchinUserStore.handlePunchinStore.bind(objAddNewPunchinUserStore));
export default objAddNewPunchinUserStore;