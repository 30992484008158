/**
 * 
 * RMAA_PC_01
 */

import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as $ from 'jquery';
import * as MenuAction from './MenuAction';
/**
 * 
 * RMAA_PC_02
 */
const pageLoadAction = (BO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RMA,
            SDIConstants.methodNames.RMA_PageLoad,
            JSON.stringify(BO),

            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RMA_PageLoad, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const searchBtnClickAction = (BO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RMA,
            SDIConstants.methodNames.RMA_SearchButtonClick,
            JSON.stringify(BO).replaceAll(/'/g, "''"),

            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RMA_SearchButtonClick, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}

const saveButtonClickAction = (BO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RMA,
            SDIConstants.methodNames.RMA_SaveButtonClick,
            JSON.stringify(BO),

            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RMA_SaveButtonClick, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const reasonSelectAction = (BO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.RMA,
            SDIConstants.methodNames.RMA_ReasonSelectClick,
            JSON.stringify(BO),

            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.RMA_ReasonSelectClick, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                }
                else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });
    } catch (ex) {
        console.log(ex)
    }
}
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "NOTIFY_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export { pageLoadAction, searchBtnClickAction, saveButtonClickAction, reasonSelectAction, insertFavPage, deleteFavPage, getCurrentFavPage }