/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 08/18/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
import * as $ from 'jquery';
const getApprovals = async (orderApprovalBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderApproval,
            SDIConstants.methodNames.OrderApproval_PageLoad,
            JSON.stringify(orderApprovalBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderApproval_PageLoad, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const getApprovalsUnauth = async (orderApprovalBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.OrderApproval,
            SDIConstants.methodNames.OrderApproval_PageLoad,
            JSON.stringify(orderApprovalBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderApproval_PageLoad, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const exportApprovals = async (exportBO) => {
    try {

        SDISVC.downloadFile(
            SDIConstants.moduleNames.OrderApproval,
            SDIConstants.methodNames.OrderApproval_ExportToExcel,
            JSON.stringify(exportBO),
            "ExportOrderApprovals.xlsx",
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                  
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const approveHeaderOrders = async (approveBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderApproval,
            SDIConstants.methodNames.OrderApproval_ApproveHeaderOrder,
            JSON.stringify(approveBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderApproval_ApproveHeaderOrder, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const approveHeaderOrdersUnAuth = async (approveBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.OrderApproval,
            SDIConstants.methodNames.OrderApproval_ApproveHeaderOrder,
            JSON.stringify(approveBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderApproval_ApproveHeaderOrder, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const declineHeaderOrders = async (declineBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.OrderApproval,
            SDIConstants.methodNames.OrderApproval_DeclineHeaderOrder,
            JSON.stringify(declineBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderApproval_ApproveHeaderOrder, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const declineHeaderOrdersUnAuth = async (declineBO) => {
    try {

        SDISVC.callGateway(
            SDIConstants.moduleNames.OrderApproval,
            SDIConstants.methodNames.OrderApproval_DeclineHeaderOrder,
            JSON.stringify(declineBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.OrderApproval_ApproveHeaderOrder, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * getCurrentFavPage method gets called on page load
 * @param getFavPageBO
 */
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ORDERAPP_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ORDERAPP_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "ORDERAPP_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
export {
    getApprovals,
    getApprovalsUnauth,
    exportApprovals,
    approveHeaderOrders,
    approveHeaderOrdersUnAuth,
    declineHeaderOrders,
    declineHeaderOrdersUnAuth,
    getCurrentFavPage,
    insertFavPage,
    deleteFavPage
};

