/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Crated Date: 08/24/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import * as SDISVC from '../SDIService';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';
import * as MenuAction from './MenuAction';
import * as $ from 'jquery';

const getWaitingReqData = async (pageLoadBO) => {
    try {

        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.WaitingRequestorApproval,
            SDIConstants.methodNames.WaitingRequestorApproval_Pageload,
            JSON.stringify(pageLoadBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: SDIConstants.methodNames.WaitingRequestorApproval_Pageload, response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
const exportData = async (exportBO) => {
    try {

        SDISVC.downloadFile(
            SDIConstants.moduleNames.WaitingRequestorApproval,
            SDIConstants.methodNames.WaitingRequestorApproval_ExportToExcel,
            JSON.stringify(exportBO),
            "Export_RequestorApprovals.xlsx",
            (data) => {
                if (data.success) {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                } 
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * getCurrentFavPage method gets called on page load
 * @param getFavPageBO
 */
const getCurrentFavPage = async (getFavPageBO) => {
    try {
        SDISVC.callRecurringGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_GetCurrentFavPage,
            JSON.stringify(getFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "WREQAPP_CurrentFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * insertFavPage method gets called on adding page to favorite
 * @param insertFavPageBO
 */
const insertFavPage = async (insertFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_InsertFavPage,
            JSON.stringify(insertFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "WREQAPP_InsertFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};
/**
 * deleteFavPage method gets called on removing page from favorite
 * @param deleteFavPageBO
 */
const deleteFavPage = async (deleteFavPageBO) => {
    try {
        SDISVC.callSecureGateway(
            SDIConstants.moduleNames.Menu,
            SDIConstants.methodNames.Menu_DeleteFavPage,
            JSON.stringify(deleteFavPageBO),
            (data) => {
                if (data.success) {
                    if (data.response != "" && data.response) {
                        let contentData = { type: "WREQAPP_DeleteFavPage", response: data.response };
                        Dispatcher.dispatch(contentData);
                    }
                } else if (data.success == false) {
                    if (data.response) {
                        if (data.response == SDIConstants.tokenExpired) {
                            MenuAction.tokenExpired();
                        }
                    }
                }
            });

    } catch (ex) {
        console.log(ex)
    }
};

export {
    getWaitingReqData,
    exportData,
    getCurrentFavPage,
    insertFavPage,
    deleteFavPage
}