/**
 * [Ref] - Denotes Pseudo Code Reference  
 *  
 * This component is the Punchout page component. The component displays the Punchout page details.
 * 
 * App Name: SDI-Walmart
 * Author: Chandrasekar
 * Created Date: 08/17/2020 
 */
import React, { Component } from 'react';
import * as CookieService from '../CookieService';
/**
 * Ref: PO_PC_02
 * Importing the action and store file and set to an object.
 */
import * as PunchoutAction from '../actions/PunchoutAction';
import PunchoutStore from '../stores/PunchoutStore';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import * as MenuAction from '../actions/MenuAction';
//[PC_07, 09/09/21, Author -  Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
//[PC_ZE_147_APP_1, 06/28/23, Zeus 147-Punchout wo validation - Sathis N]
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
import ShoppingCartStore from '../stores/ShoppingCartStore';
import * as SearchResultsAction from '../actions/SearchResultsAction';
import SearchResultsStore from '../stores/SearchResultsStore';
import swal from 'sweetalert';
let currentPagePath;
let serviceURL = "";//await getAPIURL();
console.log("serviceURL", serviceURL);

class PunchoutComponent extends Component {
  /**
   * Ref: PO_PC_04
   * Define the constructor that takes props as parameter
   * 
   * @param props
   */
    constructor(props) {
        super(props);
        this.assignPunchoutData = this.assignPunchoutData.bind(this);
        this.assingLogoData = this.assingLogoData.bind(this);
        this.loadFavPageResponse = this.loadFavPageResponse.bind(this);
        this.handleFavorite = this.handleFavorite.bind(this);
        this.updateFavPageResponse = this.updateFavPageResponse.bind(this);
        this.logoClick = this.logoClick.bind(this);
        this.COStatus = this.COStatus.bind(this);
      /**
       * Ref: PO_PC_05
       * Set the initial values of the state variables
       */
        this.state = {
            punchoutData: [], errorMessage: "", isFavorited: false, isPageLoaded: false, isError: false, apiURL: "",
            //New Site Change 10/11/2020
            PageTitle: CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0W01" ? "Pickup From Local Supplier" : "Punchout Catalog Search for ZEUS",
            workOrderDescription: CookieService.getLocalStorageValues("workOrderDescription") != "" && CookieService.getLocalStorageValues("workOrderDescription") != null && CookieService.getLocalStorageValues("workOrderDescription") != undefined ? CookieService.getLocalStorageValues("workOrderDescription") : "",
            disableWo: CookieService.getLocalStorageValues("workOrderDisable") != "" && CookieService.getLocalStorageValues("workOrderDisable") != null && CookieService.getLocalStorageValues("workOrderDisable") != undefined ? CookieService.getLocalStorageValues("workOrderDisable") : false,
            ValidatedWo: CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("validatedWO") != "error" && CookieService.getLocalStorageValues("validatedWO") != "blockpopuprepeat" && CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != "null" ? CookieService.getLocalStorageValues("validatedWO") : ""
        }
    };
    /*
     * To Get API URL from Controller
     */
    setAPIURL = () => {
        let cachedURL = sessionStorage.getItem("serviceURL");
        if (!cachedURL || cachedURL == "") {
            $.ajax({
                type: "GET",
                url: SDIConstants.localService
            }).then((data) => {
                this.setState({ apiURL: data });
                console.log("ThroughCall", data);
                sessionStorage.setItem("serviceURL", data);
            });

        } else {
            console.log("ThroughCache", sessionStorage.getItem("serviceURL"));
            this.setState({ apiURL: sessionStorage.getItem("serviceURL") });
        }
    };
    /**
    * Ref: PO_PC_06
    * componentWillMount method will be first method which will be invoked
    * when punchout page is loaded
    */
    componentDidMount() {
        /**
         * Ref: PO_PC_08
         * Invoke the action to get the page load data from API.
         */
        //[PC_ZE_147_APP_1, 06/28/23, Zeus 147-Punchout wo validation - Sathis N]
        if (CookieService.getLocalStorageValues("Session_BUSUNIT") != 'I0631' || CookieService.getLocalStorageValues("Session_ValidateWorkOrder") != "Y") {
            let pageLoadBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME"),
                Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                Session_PUNCART: "",
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            currentPagePath = this.props.location.pathname;
            currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
            let getFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            };
            PunchoutAction.getCurrentFavPage(getFavPageBO);
            PunchoutAction.getPunchoutSites(pageLoadBO);
            this.setAPIURL();

        }
        else {
            this.handleWorkOrderPopup()
        }

        /**
 * Ref: PO_PC_07
 * Define the listener methods
 */
        PunchoutStore.on("punchoutData", this.assignPunchoutData);
        PunchoutStore.on("logoData", this.assingLogoData);
        PunchoutStore.on('favpage', this.loadFavPageResponse);
        PunchoutStore.on('updateFavPage', this.updateFavPageResponse);
        SearchResultsStore.on('workordercheck', this.woValidateResponse);
        ShoppingCartStore.on('CancelOrder', this.COStatus);
    };
    componentWillUnmount() {
        PunchoutStore.removeListener("punchoutData", this.assignPunchoutData);
        PunchoutStore.removeListener("logoData", this.assingLogoData);
        PunchoutStore.removeListener('favpage', this.loadFavPageResponse);
        PunchoutStore.removeListener('updateFavPage', this.updateFavPageResponse);
        SearchResultsStore.removeListener('workordercheck', this.woValidateResponse);
        ShoppingCartStore.removeListener('CancelOrder', this.COStatus);
    }
    /**
     * Ref: PO_PC_10
     * Listener method for page load response
     */
    assignPunchoutData = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let punchoutData = JSON.parse(PunchoutStore.punchoutData);
        //[11/30/2023 - Zeus 237 - Show supplier on search and punchout based on table value - Johnprinto]
         this.setState({ punchoutData: punchoutData.PunchoutSites, isPageLoaded: true });
    };
    /**
     * Ref: PO_PC_11
     * Listener method for Logo click action
     */
    assingLogoData = () => {
        //[06/3/2023 - Zeus 116 - the page is refreshed, the punchout logos are displayed for around 1-2 sec and then the partner’s site is open - bug fix - Johnprinto D]
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let imageLogoResponse = JSON.parse(PunchoutStore.imageLogoResponse);
        console.log("imageLogoResponse", imageLogoResponse);
        if (imageLogoResponse.length > 0) {
            CookieService.updateLocalStorageValues("Session_VENDOR_CONFIG", imageLogoResponse[0].Session_VENDOR_CONFIG);
            if (imageLogoResponse[0].RedirectURL) {
                window.location.href = imageLogoResponse[0].RedirectURL;
                //[08/05/2024 - Zeus - 361 - Add the loader hide hide after 6 sec if we get an URL - Johnprinto D ]
                setTimeout(() => {
                    $(`#${SDIConstants.SDILoader}`).addClass('hide');
                }, 6000);
            } else if (imageLogoResponse[0].lblerror) {
                //[08/05/2024 - Zeus - 361 - Hide the loader if any exception occure - Johnprinto D ]
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isError: true });
            }
        } else {
            //[08/05/2024 - Zeus - 361 - Hide the loader if any exception occure - Johnprinto D ]
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            this.setState({ isError: true });
        }
    };
    /**
     * Ref: PO_PC_09
     * This method will be invoked on click of Punchout vendor logo
     */
    logoClick = (event, VendorID) => {
        event.preventDefault();
        let logoBO = {
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_PUNCART: "",
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_IOLServer: CookieService.getLocalStorageValues("Session_IOLServer"),
            vendorID: VendorID,
            Session_email: CookieService.getLocalStorageValues("Session_EMAIL"),
            Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
            Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
            Session_cplusServer: CookieService.getLocalStorageValues("Session_cplusServer"),
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        PunchoutAction.punchoutLogoClick(logoBO);

    };
    /**
     * Ref: PO_PC_13
     * This method will be invoked on render for binding punchout data
     */
    bindPunchoutSites = () => {
        return this.state.punchoutData.map((site, index) => {
            let imageLogo = "";
            //[05/17/2023-ZEUS-116-Check the punch out logo is available in the API folder or in the public URL ]
            if (site.LOGOIMAGE != undefined && site.LOGOIMAGE != null) {
                if (site.LOGOIMAGE.includes("http")) {
                    imageLogo = site.LOGOIMAGE.replace(":8083", "");;
                }
                else {
                    imageLogo = site.LOGOIMAGE ? this.state.apiURL + site.LOGOIMAGE : "";
                }
            }
            return (<a href="#" key={index} className="col-6 col-sm-4 col-lg-3 float-left vendor-container mb-3" onClick={(e) => this.logoClick(e, site.VENDOR_ID)}>
                <div className="col-md-12 vendorlogo-container" >
                    <img src={imageLogo} className="pounchout-logo" alt={site.PUNCHOUT_NAME} />
                </div>
            </a>);
        })
    };
    /**
    * Listener method for the response of Save/Delete favorite page
    */
    updateFavPageResponse = () => {
        if (PunchoutStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(PunchoutStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(PunchoutStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    /**
    * Handle change method for favorite page checkbox
    * @param event
    */
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        //let TitleValue = CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0W01" ? "Pickup From Local Supplier":"PunchOut"
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: this.state.PageTitle,
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            PunchoutAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            PunchoutAction.deleteFavPage(deleteFavPageBO)
        }
    };
    /**
    * Listener method for loading favorite page icon
    */
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(PunchoutStore.currentFavPageResponse);
        console.log("favPageResponse", favPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    //[PC_ZE_147_APP_2, 06/28/23, Zeus 147-Punchout wo validation - Sathis N]
    handleWorkOrderPopup = () => {
        if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("validWO") != "clearWO" && CookieService.getLocalStorageValues("validWO") != " " && CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null) {
            //[11/30/2023 - Zeus 237 - Show supplier on search and punchout based on table value - Johnprinto]
            let pageLoadBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME"),
                Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                Session_PUNCART: "",
                WODesc: CookieService.getLocalStorageValues("WOName"),
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            currentPagePath = this.props.location.pathname;
            currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
            let getFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            };
            PunchoutAction.getCurrentFavPage(getFavPageBO);
            PunchoutAction.getPunchoutSites(pageLoadBO);
            this.setAPIURL();
        }
        else {
            $('#WorkOrder-punchout').modal('show');
            return;
        }


    }
    //[PC_ZE_147_APP_4, 06/28/23, Zeus 147-Punchout wo validation - Sathis N]
    WOClick = () => {
        debugger
        let workOrder = $('#WONUM').val()
        if (workOrder != "" && workOrder != null && workOrder != undefined) {
            if (this.state.disableWo != true) {
                let workOrderBO = {
                    WorkOrder: workOrder,
                    BusinessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT")
                }
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                SearchResultsAction.workOrderValidation(workOrderBO);
            }
        }
        else {
            this.setState({ WOmsg: "Please enter work order number" });
        }
    }
    //[PC_ZE_147_APP_5, 06/28/23, Zeus 147-Punchout wo validation - Sathis N]
    woValidateResponse = () => {
        const { t } = this.props;
        let workOrder = $('#WONUM').val()
        let woValidationData = SearchResultsStore.woValidationData;
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (woValidationData != undefined && woValidationData != null && woValidationData != "" && woValidationData != "null") {
            if (woValidationData != "error occured in server" && woValidationData != "error occured" && woValidationData != "authorization required"
                && woValidationData != "internal server error" && woValidationData != "invalid") {
                let responeData = JSON.parse(SearchResultsStore.woValidationData);
                debugger
                if (responeData[0].WOVALIDATE != null && responeData[0].WOVALIDATE != "") {
                    if (responeData[0].WOVALIDATE == "valid") {
                        if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                this.CancelOrder();
                            }
                        }
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].WONAME);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workorderLocation", responeData[0].LOCATION);
                        if (CookieService.getLocalStorageValues("Session_BUSUNIT") == "I0631") {
                            CookieService.updateLocalStorageValues("validWO", workOrder);
                            CookieService.updateLocalStorageValues("WOName", responeData[0].WONAME);
                            //[11/30/2023 - Zeus 237 - Show supplier on search and punchout based on table value - Johnprinto]
                            let rmvndr = responeData.map(function (obj) {
                                return obj.REMOVE_VNDR_ID;
                            });
                            CookieService.updateLocalStorageValues("RemoveVNDR", rmvndr);
                            if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("validWO") != " " && CookieService.getLocalStorageValues("WOName") != undefined && CookieService.getLocalStorageValues("WOName") != null) {

                                this.handleWorkOrderPopup()

                            }


                        }
                        else {
                            CookieService.updateLocalStorageValues("validWO", "");
                            CookieService.updateLocalStorageValues("WOName", "");
                        }
                        this.setState({ WOmsg: "", ValidatedWo: workOrder, disableWo: true, workOrderDescription: responeData[0].WONAME })
                        $('#WorkOrder-punchout').modal('hide');
                    }

                }

            }
            else if (woValidationData == "invalid") {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workorderLocation", "");
                $(`#WONUM`).val('');
                this.setState({ WOmsg: "Please enter valid work order number" })
            }
            else {
                CookieService.updateLocalStorageValues("validatedWO", "error");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workorderLocation", "");
                $(`#WONUM`).val('');
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error occured in work order validation")
                })
            }

        }
    }
    //[PC_ZE_147_APP_7, 06/28/23, Zeus 147-Punchout wo validation - Sathis N]
    CancelOrder = () => {
        debugger
        let cancelorderBo = {
            Session_UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        ShoppingCartAction.CancelOrderItem(cancelorderBo);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    COStatus = () => {
        const { t } = this.props;
        if (CookieService.getLocalStorageValues("Session_UserID").toUpperCase() != "I0631") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        let CancelResponse = JSON.parse(ShoppingCartStore.CancelOrderMsge);
        if (CancelResponse == null || CancelResponse == "" || CancelResponse == " ") {
            this.refreshMenu();
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Issue in clearing the cart")
            });
        }
    }
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        MenuAction.getMasterData(masterBO);
    };
    //[PC_ZE_147_APP_8, 06/28/23, Zeus 147-Punchout wo validation - Sathis N]
    WOClose = () => {
        this.setState({ ValidatedWo: "" })
        $('#WorkOrder-punchout').modal('hide');
        this.props.history.push({
            pathname: '/HomePage',
        })
    }

    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        return (
            <div className="container-fluid content-section">
                <div className="row">
                    {this.state.punchoutData.length > 0 ? (<div className="container-fluid content-margin">
                        <div className="row justify justify-content-center mx-0">
                            <div className="col-md-12 page-heading mb-3">
                                {/*2546 - UI issue in punch out page - Dhamotharan P*/}
                                <h5 className="page-title PunchoutTitle"><Trans>{this.state.PageTitle}</Trans><span>
                                    <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                    <label htmlFor="checkbox-1">
                                        <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                            <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                <g className="grp7" opacity={0} transform="translate(7 6)">
                                                    <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                    <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                </g>
                                                <g className="grp6" opacity={0} transform="translate(0 28)">
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                </g>
                                                <g className="grp3" opacity={0} transform="translate(52 28)">
                                                    <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                    <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                </g>
                                                <g className="grp2" opacity={0} transform="translate(44 6)">
                                                    <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                    <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp5" opacity={0} transform="translate(14 50)">
                                                    <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp4" opacity={0} transform="translate(35 50)">
                                                    <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                    <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                </g>
                                                <g className="grp1" opacity={0} transform="translate(24)">
                                                    <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                    <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                </g>
                                            </g>
                                        </svg>
                                    </label>
                                </span></h5>
                                
                            </div>
                            {this.state.isError == false ? (<div className="col-md-8 mx-auto my-5" id="punchoutContent1">
                                <h2 className="headingl2"><Trans>Partner Vendor</Trans></h2>
                                <p className="col-md-12 float-left my-2 p-0 punchout-helptext">** <Trans>Click on vendor's logo below to start browsing their catalog</Trans>.</p>
                                {this.bindPunchoutSites()}

                            </div>) :
                                (<div className="col-md-7 float-left" id="punchoutContent2">
                                        <p className="error-mg-txt text-center">*** <Trans>ERROR</Trans> ***</p>
                                        <br />
                                        <br />
                                        <p className="error-txt-msg text-center"><Trans>PunchOut is not currently available for this Vendor</Trans>.&nbsp;&nbsp;<br /><br /><Trans>A message was sent notifying site administrator of this problem</Trans>.&nbsp;&nbsp;<Trans>We apologize for this inconvenience</Trans>.&nbsp;&nbsp;</p>
                                        <div className="text-center my-5 ">
                                        <button className="btn btn-primary custom-btn-primary primary-btn secondaryinfo" onClick={() => this.props.history.push({
                                            pathname: '/homepage',
                                        })}><Trans>Return</Trans></button>
                                        </div>
                                 </div>)}
                        </div>
                    </div>) :
                      this.state.isPageLoaded == true ? (<section className="site-undercontruction container text-center center-panel">
                            <p className="primaryinfo"><Trans>No Vendor Available</Trans></p>
                            <p className="secondaryinfo"><Trans>There are no available vendors</Trans></p>
                            <div className="img-section">
                                <img src="images/no_vendor.jpg" alt="imagefile" />
                            </div>
                            <div className="text-center my-3">
                                <button className="btn btn-primary custom-btn-primary primary-btn secondaryinfo" onClick={() => this.props.history.push({
                                    pathname: '/homepage',
                                })}><Trans>Back To Home</Trans></button>
                            </div>
                        </section>) : null}
                    <div className="modal userid" id="WorkOrder-punchout" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog  custom-popup modal-dialog-centered">
                            <div className="modal-content">
                                {/* Modal Header */}
                                <div className="modal-header">
                                    <h4 className="modal-title mt-1"><Trans>Work Order Number</Trans></h4>
                                    <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.WOClose()}>
                                        X
              </button>
                                </div>
                                {/* Modal body */}
                                <div className="modal-body">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 my-3">
                                            <div className="row">
                                                <label className="col-md-4 form-label catalog-label"><Trans>workorder_key</Trans><span> #</span></label>
                                                <div className="col-md-7">
                                                    <input type="text" className="form-control " placeholder="Enter work order number" id="WONUM" name="WorkOrderNo" disabled={this.state.disableWo} maxLength="80" defaultValue={this.state.ValidatedWo} />
                                                    {this.state.WOmsg != "" ? <span id="WOError" className="DropDown-Font-Size errorcolour"><Trans>{this.state.WOmsg}</Trans></span> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center mb-2">
                                        {CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? <span className=" W-50 text-left " style={{ fontSize: '15px' }}>*<Trans>The items in the cart will be removed by changing the WO</Trans></span> : null}
                                        <button className={`btn btn-primary custom-btn-primary primary-btn px-3  ${CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y" ? ' mt-3' : ''}`} id="WOOK" onClick={() => this.WOClick()}><Trans>Submit</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    };
    
}
//[PC_07, 09/09/21, Author -  Priyanka D]
export default withTranslation()(PunchoutComponent)