/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 07/30/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
 * Ref: MN_PC_01
*/
import React, { Component } from 'react';
import * as $ from 'jquery';
//[PC_04, 09/07/21, Author - Dhevanesam R, Priyanka D]
import Select, { components } from "react-select";
import i18n from '../i18n';
//[PC_07, 09/07/21, Author - Dhevanesam R, Priyanka D]
import { withTranslation, Trans } from 'react-i18next';
import Image from 'react-image-resizer';
import * as MenuAction from '../actions/MenuAction';
import MenuStore from '../stores/MenuStore';
import * as CookieService from '../CookieService';
import SDIConstants, { redirectToItemDetails } from '../SDIConstants';
import BreadCrumpData from '../stores/BreadCrumpStore';
import IdleTimer from 'react-idle-timer';
import Autocomplete from 'react-autocomplete';
import swal from 'sweetalert';
//[8/11/22, Bug - 2358, PS_MC_01, Dhevanesam R]
import { RotatingLines } from 'react-loader-spinner';
import * as ShoppingCartAction from '../actions/ShoppingCartAction';
import ShoppingCartStore from '../stores/ShoppingCartStore';

const availableMenu = SDIConstants.availablePages;
let time;
let isFirstTimeLoaded = false;
let currentPath = "";
//[PC_04, 09/07/21, Author - Dhevanesam R, Priyanka D]
//[PC_01, 03/18/22, CAS - 31, Dhevanesam R]
const options = [
    {
        value: 'en', label: 'English', icon:
            "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
    },
    {
        value: 'es', label: 'Español', icon: "http://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg"
    },
    {
        value: 'fr', label: 'Français (Canada)', icon: "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"
    }
]
//[PC_05, 09/07/21, Author - Dhevanesam R, Priyanka D]
const { Option } = components;
const IconOption = props => (
    <Option {...props}>
        <img
            src={props.data.icon}
            alt={props.data.label}
            style={{ "width": "12px" }}
        />
        {" "}
        {props.data.label}
    </Option>
);

let workOrder;

class MenuComponent extends Component {
    _isMounted = false;
    /**
  * Ref: LN_PC_04
  * Define the constructor that takes props as parameter
  * 
  * @param props
  */
    //CrossSiteArr = [];
    constructor(props) {
        super(props);
        this.assignUserMenu = this.assignUserMenu.bind(this);
        this.menuNavigation = this.menuNavigation.bind(this);
        this.showChild = this.showChild.bind(this);
        this.showParent = this.showParent.bind(this);
        this.hideHamBurger = this.hideHamBurger.bind(this);
        this.assignMasterData = this.assignMasterData.bind(this);
        this.headerRedirect = this.headerRedirect.bind(this);
        this.favPagelinkClick = this.favPagelinkClick.bind(this);
        this.addFavItemsToCart = this.addFavItemsToCart.bind(this);
        this.selectFavItem = this.selectFavItem.bind(this);
        this.addFavOrderItemsToCart = this.addFavOrderItemsToCart.bind(this);
        this.selectFavOrderItem = this.selectFavOrderItem.bind(this);
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.logout = this.logout.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.getItemValue = this.getItemValue.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.sessionTimeoutMethod = this.sessionTimeoutMethod.bind(this);
        this.retrieveDataAsynchronously = this.retrieveDataAsynchronously.bind(this);
        this.megaMenuAssigment = this.megaMenuAssigment.bind(this);
        this.searchKeyPress = this.searchKeyPress.bind(this);
        this.searchClick = this.searchClick.bind(this);
        this.COStatus = this.COStatus.bind(this);
        this.state = {
            userMenu: [], headerMenu: [], childMenu: [], currentHeader: "", ShowFavOrder: true,
            childComponents: null, hasFavItem: true,
            hasReqApproval: false, hasOrderApproval: false, hasFavOrder: true, hasMyAccount: false, hasOrderStatus: false, hasSupplierCallin: false,
            //[8/11/22, Bug - 2358, PS_MC_02, Dhevanesam R]
            cartCount: -1, notificationCount: -1, requestorApprovalCount: 0, orderApprovalCount: 0, myAccQS: "", favPages: [], logo: "/Images/sdi-logo.png"
            , AnswerlabVisible: false, lblTest: "", lblTestVisible: false, lblVersion: "", favitems: [], favorders: [], cartItems: [],
            favItemsCount: 0, favOrdersCount: 0, favOrderLineItems: [], favOrderLineItemImgs: [],
            autoCompleteValue: "",
            autocompleteData: [],
            timeout: 1000 * 60 * SDIConstants.sessionTimeout,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            loading: false,
            breadCrumpDetails: [],
            megaMenuCategories: [],
            megaMenuHeader: [], selectedCrossSite: "",
            apiURL: "", FavitemSelected: false, CrossSiteArr: [],
            // Zeus 206 - Menu revamp - Johnprinto 
            SuggestionMenu: [],
            megaMenuList: [],
            UserMenuData: [],
            ActualMenuSugg: [],
            PrivList: [], hasHelp: false, isCrossSiteChange: false,//[Zeus 208, 11/22/2023] - Multi site change for menu refresh - Kishore
            Login_UserId: (CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID")),
            Login_UserBU: (CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU")),
            //[10/18/21, PC_01, Bug ID - 1736, Dhevanesam R]
            language: CookieService.getLocalStorageValues("newlanguage") != null && CookieService.getLocalStorageValues("newlanguage") != undefined && CookieService.getLocalStorageValues("newlanguage") != false ? CookieService.getLocalStorageValues("newlanguage") : options[0],
            SiteCurrencyTable: [],
            disableWo: CookieService.getLocalStorageValues("workOrderDisable") != "" && CookieService.getLocalStorageValues("workOrderDisable") != null && CookieService.getLocalStorageValues("workOrderDisable") != undefined ? CookieService.getLocalStorageValues("workOrderDisable") : false,
            workOrderDescription: CookieService.getLocalStorageValues("workOrderDescription") != "" && CookieService.getLocalStorageValues("workOrderDescription") != null && CookieService.getLocalStorageValues("workOrderDescription") != undefined ? CookieService.getLocalStorageValues("workOrderDescription") : "",
            ValidatedWo: CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("validatedWO") != "error" && CookieService.getLocalStorageValues("validatedWO") != "blockpopuprepeat" && CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != "null" ? CookieService.getLocalStorageValues("validatedWO") : "",
            favOrderCategory: [], favOrderSubCategory: [], // 03/08/2024 - Zeus 264 - To store the favorite order category and sub category list - Johnprinto D
            //[Zeus 276, 02/21/2024] - Conversion ofsister site dropdown to type ahead dropdown - Kishore
            crossSiteDD: localStorage.getItem("SDIcrosssite") && JSON.parse(localStorage.getItem("SDIcrosssite")).length > 0 ? JSON.parse(localStorage.getItem("SDIcrosssite")) : []
        };
    };
    /*
     * To Get API URL from Controller
     */
    setAPIURL = () => {
        let cachedURL = sessionStorage.getItem("serviceURL");
        if (!cachedURL || cachedURL == "") {
            $.ajax({
                type: "GET",
                url: SDIConstants.localService
            }).then((data) => {
                this.setState({ apiURL: data });
                console.log("ThroughCall", data);
                sessionStorage.setItem("serviceURL", data);
            });

        } else {
            console.log("ThroughCache", sessionStorage.getItem("serviceURL"));
            this.setState({ apiURL: sessionStorage.getItem("serviceURL") });
        }
    };

    returnAPIURL = async () => {
        let response = await $.ajax({
            type: "GET",
            url: SDIConstants.localService
        });
        return response;
    }
    componentWillUnmount() {
        MenuStore.removeListener('load', this.assignUserMenu);
        MenuStore.removeListener('masterData', this.assignMasterData);
        MenuStore.removeListener('envData', this.assignEnvData);
        MenuStore.removeListener('addFav', this.cartInsertSuccess);
        MenuStore.removeListener('addFavOrder', this.cartOrderSuccess);
        MenuStore.removeListener('tokenExpired', this.sessionTimeoutMethod);
        MenuStore.removeListener('crossSite', this.CrossSiteSession);
        //[05 / 09 / 2023 - ZEUS - 99 - Change the navigation URL of Help]
        MenuStore.removeListener('PassThroughAuthe', this.PassThroughAutheResponse);
        MenuStore.removeListener('WOValidate', this.woValidateResponse);
        ShoppingCartStore.removeListener('CancelOrder', this.COStatus);
        this._isMounted = false;
    };
    /**
    * Updates the state of the autocomplete data with the remote data obtained via AJAX.
    * 
    * @param {String} searchText content of the input that will filter the autocomplete data.
    * @return {Nothing} The state is updated but no value is returned
    */
    retrieveDataAsynchronously = async (searchText) => {
        let searchBO = {
            keywordStartsWith: searchText,
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
            Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
            Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
            Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
            Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
            Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
            Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
            Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
            Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
            Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
            Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
            Session_ZEUSNOCATALOGSITE: CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE"),
            Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
            Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
            Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite")
        }

        let responseData = await MenuAction.getSearchSuggestions(searchBO);
        let autoCompleteDataRes = [];
        let suggestionsStr = []
        if (responseData != "") {
            responseData = JSON.parse(responseData);
            suggestionsStr = responseData.filter((item) => item != null);

            suggestionsStr.forEach((sugg, index) => {
                autoCompleteDataRes.push({ label: sugg, value: sugg });
            });
            this.setState({ autocompleteData: [] });
        }
    }
    /**
     * Callback triggered when the user types in the autocomplete field
     * 
     * @param {Event} e JavaScript Event
     * @return {Event} Event of JavaScript can be used as usual.
     */
    onChange(e) {
        this.setState({
            autoCompleteValue: e.target.value
        });

        /**
         * Handle the remote request with the current text !
         */
        this.retrieveDataAsynchronously(e.target.value);

    }
    /**
   * Callback triggered when the autocomplete input changes.
   * 
   * @param {Object} val Value returned by the getItemValue function.
   * @return {Nothing} No value is returned
   */
    onSelect(val) {
        this.setState({
            autoCompleteValue: val
        });

    }
    /**
 * Define the markup of every rendered item of the autocomplete.
 * 
 * @param {Object} item Single object from the data that can be shown inside the autocomplete
 * @param {Boolean} isHighlighted declares wheter the item has been highlighted or not.
 * @return {Markup} Component
 */
    renderItem(item, isHighlighted) {
        return (
            <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );

    }
    /**
   * Define which property of the autocomplete source will be show to the user.
   * 
   * @param {Object} item Single object from the data that can be shown inside the autocomplete
   * @return {String} val
   */
    getItemValue(item) {
        // You can obviously only return the Label or the component you need to show
        // In this case we are going to show the value and the label that shows in the input
        // something like "1 - Microsoft"
        return `${item.value}`;
    }
    /**
   * MN_PC_53
   * This listener method gets the response from Store 
   * after adding Favorite order line item into cart
   */
    cartOrderSuccess = () => {
        //[09/08/2023] PS_SR_Bug_4066_APP_01 - 4066 - Bug fix - Johnprinto 
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let addFavOrderdataRes = JSON.parse(MenuStore.cartOrderSuccess);
        if (addFavOrderdataRes) {
            if (addFavOrderdataRes.Table1.length > 0) {
                addFavOrderdataRes = addFavOrderdataRes.Table1[0];
                if (addFavOrderdataRes.is_Success == "True") {
                    if (currentPath.toLowerCase() == "/shoppingcart") {
                        window.location.reload();
                    } else {
                        this.props.history.push({
                            pathname: '/shoppingcart',
                        });
                    }
                } else {
                    console.log("Problem in adding items to cart");
                    CookieService.updateLocalStorageValues("Session_CARTFAIL", addFavOrderdataRes.Session_CARTFAIL);
                }
            }
        }
    }
    /**
    * MN_PC_53
    * This listener method gets the response from Store 
    * after adding Favorite item into cart
    */
    cartInsertSuccess = () => {
        const { t } = this.props;
        //[09/08/2023] PS_SR_Bug_4066_APP_02 - 4066 - Bug fix - Johnprinto 
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let addFavdataRes = JSON.parse(MenuStore.cartSuccess);
        if (addFavdataRes.length > 0) {
            addFavdataRes = addFavdataRes[0];
            CookieService.updateLocalStorageValues("Session_gotNSTK", addFavdataRes.Session_gotNSTK);
            //[6/8/2022]PS_FI_09 - Added isAddedToCart flag to check whether item added to cart or not if true then move to  cart else show alert - Poornima S
            if (addFavdataRes.isAddedToCart == "True") {
                if (currentPath.toLowerCase() == "/shoppingcart") {
                    window.location.reload();
                } else {
                    this.props.history.push({
                        pathname: '/shoppingcart'
                    });
                }
            } else {
                //alert(t("Invalid or the itemid already exists"));
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Invalid item ID")
                })
                console.log("Problem in adding items to cart");
                CookieService.updateLocalStorageValues("Session_CARTFAIL", addFavdataRes.Session_CARTFAIL);
            }
        }
    }

    //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
    WoValidation = () => {

        if (CookieService.getLocalStorageValues("Session_ValidateWorkOrder") == "Y") {
            let EMP_EMAIL = CookieService.getLocalStorageValues("Session_EMP_EMAIL");
            if (EMP_EMAIL == undefined || EMP_EMAIL == null || EMP_EMAIL.trim() == "") {
                EMP_EMAIL = CookieService.getLocalStorageValues("Session_EMAIL");
            }
            let sunetID = EMP_EMAIL.split("@");
            let workOrderBO = {
                WorkOrder: workOrder,
                BusinessUnit: this.state.Login_UserBU,
                sunetID: sunetID[0]
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            MenuAction.workOrderValidation(workOrderBO);
        }
        else {
            CookieService.updateLocalStorageValues("validatedWO", workOrder);
            CookieService.updateLocalStorageValues("stanfordAssetId", "");
        }
    }

    //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
    woValidateResponse = () => {
        debugger
        const { t } = this.props;
        let woValidationData = MenuStore.woValidationData;
        if (CookieService.getLocalStorageValues("OrderStatusURL") != "Y") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        if (woValidationData != undefined && woValidationData != null && woValidationData != "" && woValidationData != "null") {
            if (woValidationData != "error occured in server" && woValidationData != "error occured" && woValidationData != "authorization required"
                && woValidationData != "internal server error" && woValidationData != "invalid" && woValidationData != "valid") {
                let responeData = JSON.parse(MenuStore.woValidationData);
                if (responeData[0].WOVALIDATE != null && responeData[0].WOVALIDATE != "") {
                    if (responeData[0].WOVALIDATE == "valid") {
                        if (CookieService.getLocalStorageValues("validWO") != undefined && CookieService.getLocalStorageValues("validWO") != null && CookieService.getLocalStorageValues("validWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                            if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                                this.CancelOrder();
                            }
                        }
                        CookieService.updateLocalStorageValues("validatedWO", workOrder);
                        CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                        CookieService.updateLocalStorageValues("stanfordAssetId", "");
                        CookieService.updateLocalStorageValues("workOrderDisable", true);
                        CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].WONAME);
                        CookieService.updateLocalStorageValues("workorderLocation", responeData[0].LOCATION);
                        if (this.state.Login_UserBU.toUpperCase() == "I0631") {
                            CookieService.updateLocalStorageValues("validWO", workOrder);
                            CookieService.updateLocalStorageValues("WOName", responeData[0].WONAME);
                            let rmvndr = responeData.map(function (obj) {
                                return obj.REMOVE_VNDR_ID;
                            });
                            CookieService.updateLocalStorageValues("RemoveVNDR", rmvndr);
                        }
                        else {
                            CookieService.updateLocalStorageValues("validWO", "");
                            CookieService.updateLocalStorageValues("WOName", "");
                        }
                        this.setState({
                            disableWo: true,
                            ValidatedWo: workOrder,
                            workOrderDescription: responeData[0].WONAME
                        });
                    }

                }
                else {
                    if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != null && CookieService.getLocalStorageValues("validatedWO") != "" && CookieService.getLocalStorageValues("CartClearanceWO") != undefined && CookieService.getLocalStorageValues("CartClearanceWO") != null && CookieService.getLocalStorageValues("CartClearanceWO") != "") {
                        if (CookieService.getLocalStorageValues("CartClearanceWO") != workOrder) {
                            this.CancelOrder();
                        }
                    }

                    CookieService.updateLocalStorageValues("validatedWO", workOrder);
                    CookieService.updateLocalStorageValues("CartClearanceWO", workOrder);
                    CookieService.updateLocalStorageValues("stanfordAssetId", responeData[0].assetNumber);
                    CookieService.updateLocalStorageValues("workOrderDescription", responeData[0].workOrderOpDesc);
                    CookieService.updateLocalStorageValues("workOrderDisable", true);
                    this.setState({
                        disableWo: true,
                        ValidatedWo: workOrder,
                        workOrderDescription: responeData[0].workOrderOpDesc
                    })

                }
            }

            else if (woValidationData == "invalid") {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("workorderLocation", "");
                this.setState({
                    ValidatedWo: "",
                    disableWo: false,
                    workOrderDescription: ""
                })
                if (CookieService.getLocalStorageValues("OrderStatusURL") != "Y") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Invalid work order")
                    })
                }
            }
            else {
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
                CookieService.updateLocalStorageValues("stanfordAssetId", "");
                CookieService.updateLocalStorageValues("workOrderDescription", "");
                CookieService.updateLocalStorageValues("validWO", "clearWO");
                CookieService.updateLocalStorageValues("WOName", "");
                CookieService.updateLocalStorageValues("workOrderDisable", false);
                CookieService.updateLocalStorageValues("workorderLocation", "");
                this.setState({
                    ValidatedWo: "",
                    disableWo: false,
                    workOrderDescription: ""
                })
                if (CookieService.getLocalStorageValues("OrderStatusURL") != "Y") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Error occured in work order validation")
                    })
                }
            }

        }
    }

    CancelOrder = () => {
        debugger
        let cancelorderBo = {
            Session_UserID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID")
        }
        ShoppingCartAction.CancelOrderItem(cancelorderBo);
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
    }
    COStatus = () => {
        const { t } = this.props;
        if (CookieService.getLocalStorageValues("Session_UserID").toUpperCase() != "I0631") {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        let CancelResponse = JSON.parse(ShoppingCartStore.CancelOrderMsge);
        if (CancelResponse == null || CancelResponse == "" || CancelResponse == " ") {
            this.refreshMenu();
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t("Issue in clearing the cart")
            });
        }
    }
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: false,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : ""
        };
        MenuAction.getMasterData(masterBO);
    };
    /**
    * Predefined React method, will be called once the HTML elements are renderd
    * on pageload
    */
    componentDidMount() {
        debugger;
        //this.bindcomponentdidmount();
        //[09/08/2023 - PS_ZE_134_APP_02 - Johnprinto D]
        this.changeOrderCount(true);
        //[09/08/2023 - PS_ZE_134_APP_03 - Johnprinto D]
        this.changeRequestCount(true);
        let isTokenPresent = CookieService.getCookieValue(SDIConstants.access_token);
        let currentPagePath = this.props.location.pathname;
        console.log("currentPagePath", currentPagePath)

        //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
        let queryParams = new URLSearchParams(window.location.search)
        let workorderNumber = queryParams.get('workorder');

        workOrder = workorderNumber != undefined && workorderNumber != null ? workorderNumber.toUpperCase() : CookieService.getLocalStorageValues("URLWONumber");
        if (workOrder != "" && workOrder != null && workOrder != undefined && workOrder != CookieService.getLocalStorageValues("validatedWO")) {
            CookieService.updateLocalStorageValues("URLWONumber", workorderNumber);
            this.WoValidation();
        }

        let QryOrderStatus = CookieService.getLocalStorageValues("currentPath");
        if (QryOrderStatus == "/orderstatus2") {
            this.props.history.push({
                pathname: '/',
            });
            return false;
        }

        if (currentPagePath == "/PIPost" || currentPagePath == "/Punchinend") {
            $('#SearchDiv').addClass('d-none')
            $('#hamburgermenu').addClass('d-none')
            $('#RightDiv').addClass('d-none')
        }
        if (currentPagePath != "/PIPost" && currentPagePath != "/Punchinend") {
            if (isTokenPresent == "") {
                localStorage.clear();
                this.props.history.push({
                    pathname: '/',
                });
                return false;
            }
            $('#FavItemLi').removeClass('d-none')
            $('#SearchDiv').removeClass('d-none')
            $('#hamburgermenu').removeClass('d-none')
            $('#RightDiv').removeClass('d-none')
            this.setAPIURL();
            this._isMounted = true;
            //if (currentPagePath == "/shoppingcart") {
            //    const queryParams = new URLSearchParams(window.location.search)
            //    const CartpaymentEnable = queryParams.get('CartPayment')
            //    if (CartpaymentEnable == "yes") {
            //        $('#FavItemLi').addClass('d-none')
            //        $('#FavOrderLi').addClass('d-none')
            //    }

            //}
            currentPath = this.props.location.pathname;
            currentPath = currentPath.indexOf("?") >= 0 ? currentPath.split("?")[0] : currentPath;
            if (!localStorage.getItem("SDIMenu")) {
                let menuBO = {
                    Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                    Session_MenuUpdated: "False",
                    Session_SDIMenu: "",
                    Session_BARCODE: CookieService.getLocalStorageValues("Session_BARCODE") ? CookieService.getLocalStorageValues("Session_BARCODE") : "",
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
                    Session_DEFAULTPAGE: CookieService.getLocalStorageValues("Session_DEFAULTPAGE"),
                    Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
                    Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
                    Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
                    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    Session_AGENTUSERNAME: CookieService.getLocalStorageValues("Session_AGENTUSERNAME") ? CookieService.getLocalStorageValues("Session_AGENTUSERNAME") : "",
                    Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME")
                }
                MenuAction.getMenuItems(menuBO);
                console.log("API");
                isFirstTimeLoaded = true;
            } else {
                console.log("Cache");
                let MenuData = [];
                let SuggData = [];
                let CatWithChild = [];
                let ParentWithChild = [];
                let WithoutParent = [];
                let megaMenuList = [];
                let PrivlegeList;
                let menuResponse = localStorage.getItem("SDIMenu");

                let menuCategories = localStorage.getItem("SDICategories");
                menuResponse = JSON.parse(menuResponse);
                menuCategories = JSON.parse(menuCategories);
                let LoginBU = CookieService.getLocalStorageValues("Session_BUSUNIT")
                //******NEW(12/24/2020)********************
                if (CookieService.getLocalStorageValues("Session_ISACrossSiteAcc") == "Y") {
                    this.setState({ selectedCrossSite: { label: CookieService.getLocalStorageValues("Session_BUDESCR"), value: CookieService.getLocalStorageValues("Session_BUSUNIT") } });
                }
                //**********END****************************
                if (menuResponse) {
                    MenuData = menuResponse;
                    PrivlegeList = MenuData;
                    MenuData = MenuData.filter((x) => x.ISA_IDENTIFIER != 1 && x.ISA_IDENTIFIER != 156 && x.ISA_IDENTIFIER != 162 && x.ISA_IDENTIFIER != 296 && x.ISA_IDENTIFIER != 298 && x.ISA_IDENTIFIER != 319 && x.ISA_IDENTIFIER != 320 && x.ISA_IDENTIFIER != 321);
                    CatWithChild = MenuData.filter((x) => x.ISA_CATEGORY_ID != null && x.ISA_CATEGORY_ID != "");
                    megaMenuList = CatWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_CATEGORY_ID === v.ISA_CATEGORY_ID)) === i);
                    megaMenuList.forEach((catVal, catInd) => {
                        ParentWithChild = CatWithChild.filter((x) => x.ISA_CATEGORY_ID == catVal.ISA_CATEGORY_ID && x.ISA_PARENT_IDENT != null && x.ISA_PARENT_IDENT != "");
                        WithoutParent = CatWithChild.filter((x) => x.ISA_CATEGORY_ID == catVal.ISA_CATEGORY_ID && (x.ISA_PARENT_IDENT == null || x.ISA_PARENT_IDENT == ""));
                        CatWithChild = CatWithChild.sort((c1, c2) => (c1.ISA_CATEGORY_ID > c2.ISA_CATEGORY_ID) ? 1 : (c1.ISA_CATEGORY_ID < c2.ISA_CATEGORY_ID) ? -1 : 0);
                        if (ParentWithChild.length > 0) {
                            ParentWithChild.forEach((parVal, parInd) => {
                                let parObj = {
                                    value: parVal.ISA_PARENT_NAME,
                                    label: parVal.ISA_PARENT_NAME
                                }
                                let ParPresent = SuggData.some((x) => x.label == parVal.ISA_PARENT_NAME);
                                if (SuggData.length == 0 || ParPresent == false) {
                                    SuggData.push(parObj);
                                }
                                let childObj = {
                                    value: parVal.ISA_IDENTIFIER_NAME,
                                    label: parVal.ISA_IDENTIFIER_NAME
                                }
                                let ChildPresent = SuggData.some((x) => x.label == parVal.ISA_IDENTIFIER_NAME);
                                if (SuggData.length == 0 || ChildPresent == false) {
                                    SuggData.push(childObj);
                                }
                            });
                        }
                        if (WithoutParent.length > 0) {
                            WithoutParent.forEach((parVal, parInd) => {
                                let parObj = {
                                    value: parVal.ISA_IDENTIFIER_NAME,
                                    label: parVal.ISA_IDENTIFIER_NAME
                                }
                                let ParPresent = SuggData.some((x) => x.label == parVal.ISA_PARENT_NAME);
                                if (SuggData.length == 0 || ParPresent == false) {
                                    SuggData.push(parObj);
                                }
                            });
                        }
                    });
                    let DirectMenuItems = MenuData.filter((x) => x.ISA_CATEGORY_ID == null || x.ISA_CATEGORY_ID == "");
                    DirectMenuItems.forEach((dirVal, dirInd) => {
                        megaMenuList.push(dirVal);
                    })
                }
                this.menuAssigment(PrivlegeList);
                //Zeus 206 - 
                //this.megaMenuAssigment(menuResponse, menuCategories);

                this.setState({ UserMenuData: MenuData, ActualMenuSugg: SuggData, megaMenuList: megaMenuList, PrivList: PrivlegeList });
                isFirstTimeLoaded = false;
            }

            if (!localStorage.getItem("SDIEnvironmentData")) {
                let environmentBO = {
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                    Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
                    AbsoluteUri: window.location.hostname,
                    Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                    Session_TESTMODE: CookieService.getLocalStorageValues("Session_TESTMODE"),
                    Session_ClientLogoImagePath: CookieService.getLocalStorageValues("Session_ClientLogoImagePath"),
                    Session_ISACrossSiteAcc: CookieService.getLocalStorageValues("Session_ISACrossSiteAcc"),
                    Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),//[Zeus 208, 11/22/2023] - Fetch multisite dropdown values for PunchIn - Kishore
                    Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN"),
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID")
                }
                MenuAction.getEnvironmentDetails(environmentBO);
            } else {
                this.assignEnvData();
            }
        }
        MenuStore.on('load', this.assignUserMenu);
        MenuStore.on('masterData', this.assignMasterData);
        MenuStore.on('envData', this.assignEnvData);
        MenuStore.on('addFav', this.cartInsertSuccess);
        MenuStore.on('addFavOrder', this.cartOrderSuccess);
        MenuStore.on('tokenExpired', this.sessionTimeoutMethod);
        MenuStore.on('crossSite', this.CrossSiteSession);
        //[05 / 09 / 2023 - ZEUS - 99 - Change the navigation URL of Help]
        MenuStore.on('PassThroughAuthe', this.PassThroughAutheResponse);
        //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
        MenuStore.on('WOValidate', this.woValidateResponse);
        ShoppingCartStore.on('CancelOrder', this.COStatus);
        $('#hamburgermenu').show();
        $('#hamburgermenu').click(function () {
            $('#non-catalog').hide();
            //[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]
            $('#hamburger-canvas').show();
            $('#hamburger-menu-container-background').addClass('hmenu-opaque');
            $('#hamburger-menu-container').addClass('showvisible');
        });
        $('#hamgurgerhide').click(function () {

            $('#hamburger-menu-container-background').removeClass('hmenu-opaque');
            $('#hamburger-menu-container').removeClass('showvisible');

        });
        $('#non-catalog').hide();
        //[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]
        $('#hamburger-canvas').show();
        let currentPageProps = availableMenu.filter((page) => page.pageURL.toLowerCase() == currentPath.toLowerCase());
        document.title = currentPageProps.length > 0 ? currentPageProps[0].pageTitle : "ZEUS";
        if (currentPageProps.length > 0) {
            let breadCrumpDetails = BreadCrumpData[currentPageProps[0].pageName];
            //[6/20/2024, Zeus - 297 - Changing the breadcrum menu dispaly for RTI page - Johnprinto D]
            if (currentPageProps[0].pageName == "Real time inventory") {
                breadCrumpDetails[1].pageName = CookieService.getLocalStorageValues("RTI_BackScreen");
                breadCrumpDetails[1].pageURL = CookieService.getLocalStorageValues("RTI_BackURL");
            }
            if (breadCrumpDetails) {
                if (this._isMounted) {
                    this.setState({ breadCrumpDetails: breadCrumpDetails });
                }

            }
        }
    };
    bindcomponentdidmount() {
        let isTokenPresent = CookieService.getCookieValue(SDIConstants.access_token);
        let currentPagePath = this.props.location.pathname;
        console.log("currentPagePath", currentPagePath)

        if (currentPagePath == "/PIPost" || currentPagePath == "/Punchinend") {
            $('#SearchDiv').addClass('d-none')
            $('#hamburgermenu').addClass('d-none')
            $('#RightDiv').addClass('d-none')
        }
        if (currentPagePath != "/PIPost" && currentPagePath != "/Punchinend") {
            if (isTokenPresent == "") {
                localStorage.clear();
                this.props.history.push({
                    pathname: '/',
                });
                return false;
            }
            $('#FavItemLi').removeClass('d-none')
            $('#SearchDiv').removeClass('d-none')
            $('#hamburgermenu').removeClass('d-none')
            $('#RightDiv').removeClass('d-none')
            this.setAPIURL();
            this._isMounted = true;
            //if (currentPagePath == "/shoppingcart") {
            //    const queryParams = new URLSearchParams(window.location.search)
            //    const CartpaymentEnable = queryParams.get('CartPayment')
            //    if (CartpaymentEnable == "yes") {
            //        $('#FavItemLi').addClass('d-none')
            //        $('#FavOrderLi').addClass('d-none')
            //    }

            //}
            currentPath = this.props.location.pathname;
            currentPath = currentPath.indexOf("?") >= 0 ? currentPath.split("?")[0] : currentPath;
            if (!localStorage.getItem("SDIMenu")) {
                let menuBO = {
                    Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                    Session_MenuUpdated: "False",
                    Session_SDIMenu: "",
                    Session_BARCODE: CookieService.getLocalStorageValues("Session_BARCODE") ? CookieService.getLocalStorageValues("Session_BARCODE") : "",
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
                    Session_DEFAULTPAGE: CookieService.getLocalStorageValues("Session_DEFAULTPAGE"),
                    Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
                    Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
                    Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
                    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    Session_AGENTUSERNAME: CookieService.getLocalStorageValues("Session_AGENTUSERNAME") ? CookieService.getLocalStorageValues("Session_AGENTUSERNAME") : "",
                    Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME")
                }
                MenuAction.getMenuItems(menuBO);
                console.log("API");
                isFirstTimeLoaded = true;
            } else {
                console.log("Cache");
                let menuResponse = localStorage.getItem("SDIMenu");

                let menuCategories = localStorage.getItem("SDICategories");
                menuResponse = JSON.parse(menuResponse);
                menuCategories = JSON.parse(menuCategories);
                let LoginBU = CookieService.getLocalStorageValues("Session_BUSUNIT")
                //******NEW(12/24/2020)********************
                if (CookieService.getLocalStorageValues("Session_ISACrossSiteAcc") == "Y") {
                    this.setState({ selectedCrossSite: CookieService.getLocalStorageValues("Session_BUSUNIT") });
                }
                //**********END****************************
                if (menuResponse) {
                    if (menuResponse.length > 0) {
                        let punchoutMenuIndex = menuResponse.findIndex((menuItem) => menuItem.PROGRAMNAME.toLowerCase() == 'punchout');
                        if (punchoutMenuIndex >= 0 && LoginBU == "I0W01") {
                            menuResponse[punchoutMenuIndex].PROGRAMNAME = 'Pickup From Local Supplier';
                        }
                        //'New Site Change 10/11/2020

                        else if (punchoutMenuIndex >= 0 && LoginBU != "I0W01") {
                            menuResponse[punchoutMenuIndex].PROGRAMNAME = 'PunchOut';
                        }
                    }
                }

                this.menuAssigment(menuResponse);
                this.megaMenuAssigment(menuResponse, menuCategories);
                isFirstTimeLoaded = false;
            }

            if (!localStorage.getItem("SDIEnvironmentData")) {
                let environmentBO = {
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                    Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
                    AbsoluteUri: window.location.hostname,
                    Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                    Session_TESTMODE: CookieService.getLocalStorageValues("Session_TESTMODE"),
                    Session_ClientLogoImagePath: CookieService.getLocalStorageValues("Session_ClientLogoImagePath"),
                    Session_ISACrossSiteAcc: CookieService.getLocalStorageValues("Session_ISACrossSiteAcc")
                }
                MenuAction.getEnvironmentDetails(environmentBO);
            } else {
                this.assignEnvData();
            }
        }

        //[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]
        $('#hamburgermenu').show();
        $('#hamburgermenu').click(function () {
            $('#non-catalog').hide();
            $('#hamburger-canvas').show();
            $('#hamburger-menu-container-background').addClass('hmenu-opaque');
            $('#hamburger-menu-container').addClass('showvisible');
        });
        $('#hamgurgerhide').click(function () {

            $('#hamburger-menu-container-background').removeClass('hmenu-opaque');
            $('#hamburger-menu-container').removeClass('showvisible');

        });
        $('#non-catalog').hide();
        //[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]
        $('#hamburger-canvas').show();
        let currentPageProps = availableMenu.filter((page) => page.pageURL.toLowerCase() == currentPath.toLowerCase());
        document.title = currentPageProps.length > 0 ? currentPageProps[0].pageTitle : "ZEUS";
        if (currentPageProps.length > 0) {
            let breadCrumpDetails = BreadCrumpData[currentPageProps[0].pageName];
            if (breadCrumpDetails) {
                if (this._isMounted) {
                    this.setState({ breadCrumpDetails: breadCrumpDetails });
                }

            }
        }



    }
    /**
    * MN_PC_54
    * This method returns the Breadcrump of page
    * from the BreadCrumpStore
    */
    bindBreadCrump = () => {
        //[11/26/21, CAS - 31, Dhevanesam R]
        return this.state.breadCrumpDetails.map((breadCrump, index) => {
            let activeClass = ""
            let breadCrumpContent = (<a href="#" onClick={(event) => this.headerRedirect(event, breadCrump.pageURL)}><Trans>{breadCrump.pageName}</Trans></a>);
            if (index == this.state.breadCrumpDetails.length - 1) {
                activeClass = "active"
                breadCrumpContent = breadCrump.pageName;
            }

            return (<li key={index} className={`breadcrumb-item ${activeClass}`} aria-current="page" ><Trans>{breadCrumpContent}</Trans></li>)
        });
    }
    /**
   * MN_PC_20
   * This method will be called on log out button click
   * and clears the cookies and localStorage values
   */
    logout = (event) => {
        event.preventDefault();

        let Punchinuser = CookieService.getLocalStorageValues("Session_PUNCHINID");
        //[08/05/2024 - Update the order status pagination count each time user logout to prevent the cookie value - Shanmugapriya]
        if (CookieService.getCookieValue("RecCount")) {
            const newRecCount = CookieService.getCookieValue("RecCount");
            CookieService.setCookieValues("RecCount", newRecCount, 50 * 365);
        }
        if (Punchinuser == "" || Punchinuser == null || Punchinuser == undefined) {
            CookieService.setCookieValues(SDIConstants.access_token, "", -1);
            //[10/18/21, PC_02, Bug ID - 1736, Dhevanesam R]
            let newLang = CookieService.getLocalStorageValues("newlanguage");
            localStorage.clear();
            CookieService.updateLocalStorageValues("newlanguage", newLang);
            this.props.history.push({
                pathname: '/',
            });
        }
        else {
            this.props.history.push({
                pathname: `\Punchinend`,
            });
        }
    }

    // ZEUS-206 - Menu structure revamp - Hide the hamburger menu when user click outside - Johnprinto
    handleOutsideClick = (event) => {
        $('#non-catalog').hide();
        this.hideHamBurger();
    }
    /**
    * MN_PC_51
    * This listner method will be called for getting environment and log data from store
    * or from localStorage
    */
    assignEnvData = async () => {

        let environmentDataResponse;
        //*******************NEW(12/24/2020)****************
        //let PageLoadResponse = JSON.parse(MenuStore.environmentData);
        //*********END*************************
        let crosssite = [];
        let multiCrossSite = [];
        if (!localStorage.getItem("SDIEnvironmentData")) {
            environmentDataResponse = JSON.parse(MenuStore.environmentData).EnvironmentDetails;
            localStorage.setItem("SDIEnvironmentData", JSON.stringify(environmentDataResponse));
            //*******************NEW(12/24/2020)****************
            crosssite = JSON.parse(MenuStore.environmentData).crosssite;
            //[Zeus 276, 02/21/2024] - Conversion of BU DD into Type ahead DD - Kishore
            if (crosssite) {
                crosssite.map((val) => {
                    let locObj = { label: val.DESCRIPTION, value: val.BUSINESS_UNIT }
                    multiCrossSite.push(locObj);
                })
                localStorage.setItem("SDIcrosssite", JSON.stringify(multiCrossSite));
                this.setState({ crossSiteDD: multiCrossSite })
            }

            //if (PageLoadResponse.crossSite) {
            //    crosssite = PageLoadResponse.crossSite;
            //}
            //*********END*************************
        } else {

            environmentDataResponse = JSON.parse(localStorage.getItem("SDIEnvironmentData"));
        }
        if (environmentDataResponse.length > 0) {
            environmentDataResponse = environmentDataResponse[0];

            console.log("environmentDataResponse", environmentDataResponse);
            CookieService.updateLocalStorageValues("Session_IOLServer", environmentDataResponse.Session_WEBSITEID);
            let logoURL = this.state.logo;
            let { apiURL } = this.state;
            if (!apiURL) {
                apiURL = await this.returnAPIURL();
            };
            if (environmentDataResponse.imgCustomerLogoVisible == "True") {
                logoURL = apiURL + (environmentDataResponse.imgCustomerLogoURL.indexOf("~") >= 0 ? environmentDataResponse.imgCustomerLogoURL.slice(environmentDataResponse.imgCustomerLogoURL.indexOf("~")) : environmentDataResponse.imgCustomerLogoURL)
            }
            else if (environmentDataResponse.imgClientLogoVisible == "True") {
                logoURL = apiURL + (environmentDataResponse.imgClientLogoUrl.indexOf("~/images/ClientImages") >= 0 ? "/Images/" + environmentDataResponse.imgClientLogoUrl.slice(environmentDataResponse.imgClientLogoUrl.indexOf("ClientImages")) : environmentDataResponse.imgClientLogoUrl);
            }
            else if (environmentDataResponse.imgLogoVisible == "True") {
                logoURL = apiURL + (environmentDataResponse.imgLogoURL.indexOf("~") >= 0 ? environmentDataResponse.imgLogoURL.slice(environmentDataResponse.imgLogoURL.indexOf("~")) : environmentDataResponse.imgLogoURL);
            }
            if (this._isMounted) {
                /*this.CrossSiteArr = crosssite*/;
                console.log("crossiteenvdata", crosssite);

                this.setState({
                    logo: logoURL, AnswerlabVisible: environmentDataResponse.AnswerlabVisible == "True", CrossSiteArr: crosssite,
                    lblTestVisible: environmentDataResponse.lblTestVisible == "True", lblTest: environmentDataResponse.lblTest, lblVersion: environmentDataResponse.lblVersion
                });
            }
            //Fix Zeus logo not blinking isus - Johprinto
            localStorage.setItem("z_logo", logoURL)
        }
    };
    /**
     * MN_PC_37/MN_PC_38/MN_PC_39/MN_PC_40/MN_PC_41/MN_PC_42/MN_PC_43/MN_PC_44
     * This listner method will get the data of  Get the Notification count,
     * Cart data, Cart count, Fav Order and Fav Item flags, 
     * Fav Order data, Fav item data, Fav Pages data, 
     * Requestor approval Count and Order Approval Count
     */
    assignMasterData = () => {
        const { t } = this.props;
        let masterDataResponse = JSON.parse(MenuStore.masterData);
        let requestorApprovalCountRes = 0;
        let orderApprovalCountRes = 0;
        let favOrderCategory = [];
        let notificationCountRes = masterDataResponse.NotificationCount.length > 0 ? masterDataResponse.NotificationCount[0].NotificationCount : 0;
        let cartCountRes = masterDataResponse.CartCount.length > 0 ? masterDataResponse.CartCount[0].totalCount : 0;
        if (this.state.hasReqApproval)
            requestorApprovalCountRes = masterDataResponse.RequestorApprovalCount.length > 0 ? masterDataResponse.RequestorApprovalCount[0].TOTAL_COUNT : 0;
        if (this.state.hasOrderApproval)
            orderApprovalCountRes = masterDataResponse.OrderApprovalCount.length > 0 ? masterDataResponse.OrderApprovalCount[0].TOTAL_COUNT : 0;
        console.log("FavPages", masterDataResponse.FavPages);
        let cartOrginalData = [];
        masterDataResponse.CartData = masterDataResponse.CartData.reverse();
        cartOrginalData = cartOrginalData.concat(masterDataResponse.CartData);
        console.log("cartData", cartOrginalData);
        if (this._isMounted) {
            this.setState({
                notificationCount: notificationCountRes,
                cartCount: cartCountRes,
                requestorApprovalCount: requestorApprovalCountRes,
                orderApprovalCount: orderApprovalCountRes,
                favPages: masterDataResponse.FavPages,
                cartItems: masterDataResponse.CartData,
                SiteCurrencyTable: masterDataResponse.siteCurrencyTable
            });
        }
        //*************NEW********************
        console.log("cartItems", this.state.cartItems);
        //**************END***********
        //[09/08/2023 - PS_ZE_134_APP_06 - Johnprinto D]
        localStorage.setItem("requestorApprovalCount", requestorApprovalCountRes);
        this.changeRequestCount(false);
        //[09/08/2023 - PS_ZE_134_APP_07 - Johnprinto D]
        localStorage.setItem("orderApprovalCount", orderApprovalCountRes);
        this.changeOrderCount(false);
        let favOrdersArray = null;
        let favItemArray = null
        let favFlags = masterDataResponse.FavFlags;
        if (favFlags.length > 0) {
            favFlags = favFlags[0];
            //[7/12/2023, PS_ZE_142_APP_21, Dhevanesam R]
            if (this.state.hasFavOrder || favFlags.ORDER_FLAG != 0) {
                //if (favFlags.ORDER_FLAG != 0) {
                favOrdersArray = masterDataResponse.FavOrderdata;
                if (favOrdersArray.length > 0) {
                    favOrdersArray = JSON.parse(favOrdersArray[0].data);
                    console.log("Orderflag");
                    localStorage.setItem("SDIFavOrders", JSON.stringify(favOrdersArray));
                }
                //} else {
                //    console.log("cacheOrderflag");
                //    favOrdersArray = JSON.parse(localStorage.getItem("SDIFavOrders"));
                //}
                if (favOrdersArray) {
                    if (favOrdersArray.LineItemData) {
                        favOrdersArray.LineItemData.forEach((item, index) => {
                            item.checked = false;
                            let filteredItem = [];
                            if (favOrdersArray.LineItemImgData)
                                filteredItem = favOrdersArray.LineItemImgData.filter((x) => x.ORDER_NO == item.ORDER_NO && x.BUSINESS_UNIT_OM == item.BUSINESS_UNIT_OM && x.ISA_INTFC_LN == item.ISA_INTFC_LN);
                            if (filteredItem.length > 0) {
                                item.INV_ITEM_ID = filteredItem[0].ISA_ITEM;
                            }
                        });
                    }
                    // 03/08/2024 - Zeus 264 - Replace the empty string with ORDER_CATEGORY and ORDER_SUBCATEGORY- Johnprinto D
                    favOrdersArray.HeaderData.forEach(value => {
                        if (!value.ORDER_CATEGORY) {
                            value.ORDER_CATEGORY = "UNCLASSIFIED"
                        }
                        if (!value.ORDER_SUBCATEGORY) {
                            value.ORDER_SUBCATEGORY = "UNCLASSIFIED"
                        }
                        value.checked = false
                        value.subChecked = false;
                    })
                    // 03/08/2024 - Zeus 264 - Filter the unique ORDER_CATEGORY - Johnprinto D
                    favOrderCategory = favOrdersArray.HeaderData.filter((v, i, a) => a.findIndex(t => (t.ORDER_CATEGORY === v.ORDER_CATEGORY)) === i)
                    // 03/08/2024 - Zeus 264 - Filter the unique ORDER_SUBCATEGORY - Johnprinto D
                    const filteredObjects = {};
                    favOrdersArray.HeaderData.forEach(obj => {
                        const { ORDER_CATEGORY, ORDER_SUBCATEGORY } = obj;
                        const key = `${ORDER_CATEGORY}-${ORDER_SUBCATEGORY}`;
                        if (!(key in filteredObjects)) {
                            filteredObjects[key] = obj;
                        }
                    });
                    if (this._isMounted) {
                        this.setState({
                            favorders: favOrdersArray.HeaderData,
                            favOrdersCount: favOrdersArray.TotalCount.length > 0 ? favOrdersArray.TotalCount[0].TOTAL_COUNT : 0,
                            favOrderLineItems: favOrdersArray.LineItemData,
                            favOrderLineItemImgs: favOrdersArray.LineItemImgData,
                            favOrderCategory: favOrderCategory,
                            favOrderSubCategory: Object.values(filteredObjects)
                        });
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        favorders: [],
                        favOrdersCount: 0,
                        favOrderLineItems: [],
                        favOrderLineItemImgs: []
                    });
                }
            }
            //[7/12/2023, PS_ZE_142_APP_21, Dhevanesam R]
            if (this.state.hasFavItem || favFlags.ITEM_FLAG != 0) {
                //if (favFlags.ITEM_FLAG != 0) {
                favItemArray = masterDataResponse.FavItemData;
                if (favItemArray.length > 0) {
                    favItemArray = JSON.parse(favItemArray[0].data);

                    localStorage.setItem("SDIFavItems", JSON.stringify(favItemArray));
                }
                //} else {
                //    favItemArray = JSON.parse(localStorage.getItem("SDIFavItems"));
                //};
                if (favItemArray) {
                    if (favItemArray.FavItemData) {
                        favItemArray.FavItemData.forEach((value, index) => {
                            value.checked = false;
                        });
                    } else {
                        favItemArray.FavItemData = [];
                    }
                    if (this._isMounted) {
                        this.setState({
                            favitems: favItemArray.FavItemData,
                            favItemsCount: favItemArray.TotalCount.length > 0 ? favItemArray.TotalCount[0].TOTAL_COUNT : 0
                        });
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        favitems: [],
                        favItemsCount: 0
                    });
                }
            }
        } else {
            if (this.state.hasFavOrder) {
                favOrdersArray = JSON.parse(localStorage.getItem("SDIFavOrders"));
                if (favOrdersArray) {
                    if (favOrdersArray.LineItemData) {
                        favOrdersArray.LineItemData.forEach((item, index) => {
                            item.checked = false;
                            let filteredItem = [];
                            if (favOrdersArray.LineItemImgData)
                                filteredItem = favOrdersArray.LineItemImgData.filter((x) => x.ORDER_NO == item.ORDER_NO && x.BUSINESS_UNIT_OM == item.BUSINESS_UNIT_OM && x.ISA_INTFC_LN == item.ISA_INTFC_LN);
                            if (filteredItem.length > 0) {
                                item.INV_ITEM_ID = filteredItem[0].ISA_ITEM;
                            }
                        });
                    }
                    // 03/08/2024 - Zeus 264 - Replace the empty string with ORDER_CATEGORY and ORDER_SUBCATEGORY- Johnprinto D
                    favOrdersArray.HeaderData.forEach(value => {
                        if (!value.ORDER_CATEGORY) {
                            value.ORDER_CATEGORY = "UNCLASSIFIED"
                        }
                        if (!value.ORDER_SUBCATEGORY) {
                            value.ORDER_SUBCATEGORY = "UNCLASSIFIED"
                        }
                        value.checked = false
                        value.subChecked = false;
                    })
                    // 03/08/2024 - Zeus 264 - Filter the unique ORDER_CATEGORY - Johnprinto D
                    favOrderCategory = favOrdersArray.HeaderData.filter((v, i, a) => a.findIndex(t => (t.ORDER_CATEGORY === v.ORDER_CATEGORY)) === i)
                    // 03/08/2024 - Zeus 264 - Filter the unique ORDER_SUBCATEGORY - Johnprinto D
                    const filteredObjects = {};
                    favOrdersArray.HeaderData.forEach(obj => {
                        const { ORDER_CATEGORY, ORDER_SUBCATEGORY } = obj;
                        const key = `${ORDER_CATEGORY}-${ORDER_SUBCATEGORY}`;
                        if (!(key in filteredObjects)) {
                            filteredObjects[key] = obj;
                        }
                    });
                    if (this._isMounted) {
                        this.setState({
                            favorders: favOrdersArray.HeaderData,
                            favOrdersCount: favOrdersArray.TotalCount.length > 0 ? favOrdersArray.TotalCount[0].TOTAL_COUNT : 0,
                            favOrderLineItems: favOrdersArray.LineItemData,
                            favOrderLineItemImgs: favOrdersArray.LineItemImgData,
                              favOrderCategory: favOrderCategory,
                            favOrderSubCategory: Object.values(filteredObjects)
                        });
                    }
                }

            }
            if (this.state.hasFavItem) {
                favItemArray = JSON.parse(localStorage.getItem("SDIFavItems"));
                if (favItemArray) {
                    if (favItemArray.FavItemData) {
                        favItemArray.FavItemData.forEach((value, index) => {
                            value.checked = false;
                        });
                    } else {
                        favItemArray.FavItemData = [];
                    }
                    if (this._isMounted) {
                        this.setState({
                            favitems: favItemArray.FavItemData,
                            favItemsCount: favItemArray.TotalCount.length > 0 ? favItemArray.TotalCount[0].TOTAL_COUNT : 0
                        });
                    }

                }
            }
        }
        //[PS_FE_GC_02, Zeus 94, Kishore] - Updating the category and sub category values to the cookie
        let favItemCat = []
        let favItemSubCat = []
        console.log("Cookie category and sub category", favItemArray);
        debugger;
        if (favItemArray != null) {
            if (favItemArray.FavItemData != null && favItemArray.FavItemData != undefined) {
                favItemSubCat = favItemArray.FavItemData.map(({ ISA_FAVS_CATEGORY, ISA_FAVS_SUBCATEGORY }) => ({ ISA_FAVS_CATEGORY, ISA_FAVS_SUBCATEGORY }))
                CookieService.updateLocalStorageValues("FavItem_SubCat", favItemSubCat);
                favItemCat = favItemArray.FavItemData.filter((v, i, a) => a.findIndex(t => (t.ISA_FAVS_CATEGORY.toUpperCase() === v.ISA_FAVS_CATEGORY.toUpperCase() && t.ISA_FAVS_CATEGORY != null && t.ISA_FAVS_CATEGORY != " ")) === i)
                CookieService.updateLocalStorageValues("FavItem_Cat", favItemCat);
            }
            else {
                CookieService.updateLocalStorageValues("FavItem_Cat", favItemCat);
            }
        }
        let ShowFavOrder = true
        let currentPagePath = this.props.location.pathname;
        if (currentPagePath == "/shoppingcart") {
            const queryParams = new URLSearchParams(window.location.search)
            const CartpaymentEnable = queryParams.get('CartPayment')
            if (CartpaymentEnable == "yes") {
                ShowFavOrder = false
            }

        }
        this.setState({ ShowFavOrder: ShowFavOrder })
        console.log("favOrdersArray", favOrdersArray);
        console.log("favItemArray", favItemArray);
        if (CookieService.getLocalStorageValues("validatedWO") != undefined && CookieService.getLocalStorageValues("validatedWO") != "null") {
            //[05/05/23, PS_WVB_03, Zeus 86, Sathis. N]-work order validation stanford error handling
            //[15/05/23, PS_EWVB_016, Zeus 112, Sathis. N]- invalid condition check changes
            if (CookieService.getLocalStorageValues("validatedWO").trim() == 'error') {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("Error occured in work order validation")
                })
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");
            }
            if (CookieService.getLocalStorageValues("validatedWO").trim() == "invalid") {
                if (CookieService.getLocalStorageValues("QryOrderStatus") != "Y") {
                    swal({
                        allowOutsideClick: false,
                        closeOnClickOutside: false,
                        text: t("Invalid work order")
                    })
                }
                CookieService.updateLocalStorageValues("validatedWO", "blockpopuprepeat");

            }
        }


    };

    /**
     * Reusable method for finding page level entitlement of the user
     */

    pageLevelEnt = (menu, pagePath) => {

        if (pagePath !== "/") {
            return menu.filter((item) => item.ISA_NAVIGATIONURL.toLowerCase().indexOf(pagePath.toLowerCase()) >= 0).length > 0 ? true : false;
        }
    }
    megaMenuAssigment = (megaMenuResponse, megaMenuCategories) => {
        console.log("megaMenuCategories", megaMenuCategories);
        console.log("megaMenuResponse", megaMenuResponse);
        if (megaMenuCategories) {
            megaMenuCategories = megaMenuCategories.filter(
                function (x) {
                    return this.findIndex((y) => y.CATAGORY_ID == x.CATAGORY_ID) >= 0;
                },
                megaMenuResponse
            );
            this.setState({ megaMenuCategories: megaMenuCategories });
        }
    };
    checkDefaultPages = (currentPagePath) => {
        //[PC_03, Priyanka D, 09/24/21]Added favorder in default pages.
        let defaultPages = ["/itemdetailnew",
            "/approvequote",
            "/shoppingcart",
            "/testpage",
            "/underconstruction",
            "/accessdenied",
            "/usernotification",
            "/homepage",
            "/searchresults",
            "/pipost",
            "/punchinend",
            "/punchinrfqconfirm",
            //[5/8/2023, PS_FO_01, ZEUS-95 - Added favorder in default pages - Johnprinto D]
            "/favorder",
            "/favorites",
            "/profile",
            "/realtimeinv"

        ];
        return defaultPages.indexOf(currentPagePath.toLowerCase()) >= 0 ? true : false;
    }
    /**
     * Reusable method for assigning menu response from API and from localStorage
     */
    menuAssigment = (menuResponse) => {
        let isPageExists = this.pageLevelEnt(menuResponse, currentPath);
        if (isPageExists || this.checkDefaultPages(currentPath)) {
            if (availableMenu.findIndex(x => x.pageURL.toLowerCase() == currentPath.toLowerCase()) < 0) {
                this.props.history.push({
                    pathname: "/"
                });
            } else {

                let hasReqApprovalCheck = this.filterPage(menuResponse, "Requestor Approval");
                let hasOrderApprovalCheck = this.filterPage(menuResponse, "Approve Orders");
                let hasOrderStatusCheck = this.filterPage(menuResponse, "Order Status Report");
                let hasTechVault = this.filterPage(menuResponse, "Tech Vault");
                let hasHelpCheck = this.filterPage(menuResponse, "Help");
                //[Zeus 208, 11/22/2023] - Home page refresh issue while changing Multisite - Kishore
                let hasNonCatalogCheck = this.filterPage(menuResponse, "Non-Catalog Request");
                let hasPunchOutCheck = this.filterPage(menuResponse, "PunchOut");
                if (this._isMounted) {
                    this.setState({
                        hasReqApproval: hasReqApprovalCheck,
                        hasOrderApproval: hasOrderApprovalCheck, hasOrderStatus: hasOrderStatusCheck,
                        hasHelp: hasHelpCheck
                    });
                }
                CookieService.updateLocalStorageValues("hasReqApprovalCheck", hasReqApprovalCheck);
                CookieService.updateLocalStorageValues("hasOrderApprovalCheck", hasOrderApprovalCheck);
                CookieService.updateLocalStorageValues("hasFavItemCheck", true);
                CookieService.updateLocalStorageValues("hasFavOrderCheck", true);
                CookieService.updateLocalStorageValues("hasTechVault", hasTechVault);
                CookieService.updateLocalStorageValues("hasNonCatalogCheck", hasNonCatalogCheck);
                CookieService.updateLocalStorageValues("hasPunchOutCheck", hasPunchOutCheck);

                let masterBO = {
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                    WorkOrderPnchInA: CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") ? CookieService.getLocalStorageValues("Session_WorkOrderPnchInA") : "",
                    isRequestorApproval: hasReqApprovalCheck,
                    isOrderApproval: hasOrderApprovalCheck,
                    isFavItem: true,
                    isFavOrder: true,//hasFavOrderCheck
                    isFirstTime: isFirstTimeLoaded,//isFirstTimeLoaded
                    Menucall: true,
                    //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
                    Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : "",
                    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                    //[04/18/2023, GOA_PS_15, Zeus 79] add Session_USERTYPE to get order approvals count for corp admin logged in -Sathis N
                    Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : ""
                };

                if (this._isMounted) {
                    console.log("masterBO", masterBO);
                    //[10 / 25 / 21, PC_01, CAS-40, Dhevanesam R]
                    // $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    MenuAction.getMasterData(masterBO);
                }
            }
        } else {
            this.props.history.push({
                pathname: "/accessdenied",
            });
        }
    };
    /**
     * MN_PC_33
     * This listner method will get the data of user menu,
     */
    assignUserMenu = () => {
        //ZEUS 206 Menu structure revamp - Johnprinto D
        let MenuData = [];
        let SuggData = [];
        let megaMenuList = [];
        let CatWithChild = [];
        let ParentWithChild = [];
        let WithoutParent = [];
        let PrivlegeList = [];
        let menuResponse = JSON.parse(MenuStore.userMenuData);
        if (menuResponse.UserMenu) {
            if (menuResponse.UserMenu.length > 0) {
                MenuData = menuResponse.UserMenu;
                PrivlegeList = MenuData;
                MenuData = MenuData.filter((x) => x.ISA_IDENTIFIER != 1 && x.ISA_IDENTIFIER != 156 && x.ISA_IDENTIFIER != 162 && x.ISA_IDENTIFIER != 296 && x.ISA_IDENTIFIER != 298 && x.ISA_IDENTIFIER != 319 && x.ISA_IDENTIFIER != 320 && x.ISA_IDENTIFIER != 321);
                CatWithChild = MenuData.filter((x) => x.ISA_CATEGORY_ID != null && x.ISA_CATEGORY_ID != "");
                megaMenuList = CatWithChild.filter((v, i, a) => a.findIndex(t => (t.ISA_CATEGORY_ID === v.ISA_CATEGORY_ID)) === i);
                megaMenuList.forEach((catVal, catInd) => {
                    ParentWithChild = CatWithChild.filter((x) => x.ISA_CATEGORY_ID == catVal.ISA_CATEGORY_ID && x.ISA_PARENT_IDENT != null && x.ISA_PARENT_IDENT != "");
                    WithoutParent = CatWithChild.filter((x) => x.ISA_CATEGORY_ID == catVal.ISA_CATEGORY_ID && (x.ISA_PARENT_IDENT == null || x.ISA_PARENT_IDENT == ""));
                    CatWithChild = CatWithChild.sort((c1, c2) => (c1.ISA_CATEGORY_ID > c2.ISA_CATEGORY_ID) ? 1 : (c1.ISA_CATEGORY_ID < c2.ISA_CATEGORY_ID) ? -1 : 0);
                    if (ParentWithChild.length > 0) {
                        ParentWithChild.forEach((parVal, parInd) => {
                            let parObj = {
                                value: parVal.ISA_PARENT_NAME,
                                label: parVal.ISA_PARENT_NAME
                            }
                            let ParPresent = SuggData.some((x) => x.label == parVal.ISA_PARENT_NAME);
                            if (SuggData.length == 0 || ParPresent == false) {
                                SuggData.push(parObj);
                            }

                            let childObj = {
                                value: parVal.ISA_IDENTIFIER_NAME,
                                label: parVal.ISA_IDENTIFIER_NAME
                            }
                            let childPresent = SuggData.some((x) => x.label == parVal.ISA_IDENTIFIER_NAME);
                            if (SuggData.length == 0 || childPresent == false) {
                                SuggData.push(childObj);
                            }
                        });
                    }
                    if (WithoutParent.length > 0) {
                        WithoutParent.forEach((parVal, parInd) => {
                            let parObj = {
                                value: parVal.ISA_IDENTIFIER_NAME,
                                label: parVal.ISA_IDENTIFIER_NAME
                            }
                            let ParPresent = SuggData.some((x) => x.label == parVal.ISA_PARENT_NAME);
                            if (SuggData.length == 0 || ParPresent == false) {
                                SuggData.push(parObj);
                            }
                        });
                    }
                });
                let DirectMenuItems = MenuData.filter((x) => x.ISA_CATEGORY_ID == null || x.ISA_CATEGORY_ID == "");
                DirectMenuItems.forEach((dirVal, dirInd) => {
                    megaMenuList.push(dirVal);

                })
            }
        }
        this.menuAssigment(PrivlegeList);
        this.setState({ UserMenuData: MenuData, ActualMenuSugg: SuggData, megaMenuList: megaMenuList, PrivList: PrivlegeList });
        localStorage.setItem("SDIMenu", JSON.stringify(menuResponse.UserMenu));
        localStorage.setItem("SDICategories", JSON.stringify(menuResponse.Categories));
        //[Zeus 208, 11/22/2023] - Home page and menu binding refresh issue while changing Multisite - Kishore
        if (this.state.isCrossSiteChange) {
            this.setState({ isCrossSiteChange: false })
            this.props.history.push({
                pathname: "/homepage"
            });
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        //this.menuAssigment(menuResponse.UserMenu);
        //this.megaMenuAssigment(menuResponse.UserMenu, menuResponse.Categories);
    }
    /**
    * Reusable method for checking whether the header link is present in user menu
    */
    filterPage = (menu, pageName) => {
        return menu.filter((item) => item.ISA_IDENTIFIER_NAME.toLowerCase() == pageName.toLowerCase()).length > 0 ? true : false;
    }
    /**
     * Reusable method for formation of Header menu
     */
    filterHeaderMenu = (menu) => {
        return menu.filter((item) => item.ISA_PARENT_IDENT == null && item.ISA_IDENTIFIER_NAME.toLowerCase() != "my account");
    }
    /**
     * Reusable method for formation of child menu
     */
    //[11/19/21, PC_MC_02, Bug - 1844, Dhevanesam R]
    filterChildMenu = (menu, currentMenu) => {

        return menu.filter((item) => item.ISA_PARENT_IDENT == currentMenu.ISA_IDENTIFIER && item.PROGRAMNAME != "RFQ Status" && item.PROGRAMNAME != "PO Status" && item.PROGRAMNAME != "RTV" && item.PROGRAMNAME != "Reprint Delivery Ticket" && item.PROGRAMNAME != "Receiving Report" && item.PROGRAMNAME != "Load Stage Errors" && item.PROGRAMNAME != "Bin-to-Bin Transfer" && item.PROGRAMNAME != "Cycle Count Reconciliation" && item.PROGRAMNAME != "Manual Stock Adjustment" && item.PROGRAMNAME != "Material Storage Locations" && item.PROGRAMNAME != "Stock Issues" && item.PROGRAMNAME != "Item Holds" && item.PROGRAMNAME != "RMA" && item.PROGRAMNAME != "Interunit Transfers");
    }
    /**
     * Reusable method for menu navigation
     */
    menuNavigation = (e, pagePath) => {
        e.preventDefault();
        let routePath = availableMenu.findIndex(x => x.pageURL.toLowerCase() == pagePath.toLowerCase()) >= 0 ? pagePath : "/";
        this.props.history.push({
            pathname: routePath

        });

    };
    /**
     * Reusable method for click of header link navigation
     */
    headerRedirect = (event, pageName) => {
        let queryParamindex = pageName.indexOf("?");
        let pagePath = pageName;
        let queryParams = "";
        if (queryParamindex > 0) {
            pagePath = pageName.substring(0, queryParamindex);
            queryParams = pageName.substring(queryParamindex, pageName.length);
        };

        event.preventDefault();
        this.props.history.push({
            pathname: pagePath,
            search: queryParams
        });
    };
    //**NEW***ADDED****
    headershop = (event, pageName) => {
        let queryParamindex = pageName.indexOf("?");
        let pagePath = pageName;
        let queryParams = "";
        if (queryParamindex > 0) {
            pagePath = pageName.substring(0, queryParamindex);
            queryParams = pageName.substring(queryParamindex, pageName.length);
        };
        event.preventDefault();
        let urlParam = new URLSearchParams(window.location.search);
        let CartpaymentEnable = urlParam.get('CartPayment');
        if (CartpaymentEnable != null && CartpaymentEnable != undefined) {

            this.props.history.push({
                pathname: "/shoppingcart"
            });
            window.location.reload(false);
        }
        else {
            this.props.history.push({
                pathname: pagePath,
                search: queryParams
            });
        }

    };
    //***END****
    /**
    * HTML method for Hide/show of Parent/Child methods in Hamburgermenu
    */
    //[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]
    showChild = (event, parentMenu, arrowClass) => {
        event.preventDefault();
        if (arrowClass != "") {
            $(`#non-catalog`).show();
            $('#hamburger-canvas').hide();
            this.bindChildElements(parentMenu)
        } else {
            this.menuNavigation(parentMenu);
        }
    };
    /**
    * Local method to bind the Fav pages in Hamburger menu
    */
    //[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]
    favPagelinkClick = (event) => {
        event.preventDefault();
        $(`#non-catalog`).show();
        $('#hamburger-canvas').hide();
        /*[PC_MC_01, BUG-1796, Priyanka D]*/
        let menuResponse = localStorage.getItem("SDIMenu");
        menuResponse = JSON.parse(menuResponse);
        let childComponents = this.state.favPages.map((page, pageIndex) => {
            /*[PC_MC_02, BUG-1796, Priyanka D]*/
            let isPageExists = this.pageLevelEnt(menuResponse, page.PAGE_URL)
            /*[PC_MC_03, BUG-1796, Priyanka D]*/
            if (isPageExists || this.checkDefaultPages(page.PAGE_URL)) {
                if (availableMenu.findIndex(x => x.pageURL.toLowerCase() == page.PAGE_URL.toLowerCase()) >= 0) {
                    return (<a href="#" key={pageIndex} className="list-group-item list-group-item-action border-0" onClick={(event) => this.headerRedirect(event, page.PAGE_URL)}><Trans>{page.PAGE_TITLE}</Trans></a>);
                }
            }
        });
        if (this._isMounted) {
            this.setState({ currentHeader: "Favorite Pages", childComponents: childComponents });
        }
    }

    /**
    * Local method to redirect the page in Hamburger child menu
    */
    childMenuClick = (event, childMenu) => {
        event.preventDefault();
        this.menuNavigation(childMenu);

    };
    /**
     * HTML method to Hide/show of Parent/Child methods in Hamburgermenu
     */
    //[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]
    showParent = (event) => {
        {/* [10/10/23, Zeus 206 - Zeus 2.0 Menu structure revamp, Johnprinto ] */ }
        let CurrentParent = this.state.currentHeader;
        if (CurrentParent.toUpperCase() == "FAVORITE PAGES") {
            event.preventDefault();
            $(`#non-catalog`).hide();
            $('#hamburger-canvas').show();
            //[02/11/2023 - Zeus-206_Hamburger menu_ Search box issue - 4310 - SIT - 1 bug fix - Johnprinto]
            this.setState({ SelectedMenuName: "", SuggestionMenu: [] });
        } else {
            let IsParent = this.state.UserMenuData.filter((x) => x.ISA_PARENT_NAME == CurrentParent);
            let IsCat = this.state.UserMenuData.filter((x) => x.ISA_CATEGORY_NAME == CurrentParent);
            if (IsParent.length > 0) {
                $(`#non-catalog`).show();
                this.showNewChild(event, IsParent[0].ISA_CATEGORY_ID, IsParent[0].ISA_CATEGORY_NAME);
                CurrentParent = IsParent[0].ISA_CATEGORY_NAME;
            }
            else if (IsCat.length > 0) {
                event.preventDefault();
                $(`#non-catalog`).hide();
            }
            //[02/11/2023 - Zeus-206_Hamburger menu_ Search box issue - 4310 - SIT - 1 bug fix - Johnprinto]
            this.setState({ currentHeader: CurrentParent, SelectedMenuName: "", SuggestionMenu: [] });
        }
    }
    /**
     * HTML method to Hide/show Hamburgermenu
     */
    hideHamBurger = () => {
        //[02/11/2023 - Zeus-206_Hamburger menu_ Search box issue - 4310 - SIT - 1 bug fix - Johnprinto]
        this.setState({ SelectedMenuName: "", SuggestionMenu: [] });
        $('#hamburger-menu-container-background').removeClass('hmenu-opaque');
        $('#hamburger-menu-container').removeClass('showvisible');
    }
    getItemMenuValue(item) {
        return `${item.value}`;
    }
    rendermenuItem(item, isHighlighted) {
        return (
            <div key={item.label} className="cursor-pointer" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        );

    }
    onChangeItem(e) {
        let val = e.target.value.toUpperCase()
        let len = e.target.value;
        if (e.target.value == "") {
            this.setState({
                SelectedMenuName: e.target.value, SuggestionMenu: []
            });

        }
        else if (len.length <= 40) {
            let result = this.state.ActualMenuSugg.filter(name => name.label.toUpperCase().includes(val))
            this.setState({
                SelectedMenuName: e.target.value, SuggestionMenu: result
            });
        }
    }
    onSelectItem = (event) => {
        this.setState({
            SelectedMenuName: event
        });
        this.MenuSearchClick(event);
    }
    bindHeaderNewElements = () => {
        const { t } = this.props;
        return this.state.megaMenuList.map((header, headerIndex) => {
            return (<>{header.ISA_CATEGORY_ID != null && header.ISA_CATEGORY_ID != "" ? <a href="#" key={headerIndex} className={`list-group-item list-group-item-action border-0 menu-arrow`} id={`${header.ISA_CATEGORY_NAME}id`} onClick={(event) => this.showNewChild(event, header.ISA_CATEGORY_ID, header.ISA_CATEGORY_NAME)} ><Trans>{header.ISA_CATEGORY_NAME}</Trans></a> :
                <a href="#" key={headerIndex} id={`${header.ISA_IDENTIFIER_NAME}id`} className="list-group-item list-group-item-action border-0 " onClick={(e) => this.menuNavigation(e, header.ISA_NAVIGATIONURL)} ><Trans>{header.ISA_IDENTIFIER_NAME}</Trans></a>}
            </>)
        });
    }
    showNewChild = (event, Cat_ID, Cat_Name) => {
        event.preventDefault();
        $(`#non-catalog`).show();
        this.bindChildNewElements(Cat_ID, Cat_Name);
    };
    bindChildNewElements = (Cat_ID, Cat_Name) => {
        let MenuData = this.state.UserMenuData;
        let subParents = MenuData.filter((x) => x.ISA_CATEGORY_ID == Cat_ID);
        let ParentsWithChild = [];
        let childComponents;
        childComponents = subParents.map((parval, parInd) => {
            let IsParent = subParents.filter((x) => x.ISA_PARENT_IDENT == parval.ISA_PARENT_IDENT && x.ISA_PARENT_IDENT != null);
            if (IsParent.length > 0) {
                if (!ParentsWithChild.includes(parval.ISA_PARENT_NAME) || ParentsWithChild.length == 0) {
                    ParentsWithChild.push(parval.ISA_PARENT_NAME);
                    return (<a href="#" key={parInd} className="list-group-item list-group-item-action border-0 menu-arrow" id={`${parval.ISA_PARENT_NAME}id`} onClick={(event) => this.showSubChild(event, parval.ISA_PARENT_IDENT, parval.ISA_PARENT_NAME)} ><Trans>{parval.ISA_PARENT_NAME}</Trans></a>);
                }
            }
            else {
                return (<a href="#" key={parInd} className="list-group-item list-group-item-action border-0 " id={`${parval.ISA_IDENTIFIER_NAME}id`} onClick={(e) => this.menuNavigation(e, parval.ISA_NAVIGATIONURL)} ><Trans>{parval.ISA_IDENTIFIER_NAME}</Trans></a>);
            }
        });
        if (this._isMounted) {
            this.setState({ currentHeader: Cat_Name, childComponents: childComponents });
        }
    }
    showSubChild = (event, Par_ID, Par_Name) => {
        if (event.preventDefault) {
            event.preventDefault();
        }
        let childComponents;
        let childList = this.state.UserMenuData.filter((x) => x.ISA_PARENT_IDENT == Par_ID);
        if (childList.length > 0) {
            childComponents = childList.map((childVal, childInd) => {
                return (<a href="#" key={childInd} className="list-group-item list-group-item-action border-0 " id={`${childVal.ISA_IDENTIFIER_NAME}id`} onClick={(e) => this.menuNavigation(e, childVal.ISA_NAVIGATIONURL)} ><Trans>{childVal.ISA_IDENTIFIER_NAME}</Trans></a>);
            });
        }
        if (this._isMounted) {
            this.setState({ currentHeader: Par_Name, childComponents: childComponents });
        }
    };
    MenuSearchClick = (event) => {
        debugger
        let MenuName = event;
        let MenuData = this.state.UserMenuData;
        let IsParent = MenuData.filter((x) => x.ISA_PARENT_NAME ? x.ISA_PARENT_NAME.toLowerCase() == MenuName.toLowerCase() : x.ISA_PARENT_NAME == MenuName);
        let IsChild = MenuData.filter((x) => x.ISA_IDENTIFIER_NAME ? x.ISA_IDENTIFIER_NAME.toLowerCase() == MenuName.toLowerCase() : x.ISA_IDENTIFIER_NAME == MenuName);
        if (IsParent.length > 0) {
            $(`#non-catalog`).show();
            let catName = IsParent[0].ISA_CATEGORY_NAME;
            let catId = IsParent[0].ISA_CATEGORY_ID;
            this.bindChildNewElements(catId, catName);
        }
        if (IsChild.length > 0) {
            if (IsChild[0].ISA_PARENT_IDENT != null && IsChild[0].ISA_PARENT_IDENT != "") {
                $(`#non-catalog`).show();
                let parName = IsChild[0].ISA_PARENT_NAME;
                let parID = IsChild[0].ISA_PARENT_IDENT;
                this.showSubChild(event, parID, parName);
            }
            else {
                $(`#non-catalog`).show();
                let catName = IsChild[0].ISA_CATEGORY_NAME;
                let catId = IsChild[0].ISA_CATEGORY_ID;
                this.bindChildNewElements(catId, catName);
            }
        }
    }
    ClearMenuSearch = () => {
        this.setState({ SelectedMenuName: "", SuggestionMenu: [] });
    }
    MenuSearchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.MenuSearchClick(this.state.SelectedMenuName);
        };
    }
    /**
     * MN_PC_36
     * This method binds the child elements in Hamburgermenu
     */
    bindChildElements = (parentMenu) => {
        debugger
        let finalUserProfile = [];
        let userProfileItems = [];
        let nonUserProfileItems = [];
        let UserProfOnly = [];
        //[11/26/21, CAS - 31, Dhevanesam R]
        /*[PC_MC_02, Priyanka D]Removal of Under Construction pages*/
        let childMenuItems = this.filterChildMenu(this.state.userMenu, parentMenu);
        if (parentMenu.PROGRAMNAME == "Order Status") {
            childMenuItems = childMenuItems.filter((x) => x.PROGRAMNAME != "RFQ Status" && x.PROGRAMNAME != "PO Status")
        }
        //[PC_MCpP_01, Poornima S]Removed vendor information panels for Admin users and Mexico panels for all users
        //User Profile menu issue - Dhamotharan P - 9/2/2022
        //Bug - 2160 User profile panel is disable mode in Super account. - Dhamotharan P
        if (parentMenu.PROGRAMNAME == "User Profile") {
            if (CookieService.getLocalStorageValues("Session_USERTYPE").trim() == "ADMIN") {

                userProfileItems = childMenuItems.filter((x) => x.PROGRAMNAME == "Add New User" || x.PROGRAMNAME == "Edit User Information" || x.PROGRAMNAME == "Activate/Inactivate Users" || x.PROGRAMNAME == "Add New Vendor" || x.PROGRAMNAME == "Edit Vendor Information" || x.PROGRAMNAME == "Add New Mexico" || x.PROGRAMNAME == "Edit Mexico Information" || x.PROGRAMNAME == "User Profile")
                nonUserProfileItems = childMenuItems.filter((x) => x.PROGRAMNAME != "Add New User" && x.PROGRAMNAME != "Edit User Information" && x.PROGRAMNAME != "Activate/Inactivate Users" && x.PROGRAMNAME != "Add New Vendor" && x.PROGRAMNAME != "Edit Vendor Information" && x.PROGRAMNAME != "Add New Mexico" && x.PROGRAMNAME != "Edit Mexico Information")

                userProfileItems.map((val, index) => {
                    if (index == 0) {
                        val.PROGRAMNAME = "User Profile"
                        finalUserProfile.push(val);
                    }
                });
                nonUserProfileItems.map((val, index) => {
                    //[4/7/2023]Added program name check condition to avoid duplicate user profile child menus - Poornima S
                    if (val.PROGRAMNAME != "User Profile") {
                        finalUserProfile.push(val);
                    }
                });


                childMenuItems = finalUserProfile;
                this.setState({ childMenuItems: finalUserProfile });
                //childMenuItems.forEach((value, index) => {
                //    if (childMenuItems[index].PROGRAMNAME == "Edit User Information") {
                //        childMenuItems[index].PROGRAMNAME = "User Profile";
                //    }
                //});

            }


            else {
                userProfileItems = childMenuItems.filter((x) => x.PROGRAMNAME == "Activate/Inactivate Users" || x.PROGRAMNAME == "Add New User" || x.PROGRAMNAME == "Edit User Information" || x.PROGRAMNAME == "Add New Vendor" || x.PROGRAMNAME == "Edit Vendor Information" || x.PROGRAMNAME == "Add New Mexico" || x.PROGRAMNAME == "Edit Mexico Information" || x.PROGRAMNAME == "User Profile")
                nonUserProfileItems = childMenuItems.filter((x) => x.PROGRAMNAME != "Activate/Inactivate Users" && x.PROGRAMNAME != "Add New User" && x.PROGRAMNAME != "Edit User Information" && x.PROGRAMNAME != "Add New Vendor" && x.PROGRAMNAME != "Edit Vendor Information" && x.PROGRAMNAME != "Add New Mexico" && x.PROGRAMNAME != "Edit Mexico Information")

                userProfileItems.map((val, index) => {
                    if (index == 0) {
                        val.PROGRAMNAME = "User Profile"
                        finalUserProfile.push(val);
                    }
                });
                nonUserProfileItems.map((val, index) => {
                    if (val.PROGRAMNAME != "User Profile") {
                        finalUserProfile.push(val);
                    }
                });


                childMenuItems = finalUserProfile;
                this.setState({ childMenuItems: finalUserProfile });
                //childMenuItems = childMenuItems.filter((x) => x.PROGRAMNAME != "Activate/Inactivate Users" && x.PROGRAMNAME != "Add New User" && x.PROGRAMNAME != "Add New Vendor" && x.PROGRAMNAME != "Edit Vendor Information" && x.PROGRAMNAME != "Add New Mexico" && x.PROGRAMNAME != "Edit Mexico Information")
                //childMenuItems.forEach((value, index) => {
                //    if (childMenuItems[index].PROGRAMNAME == "Edit User Information") {
                //        childMenuItems[index].PROGRAMNAME = "User Profile";
                //    }
                //});
            }
        }
        //[11/19/21, PC_MC_03, Bug - 1844, Dhevanesam R]
        if (parentMenu.PROGRAMNAME == "Receiving") {
            childMenuItems = childMenuItems.filter((x) => x.PROGRAMNAME != "RTV" && x.PROGRAMNAME != "Reprint Delivery Ticket" && x.PROGRAMNAME != "Receiving Report" && x.PROGRAMNAME != "Load Stage Errors")
        }

        if (parentMenu.PROGRAMNAME == "Inventory") {
            childMenuItems = childMenuItems.filter((x) => x.PROGRAMNAME == "Default Putaway Locations")
        }

        let childComponents = childMenuItems.map((childMenu, childindex) => {

            return (<a href="#" key={childindex} className="list-group-item list-group-item-action border-0" onClick={(event) => this.childMenuClick(event, childMenu)}><Trans>{childMenu.PROGRAMNAME}</Trans></a>);
        });
        if (this._isMounted) {
            this.setState({ currentHeader: parentMenu.PROGRAMNAME, childComponents: childComponents });
        }
    }




    /**
     * MN_PC_50
     * This method binds the Favorite pages
     */
    bindFavPages = () => {
        /*[PC_MC_01, BUG-1796, Priyanka D]*/
        const { t } = this.props;
        {/*[12/8/21, PC_MC_01, Bug - 1857, Dhevanesam R]*/ }
        if (localStorage.getItem("SDIMenu") != "" && localStorage.getItem("SDIMenu") != " " && localStorage.getItem("SDIMenu") != null && localStorage.getItem("SDIMenu") != undefined) {
            let menuResponse = localStorage.getItem("SDIMenu");
            menuResponse = JSON.parse(menuResponse);
            return this.state.favPages.map((page, index) => {
                /*[PC_MC_02, BUG-1796, Priyanka D]*/
                let isPageExists = this.pageLevelEnt(menuResponse, page.PAGE_URL)
                /*[PC_MC_03, BUG-1796, Priyanka D]*/
                if (isPageExists || this.checkDefaultPages(page.PAGE_URL)) {
                    if (availableMenu.findIndex(x => x.pageURL.toLowerCase() == page.PAGE_URL.toLowerCase()) >= 0) {
                        return (<React.Fragment key={index}>
                            <a href="#" className="list-group-item list-group-item-action border-0 py-1 pl-0 pr-0" onClick={(event) => this.headerRedirect(event, page.PAGE_URL)}>
                                <span>
                                    <img className="custom-fav-icon" src="/Images/favorite.svg" alt="favorite icon" />
                                </span>
                                {t(page.PAGE_TITLE)}
                            </a>
                        </React.Fragment>)
                    }
                }
            })
        }
    };

    /**
     * MN_PC_31
     * This method calls the action method to add
     * Favorite items in cart
     */
    addFavItemsToCart = () => {
        let itemsSelected = this.state.favitems.filter((fav) => fav.checked == true);
        if (itemsSelected.length > 0) {
            let itemsToAdd = [];
            itemsSelected.forEach((value) => {
                //[6/8/2022] PS_FI_10 - Passing Mfg & MFG part num to API to check whether valid item is added to cart - Poornima S
                itemsToAdd.push({
                    ISA_CP_ITEM_ID: value.ISA_CP_ITEM_ID, Quantity: 1, MFG: value.MANUFACTURER,
                    MFGPartnum: value.CLIENT_MFG_PART, Supplier_ID: value.SUPPLIER_ID
                //[06/29/2023, Zeus-94]- Kishore S - Checking supplier ID while adding to Cart in Menu
                });

            });
            let addFavItemsBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                LineItems: itemsToAdd,
                Session_CplusDB: CookieService.getLocalStorageValues("Session_CplusDB"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_PRODVIEW: CookieService.getLocalStorageValues("Session_PRODVIEW"),
                Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                Session_SDICAT: CookieService.getLocalStorageValues("Session_SDICAT"),
                Session_SEARCH3M: CookieService.getLocalStorageValues("Session_SEARCH3M"),
                Session_SEARCHORONASCO: CookieService.getLocalStorageValues("Session_SEARCHORONASCO"),
                Session_SEARCHCAPP: CookieService.getLocalStorageValues("Session_SEARCHCAPP"),
                Session_SEARCHMSC: CookieService.getLocalStorageValues("Session_SEARCHMSC"),
                Session_SEARCHIMP: CookieService.getLocalStorageValues("Session_SEARCHIMP"),
                Session_SEARCHSTAUFFER: CookieService.getLocalStorageValues("Session_SEARCHSTAUFFER"),
                Session_CplusDbSQL: CookieService.getLocalStorageValues("Session_CplusDbSQL"),
                Session_WEBSITEID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                Session_CARTTAXEXMPTFLAG: CookieService.getLocalStorageValues("Session_CARTTAXEXMPTFLAG"),
                Session_ZEUSNOCATALOGSITE: CookieService.getLocalStorageValues("Session_ZEUSNOCATALOGSITE"),
                Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
                Session_SEARCHGRAINGER: CookieService.getLocalStorageValues("Session_SEARCHGRAINGER"),//[11/12/21, PC_SL_01, CAS - 36], Poornima S //Added Grainger search yes or no to get those items in search
                Session_IPMSite: CookieService.getLocalStorageValues("Session_IPMSite"),
                Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            MenuAction.addFavItemsTocart(addFavItemsBO);
        }

    };
    /**
    * MN_PC_31
    * This method calls the action method to add
    * Favorite order line items in cart
    */
    addFavOrderItemsToCart = (e) => {
        let orderSelected = this.state.favorders.filter((value) => e.target.id == value.ORDER_CATEGORY)
        orderSelected = orderSelected.filter((value) => value.checked == true)
        let itemsSelected = [];
        let itemsHashTable = {};
        this.state.favOrderLineItems.forEach((favOrder) => {
            const orderExists = orderSelected.some(order => order.ORDER_NO == favOrder.ORDER_NO);
            if (orderExists) {
                const key = `${favOrder.INV_ITEM_ID}-${favOrder.MFG_ITM_ID}-${favOrder.VENDOR_ID}-${favOrder.ISA_MFG_FREEFORM}`;
                if (itemsHashTable[key] == undefined) {
                    itemsHashTable[key] = itemsSelected.length;
                    itemsSelected.push(favOrder);
                } else {
                    itemsSelected[itemsHashTable[key]].QTY_REQUESTED = itemsSelected[itemsHashTable[key]].QTY_REQUESTED + favOrder.QTY_REQUESTED
                }
            }
        });
        let itemsToAdd = [];
        if (itemsSelected.length > 0) {
            itemsSelected.forEach((value) => {
                itemsToAdd.push({
                    Order_Number: value.ORDER_NO,
                    Business_unit_om: value.BUSINESS_UNIT_OM,
                    INV_ITEM_ID: value.INV_ITEM_ID,
                    Quantity: value.QTY_REQUESTED,
                    ISA_INTFC_LN: value.ISA_INTFC_LN,
                    MFG_ITM_ID: value.MFG_ITM_ID,
                    ISA_MFG_FREEFORM: value.ISA_MFG_FREEFORM,
                    Descr: value.DESCR254,
                    price: value.ISA_SELL_PRICE
                });

            });
            let addFavOrderItemsBO = {
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                Session_ITEMMODE: CookieService.getLocalStorageValues("Session_ITEMMODE"),
                Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                LineItems: itemsToAdd,
                Session_ID: CookieService.getLocalStorageValues("Session_ID"),
                Session_WONO: CookieService.getLocalStorageValues("validatedWO") ? CookieService.getLocalStorageValues("validatedWO") : " "
            };
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            MenuAction.addFavOrderItemsTocart(addFavOrderItemsBO);
        }

    };
    /**
    * This method pushes the selected favorite items
    * for adding to cart
    */
    selectFavItem = (index1) => {

        let { favitems } = this.state;
        if (index1 != "ALL") {
            favitems[index1].checked = favitems[index1].checked == true ? false : true;
        }
        else if (index1 == "ALL") {
            favitems.map((item, index) => {
                favitems[index].checked = true
            })
        }
        console.log("favitems", favitems)
        let Addfav = false
        if (favitems != null && favitems != []) {
            favitems.map((item, index) => {
                if (item.checked == true && item.PRICE > 0) {
                    Addfav = true
                }
            })
        }
        this.setState({ favitems: favitems, FavitemSelected: Addfav });
    }
    /**
    * This method pushes the selected favorite order line items
    * for adding to cart
    */
    selectFavOrderItem = (lineID, OrderNO) => {
        let { favOrderLineItems } = this.state;
        if (lineID != null && lineID != undefined && lineID != "") {
            let index = favOrderLineItems.findIndex(x => x.ORDER_NO == OrderNO && x.ISA_INTFC_LN == lineID);
            favOrderLineItems[index].checked = favOrderLineItems[index].checked == true ? false : favOrderLineItems[index].checked == false ? true : true;
        }
        else {
            this.state.favOrderLineItems.map((item, index) => {
                favOrderLineItems[index].checked = favOrderLineItems[index].ORDER_NO == OrderNO ? true : false
            })
        }
        //console.log("favOrderLineItems", favOrderLineItems)
        this.setState(favOrderLineItems);
    }
    //*************NEW(12/24/2020)*********************
    //****************NEW(12/24/2020)*********
    CrossSiteBuBind = () => {

        //if (this.state.CrossSiteArr)
        let crosslocalsite;
        crosslocalsite = localStorage.getItem("SDIcrosssite")
        //console.log("crosslocalsite", crosslocalsite);
        let movies2 = [];
        movies2 = JSON.parse(crosslocalsite)
        //console.log("movies2", movies2);
        //console.log("this.state.CrossSiteArr", this.state.CrossSiteArr);
        if (movies2)
            return movies2.map((value, ID) => {
                return (
                    <option key={value.BUSINESS_UNIT} value={value.BUSINESS_UNIT}>{value.DESCRIPTION}</option>
                )

            });
    }
    ChangeCrossSite = (event) => {

        let selectedbu = "";
        selectedbu = event.value;
        console.log("selectedbu", selectedbu);
        let CrossSiteBO = {
            Session_ORGINSITE: CookieService.getLocalStorageValues("Session_ORGINSITE"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN"),
            Session_PUNCHINID: CookieService.getLocalStorageValues("Session_PUNCHINID"),
            selectedBU: selectedbu
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        MenuAction.CrossSiteChange(CrossSiteBO);
        //[Zeus 208, 11/22/2023] - Home page refresh and menu binding issue while changing Multisite - Kishore
        this.setState({ isCrossSiteChange: true });
        //this.props.history.push({
        //    pathname: "/homepage"
        ////    //search: queryParams
        //});
    }
    CrossSiteSession = () => {
        //[11/30/21, PC_MT_01, CAS - 31], Poornima S//Added translation keywords for alerts
        const { t } = this.props;
        //[10 / 25 / 21, PC_02, CAS-40, Dhevanesam R]
        let response = JSON.parse(MenuStore.cartOrderSuccess);
        let error = response.FlagTable;
        console.log("error", response.FlagTable);
        if (error) {
            if (error.Error == "True") {
                swal({
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    text: t("True")
                })
                //alert(t("True"));
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                return false;
            }
        }
        //[Zeus 208, 11/22/2023] - Home page work order clear while changing Multisite - Kishore
        this.setState({ disableWo: false });
        let Validationdata = response.LoginClickFlags_UpdatetoSession;
        if (Validationdata) {
            if (Validationdata.length > 0) {
                console.log("Validationdata", Validationdata);
                Validationdata = Validationdata[0];
                console.log("busunit", CookieService.getLocalStorageValues("Session_BUSUNIT"));
                this._isMounted = true;
                isFirstTimeLoaded = true;
                let randomId = Math.floor(Math.random() * 10000000000)
                CookieService.updateLocalStorageValues("Session_ID", randomId)
                console.log("uuid", randomId)
                console.log("Session_ID", CookieService.getLocalStorageValues("Session_ID"))
                if (CookieService.getLocalStorageValues("Session_ISACrossSiteAcc") == "Y") {
                    this.setState({ selectedCrossSite: { label: CookieService.getLocalStorageValues("Session_BUDESCR"), value: CookieService.getLocalStorageValues("Session_BUSUNIT") } });
                }
                // this.refreshMenu();
                //  this.bindcomponentdidmount();

                let menuBO = {
                    Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                    Session_MenuUpdated: "False",
                    Session_SDIMenu: "",
                    Session_BARCODE: CookieService.getLocalStorageValues("Session_BARCODE") ? CookieService.getLocalStorageValues("Session_BARCODE") : "",
                    Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
                    Session_DEFAULTPAGE: CookieService.getLocalStorageValues("Session_DEFAULTPAGE"),
                    Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
                    Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
                    Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
                    Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
                    Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
                    Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
                    Session_AGENTUSERNAME: CookieService.getLocalStorageValues("Session_AGENTUSERNAME") ? CookieService.getLocalStorageValues("Session_AGENTUSERNAME") : "",
                    Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME")
                }
                MenuAction.getMenuItems(menuBO);
                if (!localStorage.getItem("SDIEnvironmentData")) {
                    let environmentBO = {
                        Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                        Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                        Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
                        AbsoluteUri: window.location.hostname,
                        Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                        Session_TESTMODE: CookieService.getLocalStorageValues("Session_TESTMODE"),
                        Session_ClientLogoImagePath: CookieService.getLocalStorageValues("Session_ClientLogoImagePath"),
                        Session_ISACrossSiteAcc: CookieService.getLocalStorageValues("Session_ISACrossSiteAcc"),
                        Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),//[Zeus 208, 11/22/2023] - Fetching multisite dropdown values for PunchIn- Kishore
                        Session_PUNCHIN: CookieService.getLocalStorageValues("Session_PUNCHIN"),
                        Session_USERID: CookieService.getLocalStorageValues("Session_UserID")
                    }
                    MenuAction.getEnvironmentDetails(environmentBO);
                }
                console.log("coname", CookieService.getLocalStorageValues("Session_CONAME"));
                //let environmentBO = {
                //    Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                //    Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
                //    Session_ZEUS_SITE: CookieService.getLocalStorageValues("Session_ZEUS_SITE"),
                //    AbsoluteUri: window.location.hostname,
                //    Session_USESOLRSEARCH: CookieService.getLocalStorageValues("Session_USESOLRSEARCH"),
                //    Session_TESTMODE: CookieService.getLocalStorageValues("Session_TESTMODE"),
                //    Session_ClientLogoImagePath: CookieService.getLocalStorageValues("Session_ClientLogoImagePath"),
                //    Session_ISACrossSiteAcc: CookieService.getLocalStorageValues("Session_ISACrossSiteAcc")
                //}
                //MenuAction.getEnvironmentDetails(environmentBO);
                ////window.location.reload(false)


                //$(`#${SDIConstants.SDILoader}`).addClass('hide');
                //MenuAction.refreshMasterData1()
            }
        }

    }
    refreshMenu = () => {
        let masterBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            isRequestorApproval: CookieService.getLocalStorageValues("hasReqApprovalCheck"),
            isOrderApproval: CookieService.getLocalStorageValues("hasOrderApprovalCheck"),
            isFavItem: CookieService.getLocalStorageValues("hasFavItemCheck"),
            isFavOrder: CookieService.getLocalStorageValues("hasFavOrderCheck"),
            isFirstTime: true,//isFirstTimeLoaded
            //6/10/22, PC_OA_02, CAS - Waiting order approval panel query change, Dhevanesam R]
            Session_MULTISITEUSER: CookieService.getLocalStorageValues("Session_MULTISITEUSER") ? CookieService.getLocalStorageValues("Session_MULTISITEUSER") : "",
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            //[04/18/2023, GOA_PS_16, Zeus 79] add Session_USERTYPE to get order approvals count for corp admin logged in -Sathis N
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE") ? CookieService.getLocalStorageValues("Session_USERTYPE").toUpperCase() : ""
        };
        console.log("masterBO", masterBO);
        MenuAction.getMasterData(masterBO);
        let menuBO = {
            Session_SDIEMP: CookieService.getLocalStorageValues("Session_SDIEMP"),
            Session_MenuUpdated: "False",
            Session_SDIMenu: "",
            Session_BARCODE: CookieService.getLocalStorageValues("Session_BARCODE") ? CookieService.getLocalStorageValues("Session_BARCODE") : "",
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_USERTYPE: CookieService.getLocalStorageValues("Session_USERTYPE"),
            Session_DEFAULTPAGE: CookieService.getLocalStorageValues("Session_DEFAULTPAGE"),
            Session_CONAME: CookieService.getLocalStorageValues("Session_CONAME"),
            Session_CUSTID: CookieService.getLocalStorageValues("Session_CUSTID"),
            Session_SITEBU: CookieService.getLocalStorageValues("Session_SITEBU"),
            Session_SITEPREFIX: CookieService.getLocalStorageValues("Session_SITEPREFIX"),
            Session_AGENTUSERBU: CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            Session_AGENTUSERID: CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            Session_AGENTUSERNAME: CookieService.getLocalStorageValues("Session_AGENTUSERNAME") ? CookieService.getLocalStorageValues("Session_AGENTUSERNAME") : "",
            Session_USERNAME: CookieService.getLocalStorageValues("Session_USERNAME")
        }
        MenuAction.getMenuItems(menuBO);
    };
    //********END***************************
    //***************************END***************


    /**
    * MN_PC_49
    * This method bind the favorite items in HTML
    */
    bindFavItems = () => {
        const { t } = this.props;
        return this.state.favitems.map((item, index) => {
            let imagePath = this.state.apiURL + `Images/noimage_new.png`;
            if (item.IMAGE_NAME) {
                item.IMAGE_NAME = item.IMAGE_NAME.replace('\\', '/');
                if (item.IMAGE_NAME.toLowerCase().indexOf("http") >= 0) {
                    imagePath = item.IMAGE_NAME;
                } else {
                    imagePath = item.IMAGE_NAME.lastIndexOf(`/`) >= 0 ? item.IMAGE_NAME.substring(item.IMAGE_NAME.lastIndexOf("/") + 1, item.IMAGE_NAME.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = this.state.apiURL + `Images/${imagePath}`;
                    }
                }
            } else {
                imagePath = this.state.apiURL + `Images/noimage_new.png`;
            }
            const style = {
                image: {
                    position: 'sticky !important',
                    display: 'block',
                    left: '0px !important',
                    top: '0px !important',
                },
            }
            let UnitPrice = parseFloat(item.PRICE) ? parseFloat(item.PRICE) : 0
            if (UnitPrice > 0) {
                UnitPrice = UnitPrice.toFixed(2)
            }
            else {
                UnitPrice = 0.00
            }
            return (<li className="list-group-item border-0 popover-shopping-background" key={index}>
                <div className="popover-item-id custom-checkbox custom-color item-custom-control d-inline-block col-md-12 p-0  custom-dropdown-chk">
                    <input type="checkbox" className="float-right custom-control-input" id={`customCheck${index + 1}`} checked={item.checked} name="example" onClick={() => this.selectFavItem(index)} />
                    <label className="custom-control-label w-100 priority-checkbox priority-checkbox-position profile-update-checkbox cursor-pointer" htmlFor={`customCheck${index + 1}`}>
                        {/*[01/26/2024, Zeus 261] - Invoke redirectToItemDetails() to navigate to Item details page - Kishore*/}
                        <div className="image-parent col-md-3 text-center float-left p-0" onClick={() => redirectToItemDetails(item.ISA_CP_ITEM_ID, item.CLIENT_MFG_PART, item.SUPPLIER_ID, "HeaderMenu", this.props)}><div className="row justify-content-center">
                            <Image
                                src={imagePath}
                                width={60}
                                height={60}
                                style={style.image}
                                noImageSrc="/Images/noimage_new.png"
                            /></div>
                            {/* <img src={imagePath} className="img-fluid" alt="Fav-Img" /> */}</div>
                        <div className="col-md-9 float-left pr-0" onClick={() => redirectToItemDetails(item.ISA_CP_ITEM_ID, item.CLIENT_MFG_PART, item.SUPPLIER_ID, "HeaderMenu", this.props)}>
                            <span className="popover-item-id-label"><Trans>Item ID</Trans> :</span>
                            {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
                            <span className="popover-item-id-value">{item.CLIENT_MFG_PART}</span>
                            <div className="popover-item-description"> {item.ITEM_DESC ? item.ITEM_DESC.length > 25 ? `${item.ITEM_DESC.substring(0, 22)}...` : item.ITEM_DESC : ``} </div>
                            {/**********NEW****************/}
                            <span className="popover-item-description"><Trans>Unit Price</Trans>:</span>
                            <span className="popover-item-description"> <Trans>{item.PRICE > 0 ? `$ ${UnitPrice}` : t("Price on request")}</Trans></span>
                            {/**********END****************/}
                        </div>
                    </label>
                </div>

            </li>)
        });
    };



    /**
    * MN_PC_48
    * This method bind the favorite Order in HTML
    */
    // bindFavOrderItems = (OrderNo) => {
    //     const { t } = this.props;
    //     let childItems = this.state.favOrderLineItems.filter((item, index) => item.ORDER_NO == OrderNo);
    //     //if (childItems.length > 5) {
    //     //    childItems.length = 5;
    //     //}
    //     return childItems.map((childVal, index) => {
    //         let imagePath = this.state.apiURL + `Images/noimage_new.png`;
    //         if (this.state.favOrderLineItemImgs.length > 0) {
    //             let filteredImages = this.state.favOrderLineItemImgs.filter((x) => x.ORDER_NO == OrderNo && x.BUSINESS_UNIT_OM == childVal.BUSINESS_UNIT_OM && x.ISA_INTFC_LN == childVal.ISA_INTFC_LN);
    //             let IMAGE_NAME = filteredImages.length > 0 ? filteredImages[0].ISA_ORIG_URL : "";
    //             if (IMAGE_NAME) {
    //                 if (IMAGE_NAME) {
    //                     IMAGE_NAME = IMAGE_NAME.replace('\\', '/');
    //                     if (IMAGE_NAME.toLowerCase().indexOf("http") >= 0) {
    //                         imagePath = IMAGE_NAME;
    //                     } else {
    //                         imagePath = IMAGE_NAME.lastIndexOf(`/`) >= 0 ? IMAGE_NAME.substring(IMAGE_NAME.lastIndexOf("/") + 1, IMAGE_NAME.length) : "noimage_new.png";
    //                         if (imagePath) {
    //                             imagePath = this.state.apiURL + `Images/${imagePath}`;
    //                         }
    //                     }
    //                 } else {
    //                     imagePath = this.state.apiURL + `Images/noimage_new.png`;
    //                 }
    //             }
    //         }
    //         const style = {
    //             image: {
    //                 position: 'sticky !important',
    //                 display: 'block',
    //                 left: '0px !important',
    //                 top: '0px !important',
    //             },
    //         }
    //         let UnitPrice = parseFloat(childVal.ISA_SELL_PRICE) ? parseFloat(childVal.ISA_SELL_PRICE) : 0
    //         if (UnitPrice > 0) {
    //             UnitPrice = UnitPrice.toFixed(2)
    //         }
    //         else {
    //             UnitPrice = 0.00
    //         }
    //         return (<li className="list-group-item border-0 popover-shopping-background" key={index}>
    //             <div className="popover-item-id custom-checkbox custom-color item-custom-control d-inline-block col-md-12 p-0  custom-dropdown-chk">
    //                 <input type="checkbox" className="float-right custom-control-input" id={`customCheck${index + 1}-chka-${OrderNo}`} checked={childVal.checked} name="example-chka" onClick={() => this.selectFavOrderItem(childVal.ISA_INTFC_LN, OrderNo)} />
    //                 <label className="custom-control-label w-100 priority-checkbox priority-checkbox-position profile-update-checkbox cursor-pointer" htmlFor={`customCheck${index + 1}-chka-${OrderNo}`}>
    //                     <div className="image-parent col-md-3 text-center float-left p-0">
    //                         <div className="row justify-content-center">
    //                             <Image
    //                                 src={imagePath}
    //                                 width={60}
    //                                 height={60}
    //                                 style={style.image}
    //                                 noImageSrc="/Images/noimage_new.png"
    //                             /></div>
    //                         {/*<img src={imagePath} className="img-fluid" alt="Fav-Order" /> */}</div>
    //                     <div className="col-md-9 float-left pr-0">
    //                         <span className="popover-item-id-label"><Trans>Item ID</Trans> :</span>

    //                         {/*19/12/23 - Zeus 242 - INC0028868 - Item ID change as MFG part number - Johnprinto*/}
    //                         <span className="popover-item-id-value">{childVal.MFG_ITM_ID}</span>
    //                         <div className="popover-item-description"> {childVal.DESCR254 ? childVal.DESCR254.length > 26 ? `${childVal.DESCR254.substring(0, 22)}...` : childVal.DESCR254 : ``} </div>
    //                         {/**********NEW****************/}
    //                         <span className="popover-item-description"><Trans>Unit Price</Trans>: </span>
    //                         <span className="popover-item-description"> <Trans>{childVal.ISA_SELL_PRICE > 0 ? `$ ${UnitPrice}` : t("Price on request")}</Trans></span>
    //                         {/**********END****************/}
    //                     </div>
    //                 </label>
    //             </div>
    //         </li>)
    //     });

    // }
    /**
     * MN_PC_48
     * This method bind the favorite Order in HTML
     */

    // bindFavOrders = () => {
    //     return this.state.favorders.map((order, index) => {
    //         let orderLineItemCount = this.state.favOrderLineItems.filter((value, index) => value.ORDER_NO == order.ORDER_NO).length;
    //         let childItems = this.state.favOrderLineItems.filter((item, index) => item.ORDER_NO == order.ORDER_NO);
    //         let itemchecked = false
    //         childItems.map((val, index) => {
    //             if (val.checked == true) { itemchecked = true }
    //       });
    //         //console.log("favOrderLineItems", this.state.favOrderLineItems)
    //         return (<li className="dropdown-submenu" key={index}>
    //             <a className="dropdown-item popover-shopping-background font-style" href="#" onClick={(event) => event.preventDefault()} >
    //                 {orderLineItemCount > 0 ? <span className="favmenu-right-arrow float-left" /> : null}
    //                 <span className={orderLineItemCount > 0 ? `ml-3` : `ml-3 pl-1`}> {order.ORDER_NO} <span>({orderLineItemCount})</span> </span>

    //             </a>
    //             {orderLineItemCount > 0 ? <div className="dropdown-menu dropdown-order-fav  custom-welcome-dropdown FavMenuWidth" aria-labelledby="navbarDropdown-myfav-order">{/*right-triangle-dropdownstyle*/}
    //                 <div className="col-md-12">
    //                     <div className="row">
    //                         <div className="col-md-12 p-0">
    //                             <ul className="list-group FavMenuWidth-ul">
    //                                 {this.bindFavOrderItems(order.ORDER_NO)}
    //                             </ul>
    //                             <div className="list-group-item border-0 mt-0 mb-0">

    //                                 <div className="col-md-12 text-center border-style pb-4">
    //                                     <button className="btn btn-primary custom-btn-showall primary-btn pb-2"
    //                                         onClick={(event) => this.selectFavOrderItem("", order.ORDER_NO)}>Select All</button>
    //                                 </div>
    //                             </div>
    //                             <div className="list-group-item border-0 pt-0">
    //                                 {itemchecked == true ? <div className="popover-shopping-addcart float-left itemdetailPrice">
    //                                     <span><Trans>Order Total</Trans>: {this.FavOrderTotalCal(childItems)}</span>
    //                                 </div> : null}
    //                                 <div className="popover-shopping-addcart">
    //                                     <button className="btn btn-primary custom-btn-primary " onClick={() => this.addFavOrderItemsToCart(order.ORDER_NO)}>
    //                                         <img src="/Images/item_cart.png" className="addtocart" alt="Add to cart icon" />
    //                                         Add to Cart
    //                                             </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div> : null}
    //         </li>)
    //     });
    // };

    // 03/08/2024 - Zeus 264 - Display the fav order category list - Johnprinto D
    bindFavOrderCategroy = () => {
        return this.state.favOrderCategory.map((value, index) => {
            return <li className="dropdown-submenu custom-position">
                <a className="dropdown-item items-bg-style Font13 font-medium px-3 py-2 display-long-word cursor-pointer" name="category-list" title={value.ORDER_CATEGORY}>{value.ORDER_CATEGORY}</a>
                {this.bindFavOrderSubCategory(value)}
            </li>
        })
    }
    // 03/08/2024 - Zeus 264 - Display the fav order sub category list - Johnprinto D
    bindFavOrderSubCategory = (orderDetails) => {
        let tempArr = this.state.favOrderSubCategory.filter((value) => value.ORDER_CATEGORY == orderDetails.ORDER_CATEGORY);
        let selectedOrder = this.state.favorders.filter((value) => value.ORDER_CATEGORY == orderDetails.ORDER_CATEGORY);
        let isDisable = true;
        selectedOrder.forEach((value) => {
            if (value.checked) {
                isDisable = false
                return;
            }
        })
        return <div className="dropdown-menu custom-welcome-dropdown" name="sub-category-list" aria-labelledby="navbarDropdown-myfav-order">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="font-16 font-medium mb-0">Subcategory list</h5>
                            <div>
                                <button type="button" id={orderDetails.ORDER_CATEGORY} disabled={isDisable} className={`btn btn-custom-primary Font13 font-medium px-3 py-2 ${isDisable ? "not-allowed-cursor" : ""}`} onClick={(e) => this.addFavOrderItemsToCart(e)}><img className="mr-2 custom-cart-width" src="images/add-to-cart-icon.svg" alt="cart-icon" />Add to cart</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mt-2 pr-1">
                <div className="fav-order-custom-scroll pr-1">
                    {tempArr.map((value) => {
                        return <div className="px-1 py-1" id="accordion">
                            <div className="card custom-card mb-1">
                                <div className="card-header border-bottom-0 bg-white px-3 pb-0" id="headingOne">
                                    <div className="d-flex justify-content-between align-items-center border-bottom">
                                        <div>
                                            <div data-toggle="collapse" data-target={`#order-row-${value.ORDER_NO}`} className="accordion-toggle collapsed" aria-expanded="true">
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="d-block mr-2">
                                                        <span className="accordion-arrow accordion-arrow-change"></span>
                                                    </div>
                                                    <div className="d-block display-long-word" style={{ width: '290px' }}>
                                                        <span className="Font13 font-medium text-dark" title={value.ORDER_SUBCATEGORY}>{value.ORDER_SUBCATEGORY}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline mr-0">
                                                <label className="form-check-label Font13 font-regular mr-2" for={`selectall-${value.ORDER_NO}`}>Select all</label>
                                                <input className="form-check-input" type="checkbox" id={`selectall-${value.ORDER_NO}`} checked={value.subChecked} onChange={(e) => { this.favOrderClick(e, value, "SubCat") }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id={`order-row-${value.ORDER_NO}`} className="accordian-body collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="card-body py-3 px-3">
                                        <div className="row">
                                            {this.bindFavOrder(value)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>

    }
    // 03/08/2024 - Zeus 264 - Display the fav favorite order list - Johnprinto D
    bindFavOrder = (orderDetails) => {
        let tempArr = this.state.favorders.filter((value) => value.ORDER_CATEGORY == orderDetails.ORDER_CATEGORY && value.ORDER_SUBCATEGORY == orderDetails.ORDER_SUBCATEGORY);
        const style = {
            image: {
                position: 'sticky !important',
                display: 'block',
                left: '0px !important',
                top: '0px !important',
            },
        }

        return tempArr.map((value) => {
            return <div className="col-md-12 mb-3">
                <div className="item-box">
                    <div className="d-flex justify-content-between px-2 py-2">
                        <div className="mr-2">
                            <Image
                                src={value.IMG_URL}
                                width={86}
                                height={92}
                                style={style.image}
                                noImageSrc="/Images/noimage_new.png"
                            />
                        </div>
                        <div className="w-100">
                            <div className="w-100">
                                <span className="font-12 font-medium mr-2">Work order :</span>
                                <div style={{ display: 'inline-flex' }}>
                                    <span style={{ width: '210px' }} className="font-12 display-long-word" title={value.ISA_WORK_ORDER_NO}>{value.ISA_WORK_ORDER_NO}</span>
                                </div>
                            </div>
                            <div className="w-100">
                                <span className="font-12 font-medium mr-2">Asset ID :</span><span className="font-12">{value.ISA_MACHINE_NO}</span>
                            </div>
                            <div className="w-100">
                                <span className="font-12 font-medium mr-2 display-long-word">Order no :</span><span className="font-12">{value.ORDER_NO}</span>
                            </div>
                            <div className="w-100">
                                <span className="font-12 font-medium mr-2">WO description :</span>
                                <div style={{ display: 'inline-flex' }}>
                                    <span style={{ width: '188px' }} className="font-12 display-long-word" title={value.WODESC}>{value.WODESC}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-check form-check-inline mr-0">
                                <input className="form-check-input" type="checkbox" id={`select-order-${value.ORDER_CATEGORY}${value.ORDER_SUBCATEGORY}`} checked={value.checked} onChange={(e) => { this.favOrderClick(e, value, "Order") }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        })
    }

    // 03/08/2024 - Zeus 264 - Invoke when favorite order sub category and order level check box click - Johnprinto D
    favOrderClick = (e, order, type) => {
        let favorders = this.state.favorders;
        let favOrderSubCategory = this.state.favOrderSubCategory;
        let index;
        let orderIndex;
        if (type == "SubCat") {
            index = favOrderSubCategory.findIndex(value => value.ORDER_CATEGORY == order.ORDER_CATEGORY && value.ORDER_SUBCATEGORY == order.ORDER_SUBCATEGORY);
            favOrderSubCategory[index].subChecked = e.target.checked;
            favorders.forEach((value) => {
                if (value.ORDER_CATEGORY == order.ORDER_CATEGORY && value.ORDER_SUBCATEGORY == order.ORDER_SUBCATEGORY) {
                    value.checked = e.target.checked;
                }
            })
            this.setState({ favorders, favOrderSubCategory })
        } else {
            orderIndex = favorders.findIndex(value => value.ORDER_NO == order.ORDER_NO);
            favorders[orderIndex].checked = e.target.checked;
            let count = 0;
            let total = 0
            favorders.forEach((value) => {
                if (value.ORDER_CATEGORY == order.ORDER_CATEGORY && value.ORDER_SUBCATEGORY == order.ORDER_SUBCATEGORY) {
                    if (value.checked == e.target.checked) {
                        count++;
                    }
                    total++;
                }
            })
            index = favOrderSubCategory.findIndex(value => value.ORDER_CATEGORY == order.ORDER_CATEGORY && value.ORDER_SUBCATEGORY == order.ORDER_SUBCATEGORY);

            if (count == total) {
                favOrderSubCategory[index].subChecked = e.target.checked;
            } else {
                favOrderSubCategory[index].subChecked = false;
            }

            this.setState({ favorders })
        }
    }

    /**
     * MN_PC_39
     * This method bind the cart data in HTML
     */
    /*[11/22/21, PC_SC_02, 1830,1831], Priyanka D*/
    Replace = (replace_string, replace_char, replacement_char) => {
        let i;
        for (i = 0; i < replace_string.length; i++) {
            if (replace_string.charAt(i) == replace_char) {
                replace_string = replace_string.substring(0, i) + replacement_char + replace_string.substring(i + 1);

            }
        }
        return replace_string;
    }

    bindCartData = () => {
        const { t } = this.props;
        return this.state.cartItems.map((cart, index) => {
            let imagePath = "";
            if (cart.Image) {
                cart.Image = cart.Image.replace('\\', '/');
                if (cart.Image.toLowerCase().indexOf("http") >= 0) {
                    imagePath = cart.Image;
                } else {
                    imagePath = cart.Image.lastIndexOf(`/`) >= 0 ? cart.Image.substring(cart.Image.lastIndexOf("/") + 1, cart.Image.length) : "noimage_new.png";
                    if (imagePath) {
                        imagePath = `/Images/${imagePath}`;
                    }
                }
            } else {
                imagePath = `/Images/noimage_new.png`;
            }
            const style = {
                image: {
                    position: 'sticky !important',
                    display: 'block',
                    left: '0px !important',
                    top: '0px !important',
                },
            }
            /*[11/22/21, PC_SC_07, 1830,1831], Priyanka D*/
            let unitepricevalue = "";
            //[11/9/21, PC_06, CAS - 37, Dhevanesam R]
            if (cart.Price != undefined && cart.Price != "" && cart.Price != " " && cart.Price != null) {
                unitepricevalue = this.Replace(cart.Price, ",", "")
            }
            let UnitPrice = parseFloat(unitepricevalue) ? parseFloat(unitepricevalue) : 0
            if (UnitPrice > 0) {
                UnitPrice = UnitPrice.toFixed(2)
            }
            else {
                UnitPrice = 0.00
            }
            return (<li className="list-group-item border-0 popover-shopping-background" key={index} onClick={() => redirectToItemDetails(cart.Itemid, cart.Manufacturerpartnumber, cart.SupplierID, "HeaderMenu", this.props)}>
                <div className="image-parent"><div className="row justify-content-center">
                    <Image
                        src={imagePath}
                        width={60}
                        height={60}
                        style={style.image}
                        noImageSrc="/Images/noimage_new.png"
                    /></div>
                    {/*<img src={imagePath} className="img-fluid" alt="cart-item" />*/} </div>
                <div className="popover-cart-leftpane">
                    <div className="popover-item-id pb-1"> <span className="popover-item-id-label"><Trans>Item ID</Trans> :</span> <span className="popover-item-id-value">{cart.Manufacturerpartnumber}</span> </div>

                    <div className="popover-item-description pb-1"> {cart.ItemDescription ? cart.ItemDescription.length > 26 ? `${cart.ItemDescription.substring(0, 22)}...` : cart.ItemDescription : ``}</div>
                    {/**********NEW****************/}
                    {/*[3/30/22, CAS - 31, Dhevanesam R]*/}
                    <span className="popover-item-description"><Trans>Unit Price</Trans>:</span>
                    <span className="popover-item-description"> <Trans>{cart.Price > 0 ? `$ ${UnitPrice}` : t("Price on request")}</Trans>{cart.Price > 0 ? this.PriceDesignation() : null}</span>
                    {/**********END****************/}
                </div>
            </li>)
        })

    };
    //PriceDesignation() method is to bind the currency designation of items in menu cart
    PriceDesignation = () => {
        let SiteCurrency = this.state.SiteCurrencyTable;
        let cartcount = this.state.cartItems.length;
        if (cartcount != 0) {
            return this.state.cartItems.map((val, index) => {
                let loopindex = index + 1;
                if (loopindex == cartcount) {
                    return (" " + SiteCurrency[0].id);
                }
            }
            );
        }
    }

    OrderTotalCal = () => {
        //let SiteCurrency = this.state.SiteCurrencyTable;
        let ItemTotal = 0;
        let OrderTotal = 0;
        let cartcount = this.state.cartItems.length;
        console.log("cartItems", this.state.cartItems);
        /*[11/22/21, PC_SC_08, 1830,1831], Priyanka D*/
        let unitepricevalue = "";
        return this.state.cartItems.map((val, index) => {
            if (val.Price != undefined && val.Price != "" && val.Price != " " && val.Price != null) {
                /*[6/6/22],removed redeclaration of unitpricevalue variable since the order total wasn't displyed in cart menu- Poornima S*/
                unitepricevalue = val.Price.replace(/,/g, '');
            }
            let uniteprice = parseFloat(unitepricevalue) ? parseFloat(unitepricevalue) : 0
            //********NEW(1/18/2021) *ADDED*************parseFloat(val.Quantity) : 1************
            let QuantityAvail = parseFloat(val.Quantity) ? parseFloat(val.Quantity) : 1
            //**********END*****(1/18/2021)**************
            //********NEW(1/6/2021) *ADDED*************(|| val.Price!= 0)************                  
            if (val.Price.toUpperCase() != "PRICE ON REQUEST" && val.Price != 0) {
                //************END*************
                if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
                    //********NEW(1/6/2021)************
                    //*ADDED
                    //ItemTotal = uniteprice ;
                    //*Removed
                    //***NEW(1/18/2021)///ADDED
                    ItemTotal = uniteprice * QuantityAvail;
                    //**(1/18/2021)*END
                    //************END*************
                    //ItemTotal = uniteprice;
                }
                OrderTotal = OrderTotal + ItemTotal;
            }
            let loopindex = index + 1;
            if (loopindex == cartcount) {
                let roundOffvalue = OrderTotal.toFixed(2)
                return ("$ " + roundOffvalue);
            }
        });

    }
	//FavOrderTotalCal = (checkeditem) => {

	//	//let SiteCurrency = this.state.SiteCurrencyTable;
	//	let ItemTotal = 0;
	//	let OrderTotal = 0;
	//	let cartcount = checkeditem.length;
	//	return checkeditem.map((val, index) => {
	//		let uniteprice = parseFloat(val.ISA_SELL_PRICE) ? parseFloat(val.ISA_SELL_PRICE) : 0
	//		let QuantityAvail = parseFloat(val.QTY_REQUESTED) ? parseFloat(val.QTY_REQUESTED) : 0
	//		if (uniteprice > 0 && val.checked == true) {
	//			if (uniteprice != NaN && QuantityAvail != NaN && uniteprice != 0 && QuantityAvail != 0) {
	//				ItemTotal = uniteprice * QuantityAvail;
	//				//ItemTotal = uniteprice;
	//			}
	//			OrderTotal = OrderTotal + ItemTotal;
	//		}
	//		let loopindex = index + 1;
	//		if (loopindex == cartcount) {
	//			let roundOffvalue = OrderTotal.toFixed(2)
	//			return ("$ " + roundOffvalue);
	//		}
	//	});

	//}
    FavTotalCal = () => {
        //let SiteCurrency = this.state.SiteCurrencyTable;

        let ItemTotal = 0;
        let OrderTotal = 0;
        let cartcount = this.state.favitems.length;
        return this.state.favitems.map((val, index) => {

            let uniteprice = parseFloat(val.PRICE) ? parseFloat(val.PRICE) : 0
            let QuantityAvail = val.PRICE != null && val.PRICE != "" ? val.PRICE : "PRICE ON REQUEST"
            if (uniteprice > 0 && val.checked == true) {
                OrderTotal = OrderTotal + uniteprice;
            }
            let loopindex = index + 1;
            if (loopindex == cartcount) {
                let roundOffvalue = OrderTotal.toFixed(2)
                return ("$ " + roundOffvalue);
            }
        });

    }
    /**
   * Session time out method
   */
    _onAction = (e) => {
        this.setState({ isTimedOut: false })
    };
    /**
   * Session time out method
   */
    _onActive = (e) => {
        this.setState({ isTimedOut: false })
    };
    /**
   * Session time out method
   */
    sessionTimeoutMethod = () => {
        let envData = localStorage.getItem("SDIEnvironmentData");
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        CookieService.setCookieValues(SDIConstants.access_token, "", -1);
        // localStorage.clear();
        localStorage.setItem("sessionTimeOutData", envData);
        this.props.history.push({
            pathname: '/sessiontimeout',
        });
    }
    _onIdle(e) {
        console.log('user is idle', e);
        this.sessionTimeoutMethod();
    };

    buildHeaderMegaMenu = (CATAGORY_ID) => {
        //fav-right-arrow
        let { userMenu } = this.state;
        let headerMegaMenu = userMenu.filter((x) => (x.ISA_PARENT_IDENT != null && x.CATAGORY_ID == CATAGORY_ID));
        //[11/22/21, PC_MC_01, CAS - MegaMenu Issue, Dhevanesam R]

        headerMegaMenu = headerMegaMenu.filter((x) => x.PROGRAMNAME == "PunchOut" || x.PROGRAMNAME == "Non-Catalog Request" || x.PROGRAMNAME == "Receive Items"
            || x.PROGRAMNAME == "Add New User" || x.PROGRAMNAME == "Edit User Information" || x.PROGRAMNAME == "Add New Vendor" || x.PROGRAMNAME == "Edit Vendor Information"
            || x.PROGRAMNAME == "Add New Mexico" || x.PROGRAMNAME == "Edit Mexico information" || x.PROGRAMNAME == "Edit Favourites" || x.PROGRAMNAME == "Your User Profile" || x.PROGRAMNAME == "Requestor Approval"
            || x.PROGRAMNAME == "Approve Orders")
        //headerMegaMenu = headerMegaMenu.filter((x) => x.PROGRAMNAME != "RFQ Status" && x.PROGRAMNAME != "PO Status" && x.PROGRAMNAME != "Active Users - SDI/Customers" && x.PROGRAMNAME != "RTV" && x.PROGRAMNAME != "Reprint Delivery Ticket" && x.PROGRAMNAME != "Receiving Report" && x.PROGRAMNAME != "Load Stage Errors" && x.PROGRAMNAME != "Approve All by Work Order")
        let noChildHeaders = userMenu.filter((x) => (x.ISA_PARENT_IDENT == null && x.CATAGORY_ID == CATAGORY_ID));
        //noChildHeaders = noChildHeaders.filter((x) => x.PROGRAMNAME != "RFQ Status" && x.PROGRAMNAME != "PO Status" && x.PROGRAMNAME != "Active Users - SDI/Customers" && x.PROGRAMNAME != "RTV" && x.PROGRAMNAME != "Reprint Delivery Ticket" && x.PROGRAMNAME != "Receiving Report" && x.PROGRAMNAME != "Load Stage Errors" && x.PROGRAMNAME != "Approve All by Work Order")
        noChildHeaders = noChildHeaders.filter((x) => x.PROGRAMNAME == "PunchOut" || x.PROGRAMNAME == "Non-Catalog Request" || x.PROGRAMNAME == "Receive Items"
            || x.PROGRAMNAME == "Add New User" || x.PROGRAMNAME == "Edit User Information" || x.PROGRAMNAME == "Add New Vendor" || x.PROGRAMNAME == "Edit Vendor Information"
            || x.PROGRAMNAME == "Add New Mexico" || x.PROGRAMNAME == "Edit Mexico information" || x.PROGRAMNAME == "Edit Favourites" || x.PROGRAMNAME == "Your User Profile" || x.PROGRAMNAME == "Requestor Approval"
            || x.PROGRAMNAME == "Approve Orders")
        noChildHeaders.forEach((nochdm, index) => {
            if (this.filterChildMenu(userMenu, nochdm).length == 0) {
                headerMegaMenu = headerMegaMenu.concat(nochdm);
            }
        });
        return headerMegaMenu.map((header, headerIndex) => {
            return (<li className="float-left col-md-3" key={header.PROGRAMNAME + "-" + headerIndex}>
                <a className="dropdown-item" href="#" onClick={(event) => this.childMenuClick(event, header)}><Trans>{header.PROGRAMNAME}</Trans></a>
            </li>);
        });
    };
    isOdd = (num) => num % 2 > 0 ? true : false;
    buildMegaMenu = () => {
        const { megaMenuCategories } = { ...this.state };
        let rightAllignThreshold = megaMenuCategories.length > 0 ? megaMenuCategories.length / 2 : 0;
        let isOddLength = false;
        let middleIndex = -1;
        if (megaMenuCategories.length > 0) {
            isOddLength = megaMenuCategories.length >= 3 ? this.isOdd(megaMenuCategories.length) : false;
            if (isOddLength)
                middleIndex = (Math.round((megaMenuCategories.length) / 2) - 1);
        }

        //[11/12/21, PC_MC_01, Bug - 1756, Dhevanesam R]
        let megaMenuCategoriesloc = megaMenuCategories;
        megaMenuCategoriesloc.forEach((val, index) => {

            let { userMenu } = this.state;
            let CATAGORY_ID = val.CATAGORY_ID;
            let headerMegaMenu = userMenu.filter((x) => (x.ISA_PARENT_IDENT != null && CATAGORY_ID == x.CATAGORY_ID))

            headerMegaMenu = headerMegaMenu.filter((x) => x.PROGRAMNAME == "PunchOut" || x.PROGRAMNAME == "Non-Catalog Request" || x.PROGRAMNAME == "Receive Items"
                || x.PROGRAMNAME == "Add New User" || x.PROGRAMNAME == "Edit User Information" || x.PROGRAMNAME == "Add New Vendor" || x.PROGRAMNAME == "Edit Vendor Information"
                || x.PROGRAMNAME == "Add New Mexico" || x.PROGRAMNAME == "Edit Mexico information" || x.PROGRAMNAME == "Edit Favourites" || x.PROGRAMNAME == "Your User Profile" || x.PROGRAMNAME == "Requestor Approval"
                || x.PROGRAMNAME == "Approve Orders")
            // headerMegaMenu = headerMegaMenu.filter((x) => x.PROGRAMNAME != "Active Users - SDI/Customers")
            if (headerMegaMenu.length == 0) {

                megaMenuCategoriesloc.splice(index, 1);
            }
        });
        return megaMenuCategoriesloc.map((title, index) => {

            let allignClass = "";


            if (index >= rightAllignThreshold && index != middleIndex)
                allignClass = "dropdown-menu-right position-mega-menu";
            if (isOddLength && index == middleIndex)
                allignClass = 'center-menu';
            //[11/26/21, CAS - 31, Dhevanesam R]
            return (<li className="custom-menu-nav nav-item" key={title.CATAGORY_ID}>
                <div className="dropdown nav-megamenu-dropdown d-inline-block">
                    <a className="dropdown-toggle submenu-dropdown-arrow home-page-menu" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{title.CATAGORY_NAME}</Trans></a>
                    <ul className={"dropdown-menu megamenu-container " + allignClass}>
                        {this.buildHeaderMegaMenu(title.CATAGORY_ID)}
                    </ul>
                </div>
            </li>);
        })
    };
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.searchClick();
        };
    };
    searchClick = () => {
        console.log(this.state.autoCompleteValue);
        if (this.state.autoCompleteValue.trim()) {
            let autoCompleteValue = encodeURIComponent(this.state.autoCompleteValue.trim());
            if (currentPath.toLowerCase() == "/searchresults") {
                let currentpage = window.location.href;
                if (window.location.href.indexOf(`?`))
                    currentpage = window.location.href.split('?')[0];
                window.location.href = currentpage + `?key=${autoCompleteValue}`;
            }
            //Zeus 279 - Unexpected suggested options for "SPACER SHI" request - Dhinesh R//
            this.props.history.push({
                pathname: `\searchresults`,
                search: `?key=${autoCompleteValue}`
            });
        }
    };
    //[PC_06, 09/07/21, Author - Priyanka D, Dhevanesam R]
    onLanguageHandle = (e) => {

        let newObj = e;
        let newLang = e.value;

        CookieService.updateLocalStorageValues("oldlanguage", this.state.language);
        CookieService.updateLocalStorageValues("newlanguage", newObj);
        //[02/21/22, CAS - 31, Dhevanesam R]
        // $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        //window.location.reload(true);
        //e.preventDefault();
        //window.location.reload(false);
        //window.history.go(-1);
        i18n.changeLanguage(newLang);
        this.setState({ language: newObj });
    };
    clearSearch = () => {
        this.setState({ autoCompleteValue: "", autocompleteData: [] });
    }
    bindContactUs = () => {

        return (<div className="fixed-bottom ham-section">
            <h5 className="w-100 float-left ham-title">Contact Us</h5>
            <div className="col-md-12 float-left p-0">
                <label className="float-left ham-label ">Phone:</label>
                <a href="tel:+1-885-446-9423" className="float-left ham-link">885-446-9423</a>
            </div>
            <div className="col-md-12 float-left p-0">
                <label className="float-left  ham-label ">Email:</label>
                <a href="mailto:WalmartPurchasing@sdi.com" className="float-left ham-link ">WalmartPurchasing@sdi.com</a>
            </div>
        </div>
        );
    }
    //[05/09/2023 - PC_OSC_3 - ZEUS-99 - Invoke method for get the OSC URL]
    PassAuthe = (event) => {
        event.preventDefault()
        let customFields = []
        let PassAutheBO = {
            UserID: CookieService.getLocalStorageValues("Session_AGENTUSERID") == "" ? CookieService.getLocalStorageValues("Session_UserID") : CookieService.getLocalStorageValues("Session_AGENTUSERID"),
            UserBu: CookieService.getLocalStorageValues("Session_AGENTUSERBU") == "" ? CookieService.getLocalStorageValues("Session_BUSUNIT") : CookieService.getLocalStorageValues("Session_AGENTUSERBU"),
            EmailID: CookieService.getLocalStorageValues("Session_EMAIL"),
            IsThirdParty: CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") != "0" && CookieService.getLocalStorageValues("Session_THIRDPARTY_COMP_ID") != null ? true : false,
            PassWord: "",
            OrderNumber: "",
            PONumber: "",
            HomepageRequest: true,
            customFields: customFields
        }
        MenuAction.getPassThroughAutheURl(PassAutheBO)
    }
    //[05/09/2023 - PC_OSC_7 - ZEUS-99 - Redirect the user to OSC page]
    PassThroughAutheResponse = () => {
        let URl = JSON.parse(MenuStore.PassThroughAuthe)
        window.open(URl);
        window.preventDefault();
    }

    wodescPopup = () => {
        $('#work-order-details-menu').modal('show');
    }
    ////[09/08/2023 - PS_ZE_134_APP_04 - Johnprinto D]
    changeOrderCount = (flage) => {
        if (flage) {
            $('#order-loader').removeClass('d-none');
            $('#order-count').addClass('d-none');
        } else {
            $('#order-count').removeClass('d-none');
            $('#order-loader').addClass('d-none');
            let n = localStorage.getItem("orderApprovalCount");
            let digit = n.toString().split('').length;
            console.log("o-digit", digit);
            if (digit == 1) {
                $('#order-count').removeClass('threedigit-count');
                $('#order-count').addClass('null-count');
            } else if (digit == 3) {
                $('#order-count').removeClass('null-count');
                $('#order-count').addClass('threedigit-count');
            } else {
                $('#order-count').removeClass('null-count');
                $('#order-count').removeClass('threedigit-count');
            }
            $('#order-count').text(localStorage.getItem("orderApprovalCount"));
        }
    }
    //[09/08/2023 - PS_ZE_134_APP_05 - Johnprinto D]
    changeRequestCount = (flage) => {
        if (flage) {
            $('#request-loader').removeClass('d-none');
            $('#request-count').addClass('d-none');
        } else {
            $('#request-count').removeClass('d-none');
            $('#request-loader').addClass('d-none');
            let n = localStorage.getItem("requestorApprovalCount");
            let digit = n.toString().split('').length;
            console.log("r-digit", digit);
            if (digit == 1) {
                $('#request-count').removeClass('threedigit-count');
                $('#request-count').addClass('null-count');
            } else if (digit == 3) {
                $('#request-count').removeClass('null-count');
                $('#request-count').addClass('threedigit-count');
            } else {
                $('#request-count').removeClass('null-count');
                $('#request-count').removeClass('threedigit-count');
            }
            $('#request-count').text(localStorage.getItem("requestorApprovalCount"));
        }
    }
    /**
    * Render method loads the HTML components to page
    */
    //[11/26/21, CAS - 31, Dhevanesam R]
    render() {
        const { t } = this.props;
        let displayName = "";
        if (CookieService.getLocalStorageValues("Session_USERNAME")) {
            displayName = CookieService.getLocalStorageValues("Session_USERNAME").indexOf(",") >= 0 ? CookieService.getLocalStorageValues("Session_USERNAME").split(",")[1] + " " + CookieService.getLocalStorageValues("Session_USERNAME").split(",")[0] : CookieService.getLocalStorageValues("Session_USERNAME");
            if (CookieService.getLocalStorageValues("Session_AGENTUSERID") != "" && CookieService.getLocalStorageValues("Session_AGENTUSERID")) {
                if (CookieService.getLocalStorageValues("Session_USERTYPE") == "ADMINX") {
                    displayName = displayName + CookieService.getLocalStorageValues("Session_CONAME");
                } else {
                    displayName = displayName + CookieService.getLocalStorageValues("Session_AGENTUSERNAME");
                }
            }
        };
        var height = $(window).height() - (62 + 230);
        var hg = $(window).height() - (62 + 195);
        $(".hamburger-navigation").attr('style', `height:${height}px !important`);
        $(".subham").attr('style', `height:${hg}px !important`);
        // 03/08/2024 - Zeus 264 - Get the category-list element using its name - Johnprinto D
        var items = document.querySelectorAll('[name="category-list"]');

        // 03/08/2024 Zeus 264 - Add event listener to each item - Johnprinto D
        items.forEach(function (item) {
            item.addEventListener('mouseenter', function (event) {
                // Call function to calculate top position and log it
                calculateTopPosition(event.target);
            });
        });

        // 03/08/2024 - Zeus 264 - Function to calculate top position - Johnprinto D
        function calculateTopPosition(element) {
            var topPosition = element.getBoundingClientRect().top;
            $('[name="sub-category-list"]').attr('style', `top:${topPosition}px !important`);
        }
        return (<React.Fragment>
            <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={this.state.timeout} />
            <div id="hamburger-menu-container" >
                {/* [10/10/23, Zeus 206 - Zeus 2.0 Menu structure revamp, Johnprinto ] */}
                <div id="hamburger-menu-container-background" className="hmenu-dark-bkg-color hmenu-transparent hmenu-opaque" onClick={(e) => this.handleOutsideClick(e)} ></div>
                {/*[3/4/22, PC_MC_01, Menu Overlap issue, Dhevanesam R]*/}
                <div id={`hamburger-canvas`}>
                    <div className="nav-sprite hmenu-close-icon" id="hamgurgerhide" onClick={this.hideHamBurger} />
                    <h3 className="hamburger-header"><Trans>Main Menu</Trans></h3>
                    <div className="d-flex">
                        <div className=" input-group charge-code-group p-3" style={{ zIndex: "9999" }}>
                            <Autocomplete
                                getItemValue={this.getItemMenuValue}
                                items={this.state.SuggestionMenu}
                                renderItem={this.rendermenuItem}
                                value={this.state.SelectedMenuName}
                                onChange={e => this.onChangeItem(e)}
                                onSelect={(e) => this.onSelectItem(e)}
                                inputProps={{ placeholder: "Search Panel", className: "form-control float-left sdi-leftnav-search", style: { width: "246px", 'padding-right': '26px' }, onKeyDown: this.MenuSearchKeyPress }}
                            />
                            {this.state.SelectedMenuName && this.state.SelectedMenuName.length > 0 ? <span class="search-cancel-header cursor-pointer" style={{ "top": "28px", "left": "240px" }} onClick={() => this.ClearMenuSearch()} > </span> : null}
                            <div className="input-group-append" >
                                <span className="input-group-text" onClick={(e) => this.MenuSearchClick(this.state.SelectedMenuName)} >
                                    <span className="shopping-search-icon" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="list-group hamburger-navigation">
                        {this.bindHeaderNewElements()}
                        <div className="h-line" />
                        <a href="#" className="list-group-item list-group-item-action border-0" onClick={(event) => this.headerRedirect(event, "/profile")}><Trans>User Profile</Trans></a>
                        <a href="#" className="list-group-item list-group-item-action border-0" onClick={(event) => this.headerRedirect(event, "/favorder")}><Trans>Favorite Orders</Trans></a>
                        {/*[3/30/22, CAS - 31, Dhevanesam R]*/}
                        <a href="#" className="list-group-item list-group-item-action border-0" onClick={(event) => this.headerRedirect(event, "/favorites")}><Trans>Favorite Items</Trans></a>
                        {this.state.favPages.length > 0 ? <a href="#" className="list-group-item list-group-item-action border-0 menu-arrow" onClick={this.favPagelinkClick.bind(this)}><Trans>Favorite Pages</Trans></a> : null}

                        {/* <a href="#" className="btn btn-default logoutbtn" onClick={this.logout}>Logout</a>*/}
                    </div>
                    <div className="new-hamburger ham-section">
                        <div className="text-center pb-2">
                            <a href="#" className="btn btn-default logoutbtn" onClick={this.logout}><Trans>Logout</Trans></a>
                        </div>
                        {/*[05/24/2023, PC_ZE_87_APP_06 - Johnprinto D]*/}
                        {/*[05/24/2023, PC_ZE_87_APP_06 - Johnprinto D]*/}
                        {/*[06/02/2023, 3610-Bugfix - Johnprinto D]*/}

                        {CookieService.getLocalStorageValues("Session_PHONENUM") != "" || CookieService.getLocalStorageValues("Session_EMAILID") != "" ? <div>
                            <h5 className="ham-title bg-white"><Trans>Contact Us</Trans></h5>
                            {CookieService.getLocalStorageValues("Session_PHONENUM") != "" ?
                                <div className="col-md-12 float-left px-0">
                                    <label className="ham-label bg-white d-inline mr-2" style={{ width: "35%" }}><Trans>Phone</Trans><span>:</span></label>
                                    <a href={"tel:" + CookieService.getLocalStorageValues("Session_PHONENUM")} className="ham-link">{CookieService.getLocalStorageValues("Session_PHONENUM")}</a>
                                </div> : null}
                            {CookieService.getLocalStorageValues("Session_EMAILID") != "" ?
                                <div className="col-md-12 float-left px-0">
                                    <label className="ham-label bg-white d-inline mr-2" style={{ width: "35%" }}><Trans>Email</Trans><span>:</span></label>
                                    <a href={"mailto:" + CookieService.getLocalStorageValues("Session_EMAILID")} className="ham-link ">{CookieService.getLocalStorageValues("Session_EMAILID")}</a>
                                </div> : null}
                        </div> : null}
                    </div>
                    {/********NEW*****REMOVED**************(2/8/2021)**/}
                    {/* {this.state.Login_UserBU == "I0W01" ? this.bindContactUs() : ""}*/}
                    {/****END********************/}
                </div>
                <div id={`non-catalog`} >
                    <div className="nav-sprite hmenu-close-icon" id="hamgurgerhide" onClick={this.hideHamBurger} />
                    <h3 className="hamburger-header mb-3"><img className="mr-3 back-icon" title="Back" id="back" src="/Images/left-arrow.svg" onClick={this.showParent} /><Trans>{t(this.state.currentHeader)}</Trans></h3>
                    <span className="hamburger-hidden-title">{t(this.state.currentHeader)}</span>
                    <div className="list-group hamburger-navigation pt-2 subham">
                        {this.state.childComponents}
                    </div>
                    <div className="new-hamburger ham-section">
                        <div className="text-center pb-2">
                            <a href="#" className="btn btn-default logoutbtn" onClick={this.logout}><Trans>Logout</Trans></a>
                        </div>
                        {/*[05/24/2023, PC_ZE_87_APP_06 - Johnprinto D]*/}
                        {/*[06/02/2023, 3610-Bugfix - Johnprinto D]*/}

                        {CookieService.getLocalStorageValues("Session_PHONENUM") != "" || CookieService.getLocalStorageValues("Session_EMAILID") != "" ? <div>
                            <h5 className="ham-title bg-white"><Trans>Contact Us</Trans></h5>
                            {CookieService.getLocalStorageValues("Session_PHONENUM") != "" ?
                                <div className="col-md-12 float-left px-0">
                                    <label className="ham-label bg-white d-inline mr-2" style={{ width: "35%" }}><Trans>Phone</Trans><span>:</span></label>
                                    <a href={"tel:" + CookieService.getLocalStorageValues("Session_PHONENUM")} className="ham-link">{CookieService.getLocalStorageValues("Session_PHONENUM")}</a>
                                </div> : null}
                            {CookieService.getLocalStorageValues("Session_EMAILID") != "" ?
                                <div className="col-md-12 float-left px-0">
                                    <label className="ham-label bg-white d-inline mr-2" style={{ width: "35%" }}><Trans>Email</Trans><span>:</span></label>
                                    <a href={"mailto:" + CookieService.getLocalStorageValues("Session_EMAILID")} className="ham-link ">{CookieService.getLocalStorageValues("Session_EMAILID")}</a>
                                </div> : null}
                        </div> : null}
                    </div>
                    {/********NEW*****REMOVED**************(2/8/2021)**/}
                    {/* {this.state.Login_UserBU == "I0W01" ? this.bindContactUs() : ""}*/}
                    {/****END********************/}
                </div>


            </div>
            <div className="fixed-top">
                <nav className="navbar navbar-expand-lg navbar-light bg-dark-sdi-nav mobile-menu">
                    {/*[09/17/21, PC_01, Dhevanesam R] Check using ternary operator whether the value of Session_ISACrossSiteAcc is "Y" to show the cross site dropdown else show the business unit text label
                     [09/17/21, PC_02, Dhevanesam R] Change the css style of cross site dropdown as "Width:50%"*/}
                    {/*[09/24/21 PC_04, Priyanka D]Change the css class from "col-md-6 float-left" to "col-md-5 float-left"*/}
                    <div className="col-md-4 float-left">
                        {/*[Zeus 208, 11/22/2023] - Multisite empty dropdown issue fix - Kishore*/}
                        {CookieService.getLocalStorageValues("Session_ISACrossSiteAcc") == "Y" && localStorage.getItem("SDIcrosssite") != undefined && localStorage.getItem("SDIcrosssite") != null && JSON.parse(localStorage.getItem("SDIcrosssite")).length > 0 ?
                            <>
                                {/*<select className="crossSite" style={{ width: "50%"}} name="selectedCrossSite" value={this.state.selectedCrossSite} onChange={this.ChangeCrossSite}>
                                {/*<option value="Site">Select Business Unit</option>*/}
                                {/*{this.CrossSiteBuBind()}*/}
                                {/*[Zeus 276, 02/21/2024] - Conversion of BU DD into Typeahead DD - Kishore*/}
                                <span style={{ "fontSize": "11px" }} className="col-lg-6 col-md-12 float-left px-0 col-12 mt-2 mb-2"><Select
                                    id="Address"
                                    name="Address"
                                    value={this.state.selectedCrossSite}
                                    options={this.state.crossSiteDD}
                                    onChange={(e) => this.ChangeCrossSite(e)}
                                /></span>
                                {/*</select>*/}
                            </> : <span className="navbar-brand-business-unit"> {CookieService.getLocalStorageValues("Session_CONAME")} </span>}
                    </div>

                    {/* {CookieService.getLocalStorageValues("Session_ISACrossSiteAcc") == "Y" ?
                        <div className="navbar navbar-expand-lg navbar-light bg-dark-sdi-nav mobile-menu " id="RightDiv">
                            <select className="crossSite" name="selectedCrossSite" value={this.state.selectedCrossSite} onChange={this.ChangeCrossSite}>
                                {/*<option value="Site">Select Business Unit</option>
                                {this.CrossSiteBuBind()}
                            </select>
                        </div> : null}*/}
                    {/*[3/3/22, PC_MC_01, Bug - 1914, Dhevanesam R]*/}
                    {/*Zeus-241 - Product Replenishmet panel -- Hiding buy again button*/}
                    <div className="collapse navbar-collapse margin-top-links">

                        <ul className="navbar-nav ml-auto sdi-primary-nav">
                            {this.state.hasOrderStatus ? <li className="nav-item active"> <a className="nav-link text-nowrap" href="#" onClick={(event) => this.headerRedirect(event, "/OrderStatus2")}><Trans>Order Status</Trans></a> </li> : null}

                            {this.state.ShowFavOrder ? <li className="nav-item dropdown fav-order-dropdown" id="FavOrderLi">
                                <a className="nav-link dropdown-toggle custom-dropdown-togglek text-nowrap " href="#" aria-haspopup="true" aria-expanded="false" onClick={(event) => this.headerRedirect(event, "/favorder")}> <Trans>My Favorite Order</Trans> </a>
                                {this.state.favOrdersCount > 0 ?
                                    <span className="fav-order-custom-dropdown dropdown-menu border-0" style={{ "z-index": "999999" }}>
                                        <ul className="px-1 nav-scroll">
                                            {/* 03/08/2024 - Zeus 264 - Bind the favorite order category list - Johnprinto D */}
                                            {this.bindFavOrderCategroy()}

                                        </ul> </span> :
                                    <div className="dropdown-menu  custom-welcome-dropdown dropdown-style-show dropdown-width" aria-labelledby="navbarDropdown-myfav-order" style={{ "z-index": "999999" }}>
                                        <div className="col-md-12">
                                            <div className="text-center col-md-12">
                                                <span className="cc-help-info"><Trans>You don't have any record</Trans></span>
                                            </div>
                                        </div>
                                    </div>}
                            </li> : null}
                            {/*[10/07/21, PC_02, Bug ID - 1734, Dhevanesam R]*/}
                            {this.state.ShowFavOrder ? <li className="nav-item dropdown mt-1 fav-order-dropdown" id="FavItemLi">
                                <a className="nav-link custom-nav-link dropdown-toggle custom-dropdown-toggle text-nowrap" href="#" id="navbarDropdown-myfav-order" role="button" aria-haspopup="true" aria-expanded="false" onClick={(event) => this.headerRedirect(event, "/favorites")}><Trans>My Favorite Item</Trans> </a>
                                {this.state.favItemsCount > 0 ? <div className="dropdown-menu custom-welcome-dropdown  dropdown-style-show FavItemMenuWidth" aria-labelledby="navbarDropdown-myfav-order" style={{ "z-index": "999999" }}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <ul className="list-group FavItemWidth-ul">
                                                    {this.bindFavItems()}
                                                    {/*this.state.favItemsCount > 5 ? <li className="list-group-item border-0 mt-0 mb-0">
                                                        <div className="col-md-12 text-center border-style pb-2">
                                                            <button className="btn btn-primary custom-btn-showall primary-btn px-4" onClick={(event) => this.headerRedirect(event, "/favorites")}>Show All</button>
                                                        </div>
                                                    </li> : null*/}

                                                </ul>
                                                <div className="list-group-item border-0 mt-0 mb-0">
                                                    <div className="col-md-12 text-center border-style pb-2">
                                                        {/*[3/30/22, CAS - 31, Dhevanesam R]*/}
                                                        <button className="btn btn-primary custom-btn-showall primary-btn px-4" onClick={() => this.selectFavItem("ALL")}><Trans>Select All</Trans></button>
                                                    </div>
                                                </div>
                                                <div className="list-group-item border-0 pt-0">
                                                    {this.state.FavitemSelected == true ? <div className="popover-shopping-addcart float-left itemdetailPrice">
                                                        <span><Trans>Order Total</Trans>: {this.FavTotalCal()}</span>
                                                    </div> : null}
                                                    <div className="popover-shopping-addcart">
                                                        <button className="btn btn-primary custom-btn-primary " onClick={this.addFavItemsToCart}>
                                                            <img src="/Images/item_cart.png" className="addtocart" alt="Add to cart icon" />
                                                            <Trans>Add to Cart</Trans>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <div className="dropdown-menu  custom-welcome-dropdown dropdown-style-show dropdown-width" aria-labelledby="navbarDropdown-myfav-order" style={{ "z-index": "999999" }}>
                                        <div className="col-md-12">
                                            <div className="text-center col-md-12">
                                                <span className="cc-help-info"><Trans>You don't have any record</Trans></span>
                                            </div>
                                        </div>
                                    </div>}
                            </li> : null}

                            {this.state.hasReqApproval ? <li className="nav-item"> <a className="nav-link text-nowrap" href="#" onClick={(event) => this.headerRedirect(event, "/needquote")}><Trans>Requestor Approval</Trans>({this.state.requestorApprovalCount})</a> </li> : null}
                            {this.state.hasOrderApproval ? <li className="nav-item"> <a className="nav-link text-nowrap" href="#" onClick={(event) => this.headerRedirect(event, "/needapprove?TYPE=W&FROM=MENU")}><Trans>Order Approval</Trans>({this.state.orderApprovalCount})</a> </li> : null}
                        </ul>
                    </div>
                </nav>
                <nav className="navbar navbar-expand-lg navbar-light bg-dark-sdi-nav border-0 main-menu d-flex justify-content-between">
                    <div className="row">
                        <button className="navbar-toggler float-left display-block" type="button" id="hamburgermenu"> <span className="navbar-toggler-icon" /> </button>
                        <a className="navbar-brand ml-2 pt-0" href="#" onClick={(event) => {
                            event.preventDefault();
                            this.props.history.push({
                                pathname: '/homepage',
                            });
                        }}> <img src={localStorage.getItem("z_logo")} className="sdi-logo" alt="SDI Logo" />
                        </a>
                    </div>
                    {currentPath.toLowerCase() != "/homepage" ? <div className="row" id="SearchDiv">
                        <div className=" input-group desktop-search search-autocomplete">
                            <Autocomplete
                                getItemValue={this.getItemValue}
                                items={this.state.autocompleteData}
                                renderItem={this.renderItem}
                                value={this.state.autoCompleteValue}
                                onChange={this.onChange}
                                onSelect={this.onSelect}
                                inputProps={{ placeholder: "Search for Product", className: "form-control charge-code-control pr-5", onKeyDown: this.searchKeyPress }}

                            />
                            <span className={this.state.autoCompleteValue.trim().length > 0 ? "search-cancel-header cursor-pointer" : "d-none"} onClick={() => this.clearSearch()}></span>
                            <div className="input-group-append menu-search-group">
                                <span className="input-group-text">
                                    <span className="shopping-search-icon" onClick={this.searchClick} />
                                </span>
                            </div>
                        </div>
                    </div> : null}
                    {/*****NEW(12/24/2020***********/}

                    {/*****END***********/}
                    {/*[PC_04, 09/07/21], Author - Priyanka D */}
                    {/*[10/26/21, PC_03, IE 11 issue, Dhevanesam R]*/}
                    <div className="row" id="RightDiv">
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item dropdown custom-display"> <a className="nav-link dropdown-toggle sdi-logged-user user-dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" > <Trans>Welcome</Trans>! {displayName} </a>
                                    <div className="dropdown-menu custom-welcome-dropdown  dropdown-triangle dropdown-style-show fav-page-width WelcomeArrow" aria-labelledby="navbarDropdown">
                                        <div className="col-md-12">
                                            <div className="row">
                                                {/*[04/08/22, CAS - 31, Dhevanesam R]*/}
                                                <div className="col-md-7 border-right pr-0">
                                                    <h2 className="custom-welcome-dropdown-header"><Trans>My Favorite Page</Trans></h2>
                                                    <div className="list-group custom-list-group">
                                                        {this.bindFavPages()}
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <h2 className="custom-welcome-dropdown-header"><Trans>My Account</Trans></h2>
                                                    <div className="list-group custom-list-group">
                                                        <a href="#" className="list-group-item list-group-item-action border-0 py-1 pl-0" onClick={(event) => this.headerRedirect(event, "/profile" + this.state.myAccQS)}><Trans>My Profile</Trans></a>
                                                        {/*{this.state.AnswerlabVisible ? <a href="#" className="list-group-item list-group-item-action border-0 py-1 pl-0" onClick={(event) => {
                                                            event.preventDefault();
                                                            window.open('https://sdiextest.atlassian.net/servicedesk/customer/portals')
                                                        }}><Trans>Answer Lab</Trans></a> : null}*/}
                                                        {/*[10/07/21, PC_01, Bug ID - 1748, Dhevanesam R]*/}
                                                        {/*[05/09/2023 -ZEUS-99 - Change the navigation URL of Help]*/}
                                                        {/* [10/10/23, Zeus 206 - Zeus 2.0 Menu structure revamp, Johnprinto ] */}
                                                        {this.state.hasHelp ? <a href="#" className="list-group-item list-group-item-action border-0 py-1 pl-0" onClick={(event) => this.PassAuthe(event)}><Trans>Help</Trans></a> : null}
                                                        <a href="#" className="list-group-item list-group-item-action border-0 py-1 pl-0" data-toggle="modal" data-target="#pricing"><Trans>Term of Purchase</Trans></a>
                                                        <a href="#" className="list-group-item list-group-item-action border-0 py-1 pl-0" onClick={this.logout}><Trans>Logout</Trans></a> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {/*[PC_04, 09/07/21, Author - Dhevanesam R, Priyanka D]*/}
                                {/*[PC_TD_10, CAS - CSS Issue in translation dropdown, Dhevanesam R*/}
                                <div className="mb-0 mt-0 TransCSS">
                                    {/*4/15/2022 Bug 2056 Lang DD is hidden behind search bar in RFQ in iPad view--Poornima S*/}
                                    <Select
                                        id="pickupstore_DD"
                                        name="PickupStoreSelected"
                                        className="DropDown-Font-Size LangDD-Z-Index"
                                        onChange={(e) => this.onLanguageHandle(e)}
                                        value={this.state.language}
                                        defaultValue={options[0]}
                                        options={options}
                                        components={{ Option: IconOption }}
                                    />
                                </div>
                                {/*[05/09/2023 - PC_OSC_2 - ZEUS-99 - Add help icon and Change the navigation URL of Help icon]*/}
                                {/*[05/11/2023 - ZEUS-99 -Swap the help icon  with shopping cart icon]*/}
                                {this.state.hasHelp ? <li className="nav-item mt-1">
                                    <a className="custom-nav-link nav-link notification-icon-arrow pl-3" href="#" id="navbarDropdown-help" role="button" onClick={(event) => this.PassAuthe(event)}><img src="/Images/Help.png" style={{ "width": "29px" }} alt="Help Icon" /></a>
                                </li> : null}
                                {/*[8/11/22, Bug - 2358, PS_MC_03, Dhevanesam R]*/}
                                <li className="nav-item mt-1">
                                    <a className="custom-nav-link nav-link dropdown-toggle notification-icon-arrow pl-3 px-1" href="#" id="navbarDropdown-notification" role="button" onClick={(event) => this.headerRedirect(event, "/usernotification")}> <img src="/Images/bell-icon.png" alt="Notification Icon" />
                                        {this.state.notificationCount != -1 ? <span className={`cart-count ${this.state.notificationCount < 10 ? 'null-count' : this.state.notificationCount > 99 ? 'threedigit-count' : ''}`} id="notification">{this.state.notificationCount > 99 ? `99+` : `${this.state.notificationCount}`}</span> :
                                            <RotatingLines
                                                strokeColor="#ffc107"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="15"
                                                visible={true}
                                            />
                                        } </a>

                                </li>
                                {/*[05/11/2023 - ZEUS-99 -Swap the help icon area with shopping cart icon]*/}
                                <li className="nav-item dropdown cart-dropdown fav-order-dropdown mt-1">
                                    {/*[8/11/22, Bug - 2358, PS_MC_03, Dhevanesam R]*/}
                                    <a className="nav-link custom-nav-link dropdown-toggle notification-icon-arrow px-1" href="#" id="navbarDropdown-shoppingcart" role="button" aria-haspopup="true" aria-expanded="false" onClick={(event) => this.headershop(event, "/shoppingcart")}><img src="/Images/sdi-shopping-cart-icon.png" alt="Shopping Cart Icon" />
                                        {this.state.cartCount != -1 ? <span className={`cart-count ${this.state.cartCount < 10 ? 'null-count' : this.state.cartCount > 99 ? 'threedigit-count' : ''}`} id="cartCount">{this.state.cartCount > 99 ? `99+` : `${this.state.cartCount}`}</span> :
                                            <RotatingLines
                                                strokeColor="#ffc107"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="15"
                                                visible={true}
                                            />
                                        } </a>
                                    <div className="dropdown-menu dropdown-menu-cart custom-welcome-dropdown custom-dropdown-style mobile-index" aria-labelledby="navbarDropdown-notification">
                                        <div className="col-md-12">
                                            {this.state.cartCount > 0 ? <div className="row">
                                                <div className="col-md-12 p-0">
                                                    <ul className="list-group FavItemWidth-ul">
                                                        {this.bindCartData()}
                                                        {/* <li className="list-group-item border-0">
                                                            {this.state.cartCount > 5 ? <div className="col-md-12 text-center border-style pb-4">
                                                                <button className="btn btn-primary custom-btn-showall primary-btn px-4" onClick={(event) => this.headerRedirect(event, "/shoppingcart")}>Show All</button>
                                                            </div> : null}
                                                        </li>*/}

                                                    </ul>
                                                    <div className="list-group-item border-0 pt-0 mt-3">
                                                        <div className="popover-shopping-addcart float-left itemdetailPrice">
                                                            <span><Trans>Order Total</Trans>: {this.OrderTotalCal()}</span><span>{this.PriceDesignation()}</span>
                                                        </div>
                                                        <div className="popover-shopping-addcart">
                                                            <button className="btn btn-primary custom-btn-primary" onClick={(event) => this.headershop(event, "/shoppingcart")}>
                                                                <img src="/Images/item_cart.png" className="addtocart mr-2" alt="Add to cart icon" />
                                                                <Trans>Go to Cart</Trans>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : <div className="text-center col-md-12">
                                                    <span className="cc-help-info"><Trans>You don't have any record</Trans></span>
                                                </div>}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {currentPath.toLowerCase() != "/homepage" ? <div className="col-md-12 input-group charge-code-group py-3 mobile-search search-autocomplete">
                    {/*4/15/2022 Bug 2056 Lang DD is hidden behind search bar in RFQ in iPad view--Poornima S*/}
                    <Autocomplete
                        getItemValue={this.getItemValue}
                        items={this.state.autocompleteData}
                        renderItem={this.renderItem}
                        value={this.state.autoCompleteValue}
                        onChange={this.onChange}
                        onSelect={this.onSelect}
                        inputProps={{ placeholder: "Search for Product", className: "form-control charge-code-control MenuSearch-Z-Index", onKeyDown: this.searchKeyPress }}
                    />
                    <span className={this.state.autoCompleteValue.trim().length > 0 ? "search-cancel-mobile" : "d-none"} onClick={() => this.clearSearch()}></span>
                    <div className="input-group-append menu-mobilesearch-group">
                        <span className="input-group-text mobile-search-icon">
                            <span className="shopping-search-icon" onClick={this.searchClick} />
                        </span>
                    </div>
                </div> : null}
                {/* [10/10/23, Zeus 206 - Remove Mega Menu for punchin users, Johnprinto ] */}
                {/*{CookieService.getLocalStorageValues("Session_PUNCHINID") != "" && CookieService.getLocalStorageValues("Session_PUNCHINID") != " " && CookieService.getLocalStorageValues("Session_PUNCHINID") != null && CookieService.getLocalStorageValues("Session_PUNCHINID") != undefined ?
                <nav className="navbar navbar-expand-lg navbar-light border-bottom bg-white py-0">
                    <div className="col-md-12 collapse navbar-collapse p-0">
                        <div className="col-md-12 p-0">
                            <ul className="row m-0 header-align p-0">
                                {this.buildMegaMenu()}
                            </ul>
                        </div>
                    </div>
                </nav> : null}*/}
                <section className="breadcrumb-section bg-white">
                    {this.state.breadCrumpDetails.length > 0 ? <nav aria-label="breadcrumb" className="d-inline-block col-md-4 float-left p-0">
                        <ol className="breadcrumb custom_breadcrumb mb-0 pl-4">
                            {/* <li class="breadcrumb-item"><a href="#">Home</a></li> */}
                            {this.bindBreadCrump()}
                        </ol>
                    </nav> : null}
                    <div className="alert-text float-right text-right col-md-8 Font-14">
                        {/*<div className="col-md-6 float-left ">
                            {CookieService.getLocalStorageValues("Session_ISACrossSiteAcc") == "Y" ?
                                <>
                                    <select className="crossSite float-right" name="selectedCrossSite" value={this.state.selectedCrossSite} onChange={this.ChangeCrossSite}>
                                        {/*<option value="Site">Select Business Unit</option>*/}
                        {/*{this.CrossSiteBuBind()}
                                    </select>
                                </> : null}
                        </div>*/}
                        {/*[PC_04, Priyanka D, Dhevanesam R, 09/17/21]Change the column class as "col-md-8 float-right pr-0" from "col-md-6 float-right pr-0" to align the test site label in single line*/}
                        {currentPath.toUpperCase() == "/HOMEPAGE" && this.state.disableWo != false ?
                            <><span class="pr-4">
                                <span class="font-14 font-medium color-sec-black">Work Order # - </span><span class="font-16 font-regular modal-inner-label-value">{this.state.ValidatedWo}</span>
                            </span>
                                <span>
                                    <span class="font-14 font-medium color-sec-black">Description - </span>{this.state.workOrderDescription != "" && this.state.workOrderDescription != null && this.state.workOrderDescription != undefined ?
                                        this.state.workOrderDescription.length < 34 ? <span class="font-16 font-regular modal-inner-label-value">{this.state.workOrderDescription}</span>
                                            : <span class="font-16 font-regular modal-inner-label-value">{this.state.workOrderDescription.substring(0, 34)}...<button type="button" class="view-more" data-toggle="modal" onClick={() => this.wodescPopup()}>View more</button></span> : null
                                    }
                                </span>
                            </>
                            :
                            <div className="col-md-9 float-right pr-0">
                                {this.state.lblTestVisible ? <span className="alert-text-title">{t(this.state.lblTest)}</span> : null}
                                {this.state.lblTestVisible ? <span className="px-2 text-nowrap">LOCALHOST _Z_ :: DEVL :: 1.0.0.33</span> : null}
                            </div>}


                    </div>
                </section>
                {currentPath.toUpperCase() == "/HOMEPAGE" && this.state.disableWo != false ?
                    <section className="bg-white">
                        <div className="alert-text float-right col-md-12 custom-alert-section-bg" style={{ 'padding-left': '42px' }}>
                            <div className="col-md-8 text-right float-right ">
                                {this.state.lblTestVisible ? <span className="alert-text-title">{t(this.state.lblTest)}</span> : null}
                                {this.state.lblTestVisible ? <span className="px-2  text-nowrap">LOCALHOST _Z_ :: DEVL :: 1.0.0.33</span> : null}
                            </div>


                        </div>
                    </section> : null}
                <div className="alert-text float-right mobile-alert-txt bg-white">
                    {/*[09/17/21, PC_01, Dhevanesam R] Check using ternary operator whether the value of Session_ISACrossSiteAcc is "Y" to show the cross site dropdown else show the business unit text label
                     [09/17/21, PC_02, Dhevanesam R] Change the css style of BU label as "color:black"*/}
                    <div className="float-left  menucrosssite">
                        {CookieService.getLocalStorageValues("Session_ISACrossSiteAcc") == "Y" ?
                            <>
                                <select className="crossSite float-left" name="selectedCrossSite" value={this.state.selectedCrossSite} onChange={this.ChangeCrossSite}>
                                    {/*<option value="Site">Select Business Unit</option>*/}
                                    {this.CrossSiteBuBind()}
                                </select>
                            </> : <span className="navbar-brand-business-unit" style={{ color: "black" }}> {CookieService.getLocalStorageValues("Session_CONAME")} </span>}
                    </div>
                    <div className="float-right menucrosssite">
                        {this.state.lblTestVisible ? <span className="alert-text-title">{t(this.state.lblTest)}</span> : null}
                        {this.state.lblTestVisible ? <span className="px-2">{this.state.lblVersion}</span> : null}
                    </div>

                </div>
            </div>
            <div className="modal" id="pricing">
                <div className="modal-dialog modal-md custom-popup modal-dialog-centered">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title mt-1"><Trans>Catalog Pricing</Trans></h4>
                            <button type="button" className="btn close-btn" data-dismiss="modal">
                                X
              </button>
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">
                            <div className="col-md-12 float-left p-0">
                                <p className="extended-content  mb-0"><Trans>In the absence of written agreement to the contrary between customer and SDI, all pricing contained in this catalog is subject to change by SDI at any time without notice.</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*work order popup starts here*/}
            <div className="modal fade work-order-info" id="work-order-details-menu" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog custom-work-order-width modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header sp-delv-header">
                            <h6 className="modal-title font-medium font-16" id="exampleModalLongTitle">Work Order Info</h6>
                            <button type="button" className="btn close-btn" data-dismiss="modal">
                                X
              </button>
                        </div>
                        <div className="modal-body py-4">
                            <div className="row">
                                {/*work order starts here*/}
                                <div className="col-md-12 mb-3">
                                    <label className="font-16 font-medium w-100 color-sec-black">Work Order #</label>
                                    <label className="font-16 font-regular modal-inner-label-value">{this.state.ValidatedWo}</label>
                                </div>
                                <div className="col-md-12">
                                    <label className="font-16 font-medium w-100 color-sec-black">Description</label>
                                    <label className="font-16 font-regular modal-inner-label-value">{this.state.workOrderDescription}</label>
                                </div>
                                {/*work order ends here*/}
                            </div>
                        </div>
                        {/*footer starts here*/}
                        <div className="modal-footer d-flex justify-content-center border-0 pt-0 pb-4">
                            <button type="button" className="btn custom-btn-primary px-4 font-16 font-medium" data-dismiss="modal">OK</button>
                        </div>
                        {/*footer starts here*/}
                    </div>
                </div>
            </div>
            {/*work order popup end here*/}
        </React.Fragment>);
    };
};
//[PC_07, 09/09/21, Author -  Priyanka D]
export default withTranslation()(MenuComponent);