
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class ChangePasswordStore extends EventEmitter {
    ChangePwdList = "";
    handleChangePasswordStore = (action) => {
        switch (action.action.type) {
            case "ChangePwd": {
                this.ChangePwdList = action.action.response;
                this.emit('ChangePwd');
                break;
            }
        }
    }

}

const objChangePasswordStore = new ChangePasswordStore;
Dispatcher.register(objChangePasswordStore.handleChangePasswordStore.bind(objChangePasswordStore));
export default objChangePasswordStore;
