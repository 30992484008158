
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class PunchinStore extends EventEmitter {
    PunchinData = "";
    loginSuccess = "";
    loginError = "";
    /**
    * handleLoginStore method takes action as parameter.
    * Based on the action we will dispatch the data from the action.
    */
    handlePunchinStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.punchinload: {
                this.PunchinData = action.action.response;
                this.emit('load');
                break;
            }
            case "tokenupdate": {
                this.loginSuccess = action.action.response;
                this.emit('success');
                this.emit('contactChange');//[Zeus 208, 11/24/2023] - Footer component not rendered bug fix - Kishore
                break;
            }
            case "Login_Error": {
                this.loginError = action.action.response;
                this.emit('error');
                break;
            }
        }
    }
}

const objPunchinStore = new PunchinStore;
// Dispatcher code
Dispatcher.register(objPunchinStore.handlePunchinStore.bind(objPunchinStore));
export default objPunchinStore;