import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class ReceiveItemsStore extends EventEmitter {
    //[09/22/21, PC_11 Dhevanesam R, Priyanks D]
    GetData = "";
    PageLoadData = "";
    PODetailData = "";
    PassThroughAuthe = "";
    SumbitReceive = "";
    updateFavPageResponse = { updateType: 0, responseStr: "" };
    handleMMReceivingStore = (action) => {
        switch (action.action.type) {
            case SDIConstants.methodNames.GetData: {
                debugger;
                this.GetData = action.action.response;
                debugger;
                this.emit('GetData');
                break;
            }
            case SDIConstants.methodNames.ReceivingPageLoad: {
                this.PageLoadData = action.action.response;
                this.emit('ReceivingPageLoad');
                break;
            }
            case SDIConstants.methodNames.getPODetails: {
                this.PODetailData = action.action.response;
                debugger;
                this.emit('GetPODetails');
                break;
            }
            case SDIConstants.methodNames.submitReceiving: {
                this.SumbitReceive = action.action.response;
                debugger;
                this.emit('SumbitReceiving');
                break;
            }
            //[11/29/23, Regression-6 Bug (4364) - Added cases for Fav icon for pageload, insert & delete - Harshitha G]
            case "Receive_CurrentFavPage": {
                this.currentFavPageResponse = action.action.response;
                this.emit('favpage');
                break;
            }
            case "Receive_InsertFavPage": {
                this.updateFavPageResponse.updateType = 1;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }
            case "Receive_DeleteFavPage": {
                this.updateFavPageResponse.updateType = 2;
                this.updateFavPageResponse.responseStr = action.action.response;
                this.emit('updateFavPage');
                break;
            }

        }
    }
}
const MMreceivingStore = new ReceiveItemsStore;
// Dispatcher code
Dispatcher.register(MMreceivingStore.handleMMReceivingStore.bind(MMreceivingStore));
export default MMreceivingStore;