import * as $ from 'jquery';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Select from 'react-select';
import swal from 'sweetalert';
import * as ManualStkAdjAction from '../actions/ManualStkAdjAction';
import * as MenuAction from '../actions/MenuAction';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import ManualStkAdjStore from '../stores/ManualStkAdjStore';
let currentPagePath;

class ManualStkAdjComponent extends Component {
    constructor(props) {

        super(props);
        this.searchBtnClickResponse = this.searchBtnClickResponse.bind(this);
        this.submitBtnClickResponse = this.submitBtnClickResponse.bind(this);
        this.state = {
            itemID: "",
            itemIDError: "",
            includeZeroQty: false,
            itemDescription: "",
            gridData: [],
            adjTypeOptions: [{ "label": "Increase Inventory", "value": "I" },
            { "label": "Decrease Inventory", "value": "D" }],
            adjType: "",
            reasonOptions: [],
            reason: "",
            adjQuantity: "",
            notes: "",
            adjTypeError: "",
            reasonError: "",
            adjQuantityError: "",
            noterError: "",
            isSuccessResponse: "FALSE",
            isValidToSubmit: true,
            gridDatawithZeroQty: [],
            gridDatawithoutZeroQty: [],
            QRError: false
        }
    }
    /**         
    * PS_ZE_252_APP_10/Sathis/02/01/2024
    * Invoke this method on the click of Manual stock adjustment from menu bar.
    * */
    componentDidMount() {
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let cribID = CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT");
        if (cribID == undefined || cribID == null || cribID.trim() == "") {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please select crib from user profile"
            });
            this.props.history.push({
                pathname: `/profile`,
                state: { origin: 'Inventory' }
            });
            return;
        }
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        ManualStkAdjAction.getCurrentFavPage(getFavPageBO);
        ManualStkAdjStore.on('search', this.searchBtnClickResponse.bind(this));
        ManualStkAdjStore.on('submit', this.submitBtnClickResponse.bind(this));
        ManualStkAdjStore.on('favpage', this.loadFavPageResponse.bind(this));
        ManualStkAdjStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    /**         
    * PS_ZE_252_APP_10/Sathis/02/01/2024
    * Invoke this method while redirect to other panels from Manual stock adjustment.
    * */
    componentWillUnmount() {
        ManualStkAdjStore.removeListener('search', this.searchBtnClickResponse.bind(this));
        ManualStkAdjStore.removeListener('submit', this.submitBtnClickResponse.bind(this));
        ManualStkAdjStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        ManualStkAdjStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    /****************************************************************Action methods starts here***********************************************************************/
    /**         
   * PS_ZE_219_APP_14/Sathis/02/01/2024
   * This method invokes on the click of search button
   * */
    handleSearchBtnClick = (isFromSubmit) => {
        if (this.state.gridData.length == 0 || isFromSubmit) {
            if (this.state.itemID != "") {
                let searchBO = {
                    businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                    itemID: this.state.itemID.toUpperCase().trim(),
                    userID: CookieService.getLocalStorageValues("Session_UserID"),
                    cribID: CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT"),
                    websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID")
                }
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                ManualStkAdjAction.searchBtnClickAction(searchBO)
            }
            else {
                this.setState({ itemIDError: "Please enter the item ID" })
                $("#item-id").addClass('validation')
            }
        }
    }
    /**         
     * PS_ZE_219_APP_17/Sathis/02/01/2024
     * This method invokes to handle seaarch response
     * */
    searchBtnClickResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(ManualStkAdjStore.searchResponse);
        let errorDt = response.errorDt
        let itemDescDt = response.itemDescDt
        let physicalInvDt = response.physicalInvDt
        let reasonDt = response.reasonDt
        if (errorDt[0].errorMessage.toUpperCase() == "FALSE") {
            let itemDescription = itemDescDt[0].ITEMDESCRIPTION
            if (itemDescription != undefined && itemDescription != null && itemDescription != "") {
                if (physicalInvDt != undefined && physicalInvDt != null && physicalInvDt != "") {
                    let gridDatawithoutZeroQty = []
                    let reasonOptions = []
                    if (physicalInvDt.length > 0) {
                        physicalInvDt.forEach((value, index) => {
                            if (value.QUANTITYAVAIL > 0) {
                                gridDatawithoutZeroQty.push(value)
                            }
                        })
                    }

                    if (reasonDt != undefined && reasonDt != null && reasonDt != "") {
                        reasonDt.forEach((value, index) => {
                            let locObj = {
                                label: value.DESCR,
                                value: value.REASON_CD
                            }
                            reasonOptions.push(locObj)
                        })
                    }
                    this.setState({
                        itemDescription, gridDatawithoutZeroQty, gridDatawithZeroQty: physicalInvDt, reasonOptions,
                        gridData: this.state.includeZeroQty ? physicalInvDt : gridDatawithoutZeroQty
                    })
                    if (physicalInvDt.length > 0 && gridDatawithoutZeroQty.length == 0) {
                        swal({
                            allowOutsideClick: true,
                            closeOnClickOutside: true,
                            text: t('Item with zero quantity is only available in the crib')
                        });
                        this.setState({ gridData: physicalInvDt, includeZeroQty: true })
                    }
                }
                else {
                    this.setState({ itemIDError: "Item is not available in crib" })
                    $("#item-id").addClass('validation')
                }

            }
            else {
                this.setState({ itemIDError: "Please enter the valid item ID" })
                $("#item-id").addClass('validation')
            }
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

    }
    /**         
     * PS_ZE_252_APP_22/Sathis/02/01/2024
     * This method invokes on the click of submit button
     * */
    handleSubmitBtnClick = () => {
        let isValidToSubmit = true
        if (this.state.adjType == "") {
            isValidToSubmit = false
            this.setState({ adjTypeError: "Please select adjustment type" })
            $("#adjType").addClass('mstk-dropdown')
        }
        if (this.state.reason == "") {
            isValidToSubmit = false
            this.setState({ reasonError: "Please select reason" })
            $("#reason").addClass('mstk-dropdown')
        }
        if (this.state.adjQuantity == "") {
            isValidToSubmit = false
            this.setState({ adjQuantityError: "Please enter quantity" })
            $("#adjQuantity").addClass('validation')
        }
        if (this.state.adjQuantityError == "" && this.state.notesError == "" && isValidToSubmit) {
            let selectedValue = this.state.gridData[this.state.selectedIndex]
            let submitBO = {
                itemID: this.state.itemID.toUpperCase(),
                businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                userID: CookieService.getLocalStorageValues("Session_UserID"),
                websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                selectedBU: selectedValue.BUSINESS_UNIT,
                reason: this.state.reason.value,
                notes: this.state.notes,
                adjQuantity: this.state.adjQuantity,
                adjType: this.state.adjType.value,
                area: selectedValue.STORAGE_AREA,
                aisle: selectedValue.STOR_LEVEL_1,
                row: selectedValue.STOR_LEVEL_2,
                shelf: selectedValue.STOR_LEVEL_3,
                bin: selectedValue.STOR_LEVEL_4,
                UOM: selectedValue.UNIT_OF_MEASURE,
                serialID: selectedValue.SERIAL_ID,
                qtyAvailable: selectedValue.QTY
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            ManualStkAdjAction.submitBtnClickAction(submitBO)
        }
    }
    /**         
    * PS_ZE_252_APP_25/Sathis/02/01/2024
    * This method invokes to handle submit button response
    * */
    submitBtnClickResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(ManualStkAdjStore.submitResponse);
        let flagDt = response.flagDt
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (flagDt[0].errorMessage.toUpperCase() == "FALSE") {
            let isSuccess = flagDt[0].isSuccess
            this.setState({ isSuccessResponse: isSuccess });
            $(`#editAdj-popup`).modal('hide')
            $(`#mstk-success-popup`).modal('show')
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
    }
    /**         
  * PS_ZE_252_APP_27/Sathis/02/01/2024
  * This method invokes to handle QR code scanner.
  * */
    handleQRScan = (err, data) => {

        if (data != undefined && data != null) {
            this.setState({ itemID: data.text, QR: false, itemIDError: "" })
            $("#item-id").removeClass('validation')
            $(`#QR_Scanner_ManualPopup`).modal('hide')
            this.handleSearchBtnClick(false)
        } else {
            console.log("QR_ERROR", err);
        }
    }

    handleQRScanError = () => {

        this.setState({ QRError: true })
    }

    handleOpenQR = () => {
        this.setState({ QR: true })
    }

    handleCloseQR = () => {
        this.setState({ QR: false })
    }
    /**         
  * PS_ZE_252_APP_20/Sathis/02/01/2024
  * This method invokes to handle edit icon click.
  * */
    handleEditBtnClick = (index) => {
        $(`#editAdj-popup`).modal('show')
        this.setState({
            selectedIndex: index, adjQuantity: "", adjType: "", notes: "", reason: "",
            adjQuantityError: "", adjTypeError: "", notesError: "", reasonError: ""
        })
        $("#adjQuantity").removeClass('validation')
        $("#adjType").removeClass('mstk-dropdown')
        $("#notes").removeClass('validation')
        $("#reason").removeClass('mstk-dropdown')
    }

    handleOkBtnClick = () => {
        if (this.state.isSuccessResponse.toUpperCase() == "TRUE") {
            this.handleSearchBtnClick(true)
        }
    }

    /***********************************************************Fields onchange handling methods starts here************************************************************/
    /**         
    * PS_ZE_252_APP_19/Sathis/02/01/2024
    * This method invokes to handle drop down changes(reason and adjustment Type)
    * */
    handleOnChangeDD = (event, nameStr) => {
        let tempName = nameStr + "Error";
        this.setState({ [nameStr]: event, [tempName]: "" })
        $(`#${nameStr}`).removeClass('mstk-dropdown');
        if (nameStr == "adjType" && this.state.adjQuantity != "") {
            let remainingQty = this.state.gridData[this.state.selectedIndex].QUANTITYAVAIL
            if (event.value == "D") {
                if (this.state.adjQuantity > remainingQty) {
                    this.setState({ adjQuantityError: "Adjustment qty should not be greater than available qty" })
                    $("#adjQuantity").addClass('validation')
                }
                else {
                    this.setState({ adjQuantityError: "" })
                    $("#adjQuantity").removeClass('validation')
                }
            }
            else {
                this.setState({ adjQuantityError: "" })
                $("#adjQuantity").removeClass('validation')
            }

        }
    }

    /**         
     * PS_ZE_252_APP_18/Sathis/02/01/2024
     * This method invokes to handle check box
     * */
    handleOnChangeCheckBox = (event) => {
        if (event.target.checked == true) {
            this.setState({ includeZeroQty: true, gridData: this.state.gridDatawithZeroQty })
        }
        else {
            this.setState({ includeZeroQty: false, gridData: this.state.gridDatawithoutZeroQty })
        }
    }
    /**         
    * PS_ZE_252_APP_13/Sathis/02/01/2024
    * This method invokes to handle text field changes(itemd ID, adjustment Qty and notes)
    * */

    handleOnChangeText = (event, name) => {
        let value = event.target.value
        if (name == "itemID") {
            if (value != "") {
                this.setState({ itemID: value, itemIDError: "" })
                $("#item-id").removeClass('validation')
            }
            else {
                this.setState({ itemID: value, itemIDError: "Please enter the item ID" })
                $("#item-id").addClass('validation')
            }
        }
        else if (name == "adjQuantity") {
            let regex = SDIConstants.quantityRegEX
            let remainingQty = this.state.gridData[this.state.selectedIndex].QUANTITYAVAIL
            if (value != "") {
                if (regex.test(value)) {
                    let quantity = Math.round(value)
                    if (quantity != "0") {
                        if (this.state.adjType != "" && this.state.adjType.value == "D") {
                            if (quantity > remainingQty) {
                                this.setState({ adjQuantity: value, adjQuantityError: "Adjustment qty should not be greater than available qty" })
                                $("#adjQuantity").addClass('validation')
                            }
                            else {
                                this.setState({ adjQuantity: value, adjQuantityError: "" })
                                $("#adjQuantity").removeClass('validation')
                            }
                        }
                        else {
                            this.setState({ adjQuantity: value, adjQuantityError: "" })
                            $("#adjQuantity").removeClass('validation')
                        }
                    }
                }
            }
            else {
                this.setState({ adjQuantity: value, adjQuantityError: "Please enter quantity" })
                $("#adjQuantity").addClass('validation')
            }
        }
        else if (name == "notes") {
            let specialCharRegex = /^[A-Za-z0-9 ]+$/
            if (value != "") {
                if (specialCharRegex.test(value)) {
                    this.setState({ notes: value, notesError: "" })
                    $("#notes").removeClass('validation')
                }
                else {
                    this.setState({ notes: value, notesError: "Please remove special characters" })
                    $("#notes").addClass('validation')

                }
            }
            else {
                this.setState({ notes: value, notesError: "" })
                $("#notes").removeClass('validation')
            }
        }

    }

    /**         
    * PS_ZE_252_APP_29/Sathis/02/01/2024
    * This method invokes to handle clear icon click.
    * */
    handleClear = () => {
        if (this.state.gridData.length == 0) {
            $("#item-id").addClass('validation');
        }
        this.setState({
            itemID: "", gridData: [], itemIDError: this.state.gridData.length > 0 ? "" : "Please enter the item ID",
            gridDatawithZeroQty: [], gridDatawithoutZeroQty: []
        });
    }

    /**         
    * PS_ZE_252_APP_30/Sathis/02/01/2024
    * This method invokes to handle enter button click from item ID.
    * */
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.handleSearchBtnClick(false);
        };
    };

    /****************************************************************Favorites handle starts from here***********************************************************************/

    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Manual Stock Adjustment",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            ManualStkAdjAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            ManualStkAdjAction.deleteFavPage(deleteFavPageBO)
        }
    };
    loadFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        let favPageResponse = JSON.parse(ManualStkAdjStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (ManualStkAdjStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(ManualStkAdjStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(ManualStkAdjStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };

    /****************************************************************HTML parts starts from here***********************************************************************/
    /**         
    * PS_ZE_252_APP_26/Sathis/02/01/2024
    * This method invokes to bind item details grid.
    * */
    bindGridData = () => {
        if (this.state.gridData.length > 0) {
            return this.state.gridData.map((value, index) => {
                let QtyWhole = parseInt(value.QTY);
                let QtyAvailWhole = parseInt(value.QUANTITYAVAIL);
                return (<React.Fragment key={index}>
                    <tr>
                        <td >{value.BUSINESS_UNIT}</td>
                        <td >{QtyWhole}</td>
                        <td >{QtyAvailWhole}</td>
                        <td >{value.SERIAL_ID}</td>
                        <td >{value.STORAGE_AREA}</td>
                        <td >{value.STOR_LEVEL_1}</td>
                        <td >{value.STOR_LEVEL_2}</td>
                        <td >{value.STOR_LEVEL_3}</td>
                        <td >{value.STOR_LEVEL_4}</td>
                        <td >{value.PRICE}</td>
                        <td className="text-nowrap text-center"><img src="images/edit-icon.svg" alt="edit" onClick={(e) => this.handleEditBtnClick(index)} /></td>
                    </tr>
                </React.Fragment>
                );
            })

        }
    }

    /**         
    * PS_ZE_252_APP_26/Sathis/02/01/2024
    * This method invokes to bind HTML.
    * */
    render() {
        const { t } = this.props;
        return (
            <>
                <div className="container-fluid content-section" >
                    <div className="row">
                        {/*Page Heading starts here*/}
                        <div className="col-md-12 mt-3 mb-4 page-heading">
                            <h5 className="page-title"><Trans>Manual Stock Adjustment</Trans></h5>
                            <div className="fav-item-maintenance-icon mt-1">
                                <input type="checkbox" id="checkbox-1" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                <label htmlFor="checkbox-1">
                                    <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                        <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                            <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                            <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                            <g className="grp7" opacity={0} transform="translate(7 6)">
                                                <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                            </g>
                                            <g className="grp6" opacity={0} transform="translate(0 28)">
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                            </g>
                                            <g className="grp3" opacity={0} transform="translate(52 28)">
                                                <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                            </g>
                                            <g className="grp2" opacity={0} transform="translate(44 6)">
                                                <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp5" opacity={0} transform="translate(14 50)">
                                                <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp4" opacity={0} transform="translate(35 50)">
                                                <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                            </g>
                                            <g className="grp1" opacity={0} transform="translate(24)">
                                                <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                            </g>
                                        </g>
                                    </svg>
                                </label>
                            </div>
                        </div>
                        {/*Page Heading ends here*/}
                        {/*form starts here*/}
                        <div className="col-md-12">
                            <div className="row align-items-end">
                                <div className="col-md-4 col-9 col-sm-9 col-lg-3">
                                    <label className="form-label catalog-label text-nowrap" htmlFor="item-id"><Trans>Item ID or QR</Trans> </label>
                                    <input type="text" className="form-control" id="item-id" placeholder={t("Enter item ID or scan QR")} maxLength="18" value={this.state.itemID} style={{ padding: '0px 57px 0px 10px' }} onKeyDown={(e) => this.searchKeyPress(e)} onChange={(e) => this.handleOnChangeText(e, "itemID")} disabled={this.state.gridData.length > 0 ? true : false} />
                                    {this.state.itemID != "" ?
                                        < span class="mstk-cancel-header cursor-pointer" onClick={() => this.handleClear()} > </span> : null}
                                    <span
                                        onClick={() => this.handleSearchBtnClick(false)}
                                        className="mstk-validate-icon"
                                        id="mstk-search"
                                    />  {this.state.itemIDError != "" ?
                                        <span className="font-12 font-regular color-validation"><Trans>{this.state.itemIDError}</Trans></span> : null}
                                </div>
                                {this.state.gridData.length == 0 ?
                                    <div className="col-md-8 col-3 col-sm-3 col-lg-9 custom-border-left mt-1">
                                        <img className={`mt-2 ${this.state.itemIDError != "" ? 'mb-3' : ''}`} src="images/qr-code.svg" alt="qr-code" id="mstk-qr" data-toggle="modal" data-target="#QR_Scanner_ManualPopup" onClick={() => this.handleOpenQR()} />
                                    </div> : null}
                            </div>
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                            {/*<div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="zero-qty" checked={this.state.includeZeroQty} onChange={(e) => this.handleOnChangeCheckBox(e)} />
                                <label className="form-check-label font-14 font-regular" htmlFor="zero-qty">Include zero qty loacations</label>
                            </div>*/}
                            <div className="custom-control custom-checkbox item-custom-control mt-2">
                                <input type="checkbox" className="custom-control-input" id="zero-qty" checked={this.state.includeZeroQty} onChange={(e) => this.handleOnChangeCheckBox(e)} />
                                <label className="custom-control-label" htmlFor="zero-qty"><Trans>Include zero quantity locations</Trans></label>
                            </div>
                        </div>
                        {/*form ends here*/}
                        {/*Item Details Grid*/}
                        {this.state.gridData.length > 0 ?
                            <div className="col-md-12 mt-3">
                                {/*<h5 className="font-14 font-bold">Item details</h5>*/}
                                <h5 className="fav-item-title"><Trans>Item details</Trans></h5>
                                <div className="w-100 mb-2">
                                    {/*<span className="font-14 font-medium">Description :</span>
                                    <span className="font-14 font-regular ml-1">{this.state.itemDescription}</span>*/}
                                    <span className="catalog-label"><Trans>Description</Trans>:</span>
                                    <span className="item-details-value ml-1">{this.state.itemDescription}</span>
                                </div>
                                <div className="table-responsive mb-3">
                                    <table className="table split-delv-table">
                                        {/*<table className="table split-delv-table">*/}
                                        <thead className="sticky-top">
                                            <tr>
                                                <th><Trans>BU</Trans></th>
                                                <th><Trans>Qty</Trans></th>
                                                <th><Trans>Qty available</Trans></th>
                                                <th><Trans>Serial ID</Trans></th>
                                                <th><Trans>Area</Trans></th>
                                                <th><Trans>Aisle</Trans></th>
                                                <th><Trans>Row</Trans></th>
                                                <th><Trans>Shelf</Trans></th>
                                                <th><Trans>Bin</Trans></th>
                                                <th><Trans>Unit cost</Trans></th>
                                                <th className="text-center"><Trans>Action</Trans></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.bindGridData()}
                                        </tbody>
                                    </table>
                                </div>
                            </div> : null}
                        {/*Item Details Grid*/}
                    </div>
                </div>

                {/*Modal Starts here*/}
                <div className="modal fade edit-adjustment" id="editAdj-popup" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered edit-adjustment-custom-width" role="document">
                        <div className="modal-content">
                            <div className="modal-header sp-delv-header align-items-center">
                                <h5 className="modal-title" id="exampleModalLongTitle"><Trans>Edit adjustment</Trans></h5>
                                <button type="button" className="btn close-btn" data-dismiss="modal" >
                                    X
                                </button>
                            </div>
                            {/*Form Starts here*/}
                            <div className="modal-body modal-overflow">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label catalog-label w-100 mandatory-after" htmlFor="adjustment-type"><Trans>Adjustment type</Trans> </label>
                                        <Select
                                            options={this.state.adjTypeOptions}
                                            value={this.state.adjType}
                                            onChange={(e) => this.handleOnChangeDD(e, "adjType")}
                                            name="adjType"
                                            id="adjType"
                                        />
                                        {this.state.adjTypeError != "" ? <span className="font-12 font-regular color-validation"><Trans>{this.state.adjTypeError}</Trans></span> : null}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label catalog-label w-100 mandatory-after" htmlFor="reason"><Trans>Reason</Trans> </label>
                                        <Select
                                            options={this.state.reasonOptions}
                                            value={this.state.reason}
                                            onChange={(e) => this.handleOnChangeDD(e, "reason")}
                                            name='reason'
                                            id='reason'
                                        />
                                        {this.state.reasonError != "" ? <span className="font-12 font-regular color-validation"><Trans>{this.state.reasonError}</Trans></span> : null}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label catalog-label mandatory-after" htmlFor="adjQuantity"><Trans>Adjust quantity</Trans> </label>
                                        <input type="text" className="form-control" id="adjQuantity" name="adjQuantity" placeholder={t("Enter quantity")} maxLength="8" value={this.state.adjQuantity} onChange={(e) => this.handleOnChangeText(e, "adjQuantity")} />
                                        {this.state.adjQuantityError != "" ? <span className="font-12 font-regular color-validation"><Trans>{this.state.adjQuantityError}</Trans></span> : null}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label catalog-label" htmlFor="notes"><Trans>Notes</Trans></label>
                                        <input type="text" className="form-control" id="notes" name="notes" placeholder={t("Enter notes")} maxLength="12" value={this.state.notes} onChange={(e) => this.handleOnChangeText(e, "notes")} />
                                        {this.state.notesError != "" ? <span className="font-12 font-regular color-validation"><Trans>{this.state.notesError}</Trans></span> : null}
                                    </div>
                                </div>
                            </div>
                            {/*Form ends here*/}
                            {/*button*/}
                            <div className="modal-footer border-0 pt-1 mb-2">
                                <button className="btn btn-secondary custom-btn-secondary secondary-btn mr-2 mb-2 px-4 py-2" data-dismiss="modal"><Trans>Cancel</Trans></button>
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" onClick={() => this.handleSubmitBtnClick()}><Trans>Submit</Trans></button>
                            </div>
                            {/*button*/}
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

                {/*Modal Starts here*/}
                <div className="modal fade success-customer" id="mstk-success-popup" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12 col-sm-12  col-md-12 col-lg-12 text-center">
                                        {this.state.isSuccessResponse.toUpperCase() == "TRUE" ?
                                            <><img className="mb-3" src="images/tick.svg" alt="tick" />
                                                <p className="mb-1 font-13 font-medium color-light-black"><Trans>Stock adjustment updated successfully!</Trans></p></> :
                                            <><img className="mb-3" src="images/alert-Icon.svg" alt="alert" />
                                                <p className="mb-1 font-13 font-medium color-light-black"><Trans>Stock adjustment updation failed!</Trans></p></>}
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            {/*button*/}
                            <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={() => this.handleOkBtnClick()} ><Trans>OK</Trans></button>
                            </div>
                            {/*button*/}
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

                <div className="modal p-0" id="QR_Scanner_ManualPopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg custom-popup modal-dialog-centered">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <h4 className="modal-title mt-1" ><Trans>QR scan</Trans></h4>
                                <button type="button" className="btn close-btn" data-dismiss="modal" onClick={() => this.handleCloseQR()} >
                                    X
              </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row mt-3">
                                    <div className="col-12 text-center  mb-4">
                                        {this.state.QRError == true ?
                                            <><img className="mt-2" src="images/qr-scan-camera.svg" alt="qr-code-camera" />
                                                <p className="mb-1 font-18 mt-5 font-medium color-light-black"><Trans>Please allow your browser to access your webcam</Trans></p>
                                                <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                                    <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal" onClick={() => this.handleCloseQR()}><Trans>OK</Trans></button>
                                                </div>
                                            </> :
                                            this.state.QR == true ?
                                                <BarcodeScannerComponent
                                                    width="100%"
                                                    height={500}
                                                    onUpdate={this.handleQRScan}
                                                    facingMode={"environment"}
                                                    onError={this.handleQRScanError}

                                                /> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}
export default withTranslation()(ManualStkAdjComponent);