
import React, { Component } from 'react';
import * as RMAAction from '../actions/RMAAction';
import RMAStore from '../stores/RMAStore';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import * as MenuAction from '../actions/MenuAction';
import { withTranslation, Trans } from 'react-i18next'
import Select from 'react-select';
import swal from 'sweetalert';
let currentPagePath;
let reasonChangedArr = []

const options = [
    {
        value: '', label: 'Select'
    },
    {
        value: 'vm.ORDER_NO', label: 'Order number'
    },
    {
        value: 'vm.PRODUCT_ID', label: 'Product ID'
    },
    {
        value: 'lin.ISA_EMPLOYEE_ID', label: 'Employee ID'
    },
    {
        value: 'vm.SERIAL_ID', label: 'Serial ID'
    },
    {
        value: 'vm.DESCR', label: 'Description'
    },
    {
        value: 'lin.ISA_CUST_CHARGE_CD', label: 'Charge code'
    },
    {
        value: 'lin.ISA_WORK_ORDER_NO', label: 'Work order'
    }
]

const backgout = {
    backgroundColor: "white",
}
class RMAComponent extends Component {
    /**
    * RR_PC_02
    * Define the constructor that takes props as parameter
    * 
    * @param props
    */


    constructor(props) {

        super(props);
        this.pageLoadResponse = this.pageLoadResponse.bind(this);
        this.searchBtnClickResponse = this.searchBtnClickResponse.bind(this);
        this.saveButtonClickResponse = this.saveButtonClickResponse.bind(this);
        this.state = {
            gridData: [],
            businessUnitDD: [],
            employeeDD: [],
            businessUnitLabel: "",
            searchByOptions: options,
            radioContainsAny: true,
            radioContainsAll: false,
            startDate: "",
            endDate: "",
            criteria: "",
            employeeID: "",
            searchBy: "",
            totalCount: 0,
            reqCount: 10,
            pageCount: 0,
            reasonError: "",
            isFavorited: false,
            sortColumn: "vm.ORDER_NO",
            sortValue: "DESC",
            isPageLoad: true,
            selectedBusinessUnit: "",
            pageNo: 1,
            businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            isQtyenteredError: [],
            searchClick: false,
            reasonOptionsDD: []
        }
    };


    componentDidMount() {
        if (this.state.businessUnit == 'I0631') {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: "Access denied"
            });
            this.props.history.push({
                pathname: '/homepage',
                state: { origin: 'Inventory' }
            });

            return;
        }
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        this.pageLoad(true, this.state.businessUnit);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        };
        RMAAction.getCurrentFavPage(getFavPageBO);
        RMAStore.on('pageLoad', this.pageLoadResponse.bind(this));
        RMAStore.on('search', this.searchBtnClickResponse.bind(this));
        RMAStore.on('submit', this.saveButtonClickResponse.bind(this));
        RMAStore.on('reasonSelect', this.reasonSelectResponse.bind(this));
        RMAStore.on('favpage', this.loadFavPageResponse.bind(this));
        RMAStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    componentWillUnmount() {
        RMAStore.removeListener('pageLoad', this.pageLoadResponse.bind(this));
        RMAStore.removeListener('search', this.searchBtnClickResponse.bind(this));
        RMAStore.removeListener('submit', this.saveButtonClickResponse.bind(this));
        RMAStore.removeListener('reasonSelect', this.reasonSelectResponse.bind(this));
        RMAStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        RMAStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }
    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(RMAStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };
    updateFavPageResponse = () => {
        if (RMAStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(RMAStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(RMAStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };
    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Return to Inventory",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            RMAAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            RMAAction.deleteFavPage(deleteFavPageBO)
        }
    };
    /**         
    * PS_ZE_219_APP_15/Sathis/12/25/2023
    * Invoke this method to get page load data
    * */

    pageLoad = (isPageLoad, businessUnit) => {
        let endDate = new Date();
        let startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);
        this.setState({ startDate, endDate, isPageLoad, businessUnit });
        let pageLoadBO = {
            businessUnit: businessUnit,
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            isPageLoad: isPageLoad
        }
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        RMAAction.pageLoadAction(pageLoadBO);
    }

    /**         
    * PS_ZE_219_APP_18/Sathis/12/25/2023
    * This method invokes to handle page load response
    * */
    pageLoadResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RMAStore.pageLoadResponse);
        let errorDt = response.errorDt;
        let businessUnitDt = response.businessUnitDt;
        let employeeDt = response.employeeDt;
        let reasonOptionsDt = response.reasonOptionsDt;

        if (errorDt == undefined || errorDt == null) {

            let businessUnitDD = [];
            let employeeDD = [{
                value: "",
                label: "Select"
            }];
            let reasonOptionsDD = [{
                value: "",
                label: "Select"
            }];
            if (this.state.isPageLoad == true) {
                if (businessUnitDt != undefined && businessUnitDt != null && businessUnitDt != "") {
                    if (businessUnitDt.length > 0) {
                        businessUnitDt.forEach((value, index) => {
                            let locObj = {
                                value: value.ISA_BUSINESS_UNIT,
                                label: value.DESCRIPT
                            }
                            businessUnitDD.push(locObj);
                        });
                        this.setState({ businessUnitDD, businessUnitLabel: businessUnitDD[0].label })
                    }
                }
                if (reasonOptionsDt != undefined && reasonOptionsDt != null && reasonOptionsDt != "") {
                    if (reasonOptionsDt.length > 0) {
                        reasonOptionsDt.forEach((value, index) => {
                            let locObj = {
                                value: value.REASON_CD,
                                label: value.DESCR50
                            }
                            reasonOptionsDD.push(locObj);
                        });
                        this.setState({ reasonOptionsDD })
                    }
                }
            }

            if (employeeDt != undefined && employeeDt != null && employeeDt != "") {
                if (employeeDt.length > 0) {
                    employeeDt.forEach((value, index) => {
                        let locObj = {
                            value: value.ISA_EMPLOYEE_ID,
                            label: value.EMP_NAME
                        }
                        employeeDD.push(locObj);
                    });
                    this.setState({ employeeDD })
                }
            }
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            }).then(() => {
                window.location.href = '/';
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }


    /**         
    * PS_ZE_219_APP_22/Sathis/12/25/2023
    * This method invokes to handle reason select response
    * */
    reasonSelectResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RMAStore.reasonSelectResponse);
        let errorDt = response.errorDt
        let flagDt = response.flagDt
        if (errorDt == undefined || errorDt == null) {
            let index = flagDt[0].index
            let error = flagDt[0].msg
            let returnBU = flagDt[0].returnToBU
            let returnType = flagDt[0].returnType
            let gridData = [...this.state.gridData];
            if (error != undefined && error != null && error != "") {
                gridData[index] = { ...gridData[index], reason: "" };
                this.setState({ reasonError: error });
            }
            else {

                gridData[index] = { ...gridData[index], returnType: returnType, returnToBU: returnBU, changed: true };
                reasonChangedArr.push(((this.state.pageNo - 1) * this.state.reqCount) + index)
                this.setState({ gridData, reasonError: "" });
            }
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    /**         
    * PS_ZE_219_APP_26/Sathis/12/25/2023
    * This method invokes on the click of search button
    * */
    handleSearchBtnClick = (pageNo, sortColumn, sortValue) => {
        const { t } = this.props;
        let validationFlag = false;
        let validationMsg = "";
        if (this.state.startDate == "" || this.state.startDate == undefined || this.state.endDate == "" || this.state.endDate == undefined) {
            validationMsg = "Please select the date range"
            validationFlag = true;
        }
        else if (this.state.searchBy != "" && this.state.criteria == "" && this.state.searchBy.value != "lin.ISA_EMPLOYEE_ID") {
            validationMsg = "Please enter the criteria based on searchby"
            validationFlag = true;
        }
        else if (this.state.searchBy == "" && this.state.criteria != "") {
            validationMsg = "Please select the search by"
            validationFlag = true;
        }
        else if (this.state.searchBy.value == "lin.ISA_EMPLOYEE_ID" && this.state.employeeID == "") {
            validationMsg = "Please select the employee ID"
            validationFlag = true;
        }
        if (validationFlag == false) {
            let searchBO = {
                userID: CookieService.getLocalStorageValues("Session_UserID"),
                businessUnit: this.state.businessUnit,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                searchCriteria: this.state.searchBy.value == "lin.ISA_EMPLOYEE_ID" ? this.state.employeeID.value : this.state.criteria,
                searchBy: this.state.searchBy.value,
                radioContains: this.state.radioContainsAny == true ? "True" : "False",
                pageNo: pageNo,
                sortColumn: sortColumn,
                sortValue: sortValue
            }
            reasonChangedArr = [];
            this.setState({ searchClick: true, pageNo, sortColumn, sortValue })
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            $('.header-arrow').addClass('accordion-arrow-change');
            $('.content-row').removeClass('d-none');
            $('.header-arrow').removeClass('accordion-arrow-change');
            $('.content-row').addClass('d-none');
            RMAAction.searchBtnClickAction(searchBO)
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t(validationMsg)
            });
        }
    }

    /**         
    * PS_ZE_219_APP_29/Sathis/12/25/2023
    * This method invokes to handle seaarch response
    * */
    searchBtnClickResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RMAStore.searchResponse);
        let errorDt = response.errorDt
        let flagDt = response.flagDt
        let countDt = response.countDt
        if (errorDt == undefined || errorDt == null) {
            let count = countDt[0].TOTALCOUNT;
            let pg = Math.ceil((count > 0 ? count : 0) / this.state.reqCount);
            flagDt.forEach((value, index) => {

                value.reason = "";
                value.qtyEntered = "";
                value.changed = false;
                value.returnType = "";
                value.returnBU = "";

            });
            reasonChangedArr = [];
            this.setState({
                gridData: flagDt, totalCount: count, pageCount: pg, isQtyenteredError: []
            })
        }
        else {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');

    }

    /**         
    * PS_ZE_219_APP_33/Sathis/12/25/2023
    * This method invokes on the click of save button
    * */
    handleSaveBtnClick = () => {
        const { t } = this.props;
        let conditionVar = true;
        let intQtyentered = true;
        if (this.state.isQtyenteredError.length != 0) {
            conditionVar = false;
            intQtyentered = false;
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Qty returned cannot be greater than remaining qty')
            });
        }
        else if (this.state.gridData != undefined && this.state.gridData != []) {
            if (this.state.gridData.length > 0) {
                this.state.gridData.forEach((value) => {
                    let qtyEntered = value.qtyEntered;
                    if (qtyEntered !== "" && parseInt(qtyEntered) !== 0) {
                        intQtyentered = false;
                    }
                    if (parseInt(qtyEntered) == 0) {
                        conditionVar = false;
                        intQtyentered = false;
                        swal({
                            allowOutsideClick: false,
                            closeOnClickOutside: false,
                            text: "Qty returned cannot be zero"
                        });
                    }
                })
            }
        }
        if (intQtyentered) {
            conditionVar = false;
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Please enter returned qty for at least one order to save')
            });
        }
        if (conditionVar) {
            let linedetailsArr = []
            let selectedReason = ""
            if (this.state.gridData != undefined && this.state.gridData != []) {
                if (this.state.gridData.length > 0) {
                    this.state.gridData.forEach((value) => {
                        let qtyEntered = value.qtyEntered;
                        if (qtyEntered == "") {
                            qtyEntered = 0;
                        }
                        if (value.changed == true && qtyEntered != 0) {
                            if (value.reason.value != "") {
                                selectedReason = value.reason.value
                            }
                            linedetailsArr.push({
                                orderNo: value.ORDER_NO,
                                orderIntLineNo: value.ORDER_INT_LINE_NO,
                                lineReasonCD: value.LINE_REASON_CD,
                                qtyReturn: qtyEntered,
                                productID: value.PRODUCT_ID,
                                shipFromBu: value.SHIP_FROM_BU,
                                shipID: value.SHIP_ID,
                                shipDate: value.SHIP_DATE,
                                businessUnitBI: value.BUSINESS_UNIT_BI,
                                invoice: value.INVOICE,
                                demandLineNo: value.DEMAND_LINE_NO,
                                selectedReason: selectedReason,
                                lineSeqNo: value.LINE_SEQ_NUM,
                                billToCustId: value.BILL_TO_CUST_ID,
                                chargeCode: value.ISA_CUST_CHARGE_CD,
                                workOrder: value.ISA_WORK_ORDER_NO
                            })
                        }
                    })
                }
            }
            if (linedetailsArr != undefined) {
                if (linedetailsArr.length > 0) {
                    reasonChangedArr = [];
                    let saveBO = {
                        userID: CookieService.getLocalStorageValues("Session_UserID"),
                        businessUnit: this.state.businessUnit,
                        websiteId: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                        selectedBu: this.state.businessUnit,
                        lineDetails: linedetailsArr
                    }
                    $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                    RMAAction.saveButtonClickAction(saveBO)
                }

            }
        }
    }
    /**         
    * PS_ZE_219_APP_36/Sathis/12/25/2023
    * This method invokes to handle save button response
    * */
    saveButtonClickResponse = () => {
        const { t } = this.props;
        let response = JSON.parse(RMAStore.submitResponse);
        let errorDt = response.errorDt
        let flagDt = response.flagTable
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
        if (errorDt == undefined || errorDt == null) {
            let error = flagDt[0].msg
            if (error != undefined && error != "") {
                this.setState({ reasonError: error });
                $(`#Success-popup`).modal('show')
            }
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
    }

    /**         
     * PS_ZE_219_APP_19/Sathis/12/25/2023
     * This method invokes to handle drop down changes(businessUnit,search by, employee ID,reason CD)
     * */
    handleOnChangeDD = (name, event, index, value) => {
        let gridData = this.state.gridData;
        if (name == "businessUnit") {
            let businessUnit = event.value
            let endDate = new Date();
            let startDate = new Date();
            startDate.setMonth(endDate.getMonth() - 3);
            this.setState({
                selectedBusinessUnit: event, businessUnit, radioContainsAll: false, radioContainsAny: true, searchBy: "", startDate, endDate,
                employeeID: "", criteria: "", gridData: [], sortColumn: "", sortValue: "",
                totalCount: 0, searchClick: false
            })
            this.pageLoad(false, businessUnit);
        }
        else if (name == "reason") {
            if (event.value != "") {
                gridData[index] = { ...gridData[index], reason: event, changed: true };
                let reasonIndex = reasonChangedArr.indexOf(((this.state.pageNo - 1) * this.state.reqCount) + index);
                if (reasonIndex === -1) {
                    reasonChangedArr.push(((this.state.pageNo - 1) * this.state.reqCount) + index)
                }
                let rasonSelectBO = {
                    businessUnit: this.state.businessUnit,
                    selectedReason: event.value,
                    invItemID: value.INV_ITEM_ID,
                    shipFromBU: value.SHIP_FROM_BU,
                    index: index,

                }
                $(`#${SDIConstants.SDILoader}`).removeClass('hide');
                RMAAction.reasonSelectAction(rasonSelectBO)

            }
            else {
                gridData[index] = { ...gridData[index], reason: event, changed: false, returnToBU: "", returnType: "", qtyEntered: "" };
                let reasonIndex = reasonChangedArr.indexOf(((this.state.pageNo - 1) * this.state.reqCount) + index);

                if (reasonIndex !== -1) {
                    reasonChangedArr.splice(reasonIndex, 1);
                }
                let itemIndex = this.state.isQtyenteredError.indexOf(((this.state.pageNo - 1) * this.state.reqCount) + index);

                if (itemIndex !== -1) {
                    this.state.isQtyenteredError.splice(itemIndex, 1);
                }
            }
            this.setState({ gridData });
            console.log("reasonChange", reasonChangedArr)

        }

        else if (name == "employeeID") {
            if (event.value != "") {

                this.setState({ employeeID: event, searchClick: false, gridData: [], totalCount: 0, sortColumn: "", sortValue: "", pageNo: 1 })
            }
            else {
                this.setState({ employeeID: "" })
            }
        }
        else {
            if (event.value != "") {
                this.setState({ searchBy: event, searchClick: false, gridData: [], totalCount: 0, sortColumn: "", sortValue: "", pageNo: 1 })
            }
            else {
                this.setState({ searchBy: "" })
            }
        }
    }

    /**         
    * PS_ZE_219_APP_23/Sathis/12/25/2023
    * This method invokes to handle date pickers(start date,end date)
    * */
    handleOnChangeDatePicker = (event, name) => {
        this.setState({
            [name]: event, gridData: [], totalCount: 0, searchClick: false
        });
    };

    /**         
    * PS_ZE_219_APP_24/Sathis/12/25/2023
    * This method invokes on the chnage of criteria field
    * */
    handleOnChangeCriteria = (event) => {

        this.setState({ [event.target.name]: event.target.value, gridData: [], totalCount: 0, searchClick: false, sortColumn: "", sortValue: "", pageNo: 1 })
    }
    /**         
    * PS_ZE_219_APP_24/Sathis/12/25/2023
    * This method invokes on the chnage of radio button
    * */
    handleOnChangeRadioBtn = (type) => {
        if (type == "Any") {
            this.setState({ radioContainsAll: false, radioContainsAny: true, gridData: [], totalCount: 0, searchClick: false, sortColumn: "", sortValue: "", pageNo: 1 });
        }
        else {
            this.setState({ radioContainsAll: true, radioContainsAny: false, gridData: [], totalCount: 0, searchClick: false, sortColumn: "", sortValue: "", pageNo: 1 });
        }
    }

    /**         
    * PS_ZE_219_APP_25/Sathis/12/25/2023
    * This method invokes on the click of clear button
    * */
    handleClearBtnClick = (e) => {
        let endDate = new Date();
        let startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);
        reasonChangedArr = [];
        this.setState({
            radioContainsAll: false, radioContainsAny: true, searchBy: "", startDate, endDate,
            employeeID: "", criteria: "", gridData: [], sortColumn: "", sortValue: "",
            totalCount: 0, searchClick: false
        });
    }

    /**         
    * PS_ZE_219_APP_32/Sathis/12/25/2023
    * This method invokes on the click of sort icon
    * */
    handleSorting = (sortValue, sortColumn) => {
        this.handleSearchBtnClick(this.state.pageNo, sortColumn, sortValue);
    }

    /**         
    * PS_ZE_219_APP_31/Sathis/12/25/2023
    * This method invokes on the click of page no
    * */
    handlePagination = (data) => {
        let pageNo = data.selected + 1;
        this.handleSearchBtnClick(pageNo, this.state.sortColumn, this.state.sortValue)
    };

    /**         
    * PS_ZE_219_APP_37/Sathis/12/25/2023
    * This method invokes on the change of quantity field
    * */
    handleOnChangeQty = (name, e, index, value) => {
        let gridData = this.state.gridData
        const re = SDIConstants.quantityRegEX;
        let left = value.QTYREMAINING
        if (e.target.value != "") {
            if (re.test(e.target.value)) {
                let quantity = Math.round(e.target.value);
                if (quantity != "0") {
                    gridData[index] = { ...gridData[index], qtyEntered: e.target.value };
                    if (parseInt(e.target.value) <= left || e.target.value == "") {

                        let itemIndex = this.state.isQtyenteredError.indexOf(((this.state.pageNo - 1) * this.state.reqCount) + index);

                        if (itemIndex !== -1) {
                            this.state.isQtyenteredError.splice(itemIndex, 1);
                        }
                    }
                    else {
                        if (!this.state.isQtyenteredError.includes(((this.state.pageNo - 1) * this.state.reqCount) + index)) {
                            this.state.isQtyenteredError.push(((this.state.pageNo - 1) * this.state.reqCount) + index)
                        }
                    }
                }
            }
        }
        else {
            let itemIndex = this.state.isQtyenteredError.indexOf(((this.state.pageNo - 1) * this.state.reqCount) + index);

            if (itemIndex !== -1) {
                this.state.isQtyenteredError.splice(itemIndex, 1);
            }
            gridData[index] = { ...gridData[index], qtyEntered: e.target.value };
        }
        this.setState({ gridData });
    }

    handleOkBtnClick = () => {
        if (this.state.reasonError.includes("successfully")) {
            this.setState({ searchClick: true });
            this.handleSearchBtnClick(1, this.state.sortColumn, this.state.sortValue)
        }
    }

    /**         
    * PS_ZE_219_APP_38/Sathis/12/25/2023
    * This method invokes on the click of accordian
    * */
    onAccordianClick = (event, index) => {
        $(event.currentTarget).children().toggleClass('accordion-arrow-change');
        $(`#contentrow-${index}`).toggleClass('d-none');
        $('tr').removeClass('tr-border');
        $(event.currentTarget).parent('tr').addClass('tr-border');
    };

    /**         
    * PS_ZE_219_APP_30/Sathis/12/25/2023
    * This method invokes on the render part to bind grid data
    * */
    bindGridData = () => {
        const { t } = this.props;
        if (this.state.gridData != undefined) {
            if (this.state.gridData.length > 0) {
                return this.state.gridData.map((value, index) => {
                    return (
                        <tbody>
                            <tr id={index}>

                                <td className="accordion-toggle" onClick={(e) => this.onAccordianClick(e, index)}>
                                    <span className="accordion-arrow header-arrow" id={`arrow-${index}`}></span>
                                </td>
                                <td className="">{value.ORDER_NO}</td>
                                <td className="">{value.ORDER_INT_LINE_NO}</td>
                                <td className="">{value.INV_ITEM_ID}</td>
                                <td className="">{value.DESCR}</td>
                                <td className="">{value.ISA_EMPLOYEE_ID}</td>
                                <td className="">{value.SHIP_DATE}</td>
                            </tr>
                            <tr className="p d-none content-row" id={`contentrow-${index}`} >
                                <td colSpan={12} className="hiddenRow" style={backgout}>
                                    <div className="accordian-body  py-1 replen-row" id="order-row-1">
                                        <div className="row mx-0 pb-3">
                                            <div className="col-md-12 pl-5">
                                                <div className="row mt-4">

                                                    <div className="col-md-6 col-lg-4 col-sm-12 float-left mb-3">
                                                        <div className="row">
                                                            <div className="col-md-4 float-left mt-2">
                                                                <label className="catalog-label"><Trans>Reason CD</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <Select
                                                                    className="DropDown-Font-Size"
                                                                    options={this.state.reasonOptionsDD}
                                                                    value={value.reason}
                                                                    onChange={e => this.handleOnChangeDD("reason", e, index, value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left mt-2">
                                                                <label className="catalog-label"><Trans>Qty returned</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">


                                                                <React.Fragment><input type="text" min="0" defaultValue={0} placeholder={reasonChangedArr.includes(((this.state.pageNo - 1) * this.state.reqCount) + index) ? t("Enter quantity") : null} disabled={reasonChangedArr.includes(((this.state.pageNo - 1) * this.state.reqCount) + index) ? false : true} maxLength="8" value={value.qtyEntered} name="qtyentered" step="1" inputmode="numeric" onChange={(e) => this.handleOnChangeQty("qtyEntered", e, index, value)} className="form-control float-left mr-2 " />
                                                                </React.Fragment>
                                                            </div>
                                                            {value.changed == true ? <span>{this.state.isQtyenteredError.includes(((this.state.pageNo - 1) * this.state.reqCount) + index) ? <span className="errorspan float-left ml-3"><Trans>Qty cannot be greater than remaining qty</Trans></span> : null}</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label  mb-0 mt-2"><Trans>Qty remaining</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0 mt-2" >{value.QTYREMAINING}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label  mb-0"><Trans>UOM</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0"  >{value.UNIT_OF_MEASURE}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label"><Trans>Unit price</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 col-md-6 col-sm-12 float-left">
                                                                <label className="catalog mb-0">{value.NET_UNIT_PRICE}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label  mb-0"><Trans>Charge code</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog-label mb-0 " >{value.ISA_CUST_CHARGE_CD}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-4 col-sm-12 float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label  mb-0"><Trans>Work order no</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0"  >{value.ISA_WORK_ORDER_NO}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label"><Trans>Machine no</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0">{value.ISA_MACHINE_NO}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label mb-0"><Trans>Return to bu</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0" >{value.returnToBU}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label mb-0"><Trans>Return type</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0 "  >{value.returnType}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label"><Trans>Serial out</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog">{}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label mb-0"><Trans>Serial in</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0">{value.SERIAL_ID}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-4 col-sm-12  float-left mb-3">
                                                        <div className="row ">
                                                            <div className="col-md-4 float-left">
                                                                <label className="catalog-label mb-0"><Trans>Ship ID</Trans>:</label>
                                                            </div>
                                                            <div className="col-md-6 float-left">
                                                                <label className="catalog mb-0">{value.SHIP_ID}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    )
                })
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="container-fluid content-section" id="ContentDiv"  >
                    <div className="row">
                        <div id="accordion" className="col-lg-12 float-left mt-3 p-0">
                            <div className="col-lg-12 float-left px-0 cust-resp-mt">
                                <div className="col-md-12 page-heading mb-3 float-left p-0">
                                    <h5 className="page-title px-3"><Trans>Return To Inventory</Trans></h5>
                                    <span className="info-container float-left">
                                        <img className=" mr-2 mt-3 float-left" src="Images/info-icon.png" />
                                        <span className="Grid-info-icon3 text-left sdi-fede-tooltippickup "><Trans>Return to Inventory is the page where purchased products are returned</Trans></span>
                                    </span>
                                    <div className="fav-item-maintenance-icon mt-1">
                                        <input type="checkbox" id="checkbox-OrdStatus" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                        <label htmlFor="checkbox-OrdStatus">
                                            <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                    <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                    <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                    <g className="grp7" opacity={0} transform="translate(7 6)">
                                                        <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                        <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp6" opacity={0} transform="translate(0 28)">
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp3" opacity={0} transform="translate(52 28)">
                                                        <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                        <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp2" opacity={0} transform="translate(44 6)">
                                                        <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                        <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp5" opacity={0} transform="translate(14 50)">
                                                        <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp4" opacity={0} transform="translate(35 50)">
                                                        <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                        <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                    </g>
                                                    <g className="grp1" opacity={0} transform="translate(24)">
                                                        <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                        <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 float-left border-bottom  mb-3">
                                <div className="row ">
                                    <div className="col-md-12 mb-3">
                                        <label className="catalog-label pr-1" style={{}}><Trans>Business unit</Trans>:</label>
                                        {this.state.businessUnitDD.length > 1 ?
                                            <Select
                                                className="DropDown-Font-Size"
                                                options={this.state.businessUnitDD}
                                                value={this.state.selectedBusinessUnit}
                                                onChange={e => this.handleOnChangeDD("businessUnit", e, 0, "")}
                                                name="businessUnit"
                                            />
                                            : <label className="col-lg-8 col-md-12 float-left p-0 catalog-label">{this.state.businessUnitLabel}</label>}
                                    </div>
                                    <div className="col-md-3 mb-3 rmaclass">
                                        <label className="col-lg-5 col-md-12 float-left p-0 catalog-label form-label"><Trans>Start date</Trans></label>
                                        <DatePicker className="form-control "
                                            id="OF"
                                            fixedHeight
                                            placeholderText="MM/DD/YYYY"
                                            dateFormat="MM/dd/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={this.state.startDate}
                                            maxDate={this.state.endDate}
                                            onChange={(e) => this.handleOnChangeDatePicker(e, "startDate")}
                                        //value={this.state.FromDate}
                                        />
                                        <span className="calendar-icon-RMA" onClick={() => $(`#OF`).focus()} />
                                    </div>
                                    <div className="col-md-3 mb-3 rmaclass">
                                        <label className="col-lg-5 col-md-12 float-left p-0 catalog-label form-label"><Trans>To date</Trans></label>
                                        <DatePicker className="form-control"
                                            id="OT"
                                            fixedHeight
                                            placeholderText="MM/DD/YYYY"
                                            dateFormat="MM/dd/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={this.state.endDate}
                                            minDate={this.state.startDate}
                                            onChange={(e) => this.handleOnChangeDatePicker(e, "endDate")}
                                        />
                                        <span className="calendar-icon-RMA" onClick={() => $(`#OT`).focus()} />
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <label className="col-lg-5 col-md-12 float-left p-0 catalog-label form-label" style={{}}><Trans>Search by</Trans>:</label>
                                        <div className="col-lg-12 col-md-12 float-left p-0 col-12">
                                            <Select
                                                className="DropDown-Font-Size"
                                                options={this.state.searchByOptions}
                                                value={this.state.searchBy}
                                                onChange={e => this.handleOnChangeDD("searchBy", e, 0, "")}
                                                name="SearchBy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="col-lg-3 col-md-12 float-left p-0 catalog-label form-label" style={{}}><Trans>Criteria</Trans>:</label>
                                        {this.state.searchBy.value == "lin.ISA_EMPLOYEE_ID" ?
                                            <div className="col-lg-12 col-md-12 p-0 col-12">
                                                <Select
                                                    className="DropDown-Font-Size"
                                                    options={this.state.employeeDD}
                                                    value={this.state.employeeID}
                                                    onChange={e => this.handleOnChangeDD("employeeID", e, 0, "")}
                                                    inputProps={{ placeholder: "Select Employee ID" }}
                                                />
                                            </div> : <input type="text" className="col-md-12 form-control" placeholder={t("Enter criteria")} name="criteria" value={this.state.criteria} onChange={e => this.handleOnChangeCriteria(e)} />}

                                        <div className="col-md-12 p-0 mt-2">
                                            <label className="custom-radiobtn "><Trans>Contains any</Trans>
                                                <input type="radio" name="RContainsAny" id="duration0" checked={this.state.radioContainsAny} onChange={e => this.handleOnChangeRadioBtn("Any")} />
                                                <span className="checkmark checkmark-org"></span>
                                            </label>
                                            <label className="custom-radiobtn"> <Trans>Contains all</Trans>

                                                <input type="radio" name="RContainsAll" id="duration1" checked={this.state.radioContainsAll} onChange={e => this.handleOnChangeRadioBtn("All")} />
                                                <span className="checkmark checkmark-org"></span>
                                            </label>
                                        </div>

                                    </div>
                                    {/********END*********/}




                                </div>

                                <div className="col-md-12 my-3 float-left p-0">
                                    <button className="btn btn-primary custom-btn-primary primary-btn float-right px-4" onClick={(e) => this.handleSearchBtnClick(1, "ORDER_NO", "DESC")}><Trans>Search</Trans></button>
                                    <button className="btn btn-secondary custom-btn-secondary secondary-btn float-right px-4 mr-3" onClick={(e) => this.handleClearBtnClick(e)} ><Trans>Clear</Trans></button>
                                </div>
                            </div>
                            <div id="collapseOne" className="card-body collapse show" data-parent="#accordion">

                            </div>



                        </div>
                    </div>

                    {this.state.gridData.length > 0 ?
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-12 mb-2 px-0">
                                    <div className="float-right">
                                        <button className="btn btn-primary custom-btn-primary primary-btn float-right px-4 mr-1" onClick={(e) => this.handleSaveBtnClick(e)} ><Trans>Save</Trans></button>

                                    </div>
                                    <div>
                                        <span className="DropDown-Font-Size errorcolour d-none">{this.state.reasonError}</span>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table ZEUS_Grid chk-ordertbl">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className=" position-sort-icon"><Trans>order_key</Trans><span> no</span><span className="sort-up1 ml-1" onClick={(e) => this.handleSorting("ASC", "ORDER_NO")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.handleSorting("DESC", "ORDER_NO")}><img src="Images/sort1.svg" /></span></th>
                                                <th className=" position-sort-icon"><Trans>Order ln</Trans></th>
                                                <th className=" position-sort-icon"><Trans>Product ID</Trans><span className="sort-up1 ml-1" onClick={(e) => this.handleSorting("ASC", "INV_ITEM_ID")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.handleSorting("DESC", "INV_ITEM_ID")}><img src="Images/sort1.svg" /></span></th>
                                                <th className=" position-sort-icon"><Trans>Description</Trans></th>
                                                <th className=" position-sort-icon"><Trans>Employee ID</Trans> <span className="sort-up1 ml-1" onClick={(e) => this.handleSorting("ASC", "ISA_EMPLOYEE_ID")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.handleSorting("DESC", "ISA_EMPLOYEE_ID")}><img src="Images/sort1.svg" /></span></th>
                                                <th className=" position-sort-icon"><Trans>Ship date</Trans> <span className="sort-up1 ml-1" onClick={(e) => this.handleSorting("ASC", "SHIP_DATE")}><img src="Images/sort2.svg" /></span><span className="sort-down ml-1" onClick={(e) => this.handleSorting("DESC", "SHIP_DATE")}><img src="Images/sort1.svg" /></span></th>
                                            </tr>
                                        </thead>
                                        {this.bindGridData()}
                                    </table>
                                </div>
                            </div>
                        </div> : <span> {
                            this.state.searchClick == true ? <section className="site-undercontruction container text-center center-panel pt-4">
                                <div className="img-section">
                                    <img src="images/nosearch.jpg" alt="imagefile" />
                                </div>
                                <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                </p>
                            </section> : null}
                        </span>}
                    <div className="col-md-12">
                        {this.state.totalCount > 0 ? <>
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <span className="total-records mt-2 d-inline-block">
                                        <Trans>Number of Records</Trans><span> :</span><span className="ml-2">{this.state.totalCount}</span>
                                    </span>
                                </div>
                                {this.state.totalCount > this.state.reqCount ? <div> <ReactPaginate
                                    previousLabel={<span className="right-pagination-arrow" />}
                                    nextLabel={<span className="left-pagination-arrow" />}
                                    previousClassName={'page-item'}
                                    nextClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextLinkClassName={'page-link'}
                                    disabledClassName={'disabled'}
                                    breakLabel={'...'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePagination}
                                    containerClassName={'custom-pagination pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    hrefBuilder={() => '#'}
                                    forcePage={this.state.pageNo - 1}
                                /></div> : null}
                            </div></> : null}

                    </div>
                </div>


                {/*Modal Starts here*/}
                <div className="modal fade save" id="Success-popup" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                        <div className="modal-content py-2">
                            {/*Success Starts here*/}
                            <div className="modal-body pb-2">
                                <div className="row">
                                    <div className="col-12  col-sm-12  col-md-12 col-lg-12 text-center">
                                        {this.state.reasonError.includes("FAILURE") ? <img className="mb-2" src="images/alert-Icon.svg" alt="alert" /> : <img className="mb-2" src="images/tick.svg" alt="tick" />}
                                        <p className="mb-1 font-13 font-regular color-light-black">{this.state.reasonError}</p>
                                    </div>
                                </div>
                            </div>
                            {/*Success end here*/}
                            <div className="modal-footer border-0 d-flex justify-content-center">
                                <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2"
                                    data-dismiss="modal" onClick={() => this.handleOkBtnClick()}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Modal Ends here*/}

            </>
        )
    }



}
export default withTranslation()(RMAComponent);