/** 
 * [Ref] - Denotes Pseudo Code Reference
 * Author: Chandrasekar
 * Created Date: 07/30/2020
 * 
 * Import the necessary node-modules. If any of the nodes modules are not avaialble then 
 * install the node modules using the npm install command.
 * 
*/
import EventEmitter from 'events';
import SDIConstants from '../SDIConstants';
import { Dispatcher } from 'simplr-flux';

class MenuStore extends EventEmitter {
    userMenuData = "";
    masterData = "";
    environmentData = "";
    cartSuccess = "";
    cartOrderSuccess = "";
    woValidationData = "";
    handleMenuStore = (action) => {
        switch (action.action.type) {
            /**
             * Store case for getting user menu data from action
             */
            case SDIConstants.methodNames.Menu_BindUserMenu: {
                this.userMenuData = action.action.response;
                this.emit('load');
                break;
            }
             /**
             * Store case for getting master data from action
             */
            case SDIConstants.methodNames.Menu_GetMasterData: {
                this.masterData = action.action.response;
                this.emit('masterData');
                break;
            }
             /**
             * Store case for getting Environment data from action
             */
            case SDIConstants.methodNames.Menu_GetEnvironmentDetails: {
                    this.environmentData = action.action.response;
                    this.emit('envData');
                    break;
            }
             /**
             * Store case for getting add to cart of Fav item response data from action
             */
            case "MENU_AddFavItemsToCart": {
                this.cartSuccess = action.action.response;
                this.emit('addFav');
                break;
            }
             /**
             * Store case for getting add to cart of Fav order response data from action
             */
            case "MENU_AddFavOrderItemsToCart": {
                this.cartOrderSuccess = action.action.response;
                this.emit('addFavOrder');
                break;
            }
            case SDIConstants.methodNames.Menu_CrossSiteAccess: {
                debugger;
                this.cartOrderSuccess = action.action.response;
                this.emit('crossSite');
                break;
            }
            case "Token_Expired": {
                this.emit('tokenExpired');
                break;
            }
            //[05/09/2023 - PC_OSC_5 - ZEUS-99 - Get the OSC page URL]
              case "PassThroughAuthe": {
                this.PassThroughAuthe = action.action.response;
                this.emit('PassThroughAuthe');
                break;
            }
            //[05 / 26 / 2023 - ZEUS - 88 - To validate WO when changed in URL from anywhere in the app]
            case "WOValidate": {
                this.woValidationData = action.action.response;
                this.emit('WOValidate');
                break;
            }
        
        }
    }
}
const objMenuStore = new MenuStore;
// Dispatcher code
Dispatcher.register(objMenuStore.handleMenuStore.bind(objMenuStore));
export default objMenuStore;