
import React, { Component } from 'react';
import * as PickingAssigntoAction from '../actions/PickingAssigntoAction';
import PickingAssigntoStore from '../stores/PickingAssigntoStore';
import * as CookieService from '../CookieService';
import * as $ from 'jquery';
import ReactPaginate from 'react-paginate';
import SDIConstants from '../SDIConstants';
import * as MenuAction from '../actions/MenuAction';
import { RotatingLines } from 'react-loader-spinner';
import { withTranslation, Trans } from 'react-i18next';
import Select from 'react-select';
import swal from 'sweetalert';

let searchTextGlobVar = "";
let currentPagePath;

class PickingAssigntoComponent extends Component {
    constructor(props) {

        super(props);
        this.pageLoadResponse = this.pageLoadResponse.bind(this);
        this.lineDetailResponse = this.lineDetailResponse.bind(this);
        this.pickingAssignToResponse = this.pickingAssignToResponse.bind(this);
        this.state = {
            filterDisplayName: "All",
            isFavorited: false,
            searchByOptions: [
                {
                    value: 'All', label: 'All'
                },
                {
                    value: 'ORDER_NO', label: 'Order #'
                },
                {
                    value: 'ISA_WORK_ORDER_NO', label: 'Work order'
                }

            ],
            searchType: "All",
            searchValue: "",
            count: 0,
            reqCount: 200,
            pageNo: 1,
            pageCount: 0,
            sortColumn: "",
            sortValue: "",
            gridData: [],
            lineData: [],
            employeeOptions: [],
            popupMessage: ""
        }
    }

    /**
    * PS_ZE_291_APP_11/Dhinesh/06/14/2024
    * Invoke this method on the click of Picking Assign To panel from menu bar.
    * */

    componentDidMount() {
        CookieService.updateLocalStorageValues("Session_PageName", window.location.pathname);
        currentPagePath = this.props.location.pathname;
        currentPagePath = currentPagePath.indexOf("?") >= 0 ? currentPagePath.split("?")[0] : currentPagePath;
        let cribID = CookieService.getLocalStorageValues("Session_ISA_CRIB_IDENT");
        if (!cribID || !cribID.trim()) {
            swal({
                allowOutsideClick: false,
                closeOnClickOutside: false,
                text: "Please select crib from user profile"
            });
            this.props.history.push({
                pathname: `/profile`,
                state: { origin: 'Inventory' }
            });
            return;
        }
        let getFavPageBO = {
            Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
            pageURL: currentPagePath.toLowerCase(),
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
        }
        this.pageLoad(1, "", "", "");
        PickingAssigntoAction.getCurrentFavPage(getFavPageBO);
        PickingAssigntoStore.on('funcPageLoad', this.pageLoadResponse.bind(this));
        PickingAssigntoStore.on('funcLineDetail', this.lineDetailResponse.bind(this));
        PickingAssigntoStore.on('funcPickingAssign', this.pickingAssignToResponse.bind(this));
        PickingAssigntoStore.on('favpage', this.loadFavPageResponse.bind(this));
        PickingAssigntoStore.on('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    /**
    * PS_ZE_291_APP_14/Dhinesh/04/17/2024
    * This method invokes on page load,click of search button and change of business unit drop down.
    * */
    componentWillUnmount() {
        PickingAssigntoStore.removeListener('funcPageLoad', this.pageLoadResponse.bind(this));
        PickingAssigntoStore.removeListener('funcLineDetail', this.lineDetailResponse.bind(this));
        PickingAssigntoStore.removeListener('funcPickingAssign', this.pickingAssignToResponse.bind(this));
        PickingAssigntoStore.removeListener('favpage', this.loadFavPageResponse.bind(this));
        PickingAssigntoStore.removeListener('updateFavPage', this.updateFavPageResponse.bind(this));
    }

    loadFavPageResponse = () => {
        let favPageResponse = JSON.parse(PickingAssigntoStore.currentFavPageResponse);
        if (favPageResponse.length > 0) {
            favPageResponse = favPageResponse[0];
            this.setState({ isFavorited: true, Page_ID: favPageResponse.PAGE_ID });
        };
    };

    updateFavPageResponse = () => {
        if (PickingAssigntoStore.updateFavPageResponse.updateType == 1) {
            let response = JSON.parse(PickingAssigntoStore.updateFavPageResponse.responseStr)[0];
            if (response.is_Success == "True") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: true, Page_ID: response.Page_ID });
                MenuAction.toastMessage(1);
            }
        } else {
            let response = JSON.parse(PickingAssigntoStore.updateFavPageResponse.responseStr)[0];
            if (response.success == "true") {
                $(`#${SDIConstants.SDILoader}`).addClass('hide');
                this.setState({ isFavorited: false });
                MenuAction.toastMessage(2);
            }
        }
    };

    handleFavorite = (event) => {
        this.setState({ isFavorited: event.target.checked });
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        if (event.target.checked == true) {
            let insertFavPageBO = {
                Session_USERID: CookieService.getLocalStorageValues("Session_UserID"),
                pageURL: currentPagePath.toLowerCase(),
                pageTitle: "Picking Assign To",
                Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT")
            }
            PickingAssigntoAction.insertFavPage(insertFavPageBO)
        } else {
            let deleteFavPageBO = {
                Page_ID: this.state.Page_ID
            }
            PickingAssigntoAction.deleteFavPage(deleteFavPageBO)
        }
    };

    /**
        * PS_ZE_82_APP_14/Dhinesh/06/14/2024
        * This method invokes on page load of picking assign to panel.
        * */
    pageLoad = (pageNo, sortColumn, sortValue, searchTextGlobVar) => {

        let pageLoadBO = {
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            pageNo: pageNo,
            sortValue: sortValue,
            sortColumn: sortColumn,
            searchColumn: this.state.searchType,
            searchValue: searchTextGlobVar
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        PickingAssigntoAction.pageLoadAction(pageLoadBO)
    }

    /**
       * PS_ZE_82_APP_14/Dhinesh/06/14/2024
       * This method invokes on page load of picking assign to panel.
       * */
    pageLoadResponse = () => {
        const { t } = this.props;
        const response = JSON.parse(PickingAssigntoStore.pageLoadResponse);

        const errorDt = response.errorDt;
        const employeeDt = response.employeeDt;
        const gridDataDt = response.gridDataDt;

        if (errorDt[0].errorMessage.toUpperCase() == "FALSE") {
            let count = 0;
            const employeeDD = [
                { label: "Select User", value: " " },
                { label: "SKIPPED", value: "SKIPPED" }
            ];
            if (employeeDt && employeeDt.length > 0) {
                employeeDt.forEach((value, index) => {
                    const localObj = {
                        label: value.EMPLOYEENAME, value: value.ISA_EMPLOYEE_ID
                    };
                    employeeDD.push(localObj);
                });
            }


            if (gridDataDt && gridDataDt.length > 0) {
                count = gridDataDt[0].TOTALRECORDS;
            }
            else {
                count = 0;
            }

            const pg = Math.ceil(count / this.state.reqCount);

            this.setState({
                gridData: gridDataDt,
                count: count,
                pageCount: pg,
                employeeOptions: employeeDD
            });
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    /**
       * PS_ZE_82_APP_14/Dhinesh/06/14/2024
       * This method invokes on page load of picking assign to panel.
       * */

    handleAccordian = (orderNo, lineNo, index) => {
        let gridData = this.state.gridData;

        gridData.forEach((value, i) => {
            if (i === index) {
                gridData[i].ISACCORDIANCLICKED = gridData[i].ISACCORDIANCLICKED === "FALSE" ? "TRUE" : "FALSE";
            } else {
                gridData[i].ISACCORDIANCLICKED = "FALSE";
            }
        });

        if (gridData[index].ISACCORDIANCLICKED == "TRUE") {
            const lineDetailBO = {
                userID: CookieService.getLocalStorageValues("Session_UserID"),
                websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                orderNo: orderNo,
                lineNo: lineNo
            };

            PickingAssigntoAction.lineDetailAction(lineDetailBO);
        }
        this.setState({ gridData, lineData: [] });
    }

    /**
       * PS_ZE_82_APP_17/Dhinesh/06/14/2024
       * This method invokes on page load of picking assign to panel.
       * */

    lineDetailResponse = () => {
        const { t } = this.props;
        const response = JSON.parse(PickingAssigntoStore.lineDetailResponse);

        const errorDt = response.errorDt;
        const lineDataDt = response.lineDetailDt;

        if (errorDt[0].errorMessage.toUpperCase() == "FALSE") {
            this.setState({
                lineData: lineDataDt
            });
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
    }

    /**
       * PS_ZE_82_APP_21/Dhinesh/06/14/2024
       * This method is response update picking for picking assign to panel.
    * */

    handlePickingAssignTo = (assignTo, index, orderNo, lineNo) => {
        let gridData = this.state.gridData;
        if (gridData[index].ASSIGNED_TO !== assignTo.value) {

            const updatePickingAssignToBO = {
                userID: CookieService.getLocalStorageValues("Session_UserID"),
                websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
                businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
                assignedTo: assignTo.value,
                orderNo: orderNo,
                userDefined: assignTo.value === "SKIPPED" ? "SKIPPED" : (assignTo.value === " " ? " " : "ASSIGNED"),
                lineNo: lineNo
            };

            gridData[index].ASSIGNED_TO = assignTo.value;
            gridData[index].EMPLOYEENAME = assignTo.label;
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            this.setState({ gridData: gridData });
            PickingAssigntoAction.pickingAssignAction(updatePickingAssignToBO);
        }

    }

    /**
        * Dhinesh/06/14/2024
        * This method is response update picking for picking assign to panel.
     * */

    pickingAssignToResponse = () => {
        const { t } = this.props;
        const response = JSON.parse(PickingAssigntoStore.pickingAssignToResponse);

        if (response !== 'Exception') {
            this.setState({ popupMessage: response });
            $(`#exp-popup`).modal('show')
        }
        else {
            swal({
                allowOutsideClick: true,
                closeOnClickOutside: true,
                text: t('Technical issue please contact SDI help desk')
            });
        }
        $(`#${SDIConstants.SDILoader}`).addClass('hide');
    }

    /**
        *PS_ZE_82_APP_21/Dhinesh/06/14/2024
        * This method is response for picking assign to panel.
     * */

    exportToExcel = () => {
        const exportToExcelBO = {
            userID: CookieService.getLocalStorageValues("Session_UserID"),
            websiteID: CookieService.getLocalStorageValues("Session_WEBSITEID"),
            businessUnit: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            searchValue: searchTextGlobVar.trim(),
            searchcolumn: this.state.searchType
        };
        $(`#${SDIConstants.SDILoader}`).removeClass('hide');
        PickingAssigntoAction.exportToExcelAction(exportToExcelBO);
    }



    /**
       * PS_ZE_291_APP_25/Dhinesh/06/17/2024
       * This method is search click for picking assign to panel.
    * */
    handleSearch = () => {
        searchTextGlobVar = this.state.searchValue.trim();
        this.pageLoad(1, "", "", searchTextGlobVar)
        this.setState({ pageNo: 1, sortColumn: "", sortValue: "" });
    }

    /**
       * PS_ZE_82_APP_27/Dhinesh/06/17/2024
       * This method is Clear Search for picking assign to panel.
    * */
    ClearSearch = () => {
        this.setState({ searchValue: "" });
    }
    searchKeyPress = (event) => {
        if (event.keyCode == 13) {
            this.handleSearch();
        };
    }
    onChangeSearch = (e) => {
        this.setState({ searchValue: e })
    }

    filterClick = (event, displayName, columnName) => {
        event.preventDefault();
        this.setState({ filterDisplayName: displayName, searchType: columnName });
    };


    bindDropdown = () => {
        return this.state.searchByOptions.map((values, index) => {
            return (
                <a className="dropdown-item search-z-index" href="#" value={values.value} onClick={(e) => this.filterClick(e, values.label, values.value)}><Trans>{values.label}</Trans> </a>
            )
        })
    }

    /**
   *  PS_ZE_82_APP_28/Dhinesh/06/17/2024
   * This method invokes when on sorting icon 
   * */
    handleSorting = (sortColumn, sortValue) => {
        this.pageLoad(this.state.pageNo, sortColumn, sortValue, searchTextGlobVar);
        this.setState({ sortColumn: sortColumn, sortValue: sortValue })
    }

    /**         
     *  PS_ZE_82_APP_29/Dhinesh/06/17/2024
     *This method invokes on the click of pagination 
     * */

    handlePagination = (data) => {
        let pageNo = data.selected + 1;
        this.pageLoad(pageNo, this.state.sortColumn, this.state.sortValue, searchTextGlobVar);
        this.setState({ pageNo: pageNo });

    };

    bindLineData = () => {
        if (this.state.lineData.length > 0) {
            return this.state.lineData.map((value, index) => {
                return (
                    <React.Fragment key={index}>
                        {/* accordion starts here */}
                        <tr className="p">
                            <td colSpan={8} className="hiddenRow border-bottom-0">
                                <div className="accordian-body collapse py-1 px-3 border-bottom show" id={`order-row-${index}`}>
                                    <div className="px-3 py-3">
                                        <div className="row custom-bg py-3">
                                            <div className="col-md-12">
                                                <div className="d-flex flex-wrap flex-lg-nowrap">
                                                    <div className="image-resp-width">
                                                        <img className="cart-product-img-style" onError={(e) => { e.target.src = "/Images/noimage_new.png" }} src={(value.IMAGE_URL) ? value.IMAGE_URL : "/Images/noimage_new.png"} alt="fav_item" />
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="row">
                                                            <div className="col-md-12 responsive-spacing-style">
                                                                <div className="w-100">
                                                                    <h5 className="font-16 font-bold mb-3 text-dark">{value.INV_ITEM_ID}</h5>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-3">
                                                                        <label className="font-12 font-regular label-value-color text-wrap mb-0">{value.DESCR254}</label>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-12 mb-3">
                                                                        <label className="font-12 font-medium label-color mb-0"><Trans>Line #</Trans> :</label>
                                                                        <label className="ml-2 font-12 font-regular label-value-color mb-0">{value.ORDER_INT_LINE_NO}</label>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-12 mb-3">
                                                                        <label className="font-12 font-medium label-color mb-0"><Trans>Location</Trans> :</label>
                                                                        <label className="ml-2 font-12 font-regular label-value-color mb-0">{value.SHIPTO_ID}</label>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-12 mb-3">
                                                                        <label className="font-12 font-medium label-color mb-0"><Trans>Qty requested</Trans> :</label>
                                                                        <label className="ml-2 font-12 font-regular label-value-color mb-0">{value.QTY_REQUESTED}</label>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-12 mb-3">
                                                                        <label className="font-12 font-medium label-color mb-0"><Trans>Reserved qty</Trans>:</label>
                                                                        <label className="ml-2 font-12 font-regular label-value-color mb-0">{value.QTY_REQUESTED}</label>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <label className="font-12 font-medium label-color mb-0"><Trans>Customer notes</Trans>:</label>
                                                                        <label className="ml-2 font-12 font-regular label-value-color mb-0">{value.ISA_LINE_NOTES}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {/* accordion ends here */}
                    </React.Fragment>
                );
            });
        }
    }

    bindGridData = () => {
        if (this.state.gridData.length > 0) {
            return this.state.gridData.map((value, index) => {
                let localObj = "";
                let priority = "";
                if (!value.ASSIGNED_TO || !value.ASSIGNED_TO.trim()) {
                    localObj = { label: "Select User", value: " " };
                }
                else if (value.ASSIGNED_TO.trim() == "SKIPPED") {
                    localObj = { label: "SKIPPED", value: "SKIPPED" };
                }
                else {
                    localObj = {
                        label: value.EMPLOYEENAME,
                        value: value.ASSIGNED_TO
                    };
                }
                if (value.ISA_CUST_PRIORITY == null || value.ISA_CUST_PRIORITY.trim() == "") {
                    priority = value.PRIORITY_NBR;
                }
                else if (value.DESCR) {
                    priority = value.ISA_CUST_PRIORITY + " - " + value.DESCR;
                }
                return (<React.Fragment key={index}>
                    <>
                        {/*row-1 starts here*/}
                        <tr>
                            <td class={`accordion-toggle ${value.ISACCORDIANCLICKED == "FALSE" ? 'collapsed' : ''}`}><span className="accordion-arrow accordion-arrow-change" onClick={(e) => { this.handleAccordian(value.ORDER_NO, value.ORDER_INT_LINE_NOS, index) }} /></td>
                            <td>{value.ORDER_NO}</td>
                            <td>{value.LINE_NOS_COUNT}</td>
                            <td>{value.ISA_WORK_ORDER_NO}</td>
                            <td>{priority}</td>
                            <td>{value.SOURCE_BUS_UNIT}</td>
                            <td>{value.SCHED_DATE}</td>
                            <td className="dropdown-width-style dropdown-pickingassign">
                                <Select
                                    className="dropdown-pickingassign"
                                    options={this.state.employeeOptions}
                                    value={[localObj]}
                                    onChange={(e) => { this.handlePickingAssignTo(e, index, value.ORDER_NO, value.ORDER_INT_LINE_NOS) }}
                                />
                            </td>
                        </tr>
                        {/*row-1 ends here*/}

                    </>
                    {value.ISACCORDIANCLICKED == "TRUE" ? (
                        this.state.lineData.length > 0 ? (
                            this.bindLineData()
                        ) : (
                                <tr>
                                    <td colspan="8">
                                        <div style={{ display: "flex", justifyContent: "center" }} id="RotatingLinesId">
                                            <RotatingLines
                                                strokeColor="#fcb913"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="50"
                                                visible={true}
                                            />
                                        </div>
                                    </td>
                                </tr>

                            )
                    ) : null}
                </React.Fragment>

                );
            })
        }
    }
    /****************************************************************HTML parts starts from here***********************************************************************/
    render() {
        const { t } = this.props;
        return (
            <>

                <div>
                    <title>Picking Assign To</title>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="stylesheet" type="text/css" href="css/sdiframework.css" />
                    <link rel="stylesheet" type="text/css" href="css/sdistyles.css" />
                    <div className="container-fluid content-section">
                        <div className="row">
                            <div className="container-fluid Ord-title" >
                                <div className="row">
                                    {/* Page Heading starts here */}
                                    <div className="col-md-12 mt-3 page-heading">
                                        <h5 className="page-title"><Trans>Picking Assign To</Trans></h5>
                                        <div className="fav-item-maintenance-icon mt-1">
                                            <input type="checkbox" id="checkbox-OrdStatus" className="checknow" name="isFavorited" checked={this.state.isFavorited} onChange={this.handleFavorite} />
                                            <label htmlFor="checkbox-OrdStatus">
                                                <svg className="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                                                    <g className="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                                                        <path d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" className="heart" fill="#fff" />
                                                        <circle className="main-circ" fill="#E2264D" opacity={0} cx="29.5" cy="29.5" r="1.5" />
                                                        <g className="grp7" opacity={0} transform="translate(7 6)">
                                                            <circle className="oval1" fill="#9CD8C3" cx={2} cy={6} r={2} />
                                                            <circle className="oval2" fill="#8CE8C3" cx={5} cy={2} r={2} />
                                                        </g>
                                                        <g className="grp6" opacity={0} transform="translate(0 28)">
                                                            <circle className="oval1" fill="#CC8EF5" cx={2} cy={7} r={2} />
                                                            <circle className="oval2" fill="#91D2FA" cx={3} cy={2} r={2} />
                                                        </g>
                                                        <g className="grp3" opacity={0} transform="translate(52 28)">
                                                            <circle className="oval2" fill="#9CD8C3" cx={2} cy={7} r={2} />
                                                            <circle className="oval1" fill="#8CE8C3" cx={4} cy={2} r={2} />
                                                        </g>
                                                        <g className="grp2" opacity={0} transform="translate(44 6)">
                                                            <circle className="oval2" fill="#CC8EF5" cx={5} cy={6} r={2} />
                                                            <circle className="oval1" fill="#CC8EF5" cx={2} cy={2} r={2} />
                                                        </g>
                                                        <g className="grp5" opacity={0} transform="translate(14 50)">
                                                            <circle className="oval1" fill="#91D2FA" cx={6} cy={5} r={2} />
                                                            <circle className="oval2" fill="#91D2FA" cx={2} cy={2} r={2} />
                                                        </g>
                                                        <g className="grp4" opacity={0} transform="translate(35 50)">
                                                            <circle className="oval1" fill="#F48EA7" cx={6} cy={5} r={2} />
                                                            <circle className="oval2" fill="#F48EA7" cx={2} cy={2} r={2} />
                                                        </g>
                                                        <g className="grp1" opacity={0} transform="translate(24)">
                                                            <circle className="oval1" fill="#9FC7FA" cx="2.5" cy={3} r={2} />
                                                            <circle className="oval2" fill="#9FC7FA" cx="7.5" cy={2} r={2} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                    {/* Page Heading ends here */}

                                    {/* Grid starts here */}
                                    <div className="col-md-12 my-4">
                                        {/* Search and add button */}
                                        <div className="d-grid d-md-flex justify-content-md-end align-items-center grid-resp-style my-3">
                                            <div>
                                                <div className="input-group dropdown-search w-100">
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary dropdown-toggle font-14" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Trans>{this.state.filterDisplayName}</Trans></button>
                                                        <div className="dropdown-menu search-z-index">
                                                            {this.bindDropdown()}
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control search-height" placeholder={t("Search")} style={{ 'padding-right': '50px' }} value={this.state.searchValue} aria-label="Text input with dropdown button" maxLength="254" onKeyDown={(e) => this.searchKeyPress(e)} onChange={(e) => { this.onChangeSearch(e.target.value) }} />
                                                    {this.state.searchValue.length > 0 ? <span className="search-cancel-header cursor-pointer" onClick={() => this.ClearSearch()} /> : null}
                                                    <span className="dropdown-search-icon" onClick={(e) => this.handleSearch()} />
                                                </div>
                                            </div>
                                            <div>
                                                <button className="btn btn-custom-primary font-14 font-medium px-4 primary-btn-responsive" onClick={(e) => this.exportToExcel()}><Trans>Export to excel</Trans></button>
                                            </div>
                                        </div>
                                        {/* Search and add button */}
                                        {/* Table starts here */}
                                        <div className="table-responsive table-scroll-style mt-2">
                                            <table className="table ZEUS_Grid FOM-grid mobileresponsive-grid text-nowrap">
                                                <thead className="sticky-top" style={{ "z-index": "20" }}>
                                                    <tr>
                                                        <th />
                                                        <th className="position-sort-icon font-14"><Trans>Order #</Trans><span className="sort-up ml-1" onClick={() => { this.handleSorting("ORDER_NO", "ASC") }}><img src="Images/sort2.svg" alt="sort-up" /></span><span className="sort-down ml-1" onClick={() => { this.handleSorting("ORDER_NO", "DESC") }}><img src="Images/sort1.svg" alt="sort-down" /></span></th>
                                                        <th className="position-sort-icon"><Trans>Total items</Trans></th>
                                                        <th><Trans>Work order</Trans></th>
                                                        <th className="position-sort-icon"><Trans>Priority</Trans><span className="sort-up ml-1" onClick={() => { this.handleSorting("ISA_CUST_PRIORITY", "ASC") }}><img src="Images/sort2.svg" alt="sort-up" /></span><span className="sort-down ml-1" onClick={() => { this.handleSorting("ISA_CUST_PRIORITY", "DESC") }}><img src="Images/sort1.svg" alt="sort-down" /></span></th>
                                                        <th className="position-sort-icon"><Trans>Business unit</Trans></th>
                                                        <th className="position-sort-icon"><Trans>Order date</Trans><span className="sort-up ml-1" onClick={() => { this.handleSorting("SCHED_DATE", "ASC") }}><img src="Images/sort2.svg" alt="sort-up" /></span><span className="sort-down ml-1" onClick={() => { this.handleSorting("SCHED_DATE", "DESC") }}><img src="Images/sort1.svg" alt="sort-down" /></span></th>
                                                        <th><Trans>Assign to</Trans></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.gridData.length > 0 ? this.bindGridData() : null
                                                    }
                                                </tbody>
                                            </table>
                                            {this.state.gridData.length === 0 ?
                                                <span>
                                                    <section className="site-undercontruction container text-center center-panel pt-4">
                                                        <div className="img-section">
                                                            <img src="images/nosearch.jpg" alt="imagefile" />
                                                        </div>
                                                        <p className="search-result-content pt-4"><Trans>No Records found</Trans>
                                                        </p>
                                                    </section>
                                                </span> : null}
                                            <div className="col-md-12">
                                                {this.state.count > 0 ? <>
                                                    <div class="d-flex justify-content-between table-footer-responsive">
                                                        <div>
                                                            <span className="total-records mt-2 d-inline-block">
                                                                <Trans>Number of records</Trans><span> :</span><span className="ml-2">{this.state.count}</span>
                                                            </span>
                                                        </div>
                                                        {this.state.count > this.state.reqCount ? <div> <ReactPaginate
                                                            previousLabel={<span className="right-pagination-arrow" />}
                                                            nextLabel={<span className="left-pagination-arrow" />}
                                                            previousClassName={'page-item'}
                                                            nextClassName={'page-item'}
                                                            previousLinkClassName={'page-link'}
                                                            nextLinkClassName={'page-link'}
                                                            disabledClassName={'disabled'}
                                                            breakLabel={'...'}
                                                            breakClassName={'page-item'}
                                                            breakLinkClassName={'page-link'}
                                                            pageCount={this.state.pageCount}
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={2}
                                                            onPageChange={this.handlePagination}
                                                            containerClassName={'custom-pagination pagination mb-5'}
                                                            pageClassName={'page-item'}
                                                            pageLinkClassName={'page-link'}
                                                            activeClassName={'active'}
                                                            hrefBuilder={() => '#'}
                                                            forcePage={this.state.pageNo - 1}
                                                        /></div> : null}
                                                    </div></> : null}
                                            </div>
                                        </div>
                                        {/* Table ends here */}
                                    </div>
                                    {/* Grid ends here */}


                                    {/*Modal Starts here*/}
                                    <div className="modal fade success-customer" tabIndex={-1} id="exp-popup" role="dialog" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered custom-success-width" role="document">
                                            <div className="modal-content border-0 py-2">
                                                {/* Success Starts here */}
                                                <div className="modal-body pb-2">
                                                    <div className="row">
                                                        <div className="col-12 text-center">
                                                            {this.state.popupMessage.includes("couldn't") ? (
                                                                <img className="mb-3" src="images/alert-Icon.svg" alt="alert" />
                                                            ) : (
                                                                    <img className="mb-3" src="images/tick.svg" alt="tick" />
                                                                )}
                                                            <p className="mb-1 font-14 font-regular color-light-black">{this.state.popupMessage}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Success end here */}
                                                {/* button */}
                                                <div className="modal-footer border-0 d-flex justify-content-center pt-1">
                                                    <button className="btn btn-custom-primary font-15 font-medium mr-2 mb-2 px-4 py-2" data-dismiss="modal">
                                                        OK
                                                    </button>
                                                </div>
                                                {/* button */}
                                            </div>
                                        </div>
                                    </div>

                                    {/*Modal Ends here*/}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}
export default withTranslation()(PickingAssigntoComponent);