import React, { Component } from 'react';
import * as $ from 'jquery';
import SDIConstants from '../SDIConstants';
import * as CookieService from '../CookieService';
import { withTranslation, Trans } from 'react-i18next';
 class PIPostComponent extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (!$(`#${SDIConstants.SDILoader}`).hasClass("hide")) {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
        }
        CookieService.setCookieValues(SDIConstants.access_token, "", -1);
        localStorage.clear();
    }
    componentWillUnmount() { }
    Redirect = (event) => {
        if (event) {
            event.preventDefault();
        }
    }
     render() {
        //[11/26/21, CAS - 31, Priyanka D]
        return (
            <div className="container-fluid content-section mb-0">
                <div className="row">
                    {/* Loading starts */}
                    <div className="container-fluid content-margin mb-0">
                        <div className="row">
                            <div className="col-md-12 mx-auto d-block mt-5">
                                <h3 className="text-center"><Trans>Now exiting</Trans></h3>
                            </div>
                            <div className="col-md-12 text-center loading-heading d-block mb-2 img-section">
                                <img src="Images/sdi-logo-pdf.jpg" className="text-center loading-heading pipostimage" />
                            </div>
                            {/*[4/22/22, CAS - 31, Dhevanesam R]*/}
                            <div className="col-md-12 mx-auto d-block">
                                <h3 className="text-center "><Trans>and returning to</Trans></h3>
                                <h3 className="text-center "><Trans>disabling session connection...</Trans></h3>
                                <h5 className="text-center">
                                    <a href="#" onClick={(e) => this.Redirect(e)}><Trans>Please click here, if this page does not automatically refresh in 5 seconds</Trans></a>
                                </h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
export default withTranslation()(PIPostComponent);

