import React, { Component } from 'react';
import LoginComponent from './LoginComponent'
import ForgotPassword from './ForgotPasswordComponent'
import Changepassword from './ChangePasswordComponent'
import CoopFormComponent from './CoopFormComponent'
import CoopLandingComponent from './CoopLandingComponent'
//[10/18/21, PC_03, Bug ID - 1736, Dhevanesam R]
import * as CookieService from '../CookieService';
//[PC_07, 09/07/21, Author - Dhevanesam R]
import { withTranslation, Trans } from 'react-i18next';
import Select, { components } from "react-select";
import i18n from '../i18n';
import SDIConstants from '../SDIConstants';
import * as $ from 'jquery';
//[PC_01, 03/18/22, CAS - 31, Dhevanesam R]
const options = [
	{
		value: 'en', label: 'English', icon:
			"http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
	},
	{
		value: 'es', label: 'Español', icon: "http://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg"
	},
	{
		value: 'fr', label: 'Français (Canada)', icon: "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"
	}
]

const { Option } = components;
const IconOption = props => (
	<Option {...props}>
		<img
			src={props.data.icon}
			alt={props.data.label}
			style={{ "width": "12px" }}
		/>
		{" "}
		{props.data.label}
	</Option>
);

class BgOverlayComponent extends Component {
	constructor(props) {
		super(props);
		this.ZeuslogoClick = this.ZeuslogoClick.bind(this);
		this.state = {
            language: CookieService.getLocalStorageValues("newlanguage") != null && CookieService.getLocalStorageValues("newlanguage") != undefined && CookieService.getLocalStorageValues("newlanguage") != false ? CookieService.getLocalStorageValues("newlanguage") : options[0], logoclkcnt: 0, showlogin: CookieService.getLocalStorageValues("ShowNormalLogin") != null && CookieService.getLocalStorageValues("ShowNormalLogin") != undefined ? CookieService.getLocalStorageValues("ShowNormalLogin") : false,
            clientName: ""
		}
	}

	onLanguageHandle = (e) => {
		let newObj = e;
		let newLang = e.value;
		CookieService.updateLocalStorageValues("oldlanguage", this.state.language);
		CookieService.updateLocalStorageValues("newlanguage", newObj);
		i18n.changeLanguage(newLang);
		this.setState({ language: newObj });
		//window.location.reload(true);
		//$(`#${SDIConstants.SDILoader}`).removeClass('hide');
	};

	componentDidMount() {
		if (this.state.showlogin == true) {
			this.setState({ logoclkcnt: 3 });
		}
	}

	//[5/18/2022]Zeus -107 ZeuslogoClick() will be hit onclick of zeus logo, click count will be incremented and sent to logincomp to show normal login page if count is 3
	ZeuslogoClick = () => {

		let logoclick = this.state.logoclkcnt + 1;
		if (logoclick == 4 || (CookieService.getLocalStorageValues("isSSONormalLogin") == true)) {
			logoclick = 0;
			CookieService.updateLocalStorageValues("SSOFirstHit", true);
			CookieService.updateLocalStorageValues("isSSONormalLogin", false);
			CookieService.updateLocalStorageValues("ShowNormalLogin", false);
		}
		if (logoclick == 3) {
			CookieService.updateLocalStorageValues("LoginFirstHit", true);
			CookieService.updateLocalStorageValues("ShowNormalLogin", true);
		}
		this.setState({ logoclkcnt: logoclick, showlogin: CookieService.getLocalStorageValues("ShowNormalLogin") });
    }

    displayClientName = (clientName) => {
        this.setState({ clientName: clientName })
    }


	render() {
		const { t } = this.props;
		let bodyComponent;
		debugger;
		if (this.props.location.pathname.toLowerCase().indexOf('forgotpassword')>=0) {
			bodyComponent = <ForgotPassword title={this.props.title} {...this.props}/>
		}
		else if (this.props.location.pathname.toLowerCase().indexOf('changepassword')>= 0) {
			bodyComponent = <Changepassword title={this.props.title} {...this.props} />
		}
		else if (this.props.location.pathname.toLowerCase().indexOf('coopform') >= 0) {
			bodyComponent = <CoopFormComponent title={this.props.title} {...this.props} />
        }
        else if (this.props.location.pathname.toLowerCase().indexOf('sdicoop') >= 0) {
            bodyComponent = <CoopLandingComponent title={this.props.title} {...this.props} displayClientName={this.displayClientName} />
        }
		else {
			//[10/18/21, PC_03, Bug ID - 1736, Dhevanesam R]
			console.log("newlang", CookieService.getLocalStorageValues("newlanguage"));
			bodyComponent = <LoginComponent title={this.props.title} newlanguage={CookieService.getLocalStorageValues("newlanguage")} logoClickCount={this.state.logoclkcnt} showNormalLogin={this.state.showlogin}  {...this.props} />
		}
		return (<div className="container-fluid" id="Log-in">
            {this.props.location.pathname.toLowerCase().indexOf('coopform') >= 0 || this.props.location.pathname.toLowerCase().indexOf('sdicoop') >= 0  ?
				<div>
					<div class="fixed-top">
						<nav class="navbar navbar-expand-lg navbar-light bg-dark-sdi-nav border-0 main-menu">
							<a class="navbar-brand ml-2"> <img src="/Images/sdi-logo.png" class="sdi-logo" alt="SDI Logo" />
                            </a>{this.props.location.pathname.toLowerCase() == "/sdicoop/" ?
                                <div className="text-right" style={{ 'float': 'right', 'width': '100%' }}>
                                    <label className="font-12 font-medium mt-2">
                                        <img src="images/vendor-icon.svg" alt="profile-icon" className="mr-2 mb-1" width="30px" />
                                        <span className="font-16" style={{ 'color': 'white' }}>{this.state.clientName}</span>
                                    </label>
                                </div> : null}
                        </nav>
					</div>
					{bodyComponent}</div> :
				<div className="row">
					<div className="col-md-4 login-bg p-0">
						<div className="position-absolute col-12 p-0 text-center banner-content">
							{/*[11/9/21, PC_BG_01, Bug - 1814, Dhevanesam R]*/}
							<h3 className="font-22 banner-heading"><Trans>SDI MRO for a more</Trans> <span className="font-bold"><Trans>connected</Trans></span>&nbsp;
                            <Trans>enterprise</Trans> </h3>
							<span className="font-10 copyright">{`© 2013-${new Date().getFullYear().toString()} sdi, `}{t("inc. All Rights Reserved")}</span>
						</div>
					</div>
					{/*[PC_TD_10, CAS - CSS Issue in translation dropdown, Dhevanesam R*/}
					<div className="col-md-8 p-0 Login-form">
						{/*4/15/2022 Bug 2054 Lang DD is overlapping with Zeus logo in login page in iPad view- Poornima S*/}
						<div className="mb-0 TransCSS" style={{"margin-right": "8%", "margin-left": "-25%","float": "right","margin-top": "2%" }}>
							<Select
								id="langDD"
								name="langDDSelected"
								className="DropDown-Font-Size"
								onChange={(e) => this.onLanguageHandle(e)}
								value={this.state.language}
								defaultValue={options[0]}
								options={options}
								components={{ Option: IconOption }}
							/>
						</div>
						<div className="row mx-0">
							<div className="col-lg-4 col-md-8 col-11 p-0 mx-auto login-margin text-center login-shape-1">
								<div className="col-12 mb-3 mx-auto p-0">
									<img src="/Images/zeus-logo.png" alt="Logo" className="logo header-logo" onClick={this.ZeuslogoClick}/>
								</div>
								{bodyComponent}
							</div>
							{/*4/15/2022 Bug 2054 Lang DD is overlapping with Zeus logo in login page in iPad view- Poornima S*/}
							<div className="col-12 p-0 mx-auto mt-2 footer-logo text-center">
								<div className="col-12">
                                    {/* <img src="images/footer-logo.png" alt="footer-logo" class="mob-w-100"> */}
                                    {/* Zeus-347 Core value change - Shanmugapriya */}
                                    <img src="/Images/core5.png" alt="footer-logo" className="core-img mt-1" />
                                    <span className="core-links">
                                        <span className=" footer-content m-1 d-inline-block">{t("Speed to Execution")}</span>
                                        <span className="slash">/</span>
                                        <span className=" m-1 footer-content d-inline-block">{t("Agility")}</span>
                                        <span className="slash">/</span>
                                        <span className=" m-1 footer-content d-inline-block">{t("One SDI")}</span>
                                        <span className="slash">/</span>
                                        <span className=" m-1 footer-content d-inline-block">{t("Operational Excellence")}</span>
                                        <span className="slash">/</span>
                                        <span className=" m-1 footer-content d-inline-block">{t("Courage")}</span>
                                    </span>
									<img src="/Images/sdi logo.png" alt="SDi Logo" />
								</div>
								<div className="col-12">
									<p className="font-12 mt-3">										
										<a href="http://www.sdi.com/" className="footer-info mr-2 pr-2">http://www.sdi.com/</a>
                                        <a href="mailto:sdi@mailac.custhelp.com" className="footer-info mr-2 pr-2"><Trans>Contact sdi customer care</Trans></a></p>
								</div>
							</div>
						</div>
					</div>
				</div>}
		</div>)

	}
};
//[PC_07, 09/07/21, Author - Dhevanesam R]
export default withTranslation()(BgOverlayComponent);