import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as CookieService from '../CookieService';
import SDIConstants from '../SDIConstants';
import * as AddNewPunchinUserAction from '../actions/AddNewPunchinUserAction';
import * as MenuAction from '../actions/MenuAction';
import AddNewPunchinUserStore from '../stores/AddNewPunchinUserStore'
import * as $ from 'jquery';
//[11/26/21, CAS - 31, Dhevanesam R]
import Select, { components } from "react-select";
import i18n from '../i18n';
import { withTranslation, Trans } from 'react-i18next';
let currentPagePath;
//[11/26/21, CAS - 31, Dhevanesam R]
//[PC_01, 03/18/22, CAS - 31, Dhevanesam R]
const options = [
    {
        value: 'en', label: 'English', icon:
            "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
    },
    {
        value: 'es', label: 'Español', icon: "http://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg"
    },
    {
        value: 'fr', label: 'Français (Canada)', icon: "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"
    }
]
const { Option } = components;
const IconOption = props => (
    <Option {...props}>
        <img
            src={props.data.icon}
            alt={props.data.label}
            style={{ "width": "12px" }}
        />
        {" "}
        {props.data.label}
    </Option>
);
class AddNewPunchinUser extends Component {
    constructor(props) {
        super(props);
        this.assignNewUserData = this.assignNewUserData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitclick = this.submitclick.bind(this);

        this.submitclicknavigation = this.submitclicknavigation.bind(this);
        this.state = {
            FlagTable: [],
            Userdata: [],
            pagedata: [],
            multisitedata: [], EMalert: "", FNalert: "", LNalert: "", lblBusUnitValue: "", rcbSiteGroup_Visible: false, lblUserId1_Visible: false,
            FirstName: "", LastName: "", CPasswrd: "", Passwrd: "", EMail: "", txtEmail_Visible: false, lblEmailValue: "",
            dropShipto_Visible: false, lblBusUnitValue_Visible: false, lblNewPW: false, lblUserId1: "", MultiBU: [], lblNewPW: false, shipds: [], lblShipto_Visible: false, lblshipto: "SHIP",
            //[11/26/21, CAS - 31, Dhevanesam R]
            language: CookieService.getLocalStorageValues("newlanguage") != null && CookieService.getLocalStorageValues("newlanguage") != undefined && CookieService.getLocalStorageValues("newlanguage") != false ? CookieService.getLocalStorageValues("newlanguage") : options[0],
            FNameError: "", LNameError: "", EmailError: "", PWError: "", ConfirmPWError: "", ShipToError:""

        }

    }
    componentDidMount() {
        debugger;
        //let isTokenPresent = CookieService.getCookieValue(SDIConstants.access_token);
        //if (isTokenPresent != "") {
        //    this.props.history.push({
        //        pathname: '/HomePage',
        //    });
        //}
        //else {
        //    sessionStorage.clear();
        //    localStorage.clear();
        //}
      
        console.log("bu", CookieService.getLocalStorageValues("Session_BUS_UNIT"));
        let currentpage = window.location.href;
        const queryParams = new URLSearchParams(window.location.search)

        const USERID = queryParams.get('USERID');
        const BU = queryParams.get('BU');

        const ASCENDBU = queryParams.get('ASCENDBU');
        const ASCENDEMAIL = queryParams.get('ASCENDEMAIL');
        const EMAIL = queryParams.get('EMAIL');
        const Session_BUS_CROSS = queryParams.get('Session_BUS_CROSS');
        console.log("currentpage", queryParams);
        console.log("USERID", USERID);
        console.log("BU", BU);
        let AdduserBO = {
            USERID: USERID,
            EMAIL: EMAIL,
            ASCENDBU: ASCENDBU,
            ASCENDEMAIL: ASCENDEMAIL,
            BU: BU,
            Session_USERID: "",
            Session_BUSUNIT: CookieService.getLocalStorageValues("Session_BUSUNIT"),
            Session_UserName: "",
            Session_SDIEMP: "",
            Session_BUS_CROSS: Session_BUS_CROSS


        };
        AddNewPunchinUserAction.pageLoad(AdduserBO);        
        AddNewPunchinUserStore.on('load', this.assignNewUserData);
        AddNewPunchinUserStore.on('submit', this.submitclicknavigation);
    }
    componentWillUnmount() {
        AddNewPunchinUserStore.removeListener('load', this.assignNewUserData);
        AddNewPunchinUserStore.removeListener('submit', this.submitclicknavigation);
    }
    //[11/26/21, CAS - 31, Dhevanesam R]
    onLanguageHandle = (e) => {
        debugger
        let newObj = e;
        let newLang = e.value;
        debugger
        CookieService.updateLocalStorageValues("oldlanguage", this.state.language);
        CookieService.updateLocalStorageValues("newlanguage", newObj);
        i18n.changeLanguage(newLang);
        this.setState({ language: newObj });
    };
    assignNewUserData = () => {
        // $(`#${SDIConstants.SDILoader}`).addClass('hide');
        debugger;
        let PageLoadResponse = JSON.parse(AddNewPunchinUserStore.PunchinData);
        let Flag = JSON.parse(AddNewPunchinUserStore.PunchinData).FlagTable;
        let sessiondata = [];
        let MultiBUTable = [];
        let shipdsTable = [];
        let currentBU = [];
        if (PageLoadResponse.MultiBUTable) {
            //[Zeus 276, 02/21/2024] - Conversion of BU DD into Typeahead DD - Kishore
            PageLoadResponse.MultiBUTable.map((val) => {
                let locObj = { label: val.DESCRIPTION, value: val.BUSINESS_UNIT, name: "lblBusUnitValue" };
                MultiBUTable.push(locObj);
            })
            currentBU = MultiBUTable.filter((x) => x.value == Flag[0].lblBusUnitValue);
            currentBU = currentBU[0];
        }
        else {
            currentBU = { value: Flag[0].lblBusUnitValue }
        }
        if (PageLoadResponse.shipds) {
            shipdsTable = PageLoadResponse.shipds;
        }
        sessiondata = JSON.parse(AddNewPunchinUserStore.PunchinData);
        console.log("JSON.parse(AddNewPunchinUserStore.PunchinData)", JSON.parse(AddNewPunchinUserStore.PunchinData))
        // let response = JSON.parse(PunchinStore.PunchinData.FlagTable)[0];
       // let Shipto_Visible = true;
        debugger;
        if (Flag[0].lblShipto_Visible == "False") {
            $("#lblShipto_Visible").hide();
            //Shipto_Visible = false;
        }
        let buslbl = false;
        let EMtxt_Visible = false;
        let Shipto_Visible = false;
        let PWtxt_Visible = false;
        let SiteGroup_Visible = false;
        let userid_visible = false;
        let password_visible = false;
        if (Flag[0].lblBusUnitValue_Visible == "True") {
            buslbl = true;
        }
        if (Flag[0].txtEmail_Visible == "True") {
            EMtxt_Visible = true;
        }
        if (Flag[0].dropShipto_Visible == "True") {
            Shipto_Visible = true;
        }
        if (Flag[0].lblNewPW == "True") {
            PWtxt_Visible = true;
        }
        if (Flag[0].rcbSiteGroup_Visible == "True") {
            SiteGroup_Visible = true;
        }
        if (Flag[0].lblUserId1_Visible == "True" || Flag[0].lblUserId1_Visible==null) {
            userid_visible = true;
        }
        if (Flag[0].lblNewPW == "True") {
            password_visible = true;
        }
        console.log("lblUserId1", Flag[0].lblUserId1);
        this.setState({
            FlagTable: Flag, lblBusUnitValue: currentBU, lblUserId1: Flag[0].lblUserId1,
            txtEmail_Visible: EMtxt_Visible, lblEmailValue: Flag[0].lblEmailValue, dropShipto_Visible: Shipto_Visible,
            lblBusUnitValue_Visible: buslbl, lblNewPW: PWtxt_Visible, rcbSiteGroup_Visible: SiteGroup_Visible,
            lblUserId1_Visible: userid_visible, lblNewPW: password_visible, MultiBU: MultiBUTable, shipds: shipdsTable
        });


    }
    BusUnitDropdownmap = () => {
        //debugger;
        return this.state.MultiBU.map((value, ID) => {
            return (
                <option key={value.BUSINESS_UNIT} value={value.BUSINESS_UNIT}>{value.DESCRIPTION}</option>
            )

        });
    }
    
    shipDropdownmap = () => {
        //debugger;
        return this.state.shipds.map((value, ID) => {
            return (
                <option key={value.CUSTID} value={value.CUSTID}>{value.LOCNAME}</option>
            )

        });
    }

    submitclick = (event) => {
        const { t } = this.props;
        let FNameErrMsg = "";
        let LNameErrMsg = "";
        let EmailErrMsg="";
        let PWErrMsg="";
        let ConfirmPWErrMsg = "";
        let ShipToErrMsg = "";
        event.preventDefault();
        debugger;
        console.log("acces", CookieService.getCookieValue(SDIConstants.access_token));
        let errormsg = false;
        if (this.state.Passwrd == "" || this.state.CPasswrd == "" || this.state.FirstName == "" || this.state.LastName == "" || this.state.EMail == "") {
            console.log("submit click");
            let alert = ""
            if (this.state.FirstName == "") {
                
                alert = (t("Please enter first name"));               
                FNameErrMsg = "Please enter first name";
                //this.setState({ FNalert: alert });
                //return;
                errormsg = true;
            }
            if (this.state.LastName == "") {

                alert = (t("Please enter last name"));
                LNameErrMsg = "Please enter last name";
                //this.setState({ LNalert: alert });
                // return;
                errormsg = true;
            }
            if (this.state.txtEmail_Visible) {
                if (this.state.EMail == "") {
                    alert = (t("Please enter email address"));
                    EmailErrMsg = "Please enter email address";
                    // this.setState({ EMalert: alert });
                    // return;
                    errormsg = true;
                }
                else {
                    EmailErrMsg = "";
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (!pattern.test(this.state.EMail)) {
                        errormsg = true;
                        EmailErrMsg = "Please enter valid email address";

                    }
                }

            }
            if (this.state.lblNewPW) {
                if (this.state.Passwrd == "") {

                    alert = (t("Please enter password"));
                    PWErrMsg = "Please enter password";
                    // this.setState({ PWalert: alert });
                    // return;
                    errormsg = true;
                }
                if (this.state.CPasswrd == "") {

                    alert = (t("Please enter confirm password"));
                    ConfirmPWErrMsg = "Please enter confirm password";
                    // this.setState({ LNalert: alert });
                    //  return;
                    errormsg = true;
                }
            }

        }
        if (this.state.txtEmail_Visible) { 
        if (this.state.EMail != "") {
            EmailErrMsg = "";
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.EMail)) {
                errormsg = true;
                EmailErrMsg = "Please enter valid email address";

            }
        }
    }
        if (this.state.lblNewPW) {
            if (this.state.Passwrd != "") {
                PWErrMsg = "";
                let firstletter1 = this.state.Passwrd.slice(0, 1)
                let Numtest = this.state.Passwrd.match(/[0-9]/g)
                if (this.state.Passwrd.length < 6) {
                    PWErrMsg = "Password must contain at least six characters with 1 number and 1 letter - must start with a letter!";
                    errormsg = true;
                }
                else if ((/[a-zA-Z]/).test(firstletter1) == false || Numtest == null || Numtest == undefined || Numtest == "") {
                    PWErrMsg = "Password must contain at least 1 number and 1 letter - must start with a letter!";
                    errormsg = true;
                }
                else {
                    PWErrMsg = "";
                }
            }

            if (this.state.CPasswrd != this.state.Passwrd ) {
                errormsg = true;
                ConfirmPWErrMsg = "Password doesn't match";
            }
                     

        }
        if (this.state.dropShipto_Visible != false){
            if (this.state.lblshipto == "SHIP") {
                
                ShipToErrMsg= "Please select shipto";
                errormsg = true;
            }
        }

        if (errormsg == false) {
            debugger;
            const queryParams = new URLSearchParams(window.location.search)

            const USERID = queryParams.get('USERID');
            const BU = queryParams.get('BU');

            const ASCENDBU = queryParams.get('ASCENDBU');
            const ASCENDEMAIL = queryParams.get('ASCENDEMAIL');
            const EMAIL = queryParams.get('EMAIL');
            const username = queryParams.get('username');
            const punchincomp = queryParams.get('PUNCHINCOMPNAME');
            const punchinrolename = queryParams.get('PUNCHINROLENAME');
            const punchinbu = queryParams.get('PUNCHINBUSNUNIT');
            const sGUID = queryParams.get('SGUID');
            const Session_BUS_CROSS = queryParams.get('Session_BUS_CROSS');
            const punchinkey = queryParams.get('PUNCHINVALIDATIONKEY');
            const workOrder = queryParams.get('WORKORDER'); //[Zeus 208, 11/22/2023] - Work order validation through Add new user Punchin - Kishore
            //let loginClickBO = {
            //    userName: this.state.userName,
            //    password: this.state.password,
            //    failureAttemptCount: failureAttemptCount,
            //    failureAttemptUname: this.state.userName,
            //    PARAM_DESTINATION_URL: ""
            //}
            console.log("buscross", Session_BUS_CROSS);
            console.log("sGUID", sGUID);
            let eml = ""

            if (this.state.EMail == "") {
                eml = EMAIL;
            }
            else {
                eml = this.state.EMail;
            }
            //let User_Id = ""
            //if (USERID == "" || USERID == null || USERID== undefined) {
            //    USERID = username
            //}

            let submitBO = {
                punchin: "punchin",
                ASCENDBU: ASCENDBU,
                ASCENDEMAIL: ASCENDEMAIL,
                BU: BU,
                USERID: USERID == "" || USERID == null || USERID == undefined ? username : USERID,
                EMAIL: EMAIL,
                lblUserId1: this.state.lblUserId1,
                txtNewPW_Visible: this.state.lblNewPW,
                txtNewPW: this.state.Passwrd,
                txtFirst: this.state.FirstName,
                txtLast: this.state.LastName,
                lblBusUnitValue_Visible: this.state.lblBusUnitValue_Visible,
                lblBusUnitValue: this.state.FlagTable[0].lblBusUnitValue,
                lblEmailValue: eml,
                rcbSiteGroup: this.state.lblBusUnitValue.value,
                strshipto: this.state.lblshipto,
                dropShipto_Visible: this.state.dropShipto_Visible,
                PUNCHINCOMPNAME: punchincomp,
                PUNCHINROLENAME: punchinrolename,
                PUNCHINBUSNUNIT: punchinbu,
                SGUID: sGUID,
                Session_BUS_CROSS: Session_BUS_CROSS,
                PUNCHINVALIDATIONKEY: punchinkey,
                workOrder: workOrder
                //userName: this.state.lblUserId1,
                //password: this.state.Passwrd,
                //failureAttemptCount: 1,
                //failureAttemptUname: this.state.lblUserId1,
                //PARAM_DESTINATION_URL: ""
            }
            $(`#${SDIConstants.SDILoader}`).removeClass('hide');
            AddNewPunchinUserAction.punchinsession(submitBO);
            //********punchin*****
            //AddNewPunchinUserAction.punchinClick(submitBO);
            //********************
        }
        this.setState({ FNameError: FNameErrMsg, LNameError: LNameErrMsg, EmailError: EmailErrMsg, PWError: PWErrMsg, ConfirmPWError: ConfirmPWErrMsg, ShipToError: ShipToErrMsg})
    }

    submitclicknavigation = () => {
        const { t } = this.props;
        let session = [];
        //$(`#${SDIConstants.SDILoader}`).addClass('hide');
        let Flag = JSON.parse(AddNewPunchinUserStore.PunchinData).FlagTable;
        if (Flag[0].txtDisplError == "" || Flag[0].txtDisplError == null || Flag[0].txtDisplError == undefined || Flag[0].txtDisplError == "undefined") {
            document.getElementById("lblerror").innerHTML = "";
        }
        else {
            $(`#${SDIConstants.SDILoader}`).addClass('hide');
            document.getElementById("lblerror").innerHTML = (t(Flag[0].txtDisplError));
            return;
        }

        if (Flag[0].lblDBError == "" || Flag[0].lblDBError == null) {
            let userData = JSON.parse(AddNewPunchinUserStore.PunchinData);
            session = userData.sessiontable;
            if (session.length > 0) {
                debugger;
                console.log("session", session);
                session = session[0];
            }
            console.log(userData)
            let localStorageSuccess = false;
            console.log("accesbEFOR", CookieService.getCookieValue(SDIConstants.access_token));            
            //[11/26/21, CAS - 31, Dhevanesam R]
            if (session.Session_UserID != null && session.Session_UserID != undefined && session.Session_UserID != "") {               
                let newLang = CookieService.getLocalStorageValues("newlanguage");
                localStorage.clear();
                localStorageSuccess = CookieService.insertLocalStorageValues(session);
                CookieService.updateLocalStorageValues("pickupVendor", userData.pickupVendor);   
                CookieService.updateLocalStorageValues("newlanguage", newLang);
            }
            console.log("accesaTERF", CookieService.getCookieValue(SDIConstants.access_token));
            let isTokenPresent = CookieService.getCookieValue(SDIConstants.access_token);
            //$(`#${SDIConstants.SDILoader}`).addClass('hide');
            if (isTokenPresent != "" && localStorageSuccess) {
                let randomId = Math.floor(Math.random() * 10000000000)
                CookieService.updateLocalStorageValues("Session_ID", randomId)
                console.log("uuid", randomId)
                console.log("Session_ID", CookieService.getLocalStorageValues("Session_ID"))
                MenuAction.refreshMenuItems(true, this.props);
            }
        }
    }

    handleChange = (event) => {
        if (event.target) {
            //[Zeus 208, 11/24/2023] - Bug fix to restrict latin words - Kishore
            if (event.target.name == 'FirstName' || event.target.name == 'LastName' || event.target.name == 'EMail') {
                event.target.value = event.target.value.replace(/[^\x20-\xFF]/g, "");
            }
            //[05/17/22, PC_AN_01, Bug - 2143, Dhevanesam R]
            if (event.target.value == "SHIP") {
                this.setState({ [event.target.name]: event.target.value});
            }
            else {
                this.setState({ [event.target.name]: event.target.value, ShipToError: ""});
            }
        }
        else {
            this.setState({ [event.name]: event });
        }
              
    }
    addnewpunchinuserCancel = (event) => {
        this.props.history.push({
            pathname: `\Punchinend`,
        });
    }

    render() {
        const { t } = this.props;        
        $(".changeevent").on('keyup change', function (event) {

            let id = $(this).attr('id');
            let targetval = $('#' + id).val();            
            switch (id) {
                case "FNid": {
                    if (targetval == "") {
                        document.getElementById("FNalert").innerHTML = (t("Please enter first name"));
                    }
                    else {
                        document.getElementById("FNalert").innerHTML = "";
                    }
                    break;
                }
                case "LNid": {
                    if (targetval == "") {
                        document.getElementById("LNalert").innerHTML = (t("Please enter last name"));
                    }
                    else {
                        document.getElementById("LNalert").innerHTML = "";
                    }
                    break;
                }
                case "EMid": {
                    if (targetval == "") {
                        document.getElementById("EMalert").innerHTML = (t("Please enter email address"));
                    }
                    else {
                        document.getElementById("EMalert").innerHTML = "";
                        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                        if (!pattern.test(targetval)) {

                            document.getElementById("EMalert").innerHTML = (t("Please enter valid email address"));

                        }
                    }
                    break;
                }

                case "PWid": {
                    if (targetval == "") {
                        document.getElementById("PWalert").innerHTML = (t("Please enter password"));
                    }
                    else {
                        document.getElementById("PWalert").innerHTML = "";
                        let firstletter1 = targetval.slice(0, 1)
                        let Numtest = targetval.match(/[0-9]/g)
                        if (targetval.length < 6) {
                            document.getElementById("PWalert").innerHTML = (t("Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"));
                        }
                        else if ((/[a-zA-Z]/).test(firstletter1) == false || Numtest == null || Numtest == undefined || Numtest == "") {
                            document.getElementById("PWalert").innerHTML = (t("Password must contain at least 1 number and 1 letter - must start with a letter!"));
                        }
                        else {
                            document.getElementById("PWalert").innerHTML = "";
                        }
                    }
                    break;
                }
                case "CPWid": {
                    let Password = document.getElementById("PWid").innerText
                    let Passwordl = $('#PWid').val()
                    console.log("Password", Passwordl)
                    if (targetval == "" && Passwordl != null && Passwordl != "") {
                        document.getElementById("CPWalert").innerHTML = (t("Please enter confirm password"));
                    }
                    else if (Passwordl == null || Passwordl == "") {
                        document.getElementById("CPWalert").innerHTML = (t("Please enter password"));
                    }
                    else if (Passwordl != targetval) {
                        document.getElementById("CPWalert").innerHTML = (t("Password doesn't match"));
                    }
                    else {
                        document.getElementById("CPWalert").innerHTML = "";
                        let firstletter = targetval.slice(0, 1)
                        let NumtestC = targetval.match(/[0-9]/g)
                        if (targetval.length < 6) {
                            document.getElementById("CPWalert").innerHTML = (t("Password must contain at least six characters with 1 number and 1 letter - must start with a letter!"));
                        }
                        else if ((/[a-zA-Z]/).test(firstletter) == false || NumtestC == null || NumtestC == undefined || NumtestC == "") {
                            document.getElementById("CPWalert").innerHTML = (t("Password must contain at least 1 number and 1 letter - must start with a letter!"));
                        }
                        else {
                            document.getElementById("CPWalert").innerHTML = "";
                        }
                    }
                    break;
                }

            }            
        });
        
      
        return (
            <div className="container-fluid content-section-margin">
                <div className="row">
                    {/* content part starts */}
                    {/*[PC_TD_10, CAS - CSS Issue in translation dropdown, Dhevanesam R*/}
                    <div className="col-md-12 page-heading mb-3 mt-3">
                        <h5 className="page-title"><Trans>New PunchIn User Registration</Trans></h5>
                        <div className="mb-0 mt-0 TransCSS" style={{"float": "right", "marginRight": "210px" }}>
                            <Select
                                id="pickupstore_DD"
                                name="PickupStoreSelected"
                                className="DropDown-Font-Size"
                                onChange={(e) => this.onLanguageHandle(e)}
                                value={this.state.language}
                                defaultValue={options[0]}
                                options={options}
                                components={{ Option: IconOption }}
                            />
                        </div>
                    <div className="row punchin-form-container">
                        <span className="float-left err-msg text-danger" id="lblerror"></span>
                        <span className="ml-auto indicate-alert"><Trans>All fields are mandatory</Trans></span>
                        <div className="punchin-form-set col-md-12 float-left">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <label className="float-left col-md-5 mandatory form-label catalog-label p-0"><Trans>First Name</Trans></label>
                                    <div className="float-left col-md-7 p-0">
                                            <input type="text" className="form-control changeevent" placeholder={t("Enter First Name")} maxLength="30" id="FNid" name="FirstName" value={this.state.FirstName} onChange={this.handleChange} />
                                            <span className="float-left err-msg text-danger" id="FNalert">{t(this.state.FNameError)}</span>
                                    </div>
                                </div>
                               
                                    <div className="col-md-12 mb-4">
                                        <label className="float-left col-md-5 mandatory form-label catalog-label p-0"><Trans>Last Name</Trans></label>
                                        <div className="float-left col-md-7 p-0">
                                            <input type="text" className="form-control changeevent" placeholder={t("Enter Last Name")} maxLength="30" id="LNid" name="LastName" value={this.state.LastName} onChange={this.handleChange} />
                                            <span className="float-left err-msg text-danger" id="LNalert">{t(this.state.LNameError)}</span>
                                        </div>
                                    </div> 
                                {this.state.lblUserId1_Visible ?
                                    <div className="col-md-12 mb-4">
                                        <label className="float-left col-md-5 form-label catalog-label p-0"><Trans>User ID</Trans></label>
                                        <div className="float-left col-md-7 p-0">
                                            <label>{this.state.lblUserId1}</label>
                                        </div>
                                    </div> : null}
                                {this.state.lblNewPW ?<>
                                <div className="col-md-12 mb-4">
                                    <label className="float-left col-md-5 mandatory form-label catalog-label p-0"><Trans>Password</Trans></label>
                                    <div className="float-left col-md-7 p-0" id="lblEmailValue">
                                                <input type="password" className="form-control changeevent" placeholder={t("Enter Password")} id="PWid" maxLength="10" name="Passwrd" value={this.state.Passwrd} onChange={this.handleChange} />
                                                <span className="float-left err-msg text-danger" id="PWalert">{t(this.state.PWError)}</span>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label className="float-left col-md-5 mandatory form-label catalog-label p-0"><Trans>Confirm Password</Trans></label>
                                    <div className="float-left col-md-7 p-0" id="lblEmailValue">
                                                <input type="password" className="form-control changeevent" placeholder={t("Enter Confirm Password")} id="CPWid" maxLength="10" name="CPasswrd" value={this.state.CPasswrd} onChange={this.handleChange} />
                                                <span className="float-left err-msg text-danger" id="CPWalert">{t(this.state.ConfirmPWError)}</span>
                                    </div>
                                    </div>
                                    </>:null}
                                {this.state.txtEmail_Visible ?
                                    <div className="col-md-12 mb-4" id="txtEmail_Visible">
                                        <label className="float-left col-md-5 mandatory form-label catalog-label p-0"><Trans>Email Address</Trans></label>
                                            <div className="float-left col-md-7 p-0" id="lblEmailValue">
                                                {/*[5/26/2022] Increased email address field length to 60 after Ben's request -Poornima S*/}
                                                <input type="text" className="form-control changeevent" placeholder={t("Enter E-Mail address")} name="EMail" id="EMid" maxLength="60" value={this.state.EMail} onChange={this.handleChange} />
                                                <span className="float-left err-msg text-danger" id="EMalert">{t(this.state.EmailError)}</span>
                                        </div>
                                    </div> :
                                    <div className="col-md-12 mb-4" >
                                        <label className="float-left col-md-5 form-label catalog-label p-0"><Trans>Email Address</Trans></label>
                                        <div className="float-left col-md-7 p-0" >
                                            <label>{this.state.lblEmailValue}</label>
                                        </div>
                                        </div>}
                                    {/*[5/12/22, CAS - 31, Dhevanesam R]*/}
                                <div className="col-md-12 mb-4" id="lblShipto_Visible">
                                    <label className="float-left col-md-5 form-label mandatory catalog-label p-0"><Trans>Ship To</Trans></label>
                                    <div className="float-left col-md-7 p-0">
                                        <select className="form-control" name="lblshipto" value={this.state.lblshipto} onChange={this.handleChange}>
                                                <option value="SHIP">{t("Select")}</option>
                                            {this.shipDropdownmap()}
                                        </select>
                                            <span className="float-left err-msg text-danger" id="Shipalert">{t(this.state.ShipToError)}</span>
                                    </div>
                                </div>

                                <div className="col-md-12 mb-4">
                                    <label className="float-left col-md-5 form-label catalog-label p-0" id="lblBusUnitValue_Visible"><Trans>Business Unit</Trans></label>
                                        {this.state.rcbSiteGroup_Visible ?
                                            <div className="float-left col-md-7 p-0" style={{ "fontSize": "14px" }}>
                                                {/*<select className="form-control" name="lblBusUnitValue" value={this.state.lblBusUnitValue} onChange={this.handleChange}>
                                                
                                                {this.BusUnitDropdownmap()}
                                            </select>*/}
                                                {/*[Zeus 276, 02/21/2024] - Conversion of BU DD into Typeahead DD - Kishore*/}
                                                <Select
                                                    id="BU"
                                                    name="MultiSiteBU"
                                                    value={this.state.lblBusUnitValue}
                                                    options={this.state.MultiBU}
                                                    onChange={this.handleChange}
                                                    styles={{
                                                        menuList: (provided) => ({
                                                            ...provided,
                                                            maxHeight: '195px', 
                                                        }),}}
                                                />
                                            </div> :
                                            <div className="float-left col-md-7 p-0">
                                                <label>{this.state.lblBusUnitValue.value}</label>
                                            </div>}
                                </div>
                                <div className="col-md-12">
                                    <label className="float-left col-md-5 form-label catalog-label" />
                                        <div className="float-left col-md-7 p-0">
                                            {/*[4/18/22, Bug - 2076, Dhevanesam R]*/}
                                            {/*[8/8/22, Bug - 2076, PC_AP_01, Dhevanesam R]*/}
                                        <button className="btn btn-secondary custom-btn-secondary secondary-btn float-left px-4 mr-3" onClick={this.addnewpunchinuserCancel}><Trans>Cancel</Trans></button>
                                        <button className="btn btn-primary custom-btn-primary float-left px-4 mr-3" onClick={this.submitclick}><Trans>Submit</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
};
export default withTranslation()(AddNewPunchinUser);
